export const Key = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.7897 14.9301C17.7297 16.9801 14.7797 17.6101 12.1897 16.8001L7.47966 21.5001C7.13966 21.8501 6.46966 22.0601 5.98966 21.9901L3.80966 21.6901C3.08966 21.5901 2.41966 20.9101 2.30966 20.1901L2.00966 18.0101C1.93966 17.5301 2.16966 16.8601 2.49966 16.5201L7.19966 11.8201C6.39966 9.22007 7.01966 6.27007 9.07966 4.22007C12.0297 1.27007 16.8197 1.27007 19.7797 4.22007C22.7397 7.17007 22.7397 11.9801 19.7897 14.9301Z"
        stroke="#2B4B6D"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.88965 17.49L9.18965 19.79"
        stroke="#2B4B6D"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5 11C15.3284 11 16 10.3284 16 9.5C16 8.67157 15.3284 8 14.5 8C13.6716 8 13 8.67157 13 9.5C13 10.3284 13.6716 11 14.5 11Z"
        stroke="#2B4B6D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
