import { Button, Icon, Text, View } from "reshaped";

import { NoEvalutionMessage, Plus } from "icons";
import useSpacing from "components/hooks/useSpacing";
import useTranslation from "components/hooks/useTranslation";

const NoEvalution = (props) => {
  const { setAddEvaluation, selectCategory, setSelectedCategory } = props;

  const { t } = useTranslation();
  const { xs, xxl } = useSpacing();

  const handleAddEvalution = () => {
    if (selectCategory !== "evaluation") setSelectedCategory("evaluation");
    setAddEvaluation(true);
  };

  return (
    <View
      direction="row"
      align="center"
      justify="center"
      height="100%"
      attributes={{
        style: {
          height: "calc(100% - 24px)",
        },
      }}
    >
      <View padding={xs} align="center" height="100%" justify="center">
        <Text color="neutral-faded" variant="body-strong-1">
          {t("There is no evalution for this candidate")}
        </Text>
        <Icon svg={NoEvalutionMessage} size={xxl * 3.5} />
        <Button color="primary" size="small" onClick={handleAddEvalution} startIcon={Plus}>
          {t("Add your evaluation")}
        </Button>
      </View>
    </View>
  );
};

export default NoEvalution;
