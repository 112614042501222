export const ArrowRightPrimary = () => {
  return (
    <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.025 0.0499992L18 6.025L12.025 12L10.95 10.925L15.1 6.775L0 6.775V5.275L15.1 5.275L10.95 1.125L12.025 0.0499992Z"
        fill="var(--rs-color-foreground-primary)"
      />
    </svg>
  );
};
