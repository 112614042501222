import { useState, useEffect } from "react";
import { Container, Text, View } from "reshaped";

import ReactMarkdown from "react-markdown";
import { useNavigate } from "react-router";
import useSpacing from "components/hooks/useSpacing";
import { ReshapedLoader } from "components/reusables";
import UseTerms from "assets/markdowns/privacy-policy.md";
import common from "components/styles/reshaped/reusables/common.module.css";

const DataPrivacy = () => {
  const [privacyTerms, setPrivacyTerms] = useState("");

  const { xs } = useSpacing();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchMarkdown() {
      try {
        const privacyResponse = await fetch(UseTerms);
        const privacyMarkdown = await privacyResponse.text();
        if (typeof privacyMarkdown === "string") setPrivacyTerms(privacyMarkdown);
      } catch (err) {
        navigate("/register");
      }
    }
    fetchMarkdown();

    //eslint-disable-next-line
  }, []);

  return (
    <Container width="800px">
      <View className={common.markdown__wrapper}>
        {!privacyTerms && (
          <View
            attributes={{
              style: {
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              },
            }}
          >
            <ReshapedLoader />
          </View>
        )}
        {privacyTerms && (
          <View className={common.markdown}>
            <ReactMarkdown children={privacyTerms} />
          </View>
        )}
        <View
          className={common.footer}
          height="120px"
          direction="row"
          gap={xs}
          align="center"
          justify="center"
        >
          <Text color="neutral-faded" variant="body-medium-1">
            Powered
          </Text>
          <Text color="neutral-faded" variant="body-medium-1">
            by
          </Text>
          <Text color="primary" variant="body-strong-1">
            CooperHire
          </Text>
        </View>
      </View>
    </Container>
  );
};

export default DataPrivacy;
