import { useEffect } from "react";
import { Plus, VolumeHigh } from "icons";
import { useNavigate } from "react-router-dom";
import useSpacing from "components/hooks/useSpacing";
import { useDispatch, useSelector } from "react-redux";
import { exceededJobsLimit } from "utils/pages/settings";
import { ShowToastWithTranslation } from "utils/showToast";
import useTranslation from "components/hooks/useTranslation";
import { Button, View, Text, Icon, useToast } from "reshaped";
import { OperationFailedWithTranslation } from "components/reusables";
import { fetchSubscriptionData } from "redux/subscriptions/subscriptionsSlice";

export const JobsTitleGroup = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();

  const { sm, lg } = useSpacing();
  const dispatch = useDispatch();

  const state = useSelector((state) => state);
  const { jobs: jobsFilters } = state.filters;
  const { jobs } = useSelector((state) => state.job);
  const { subscription } = useSelector((state) => state.subscriptions);

  const { statuses } = jobsFilters;
  const { selected: selectedStatuses } = statuses;

  useEffect(() => {
    if (!subscription.subscription_type) {
      dispatch(fetchSubscriptionData());
    }
    //eslint-disable-next-line
  }, []);

  const handleRedirect = (url) => {
    if (url === "new_job") {
      if (exceededJobsLimit(subscription)) {
        ShowToastWithTranslation({
          toast,
          Children: OperationFailedWithTranslation,
          text: `${t(
            "Please update your payment plan before adding more jobs. You cannot add more than"
          )}
          ${subscription.max_jobs} ${t("jobs with the")} ${subscription.subscription_type} ${t(
            "plan job_limit_error"
          )}`,
        });
        return;
      }
      navigate("new");
    }

    if (url === "bookings") {
      navigate("/bookings");
    }
  };

  return (
    <View direction="row" align="center" gap={sm}>
      <Text variant="title-2">{t("All Jobs")}</Text>
      <Text variant="body-1" color="neutral-faded">
        {Array.isArray(selectedStatuses) && selectedStatuses.length > 0
          ? t("Filtered")
          : t("All Created")}{" "}
        ({jobs ? jobs.length : 0})
      </Text>
      <View.Item grow>
        <View direction="row" gap={lg} align="center" justify="end">
          <View borderRadius="medium" overflow="hidden">
            <Button
              color="primary"
              onClick={() => handleRedirect("new_job")}
              attributes={{ "data-cooper": "create a job" }}
            >
              <View gap={sm} direction="row" align="center" padding={[0, sm]}>
                <Icon color="primary" svg={Plus} />
                {t("Create job")}
              </View>
            </Button>
          </View>
          <View borderColor="primary" borderRadius="medium" overflow="hidden">
            <Button variant="ghost" color="primary" onClick={() => handleRedirect("bookings")}>
              <View gap={sm} direction="row" align="center" padding={[0, sm]}>
                <Icon color="primary" svg={VolumeHigh} />
                {t("Promote")}
              </View>
            </Button>
          </View>
        </View>
      </View.Item>
    </View>
  );
};
