import { Button } from "reshaped";
import { useNavigate } from "react-router-dom";
import useTranslation from "components/hooks/useTranslation";

export const UpdateSuccessful = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/login");
  };

  return (
    <Button onClick={handleNavigate} fullWidth color="primary">
      {t("Log in")}
    </Button>
  );
};
