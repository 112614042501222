import { useState } from "react";
import { Button, Text, View } from "reshaped";
import useSpacing from "components/hooks/useSpacing";
import useTranslation from "components/hooks/useTranslation";
import { FilterAccordion } from "components/pages/jobs";

export const FilterMenu = ({ statusRef, closeDropdown }) => {
  const [isUpdated, setIsUpdated] = useState(false);

  const { t } = useTranslation();
  const { xs, md, lg, xl } = useSpacing();

  const handleUpdateStages = ({ updatedData = {} }) => {
    statusRef.current = { ...statusRef.current, ...updatedData };
  };

  const handleClose = () => {
    if (closeDropdown && typeof closeDropdown === "function") closeDropdown({ reset: false });
  };

  return (
    <>
      <View padding={[md, xs]} gap={xl} paddingBottom={xl}>
        <View padding={[0, lg]}>
          <Text variant="body-2">{t("Filters")}</Text>
        </View>
        <View gap={lg}>
          <View>
            <FilterAccordion
              title={t("Job status")}
              optionsName="status"
              isOdd={false}
              handleUpdate={handleUpdateStages}
              setIsUpdated={setIsUpdated}
            />
          </View>
          {isUpdated && (
            <View padding={[0, lg]}>
              <View
                borderRadius="medium"
                overflow="hidden"
                attributes={{ style: { width: "max-content" } }}
              >
                <Button
                  color="primary"
                  onClick={handleClose}
                  attributes={{ "data-cooper": "jobs apply filter" }}
                  disabled={!isUpdated}
                >
                  {t("Apply")}
                </Button>
              </View>
            </View>
          )}
        </View>
      </View>
    </>
  );
};
