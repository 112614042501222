import { isObject } from "utils/miniHelpers";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  countries: {
    data: null,
    error: "",
    loading: false,
  },
};

export const publicSlice = createSlice({
  name: "public",
  initialState,
  reducers: {
    reset: (state) => {
      for (let key in initialState) {
        state[key] = initialState[key];
      }
    },
    setCountriesProperties: (state, actions) => {
      const { payload } = actions;

      if (!isObject(payload)) return;

      const newProperty = Object.entries(payload).reduce((acc, [key, value]) => {
        if (key in initialState["countries"]) {
          return {
            ...acc,
            [key]: value,
          };
        }
        return acc;
      }, {});

      state.countries = {
        ...state.countries,
        ...newProperty,
      };
    },
  },
});
