import { cooperColors } from "utils/colors/accents";

export const Message = ({ color = cooperColors["foreground-neutral"] }) => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <rect width="17" height="17" fill={color} />
      <g clipPath="url(#clip0_41_275)">
        <rect width="1440" height="968" transform="translate(-650 -204)" fill="white" />
        <g opacity="0.3" filter="url(#filter0_d_41_275)">
          <rect x="-58" y="-130" width="778" height="869" rx="12" fill="#EBEEF2" />
        </g>
        <rect x="-36" y="-26" width="719" height="756" rx="8" fill="white" />
        <g clipPath="url(#clip1_41_275)">
          <rect width="17" height="17" fill="url(#pattern0)" />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_41_275"
          x="-62"
          y="-132"
          width="786"
          height="877"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_41_275" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_41_275"
            result="shape"
          />
        </filter>
        <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use xlinkHref="#image0_41_275" transform="scale(0.02)" />
        </pattern>
        <clipPath id="clip0_41_275">
          <rect width="1440" height="968" fill="white" transform="translate(-650 -204)" />
        </clipPath>
        <clipPath id="clip1_41_275">
          <rect width="17" height="17" fill="white" />
        </clipPath>
        <image
          id="image0_41_275"
          width="50"
          height="50"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAFGUlEQVR4nO1Za4xdUxTeHa8K6q1Ex9U4Zu7Z37fPGa4fQyoTFYkQET9GROotqaSCIBFNvBJJPYqkjErjh9COqkcilSaoqT9EpESjiFKiSKdo0WGooWR11padmzvuOXfONVfcLznJzLl7rb3WXo+91jrGtNFGG238LxHH8YkkrwCwlOQQgM0AdgD4XR/5ezPJ10g+KmuttZFpBQDoBHAnyQ9J/tng8wHJO5xzs6ZCgZjkoJx0INB2AM+QvInkWWIh59yh/f39e8kjfydJ0iW/yRoAq4TG06vVlpMsN10Ba+2BAB4EMKYC/CqbW2vnirB5+fX19e3tnDsTwArhxXGFxgAsTpLkgKYoEcfxSSQ/8ZuRHEjT9NiC3fQxb2UAH1tre0yRIHkBgFG1wrsAUtMkOOdOJvmeKjPqnDu/EMYk5wWxsKxUKk03TUapVJpO8vEgdi6aFEMJTAC/qRK3m38ZAO7SvXdJHDbERPyf5LfK6F4zRQCwWC2zrVwuH5ObAckXVYmXjTEdZurQAWCtKvNCLkpJiarEzoZOoWA452aRHFFlzshMCOBVJVpoWgQkbws8pD4AnKAEI2maHmJaBNbawwD8RHJ3kiSz6xKQvFGtscK0GEiuVNluqLtYAkotMs+0GABcqrI9V3cxyU9lsbXWmhZDkiRURTbVXUzyR1kcRdGMIoWQ6pfkhVpPrQfwpRadowCGSa6TO0MyphSTtXhUKpWD1bV+qLuhv8krlco+RSgggQngYQ3UrD3KFgA3V5dDIpMvWrMosk0W9/T0HDlJHaaRvBbAzyrcbknrIqBzrlcqhyiK9uvu7j5IM+U5JO+TqjfoUT5zzp3uGVprj9b3O7Io8r4slrK9UQ305J4NBHoqR8PUAeA8ABuClmG+/OCcqyjPjXW5kHxCg31BI0pIg0XyeX9yIlQjfKy1+wK4J7DmVQCu1/8HsyhypS5e3YgA4jq+9Z2MVavvNe0gN+oBXW3qoaur6wjNJtIHdJr8gb2nAbPWnm0KAsllgZtKMpqZiVB9WoiW5NkQwKLMps+BKIpm+CQkA47MhHEcQ4cAY845l5FsGoCvdLPTTPP6kiW5CEk+pIQbskw0kiQ5Sq3xXfVvzrlbAHwOYI2sU8E6/SAvTCzW2gU6yBsKXZtkn8qzPpcikt91eCbEq+qNe0ieUmsjsW542cnlqOufDN9Lz5GOd6Xh2uWejyou77eavJCBmpQDyuDpiUoHFezUWorIxKVKuKW6fjB8H8dxSZ6qG35ljctwOLciXkAA3yuTtRNljECI7RIvVbGzSHm84d1F5r4A3tEJ5d8NHICF4p7ym0wrA0XmNORaIfRG3epNK3OuCZT+WlPvHFMwSN4duuZkGM30LbA+Q9VlB4AHql2iCHSP12PDRWbEDpLXAPjGlyDlcvnw0L2CieS5piCQHND9XjdFQnp5qUzVjeYGs2GZDn7hlZRMNtm9SF4XjE+z3mnZIAylkJOg1GZHNttV3VdogNeMp4wV9P26zx/OuYtN0dBvG3vuF8lEgfADMhjQr1Zvh2lU2tQc/FNfIOq49LLClQiGd+HJS7l+SY3Gan7QGcrJriN5qzRL1trjent795dATtP0eGmsnHOXS0MH4E3l+5E0YaYZ8CN/f1rSg/9TNarfPSSb7czY5v7iPyRJ2m+KEiqYpOBNMinPU+ZLBSvxQvIRkm9JUtAMN6I9+isAXpJ4CCy9xvxXQTLRr75bpB6bannaaKMN01r4C+NSlRcvVlZ0AAAAAElFTkSuQmCC"
        />
      </defs>
    </svg>
  );
};
