import { useMemo, useRef, useState } from "react";
import { Text, View, useToast } from "reshaped";

import { useDispatch } from "react-redux";
import { candidateSlice } from "redux/candidate";
import { dashboardSlice } from "redux/dashboard";
import useSpacing from "components/hooks/useSpacing";
import { conversationSlice } from "redux/conversation";
import { TextWithEclipses } from "components/reusables";
import { updateCandidateAPI } from "utils/pages/candidate";
import { ShowToastWithTranslation } from "utils/showToast";
import useTranslation from "components/hooks/useTranslation";
import { TransparentInput } from "components/reusables/common";
import { viewingCandidateSlice } from "redux/viewingCandidate";
import { removeHtmlTagsWithAttributes } from "utils/regexHelpers";
import styles from "components/styles/reshaped/candidates.module.css";
import { areKeysAvailableWithType, isString } from "utils/miniHelpers";
import { EMAILVALIDATIONREGEX, NOSYMBOLSINEMAIL } from "utils/RegularExpressions";

export function EditCandidate({
  stage = "",
  candidateID,
  label = "label",
  placeholder = "",
  defaultValue = "",
  propertyName = "",
  ...rest
}) {
  const [value, setValue] = useState(defaultValue);

  const inputRef = useRef();

  const toast = useToast();
  const { xs } = useSpacing();
  const dispatch = useDispatch();
  const { t, locale } = useTranslation();

  const stageStyles = useMemo(() => {
    const list = {
      applied: "applied__fade",
      in_review: "in_review__fade",
      interviewing: "interviewing__fade",
      evaluation: "evaluation__fade",
      offer: "offer__fade",
      hired: "hired__fade",
    };

    const stageClassName = list[stage] ? list[stage] : "";

    return {
      fadeClassName: styles[stageClassName],
    };
  }, [stage]);

  const handleFocusWithLabel = () => {
    if (!(inputRef.current instanceof HTMLInputElement)) return;
    inputRef.current.focus();
  };

  const handleChange = (e) => {
    if (
      !areKeysAvailableWithType({
        object: e.target,
        keys: [
          {
            value: "string",
          },
        ],
      })
    )
      return;

    let value = e.target.value;

    if (propertyName === "phone_no") {
      const ALPHABETREGEX = /[A-Za-z]/g;
      if (ALPHABETREGEX.test(value)) return;
    }

    if (propertyName === "name") {
      if (value.length > 70) return;
    }

    if (propertyName === "email") {
      if (value.match(NOSYMBOLSINEMAIL)) return;
    }

    value = removeHtmlTagsWithAttributes({ text: value });

    setValue(value);
  };

  const handleBlur = async () => {
    if (!value || value === "") {
      setValue(defaultValue);
      return;
    }

    if (value === defaultValue) return;

    let data = {};

    if (propertyName === "name") {
      const splittedName = value.split(" ");
      const nameDetails = splittedName.reduce(
        (acc, each, index) => {
          if (isString(each)) {
            if (index === 0) {
              return {
                ...acc,
                first_name: `${each}`,
              };
            }
            if (index > 0) {
              return {
                ...acc,
                last_name: `${acc.last_name} ${each}`,
              };
            }
          }
          return acc;
        },
        { first_name: "", last_name: "" }
      );

      nameDetails.last_name = nameDetails.last_name.trim();

      if (!isString(nameDetails.last_name)) {
        ShowToastWithTranslation({
          toast,
          Children: FailedToUpdateField,
          text: t("Last Name not found."),
        });
        setValue(defaultValue);
        return;
      }

      data = nameDetails;
    }

    if (propertyName === "email" && !EMAILVALIDATIONREGEX.test(value)) {
      ShowToastWithTranslation({
        toast,
        Children: FailedToUpdateField,
        text: t("Enter a valid E-mail address"),
      });
      setValue(defaultValue);
      return;
    }

    if (["email", "phone_no", "linkedin_url", "utm_source"].includes(propertyName)) {
      data = {
        [propertyName]: value,
      };
    }

    const result = await updateCandidateAPI({ data, id: candidateID });
    if (result.error) {
      let failedText = `Update for "${label}" failed. Please try again!`;

      if (locale === "de") {
        failedText = `Die Aktualisierung für „${label}“ ist fehlgeschlagen. Bitte versuchen Sie es erneut!`;
      }

      setValue(defaultValue);
      ShowToastWithTranslation({
        toast,
        Children: FailedToUpdateField,
        text: failedText,
      });
    }

    if (result.data) {
      dispatch(candidateSlice.actions.updateCandidate({ candidateID, data }));
      dispatch(dashboardSlice.actions.updateCandidate({ candidateID, data }));
      dispatch(viewingCandidateSlice.actions.updateCandidate({ candidateID, data }));
      dispatch(conversationSlice.actions.setDestination(result.data));
    }
  };

  if (
    !propertyName ||
    typeof propertyName !== "string" ||
    !candidateID ||
    typeof candidateID !== "string"
  )
    return;

  return (
    <View gap={xs + xs * 0.6} attributes={{ title: value }}>
      <TextWithEclipses
        text={label}
        variant="caption-1"
        customAttributes={{ onClick: handleFocusWithLabel, style: { cursor: "pointer" } }}
      />
      <View className={`${styles.transparent__wrapper}`}>
        <TransparentInput
          value={value}
          ref={inputRef}
          onBlur={handleBlur}
          onChange={handleChange}
          className={styles.transparent__text__input}
          {...(placeholder && typeof placeholder === "string" ? { placeholder } : {})}
          {...rest}
        />
        <span className={`${stageStyles.fadeClassName} ${styles.fade__chip}`} />
      </View>
    </View>
  );
}

const FailedToUpdateField = ({ children }) => {
  return (
    <Text color="critical" variant="body-strong-1">
      {children}
    </Text>
  );
};
