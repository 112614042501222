import { areKeysAvailableWithType } from "utils/miniHelpers";

/**
 * @param {object} person - The person object.
 * @param {string} person.first_name - The first name of the person.
 * @param {string} person.last_name - The last name of the person.
 * @param {string} person.fallback - The fallback name if there is no name.
 * @returns {string} - The full name of the person.
 */

export function getFullName(person) {
  if (
    !person ||
    typeof person !== "object" ||
    (!areKeysAvailableWithType({ object: person, keys: [{ last_name: "string" }] }) &&
      !areKeysAvailableWithType({ object: person, keys: [{ first_name: "string" }] }))
  )
    return areKeysAvailableWithType({ object: person, keys: [{ fallback: "string" }] })
      ? person.fallback
      : "";

  if (
    areKeysAvailableWithType({
      object: person,
      keys: [{ first_name: "string" }, { last_name: "string" }],
    })
  ) {
    return `${person.first_name} ${person.last_name}`;
  }

  if (
    areKeysAvailableWithType({ object: person, keys: [{ first_name: "string" }] }) &&
    !areKeysAvailableWithType({ object: person, keys: [{ last_name: "string" }] })
  ) {
    return `${person.first_name}`;
  }

  if (
    areKeysAvailableWithType({ object: person, keys: [{ last_name: "string" }] }) &&
    !areKeysAvailableWithType({ object: person, keys: [{ first_name: "string" }] })
  ) {
    return `${person.last_name}`;
  }
}
