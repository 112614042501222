import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { userAuth } from "utils/constants";
import { ninjaFilterAPI } from "utils/axios";
import { useNavigate } from "react-router-dom";
import { decryptToken } from "utils/encryptions";
import { removeCookie } from "utils/checkForAuth";
import { areKeysAvailableWithType } from "utils/miniHelpers";
import { clearAuthToken, setAuthToken } from "utils/setAuthToken";

const useSetNinjaFilter = (dependency) => {
  const navigate = useNavigate();
  const [cookies] = useCookies([userAuth]);

  useEffect(
    () => {
      const token = cookies[userAuth];
      if (token) {
        const BEARERTOKENREGEX = /^Bearer\s/;
        const common = ninjaFilterAPI.defaults.headers.common;
        if (
          !areKeysAvailableWithType({
            object: common,
            keys: [{ Authorization: "string" }],
          }) ||
          (areKeysAvailableWithType({
            object: common,
            keys: [{ Authorization: "string" }],
          }) &&
            !BEARERTOKENREGEX.test(common["Authorization"]))
        ) {
          try {
            const decryptedToken = decryptToken({
              encryptedToken: token,
            });
            setAuthToken(decryptedToken);
          } catch (error) {
            if (cookies[userAuth]) removeCookie(userAuth);
            clearAuthToken();
            navigate("/login");
          }
        }
      }
    },
    //eslint-disable-next-line
    dependency ? [dependency] : []
  );
};

export default useSetNinjaFilter;
