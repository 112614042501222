import { baseColor, cooperColors } from "utils/colors/accents";

export const ClickableStar = ({
  fillColor = baseColor.white,
  strokeColor = cooperColors["foreground-neutral-faded"],
}) => {
  return (
    <svg width="26" height="23" viewBox="0 0 26 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.302 1.61313C13.84 0.258834 11.9246 0.258835 11.4626 1.61313L9.47045 7.45327C9.40138 7.65574 9.21115 7.79184 8.99722 7.79184H2.65789C1.18719 7.79184 0.594948 9.68837 1.80429 10.5253L6.84789 14.0157C7.03171 14.1429 7.10876 14.3767 7.03658 14.5882L5.09058 20.293C4.62352 21.6623 6.17424 22.834 7.36385 22.0107L12.5978 18.3887C12.769 18.2702 12.9956 18.2702 13.1668 18.3886L18.4007 22.0107C19.5903 22.834 21.1411 21.6623 20.674 20.293L18.728 14.5882C18.6558 14.3767 18.7329 14.1429 18.9167 14.0157L23.9603 10.5253C25.1696 9.68837 24.5774 7.79184 23.1067 7.79184H16.7674C16.5534 7.79184 16.3632 7.65574 16.2941 7.45327L14.302 1.61313Z"
        fill={fillColor}
        stroke={strokeColor}
      />
    </svg>
  );
};
