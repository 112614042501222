export const ALL = "ALL";
export const SET_SALARY = "SET_SALARY";
export const RESET_SALARY = "RESET_SALARY";
export const TITLE = "TITLE";
export const SHORT_INTRO = "SHORT_INTRO";
export const TASKS = "TASKS";
export const REQUIREMENTS = "REQUIREMENTS";
export const EDUCATION = "EDUCATION";
export const EXPERIENCE = "EXPERIENCE";
export const EMPLOYMENT_CONTRACT = "EMPLOYMENT_CONTRACT";
export const COUNTRY = "COUNTRY";
export const CITY = "CITY";
export const ZIP_CODE = "ZIP_CODE";
export const ANYTHING_ELSE = "ANYTHING_ELSE";
export const SALARY_TYPE = "SALARY_TYPE";
export const MIN_SALARY = "MIN_SALARY";
export const MAX_SALARY = "MAX_SALARY";
export const SALARY_KIND = "SALARY_KIND";
export const CURRENCY = "CURRENCY";
export const REMOTE_JOB = "REMOTE_JOB";
export const BENEFITS = "BENEFITS";
export const CATEGORY = "CATEGORY";
export const LANGUAGE_APPLICATION_FORM = "LANGUAGE_APPLICATION_FORM";
export const DEPARTMENT = "DEPARTMENT";
export const APPLICATION_DEADLINE = "APPLICATION_DEADLINE";

export const requiredFields = [
  TITLE,
  TASKS,
  REQUIREMENTS,
  // CATEGORY,
  DEPARTMENT,
  COUNTRY,
  CITY,
  ZIP_CODE,
  LANGUAGE_APPLICATION_FORM,
];

export let initialState = {
  title: "",
  short_intro: "",
  tasks: "",
  requirements: "",
  education: "",
  experience: "",
  employment_contract: "",
  country: "",
  city: "",
  zip_code: "",
  anything_else: "",
  salary_type: "",
  min_salary: "",
  max_salary: "",
  salary_kind: "",
  currency: "",
  remote_job: "",
  benefits: "",
  category: "",
  language_application_form: "",
  department: "",
  application_deadline: "",
};

export const reducer = (state, action) => {
  if (action.type === ALL) {
    return {
      ...action.payload,
    };
  }
  if (action.type === RESET_SALARY) {
    return {
      ...state,
      min_salary: "",
      max_salary: "",
      salary_kind: action.payload,
    };
  }
  if (action.type === SET_SALARY) {
    return {
      ...action.payload,
    };
  }
  if (action.type === TITLE) {
    return {
      ...state,
      title: action.payload,
    };
  }
  if (action.type === CURRENCY) {
    return {
      ...state,
      currency: action.payload,
    };
  }
  if (action.type === EDUCATION) {
    return {
      ...state,
      education: action.payload,
    };
  }
  if (action.type === EXPERIENCE) {
    return {
      ...state,
      experience: action.payload,
    };
  }
  if (action.type === EMPLOYMENT_CONTRACT) {
    return {
      ...state,
      employment_contract: action.payload,
    };
  }
  if (action.type === CATEGORY) {
    return {
      ...state,
      category: action.payload,
    };
  }
  if (action.type === LANGUAGE_APPLICATION_FORM) {
    return {
      ...state,
      language_application_form: action.payload,
    };
  }
  if (action.type === DEPARTMENT) {
    return {
      ...state,
      department: action.payload,
    };
  }
  if (action.type === SALARY_TYPE) {
    return {
      ...state,
      salary_type: action.payload,
    };
  }
  if (action.type === SHORT_INTRO) {
    return {
      ...state,
      short_intro: action.payload,
    };
  }
  if (action.type === TASKS) {
    return {
      ...state,
      tasks: action.payload,
    };
  }
  if (action.type === REQUIREMENTS) {
    return {
      ...state,
      requirements: action.payload,
    };
  }
  if (action.type === BENEFITS) {
    return {
      ...state,
      benefits: action.payload,
    };
  }
  if (action.type === ANYTHING_ELSE) {
    return {
      ...state,
      anything_else: action.payload,
    };
  }
  if (action.type === DEPARTMENT) {
    return {
      ...state,
      department: action.payload,
    };
  }
  if (action.type === CITY) {
    return {
      ...state,
      city: action.payload,
    };
  }
  if (action.type === COUNTRY) {
    return {
      ...state,
      country: action.payload,
    };
  }
  if (action.type === ZIP_CODE) {
    return {
      ...state,
      zip_code: action.payload,
    };
  }
  if (action.type === RESET_SALARY) {
    return {
      ...state,
      min_salary: "",
      max_salary: "",
    };
  }
  if (action.type === MIN_SALARY) {
    return {
      ...state,
      min_salary: action.payload,
    };
  }
  if (action.type === MAX_SALARY) {
    return {
      ...state,
      max_salary: action.payload,
    };
  }
  if (action.type === SALARY_KIND) {
    return {
      ...state,
      salary_kind: action.payload,
    };
  }
  if (action.type === REMOTE_JOB) {
    return {
      ...state,
      remote_job: action.payload,
    };
  }
  if (action.type === APPLICATION_DEADLINE) {
    return {
      ...state,
      application_deadline: action.payload,
    };
  }
  return state;
};
