import { useState, useEffect, useRef } from "react";
import { Button, Text, View, useToast } from "reshaped";

import { backendAPI } from "utils/axios";
import { isString } from "utils/miniHelpers";
import { accent } from "utils/colors/accents";
import useSpacing from "components/hooks/useSpacing";
import { useDispatch, useSelector } from "react-redux";
import { reloadOnUnauthorized } from "utils/checkForAuth";
import { ShowToastWithTranslation } from "utils/showToast";
import useTranslation from "components/hooks/useTranslation";
import { HTMLValidationHelper } from "utils/validationHelper";
import { viewingCandidateSlice } from "redux/viewingCandidate";
import { OperationFailedWithTranslation } from "components/reusables/OperationFailed";
import { PlainTextEditor } from "components/reusables/richTextEditors/PlainTextEditor";

const EditNote = (props) => {
  const { editNoteID, setEditNoteID } = props;

  const [oldNote, setOldNote] = useState("");
  const [noteErrors, setNoteErrors] = useState("");
  const [noteCreatedUser, setNoteCreatedUser] = useState("");

  const stateRef = useRef("");

  const toast = useToast();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { xs, lg, xl } = useSpacing();
  const { user: loggedInUser } = useSelector((state) => state.auth);
  const { notes, currentCandidate, loading } = useSelector((state) => state.viewingCandidate);

  const { setLoading, updateNote } = viewingCandidateSlice.actions;

  useEffect(() => {
    if (notes && Array.isArray(notes.data) && editNoteID) {
      const { data } = notes;
      const found = data.find((prevNote) => prevNote.id === editNoteID);
      if (found) {
        const { note = "", user } = found;
        setNoteCreatedUser(user?.id);
        setOldNote(note);
        stateRef.current = note;
      } else {
        setEditNoteID("");
      }
    } else {
      setEditNoteID("");
    }

    //eslint-disable-next-line
  }, []);

  const editNote = async (note) => {
    if (typeof note !== "object") return;
    if (loggedInUser?.id !== noteCreatedUser) {
      setEditNoteID("");
    }
    try {
      dispatch(setLoading());
      const response = await backendAPI.put(
        `/candidates/${currentCandidate.id}/notes/${editNoteID}`,
        { note }
      );
      const { data } = response;
      dispatch(setLoading(false));
      if (data?.note) {
        const {
          user,
          id,
          note,
          created_at,
          updated_at,
          //there are other properties as well, which can be usefull
        } = data.note;
        const newNote = { user, id, note, created_at, updated_at };
        const notePayload = {
          note: newNote,
          candidate_id: currentCandidate.id,
        };
        dispatch(updateNote(notePayload));
        ShowToastWithTranslation({
          toast,
          Children: NoteUpdated,
          text: t("Note updated!"),
        });
        setEditNoteID("");
        return;
      } else {
        ShowToastWithTranslation({
          toast,
          Children: OperationFailedWithTranslation,
          text: t("Operation unsuccessful!"),
        });
      }
    } catch (error) {
      reloadOnUnauthorized(error);
      dispatch(setLoading(false));
      ShowToastWithTranslation({
        toast,
        Children: OperationFailedWithTranslation,
        text: t("Operation unsuccessful!"),
      });
    }
  };

  const handleValidation = (value) => {
    const errorResult = HTMLValidationHelper({
      value,
      minimumLimit: 2,
      characterLimit: 8000,
      minimumLimitCheck: true,
      minimumLimitErrorString: t("Note should have minimum 2 characters"),
      requiredErrorString: t("Note is required"),
      characterLimitErrorString: t("Note should have maximum 8000 characters"),
    });

    if (isString(errorResult)) {
      setNoteErrors(errorResult);
      return true;
    } else {
      setNoteErrors("");
      return false;
    }
  };

  const handleSubmit = () => {
    let hasError = handleValidation(stateRef.current);
    if (hasError) return;

    const note = {
      note: stateRef.current,
    };

    editNote(note);
  };

  const handleBlur = (value) => {
    handleValidation(value);
  };

  const handleChange = (value) => {
    if (noteErrors) setNoteErrors("");
    stateRef.current = value;
  };

  const handleCancel = () => {
    setEditNoteID("");
  };

  return (
    <View
      padding={[lg + xl, xl + xs]}
      gap={xl}
      backgroundColor="white"
      borderRadius="medium"
      attributes={{
        style: {
          zIndex: 3,
        },
      }}
    >
      <View gap={lg + xs} direction="row" align="center">
        <View
          height="60px"
          width="60px"
          align="center"
          justify="center"
          borderRadius="medium"
          attributes={{
            style: {
              backgroundColor: accent["4-100"],
            },
          }}
        >
          <Text variant="title-1">📝</Text>
        </View>
        <Text variant="title-3" color="neutral">
          Your team would love to hear from you ❤️,
          <br />
          Leave a note about the candidate ✏️
        </Text>
      </View>
      <View gap={xl}>
        <View>
          <PlainTextEditor
            value={oldNote}
            showCount={true}
            error={noteErrors}
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder={t("Write a note about the candidate")}
          />
          <View height="24px">
            {isString(noteErrors) && (
              <Text variant="body-2" color="critical">
                {noteErrors}
              </Text>
            )}
          </View>
        </View>
        <View direction="row" gap={lg} justify="end" align="center">
          <Button color="critical" variant="outline" onClick={handleCancel}>
            {t("Cancel")}
          </Button>
          <Button color="primary" loading={loading} onClick={handleSubmit}>
            {t("Update")}
          </Button>
        </View>
      </View>
    </View>
  );
};

export default EditNote;

const NoteUpdated = ({ children }) => {
  return (
    <Text color="positive" variant="body-strong-1">
      {children}
    </Text>
  );
};
