import { useState, useEffect, useRef, useMemo } from "react";
import { Avatar, Loader, View, Text, useToast } from "reshaped";

import { Camera } from "icons";
import useSpacing from "components/hooks/useSpacing";
import { useDispatch, useSelector } from "react-redux";
import { CompanyForm } from "components/pages/settings";
import { ShowToastWithTranslation } from "utils/showToast";
import useTranslation from "components/hooks/useTranslation";
import { FillRequiredFields } from "pages/recruiter/jobs/New";
import { useDisplayImage } from "components/hooks/useDisplayImage";
import styles from "components/styles/reshaped/settings.module.css";
import { CooperButton } from "components/reusables/reshapedCustoms";
import usePublicCountries from "components/hooks/usePublicCountries";
import { getInitialLettersFromName } from "utils/getLettersFromName";
import { areKeysAvailableWithType, isString } from "utils/miniHelpers";
import common from "components/styles/reshaped/reusables/common.module.css";
import { ReshapedErrorToast } from "components/reusables/layouts/ErrorToast";
import { fetchUserCompany, updateCompanyInAPI } from "redux/company/companySlice";

const languagePreferenceOptions = {
  en: "English",
  de: "German",
  English: "en",
  German: "de",
};

export const Company = () => {
  const [isChanged, setIsChanged] = useState(false);
  const [cancelChanges, setCancelChanges] = useState(false);
  const [newCompanyProfile, setNewCompanyProfile] = useState(null);
  const [errors, setErrors] = useState({
    "company name": null,
    "company website": null,
    "phone number": null,
    Country: null,
  });

  const toast = useToast();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { sm, md, xl } = useSpacing();
  const changedDataRef = useRef(null);
  const state = useSelector((state) => state);
  const { result, uploader } = useDisplayImage();
  const { getCountryName, getCountryCode } = usePublicCountries();

  const { company, loading, error } = state.company;
  const changed = { isChanged, setIsChanged };
  const changes = { cancelChanges, setCancelChanges };

  useEffect(() => {
    if (!company) {
      dispatch(fetchUserCompany());
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isChanged) {
      if (newCompanyProfile) setNewCompanyProfile(null);
      setIsChanged(false);
    }
    //eslint-disable-next-line
  }, [company]);

  const profile = useMemo(() => {
    if (company) {
      const { name } = company;
      if (!company?.logo) {
        const letters = getInitialLettersFromName(name);
        return {
          hasURL: false,
          letters,
        };
      }
      if (company.logo && !company.logo.url) {
        const letters = getInitialLettersFromName(name);
        return {
          hasURL: false,
          letters,
        };
      }
      if (company.logo.url) {
        return {
          hasURL: true,
          url: company.logo.url,
        };
      }
    }
    return {
      hasURL: false,
      letters: "JD",
    };

    // eslint-disable-next-line
  }, [company]);

  const handleCompanyProfile = (e) => {
    if (!isChanged) setIsChanged(true);

    const target = e.target;
    const file = target.files[0];
    if (file) {
      const supportedFormat = {
        jpg: true,
        jpeg: true,
        png: true,
        gif: true,
      };
      const extension = file.type;
      const type = /(?:\/([^.]+))?$/.exec(extension)[1];
      if (supportedFormat[type]) {
        setNewCompanyProfile(target.files[0]);
        uploader(e);
      }
    }
  };

  const handleSaveChanges = () => {
    if (!changedDataRef.current) return;
    let changedData = { ...changedDataRef.current };

    if (changedData && changedData.language_preference) {
      changedData.language_preference = languagePreferenceOptions[changedData.language_preference];
    }

    if (
      areKeysAvailableWithType({
        object: changedData,
        keys: [{ country: "string", callback: (value) => isString(value) }],
      })
    ) {
      const translatedCountry = getCountryName({ country: changedData.country, defaultValue: "" });
      if (isString(translatedCountry)) {
        changedData.country = translatedCountry;
        changedData.country_code = getCountryCode({ country: translatedCountry });
      } else {
        delete changedData["country"];
      }
    }

    for (let key in company) {
      if (!(changedData[key] === undefined)) {
        const previousValue =
          company[key] === null || company[key] === undefined ? "" : company[key];
        if (previousValue === changedData[key]) {
          delete changedData[key];
        }
      }
    }

    let hasError = false;

    for (let field in errors) {
      if (errors[field]) {
        hasError = true;
      }
    }

    if (hasError) {
      ShowToastWithTranslation({
        toast,
        Children: FillRequiredFields,
        text: t("Please enter required fields or valid inputs!"),
      });
      return;
    }

    if (newCompanyProfile) changedData.logo = newCompanyProfile;

    if (typeof changedData === "object" && Object.keys(changedData).length === 0) {
      ShowToastWithTranslation({
        toast,
        Children: FillRequiredFields,
        text: t("Please update input fields with valid or new values!"),
      });
      setIsChanged(false);
      return;
    }

    if (typeof changedData === "object" && Object.keys(changedData).length > 0) {
      for (let key in changedData) {
        if (key === "custom_url" && isString(changedData["custom_url"])) {
          changedData["slug"] = changedData["custom_url"].toLowerCase();
          delete changedData["custom_url"];
          break;
        }
      }
    }

    dispatch(
      updateCompanyInAPI({
        updatedCompanyData: changedData,
        toast,
        t,
        previousSlug: company.slug,
        previousCountry: company.country,
      })
    );
  };

  const handleRefetchCompanyDetails = () => {
    dispatch(fetchUserCompany());
  };

  return (
    <View
      height="100%"
      attributes={{
        style: {
          position: "relative",
        },
      }}
    >
      {!loading && (
        <View padding={[xl, 0]} direction="row">
          <View.Item grow>
            <View gap={xl + sm} maxWidth="650px">
              <Text variant="title-3">{t("Set up your company profile")}</Text>
              <View gap={!profile.hasURL ? md : 0}>
                {!profile.hasURL && <Text variant="body-strong-1">{t("Add company logo")}</Text>}
                <label htmlFor="company__profile" className={styles.upload__label}>
                  {!newCompanyProfile && (
                    <>
                      {profile.hasURL && (
                        <Avatar
                          size={18}
                          src={profile.url}
                          alt={`${company?.name ? company.name : "company profile"}`}
                        />
                      )}
                      {!profile.hasURL && (
                        <Avatar
                          size={18}
                          initials={profile.letters}
                          alt={`${company?.name ? company.name : "company profile"}`}
                        />
                      )}
                    </>
                  )}
                  {newCompanyProfile && result && (
                    <Avatar
                      src={result}
                      size={18}
                      alt={`${company?.name ? company.name : "company profile"}`}
                    />
                  )}
                  <input
                    id="company__profile"
                    type="file"
                    accept="image/gif, image/jpeg, image/jpg, image/png"
                    className={styles.upload__input}
                    onChange={handleCompanyProfile}
                  />
                  <View as="span" className={styles.icon__wrapper}>
                    <Camera />
                  </View>
                </label>
              </View>
              <CompanyForm
                {...changed}
                {...changes}
                error={errors}
                setError={setErrors}
                company={company}
                ref={changedDataRef}
              />
              <View width="100%" align="center" justify="center">
                <View width="500px" align="center">
                  <CooperButton
                    color="primary"
                    fullWidth
                    onClick={handleSaveChanges}
                    disabled={!isChanged}
                  >
                    {t("Save & Submit")}
                  </CooperButton>
                </View>
              </View>
            </View>
          </View.Item>
        </View>
      )}
      {loading && (
        <View height="100%" align="center" justify="center">
          <Loader size="medium" className={common.loader__big} />
        </View>
      )}
      {!loading && error && (
        <ReshapedErrorToast
          navigateTo="/settings"
          message={error}
          position={"absolute"}
          handler={handleRefetchCompanyDetails}
          reload={false}
        />
      )}
    </View>
  );
};

// <View className={common.profile__wrapper__nobg}>
//   <img
//     className={common.profile__img}
//     src={result}
//     alt={`${company?.name ? company.name : "company profile"}`}
//   />
// </View>
