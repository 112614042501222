import { backendAPI } from "utils/axios";
import { areKeysAvailableIn } from "utils/miniHelpers";
import { reloadOnUnauthorized } from "utils/checkForAuth";
import { updateCompanyInAPI } from "redux/company/companySlice";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  allJobBoards: [],
};

export const fetchJobBoards = createAsyncThunk("jobBoards/fetch", async () => {
  try {
    const response = await backendAPI.get("/job_boards");
    if (response.data && Array.isArray(response.data)) {
      return {
        data: response.data,
      };
    }
    return {
      error: "Something went wrong",
    };
  } catch (error) {
    reloadOnUnauthorized(error);
    return {
      error: "Something went wrong",
    };
  }
});

export const jobBoardsSlice = createSlice({
  name: "jobBoards",
  initialState,
  reducers: {
    reset: (state) => {
      for (let key in initialState) {
        state[key] = initialState[key];
      }
    },
    setloading: (state) => {
      state.loading = true;
    },
    stoploading: (state) => {
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    // refetch job boards on company country change
    builder.addCase(updateCompanyInAPI.fulfilled, (state, action) => {
      const { payload } = action;
      if (
        payload &&
        areKeysAvailableIn({ object: payload, keys: ["isSlugUpdated", "isCountryUpdated"] })
      ) {
        const { isCountryUpdated } = payload;
        if (isCountryUpdated) state.allJobBoards = [];
      }
    });
    builder.addCase(fetchJobBoards.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchJobBoards.fulfilled, (state, action) => {
      const { payload } = action;
      state.loading = false;
      if (payload.data) {
        state.allJobBoards = payload.data;
      }
      if (payload.error) {
        state.error = payload.error;
      }
    });
    builder.addCase(fetchJobBoards.rejected, (state, action) => {
      const { payload } = action;
      state.loading = false;
      state.error = payload.error;
    });
  },
});
