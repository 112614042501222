import { useState } from "react";

const useToggle = (initialValue = false) => {
  const [value, setValue] = useState(initialValue);

  const setToggle = (toggleValue) => {
    setValue((currentValue) => {
      return typeof toggleValue === "boolean" ? toggleValue : !currentValue;
    });
  };

  return { value, setToggle };
};

export default useToggle;
