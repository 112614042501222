import { useEffect, useMemo, useRef } from "react";
import { DropdownMenu, Text, View, Avatar, useToast } from "reshaped";
import { metaSlice } from "redux/meta";
import useName from "components/hooks/useName";
import { ArrowDown, CooperhireLogo } from "icons";
import { cooperColors } from "utils/colors/accents";
import useSpacing from "components/hooks/useSpacing";
import { useDispatch, useSelector } from "react-redux";
import useFullName from "components/hooks/useFullName";
import { exceededJobsLimit } from "utils/pages/settings";
import { ShowToastWithTranslation } from "utils/showToast";
import useTranslation from "components/hooks/useTranslation";
import { ShowToastForFailedConversation } from "utils/showToast";
import { Link, useNavigate, useLocation } from "react-router-dom";
import styles from "components/styles/reshaped/navbar.module.css";
import { inProgressConversationsSlice } from "redux/inProgressConversations";
import { areKeysAvailableIn, areKeysAvailableWithType, isObject } from "utils/miniHelpers";
import {
  decodeConversationOfLocalStorage,
  getCooperhireInProgressConversationFromLocalStorage,
} from "utils/pages/candidate";
import { OperationFailedWithTranslation, TextWithEclipses } from "components/reusables";
import { fetchSubscriptionData } from "redux/subscriptions/subscriptionsSlice";

export const TopNavbar = () => {
  const runOnlyOnceRef = useRef(0);

  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t, locale } = useTranslation();
  const { xs, sm, lg, xl } = useSpacing();
  const { auth, inProgressConversations, conversation } = useSelector((state) => state);

  const { user } = auth;
  const { isChatBoxOpen } = conversation;
  const { initialConversationLoaded, newMessage, viewingCandidateID } = inProgressConversations;

  useEffect(() => {
    if (
      runOnlyOnceRef.current === 0 &&
      !initialConversationLoaded &&
      areKeysAvailableWithType({ object: user, keys: [{ id: "number" }] })
    ) {
      const locallySavedPreviousConversations = getCooperhireInProgressConversationFromLocalStorage(
        { userID: user.id }
      );
      const result = {};
      if (isObject(locallySavedPreviousConversations)) {
        const keys = Object.keys(locallySavedPreviousConversations);
        const length = keys.length;
        if (length > 0) {
          for (let i = 0; i < length; i++) {
            const candidate_id = keys[i];
            let progressConversation = areKeysAvailableWithType({
              object: locallySavedPreviousConversations,
              keys: [{ [candidate_id]: "array" }],
            })
              ? locallySavedPreviousConversations[candidate_id]
              : [];
            progressConversation = progressConversation.reduce((acc, each) => {
              const decodedConversation = decodeConversationOfLocalStorage({
                data: each,
                candidate_id,
              });
              if (decodedConversation) return [...acc, decodedConversation];
              return acc;
            }, []);
            if (progressConversation.length > 0) {
              result[candidate_id] = progressConversation;
            }
          }
        }
      }
      runOnlyOnceRef.current = 1;
      dispatch(
        inProgressConversationsSlice.actions.setInitialConversationLoaded({
          status: true,
          data: result,
        })
      );
    }

    //eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (areKeysAvailableIn({ object: newMessage, keys: ["status", "message"] })) {
      const { status, message } = newMessage;
      if (
        status === "fail" &&
        areKeysAvailableIn({ object: message, keys: ["message_id", "candidate_id"] }) &&
        viewingCandidateID === "" &&
        !isChatBoxOpen
      ) {
        ShowToastForFailedConversation({
          toast,
          locale,
          candidate_id: message.candidate_id,
        });
      }
      dispatch(inProgressConversationsSlice.actions.emptyNewMessage());
    }

    //eslint-disable-next-line
  }, [newMessage]);

  useEffect(() => {
    if (!subscription.subscription_type) {
      dispatch(fetchSubscriptionData());
    }
    //eslint-disable-next-line
  }, [])

  const { first_name, last_name } = user;
  const profile = useName({ first_name, last_name, info: user });
  const fullName = useFullName({ first_name, last_name, dependency: [user] });
  const { subscription } = useSelector((state) => state.subscriptions);

  const addNewOptions = useMemo(() => {
    return [
      { title: "Candidate", value: "candidate" },
      { title: "Job", value: "job" },
    ];
  }, []);

  const goToSettings = () => {
    if (pathname === "/settings") {
      dispatch(metaSlice.actions.setToProfileShortcut(true));
    }
    navigate("/settings?to=userprofile");
  };

  const handleDropdown = ({ selectedOption }) => {
    if (selectedOption.value === "candidate") {
      if (pathname === "/candidates") {
        dispatch(metaSlice.actions.setAddCandidateShortcut(true));
        return;
      }
      navigate("/candidates?to=new");
    }
    if (selectedOption.value === "job") {
      if (exceededJobsLimit(subscription)) {
        ShowToastWithTranslation({
          toast,
          Children: OperationFailedWithTranslation,
          text: `${t(
            "Please update your payment plan before adding more jobs. You cannot add more than"
          )}
          ${subscription.max_jobs} ${t("jobs with the")} ${subscription.subscription_type} ${t(
            "plan job_limit_error"
          )}`,
        });
        return;
      }

      navigate("/jobs/new");
    }
  };

  return (
    <View
      padding={[0, lg]}
      backgroundColor="white"
      height="50px"
      width="100%"
      className={styles.topnav}
      direction="row"
      align="center"
      gap={lg}
    >
      <View.Item columns={{ l: 4, xl: 6 }}>
        <View height="100%" width="100%" justify="start" direction="row" align="center">
          <Link to="/" style={{ textDecoration: "none" }}>
            <CooperhireLogo />
          </Link>
        </View>
      </View.Item>
      <View.Item grow>
        <View direction="row" align="center" gap={lg + xs} width="80%" justify="end">
          <View.Item grow>
            <View direction="row" align="center" justify="end" gap={xl + xs}>
              <DropdownMenu position="bottom-start">
                <DropdownMenu.Trigger>
                  {(attributes) => (
                    <View
                      attributes={{
                        ...attributes,
                        style: { cursor: "pointer" },
                        "data-cooper": "nav add dropdown",
                      }}
                      backgroundColor="primary-faded"
                      padding={[0, sm]}
                      borderColor="primary-faded"
                      borderRadius="small"
                      direction="row"
                      align="center"
                      justify="center"
                      height="30px"
                      gap={xs}
                    >
                      <Text variant="caption-1" color="primary">
                        {t("Add New")}
                      </Text>
                      <ArrowDown fill={cooperColors["foreground-primary"]} />
                    </View>
                  )}
                </DropdownMenu.Trigger>
                <DropdownMenu.Content>
                  {addNewOptions.map((option) => {
                    return (
                      <DropdownMenu.Item
                        onClick={() => handleDropdown({ selectedOption: option })}
                        key={option.value}
                        attributes={{ "data-cooper": `nav select ${option.value}` }}
                      >
                        {t(option.title)}
                      </DropdownMenu.Item>
                    );
                  })}
                </DropdownMenu.Content>
              </DropdownMenu>
              <View
                direction="row"
                align="center"
                justify="start"
                gap={sm}
                attributes={{
                  onClick: goToSettings,
                  style: {
                    cursor: "pointer",
                  },
                }}
              >
                <View
                  borderColor="primary"
                  attributes={{
                    style: {
                      borderRadius: "50%",
                    },
                  }}
                >
                  {profile.hasURL && (
                    <Avatar
                      src={profile.url}
                      size={7}
                      alt={user ? `${user.first_name + user.last_name}` : "John Doe"}
                    />
                  )}
                  {!profile.hasURL && (
                    <Avatar
                      size={7}
                      initials={profile.letters}
                      alt={user ? `${user.first_name + user.last_name}` : "John Doe"}
                    />
                  )}
                </View>
                <TextWithEclipses
                  variant="body-strong-2"
                  text={fullName}
                  characterLimit={17}
                  noOfEclipses={2}
                />
              </View>
            </View>
          </View.Item>
        </View>
      </View.Item>
    </View>
  );
};
