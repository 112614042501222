import { locales } from "translations/config";
import { replacePattern } from "utils/regexHelpers";
import { backendAPI, ninjaFilterAPI } from "utils/axios";
import { reloadOnUnauthorized } from "utils/checkForAuth";
import { cooperhireInProgressConversationLocalStorageName } from "utils/constants";
import { IMAGEHTTPSSRCLOCATERREGEX, IMAGEBASE64SRCLOCATERREGEX } from "utils/RegularExpressions";
import {
  isObject,
  sanitizeArray,
  areKeysAvailableIn,
  areKeysAvailableWithType,
} from "utils/miniHelpers";

export const errorResponseCodesWithRetry = [
  "400",
  "403",
  "421",
  "450",
  "451",
  "452",
  "500",
  "550",
  "551",
  "552",
  "553",
  "554",
  "Other",
];
export const errorResponseCodesWithNoErrorMessage = ["451"];

export function getSanitizedFilteredRequestBody({
  size,
  from,
  sorting,
  company_id,
  selectedJobs,
  selectedStages,
  selectedFollowers,
  current_language,
  selectedSearches
}) {
  const supportedCandidateSortingColumns = ["date", "stage"];
  const orders = ["asc", "desc"];
  const defaultSorting = { key: "date", order: "desc" };
  let sanitizedFrom = 0;
  let sanitizedSize = 15;

  if (typeof from === "number" && from >= 0) sanitizedFrom = from;
  if (typeof size === "number" && size >= 0) sanitizedSize = size;

  const sanitizedSorting = sanitizeArray({
    array: sorting,
    elementType: "object",
    keys: [{ key: "string" }, { order: "string" }],
    emptyResultValue: [defaultSorting],
  })[0];

  const sanitizedSelectedJobs = sanitizeArray({
    array: selectedJobs,
    elementType: "object",
    keys: ["id", "title"],
    noPropertyTypeChecking: false,
    hardSanitization: true,
    emptyResultValue: [],
  }).map((job) => job.id);

  const sanitizedSelectedStages = sanitizeArray({
    array: selectedStages,
    elementType: "string",
    emptyResultValue: [],
  });

  const sanitizedSelectedFollowers = sanitizeArray({
    array: selectedFollowers,
    elementType: "number",
    emptyResultValue: [],
  });

  const sanitizedLanguage = locales.includes(current_language) ? current_language : "en";

  const reqBody = {
    from: sanitizedFrom * sanitizedSize,
    size: sanitizedSize,
    company_id,
    sort:
      supportedCandidateSortingColumns.includes(sanitizedSorting?.key) &&
      orders.includes(sanitizedSorting?.order)
        ? sanitizedSorting
        : defaultSorting,
    filter: {
      jobs: sanitizedSelectedJobs,
      stages: sanitizedSelectedStages,
      followers: sanitizedSelectedFollowers,
    },
    current_language: sanitizedLanguage,
  };

  if (selectedSearches && selectedSearches.length > 0) {
    reqBody.filter.c_full_name = selectedSearches;
  }

  return reqBody;
}

export async function fetchFilteredCandidates({
  company_id,
  selectedJobs,
  selectedStages,
  selectedFollowers,
  sorting,
  from,
  size,
  current_language,
  selectedSearches
}) {
  const errorResponse = { hasError: true };

  if (!company_id || typeof company_id !== "number") return errorResponse;

  const reqBody = getSanitizedFilteredRequestBody({
    company_id,
    selectedJobs,
    selectedStages,
    selectedFollowers,
    sorting,
    from,
    size,
    current_language,
    selectedSearches
  });

  try {
    const response = await ninjaFilterAPI.post("/candidates", reqBody);
    if (
      areKeysAvailableWithType({
        object: response,
        keys: [{ status: 200, exact: true }, { data: "object" }],
      }) &&
      areKeysAvailableWithType({
        object: response.data,
        keys: [{ candidates: "array" }, { total_candidates: "number" }],
      })
    ) {
      return {
        data: {
          candidates: sanitizeArray({
            array: response.data.candidates,
            elementType: "object",
            keys: [
              { id: "string" },
              { first_name: "string" },
              { last_name: "string" },
              { created_at: "string" },
              { stage: "string" },
              { average_rating: "number" },
              { job_id: "number" },
              { followers: "array" },
            ],
          }),
          totalCandidates: response.data.total_candidates,
        },
      };
    }
    return errorResponse;
  } catch (error) {
    if (
      areKeysAvailableWithType({ object: error.response, keys: [{ data: "object" }] }) &&
      areKeysAvailableWithType({
        object: error.response.data,
        keys: [{ error: "No candidate found", exact: true }],
      })
    ) {
      return { data: { totalCandidates: 0, candidates: [] } };
    }
    return errorResponse;
  }
}

export async function fetchFilterJobs({ batch, company_id, key }) {
  const size = 7;
  const errorData = {
    viewing: batch,
    isExhausted: false,
    error: true,
    list: [],
    afterKey: key ? key : null,
  };

  try {
    const response = await ninjaFilterAPI.post("/jobs", {
      from: batch * size,
      size,
      company_id,
      job_order: "desc",
      after_key: key ? key : null,
    });

    if (
      areKeysAvailableWithType({
        object: response.data,
        keys: [{ exhausted: "boolean" }, { jobs: "array" }],
      }) &&
      areKeysAvailableIn({ object: response.data, keys: ["after_key"] })
    ) {
      const { jobs, exhausted, after_key } = response.data;
      return {
        viewing: batch,
        isExhausted: exhausted,
        list: sanitizeArray({
          array: jobs,
          elementType: "object",
          noPropertyTypeChecking: false,
          keys: ["title", "id"],
        }),
        error: false,
        afterKey: after_key,
      };
    }

    return errorData;
  } catch (error) {
    if (
      areKeysAvailableWithType({
        object: error.response,
        keys: [{ data: "No jobs found.", exact: true }],
      })
    ) {
      return {
        viewing: 0,
        isExhausted: true,
        list: [],
        error: false,
        afterKey: null,
      };
    }
    return errorData;
  }
}

export function getPropsOfErrorResponseCodes(conversation) {
  // need to be delete
  // return {
  //   shouldRetryOfBackend: true,
  //   errorMessage: "E-mail failed! E-mail failed! E-mail failed! E-mail failed!",
  // };

  // need to be delete

  if (!areKeysAvailableIn({ object: conversation, keys: ["id", "message"] })) return {};

  if (
    !conversation.response_code ||
    !areKeysAvailableWithType({ object: conversation, keys: [{ response_code: "string" }] })
  )
    return {};

  const responseCode = conversation.response_code;

  if (!errorResponseCodesWithRetry.includes(responseCode)) return {};

  let hideError = false;

  const props = {
    shouldRetryOfBackend: true,
  };

  if (errorResponseCodesWithNoErrorMessage.includes(responseCode)) {
    hideError = true;
    props.errorMessage = "E-mail failed!";
  }

  if (
    !hideError &&
    areKeysAvailableWithType({ object: conversation, keys: [{ response_message: "string" }] })
  ) {
    props.errorMessage = conversation["response_message"];
  }

  return props;
}

export function getSanitizedConversations({ conversation, subjectDefault = "", candidate_id }) {
  let latestSubject = subjectDefault;

  if (!conversation || !Array.isArray(conversation) || conversation.length <= 0) {
    return {
      conversation: [],
      latestSubject,
    };
  }

  const sanitizedConversation = conversation.reduce((acc, each) => {
    if (each && typeof each === "object") {
      const eachConversationSubjects = Object.keys(each);
      if (eachConversationSubjects.length <= 0) return acc;
      let sanitizedEachSubjectConversations = eachConversationSubjects.reduce(
        (subjectAcc, subject) => {
          latestSubject = subject;
          const subjectDetails = sanitizeArray({
            array: each[subject],
            elementType: "object",
            keys: [
              { id: "number" },
              { message: "string" },
              { updated_at: "string" },
              { attachment: "array" },
              { sender: "object" },
              { date: "object" },
            ],
            emptyResultValue: [],
          });

          const eachSubjectConversationLength = subjectDetails.length;

          if (eachSubjectConversationLength > 0) {
            const sanitizedSubjectConversation = [];
            for (let i = 0; i < eachSubjectConversationLength; i++) {
              const message = {
                ...subjectDetails[i],
                message: sanitizeMessage(subjectDetails[i].message),
                isRecipient: areKeysAvailableWithType({
                  object: subjectDetails[i].sender,
                  keys: [{ id: candidate_id, exact: true }],
                }),
                ...getPropsOfErrorResponseCodes(subjectDetails[i]),
              };

              if (
                areKeysAvailableWithType({
                  object: message,
                  keys: [{ message: "nexigon", exact: true }],
                })
              )
                continue;

              if (i === 0) {
                sanitizedSubjectConversation.push({
                  subject,
                  showSubject: true,
                  ...message,
                });
              } else {
                sanitizedSubjectConversation.push({ subject, ...message });
              }
            }
            return [...subjectAcc, ...sanitizedSubjectConversation];
          }

          return subjectAcc;
        },
        []
      );
      return [...acc, ...sanitizedEachSubjectConversations];
    }
    return acc;
  }, []);

  return {
    conversation: sanitizedConversation,
    latestSubject,
  };
}

export async function getPreviousMessages({ candidate_id, id }) {
  if (!candidate_id) return { error: "Something went wrong!" };

  try {
    const url = id && (typeof id === "string" || typeof id === "number") ? `&id=${id}` : "";
    const response = await backendAPI.get(
      `/conversations/load_messages?candidate_id=${candidate_id}${url}`
    );

    if (
      areKeysAvailableWithType({
        object: response.data,
        keys: [{ candidate_id, exact: true }, { conversations: "array" }, { exhausted: "boolean" }],
      })
    ) {
      const { latestSubject, conversation } = getSanitizedConversations({
        conversation: response.data.conversations,
        subjectDefault: "",
        candidate_id,
      });

      const isExhausted = response.data.exhausted;
      return {
        conversation,
        latestSubject,
        isExhausted: !isExhausted && conversation.length <= 0 ? true : isExhausted,
      };
    }
    return { error: "Messages fetching failed" };
  } catch (error) {
    reloadOnUnauthorized(error);
    return { error: "Messages fetching failed" };
  }
}

export function removeBreakTagsFromEnd(text = "") {
  if (!text || typeof text !== "string") return "";

  // Remove HTML tags and elements
  const textWithoutTags = text.replace(/<[^>]+>/g, "cooperhirecooperhire");

  // Find the last non-space word
  const words = textWithoutTags.split(/cooperhirecooperhire/);

  let lastWord = "";
  let lastIndex = -1;
  let firstWord = "";
  let firstIndex = -1;
  const wordsLength = words.length;

  // starting breaks sanitization
  for (let i = 0; i < wordsLength; i++) {
    if (words[i].trim() !== "" && words[i].trim() !== "&nbsp;") {
      const firstWordArray = words[i].trim().split(" ");
      firstWord = firstWordArray[0];
      break;
    }
  }

  // Find the character index of the first word
  if (firstWord) {
    firstIndex = text.indexOf(firstWord);
  }

  const removingbeginningBreaksStartingWords = text.substring(0, firstIndex);
  const removingbeginningBreaksEndingWords = text.substring(firstIndex);
  let newRemovingBeginningBreaksStartingWords = removingbeginningBreaksStartingWords.replace(
    /<\s*\/*\s*[bBhH][Rr]\s*\/*\s*>/g,
    ""
  );
  newRemovingBeginningBreaksStartingWords = newRemovingBeginningBreaksStartingWords.replace(
    /&nbsp;/g,
    ""
  );
  const fullTextAfterSanitizingBeginningBreaks = `${newRemovingBeginningBreaksStartingWords}${removingbeginningBreaksEndingWords}`;
  // starting breaks sanitization end here

  // ending breaks sanitization
  for (let i = wordsLength - 1; i >= 0; i--) {
    if (words[i].trim() !== "" && words[i].trim() !== "&nbsp;") {
      const lastWordArray = words[i].trim().split(" ");
      lastWord = lastWordArray[lastWordArray.length - 1];
      break;
    }
  }

  // Find the character index of the last word
  if (lastWord) {
    lastIndex = fullTextAfterSanitizingBeginningBreaks.lastIndexOf(lastWord);
  }

  const removingEndingBreaksStartingWords = fullTextAfterSanitizingBeginningBreaks.substring(
    0,
    lastIndex
  );
  const removingEndingBreaksEndingWords =
    fullTextAfterSanitizingBeginningBreaks.substring(lastIndex);

  let newRemovingEndingBreaksEndingWords = removingEndingBreaksEndingWords.replace(
    /<\s*[bBhH][Rr]\s*\/*\s*>/g,
    ""
  );
  newRemovingEndingBreaksEndingWords = newRemovingEndingBreaksEndingWords.replace(/&nbsp;/g, "");
  // ending breaks sanitization end here

  return `${removingEndingBreaksStartingWords}${newRemovingEndingBreaksEndingWords}`;
}

export function sanitizeTags(text) {
  if (!text || typeof text !== "string") return "";
  let finalString = text;

  const openBodyTagRegex = /<\s?body/;
  const hasOpenBodyTag = openBodyTagRegex.test(text);

  if (hasOpenBodyTag) {
    const closeBodyTagRegex = /<\s?\/\s?body>/;
    const hasCloseBodyTag = closeBodyTagRegex.test(text);

    if (hasCloseBodyTag) {
      const getFullBodyTagContentRegex = /<body.*<\/body>/;
      const matchedText = text.match(getFullBodyTagContentRegex);

      if (matchedText && Array.isArray(matchedText) && matchedText.length > 0) {
        finalString = matchedText[0];
      }

      finalString = finalString.replace(openBodyTagRegex, "<div");
      return finalString.replace(closeBodyTagRegex, "</div>");
    } else {
      const getOpenBodyTagToEnd = /<\s?body.*/;
      const matchedText = text.match(getOpenBodyTagToEnd);

      if (matchedText && Array.isArray(matchedText) && matchedText.length > 0) {
        finalString = text.substring(matchedText.index);
      }

      return balanceDivTags(finalString.replace(openBodyTagRegex, "<div"));
    }
  }
  return finalString;
}

export function balanceDivTags(text) {
  if (!text || typeof text !== "string") return "";

  let finalString = text;
  const noOfOpenDivTags = text.match(/<\s?div/g);

  if (!noOfOpenDivTags || !Array.isArray(noOfOpenDivTags)) return text;

  const openDivTagsLength = noOfOpenDivTags.length;

  let finalDivTags = "";
  const noOfCloseDivTags = text.match(/<\s?\/\s?div/g);

  if (!noOfCloseDivTags || !Array.isArray(noOfCloseDivTags)) {
    for (let i = 0; i < openDivTagsLength; i++) {
      finalDivTags = `${finalDivTags}</div>`;
    }
  } else {
    const closeDivTagsLength = noOfOpenDivTags.length;

    if (openDivTagsLength > closeDivTagsLength) {
      const divTagsDiff = openDivTagsLength - closeDivTagsLength;

      for (let i = 0; i < divTagsDiff; i++) {
        finalDivTags = `${finalDivTags}</div>`;
      }
    }
  }
  return `${finalString}${finalDivTags}`;
}

export function removeStyleAttributes(text = "") {
  if (!text || typeof text !== "string") return "";

  const tempElement = document.createElement("div");
  tempElement.innerHTML = text;

  const elements = tempElement.querySelectorAll("*");
  const elementsLength = elements.length;

  for (let i = 0; i < elementsLength; i++) {
    elements[i].removeAttribute("style");
    elements[i].removeAttribute("class");
    elements[i].removeAttribute("dir");
  }

  // Get the cleaned HTML string without inline styles
  const cleanedHtmlString = tempElement.innerHTML;

  return cleanedHtmlString;
}

export function remove({ tag = "img", text = "", matchingText }) {
  if (!text || typeof text !== "string") return "";

  const tempElement = document.createElement("div");
  tempElement.innerHTML = text;

  const matchedTags = tempElement.getElementsByTagName(tag);
  const matchedTagsLength = matchedTags.length;

  if (typeof matchingText === "string") {
    for (let i = matchedTagsLength - 1; i >= 0; i--) {
      const matchedTag = matchedTags[i];
      if (matchedTag.textContent === matchingText) {
        matchedTag.parentNode.removeChild(matchedTag);
      }
    }
  } else {
    for (let i = matchedTagsLength - 1; i >= 0; i--) {
      const matchedTag = matchedTags[i];
      matchedTag.parentNode.removeChild(matchedTag);
    }
  }

  // Get the cleaned HTML string
  const cleanedHTMLString = tempElement.innerHTML;

  return cleanedHTMLString;
}

export function removeImageTagWithNoValidSrc({ text = "" }) {
  if (!text || typeof text !== "string") return "";

  const tempElement = document.createElement("div");
  tempElement.innerHTML = text;

  const matchedTags = tempElement.getElementsByTagName("img");
  const matchedTagsLength = matchedTags.length;

  for (let i = matchedTagsLength - 1; i >= 0; i--) {
    const matchedTag = matchedTags[i];
    if (matchedTag instanceof HTMLImageElement) {
      const src = matchedTag.getAttribute("src");
      if (
        !src ||
        typeof src !== "string" ||
        (!IMAGEBASE64SRCLOCATERREGEX.test(src) && !IMAGEHTTPSSRCLOCATERREGEX.test(src))
      ) {
        matchedTag.parentNode.removeChild(matchedTag);
      }
    }
  }

  // Get the cleaned HTML string
  const cleanedHTMLString = tempElement.innerHTML;

  return cleanedHTMLString;
}

export function sanitizeMessage(text = "") {
  if (!text || typeof text !== "string") return "";

  let newText = sanitizeTags(text);

  newText = removeStyleAttributes(newText);

  newText = removeImageTagWithNoValidSrc({ text: newText });

  newText = remove({ tag: "font", text: newText, matchingText: "ᐧ" });

  newText = replacePattern({
    withThisCharacter: "&nbsp; &nbsp; &nbsp;",
    text: newText,
    regex: /\t/g,
  });

  newText = replacePattern({ withThisCharacter: "<br />", text: newText, regex: /\n/g });

  newText = removeBreakTagsFromEnd(newText);

  return newText;
}

export function mergeConversationWithSubject({ conversation = [], getLatestSubject = true }) {
  let HasError = false;
  if (!conversation || !Array.isArray(conversation)) HasError = true;

  let currentSubject = "";
  let sanitizedConversation = [];
  const messageIds = {};

  if (!HasError) {
    sanitizedConversation = conversation.reduce((acc, each) => {
      if (messageIds[each.id] && messageIds[each.id] === "true") return acc;
      messageIds[each.id] = "true";
      const newEach = {
        ...each,
      };

      if (each.subject !== currentSubject) {
        currentSubject = each.subject;
        newEach["showSubject"] = true;
        return [...acc, newEach];
      }

      if (each.subject === currentSubject) {
        delete newEach["showSubject"];
        return [...acc, newEach];
      }

      return acc;
    }, []);
  }
  if (getLatestSubject) {
    return {
      conversation: sanitizedConversation,
      latestSubject: currentSubject,
    };
  }
  return {
    conversation: sanitizedConversation,
  };
}

export function encodeConversationForLocalStorage({ data, candidate_id }) {
  if (!isObject(data) || !candidate_id || typeof candidate_id !== "string") return false;
  if (
    areKeysAvailableIn({
      object: data,
      keys: ["sender", "subject", "message", "message_id"],
    })
  ) {
    const result = {
      sender: data.sender,
      subject: data.subject,
      message: data.message,
      message_id: data.message_id,
      ...(Array.isArray(data.attachment) && data.attachment.length > 0
        ? { attachment: data.attachment }
        : {}),
      ...(data.conversation_id ? { conversation_id: data.conversation_id } : {}),
      ...(Array.isArray(data.exclude_attachments) && data.exclude_attachments.length > 0
        ? { exclude_attachments: data.exclude_attachments }
        : {}),
    };
    return result;
  }
  return false;
}

export function decodeConversationOfLocalStorage({ data, candidate_id }) {
  if (!isObject(data) || !candidate_id || typeof candidate_id !== "string") return false;
  if (
    areKeysAvailableIn({
      object: data,
      keys: ["sender", "subject", "message", "message_id"],
    })
  ) {
    const result = {
      id: data.conversation_id ? data.conversation_id : data.message_id,
      status: "unread",
      sender: data.sender,
      subject: data.subject,
      message: data.message,
      message_id: data.message_id,
      attachment:
        Array.isArray(data.attachment) && data.attachment.length > 0 ? data.attachment : [],
      isRecipient: data.sender.id === candidate_id,
      isSending: false,
      shouldRetry: true,
      request: {
        sender: data.sender,
        subject: data.subject,
        message: data.message,
        message_id: data.message_id,
        candidate_id,
        ...(data.conversation_id ? { conversation_id: data.conversation_id } : {}),
        ...(Array.isArray(data.exclude_attachments) && data.exclude_attachments.length > 0
          ? { exclude_attachments: data.exclude_attachments }
          : {}),
      },
    };
    return result;
  }
  return false;
}

export function getCooperhireInProgressConversationFromLocalStorage({ userID }) {
  let conversations = {};
  if (typeof userID === "string" || typeof userID === "number") {
    conversations = JSON.parse(
      localStorage.getItem(`${cooperhireInProgressConversationLocalStorageName}_${userID}`)
    );
  }
  return conversations;
}

export function setCooperhireInProgressConversationToLocalStorage({
  data,
  message_id,
  candidate_id,
  userID,
}) {
  if (!data || !candidate_id) return;
  if (typeof userID === "string" || typeof userID === "number") {
    const conversations = getCooperhireInProgressConversationFromLocalStorage({ userID });

    if (!isObject(conversations)) {
      let newData = {};
      newData[candidate_id] = [data];
      localStorage.setItem(
        `${cooperhireInProgressConversationLocalStorageName}_${userID}`,
        JSON.stringify(newData)
      );
      return;
    }

    if (isObject(conversations)) {
      let newConversations = areKeysAvailableWithType({
        object: conversations,
        keys: [{ [candidate_id]: "array" }],
      })
        ? conversations[candidate_id]
        : [];
      if (message_id) {
        let newData = {};

        if (newConversations.length === 0) {
          newData = {
            ...conversations,
            [candidate_id]: [data],
          };

          localStorage.setItem(
            `${cooperhireInProgressConversationLocalStorageName}_${userID}`,
            JSON.stringify(newData)
          );
          return;
        }
        if (newConversations.length > 0) {
          newConversations = newConversations.filter((each) => {
            if (areKeysAvailableWithType({ object: each, keys: [{ message_id, exact: true }] })) {
              return false;
            }
            return true;
          });
          newData = {
            ...conversations,
            [candidate_id]: [...newConversations, data],
          };

          localStorage.setItem(
            `${cooperhireInProgressConversationLocalStorageName}_${userID}`,
            JSON.stringify(newData)
          );
          return;
        }
        return;
      }
      if (!message_id) {
        newConversations.push(data);
        const newData = {
          ...conversations,
          [candidate_id]: newConversations,
        };
        localStorage.setItem(
          `${cooperhireInProgressConversationLocalStorageName}_${userID}`,
          JSON.stringify(newData)
        );
        return;
      }
    }
  }
}

export function removeCooperhireInProgressConversationFromLocalStorage({
  candidate_id,
  message_id,
  userID,
}) {
  if (!message_id || !candidate_id) return;
  if (typeof userID === "string" || typeof userID === "number") {
    const conversations = getCooperhireInProgressConversationFromLocalStorage({ userID });

    if (!isObject(conversations)) return;
    if (isObject(conversations)) {
      let candidateConversations = areKeysAvailableWithType({
        object: conversations,
        keys: [{ [candidate_id]: "array" }],
      })
        ? conversations[candidate_id]
        : [];

      if (candidateConversations.length <= 0) return;
      if (candidateConversations.length > 0) {
        let newData = {
          ...conversations,
          [candidate_id]: candidateConversations.filter((each) => {
            if (areKeysAvailableWithType({ object: each, keys: [{ message_id, exact: true }] })) {
              return false;
            }
            return true;
          }),
        };
        if (newData[candidate_id] && newData[candidate_id].length <= 0) {
          delete newData[candidate_id];
        }
        const length = Object.keys(newData).length;
        if (length === 0) {
          localStorage.removeItem(`${cooperhireInProgressConversationLocalStorageName}_${userID}`);
          return;
        }
        localStorage.setItem(
          `${cooperhireInProgressConversationLocalStorageName}_${userID}`,
          JSON.stringify(newData)
        );
      }
    }
  }
}

export async function updateCandidateAPI({ data, id }) {
  const errorResult = { error: "Operation failed." };
  if (!data || !isObject(data) || Object.entries(data).length <= 0 || !id || typeof id !== "string")
    return errorResult;

  try {
    const formData = new FormData();

    for (let key in data) {
      formData.append(`candidate[${key}]`, data[key]);
    }

    const response = await backendAPI.patch(`/candidates/${id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (
      areKeysAvailableWithType({
        object: response.data,
        keys: [
          {
            candidate: "object",
            callback: (value) =>
              areKeysAvailableIn({
                object: value,
                keys: ["id", "email", "first_name", "last_name"],
              }),
          },
        ],
      })
    ) {
      return { data: response.data.candidate };
    }
    return errorResult;
  } catch (error) {
    reloadOnUnauthorized(error);
    return errorResult;
  }
}

export async function rejectCandidateAPI({ data, id }) {
  const errorResult = { error: "Operation failed." };

  if (!isObject(data) || !id || typeof id !== "string") return errorResult;

  try {
    const response = await backendAPI.patch(`/candidates/${id}/reject`, { candidate: data });

    if (
      areKeysAvailableWithType({
        object: response.data,
        keys: [
          {
            candidate: "object",
            callback: (value) =>
              areKeysAvailableIn({ object: value, keys: ["id", "email", "stage"] }),
          },
        ],
      })
    ) {
      return { data: response.data.candidate };
    }
    return errorResult;
  } catch (error) {
    reloadOnUnauthorized(error);
    return errorResult;
  }
}
