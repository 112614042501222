import { useMemo } from "react";

const useRating = ({ rating }) => {
  const result = useMemo(() => {
    const ratingsText = {
      0: "",
      1: "Strong No Hire",
      2: "No Hire",
      3: "Not Sure",
      4: "Hire",
      5: "Strong Hire",
    };

    const emptyFillColors = {
      0: "#FFFFFF",
      1: "#FDC3B6",
      2: "#FDC3B6",
      3: "#FED69F",
      4: "#90FDC5",
      5: "#90FDC5",
    };

    const fillColors = {
      0: "#D1D1D1",
      1: "#E23F12",
      2: "#E23F12",
      3: "#F4AF34",
      4: "#00AD71",
      5: "#00AD71",
    };

    const backgroundColors = {
      0: "#D1D1D1",
      1: "#FFEEEB",
      2: "#FFEEEB",
      3: "#FFEDD6",
      4: "#E5FFF0",
      5: "#E5FFF0",
    };

    const textColors = {
      0: "#6B6B6B",
      1: "#E23F12",
      2: "#E23F12",
      3: "#A66F00",
      4: "#005237",
      5: "#005237",
    };

    const strokeColors = {
      0: "#D1D1D1",
      1: "#E23F12",
      2: "#E23F12",
      3: "#F4AF34",
      4: "#00AD71",
      5: "#00AD71",
    };

    const emptyStrokeColors = {
      0: "#6B6B6B",
      1: "#FDC3B6",
      2: "#FDC3B6",
      3: "#FED69F",
      4: "#90FDC5",
      5: "#90FDC5",
    };

    return {
      text: ratingsText[rating],
      fill: fillColors[rating],
      emptyFill: emptyFillColors[rating],
      background: backgroundColors[rating],
      textColor: textColors[rating],
      strokeColor: strokeColors[rating],
      emptyStrokeColor: emptyStrokeColors[rating],
    };

    //eslint-disable-next-line
  }, [rating]);
  return result;
};

export default useRating;
