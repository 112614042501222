const DASHBOARD = {
  Hi: "Hi",
  "Welcome to CooperHire, your new Recruiting App":
    "Welcome to CooperHire, your new Recruiting App",
  "You received": "You received",
  "new applicants this week": "new applicants this week",
  "new applicant this week": "new applicant this week",
  "More Details": "More Details",
  "Company Profile": "Company Profile",
  "Complete your profile": "Complete your profile",
  "Jobs online": "Jobs online",
  "Job online": "Job online",
  Hired: "Hired",
  "New Applicants": "New Applicants",
  "My Candidates": "My Candidates",
  Name: "Name",
  "Job title": "Job title",
  Position: "Position",
  Stage: "Stage",
  Date: "Date",
  applied: "Applied",
  "In Review": "In Review",
  interviewing: "Interviewing",
  rejected: "Rejected",
  Search: "Search",
  "Add New": "Add New",
  Dashboard: "Dashboard",
  Jobs: "Jobs",
  Candidate: "Candidate",
  Candidates: "Candidates",
  Inbox: "Inbox",
  Promote: "Promote",
  Settings: "Settings",
  Interviews: "Interviews",
  Job: "Job",
  Super: "Super",
  Good: "Good",
  Average: "Average",
  Vacant: "Vacant",
  "No candidates applied yet!": "No candidates applied yet!",
  "We would love your feedback!": "We would love your feedback!",
  "Request Feature": "Request Feature",
  "Give Feedback": "Give Feedback",
};

const LOGIN = {
  "Welcome to CooperHire!": "Welcome to CooperHire!",
  "Welcome to CooperHire": "Welcome to CooperHire",
  "A modern recruiting tool that helps you hire top candidates quickly":
    "A modern recruiting tool that helps you hire top candidates quickly",
  "Get started to hire candidates quickly with our easy to use platform":
    "Get started to hire candidates quickly with our easy to use platform",
  Email: "Email",
  Password: "Password",
  "Forgot password? No worries": "Forgot password? No worries",
  "Don't have an account?": "Don't have an account?",
  "Create an account.": "Create an account.",
  "No account exists for this email.": "No account exists for this email.",
  "Invalid email or password.": "Invalid email or password.",
  "You must confirm your account before you can proceed.":
    "You must confirm your account before you can proceed.",
  "Forgot Password?": "Forgot Password?",
  "No worries, we will send you the reset instructions":
    "No worries, we will send you the reset instructions",
  "Send email": "Send email",
  "Back to settings": "Back to settings",
  "Back to log in": "Back to log in",
  "Check your email": "Check your email",
  "We sent you a password reset link to": "We sent you a password reset link to",
  "Didn't receive the email? ": "Didn't receive the email? ",
  Resend: "Resend",
  "Change password": "Change password",
  "Your password must be very strong with special characters":
    "Your password must be very strong with special characters",
  "Set a password": "Set a password",
  "New password": "New password",
  "Confirm password": "Confirm password",
  "New Password is required": "New Password is required",
  "Confirm Password is required": "Confirm Password is required",
  "Enter valid New Password": "Enter valid New Password",
  "Enter a valid new password": "Enter a valid new password",
  "Password does not match": "Password does not match",
  "Activate account": "Activate account",
  "Password set": "Password set",
  "Your password has been set successfully": "Your password has been set successfully",
  "Click here to log in": "Click here to log in",
  "Something went wrong! Please refresh the page": "Something went wrong! Please refresh the page",
  "Something went wrong!": "Something went wrong!",
  "Something went wrong": "Something went wrong",
  "You have deleted your account. Thanks!": "You have deleted your account. Thanks!",
  "Your account is now deleted": "Your account is now deleted",
  "You have to confirm your email address before continuing.":
    "You have to confirm your email address before continuing.",
  "Your account has been deleted.": "Your account has been deleted.",
  "Account not found": "Account not found",
  "Your account is currently under review. If you have any questions, you can contact hello@cooperhire.io.":
    "Your account is currently under review. If you have any questions, you can contact hello@cooperhire.io.",
  "Something went wrong, please delete your browser cache and try again. For further help please reach out to ":
    "Something went wrong, please delete your browser cache and try again. For further help please reach out to ",
};

const REGISTER = {
  "Click here": "Click here",
  "First Name": "First Name",
  "Last Name": "Last Name",
  "Already have a recruiter account?": "Already have a recruiter account?",
  "Log in": "Log in",
  "I agree to the Cooperhire": "I agree to the Cooperhire",
  "Terms & Conditions": "Terms & Conditions",
  "and the": "and the",
  "Data Processing Agreements": "Data Processing Agreements",
  "and have read the": "and have read the",
  "Data Privacy Policy": "Data Privacy Policy",
  "First Name is required": "First Name is required",
  "Last Name is required": "Last Name is required",
  "email is required": "Email is required",
  "Email is restricted": "Email is restricted",
  "password is required": "Password is required",
  "Email is required": "Email is required",
  "Password is required": "Password is required",
  "Enter valid Password": "Enter valid Password",
  "Enter a valid password": "Enter a valid password",
  "Enter valid Email": "Enter valid Email",
  "Enter a valid E-mail address": "Enter a valid E-mail address",
  Requirements: "Requirements",
  characters: "characters",
  "lowercase character": "lowercase character",
  "uppercase character": "uppercase character",
  "special character": "special character",
  number: "number",
  "Set up your company profile": "Set up your company profile",
  "Set up your company profile in just a few easy steps!":
    "Set up your company profile in just a few easy steps!",
  "Give candidates a clear picture of your business and open roles":
    "Give candidates a clear picture of your business and open roles",
  "Let candidates know who you are.": "Let candidates know who you are.",
  "Company Name": "Company Name",
  "Company website": "Company website",
  "Phone number": "Phone number",
  "Enter valid Number": "Enter valid Number",
  "Enter valid Country": "Enter valid Country",
  "Not a country": "Not a country",
  "Please choose a country from the list": "Please choose a country from the list",
  "Website is required": "Website is required",
  "Number is required": "Number is required",
  "Country is required": "Country is required",
  "Name is required": "Name is required",
  "Enter valid Website": "Enter valid Website",
  "Create company profile": "Create company profile",
  "Which of the following best describes your company":
    "Which of the following best describes your company",
  "Which of the following best describes your company size?":
    "Which of the following best describes your company size?",
  "We always tailor the customer experience - CooperHire is best suited for SMB size companies":
    "We always tailor the customer experience - CooperHire is best suited for SMB size companies",
  "Tailor CooperHire to your needs": "Tailor CooperHire to your needs",
  "Micro company": "Micro company",
  employee: "Employees",
  "Small size company": "Small size company",
  "Medium size company": "Medium size company",
  "Large size company": "Large size company",
  "Hiring Agency": "Hiring Agency",
  "Find suitable candidates for employees": "Find suitable candidates for employees",
  "Thank you for joining CooperHire!": "Thank you for joining CooperHire!",
  "We have sent a confirmation mail with an activation link to your registered email address to get you started.":
    "We have sent a confirmation mail with an activation link to your registered email address to get you started.",
  "Thank You for Signing Up to Cooperhire": "Thank You for Signing Up to Cooperhire",
  "We’ve just sent you an account activation link. Please check your email and your spam folder to get started. Welcome aboard!":
    "We have sent you an account activation link. Please check your email and your spam folder to get started. Welcome aboard!",
  "Thank you for signing up!": "Thank you for signing up!",
  "We just sent you a confirmation email. Please follow the instructions to activate your account.":
    "We have sent you a confirmation email. Please follow the instructions to activate your account.",
  "Create recruiter account": "Create recruiter account",
  "Number, Symbols and other characters are not allowed in First Name":
    "Number, Symbols and other characters are not allowed in First Name",
  "Number, Symbols and other characters are not allowed in Last Name":
    "Number, Symbols and other characters are not allowed in Last Name",
  "A company account already exists with this email domain. Please reach out to your admin ":
    "A company account already exists with this email domain. Please reach out to your admin ",
  " to invite you.": " to invite you.",
  "Email already exists.": "Email already exists.",
  "Please agree terms and conditions": "Please agree terms and conditions",
  "First Name should have maximum 240 characters": "First Name should have maximum 240 characters",
  "Last Name should have maximum 240 characters": "Last Name should have maximum 240 characters",
};

const JOBS = {
  "All Jobs": "All Jobs",
  "All Created": "All Created",
  "Filtered": "Filtered",
  List: "List",
  Filters: "Filters",
  "Create job": "Create job",
  Promote: "Promote",
  "Total Candidates": "Total Candidates",
  "Full Time": "Full Time",
  "Part Time": "Part Time",
  "Job ID": "Job ID",
  "More Jobs": "More Jobs",
  Edit: "Edit",
  Delete: "Delete",
  "Copy URL": "Copy URL",
  Closed: "Closed",
  Published: "Published",
  Internal: "Internal",
  Archived: "Archived",
  "Are you sure you want to delete ": "Are you sure you want to delete ",
  " job": " job",
  "Delete job?": "Delete job?",
  "Publish Job?": "Publish Job?",
  Publish: "Publish",
  Cancel: "Cancel",
  "Publishing a job will display it publicly and on free job boards. Are you sure you want to publish this job?":
    "Publishing a job will display it publicly and on free job boards. Are you sure you want to publish this job?",
  "Job status changed!": "Job status changed!",
  "URL copied!": "URL copied!",
  "Operation unsuccessful!": "Operation unsuccessful!",
  "Job deleted!": "Job deleted!",
  "No jobs created!": "No jobs created!",
  "No job found!": "No job found!",
  "You are not authorized to perform this action.":
    "You are not authorized to perform this action.",
  "Normal user": "Normal user",
};

const CREATE_JOB = {
  "Add job details": "Add job details",
  "Job Title": "Job Title",
  "Short Company Description": "Short Company Description",
  "Job Responsibilities": "Job Responsibilities",
  Optional: "Optional",
  "Job Qualifications": "Job Qualifications",
  "Qualification Requirement": "Qualification Requirement",
  "Job Experience": "Job Experience",
  "Less than 1 Year": "Less than 1 Year",
  Years: "Years",
  "Employment Type": "Employment Type",
  "Full-Time (Permanent)": "Full-Time (Permanent)",
  "Full-Time (Temporary)": "Full-Time (Temporary)",
  "Part-Time (Permanent)": "Part-Time (Permanent)",
  "Part-Time (Temporary)": "Part-Time (Temporary)",
  State: "State",
  "Zip Code": "Zip Code",
  "Last Words": "Last Words",
  "eg. Happy to receive your application": "eg. Happy to receive your application",
  "Remote option": "Remote option",
  Remote: "Remote",
  "Add Screening Questions": "Add Screening Questions",
  Yes: "Yes",
  No: "No",
  "Save as draft": "Save as draft",
  Back: "Back",
  Save: "Save",
  "Job added successfully!": "Job added successfully!",
  "Please enter required fields!": "Please enter required fields!",
  "Please enter valid input!": "Please enter valid input!",
  "Job title": "Job title",
  "Short intro": "Short intro",
  Tasks: "Tasks",
  Requirements: "Requirements",
  Benefits: "Benefits",
  "Anything else you wish to share with the candidate":
    "Anything else you wish to share with the candidate",
  Salary: "Salary",
  EUR: "EUR",
  USD: "USD",
  GBP: "GBP",
  to: "to",
  "Single value": "Single value",
  Range: "Range",
  Day: "Day",
  Week: "Week",
  Month: "Month",
  Year: "Year",
  Education: "Education",
  "Level of education": "Level of education",
  Experience: "Experience",
  "Years of experience": "Years of experience",
  "Employment Contract": "Employment Contract",
  "Employment contract": "Employment contract",
  Department: "Department",
  "Language Application Form": "Language Application Form",
  "Application form language": "Application form language",
  Country: "Country",
  City: "City",
  "ZIP Code": "ZIP Code",
  "Remote Job": "Remote Job",
  "Application Deadline": "Application Deadline",
  "High school or equivalent required": "High school or equivalent required",
  "Vocational training required": "Vocational training required",
  "Undergraduate student": "Undergraduate student",
  "Bachelor degree required": "Bachelor degree required",
  "Graduate student": "Graduate student",
  "Master degree required": "Master degree required",
  "PhD required": "PhD required",
  "Less than 1 year": "Less than 1 year",
  "1-3 years": "1-3 years",
  "3-5 years": "3-5 years",
  "5-7 years": "5-7 years",
  "+7 years": "+7 years",
  Sales: "Sales",
  "Finance and Operations": "Finance and Operations",
  Engineering: "Engineering",
  "Customer service": "Customer service",
  Product: "Product",
  "Human resources": "Human resources",
  Marketing: "Marketing",
  Other: "Other",
  English: "English",
  German: "German",
  "Please select valid Date": "Please select valid Date",
  "Add your job in a few simple steps": "Add your job in a few simple steps",
  "Job Details": "Job Details",
  "Title is required": "Title is required",
  "Title should have minimum 2 characters": "Title should have minimum 2 characters",
  "Title should have maximum 250 characters": "Title should have maximum 250 characters",
  "Short Intro should have minimum 2 characters": "Short Intro should have minimum 2 characters",
  "Short Intro should have maximum 8000 characters":
    "Short Intro should have maximum 8000 characters",
  "Tasks is required": "Tasks is required",
  "Tasks should have minimum 2 characters": "Tasks should have minimum 2 characters",
  "Tasks should have maximum 10000 characters": "Tasks should have maximum 10000 characters",
  "Requirements is required": "Requirements is required",
  "Requirement should have minimum 2 characters": "Requirement should have minimum 2 characters",
  "Requirement should have maximum 10000 characters":
    "Requirement should have maximum 10000 characters",
  "Benefits should have minimum 2 characters": "Benefits should have minimum 2 characters",
  "Benefits should have maximum 10000 characters": "Benefits should have maximum 10000 characters",
  "Text field should have minimum 2 characters": "Text field should have minimum 2 characters",
  "Text field should have minimum 1 character": "Text field should have minimum 1 character",
  "Text field should have maximum 600 characters": "Text field should have maximum 600 characters",
  "Text field should have maximum 1200 characters":
    "Text field should have maximum 1200 characters",
  "Text field should have maximum 6000 characters":
    "Text field should have maximum 6000 characters",
  "Text field should have maximum 70 characters":
    "Text field should have maximum 70 characters",
  "Text field should have maximum 8000 characters":
    "Text field should have maximum 8000 characters",
  "Category is required": "Category is required",
  "Department is required": "Department is required",
  "Language Application Form is required": "Language Application Form is required",
  "Application form language is required": "Application form language is required",
  "City is required": "City is required",
  "Zip Code is required": "Zip Code is required",
  "Zip Code should have maximum 20 characters": "Zip Code should have maximum 20 characters",
  "Symbols are not allowed in zip code": "Symbols are not allowed in zip code",
  "Numbers are not allowed in country": "Numbers are not allowed in country",
  "Please select a valid date": "Please select a valid date",
  "Invalid option": "Invalid option",
  "Enter valid option": "Enter valid option",
  Update: "Update",
  "Job updated successfully!": "Job updated successfully!",
  "Backend Engineer": "Backend Engineer",
  "Our mission is to create and sell quality products...":
    "Our mission is to create and sell quality products...",
  "Executing full lifecycle software development (SDLC)":
    "Executing full lifecycle software development (SDLC)",
  "Excellent knowledge of relational databases and ORM technologies...":
    "Excellent knowledge of relational databases and ORM technologies...",
  "Performance Bonus, Paid Sick Leaves etc.": "Performance Bonus, Paid Sick Leaves etc.",
  "Bonus point if candidate is open to learn new technologies...":
    "Bonus point if candidate is open to learn new technologies...",
};

const CANDIDATES = {
  "All Candidates": "All Candidates",
  "No View Selected": "No View Selected",
  "Add Candidates": "Add Candidates",
  "Search any tags or positions": "Search any tags or positions",
  Name: "Name",
  Job: "Job",
  Stage: "Stage",
  Date: "Date",
  "Candidate Score": "Candidate Score",
  "Candidate list": "Candidate list",
  " Lets find the best fit for the role!": " Lets find the best fit for the role!",
  "Job deleted": "Job deleted",
  Deleted: "Deleted",
  "There are no candidates, right now!": "There are no candidates, right now!",
  "Don't worry we will inform you when someone applies, or you can":
    "Don't worry we will inform you when someone applies, or you can",
  hired: "hired",
  offer: "offer",
  "Candidate not found!": "Candidate not found!",
  "Full Name": "Full Name",
  "Position applied for": "Position applied for",
  "Email Address": "Email Address",
  "Take an action": "Take an action",
  Reject: "Reject",
  "Reject candidate?": "Reject candidate?",
  "Candidate Rejected": "Candidate Rejected",
  "Are you sure you want to reject ": "Are you sure you want to reject ",
  Applied: "Applied",
  Interviewing: "Interviewing",
  Evaluation: "Evaluation",
  Offer: "Offer",
  Hired: "Hired",
  Rejected: "Rejected",
  Evaluating: "Evaluating",
  "Offer Sent": "Offer Sent",
  Details: "Details",
  "Date Applied": "Date Applied",
  "Job Location": "Job Location",
  LinkedIn: "LinkedIn",
  "Job Owner": "Job Owner",
  Documents: "Documents",
  "Document-": "Document-",
  Download: "Download",
  "No documents uploaded": "No documents uploaded",
  Notes: "Notes",
  "There is no evalution for this candidate": "There is no evalution for this candidate",
  "Add your evaluation": "Add your evaluation",
  "Refresh this page": "Refresh this page",
  "Add your Notes": "Add your Notes",
  "Add Evaluation": "Add Evaluation",
  "View All": "View All",
  "Strong No Hire": "Strong No Hire",
  "No Hire": "No Hire",
  "Not Sure": "Not Sure",
  Hire: "Hire",
  "Strong Hire": "Strong Hire",
  Overview: "Overview",
  "View all Notes": "View all Notes",
  "Rate candidate": "Rate candidate",
  "Share your evaluation about the candidate": "Share your evaluation about the candidate",
  "Submit evaluation": "Submit evaluation",
  "Evaluation field should have minimum 2 characters":
    "Evaluation field should have minimum 2 characters",
  "Evaluation field should have maximum 8000 characters":
    "Evaluation field should have maximum 8000 characters",
  "Evaluation added!": "Evaluation added!",
  "You have already evaluated this candidate!": "You have already evaluated this candidate!",
  "Delete evaluation?": "Delete evaluation?",
  "Evaluation updated!": "Evaluation updated!",
  "Evaluation deleted!": "Evaluation deleted!",
  "Are you sure you want to delete evaluation?": "Are you sure you want to delete evaluation?",
  "Write a note about the candidate": "Write a note about the candidate",
  "Add note": "Add note",
  "Note added!": "Note added!",
  "Submit note": "Submit note",
  "Update note": "Update note",
  "Note updated!": "Note updated!",
  "Delete note?": "Delete note?",
  "Are you sure you want to delete note?": "Are you sure you want to delete note?",
  "Note deleted!": "Note deleted!",
  Copy: "Copy",
  "Candidate source": "Candidate source",
  Reload: "Reload",
  "Refresh candidates": "Refresh candidates",
  "Filter by": "Filter by",
  "Candidate stage": "Candidate stage",
  "Job title": "Job title",
  "Jobs fetching failed": "Jobs fetching failed",
  "Clear all": "Clear all",
  Apply: "Apply",
  Disable: "Disable",
  "Looks like you don't have active jobs!": "Looks like you don't have active jobs!",
  "No candidates found!": "No candidates found!",
  "Please refresh the page or": "Please refresh the page or",
  "Please try again.": "Please try again.",
  of: "of",
  "Send email": "Send email",
  "Add Note": "Add Note",
  "No messages": "No messages",
  "No data": "No data",
  "No source": "No source",
  Messages: "Messages",
  Subject: "Subject",
  "Connection failed. Please refresh and try again.":
    "Connection failed. Please refresh and try again.",
  Yesterday: "Yesterday",
  "Sending...": "Sending...",
  "New subject": "New subject",
  Retry: "Retry",
  "a week ago": "a week ago",
  "a month ago": "a month ago",
  "a year ago": "a year ago",
  Today: "Today",
  "Subject limit is 150 characters": "Subject limit is 150 characters",
  "Message limit is 8000 characters": "Message limit is 8000 characters",
  "": "",
  "Refresh page; connection to server not found.": "Refresh page; connection to server not found.",
  "Connection issue with the server. Please refresh the page!":
    "Connection issue with the server. Please refresh the page!",
  "Sorry we encountered a problem. Please refresh the page":
    "Sorry we encountered a problem. Please refresh the page",
  "Oops, can't delete at the moment!": "Oops, can't delete at the moment!",
  "Deleted successfully!": "Deleted successfully!",
  "Failed to get message template.": "Failed to get message template.",
  Phone: "Phone",
  "No location": "No location",
  "Customise Message": "Customise Message",
  "Candidate rejection unsuccessful. Please try again.":
    "Candidate rejection unsuccessful. Please try again.",
  "Reject Candidate": "Reject Candidate",
  "Would you like to send a rejection message?": "Would you like to send a rejection message?",
  "Reject with Email": "Reject with Email",
  "Reject without Email": "Reject without Email",
  "Last Name not found.": "Last Name not found.",
  "Candidate rejection successful.": "Candidate rejection successful.",
  "Stage successfully changed!": "Stage successfully changed!",
  "Message templates": "Message templates",
  "Messaging Template Settings": "Messaging Template Settings",
  "1 day ago": "1 day ago",

  "Note is required": "Note is required",
  "Note should have minimum 2 characters": "Note should have minimum 2 characters",
  "Note should have maximum 8000 characters": "Note should have maximum 8000 characters",
  "Evaluation should have minimum 2 characters": "Evaluation should have minimum 2 characters",
  "Evaluation should have maximum 8000 characters":
    "Evaluation should have maximum 8000 characters",
  "Search candidates": "Search candidates",
  "Follower": "Follower",
  "Followers": "Followers",
  "more followers": "more followers", // +2 more followers
  "Unfollowed successfully!": "Unfollowed successfully!",
  "Followed successfully!": "Followed successfully!",
  "0 Following (follow to keep track of this candidate)": "0 Following (follow to keep track of this candidate)",
  "+Assign": "+Assign",
  "Assigned successfully!": "Assigned successfully!",
  "Failed to Assign!": "Failed to Assign!",
  "Unassigned successfully!": "Unassigned successfully!",
  "Failed to Unassign!": "Failed to Unassign!",
  "Assign others to track this candidate.": "Assign others to track this candidate.",
  "Follow" : "Follow",
  "Following": "Following",
  "Unassign": "Unassign",
  "Assign": "Assign",
  "No Followers": "No Followers",
};

const CREATE_CANDIDATE = {
  "Add Candidate": "Add Candidate",
  "First Name": "First Name",
  "Last Name": "Last Name",
  "E-mail address": "E-mail address",
  "Phone Number": "Phone Number",
  "LinkedIn URL": "LinkedIn URL",
  "Applied to job": "Applied to job",
  "Candidate Notes": "Candidate Notes",
  "Your Documents": "Your Documents",
  Cancel: "Cancel",
  Save: "Save",
  "Upload New": "Upload New",
  "Applied Job": "Applied Job",
  "Jobs fetching failed!": "Jobs fetching failed!",
  "Reload jobs": "Reload jobs",
  "Select job": "Select job",
  "Please wait adding candidate": "Please wait adding candidate",
  "First Name should have minimum alphabet character":
    "First Name should have minimum alphabet character",
  "Last Name should have minimum alphabet character":
    "Last Name should have minimum alphabet character",
  "Enter valid LinkedIn URL": "Enter valid LinkedIn URL",
  "Applied Job is required": "Applied Job is required",
  "Select valid Job": "Select valid Job",
  "Uploaded document size should be less than 5MB":
    "Uploaded document size should be less than 5MB",
  "Documents should not exceed more than 5": "Documents should not exceed more than 5",
  "Candidate added successfully!": "Candidate added successfully!",
  "No documents uploaded!": "No documents uploaded!",
};

const PROMOTION = {
  "Job Board Promotion": "Job Board Promotion",
  "Search Job Boards": "Search Job Boards",
  "Job boards": "Job boards",
  "Your cart": "Your cart",
  "Monthly Visitors": "Monthly Visitors",
  Reach: "Reach",
  Jobs: "Jobs",
  Regions: "Regions",
  "Add product": "Add product",
  "Original Price": "Original Price",
  Discount: "Discount",
  Total: "Total",
  "404 Page not found": "404 Page not found",
};

const SETTINGS = {
  "Set up your company profile": "Set up your company profile",
  Settings: "Settings",
  "Company settings": "Company settings",
  Cancel: "Cancel",
  "Save changes": "Save changes",
  "Company Name": "Company Name",
  "Company Website": "Company Website",
  "Phone number": "Phone number",
  "Brief Company Description": "Company settings",
  "Industry type": "Industry type",
  "Company size": "Company size",
  "Privacy Options": "Industry type",
  "Custom URL": "Custom URL",
  "Coming soon": "Coming soon",
  "Team Settings": "Team Settings",
  "Pending invites": "Pending invites",
  "Send invite": "Send invite",
  Role: "Role",
  "Send Invitation": "Send Invitation",
  "User Profile Settings": "User Profile Settings",
  "Language Preference": "Language Preference",
  "Language preference of company page": "Language preference of company page",
  "Privacy Settings": "Privacy Settings",
  "Email ID": "Email ID",
  "Can't remember your password?": "Can't remember your password?",
  "This action will freeze your account and deactivate all your active jobs. Please proceed with caution.":
    "This action will freeze your account and deactivate all your active jobs. Please proceed with caution.",
  "Deactivate Account": "Deactivate Account",
  Change: "Change",
  "Reset your password": "Reset your password",
  Company: "Company",
  Team: "Team",
  Purchases: "Purchases",
  "User Profile": "User Profile",
  "Company name": "Company name",
  "Brief company description": "Brief company description",
  "ProTip!": "ProTip!",
  " Use hyphen (-) instead of space in Custom URL":
    " Use hyphen (-) instead of space in Custom URL",
  "Company name is required": "Company name is required",
  "Enter valid Company name": "Enter valid Company name",
  "Phone number is required": "Phone number is required",
  "Enter valid Phone number": "Enter valid Phone number",
  "Company website is required": "Company website is required",
  "Enter valid Company website": "Enter valid Company website",
  Micro: "Micro",
  "Hiring Agency": "Hiring Agency",
  Small: "Small",
  Medium: "Medium",
  Large: "Large",
  "Spaces and symbols except hyphen are not allowed in Custom URL":
    "Spaces and symbols except hyphen are not allowed in Custom URL",
  "Accepted Invites": "Accepted Invites",
  "My team": "My team",
  "Send Invite": "Send Invite",
  "No accepted invites": "No accepted invites",
  "No pending invites": "No pending invites",
  Member: "Member",
  Admin: "Admin",
  "Assign as admin": "Assign as admin",
  "Assign as member": "Assign as member",
  Remove: "Remove",
  "Are you sure you want to remove": "Are you sure you want to remove",
  "Remove user?": "Remove user?",
  "Member deleted!": "Member deleted!",
  "on the way": "on the way",
  "Coming soon...": "Coming soon...",
  "First name is required": "First name is required",
  "Enter valid First name": "Enter valid First name",
  "Last name is required": "Last name is required",
  "Enter valid Last name": "Enter valid Last name",
  Logout: "Logout",
  Success: "Success",
  "Invitation was sent to ": "Invitation was sent to ",
  "Please update input fields with valid or new values!":
    "Please update input fields with valid or new values!",
  "Only admins can send email invites": "Only admins can send email invites",
  "Email address is already taken": "Email address is already taken",
  "Save & Submit": "Save & Submit",
  "Add team member": "Add team member",
  "Invite your team to collaborate": "Invite your team to collaborate",
  Add: "Add",
  "Add company logo": "Add company logo",
  "User updated!": "User updated!",
  "JPG, JPEG, PNG and GIF are allowed in profile": "JPG, JPEG, PNG and GIF are allowed in profile",
  "Custom URL contains invalid characters.": "Custom URL contains invalid characters.",
  "Custom URL already taken.": "Custom URL already taken.",
  "Company updated!": "Company updated!",
  "Admin access": "Admin access",
  "Easily attract talent by setting up your company details and career page.":
    "Easily attract talent by setting up your company details and career page.",
  "Add and manage new team members to better collaborate on hiring.":
    "Add and manage new team members to better collaborate on hiring.",
  "Show your team who you are by customising your profile and settings.":
    "Show your team who you are by customising your profile and settings.",
  "Edit your User profile": "Edit your User profile",
  "Change avatar": "Change avatar",
  "Please ask your admins to remove you from team!":
    "Please ask your admins to remove you from team!",
  "Email already exists": "Email already exists",
  "Description should have maximum 240 characters":
    "Description should have maximum 240 characters",
  "Please enter required fields or valid inputs!": "Please enter required fields or valid inputs!",
  "Company name should have maximum 240 characters":
    "Company name should have maximum 240 characters",
  "Company website should have maximum 240 characters":
    "Company website should have maximum 240 characters",
  "City should have maximum 240 characters": "City should have maximum 240 characters",
  "Email Invite": "Email Invite",
  "Last name should have maximum 240 characters": "Last name should have maximum 240 characters",
  "First name should have maximum 240 characters": "First name should have maximum 240 characters",
  "Position should have maximum 240 characters": "Position should have maximum 240 characters",
  "Company description in 2500 characters...": "Company description in 2500 characters...",
  "Description should have maximum 2500 characters":
    "Description should have maximum 2500 characters",
  "Failed to get message templates.": "Failed to get message templates.",
  Templates: "Templates",
  templates: "templates",
  "Default Template": "Default Template",
  "Create new template": "Create new template",
  "Enter the contents of your template message here":
    "Enter the contents of your template message here",
  "The email signature is automatically added using the details on your profile":
    "The email signature is automatically added using the details on your profile",
  "Message is required": "Message is required",
  "Message should have maximum 2000 characters": "Message should have a maximum of 2000 characters",
  "Please update template!": "Please update template!",
  "Message Trigger": "Message Trigger",
  "Template Name": "Template Name",
  Message: "Message",
  "Template Name is required": "Template Name is required",
  "Subject is required": "Subject is required",
  "Subject should have maximum 240 characters": "Subject should have a maximum of 240 characters",
  "Template Name should have maximum 240 characters":
    "Template Name should have a maximum of 240 characters",
  "Message should have maximum 8000 characters": "Message should have a maximum of 8000 characters",
  "What do you want to call your new template?": "What do you want to call your new template?",
  "What is the subject of this message?": "What is the subject of this message?",
  "Delete template?": "Delete template?",
  "Template updated.": "Template updated.",
  "Template created.": "Template created.",
  "Template deleted.": "Template deleted.",
  "Operation failed, Try again!": "Operation failed, Try again!",
  "Unable to fetch the placeholders.": "Unable to fetch the placeholders.",
  "Candidate Last Name": "Candidate Last Name",
  "Candidate Full Name": "Candidate Full Name",
  "Candidate First Name": "Candidate First Name",
  "Unsupported file format.": "Unsupported file format.",
  "File size exceeds 5MB limit.": "File size exceeds 5MB limit.",
  "Unable to retrieve message templates at this time. Please try again later":
    "Unable to retrieve message templates at this time. Please try again later",
  "Would you like to delete this template?": "Would you like to delete this template?",
  "Template settings": "Template settings",
  "Default Rejection Template": "Default Rejection Template",
  "This template is selected by default when rejecting a candidate":
    "This template is selected by default when rejecting a candidate",
  "Default Apply Template": "Default Apply Template",
  "This template is sent out to any candidate that applies to one of your jobs.":
    "This template is sent out to any candidate that applies to one of your jobs.",
  "Message field is mandatory.": "Message field is mandatory.",
  "Message must not exceed 8000 characters.": "Message must not exceed 8000 characters.",
  "Please update the template.": "Please update the template.",
  "Template successfully generated.": "Template successfully generated.",
  "Template successfully updated.": "Template successfully updated.",
  "Operation unsuccessful. Please retry.": "Operation unsuccessful. Please retry.",
  "Unable to retrieve placeholders.": "Unable to retrieve placeholders.",
  "Template Name is mandatory.": "Template Name is mandatory.",
  "Subject field is mandatory.": "Subject field is mandatory.",
  "Please specify the name for your new template.":
    "Please specify the name for your new template.",
  "Template Name should not exceed 240 characters.":
    "Template Name should not exceed 240 characters.",
  "Please add the subject of this message.": "Please add the subject of this message.",
  "Subject should not exceed 240 characters.": "Subject should not exceed 240 characters.",
  "Template successfully removed.": "Template successfully removed.",
  "Please input the content of your template message here.":
    "Please input the content of your template message here.",
  "File size exceeds the 5MB limit.": "File size exceeds the 5MB limit.",
  "File format is not supported.": "File format is not supported.",
  "Personalise Your Message": "Personalise Your Message",
  "Message Templates": "Message Templates",
  Placeholders: "Placeholders",
  "Create a new template": "Create a new template",
  "Message size must not exceed 20MB limit": "Message size must not exceed 20MB limit",
  Trigger: "Trigger",
  "Reject duplicate applications from the same candidate for the same job":
    "Reject duplicate applications from the same candidate for the same job",
  "How long should duplicate applications to the same job be blocked?":
    "How long should duplicate applications to the same job be blocked?",
  "1 day": "1 Day",
  "1 week": "1 Week",
  "2 weeks": "2 Weeks",
  "1 month": "1 Month",
  "2 months": "2 Months",
  "6 months": "6 Months",
  "Select an option": "Select an option",
};

const SUBSCRIPTIONS = {
  "My Subscription": "My Subscription",
  "Current Subscription": "Current Subscription",
  Status: "Status",
  Subscribed: "Subscribed",
  Subscriptions: "Subscriptions",
  Canceled: "Canceled",
  "Next Invoice": "Next Invoice",
  Renews: "Renews",
  Every: "Every",
  Month: "Month",
  Year: "Year",
  month: "month",
  year: "year",
  "Downgrade Status": "Downgrade Status",
  to: "to",
  on: "on",
  On: "On",
  "Manage Subscription": "Manage Subscription",
  per: "per",
  From: "From",
  "This includes": "This includes",
  "Change Plan": "Change Plan",
  Monthly: "Monthly",
  Yearly: "Yearly",
  this: "this",
  Scheduled: "Scheduled",
  "Please review the new plan details and features":
    "Please review the new plan details and features",
  "You are currently on the": "You are currently on the",
  Manage: "Manage",
  Confirm: "Confirm",
  "including tax": "including tax",
  "plus tax": "plus tax",
  Starting: "Starting",
  "you will be charged": "you will be charged",
  upgrade: "upgrade",
  downgrade: "downgrade",
  off: "off",
  "I have reviewed the summary and accept changes":
    "I have reviewed the summary and accept changes",
  "Are you sure you want to delete this invite?": "Are you sure you want to delete this invite?",
  "This action will revoke invitation to": "This action will revoke invitation to",
  "You are about to change your subscription from {{oldInterval}} {{oldPlan}} to {{newInterval}} {{newPlan}}.":
    "You are about to change your subscription from {{oldInterval}} {{oldPlan}} to {{newInterval}} {{newPlan}}.",
  "Your new subscription will cost {{newPlanPrice}} (plus tax) per {{newInterval}}.":
    "Your new subscription will cost {{newPlanPrice}} (plus tax) per {{newInterval}}.",
  "Your {{planLimitsChangeType}} will be scheduled to take effect on {{downgradeDate}}.":
    "Your {{planLimitsChangeType}} will be scheduled to take effect on {{downgradeDate}}.",
  "Please ensure to limit the users and jobs according to the {{newPlanType}} {{newPlan}} plan before due date for the downgrade to take place. If adjustments are not made before the due date, your account will automatically continue on your current plan":
    "Please ensure to limit the users and jobs according to the {{newPlanType}} {{newPlan}} plan before due date for the downgrade to take place. If your account exceeds its limits by the due date, your account will simply remain on your current plan",
  "Since you have already paid for your current subscription, a prorated credit of {{proratedCredit}} will be applied towards your new subscription. As a result, this {{oldInterval}} you will only be charged {{newCharge}}.":
    "Since you have already paid for your current subscription, a prorated credit of {{proratedCredit}} will be applied towards your new subscription. As a result, this {{oldInterval}} you will only be charged {{newCharge}}.",
  "Your {{planLimitsChangeType}} will takes place immediately.":
    "Your {{planLimitsChangeType}} will takes place immediately.",
  "Starting {{downgradeDate}} you will be charged {{newPlanPrice}} (plus tax) per {{newInterval}}.":
    "Starting {{downgradeDate}} you will be charged {{newPlanPrice}} (plus tax) per {{newInterval}}.",

  // notifications
  "Downgrade canceled successfully!": "Downgrade canceled successfully!",
  "Please renew your plan to update the subscription!":
    "Please renew your plan to update the subscription!",
  "Downgrade scheduled successfully!": "Downgrade scheduled successfully!",
  "Updating...": "Updating...",
  "Your plan has successfully changed!": "Your plan has successfully changed!",
  plan: "plan",
  "<b>before due date</b> for the downgrade to take place":
    "<b>before due date</b> for the downgrade to take place",
  "If adjustments are not made before the due date, your account will automatically continue on your current plan":
    "If your account exceeds its limits by the due date, your account will simply remain on your current plan",
  "Please update your payment plan before adding more users. You cannot invite more than":
    "Please update your payment plan before adding more users. You cannot invite more than",
  "users with the": "users with the",
  "plan user_limit_error": "plan",
  "Please update your payment plan before adding more jobs. You cannot add more than":
    "Please update your payment plan before adding more jobs. You cannot add more than",
  "jobs with the": "jobs with the",
  "plan job_limit_error": "plan",
  "Please limit the users and jobs before downgrading the plan!":
    "Please limit the account usage according to the new plan before downgrading the plan!",
  "Your plan is scheduled to change on": "Your plan is scheduled to change on",
  "Please ensure everything is within the new limits, according to the":
    "Please ensure everything is within the new limits, according to the",
  "plan plan_change_toast": "plan",
  "Your subscription is scheduled for downgrade on":
    "Your subscription is scheduled for downgrade on",
  "plan by due date": "plan by due date",
  "Your subscription has for downgraded to": "Your subscription has for downgraded to",
  "on due_date_notification": "on",
  "Your posted jobs are currently inactive/deleted":
    "Your posted jobs are currently inactive/deleted",
  "You have already scheduled a downgrade for your subscription, please wait for the downgrade to take effect before changing the plan!":
    "You have already scheduled a downgrade for your subscription, please wait for the downgrade to take effect before changing the plan!",
  "Your payment has failed. Please update your payment details in billing portal to avoid service interruption.":
    "Your payment has failed. Please update your payment details in billing portal to avoid service interruption.",
  "Please update your payment plan before adding more message templates. You cannot add more than":
    "Please update your payment plan before adding more message templates. You cannot add more than",
  "message templates with the": "message templates with the",
  "plan message_template_limit_error": "plan.",
  "You have exceeded the limits of your current plan. Please upgrade or limit your usage according to your plan limits.":
    "You have exceeded the limits of your current plan. Please upgrade or limit your usage according to your plan limits.",
  "Please update your payment plan before adding more users. You cannot invite more than {{subscription.max_users}} users with the {{subscription.subscription_type}} plan":
    "Please update your payment plan before adding more users. You cannot invite more than {{subscription.max_users}} users with the {{subscription.subscription_type}} plan",
  "Manage your subscriptions and billing details": "Manage your subscriptions and billing details",
  "Invite deleted successfully!": "Invite deleted successfully!",
  "Cancel Downgrade": "Cancel Downgrade",
  "Please update your payment plan before adding more templates. You cannot add more than {{subscription.max_message_templates}} templates with the {{subscription.subscription_type}} plan":
    "Please update your payment plan before adding more templates. You cannot add more than {{subscription.max_message_templates}} templates with the {{subscription.subscription_type}} plan",
  // Pricing cards
  "For companies starting out with basic recruiting needs.":
    "For companies starting out with basic recruiting needs.",
  "For companies that are hiring all year round.": "For companies that are hiring all year round.",
  "Free Multiposting": "Free Multiposting",
  "Basic Customer Support": "Basic Customer Support",
  "Normal application speed": "Normal application speed",
  "{{jobs_limit}} Total Jobs": "{{jobs_limit}} Total Jobs",
  "{{users_limit}} User Account": "{{users_limit}} User Account",
  "For Companies that are growing their perfect team!":
    "For Companies that are growing their perfect team!",
  "Premium Multiposting": "Premium Multiposting",
  "All your jobs on Kleinanzeigen": "All your jobs on Classifieds",
  "Fast (zappy) Application Speed": "Fast (zappy) Application Speed",
  "{{users_limit}} User Accounts": "{{users_limit}} User Accounts",
  "For companies that are hiring all yearly round.":
    "For companies that are hiring all yearly round.",
  "Everything in growth tier": "Everything in growth tier",
  "{{jobs_limit}} Active Jobs (upgradable)": "{{jobs_limit}} Active Jobs (upgradable)",
  "{{users_limit}} User Accounts (upgradable)": "{{users_limit}} User Accounts (upgradable)",
  "Dedicated Account Manager": "Dedicated Account Manager",
  "Dedicated Customer Support Manager": "Dedicated Customer Support Manager",
  "Access to Beta Features": "Access to Beta Features",
  "Migration Support": "Migration Support",
};

const PROMOTE = {
  Promotions: "Promotions",
  "Top job boards for you": "Top job boards for you",
  "Tailor made job boards for your hiring needs": "Tailor made job boards for your hiring needs",
  Before: "Before",
  RECOMMENDED: "RECOMMENDED",
  "VALUE PLAN": "VALUE PLAN",
  OFF: "OFF",
  Now: "Now",
  "Starting from": "Starting from",
  "Stay ahead in hiring the best talent by posting your job on top selected job boards locally and internationally!":
    "Stay ahead in hiring the best talent by posting your job on top selected job boards locally and internationally!",
  "No job boards found": "No job boards found",
  "Job boards fetching failed": "Job boards fetching failed",
};

const SLOT = {
  "Job Board": "Job Board",
  "Monthly Visitors": "Monthly Visitors",
  Reach: "Reach",
  Jobs: "Jobs",
  MRegions: "MRegions",
  Products: "Products",
  "Best plans from ": "Best plans from ",
  day: "day",
  days: "days",
  Price: "Price",
  "Go to cart": "Go to cart",
  "No slots found!": "No slots found!",
  "Slot added!": "Slot added!",
  "Slots added!": "Slots added!",
  "Slot updated!": "Slot updated!",
  "Slots updated!": "Slots updated!",
  "Slot deleted!": "Slot deleted!",
  "Slots deleted!": "Slots deleted!",
  "slot already exist!": "slot already exist!",
  "slots already exist!": "slots already exist!",
  "No slots added!": "No slots added!",
  "Your cart is empty!": "Your cart is empty!",
};

const BOOKINGS = {
  "My Bookings": "My Bookings",
  "You haven’t made any bookings yet.": "You haven’t made any bookings yet.",
  "You can visit our Job Board Store by clicking the button below.":
    "You can visit our Job Board Store by clicking the button below.",
  "Explore Job Boards": "Explore Job Boards",
  Inventory: "Inventory",
  "Active & Past Bookings": "Active & Past Bookings",
  "Easily track all your job board bookings and manage your promoted jobs in one place":
    "Easily track all your job board bookings and manage your promoted jobs in one place",
  "Job Board": "Job Board",
  Booking: "Booking",
  "Purchase Date": "Purchase Date",
  Quantity: "Quantity",
  Duration: "Duration",
  Action: "Action",
  "Activate Now": "Activate Now",
  "Job Name": "Job Name",
  "Activated On": "Activated On",
  "days left": "days left",
  "day left": "day left",
  Clicks: "Clicks",
  Link: "Link",
  Days: "Days",
  Expired: "Expired",
  "days ago": "days ago",
  "day ago": "day ago",
  "View link": "View link",
  "Select the job you want to promote": "Select the job you want to promote",
  "Job ID ": "Job ID ",
  Location: "Location",
  Next: "Next",
  "Activate your booking": "Activate your booking",
  "Confirm Activation": "Confirm Activation",
  Quantitiy: "Quantitiy",
  Bookings: "Bookings",
  "Looks like you do not have published jobs!": "Looks like you do not have published jobs!",
  "Reload inventory": "Reload inventory",
  "Your inventory is empty!": "Your inventory is empty!",
  "Reload bookings": "Reload bookings",
  "Currently you do not have active and past bookings":
    "Currently you do not have active and past bookings",
  "Promote job": "Promote job",
  "No date": "No date",
  "Expiring today": "Expiring today",
  "can't promote same job twice before expiry for same job board":
    "can't promote same job twice before expiry for same job board",
  "Operation successful!": "Operation successful!",
  Pending: "Pending",
};

const strings = {
  ...DASHBOARD,
  ...LOGIN,
  ...REGISTER,
  ...JOBS,
  ...CREATE_JOB,
  ...CANDIDATES,
  ...CREATE_CANDIDATE,
  ...PROMOTION,
  ...SETTINGS,
  ...SUBSCRIPTIONS,
  ...PROMOTE,
  ...SLOT,
  ...BOOKINGS,
};

export default strings;
