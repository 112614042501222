import { DropdownMenu, Text, View } from "reshaped";

import { Feedback } from "icons";
import { Tooltip } from "components/library";
import useTranslation from "components/hooks/useTranslation";
import common from "components/styles/reshaped/reusables/common.module.css";

export function FeedbackButton() {
  const { t, locale } = useTranslation();

  const handleFeedback = () => {
    let link = "https://form.jotform.com/233483434328357";

    if (locale === "de") {
      link = "https://form.jotform.com/233485008218354";
    }

    window.open(link, "_blank");
  };

  const handleFeatureRequest = () => {
    window.open("https://cooperhire.canny.io/feedback", "_blank");
  };

  return (
    <DropdownMenu width={locale === "de" ? "170px" : "150px"} position="top">
      <DropdownMenu.Trigger>
        {(attributes) => (
          <View
            attributes={{
              ...attributes,
              style: {
                position: "fixed",
                bottom: "calc(10 * var(--spacing-xs))",
                right: "calc(10 * var(--spacing-xs))",
                zIndex: 50,
              },
            }}
          >
            <Tooltip position="left" text={t("We would love your feedback!")}>
              {({ className }) => (
                <button className={`${common.feedback__btn} ${className}`}>
                  <Feedback />
                </button>
              )}
            </Tooltip>
          </View>
        )}
      </DropdownMenu.Trigger>
      <DropdownMenu.Content>
        <DropdownMenu.Item onClick={handleFeedback}>
          <Text variant="feature-3">{t("Give Feedback")}</Text>
        </DropdownMenu.Item>
        <DropdownMenu.Item onClick={handleFeatureRequest}>
          <Text variant="feature-3">{t("Request Feature")}</Text>
        </DropdownMenu.Item>
      </DropdownMenu.Content>
    </DropdownMenu>
  );
}
