import { useMemo } from "react";
import { Avatar, Button, Text, View } from "reshaped";

import useName from "components/hooks/useName";
import useSpacing from "components/hooks/useSpacing";
import { TextWithEclipses } from "components/reusables";
import useTranslation from "components/hooks/useTranslation";
import { getFullFormattedDate } from "utils/getFormattedDate";
import styles from "components/styles/reshaped/bookings/bookings.module.css";

export const InventoryRow = ({
  purchasedSlot = {},
  setPromotingInProgress,
  activate,
  setPromotingSlot,
}) => {
  const {
    job_board_name,
    slot_name,
    purchased_at,
    // disabling "price" for period of time
    // price,
    quantity,
    duration,
  } = purchasedSlot;

  const { t } = useTranslation();
  const { sm, xl } = useSpacing();
  const jobBoardProfile = useName({
    info: purchasedSlot,
    first_name: purchasedSlot?.job_board_name,
  });

  const fullDate = useMemo(() => {
    return getFullFormattedDate(purchased_at);

    //eslint-disable-next-line
  }, []);

  const handlePromoteJob = () => {
    setPromotingSlot(purchasedSlot);
    setPromotingInProgress(true);
    activate();
  };

  return (
    <View
      height="58px"
      className={styles.inventory__row}
      attributes={{
        style: {
          backgroundColor: "var(--rs-color-background-neutral-faded)",
        },
      }}
    >
      {Object.entries({
        job_board_name,
        slot_name,
        purchased_at: fullDate.text,
        // disabling "price" for period of time
        // price,
        quantity,
        duration: `${duration} ${duration === 1 ? "day" : "days"}`,
      }).map(([key, value]) => {
        return (
          <View
            key={value}
            className={`${styles.inventory__column} ${
              ["job_board_name", "slot_name", "purchased_at"].includes(key) ? styles[`grow-2`] : ""
            }`}
          >
            {key === "job_board_name" ? (
              <View direction="row" align="center" gap={sm}>
                {jobBoardProfile.hasURL && (
                  <Avatar size={xl} src={jobBoardProfile.url} alt={value} />
                )}
                {!jobBoardProfile.hasURL && (
                  <Avatar size={xl} initials={jobBoardProfile.letters} alt={value} />
                )}
                <TextWithEclipses variant="body-strong-2" text={value} characterLimit={20} />
              </View>
            ) : (
              <TextWithEclipses
                variant="body-2"
                text={value}
                characterLimit={key === "slot_name" ? 27 : 20}
              />
            )}
          </View>
        );
      })}
      <View className={styles.inventory__column} align="start">
        <Button variant="ghost" attributes={{ style: { padding: 0 } }} onClick={handlePromoteJob}>
          <Text
            variant="body-strong-2"
            attributes={{
              style: {
                color: "var(--rs-color-border-primary)",
              },
            }}
          >
            {t("Activate Now")}
          </Text>
        </Button>
      </View>
    </View>
  );
};
