import { View } from "reshaped";
import useSpacing from "components/hooks/useSpacing";
import { WrapperWithNav } from "components/reusables";
import { JobFilters, JobsCardGroup, JobsTitleGroup } from "components/pages/jobs";

function Jobs() {
  const { sm, xl } = useSpacing();

  return (
    <WrapperWithNav>
      <View.Item grow>
        <View
          gap={xl + sm}
          height="100%"
          attributes={{
            style: {
              marginBlockEnd: "calc(var(--spacing-xxl) + var(--spacing-xl))",
            },
          }}
        >
          <JobsTitleGroup />
          <JobFilters />
          <JobsCardGroup />
        </View>
      </View.Item>
    </WrapperWithNav>
  );
}

export default Jobs;
