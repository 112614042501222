import { useMemo } from "react";
import { Link, Text } from "reshaped";

import { ArrowDown } from "icons";
import { baseColor } from "utils/colors/accents";
import useToggle from "components/hooks/useToggle";
import useTranslation from "components/hooks/useTranslation";
import { areKeysAvailableIn, isObject } from "utils/miniHelpers";
import common from "components/styles/reshaped/reusables/common.module.css";
import { getTextWithEclipses, isTextExceeding } from "utils/getTextWithEclipses";

export const FoldText = ({
  color,
  variant,
  text = "",
  characterLimit,
  noOfEclipses = 2,
  withTranslation = false,
}) => {
  const { t } = useTranslation();

  const sanitizedText = useMemo(() => {
    return withTranslation ? t(text) : text;

    //eslint-disable-next-line
  }, []);

  return (
    <Text color={color || "neutral"} variant={variant || "body-1"}>
      {isTextExceeding(sanitizedText, characterLimit) ? (
        <FoldTextButton {...{ characterLimit, noOfEclipses }} text={sanitizedText} />
      ) : (
        sanitizedText
      )}
    </Text>
  );
};

// show only when characters exceed
export const FoldTextButton = ({ text, characterLimit, noOfEclipses }) => {
  const { value: fold, setToggle: setFold } = useToggle(true);

  return (
    <>
      {fold && getTextWithEclipses(text, characterLimit, noOfEclipses)}
      {!fold && text}
      <button
        className={`${common.fold__btn} ${!fold ? common.fold__opened : ""}`}
        onClick={setFold}
      >
        <ArrowDown fill={baseColor.black} />
      </button>
    </>
  );
};

export const TextWithEclipses = ({
  text = "",
  className,
  attributes,
  characterLimit = 20,
  noTitle = false,
  noOfEclipses = 3,
  color = "neutral",
  variant = "body-1",
  withTranslation = false,
  customAttributes = {},
  ...rest
}) => {
  const { t } = useTranslation();

  const sanitizedProperties = useMemo(() => {
    const textVersion = withTranslation ? t(text) : text;

    const title = noTitle
      ? {}
      : { title: isTextExceeding(textVersion, characterLimit) ? textVersion : "" };

    const properties = {
      text: textVersion,
      attributes:
        typeof attributes === "object" && Object.keys(attributes).length > 0
          ? { ...title, ...attributes }
          : { ...title },
    };

    return properties;

    //eslint-disable-next-line
  }, [className, characterLimit, noTitle, text]);

  return (
    <Text
      color={color}
      variant={variant}
      {...rest}
      {...(className ? { className } : {})}
      attributes={{
        ...sanitizedProperties.attributes,
        ...(isObject(customAttributes) ? customAttributes : {}),
      }}
    >
      {getTextWithEclipses(sanitizedProperties.text, characterLimit, noOfEclipses)}
    </Text>
  );
};

export const LinkTextWithEclipse = ({
  text = "",
  url = "",
  className,
  textAttributes = {},
  linkAttributes = {},
  characterLimit,
  noTitle = false,
  noOfEclipses = 3,
  color = "neutral",
  variant = "body-1",
  withTranslation = false,
  ...rest
}) => {
  const { t } = useTranslation();

  const sanitizedProperties = useMemo(() => {
    const textVersion = withTranslation ? t(text) : text;

    const title = noTitle
      ? {}
      : { title: isTextExceeding(textVersion, characterLimit) ? textVersion : "" };

    const properties = {
      text: textVersion,
      textAttributes:
        textAttributes &&
        typeof textAttributes === "object" &&
        Object.keys(textAttributes).length > 0
          ? {
              ...textAttributes,
              style: areKeysAvailableIn({ object: textAttributes, keys: ["style"] })
                ? { cursor: "pointer", ...textAttributes.style }
                : { cursor: "pointer" },
            }
          : { style: { cursor: "pointer" } },

      linkAttributes:
        typeof linkAttributes === "object" && Object.keys(linkAttributes).length > 0
          ? { ...title, target: "_blank", ...linkAttributes }
          : { ...title, target: "_blank" },
    };

    return properties;

    //eslint-disable-next-line
  }, [className, characterLimit, noTitle, text]);

  return (
    <Text
      color={color}
      variant={variant}
      {...rest}
      {...(className ? { className } : {})}
      attributes={sanitizedProperties.textAttributes}
    >
      <Link
        variant="plain"
        color="inherit"
        href={url}
        attributes={sanitizedProperties.linkAttributes}
        className={common.link__underline}
      >
        {getTextWithEclipses(sanitizedProperties.text, characterLimit, noOfEclipses)}
      </Link>
    </Text>
  );
};
