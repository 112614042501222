import { useState, useCallback } from "react";
import { Button, Checkbox, FormControl, Link, TextField, Text, View } from "reshaped";

import { Eye } from "icons";
import { setInitial } from "utils/setInitial";
import useSpacing from "components/hooks/useSpacing";
import { useDispatch, useSelector } from "react-redux";
import { setInitialErrors } from "utils/setInitialErrors";
import useTranslation from "components/hooks/useTranslation";
import { PasswordProgress } from "components/pages/register";
import { ShowSupportError } from "components/reusables/common";
import { isEmailRestricted } from "utils/checkRestrictionEmail";
import Titles from "components/reusables/loginAndRegister/Titles";
import { sanitizeWord, validateErrors } from "utils/validateError";
import { passwordProgressHelper } from "utils/passwordProgressHelper";
import { metaSlice, setUserLeadInBackend } from "redux/meta/metaSlice";
import styles from "components/styles/reshaped/reusables/form.module.css";
import { EMAILVALIDATIONREGEX, PASSWORDVALIDATIONREGEX } from "utils/RegularExpressions";

export const RegisterUser = (props) => {
  const { title, subTitle, setUserDetails } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { xs, sm, lg, xl } = useSpacing();
  const { errors, registrationError, userLead, APIError, isLoading } = useSelector(
    (state) => state.meta
  );

  const [password, setPassword] = useState("");
  const [agreedTerms, setAgreedTerms] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordHelper, setShowPasswordHelper] = useState(false);
  const [email, setEmail] = useState(setInitial("email", userLead.user));
  const [lastName, setLastName] = useState(setInitial("lastName", userLead.user));
  const [firstName, setFirstName] = useState(setInitial("firstName", userLead.user));
  const [passwordProgress, setPasswordProgress] = useState({
    sevenCharacters: false,
    oneNumber: false,
    oneLowercase: false,
    oneUppercase: false,
    oneSpecial: false,
  });
  const [error, setError] = useState(
    setInitialErrors(["firstName", "lastName", "email", "password", "agreeTerms"], errors, [
      "email",
      "password",
    ])
  );

  const validate = useCallback((errorName, name, handleError, regex) => {
    validateErrors(errorName, name, handleError, t, regex);

    //eslint-disable-next-line
  }, []);

  const handleLimitedCharactersValidation = ({ state, errorKey, labelName }) => {
    let charactersExceeded = false;
    if (state && typeof state === "string" && state.length > 240) {
      charactersExceeded = true;
      setError((prev) => {
        return {
          ...prev,
          [errorKey]: t(`${labelName} should have maximum 240 characters`),
        };
      });
    }
    return charactersExceeded;
  };

  const handleFirstName = ({ value }) => {
    if (error.firstName) {
      setError((previousError) => {
        return {
          ...previousError,
          firstName: null,
        };
      });
    }
    setFirstName(value);
  };

  const handleLastName = ({ value }) => {
    if (error.lastName) {
      setError((previousError) => {
        return {
          ...previousError,
          lastName: null,
        };
      });
    }
    setLastName(value);
  };

  const handleEmail = ({ value }) => {
    if (APIError) dispatch(metaSlice.actions.clearAPIError());
    if (error.email) {
      setError((previousError) => {
        return {
          ...previousError,
          email: null,
        };
      });
    }
    setEmail(value);
  };

  const handlePassword = ({ value }) => {
    if (!showPasswordHelper) setShowPasswordHelper(true);

    passwordProgressHelper(value, setPasswordProgress);

    if (error.password) {
      setError((previousError) => {
        return {
          ...previousError,
          password: null,
        };
      });
    }
    setPassword(value);
  };

  const handleAgreeTerms = ({ event }) => {
    if (error.agreeTerms) {
      setError((previousError) => {
        return {
          ...previousError,
          agreeTerms: null,
        };
      });
    }
    setAgreedTerms(event.target.checked);
  };

  const handleFirstNameValidation = (e) => {
    const hasError = handleLimitedCharactersValidation({
      state: firstName,
      errorKey: "firstName",
      labelName: "First Name",
    });
    if (hasError) return;
    validate("firstName", firstName, setError, /[a-zA-Z]{1,}\s?'?[a-zA-Z]{0,}/);
  };

  const handleLastNameValidation = (e) => {
    const hasError = handleLimitedCharactersValidation({
      state: lastName,
      errorKey: "lastName",
      labelName: "Last Name",
    });
    if (hasError) return;
    validate("lastName", lastName, setError, /[a-zA-Z]{1,}\s?'?[a-zA-Z]{0,}/);
  };

  const handleEmailValidation = (e) => {
    if (registrationError) {
      dispatch(metaSlice.actions.clearRegistrationError());
    }
    validate("email", email, setError, EMAILVALIDATIONREGEX);
  };

  const handleShowPassword = (e) => {
    setShowPassword(!showPassword);
  };

  const handlePasswordValidation = (e) => {
    if (showPasswordHelper) setShowPasswordHelper(false);

    if (registrationError) {
      dispatch(metaSlice.actions.clearRegistrationError());
    }

    validate("password", password, setError, PASSWORDVALIDATIONREGEX);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (registrationError) {
      dispatch(metaSlice.actions.clearRegistrationError());
    }

    let hasError = false;

    if (!agreedTerms) {
      hasError = true;
      setError((previousError) => {
        return {
          ...previousError,
          agreeTerms: t("Please agree terms and conditions"),
        };
      });
    }

    // if user submitting with empty or invalid fields
    for (let field in error) {
      if (error[field]) {
        hasError = true;
        if (field === "agreeTerms") continue;
        if (typeof error[field] === "object") {
          setError((previousError) => {
            return {
              ...previousError,
              [field]: t(`${sanitizeWord(field)} is required`),
            };
          });
        }
      }
    }

    if (hasError) return;

    // all sanitized inputs with spaces removed at the ends
    const userFormData = {
      first_name: firstName.trim(),
      last_name: lastName.trim(),
      email: email.trim(),
      password,
    };

    // Not allowing restricted emails
    if (isEmailRestricted(userFormData.email)) {
      setError((previousError) => {
        return {
          ...previousError,
          email: t("Email is restricted"),
        };
      });
      return;
    }

    setUserDetails(userFormData);
    dispatch(
      setUserLeadInBackend({
        user: userFormData,
        step_completed: "first",
        nextStage: "company",
      })
    );
  };

  return (
    <View height="100%" width="100%" align="center" padding={[0, xl]} justify="center">
      <View as="form" gap={lg} width="100%" maxWidth="50rem">
        {registrationError && registrationError.message && (
          <View
            padding={sm}
            align="center"
            justify="center"
            backgroundColor="critical-faded"
            borderRadius="small"
          >
            {registrationError.isExist ? (
              <Text align="center" color="critical" variant="body-1">
                <>
                  {`${t(
                    "A company account already exists with this email domain. Please reach out to your admin "
                  )}`}
                  <strong>{registrationError.message}</strong>
                  {`${t(" to invite you.")}`}
                </>
              </Text>
            ) : (
              <ShowSupportError error={registrationError.message} />
            )}
          </View>
        )}
        <Titles title={title} subTitle={subTitle} />
        <View gap={sm} direction="row">
          <View.Item grow={true}>
            <FormControl
              required
              hasError={error["firstName"] && typeof error["firstName"] !== "object"}
            >
              <FormControl.Label>{t("First Name")}</FormControl.Label>
              <TextField
                value={firstName}
                onChange={handleFirstName}
                inputAttributes={{
                  type: "text",
                  onBlur: handleFirstNameValidation,
                  autoFocus: true,
                  "data-cooper": "register_firstname",
                }}
              />
              <FormControl.Error>{error["firstName"]}</FormControl.Error>
            </FormControl>
          </View.Item>
          <View.Item grow={true}>
            <FormControl
              required
              hasError={error["lastName"] && typeof error["lastName"] !== "object"}
            >
              <FormControl.Label>{t("Last Name")}</FormControl.Label>
              <TextField
                value={lastName}
                onChange={handleLastName}
                inputAttributes={{
                  type: "text",
                  onBlur: handleLastNameValidation,
                  "data-cooper": "register_lastname",
                }}
              />
              <FormControl.Error>{error["lastName"]}</FormControl.Error>
            </FormControl>
          </View.Item>
        </View>
        <View gap={xs}>
          <FormControl required hasError={error["email"] && typeof error["email"] !== "object"}>
            <FormControl.Label>{t("Email")}</FormControl.Label>
            <TextField
              value={email}
              onChange={handleEmail}
              inputAttributes={{
                type: "email",
                onBlur: handleEmailValidation,
                "data-cooper": "register_email",
              }}
            />
            <FormControl.Error>{error["email"]}</FormControl.Error>
          </FormControl>
          {APIError && <ShowSupportError error={APIError} />}
        </View>
        <FormControl required hasError={error["password"] && typeof error["password"] !== "object"}>
          <FormControl.Label>{t("Password")}</FormControl.Label>
          <View className={styles.password__input}>
            <TextField
              value={password}
              onChange={handlePassword}
              inputAttributes={{
                type: showPassword ? "text" : "password",
                onBlur: handlePasswordValidation,
                autoComplete: "on",
                "data-cooper": "register_password",
              }}
            />
            <Button
              className={`${styles.show__password__btn} ${showPassword ? styles.active : ""}`}
              variant="ghost"
              startIcon={Eye}
              onClick={handleShowPassword}
              attributes={{ tabIndex: -1 }}
            />
            {showPasswordHelper && <PasswordProgress passwordProgress={passwordProgress} />}
          </View>
          <FormControl.Error>{error["password"]}</FormControl.Error>
        </FormControl>
        <View direction="row" align="start" gap={sm} as="label">
          <Checkbox
            name="agree-terms"
            hasError={error["agreeTerms"] && typeof error["agreeTerms"] !== "object" ? true : false}
            onChange={handleAgreeTerms}
            checked={agreedTerms}
            attributes={{ "data-cooper": "register_checkbox" }}
          />
          <View.Item grow>
            <Text variant="body-medium-2">
              {t("I agree to the Cooperhire")}
              <Link
                variant="plain"
                href="/terms-and-conditions"
                attributes={{
                  target: "_blank",
                }}
              >
                {" "}
                {t("Terms & Conditions")}{" "}
              </Link>
              {t("and the")}
              <Link
                variant="plain"
                href="/data-processing-agreements"
                attributes={{
                  target: "_blank",
                }}
              >
                {" "}
                {t("Data Processing Agreements")}{" "}
              </Link>
              {t("and have read the")}
              <Link
                variant="plain"
                href="/data-privacy-policy"
                attributes={{
                  target: "_blank",
                }}
              >
                {" "}
                {t("Data Privacy Policy")}{" "}
              </Link>
            </Text>
          </View.Item>
        </View>
        <Button
          onClick={handleSubmit}
          loading={isLoading}
          fullWidth
          attributes={{ type: "submit", "data-cooper": "register_submit" }}
          color="primary"
        >
          {t("Create recruiter account")}
        </Button>
        <Text variant="body-medium-2" align="center">
          {t("Already have a recruiter account?")}{" "}
          <Link variant="plain" href="/login">
            {t("Log in")}{" "}
          </Link>
        </Text>
      </View>
    </View>
  );
};
