import { Button, View } from "reshaped";

import { Plus } from "icons";
import { cooperColors } from "utils/colors/accents";
import useSpacing from "components/hooks/useSpacing";
import useTranslation from "components/hooks/useTranslation";
import EvaluationOverview from "components/pages/candidates/categories/overview/EvaluationOverview";

const EvaluationsOverview = (props) => {
  const { setAddEvaluation, setSelectedCategory, evaluations } = props;

  const { t } = useTranslation();
  const { xs, sm, md, lg } = useSpacing();

  const handleAddEvaluation = () => {
    setSelectedCategory("evaluation");
    setAddEvaluation(true);
  };

  const handleSelectedCategory = () => {
    setSelectedCategory("evaluation");
  };

  return (
    <View>
      <View direction="row" padding={[lg + xs, 0]} gap={lg + sm}>
        {Array.isArray(evaluations) && evaluations.length < 5 && (
          <>
            {evaluations.map((evaluation) => {
              return (
                <View.Item columns={6} key={evaluation.id}>
                  <EvaluationOverview evaluation={evaluation} />
                </View.Item>
              );
            })}
          </>
        )}
        {Array.isArray(evaluations) && evaluations.length >= 5 && (
          <>
            {[evaluations[0], evaluations[1], evaluations[2], evaluations[3]].map((evaluation) => {
              return (
                <View.Item columns={6} key={evaluation.id}>
                  <EvaluationOverview evaluation={evaluation} />
                </View.Item>
              );
            })}
          </>
        )}
      </View>
      <View gap={md} direction="row" justify="end" align="center">
        <Button
          size="small"
          color="primary"
          variant="ghost"
          startIcon={() => <Plus fill={cooperColors["foreground-primary"]} />}
          onClick={handleAddEvaluation}
        >
          {t("Add Evaluation")}
        </Button>
        <Button size="small" color="primary" variant="ghost" onClick={handleSelectedCategory}>
          {t("View All")}
        </Button>
      </View>
    </View>
  );
};

export default EvaluationsOverview;
