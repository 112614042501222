import { backendAPI } from "utils/axios";
import {
  areKeysAvailableWithType,
} from "utils/miniHelpers";

export const errorResponseCodesWithRetry = [
  "400",
  "403",
  "421",
  "450",
  "451",
  "452",
  "500",
  "550",
  "551",
  "552",
  "553",
  "554",
  "Other",
];
export const errorResponseCodesWithNoErrorMessage = ["451"];


export async function fetchFilteredJobs({
  company_id,
  selectedStatuses
}) {
  const errorResponse = { hasError: true };

  if (!company_id || typeof company_id !== "number") return errorResponse;

  try {
    const response = await backendAPI.get(`/jobs?status=${selectedStatuses.join(",")}`);
    if (
      areKeysAvailableWithType({
        object: response,
        keys: [{ status: 200, exact: true }, { data: "array" }],
      })
    ) {
      return {
        data: {
          jobs: response.data
        },
      };
    }
    return errorResponse;
  } catch (error) {
    if (
      areKeysAvailableWithType({ object: error.response, keys: [{ data: "object" }] }) &&
      areKeysAvailableWithType({
        object: error.response.data,
        keys: [{ error: "No job found", exact: true }],
      })
    ) {
      return { data: { jobs: [] } };
    }
    return errorResponse;
  }
}

