import { useMemo } from "react";
import { Divider, View } from "reshaped";
import useSpacing from "components/hooks/useSpacing";
import { TabButton } from "components/reusables/layouts";

export const SettingsNav = ({ currentTab, handleChange }) => {
  const { sm } = useSpacing();

  const tabs = useMemo(() => {
    return [
      { title: "Company", value: "company" },
      { title: "Team", value: "team" },
      { title: "User Profile", value: "user profile" },
      { title: "Subscriptions", value: "subscriptions" },
      { title: "Message Templates", value: "templates" },
    ];

    //eslint-disable-next-line
  }, []);

  const handleTabChange = ({ value }) => {
    handleChange({ value });
  };

  return (
    <View gap={sm}>
      <View direction="row" align="center" justify="start">
        {tabs.map((tab) => {
          return (
            <TabButton
              key={tab.value}
              title={tab.title}
              value={tab.value}
              currentActiveTab={currentTab}
              handler={handleTabChange}
            />
          );
        })}
      </View>
      <Divider
        attributes={{
          style: {
            backgroundColor: "var(--rs-color-border-neutral)",
          },
        }}
      />
    </View>
  );
};
