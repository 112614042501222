import React, { useMemo } from "react";
import { Button, DropdownMenu, FormControl, View } from "reshaped";

import { ArrowDown } from "icons";
import { useSelector } from "react-redux";
import useTranslation from "components/hooks/useTranslation";
import { stepOneProgressHelper } from "utils/jobStepProgress";
import styles from "components/styles/reshaped/jobs/new.module.css";

const EmploymentContractDropdown = (props) => {
  const { propTitle, setStepOneProgress, employmentContract, setEmploymentContract } = props;

  const { t } = useTranslation();
  const auth = useSelector((state) => state.auth);
  const employmentContractOptions = useMemo(() => {
    return [
      { value: "Full-Time (Permanent)" },
      { value: "Full-Time (Temporary)" },
      { value: "Part-Time (Permanent)" },
      { value: "Part-Time (Temporary)" },
    ];

    //eslint-disable-next-line
  }, []);

  const handleEmploymentContract = ({ selectedOption }) => {
    const { value } = selectedOption;
    setEmploymentContract(value);
    stepOneProgressHelper(propTitle, setStepOneProgress, "add");
  };

  const translations = useMemo(() => {
    const employmentContractTranslations = employmentContractOptions.reduce(
      (acc, curr) => ({ ...acc, [curr.value]: t(curr.value) }),
      {}
    );
    return {
      "Employment Contract": t("Employment Contract"),
      "Employment contract": t("Employment contract"),
      ...employmentContractTranslations,
    };

    //eslint-disable-next-line
  }, [auth && auth?.user ? [auth.user] : []]);

  return (
    <View.Item grow={true} className={styles.dropdown__wrapper}>
      <FormControl>
        <FormControl.Label>{translations["Employment contract"]}</FormControl.Label>
        <DropdownMenu position="bottom-start">
          <DropdownMenu.Trigger>
            {(attributes) => (
              <Button
                className={styles.dropdown__button}
                variant="outline"
                fullWidth
                attributes={attributes}
                endIcon={ArrowDown}
              >
                {employmentContract
                  ? translations[employmentContract]
                  : translations["Employment contract"]}
              </Button>
            )}
          </DropdownMenu.Trigger>
          <DropdownMenu.Content>
            {employmentContractOptions.map((option, index) => {
              return (
                <DropdownMenu.Item
                  onClick={() => handleEmploymentContract({ selectedOption: option })}
                  key={index}
                >
                  {translations[option.value]}
                </DropdownMenu.Item>
              );
            })}
          </DropdownMenu.Content>
        </DropdownMenu>
      </FormControl>
    </View.Item>
  );
};

export default React.memo(EmploymentContractDropdown);
