import { userAuth } from "utils/constants";
import { clearAuthToken } from "utils/setAuthToken";
import { areKeysAvailableIn, areKeysAvailableWithType } from "utils/miniHelpers";

export function removeCookie({ cookieName = "" }) {
  if (!cookieName || typeof cookieName !== "string") return;
  document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}

export function logoutUser() {
  removeCookie({ cookieName: userAuth });
  clearAuthToken();
}

export function reloadOnUnauthorized(error) {
  const { response } = error;

  if (areKeysAvailableIn({ object: response, keys: ["status"] })) {
    if (areKeysAvailableWithType({ object: response, keys: [{ status: 401, exact: true }] })) {
      logoutUser();
      window.location.href = "/login";
    }
    if (
      areKeysAvailableWithType({
        object: response,
        keys: [{ data: "object" }, { status: 403, exact: true }],
      })
    ) {
      if (
        areKeysAvailableWithType({
          object: response.data,
          keys: [
            {
              error:
                "Your account is currently under review. If you have any questions, you can contact hello@cooperhire.io.",
              exact: true,
            },
          ],
        })
      ) {
        logoutUser();
        window.location.href = "/login";
      }
    }
  }
}
