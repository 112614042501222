import { useMemo } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { Editor } from "react-draft-wysiwyg";
import useWebkit from "components/hooks/useWebkit";
import common from "components/styles/reshaped/reusables/common.module.css";

export const TextEditor = (props) => {
  const {
    state,
    setState,
    placeholder = "",
    toolbarHidden = false,
    wrapperClassName = "",
    toolbarClassName = "",
    editorClassName = "",
    handleChange,
    autoFocus = false,
  } = props;
  const { isWebkit } = useWebkit();

  const toolbarOptions = useMemo(() => {
    return {
      options: ["inline", "list", "history"],
      inline: {
        options: ["bold", "italic", "underline"],
        bold: {
          className: "toolbar__icon",
        },
        italic: {
          className: "toolbar__icon",
        },
        underline: {
          className: "toolbar__icon",
        },
      },
      list: {
        options: ["unordered", "ordered"],
        unordered: {
          className: "toolbar__icon",
        },
        ordered: {
          className: "toolbar__icon",
        },
      },
      history: {
        options: ["undo", "redo"],
        undo: {
          className: "toolbar__icon",
        },
        redo: {
          className: "toolbar__icon",
        },
      },
    };

    //eslint-disable-next-line
  }, []);

  const handleEditorStateChange = (state) => {
    if (handleChange) handleChange({ state });
    setState(state);
  };

  const setEditorReference = (ref) => {
    if (autoFocus && ref && ref instanceof HTMLElement && ref.nodeType === 1) {
      ref.focus();
    }
  };

  return (
    <Editor
      toolbarHidden={toolbarHidden}
      editorState={state}
      onEditorStateChange={handleEditorStateChange}
      toolbarClassName={`${common.toolbar} ${toolbarClassName}`}
      wrapperClassName={`${common.editor__wrapper} ${wrapperClassName}`}
      editorClassName={`${isWebkit ? "webkit" : "non__webkit"} ${
        common.text__editor
      }  ${editorClassName} new_class_name1`}
      placeholder={placeholder}
      toolbar={toolbarOptions}
      editorRef={setEditorReference}
    />
  );
};
