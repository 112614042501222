import React from "react";
import { Button, Link, Text, View } from "reshaped";

import { Close } from "icons";
import { isObject } from "utils/miniHelpers";
import { baseColor } from "utils/colors/accents";

export function ShowToast({ toast, stay = false, time = 5000, position = "top-end", Children }) {
  if (!stay) {
    const id = toast.show({
      position,
      children: <Children />,
    });
    return setTimeout(() => {
      toast.hide(id);
    }, time);
  }
  const id = toast.show({
    children: <Children />,
    timeout: 0,
    position,
  });
  return id;
}

export function ShowToastWithTranslation({
  toast,
  stay = false,
  time = 5000,
  position = "top-end",
  Children,
  text,
}) {
  if (!stay) {
    const id = toast.show({
      position,
      children: <Children>{text}</Children>,
    });
    return setTimeout(() => {
      toast.hide(id);
    }, time);
  }
  const id = toast.show({
    children: <Children>{text}</Children>,
    timeout: 0,
    position,
  });
  return id;
}

export function ShowToastForFailedConversation({
  toast,
  position = "top-end",
  locale,
  candidate_id,
}) {
  let sentence = [
    "Oh no! Your message didn't send. ",
    ...(candidate_id || typeof candidate_id === "string"
      ? [{ text: "View", link: `/candidates?id=${candidate_id}&view=messages` }, " Conversation."]
      : []),
  ];
  if (locale === "de") {
    sentence = [
      "Oh nein! Ihre Nachricht konnte nicht gesendet werden.",
      ...(candidate_id || typeof candidate_id === "string"
        ? [
            " Nachricht ",
            { text: "ansehen", link: `/candidates?id=${candidate_id}&view=messages` },
            ".",
          ]
        : []),
    ];
  }
  const id = toast.show({
    children: (
      <View attributes={{ style: { position: "relative" } }} padding={[2, 0]}>
        <Button
          variant="ghost"
          size="small"
          onClick={() => toast.hide(id)}
          attributes={{
            style: {
              position: "absolute",
              top: "-12px",
              right: "-12px",
            },
          }}
        >
          <View align="center" justify="center">
            <Close color={baseColor.critical} />
          </View>
        </Button>
        <Text
          color="critical"
          variant="body-strong-2"
          attributes={{ style: { maxWidth: "270px" } }}
        >
          {sentence.map((each, index) => {
            if (isObject(each)) {
              return (
                <Link key={index} color="critical" href={each.link}>
                  {each.text}
                </Link>
              );
            }
            return <React.Fragment key={index}>{each}</React.Fragment>;
          })}
        </Text>
      </View>
    ),
    timeout: 0,
    position,
  });
  return id;
}
