import { Text, View } from "reshaped";
import useTranslation from "components/hooks/useTranslation";

const Titles = (props) => {
  const { title, subTitle } = props;
  const { t } = useTranslation();
  return (
    <>
      <View gap={1}>
        <Text align="center" variant="title-2" as="h1">
          {t(title)}
        </Text>
        <Text color="neutral-faded" align="center" variant="body-medium-1" as="p">
          {t(subTitle)}{" "}
        </Text>
      </View>
    </>
  );
};

export default Titles;
