import moment from "moment";
import { backendAPI } from "utils/axios";
import { reloadOnUnauthorized } from "utils/checkForAuth";
import {
  PlanLimits,
  idsAndPlans,
  intervalToNumber,
  currenciesAndSymbols,
  countriesAndCurrencies,
} from "utils/constants";
import {
  isNumber,
  isObject,
  sanitizeArray,
  areKeysAvailableIn,
  areKeysAvailableWithType,
} from "utils/miniHelpers";

export async function getTemplates() {
  const errorResult = {
    error: "Unable to retrieve message templates at this time. Please try again later",
  };
  try {
    const response = await backendAPI.get("/conversation_templates");
    if (areKeysAvailableWithType({ object: response, keys: [{ data: "array" }] })) {
      const sanitizedTemplates = sanitizeArray({
        array: response.data,
        elementType: "object",
        keys: ["id", "name", "subject", "message", "trigger", "is_default_trigger"],
      });
      return {
        data: sanitizedTemplates,
      };
    }
    return errorResult;
  } catch (error) {
    reloadOnUnauthorized(error);
    return errorResult;
  }
}

export async function handleTemplateAPI({ data, status = "create" }) {
  const errorResult = {
    error: "Operation unsuccessful. Please retry.",
  };

  if (!data || !isObject(data)) {
    return errorResult;
  }

  try {
    let response = errorResult;

    if (
      status === "update" &&
      areKeysAvailableWithType({ object: data, keys: [{ id: "number" }] })
    ) {
      const sanitizedData = {
        ...data,
      };
      delete sanitizedData["id"];
      response = await backendAPI.put(`/conversation_templates/${data.id}`, {
        conversation_template: sanitizedData,
      });
    }

    if (status === "create") {
      response = await backendAPI.post("/conversation_templates", {
        conversation_template: data,
      });
    }

    if (
      areKeysAvailableIn({ object: response, keys: ["data"] }) &&
      areKeysAvailableIn({ object: response.data, keys: ["conversation_template"] }) &&
      areKeysAvailableIn({
        object: response.data.conversation_template,
        keys: ["id", "message", "name", "subject", "trigger", "is_default_trigger"],
      })
    ) {
      return {
        data: response.data.conversation_template,
      };
    }

    return errorResult;
  } catch (error) {
    reloadOnUnauthorized(error);
    return errorResult;
  }
}

export async function handleTemplateDeleteAPI({ id }) {
  const errorResult = {
    error: "Operation unsuccessful. Please retry.",
  };

  if (!isNumber(id)) {
    return errorResult;
  }

  try {
    let response = await backendAPI.delete(`/conversation_templates/${id}`);

    if (
      areKeysAvailableIn({ object: response, keys: ["data"] }) &&
      areKeysAvailableWithType({
        object: response.data,
        keys: [{ status: 200, exact: true }, { message: "string" }],
      })
    ) {
      return {
        data: response.data,
      };
    }

    return {
      errorResult,
    };
  } catch (error) {
    reloadOnUnauthorized(error);
    return errorResult;
  }
}

export async function getPlaceholderFromAPI() {
  const errorResult = {
    error: "Unable to retrieve placeholders.",
  };
  try {
    const response = await backendAPI.get("/conversation_templates/placeholders");
    if (
      response.data &&
      areKeysAvailableWithType({ object: response.data, keys: [{ placeholders: "array" }] })
    ) {
      const sanitizedData = sanitizeArray({
        array: response.data.placeholders,
        elementType: "object",
        keys: [{ name: "string" }, { key: "string" }],
        hardSanitization: true,
      });
      return {
        data: sanitizedData,
      };
    }
    return errorResult;
  } catch (error) {
    reloadOnUnauthorized(error);
    return errorResult;
  }
}

export const Capitalize = (str) => {
  if (typeof str !== "string") return "";
  if (str.length === 0) return "";
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getPlanByPriceId = (price_id, subscriptionPrices) => {
  if (!subscriptionPrices || !subscriptionPrices.length) return null;
  return subscriptionPrices.find((plan) =>
    Object.values(plan.pricing).some((currency) =>
      Object.values(currency).some((interval) => interval.price_id === price_id)
    )
  );
};

export const getPlanDetails = (price_id, subscriptionPrices) => {
  const plan = getPlanByPriceId(price_id, subscriptionPrices);
  const currencyKeys = Object.keys(plan.pricing);
  const currency = currencyKeys.find((currency) =>
    Object.values(plan.pricing[currency]).some((interval) => interval.price_id === price_id)
  );

  const planData = plan.pricing[currency];
  const interval = Object.keys(planData).find(
    (interval) => planData[interval].price_id === price_id
  );

  let priceString = planData[interval].discounted_price;
  let price = priceString;
  let usersLimit = plan.users_limit;
  let jobsLimit = plan.jobs_limit;
  let currencySymbol = currenciesAndSymbols[currency];

  return {
    price,
    priceString,
    currency,
    currencySymbol,
    interval,
    usersLimit,
    jobsLimit,
  };
};

export const isCanceledAndPastDue = (subscription) => {
  const endedAtTimestamp = new Date(subscription.current_period_end_at).getTime();
  const isCanceledAndPastDue = subscription.status === "canceled" && endedAtTimestamp < Date.now();

  return isCanceledAndPastDue;
};

export const formatDate = (dateStr, monthFormat = "short") => {
  const dateObj = new Date(dateStr);

  const options = { year: "numeric", month: monthFormat, day: "numeric" };
  const locale = navigator.language || "en-US";
  const formattedDate = dateObj.toLocaleDateString(locale, options);
  return formattedDate;
};

export const formatCurrency = (amount, currency = "EUR", country = "de") => {
  const locale = country || navigator.language || "en-US";
  const formattedCurrency = new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
  }).format(amount);

  return formattedCurrency;
};

export const calculateProration = (
  currentSubStartDate,
  currentSubNextInvoice,
  currentPlanPrice,
  newPlanPrice
) => {
  const totalDays = moment(currentSubNextInvoice).diff(moment(currentSubStartDate), "days");
  const usedDays = moment.utc().diff(moment.utc(currentSubStartDate), "days");

  const proratedCredit = (1 - usedDays / totalDays) * currentPlanPrice;
  const newCharge = newPlanPrice - proratedCredit;

  return {
    proratedCredit,
    newCharge,
  };
};

export const exceededPlanLimits = (subscription) => {
  return (
    subscription.jobs_count > subscription.max_jobs ||
    subscription.users_count > subscription.max_users ||
    subscription.message_templates_count > subscription.max_message_templates
  );
};

export const freePlanLimits = (subscriptionPrices = []) => {
  const defaultUsersLimit = PlanLimits.free.users;
  const defaultJobsLimit = PlanLimits.free.jobs;
  const defaultMessageTemplates = PlanLimits.free.messageTemplates;

  const freePlan = subscriptionPrices
    ? subscriptionPrices.find((plan) => plan.title === "Free")
    : null;
  const users_limit = freePlan ? freePlan.users_limit : defaultUsersLimit;
  const jobs_limit = freePlan ? freePlan.jobs_limit : defaultJobsLimit;
  const total_message_templates = freePlan
    ? freePlan.total_message_templates
    : defaultMessageTemplates;

  return { users_limit, jobs_limit, total_message_templates };
};

export const exceededJobsLimit = (subscription, jobsLimit = null) => {
  if (!subscription.subscription_type) return false;
  jobsLimit = subscription.max_jobs || jobsLimit;
  return subscription.jobs_count >= jobsLimit;
};

export const exceededUsersLimit = (subscription, usersLimit = null) => {
  if (!subscription.subscription_type) return false;

  usersLimit = subscription.max_users || usersLimit;
  return subscription.users_count >= usersLimit;
};

export const exceededMessageTemplatesLimit = (subscription, templatesLimit = null) => {
  if (!subscription.subscription_type) return false;

  templatesLimit = subscription.max_message_templates || templatesLimit;
  return subscription.message_templates_count >= templatesLimit;
};

export const isCronjobDowngradeTriggered = (subscription) => {
  const subscription_ends_at = moment.utc(
    subscription.current_period_end_at + " 00:00",
    "YYYY-MM-DD HH:mm"
  );
  const nowUTC = moment.utc();
  const differenceInMinutes = subscription_ends_at.diff(nowUTC, "minutes");
  const isWithin30Minutes = Math.abs(differenceInMinutes) <= 30;

  return (
    (subscription.status === "subscribed" || subscription.status === "cancelled") &&
    subscription.scheduled_for_downgrade === true &&
    isWithin30Minutes
  );
};

export const getPlanChangeSummary = (
  currentSubscription,
  newPlan,
  newPlanType,
  country,
  subscriptionPrices
) => {
  if (!currentSubscription.status || !newPlan || !newPlanType || !country)
    return {
      changeType: null,
      currencySymbol: null,
      proratedCredit: null,
      newCharge: null,
      currency: null,
      new_plan_id: null,
      new_plan_name: null,
      new_price_id: null,
      new_plan_price: null,
      new_interval: null,
      new_plan_type: null,
      old_plan_id: null,
      old_plan_name: null,
      old_price_id: null,
      old_interval: null,
      old_plan_price: null,
      next_invoice: null,
      planLimitsChangeType: null,
      maxJobs: null,
      maxUsers: null,
      currentUsersCount: null,
      currentJobsCount: null,
    };

  const planCurrency = currentSubscription.currency || countriesAndCurrencies[country].code;
  const planCurrencySymbol =
    currenciesAndSymbols[currentSubscription.currency] || countriesAndCurrencies[country].symbol;
  const new_plan_id = newPlan.pricing[planCurrency][newPlanType].plan_id;
  const new_price_id = newPlan.pricing[planCurrency][newPlanType].price_id;

  const currentSubStartDate = currentSubscription.current_period_start_at;
  const currentSubNextInvoiceDate = currentSubscription.next_invoice;

  let changeType = null;
  if (intervalToNumber[currentSubscription.interval] > intervalToNumber[newPlanType]) {
    changeType = "downgrade";
  } else if (intervalToNumber[currentSubscription.interval] < intervalToNumber[newPlanType]) {
    changeType = "upgrade";
  } else if (currentSubscription.plan_id > new_plan_id) {
    changeType = "downgrade";
  } else if (currentSubscription.plan_id < new_plan_id) {
    changeType = "upgrade";
  }

  let planLimitsChangeType = null;
  if (
    idsAndPlans[currentSubscription.subscription_type] > idsAndPlans[newPlan.title.toLowerCase()]
  ) {
    planLimitsChangeType = "downgrade";
  } else if (
    idsAndPlans[currentSubscription.subscription_type] < idsAndPlans[newPlan.title.toLowerCase()]
  ) {
    planLimitsChangeType = "upgrade";
  } else if (
    idsAndPlans[currentSubscription.subscription_type] ===
    idsAndPlans[newPlan.title.toLowerCase()] &&
    intervalToNumber[currentSubscription.interval] > intervalToNumber[newPlanType]
  ) {
    planLimitsChangeType = "downgrade";
  } else if (
    idsAndPlans[currentSubscription.subscription_type] ===
    idsAndPlans[newPlan.title.toLowerCase()] &&
    intervalToNumber[currentSubscription.interval] < intervalToNumber[newPlanType]
  ) {
    planLimitsChangeType = "upgrade";
  } else {
    planLimitsChangeType = "none";
  }

  const currentPlanDetails = getPlanDetails(
    currentSubscription.stripe_price_id,
    subscriptionPrices
  );
  const newPlanDetails = getPlanDetails(new_price_id, subscriptionPrices);
  const { proratedCredit, newCharge } = calculateProration(
    currentSubStartDate,
    currentSubNextInvoiceDate,
    currentPlanDetails.price,
    newPlanDetails.price
  );

  return {
    changeType,
    currencySymbol: planCurrencySymbol,
    proratedCredit,
    newCharge,
    currency: currentSubscription.currency,
    new_plan_id: new_plan_id,
    new_plan_name: newPlan.title,
    new_price_id: new_price_id,
    new_plan_price: newPlanDetails.price,
    new_interval: newPlanDetails.interval,
    new_plan_type: newPlanType,
    old_plan_id: currentSubscription.plan_id,
    old_plan_name: currentSubscription.subscription_type,
    old_price_id: currentSubscription.stripe_price_id,
    old_interval: currentSubscription.interval,
    old_plan_price: currentPlanDetails.price,
    next_invoice: currentSubscription.next_invoice,
    planLimitsChangeType,
    maxJobs: currentSubscription.max_jobs,
    maxUsers: currentSubscription.max_users,
    currentUsersCount: currentSubscription.users_count,
    currentJobsCount: currentSubscription.jobs_count,
  };
};

export const planFeaturesList = (plan_name) => {
  const freePlanFeatures = [
    "Free Multiposting",
    "Basic Customer Support",
    "Normal application speed",
    `{{jobs_limit}} Total Jobs`,
    `{{users_limit}} User Account`,
  ];
  const growthPlanFeatures = [
    "Premium Multiposting",
    "All your jobs on Kleinanzeigen",
    "Fast (zappy) Application Speed",
    `{{jobs_limit}} Total Jobs`,
    `{{users_limit}} User Accounts`,
  ];
  const premiumPlanFeatures = [
    "Everything in growth tier",
    `{{jobs_limit}} Active Jobs (upgradable)`,
    `{{users_limit}} User Accounts (upgradable)`,
    "Dedicated Account Manager",
    "Dedicated Customer Support Manager",
    "Access to Beta Features",
    "Migration Support",
  ];

  switch (plan_name) {
    case "Free":
      return freePlanFeatures;
    case "Growth":
      return growthPlanFeatures;
    case "Premium":
      return premiumPlanFeatures;
    default:
      return [];
  }
};
