import { View } from "reshaped";
import { useSelector } from "react-redux";
import useSpacing from "components/hooks/useSpacing";
import { ProfileStats, ApplicantsStats } from "components/pages/dashboard";

export const DashboardStats = () => {
  const { lg } = useSpacing();

  const { total } = useSelector((state) => state.dashboard);

  return (
    <View direction="row" gap={lg} maxWidth="1200px">
      <View.Item columns={9}>{total && <ApplicantsStats total={total} />}</View.Item>
      <View.Item columns={3}>{total && <ProfileStats total={total} />}</View.Item>
    </View>
  );
};
