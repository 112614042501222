import { View } from "reshaped";
import useSpacing from "components/hooks/useSpacing";
import { DashboardMetaStats, DashboardInboxOverview } from "components/pages/dashboard";

export const ApplicantsStats = (props) => {
  const { total } = props;
  const { lg } = useSpacing();

  return (
    <View gap={lg}>
      <DashboardInboxOverview count={total?.weekly_candidates} />
      <DashboardMetaStats jobs={total?.jobs} candidates={total?.candidates} hired={total?.hired} />
    </View>
  );
};
