import { useRef, useMemo } from "react";
import { Button, View } from "reshaped";

import { Plus } from "icons";
import useSpacing from "components/hooks/useSpacing";
import { accent, cooperColors } from "utils/colors/accents";
import useTranslation from "components/hooks/useTranslation";
import Note from "components/pages/candidates/categories/notes/Note";

const Notes = (props) => {
  const { setAddNote, notes, setEditNoteID } = props;

  const { t } = useTranslation();
  const sequenceNumberRef = useRef(1);
  const { xs, sm, lg, xxl } = useSpacing();

  const notesColors = useMemo(() => {
    return {
      1: {
        bar: "#1f74EA",
        background: "#E7F0FD",
      },
      2: {
        bar: accent["3-400"],
        background: accent["3-100"],
      },
      3: {
        bar: accent["4-400"],
        background: accent["4-100"],
      },
    };
  }, []);

  const handleAddNotes = () => {
    setAddNote(true);
  };

  return (
    <View gap={xxl}>
      <View padding={[lg + xs, 0]} gap={lg + sm}>
        {notes.map((note, index, array) => {
          let sequence;
          if (sequenceNumberRef.current <= 2) {
            sequence = sequenceNumberRef.current;
            sequenceNumberRef.current = sequenceNumberRef.current + 1;
          } else {
            sequence = 3;
            sequenceNumberRef.current = 1;
          }
          if (array.length === index + 1) {
            sequenceNumberRef.current = 1;
          }
          return (
            <Note
              key={note.id}
              data={note}
              updated_at={note.updated_at}
              noteColor={notesColors[sequence]}
              maxHeight="auto"
              showFullName
              setEditNoteID={setEditNoteID}
            />
          );
        })}
      </View>
      <View direction="row" justify="end" align="center">
        <Button
          size="small"
          color="primary"
          variant="ghost"
          startIcon={() => <Plus fill={cooperColors["foreground-primary"]} />}
          onClick={handleAddNotes}
        >
          {t("Add Note")}
        </Button>
      </View>
    </View>
  );
};

export default Notes;
