import { useEffect, useState, useMemo } from "react";
import { Text } from "reshaped";

export const Counter = ({ minutes = 2, isCounting = true, setIsCounting }) => {
  const [count, setCount] = useState(0);
  const [timeout, setTimeout] = useState(false);
  const [counter, setCounter] = useState({ minutes, seconds: 0 });

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prev) => prev + 1);
    }, 1000);
    if (timeout) {
      setIsCounting(false);
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };

    //eslint-disable-next-line
  }, [isCounting, count]);

  const isTimeLeft = useMemo(() => {
    if (!isCounting) return false;
    if (counter.seconds > 0) {
      setCounter((prevCounter) => {
        return {
          ...prevCounter,
          seconds: prevCounter.seconds - 1,
        };
      });
      return true;
    }
    if (counter.seconds === 0) {
      if (counter.minutes === 0) {
        setTimeout(true);
        return false;
      } else {
        setCounter((prevCounter) => {
          return {
            ...prevCounter,
            seconds: 59,
            minutes: prevCounter.minutes - 1,
          };
        });
        return true;
      }
    }
    return false;

    //eslint-disable-next-line
  }, [count]);

  return (
    <>
      {setIsCounting && isTimeLeft && (
        <Text color="primary" variant="body-strong-2">
          {`${counter.minutes}`.padStart(2, 0)}:{`${counter.seconds}`.padStart(2, 0)}
        </Text>
      )}
    </>
  );
};
