import { DropdownMenu } from "reshaped";
import useTranslation from "components/hooks/useTranslation";

const DataManipulateDropdown = ({
  options = [],
  handleOptionSelection,
  position = "bottom-start",
  DisplayComponent,
  width = "150px",
  children,
  ...rest
}) => {
  const { t } = useTranslation();

  const handleDropdown = (selectedValue) => {
    options.forEach((option) => {
      if (option.value === selectedValue) {
        handleOptionSelection({ selectedOption: option, ...rest });
      }
    });
  };

  return (
    <DropdownMenu position={position} width={width}>
      <DropdownMenu.Trigger>
        {(attributes) => <DisplayComponent attributes={attributes} />}
      </DropdownMenu.Trigger>
      <DropdownMenu.Content>
        {options.map((option) => {
          return (
            <DropdownMenu.Item
              disabled={option?.disable}
              onClick={() => handleDropdown(option.value)}
              key={option.value}
            >
              {t(option.value)}
            </DropdownMenu.Item>
          );
        })}
      </DropdownMenu.Content>
    </DropdownMenu>
  );
};

export default DataManipulateDropdown;
