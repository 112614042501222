import { Route } from "react-router-dom";

export const GenerateNestedRoutes = ({ path, component, exact, nested }) => {
  return (
    <Route path={path} exact={exact} element={component}>
      {nested.map((childItem, index) => {
        return childItem.index ? (
          <Route index element={childItem.component} key={`${childItem.path}-${index}`} />
        ) : (
          <Route
            path={childItem.path}
            element={childItem.component}
            key={`${childItem.path}-${index}`}
          />
        );
      })}
    </Route>
  );
};
