import { createSlice } from "@reduxjs/toolkit";
import { areKeysAvailableIn, areKeysAvailableWithType } from "utils/miniHelpers";

const initialState = {
  data: null,
  loading: false,
  error: null,
};

export const placeholdersSlice = createSlice({
  name: "placeholders",
  initialState,
  reducers: {
    reset: (state) => {
      for (let key in initialState) {
        state[key] = initialState[key];
      }
    },
    setLoading: (state, action) => {
      if (!areKeysAvailableWithType({ object: action, keys: [{ payload: "boolean" }] })) return;
      state.loading = action.payload;
    },
    setData: (state, action) => {
      if (!areKeysAvailableIn({ object: action, keys: ["payload"] })) return;
      state.data = action.payload;
    },
    setError: (state, action) => {
      if (areKeysAvailableWithType({ object: action, keys: [{ payload: "string" }] })) {
        state.error = action.payload;
      } else {
        state.error = null;
      }
    },
    setProperties: (state, action) => {
      if (areKeysAvailableWithType({ object: action, keys: [{ payload: "object" }] })) {
        const { payload } = action;
        for (let k in payload) {
          if (initialState.hasOwnProperty(k)) {
            state[k] = payload[k];
          }
        }
      }
    },
  },
});
