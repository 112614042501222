import { Text, View } from "reshaped";
import { accent } from "utils/colors/accents";
import useSpacing from "components/hooks/useSpacing";
import useTranslation from "components/hooks/useTranslation";

export const DiscountPill = ({ text }) => {
  const { xs } = useSpacing();
  const { t } = useTranslation();

  return (
    <View
      height="20px"
      padding={[0, xs]}
      direction="row"
      align="center"
      justify="center"
      attributes={{
        style: {
          backgroundColor: accent["2-600"],
          borderRadius: "4px",
        },
      }}
    >
      <Text
        variant="caption-1"
        attributes={{
          style: {
            color: "var(--rs-color-white)",
          },
        }}
      >
        {text} {t("off")}
      </Text>
    </View>
  );
};
