import { useMemo } from "react";
import { Avatar, Button, Icon, Text, useToast, useToggle, View } from "reshaped";

import { Options } from "icons";
import { backendAPI } from "utils/axios";
import useName from "components/hooks/useName";
import useSpacing from "components/hooks/useSpacing";
import { useDispatch, useSelector } from "react-redux";
import useFullName from "components/hooks/useFullName";
import { TextWithEclipses } from "components/reusables";
import { reloadOnUnauthorized } from "utils/checkForAuth";
import { ShowToastWithTranslation } from "utils/showToast";
import useTranslation from "components/hooks/useTranslation";
import { viewingCandidateSlice } from "redux/viewingCandidate";
import styles from "components/styles/reshaped/candidates.module.css";
import ConfirmationModal from "components/reusables/modals/ConfirmationModal";
import { OperationFailedWithTranslation } from "components/reusables/OperationFailed";
import DataManipulateDropdown from "components/reusables/dropdowns/DataManipulateDropdown";
import { DatePill } from "components/reusables/DatePill";

const Note = (props) => {
  const { data, noteColor, maxHeight, showFullName = false, noManipulations = false, updated_at } = props;
  const { user, note } = data;
  const { first_name, last_name, id } = user;

  const toast = useToast();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { xs, sm, lg, xl } = useSpacing();
  const { active, activate, deactivate } = useToggle(false);
  const { setLoading, removeNote } = viewingCandidateSlice.actions;
  const { user: loggedInUser } = useSelector((state) => state.auth);
  const userProfile = useName({ first_name, last_name, info: data?.user });
  const { currentCandidate } = useSelector((state) => state.viewingCandidate);
  const fullName = useFullName({ first_name, last_name, dependency: [user] });

  const options = useMemo(() => {
    return [
      {
        value: "Edit",
        disable: loggedInUser.id !== id,
      },
      {
        value: "Delete",
        disable: loggedInUser.id !== id,
      },
    ];

    //eslint-disable-next-line
  }, []);

  const deleteNote = async () => {
    try {
      if (loggedInUser.id !== id) return;
      dispatch(setLoading());
      const response = await backendAPI.delete(
        `/candidates/${currentCandidate?.id}/notes/${data.id}`
      );
      const { data: responseData } = response;
      if (
        responseData?.status &&
        responseData.status === 200 &&
        responseData.message === "successfully deleted"
      ) {
        dispatch(removeNote({ id: data?.id, candidate_id: currentCandidate.id }));
        ShowToastWithTranslation({
          toast,
          Children: NoteDeleted,
          text: t("Note deleted!"),
        });
      } else {
        ShowToastWithTranslation({
          toast,
          Children: OperationFailedWithTranslation,
          text: t("Operation unsuccessful!"),
        });
      }
      dispatch(setLoading(false));
    } catch (error) {
      reloadOnUnauthorized(error);
      dispatch(setLoading(false));
      ShowToastWithTranslation({
        toast,
        Children: OperationFailedWithTranslation,
        text: t("Operation unsuccessful!"),
      });
      console.log(error);
    }
  };

  const handleNoteManipulation = ({ selectedOption }) => {
    if (selectedOption.value === "Edit") {
      props.setEditNoteID(data?.id);
    }
    if (selectedOption.value === "Delete") {
      activate();
    }
  };

  const handleNoteDelete = (action) => {
    if (action === "accept") {
      deleteNote();
    }
    deactivate();
  };
  return (
    <>
      <View
        gap={lg}
        padding={[xl, lg]}
        borderRadius="medium"
        attributes={{
          style: {
            background: `${noteColor.background}`,
            position: "relative",
          },
        }}
      >
        <span
          className={styles.note__bar}
          style={{
            backgroundColor: `${noteColor.bar}`,
          }}
        />
        {!noManipulations && (
          <View className={styles.dropdown}>
            <DataManipulateDropdown
              options={options}
              handleOptionSelection={handleNoteManipulation}
              DisplayComponent={DropdownFrontDisplay}
              position="bottom-end"
            />
          </View>
        )}
        <View direction="row" align="center" gap={sm}>
          <>
            {userProfile.hasURL && <Avatar size={xl + xs} src={userProfile.url} alt={fullName} />}
            {!userProfile.hasURL && (
              <View
                attributes={{
                  style: {
                    border: `1px solid ${noteColor.bar}`,
                    borderRadius: "50%",
                  },
                }}
              >
                <Avatar size={xl + xs} initials={userProfile.letters} alt={fullName} />
              </View>
            )}
          </>
          {showFullName && (
            <Text
              variant="body-strong-1"
              attributes={{
                style: {
                  textTransform: "capitalize",
                  color: "#0A3D8A",
                },
              }}
            >
              {fullName}
            </Text>
          )}
          {!showFullName && (
            <TextWithEclipses
              variant="body-strong-1"
              text={fullName}
              characterLimit={18}
              attributes={{
                style: {
                  textTransform: "capitalize",
                  color: "#0A3D8A",
                },
              }}
            />
          )}
        </View>
        {maxHeight && (
          <View className={styles.note}>
            <View
              className={"html__content"}
              attributes={{
                dangerouslySetInnerHTML: { __html: note },
              }}
            />
          </View>
        )}
        {!maxHeight && (
          <Text color="neutral-faded" variant="body-2" maxLines={6}>
            {note}
          </Text>
        )}
        <DatePill date={updated_at} />
      </View>
      <ConfirmationModal
        activate={activate}
        active={active}
        deactivate={deactivate}
        acceptText={"Delete"}
        title={t("Delete note?")}
        subTitle={t("Are you sure you want to delete note?")}
        handleAction={handleNoteDelete}
      />
    </>
  );
};

export default Note;

function DropdownFrontDisplay({ attributes }) {
  const { md } = useSpacing();

  return (
    <Button attributes={attributes} color="inherit" variant="ghost" size="small">
      <Icon size={md} svg={Options} />
    </Button>
  );
}

const NoteDeleted = ({ children }) => {
  return (
    <Text color="positive" variant="body-strong-1">
      {children}
    </Text>
  );
};
