import { useMemo } from "react";
import { Text, View } from "reshaped";
import { useSelector } from "react-redux";
import { NoMessages as NoMessage } from "icons";
import useSpacing from "components/hooks/useSpacing";
import { areKeysAvailableIn } from "utils/miniHelpers";
import useTranslation from "components/hooks/useTranslation";

export const NoMessages = () => {
  const { md } = useSpacing();
  const { locale, t } = useTranslation();
  const { conversation } = useSelector((state) => state);
  const { destination } = conversation;

  const NoMessagesText = useMemo(() => {
    if (
      areKeysAvailableIn({ object: destination, keys: ["full_name"] }) &&
      destination?.full_name
    ) {
      const sentence =
        locale === "de"
          ? `Noch keine Nachrichten mit ${destination?.full_name}`
          : `No messages with ${destination?.full_name} yet`;
      return sentence;
    }
    return t("No messages");

    //eslint-disable-next-line
  }, [destination]);

  return (
    <View justify="center" align="center" height="100%" gap={md}>
      <Text color="neutral-faded" variant="body-strong-1">
        {NoMessagesText}
      </Text>
      <NoMessage />
    </View>
  );
};
