import { useEffect } from "react";
import { Link, Text, View } from "reshaped";

import { JobsCard } from "components/pages/jobs";
import { fetchJobData, jobSlice } from "redux/job/jobSlice";
import useSpacing from "components/hooks/useSpacing";
import { ReshapedLoader } from "components/reusables";
import { useDispatch, useSelector } from "react-redux";
import { areKeysAvailableWithType } from "utils/miniHelpers";
import useTranslation from "components/hooks/useTranslation";
import { fetchFilteredJobs } from "utils/pages/job";

export const JobsCardGroup = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { md, xl, sm } = useSpacing();
  const state = useSelector((state) => state);
  const { user } = state.auth;
  const { jobs: jobsFilters } = state.filters;
  const { loading, error, jobs, isFiltering, filteringError, shouldJobsRefetch } = useSelector(
    (state) => state.job
  );

  const { statuses } = jobsFilters;
  const { selected: selectedStatuses } = statuses;

  useEffect(() => {
    if (!jobs) dispatch(fetchJobData());

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (shouldJobsRefetch) {
      async function refetch() {
        dispatch(
          jobSlice.actions.set({
            isFiltering: true,
            filteringError: null,
            shouldJobsRefetch: false,
          })
        );

        const response = await fetchFilteredJobs({
          company_id: user.company_id,
          selectedStatuses,
        });

        if (areKeysAvailableWithType({ object: response, keys: [{ data: "object" }] })) {
          const { jobs } = response.data;
          return dispatch(
            jobSlice.actions.set({
              isFiltering: false,
              jobs,
            })
          );
        }

        dispatch(
          jobSlice.actions.set({
            isFiltering: false,
            filteringError: "something went wrong!",
          })
        );
      }
      refetch();
    }
    //eslint-disable-next-line
  }, [shouldJobsRefetch]);

  const handleRetry = async () => {
    dispatch(
      jobSlice.actions.set({
        isFiltering: true,
        filteringError: null,
      })
    );

    const response = await fetchFilteredJobs({
      company_id: user.company_id,
      selectedStatuses,
    });

    if (areKeysAvailableWithType({ object: response, keys: [{ data: "object" }] })) {
      const { jobs } = response.data;
      return dispatch(
        jobSlice.actions.set({
          isFiltering: false,
          jobs,
        })
      );
    }

    dispatch(
      jobSlice.actions.set({
        isFiltering: false,
        filteringError: "something went wrong!",
      })
    );
  };

  return (
    <>
      {!filteringError && (
        <>
          {!loading && !error && !isFiltering && Array.isArray(jobs) && (
            <>
              {jobs.length > 0 ? (
                <View
                  as="div"
                  gap={xl + sm}
                  attributes={{
                    style: {
                      display: "grid",
                      gridTemplateColumns: "repeat(auto-fill, 320px)",
                    },
                  }}
                >
                  {jobs.map((job) => {
                    return <JobsCard key={job.id} job={job} />;
                  })}
                </View>
              ) : (
                <View.Item grow={true}>
                  <View align="center" justify="center" height="100%">
                    <Text variant="title-3">
                      {selectedStatuses && selectedStatuses.length
                        ? t("No job found!")
                        : t("No jobs created!")}
                    </Text>
                  </View>
                </View.Item>
              )}
            </>
          )}
          {(loading && !error) ||
            (isFiltering && (
              <View.Item grow={true}>
                <ReshapedLoader />
              </View.Item>
            ))}
        </>
      )}

      {filteringError && !isFiltering && (
        <View align="center" justify="center" height="100%" padding={[xl, 0]} gap={md}>
          <Text variant="title-3" color="critical">
            {filteringError}
          </Text>
          <View direction="row" align="center" justify="center" gap={md}>
            <Text color="critical">
              {t("Please refresh the page or")}{" "}
              <Link color="critical" onClick={handleRetry}>
                {t("Please try again.")}
              </Link>{" "}
            </Text>
          </View>
        </View>
      )}

      {loading && !error && (
        <View.Item grow={true}>
          <ReshapedLoader />
        </View.Item>
      )}
    </>
  );
};
