import { cooperColors } from "utils/colors/accents";

export const Location = () => {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.50005 8.39374C8.577 8.39374 9.45005 7.5207 9.45005 6.44374C9.45005 5.36679 8.577 4.49374 7.50005 4.49374C6.42309 4.49374 5.55005 5.36679 5.55005 6.44374C5.55005 7.5207 6.42309 8.39374 7.50005 8.39374Z"
        stroke="#292D32"
      />
      <path
        d="M2.26244 5.30625C3.49369 -0.106249 11.5124 -0.0999984 12.7374 5.3125C13.4562 8.4875 11.4812 11.175 9.74994 12.8375C8.49369 14.05 6.50619 14.05 5.24369 12.8375C3.51869 11.175 1.54369 8.48125 2.26244 5.30625Z"
        stroke="#292D32"
      />
    </svg>
  );
};

export const JobLocation = ({ color = cooperColors["foreground-neutral-faded"] }) => {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.49999 8.39365C8.57694 8.39365 9.44999 7.52061 9.44999 6.44365C9.44999 5.3667 8.57694 4.49365 7.49999 4.49365C6.42303 4.49365 5.54999 5.3667 5.54999 6.44365C5.54999 7.52061 6.42303 8.39365 7.49999 8.39365Z"
        stroke={color}
      />
      <path
        d="M2.2625 5.30625C3.49375 -0.106249 11.5125 -0.0999984 12.7375 5.3125C13.4563 8.4875 11.4813 11.175 9.75 12.8375C8.49375 14.05 6.50625 14.05 5.24375 12.8375C3.51875 11.175 1.54375 8.48125 2.2625 5.30625Z"
        stroke={color}
      />
    </svg>
  );
};
