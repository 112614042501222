import { useState } from "react";
import LoginUser from "components/pages/login";
import { RegisterLayout } from "components/pages/register";
import useTranslation from "components/hooks/useTranslation";

const Login = () => {
  const [loginStage, setLoginStage] = useState("login");
  const { t } = useTranslation();
  const login = { loginStage, setLoginStage };
  return (
    <RegisterLayout {...login}>
      <LoginUser
        title={t("Welcome to CooperHire")}
        subTitle={t("A modern recruiting tool that helps you hire top candidates quickly")}
      />
    </RegisterLayout>
  );
};

export default Login;
