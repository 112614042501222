import { baseColor } from "utils/colors/accents";

export const Options = ({ color = baseColor.black }) => {
  return (
    <svg width="4" height="13" viewBox="0 0 4 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="2" cy="1.49998" r="1.5" fill={color} />
      <circle cx="2" cy="6.49998" r="1.5" fill={color} />
      <circle cx="2" cy="11.5" r="1.5" fill={color} />
    </svg>
  );
};
