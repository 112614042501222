import { useEffect, useMemo, useRef, useState } from "react";
import { Button, Text, View, useToast, useToggle } from "reshaped";

import { TrashOutline } from "icons";
import { templatesSlice } from "redux/templates";
import { cooperColors } from "utils/colors/accents";
import useSpacing from "components/hooks/useSpacing";
import { placeholdersSlice } from "redux/placeholders";
import { useDispatch, useSelector } from "react-redux";
import { ShowToastWithTranslation } from "utils/showToast";
import useTranslation from "components/hooks/useTranslation";
import { supportedTemplateTriggers } from "utils/constants";
import { ViewTemplateForm } from "components/pages/settings";
import { HTMLValidationHelper } from "utils/validationHelper";
import { addBlankTargetToAnchorTags } from "utils/EditorHelpers";
import { SuccessfulToast } from "components/reusables/tab/SuccessfulToast";
import ConfirmationModal from "components/reusables/modals/ConfirmationModal";
import { fetchSubscriptionData } from "redux/subscriptions/subscriptionsSlice";
import { OperationFailedWithTranslation, ReshapedLoader } from "components/reusables";
import {
  isObject,
  isString,
  areKeysAvailableIn,
  areKeysAvailableWithType,
} from "utils/miniHelpers";
import {
  handleTemplateAPI,
  getPlaceholderFromAPI,
  handleTemplateDeleteAPI,
} from "utils/pages/settings";

export function ViewTemplate({ viewingTemplate, handleView, view }) {
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [shouldDisable] = useState(() => {
    if (
      areKeysAvailableWithType({
        object: viewingTemplate,
        keys: [
          { is_default_trigger: true, exact: true },
          { trigger: "string", callback: (value) => supportedTemplateTriggers.includes(value) },
        ],
      })
    ) {
      return true;
    }
    return false;
  });

  const [isChanged, setIsChanged] = useState(() => {
    if (view === "create") return true;
    return false;
  });

  const [errors, setErrors] = useState({
    name: {},
    subject: {},
    message: {},
  });

  const stateRef = useRef(null);

  const toast = useToast();
  const dispatch = useDispatch();
  const { xs, md, xl } = useSpacing();
  const { t, locale } = useTranslation();
  const { placeholders } = useSelector((state) => state);
  const { active, activate, deactivate } = useToggle(false);

  const { data: placeholdersData } = placeholders;

  useEffect(() => {
    async function getPlaceholders() {
      dispatch(placeholdersSlice.actions.setLoading(true));
      const result = await getPlaceholderFromAPI();
      if (result.error) {
        dispatch(
          placeholdersSlice.actions.setProperties({
            loading: false,
            error: result.error,
            data: null,
          })
        );
      }
      if (result.data) {
        dispatch(
          placeholdersSlice.actions.setProperties({
            loading: false,
            data: result.data,
            error: null,
          })
        );
      }
    }

    if (!placeholdersData) {
      getPlaceholders();
    }
    //eslint-disable-next-line
  }, []);

  const textVariants = useMemo(() => {
    let modalDeleteTitleText = areKeysAvailableWithType({
      object: viewingTemplate,
      keys: [{ name: "string" }],
    })
      ? `Are you sure you want to delete the "${viewingTemplate.name}" template?`
      : "Are you sure you want to delete template?";
    if (locale === "de") {
      if (areKeysAvailableWithType({ object: viewingTemplate, keys: [{ name: "string" }] })) {
        modalDeleteTitleText = `Sind Sie sicher, dass Sie die Nachrichtenvorlage "${viewingTemplate.name}" löschen möchten?`;
      } else {
        modalDeleteTitleText = "Sind Sie sicher, dass Sie die Nachrichtenvorlage löschen möchten?";
      }
    }
    return {
      modalDeleteTitleText,
      modalTitle: t("Would you like to delete this template?"),
    };

    //eslint-disable-next-line
  }, []);

  const handleCancel = () => {
    if (loading || deleteLoading) return;
    handleView("list");
  };

  const handleSave = () => {
    if (loading || deleteLoading) return;
    let hasErrors = false;

    const errorSentences = {
      name: "Template Name is mandatory.",
      subject: "Subject field is mandatory.",
      message: "Message field is mandatory.",
    };

    if (!isObject(errors)) return;

    const newErrors = {
      ...errors,
    };

    for (let k in errors) {
      const each = errors[k];
      if (each && isObject(each)) {
        hasErrors = true;
        if (errorSentences[k]) {
          newErrors[k] = t(errorSentences[k]);
        } else {
          newErrors[k] = t(`${k} is required`);
        }
      }
      if (each && typeof each === "string") {
        hasErrors = true;
      }
    }

    if (
      isObject(stateRef.current) &&
      areKeysAvailableIn({ object: stateRef.current, keys: ["message"] })
    ) {
      const errorResult = HTMLValidationHelper({
        value: stateRef.current.message,
        requiredErrorString: t("Message field is mandatory."),
        characterLimitErrorString: t("Message must not exceed 8000 characters."),
        sizeLimitErrorString: t("Message size must not exceed 20MB limit"),
      });

      if (errorResult) {
        hasErrors = true;
        newErrors.message = errorResult;
      } else {
        newErrors.message = "";
      }
    }

    if (hasErrors) {
      setErrors(newErrors);
      return;
    }

    if ((view === "update" && !isChanged) || !stateRef || !isObject(stateRef.current)) {
      ShowToastWithTranslation({
        toast,
        Children: OperationFailedWithTranslation,
        text: t("Please update the template."),
      });
      return;
    }

    const newState = {
      ...(isObject(stateRef.current) ? stateRef.current : {}),
    };

    if (
      areKeysAvailableWithType({
        object: newState,
        keys: [{ message: "string", callback: (value) => isString(value) }],
      })
    ) {
      let sanitizedMessage = addBlankTargetToAnchorTags({ text: newState.message });
      if (isString(sanitizedMessage)) {
        newState["message"] = sanitizedMessage;
      } else {
        delete newState["message"];
      }
    }

    if (view === "update") {
      if (!areKeysAvailableIn({ object: viewingTemplate, keys: ["id"] })) return;

      newState["id"] = viewingTemplate.id;
    }

    if (
      view === "create" &&
      !areKeysAvailableIn({ object: newState, keys: ["trigger", "is_default_trigger"] })
    ) {
      newState["trigger"] = "";
      newState["is_default_trigger"] = null;
    }

    handleTemplate({ data: newState, status: view });
  };

  const handleTemplate = async ({ data, status = "create" }) => {
    if (!data || !isObject(data) || !status) return;
    setLoading(true);
    const result = await handleTemplateAPI({ data, status });
    setLoading(false);
    if (result.error) {
      ShowToastWithTranslation({
        toast,
        Children: OperationFailedWithTranslation,
        text: t(result.error),
      });
    }
    if (result.data) {
      if (status === "create") {
        dispatch(templatesSlice.actions.addData(result.data));
        dispatch(fetchSubscriptionData());
        ShowToastWithTranslation({
          toast,
          Children: SuccessfulToast,
          text: t("Template successfully generated."),
        });
      }
      if (status === "update") {
        dispatch(templatesSlice.actions.updateData(result.data));
        ShowToastWithTranslation({
          toast,
          Children: SuccessfulToast,
          text: t("Template successfully updated."),
        });
      }
      handleView("list");
    }
  };

  const handleDeleteModal = () => {
    if (
      loading ||
      deleteLoading ||
      !areKeysAvailableIn({ object: viewingTemplate, keys: ["id"] }) ||
      shouldDisable
    )
      return;

    activate();
  };

  const handleDeleteTemplate = (action) => {
    if (action === "accept") {
      handleDelete();
    }
    deactivate();
  };

  const handleDelete = async () => {
    if (
      loading ||
      deleteLoading ||
      !areKeysAvailableIn({ object: viewingTemplate, keys: ["id"] }) ||
      shouldDisable
    )
      return;

    setDeleteLoading(true);

    const result = await handleTemplateDeleteAPI({ id: viewingTemplate.id });

    setDeleteLoading(false);

    if (result.error) {
      ShowToastWithTranslation({
        toast,
        Children: OperationFailedWithTranslation,
        text: t(result.error),
      });
    }

    if (result.data) {
      dispatch(templatesSlice.actions.deleteData({ id: viewingTemplate.id }));
      dispatch(fetchSubscriptionData());
      ShowToastWithTranslation({
        toast,
        Children: SuccessfulToast,
        text: t("Template successfully removed."),
      });
      handleView("list");
    }
  };

  return (
    <View gap={10 * xs} padding={[xl, 0]}>
      <ViewTemplateForm {...{ viewingTemplate, stateRef, view, errors, setErrors, setIsChanged }} />
      <View direction="row" align="center" gap={md}>
        <View.Item grow>
          <View direction="row" align="center" gap={md}>
            <Button
              color="primary"
              disabled={!isChanged || deleteLoading}
              variant={loading ? "outline" : "solid"}
              onClick={handleSave}
            >
              <View
                direction="row"
                align="center"
                justify="center"
                attributes={{
                  style: {
                    minWidth: "45px",
                  },
                }}
              >
                {loading ? (
                  <ReshapedLoader size="small" loaderColor={"inherit"} />
                ) : (
                  <Text color="white" variant="body-strong-2">
                    {t("Save")}
                  </Text>
                )}
              </View>
            </Button>
            <Button
              variant="outline"
              color="primary"
              onClick={handleCancel}
              disabled={loading || deleteLoading}
            >
              <Text color="primary" variant="body-strong-2">
                {t("Cancel")}
              </Text>
            </Button>
          </View>
        </View.Item>
        {view === "update" && (
          <Button
            color="critical"
            disabled={shouldDisable}
            onClick={handleDeleteModal}
            variant={deleteLoading ? "solid" : "ghost"}
          >
            <View
              gap={xs}
              direction="row"
              align="center"
              justify="center"
              attributes={{
                style: {
                  minWidth: "80px",
                },
              }}
            >
              {deleteLoading ? (
                <ReshapedLoader size="small" loaderColor={"inherit"} />
              ) : (
                <>
                  <TrashOutline
                    size="20"
                    color={
                      shouldDisable
                        ? cooperColors["foreground-disabled"]
                        : cooperColors["background-critical"]
                    }
                  />
                  <Text color={shouldDisable ? "disabled" : "critical"} variant="body-strong-2">
                    {t("Delete")}
                  </Text>
                </>
              )}
            </View>
          </Button>
        )}
      </View>
      <ConfirmationModal
        activate={activate}
        active={active}
        deactivate={deactivate}
        title={textVariants.modalTitle}
        subTitle={textVariants.modalDeleteTitleText}
        acceptText="Delete"
        colorVariant={"critical"}
        handleAction={handleDeleteTemplate}
      />
    </View>
  );
}
