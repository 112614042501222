import { Text, View } from "reshaped";
import { accent } from "utils/colors/accents";
import { Banner } from "components/pages/promote";
import useSpacing from "components/hooks/useSpacing";
import useTranslation from "components/hooks/useTranslation";

export const BookingsBanner = ({ bookingsTab = "inventory", noSubTitle = false }) => {
  const { t } = useTranslation();
  const { xs, lg, xl } = useSpacing();

  return (
    <View gap={lg + xl}>
      <View direction="row" gap={xs} align="center" justify="start">
        <Text variant="title-2">{t("My Bookings")}</Text>
        {!noSubTitle && (
          <Text variant="body-1" color="neutral-faded">
            {bookingsTab === "inventory" ? t("Inventory") : t("Active & Past Bookings")}
          </Text>
        )}
      </View>
      <Banner
        title={
          "Easily track all your job board bookings and manage your promoted jobs in one place"
        }
        bannerName={"bookings"}
        backgroundColor={accent["4-100"]}
      />
    </View>
  );
};
