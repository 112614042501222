import { useMemo } from "react";
import { View } from "reshaped";

import { useSelector } from "react-redux";
import { validationHelper } from "utils/validationHelper";
import useTranslation from "components/hooks/useTranslation";
import { getBrowserLanguage } from "utils/getBrowserLanguage";
import styles from "components/styles/reshaped/settings.module.css";
import usePublicCountries from "components/hooks/usePublicCountries";
import { BasicAutoComplete } from "components/reusables/AutoCompletes";

export const CompanySelectCountry = (props) => {
  const {
    state,
    dispatch,
    error,
    setError,
    errorKey,
    stateKey,
    reducerKey,
    isChanged,
    setIsChanged,
    // with user or browser
    getCountriesInLanguage = "user",
  } = props;

  const { t } = useTranslation();
  const { getCountriesList } = usePublicCountries();
  const auth = useSelector((state) => state.auth);

  const browserLang = useMemo(
    () => getBrowserLanguage(),

    //eslint-disable-next-line
    []
  );

  const userLang = useMemo(() => {
    let userLang = "en";
    if (auth.user && auth.user.language_preference === "de") {
      userLang = "de";
    }
    return userLang;

    //eslint-disable-next-line
  }, [auth.user]);

  const countries = useMemo(() => {
    let listLocale = getCountriesInLanguage === "user" ? userLang : browserLang;
    const countriesList = getCountriesList({ locale: listLocale });

    if (!countriesList || !Array.isArray(countriesList)) return [];

    return countriesList;

    //eslint-disable-next-line
  }, []);

  const handleCountry = (value) => {
    dispatch({ type: reducerKey, payload: value });
    if (!isChanged) setIsChanged(true);
    if (error[errorKey]) {
      setError((previousError) => {
        return {
          ...previousError,
          [errorKey]: null,
        };
      });
    }
  };

  const handleCountryValidation = () => {
    const errorResult = validationHelper({
      value: state[stateKey],
      requiredErrorString: "Country is required",
      callback: (value) => {
        if (countries.includes(value)) return "";
        return "Please choose a country from the list";
      },
    });

    if (errorResult) {
      setError((prev) => {
        return {
          ...prev,
          [errorKey]: t(errorResult),
        };
      });
    }
  };

  return (
    <View>
      <BasicAutoComplete
        label={t("Country")}
        id="company__country"
        state={state[stateKey]}
        handleBlur={handleCountryValidation}
        inputAttributes={{ type: "text" }}
        handleChange={handleCountry}
        options={countries}
        required
        hasError={error[errorKey] && typeof error[errorKey] !== "object"}
        error={error[errorKey]}
        customClasses={{
          input: styles.country__input,
          dropdown: styles.country__dropdown,
          label: styles.country__input__label,
          error: styles.country__input__error,
          option: styles.country__dropdown__option,
          decorator: styles.country__input__decorator,
        }}
      />
    </View>
  );
};
