import { Text } from "reshaped";

export const PrimaryShadedText = ({
  variant = "body-1",
  attributes = {
    style: {},
  },
  children,
}) => {
  return (
    <Text
      variant={variant}
      attributes={
        typeof attributes === "object" && attributes.style && typeof attributes.style === "object"
          ? {
              ...attributes,
              style: { color: "var(--rs-color-foreground-primary-shaded)", ...attributes.style },
            }
          : typeof attributes === "object"
          ? { ...attributes, style: { color: "var(--rs-color-foreground-primary-shaded)" } }
          : { style: { color: "var(--rs-color-foreground-primary-shaded)" } }
      }
    >
      {children}
    </Text>
  );
};
