import { Avatar, Text, View } from "reshaped";
import useName from "components/hooks/useName";
import useSpacing from "components/hooks/useSpacing";
import useFullName from "components/hooks/useFullName";
import { TextWithEclipses } from "components/reusables";
import styles from "components/styles/reshaped/candidates.module.css";
import { DatePill } from "components/reusables/DatePill";

const NoteOverview = (props) => {
  const { data, noteColor, showFullName = false } = props;
  const { user, note } = data;
  const { first_name, last_name } = user;

  const { xs, sm, lg, xl } = useSpacing();
  const userProfile = useName({ first_name, last_name, info: data?.user });
  const fullName = useFullName({ first_name, last_name, dependency: [user] });
  const overviewBackgroundClassName = `note__overview__${noteColor.code}`;

  return (
    <>
      <View
        gap={lg}
        padding={[xl, lg]}
        borderRadius="medium"
        attributes={{
          style: {
            background: `${noteColor.background}`,
            position: "relative",
          },
        }}
      >
        <span
          className={styles.note__bar}
          style={{
            backgroundColor: `${noteColor.bar}`,
          }}
        />
        <View direction="row" align="center" gap={sm}>
          <>
            {userProfile.hasURL && <Avatar size={xl + xs} src={userProfile.url} alt={fullName} />}
            {!userProfile.hasURL && (
              <View
                attributes={{
                  style: {
                    border: `1px solid ${noteColor.bar}`,
                    borderRadius: "50%",
                  },
                }}
              >
                <Avatar size={xl + xs} initials={userProfile.letters} alt={fullName} />
              </View>
            )}
          </>
          {showFullName && (
            <Text
              variant="body-strong-1"
              attributes={{
                style: {
                  textTransform: "capitalize",
                  color: "#0A3D8A",
                },
              }}
            >
              {fullName}
            </Text>
          )}
          {!showFullName && (
            <TextWithEclipses
              variant="body-strong-1"
              text={fullName}
              characterLimit={18}
              attributes={{
                style: {
                  textTransform: "capitalize",
                  color: "#0A3D8A",
                },
              }}
            />
          )}
        </View>
        <View
          className={`${styles.note__overview} ${styles[overviewBackgroundClassName]} no__scroll`}
        >
          <View
            className={"html__content"}
            attributes={{
              dangerouslySetInnerHTML: { __html: note },
            }}
          />
        </View>
        <View
          attributes={{
            style: {
              position: "absolute",
              bottom: xl,
              left: xl,
            },
          }}
        >
          <DatePill date={data.updated_at} />
        </View>
      </View>
    </>
  );
};

export default NoteOverview;
