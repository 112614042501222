import { Icon, View, Text, Button } from "reshaped";

import { ArrowLeft } from "icons";
import useSpacing from "components/hooks/useSpacing";
import useTranslation from "components/hooks/useTranslation";
import styles from "components/styles/reshaped/reusables/publicTemplate.module.css";

export const PublicTemplate = (props) => {
  const {
    children,
    IconSvg,
    title,
    subTitle,
    info,
    showBackButton = false,
    backButtonText = "Back to log in",
    backButtonHref = "/login",
  } = props;

  const { t } = useTranslation();
  const { xl, xxl } = useSpacing();

  return (
    <View
      as="section"
      backgroundColor="white"
      width="100vw"
      height="100vh"
      padding={[xxl + xxl, xl]}
      justify="center"
      direction="row"
    >
      <View maxWidth="380px" align="center" justify="stretch" gap={4}>
        <View align="center" justify="stretch" gap={1}>
          <View
            width="40px"
            height="40px"
            borderRadius="medium"
            align="center"
            justify="center"
            direction="row"
            backgroundColor="primary-faded"
          >
            <Icon svg={IconSvg} size={5} />
          </View>
          <Text align="center" variant="title-2" as="h1" color="neutral">
            {title}
          </Text>
          <Text align="center" variant="body-medium-1" as="p" color="neutral-faded">
            {subTitle}
          </Text>
          {info && (
            <Text align="center" variant="body-medium-1" as="p" color="neutral">
              {info}
            </Text>
          )}
        </View>
        <View width="100%">{children}</View>
        {showBackButton && (
          <View justify="center" align="center" direction="row">
            <Button
              color="neutral"
              className={styles.login__btn}
              href={backButtonHref}
              startIcon={ArrowLeft}
            >
              {t(backButtonText)}
            </Button>
          </View>
        )}
      </View>
    </View>
  );
};
