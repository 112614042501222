import { useMemo } from "react";
import { useSelector } from "react-redux";

const useSlug = () => {
  const auth = useSelector((state) => state.auth);

  const { user } = auth;
  const { company } = user;
  const { slug: company_slug } = company;

  const slug = useMemo(
    () => {
      return company_slug ?? "company";
    },

    //eslint-disable-next-line
    [company_slug]
  );

  return slug;
};

export default useSlug;
