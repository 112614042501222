import { useState } from "react";
import { areKeysAvailableIn } from "utils/miniHelpers";
import { ListTemplates, ViewTemplate } from "components/pages/settings";

export function Templates() {
  // 3 values will be used - "list", "create", "update"
  const [view, setView] = useState("list");
  const [viewingTemplate, setViewingTemplate] = useState(null);

  const handleView = (value) => {
    const availableView = {
      list: "list",
      create: "create",
      update: "update",
    };
    if (!value || !availableView[value]) return;
    setView(value);
  };

  const handleViewingTemplate = ({ data }) => {
    if (
      !areKeysAvailableIn({ object: data, keys: ["id", "message", "subject", "name", "trigger"] })
    )
      return;
    setViewingTemplate(data);
  };

  return (
    <>
      {view === "create" && <ViewTemplate {...{ viewingTemplate: {}, handleView, view }} />}

      {view === "list" && (
        <ListTemplates
          handleChangeView={handleView}
          handleViewingTemplate={handleViewingTemplate}
        />
      )}

      {viewingTemplate && view === "update" && (
        <ViewTemplate {...{ viewingTemplate, handleView, view }} />
      )}
    </>
  );
}
