const BookingsBannerImage = () => {
  return (
    <svg
      width="137"
      height="137"
      viewBox="0 0 137 137"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="68.5" cy="68.5" r="68.5" fill="#FFEDD6" />
      <mask
        id="mask0_1752_8745"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="137"
        height="137"
      >
        <circle cx="68.5" cy="68.5" r="68.5" fill="#FFEDD6" />
      </mask>
      <g mask="url(#mask0_1752_8745)">
        <path
          d="M140.749 71.6554L69.2975 128.226L31.7042 97.9178C24.6671 103.781 13.8008 112.833 2.86847 121.915C-4.60247 128.122 -10.651 133.137 -15.5537 137.196L-49.6992 137.159C-40.2303 129.529 -2.10489 97.8233 23.5801 76.3957L31.4337 69.8442L69.4363 100.483L125.216 56.3197L140.749 71.6554Z"
          fill="#D5E5FB"
        />
        <path
          d="M9.18945 113.217C9.06232 110.26 8.78837 107.686 8.46052 105.892C7.93776 103.031 7.46726 101.386 7.46182 101.369C7.39294 101.117 6.39833 100.099 4.18677 100.131C1.97531 100.162 2.18502 101.497 2.18502 101.497C2.18484 101.542 2.1911 101.584 2.2009 101.615C2.22222 101.684 4.2522 108.65 4.16427 117.644C4.16309 117.77 4.14812 117.988 4.15756 118.134C4.15765 118.134 9.28246 118.259 9.28364 118.134C9.4624 117.348 9.3752 115.17 9.18945 113.217Z"
          fill="#B7D3F3"
        />
        <path
          d="M9.83609 117.599C9.83454 117.725 9.76467 117.825 9.67937 117.825H9.67738C9.59217 117.823 9.5243 117.723 9.5233 117.599H5.13131C5.12977 117.725 5.05981 117.825 4.97461 117.825H4.97251C4.88576 117.823 4.81689 117.72 4.81789 117.593C4.84339 115.019 4.68832 112.614 4.45357 110.492C3.86965 105.201 2.79036 101.664 2.77503 101.615C2.76532 101.584 2.75924 101.542 2.75924 101.497C2.75924 101.497 2.54944 100.162 4.76072 100.131C6.97255 100.099 7.96726 101.117 8.03613 101.369C8.04022 101.382 8.30663 102.314 8.6608 103.989C8.77813 104.544 8.90472 105.18 9.03484 105.892C9.36251 107.686 9.63654 110.26 9.76367 113.217C9.94933 115.17 10.0148 116.814 9.83609 117.599Z"
          fill="#D2E3FC"
        />
        <path
          d="M4.49286 117.825H21.7117C21.7117 117.825 21.3106 116.29 11.3387 116.518C4.2817 116.68 4.49286 117.825 4.49286 117.825Z"
          fill="#C5DCF8"
        />
        <path
          d="M3.08984 133.866C5.08634 124.301 4.57292 118.432 4.57047 118.358C4.56539 118.21 4.85222 118.077 5.00649 118.072C5.16265 118.069 5.85175 118.028 5.93151 118.154C6.03595 118.319 6.43803 125.551 3.46252 133.866H3.08984Z"
          fill="#B7D3F3"
        />
        <path
          d="M19.1686 133.866C17.1722 124.301 17.6858 118.432 17.6886 118.358C17.6931 118.21 17.4064 118.077 17.2521 118.072C17.096 118.069 16.4073 118.028 16.3274 118.154C16.2231 118.319 15.8202 125.551 18.7965 133.866H19.1686Z"
          fill="#B7D3F3"
        />
        <path
          d="M22.352 133.866C20.3557 124.301 20.8693 118.432 20.872 118.358C20.8765 118.21 20.5898 118.077 20.4355 118.072C20.2795 118.069 19.5907 118.028 19.5109 118.154C19.4065 118.319 19.0046 125.551 21.98 133.866H22.352Z"
          fill="#B7D3F3"
        />
        <path
          d="M6.29688 133.866C8.29337 124.301 7.78005 118.432 7.7775 118.358C7.77251 118.21 8.05926 118.077 8.21352 118.072C8.36977 118.069 9.05886 118.028 9.13853 118.154C9.24307 118.319 9.64505 125.551 6.66954 133.866H6.29688Z"
          fill="#B7D3F3"
        />
        <path
          d="M21.7197 118.315H4.50115C4.30715 118.315 4.14844 118.165 4.14844 117.981V117.762C4.14844 117.579 4.30715 117.428 4.50115 117.428H21.7197C21.9139 117.428 22.0727 117.579 22.0727 117.762V117.981C22.0727 118.165 21.9139 118.315 21.7197 118.315Z"
          fill="#B7D3F3"
        />
        <path
          d="M21.1183 103.599L26.8414 102.426L27.1789 103.203C27.1789 103.203 20.8633 106.76 20.2717 106.586C19.6801 106.411 21.1183 103.599 21.1183 103.599Z"
          fill="#FFB27D"
        />
        <path
          d="M15.9122 128.097C15.7688 128.253 15.7507 128.426 15.8215 128.612C15.8215 128.613 15.8224 128.613 15.8233 128.614C15.9539 128.957 16.3877 129.341 16.8913 129.723C16.9557 129.772 17.0129 129.817 17.0628 129.859C17.0755 129.869 17.0891 129.88 17.1009 129.891C17.1109 129.9 17.1209 129.908 17.1309 129.916C17.6 130.326 17.4239 130.419 18.2669 131.251C19.2288 132.198 19.8912 132.215 20.079 132.129C20.2387 132.055 19.9148 131.553 19.5564 130.989C19.5391 130.961 19.5219 130.934 19.5037 130.906C19.4566 130.831 19.4085 130.755 19.3613 130.68C18.9411 130 19.0491 129.681 19.148 128.67C19.2297 127.848 20.4992 124.079 20.4992 124.079L18.6562 123.779C18.6562 123.779 18.1009 126.78 17.4893 127.242C17.1626 127.488 16.796 127.492 16.4821 127.674C16.4594 127.687 16.4376 127.7 16.4167 127.713C16.238 127.825 16.0565 127.941 15.9122 128.097Z"
          fill="#FFB27D"
        />
        <path
          d="M15.9122 128.097C15.7688 128.253 15.7507 128.426 15.8215 128.612C15.8215 128.613 15.8224 128.613 15.8233 128.614C15.9839 128.957 16.4058 129.338 16.8931 129.72C16.9566 129.769 17.0129 129.816 17.0628 129.859C17.0755 129.869 17.0891 129.88 17.1009 129.891C17.1109 129.9 17.1209 129.908 17.1309 129.916C17.6 130.326 17.4239 130.419 18.2669 131.251C19.2288 132.198 19.8912 132.215 20.079 132.129C20.2387 132.055 19.9148 131.553 19.5564 130.989C19.3694 130.924 18.4992 130.589 17.8414 129.793C17.2624 129.094 16.8768 128.194 16.4821 127.674C16.4594 127.687 16.4376 127.7 16.4167 127.713C16.238 127.825 16.0565 127.941 15.9122 128.097Z"
          fill="#1C468A"
        />
        <path
          d="M30.7753 127.008C30.8888 127.547 31.7 127.457 32.6065 127.247C33.5121 127.038 33.297 127.287 34.5865 127.152C35.8759 127.017 36.267 126.562 36.306 126.381C36.3414 126.219 35.7044 126.163 35.0202 126.088C34.9349 126.079 34.8478 126.069 34.7616 126.059C33.9903 125.967 33.425 125.496 32.7245 125.109C32.0602 124.742 31.0693 123.249 31.0693 123.249L29.3008 123.381C29.3008 123.381 30.2291 124.716 30.5884 125.295C30.7989 125.636 30.788 126.088 30.7617 126.436C30.7481 126.625 30.7354 126.819 30.7753 127.008Z"
          fill="#FFB27D"
        />
        <path
          d="M32.608 127.247C33.5136 127.038 33.2986 127.287 34.588 127.152C35.8775 127.017 36.2686 126.562 36.3076 126.381C36.343 126.219 35.706 126.163 35.0218 126.088L35.0154 126.101C35.0154 126.101 34.2423 126.619 33.1688 126.643C32.2941 126.664 31.3676 126.426 30.7633 126.436C30.7497 126.625 30.737 126.819 30.7769 127.008C30.8903 127.547 31.7015 127.457 32.608 127.247Z"
          fill="#1C468A"
        />
        <path
          d="M26.8398 102.426C26.8398 102.426 27.2618 101.449 27.7255 101.409C28.1883 101.369 29.6637 101.15 29.769 101.349C29.8742 101.548 29.769 102.266 29.4523 102.526C29.1365 102.785 27.1774 103.203 27.1774 103.203L26.8398 102.426Z"
          fill="#FFB27D"
        />
        <path
          d="M23.9967 116.306L20.1855 126.013C20.1855 126.013 19.6002 126.067 19.0386 125.914C18.5222 125.773 17.9297 125.403 17.9297 125.403C17.9297 125.403 18.8752 116.082 20.6093 114.144C22.3424 112.205 23.9967 116.306 23.9967 116.306Z"
          fill="#163560"
        />
        <path
          d="M8.92579 112.379C8.92579 112.379 19.8775 108.875 22.3202 109.62C24.763 110.366 32.0958 124.174 32.0958 124.174C32.0958 124.174 31.9307 124.382 31.4788 124.543C31.0587 124.692 30.0414 124.682 30.0414 124.682C30.0414 124.682 23.4228 119.314 22.6351 117.748C21.8475 116.182 21.5326 114.691 20.7441 114.839C19.9564 114.989 10.5804 118.493 8.45303 116.406C6.32568 114.318 6.88202 111.605 6.88202 111.605L8.92579 112.379Z"
          fill="#1C468A"
        />
        <path
          d="M15.165 87.6667C14.6916 87.4125 14.1242 87.3842 13.7274 87.4065C13.8296 87.3069 14.0306 87.2699 14.0306 87.2699C13.7845 87.2356 13.2353 87.4666 13.2353 87.4666C9.81256 86.2188 9.27456 90.9883 9.34652 92.0961C9.41784 93.2039 9.42656 94.4792 7.71789 95.4736C4.35601 97.429 7.19258 100.843 7.19258 100.843C7.21908 100.857 8.75679 103.325 8.75679 103.325C8.67049 102.43 13.0361 97.7047 15.3137 96.2894C15.8685 95.5166 16.0654 94.152 16.0799 94.0446C15.9846 95.1267 15.7886 95.7338 15.6139 96.0756C17.5427 94.3959 16.4193 88.3417 15.165 87.6667Z"
          fill="#B5453C"
        />
        <path
          d="M15.1818 96.3604C15.1931 96.3647 14.082 96.9108 13.2463 96.6944C12.4601 96.4918 11.9464 95.5274 11.9572 95.5248C12.2902 95.4621 12.3862 95.0293 12.3791 93.1692L12.5297 93.1967L15.0603 93.6707C15.0603 93.6707 15.0028 94.4247 15.001 95.1349C14.9993 95.7309 15.0375 96.2968 15.1818 96.3604Z"
          fill="#FFB27D"
        />
        <path
          d="M15.0579 93.6706C15.0579 93.6706 15.0004 94.4246 14.9987 95.1348C13.5826 95.097 12.8341 93.8741 12.5273 93.1966L15.0579 93.6706Z"
          fill="#ED975D"
        />
        <path
          d="M12.2662 92.9355C12.2662 92.9355 11.524 91.8252 11.3459 90.8763C11.2187 90.1996 11.5833 87.6877 14.2498 88.0501C14.2498 88.0501 15.0242 88.1222 15.7522 88.5877C16.3311 88.9569 17.1668 89.7564 16.2322 91.9471L15.9899 92.6874L12.2662 92.9355Z"
          fill="#B5453C"
        />
        <path
          d="M15.5311 94.3426C15.5311 94.3426 12.1806 94.395 12.1782 92.4199C12.176 90.4448 11.742 89.1214 13.8812 89.0046C16.0199 88.8878 16.4364 89.641 16.607 90.2996C16.7776 90.9592 16.4028 94.2782 15.5311 94.3426Z"
          fill="#FFB27D"
        />
        <path
          d="M15.4102 88.9565C15.4102 88.9565 14.4929 91.2734 13.0849 91.6161C11.6771 91.9587 11.0664 91.6015 11.0664 91.6015C11.0664 91.6015 12.1463 90.7899 12.3705 89.373C12.3705 89.373 14.7182 87.853 15.4102 88.9565Z"
          fill="#B5453C"
        />
        <path
          d="M15.1797 89.1441C15.1797 89.1441 15.8646 89.661 16.0316 90.1643C16.1985 90.6675 16.2739 91.7023 16.5933 91.6671C16.5933 91.6671 17.2838 90.0921 16.5071 89.2016C15.6837 88.2561 15.1797 89.1441 15.1797 89.1441Z"
          fill="#B5453C"
        />
        <path
          d="M7.71955 104.889C7.38916 103.578 8.51335 101.034 9.68682 98.927C10.7516 97.0172 11.8568 95.468 11.9561 95.5238C14.7643 97.1013 15.0181 95.79 15.0181 95.79H15.0182C15.0192 95.79 17.5688 96.1103 18.2911 97.2868C19.1867 98.7467 20.155 102.827 21.1168 103.599C20.9689 104.026 21.0324 104.254 21.1885 104.784C21.2221 104.897 21.2593 105.005 21.3001 105.107C21.4453 105.48 21.6259 105.783 21.7829 106.045C21.2938 106.327 20.35 106.861 19.655 106.477C18.5951 105.89 17.7675 104.103 17.302 103.391C17.3002 103.388 17.2993 103.386 17.2975 103.383C16.2857 108.803 17.6614 110.037 16.9645 110.608C16.2295 111.212 15.1788 110.321 12.8674 111.269C11.3515 111.892 9.24763 112.791 7.83142 112.948C7.0887 113.03 6.53445 112.908 6.35379 112.434C5.82848 111.054 8.34993 107.39 7.71955 104.889Z"
          fill="#1C468A"
        />
        <path
          d="M14.7852 93.1452C14.7852 93.1452 14.8476 93.3959 15.5072 93.3573C15.5072 93.3573 16.0613 93.3255 16.0441 93.049C16.0441 93.049 15.507 92.8532 14.7852 93.1452Z"
          fill="white"
        />
        <path
          d="M6.35379 112.434C6.53445 112.908 7.0887 113.03 7.83142 112.948C8.8117 112.543 13.0461 110.991 12.9988 109.939C12.9284 108.382 12.1846 108.332 12.7099 106.409C13.1317 104.866 9.21914 101.996 8.59502 101.372C7.42155 103.478 7.38916 103.578 7.71955 104.889C8.34993 107.39 5.82848 111.054 6.35379 112.434Z"
          fill="#163560"
        />
        <path
          d="M5.87646 106.815C6.62744 105.818 7.40973 105.29 7.92432 105.025C7.80591 104.736 11.0829 100.934 11.0829 100.345C11.0829 99.7479 11.9573 95.5237 11.9573 95.5237C11.9573 95.5237 9.22683 95.6834 8.28139 96.9312C7.09903 98.4915 4.64347 104.497 5.87646 106.815Z"
          fill="#1C468A"
        />
        <path
          d="M7.92294 105.026L13.3796 106.373L12.8674 107.243C12.8674 107.243 6.22698 107.199 5.87508 106.816C5.52318 106.434 7.92294 105.026 7.92294 105.026Z"
          fill="#FFB27D"
        />
        <path
          d="M17.3008 103.391C17.7663 104.103 18.5938 105.89 19.6537 106.477C20.3488 106.861 21.2925 106.327 21.7816 106.045C21.6246 105.783 21.444 105.48 21.2989 105.108C20.9087 105.193 20.4332 105.3 20.1256 105.388C19.5285 105.558 17.3008 103.391 17.3008 103.391Z"
          fill="#163560"
        />
        <path
          d="M13.3794 106.372C13.3794 106.372 15.0929 105.913 15.5011 106.02C15.9089 106.128 17.3073 107.004 17.1113 107.242C16.9144 107.48 15.6824 107.1 15.1156 107.172C14.5487 107.244 14.1103 107.508 13.7022 107.451C13.294 107.394 12.8672 107.242 12.8672 107.242L13.3794 106.372Z"
          fill="#FFB27D"
        />
        <path
          d="M13.3789 106.373C13.3789 106.373 14.2173 105.965 14.4557 105.733C14.4858 105.704 14.5367 105.71 14.5598 105.744C14.6237 105.84 14.6835 106.024 14.3895 106.207C13.9662 106.471 13.3789 106.373 13.3789 106.373Z"
          fill="#FFB27D"
        />
        <path
          d="M21.2989 108.654H13.3047C13.3047 108.321 13.59 108.051 13.942 108.051H21.2989V108.654Z"
          fill="#ED7D2B"
        />
        <path
          d="M21.2758 100.414H33.6357L30.6711 108.654H18.2305L21.2758 100.414Z"
          fill="#ED7D2B"
        />
        <path
          d="M33.8153 100.414L30.8499 108.653H18.4102L21.4545 100.414H33.8153Z"
          fill="#F99746"
        />
        <path
          d="M27.1544 104.458C27.0773 104.773 26.7452 105.028 26.4131 105.028C26.0801 105.028 25.8732 104.773 25.9494 104.458C26.0265 104.144 26.3586 103.888 26.6907 103.888C27.0238 103.888 27.2316 104.144 27.1544 104.458Z"
          fill="#F9DDBF"
        />
        <path
          d="M33.8171 100.414L30.8517 108.653H22.6133C28.0705 106.891 29.5232 101.516 29.7673 100.414H33.8171Z"
          fill="#ED893E"
        />
        <path
          d="M-2.0649 133.866C-1.90856 133.866 -1.7749 133.762 -1.74822 133.62L-0.19581 125.293L2.03707 113.316L2.66146 109.968L1.07103 109.925L0.79509 111.869L-1.14234 125.531L-2.29104 133.629C-2.30873 133.755 -2.20265 133.866 -2.0649 133.866Z"
          fill="#95C0F4"
        />
        <path
          d="M0.792969 111.869C1.82343 112.3 2.03495 113.316 2.03495 113.316L2.65934 109.968L1.06891 109.925L0.792969 111.869Z"
          fill="#78ACEA"
        />
        <path
          d="M37.4914 133.866C37.3354 133.866 37.202 133.762 37.1748 133.62L35.6231 125.293L33.3899 113.316L32.7656 109.968L34.3554 109.925L34.6313 111.869L36.5695 125.531L37.7174 133.629C37.7355 133.755 37.6294 133.866 37.4914 133.866Z"
          fill="#95C0F4"
        />
        <path
          d="M34.6313 111.869C33.6014 112.3 33.3899 113.316 33.3899 113.316L32.7656 109.968L34.3554 109.925L34.6313 111.869Z"
          fill="#78ACEA"
        />
        <path
          d="M-3.12372 111.282H37.6044C38.1379 111.282 38.5744 110.869 38.5744 110.364V109.571C38.5744 109.066 38.1379 108.653 37.6044 108.653H-3.12372C-3.65728 108.653 -4.09375 109.066 -4.09375 109.571V110.364C-4.09375 110.869 -3.65728 111.282 -3.12372 111.282Z"
          fill="#78ACEA"
        />
        <path
          d="M179.725 133.786H-59.7281C-59.8777 133.786 -60 133.671 -60 133.529V133.441C-60 133.3 -59.8777 133.184 -59.7281 133.184H179.725C179.875 133.184 179.998 133.3 179.998 133.441V133.529C179.998 133.671 179.875 133.786 179.725 133.786Z"
          fill="#2E3552"
        />
        <path
          d="M98.0363 107.252C98.0363 107.252 96.9791 108.492 97.3103 108.661C97.6397 108.83 98.5127 109.146 98.7187 108.947C98.9246 108.748 98.9891 107.286 98.9891 107.286L98.0363 107.252Z"
          fill="#FFB27D"
        />
        <path
          d="M98.0773 107.272C98.0773 107.272 97.6454 107.413 97.4757 107.626C97.306 107.838 96.9304 108.163 97.022 108.252C97.1146 108.342 97.6536 107.946 97.6536 107.946L98.0773 107.272Z"
          fill="#FFB27D"
        />
        <path
          d="M102.029 90.6922C102.029 90.6922 100.838 101.184 100.732 101.482C100.71 101.547 100.616 101.879 100.485 102.357C100.297 103.039 100.032 104.02 99.7804 104.953C99.402 106.361 99.0545 107.661 99.0545 107.661L98.02 107.338C98.02 107.338 97.9665 102.24 98.2324 101.066C98.4992 99.892 99.3494 92.6785 99.7795 91.7081C100.208 90.7377 102.029 90.6922 102.029 90.6922Z"
          fill="#AEC3FF"
        />
        <path
          d="M99.5553 115.849L93.0609 114.238C92.2161 114.027 91.7053 113.201 91.9267 112.402L92.6653 109.738C92.8867 108.938 93.7597 108.455 94.6054 108.665L101.1 110.277C101.945 110.487 102.455 111.313 102.234 112.113L101.495 114.776C101.274 115.576 100.401 116.059 99.5553 115.849Z"
          fill="#A02E2E"
        />
        <path
          d="M99.4005 109.848L97.0803 109.272L97.24 108.697C97.2672 108.598 97.3752 108.539 97.4795 108.565L98.0621 108.709L99.131 108.975L99.4196 109.046C99.4395 109.051 99.4568 109.059 99.474 109.068C99.5448 109.11 99.582 109.193 99.5602 109.273L99.4005 109.848ZM93.5214 109.949L92.7746 112.643C92.6303 113.165 92.7955 113.698 93.162 114.064C93.2827 114.186 93.4261 114.29 93.5885 114.368C93.5885 114.368 93.5885 114.368 93.5894 114.368V114.369C93.6902 114.418 93.7981 114.458 93.9134 114.486L100.396 116.095C101.244 116.306 102.121 115.821 102.343 115.018L103.09 112.324C103.312 111.521 102.801 110.691 101.952 110.481L99.7199 109.927L99.8787 109.352C99.9531 109.086 99.7834 108.814 99.5031 108.744L99.259 108.683L97.9006 108.347L97.563 108.263C97.4278 108.229 97.2862 108.248 97.1647 108.315C97.0431 108.382 96.9559 108.49 96.9206 108.618L96.7618 109.193L95.4687 108.872C95.417 108.859 95.3661 108.849 95.3153 108.841H95.3144H95.3135C94.7945 108.762 94.2791 108.937 93.9261 109.276V109.277C93.7364 109.458 93.594 109.686 93.5214 109.949Z"
          fill="#BC3F3F"
        />
        <path
          d="M98.655 108.974C98.655 108.974 98.9436 108.514 98.8356 108.354C98.7276 108.193 98.3384 108.83 98.3384 108.83C98.3384 108.83 98.6042 108.283 98.5053 108.193C98.4055 108.103 97.9981 108.731 97.9981 108.731C97.9981 108.731 98.2521 108.2 98.1632 108.182C98.0743 108.164 97.7721 108.629 97.7721 108.629C97.7721 108.629 97.9291 108.231 97.7857 108.251C97.6424 108.269 97.332 108.632 97.332 108.632L98.655 108.974Z"
          fill="#FFB27D"
        />
        <path
          d="M100.484 102.356C100.296 103.039 100.031 104.02 99.7796 104.952C99.6181 104.521 99.4121 103.835 99.2823 102.894C99.0428 101.158 99.7787 97.4587 99.7787 97.4587L100.484 102.356Z"
          fill="#9DB0F4"
        />
        <path
          d="M114.109 106.82C114.109 106.82 115.261 107.705 115.403 107.981C115.545 108.258 115.903 109.282 115.884 109.611C115.865 109.942 115.002 108.857 114.748 108.769C114.495 108.681 115.013 109.215 114.955 109.329C114.897 109.443 114.356 109.069 114.15 108.948C113.944 108.826 113.8 107.998 113.492 107.547C113.185 107.097 114.109 106.82 114.109 106.82Z"
          fill="#FFB27D"
        />
        <path
          d="M99 133.324H104.74C105.151 133.061 104.63 130.48 104.63 130.48C104.63 130.48 103.063 129.917 102.792 131.061C102.521 132.204 100.797 132.568 99.8838 132.708C99.2595 132.804 99.0617 133.128 99 133.324Z"
          fill="#233862"
        />
        <path
          d="M107.512 133.324H113.253C113.664 133.061 113.141 130.48 113.141 130.48C113.141 130.48 111.575 129.917 111.304 131.061C111.032 132.204 109.308 132.568 108.396 132.708C107.771 132.804 107.574 133.128 107.512 133.324Z"
          fill="#233862"
        />
        <path
          d="M100.543 106.995C100.543 106.995 100.801 110.042 101.104 113.704C101.516 118.679 102.628 131.013 102.628 131.013C102.628 131.013 103.357 131.602 104.971 131.28C104.971 131.28 106.345 127.024 106.756 122.168C106.762 122.088 106.769 122.009 106.775 121.928C107.158 116.984 107.752 106.573 107.752 106.573L100.543 106.995Z"
          fill="#2B478B"
        />
        <path
          d="M100.457 105.943C100.457 105.943 100.803 110.042 101.106 113.705L106.758 122.169C106.764 122.089 106.771 122.009 106.777 121.928C107.16 116.985 107.754 106.574 107.754 106.574L100.457 105.943Z"
          fill="#233862"
        />
        <path
          d="M102.105 105.942C102.105 105.942 106.205 115.6 107.515 119.261C108.449 121.868 111.082 131.015 111.082 131.015C111.082 131.015 112.227 131.345 113.189 130.708C113.189 130.708 114.169 121.362 112.19 118.727C111.14 117.328 111.929 104.194 107.673 104.081C103.417 103.969 102.105 105.942 102.105 105.942Z"
          fill="#2B478B"
        />
        <path
          d="M109.917 106.819C108.208 107.732 100.885 107.711 100.546 106.995C99.7885 105.395 99.598 97.2673 99.9555 94.7743C99.9555 94.7743 100.141 90.9426 100.546 91.1118C103.25 89.9353 107.629 91.0036 107.629 91.0036C108.809 91.3325 108.708 93.8855 109.038 97.4708C109.08 97.9328 109.127 98.4137 109.179 98.9118C109.413 101.126 109.752 103.668 110.266 106.351C110.301 106.528 110.171 106.684 109.917 106.819Z"
          fill="#AEC3FF"
        />
        <path
          d="M105.605 90.5726C105.56 91.2579 104.615 91.1806 103.48 90.935C102.345 90.6902 102.756 90.0565 102.756 90.0565C102.756 90.0565 102.772 89.5695 102.844 88.9856C102.87 88.7735 102.903 88.5476 102.947 88.3287C102.967 88.2222 102.988 88.1157 103.014 88.0143C103.163 87.4106 105.282 87.9929 105.268 88.1552C105.226 88.5931 105.561 90.0341 105.605 90.5726Z"
          fill="#FFB27D"
        />
        <path
          d="M103.168 91.7688L103.078 90.8216L104.335 91.0466L103.851 91.8916L103.168 91.7688Z"
          fill="#233862"
        />
        <path
          d="M102.783 89.6114L102.031 90.6925C102.031 90.6925 102.031 91.3572 102.684 91.8398L103.711 90.9347C103.711 90.9347 103.047 90.6951 102.783 89.6114Z"
          fill="#9DB0F4"
        />
        <path
          d="M103.707 90.9347C103.707 90.9347 103.919 91.6578 104.33 91.9893C104.33 91.9893 105.935 91.5814 106.317 90.8033L105.482 89.7831C105.482 89.7831 105.441 90.5551 103.707 90.9347Z"
          fill="#9DB0F4"
        />
        <path
          d="M105.279 88.4958C105.279 88.4958 104.258 89.1381 102.844 88.9853C102.87 88.7732 102.903 88.5473 102.946 88.3283C103.225 88.2648 103.459 88.2244 103.459 88.2244L105.279 88.4958Z"
          fill="#E2915A"
        />
        <path
          d="M105.925 87.7665C105.425 89.0658 103.604 88.7343 102.829 88.5256C102.611 88.4672 102.475 88.4183 102.475 88.4183C102.236 88.3152 102.162 87.9193 102.183 87.4196C102.224 86.4303 102.637 85.0339 102.874 84.6973C103.23 84.1906 103.825 83.6762 105.592 84.3933C107.358 85.1104 106.564 86.1014 105.925 87.7665Z"
          fill="#FFB27D"
        />
        <path
          d="M107.291 87.0989C106.734 87.5291 105.536 88.3252 105.536 88.3252C105.536 88.3252 105.669 88.1758 105.832 87.7464C105.851 87.6974 105.856 87.6262 105.854 87.5506C105.847 87.3402 105.901 87.1298 106.018 86.9503C106.09 86.8378 106.117 86.7219 106.025 86.6395C105.873 86.5038 105.615 86.6283 105.445 86.7382C105.334 86.8104 105.187 86.8146 105.072 86.7477C105.071 86.7468 105.07 86.7468 105.07 86.7459C104.594 86.4634 105.119 85.9662 104.733 85.5531C104.344 85.1401 102.669 85.0834 102.737 84.8249C102.165 84.5192 102.525 83.3908 102.536 83.3307C102.536 83.3307 103.356 83.3994 104.382 83.1572C105.214 82.9597 106.131 83.4655 106.421 83.6733C106.421 83.6235 106.413 83.5548 106.374 83.481C106.374 83.481 106.501 83.5986 106.499 83.736C106.511 83.748 106.517 83.7566 106.513 83.7592C106.513 83.7592 106.678 83.7558 106.775 83.8167C106.775 83.8167 106.705 83.7953 106.671 83.8356C106.667 83.8399 106.662 83.8425 106.657 83.8459C107.77 84.5905 107.754 86.7408 107.291 87.0989Z"
          fill="#233862"
        />
        <path
          d="M103.292 87.5781C103.292 87.5781 103.203 87.7421 102.752 87.6064C102.752 87.6064 102.374 87.4922 102.437 87.3033C102.437 87.3033 102.845 87.256 103.292 87.5781Z"
          fill="white"
        />
        <path
          d="M103.169 91.7688C103.169 91.7688 102.165 103.764 102.18 104.763C102.193 105.763 103.291 106.819 103.291 106.819C103.291 106.819 104.165 105.913 104.336 104.958C104.506 104.002 103.852 91.8916 103.852 91.8916L103.169 91.7688Z"
          fill="#2A4E96"
        />
        <path
          d="M107.625 91.0038C107.625 91.0038 109.244 91.3705 110.524 93.6978C111.805 96.025 112.815 100.719 113.426 101.957C114.038 103.196 114.457 107.098 114.457 107.098L113.489 107.547C113.489 107.547 111.153 103.549 110.807 102.237C110.62 101.528 107.764 95.8489 107.764 95.8489L107.625 91.0038Z"
          fill="#AEC3FF"
        />
        <path
          d="M109.917 106.819C109.917 106.819 109.917 106.819 109.916 106.819C106.867 106.405 107.741 96.8552 107.979 96.8174C108.219 96.7797 109.096 98.5246 109.096 98.5246L109.179 98.9119C109.412 101.126 109.752 103.669 110.266 106.351C110.301 106.528 110.171 106.685 109.917 106.819Z"
          fill="#9DB0F4"
        />
        <path
          d="M132.38 130.515C132.38 130.515 132.607 133.01 132.049 133.27L124.918 133.256C124.918 133.256 124.918 132.477 126.063 132.303C127.209 132.131 129.221 131.496 129.562 130.082C129.902 128.668 132.38 130.515 132.38 130.515Z"
          fill="#233862"
        />
        <path
          d="M126.359 95.271C126.359 95.271 126.735 99.6446 127.178 104.902C127.779 112.043 128.501 120.815 128.573 122.477C128.696 125.363 129.192 129.978 129.192 129.978C129.192 129.978 130.368 131.075 132.721 130.613C132.721 130.613 134.819 124.024 135.418 117.052C135.427 116.937 135.436 116.822 135.446 116.707C136.003 109.61 136.87 94.6647 136.87 94.6647L126.359 95.271Z"
          fill="#2A4E96"
        />
        <path
          d="M126.359 95.2712C126.359 95.2712 126.735 99.6448 127.178 104.902L135.418 117.052C135.427 116.938 135.436 116.822 135.446 116.707C136.003 109.61 136.87 94.6649 136.87 94.6649L126.359 95.2712Z"
          fill="#233862"
        />
        <path
          d="M128.078 95.7902C128.078 95.7902 134.858 111.629 139.316 119.073C143.776 126.516 145.168 128.594 145.168 128.594C145.168 128.594 147.723 127.641 147.955 126.992C147.955 126.992 146.25 112.622 143.265 109.119C141.578 107.14 140.122 102.748 140.195 100.67C140.321 97.0861 138.899 92.9332 138.899 92.9332L128.078 95.7902Z"
          fill="#2A4E96"
        />
        <path
          d="M122.665 96.0233C122.665 96.0233 121.399 97.6455 121.306 98.0783C121.212 98.5111 121.103 100.057 121.255 100.509C121.406 100.962 122.196 99.1595 122.515 98.9516C122.835 98.7438 122.316 99.661 122.44 99.8001C122.564 99.9401 123.174 99.2367 123.415 98.9972C123.655 98.7567 123.542 97.5562 123.798 96.8254C124.054 96.0946 122.665 96.0233 122.665 96.0233Z"
          fill="#FFB27D"
        />
        <path
          d="M128.247 72.4472C128.247 72.4472 126.536 87.5192 126.384 87.9485C126.35 88.0422 126.216 88.5196 126.027 89.2058C125.758 90.1856 125.378 91.5948 125.016 92.9336C124.472 94.9577 123.884 96.7078 123.884 96.7078C123.884 96.7078 123.625 96.8658 123.003 96.8152C122.577 96.7808 122.348 96.4373 122.348 96.4373C122.348 96.4373 122.41 89.0375 122.792 87.3509C123.175 85.6634 124.397 75.3017 125.015 73.9071C125.631 72.5125 128.247 72.4472 128.247 72.4472Z"
          fill="white"
        />
        <path
          d="M126.029 89.2057C125.76 90.1856 125.379 91.5948 125.018 92.9336C124.786 92.3144 124.49 91.3286 124.303 89.9778C123.959 87.4831 125.016 82.17 125.016 82.17L126.029 89.2057Z"
          fill="#DCEAFF"
        />
        <path
          d="M139.573 95.6176C137.118 96.928 126.597 96.8989 126.11 95.8701C125.022 93.572 124.749 81.8947 125.262 78.3129C125.262 78.3129 125.528 72.8082 126.11 73.0504C129.996 71.3612 136.286 72.8958 136.286 72.8958C137.981 73.3682 137.836 77.0359 138.309 82.1867C138.37 82.8496 138.438 83.5418 138.513 84.2563C138.848 87.4371 139.335 91.0911 140.075 94.9452C140.125 95.1985 139.938 95.4235 139.573 95.6176Z"
          fill="white"
        />
        <path
          d="M133.382 72.2764C133.317 73.2605 131.96 73.1489 130.328 72.7968C128.697 72.4456 129.288 71.5344 129.288 71.5344C129.288 71.5344 129.311 70.8345 129.415 69.9964C129.452 69.6924 129.499 69.3678 129.561 69.0535C129.59 68.8989 129.622 68.7478 129.659 68.6009C129.872 67.7344 132.918 68.5709 132.896 68.8036C132.837 69.4331 133.318 71.5026 133.382 72.2764Z"
          fill="#FFB27D"
        />
        <path
          d="M129.883 73.994L129.754 72.6337L131.56 72.9558L130.865 74.1709L129.883 73.994Z"
          fill="#233862"
        />
        <path
          d="M129.321 70.8934L128.242 72.4469C128.242 72.4469 128.242 73.4027 129.179 74.0957L130.654 72.7955C130.654 72.7955 129.7 72.4512 129.321 70.8934Z"
          fill="#DCEAFF"
        />
        <path
          d="M130.656 72.7957C130.656 72.7957 130.962 73.8339 131.553 74.3105C131.553 74.3105 133.857 73.724 134.407 72.6076L133.206 71.1409C133.206 71.1409 133.148 72.2504 130.656 72.7957Z"
          fill="#DCEAFF"
        />
        <path
          d="M132.912 69.2933C132.912 69.2933 131.446 70.2156 129.414 69.9966C129.451 69.6927 129.498 69.368 129.56 69.0537C129.961 68.961 130.297 68.9035 130.297 68.9035L132.912 69.2933Z"
          fill="#E2915A"
        />
        <path
          d="M133.845 68.244C133.128 70.1109 130.512 69.6343 129.398 69.3346C129.084 69.2505 128.89 69.18 128.89 69.18C128.546 69.0323 128.439 68.4638 128.47 67.7459C128.529 66.3247 129.122 64.3178 129.462 63.8343C129.974 63.1069 130.828 62.3676 133.365 63.3972C135.903 64.4277 134.764 65.8515 133.845 68.244Z"
          fill="#FFB27D"
        />
        <path
          d="M135.803 67.2847C135.002 67.903 133.281 69.0469 133.281 69.0469C133.281 69.0469 133.473 68.833 133.707 68.2156C133.733 68.146 133.742 68.043 133.738 67.9348C133.729 67.6316 133.806 67.3302 133.974 67.0717C134.078 66.9112 134.116 66.7437 133.984 66.6252C133.766 66.4303 133.395 66.6097 133.152 66.7677C132.991 66.8717 132.781 66.8777 132.615 66.7806C132.615 66.7798 132.614 66.7789 132.612 66.778C131.928 66.3727 132.683 65.6582 132.127 65.0648C131.57 64.4723 129.164 64.3907 129.261 64.0189C128.439 63.5792 128.957 61.9579 128.971 61.872C128.971 61.872 130.151 61.9707 131.623 61.623C132.819 61.3387 134.136 62.0652 134.554 62.3649C134.554 62.2928 134.541 62.1932 134.487 62.0884C134.487 62.0884 134.668 62.2576 134.664 62.4551C134.683 62.4723 134.691 62.4843 134.685 62.4869C134.685 62.4869 134.923 62.4826 135.062 62.5702C135.062 62.5702 134.96 62.5392 134.912 62.5968C134.906 62.6037 134.899 62.6079 134.891 62.6122C136.491 63.6814 136.467 66.7712 135.803 67.2847Z"
          fill="#BC3F3F"
        />
        <path
          d="M130.059 67.9739C130.059 67.9739 129.93 68.2083 129.283 68.0134C129.283 68.0134 128.741 67.8502 128.83 67.5788C128.83 67.5788 129.417 67.511 130.059 67.9739Z"
          fill="white"
        />
        <path
          d="M129.886 73.9946C129.886 73.9946 128.445 91.2272 128.465 92.6631C128.485 94.0989 130.062 95.6172 130.062 95.6172C130.062 95.6172 131.318 94.3153 131.563 92.9422C131.809 91.5682 130.868 74.1715 130.868 74.1715L129.886 73.9946Z"
          fill="#2A4E96"
        />
        <path
          d="M139.574 95.6172C139.573 95.6172 139.573 95.6172 139.572 95.6172C135.192 95.0204 136.447 81.3018 136.791 81.2477C137.135 81.1936 138.394 83.7003 138.394 83.7003L138.514 84.2559C138.849 87.4367 139.336 91.0907 140.076 94.9448C140.125 95.1981 139.939 95.4231 139.574 95.6172Z"
          fill="#DCEAFF"
        />
        <path
          d="M136.289 72.8949C136.289 72.8949 138.615 73.4222 140.454 76.7653C142.293 80.1085 143.745 86.8514 144.624 88.6316C145.501 90.4109 146.17 95.7515 146.17 95.7515C146.17 95.7515 146.033 96.0169 145.559 96.1139C145.193 96.1886 144.815 96.1594 144.815 96.1594C144.815 96.1594 141.357 90.9167 140.861 89.0335C140.592 88.0141 136.488 79.856 136.488 79.856L136.289 72.8949Z"
          fill="white"
        />
        <path
          d="M94.8384 40.1401C94.8384 40.1401 93.1805 37.3509 94.8384 36.7832C96.4971 36.2156 97.2476 36.7394 97.3265 37.3741C97.4055 38.0095 96.695 40.5137 96.695 40.5137L94.8384 40.1401Z"
          fill="#FFB27D"
        />
        <path
          d="M96.1705 57.9809C93.147 58.497 90.3666 58.7358 87.8567 58.789C85.4748 58.8397 83.3378 58.7229 81.4667 58.5168C80.964 58.4627 80.4813 58.4008 80.0176 58.3347C75.1221 57.6323 72.418 56.3862 72.418 56.3862C72.418 56.3862 76.8915 47.5239 80.5058 47.8639C84.7452 48.2641 91.9292 51.8649 92.6434 50.855C93.4473 49.7171 94.1206 39.96 94.1206 39.96C95.9999 38.7423 97.1224 40.0261 97.1224 40.0261C97.1224 40.0261 100.795 57.1917 96.1705 57.9809Z"
          fill="#E69A05"
        />
        <path
          d="M96.1706 57.9809C93.1471 58.497 90.3668 58.7358 87.8568 58.789C85.4749 58.8397 83.3379 58.7229 81.4668 58.5168C80.3389 55.1221 79.5277 51.6545 81.7962 53.7455C85.7979 57.4322 96.1706 57.9809 96.1706 57.9809Z"
          fill="#ED7D2B"
        />
        <path
          d="M72.0766 129.534C72.0766 129.534 71.7554 133.057 72.5421 133.423L82.6126 133.404C82.6126 133.404 82.6126 132.304 80.9947 132.059C79.3777 131.815 76.5357 130.919 76.0547 128.923C75.5738 126.926 72.0766 129.534 72.0766 129.534Z"
          fill="#233862"
        />
        <path
          d="M79.4677 86.8948C79.4178 89.198 79.3007 91.5741 79.0721 93.7099C77.9841 103.843 77.6021 116.087 77.446 118.448C77.1747 122.545 76.3199 129.091 76.3199 129.091C76.3199 129.091 74.623 130.616 71.3155 129.888C71.3155 129.888 68.576 120.46 67.9635 110.532C67.9544 110.369 67.9453 110.205 67.9363 110.042C67.8655 108.729 67.7911 107.228 67.7148 105.61C67.2112 94.7696 66.6641 78.6671 66.6641 78.6671L79.3007 75.859C79.3007 75.859 79.592 81.1514 79.4677 86.8948Z"
          fill="#2A4E96"
        />
        <path
          d="M79.4677 86.8946C76.2645 92.1235 70.1603 101.758 67.7148 105.609C67.2112 94.7693 66.6641 78.6668 66.6641 78.6668L79.3007 75.8587C79.3007 75.8587 79.592 81.1512 79.4677 86.8946Z"
          fill="#233862"
        />
        <path
          d="M47.4732 76.7653C47.4732 76.7653 45.417 79.0539 45.2319 79.6885C45.0468 80.3231 44.7174 82.6091 44.8944 83.2961C45.0704 83.9831 46.4379 81.3802 46.9351 81.1028C47.4324 80.8246 46.5622 82.1376 46.7319 82.3566C46.9016 82.5756 47.8834 81.5906 48.2663 81.2566C48.6492 80.9234 48.6111 79.1286 49.0712 78.0671C49.5313 77.0057 47.4732 76.7653 47.4732 76.7653Z"
          fill="#FFB27D"
        />
        <path
          d="M46.9921 125.53C46.9921 125.53 45.199 127.077 45.5493 128.177C45.8986 129.277 47.5166 130.01 47.7352 131.354C47.9539 132.699 48.8278 133.433 49.6145 133.433C50.4021 133.433 53.3667 133.433 53.3667 133.433C53.3667 133.433 53.9456 132.486 52.5464 132.201C51.148 131.916 50.6199 130.336 50.7079 128.87C50.795 127.403 50.5764 125.448 50.5764 125.448L47.6037 124.796L46.9921 125.53Z"
          fill="#233862"
        />
        <path
          d="M77.3144 78.913C77.3144 78.913 65.4844 102.867 59.1888 113.377C52.894 123.888 50.9267 126.82 50.9267 126.82C50.9267 126.82 47.3198 125.476 46.9922 124.559C46.9922 124.559 49.3996 104.269 53.6154 99.3222C55.9974 96.5278 58.7959 88.1403 58.9265 85.2068C59.0581 82.2733 61.8212 75.2728 61.8212 75.2728L77.3144 78.913Z"
          fill="#2A4E96"
        />
        <path
          d="M80.769 80.0417C80.769 80.0417 76.065 83.0181 69.4064 82.7416C66.4845 82.6214 60.6888 81.8374 59.7533 79.6381C59.5337 79.1211 59.6943 78.4659 60.0237 77.4603C60.8794 74.8462 62.8721 69.868 62.2705 58.8305C62.2142 57.8043 62.2451 56.8382 62.3485 55.9348C62.9755 50.498 66.2676 47.2785 69.4499 46.2266C77.2102 43.6589 79.7972 47.2158 79.7972 47.2158C79.7972 47.2158 83.2272 49.7878 83.7445 55.155C84.778 65.8912 80.769 80.0417 80.769 80.0417Z"
          fill="#E69A05"
        />
        <path
          d="M71.7266 45.61C71.7266 45.61 75.2873 47.659 79.1492 47.0793C79.1492 47.0793 79.9133 46.8955 79.5376 46.048C79.7726 45.3627 79.7445 45.4245 79.8806 44.9161C80.2191 43.7448 80.3225 43.0689 80.3225 43.0689L78.1311 40.8894L76.0549 38.8061C75.4814 43.7851 71.7266 45.61 71.7266 45.61Z"
          fill="#FFB27D"
        />
        <path
          d="M75.7227 40.6944C75.7227 40.6944 76.915 43.7112 79.8804 44.916C80.2189 43.7447 80.4394 42.5201 80.4394 42.5201L78.1309 40.8893L75.7227 40.6944Z"
          fill="#ED985F"
        />
        <path
          d="M82.7831 42.9641C82.7831 42.9641 75.9866 43.7679 75.5093 39.815C75.032 35.8622 73.8333 33.3039 78.1526 32.6238C82.4719 31.9428 83.4991 33.3641 84.0027 34.6479C84.5063 35.9309 84.539 42.6532 82.7831 42.9641Z"
          fill="#FFB27D"
        />
        <path
          d="M73.3594 31.9812C73.3005 31.9125 73.1616 31.8532 72.8477 31.8257C72.8477 31.8257 73.1054 31.6858 73.442 31.6901C73.5237 31.2779 73.7859 30.5531 74.5463 30.4019C74.5463 30.4019 74.1335 30.5917 74.0636 31.0305C74.9692 30.2139 76.8639 29.6634 82.0253 30.2353C88.8282 30.9893 86.4144 35.0426 85.5106 35.5038C84.6078 35.9649 82.439 34.5342 80.7884 34.5987C79.1379 34.6639 78.7341 36.9027 78.1642 37.1921C77.5934 37.4806 77.8348 36.9422 77.0926 36.5927C76.3494 36.2423 76.2305 37.4239 76.4183 38.202C76.6053 38.98 75.5273 39.959 75.5273 39.959C75.5273 39.959 75.5273 39.959 74.5209 38.8521C73.5146 37.746 72.707 33.1826 73.2224 32.2345C73.2678 32.1512 73.3132 32.0662 73.3594 31.9812Z"
          fill="#2C3A64"
        />
        <path
          d="M94.1216 38.2898L82.5829 38.3877L82.5039 36.7449L94.0063 35.9007L94.1216 38.2898Z"
          fill="#2A4E96"
        />
        <path
          d="M102.832 38.5646L90.2684 38.7363L90.125 35.7496L102.652 34.8307L102.832 38.5646Z"
          fill="#2A4E96"
        />
        <path
          d="M111.299 38.7995L100.705 38.8115L100.512 34.7797L111.062 33.8711L111.299 38.7995Z"
          fill="#233862"
        />
        <path
          d="M111.45 39.4798C111.777 39.4661 112.03 39.2042 112.015 38.895L111.766 33.715C111.751 33.4059 111.475 33.1663 111.148 33.18C110.82 33.1946 110.568 33.4565 110.583 33.7657L110.831 38.9457C110.846 39.2548 111.123 39.4944 111.45 39.4798Z"
          fill="#233862"
        />
        <path
          d="M82.5446 38.7378C82.2542 38.7498 82.0083 38.5377 81.9947 38.2629L81.9303 36.9189C81.9167 36.6433 82.1417 36.4106 82.4321 36.3977C82.7234 36.3857 82.9693 36.5986 82.982 36.8734L83.0464 38.2174C83.06 38.4922 82.835 38.7249 82.5446 38.7378Z"
          fill="#2A4E96"
        />
        <path
          d="M60.0273 77.46C60.883 74.8459 62.8757 69.8677 62.2741 58.8302C62.2179 57.8039 62.2487 56.8379 62.3521 55.9345H62.353C64.9065 55.7266 68.8111 58.339 68.8111 58.339C68.8111 58.339 66.4065 72.2113 60.0273 77.46Z"
          fill="#ED7D2B"
        />
        <path
          d="M71.7267 45.6102C71.7267 45.6102 65.1779 46.675 61.7624 50.621C58.3468 54.567 52.8524 60.1308 51.5539 62.0794C50.2545 64.0279 46.6484 77.2879 46.6484 77.2879C46.6484 77.2879 46.8871 77.7138 47.8816 77.962C48.8335 78.1999 49.524 77.8315 49.524 77.8315C49.524 77.8315 54.3016 70.0932 56.1001 66.2271C56.8469 64.6213 69.7676 58.2605 74.625 58.92C76.5324 59.1785 71.7267 45.6102 71.7267 45.6102Z"
          fill="#E69A05"
        />
        <path
          d="M94.0469 35.4306C94.0469 35.4306 95.4552 36.2464 96.2982 35.9012C97.1403 35.5559 97.0658 35.2408 97.0658 35.2408C97.0658 35.2408 94.5205 34.5718 94.0469 35.4306Z"
          fill="#FFB27D"
        />
        <path
          d="M78.0794 49.9316C78.0794 49.9316 75.2056 67.1032 54.8151 72.1029L52.5938 76.2498L56.4956 76.875C56.4956 76.875 79.71 70.5099 79.397 49.6285L78.0794 49.9316Z"
          fill="#FDC3B6"
        />
        <path
          d="M77.6324 47.1914L76.6914 47.2781V48.5679L78.0779 49.9316L79.3964 49.6285V47.8389L78.4482 47.1914H77.6324Z"
          fill="#A02E2E"
        />
        <path
          d="M73.9437 43.7772C73.9437 43.7772 73.0326 43.9713 70.9492 45.6098C70.9492 45.6098 73.0209 49.7705 77.391 50.2686C77.391 50.2686 77.3211 48.3381 77.6369 47.1916C77.6369 47.1916 74.1733 46.0177 73.9437 43.7772Z"
          fill="#ED7D2B"
        />
        <path
          d="M77.6328 47.1914C77.6328 47.1914 79.7652 48.2631 79.9231 49.9316C79.9231 49.9316 80.738 48.7096 80.5021 47.8638C80.2652 47.017 79.5348 46.0475 79.5348 46.0475C79.5348 46.0475 79.6073 47.0668 77.6328 47.1914Z"
          fill="#ED7D2B"
        />
      </g>
    </svg>
  );
};

export default BookingsBannerImage;
