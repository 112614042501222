import { createSlice } from "@reduxjs/toolkit";
import { areKeysAvailableIn, areKeysAvailableWithType, isObject } from "utils/miniHelpers";
import { removeCooperhireInProgressConversationFromLocalStorage } from "utils/pages/candidate";

const initialState = {
  initialConversationLoaded: false,
  // current viewing candidate_id
  viewingCandidateID: "",

  // all in progress messages
  inProgressConversations: {},

  // all in successfull messages
  // successfulConversations: {},

  inProgressConversationsIntervalCodes: {},

  // newMessage will object with status and message - status: "fail || success", message: conversation
  newMessage: "empty",
};

export const inProgressConversationsSlice = createSlice({
  name: "inProgressConversations",
  initialState,
  reducers: {
    reset: (state) => {
      for (let key in initialState) {
        state[key] = initialState[key];
      }
    },
    setInitialConversationLoaded: (state, action) => {
      const { payload } = action;
      if (
        areKeysAvailableWithType({
          object: payload,
          keys: [{ status: "boolean" }, { data: "object" }],
        })
      ) {
        state.initialConversationLoaded = payload.status;
        state.inProgressConversations = payload.data;
      }
    },
    setViewingingCandidateID: (state, action) => {
      const { payload } = action;
      if (typeof payload === "string") {
        state.viewingCandidateID = payload;
      }
    },
    setInProgressConversation: (state, action) => {
      const { payload } = action;
      if (!areKeysAvailableIn({ object: payload, keys: ["candidate_id", "data", "message_id"] }))
        return;
      const { candidate_id, data, message_id } = payload;
      state.inProgressConversations = {
        ...state.inProgressConversations,
        [candidate_id]: {
          ...(isObject(state.inProgressConversations[candidate_id])
            ? state.inProgressConversations[candidate_id]
            : {}),
          [message_id]: data,
        },
      };
    },
    setInProgressConversationInterval: (state, action) => {
      const { payload } = action;
      if (
        !areKeysAvailableWithType({
          object: payload,
          keys: [{ candidate_id: "string" }, { message_id: "string" }, { code: "number" }],
        })
      )
        return;
      const { candidate_id, code, message_id } = payload;

      let newInProgressConversationsIntervalCodes =
        state.inProgressConversationsIntervalCodes[candidate_id];

      if (isObject(newInProgressConversationsIntervalCodes)) {
        newInProgressConversationsIntervalCodes = {
          ...newInProgressConversationsIntervalCodes,
          [message_id]: code,
        };
      } else {
        newInProgressConversationsIntervalCodes = {
          [message_id]: code,
        };
      }

      state.inProgressConversationsIntervalCodes = {
        ...state.inProgressConversationsIntervalCodes,
        [candidate_id]: newInProgressConversationsIntervalCodes,
      };
    },
    removeInProgressConversationInterval: (state, action) => {
      const { payload } = action;
      if (
        !areKeysAvailableWithType({
          object: payload,
          keys: [{ candidate_id: "string" }, { message_id: "string" }],
        })
      )
        return;

      const { candidate_id, message_id } = payload;

      let inProgressConversationsIntervalCodes =
        state.inProgressConversationsIntervalCodes[candidate_id];

      if (isObject(inProgressConversationsIntervalCodes)) {
        if (
          areKeysAvailableWithType({
            object: inProgressConversationsIntervalCodes,
            keys: [{ [message_id]: "number" }],
          })
        ) {
          clearInterval(inProgressConversationsIntervalCodes[message_id]);
        }
        delete inProgressConversationsIntervalCodes[message_id];
        const updatedVersion = {
          ...state.inProgressConversationsIntervalCodes,
          [candidate_id]: inProgressConversationsIntervalCodes,
        };

        if (Object.keys(updatedVersion[candidate_id]).length <= 0) {
          delete updatedVersion[candidate_id];
        }
        state.inProgressConversationsIntervalCodes = updatedVersion;
      }
    },
    setConversation: (state, action) => {
      const { payload } = action;
      if (areKeysAvailableIn({ object: payload, keys: ["candidate_id", "data", "message_id"] })) {
        const { candidate_id, data, message_id } = payload;
        let newConversations = areKeysAvailableWithType({
          object: state.inProgressConversations,
          keys: [{ [candidate_id]: "array" }],
        })
          ? state.inProgressConversations[candidate_id]
          : [];
        if (newConversations.length === 0) {
          state.inProgressConversations = {
            ...state.inProgressConversations,
            [candidate_id]: [payload.data],
          };
        }
        if (newConversations.length > 0) {
          newConversations = newConversations.map((each) => {
            if (areKeysAvailableWithType({ object: each, keys: [{ message_id, exact: true }] })) {
              return data;
            }
            return each;
          });
          state.inProgressConversations = {
            ...state.inProgressConversations,
            [candidate_id]: newConversations,
          };
        }
        return;
      }
      if (areKeysAvailableIn({ object: payload, keys: ["candidate_id", "data"] })) {
        const { candidate_id, data } = payload;
        let newConversations = areKeysAvailableWithType({
          object: state.inProgressConversations,
          keys: [{ [candidate_id]: "array" }],
        })
          ? state.inProgressConversations[candidate_id]
          : [];
        newConversations.push(data);
        state.inProgressConversations = {
          ...state.inProgressConversations,
          [candidate_id]: newConversations,
        };
      }
    },
    removeConversation: (state, action) => {
      const { payload } = action;
      if (areKeysAvailableIn({ object: payload, keys: ["candidate_id", "message_id"] })) {
        const { candidate_id, message_id } = payload;
        let newConversations = areKeysAvailableWithType({
          object: state.inProgressConversations,
          keys: [{ [candidate_id]: "array" }],
        })
          ? state.inProgressConversations[candidate_id]
          : [];
        if (newConversations.length > 0) {
          const updatedVersion = {
            ...state.inProgressConversations,
            [candidate_id]: newConversations.filter((each) => {
              if (areKeysAvailableWithType({ object: each, keys: [{ message_id, exact: true }] })) {
                return false;
              }
              return true;
            }),
          };
          if (updatedVersion[candidate_id] && updatedVersion[candidate_id].length <= 0) {
            delete updatedVersion[candidate_id];
          }
          state.inProgressConversations = updatedVersion;
        }
      }
    },
    receiveInProgressConversation: (state, action) => {
      const { payload } = action;
      if (!areKeysAvailableIn({ object: payload, keys: ["candidate_id", "data", "on", "userID"] }))
        return;
      const { candidate_id, data, on, userID } = payload;

      if (!areKeysAvailableWithType({ object: data, keys: [{ message_id: "string" }] })) return;
      const message_id = data.message_id;

      let candidateConversations = areKeysAvailableWithType({
        object: state.inProgressConversations,
        keys: [{ [candidate_id]: "array" }],
      })
        ? state.inProgressConversations[candidate_id]
        : [];

      if (candidateConversations.length <= 0) return;
      if (on === "success") {
        const result = candidateConversations.reduce(
          (acc, each) => {
            if (
              areKeysAvailableWithType({
                object: each,
                keys: [{ message_id, exact: true }],
              })
            ) {
              return {
                ...acc,
                found: { ...each, shouldRetry: false, isSending: false },
              };
            }
            return {
              ...acc,
              array: [...acc.array, each],
            };
          },
          { found: false, array: [] }
        );
        if (result.found) {
          const updatedVersion = {
            ...state.inProgressConversations,
            [candidate_id]: result.array,
          };
          if (updatedVersion[candidate_id].length <= 0) {
            delete updatedVersion[candidate_id];
          }
          removeCooperhireInProgressConversationFromLocalStorage({
            candidate_id,
            message_id,
            userID,
          });
          state.inProgressConversations = updatedVersion;
        }
      }

      if (on === "fail") {
        const result = candidateConversations.reduce(
          (acc, each) => {
            if (
              areKeysAvailableWithType({
                object: each,
                keys: [{ message_id, exact: true }],
              })
            ) {
              const found = { ...each, shouldRetry: true, isSending: false, candidate_id };
              return {
                array: [...acc.array, found],
                found,
              };
            }
            return {
              ...acc,
              array: [...acc.array, each],
            };
          },
          { found: false, array: [] }
        );

        if (result.found) {
          const updatedVersion = {
            ...state.inProgressConversations,
            [candidate_id]: result.array,
          };
          if (updatedVersion[candidate_id].length <= 0) {
            delete updatedVersion[candidate_id];
          }
          state.inProgressConversations = updatedVersion;
          state.newMessage = {
            status: "fail",
            message: result.found,
          };
        }
      }
    },
    emptyNewMessage: (state) => {
      state.newMessage = "empty";
    },
  },
});
