import { useEffect, useMemo } from "react";
import { publicSlice } from "redux/public";
import { countryHelper } from "utils/countryHelpers";
import { useDispatch, useSelector } from "react-redux";
import { getCountriesFromAPI } from "utils/pages/root";

const usePublicCountries = (fetchOnInitial = false) => {
  const dispatch = useDispatch();
  const { publicState } = useSelector((state) => state);
  const { countries } = publicState;
  const { data } = countries;
  useEffect(() => {
    async function retryCountries(currentRetryNumber) {
      if (
        typeof currentRetryNumber !== "number" ||
        currentRetryNumber < 1 ||
        currentRetryNumber > 2
      )
        return;

      const result = await fetchCountries();

      if (result.error) {
        retryCountries(currentRetryNumber + 1);
      }
    }

    if (fetchOnInitial && !Array.isArray(data)) {
      retryCountries(1);
    }

    //eslint-disable-next-line
  }, []);

  async function fetchCountries() {
    dispatch(publicSlice.actions.setCountriesProperties({ loading: true, data: null, error: "" }));
    const result = await getCountriesFromAPI();
    const reduxProperties = {
      loading: false,
      data: null,
      error: "",
    };
    if (result.error) {
      reduxProperties.error = result.error;
    }
    if (result.data) {
      reduxProperties.data = result.data;
    }
    dispatch(publicSlice.actions.setCountriesProperties(reduxProperties));
    return result;
  }

  const helpers = useMemo(() => {
    return countryHelper(data);
  }, [data]);

  return {
    fetchCountries,
    ...helpers,
  };
};

export default usePublicCountries;
