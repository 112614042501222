import React, { useEffect, useCallback, useReducer, useMemo } from "react";
import { Button, DropdownMenu, FormControl, View, TextField, Text } from "reshaped";

import { ArrowDown } from "icons";
import useSpacing from "components/hooks/useSpacing";
import { validateErrors } from "utils/validateError";
import useTranslation from "components/hooks/useTranslation";
import styles from "components/styles/reshaped/settings.module.css";
import { CooperInputWrapper, CooperLabel } from "components/reusables/reshapedCustoms";
import {
  ALL,
  initialState,
  LANGUAGE_PREFERENCE,
  reducer,
} from "components/pages/settings/userProfileReducer";

const languagePreferenceOptions = {
  en: "English",
  de: "German",
  English: "en",
  German: "de",
};

export const UserProfileForm = React.forwardRef((props, ref) => {
  const { user, isChanged, setIsChanged, cancelChanges, setCancelChanges, error, setError } = props;

  const { xl } = useSpacing();
  const { t } = useTranslation();
  const [state, dispatch] = useReducer(reducer, initialState);

  const validate = useCallback((errorName, name, handleError, regex) => {
    validateErrors(errorName, name, handleError, t, regex);

    //eslint-disable-next-line
  }, []);

  ref.current = {
    ...state,
  };

  const languagePreferences = useMemo(() => {
    return [{ value: "English" }, { value: "German" }];

    //eslint-disable-next-line
  }, []);

  const fieldProperties = useMemo(() => {
    return {
      first_name: "first name",
      last_name: "last name",
    };
  }, []);

  const notRequiredFieldProperties = useMemo(() => {
    return {
      position: "position",
    };

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch({
      type: ALL,
      payload: {
        position: user?.position || "",
        last_name: user?.last_name || "",
        first_name: user?.first_name || "",
        language_preference: languagePreferenceOptions[user?.language_preference || "en"],
      },
    });
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (cancelChanges) {
      dispatch({
        type: ALL,
        payload: {
          position: user?.position || "",
          last_name: user?.last_name || "",
          first_name: user?.first_name || "",
          language_preference: languagePreferenceOptions[user?.language_preference || "en"],
        },
      });
      setCancelChanges(false);
    }
    //eslint-disable-next-line
  }, [cancelChanges]);

  const handleChange = (options) => {
    if (!isChanged) setIsChanged(true);
    if (cancelChanges) setCancelChanges(false);
    const { name, value } = options;
    const fieldPropertyName = fieldProperties[name];
    const notRequiredFieldPropertyName = notRequiredFieldProperties[name];
    if (error[fieldPropertyName]) {
      setError((previousError) => {
        return {
          ...previousError,
          [fieldPropertyName]: null,
        };
      });
    }
    if (error[notRequiredFieldPropertyName]) {
      setError((prev) => {
        const previousErrors = { ...prev };
        delete previousErrors[notRequiredFieldPropertyName];
        return previousErrors;
      });
    }
    dispatch({ type: `${name.toUpperCase()}`, payload: value });
  };

  const handleLimitedCharactersValidation = ({ stateProperty, errorKey, labelName }) => {
    let charactersExceeded = false;
    if (
      state &&
      state[stateProperty] &&
      typeof state[stateProperty] === "string" &&
      state[stateProperty].length > 240
    ) {
      charactersExceeded = true;
      setError((prev) => {
        return {
          ...prev,
          [errorKey]: t(`${labelName} should have maximum 240 characters`),
        };
      });
    }
    return charactersExceeded;
  };

  const handleLanguagePreference = ({ value }) => {
    if (!isChanged) setIsChanged(true);
    dispatch({ type: LANGUAGE_PREFERENCE, payload: value });
  };

  const handleFirstNameValidation = () => {
    const hasError = handleLimitedCharactersValidation({
      stateProperty: "first_name",
      errorKey: "first name",
      labelName: "First name",
    });
    if (hasError) return;
    validate("first name", state?.first_name, setError, /[a-zA-Z]{1,}\s?'?[a-zA-Z]{0,}/);
  };

  const handleLastNameValidation = () => {
    const hasError = handleLimitedCharactersValidation({
      stateProperty: "last_name",
      errorKey: "last name",
      labelName: "Last name",
    });
    if (hasError) return;
    validate("last name", state?.last_name, setError, /[a-zA-Z]{1,}\s?'?[a-zA-Z]{0,}/);
  };

  return (
    <>
      <View direction="row" gap={xl}>
        <View.Item grow>
          <CooperInputWrapper>
            <FormControl
              required
              hasError={error["first name"] && typeof error["first name"] !== "object"}
            >
              <FormControl.Label>
                <CooperLabel text={t("First Name")} />
              </FormControl.Label>
              <TextField
                name="first_name"
                value={state.first_name}
                onChange={handleChange}
                inputAttributes={{
                  type: "text",
                  onBlur: handleFirstNameValidation,
                }}
              />
              <FormControl.Error>{error["first name"]}</FormControl.Error>
            </FormControl>
          </CooperInputWrapper>
        </View.Item>
        <View.Item grow>
          <CooperInputWrapper>
            <FormControl
              required
              hasError={error["last name"] && typeof error["last name"] !== "object"}
            >
              <FormControl.Label>
                <CooperLabel text={t("Last Name")} />
              </FormControl.Label>
              <TextField
                name="last_name"
                value={state.last_name}
                onChange={handleChange}
                inputAttributes={{
                  type: "text",
                  onBlur: handleLastNameValidation,
                }}
              />
              <FormControl.Error>{error["last name"]}</FormControl.Error>
            </FormControl>
          </CooperInputWrapper>
        </View.Item>
      </View>
      <CooperInputWrapper>
        <FormControl hasError={error["position"] && typeof error["position"] !== "object"}>
          <FormControl.Label>
            <CooperLabel text={t("Position")} />
          </FormControl.Label>
          <TextField
            name="position"
            value={state.position}
            onChange={handleChange}
            inputAttributes={{
              type: "text",
              onBlur: () =>
                handleLimitedCharactersValidation({
                  stateProperty: "position",
                  errorKey: "position",
                  labelName: "Position",
                }),
            }}
          />
          <FormControl.Error>{error["position"]}</FormControl.Error>
        </FormControl>
      </CooperInputWrapper>
      <View.Item className={styles.dropdown__wrapper}>
        <CooperInputWrapper>
          <FormControl>
            <FormControl.Label>
              <CooperLabel text={t("Language Preference")} />
            </FormControl.Label>
            <DropdownMenu position="bottom-start">
              <DropdownMenu.Trigger>
                {(attributes) => (
                  <Button
                    className={styles.dropdown__button}
                    variant="outline"
                    fullWidth
                    attributes={attributes}
                    endIcon={ArrowDown}
                  >
                    <Text color="neutral" variant="body-2">
                      {state.language_preference !== "" ? t(state.language_preference) : ""}
                    </Text>
                  </Button>
                )}
              </DropdownMenu.Trigger>
              <DropdownMenu.Content>
                {languagePreferences.map((language, index) => {
                  return (
                    <DropdownMenu.Item
                      onClick={() => handleLanguagePreference({ value: language.value })}
                      key={index}
                    >
                      {t(language.value)}
                    </DropdownMenu.Item>
                  );
                })}
              </DropdownMenu.Content>
            </DropdownMenu>
          </FormControl>
        </CooperInputWrapper>
      </View.Item>
    </>
  );
});
