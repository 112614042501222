import { useMemo } from "react";
import { Avatar, Button, Icon, Text, useToast, useToggle, View } from "reshaped";

import { Options } from "icons";
import { backendAPI } from "utils/axios";
import useName from "components/hooks/useName";
import useRating from "components/hooks/useRating";
import useSpacing from "components/hooks/useSpacing";
import useFullName from "components/hooks/useFullName";
import { CandidateRating } from "components/reusables";
import { useDispatch, useSelector } from "react-redux";
import { reloadOnUnauthorized } from "utils/checkForAuth";
import { ShowToastWithTranslation } from "utils/showToast";
import useTranslation from "components/hooks/useTranslation";
import { viewingCandidateSlice } from "redux/viewingCandidate";
import styles from "components/styles/reshaped/candidates.module.css";
import ConfirmationModal from "components/reusables/modals/ConfirmationModal";
import { OperationFailedWithTranslation } from "components/reusables/OperationFailed";
import DataManipulateDropdown from "components/reusables/dropdowns/DataManipulateDropdown";

const Evaluation = (props) => {
  const { evaluation, noManipulations = false } = props;
  const { user, rating, comments } = evaluation;
  const { first_name, last_name, id } = user;

  const toast = useToast();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { sm, md, lg, xl } = useSpacing();
  const userRating = useRating({ rating: Number(rating) });
  const { active, activate, deactivate } = useToggle(false);
  const { user: loggedInUser } = useSelector((state) => state.auth);
  const userProfile = useName({ first_name, last_name, info: user });
  const { currentCandidate } = useSelector((state) => state.viewingCandidate);
  const fullName = useFullName({ first_name, last_name, dependency: [user] });

  const { actions } = viewingCandidateSlice;
  const { setLoading, removeEvaluation } = actions;

  const options = useMemo(() => {
    return [
      {
        value: "Edit",
        disable: loggedInUser.id !== id,
      },
      {
        value: "Delete",
        disable: loggedInUser.id !== id,
      },
    ];

    //eslint-disable-next-line
  }, []);

  const deleteEvaluation = async () => {
    try {
      if (loggedInUser.id !== id) return;
      dispatch(setLoading());
      const response = await backendAPI.delete(
        `/candidates/${currentCandidate?.id}/evaluations/${evaluation.id}`
      );
      const { data } = response;
      if (data?.status && data.status === 200 && data.message === "successfully deleted") {
        const payload = {
          id: evaluation.id,
          average_rating: data.average_rating,
          candidate_id: currentCandidate.id,
        };
        dispatch(removeEvaluation(payload));
        ShowToastWithTranslation({
          toast,
          Children: EvaluationDeleted,
          text: t("Evaluation deleted!"),
        });
      } else {
        ShowToastWithTranslation({
          toast,
          Children: OperationFailedWithTranslation,
          text: t("Operation unsuccessful!"),
        });
      }
      dispatch(setLoading(false));
    } catch (error) {
      reloadOnUnauthorized(error);
      dispatch(setLoading(false));
      ShowToastWithTranslation({
        toast,
        Children: OperationFailedWithTranslation,
        text: t("Operation unsuccessful!"),
      });
    }
  };

  const handleEvaluationManipulation = ({ selectedOption }) => {
    if (selectedOption.value === "Edit") {
      props.setEditEvaluationID(evaluation.id);
    }
    if (selectedOption.value === "Delete") {
      activate();
    }
  };

  const handleEvaluationDelete = (action) => {
    if (action === "accept") {
      deleteEvaluation();
    }
    deactivate();
  };

  return (
    <>
      <View
        gap={lg}
        padding={lg}
        borderRadius="medium"
        attributes={{
          style: {
            border: `0.5px solid var(--rs-color-foreground-disabled)`,
            position: "relative",
          },
        }}
      >
        {!noManipulations && (
          <View className={styles.evaluation__dropdown}>
            <DataManipulateDropdown
              options={options}
              handleOptionSelection={handleEvaluationManipulation}
              DisplayComponent={DropdownFrontDisplay}
              position="bottom-end"
            />
          </View>
        )}
        <View gap={md} direction="row" align="center">
          <View direction="row" align="center" gap={sm}>
            <>
              {userProfile.hasURL && <Avatar size={xl} src={userProfile.url} alt={fullName} />}
              {!userProfile.hasURL && (
                <Avatar size={xl} initials={userProfile.letters} alt={fullName} />
              )}
            </>
            <Text
              attributes={{
                style: {
                  textTransform: "capitalize",
                },
              }}
              color="neutral"
              variant="body-strong-1"
            >
              {fullName}
            </Text>
          </View>
          <View.Item grow>
            <View
              direction="row"
              gap={lg}
              align="center"
              justify="end"
              attributes={{
                style: {
                  marginInlineEnd: " calc( var(--spacing-xl) + var(--spacing-sm))",
                },
              }}
            >
              <Text
                variant="caption-1"
                attributes={{
                  style: {
                    color: `${userRating.textColor}`,
                  },
                }}
              >
                {t(userRating.text)}
              </Text>
              <View
                borderRadius="small"
                padding={[sm, xl]}
                attributes={{
                  style: {
                    background: `${userRating.background}`,
                    width: "max-content",
                  },
                }}
              >
                <CandidateRating
                  pointsSecured={rating}
                  fill={userRating.fill}
                  emptyFill={userRating.emptyFill}
                  stroke={userRating.strokeColor}
                  emptyStroke={userRating.emptyStrokeColor}
                />
              </View>
            </View>
          </View.Item>
        </View>
        {comments && (
          <View className={styles.evaluation}>
            <View
              className="html__content"
              attributes={{
                dangerouslySetInnerHTML: { __html: comments },
              }}
            />
          </View>
        )}
      </View>
      <ConfirmationModal
        activate={activate}
        active={active}
        deactivate={deactivate}
        acceptText={"Delete"}
        title={t("Delete evaluation?")}
        subTitle={t("Are you sure you want to delete evaluation?")}
        handleAction={handleEvaluationDelete}
      />
    </>
  );
};

export default Evaluation;

function DropdownFrontDisplay({ attributes }) {
  const { md } = useSpacing();

  return (
    <Button attributes={attributes} color="inherit" variant="ghost" size="small">
      <Icon size={md} svg={Options} />
    </Button>
  );
}

const EvaluationDeleted = ({ children }) => {
  return (
    <Text color="positive" variant="body-strong-1">
      {children}
    </Text>
  );
};
