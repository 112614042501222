import { View } from "reshaped";
import { Slot } from "components/pages/promote/slot";
import useSpacing from "components/hooks/useSpacing";

export const Slots = ({ slots, jobBoard }) => {
  const { xl } = useSpacing();

  return (
    <View gap={xl}>
      {slots.map((slot) => {
        return <Slot key={slot.id} jobBoard={jobBoard} slot={slot} />;
      })}
    </View>
  );
};
