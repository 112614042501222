export const germanToEnglish = {
  Afghanistan: "Afghanistan",
  Ägypten: "Egypt",
  Åland: "Åland Islands",
  Albanien: "Albania",
  Algerien: "Algeria",
  "Amerikanisch-Samoa": "American Samoa",
  "Amerikanische Jungferninseln": "Virgin Islands (U.S.)",
  Andorra: "Andorra",
  Angola: "Angola",
  Anguilla: "Anguilla",
  "Antarktis (Sonderstatus durch Antarktisvertrag)": "Antarctica",
  "Antigua und Barbuda": "Antigua and Barbuda",
  Äquatorialguinea: "Equatorial Guinea",
  Argentinien: "Argentina",
  Armenien: "Armenia",
  Aruba: "Aruba",
  Aserbaidschan: "Azerbaijan",
  Äthiopien: "Ethiopia",
  Australien: "Australia",
  Bahamas: "Bahamas",
  Bahrain: "Bahrain",
  Bangladesch: "Bangladesh",
  Barbados: "Barbados",
  Belarus: "Belarus",
  Belgien: "Belgium",
  Belize: "Belize",
  Benin: "Benin",
  Bermuda: "Bermuda",
  Bhutan: "Bhutan",
  Bolivien: "Bolivia (Plurinational State of)",
  "Bonaire, Saba, Sint Eustatius": "Bonaire, Sint Eustatius and Saba",
  "Bosnien und Herzegowina": "Bosnia and Herzegovina",
  Botswana: "Botswana",
  Bouvetinsel: "Bouvet Island",
  Brasilien: "Brazil",
  "Britische Jungferninseln": "Virgin Islands (British)",
  "Britisches Territorium im Indischen Ozean": "British Indian Ocean Territory",
  Brunei: "Brunei Darussalam",
  Bulgarien: "Bulgaria",
  "Burkina Faso": "Burkina Faso",
  Burundi: "Burundi",
  Chile: "Chile",
  "Volksrepublik China": "China",
  Cookinseln: "Cook Islands",
  "Costa Rica": "Costa Rica",
  Curaçao: "Curaçao",
  Dänemark: "Denmark",
  Deutschland: "Germany",
  Dominica: "Dominica",
  "Dominikanische Republik": "Dominican Republic",
  Dschibuti: "Djibouti",
  Ecuador: "Ecuador",
  Elfenbeinküste: "Côte d'Ivoire",
  "El Salvador": "El Salvador",
  Eritrea: "Eritrea",
  Estland: "Estonia",
  Eswatini: "Eswatini",
  Falklandinseln: "Falkland Islands (Malvinas)",
  Färöer: "Faroe Islands",
  Fidschi: "Fiji",
  Finnland: "Finland",
  Frankreich: "France",
  "Französisch-Guayana": "French Guiana",
  "Französisch-Polynesien": "French Polynesia",
  "Französische Süd- und Antarktisgebiete": "French Southern Territories",
  Gabun: "Gabon",
  Gambia: "Gambia",
  Georgien: "Georgia",
  Ghana: "Ghana",
  Gibraltar: "Gibraltar",
  Grenada: "Grenada",
  Griechenland: "Greece",
  Grönland: "Greenland",
  Guadeloupe: "Guadeloupe",
  Guam: "Guam",
  Guatemala: "Guatemala",
  "Guernsey (Kanalinsel)": "Guernsey",
  Guinea: "Guinea",
  "Guinea-Bissau": "Guinea-Bissau",
  Guyana: "Guyana",
  Haiti: "Haiti",
  "Heard und McDonaldinseln": "Heard Island and McDonald Islands",
  Honduras: "Honduras",
  Hongkong: "Hong Kong",
  Indien: "India",
  Indonesien: "Indonesia",
  "Insel Man": "Isle of Man",
  Irak: "Iraq",
  Iran: "Iran (Islamic Republic of)",
  Irland: "Ireland",
  Island: "Iceland",
  Israel: "Israel",
  Italien: "Italy",
  Jamaika: "Jamaica",
  Japan: "Japan",
  Jemen: "Yemen",
  "Jersey (Kanalinsel)": "Jersey",
  Jordanien: "Jordan",
  Kaimaninseln: "Cayman Islands",
  Kambodscha: "Cambodia",
  Kamerun: "Cameroon",
  Kanada: "Canada",
  "Kap Verde": "Cabo Verde",
  Kasachstan: "Kazakhstan",
  Katar: "Qatar",
  Kenia: "Kenya",
  Kirgisistan: "Kyrgyzstan",
  Kiribati: "Kiribati",
  Kokosinseln: "Cocos (Keeling) Islands",
  Kolumbien: "Colombia",
  Komoren: "Comoros",
  "Kongo, Demokratische Republik": "Congo, Democratic Republic of the",
  "Kongo, Republik": "Congo",
  "Korea, Nord (Nordkorea)": "Korea (Democratic People's Republic of)",
  "Korea, Süd (Südkorea)": "Korea, Republic of",
  Kroatien: "Croatia",
  Kuba: "Cuba",
  Kuwait: "Kuwait",
  Laos: "Lao People's Democratic Republic",
  Lesotho: "Lesotho",
  Lettland: "Latvia",
  Libanon: "Lebanon",
  Liberia: "Liberia",
  Libyen: "Libya",
  Liechtenstein: "Liechtenstein",
  Litauen: "Lithuania",
  Luxemburg: "Luxembourg",
  Macau: "Macao",
  Madagaskar: "Madagascar",
  Malawi: "Malawi",
  Malaysia: "Malaysia",
  Malediven: "Maldives",
  Mali: "Mali",
  Malta: "Malta",
  Marokko: "Morocco",
  Marshallinseln: "Marshall Islands",
  Martinique: "Martinique",
  Mauretanien: "Mauritania",
  Mauritius: "Mauritius",
  Mayotte: "Mayotte",
  Mexiko: "Mexico",
  Mikronesien: "Micronesia (Federated States of)",
  Moldau: "Moldova, Republic of",
  Monaco: "Monaco",
  Mongolei: "Mongolia",
  Montenegro: "Montenegro",
  Montserrat: "Montserrat",
  Mosambik: "Mozambique",
  Myanmar: "Myanmar",
  Namibia: "Namibia",
  Nauru: "Nauru",
  Nepal: "Nepal",
  Neukaledonien: "New Caledonia",
  Neuseeland: "New Zealand",
  Nicaragua: "Nicaragua",
  Niederlande: "Netherlands",
  Niger: "Niger",
  Nigeria: "Nigeria",
  Niue: "Niue",
  "Nördliche Marianen": "Northern Mariana Islands",
  Nordmazedonien: "North Macedonia",
  Norfolkinsel: "Norfolk Island",
  Norwegen: "Norway",
  Oman: "Oman",
  Österreich: "Austria",
  Osttimor: "Timor-Leste",
  Pakistan: "Pakistan",
  Palästina: "Palestine, State of",
  Palau: "Palau",
  Panama: "Panama",
  "Papua-Neuguinea": "Papua New Guinea",
  Paraguay: "Paraguay",
  Peru: "Peru",
  Philippinen: "Philippines",
  Pitcairninseln: "Pitcairn",
  Polen: "Poland",
  Portugal: "Portugal",
  "Puerto Rico": "Puerto Rico",
  Réunion: "Réunion",
  Ruanda: "Rwanda",
  Rumänien: "Romania",
  Russland: "Russian Federation",
  Salomonen: "Solomon Islands",
  "Saint-Barthélemy": "Saint Barthélemy",
  "Saint-Martin (französischer Teil)": "Saint Martin (French part)",
  Sambia: "Zambia",
  Samoa: "Samoa",
  "San Marino": "San Marino",
  "São Tomé und Príncipe": "Sao Tome and Principe",
  "Saudi-Arabien": "Saudi Arabia",
  Schweden: "Sweden",
  Schweiz: "Switzerland",
  Senegal: "Senegal",
  Serbien: "Serbia",
  Seychellen: "Seychelles",
  "Sierra Leone": "Sierra Leone",
  Simbabwe: "Zimbabwe",
  Singapur: "Singapore",
  "Sint Maarten": "Sint Maarten (Dutch part)",
  Slowakei: "Slovakia",
  Slowenien: "Slovenia",
  Somalia: "Somalia",
  Spanien: "Spain",
  "Sri Lanka": "Sri Lanka",
  "St. Helena, Ascension und Tristan da Cunha": "Saint Helena, Ascension and Tristan da Cunha",
  "St. Kitts und Nevis": "Saint Kitts and Nevis",
  "St. Lucia": "Saint Lucia",
  "Saint-Pierre und Miquelon": "Saint Pierre and Miquelon",
  "St. Vincent und die Grenadinen": "Saint Vincent and the Grenadines",
  Südafrika: "South Africa",
  Sudan: "Sudan",
  "Südgeorgien und die Südlichen Sandwichinseln": "South Georgia and the South Sandwich Islands",
  Südsudan: "South Sudan",
  Suriname: "Suriname",
  "Spitzbergen und Jan Mayen": "Svalbard and Jan Mayen",
  Syrien: "Syrian Arab Republic",
  Tadschikistan: "Tajikistan",
  "Republik China": "Taiwan, Province of China",
  Tansania: "Tanzania, United Republic of",
  Thailand: "Thailand",
  Togo: "Togo",
  Tokelau: "Tokelau",
  Tonga: "Tonga",
  "Trinidad und Tobago": "Trinidad and Tobago",
  Tschad: "Chad",
  Tschechien: "Czechia",
  Tunesien: "Tunisia",
  Türkei: "Türkiye",
  Turkmenistan: "Turkmenistan",
  "Turks- und Caicosinseln": "Turks and Caicos Islands",
  Tuvalu: "Tuvalu",
  Uganda: "Uganda",
  Ukraine: "Ukraine",
  Ungarn: "Hungary",
  "United States Minor Outlying Islands": "United States Minor Outlying Islands",
  Uruguay: "Uruguay",
  Usbekistan: "Uzbekistan",
  Vanuatu: "Vanuatu",
  Vatikanstadt: "Holy See",
  Venezuela: "Venezuela (Bolivarian Republic of)",
  "Vereinigte Arabische Emirate": "United Arab Emirates",
  "Vereinigte Staaten": "United States of America",
  "Vereinigtes Königreich": "United Kingdom",
  Vietnam: "Viet Nam",
  "Wallis und Futuna": "Wallis and Futuna",
  Weihnachtsinsel: "Christmas Island",
  Westsahara: "Western Sahara",
  "Zentralafrikanische Republik": "Central African Republic",
  Zypern: "Cyprus",
  USA: "USA",
};

export const englishToGerman = {
  Afghanistan: "Afghanistan",
  Egypt: "Ägypten",
  "Åland Islands": "Åland",
  Albania: "Albanien",
  Algeria: "Algerien",
  "American Samoa": "Amerikanisch-Samoa",
  "Virgin Islands (U.S.)": "Amerikanische Jungferninseln",
  Andorra: "Andorra",
  Angola: "Angola",
  Anguilla: "Anguilla",
  Antarctica: "Antarktis (Sonderstatus durch Antarktisvertrag)",
  "Antigua and Barbuda": "Antigua und Barbuda",
  "Equatorial Guinea": "Äquatorialguinea",
  Argentina: "Argentinien",
  Armenia: "Armenien",
  Aruba: "Aruba",
  Azerbaijan: "Aserbaidschan",
  Ethiopia: "Äthiopien",
  Australia: "Australien",
  Bahamas: "Bahamas",
  Bahrain: "Bahrain",
  Bangladesh: "Bangladesch",
  Barbados: "Barbados",
  Belarus: "Belarus",
  Belgium: "Belgien",
  Belize: "Belize",
  Benin: "Benin",
  Bermuda: "Bermuda",
  Bhutan: "Bhutan",
  "Bolivia (Plurinational State of)": "Bolivien",
  "Bonaire, Sint Eustatius and Saba": "Bonaire, Saba, Sint Eustatius",
  "Bosnia and Herzegovina": "Bosnien und Herzegowina",
  Botswana: "Botswana",
  "Bouvet Island": "Bouvetinsel",
  Brazil: "Brasilien",
  "Virgin Islands (British)": "Britische Jungferninseln",
  "British Indian Ocean Territory": "Britisches Territorium im Indischen Ozean",
  "Brunei Darussalam": "Brunei",
  Bulgaria: "Bulgarien",
  "Burkina Faso": "Burkina Faso",
  Burundi: "Burundi",
  Chile: "Chile",
  China: "Volksrepublik China",
  "Cook Islands": "Cookinseln",
  "Costa Rica": "Costa Rica",
  Curaçao: "Curaçao",
  Denmark: "Dänemark",
  Germany: "Deutschland",
  Dominica: "Dominica",
  "Dominican Republic": "Dominikanische Republik",
  Djibouti: "Dschibuti",
  Ecuador: "Ecuador",
  "Côte d'Ivoire": "Elfenbeinküste",
  "El Salvador": "El Salvador",
  Eritrea: "Eritrea",
  Estonia: "Estland",
  Eswatini: "Eswatini",
  "Falkland Islands (Malvinas)": "Falklandinseln",
  "Faroe Islands": "Färöer",
  Fiji: "Fidschi",
  Finland: "Finnland",
  France: "Frankreich",
  "French Guiana": "Französisch-Guayana",
  "French Polynesia": "Französisch-Polynesien",
  "French Southern Territories": "Französische Süd- und Antarktisgebiete",
  Gabon: "Gabun",
  Gambia: "Gambia",
  Georgia: "Georgien",
  Ghana: "Ghana",
  Gibraltar: "Gibraltar",
  Grenada: "Grenada",
  Greece: "Griechenland",
  Greenland: "Grönland",
  Guadeloupe: "Guadeloupe",
  Guam: "Guam",
  Guatemala: "Guatemala",
  Guernsey: "Guernsey (Kanalinsel)",
  Guinea: "Guinea",
  "Guinea-Bissau": "Guinea-Bissau",
  Guyana: "Guyana",
  Haiti: "Haiti",
  "Heard Island and McDonald Islands": "Heard und McDonaldinseln",
  Honduras: "Honduras",
  "Hong Kong": "Hongkong",
  India: "Indien",
  Indonesia: "Indonesien",
  "Isle of Man": "Insel Man",
  Iraq: "Irak",
  "Iran (Islamic Republic of)": "Iran",
  Ireland: "Irland",
  Iceland: "Island",
  Israel: "Israel",
  Italy: "Italien",
  Jamaica: "Jamaika",
  Japan: "Japan",
  Yemen: "Jemen",
  Jersey: "Jersey (Kanalinsel)",
  Jordan: "Jordanien",
  "Cayman Islands": "Kaimaninseln",
  Cambodia: "Kambodscha",
  Cameroon: "Kamerun",
  Canada: "Kanada",
  "Cabo Verde": "Kap Verde",
  Kazakhstan: "Kasachstan",
  Qatar: "Katar",
  Kenya: "Kenia",
  Kyrgyzstan: "Kirgisistan",
  Kiribati: "Kiribati",
  "Cocos (Keeling) Islands": "Kokosinseln",
  Colombia: "Kolumbien",
  Comoros: "Komoren",
  "Congo, Democratic Republic of the": "Kongo, Demokratische Republik",
  Congo: "Kongo, Republik",
  "Korea (Democratic People's Republic of)": "Korea, Nord (Nordkorea)",
  "Korea, Republic of": "Korea, Süd (Südkorea)",
  Croatia: "Kroatien",
  Cuba: "Kuba",
  Kuwait: "Kuwait",
  "Lao People's Democratic Republic": "Laos",
  Lesotho: "Lesotho",
  Latvia: "Lettland",
  Lebanon: "Libanon",
  Liberia: "Liberia",
  Libya: "Libyen",
  Liechtenstein: "Liechtenstein",
  Lithuania: "Litauen",
  Luxembourg: "Luxemburg",
  Macao: "Macau",
  Madagascar: "Madagaskar",
  Malawi: "Malawi",
  Malaysia: "Malaysia",
  Maldives: "Malediven",
  Mali: "Mali",
  Malta: "Malta",
  Morocco: "Marokko",
  "Marshall Islands": "Marshallinseln",
  Martinique: "Martinique",
  Mauritania: "Mauretanien",
  Mauritius: "Mauritius",
  Mayotte: "Mayotte",
  Mexico: "Mexiko",
  "Micronesia (Federated States of)": "Mikronesien",
  "Moldova, Republic of": "Moldau",
  Monaco: "Monaco",
  Mongolia: "Mongolei",
  Montenegro: "Montenegro",
  Montserrat: "Montserrat",
  Mozambique: "Mosambik",
  Myanmar: "Myanmar",
  Namibia: "Namibia",
  Nauru: "Nauru",
  Nepal: "Nepal",
  "New Caledonia": "Neukaledonien",
  "New Zealand": "Neuseeland",
  Nicaragua: "Nicaragua",
  Netherlands: "Niederlande",
  Niger: "Niger",
  Nigeria: "Nigeria",
  Niue: "Niue",
  "Northern Mariana Islands": "Nördliche Marianen",
  "North Macedonia": "Nordmazedonien",
  "Norfolk Island": "Norfolkinsel",
  Norway: "Norwegen",
  Oman: "Oman",
  Austria: "Österreich",
  "Timor-Leste": "Osttimor",
  Pakistan: "Pakistan",
  "Palestine, State of": "Palästina",
  Palau: "Palau",
  Panama: "Panama",
  "Papua New Guinea": "Papua-Neuguinea",
  Paraguay: "Paraguay",
  Peru: "Peru",
  Philippines: "Philippinen",
  Pitcairn: "Pitcairninseln",
  Poland: "Polen",
  Portugal: "Portugal",
  "Puerto Rico": "Puerto Rico",
  Réunion: "Réunion",
  Rwanda: "Ruanda",
  Romania: "Rumänien",
  "Russian Federation": "Russland",
  "Solomon Islands": "Salomonen",
  "Saint Barthélemy": "Saint-Barthélemy",
  "Saint Martin (French part)": "Saint-Martin (französischer Teil)",
  Zambia: "Sambia",
  Samoa: "Samoa",
  "San Marino": "San Marino",
  "Sao Tome and Principe": "São Tomé und Príncipe",
  "Saudi Arabia": "Saudi-Arabien",
  Sweden: "Schweden",
  Switzerland: "Schweiz",
  Senegal: "Senegal",
  Serbia: "Serbien",
  Seychelles: "Seychellen",
  "Sierra Leone": "Sierra Leone",
  Zimbabwe: "Simbabwe",
  Singapore: "Singapur",
  "Sint Maarten (Dutch part)": "Sint Maarten",
  Slovakia: "Slowakei",
  Slovenia: "Slowenien",
  Somalia: "Somalia",
  Spain: "Spanien",
  "Sri Lanka": "Sri Lanka",
  "Saint Helena, Ascension and Tristan da Cunha": "St. Helena, Ascension und Tristan da Cunha",
  "Saint Kitts and Nevis": "St. Kitts und Nevis",
  "Saint Lucia": "St. Lucia",
  "Saint Pierre and Miquelon": "Saint-Pierre und Miquelon",
  "Saint Vincent and the Grenadines": "St. Vincent und die Grenadinen",
  "South Africa": "Südafrika",
  Sudan: "Sudan",
  "South Georgia and the South Sandwich Islands": "Südgeorgien und die Südlichen Sandwichinseln",
  "South Sudan": "Südsudan",
  Suriname: "Suriname",
  "Svalbard and Jan Mayen": "Spitzbergen und Jan Mayen",
  "Syrian Arab Republic": "Syrien",
  Tajikistan: "Tadschikistan",
  "Taiwan, Province of China": "Republik China",
  "Tanzania, United Republic of": "Tansania",
  Thailand: "Thailand",
  Togo: "Togo",
  Tokelau: "Tokelau",
  Tonga: "Tonga",
  "Trinidad and Tobago": "Trinidad und Tobago",
  Chad: "Tschad",
  Czechia: "Tschechien",
  Tunisia: "Tunesien",
  Türkiye: "Türkei",
  Turkmenistan: "Turkmenistan",
  "Turks and Caicos Islands": "Turks- und Caicosinseln",
  Tuvalu: "Tuvalu",
  Uganda: "Uganda",
  Ukraine: "Ukraine",
  Hungary: "Ungarn",
  "United States Minor Outlying Islands": "United States Minor Outlying Islands",
  Uruguay: "Uruguay",
  Uzbekistan: "Usbekistan",
  Vanuatu: "Vanuatu",
  "Holy See": "Vatikanstadt",
  "Venezuela (Bolivarian Republic of)": "Venezuela",
  "United Arab Emirates": "Vereinigte Arabische Emirate",
  "United States of America": "Vereinigte Staaten",
  "United Kingdom": "Vereinigtes Königreich",
  "Viet Nam": "Vietnam",
  "Wallis and Futuna": "Wallis und Futuna",
  "Christmas Island": "Weihnachtsinsel",
  "Western Sahara": "Westsahara",
  "Central African Republic": "Zentralafrikanische Republik",
  Cyprus: "Zypern",
  USA: "USA",
};

export const englishToEnglish = {
  Afghanistan: "Afghanistan",
  "Åland Islands": "Åland Islands",
  Albania: "Albania",
  Algeria: "Algeria",
  "American Samoa": "American Samoa",
  Andorra: "Andorra",
  Angola: "Angola",
  Anguilla: "Anguilla",
  Antarctica: "Antarctica",
  "Antigua and Barbuda": "Antigua and Barbuda",
  Argentina: "Argentina",
  Armenia: "Armenia",
  Aruba: "Aruba",
  Australia: "Australia",
  Austria: "Austria",
  Azerbaijan: "Azerbaijan",
  Bahamas: "Bahamas",
  Bahrain: "Bahrain",
  Bangladesh: "Bangladesh",
  Barbados: "Barbados",
  Belarus: "Belarus",
  Belgium: "Belgium",
  Belize: "Belize",
  Benin: "Benin",
  Bermuda: "Bermuda",
  Bhutan: "Bhutan",
  "Bolivia (Plurinational State of)": "Bolivia (Plurinational State of)",
  "Bonaire, Sint Eustatius and Saba": "Bonaire, Sint Eustatius and Saba",
  "Bosnia and Herzegovina": "Bosnia and Herzegovina",
  Botswana: "Botswana",
  "Bouvet Island": "Bouvet Island",
  Brazil: "Brazil",
  "British Indian Ocean Territory": "British Indian Ocean Territory",
  "Brunei Darussalam": "Brunei Darussalam",
  Bulgaria: "Bulgaria",
  "Burkina Faso": "Burkina Faso",
  Burundi: "Burundi",
  "Cabo Verde": "Cabo Verde",
  Cambodia: "Cambodia",
  Cameroon: "Cameroon",
  Canada: "Canada",
  "Cayman Islands": "Cayman Islands",
  "Central African Republic": "Central African Republic",
  Chad: "Chad",
  Chile: "Chile",
  China: "China",
  "Christmas Island": "Christmas Island",
  "Cocos (Keeling) Islands": "Cocos (Keeling) Islands",
  Colombia: "Colombia",
  Comoros: "Comoros",
  Congo: "Congo",
  "Congo, Democratic Republic of the": "Congo, Democratic Republic of the",
  "Cook Islands": "Cook Islands",
  "Costa Rica": "Costa Rica",
  "Côte d'Ivoire": "Côte d'Ivoire",
  Croatia: "Croatia",
  Cuba: "Cuba",
  Curaçao: "Curaçao",
  Cyprus: "Cyprus",
  Czechia: "Czechia",
  Denmark: "Denmark",
  Djibouti: "Djibouti",
  Dominica: "Dominica",
  "Dominican Republic": "Dominican Republic",
  Ecuador: "Ecuador",
  Egypt: "Egypt",
  "El Salvador": "El Salvador",
  "Equatorial Guinea": "Equatorial Guinea",
  Eritrea: "Eritrea",
  Estonia: "Estonia",
  Eswatini: "Eswatini",
  Ethiopia: "Ethiopia",
  "Falkland Islands (Malvinas)": "Falkland Islands (Malvinas)",
  "Faroe Islands": "Faroe Islands",
  Fiji: "Fiji",
  Finland: "Finland",
  France: "France",
  "French Guiana": "French Guiana",
  "French Polynesia": "French Polynesia",
  "French Southern Territories": "French Southern Territories",
  Gabon: "Gabon",
  Gambia: "Gambia",
  Georgia: "Georgia",
  Germany: "Germany",
  Ghana: "Ghana",
  Gibraltar: "Gibraltar",
  Greece: "Greece",
  Greenland: "Greenland",
  Grenada: "Grenada",
  Guadeloupe: "Guadeloupe",
  Guam: "Guam",
  Guatemala: "Guatemala",
  Guernsey: "Guernsey",
  Guinea: "Guinea",
  "Guinea-Bissau": "Guinea-Bissau",
  Guyana: "Guyana",
  Haiti: "Haiti",
  "Heard Island and McDonald Islands": "Heard Island and McDonald Islands",
  "Holy See": "Holy See",
  Honduras: "Honduras",
  "Hong Kong": "Hong Kong",
  Hungary: "Hungary",
  Iceland: "Iceland",
  India: "India",
  Indonesia: "Indonesia",
  "Iran (Islamic Republic of)": "Iran (Islamic Republic of)",
  Iraq: "Iraq",
  Ireland: "Ireland",
  "Isle of Man": "Isle of Man",
  Israel: "Israel",
  Italy: "Italy",
  Jamaica: "Jamaica",
  Japan: "Japan",
  Jersey: "Jersey",
  Jordan: "Jordan",
  Kazakhstan: "Kazakhstan",
  Kenya: "Kenya",
  Kiribati: "Kiribati",
  "Korea (Democratic People's Republic of)": "Korea (Democratic People's Republic of)",
  "Korea, Republic of": "Korea, Republic of",
  Kuwait: "Kuwait",
  Kyrgyzstan: "Kyrgyzstan",
  "Lao People's Democratic Republic": "Lao People's Democratic Republic",
  Latvia: "Latvia",
  Lebanon: "Lebanon",
  Lesotho: "Lesotho",
  Liberia: "Liberia",
  Libya: "Libya",
  Liechtenstein: "Liechtenstein",
  Lithuania: "Lithuania",
  Luxembourg: "Luxembourg",
  Macao: "Macao",
  Madagascar: "Madagascar",
  Malawi: "Malawi",
  Malaysia: "Malaysia",
  Maldives: "Maldives",
  Mali: "Mali",
  Malta: "Malta",
  "Marshall Islands": "Marshall Islands",
  Martinique: "Martinique",
  Mauritania: "Mauritania",
  Mauritius: "Mauritius",
  Mayotte: "Mayotte",
  Mexico: "Mexico",
  "Micronesia (Federated States of)": "Micronesia (Federated States of)",
  "Moldova, Republic of": "Moldova, Republic of",
  Monaco: "Monaco",
  Mongolia: "Mongolia",
  Montenegro: "Montenegro",
  Montserrat: "Montserrat",
  Morocco: "Morocco",
  Mozambique: "Mozambique",
  Myanmar: "Myanmar",
  Namibia: "Namibia",
  Nauru: "Nauru",
  Nepal: "Nepal",
  Netherlands: "Netherlands",
  "New Caledonia": "New Caledonia",
  "New Zealand": "New Zealand",
  Nicaragua: "Nicaragua",
  Niger: "Niger",
  Nigeria: "Nigeria",
  Niue: "Niue",
  "Norfolk Island": "Norfolk Island",
  "North Macedonia": "North Macedonia",
  "Northern Mariana Islands": "Northern Mariana Islands",
  Norway: "Norway",
  Oman: "Oman",
  Pakistan: "Pakistan",
  Palau: "Palau",
  "Palestine, State of": "Palestine, State of",
  Panama: "Panama",
  "Papua New Guinea": "Papua New Guinea",
  Paraguay: "Paraguay",
  Peru: "Peru",
  Philippines: "Philippines",
  Pitcairn: "Pitcairn",
  Poland: "Poland",
  Portugal: "Portugal",
  "Puerto Rico": "Puerto Rico",
  Qatar: "Qatar",
  Réunion: "Réunion",
  Romania: "Romania",
  "Russian Federation": "Russian Federation",
  Rwanda: "Rwanda",
  "Saint Barthélemy": "Saint Barthélemy",
  "Saint Helena, Ascension and Tristan da Cunha": "Saint Helena, Ascension and Tristan da Cunha",
  "Saint Kitts and Nevis": "Saint Kitts and Nevis",
  "Saint Lucia": "Saint Lucia",
  "Saint Martin (French part)": "Saint Martin (French part)",
  "Saint Pierre and Miquelon": "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines": "Saint Vincent and the Grenadines",
  Samoa: "Samoa",
  "San Marino": "San Marino",
  "Sao Tome and Principe": "Sao Tome and Principe",
  "Saudi Arabia": "Saudi Arabia",
  Senegal: "Senegal",
  Serbia: "Serbia",
  Seychelles: "Seychelles",
  "Sierra Leone": "Sierra Leone",
  Singapore: "Singapore",
  "Sint Maarten (Dutch part)": "Sint Maarten (Dutch part)",
  Slovakia: "Slovakia",
  Slovenia: "Slovenia",
  "Solomon Islands": "Solomon Islands",
  Somalia: "Somalia",
  "South Africa": "South Africa",
  "South Georgia and the South Sandwich Islands": "South Georgia and the South Sandwich Islands",
  "South Sudan": "South Sudan",
  Spain: "Spain",
  "Sri Lanka": "Sri Lanka",
  Sudan: "Sudan",
  Suriname: "Suriname",
  "Svalbard and Jan Mayen": "Svalbard and Jan Mayen",
  Sweden: "Sweden",
  Switzerland: "Switzerland",
  "Syrian Arab Republic": "Syrian Arab Republic",
  "Taiwan, Province of China": "Taiwan, Province of China",
  Tajikistan: "Tajikistan",
  "Tanzania, United Republic of": "Tanzania, United Republic of",
  Thailand: "Thailand",
  "Timor-Leste": "Timor-Leste",
  Togo: "Togo",
  Tokelau: "Tokelau",
  Tonga: "Tonga",
  "Trinidad and Tobago": "Trinidad and Tobago",
  Tunisia: "Tunisia",
  Türkiye: "Türkiye",
  Turkmenistan: "Turkmenistan",
  "Turks and Caicos Islands": "Turks and Caicos Islands",
  Tuvalu: "Tuvalu",
  Uganda: "Uganda",
  Ukraine: "Ukraine",
  "United Arab Emirates": "United Arab Emirates",
  "United Kingdom": "United Kingdom",
  "United States of America": "United States of America",
  "United States Minor Outlying Islands": "United States Minor Outlying Islands",
  Uruguay: "Uruguay",
  Uzbekistan: "Uzbekistan",
  Vanuatu: "Vanuatu",
  "Venezuela (Bolivarian Republic of)": "Venezuela (Bolivarian Republic of)",
  "Viet Nam": "Viet Nam",
  "Virgin Islands (British)": "Virgin Islands (British)",
  "Virgin Islands (U.S.)": "Virgin Islands (U.S.)",
  "Wallis and Futuna": "Wallis and Futuna",
  "Western Sahara": "Western Sahara",
  Yemen: "Yemen",
  Zambia: "Zambia",
  Zimbabwe: "Zimbabwe",
  USA: "USA",
};

export const supportedGermanToEnglish = {
  Deutschland: "Germany",
  "Vereinigtes Königreich": "United Kingdom",
  Frankreich: "France",
  USA: "USA",
};

export const supportedEnglishToEnglish = {
  Germany: "Germany",
  "United Kingdom": "United Kingdom",
  France: "France",
  USA: "USA",
};