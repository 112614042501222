export const accent = {
  "1-100": "#FFFBFA",
  "1-200": "#FFEEEB",
  "1-300": "#FDC3B6",
  "1-400": "#FC9882",
  "1-500": "#F66137",
  "1-600": "#E23F12",
  "2-100": "#F4FFFA",
  "2-200": "#E5FFF0",
  "2-300": "#90FDC5",
  "2-400": "#36FCA0",
  "2-500": "#01E486",
  "2-600": "#00AD71",
  "3-100": "#FDF7FF",
  "3-200": "#FBF0FF",
  "3-300": "#E2C0Ef",
  "3-400": "#D092DD",
  "3-500": "#A73AA7",
  "3-600": "#75226E",
  "4-100": "#FFFBF5",
  "4-200": "#FFEDD6",
  "4-300": "#FED69F",
  "4-400": "#FCC673",
  "4-500": "#F4AF34",
  "4-600": "#E69A05",
  "5-100": "#FFF3E9",
  "5-400": "#FFB06A",
  "5-500": "#F47D34",
};

export const baseColor = {
  primary: "#166EE9",
  positive: "#0BA336",
  critical: "#D61306",
  white: "#FFFFFF",
  black: "#000000",
};

export const cooperColors = {
  "foreground-neutral": "#2c2c2c",
  "foreground-neutral-faded": "#6B6B6B",
  "foreground-disabled": "#D1D1D1",
  "foreground-primary": "#166EE9",
  "foreground-positive": "#0BA336",
  "foreground-critical": "#D61306",
  "background-neutral": "#ECEFF3",
  "foreground-dark-neutral-faded": "#888888",
  "background-page-disabled-faded": "#F3F3F3",
  "background-neutral-faded": "#F3F5F7",
  "background-neutral-highlighted": "#FCFCFD",
  "background-disabled": "#EBEEF2",
  "background-disabled-faded": "#F8F9FB",
  "background-primary": "#1F74EA",
  "background-primary-faded": "#E7F0FD",
  "background-primary-highlighted": "#71A8F4",
  "background-positive": "#179B41",
  "background-positive-faded": "#E6FEEE",
  "background-positive-highlighted": "#009930",
  "background-critical": "#C51307",
  "background-critical-faded": "#FDE8E7",
  "background-critical-highlighted": "#E94135",
  "border-neutral": "#BBC1D3",
  "border-neutral-faded": "#DFE2EA",
  "border-disabled": "#DFE2EA",
  "border-primary": "#166EE9",
  "border-primary-faded": "#D0E2FB",
  "border-positive": "#0BA336",
  "border-positive-faded": "#CDEDD6",
  "border-critical": "#D61306",
  "border-critical-faded": "#FBD3D0",
  "background-page": "#F8F9FB",
  "background-page-faded": "#FCFCFD",
  "background-base": "#FFFFFF",
  "background-elevated": "#FFFFFF",
  black: "#000000",
  white: "#FFFFFF",
  "background-primary-shaded": "#0A3D8A",
  "foreground-primary-shaded": "#0A3D8A",
  "on-background-neutral": "#000000",
  "text-button-gray-color": "#6B6B6B",
  "border-chips-dark-neutral-faded": "#E2E2E2",
  "icon-stroke-dark-neutral-faded": "#2B244C"
};
