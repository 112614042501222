import { stepOneProgressHelper } from "utils/jobStepProgress";

export const properties = {
  anything_else: "ANYTHING_ELSE",
  application_deadline: "APPLICATION_DEADLINE",
  benefits: "BENEFITS",
  category: "CATEGORY",
  city: "CITY",
  country: "COUNTRY",
  currency: "CURRENCY",
  department: "DEPARTMENT",
  education: "EDUCATION",
  employment_contract: "EMPLOYMENT_CONTRACT",
  experience: "EXPERIENCE",
  language_application_form: "LANGUAGE_APPLICATION_FORM",
  requirements: "REQUIREMENTS",
  salary_type: "SALARY_TYPE",
  short_intro: "SHORT_INTRO",
  tasks: "TASKS",
  title: "TITLE",
  zip_code: "ZIP_CODE",
};

export function updateProgressWithOldState({ oldState, setProgressState }) {
  for (let key in oldState) {
    const oldStateKey = key;
    if (oldStateKey in properties) {
      if (oldState[oldStateKey]) {
        const propertiesKey = properties[oldStateKey];
        stepOneProgressHelper(propertiesKey, setProgressState, "add");
      }
    }
  }
}

export function updateProgressWithOldStateSalaries({ oldState, setProgressState }) {
  if (!oldState["salary_kind"]) {
    return;
  }
  if (oldState["salary_kind"] === "single" || oldState["salary_kind"] === "range") {
    stepOneProgressHelper("MIN_SALARY", setProgressState, "add", "single");
  }
}
