import { useEffect, useState } from "react";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { removeHtmlTagsWithAttributes } from "utils/regexHelpers";
import { isNumber, isOnlyString, isString } from "utils/miniHelpers";
import "components/styles/reshaped/reusables/chat/customTextEditor.css";

export function PlainTextEditor({
  value = "",
  placeholder = "",
  onBlur,
  onFocus,
  onChange,
  error,
  CountComponent,
  wrapperClassName,
  showCount = false,
  maxCharactersLength = 8000,
  formats = ["bold", "italic", "underline", "list", "bullet", "strike"],
}) {
  const [count, setCount] = useState(() => {
    if (isOnlyString(value)) {
      const textContent = removeHtmlTagsWithAttributes({ text: value, shouldTrim: false });
      if (isOnlyString(textContent)) return textContent.length;
      return 0;
    }
  });
  const [state, setState] = useState(() => {
    if (isOnlyString(value)) return value;
    return "";
  });

  useEffect(() => {
    if (isOnlyString(value)) {
      setState(value);
      const textContent = removeHtmlTagsWithAttributes({ text: value, shouldTrim: false });
      if (isOnlyString(textContent)) {
        setCount(textContent.length);
      }
    }
  }, [value]);

  const [inFocus, setInFocus] = useState(false);

  const handleChange = (value) => {
    const textContent = removeHtmlTagsWithAttributes({ text: value, shouldTrim: false });
    setState(value);
    setCount(textContent.length);
    if (onChange && typeof onChange === "function") onChange(value);
  };

  const handleBlur = () => {
    setInFocus(false);
    if (onBlur && typeof onBlur === "function") onBlur(state);
  };

  const handleFocus = () => {
    setInFocus(true);
    if (onFocus && typeof onFocus === "function") onFocus(state);
  };

  return (
    <div
      className={`quill-custom-wrapper ${isString(wrapperClassName) ? wrapperClassName : ""} ${
        inFocus ? "quill-focus" : error ? "quill-error" : ""
      }`}
    >
      <ReactQuill
        theme="snow"
        value={state}
        key={placeholder}
        formats={formats}
        modules={{
          toolbar: {
            container: "#plain-toolbar",
          },
        }}
        onChange={handleChange}
        placeholder={placeholder}
        onBlur={handleBlur}
        onFocus={handleFocus}
      />
      <Toolbar />
      {showCount && isNumber(maxCharactersLength) && (
        <div className={`quill-custom-character-count`}>
          {CountComponent ? (
            <CountComponent count={count} charactersLength={maxCharactersLength} />
          ) : (
            <p className={`quill-custom-character-text`}>
              {count}/{maxCharactersLength}
            </p>
          )}
        </div>
      )}
    </div>
  );
}

function Toolbar() {
  return (
    <div id="plain-toolbar" className="plain__toolbar">
      <button className="ql-bold"></button>
      <button className="ql-italic"></button>
      <button className="ql-underline"></button>
      <button className="ql-strike"></button>
      <button className="ql-list" value="ordered"></button>
      <button className="ql-list" value="bullet"></button>
    </div>
  );
}
