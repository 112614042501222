import { View, Loader, Text } from "reshaped";
import useSpacing from "components/hooks/useSpacing";
import common from "components/styles/reshaped/reusables/common.module.css";

export const ReshapedLoader = ({
  withNav = false,
  size,
  text,
  loaderClass,
  animationSpeed,
  loaderColor,
}) => {
  const { lg } = useSpacing();
  return (
    <View
      height="100%"
      align="center"
      justify="center"
      width="100%"
      gap={lg}
      className={withNav ? common.loader__nav__wrapper : ""}
    >
      <Loader
        size={size ? size : "medium"}
        className={`${size ? "" : common.loader__big} ${loaderClass ? loaderClass : ""}`}
        attributes={{
          style: {
            ...(animationSpeed ? { animationDuration: animationSpeed } : {}),
          },
        }}
        {...(loaderColor && typeof loaderColor === "string" ? { color: loaderColor } : {})}
      />
      {text && (
        <Text align="center" variant="body-strong-1">
          {text}
        </Text>
      )}
    </View>
  );
};
