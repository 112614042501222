export const ALL = "ALL";
export const POSITION = "POSITION";
export const LAST_NAME = "LAST_NAME";
export const FIRST_NAME = "FIRST_NAME";
export const LANGUAGE_PREFERENCE = "LANGUAGE_PREFERENCE ";

export let initialState = {
  first_name: "",
  last_name: "",
  position: "",
  language_preference: "",
};

export const reducer = (state, action) => {
  if (action.type === ALL) {
    return {
      ...state,
      ...action.payload,
    };
  }
  if (action.type === FIRST_NAME) {
    return {
      ...state,
      first_name: action.payload,
    };
  }
  if (action.type === LAST_NAME) {
    return {
      ...state,
      last_name: action.payload,
    };
  }
  if (action.type === POSITION) {
    return {
      ...state,
      position: action.payload,
    };
  }
  if (action.type === LANGUAGE_PREFERENCE) {
    return {
      ...state,
      language_preference: action.payload,
    };
  }
  return state;
};
