import { cooperColors } from "utils/colors/accents";

export const TagOutline = ({
  color = cooperColors["foreground-neutral"],
  width = "32",
  className,
}) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        {...(className && typeof className === "string" ? { className } : {})}
        d="M24.1532 10L12.4071 10C11.9197 10.0019 11.4529 10.184 11.1085 10.5066L6.5406 14.7792C6.19446 15.103 6 15.5421 6 16C6 16.4579 6.19446 16.897 6.5406 17.2208L11.1085 21.4934C11.2806 21.6546 11.4851 21.7824 11.7102 21.8693C11.9353 21.9563 12.1766 22.0007 12.4202 22L24.1663 22C24.6537 21.9981 25.1205 21.816 25.4649 21.4934C25.809 21.1688 26.0015 20.7298 26 20.2726V11.7274C26.0015 11.2702 25.809 10.8312 25.4649 10.5066C25.2928 10.3454 25.0883 10.2176 24.8632 10.1307C24.6381 10.0437 24.3968 9.99933 24.1532 10ZM24.1598 20.2726L12.4137 20.2726L7.84572 16L12.4137 11.7274L24.1598 11.7274M10.1297 15.0844C10.3233 14.9034 10.5699 14.78 10.8385 14.7301C11.107 14.6801 11.3853 14.7058 11.6383 14.8038C11.8912 14.9018 12.1074 15.0677 12.2595 15.2806C12.4116 15.4936 12.4928 15.7439 12.4928 16C12.4928 16.2561 12.4116 16.5064 12.2595 16.7194C12.1074 16.9323 11.8912 17.0982 11.6383 17.1962C11.3853 17.2942 11.107 17.3199 10.8385 17.2699C10.5699 17.22 10.3233 17.0966 10.1297 16.9156C9.87008 16.6727 9.72424 16.3434 9.72424 16C9.72424 15.6566 9.87008 15.3273 10.1297 15.0844Z"
        fill={color}
      />
    </svg>
  );
};
