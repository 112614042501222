import { Button } from "reshaped";

export const CooperButton = ({
  children,
  heightWithFourTimes = 12,
  radiusWithFourTimes = 2,
  attributes = {},
  ...rest
}) => {
  return (
    <Button
      {...rest}
      attributes={{
        ...{
          style: {
            height: `calc(var(--rs-unit-x1) * ${heightWithFourTimes})`,
            borderRadius: `calc(var(--rs-unit-x1) * ${radiusWithFourTimes})`,
          },
          ...attributes,
        },
      }}
    >
      {children}
    </Button>
  );
};
