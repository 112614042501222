import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { isString } from "utils/miniHelpers";
import { getAbsoluteURL } from "utils/getters";
import { convertToRaw, ContentState, EditorState } from "draft-js";

export function EditorStateToText(state) {
  const sanitizeHTMLTagRegex = /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g;
  const contentState = state.getCurrentContent();
  const HTMLState = draftToHtml(convertToRaw(contentState));
  const TextContent = HTMLState.replace(sanitizeHTMLTagRegex, "").trim();
  return TextContent;
}

export function EditorStateToHTML(Editorstate) {
  const sanitizeInlineCSSRegex = /\s\w+=\\?"[^"]*"/g;
  const contentState = Editorstate.getCurrentContent();
  let HTMLState = draftToHtml(convertToRaw(contentState));
  HTMLState = HTMLState.replace(sanitizeInlineCSSRegex, "");
  return HTMLState;
}

export function HTMLToEditorState(state) {
  if (!state) return EditorState.createEmpty();
  if (typeof state === "string" && state.length > 0) {
    const blocksFromHtml = htmlToDraft(state);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  }
  return EditorState.createEmpty();
}

export function HTMLToText(HTMLText = "") {
  const sanitizeHTMLTagRegex = /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g;
  const TextContent = HTMLText.replace(sanitizeHTMLTagRegex, "").trim();
  return TextContent;
}

export function sanitizeEditorState(text = "") {
  const REMOVESPACESFROMBEGINNING = /^(&nbsp;){1,}/g;
  const sanitizedText = text.replace(REMOVESPACESFROMBEGINNING, "");
  if (sanitizedText.length < 2) return sanitizedText;
  return text;
}

export function isEditorStateEmpty({ state }) {
  const HTMLState = EditorStateToHTML(state);
  const emptyEditorStateRegex = /\s?<[p|P]><\/[p|P]>\s?/g;
  if (emptyEditorStateRegex.test(HTMLState)) return true;
  return false;
}

export function sanitizeEditorStateText(text = "") {
  const REMOVESPACESFROMBEGINNING = /^(&nbsp;){1,}/g;
  const sanitizedText = text.replace(REMOVESPACESFROMBEGINNING, "");
  if (sanitizedText.length < 2) return sanitizedText;
  return text;
}

export function addBlankTargetToAnchorTags({ text = "" }) {
  if (!isString(text)) return "";

  if (!/<a/g.test(text)) return text;

  const div = document.createElement("div");
  div.innerHTML = text;

  const anchorTags = div.querySelectorAll("a");

  anchorTags.forEach((anchor) => {
    let href = anchor.href;
    if (isString(href)) {
      anchor.target = "_blank";
      anchor.relList.remove("noopener");
      anchor.relList.remove("noreferrer");
      anchor.href = getAbsoluteURL({ href });
    }
  });

  return div.innerHTML;
}
