import { createSlice } from "@reduxjs/toolkit";
import { areKeysAvailableIn, areKeysAvailableWithType, sanitizeArray } from "utils/miniHelpers";

// pages initial states

const initials = {
  // column sorting in candidates page
  candidates: [{ key: "date", order: "desc" }],
};

const initialState = {
  // candidates is page here
  candidates: initials.candidates,
};

export const sortingSlice = createSlice({
  name: "sorting",
  initialState,
  reducers: {
    resetPage: (state, action) => {
      if (!areKeysAvailableWithType({ object: action, keys: [{ payload: "string" }] })) return;
      const { payload } = action;
      if (payload === "all") {
        Object.keys(initials).forEach((property) => {
          state[property] = initials[property];
        });
      }
      if (areKeysAvailableIn({ object: initials, keys: [payload] })) {
        state[payload] = initials[payload];
      }
    },
    reset: (state) => {
      for (let key in initialState) {
        state[key] = initialState[key];
      }
    },
    setCandidatesPageSorting: (state, action) => {
      const { payload } = action;
      if (payload && typeof payload === "object" && Array.isArray(state.candidates)) {
        const { removeColumns, data } = payload;
        if (!Array.isArray(removeColumns) && !Array.isArray(data)) return state.candidates;
        let newData = state.candidates;
        if (!Array.isArray(removeColumns)) newData = [];
        if (Array.isArray(removeColumns)) {
          newData = newData.filter((e) => {
            return !removeColumns.includes(e.key);
          });
        }
        if (Array.isArray(data)) {
          newData = [
            ...newData,
            ...data.filter((each) => areKeysAvailableIn({ object: each, keys: ["key", "order"] })),
          ];
        }
        state.candidates = sanitizeArray({
          array: newData,
          elementType: "object",
          keys: [{ key: "string" }, { order: "string" }],
          hardSanitization: true,
          emptyResultValue: initials.candidates,
        });
      } else {
        state.candidates = initials.candidates;
      }
    },
  },
});
