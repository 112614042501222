import { useMemo } from "react";
import { Text, View, Image, Avatar } from "reshaped";

import useName from "components/hooks/useName";
import { FoldText } from "components/reusables";
import useSpacing from "components/hooks/useSpacing";
import useTranslation from "components/hooks/useTranslation";
import { SlotTitleGroup } from "components/pages/promote/slot";

export const BoardInfo = ({ board }) => {
  const { name, tags, description } = board;

  const { xs, sm, lg, xl } = useSpacing();
  const jobBoardProfile = useName({ info: board, first_name: name });

  const badges = useMemo(() => {
    let allBadges = [];
    if (tags && Array.isArray(tags) && tags.length > 0) {
      allBadges = tags.map((tag) => {
        return {
          id: tag.id,
          title: tag.tag_name,
        };
      });
    }

    return allBadges;

    //eslint-disable-next-line
  }, []);

  return (
    <View gap={sm + xl}>
      <SlotTitleGroup />
      <View gap={xs + lg} direction="row" align="center" maxWidth="1300px">
        {jobBoardProfile.hasURL && (
          <Image width="124px" height="124px" src={jobBoardProfile.url} alt={name} />
        )}
        {!jobBoardProfile.hasURL && (
          <Avatar squared size={31} initials={jobBoardProfile.letters} alt={name} />
        )}
        <View.Item grow>
          <View gap={xs + lg}>
            <View gap={xs} width="120ch">
              <Text variant="body-strong-1">{name}</Text>
              <FoldText variant="body-1" characterLimit={500} text={description} />
            </View>
            {badges.length > 0 && (
              <View direction="row" gap={xl} align="center">
                {badges.map((badge) => {
                  return <BoardHighlight key={badge.id} {...badge} />;
                })}
              </View>
            )}
          </View>
        </View.Item>
      </View>
    </View>
  );
};

export const BoardHighlight = ({ title }) => {
  const { t } = useTranslation();
  const { sm, md } = useSpacing();

  return (
    <View
      borderRadius="medium"
      height="30px"
      padding={[0, md]}
      direction="row"
      align="center"
      justify="center"
      backgroundColor="primary-faded"
      gap={sm}
    >
      <Text variant="caption-1" color="primary">
        {t(title)}
      </Text>
    </View>
  );
};
