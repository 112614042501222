import { useState, useEffect } from "react";
import { View } from "reshaped";

import useSpacing from "components/hooks/useSpacing";
import { ReshapedLoader } from "components/reusables";
import { useDispatch, useSelector } from "react-redux";
import { CategoryWrapper } from "components/pages/candidates";
import { ShowSupportError } from "components/reusables/common";
import styles from "components/styles/reshaped/candidates.module.css";
import Notes from "components/pages/candidates/categories/notes/Notes";
import NoNotes from "components/pages/candidates/categories/notes/NoNotes";
import AddNote from "components/pages/candidates/categories/notes/AddNote";
import EditNote from "components/pages/candidates/categories/notes/EditNote";
import { fetchCandidateNotes } from "redux/viewingCandidate/viewingCandidateSlice";

const NotesCategory = (props) => {
  const { addNote, setAddNote, selectedCategory, setSelectedCategory, viewingCandidateId } = props;

  const [newNote, setNewNote] = useState("");
  const [editNoteID, setEditNoteID] = useState("");

  const { xl } = useSpacing();
  const dispatch = useDispatch();
  const { notes, loading, notesError } = useSelector((state) => state.viewingCandidate);

  useEffect(() => {
    if (!notes || notes.candidate_id !== viewingCandidateId) {
      dispatch(fetchCandidateNotes(viewingCandidateId));
    }

    //eslint-disable-next-line
  }, [viewingCandidateId]);

  const editNoteProps = { editNoteID, setEditNoteID };

  return (
    <View
      gap={xl}
      height="100%"
      attributes={{
        style: {
          zIndex: 0,
        },
      }}
    >
      {!notesError && !loading && (
        <>
          {!addNote && !editNoteID && (
            <CategoryWrapper heading="Notes" height="100%">
              {notes && notes.data && Array.isArray(notes.data) && notes.data.length > 0 ? (
                <>
                  <Notes setAddNote={setAddNote} notes={notes.data} setEditNoteID={setEditNoteID} />
                </>
              ) : (
                <>
                  <NoNotes
                    selectedCategory={selectedCategory}
                    setSelectedCategory={setSelectedCategory}
                    setAddNote={setAddNote}
                  />
                </>
              )}
            </CategoryWrapper>
          )}
          {addNote && !editNoteID && (
            <View height="100%" className={styles.add__note_vector}>
              <AddNote newNote={newNote} setNewNote={setNewNote} setAddNote={setAddNote} />
            </View>
          )}
          {!addNote && editNoteID && (
            <View height="100%" className={styles.add__note_vector}>
              <EditNote {...editNoteProps} />
            </View>
          )}
        </>
      )}
      {loading && (
        <CategoryWrapper heading="Notes" height="100%">
          <ReshapedLoader />
        </CategoryWrapper>
      )}
      {notesError && !loading && (
        <View align="center">
          <ShowSupportError variant="body-strong-1" />
        </View>
      )}
    </View>
  );
};

export default NotesCategory;
