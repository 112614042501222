import { Warning } from "icons";
import { Text, View } from "reshaped";
import useSpacing from "components/hooks/useSpacing";

export const ErrorSticker = ({
  color = "critical",
  variant = "caption-1",
  maxWidth = "max-content",
  text = "Something went wrong",
  backgroundColor = "critical-faded",
}) => {
  const { xs, sm } = useSpacing();

  return (
    <View
      padding={sm}
      gap={xs}
      backgroundColor={backgroundColor}
      direction="row"
      align="center"
      borderRadius="small"
      attributes={{
        style: {
          maxWidth,
        },
      }}
    >
      <Warning />
      <Text variant={variant} color={color}>
        {text}
      </Text>
    </View>
  );
};
