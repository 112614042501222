import { useContext } from "react";
import SocketContext from "components/context/socket/SocketContext";

const useSocketContent = () => {
  const socketContext = useContext(SocketContext);
  const { consumer, setConsumer } = socketContext;
  return { consumer, setConsumer };
};

export default useSocketContent;
