import { Button, View } from "reshaped";
import { Chip } from "components/reusables";
import { jobsFiltersSlice } from "redux/filters";
import { jobSlice } from "redux/job";
import useSpacing from "components/hooks/useSpacing";
import { useDispatch, useSelector } from "react-redux";
import useTranslation from "components/hooks/useTranslation";
import { fetchFilteredJobs } from "utils/pages/job";
import { areKeysAvailableWithType } from "utils/miniHelpers";
import useUserLanguagePreference from "components/hooks/useUserLanguagePreference";

export const FilterChips = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { sm, lg } = useSpacing();
  const state = useSelector((state) => state);
  const userLang = useUserLanguagePreference();

  const { user } = state.auth;
  const { jobs: jobsFilter } = state.filters;

  const { statuses } = jobsFilter;
  const { selected: selectedStatuses } = statuses;

  const handleRemoveStatusChip = async ({ status }) => {
    if (!Array.isArray(selectedStatuses)) return;

    dispatch(
      jobSlice.actions.set({
        isFiltering: true,
        filteringError: null,
      })
    );
    const updatedStatuses = selectedStatuses.filter((s) => s !== status);

    const response = await fetchFilteredJobs({
      company_id: user.company_id,
      selectedStatuses: updatedStatuses,
      current_language: userLang,
    });

    if (areKeysAvailableWithType({ object: response, keys: [{ data: "object" }] })) {
      const { jobs } = response.data;
      dispatch(
        jobSlice.actions.set({
          jobs: jobs,
          isFiltering: false
        })
      );
    }
    if (areKeysAvailableWithType({ object: response, keys: [{ hasError: "boolean" }] })) {
      dispatch(
        jobSlice.actions.set({
          isFiltering: false,
          filteringError: "something went wrong!",
        })
      );
    }

    dispatch(
      jobSlice.actions.set({
        isFiltering: false
      })
    );

    dispatch(
      jobsFiltersSlice.actions.setPageFilter({
        page: "jobs",
        types: { name: "statuses", value: { selected: updatedStatuses } },
      })
    );
  };

  const handleClearAll = async () => {
    dispatch(
      jobSlice.actions.set({
        isFiltering: true,
        filteringError: null,
      })
    );

    const response = await fetchFilteredJobs({
      company_id: user.company_id,
      selectedStatuses: [],
      current_language: userLang,
    });

    if (areKeysAvailableWithType({ object: response, keys: [{ data: "object" }] })) {
      const { jobs } = response.data;
      dispatch(
        jobSlice.actions.set({
          isFiltering: false,
          jobs
        })
      );
    }
    if (areKeysAvailableWithType({ object: response, keys: [{ hasError: "boolean" }] })) {
      dispatch(
        jobSlice.actions.set({
          isFiltering: false,
          filteringError: "something went wrong!",
        })
      );
    }

    dispatch(
      jobsFiltersSlice.actions.setPageFilter({
        page: "jobs",
        types: { name: "statuses", value: { selected: [] } },
      })
    );
  };

  return (
    <>
      {((Array.isArray(selectedStatuses) && selectedStatuses.length > 0)) && (
        <View gap={lg} direction="row" align="center">
          <View direction="row" align="center" wrap gap={sm}>
            {Array.isArray(selectedStatuses) && selectedStatuses.length > 0 && (
              <>
                {selectedStatuses.map((status) => {
                  if (!status || typeof status !== "string" || status.length === 0) return null;
                  return (
                    <Chip
                      key={status}
                      title={t(`${status.charAt(0).toUpperCase()}${status.slice(1)}`)}
                      closeHandler={handleRemoveStatusChip}
                      handlerArgs={{ status }}
                    />
                  );
                })}
              </>
            )}
          </View>
          <Button
            color="primary"
            variant="outline"
            onClick={handleClearAll}
            attributes={{ "data-cooper": "clear jobs filter" }}
          >
            {t("Clear all")}
          </Button>
        </View>
      )}
    </>
  );
};
