import { createContext, useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { isLocale } from "translations/types";

export const LocaleContext = createContext({
  locale: "",
  setLocale: () => null,
});

export const LocaleProvider = ({ lang, children }) => {
  const [locale, setLocale] = useState(lang);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    var userLang = navigator.language || navigator.userLanguage;

    const setUserLocale = (userLocale) => {
      if (typeof userLocale === "string" && isLocale(userLocale) && locale !== userLocale) {
        localStorage.setItem("locale", userLocale);
        setLocale(userLocale);
      }
    };

    if (user) {
      setUserLocale(user.language_preference);
    } else {
      let languageCode = userLang.split("-")[0];
      setUserLocale(languageCode);
    }
  });

  return <LocaleContext.Provider value={{ locale, setLocale }}>{children}</LocaleContext.Provider>;
};
