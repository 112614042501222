import { Text, View } from "reshaped";
import useSpacing from "components/hooks/useSpacing";
import useTranslation from "components/hooks/useTranslation";
import styles from "components/styles/reshaped/promote/promote.module.css";

export const TabTitleGroup = () => {
  const { t } = useTranslation();

  return (
    <View direction="row" align="center">
      <Text variant="title-2">{t("Promotions")}</Text>
    </View>
  );
};

export const TitleGroup = ({
  title,
  subTitle,
  horizontalLine = true,
  titleWithNoTranslation = false,
  subTitleWithNoTranslation = false,
}) => {
  const { xs } = useSpacing();
  const { t } = useTranslation();

  return (
    <View gap={xs}>
      <View className={horizontalLine ? styles.line : ""}>
        {titleWithNoTranslation ? (
          <Text variant="title-2">{title}</Text>
        ) : (
          <Text variant="title-2">{t(title)}</Text>
        )}
      </View>
      {subTitleWithNoTranslation ? (
        <Text variant="body-1">{subTitle}</Text>
      ) : (
        <Text variant="body-1">{t(subTitle)}</Text>
      )}
    </View>
  );
};
