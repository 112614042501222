import { Text, View } from "reshaped";
import useSpacing from "components/hooks/useSpacing";
import { TextWithEclipses } from "components/reusables";

export const Property = (props) => {
  const {
    title = "",
    value = "",
    children,
    textLength = 48,
    style = {
      wordBreak: "break-all",
    },
  } = props;

  const { sm } = useSpacing();

  return (
    <View.Item columns={6}>
      <View gap={sm} overflow="hidden">
        <Text
          variant="caption-1"
          color="neutral"
          attributes={{
            style: { ...style },
          }}
        >
          {title}
        </Text>
        {value && !children && (
          <TextWithEclipses
            variant="body-strong-2"
            text={value}
            characterLimit={textLength}
            attributes={{
              style: {
                textTransform: "capitalize",
                ...style,
              },
            }}
          />
        )}
        {!value && children && <>{children}</>}
      </View>
    </View.Item>
  );
};
