import React, { useLayoutEffect, useRef } from "react";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { isObject } from "utils/miniHelpers";
import "components/styles/reshaped/reusables/chat/messageTemplateChat.css";

export const MessageTemplateEditor = ({
  value = "",
  placeholder = "Write something...",
  handleChange = () => {},
  editorRef,
  handleLinkChanges,
  ...attributes
}) => {
  const isEditingRef = useRef(false);

  useLayoutEffect(() => {
    const placeholderElement = document.querySelector('[data-link="https://quilljs.com"]');
    if (placeholderElement instanceof HTMLInputElement) {
      placeholderElement.setAttribute("placeholder", "https://www.cooperhire.io");
      placeholderElement.setAttribute("data-link", "https://www.cooperhire.io");
    }
  }, []);

  useLayoutEffect(() => {
    const tooltip = document.querySelector(".quill-template-wrapper .ql-tooltip");

    function handleClassChange() {
      if (tooltip instanceof HTMLDivElement) {
        tooltip.style.left = "0px";
      }
    }

    const observerConfig = {
      attributes: true,
      attributeFilter: ["class"],
      attributeOldValue: true,
      subtree: false,
    };

    const observer = new MutationObserver(handleClassChange);

    if (tooltip instanceof HTMLDivElement) {
      observer.observe(tooltip, observerConfig);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  useLayoutEffect(() => {
    const tooltip = document.querySelector(".quill-template-wrapper .ql-tooltip");

    function handleClassChange(entries) {
      if (Array.isArray(entries)) {
        const length = entries.length;
        for (let i = 0; i < length; i++) {
          const entry = entries[i];
          if (typeof entry !== "object") continue;
          if (!isEditingRef.current && entry.oldValue === "ql-tooltip ql-editing") {
            isEditingRef.current = true;
          }
          const target = entry.target;
          if (
            isEditingRef.current &&
            target instanceof HTMLDivElement &&
            target.getAttribute("class") === "ql-tooltip ql-editing ql-hidden"
          ) {
            isEditingRef.current = false;
            const quill =
              editorRef.current &&
              typeof editorRef.current === "object" &&
              typeof editorRef.current.getEditor === "function"
                ? editorRef.current.getEditor()
                : {};
            if (handleLinkChanges && typeof handleLinkChanges === "function") {
              if (typeof quill.blur === "function") quill.blur();
              handleLinkChanges(quill);
            }
          }
        }
      }
    }

    const observerConfig = {
      attributes: true,
      attributeFilter: ["class"],
      attributeOldValue: true,
      subtree: false,
    };

    const observer = new MutationObserver(handleClassChange);
    if (tooltip instanceof HTMLDivElement) {
      observer.observe(tooltip, observerConfig);
    }

    return () => {
      observer.disconnect();
    };

    //eslint-disable-next-line
  }, []);

  return (
    <>
      <ReactQuill
        theme="snow"
        value={value}
        key={placeholder}
        formats={[
          "bold",
          "italic",
          "underline",
          "list",
          "bullet",
          "link",
          // "image"
          "strike",
        ]}
        modules={{
          toolbar: {
            container: "#toolbar",
          },
        }}
        onChange={handleChange}
        placeholder={placeholder}
        {...(editorRef && isObject(editorRef) ? { ref: editorRef } : {})}
        {...attributes}
      />
      <CustomToolbar editorRef={editorRef} />
    </>
  );
};

function CustomToolbar() {
  return (
    <div id="toolbar" className="custom__toolbar__message__template">
      <button className="ql-bold"></button>
      <button className="ql-italic"></button>
      <button className="ql-underline"></button>
      <button className="ql-strike"></button>
      <button className="ql-link"></button>
      <button className="ql-list" value="ordered"></button>
      <button className="ql-list" value="bullet"></button>
    </div>
  );
}
