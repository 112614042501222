import { backendAPI } from "utils/axios";
import { areKeysAvailableWithType, sanitizeArray } from "utils/miniHelpers";

export async function getCountriesFromAPI() {
  const errorResult = {
    error: "Failed to fetch countries.",
  };
  try {
    const response = await backendAPI.get("/country_translations");
    if (
      areKeysAvailableWithType({ object: response.data, keys: [{ country_translations: "array" }] })
    ) {
      return {
        data: sanitizeArray({
          array: response.data.country_translations,
          elementType: "object",
          noPropertyTypeChecking: false,
          keys: [{ name: "string" }, { country_code: "string" }, { translation: "object" }],
          outputElementStructureCallback: (value) => {
            return {
              country: value.name,
              translation: value.translation,
              country_code: value.country_code,
            };
          },
        }),
      };
    }
    return errorResult;
  } catch (error) {
    return errorResult;
  }
}
