import { baseColor } from "utils/colors/accents";

export const MirrorScreen = ({ color = baseColor.white }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.33325 6V5.33333C1.33325 3.33333 2.66659 2 4.66659 2H11.3333C13.3333 2 14.6666 3.33333 14.6666 5.33333V10.6667C14.6666 12.6667 13.3333 14 11.3333 14H10.6666"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.45996 7.80664C5.53996 8.19997 7.79996 10.4667 8.19996 13.5467"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.74683 10.0466C4.00683 10.3333 5.66683 12 5.96016 14.26"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.32007 12.5732C2.44674 12.7199 3.28007 13.5466 3.42674 14.6799"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
