import { backendAPI } from "utils/axios";
import { reloadOnUnauthorized } from "utils/checkForAuth";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  viewingJobBoard: null,
  viewingSlots: [],
};

export const fetchSlotsWithJobBoardID = createAsyncThunk(
  "slotsWithID/fetch",
  async (jobBoardID) => {
    try {
      const noData = { viewingSlots: [], viewingJobBoard: {} };

      if (!jobBoardID) return noData;

      const response = await backendAPI.get(`/job_boards/${jobBoardID}`);
      if (
        response.data &&
        response.data.job_board_slots &&
        Array.isArray(response.data.job_board_slots)
      ) {
        const { job_board_slots, ...jobBoardData } = response.data;
        return {
          viewingSlots: job_board_slots,
          viewingJobBoard: jobBoardData,
        };
      }
      return noData;
    } catch (error) {
      reloadOnUnauthorized(error);
      return {
        error: "Something went wrong",
      };
    }
  }
);

export const slotsSlice = createSlice({
  name: "slots",
  initialState,
  reducers: {
    reset: (state) => {
      for (let key in initialState) {
        state[key] = initialState[key];
      }
    },
    setloading: (state) => {
      state.loading = true;
    },
    stoploading: (state) => {
      state.loading = false;
    },
    getJobBoardSlots: (state, action) => {
      const { ids } = action.payload;
      const slots = [];
      if (ids && Array.isArray(ids) && state.allSlots) {
        ids.forEach((id) => {
          const foundSlot = state.allSlots[id];
          slots.push(foundSlot);
        });
      }
      state.viewingSlots = slots;
    },
    resetViewingInfo: (state) => {
      state.viewingJobBoard = null;
      state.viewingSlots = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSlotsWithJobBoardID.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchSlotsWithJobBoardID.fulfilled, (state, action) => {
      const { payload } = action;
      state.loading = false;
      state.viewingSlots = payload.viewingSlots;
      state.viewingJobBoard = payload.viewingJobBoard;
    });
    builder.addCase(fetchSlotsWithJobBoardID.rejected, (state, action) => {
      const { payload } = action;
      state.loading = false;
      state.error = payload.error;
      state.viewingSlots = [];
    });
  },
});
