import React, { useState, useEffect, useRef } from "react";
import { Button, Divider, Text, useToast, useToggle, View } from "reshaped";

import { Reload } from "icons";
import { metaSlice } from "redux/meta";
import { baseColor } from "utils/colors/accents";
import useSpacing from "components/hooks/useSpacing";
import { useDispatch, useSelector } from "react-redux";
import { ShowToastWithTranslation } from "utils/showToast";
import useTranslation from "components/hooks/useTranslation";
import { useNavigate, useSearchParams } from "react-router-dom";
import useSetNinjaFilter from "components/hooks/useSetNinjaFilter";
import { fetchCandidatesData } from "redux/candidate/candidateSlice";
import common from "components/styles/reshaped/reusables/common.module.css";
import useUserLanguagePreference from "components/hooks/useUserLanguagePreference";
import { WrapperWithNav, CandidateModal, ReshapedLoader } from "components/reusables";
import {
  NoCandidates,
  AddCandidate,
  AllCandidates,
  CandidatesSearch,
  ProfileModalContent,
  CandidatesTitleGroup,
} from "components/pages/candidates";

function Candidates() {
  const [openModal, setOpenModal] = useState(false);
  const [newViewingCandidateId, setNewViewingCandidateId] = useState("");

  useSetNinjaFilter();
  const toast = useToast();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const modalProps = useToggle(false);
  const candidatesPageRef = useRef();
  const { xs, md, lg, xl, xxl } = useSpacing();
  const [searchParams] = useSearchParams();
  const state = useSelector((state) => state);
  const userLang = useUserLanguagePreference();

  const { activate, active } = modalProps;
  const { user } = state.auth;
  const { setNewCandidateModalOpen } = metaSlice.actions;
  const { candidates: candidatesFilters } = state.filters;
  const { candidates: candidatesSorting } = state.sorting;
  const { addCandidateShortcut, isNewCandidateModalOpen, isCandidateModalOpen } = state.meta;
  const { candidates, loading, error, isFiltering, totalCandidates, pageCount } = state.candidate;

  const { company_id } = user;
  const { jobs, stages, followers, search } = candidatesFilters;
  const { selected: selectedJobs } = jobs;
  const { selected: selectedStages } = stages;
  const { selected: selectedFollowers } = followers;
  const { selected: selectedSearches } = search;

  useEffect(() => {
    if (!openModal) {
      let param = searchParams.get("to");
      let currentCandidateId = searchParams.get("id");
      let newCandidate = searchParams.get("new_candidate");
      if (param) {
        param = searchParams.get("to").toLowerCase();
        if (param === "new") {
          activate();
        }
      }

      if (!newCandidate && !candidates && !currentCandidateId) {
        dispatch(
          fetchCandidatesData({
            company_id,
            selectedJobs,
            selectedStages,
            selectedFollowers,
            from: pageCount,
            sorting: candidatesSorting,
            current_language: userLang,
          })
        );
      }

      if (
        (newCandidate && typeof newCandidate === "string") ||
        (currentCandidateId && typeof currentCandidateId === "string")
      ) {
        setNewViewingCandidateId(newCandidate || currentCandidateId);
        setOpenModal(true);
        dispatch(setNewCandidateModalOpen(true));
      }
    }
    //eslint-disable-next-line
  }, [openModal]);

  useEffect(() => {
    if (addCandidateShortcut) {
      activate();
    }

    //eslint-disable-next-line
  }, [addCandidateShortcut]);

  useEffect(() => {
    function handleBackButton() {
      if (isCandidateModalOpen) {
        dispatch(metaSlice.actions.setCandidateModalOpen(false));
        navigate("/candidates");
      }
    }
    window.onpopstate = handleBackButton;

    return () => {
      window.onpopstate = () => {};
    };

    //eslint-disable-next-line
  }, [isCandidateModalOpen]);

  // const clearNewCandidateParam = () => {
  //   if (searchParams.has("new_candidate")) {
  //     searchParams.delete("new_candidate");
  //     setSearchParams(searchParams);
  //   }
  // };

  const handleCloseModal = ({ notFound = false }) => {
    setOpenModal(false);
    navigate("/candidates");
    // clearNewCandidateParam();

    if (notFound) {
      ShowToastWithTranslation({
        toast,
        Children: CandidateNotFound,
        text: t("Candidate not found!"),
      });
    }

    if (isNewCandidateModalOpen) {
      dispatch(setNewCandidateModalOpen(false));
    }
  };

  const handleModalOpen = () => {
    window.history.pushState({ id: 1 }, null, "");
    activate();
  };

  const handleFetchCandidateData = () => {
    dispatch(
      fetchCandidatesData({
        company_id,
        current_language: userLang,
      })
    );
  };

  const candidateModalViewProps = { openModal, setOpenModal };
  // const newViewProps = { newView, setNewView };
  const viewingCandidateProps = {
    viewingCandidateId: newViewingCandidateId,
    setViewingCandidateId: setNewViewingCandidateId,
  };

  return (
    <WrapperWithNav
      wrapperAttributes={{
        ref: candidatesPageRef,
      }}
    >
      <View
        gap={xxl}
        attributes={{
          ref: candidatesPageRef,
        }}
      >
        <CandidatesTitleGroup />
        {((candidates && Array.isArray(candidates) && candidates.length > 0) ||
          (Array.isArray(selectedJobs) && selectedJobs.length > 0) ||
          (Array.isArray(selectedStages) && selectedStages.length > 0) ||
          (Array.isArray(selectedFollowers) && selectedFollowers.length > 0) || selectedSearches.length > 0) && (
          <View
            gap={xl}
            paddingBottom={lg + md + (xs/2)}
            className={isFiltering ? common.cover__wrapper : ""}
          >
            <CandidatesSearch handleModalOpen={handleModalOpen} />
          </View>
        )}
      </View>
      {!loading && !error && Array.isArray(candidates) && (
        <View.Item grow>
          {Array.isArray(selectedJobs) &&
          selectedJobs.length <= 0 &&
          Array.isArray(selectedStages) &&
          selectedStages.length <= 0 &&
          Array.isArray(selectedFollowers) &&
          selectedFollowers.length <= 0 &&
          totalCandidates <= 0 &&
          candidates.length <= 0 && selectedSearches.length === 0 ? (
            <NoCandidates handleModalOpen={handleModalOpen} />
          ) : (
            <View height="100%">
              <Divider />
              <AllCandidates candidatesPageRef={candidatesPageRef} />
            </View>
          )}
        </View.Item>
      )}
      {loading && !error && (
        <View.Item grow={true}>
          <ReshapedLoader />
        </View.Item>
      )}
      {Array.isArray(selectedJobs) &&
        selectedJobs.length <= 0 &&
        Array.isArray(selectedStages) &&
        selectedStages.length <= 0 &&
        Array.isArray(selectedFollowers) &&
        selectedFollowers.length <= 0 && (
          <>
            {!loading && error && (
              <View.Item grow={true}>
                <View height="100%" align="center" justify="center" gap={lg} padding={[xxl, 0]}>
                  <Text color="critical" variant="title-2">
                    {t(error)}
                  </Text>
                  <Button
                    onClick={handleFetchCandidateData}
                    startIcon={() => <Reload fill={baseColor.white} />}
                    color="critical"
                  >
                    {t("Reload")}
                  </Button>
                </View>
              </View.Item>
            )}
          </>
        )}
      {active && <AddCandidate {...modalProps} />}
      {openModal && newViewingCandidateId && (
        <CandidateModal open={openModal} handleClose={handleCloseModal} overlayColor="#fff">
          <ProfileModalContent
            {...viewingCandidateProps}
            {...candidateModalViewProps}
            newCandidate={newViewingCandidateId && openModal ? true : false}
            // {...newViewProps}
            candidates={candidates}
            handleClose={handleCloseModal}
            // clearParams={clearNewCandidateParam}
          />
        </CandidateModal>
      )}
    </WrapperWithNav>
  );
}

export default Candidates;

const CandidateNotFound = ({ children }) => {
  return (
    <Text color="critical" variant="body-strong-1">
      {children}
    </Text>
  );
};
