import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { backendAPI } from "utils/axios";
import { PlansArray } from "utils/constants";

export const fetchSubscriptionPrices = createAsyncThunk(
  "subscriptionPrices/fetchSubscriptionPrices",
  async (_, { rejectWithValue }) => {
    try {
      const response = await backendAPI.get("/prices");
      const { data, status = 401 } = response;
      if (data && status === 200) {
        const order = PlansArray;

        const sortedResults = data.sort((a, b) => {
          return order.indexOf(a.title) - order.indexOf(b.title);
        });

        return sortedResults;
      } else {
        return rejectWithValue("Failed to fetch subscription prices");
      }
    } catch (error) {
      return rejectWithValue(error.message || "Network error");
    }
  }
);

const initialState = {
  subscriptionPrices: [],
  loading: false,
  error: null
}

export const subscriptionPricesSlice = createSlice({
  name: "subscriptionPrices",
  initialState: initialState,
  reducers: {
    reset: (state) => {
      for (let key in initialState) {
        state[key] = initialState[key];
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSubscriptionPrices.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSubscriptionPrices.fulfilled, (state, action) => {
        state.loading = false;
        state.subscriptionPrices = action.payload;
      })
      .addCase(fetchSubscriptionPrices.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default subscriptionPricesSlice.reducer;