import { jobSlice } from "redux/job";
import { authSlice } from "redux/auth";
import { cartSlice } from "redux/cart";
import { metaSlice } from "redux/meta";
import { slotsSlice } from "redux/slots";
import { publicSlice } from "redux/public";
import { filtersSlice } from "redux/filters";
import { sortingSlice } from "redux/sorting";
import { companySlice } from "redux/company";
import { bookingsSlice } from "redux/bookings";
import { templatesSlice } from "redux/templates";
import { dashboardSlice } from "redux/dashboard";
import { candidateSlice } from "redux/candidate";
import { jobBoardsSlice } from "redux/jobBoards";
import { configureStore } from "@reduxjs/toolkit";
import { invitationSlice } from "redux/invitation";
import { placeholdersSlice } from "redux/placeholders";
import { conversationSlice } from "redux/conversation";
import { subscriptionsSlice } from "redux/subscriptions";
import { viewingCandidateSlice } from "redux/viewingCandidate";
import { subscriptionPricesSlice } from "redux/subscriptionPrices";
import { inProgressConversationsSlice } from "redux/inProgressConversations";

const store = configureStore({
  reducer: {
    job: jobSlice.reducer,
    auth: authSlice.reducer,
    cart: cartSlice.reducer,
    meta: metaSlice.reducer,
    slots: slotsSlice.reducer,
    filters: filtersSlice.reducer,
    sorting: sortingSlice.reducer,
    company: companySlice.reducer,
    bookings: bookingsSlice.reducer,
    publicState: publicSlice.reducer,
    templates: templatesSlice.reducer,
    jobBoards: jobBoardsSlice.reducer,
    candidate: candidateSlice.reducer,
    dashboard: dashboardSlice.reducer,
    invitation: invitationSlice.reducer,
    conversation: conversationSlice.reducer,
    placeholders: placeholdersSlice.reducer,
    subscriptions: subscriptionsSlice.reducer,
    viewingCandidate: viewingCandidateSlice.reducer,
    subscriptionPrices: subscriptionPricesSlice.reducer,
    inProgressConversations: inProgressConversationsSlice.reducer,
  },
});

export default store;
