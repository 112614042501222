import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { Button, Text, View } from "reshaped";

import { Reload } from "icons";
import { baseColor } from "utils/colors/accents";
import useSpacing from "components/hooks/useSpacing";
import { ReshapedLoader } from "components/reusables";
import { ChatMessage, NoMessages } from "components/chat";
import { ErrorSticker } from "components/reusables/common";
import useTranslation from "components/hooks/useTranslation";
import common from "components/styles/reshaped/reusables/common.module.css";
import styles from "components/styles/reshaped/reusables/chat/chat.module.css";
import { areKeysAvailableIn, areKeysAvailableWithType } from "utils/miniHelpers";
import { getPreviousMessages, mergeConversationWithSubject } from "utils/pages/candidate";

export const ChatMessages = ({
  messages,
  setMessages,
  isTextInFocus,
  messagesBoxHeight,
  viewingCandidateId,
  handleRetryFailedEmail,
  handleDeleteFailedEmail,
  setShouldLatestMessageInFocus,
  handleDeleteBackendFailedEmail,
  messagesExhaustedAtInitialFetchRef,
  shouldLatestMessageInFocus = false,
}) => {
  const { t } = useTranslation();
  const { xs, sm, lg, xl, xxl } = useSpacing();
  const [messagesError, setMessagesError] = useState("");
  const [areInitialMessagesLoaded, setAreInitialMessagesLoaded] = useState(false);
  const [arePreviousMessagesLoading, setArePreviousMessagesLoading] = useState(false);

  const firstMessageRef = useRef(null);
  const latestMessageRef = useRef(null);
  const chatContainerRef = useRef(null);
  const messageInFocusRef = useRef(null);
  const isLastMessageInViewRef = useRef(true);
  const lastMessageObserverRef = useRef(null);
  const firstMessageObserverRef = useRef(null);
  const isFirstMessageInViewRef = useRef(false);
  const isLastMessageIsInFocusOnInInputFocus = useRef(false);

  useEffect(() => {
    if (areKeysAvailableIn({ object: chatContainerRef, keys: ["current"] })) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, []);

  useEffect(() => {
    if (
      isLastMessageInViewRef.current &&
      latestMessageRef.current &&
      isLastMessageIsInFocusOnInInputFocus.current
    ) {
      latestMessageRef.current.scrollIntoView({
        behavior: shouldLatestMessageInFocus ? "instant" : "smooth",
      });
    }

    //eslint-disable-next-line
  }, [messagesBoxHeight]);

  useEffect(() => {
    if (isTextInFocus) {
      isLastMessageIsInFocusOnInInputFocus.current = isLastMessageInViewRef.current;
    }
  }, [isTextInFocus]);

  useEffect(() => {
    if (
      (isLastMessageInViewRef.current || messages.length <= 1 || shouldLatestMessageInFocus) &&
      latestMessageRef.current
    ) {
      latestMessageRef.current.scrollIntoView({
        behavior: shouldLatestMessageInFocus || !areInitialMessagesLoaded ? "instant" : "smooth",
      });
      if (shouldLatestMessageInFocus) setShouldLatestMessageInFocus(false);
    }

    //eslint-disable-next-line
  }, [messages]);

  useEffect(() => {
    async function getPreviousConversation({ candidate_id, id }) {
      if (
        areKeysAvailableWithType({
          object: messagesExhaustedAtInitialFetchRef,
          keys: [{ current: true, exact: true }],
        }) &&
        arePreviousMessagesLoading
      ) {
        setArePreviousMessagesLoading(false);
        return;
      }

      const result = await getPreviousMessages({ candidate_id, id });
      setArePreviousMessagesLoading(false);
      if (
        areKeysAvailableIn({
          object: result,
          keys: ["conversation", "latestSubject", "isExhausted"],
        })
      ) {
        messagesExhaustedAtInitialFetchRef.current = result.isExhausted;
        setMessages((prev) => {
          if (areKeysAvailableIn({ object: prev[0], keys: ["id"] })) {
            messageInFocusRef.current = prev[0].id;
          }
          const sanitized = mergeConversationWithSubject({
            conversation: [...result.conversation, ...prev],
            getLatestSubject: false,
          });
          return sanitized.conversation;
        });
      }
      if (
        areKeysAvailableIn({
          object: result,
          keys: ["error"],
        })
      ) {
        setMessagesError(t(result.error));
      }
    }

    if (arePreviousMessagesLoading && firstMessageObserverRef.current) {
      firstMessageObserverRef.current.disconnect();
    }

    if (arePreviousMessagesLoading && isFirstMessageInViewRef.current) {
      if (
        Array.isArray(messages) &&
        messages.length > 0 &&
        areKeysAvailableIn({ object: messages[0], keys: ["id"] })
      ) {
        getPreviousConversation({ candidate_id: viewingCandidateId, id: messages[0].id });
      } else {
        setArePreviousMessagesLoading(false);
      }
    }

    //eslint-disable-next-line
  }, [arePreviousMessagesLoading]);

  useLayoutEffect(() => {
    const focusMessageElement = document.querySelector(".in__focus");
    if (focusMessageElement && focusMessageElement instanceof HTMLDivElement) {
      focusMessageElement.scrollIntoView();
      messageInFocusRef.current = null;
    }
  }, [messages]);

  useEffect(() => {
    if (firstMessageObserverRef.current) {
      firstMessageObserverRef.current.disconnect();
    }

    if (
      messages.length <= 1 ||
      arePreviousMessagesLoading ||
      areKeysAvailableWithType({
        object: messagesExhaustedAtInitialFetchRef,
        keys: [{ current: true, exact: true }],
      }) ||
      !areInitialMessagesLoaded ||
      messagesError
    )
      return;

    const options = {
      root: chatContainerRef.current,
      rootMargin: "0px",
      threshold: 1.0,
    };

    firstMessageObserverRef.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          isFirstMessageInViewRef.current = true;
          if (!isLastMessageInViewRef.current && areInitialMessagesLoaded) {
            setArePreviousMessagesLoading(true);
          }
        }
        if (!entry.isIntersecting) {
          isFirstMessageInViewRef.current = false;
        }
      });
    }, options);
    if (firstMessageRef.current) {
      firstMessageObserverRef.current.observe(firstMessageRef.current);
    }
    return () => {
      if (firstMessageObserverRef.current) {
        firstMessageObserverRef.current.disconnect();
      }
    };
    //eslint-disable-next-line
  }, [messages, arePreviousMessagesLoading, areInitialMessagesLoaded]);

  useEffect(() => {
    if (messages.length <= 1) return;
    if (lastMessageObserverRef.current) {
      lastMessageObserverRef.current.disconnect();
    }
    const options = {
      root: chatContainerRef.current,
      rootMargin: "0px",
      threshold: 1,
    };

    lastMessageObserverRef.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (
            !isFirstMessageInViewRef.current ||
            areKeysAvailableWithType({
              object: messagesExhaustedAtInitialFetchRef,
              keys: [{ current: true, exact: true }],
            })
          ) {
            if (messageInFocusRef.current) {
              messageInFocusRef.current = null;
            }
          }
          isLastMessageInViewRef.current = true;
        }
        if (!entry.isIntersecting) {
          isLastMessageInViewRef.current = false;
        }
        if (!areInitialMessagesLoaded) {
          setAreInitialMessagesLoaded(true);
        }
      });
    }, options);
    if (latestMessageRef.current) {
      lastMessageObserverRef.current.observe(latestMessageRef.current);
    }
    return () => {
      if (lastMessageObserverRef.current) {
        lastMessageObserverRef.current.disconnect();
      }
    };
    //eslint-disable-next-line
  }, [messages]);

  const optimizedMessageBoxHeight = useMemo(() => {
    return typeof messagesBoxHeight === "number" && messagesBoxHeight > 160
      ? `${messagesBoxHeight}px`
      : "160px";
  }, [messagesBoxHeight]);

  const handlePreviousMessagesRetry = () => {
    setArePreviousMessagesLoading(true);
    setMessagesError("");
  };

  return (
    <View
      attributes={{
        style: {
          height: `calc(100% - ${optimizedMessageBoxHeight})`,
          overflow: "hidden",
          position: "relative",
        },
        ref: chatContainerRef,
      }}
    >
      <View
        className={common.custom__scroll}
        padding={xl}
        paddingTop={xxl + sm}
        paddingBottom={lg + xs}
        attributes={{
          style: {
            overflow: "auto",
            height: "100%",
          },
        }}
      >
        <View.Item grow>
          {arePreviousMessagesLoading && (
            <View
              attributes={{
                style: {
                  marginBlockStart: "calc(1.5 * var(--spacing-xl))",
                },
              }}
            >
              <ReshapedLoader size="small" />
            </View>
          )}
          {messagesError && (
            <View direction="row" align="center" justify="center">
              <ErrorSticker backgroundColor="white" variant="body-2" text={t(messagesError)} />
              <Button
                color="critical"
                variant="ghost"
                size="small"
                startIcon={() => <Reload fill={baseColor.critical} />}
                onClick={handlePreviousMessagesRetry}
              >
                {t("Please try again.")}
              </Button>
            </View>
          )}
          {messages && Array.isArray(messages) && (
            <>
              {messages.length <= 0 && <NoMessages />}
              {messages.length === 1 && (
                <View justify="end" height="100%">
                  {areKeysAvailableWithType({
                    object: messages[0],
                    keys: [{ showSubject: true, exact: true }, { subject: "string" }],
                  }) && (
                    <View
                      direction="row"
                      align="center"
                      justify="center"
                      className={styles.separator}
                      attributes={{ style: { marginBlockEnd: "60px" } }}
                    >
                      <View maxWidth="500px" backgroundColor="white" padding={lg}>
                        <Text align="center" variant="body-2" color="neutral-faded">
                          {messages[0].subject}
                        </Text>
                      </View>
                    </View>
                  )}
                  <ChatMessage
                    key={messages[0].id}
                    isRecipient={messages[0].isRecipient}
                    data={messages[0]}
                    {...{
                      handleRetryFailedEmail,
                      handleDeleteFailedEmail,
                      handleDeleteBackendFailedEmail,
                    }}
                  />
                </View>
              )}
              {messages.length > 1 && (
                <>
                  <div ref={firstMessageRef} />
                  <View gap={lg + xs} height="100%">
                    {messages.map((message, index) => {
                      return (
                        <View.Item key={message.id} grow={index === 0 ? true : false}>
                          <View
                            height="100%"
                            justify="end"
                            className={
                              messageInFocusRef.current && message.id === messageInFocusRef.current
                                ? `${styles.scroll__padding} in__focus`
                                : ""
                            }
                          >
                            <View gap={3 * (lg + xs)}>
                              {areKeysAvailableWithType({
                                object: message,
                                keys: [{ showSubject: true, exact: true }, { subject: "string" }],
                              }) && (
                                <View
                                  direction="row"
                                  align="center"
                                  justify="center"
                                  className={styles.separator}
                                >
                                  <View maxWidth="500px" backgroundColor="white" padding={lg}>
                                    <Text align="center" variant="body-2" color="neutral-faded">
                                      {message.subject}
                                    </Text>
                                  </View>
                                </View>
                              )}
                              <ChatMessage
                                isRecipient={message.isRecipient}
                                data={message}
                                {...{
                                  handleRetryFailedEmail,
                                  handleDeleteFailedEmail,
                                  handleDeleteBackendFailedEmail,
                                }}
                              />
                            </View>
                          </View>
                        </View.Item>
                      );
                    })}
                  </View>
                  <div ref={latestMessageRef} />
                </>
              )}
            </>
          )}
        </View.Item>
      </View>
    </View>
  );
};
