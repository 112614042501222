import { View } from "reshaped";
import { stageOptions } from "utils/constants";
import useSpacing from "components/hooks/useSpacing";
import { TextWithEclipses } from "components/reusables";
import useTranslation from "components/hooks/useTranslation";
import styles from "components/styles/reshaped/settings.module.css";
import { areKeysAvailableIn, areKeysAvailableWithType } from "utils/miniHelpers";

export function Template({ data, onClick }) {
  const { t } = useTranslation();
  const { xs, sm, md, xl } = useSpacing();

  const handleClick = () => {
    if (onClick && typeof onClick === "function") {
      onClick({ data });
    }
  };

  return (
    <View
      as="button"
      backgroundColor="white"
      width="100%"
      direction="row"
      align="center"
      borderRadius={xs}
      padding={md}
      className={styles.template}
      gap={xl}
      attributes={{
        onClick: handleClick,
      }}
    >
      <View.Item columns={4}>
        <TextWithEclipses
          align="start"
          color="primary"
          text={data.name}
          characterLimit={35}
          variant="body-2"
        />
      </View.Item>
      <View.Item columns={5}>
        <TextWithEclipses
          align="start"
          color="neutral"
          text={`${data.subject}`}
          characterLimit={35}
          variant="body-2"
        />
      </View.Item>
      <View.Item columns={3}>
        <View width="100%" direction="row" align="center" justify="end">
          {data.trigger &&
            areKeysAvailableWithType({
              object: data,
              keys: [{ is_default_trigger: true, exact: true }],
            }) &&
            areKeysAvailableIn({ object: stageOptions[data.trigger], keys: ["title"] }) && (
              <View padding={[xs, sm]} backgroundColor="primary-faded" borderRadius="small">
                <TextWithEclipses
                  color="primary"
                  text={t(stageOptions[data.trigger].title)}
                  characterLimit={25}
                  variant="body-2"
                />
              </View>
            )}
        </View>
      </View.Item>
    </View>
  );
}
