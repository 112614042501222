import { baseColor, cooperColors } from "utils/colors/accents";

export const Briefcase = ({ color = baseColor.white }) => {
  return (
    <svg
      className="briefcase"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.66685 18.3333H13.3335C16.6835 18.3333 17.2835 16.9917 17.4585 15.3583L18.0835 8.69167C18.3085 6.65833 17.7252 5 14.1668 5H5.83351C2.27518 5 1.69185 6.65833 1.91685 8.69167L2.54185 15.3583C2.71685 16.9917 3.31685 18.3333 6.66685 18.3333Z"
        stroke={color}
        strokeWidth="1"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.6665 4.99984V4.33317C6.6665 2.85817 6.6665 1.6665 9.33317 1.6665H10.6665C13.3332 1.6665 13.3332 2.85817 13.3332 4.33317V4.99984"
        stroke={color}
        strokeWidth="1"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6668 10.8333V11.6667C11.6668 11.675 11.6668 11.675 11.6668 11.6833C11.6668 12.5917 11.6585 13.3333 10.0002 13.3333C8.35016 13.3333 8.3335 12.6 8.3335 11.6917V10.8333C8.3335 10 8.3335 10 9.16683 10H10.8335C11.6668 10 11.6668 10 11.6668 10.8333Z"
        stroke={color}
        strokeWidth="1"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.0415 9.1665C16.1165 10.5665 13.9165 11.3998 11.6665 11.6832"
        stroke={color}
        strokeWidth="1"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.18311 9.3916C4.05811 10.6749 6.17477 11.4499 8.3331 11.6916"
        stroke={color}
        strokeWidth="1"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const Briefcase2 = ({ color = cooperColors["foreground-neutral"] }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 5C1.5 4.44772 1.94772 4 2.5 4H13.5C14.0523 4 14.5 4.44772 14.5 5V13C14.5 13.5523 14.0523 14 13.5 14H2.5C1.94772 14 1.5 13.5523 1.5 13V5ZM13.5 5H2.5V13H13.5V5Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.43934 2.43934C5.72064 2.15804 6.10218 2 6.5 2H9.5C9.89782 2 10.2794 2.15804 10.5607 2.43934C10.842 2.72064 11 3.10217 11 3.5V4.5C11 4.77614 10.7761 5 10.5 5C10.2239 5 10 4.77614 10 4.5V3.5C10 3.36739 9.94732 3.24021 9.85355 3.14645C9.75979 3.05268 9.63261 3 9.5 3H6.5C6.36739 3 6.24022 3.05268 6.14645 3.14645C6.05268 3.24021 6 3.36739 6 3.5V4.5C6 4.77614 5.77614 5 5.5 5C5.22386 5 5 4.77614 5 4.5V3.5C5 3.10217 5.15804 2.72064 5.43934 2.43934Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4328 7.64343C14.5711 7.88248 14.4894 8.18833 14.2503 8.32658C12.3508 9.42517 10.1947 10.0024 8.00035 10C5.80514 10.0055 3.64784 9.42793 1.74909 8.32623C1.51024 8.18764 1.42896 7.88167 1.56755 7.64282C1.70613 7.40397 2.0121 7.32269 2.25095 7.46128C3.99676 8.47424 5.98033 9.00526 7.99872 9L8.00062 9C10.0191 9.0024 12.0024 8.47146 13.7497 7.46092C13.9887 7.32268 14.2946 7.40439 14.4328 7.64343Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.75 7.5C6.75 7.22386 6.97386 7 7.25 7H8.75C9.02614 7 9.25 7.22386 9.25 7.5C9.25 7.77614 9.02614 8 8.75 8H7.25C6.97386 8 6.75 7.77614 6.75 7.5Z"
        fill={color}
      />
    </svg>
  );
};

export const BriefcaseSmall = ({ color = cooperColors["foreground-neutral-faded"] }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.00011 16.5H12.0001C15.0151 16.5 15.5551 15.2925 15.7126 13.8225L16.2751 7.8225C16.4776 5.9925 15.9526 4.5 12.7501 4.5H5.25011C2.04761 4.5 1.52261 5.9925 1.72511 7.8225L2.28761 13.8225C2.44511 15.2925 2.98511 16.5 6.00011 16.5Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 4.5V3.9C6 2.5725 6 1.5 8.4 1.5H9.6C12 1.5 12 2.5725 12 3.9V4.5"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 9.75V10.5C10.5 10.5075 10.5 10.5075 10.5 10.515C10.5 11.3325 10.4925 12 9 12C7.515 12 7.5 11.34 7.5 10.5225V9.75C7.5 9 7.5 9 8.25 9H9.75C10.5 9 10.5 9 10.5 9.75Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.2375 8.25C14.505 9.51 12.525 10.26 10.5 10.515"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.96484 8.45312C3.65234 9.60813 5.55734 10.3056 7.49984 10.5231"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
