import { ATS_JOBS_FRONTEND_URL } from "constants";
import { getTextWithEclipses } from "utils/getTextWithEclipses";
import { CUSTOMURLWITHNOMULTIPLESYMBOLSREGEX } from "utils/RegularExpressions";

export function getJobURL({ jobTitle = "", companyID, jobID }) {
  if (!companyID && !jobID && !jobTitle) return "";
  let sanitizedTitle = jobTitle.replace("/", "");
  sanitizedTitle = sanitizedTitle.replace(/\s/g, "-");
  const URL = `${ATS_JOBS_FRONTEND_URL}/jobs/${getTextWithEclipses(
    sanitizedTitle,
    20,
    0
  )}?cid=${companyID}&jid=${jobID}`;
  return URL;
}

const COOPERHIRE_JOBS_URL = ATS_JOBS_FRONTEND_URL;

export function getSanitizedJobURL({ jobTitle = "", companyID, jobID, slug = "company" }) {
  if (!companyID && !jobID && !jobTitle) return "";

  const companiesPostfix = `${COOPERHIRE_JOBS_URL}/companies`;

  const MAX_URL_LENGTH = 110;
  const SLUG_LENGTH = slug.length;
  const JOB_ID_LENGTH = `${jobID}`.length;
  const COMPANY_URL_LENGTH = companiesPostfix.length;

  // use hyphen instead of space
  let sanitizedTitle = jobTitle.replace(/\s+/g, "-");

  // Handling possible incorrect routes if jobs titles contains symbols except hyphen
  sanitizedTitle = sanitizedTitle.replace(CUSTOMURLWITHNOMULTIPLESYMBOLSREGEX, "");

  // removing 2 or more occurence of hypens
  sanitizedTitle = sanitizedTitle.replace(/-+/g, "-");

  // removing hyphen in first character occurence
  sanitizedTitle = sanitizedTitle.replace(/^-+/g, "");

  let URL = `${companiesPostfix}/${slug}/${jobID}-${sanitizedTitle}`;
  if (URL.length <= MAX_URL_LENGTH) {
    return URL;
  }

  const SLASH = "/".length;
  const HYPHEN = "-".length;
  const MAX_JOB_TITLE_LENGTH =
    MAX_URL_LENGTH - COMPANY_URL_LENGTH - SLASH - SLUG_LENGTH - SLASH - JOB_ID_LENGTH - HYPHEN;

  const finalTitle = getFormattedTitle({ title: sanitizedTitle, maxLength: MAX_JOB_TITLE_LENGTH });

  return `${companiesPostfix}/${slug}/${jobID}-${finalTitle ? finalTitle : sanitizedTitle}`;
}

function getFormattedTitle({
  maxLength = 16,
  title = "",
  minimumWords = 3,
  noOfCharactersInLastWord = 4,
}) {
  if (title.length <= maxLength) return title;

  const titleWithHyphenBreak = title.split("-");
  if (titleWithHyphenBreak.length <= minimumWords) return titleWithHyphenBreak.join("-");

  const titleWithThreeWords = [...titleWithHyphenBreak];
  titleWithThreeWords.length = minimumWords;
  if (titleWithThreeWords[minimumWords - 1].length >= noOfCharactersInLastWord)
    return titleWithThreeWords.join("-");

  return getSentenceWithFourCharactersInLastWord({
    sentence: titleWithHyphenBreak.join("-"),
    splitter: "-",
  });
}

function getSentenceWithFourCharactersInLastWord({
  sentence = "",
  splitter = " ",
  minimumWords = 3,
  noOfCharactersInLastWord = 4,
}) {
  if (!sentence) return "";

  const splittedSentence = sentence.split(splitter);

  let finalSentenceArray = [];

  for (let [i, word] of splittedSentence.entries()) {
    if (word.length >= noOfCharactersInLastWord && i + 1 >= minimumWords) {
      // finalSentenceArray.push(word.replace(/^(\(|\]|\[|\))+/g, ""));
      finalSentenceArray.push(word);
      break;
    }
    finalSentenceArray.push(word);
  }

  return finalSentenceArray.join(splitter);
}
