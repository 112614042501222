import { countryHelper } from "utils/countryHelpers";
import { areKeysAvailableWithType, isString } from "utils/miniHelpers";

export function getTextSize({ text = "" }) {
  if (typeof text !== "string") return 0;
  const blob = new Blob([text]);
  const textContentSize = areKeysAvailableWithType({ object: blob, keys: [{ size: "number" }] })
    ? blob.size
    : 0;
  return textContentSize;
}

export function getLocationFromCityAndCountry({
  city,
  country,
  noLocationText,
  locale = "en",
  countryList,
}) {
  let location = "";
  let hasCity = false;
  const sanitizedNoLocationText = typeof noLocationText === "string" ? noLocationText : "";

  if (city && typeof city === "string" && city.length > 0) {
    location = city;
    hasCity = true;
  }

  if (country && typeof country === "string" && country.length > 0) {
    const sanitizedCountry = countryHelper(countryList).getCountryName({
      country,
      locale,
      defaultValue: country,
    });
    if (hasCity) {
      location = `${location}, ${sanitizedCountry}`;
    } else {
      location = `${sanitizedCountry}`;
    }
  }

  if (!location || location === "") return sanitizedNoLocationText;

  return location;
}

export function getAbsoluteURL({ href = "" }) {
  if (!isString(href)) return "";

  function sanitizeURL(url) {
    let sanitizedURL = url.replace(/^\/+/, "");
    sanitizedURL = url.replace(/^:+/, "");
    sanitizedURL = url.replace(/^\/+/, "");
    return `https://${sanitizedURL}`;
  }

  if (href.match(origin)) {
    const origin = window.location.origin;
    const search = window.location.search;
    const pathname = window.location.pathname;

    let newHref = href.replace(origin, "");
    newHref = newHref.replace(pathname, "");
    newHref = newHref.replace(search, "");
    return sanitizeURL(newHref);
  }

  if (/^https?:\/\//.test(href)) return href;

  return sanitizeURL(href);
}
