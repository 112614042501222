import { Button, View } from "reshaped";

import { Plus } from "icons";
import { cooperColors } from "utils/colors/accents";
import useSpacing from "components/hooks/useSpacing";
import useTranslation from "components/hooks/useTranslation";
import Evaluation from "components/pages/candidates/categories/evalution/Evaluation";

const Evaluations = (props) => {
  const { setEditEvaluationID, setAddEvaluation, evaluations } = props;

  const { t } = useTranslation();
  const { xs, lg, xl } = useSpacing();

  const handleAddEvaluation = () => {
    setAddEvaluation(true);
  };

  return (
    <View gap={lg}>
      <View gap={xs + lg} padding={[xl, 0]}>
        {evaluations.map((evaluation) => {
          return (
            <Evaluation
              key={evaluation.id}
              setEditEvaluationID={setEditEvaluationID}
              evaluation={evaluation}
              noComment={false}
            />
          );
        })}
      </View>
      <View direction="row" justify="end" align="center">
        <Button
          size="small"
          color="primary"
          variant="ghost"
          startIcon={() => <Plus fill={cooperColors["foreground-primary"]} />}
          onClick={handleAddEvaluation}
        >
          {t("Add Evaluation")}
        </Button>
      </View>
    </View>
  );
};
export default Evaluations;
