import React, { useState, useEffect } from "react";
import { Button, View } from "reshaped";

import { Plus, InputSearchIcon } from "icons";
import { filtersSlice } from "redux/filters";
import { candidateSlice } from "redux/candidate";
import { CustomInputBox } from "components/reusables/common";
import styles from "components/styles/reshaped/candidates.module.css";
import useSpacing from "components/hooks/useSpacing";
import { useDispatch, useSelector } from "react-redux";
import useTranslation from "components/hooks/useTranslation";
import { cooperColors } from "utils/colors/accents";
import { fetchFilteredCandidates } from "utils/pages/candidate";
import { areKeysAvailableWithType, sanitizeArray } from "utils/miniHelpers";
import useUserLanguagePreference from "components/hooks/useUserLanguagePreference";
import { FiltersAndSorting, FilterChips } from "components/pages/candidates";

export const CandidatesSearch = (props) => {
  const { handleModalOpen } = props;

  const { t } = useTranslation();
  const { sm, xs, lg, xl, md } = useSpacing();

  const [searchTerm, setSearchTerm] = useState("");
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const userLang = useUserLanguagePreference();

  const { candidates: candidatesFilters } = state.filters;
  const { jobs, stages, search } = candidatesFilters;
  const { selected: selectedJobs } = jobs;
  const { selected: selectedStages } = stages;
  const { selected: selectedSearches } = search;
  const [debouncedTerms, setDebouncedTerms] = useState([]);

  const { user } = state.auth;
  const { candidates } = state.sorting;

  useEffect(() => {
    setDebouncedTerms(selectedSearches);
  }, [selectedSearches]);

  const handleSubmit = async ({ searchTerms }) => {
    dispatch(
      candidateSlice.actions.set({
        isFiltering: true,
        filteringError: null,
      })
    );

    const selectedSearches = sanitizeArray({ array: searchTerms, elementType: "string" });

    const response = await fetchFilteredCandidates({
      company_id: user.company_id,
      selectedJobs: selectedJobs,
      selectedStages: selectedStages,
      sorting: candidates,
      current_language: userLang,
      selectedSearches: selectedSearches,
    });

    dispatch(
      filtersSlice.actions.setPageFilter({
        page: "candidates",
        types: { name: "search", value: { selected: selectedSearches } },
      })
    );

    if (areKeysAvailableWithType({ object: response, keys: [{ data: "object" }] })) {
      const { candidates, totalCandidates } = response.data;
      dispatch(
        candidateSlice.actions.set({
          pageCount: 0,
          isFiltering: false,
          candidates,
          totalCandidates,
        })
      );
    }

    if (areKeysAvailableWithType({ object: response, keys: [{ hasError: "boolean" }] })) {
      dispatch(
        candidateSlice.actions.set({
          pageCount: 0,
          isFiltering: false,
          totalCandidates: 0,
          filteringError: "something went wrong!",
        })
      );
    }

    setSearchTerm("");
  };

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
    if (e.target.value === "") {
      clearSearch();
    }
  };

  const clearSearch = () => {
    setSearchTerm("");
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit({ searchTerms: [...debouncedTerms, searchTerm] });
    }
  };

  return (
    <View gap={lg + xs / 2}>
      <View direction="row" align="center" gap={{ m: xl, l: xl + sm, xl: xl * 2 }}>
        <View direction="row" align="center" gap={md}>
          <CustomInputBox
            value={searchTerm}
            startIcon={<InputSearchIcon strokeColor={cooperColors["foreground-neutral-faded"]} />}
            placeholder={t("Search candidates")}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            className={styles.border__less__text__field}
            style={{
              minHeight: "40px",
              width: "300px",
            }}
          />
          <FiltersAndSorting />
        </View>
        <View.Item grow>
          <View direction="row" justify="end" align="center" width="100%" gap={lg}>
            <View divided direction="row" align="center" gap={lg}>
              {/* <Button
              size="small"
              variant="ghost"
              color="primary"
              startIcon={Reload}
              disabled={refetching}
              onClick={handleRefetchCandidateData}
              className={refetching ? common.rotate : ""}
              attributes={{
                title: t("Refresh candidates"),
              }}
            /> */}
              <View borderRadius="medium" overflow="hidden">
                <Button color="primary" startIcon={Plus} onClick={handleModalOpen}>
                  {t("Add Candidate")}
                </Button>
              </View>
            </View>
          </View>
        </View.Item>
      </View>
      <FilterChips />
    </View>
  );
};
