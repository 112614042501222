import { useCallback } from "react";
import {
  Button,
  Dismissible,
  FormControl,
  Radio,
  RadioGroup,
  View,
  Text,
  TextField,
  useToast,
} from "reshaped";

import { metaSlice } from "redux/meta";
import { backendAPI } from "utils/axios";
import { invitationSlice } from "redux/invitation";
import { validateErrors } from "utils/validateError";
import { useDispatch, useSelector } from "react-redux";
import { reloadOnUnauthorized } from "utils/checkForAuth";
import { getNameFromEmail } from "utils/getNameFromEmail";
import { ShowToastWithTranslation } from "utils/showToast";
import useTranslation from "components/hooks/useTranslation";
import { areKeysAvailableWithType } from "utils/miniHelpers";
import { isEmailRestricted } from "utils/checkRestrictionEmail";
import { EMAILVALIDATIONREGEX } from "utils/RegularExpressions";
import { OperationFailedWithTranslation } from "components/reusables/OperationFailed";
import { exceededUsersLimit, isCronjobDowngradeTriggered } from "utils/pages/settings";
import { fetchSubscriptionData } from "redux/subscriptions/subscriptionsSlice";

export const EmailInvite = (props) => {
  const {
    email,
    setEmail,
    role,
    setRole,
    setInvitationStage,
    error,
    setError,
    resetInvitation,
    subscription,
  } = props;

  const toast = useToast();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { auth, meta } = useSelector((state) => state);

  const { user } = auth;
  const { isLoading } = meta;

  const validate = useCallback((errorName, name, handleError, regex) => {
    validateErrors(errorName, name, handleError, t, regex);

    //eslint-disable-next-line
  }, []);

  const handleEmail = ({ value }) => {
    if (error.email) {
      setError((previousError) => {
        return {
          ...previousError,
          email: null,
        };
      });
    }
    setEmail(value);
  };

  const handleEmailValidation = (e) => {
    validate("email", email, setError, EMAILVALIDATIONREGEX);
  };

  const handleModalClose = () => {
    resetInvitation({ ...props });
  };

  const sendEmail = async (data) => {
    if (exceededUsersLimit(subscription)) {
      ShowToastWithTranslation({
        toast,
        Children: OperationFailedWithTranslation,
        text: t(
          `Please update your payment plan before adding more users. You cannot invite more than {{subscription.max_users}} users with the {{subscription.subscription_type}} plan`
        )
          .replace("{{subscription.max_users}}", subscription.max_users)
          .replace("{{subscription.subscription_type}}", subscription.type),
      });
      return;
    }

    if (isCronjobDowngradeTriggered(subscription)) {
      ShowToastWithTranslation({
        toast,
        Children: OperationFailedWithTranslation,
        text: t(
          "You have already scheduled a downgrade for your subscription, please wait for the downgrade to take effect before changing the plan!"
        ),
      });
      return;
    }

    const first_name = getNameFromEmail(email);
    const invite = {
      ...data,
      first_name,
    };
    try {
      dispatch(metaSlice.actions.initialLoading());
      const response = await backendAPI.post("/users/invite", { invite });
      dispatch(metaSlice.actions.stopLoading());
      if (!response.status && !response.data) {
        return setError({
          email: t("Something went wrong"),
        });
      }
      if (response.status === 200 && response.data.user) {
        dispatch(invitationSlice.actions.addPendingMember(response.data.user));
        setInvitationStage("sent");
        dispatch(fetchSubscriptionData());
      }
    } catch (err) {
      reloadOnUnauthorized(err);
      dispatch(metaSlice.actions.stopLoading());
      if (err.response && err.response.status === 422 && err.response.data) {
        const { data } = err.response;
        if (data.errors && Array.isArray(data.errors.email))
          return setError({
            email: t(data.errors.email[0]),
          });
      }
      if (
        err.response &&
        err.response.status === 401 &&
        err.response.data &&
        err.response.data.error
      ) {
        ShowToastWithTranslation({
          toast,
          Children: OperationFailedWithTranslation,
          text: t(err.response.data.error),
        });
        return handleModalClose();
      }

      if (
        areKeysAvailableWithType({
          object: err.response,
          keys: [
            {
              data: "object",
              callback: (value) =>
                areKeysAvailableWithType({
                  object: value,
                  keys: [{ error: "This email is already in use.", exact: true }],
                }),
            },
          ],
        })
      ) {
        setError({
          email: t("Email already exists"),
        });
        return;
      }

      setError({
        email: t("Something went wrong"),
      });
    }
  };

  const handleRole = ({ value }) => {
    setRole(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let hasError = false;

    // if user submitting with empty or invalid fields
    for (let field in error) {
      if (error[field]) {
        hasError = true;
        if (typeof error[field] === "object") {
          setError((previousError) => {
            return {
              ...previousError,
              [field]: t(`${field.split(/(?=[A-Z])/).join(" ")} is required`),
            };
          });
        }
      }
    }

    if (hasError) return;

    // Not allowing restricted emails
    if (isEmailRestricted(email)) {
      setError((previousError) => {
        return {
          ...previousError,
          email: t("Email is restricted"),
        };
      });
      return;
    }

    if (user && user.role === "member") {
      ShowToastWithTranslation({
        toast,
        Children: OperationFailedWithTranslation,
        text: t("Only admins can send email invites"),
      });
      handleModalClose();
      return;
    }

    await sendEmail({ email, role });
  };

  return (
    <View gap={4} padding={2}>
      <View direction="row" align="center">
        <Text variant="body-strong-1">{t("Email Invite")}</Text>
        <View.Item grow>
          <Dismissible onClose={handleModalClose} closeAriaLabel="Close modal" />
        </View.Item>
      </View>
      <FormControl required hasError={error["email"] && typeof error["email"] !== "object"}>
        <FormControl.Label>{t("Email")}</FormControl.Label>
        <TextField
          onChange={handleEmail}
          inputAttributes={{
            type: "email",
            onBlur: handleEmailValidation,
            autoFocus: true,
          }}
          value={email}
        />
        <FormControl.Error>{error["email"]}</FormControl.Error>
      </FormControl>
      <FormControl group>
        <FormControl.Label>{t("Role")}</FormControl.Label>
        <RadioGroup defaultValue={role} name="role group" onChange={handleRole}>
          <View gap={4} direction="row">
            <Radio value="admin">{t("Admin")}</Radio>
            <Radio value="member">{t("Member")}</Radio>
          </View>
        </RadioGroup>
      </FormControl>
      <Button
        onClick={handleSubmit}
        loading={isLoading}
        fullWidth
        attributes={{ type: "submit" }}
        color="primary"
      >
        {t("Send invite")}
      </Button>
    </View>
  );
};
