import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import useModalContext from "components/context/modal/useModalContext";

export function ScrollToTop() {
  const { pathname } = useLocation();
  const { isModalOpen } = useModalContext();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (isModalOpen) {
      document.body.setAttribute("class", "avoid__scroll");
    } else {
      document.body.removeAttribute("class");
    }
  }, [pathname, isModalOpen]);

  return null;
}
