import { useState } from "react";
import { Loader, View } from "reshaped";

import { metaSlice } from "redux/meta";
import { backendAPI } from "utils/axios";
import { setInitial } from "utils/setInitial";
import { useDispatch, useSelector } from "react-redux";
import { CompanySize } from "components/pages/register";
import { setUserLeadInBackend } from "redux/meta/metaSlice";
import useTranslation from "components/hooks/useTranslation";
import Titles from "components/reusables/loginAndRegister/Titles";
import common from "components/styles/reshaped/reusables/common.module.css";

export const RegisterCompanySize = (props) => {
  const { title, subTitle, userDetails, companyDetails } = props;

  const { isLoading, userLead } = useSelector((state) => state.meta);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [category, setCategory] = useState(setInitial("category", userLead.company, null));

  const handleSubmit = async (industry_type, companyData, userData) => {
    if (!industry_type) return;

    if (!userData || !companyData) return;

    const company = {
      ...companyData,
      industry_type,
    };
    const user = {
      ...userData,
    };

    dispatch(metaSlice.actions.initialLoading());

    dispatch(metaSlice.actions.setUserLead({ user, company }));
    dispatch(
      setUserLeadInBackend({
        user,
        company,
        step_completed: "final",
        nextStage: "confirm",
      })
    );
    try {
      const res = await backendAPI.post("/users", {
        user,
        company,
      });

      const { status, data } = res;

      dispatch(metaSlice.actions.stopLoading());

      if (status === 201 && data.id && data.company_id) {
        dispatch(metaSlice.actions.setRegistrationStage("confirm"));
      }
    } catch (error) {
      const { data } = error.response;
      if (typeof data.errors === "object") {
        dispatch(metaSlice.actions.stopLoading());
        dispatch(metaSlice.actions.setErrors(data.errors));
        dispatch(metaSlice.actions.setRegistrationStage("user"));
      }
    }
  };

  const params = {
    category,
    setCategory,
    handleSubmit,
    userDetails,
    companyDetails,
  };

  return (
    <View height="100%" width="100%" align="center" padding={[0, 6]} justify="center">
      {!isLoading && (
        <View gap={4} width="100%" maxWidth="50rem">
          <Titles title={title} subTitle={subTitle} />
          <View gap={2}>
            <CompanySize
              size="micro"
              title={t("Micro company")}
              subTitle={`1-9 ${t("employee")}`}
              attribute="micro"
              {...params}
            />
            <CompanySize
              size="small"
              title={t("Small size company")}
              subTitle={`10-49 ${t("employee")}`}
              attribute="small"
              {...params}
            />
            <CompanySize
              size="medium"
              title={t("Medium size company")}
              subTitle={`50-199 ${t("employee")}`}
              attribute="medium"
              {...params}
            />
            <CompanySize
              size="large"
              title={t("Large size company")}
              subTitle={`200+ ${t("employee")}`}
              attribute="large"
              {...params}
            />
            <CompanySize
              size="hiring_agency"
              title={t("Hiring Agency")}
              subTitle={t("Find suitable candidates for employees")}
              attribute="hiring_agency"
              {...params}
            />
          </View>
        </View>
      )}
      {isLoading && <Loader size="medium" className={common.loader__big} />}
    </View>
  );
};
