import { useMemo } from "react";
import { Avatar, Text, View } from "reshaped";

import useName from "components/hooks/useName";
import useSpacing from "components/hooks/useSpacing";
import useFullName from "components/hooks/useFullName";
import { TextWithEclipses } from "components/reusables";
import { stageBackgroundColor } from "utils/colors/stage";
import useTranslation from "components/hooks/useTranslation";
import { areKeysAvailableWithType, getValueFrom, isString } from "utils/miniHelpers";
import { Property, ChangeCandidateStage, EditCandidate } from "components/pages/candidates";

export const CandidateStat = (props) => {
  const { candidate } = props;
  const { first_name, last_name, email, phone_no, stage = "applied", job, id } = candidate;

  const { xs, sm, xl } = useSpacing();
  const { t, locale } = useTranslation();
  const candidateProfile = useName({ first_name, last_name, info: candidate });
  const fullName = useFullName({ first_name, last_name, dependency: [candidate] });

  const jobInfo = useMemo(() => {
    const versions = {
      de: "Keine Jobinformationen",
      en: "No job information",
    };

    const result = {
      name: getValueFrom({
        object: versions,
        key: locale,
        defaultKey: "en",
        defaultValue: "NA",
      }),
      isDeleted: false,
    };

    if (areKeysAvailableWithType({ object: job, keys: [{ title: "string" }] })) {
      result.name = job.title;
      if (areKeysAvailableWithType({ object: job, keys: [{ deleted: true, exact: true }] })) {
        result.isDeleted = true;
      }
    }

    return result;

    //eslint-disable-next-line
  }, []);

  return (
    <View
      direction="row"
      gap={xl}
      borderRadius="large"
      padding={[xl + xs, xl + sm]}
      attributes={{
        style: {
          backgroundColor: stageBackgroundColor[stage.toLowerCase()],
        },
      }}
    >
      <View.Item columns={6}>
        <View
          borderColor="primary"
          attributes={{
            style: {
              width: "max-content",
              borderRadius: "50%",
            },
          }}
        >
          {candidateProfile.hasURL && (
            <Avatar
              size={12}
              src={candidateProfile.url}
              alt={candidate ? `${candidate.first_name + candidate.last_name}` : "John Doe"}
            />
          )}
          {!candidateProfile.hasURL && (
            <Avatar
              size={12}
              initials={candidateProfile.letters}
              alt={candidate ? `${candidate.first_name + candidate.last_name}` : "John Doe"}
            />
          )}
        </View>
      </View.Item>
      <View.Item columns={6}>
        <ChangeCandidateStage candidateStage={stage} fullName={fullName} />
      </View.Item>
      <View.Item columns={6}>
        <EditCandidate
          candidateID={id}
          propertyName={"name"}
          label={t("Full Name")}
          defaultValue={fullName}
          placeholder={t("Full Name")}
          stage={isString(stage) ? stage : "applied"}
        />
      </View.Item>
      <Property title={t("Position applied for")}>
        <View gap={sm}>
          <TextWithEclipses
            variant="body-strong-2"
            text={`${jobInfo.name}`}
            characterLimit={jobInfo.isDeleted ? 25 : 48}
            noOfEclipses={2}
            attributes={{
              style: {
                textTransform: "capitalize",
                wordBreak: "break-all",
              },
            }}
          />
          {jobInfo.isDeleted && (
            <Text variant="body-strong-2" color="critical">
              {"("}
              {t("Deleted")}
              {")"}
            </Text>
          )}
        </View>
      </Property>
      <View.Item columns={6}>
        <EditCandidate
          candidateID={id}
          defaultValue={email}
          propertyName={"email"}
          label={t("Email Address")}
          placeholder={t("Email Address")}
          stage={isString(stage) ? stage : "applied"}
        />
      </View.Item>
      <View.Item columns={6}>
        <EditCandidate
          candidateID={id}
          label={t("Phone")}
          propertyName={"phone_no"}
          placeholder={""}
          stage={isString(stage) ? stage : "applied"}
          defaultValue={phone_no && typeof phone_no === "string" ? phone_no : ""}
        />
      </View.Item>
    </View>
  );
};
