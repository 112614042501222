import { baseColor } from "utils/colors/accents";

export const UserSquare = ({ color = baseColor.white }) => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.6171 18.0166C14.8838 18.2333 14.0171 18.3333 13.0005 18.3333H8.00046C6.98379 18.3333 6.11712 18.2333 5.38379 18.0166C5.56712 15.85 7.79212 14.1416 10.5005 14.1416C13.2088 14.1416 15.4338 15.85 15.6171 18.0166Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9998 1.6665H7.99984C3.83317 1.6665 2.1665 3.33317 2.1665 7.49984V12.4998C2.1665 15.6498 3.1165 17.3748 5.38317 18.0165C5.5665 15.8498 7.7915 14.1415 10.4998 14.1415C13.2082 14.1415 15.4332 15.8498 15.6165 18.0165C17.8832 17.3748 18.8332 15.6498 18.8332 12.4998V7.49984C18.8332 3.33317 17.1665 1.6665 12.9998 1.6665ZM10.4998 11.8082C8.84984 11.8082 7.5165 10.4665 7.5165 8.81652C7.5165 7.16652 8.84984 5.83317 10.4998 5.83317C12.1498 5.83317 13.4832 7.16652 13.4832 8.81652C13.4832 10.4665 12.1498 11.8082 10.4998 11.8082Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4833 8.81684C13.4833 10.4668 12.1499 11.8085 10.4999 11.8085C8.84994 11.8085 7.5166 10.4668 7.5166 8.81684C7.5166 7.16684 8.84994 5.8335 10.4999 5.8335C12.1499 5.8335 13.4833 7.16684 13.4833 8.81684Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
