import { backendAPI } from "utils/axios";
import { useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import PricingTick from "assets/images/PricingTick";
import useSpacing from "components/hooks/useSpacing";
import { reloadOnUnauthorized } from "utils/checkForAuth";
import { ShowToastWithTranslation } from "utils/showToast";
import { countriesAndCurrencies, currenciesAndSymbols } from "utils/constants";
import useTranslation from "components/hooks/useTranslation";
import { Capitalize, formatCurrency, formatDate, getPlanChangeSummary, isCronjobDowngradeTriggered, planFeaturesList } from "utils/pages/settings";
import { OperationFailedWithTranslation } from "components/reusables";
import ChangePlanModal from "components/pages/settings/ChangePlanModal";
import { Tabs, View, Text, Button, useToast, useToggle } from "reshaped";
import { SuccessfulToast } from "components/reusables/tab/SuccessfulToast";
import { fetchSubscriptionData } from "redux/subscriptions/subscriptionsSlice";
import styles from "components/styles/reshaped/settings.module.css";
import { DiscountPill } from "components/reusables/DiscountPill";

function ChangePlanTabs({ subscription, subscriptionPrices, companyCountryCode }) {
  const [value, setValue] = useState("monthly");
  const { t } = useTranslation();

  return (
    <View
      attributes={{
        style: {
          paddingInline: "24px",
        },
      }}
    >
      <View paddingBottom={1}>
        <Text variant="title-2">{t("Change Plan")}</Text>
      </View>
      <Tabs value={value} onChange={({ value }) => setValue(value)}>
        <Tabs.List>
          <Tabs.Item value="monthly">{t("Monthly")}</Tabs.Item>
          <Tabs.Item value="yearly">{t("Yearly")}</Tabs.Item>
        </Tabs.List>
        <PricingLayout
          planInterval={value}
          subscription={subscription}
          subscriptionPrices={subscriptionPrices}
          companyCountryCode={companyCountryCode}
        />
      </Tabs>
    </View>
  );
}

export default ChangePlanTabs;

function PricingLayout({ planInterval, subscription, subscriptionPrices, companyCountryCode }) {
  return (
    <View padding={4}>
      <Tabs.Panel value={planInterval}>
        <View gap={4}>
          {subscriptionPrices.map(
            (plan) =>
              plan.title !== "Free" &&
              subscription.status && (
                <PlanCard
                  key={plan.title}
                  planInterval={planInterval}
                  plan={plan}
                  subscription={subscription}
                  subscriptionPrices={subscriptionPrices}
                  companyCountryCode={companyCountryCode}
                />
              )
          )}
        </View>
      </Tabs.Panel>
    </View>
  );
}

function PlanCard({ plan, planInterval, subscription, subscriptionPrices, companyCountryCode }) {
  const country = companyCountryCode || "uk"; // default to uk for other countries currency
  const planCurrency = subscription.currency || countriesAndCurrencies[country].code;
  const planCurrencySymbol = currenciesAndSymbols[subscription.currency] || countriesAndCurrencies[country].symbol;
  const discount_percent = plan.pricing[planCurrency][planInterval].discount_percent;
  const discount_amount = plan.pricing[planCurrency][planInterval].discount_amount;

  const toast = useToast();
  const { t } = useTranslation();
  const { sm, md, lg, xl } = useSpacing();
  const { activate, deactivate, active } = useToggle(false);
  const [checkoutLoading, setCheckoutLoading] = useState(false);

  const { stripe_price_id: currentPlan } = subscription;

  const planChangeSummary = useMemo(
    () => getPlanChangeSummary(subscription, plan, planInterval, country, subscriptionPrices),
    [subscription, plan, planInterval, country, subscriptionPrices]
  );

  const planChangeText = useMemo(() => {
    const {
      changeType,
      currency,
      proratedCredit,
      newCharge,
      new_plan_name,
      new_plan_price,
      new_interval,
      new_plan_type,
      old_plan_name,
      old_interval,
      next_invoice,
      planLimitsChangeType,
    } = planChangeSummary;

    let planChangeType = changeType;
    let formattedNewPlanPrice = formatCurrency(new_plan_price, currency, country);
    let formattedDowngradeDate = formatDate(next_invoice, "long");

    if (planChangeType === "downgrade") {
      return [
        t("You are about to change your subscription from {{oldInterval}} {{oldPlan}} to {{newInterval}} {{newPlan}}.")
          .replace("{{oldInterval}}", `<b>${t(Capitalize(old_interval))}</b>`)
          .replace("{{oldPlan}}", `<b>${Capitalize(old_plan_name)}</b>`)
          .replace("{{newInterval}}", `<b>${t(Capitalize(new_interval))}</b>`)
          .replace("{{newPlan}}", `<b>${Capitalize(new_plan_name)}</b>`),

        t("Your new subscription will cost {{newPlanPrice}} (plus tax) per {{newInterval}}.")
          .replace("{{newPlanPrice}}", `<b>${formattedNewPlanPrice}</b>`)
          .replace("{{newInterval}}", `<b>${t(new_interval.slice(0, -2))}</b>`),

        t("Your {{planLimitsChangeType}} will be scheduled to take effect on {{downgradeDate}}.")
          .replace("{{planLimitsChangeType}}", `<b>${planLimitsChangeType}</b>`)
          .replace("{{downgradeDate}}", `<b>${formattedDowngradeDate}</b>`),

        t("Please ensure to limit the users and jobs according to the {{newPlanType}} {{newPlan}} plan before due date for the downgrade to take place. If adjustments are not made before the due date, your account will automatically continue on your current plan")
          .replace("{{newPlanType}}", `<b>${t(Capitalize(new_plan_type))}</b>`)
          .replace("{{newPlan}}", `<b>${Capitalize(new_plan_name)}</b>`),
      ];
    } else if (planChangeType === "upgrade") {
      let formattedProratedCredit = formatCurrency(proratedCredit, currency, country);
      let formattedNewCharge = formatCurrency(newCharge, currency, country);

      return [
        t("You are about to change your subscription from {{oldInterval}} {{oldPlan}} to {{newInterval}} {{newPlan}}.")
          .replace("{{oldInterval}}", `<b>${t(Capitalize(old_interval))}</b>`)
          .replace("{{oldPlan}}", `<b>${Capitalize(old_plan_name)}</b>`)
          .replace("{{newInterval}}", `<b>${t(Capitalize(new_interval))}</b>`)
          .replace("{{newPlan}}", `<b>${Capitalize(new_plan_name)}</b>`),

        t("Your new subscription will cost {{newPlanPrice}} (plus tax) per {{newInterval}}.")
          .replace("{{newPlanPrice}}", `<b>${formattedNewPlanPrice}</b>`)
          .replace("{{newInterval}}", `<b>${t(new_interval.slice(0, -2))}</b>`),

        t("Since you have already paid for your current subscription, a prorated credit of {{proratedCredit}} will be applied towards your new subscription. As a result, this {{oldInterval}} you will only be charged {{newCharge}}.")
          .replace("{{proratedCredit}}", `<b>${formattedProratedCredit}</b>`)
          .replace("{{oldInterval}}", `<b>${t(old_interval.slice(0, -2))}</b>`)
          .replace("{{newCharge}}", `<b>${formattedNewCharge}</b>`),

        t(`Your {{planLimitsChangeType}} will takes place immediately.`).replace("{{planLimitsChangeType}}", `<b>${planLimitsChangeType}</b>`),

        t("Starting {{downgradeDate}} you will be charged {{newPlanPrice}} (plus tax) per {{newInterval}}.")
          .replace("{{downgradeDate}}", `<b>${formattedDowngradeDate}</b>`)
          .replace("{{newPlanPrice}}", `<b>${formatCurrency(new_plan_price, currency, country)}</b>`)
          .replace("{{newInterval}}", `<b>${t(new_interval.slice(0, -2))}</b>`)
      ];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planChangeSummary]);

  const modalHeading = useMemo(() => {
    return "Please review the new plan details and features";
  }, []);

  const handleManageSubscription = async () => {
    setCheckoutLoading(true);
    let isFailed = false;

    if (isFailed) return setCheckoutLoading(false);

    try {
      const response = await backendAPI.get("/companies/subscriptions/manage");

      setCheckoutLoading(false);

      if (response.data && response.data.status === 200 && response.data.url) {
        return (window.location.href = response.data.url);
      }
      ShowToastWithTranslation({
        toast,
        Children: OperationFailedWithTranslation,
        text: t("Operation failed!"),
      });
    } catch (error) {
      reloadOnUnauthorized(error);
      setCheckoutLoading(false);
      ShowToastWithTranslation({
        toast,
        Children: OperationFailedWithTranslation,
        text: t("Operation failed!"),
      });
    }
  };

  const dispatch = useDispatch();

  const handlePlanChangeRequest = async (changeType, new_price_id) => {
    if (subscription.status === "canceled") {
      ShowToastWithTranslation({
        toast,
        Children: OperationFailedWithTranslation,
        text: t("Please renew your plan to update the subscription!"),
      });
      return;
    }

    setCheckoutLoading(true);

    try {
      let response = null;
      if (changeType === "upgrade") {
        response = await backendAPI.post("/companies/subscriptions/upgrade", {
          price_id: new_price_id,
        });
      } else if (changeType === "downgrade") {
        response = await backendAPI.post("/companies/subscriptions/change_plan", {
          price_id: new_price_id,
        });
      }

      setCheckoutLoading(false);

      if (response.data && response.status === 200) {
        let notificationText = null;
        const { next_invoice, new_plan_name } = planChangeSummary;

        if (changeType === "downgrade") {
          notificationText = t("Your plan is scheduled to change on") + " " + formatDate(next_invoice) + " " + t("Please ensure everything is within the new limits, according to the") + " " + new_plan_name + " " + t("plan plan_change_toast")
        }
        if (changeType === "upgrade") {
          notificationText = t("Your plan has successfully changed!");
        }

        ShowToastWithTranslation({
          toast,
          Children: SuccessfulToast,
          text: notificationText,
        });
      } else {
        ShowToastWithTranslation({
          toast,
          Children: OperationFailedWithTranslation,
          text: t("Operation failed!"),
        });
      }

      deactivate();
    } catch (error) {
      reloadOnUnauthorized(error);
      setCheckoutLoading(false);
      ShowToastWithTranslation({
        toast,
        Children: OperationFailedWithTranslation,
        text: t("Operation failed!"),
      });
    }
  };

  const planChangeRequest = async (changeType, new_price_id) => {
    await handlePlanChangeRequest(changeType, new_price_id);
    setTimeout(() => {
      dispatch(fetchSubscriptionData());
    }, 2000);
  };

  return (
    <View direction={"row"} gap={10}>
      <View.Item grow={true} columns={4}>
        <View
          direction="row" gap={sm} align="center"
        >
          <Text variant="title-3">{Capitalize(plan.title)}</Text>
          {(!!discount_percent || !!discount_amount) ? <DiscountPill text={(!!discount_percent && `-${discount_percent}%`) || (!!discount_amount && `-${planCurrencySymbol}${discount_amount}`)} /> : null}        </View>
        <View
          borderColor="primary"
          borderRadius="medium"
          overflow="hidden"
          attributes={{
            style: {
              marginTop: xl * 3,
              maxWidth: "fit-content",
            },
          }}
        >
          {currentPlan !== plan.pricing[planCurrency][planInterval].price_id ? (
            <>
              <Button
                color="primary"
                loading={checkoutLoading}
                attributes={{
                  style: {
                    width: "100%",
                    paddingInline: xl * 3,
                  },
                  onClick: () => {
                    if (subscription.status === "canceled") {
                      ShowToastWithTranslation({
                        toast,
                        Children: OperationFailedWithTranslation,
                        text: t("Please renew your plan to update the subscription!"),
                      });
                      return;
                    }
                    if (isCronjobDowngradeTriggered(subscription)) {
                      ShowToastWithTranslation({
                        toast,
                        Children: OperationFailedWithTranslation,
                        text: t("You have already scheduled a downgrade for your subscription, please wait for the downgrade to take effect before changing the plan!"),
                      });
                      return;
                    }
                    activate();
                  },
                }}
              >
                <Text>{t("Change Plan")}</Text>
              </Button>
              <ChangePlanModal
                active={active}
                deactivate={deactivate}
                modalHeading={modalHeading}
                planChangeText={planChangeText}
                planChangeSummary={planChangeSummary}
                subscriptionPrices={subscriptionPrices}
                checkoutLoading={checkoutLoading}
                planChangeRequest={planChangeRequest}
              />
            </>
          ) : (
            <Button
              variant="ghost"
              color="primary"
              loading={checkoutLoading}
              attributes={{
                style: {
                  width: "100%",
                  paddingInline: md * 11,
                },
                onClick: () => {
                  handleManageSubscription();
                },
              }}
            >
              <Text>{t("Manage")}</Text>
            </Button>
          )}
        </View>
      </View.Item>
      <View.Item columns={8}>
        <View
          direction={"row"}
          width={"100%"}
          padding={4}
          attributes={{
            style: {
              justifyContent: "space-between",
              border: "1px solid #E5E5E5",
              borderRadius: lg * 2,
            },
          }}
        >
          <View>
            <Text variant="body-strong-2">{t(Capitalize(planInterval))}</Text>
          </View>
          <View
            gap={2}
            attributes={{
              style: {
                width: "55%",
              },
            }}
          >
            {planFeaturesList(plan.title).map((feature) => (
              <View
                direction={"row"}
                align={"center"}
                key={feature}
                gap={sm}
                attributes={{
                  style: {
                    flexWrap: "nowrap",
                  },
                }}
              >
                <PricingTick />
                <Text key={feature}>{t(feature).replace("{{users_limit}}", plan.users_limit.toString()).replace("{{jobs_limit}}", plan.jobs_limit.toString())}</Text>
              </View>
            ))}
          </View>

          <View attributes={{
            style: {
              flexDirection: "column",
            }
          }}>
            {Math.floor(plan.pricing[planCurrency][planInterval].price !== plan.pricing[planCurrency][planInterval].discounted_price) ? (
              <View attributes={{
                style: {
                  maxWidth: "fit-content",
                  margin: "auto",
                }
              }}>
                <Text
                  align="center"
                  variant="body-strong-1"
                  color="neutral-faded"
                  className={styles.strike__text}
                >
                  {planCurrencySymbol}{Math.floor(plan.pricing[planCurrency][planInterval].price) || 0}
                </Text>
              </View>) : null
            }
            <Text variant="body-strong-1">{planCurrencySymbol}{Math.floor(plan.pricing[planCurrency][planInterval].discounted_price)}  {`(${t("plus tax")})`}</Text>
          </View>
        </View>
      </View.Item>
    </View>
  );
}
