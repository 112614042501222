export const getTextWithEclipses = (text, length = 5, noOfEclipses = 3) => {
  if (typeof text !== "string") return text;
  if (text.length <= length) return text;
  const sanitizedText = text.trim().substring(0, length);
  return sanitizedText.padEnd(length + noOfEclipses, ".");
};

export const areCharactersLengthIsGreaterThan = (text, length) => {
  if (typeof text !== "string") return false;
  return text.length > length;
};

export const isTextExceeding = (text, length) => {
  if (typeof text !== "string") return false;
  if (text.length > length) return true;
  return false;
};
