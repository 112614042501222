import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Checkbox, Text, View } from "reshaped";
import useSpacing from "components/hooks/useSpacing";
import useTranslation from "components/hooks/useTranslation";
import { backendAPI } from "utils/axios";
import { ReshapedLoader } from "components/reusables";

export const FilterAccordionFollowerOptions = ({ handleUpdate, showOptions, setIsUpdated }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(null);
  const [checkedData, setCheckedData] = useState([]);

  const { t } = useTranslation();
  const { lg, sm, xl } = useSpacing();
  const { candidates: candidatesFilterData } = useSelector((state) => state.filters);
  const { followers } = candidatesFilterData;
  const { selected } = followers;

  useEffect(() => {
    if (data.length === 0) {
      fetchRecruiters();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showOptions) {
      setCheckedData(selected);
    }
    handleUpdate({ updatedData: { selected } });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showOptions, selected]);

  const fetchRecruiters = async () => {
    setLoading(true);
    setHasError(null);
    try {
      const response = await backendAPI.get("/followers/recruiters");
      if (response.status === 200 && Array.isArray(response?.data?.recruiters)) {
        setData(response?.data?.recruiters);
      } else {
        throw new Error("Invalid response format");
      }
    } catch (error) {
      console.error("Error fetching recruiters:", error);
      setHasError("Failed to fetch recruiters");
    } finally {
      setLoading(false);
    }
  };

  const handleChange = ({ value, event }) => {
    setIsUpdated(true);
    if (event.target.checked) {
      setCheckedData((prev) => {
        const newData = [...prev, value];
        handleUpdate({ updatedData: { selected: newData } });
        return newData;
      });
    } else {
      setCheckedData((prev) => {
        const newData = prev.filter((v) => v !== value);
        handleUpdate({ updatedData: { selected: newData } });
        return newData;
      });
    }
  };

  const getDisplayName = (recruiter) => {
    if (recruiter.first_name && recruiter.last_name) {
      return `${recruiter.first_name} ${recruiter.last_name}`;
    } else if (recruiter.first_name) {
      return recruiter.first_name;
    } else {
      return recruiter.email;
    }
  };

  return (
    <View gap={lg} padding={lg} paddingBottom={xl + sm}>
      {loading && <ReshapedLoader size="small" animationSpeed={"0.7s"} />}
      {hasError && (
        <Text color="critical" variant="caption-1">
          {t(hasError)}
        </Text>
      )}
      {!loading && !hasError && data.length === 0 && (
        <Text variant="caption-1" align="center" color="neutral-faded">
          {t("No recruiters found")}
        </Text>
      )}
      {data.map((recruiter) => (
        <Checkbox
          key={recruiter.id}
          checked={checkedData.includes(recruiter.id)}
          name={getDisplayName(recruiter)}
          value={recruiter.id}
          onChange={handleChange}
          inputAttributes={{ "data-cooper": `follower filter ${getDisplayName(recruiter)}` }}
        >
          <Text variant="body-2">{getDisplayName(recruiter)}</Text>
        </Checkbox>
      ))}
    </View>
  );
};
