import { FILENAMEFROMURL, FILETYPEFROMFILENAME } from "utils/RegularExpressions";

export function getFileDetailsFromURL(URL = "") {
  if (FILENAMEFROMURL.test(URL) && FILENAMEFROMURL.exec(URL)) {
    const name = FILENAMEFROMURL.exec(URL)[0];
    const type = FILETYPEFROMFILENAME.exec(name)[1];
    return {
      name,
      type,
    };
  }
  return null;
}

export function getFileNameFromURL(URL = "") {
  if (FILENAMEFROMURL.test(URL) && FILENAMEFROMURL.exec(URL)) {
    const name = FILENAMEFROMURL.exec(URL)[0];
    return name;
  }
  return null;
}
