import { Button, Image, Text, View } from "reshaped";

import { useNavigate } from "react-router-dom";
import useSpacing from "components/hooks/useSpacing";
import useTranslation from "components/hooks/useTranslation";

export const NoBookings = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { sm, xl } = useSpacing();

  const handleRedirect = () => {
    navigate("/promote");
  };

  return (
    <View height="100%">
      <Text variant="title-2">{t("My Bookings")}</Text>
      <View.Item grow>
        <View gap={xl + sm} align="center" justify="end" height="100%">
          <View
            maxWidth="900px"
            attributes={{
              style: {
                paddingBlockStart: "var(--spacing-xl)",
              },
            }}
          >
            <Text color="neutral-faded" variant="title-2" align="center">
              {t("You haven’t made any bookings yet.")}
              <br />
              {t(`You can visit our Job Board Store by clicking the button below.`)}
            </Text>
          </View>
          <Button color="primary" size="large" onClick={handleRedirect}>
            {t("Explore Job Boards")}
          </Button>
          <Image src="images/NoBookings.svg" alt="No candidates" height="calc(100vh - 55vh)" />
        </View>
      </View.Item>
    </View>
  );
};
