// import React, { useEffect, useRef } from "react";
// import { View } from "reshaped";

// import common from "components/styles/reshaped/reusables/common.module.css";

// const CustomDropdown = ({
//   children,
//   MenuComponent,
//   MenuClassName = "",
//   dropdownClassName = "",
//   handleFocus,
//   handleFocusArgs,
//   handleUnfocus,
//   handleUnfocusArgs,
//   spectator,
// }) => {
//   const isInFocusRef = useRef(false);

//   useEffect(() => {
//     function clickHandler(e) {
//       const isDropdownButton = e?.target?.matches("[data-dropdown-button]");
//       console.log({ target: e.target });
//       const dropdownButtonClosestParent = e?.target?.closest("[data-dropdown]");

//       if (!isDropdownButton && dropdownButtonClosestParent != null) return;

//       if (isDropdownButton && dropdownButtonClosestParent instanceof HTMLDivElement) {
//         if (!dropdownButtonClosestParent.classList.contains(common.active)) {
//           isInFocusRef.current = true;
//           dropdownButtonClosestParent.classList.add(common.active);
//           if (handleFocus && typeof handleFocus === "function") {
//             if (handleFocusArgs && typeof handleFocusArgs === "object") {
//               handleFocus(handleFocusArgs);
//             } else {
//               handleFocus();
//             }
//           }
//         } else {
//           dropdownButtonClosestParent.classList.remove(common.active);
//         }
//       }

//       console.log({ dropdownButtonClosestParent });

//       if (
//         handleUnfocus &&
//         typeof handleUnfocus === "function" &&
//         !isDropdownButton &&
//         !dropdownButtonClosestParent &&
//         isInFocusRef.current
//       ) {
//         console.log("running123");
//         isInFocusRef.current = false;
//         if (handleUnfocusArgs && typeof handleUnfocusArgs === "object")
//           handleUnfocus(handleUnfocusArgs);
//         handleUnfocus();
//       }

//       document.querySelectorAll(`[data-dropdown].${common.active}`).forEach((dropdown) => {
//         if (dropdown === dropdownButtonClosestParent) {
//           return;
//         }
//         console.log("running456");

//         dropdown.classList.remove(common.active);
//       });
//     }
//     document.addEventListener("click", clickHandler);

//     return () => {
//       document.removeEventListener("click", clickHandler);
//     };

//     //eslint-disable-next-line
//   }, []);

//   return (
//     <div className={`${common.dropdown} ${dropdownClassName}`} data-dropdown>
//       <div className={common.dropdown__button__wrapper} data-dropdown-button>
//         <View attributes={{ style: { pointerEvents: "none" } }}>
//           {children ? children : "Dropdown"}
//         </View>
//       </div>
//       <div className={`${common.dropdown__menu} ${MenuClassName}`}>
//         {MenuComponent ? <MenuComponent /> : "Dropdown menu"}
//       </div>
//     </div>
//   );
// };

// export default CustomDropdown;

import { useLayoutEffect, useRef, useState } from "react";

import { Cross } from "icons";
import { cooperColors } from "utils/colors/accents";
import common from "components/styles/reshaped/reusables/common.module.css";

const CustomDropdown = ({
  children,
  MenuComponent,
  MenuClassName = "",
  dropdownClassName = "",
  focusHandler,
  focusArgs,
  unfocusHandler,
  unfocusArgs,
}) => {
  const [isFocus, setIsFocus] = useState(false);

  const dropdownRef = useRef();
  const initiatorRef = useRef();

  const handleUnfocus = ({ reset = false }) => {
    if (unfocusHandler && typeof unfocusHandler === "function") {
      if (unfocusArgs && typeof unfocusArgs === "object") {
        unfocusHandler({ reset, ...unfocusArgs });
      } else {
        unfocusHandler({ reset });
      }
    }
    setIsFocus(false);
  };

  const handleFocus = () => {
    if (focusHandler && typeof focusHandler === "function") {
      if (focusArgs && typeof focusArgs === "object") {
        focusHandler(focusArgs);
      } else {
        focusHandler();
      }
    }
    setIsFocus(true);
  };

  useLayoutEffect(() => {
    function handleClickOutside(event) {
      if (
        isFocus &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !initiatorRef.current.contains(event.target)
      ) {
        handleUnfocus({ reset: true });
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };

    // eslint-disable-next-line
  }, [isFocus]);

  return (
    <div className={`${common.dropdown} ${dropdownClassName}`}>
      <div onClick={handleFocus} ref={initiatorRef} className={common.dropdown__trigger__wrapper}>
        {children ? children : "Dropdown"}
      </div>
      {isFocus && (
        <div ref={dropdownRef} className={`${common.dropdown__menu} ${MenuClassName}`}>
          <button
            className={`${common.dropdown__close__btn}`}
            onClick={() => handleUnfocus({ reset: true })}
          >
            <Cross fill={cooperColors["foreground-neutral"]} />
          </button>
          {MenuComponent ? <MenuComponent closeDropdown={handleUnfocus} /> : "Dropdown menu"}
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
