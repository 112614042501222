import { useMemo, useState } from "react";
import { Avatar, Button, Image, Text, useToast, View } from "reshaped";

import { Bin } from "icons";
import { cartSlice } from "redux/cart";
import { backendAPI } from "utils/axios";
import { accent } from "utils/colors/accents";
import useName from "components/hooks/useName";
import useSpacing from "components/hooks/useSpacing";
import { areKeysAvailableIn } from "utils/miniHelpers";
import { useDispatch, useSelector } from "react-redux";
import useCurrency from "components/hooks/useCurrency";
import { PrimaryShadedText } from "components/reusables";
import { reloadOnUnauthorized } from "utils/checkForAuth";
import { ShowToastWithTranslation } from "utils/showToast";
import useTranslation from "components/hooks/useTranslation";
import { ReshapedLoader, TextWithEclipses } from "components/reusables";
import { OperationFailedWithTranslation } from "components/reusables/OperationFailed";

export const CartItem = ({ slot }) => {
  const { id, name, original_price, tag, discount, discounted_price, quantity } = slot;

  const [loading, setLoading] = useState(false);

  const toast = useToast();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { xs, sm, lg, xl } = useSpacing();
  const { currencySymbol } = useCurrency();
  const { slots } = useSelector((state) => state.cart);
  const jobBoardProfile = useName({ info: slot, first_name: slot.job_board_name });

  //eslint-disable-next-line
  const priceDetails = useMemo(() => {
    let discountInfo = false;
    let tagInfo = false;
    if (typeof discount === "number" && discount >= 0) {
      discountInfo = {
        priceWithDiscount: discounted_price,
        discountPercentage: discount,
        color: accent["2-600"],
      };
    }

    if (tag) {
      const tagBgColors = {
        recommended: accent["4-600"],
        RECOMMENDED: accent["4-600"],
        "value plan": "var(--rs-color-background-primary-highlighted)",
        "VALUE PLAN": "var(--rs-color-background-primary-highlighted)",
      };
      tagInfo = {
        text: tag.toUpperCase(),
        background: tagBgColors[tag],
      };
    }

    return {
      originalPrice: original_price,
      hasDiscount: discountInfo,
      hasTag: tagInfo,
    };

    //eslint-disable-next-line
  }, [name]);

  const deleteSlotFromCart = async ({ cart, quantity }) => {
    try {
      const response = await backendAPI.post("/companies/cart", { cart });
      if (
        response.data &&
        areKeysAvailableIn({ object: response.data, keys: ["cart"] }) &&
        areKeysAvailableIn({
          object: response.data.cart,
          keys: ["discount", "discounted_price", "original_price"],
        })
      ) {
        const slotPhrase = quantity === 1 ? "Slot" : "Slots";
        const { original_price, discount, discounted_price } = response.data.cart;
        return {
          message: `${slotPhrase} deleted!`,
          total: {
            original_price,
            discount,
            discounted_price,
          },
        };
      }
      return {
        error: "Operation unsuccessful!",
      };
    } catch (error) {
      reloadOnUnauthorized(error);
      return {
        error: "Operation unsuccessful!",
      };
    }
  };

  const handleRemoveSlotFromCart = async () => {
    setLoading(true);
    if (!Array.isArray(slots)) return;

    const details = [];

    slots.forEach((s) => {
      if (s.job_board_slot.id === id) return;
      details.push({
        job_board_slot_id: s.job_board_slot.id,
        quantity: s.job_board_slot.quantity,
      });
    });

    const cart = { details, status: "saved" };
    const result = await deleteSlotFromCart({ cart, quantity });
    setLoading(false);

    if (result.message && result.total) {
      dispatch(cartSlice.actions.removeSlot({ id, total: result.total }));
      ShowToastWithTranslation({
        toast,
        Children: SlotDeleted,
        text: t(result.message),
      });
    }

    if (result.error) {
      ShowToastWithTranslation({
        toast,
        Children: OperationFailedWithTranslation,
        text: t(result.error),
      });
    }
  };

  return (
    <View direction="row" align="center" gap={lg}>
      <View.Item grow>
        <View
          direction="row"
          align="stretch"
          borderRadius="medium"
          shadow="base"
          attributes={{
            style: {
              overflow: "hidden",
              backgroundColor: "var(--rs-color-background-neutral-highlighted)",
            },
          }}
        >
          <View.Item columns={8}>
            <View
              padding={[lg, lg + xs]}
              gap={lg}
              attributes={{
                style: {
                  backgroundColor: "var(--rs-color-background-neutral-highlighted)",
                },
              }}
            >
              <View direction="row" gap={xl} align="center">
                {jobBoardProfile.hasURL && (
                  <Image
                    width="40px"
                    height="40px"
                    src={jobBoardProfile.url}
                    alt={slot.job_board_name}
                  />
                )}
                {!jobBoardProfile.hasURL && (
                  <Avatar
                    squared
                    size={10}
                    initials={jobBoardProfile.letters}
                    alt={slot.job_board_name}
                  />
                )}
                <View gap={xs}>
                  <TextWithEclipses
                    variant="body-strong-1"
                    text={name}
                    characterLimit={12}
                    noOfEclipses={2}
                  />
                  <TextWithEclipses
                    variant="body-2"
                    color="neutral-faded"
                    text={`${slot.job_board_name}`}
                    characterLimit={18}
                    noOfEclipses={2}
                  />
                </View>
              </View>
              <View direction="row" align="baseline" gap={sm}>
                <Text variant="caption-2">{t("Price")}</Text>
                <Text variant="body-strong-1">
                  {currencySymbol}
                  {discounted_price}
                </Text>
              </View>
            </View>
          </View.Item>
          <View.Item columns={4}>
            <View
              backgroundColor="primary-faded"
              justify="center"
              align="center"
              padding={sm}
              width="100%"
              height="100%"
              gap={lg}
            >
              {loading && (
                <View>
                  <ReshapedLoader size="medium" />
                </View>
              )}
              {!loading && (
                <>
                  <View
                    width="40px"
                    height="40px"
                    align="center"
                    justify="center"
                    attributes={{
                      style: {
                        backgroundColor: "var(--rs-color-border-primary-faded)",
                        borderRadius: "50%",
                      },
                    }}
                  >
                    <PrimaryShadedText variant="title-3">{quantity}</PrimaryShadedText>
                  </View>
                  <Text variant="body-strong-1">
                    {currencySymbol}
                    {discounted_price * quantity}
                  </Text>
                </>
              )}
            </View>
          </View.Item>
        </View>
      </View.Item>
      <View height="100%">
        <Button
          startIcon={Bin}
          disabled={loading}
          variant="ghost"
          onClick={handleRemoveSlotFromCart}
        />
      </View>
    </View>
  );
};

const SlotDeleted = ({ children }) => {
  return (
    <Text color="positive" variant="body-strong-1">
      {children}
    </Text>
  );
};