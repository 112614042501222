import React, { useMemo } from "react";
import { Button, DropdownMenu, FormControl, View } from "reshaped";

import { ArrowDown } from "icons";
import { useSelector } from "react-redux";
import useTranslation from "components/hooks/useTranslation";
import { stepOneProgressHelper } from "utils/jobStepProgress";
import styles from "components/styles/reshaped/jobs/new.module.css";

const EducationDropdown = (props) => {
  const { setStepOneProgress, propTitle, education, setEducation } = props;

  const { t } = useTranslation();
  const auth = useSelector((state) => state.auth);

  const educationOptions = useMemo(() => {
    return [
      { value: "High school or equivalent required" },
      { value: "Vocational training required" },
      { value: "Undergraduate student" },
      { value: "Bachelor degree required" },
      { value: "Graduate student" },
      { value: "Master degree required" },
      { value: "PhD required" },
    ];

    //eslint-disable-next-line
  }, []);

  const translations = useMemo(() => {
    const educationTranslations = educationOptions.reduce(
      (acc, curr) => ({ ...acc, [curr.value]: t(curr.value) }),
      {}
    );
    return {
      Education: t("Education"),
      "Level of education": t("Level of education"),
      ...educationTranslations,
    };

    //eslint-disable-next-line
  }, [[auth && auth?.user ? [auth.user] : []]]);

  const handleEducation = ({ selectedOption }) => {
    const { value } = selectedOption;
    setEducation(value);
    stepOneProgressHelper(propTitle, setStepOneProgress, "add");
  };

  return (
    <View.Item grow={true} className={styles.dropdown__wrapper}>
      <FormControl>
        <FormControl.Label>{translations["Level of education"]}</FormControl.Label>
        <DropdownMenu position="bottom-start">
          <DropdownMenu.Trigger>
            {(attributes) => (
              <Button
                className={styles.dropdown__button}
                variant="outline"
                fullWidth
                endIcon={ArrowDown}
                attributes={{ ...attributes, "data-cooper": "jobs education level" }}
              >
                {education ? translations[education] : translations["Level of education"]}
              </Button>
            )}
          </DropdownMenu.Trigger>
          <DropdownMenu.Content>
            {educationOptions.map((option, index) => {
              return (
                <DropdownMenu.Item
                  onClick={() => handleEducation({ selectedOption: option })}
                  key={index}
                  attributes={{ "data-cooper": `education ${option.value}` }}
                >
                  {translations[option.value]}
                </DropdownMenu.Item>
              );
            })}
          </DropdownMenu.Content>
        </DropdownMenu>
      </FormControl>
    </View.Item>
  );
};

export default React.memo(EducationDropdown);
