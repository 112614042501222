import { Button, Icon, View } from "reshaped";

import { NoNotesMessage, Plus } from "icons";
import useSpacing from "components/hooks/useSpacing";
import useTranslation from "components/hooks/useTranslation";

const NoNotes = (props) => {
  const { setSelectedCategory, selectCategory, setAddNote } = props;

  const { t } = useTranslation();
  const { xs, xxl } = useSpacing();

  const handleAddNotes = () => {
    if (selectCategory !== "notes") setSelectedCategory("notes");
    setAddNote(true);
  };

  return (
    <View
      direction="row"
      align="center"
      justify="center"
      height="100%"
      attributes={{
        style: {
          height: "calc(100% - 24px)",
        },
      }}
    >
      <View padding={xs} align="center" height="100%" justify="center">
        <Icon svg={NoNotesMessage} size={xxl * 3} />
        <Button color="primary" size="small" onClick={handleAddNotes} startIcon={Plus}>
          {t("Add your Notes")}
        </Button>
      </View>
    </View>
  );
};

export default NoNotes;
