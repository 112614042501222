import { useMemo } from "react";
import { Avatar, Button, DropdownMenu, View, Text, useToggle, useToast } from "reshaped";

import { ArrowDown } from "icons";
import { backendAPI } from "utils/axios";
import { useDispatch } from "react-redux";
import useName from "components/hooks/useName";
import { invitationSlice } from "redux/invitation";
import { cooperColors } from "utils/colors/accents";
import useSpacing from "components/hooks/useSpacing";
import useFullName from "components/hooks/useFullName";
import { reloadOnUnauthorized } from "utils/checkForAuth";
import { ShowToastWithTranslation } from "utils/showToast";
import useTranslation from "components/hooks/useTranslation";
import ConfirmationModal from "components/reusables/modals/ConfirmationModal";
import { OperationFailedWithTranslation } from "components/reusables/OperationFailed";
import { fetchSubscriptionData } from "redux/subscriptions/subscriptionsSlice";

export const AcceptedInvite = (props) => {
  const { user, currentUser } = props;
  const { first_name, last_name, email, id, role } = user;

  const toast = useToast();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { sm, lg } = useSpacing();
  const { active, activate, deactivate } = useToggle(false);
  const profile = useName({ first_name, last_name, info: user });
  const fullName = useFullName({ first_name, last_name, dependency: [user] });
  const { startLoading, stopLoading, deleteMember, setError } = invitationSlice.actions;

  const optimizedOptions = useMemo(() => {
    const options = [
      { title: "Assign as admin", value: "admin" },
      { title: "Assign as member", value: "member" },
      { title: "Remove", value: "remove" },
    ];
    return options;

    //eslint-disable-next-line
  }, []);

  const roles = useMemo(() => {
    return {
      member: "Member",
      admin: "Admin",
    };

    //eslint-disable-next-line
  }, []);

  const deleteTeamMember = async (id) => {
    try {
      dispatch(startLoading());
      const response = await backendAPI.delete(`teams/${id}`);
      dispatch(stopLoading());
      if (response.status === 200 && response.data.message) {
        dispatch(deleteMember(id));
        ShowToastWithTranslation({
          toast,
          Children: MemberDeleted,
          text: t("Member Deleted!"),
        });
      }
      if (response.status === 404) {
        ShowToastWithTranslation({
          toast,
          Children: OperationFailedWithTranslation,
          text: t("Operation unsuccessful!"),
        });
        dispatch(setError("Unable to delete member, "));
      }
    } catch (error) {
      reloadOnUnauthorized(error);
      dispatch(stopLoading());
      ShowToastWithTranslation({
        toast,
        Children: OperationFailedWithTranslation,
        text: t("Operation unsuccessful!"),
      });
      dispatch(setError("Unable to delete member, "));
    }
  };

  const handleDropdown = async ({ selectedOption }) => {
    if (selectedOption.value === role) return;

    if (selectedOption.value === "admin") {
      console.log("Admin invoked");
    }

    if (selectedOption.value === "member") {
      console.log("member invoked");
    }

    if (selectedOption.value === "remove") {
      if (currentUser?.id === user?.id) {
        ShowToastWithTranslation({
          toast,
          Children: OperationFailedWithTranslation,
          text: t("Please ask your admins to remove you from team!"),
        });
        return;
      }
      activate();
    }
  };

  const handleRemoveUser = (action) => {
    if (action === "accept") {
      deleteTeamMember(id);
      dispatch(fetchSubscriptionData());
    }
    deactivate();
  };

  return (
    <View.Item columns={6}>
      <View borderRadius="medium" borderColor="neutral-faded" padding={lg} direction="row" gap={sm}>
        <View direction="row" align="center" gap={sm}>
          <View
            borderColor="neutral-faded"
            attributes={{
              style: {
                borderRadius: "50%",
              },
            }}
          >
            {profile.hasURL && <Avatar size={10} src={profile.url} alt={fullName} />}
            {!profile.hasURL && <Avatar size={10} initials={profile.letters} alt={fullName} />}
          </View>
          <View>
            <Text variant="body-strong-2">{fullName}</Text>
            <Text color="neutral" variant="body-2">
              {email}
            </Text>
          </View>
        </View>
        <View.Item grow>
          <View direction="row" justify="end" height="100%">
            <DropdownMenu position="bottom-start">
              <DropdownMenu.Trigger>
                {(attributes) => (
                  <Button
                    variant="outline"
                    color="neutral"
                    size="small"
                    attributes={attributes}
                    disabled={currentUser && currentUser?.role === "member"}
                  >
                    <View direction="row" align="center" justify="center" gap={sm}>
                      <Text variant="caption-1" color="neutral-faded">
                        {t(roles[role])}
                      </Text>
                      <ArrowDown fill={cooperColors["foreground-neutral-faded"]} />
                    </View>
                  </Button>
                )}
              </DropdownMenu.Trigger>
              <DropdownMenu.Content>
                {optimizedOptions.map((option, index) => {
                  return (
                    <DropdownMenu.Item
                      onClick={() => handleDropdown({ selectedOption: option })}
                      key={index}
                      disabled={
                        //disable options for member role
                        (currentUser && currentUser?.role === "member") ||
                        //disable options for changing roles options
                        ["admin", "member"].some((v) => v === option.value)
                        //disable 'remove' option if current and accepted invite is same
                        // (option.value === "remove" && currentUser?.id === user?.id)
                      }
                    >
                      {t(option.title)}
                    </DropdownMenu.Item>
                  );
                })}
              </DropdownMenu.Content>
            </DropdownMenu>
          </View>
        </View.Item>
      </View>
      <ConfirmationModal
        activate={activate}
        active={active}
        deactivate={deactivate}
        acceptText={"Remove"}
        title={t("Remove user?")}
        subTitle={
          <Text>
            {t("Are you sure you want to remove")}
            <strong>{` ${fullName}`}</strong> ({role})?
          </Text>
        }
        handleAction={handleRemoveUser}
      />
    </View.Item>
  );
};

export const MemberDeleted = ({ children }) => {
  return (
    <Text color="positive" variant="body-strong-1">
      {children}
    </Text>
  );
};
