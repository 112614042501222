import { backendAPI } from "utils/axios";
import { SUPPORT_ERROR } from "utils/constants";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { updateEmailInAPI, updateUserInAPI } from "redux/auth/authSlice";

const initialState = {
  locationHistory: "",
  isLoading: false,
  isModalOpen: false,
  isCandidateModalOpen: false,
  isNewCandidateModalOpen: {},
  registrationError: null,
  addCandidateShortcut: false,
  toProfileShortcut: false,
  errors: null,
  APIError: null,
  registrationStage: "user",
  isAlertClosed: false,
  showSubscriptionAlert: false,
  alertMessage: "",
  paymentFailed: false,
  userLead: {
    user: null,
    company: null,
  },
};

export const setUserLeadInBackend = createAsyncThunk("meta/userLead", async (dataLead) => {
  const data = JSON.parse(JSON.stringify(dataLead));
  if (data.user.password) delete data.user.password;
  try {
    const { nextStage = "user", ...rest } = data;
    const requestBody = {
      ...rest,
    };
    const response = await backendAPI.post("/users/create_lead", { user_lead: requestBody });
    if (response && response.data && response.data.status === 200) {
      const { data } = response;
      if (data.admin && typeof data.admin === "string") {
        return {
          error: data.admin,
          status: 403,
          isExist: true,
        };
      }
      if (data.message === "Email already exists.") {
        return {
          error: "Email already exists.",
          status: 403,
        };
      }
      if (!data?.admin && data.message === "Lead added successfully.") {
        return {
          ...response.data,
          nextStage,
        };
      }
      return {
        error: SUPPORT_ERROR,
        status: 403,
      };
    }
    return {
      error: SUPPORT_ERROR,
      status: 403,
    };
  } catch (err) {
    return {
      status: err.status,
    };
  }
});

export const metaSlice = createSlice({
  name: "meta",
  initialState,
  reducers: {
    reset: (state) => {
      for (let key in initialState) {
        state[key] = initialState[key];
      }
    },
    initialLoading: (state) => {
      state.isLoading = true;
    },
    stopLoading: (state) => {
      state.isLoading = false;
    },
    setLocationHistory: (state, action) => {
      state.locationHistory = action.payload;
    },
    setErrors: (state, action) => {
      state.errors = action.payload;
    },
    clearErrors: (state) => {
      state.errors = null;
    },
    clearRegistrationError: (state) => {
      state.registrationError = null;
    },
    clearAPIError: (state) => {
      state.APIError = null;
    },
    setRegistrationStage: (state, action) => {
      state.registrationStage = action.payload;
    },
    setAddCandidateShortcut: (state, action) => {
      const { payload = false } = action;
      state.addCandidateShortcut = payload;
    },
    setToProfileShortcut: (state, action) => {
      const { payload = false } = action;
      state.toProfileShortcut = payload;
    },
    setCandidateModalOpen: (state, action) => {
      const { payload = true } = action;
      state.isCandidateModalOpen = payload;
    },
    setNewCandidateModalOpen: (state, action) => {
      const { payload = false } = action;
      state.isNewCandidateModalOpen = payload;
    },
    // user backup if registration failed
    setUserLead: (state, action) => {
      const { user, company } = action.payload;
      if (user) {
        const { first_name, last_name, email } = user;

        // setting user same as state in registerUser
        const sanitizedUser = {
          firstName: first_name,
          lastName: last_name,
          email,
        };
        state.userLead.user = sanitizedUser;
      }
      if (company) {
        const { name, website, phone_no, country, industry_type } = company;

        // setting company same as state in registerCompany and registerCompanySize
        const sanitizedCompany = {
          name,
          website,
          number: phone_no,
          country,
          category: industry_type,
        };
        state.userLead.company = sanitizedCompany;
      }
    },

    removeUserLead: (state) => {
      state.userLead = { user: null, company: null };
    },
    showAlert: (state, action) => {
      if (state.isAlertClosed) {
        return;
      }
      state.alertMessage = action.payload;
      state.showSubscriptionAlert = true;
    },
    hideAlert: (state) => {
      state.isAlertClosed = true;
    },
    setAlertMessage: (state, action) => {
      state.alertMessage = action.payload;
    },
    setPaymentFailed: (state, action) => {
      state.paymentFailed = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(setUserLeadInBackend.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(setUserLeadInBackend.fulfilled, (state, action) => {
      const payload = action.payload;
      if (payload.nextStage !== "confirm") state.isLoading = false;
      if (payload.status === 200) {
        if (payload.nextStage !== "confirm") state.registrationStage = payload.nextStage;
      }
      if (payload.status === 400) {
        state.APIError = payload?.message;
      }
      if (payload.status === 403) {
        if (payload?.isExist) {
          state.registrationError = {
            isExist: true,
            message: payload?.error,
          };
        } else {
          state.registrationError = {
            isExist: false,
            message: payload?.error,
          };
        }
      }
    });
    builder.addCase(setUserLeadInBackend.rejected, (state) => {
      state.isLoading = false;
      state.APIError = SUPPORT_ERROR;
    });

    builder.addCase(updateUserInAPI.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateUserInAPI.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateUserInAPI.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(updateEmailInAPI.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateEmailInAPI.fulfilled, (state, action) => {
      state.isLoading = false;
      state.errors = {
        email: action.payload,
      };
    });
    builder.addCase(updateEmailInAPI.rejected, (state) => {
      state.isLoading = false;
    });
  },
});
