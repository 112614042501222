import { useState, useCallback } from "react";
import { FormControl, TextField, Button, View } from "reshaped";

import { isString } from "utils/miniHelpers";
import { setInitial } from "utils/setInitial";
import { useDispatch, useSelector } from "react-redux";
import { VALIDURLREGEX } from "utils/RegularExpressions";
import { setInitialErrors } from "utils/setInitialErrors";
import { setUserLeadInBackend } from "redux/meta/metaSlice";
import useTranslation from "components/hooks/useTranslation";
import { getBrowserLanguage } from "utils/getBrowserLanguage";
import Titles from "components/reusables/loginAndRegister/Titles";
import { sanitizeWord, validateErrors } from "utils/validateError";
import usePublicCountries from "components/hooks/usePublicCountries";
import RegisterAutoCompleteCountries from "components/reusables/loginAndRegister/RegisterAutoCompleteCountries";

export const RegisterCompany = (props) => {
  const { title, subTitle, setCompanyDetails, userDetails } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { getCountryName, getCountryCode } = usePublicCountries();
  const { userLead, errors, isLoading } = useSelector((state) => state.meta);

  const [name, setName] = useState(setInitial("name", userLead?.company));
  const [website, setWebsite] = useState(setInitial("website", userLead?.company));
  const [number, setNumber] = useState(setInitial("number", userLead?.company));
  const [country, setCountry] = useState(
    getCountryName({
      country: isString(userLead?.company?.country_code)
        ? userLead?.company?.country_code
        : userLead?.company?.country,
      locale: getBrowserLanguage(),
      defaultValue: "",
    })
  );
  const [error, setError] = useState(
    setInitialErrors(["name", "website", "number", "country"], errors)
  );

  const validate = useCallback((errorName, name, handleError, regex) => {
    validateErrors(errorName, name, handleError, t, regex);

    //eslint-disable-next-line
  }, []);

  const handleLimitedCharactersValidation = ({ state, errorKey, labelName }) => {
    let charactersExceeded = false;
    if (state && typeof state === "string" && state.length > 240) {
      charactersExceeded = true;
      setError((prev) => {
        return {
          ...prev,
          [errorKey]: t(`${labelName} should have maximum 240 characters`),
        };
      });
    }
    return charactersExceeded;
  };

  const handleName = ({ value }) => {
    if (error.name) {
      setError((previousError) => {
        return {
          ...previousError,
          name: null,
        };
      });
    }
    setName(value);
  };

  const handleWebsite = ({ value }) => {
    if (error.website) {
      setError((previousError) => {
        return {
          ...previousError,
          website: null,
        };
      });
    }
    setWebsite(value);
  };

  const handleNumber = ({ value }) => {
    if (error.number) {
      setError((previousError) => {
        return {
          ...previousError,
          number: null,
        };
      });
    }
    setNumber(value);
  };

  const handleNameValidation = () => {
    const hasError = handleLimitedCharactersValidation({
      state: name,
      errorKey: "name",
      labelName: "Company name",
    });
    if (hasError) return;
    validate("name", name, setError);
  };

  const handleWebsiteValidation = () => {
    const hasError = handleLimitedCharactersValidation({
      state: website,
      errorKey: "website",
      labelName: "Company website",
    });
    if (hasError) return;
    validate("website", website, setError, VALIDURLREGEX);
  };

  const handleNumberValidation = () => {
    validate(
      "number",
      number,
      setError,
      //eslint-disable-next-line
      /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let hasError = false;

    // if user submitting with empty or invalid fields
    for (let field in error) {
      if (error[field]) {
        hasError = true;
        if (typeof error[field] === "object") {
          setError((previousError) => {
            return {
              ...previousError,
              [field]: t(`${sanitizeWord(field)} is required`),
            };
          });
        }
      }
    }

    const countryInEnglish = getCountryName({
      country,
      defaultValue: "",
    });

    if (!isString(countryInEnglish)) {
      hasError = true;
    }

    const countryCode = getCountryCode({ country: countryInEnglish });

    if (!isString(countryCode)) {
      hasError = true;
    }

    if (hasError) return;

    // all sanitized inputs with spaces removed at the ends
    const companyFormData = {
      name: name.trim(),
      website: website.trim(),
      phone_no: number.trim(),
      country: countryInEnglish,
      country_code: countryCode,
    };

    setCompanyDetails(companyFormData);
    dispatch(
      setUserLeadInBackend({
        user: userDetails,
        company: companyFormData,
        step_completed: "second",
        nextStage: "size",
      })
    );
  };

  return (
    <View height="100%" width="100%" align="center" padding={[0, 6]} justify="center">
      <View as="form" gap={4} width="100%" maxWidth="50rem">
        <Titles title={title} subTitle={subTitle} />
        <FormControl required hasError={error["name"] && typeof error["name"] !== "object"}>
          <FormControl.Label>{t("Company name")}</FormControl.Label>
          <TextField
            value={name}
            onChange={handleName}
            inputAttributes={{
              type: "text",
              onBlur: handleNameValidation,
              autoFocus: true,
              "data-cooper": "register_companyname",
            }}
          />
          <FormControl.Error>{error["name"]}</FormControl.Error>
        </FormControl>
        <FormControl required hasError={error["website"] && typeof error["website"] !== "object"}>
          <FormControl.Label>{t("Company website")}</FormControl.Label>
          <TextField
            value={website}
            onChange={handleWebsite}
            inputAttributes={{
              type: "text",
              onBlur: handleWebsiteValidation,
              "data-cooper": "register_companywebsite",
            }}
          />
          <FormControl.Error>{error["website"]}</FormControl.Error>
        </FormControl>
        <FormControl required hasError={error["number"] && typeof error["number"] !== "object"}>
          <FormControl.Label>{t("Phone number")}</FormControl.Label>
          <TextField
            value={number}
            onChange={handleNumber}
            inputAttributes={{
              type: "text",
              onBlur: handleNumberValidation,
              "data-cooper": "register_companyphone",
            }}
          />
          <FormControl.Error>{error["number"]}</FormControl.Error>
        </FormControl>
        <RegisterAutoCompleteCountries
          country={country}
          setCountry={setCountry}
          error={error}
          setError={setError}
        />
        <Button
          onClick={handleSubmit}
          loading={isLoading}
          fullWidth
          attributes={{ type: "submit", "data-cooper": "register_companysubmit" }}
          color="primary"
        >
          {t("Create company profile")}
        </Button>
      </View>
    </View>
  );
};
