import React, { useRef } from "react";

import { Filter } from "icons";
import { Icon, Text, View } from "reshaped";
import { jobsFiltersSlice } from "redux/filters";
import { jobSlice } from "redux/job";
import { cooperColors } from "utils/colors/accents";
import useSpacing from "components/hooks/useSpacing";
import { useDispatch, useSelector } from "react-redux";
import useTranslation from "components/hooks/useTranslation";
import { fetchFilteredJobs } from "utils/pages/job";
import styles from "components/styles/reshaped/candidates.module.css";
import { FilterMenu, FilterChips } from "components/pages/jobs";
import CustomDropdown from "components/reusables/dropdowns/CustomDropdown";
import useUserLanguagePreference from "components/hooks/useUserLanguagePreference";
import { areKeysAvailableWithType, sanitizeArray } from "utils/miniHelpers";

export const JobFilters = React.memo(() => {
  const statusRef = useRef({});
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { xs, sm, md } = useSpacing();
  const state = useSelector((state) => state);
  const userLang = useUserLanguagePreference();

  const { user } = state.auth;

  const handleUnfocus = async ({ reset = false }) => {
    const finalStatusData = {};

    if (!reset) {
      let selectedStatuses = [];
      if (areKeysAvailableWithType({ object: statusRef.current, keys: [{ selected: "array" }] })) {
        selectedStatuses = sanitizeArray({ array: statusRef.current.selected, elementType: "string" });
        finalStatusData["selected"] = selectedStatuses;
      }
      dispatch(
        jobSlice.actions.set({
          isFiltering: true,
          filteringError: null,
        })
      );

      const response = await fetchFilteredJobs({
        company_id: user.company_id,
        selectedStatuses: selectedStatuses,
        current_language: userLang,
      });

      if (areKeysAvailableWithType({ object: response, keys: [{ data: "object" }] })) {
        const { jobs } = response.data;
        dispatch(
          jobSlice.actions.set({
            isFiltering: false,
            jobs
          })
        );
      }
      if (areKeysAvailableWithType({ object: response, keys: [{ hasError: "boolean" }] })) {
        dispatch(
          jobSlice.actions.set({
            isFiltering: false,
            filteringError: "something went wrong!",
          })
        );
      }
      dispatch(
        jobsFiltersSlice.actions.setPageFilter({
          page: "jobs",
          types: { name: "statuses", value: finalStatusData },
        })
      );
    }

    if (reset) {

      dispatch(
        jobsFiltersSlice.actions.setPageFilter({
          page: "jobs",
          types: { name: "statuses", value: finalStatusData },
        })
      );
    }

    statusRef.current = {};
  };

  return (
    <View gap={md}>
      <View direction="row" attributes={{ style: { zIndex: 9 } }}>
        <CustomDropdown
          unfocusHandler={handleUnfocus}
          MenuComponent={({ closeDropdown }) => (
            <FilterMenu
              {...{
                statusRef,
                closeDropdown,
              }}
            />
          )}
          MenuClassName={styles.dropdown__menu}
        >
          <View
            gap={xs}
            padding={[sm, md]}
            backgroundColor="white"
            direction="row"
            align="center"
            borderRadius="small"
            attributes={{
              style: {
                border: "1px solid var(--rs-color-border-neutral-faded)",
              },
              "data-cooper": "jobs filter by",
            }}
          >
            <Icon
              size={md}
              svg={() => <Filter fill={cooperColors["foreground-neutral-faded"]} />}
            />
            <Text variant="body-2">{t("Filter by")}</Text>
          </View>
        </CustomDropdown>
      </View>
      <FilterChips />
    </View>
  );
});
