import { Check } from "icons/Check";
import { useState, useEffect, useCallback } from "react";
import { Tooltip } from "components/library";
import { getFullName } from "utils/nameHelpers";
import useSpacing from "components/hooks/useSpacing";
import { useDispatch, useSelector } from "react-redux";
import { ShowToastWithTranslation } from "utils/showToast";
import useTranslation from "components/hooks/useTranslation";
import { getInitialLettersFromName } from "utils/getLettersFromName";
import { OperationFailedWithTranslation } from "components/reusables";
import styles from "components/styles/reshaped/candidates.module.css";
import { SuccessfulToast } from "components/reusables/tab/SuccessfulToast";
import { View, Avatar, Text, Button, useToggle, useToast } from "reshaped";
import AssignFollowersModal from "components/pages/candidates/AssignFollowersModal";
import { followCandidate, unfollowCandidate } from "redux/viewingCandidate/viewingCandidateSlice";

export const FollowersSection = (props) => {
  const { candidate } = props;
  const { user } = useSelector((state) => state.auth);

  const toast = useToast();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { sm, xs, md } = useSpacing();
  const { activate, deactivate, active } = useToggle(false);
  const { followers, unassignedRecruiters, followingLoading } = useSelector((state) => state.viewingCandidate);

  const [isFollowing, setIsFollowing] = useState(false);

  useEffect(() => {
    const isFollowing = followers.some(follower => follower.recruiter.id === user.id);
    setIsFollowing(isFollowing);
  }, [followers, user.id]);

  const getFollowingText = useCallback((followersCount) => {
    switch (true) {
      case followersCount === 1:
        return `${followersCount} ${t("Follower")}`;
      case followersCount <= 4 && followersCount > 1:
        return `${followersCount} ${t("Followers")}`;
      case followersCount > 4:
        return `+ ${followersCount - 4} ${t("more followers")}`;
      default:
        return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFollowButton = async () => {
    try {
      if (isFollowing) {
        if (unassignedRecruiters.length === 0) {
          await dispatch(unfollowCandidate({ recruiterId: user.id, candidateId: candidate.id, recruiter: user })).unwrap();
        } else {
          await dispatch(unfollowCandidate({ recruiterId: user.id, candidateId: candidate.id })).unwrap();
        }
        setIsFollowing(false);
        ShowToastWithTranslation({
          toast,
          Children: SuccessfulToast,
          text: t("Unfollowed successfully!"),
        });
      } else {
        if (unassignedRecruiters.length === 0) {
          await dispatch(followCandidate({ recruiterId: user.id, candidateId: candidate.id, recruiter: user })).unwrap();
        } else {
          await dispatch(followCandidate({ recruiterId: user.id, candidateId: candidate.id })).unwrap();
        }
        setIsFollowing(true);
        ShowToastWithTranslation({
          toast,
          Children: SuccessfulToast,
          text: t("Followed successfully!"),
        });
      }
    } catch (followingError) {
      ShowToastWithTranslation({
        toast,
        Children: OperationFailedWithTranslation,
        text: t("Something went wrong!"),
      });
    }
  };

  const handleModalClose = () => {
    deactivate();
  };

  return (
    <View>
      <View direction="row" align={"center"} attributes={{ style: { justifyContent: "space-between", flexWrap: "nowrap" } }}>
        <View direction="row" gap={md} align="center" attributes={{ style: { flexWrap: "nowrap" } }}>
          <Button
            onClick={handleFollowButton}
            color={isFollowing ? "neutral" : "primary"}
            variant={isFollowing ? "outline" : "solid"}
            startIcon={isFollowing ? Check : null}
            loading={followingLoading[user.id]}
          >
            {isFollowing ? t("Following") : t("Follow")}
          </Button>
          <View direction={"row"} gap={xs} align={"center"} justify={"start"} attributes={{
            style: {
              cursor: "pointer",
              maxWidth: "70%",
              flexWrap: "nowrap"
            },
            onClick: activate
          }}>
            <View direction={"row"} className={styles.followers_avatars} attributes={{
              style: {
                flexWrap: "nowrap",
                maxWidth: "160px"
              }
            }}>
              {followers.length ? followers.slice(0, 4).map((follower) => {
                const fullName = getFullName(follower?.recruiter);
                const letters = getInitialLettersFromName(fullName);
                return <View key={follower.recruiter.id} className={styles.followers_avatar}>
                  {follower.recruiter.logo?.url && (
                    <Tooltip position="top" text={fullName}>
                      {({ className }) => (
                        <Avatar className={`${className}`} size={sm * 3} src={follower.recruiter.logo?.url} alt={fullName} />
                      )}
                    </Tooltip>
                  )}
                  {!follower.recruiter.logo?.url && (
                    <Tooltip position="top" text={fullName}>
                      {({ className }) => (
                        <Avatar className={`${className}`} size={sm * 3} initials={letters} alt={fullName} />
                      )}
                    </Tooltip>
                  )}
                </View>
              }) : <Text color="neutral-faded" variant="caption-1">{t("0 Following (follow to keep track of this candidate)")} </Text>}
            </View>
            <View>
              {followers.length > 0 && (
                <Text color="neutral-faded" variant="caption-1">
                  {getFollowingText(followers?.length)}
                </Text>
              )}
            </View>
          </View>
        </View>
        <View>
          <Text
            variant="body-strong-2"
            color="primary"
            attributes={{
              style: {
                cursor: "pointer"
              },
              onClick: activate
            }}
          >
            {t("+Assign")}
          </Text>
          <AssignFollowersModal active={active} handleModalClose={handleModalClose} deactivate={deactivate} candidateId={candidate.id} />
        </View>
      </View>
    </View>
  );
};
