import { useEffect } from "react";
import { Button, Text, View } from "reshaped";

import { backendAPI } from "utils/axios";
import { dashboardSlice } from "redux/dashboard";
import useSpacing from "components/hooks/useSpacing";
import { useDispatch, useSelector } from "react-redux";
import { reloadOnUnauthorized } from "utils/checkForAuth";
import useTranslation from "components/hooks/useTranslation";
import { WrapperWithNav, ReshapedLoader } from "components/reusables";
import { areKeysAvailableWithType, sanitizeArray } from "utils/miniHelpers";
import { DashboardStats, DashboardCandidates } from "components/pages/dashboard";

function Dashboard() {
  useDashboardData();
  const { t } = useTranslation();
  const { sm, xl } = useSpacing();
  const { auth, dashboard } = useSelector((state) => state);

  const { user } = auth;
  const { total, new_candidates, loading, error } = dashboard;
  const { first_name } = user;

  const handleReload = () => {
    window.location.reload();
  };

  return (
    <WrapperWithNav>
      <View.Item grow>
        <View gap={xl} height="100%">
          {!loading && !error && total && (
            <>
              <View padding={[sm, 0]}>
                <Text variant="title-2">
                  {t(`Hi`)} {first_name}! {t(`Welcome to CooperHire, your new Recruiting App`)}
                </Text>
              </View>
              <DashboardStats />
            </>
          )}
          {!loading && !error && new_candidates && (
            <DashboardCandidates candidates={new_candidates} />
          )}
          {loading && !error && (
            <View.Item grow={true}>
              <ReshapedLoader />
            </View.Item>
          )}
          {!loading && !new_candidates && !total && error && typeof error === "string" && (
            <View.Item grow>
              <View align="center" justify="center" height="100%" gap={xl}>
                <Text color="critical" variant="title-2">
                  {t(error)}
                </Text>
                <Button color="critical" onClick={handleReload}>
                  {t("Please try again")}
                </Button>
              </View>
            </View.Item>
          )}
        </View>
      </View.Item>
    </WrapperWithNav>
  );
}

export default Dashboard;

function useDashboardData() {
  const { total, new_candidates } = useSelector((state) => state.dashboard);
  const { setDashboardInfo, setProperties } = dashboardSlice.actions;
  const dispatch = useDispatch();

  useEffect(() => {
    async function getDashboardData() {
      try {
        dispatch(setProperties({ loading: true, error: null, new_candidates: null, total: null }));
        const response = await backendAPI.get("/companies/dashboard");
        if (
          areKeysAvailableWithType({ object: response, keys: [{ data: "object" }] }) &&
          areKeysAvailableWithType({
            object: response.data.dashboard,
            keys: [
              { candidates: "array" },
              { profile_percentage: "number" },
              { total_candidates: "number" },
              { total_hired: "number" },
              { total_jobs: "number" },
              { weekly_total_candidates: "number" },
            ],
          })
        ) {
          const {
            profile_percentage,
            total_candidates,
            total_hired,
            total_jobs,
            weekly_total_candidates,
            candidates,
          } = response.data.dashboard;
          const payload = {
            total: {
              percentage: profile_percentage,
              candidates: total_candidates,
              hired: total_hired,
              jobs: total_jobs,
              weekly_candidates: weekly_total_candidates,
            },
            new_candidates: sanitizeArray({
              array: candidates,
              elementType: "object",
              keys: [
                { id: "string" },
                { first_name: "string" },
                { last_name: "string" },
                { created_at: "string" },
                { stage: "string" },
                { average_rating: "number" },
              ],
            }),
          };
          // setDashboardPayload(payload);
          dispatch(setDashboardInfo(payload));
          return;
        }
        dispatch(
          setProperties({
            total: null,
            loading: false,
            new_candidates: null,
            error: "Something went wrong!",
          })
        );
      } catch (error) {
        reloadOnUnauthorized(error);
        dispatch(
          setProperties({
            total: null,
            loading: false,
            new_candidates: null,
            error: "Something went wrong!",
          })
        );
      }
    }
    if (!total && !new_candidates) {
      getDashboardData();
    }
    //eslint-disable-next-line
  }, []);
}
