import CryptoJS from "crypto-js";
import { ATS_TOKEN_SECRET_KEY } from "constants";

export function encryptToken({ token }) {
  if (!token) throw new Error("Invalid token!");

  const encryptedToken = CryptoJS.AES.encrypt(
    JSON.stringify(token),
    ATS_TOKEN_SECRET_KEY
  ).toString();

  return encryptedToken;
}

export function decryptToken({ encryptedToken }) {
  if (!encryptedToken) throw new Error("Invalid encryptedToken!");

  const bytes = CryptoJS.AES.decrypt(encryptedToken, ATS_TOKEN_SECRET_KEY);
  const decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

  return decryptedToken;
}
