import { useMemo, useRef } from "react";
import { Button, View } from "reshaped";

import { ArrowRightPrimary } from "icons";
import { accent } from "utils/colors/accents";
import useSpacing from "components/hooks/useSpacing";
import useTranslation from "components/hooks/useTranslation";
import styles from "components/styles/reshaped/candidates.module.css";
import NoteOverview from "components/pages/candidates/categories/overview/NoteOverview";

const NotesOverview = (props) => {
  const { setSelectedCategory, notes } = props;

  const { t } = useTranslation();
  const sequenceNumberRef = useRef(1);
  const { xs, sm, md, lg } = useSpacing();

  const notesColors = useMemo(() => {
    return {
      1: {
        bar: "#1f74EA",
        background: "#E7F0FD",
        code: "E7F0FD",
      },
      2: {
        bar: accent["3-400"],
        background: accent["3-100"],
        code: "FDF7FF",
      },
      3: {
        bar: accent["4-400"],
        background: accent["4-100"],
        code: "FFFBF5",
      },
    };
  }, []);

  const handleSelectedCategory = () => {
    setSelectedCategory("notes");
  };

  return (
    <View>
      <View padding={[lg + xs, 0]} gap={lg + sm} className={styles.notesoverview__wrapper}>
        {Array.isArray(notes) && notes.length < 4 && (
          <>
            {notes.map((note, index, array) => {
              let sequence;
              if (sequenceNumberRef.current <= 2) {
                sequence = sequenceNumberRef.current;
                sequenceNumberRef.current = sequenceNumberRef.current + 1;
              } else {
                sequence = 3;
                sequenceNumberRef.current = 1;
              }
              if (array.length === index + 1) {
                sequenceNumberRef.current = 1;
              }
              return <NoteOverview key={note.id} noteColor={notesColors[sequence]} data={note} />;
            })}
          </>
        )}
        {Array.isArray(notes) && notes.length >= 4 && (
          <>
            {[notes[0], notes[1], notes[2]].map((note, index, array) => {
              let sequence;
              if (sequenceNumberRef.current <= 2) {
                sequence = sequenceNumberRef.current;
                sequenceNumberRef.current = sequenceNumberRef.current + 1;
              } else {
                sequence = 3;
                sequenceNumberRef.current = 1;
              }
              if (array.length === index + 1) {
                sequenceNumberRef.current = 1;
              }
              return <NoteOverview key={note.id} noteColor={notesColors[sequence]} data={note} />;
            })}
          </>
        )}
      </View>
      <View gap={md} direction="row" justify="end" align="center">
        <Button
          size="small"
          color="primary"
          variant="ghost"
          endIcon={ArrowRightPrimary}
          onClick={handleSelectedCategory}
        >
          {t("View all Notes")}
        </Button>
      </View>
    </View>
  );
};

export default NotesOverview;
