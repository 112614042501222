import { accent } from "utils/colors/accents";

export const stageBackgroundColor = {
  applied: accent["4-100"],
  in_review: accent["5-100"],
  interviewing: accent["3-100"],
  evaluation: "#E7F0FD",
  offer: "#D0E2FB",
  hired: accent["2-100"],
};

export const stageColor = {
  applied: accent["4-400"],
  in_review: accent["5-500"],
  interviewing: accent["3-400"],
  evaluation: "#D0E2FB",
  offer: "#71A8F4",
  hired: accent["2-300"],
  rejected: accent["1-400"],
};

export const trackColor = {
  applied: {
    checked: accent["4-500"],
    unChecked: accent["4-300"],
  },
  in_review: {
    checked: accent["5-500"],
    unChecked: accent["5-400"],
  },
  interviewing: {
    checked: accent["3-500"],
    unChecked: accent["3-300"],
  },
  evaluation: {
    checked: "#71A8F4",
    unChecked: "#D0E2FB",
  },
  offer: {
    checked: "#71A8F4",
    unChecked: "#D0E2FB",
  },
  hired: {
    checked: accent["2-500"],
    unChecked: accent["2-500"],
  },
  rejected: {
    checked: "#D1D1D1",
    unChecked: "#D1D1D1",
  },
};
