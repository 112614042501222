import { Text, View } from "reshaped";
import { accent } from "utils/colors/accents";
import useSpacing from "components/hooks/useSpacing";
import { CompanyBanner } from "components/pages/settings";
import styles from "components/styles/reshaped/settings.module.css";

export const SideBanner = ({
  text = "",
  width = "400px",
  height = "550px",
  Svg = CompanyBanner,
  backgroundColor = accent["1-200"],
}) => {
  const { xl } = useSpacing();

  return (
    <View
      height={height}
      width={width}
      borderRadius="large"
      className={styles.side__banner}
      attributes={{
        style: {
          backgroundColor: backgroundColor,
          position: "sticky",
          top: "calc(var(--topbar-height))",
        },
      }}
    >
      <View padding={xl}>
        <Text variant="title-2">{text}</Text>
      </View>
      <View.Item grow>
        <View justify="end" align="center" height="100%">
          <Svg />
        </View>
      </View.Item>
    </View>
  );
};
