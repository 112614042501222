import React, { useMemo } from "react";
import { Button, DropdownMenu, FormControl, View } from "reshaped";

import { ArrowDown } from "icons";
import { useSelector } from "react-redux";
import useTranslation from "components/hooks/useTranslation";
import { stepOneProgressHelper } from "utils/jobStepProgress";
import styles from "components/styles/reshaped/jobs/new.module.css";

const LanguageApplicationDropdown = (props) => {
  const { errors, setErrors, propTitle, setStepOneProgress, language, setLanguage } = props;

  const { t } = useTranslation();
  const auth = useSelector((state) => state.auth);

  const languageApplicationFormOptions = useMemo(() => {
    return [{ value: "English" }, { value: "German" }];

    //eslint-disable-next-line
  }, []);

  const translations = useMemo(() => {
    const languageApplicationFormTranslation = languageApplicationFormOptions.reduce(
      (acc, curr) => ({ ...acc, [curr.value]: t(curr.value) }),
      {}
    );
    return {
      "Language Application Form": t("Language Application Form"),
      "Application form language": t("Application form language"),
      ...languageApplicationFormTranslation,
    };

    //eslint-disable-next-line
  }, [auth && auth?.user ? [auth.user] : []]);

  const handleLanguageApplicationForm = ({ selectedOption }) => {
    const { value } = selectedOption;
    if (errors.language_application_form) {
      setErrors((previousError) => {
        return {
          ...previousError,
          language_application_form: null,
        };
      });
    }
    setLanguage(value);
    stepOneProgressHelper(propTitle, setStepOneProgress, "add");
  };

  return (
    <View.Item grow={true} className={styles.dropdown__wrapper}>
      <FormControl
        required
        hasError={
          errors["language_application_form"] &&
          typeof errors["language_application_form"] !== "object"
        }
      >
        <FormControl.Label>{translations["Application form language"]}</FormControl.Label>
        <DropdownMenu position="bottom-start">
          <DropdownMenu.Trigger>
            {(attributes) => (
              <Button
                className={`${
                  errors["language_application_form"] &&
                  typeof errors["language_application_form"] !== "object"
                    ? styles.dropdown__button__critical
                    : styles.dropdown__button
                }`}
                variant="outline"
                color={
                  errors["language_application_form"] &&
                  typeof errors["language_application_form"] !== "object"
                    ? "critical"
                    : "neutral"
                }
                fullWidth
                endIcon={ArrowDown}
                attributes={{ ...attributes, "data-cooper": "jobs application language" }}
              >
                {language ? translations[language] : translations["Application form language"]}
              </Button>
            )}
          </DropdownMenu.Trigger>
          <DropdownMenu.Content>
            {languageApplicationFormOptions.map((option, index) => {
              return (
                <DropdownMenu.Item
                  onClick={() => handleLanguageApplicationForm({ selectedOption: option })}
                  key={index}
                  attributes={{ "data-cooper": `jobs language ${option.value}` }}
                >
                  {translations[option.value]}
                </DropdownMenu.Item>
              );
            })}
          </DropdownMenu.Content>
        </DropdownMenu>
        <FormControl.Error>{errors?.language_application_form}</FormControl.Error>
      </FormControl>
    </View.Item>
  );
};

export default React.memo(LanguageApplicationDropdown);
