import { useRef, useState } from "react";
import { Button, Text, View, useToast } from "reshaped";

import { backendAPI } from "utils/axios";
import { isString } from "utils/miniHelpers";
import { accent } from "utils/colors/accents";
import useSpacing from "components/hooks/useSpacing";
import { useDispatch, useSelector } from "react-redux";
import { reloadOnUnauthorized } from "utils/checkForAuth";
import { ShowToastWithTranslation } from "utils/showToast";
import useTranslation from "components/hooks/useTranslation";
import { HTMLValidationHelper } from "utils/validationHelper";
import { viewingCandidateSlice } from "redux/viewingCandidate";
import { OperationFailedWithTranslation } from "components/reusables/OperationFailed";
import { PlainTextEditor } from "components/reusables/richTextEditors/PlainTextEditor";

const AddNote = (props) => {
  const { setAddNote } = props;

  const stateRef = useRef("");
  const [noteErrors, setNoteErrors] = useState("");

  const toast = useToast();
  const dispatch = useDispatch();
  const { xs, lg, xl } = useSpacing();
  const { t, locale } = useTranslation();
  const { currentCandidate, loading } = useSelector((state) => state.viewingCandidate);

  const { setLoading, setNote } = viewingCandidateSlice.actions;

  const createNote = async (note) => {
    if (typeof note !== "object") return;
    try {
      dispatch(setLoading());
      const response = await backendAPI.post(`/candidates/${currentCandidate.id}/notes`, { note });
      const { data } = response;
      dispatch(setLoading(false));
      if (data?.note) {
        const {
          user,
          id,
          note,
          created_at,
          updated_at,
          //there are other properties as well, which can be usefull
        } = data.note;
        const newNote = { user, id, note, created_at, updated_at };
        const notePayload = {
          note: newNote,
          candidate_id: currentCandidate.id,
        };
        dispatch(setNote(notePayload));
        setAddNote(false);
        dispatch(setLoading(false));
        ShowToastWithTranslation({
          toast,
          Children: NoteAdded,
          text: t("Note added!"),
        });
        return;
      }
      dispatch(setLoading(false));
      ShowToastWithTranslation({
        toast,
        Children: OperationFailedWithTranslation,
        text: t("Operation unsuccessful!"),
      });
    } catch (error) {
      reloadOnUnauthorized(error);
      dispatch(setLoading(false));
      ShowToastWithTranslation({
        toast,
        Children: OperationFailedWithTranslation,
        text: t("Operation unsuccessful!"),
      });
    }
  };

  const handleValidation = (value) => {
    const errorResult = HTMLValidationHelper({
      value,
      minimumLimit: 2,
      characterLimit: 8000,
      minimumLimitCheck: true,
      minimumLimitErrorString: t("Note should have minimum 2 characters"),
      requiredErrorString: t("Note is required"),
      characterLimitErrorString: t("Note should have maximum 8000 characters"),
    });

    if (isString(errorResult)) {
      setNoteErrors(errorResult);
      return true;
    } else {
      setNoteErrors("");
      return false;
    }
  };

  const handleSubmit = () => {
    let hasError = handleValidation(stateRef.current);

    if (hasError) return;

    const note = {
      note: stateRef.current,
    };

    createNote(note);
  };

  const handleBlur = (value) => {
    handleValidation(value);
  };

  const handleChange = (value) => {
    if (noteErrors) setNoteErrors("");
    stateRef.current = value;
  };

  const handleCancel = () => {
    setAddNote(false);
  };

  return (
    <View
      padding={[lg + xl, xl + xs]}
      gap={xl}
      backgroundColor="white"
      borderRadius="medium"
      attributes={{
        style: {
          zIndex: 3,
        },
      }}
    >
      <View gap={lg + xs} direction="row" align="center">
        <View
          height="60px"
          width="60px"
          align="center"
          justify="center"
          borderRadius="medium"
          attributes={{
            style: {
              backgroundColor: accent["4-100"],
            },
          }}
        >
          <Text variant="title-1">📝</Text>
        </View>
        <Text variant="title-3" color="neutral">
          {locale === "de" ? (
            <>
              Ihr Team ist gespannt auf Ihre Einschätzungen.
              <br />
              Verfassen Sie relevante Notizen zu diesem Kandidaten.
            </>
          ) : (
            <>
              Your team would love to hear from you ❤️,
              <br />
              Leave a note about the candidate ✏️
            </>
          )}
        </Text>
      </View>
      <View gap={xl}>
        <View>
          <PlainTextEditor
            showCount={true}
            error={noteErrors}
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder={t("Write a note about the candidate")}
          />
          <View height="24px">
            {isString(noteErrors) && (
              <Text variant="body-2" color="critical">
                {noteErrors}
              </Text>
            )}
          </View>
        </View>
        <View direction="row" gap={lg} justify="end" align="center">
          <Button color="critical" variant="outline" onClick={handleCancel}>
            {t("Cancel")}
          </Button>
          <Button color="primary" loading={loading} onClick={handleSubmit}>
            {t("Submit note")}
          </Button>
        </View>
      </View>
    </View>
  );
};

export default AddNote;

const NoteAdded = ({ children }) => {
  return (
    <Text color="positive" variant="body-strong-1">
      {children}
    </Text>
  );
};
