import { useMemo } from "react";
import { Text, View } from "reshaped";
import useSpacing from "components/hooks/useSpacing";
import { TextWithEclipses } from "components/reusables";
import useTranslation from "components/hooks/useTranslation";
import styles from "components/styles/reshaped/bookings/bookings.module.css";

export const ActivePastHeading = () => {
  const { xs } = useSpacing();
  const { t } = useTranslation();

  const HeadingLabels = useMemo(() => {
    const labels = [
      { title: t("Job Board"), grow: 2 },
      { title: t("Job Name"), grow: 3 },
      { title: t("Activated On") },
      { title: t("Duration") },
      { title: t("Link") },
      // { title: "Clicks" },
      // { title: "Candidates" },
    ];
    return labels;

    //eslint-disable-next-line
  }, []);

  return (
    <View height="58px" direction="row" align="center" className={styles.inventory__row}>
      {HeadingLabels.map((label) => {
        return (
          <View
            className={`${styles.inventory__column} ${
              label && label.grow ? styles[`grow-${label.grow}`] : ""
            }`}
            key={label.title}
          >
            {label.subTitle ? (
              <View direction="row" align="baseline" justify="start" gap={xs}>
                <Text align="center" variant="body-strong-1">
                  {label.title}
                </Text>
                <TextWithEclipses variant="body-1" text={label.subTitle} characterLimit={10} />
              </View>
            ) : (
              <Text align="start" variant="body-strong-1">
                {label.title}
              </Text>
            )}
          </View>
        );
      })}
    </View>
  );
};
