import { useEffect } from "react";
import { View } from "reshaped";

import useSpacing from "components/hooks/useSpacing";
import { ReshapedLoader } from "components/reusables";
import { useDispatch, useSelector } from "react-redux";
import { CategoryWrapper } from "components/pages/candidates";
import { ShowSupportError } from "components/reusables/common";
import NoNotes from "components/pages/candidates/categories/notes/NoNotes";
import { fetchCandidateOverview } from "redux/viewingCandidate/viewingCandidateSlice";
import NoEvalution from "components/pages/candidates/categories/evalution/NoEvalution";
import NotesOverview from "components/pages/candidates/categories/overview/NotesOverview";
import EvaluationsOverview from "components/pages/candidates/categories/overview/EvaluationsOverview";

const OverviewCategory = (props) => {
  const { setAddEvaluation, setSelectedCategory, setAddNote, viewingCandidateId } = props;

  const { xl } = useSpacing();
  const dispatch = useDispatch();
  const { loading, error, overview } = useSelector((state) => state.viewingCandidate);

  useEffect(() => {
    if (!overview || overview.candidate_id !== viewingCandidateId) {
      dispatch(fetchCandidateOverview(viewingCandidateId));
    }

    //eslint-disable-next-line
  }, [viewingCandidateId]);

  return (
    <View gap={xl} height="100%">
      {loading && (
        <View.Item grow={true}>
          <ReshapedLoader />
        </View.Item>
      )}
      {!loading && (
        <>
          {!error && overview && overview?.evaluations && Array.isArray(overview?.evaluations) && (
            <>
              {overview.evaluations.length === 0 ? (
                <View.Item grow>
                  <CategoryWrapper heading={"Evaluation"} height={"100%"}>
                    <NoEvalution
                      setSelectedCategory={setSelectedCategory}
                      setAddEvaluation={setAddEvaluation}
                    />
                  </CategoryWrapper>
                </View.Item>
              ) : (
                <CategoryWrapper heading={"Evaluation"}>
                  <EvaluationsOverview
                    setSelectedCategory={setSelectedCategory}
                    setAddEvaluation={setAddEvaluation}
                    evaluations={overview.evaluations}
                  />
                </CategoryWrapper>
              )}
            </>
          )}
          {!error && overview && overview?.notes && Array.isArray(overview?.notes) && (
            <>
              {overview.notes.length === 0 ? (
                <View.Item grow>
                  <CategoryWrapper heading={"Notes"} height="100%" grow>
                    <NoNotes setSelectedCategory={setSelectedCategory} setAddNote={setAddNote} />
                  </CategoryWrapper>
                </View.Item>
              ) : (
                <CategoryWrapper heading={"Notes"}>
                  <NotesOverview setSelectedCategory={setSelectedCategory} notes={overview.notes} />
                </CategoryWrapper>
              )}
            </>
          )}
        </>
      )}
      {error && !loading && (
        <View align="center">
          <ShowSupportError variant="body-strong-1" />
        </View>
      )}
    </View>
  );
};

export default OverviewCategory;
