import { Divider, Text, View } from "reshaped";
import useSpacing from "components/hooks/useSpacing";
import { TextWithEclipses } from "components/reusables";
import useTranslation from "components/hooks/useTranslation";
import styles from "components/styles/reshaped/bookings/bookings.module.css";

export const PromotingJob = ({ isSelected, job, setPromotingJob }) => {
  const { title, job_id, city } = job;

  const { t } = useTranslation();
  const { xs, sm, lg } = useSpacing();

  const handleSelectJob = () => {
    setPromotingJob(job);
  };

  return (
    <View
      as="button"
      className={`${isSelected ? styles.selected__job : ""} ${styles.job}`}
      attributes={{
        onClick: handleSelectJob,
      }}
    >
      <View gap={sm} padding={lg}>
        <TextWithEclipses
          text={title}
          color="neutral"
          variant="body-strong-1"
          align="start"
          characterLimit={45}
        />
        <View direction="row" align="center" gap={xs}>
          <Text variant="body-2" color="neutral">
            {t("Job ID ")} {job_id}
          </Text>
          <Text variant="body-2" color="neutral">
            |
          </Text>
          <Text variant="body-2" color="neutral">
            {t("Location")}: {city}
          </Text>
        </View>
      </View>
      <Divider
        attributes={{
          style: {
            backgroundColor: isSelected
              ? "transparent"
              : "var(--rs-color-foreground-neutral-faded)",
          },
        }}
      />
    </View>
  );
};
