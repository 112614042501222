import { baseColor } from "utils/colors/accents";

export const MessageOutline = ({ color = baseColor.primary, width = "32", className }) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 6C24.5304 6 25.0391 6.21071 25.4142 6.58579C25.7893 6.96086 26 7.46957 26 8V20C26 20.5304 25.7893 21.0391 25.4142 21.4142C25.0391 21.7893 24.5304 22 24 22H10L6 26V8C6 6.89 6.9 6 8 6H24ZM8 8V21.17L9.17 20H24V8H8ZM10 11H22V13H10V11ZM10 15H19V17H10V15Z"
        fill={color}
        {...(className && typeof className === "string" ? { className } : {})}
      />
    </svg>
  );
};
