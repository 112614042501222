import React, { useState } from "react";
import { View } from "reshaped";

import { stepOneInitialState } from "utils/jobStepProgress";
import NewJobForm from "components/pages/jobs/newJob/NewJobForm";
import NewJobSteps from "components/pages/jobs/newJob/NewJobSteps";
import styles from "components/styles/reshaped/jobs/new.module.css";

const NewJob = React.forwardRef((props, ref) => {
  const { currentStep, setCurrentStep } = props;
  const [stepOneProgress, setStepOneProgress] = useState(stepOneInitialState);
  const [stepTwoProgress, setStepTwoProgress] = useState([]);
  const [stepThreeProgress, setStepThreeProgress] = useState([]);

  const currentStepProps = { currentStep, setCurrentStep };

  const progressProps = {
    stepOneProgress,
    stepTwoProgress,
    stepThreeProgress,
  };
  const progressSetterProps = {
    setStepOneProgress,
    setStepTwoProgress,
    setStepThreeProgress,
  };

  ref.current = (() => {
    let refOptions = {};
    if (ref.current) refOptions = { ...ref.current };
    refOptions = {
      currentStep,
      ...refOptions,
      ...progressProps,
      ...progressSetterProps,
    };
    return refOptions;
  })();

  return (
    <>
      <View divided className={styles.wrapper} direction="row" gap={6}>
        <NewJobSteps {...currentStepProps} {...progressProps} />
        <NewJobForm {...currentStepProps} {...progressSetterProps} ref={ref} />
      </View>
    </>
  );
});

export default NewJob;
