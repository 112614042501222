import { useEffect, useMemo, useState } from "react";
import { View, Text, Checkbox, TextField, FormControl } from "reshaped";

import useSpacing from "components/hooks/useSpacing";
import { validationHelper } from "utils/validationHelper";
import { supportedTemplateTriggers } from "utils/constants";
import useTranslation from "components/hooks/useTranslation";
import { MessagesTemplateInput } from "components/pages/settings";
import { CooperInputWrapper, CooperLabel } from "components/reusables/reshapedCustoms";
import {
  isObject,
  isString,
  areKeysAvailableIn,
  areKeysAvailableWithType,
} from "utils/miniHelpers";

export const ViewTemplateForm = ({
  view,
  errors,
  stateRef,
  setErrors,
  setIsChanged,
  viewingTemplate,
}) => {
  const { lg } = useSpacing();

  useEffect(() => {
    if (view === "update") {
      setErrors({
        name: null,
        subject: null,
        message: null,
      });
    }
    //eslint-disable-next-line
  }, []);

  return (
    <View gap={2 * lg} maxWidth="650px">
      <NameInput {...{ stateRef, viewingTemplate, errors, setErrors, setIsChanged }} />
      <SubjectInput {...{ stateRef, viewingTemplate, errors, setErrors, setIsChanged }} />
      <MessagesTemplateInput {...{ stateRef, viewingTemplate, errors, setErrors, setIsChanged }} />
      <MakeItDefaultTriggerInput {...{ stateRef, viewingTemplate, setIsChanged }} />
    </View>
  );
};

export function NameInput({ viewingTemplate, stateRef, errors, setErrors, setIsChanged }) {
  const { t } = useTranslation();

  const [name, setName] = useState(() => {
    if (areKeysAvailableIn({ object: viewingTemplate, keys: ["name"] }))
      return viewingTemplate.name;
    return "";
  });

  const TranslatedText = useMemo(() => {
    return {
      placeholder: t("Please specify the name for your new template."),
      name: t("Template Name"),
    };

    //eslint-disable-next-line
  }, []);

  const handleChange = ({ value }) => {
    if (typeof value !== "string") return;
    setIsChanged(true);
    setName(value);
    stateRef.current = {
      ...(isObject(stateRef.current) ? stateRef.current : {}),
      name: value,
    };
  };

  const handleValidation = () => {
    const errorResult = validationHelper({
      value: name,
      requiredErrorString: t("Template Name is mandatory."),
      characterLimitErrorString: t("Template Name should not exceed 240 characters."),
    });
    if (errorResult) {
      setErrors((prev) => {
        return {
          ...prev,
          name: errorResult,
        };
      });
    } else {
      setErrors((prev) => {
        return {
          ...prev,
          name: null,
        };
      });
    }
  };

  return (
    <CooperInputWrapper>
      <FormControl required hasError={errors["name"] && !isObject(errors["name"])}>
        <FormControl.Label>
          <CooperLabel text={TranslatedText.name} />
        </FormControl.Label>
        <TextField
          name="name"
          value={name}
          onChange={handleChange}
          placeholder={TranslatedText.placeholder}
          inputAttributes={{
            type: "text",
            onBlur: handleValidation,
          }}
        />
        {errors["name"] && !isObject(errors["name"]) && (
          <FormControl.Error>{errors["name"]}</FormControl.Error>
        )}
      </FormControl>
    </CooperInputWrapper>
  );
}

export function SubjectInput({ viewingTemplate, stateRef, errors, setErrors, setIsChanged }) {
  const { t } = useTranslation();

  const [subject, setSubject] = useState(() => {
    if (areKeysAvailableIn({ object: viewingTemplate, keys: ["subject"] }))
      return viewingTemplate.subject;
    return "";
  });

  const TranslatedText = useMemo(() => {
    return {
      placeholder: t("Please add the subject of this message."),
      name: t("Subject"),
    };

    //eslint-disable-next-line
  }, []);

  const handleChange = ({ value }) => {
    if (typeof value !== "string") return;
    setIsChanged(true);
    setSubject(value);
    stateRef.current = {
      ...(isObject(stateRef.current) ? stateRef.current : {}),
      subject: value,
    };
  };

  const handleValidation = () => {
    const errorResult = validationHelper({
      value: subject,
      requiredErrorString: t("Subject field is mandatory."),
      characterLimitErrorString: t("Subject should not exceed 240 characters."),
    });
    if (errorResult) {
      setErrors((prev) => {
        return {
          ...prev,
          subject: errorResult,
        };
      });
    } else {
      setErrors((prev) => {
        return {
          ...prev,
          subject: null,
        };
      });
    }
  };

  return (
    <CooperInputWrapper>
      <FormControl required hasError={errors["subject"] && !isObject(errors["subject"])}>
        <FormControl.Label>
          <CooperLabel text={TranslatedText.name} />
        </FormControl.Label>
        <TextField
          name="subject"
          value={subject}
          onChange={handleChange}
          placeholder={TranslatedText.placeholder}
          inputAttributes={{
            type: "text",
            onBlur: handleValidation,
          }}
        />
        {errors["subject"] && !isObject(errors["subject"]) && (
          <FormControl.Error>{errors["subject"]}</FormControl.Error>
        )}
      </FormControl>
    </CooperInputWrapper>
  );
}

export function MakeItDefaultTriggerInput({ viewingTemplate, stateRef, setIsChanged }) {
  const [selectedTrigger, setSelectedTrigger] = useState(() => {
    if (
      areKeysAvailableWithType({
        object: viewingTemplate,
        keys: [
          { is_default_trigger: true, exact: true },
          { trigger: "string", callback: (value) => supportedTemplateTriggers.includes(value) },
        ],
      })
    )
      return viewingTemplate.trigger;
    return "";
  });

  const [shouldDisable] = useState(() => {
    if (
      areKeysAvailableWithType({
        object: viewingTemplate,
        keys: [
          { is_default_trigger: true, exact: true },
          { trigger: "string", callback: (value) => supportedTemplateTriggers.includes(value) },
        ],
      })
    )
      return true;
    return false;
  });

  const { sm } = useSpacing();
  const { t } = useTranslation();

  const handleChange = (data) => {
    if (shouldDisable) return;

    if (!areKeysAvailableIn({ object: data, keys: ["event", "value"] })) return;

    const { event, value } = data;
    const checked = areKeysAvailableWithType({
      object: event.target,
      keys: [{ checked: "boolean" }],
    })
      ? event.target.checked
      : "";

    setSelectedTrigger(checked ? value : "");
    setIsChanged(true);

    stateRef.current = {
      ...(isObject(stateRef.current) ? stateRef.current : {}),
      trigger: checked ? value : "",
      is_default_trigger: checked ? true : null,
    };
  };

  return (
    <View gap={sm}>
      <Text variant="body-strong-2">{t("Messaging Template Settings")}</Text>
      <View divided borderColor="neutral" borderRadius="medium" overflow="hidden">
        <TemplateRadioOption
          {...{
            handleChange,
            shouldDisable,
            selectedTrigger,
            triggerValue: "rejected",
            title: t("Default Rejection Template"),
            subTitle: t("This template is selected by default when rejecting a candidate"),
          }}
        />
        <TemplateRadioOption
          {...{
            handleChange,
            shouldDisable,
            selectedTrigger,
            triggerValue: "applied",
            title: t("Default Apply Template"),
            subTitle: t(
              "This template is sent out to any candidate that applies to one of your jobs."
            ),
          }}
        />
      </View>
    </View>
  );
}

function TemplateRadioOption({
  title = "",
  handleChange,
  triggerValue,
  subTitle = "",
  shouldDisable,
  selectedTrigger,
}) {
  const { md, lg } = useSpacing();
  if (!isString(triggerValue)) return;

  const handleInput = ({ value, event }) => {
    if (handleChange && typeof handleChange === "function") handleChange({ value, event });
  };

  return (
    <View
      {...(!shouldDisable ? { as: "label" } : {})}
      attributes={{ htmlFor: `template__${triggerValue}` }}
      direction="row"
      align="center"
      gap={md}
      padding={lg}
      backgroundColor={
        shouldDisable && selectedTrigger && selectedTrigger === triggerValue ? "page" : "white"
      }
      maxWidth={
        shouldDisable && selectedTrigger && selectedTrigger === triggerValue
          ? "100%"
          : "max-content"
      }
    >
      <Checkbox
        value={triggerValue}
        inputAttributes={{
          id: `template__${triggerValue}`,
        }}
        name={triggerValue}
        onChange={handleInput}
        checked={selectedTrigger && selectedTrigger === triggerValue ? true : false}
      />
      <View>
        <Text
          variant="body-strong-1"
          color={
            shouldDisable && selectedTrigger && selectedTrigger === triggerValue
              ? "neutral-faded"
              : "neutral"
          }
        >
          {isString(title) ? title : "Radio"}
        </Text>
        <Text
          variant="body-2"
          color={
            shouldDisable && selectedTrigger && selectedTrigger === triggerValue
              ? "neutral-faded"
              : "neutral"
          }
        >
          {isString(subTitle) ? subTitle : "No Data"}
        </Text>
      </View>
    </View>
  );
}
