import { memo } from "react";
import { Checkbox, View } from "reshaped";
import styles from "components/styles/reshaped/jobs/new.module.css";

const RemoteOption = (props) => {
  const { title, remote, setRemote } = props;

  const handleChange = (event) => {
    const target = event.target;
    const option = target.checked ? "yes" : "no";
    setRemote(option);
  };

  return (
    <View.Item grow={true} className={styles.remote__option}>
      <Checkbox
        name="remote-option"
        attributes={{ "data-cooper": "remote checkbox" }}
        onChange={({ event }) => {
          handleChange(event);
        }}
        checked={remote === "yes" ? true : false}
      >
        {title}
      </Checkbox>
    </View.Item>
  );
};

export default memo(RemoteOption);
