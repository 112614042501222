import { useState, useCallback } from "react";
import { Button, FormControl, TextField, View } from "reshaped";

import axios from "axios";
import { Eye } from "icons";
import { metaSlice } from "redux/meta";
import { ATS_BACKEND_HOST } from "constants";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useTranslation from "components/hooks/useTranslation";
import { PasswordProgress } from "components/pages/register";
import { sanitizeWord, validateErrors } from "utils/validateError";
import { PASSWORDVALIDATIONREGEX } from "utils/RegularExpressions";
import { passwordProgressHelper } from "utils/passwordProgressHelper";
import form from "components/styles/reshaped/reusables/form.module.css";

export const NewPassword = (props) => {
  const {
    confirmPassword,
    setConfirmPassword,
    newPassword,
    setNewPassword,
    setResetPasswordStage,
    isInvitation = false,
    invitationToken = "",
    resetPasswordToken = "",
  } = props;

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordHelper, setShowPasswordHelper] = useState(false);
  const [passwordProgress, setPasswordProgress] = useState({
    sevenCharacters: false,
    oneNumber: false,
    oneLowercase: false,
    oneUppercase: false,
    oneSpecial: false,
  });

  const { isLoading } = useSelector((state) => state.meta);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [error, setError] = useState({
    confirmPassword: {},
    newPassword: {},
  });

  const validate = useCallback((errorName, name, handleError, regex) => {
    validateErrors(errorName, name, handleError, t, regex);

    //eslint-disable-next-line
  }, []);

  const handleConfirmPassword = ({ value }) => {
    if (error.confirmPassword) {
      setError((previousError) => {
        return {
          ...previousError,
          confirmPassword: null,
        };
      });
    }
    setConfirmPassword(value);
  };

  const handleNewPassword = ({ value }) => {
    if (!showPasswordHelper) setShowPasswordHelper(true);

    passwordProgressHelper(value, setPasswordProgress);

    if (error.newPassword) {
      setError((previousError) => {
        return {
          ...previousError,
          newPassword: null,
        };
      });
    }
    setNewPassword(value);
  };

  const handleShowPassword = (e) => {
    setShowPassword(!showPassword);
  };

  const handleNewPasswordValidation = (e) => {
    if (showPasswordHelper) setShowPasswordHelper(false);

    validate("newPassword", newPassword, setError, PASSWORDVALIDATIONREGEX);
  };

  const handleConfirmPasswordValidation = (e) => {
    validate("confirmPassword", confirmPassword, setError);
    if (newPassword !== confirmPassword) {
      setError((previousErrors) => {
        return {
          ...previousErrors,
          confirmPassword: t("Password does not match"),
        };
      });
      return;
    }
  };

  const updatePassword = async ({ newPassword, confirmPassword }) => {
    const user = {
      reset_password_token: resetPasswordToken,
      password: newPassword,
      password_confirmation: confirmPassword,
    };
    try {
      dispatch(metaSlice.actions.initialLoading());
      const response = await axios.put(`${ATS_BACKEND_HOST}/users/password`, { user });
      dispatch(metaSlice.actions.stopLoading());
      if (response.data.status === 200 && response.data.message) {
        setResetPasswordStage("success");
        return;
      }
      setError({
        newPassword: t("Something went wrong"),
      });
    } catch (err) {
      dispatch(metaSlice.actions.stopLoading());
      if (err.response && err.response.status === 422 && err.response.data) {
        const { data } = err.response;
        let hasError = false;
        if ("reset_password_token" in data.errors) {
          navigate("/login");
          return;
        }
        if ("password_confirmation" in data.errors) {
          hasError = true;
          setError((previousError) => {
            return {
              ...previousError,
              confirmPassword: data.errors.password_confirmation[0],
            };
          });
        }
        if ("password" in data.errors) {
          hasError = true;
          setError((previousError) => {
            return {
              ...previousError,
              newPassword: data.errors.password[0],
            };
          });
        }
        if (hasError) return;
      }
      setError({
        newPassword: t("Something went wrong"),
      });
    }
  };

  const acceptInvitation = async ({ newPassword, confirmPassword }) => {
    const user = {
      invitation_token: invitationToken,
      password: newPassword,
      password_confirmation: confirmPassword,
    };
    try {
      dispatch(metaSlice.actions.initialLoading());
      const response = await axios.put(`${ATS_BACKEND_HOST}/users/invitation`, { user });
      dispatch(metaSlice.actions.stopLoading());
      if (!response.status && !response.data) {
        return setError({
          newPassword: t("Something went wrong"),
        });
      }
      setResetPasswordStage("success");
    } catch (err) {
      dispatch(metaSlice.actions.stopLoading());
      if (err.response && err.response.status === 401 && err.response.data) {
        const { data } = err.response;
        let hasError = false;
        if ("invitation_token" in data) {
          navigate("/login");
          return;
        }
        if ("password_confirmation" in data) {
          hasError = true;
          setError((previousError) => {
            return {
              ...previousError,
              confirmPassword: data.password_confirmation[0],
            };
          });
        }
        if ("password" in data) {
          hasError = true;
          setError((previousError) => {
            return {
              ...previousError,
              newPassword: data.password[0],
            };
          });
        }
        if (hasError) return;
      }
      setError({
        newPassword: t("Something went wrong"),
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let hasError = false;

    // if user submitting with empty or invalid fields
    for (let field in error) {
      if (error[field]) {
        hasError = true;
        if (typeof error[field] === "object") {
          setError((previousError) => {
            return {
              ...previousError,
              [field]: t(`${sanitizeWord(field)} is required`),
            };
          });
        }
      }
    }

    if (newPassword !== confirmPassword) {
      setError((previousErrors) => {
        return {
          ...previousErrors,
          confirmPassword: t("Password does not match"),
        };
      });
      return;
    }

    if (hasError) return;

    if (isInvitation && invitationToken) {
      await acceptInvitation({ newPassword, confirmPassword });
    }

    if (!isInvitation && resetPasswordToken) {
      await updatePassword({ newPassword, confirmPassword });
    }
  };

  return (
    <form>
      <View gap={4}>
        <FormControl
          required
          hasError={error["newPassword"] && typeof error["newPassword"] !== "object"}
        >
          <FormControl.Label>{t("New password")}</FormControl.Label>
          <View className={form.password__input}>
            <TextField
              value={newPassword}
              onChange={handleNewPassword}
              inputAttributes={{
                type: showPassword ? "text" : "password",
                onBlur: handleNewPasswordValidation,
                autoComplete: "on",
                autoFocus: true,
              }}
            />
            <Button
              className={`${form.show__password__btn} ${showPassword ? form.active : ""}`}
              variant="ghost"
              startIcon={Eye}
              onClick={handleShowPassword}
              attributes={{ tabIndex: -1 }}
            />
            {showPasswordHelper && <PasswordProgress passwordProgress={passwordProgress} />}
          </View>
          <FormControl.Error>{error["newPassword"]}</FormControl.Error>
        </FormControl>
        <FormControl
          required
          hasError={error["confirmPassword"] && typeof error["confirmPassword"] !== "object"}
        >
          <FormControl.Label>{t("Confirm password")}</FormControl.Label>
          <TextField
            value={confirmPassword}
            onChange={handleConfirmPassword}
            inputAttributes={{
              type: "password",
              onBlur: handleConfirmPasswordValidation,
              autoComplete: "on",
            }}
          />
          <FormControl.Error>{error["confirmPassword"]}</FormControl.Error>
        </FormControl>
        <Button
          onClick={handleSubmit}
          loading={isLoading}
          fullWidth
          attributes={{ type: "submit" }}
          color="primary"
        >
          {t("Activate account")}
        </Button>
      </View>
    </form>
  );
};
