export const getProgressText = (completed) => {
  let status = "";

  if (completed >= 60) {
    status = "Super";
  } else if (completed >= 50) {
    status = "Good";
  } else if (completed >= 30) {
    status = "Average";
  } else {
    status = "Vacant";
  }

  return status;
};
