import { baseColor } from "utils/colors/accents";

export const Bookings = ({ color = baseColor.white }) => {
  return (
    <svg width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.52414 20C1.10112 20 0.741344 19.8316 0.444818 19.4949C0.148273 19.1582 0 18.7496 0 18.2693V1.73075C0 1.2504 0.148273 0.841844 0.444818 0.505094C0.741344 0.168364 1.10112 0 1.52414 0H18.4759C18.8989 0 19.2587 0.168364 19.5552 0.505094C19.8517 0.841844 20 1.2504 20 1.73075V18.2693C20 18.7496 19.8517 19.1582 19.5552 19.4949C19.2587 19.8316 18.8989 20 18.4759 20H1.52414ZM1.52414 19.0385H18.4759C18.6452 19.0385 18.8004 18.9583 18.9416 18.7981C19.0827 18.6378 19.1532 18.4615 19.1532 18.2693V1.73075C19.1532 1.53846 19.0827 1.36218 18.9416 1.20191C18.8004 1.04166 18.6452 0.961532 18.4759 0.961532H1.52414C1.35479 0.961532 1.19955 1.04166 1.05844 1.20191C0.917319 1.36218 0.84676 1.53846 0.84676 1.73075V18.2693C0.84676 18.4615 0.917319 18.6378 1.05844 18.7981C1.19955 18.9583 1.35479 19.0385 1.52414 19.0385ZM2.84505 15C2.84505 15.3452 3.12488 15.625 3.47005 15.625H6.6231C6.96828 15.625 7.2481 15.3452 7.2481 15C7.2481 14.6548 6.96828 14.375 6.6231 14.375H3.47005C3.12488 14.375 2.84505 14.6548 2.84505 15ZM11.4864 11.3611C12.187 12.1566 13.4269 12.1566 14.1274 11.3611L17.1301 7.95125C17.3258 7.72903 17.3258 7.39597 17.1301 7.17375C16.8954 6.90715 16.4797 6.90788 16.2459 7.17529L13.5825 10.2212C13.1715 10.6912 12.4409 10.6925 12.0282 10.2239L11.6808 9.82929C11.4475 9.56437 11.0335 9.56802 10.8049 9.83702C10.6186 10.0562 10.6215 10.3788 10.8116 10.5947L11.4864 11.3611ZM2.84505 10C2.84505 10.3452 3.12488 10.625 3.47005 10.625H6.6231C6.96828 10.625 7.2481 10.3452 7.2481 10C7.2481 9.65482 6.96828 9.375 6.6231 9.375H3.47005C3.12488 9.375 2.84505 9.65482 2.84505 10ZM2.84505 5C2.84505 5.34518 3.12488 5.625 3.47005 5.625H6.6231C6.96828 5.625 7.2481 5.34518 7.2481 5C7.2481 4.65482 6.96828 4.375 6.6231 4.375H3.47005C3.12488 4.375 2.84505 4.65482 2.84505 5Z"
        fill={color}
      />
    </svg>
  );
};
