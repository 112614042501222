import { useMemo } from "react";
import { Text, View } from "reshaped";
import { accent } from "utils/colors/accents";
import useSpacing from "components/hooks/useSpacing";
import useTranslation from "components/hooks/useTranslation";
import PromoteBannerImage from "assets/images/PromoteBannerImage";
import BookingsBannerImage from "assets/images/BookingsBannerImage";

export const Banner = ({ title, backgroundColor = accent["2-100"], bannerName = "promote" }) => {
  const { t } = useTranslation();
  const { lg, xl } = useSpacing();

  const BannerImageComponent = useMemo(() => {
    const components = {
      promote: PromoteBannerImage,
      bookings: BookingsBannerImage,
    };
    return components[bannerName];

    //eslint-disable-next-line
  }, [bannerName]);

  return (
    <View
      height="150px"
      borderRadius="medium"
      direction="row"
      align="center"
      gap={lg + xl}
      padding={[0, lg]}
      attributes={{
        style: {
          backgroundColor,
        },
      }}
    >
      {/* <PromoteBannerImage /> */}
      <BannerImageComponent />
      <View.Item grow>
        <View maxWidth="130ch">
          <Text variant="title-2" align="start">
            {t(title)}
          </Text>
        </View>
      </View.Item>
    </View>
  );
};
