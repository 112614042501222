import { useState, useEffect, useRef } from "react";
import { Button, Text, useToast, View } from "reshaped";

import { backendAPI } from "utils/axios";
import { isString } from "utils/miniHelpers";
import { accent } from "utils/colors/accents";
import useSpacing from "components/hooks/useSpacing";
import { useDispatch, useSelector } from "react-redux";
import { reloadOnUnauthorized } from "utils/checkForAuth";
import { ShowToastWithTranslation } from "utils/showToast";
import useTranslation from "components/hooks/useTranslation";
import { HTMLValidationHelper } from "utils/validationHelper";
import { viewingCandidateSlice } from "redux/viewingCandidate";
import { InteractiveRating } from "components/reusables/common";
import { removeHtmlTagsWithAttributes } from "utils/regexHelpers";
import { OperationFailedWithTranslation } from "components/reusables/OperationFailed";
import { PlainTextEditor } from "components/reusables/richTextEditors/PlainTextEditor";

const EditEvaluation = (props) => {
  const { editEvaluationID, setEditEvaluationID } = props;

  const [rating, setRating] = useState(0);
  const [oldEvaluation, setOldEvaluation] = useState("");
  const [evaluationErrors, setEvaluationErrors] = useState("");
  const [evaluationCreatedUser, setEvaluationCreatedUser] = useState("");

  const { actions } = viewingCandidateSlice;
  const { setLoading } = actions;

  const stateRef = useRef("");

  const toast = useToast();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { xs, sm, lg, xl } = useSpacing();
  const { evaluations, loading, currentCandidate } = useSelector((state) => state.viewingCandidate);
  const { user: loggedInUser } = useSelector((state) => state.auth);
  const { updateEvaluation } = viewingCandidateSlice.actions;

  useEffect(() => {
    if (evaluations && Array.isArray(evaluations.data) && editEvaluationID) {
      const { data } = evaluations;
      const found = data.find((evaluation) => evaluation.id === editEvaluationID);
      if (found) {
        const { rating, comments = "", user } = found;
        setEvaluationCreatedUser(user?.id);
        setRating(rating);
        stateRef.current = comments;
        setOldEvaluation(comments);
      } else {
        setEditEvaluationID("");
      }
    } else {
      setEditEvaluationID("");
    }

    //eslint-disable-next-line
  }, []);

  const editEvaluation = async (evaluation) => {
    if (typeof evaluation !== "object") return;
    if (loggedInUser?.id !== evaluationCreatedUser) {
      setEditEvaluationID("");
    }
    try {
      dispatch(setLoading());
      const response = await backendAPI.put(
        `/candidates/${currentCandidate?.id}/evaluations/${editEvaluationID}`,
        { evaluation }
      );
      const { data } = response;
      dispatch(setLoading(false));
      if (data?.evaluation) {
        const {
          user,
          id,
          rating,
          comments,
          average_rating,
          //there are other properties as well, which can be usefull
        } = data.evaluation;
        const evaluation = { user, id, rating };
        if (comments) evaluation["comments"] = comments;
        const evaluationPayload = {
          evaluation,
          average_rating,
          candidate_id: currentCandidate.id,
        };
        dispatch(updateEvaluation(evaluationPayload));
        setEditEvaluationID("");
        ShowToastWithTranslation({
          toast,
          Children: EvaluationUpdated,
          text: t("Evaluation updated!"),
        });
        return;
      }
      if (data?.status && data.status === 422 && data.message === "evaluation not uniq") {
        ShowToastWithTranslation({
          toast,
          Children: EvaluationExist,
          text: t("You have already evaluated this candidate!"),
        });
        return;
      }
      ShowToastWithTranslation({
        toast,
        Children: OperationFailedWithTranslation,
        text: t("Operation unsuccessful!"),
      });
    } catch (error) {
      reloadOnUnauthorized();
      dispatch(setLoading(false));
      ShowToastWithTranslation({
        toast,
        Children: OperationFailedWithTranslation,
        text: t("Operation unsuccessful!"),
      });
    }
  };

  const handleSubmit = () => {
    let hasError = false;

    const evaluation = {
      rating,
    };

    hasError = handleValidation(stateRef.current);

    if (hasError) return;

    const textContent = removeHtmlTagsWithAttributes({ text: stateRef.current, shouldTrim: true });

    evaluation["comments"] = stateRef.current;

    if (textContent === "" || typeof textContent !== "string") {
      evaluation["comments"] = null;
    }

    editEvaluation(evaluation);
  };

  const handleValidation = (value) => {
    const errorResult = HTMLValidationHelper({
      value,
      requiredCheck: false,
      characterLimit: 8000,
      minimumLimitCheck: true,
      minimumLimit: 2,
      minimumLimitErrorString: t("Evaluation should have minimum 2 characters"),
      characterLimitErrorString: t("Evaluation should have maximum 8000 characters"),
    });

    if (isString(errorResult)) {
      setEvaluationErrors(errorResult);
      return true;
    } else {
      setEvaluationErrors("");
      return false;
    }
  };

  const handleBlur = (value) => {
    handleValidation(value);
  };

  const handleChange = (value) => {
    if (evaluationErrors) setEvaluationErrors("");
    stateRef.current = value;
  };

  const handleCancel = () => {
    setEditEvaluationID("");
  };

  return (
    <>
      {currentCandidate && (
        <View
          padding={[lg + xl, xl + xs]}
          gap={sm + xl}
          backgroundColor="white"
          borderRadius="medium"
          attributes={{
            style: {
              zIndex: 3,
            },
          }}
        >
          <View gap={lg + xs} direction="row" align="center">
            <View
              height="60px"
              width="60px"
              align="center"
              justify="center"
              borderRadius="medium"
              attributes={{
                style: {
                  backgroundColor: accent["4-100"],
                },
              }}
            >
              <Text variant="title-1">⭐</Text>
            </View>
            <Text variant="title-3" color="neutral">
              Every star given counts! 💫
              <br />
              Create a candidate evaluation now.
            </Text>
          </View>
          <View gap={xl}>
            <Text variant="body-2">Rate candidate</Text>
            <View gap={xl}>
              <InteractiveRating rating={rating} setRating={setRating} />
              <View>
                <PlainTextEditor
                  showCount={true}
                  onBlur={handleBlur}
                  value={oldEvaluation}
                  onChange={handleChange}
                  error={evaluationErrors}
                  placeholder={t("Share your evaluation about the candidate")}
                />
                <View height="24px">
                  {isString(evaluationErrors) && (
                    <Text variant="body-2" color="critical">
                      {evaluationErrors}
                    </Text>
                  )}
                </View>
              </View>
            </View>
          </View>
          <View direction="row" gap={lg} justify="end" align="center">
            <Button color="critical" variant="outline" onClick={handleCancel}>
              {t("Cancel")}
            </Button>
            <Button color="primary" loading={loading} onClick={handleSubmit} disabled={rating <= 0}>
              {t("Update")}
            </Button>
          </View>
        </View>
      )}
    </>
  );
};

export default EditEvaluation;

const EvaluationExist = ({ children }) => {
  return (
    <Text color="critical" variant="body-strong-1">
      {children}
    </Text>
  );
};

const EvaluationUpdated = ({ children }) => {
  return (
    <Text color="positive" variant="body-strong-1">
      {children}
    </Text>
  );
};
