import { useEffect, useMemo, useRef, useState } from "react";
import { View } from "reshaped";

import { Close } from "icons";
import { baseColor } from "utils/colors/accents";
import { useSearchParams } from "react-router-dom";
import useSpacing from "components/hooks/useSpacing";
import { useDispatch, useSelector } from "react-redux";
import { conversationSlice } from "redux/conversation";
import { areKeysAvailableWithType } from "utils/miniHelpers";
import Categories from "components/reusables/tab/Categories";
import styles from "components/styles/reshaped/candidates.module.css";
import NotesCategory from "components/pages/candidates/categories/notes";
import { inProgressConversationsSlice } from "redux/inProgressConversations";
import OverviewCategory from "components/pages/candidates/categories/overview";
import MessagesCategory from "components/pages/candidates/categories/messages";
import EvalutionCategory from "components/pages/candidates/categories/evalution";
import useConversationSubscriptions from "components/hooks/useConversationSubscriptions";

export const ProfileStats = (props) => {
  const { handleClose, viewingCandidateId } = props;

  const currentViewingCandidateIdRef = useRef("");
  const univeralInProgressConversationRef = useRef([]);

  const { xl } = useSpacing();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { inProgressConversations } = useSelector((state) => state);
  const { removeConversationSubscription } = useConversationSubscriptions();
  const { inProgressConversations: universalInProgressConversation } = inProgressConversations;

  const [addNote, setAddNote] = useState(false);
  const [addEvaluation, setAddEvaluation] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(() => {
    let view = searchParams.get("view");
    const allViews = {
      overview: "overview",
      evaluation: "evaluation",
      notes: "notes",
      messages: "messages",
    };
    if (view && typeof view === "string" && allViews[view.toLowerCase()]) {
      return allViews[view.toLowerCase()];
    }
    return "overview";
  });

  const addNoteProps = { addNote, setAddNote };
  const addEvaluationProps = { addEvaluation, setAddEvaluation };
  const selectedCategoryProps = { selectedCategory, setSelectedCategory };

  useEffect(() => {
    if (
      !currentViewingCandidateIdRef.current ||
      viewingCandidateId !== currentViewingCandidateIdRef.current
    ) {
      currentViewingCandidateIdRef.current = viewingCandidateId;
      dispatch(inProgressConversationsSlice.actions.setViewingingCandidateID(viewingCandidateId));
    }
    return () => {
      dispatch(inProgressConversationsSlice.actions.setViewingingCandidateID(""));
      dispatch(
        conversationSlice.actions.setCandidateInProgressMessages({
          data: [],
          initial: true,
        })
      );
      handleSubscription({ candidate_id: viewingCandidateId });
      currentViewingCandidateIdRef.current = "";
    };

    //eslint-disable-next-line
  }, [viewingCandidateId]);

  useEffect(() => {
    univeralInProgressConversationRef.current = areKeysAvailableWithType({
      object: universalInProgressConversation,
      keys: [{ [viewingCandidateId]: "array" }],
    })
      ? universalInProgressConversation[viewingCandidateId]
      : [];
  }, [universalInProgressConversation, viewingCandidateId]);

  function handleSubscription({ candidate_id }) {
    if (!viewingCandidateId) return;
    let shouldUnsubscribe = true;
    const length = Array.isArray(univeralInProgressConversationRef.current)
      ? univeralInProgressConversationRef.current.length
      : 0;
    if (length > 0) {
      for (let i = 0; i < length; i++) {
        const each = univeralInProgressConversationRef.current[i];
        if (areKeysAvailableWithType({ object: each, keys: [{ isSending: true, exact: true }] })) {
          shouldUnsubscribe = false;
          break;
        }
      }
    }
    if (shouldUnsubscribe) {
      removeConversationSubscription({ candidate_id: candidate_id });
    }
  }

  const categories = useMemo(() => {
    return [
      {
        title: "Overview",
        value: "overview",
      },
      {
        title: "Evaluation",
        value: "evaluation",
      },
      {
        title: "Notes",
        value: "notes",
      },
      {
        title: "Messages",
        value: "messages",
      },
    ];

    //eslint-disable-next-line
  }, []);

  const handleModalClose = () => {
    handleClose({ notFound: false });
  };

  return (
    <View className={`${styles.stat__container}`} borderRadius="large" gap={xl} height="100%">
      <View
        as="button"
        align="center"
        justify="center"
        height="24px"
        width="24px"
        className={`${styles.close__btn}`}
        attributes={{
          onClick: handleModalClose,
        }}
      >
        <Close color={baseColor.white} />
      </View>
      <Categories
        {...{
          categories,
          selectedCategory,
          setSelectedCategory,
          setAddEvaluation,
          setAddNote,
        }}
      />
      <View
        padding={[0, xl]}
        borderRadius="medium"
        attributes={{
          style: {
            minHeight: "calc(100% - 115px)",
            maxHeight: "calc(100% - 115px)",
            overflow: "scroll",
            display: "grid",
          },
        }}
        className="no__scroll"
      >
        {selectedCategory === "overview" && (
          <OverviewCategory
            {...addEvaluationProps}
            {...selectedCategoryProps}
            {...addNoteProps}
            viewingCandidateId={viewingCandidateId}
          />
        )}
        {selectedCategory === "evaluation" && (
          <EvalutionCategory
            {...addEvaluationProps}
            {...selectedCategoryProps}
            viewingCandidateId={viewingCandidateId}
          />
        )}
        {selectedCategory === "notes" && (
          <NotesCategory
            {...addNoteProps}
            {...selectedCategoryProps}
            viewingCandidateId={viewingCandidateId}
          />
        )}
        {selectedCategory === "messages" && (
          <MessagesCategory {...selectedCategoryProps} viewingCandidateId={viewingCandidateId} />
        )}
      </View>
    </View>
  );
};
