import { Modal } from "reshaped";
import styles from "components/styles/reshaped/settings.module.css";
import { InvitationSuccess, EmailInvite } from "components/pages/settings";

export const InvitationModal = (props) => {
  const { invitationStage, active, resetInvitation } = props;

  const handleModalClose = () => {
    resetInvitation({ ...props });
  };

  return (
    <Modal
      size={{
        s: "auto",
        m: "340px",
      }}
      className={styles.modal}
      active={active}
      onClose={handleModalClose}
    >
      {invitationStage === "invite" && <EmailInvite {...props} />}
      {invitationStage === "sent" && <InvitationSuccess {...props} />}
    </Modal>
  );
};
