import { locales } from "translations/config";
import { areKeysAvailableWithType, isObject } from "utils/miniHelpers";

export function countryHelper(list) {
  let sanitizedList = [
    {
      country: "Germany",
      translation: {
        de: "Deutschland",
        en: "Germany",
      },
      country_code: "de",
    },
    {
      country: "United Kingdom",
      translation: {
        de: "Vereinigtes Königreich",
        en: "United Kingdom",
      },
      country_code: "uk",
    },
    {
      country: "France",
      translation: {
        de: "Frankreich",
        en: "France",
      },
      country_code: "fr",
    },
    {
      country: "USA",
      translation: {
        de: "USA",
        en: "USA",
      },
      country_code: "us",
    },
  ];

  if (list && Array.isArray(list) && list.length > 0) {
    sanitizedList = list;
  }

  function getValuesOf({ country }) {
    const values = [];
    if (!isObject(country)) return values;

    for (let keyProperty in country) {
      const valueProperty = country[keyProperty];
      if (!valueProperty) continue;

      if (typeof valueProperty === "string") {
        values.push(valueProperty);
      }

      if (isObject(valueProperty)) {
        Object.values(valueProperty).forEach((translatedCountry) => {
          if (!translatedCountry || typeof translatedCountry !== "string") return;
          values.push(translatedCountry);
        });
      }
    }

    return values;
  }

  function sanitizeLocale(locale) {
    if (locale && typeof locale === "string" && locales.includes(locale)) return locale;
    return "en";
  }

  function getCountryName({ country = "", locale, defaultValue }) {
    const defaultString = typeof defaultValue === "string" ? defaultValue : "no country";

    const sanitizedLocale = sanitizeLocale(locale);

    if (!country || typeof country !== "string") return defaultString;
    const listLength = sanitizedList.length;
    let resultName = country;

    for (let i = 0; i < listLength; i++) {
      const each = sanitizedList[i];
      const values = getValuesOf({ country: each });

      if (
        values.includes(country) &&
        areKeysAvailableWithType({
          object: each.translation,
          keys: [{ [sanitizedLocale]: "string" }],
        })
      ) {
        resultName = each.translation[sanitizedLocale];
        break;
      }
    }

    return resultName;
  }

  function getCountriesList({ locale }) {
    let resultList = [];
    const sanitizedLocale = sanitizeLocale(locale);
    const listLength = sanitizedList.length;

    for (let i = 0; i < listLength; i++) {
      const each = sanitizedList[i];
      if (
        areKeysAvailableWithType({
          object: each.translation,
          keys: [{ [sanitizedLocale]: "string" }],
        })
      ) {
        resultList.push(each.translation[sanitizedLocale]);
      }
    }
    return resultList;
  }

  function getFullDetailsCountriesList() {
    return sanitizedList;
  }

  function getCustomList(callback) {
    if (callback && typeof callback === "function") return callback(sanitizedList);
  }

  function getCountryCode({ country = "", defaultValue }) {
    const defaultString = typeof defaultValue === "string" ? defaultValue : "";

    if (!country || typeof country !== "string") return defaultString;

    const listLength = sanitizedList.length;

    let resultName = defaultString;

    for (let i = 0; i < listLength; i++) {
      const each = sanitizedList[i];
      const values = getValuesOf({ country: each });

      if (
        values.includes(country) &&
        areKeysAvailableWithType({ object: each, keys: [{ country_code: "string" }] })
      ) {
        resultName = each.country_code;
        break;
      }
    }

    return resultName;
  }

  return {
    getCustomList,
    getCountryName,
    getCountryCode,
    getCountriesList,
    getFullDetailsCountriesList,
  };
}
