// ATS Backend staging Endpoint
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const ATS_BACKEND_HOST = process.env.REACT_APP_API_BASE_URL;

// Ninja filter staging endpoint
export const NINJA_FILTER_HOST = process.env.REACT_APP_NINJA_FILTER_API_BASE_URL;

// JWT token encryption secret
export const ATS_TOKEN_SECRET_KEY = process.env.REACT_APP_ATS_TOKEN_SECRET_KEY;

//Rollbar access token
export const ROLLBAR_ACCESS_TOKEN = process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN;

// Public ATS jobs staging url
export const ATS_JOBS_FRONTEND_URL = process.env.REACT_APP_ATS_JOBS_FRONTEND_URL;

// ATS web socket url
export const ATS_WEB_SOCKET_URL = process.env.REACT_APP_ATS_WEB_SOCKET_URL;
