import { EMAILVALIDATIONREGEX, VALIDURLREGEX } from "utils/RegularExpressions";

const properties = {
  first_name: {
    regex: /[a-zA-Z]{1,}\s?'?[a-zA-Z]{0,}/,
    message: "First Name should have minimum alphabet character",
    max: {
      numberOfCharacters: 240,
      message: "First name should have maximum 240 characters",
    },
  },
  last_name: {
    regex: /[a-zA-Z]{1,}\s?'?[a-zA-Z]{0,}/,
    message: "Last Name should have minimum alphabet character",
    max: {
      numberOfCharacters: 240,
      message: "Last name should have maximum 240 characters",
    },
  },
  email: {
    regex: EMAILVALIDATIONREGEX,
    message: "Enter valid Email",
  },
  number: {
    //eslint-disable-next-line
    regex: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
    message: "Enter valid Phone number",
  },
  linkedin_url: {
    regex: VALIDURLREGEX,
    message: "Enter valid LinkedIn URL",
  },
  applied_job: {
    regex: /[a-zA-Z0-9\s\S]{1,}/,
    message: "Select valid Job",
  },
  source: {
    regex: /[\w\W\S\s\d\D]{1,}/,
    message: "Text field should have minimum 1 character",
    max: {
      numberOfCharacters: 70,
      message: "Text field should have maximum 70 characters",
    },
  },
  notes: {
    regex: /[\w\W\S\s\d\D]{2,}/,
    message: "Text field should have minimum 2 characters",
    max: {
      numberOfCharacters: 8000,
      message: "Text field should have maximum 8000 characters",
    },
  },
  evaluation: {
    regex: /[\w\W\s\S\n]{2,}/,
    message: "Evaluation field should have minimum 2 characters",
    max: {
      numberOfCharacters: 8000,
      message: "Evaluation field should have maximum 8000 characters",
    },
  },
};

export const candidateValidationHelper = (property) => {
  if (properties[property]) {
    return properties[property];
  }
  return properties["basic"];
};
