import { useMemo } from "react";
import { Text } from "reshaped";
import "components/styles/library/tooltip.css";

export function Tooltip({
  children,
  text = "tooltip",
  tooltipClassName,
  position = "top",
  tooltipTextAlign = "left",
  tooltipMaxWidth = "300px",
  tooltipTextVariant = "caption-1"
}) {
  const positionClass = useMemo(() => {
    const positions = {
      top: "top",
      right: "right",
      bottom: "bottom",
      left: "left",
    };
    if (position && typeof position === "string" && positions[position]) return positions[position];

    return positions.top;
  }, [position]);

  return (
    <div className={"tooltip__wrapper__cooper"}>
      {children({ className: "tooltip__initiator__cooper" })}
      <div
        className={`tooltip__cooper tooltip__cooper__${positionClass} ${
          tooltipClassName && typeof tooltipClassName === "string" ? tooltipClassName : ""
        }`}
        style={{ maxWidth: `calc(${tooltipMaxWidth} + var(--spacing-md)*2)` }}
      >
        <span className={`tooltip__arrow__cooper tooltip__arrow__cooper__${positionClass}`} />
        <div
          className={"tooltip__text__cooper"}
          style={{ maxWidth: `${tooltipMaxWidth}`, textAlign: tooltipTextAlign }}
        >
          <Text
            variant={tooltipTextVariant}
            attributes={{
              style: { color: "var(--rs-color-white)" },
            }}
          >
            {text}
          </Text>
        </div>
      </div>
    </div>
  );
}

export default Tooltip;
