import { Button, Dismissible, Modal, Text, View } from "reshaped";

import useTranslation from "components/hooks/useTranslation";
import settings from "components/styles/reshaped/settings.module.css";

const ConfirmationModal = ({
  active,
  deactivate,
  acceptText = "Accept",
  rejectText = "Cancel",
  title = "Confirm",
  subTitle = "Are you sure?",
  buttonsPosition = "center",
  handleAction,
  colorVariant = "critical",
}) => {
  const { t } = useTranslation();

  const handleConfirmation = (action) => {
    if (handleAction) return handleAction(action);
    deactivate();
  };

  return (
    <Modal
      className={settings.modal}
      active={active}
      padding={0}
      onClose={deactivate}
      size={{ s: "auto", m: "500px" }}
    >
      <View padding={4} backgroundColor={colorVariant} align="center" direction="row">
        <View.Item grow={true}>
          <Text color="white" variant="body-strong-1" align="center">
            {title}
          </Text>
        </View.Item>
        <View className={settings.modal__close}>
          <Dismissible onClose={() => handleConfirmation("reject")} closeAriaLabel="Close modal" />
        </View>
      </View>
      <View padding={4} gap={2}>
        <Text color="neutral" variant="body-1" align="center">
          {subTitle}
        </Text>
        <View direction="row" justify={buttonsPosition} align="center" gap={4}>
          <Button
            variant="outline"
            onClick={() => handleConfirmation("reject")}
            attributes={{ "data-cooper": "job popup reject" }}
          >
            {t(rejectText)}
          </Button>
          <Button
            color={colorVariant}
            attributes={{ "data-cooper": "job popup accept" }}
            onClick={() => handleConfirmation("accept")}
          >
            {t(acceptText)}
          </Button>
        </View>
      </View>
    </Modal>
  );
};

export default ConfirmationModal;
