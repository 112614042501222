import { View, Text } from "reshaped";
import useTranslation from "components/hooks/useTranslation";

export const RegisterConfirm = () => {
  const { t } = useTranslation();

  return (
    <View height="100%" width="100%" align="center" padding={[0, 6]} justify="center">
      <View align="center" justify="center" maxWidth="500px" width="100%" gap={1}>
        <Text color="primary" align="center" variant="title-2" as="h1">
          {t("Thank You for Signing Up to Cooperhire")}
        </Text>
        <Text align="center" variant="body-medium-1" as="p">
          {t(
            "We’ve just sent you an account activation link. Please check your email and your spam folder to get started. Welcome aboard!"
          )}
        </Text>
      </View>
    </View>
  );
};
