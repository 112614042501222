import { Button, Image, Text, View } from "reshaped";

import Work from "assets/images/Work";
import { useNavigate } from "react-router-dom";
import useSpacing from "components/hooks/useSpacing";
import useTranslation from "components/hooks/useTranslation";

export const DashboardInboxOverview = (props) => {
  const { count = 0 } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { sm, md, lg } = useSpacing();

  const handleMoreDetailsNavigation = () => {
    navigate("/candidates");
  };

  return (
    <View
      borderRadius="medium"
      padding={lg}
      direction="row"
      width="100%"
      backgroundColor="white"
      gap={md}
      align="center"
    >
      <View align="center" justify="center">
        <Image src={`data:image/webp;base64,${Work}`} alt="work" width="150px" height="105px" />
      </View>
      <View gap={sm} align="start">
        <Text variant="body-1">
          {t("You received")} {count} {t(`new applicant${count !== 1 ? "s" : ""} this week`)}
        </Text>
        <Button size="small" variant="ghost" color="primary" onClick={handleMoreDetailsNavigation}>
          {t("More Details")}
        </Button>
      </View>
    </View>
  );
};
