import { View } from "reshaped";
import { NormalStar } from "icons";
import { accent } from "utils/colors/accents";
import useSpacing from "components/hooks/useSpacing";

export const Rating = ({
  maxPoints = 5,
  pointsSecured = 0,
  noRatingFillColor = "#D1D1D1",
  ratingFillColor = accent["1-500"],
  ratingEmptyColor = "#D1D1D1",
  size = "15",
}) => {
  const { sm } = useSpacing();

  const points = Array.from(Array(maxPoints + 1).keys());
  points.shift();

  if (!pointsSecured || pointsSecured === 0) {
    return (
      <View direction="row" gap={sm} attributes={{style: {display: "flex", flexWrap:"nowrap"}}}>
        {points.map((point) => {
          return (
            <NormalStar
              key={point}
              size={size}
              fillColor={noRatingFillColor}
              strokeColor={noRatingFillColor}
            />
          );
        })}
      </View>
    );
  }

  return (
    <View gap={sm} direction="row" attributes={{style: {display: "flex", flexWrap:"nowrap"}}}>
      {points.map((point) => {
        if (point <= pointsSecured) {
          return (
            <NormalStar
              key={point}
              strokeColor={ratingFillColor}
              size={size}
              fillColor={ratingFillColor}
            />
          );
        } else {
          return (
            <NormalStar
              key={point}
              strokeColor={ratingEmptyColor}
              fillColor={ratingEmptyColor}
              size={size}
            />
          );
        }
      })}
    </View>
  );
};
