import { useEffect, useState } from "react";
import { Button, Modal, useToggle, View, Text } from "reshaped";

import { Cross, Reload } from "icons";
import { useNavigate } from "react-router-dom";
import { baseColor } from "utils/colors/accents";
import useSpacing from "components/hooks/useSpacing";
import { useDispatch, useSelector } from "react-redux";
import useTranslation from "components/hooks/useTranslation";
import settings from "components/styles/reshaped/settings.module.css";
import { bookingsSlice, fetchInventory } from "redux/bookings/bookingsSlice";
import styles from "components/styles/reshaped/bookings/bookings.module.css";
import {
  ConfirmJobPromotion,
  InventoryHeading,
  InventoryRow,
  SelectJobPromotion,
} from "components/pages/bookings/index";

export const BookingsInventory = () => {
  const [promotingJob, setPromotingJob] = useState(null);
  const [promotingSlot, setPromotingSlot] = useState(null);
  const [promotingStage, setPromotingStage] = useState("job");
  const [promotingInProgress, setPromotingInProgress] = useState(false);

  const { lg } = useSpacing();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { active, deactivate, activate } = useToggle();
  const { inventory, error } = useSelector((state) => state.bookings);

  useEffect(() => {
    if (!inventory && !error.inventory) {
      dispatch(fetchInventory());
    }

    //eslint-disable-next-line
  }, []);

  const handleModalClose = ({ hardReset = true }) => {
    deactivate();
    dispatch(bookingsSlice.actions.clearJobs());
    if (hardReset) {
      setTimeout(() => {
        setPromotingStage("job");
        setPromotingInProgress(false);
        setPromotingJob(null);
      }, 200);
    }
  };

  const handleReloadInventory = () => {
    dispatch(fetchInventory());
  };

  const handleRedirect = () => {
    navigate("/promote");
  };

  return (
    <>
      {error && error.inventory ? (
        <View gap={lg} align="center" justify="center" height="100%">
          <Text color="critical" variant="body-strong-1">
            {t(error.inventory)}
          </Text>
          <Button
            color="critical"
            startIcon={() => <Reload fill={baseColor.white} />}
            onClick={handleReloadInventory}
          >
            {t("Reload inventory")}
          </Button>
        </View>
      ) : (
        <>
          <View
            // maxWidth="1300px"
            gap={lg}
            height="100%"
            attributes={{
              style: {
                paddingBlockEnd: "var(--spacing-xxl)",
              },
            }}
          >
            {Array.isArray(inventory) && inventory.length > 0 ? (
              <>
                <InventoryHeading />
                {inventory.map((purchasedSlot, index) => {
                  return (
                    <InventoryRow
                      key={purchasedSlot?.slot_mgt_id || index}
                      purchasedSlot={purchasedSlot}
                      {...{
                        promotingInProgress,
                        setPromotingInProgress,
                        promotingSlot,
                        setPromotingSlot,
                      }}
                      {...{ active, deactivate, activate }}
                    />
                  );
                })}
              </>
            ) : (
              <View gap={lg} align="center" justify="center" height="100%">
                <Text variant="body-strong-1" color="neutral-faded">
                  {t("Your inventory is empty!")}
                </Text>
                <Button color="primary" onClick={handleRedirect}>
                  {t("Explore Job Boards")}
                </Button>
              </View>
            )}
          </View>
          <Modal
            className={`${settings.modal} ${styles.promote__modal}`}
            active={active}
            onClose={() => handleModalClose({ hardReset: false })}
          >
            <Button
              className={`${settings.modal__close__btn}`}
              size="small"
              variant="ghost"
              startIcon={() => <Cross fill={baseColor.black} />}
              onClick={handleModalClose}
            />
            {promotingStage === "job" && (
              <SelectJobPromotion
                {...{
                  handleModalClose,
                  promotingSlot,
                  promotingStage,
                  setPromotingStage,
                  promotingJob,
                  setPromotingJob,
                }}
              />
            )}
            {promotingStage === "confirm" && (
              <ConfirmJobPromotion
                {...{
                  handleModalClose,
                  promotingSlot,
                  promotingStage,
                  setPromotingStage,
                  promotingJob,
                  setPromotingJob,
                }}
              />
            )}
          </Modal>
        </>
      )}
    </>
  );
};
