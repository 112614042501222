import { FeedbackButton } from "components/reusables/layouts";

export function PrivateRootWrapper({ children }) {
  return (
    <>
      {children}
      <FeedbackButton />
    </>
  );
}
