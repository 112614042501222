import React, { useEffect } from 'react';
import useName from 'components/hooks/useName';
import Scrollbars from 'react-custom-scrollbars-2';
import useSpacing from 'components/hooks/useSpacing';
import { useDispatch, useSelector } from 'react-redux';
import { ShowToastWithTranslation } from 'utils/showToast';
import useTranslation from 'components/hooks/useTranslation';
import { ArrowDown, Cross, UnfollowUser, FollowUser } from 'icons';
import styles from "components/styles/reshaped/candidates.module.css";
import { SuccessfulToast } from 'components/reusables/tab/SuccessfulToast';
import { OperationFailedWithTranslation, ReshapedLoader } from 'components/reusables';
import { Modal, View, Text, Divider, Avatar, Button, DropdownMenu, useToast } from 'reshaped';
import { fetchUnassignedRecruiters, followCandidate, unfollowCandidate } from 'redux/viewingCandidate/viewingCandidateSlice';

const AssignFollowersModal = ({ active, handleModalClose, deactivate, candidateId }) => {
  const dispatch = useDispatch();
  const { sm, md, lg } = useSpacing();
  const { followers, unassignedRecruiters, fetchingUnassignedRecruiters, followingLoading } = useSelector((state) => state.viewingCandidate);
  const { t } = useTranslation();

  const toast = useToast();
  useEffect(() => {
    if (active) {
      dispatch(fetchUnassignedRecruiters(candidateId));
    }
  }, [active, dispatch, candidateId]);

  const handleFollow = async (recruiterId) => {
    try {
      await dispatch(followCandidate({ recruiterId, candidateId })).unwrap();
      ShowToastWithTranslation({
        toast,
        Children: SuccessfulToast,
        text: t("Assigned successfully!"),
      });
    } catch (error) {
      ShowToastWithTranslation({
        toast,
        Children: OperationFailedWithTranslation,
        text: t("Failed to Assign!"),
      });
    }
  };

  const handleUnfollow = async (recruiterId) => {
    try {
      await dispatch(unfollowCandidate({ recruiterId, candidateId })).unwrap()
      ShowToastWithTranslation({
        toast,
        Children: SuccessfulToast,
        text: t("Unassigned successfully!"),
      });
    } catch (error) {
      ShowToastWithTranslation({
        toast,
        Children: OperationFailedWithTranslation,
        text: t("Failed to Unassign!"),
      });
    }
  };

  return (
    <Modal
      size={{
        s: "350px",
        m: "500px",
      }}
      active={active}
      onClose={handleModalClose}
    >
      <View padding={[sm, lg]}>
        <View direction={'row'} align={"center"} className={styles.modal__title_wrapper}>
          <Text variant='title-3'>{followers.length} Followers</Text>
          <View
            attributes={{
              style: { cursor: "pointer" },
              onClick: deactivate,
              "aria-label": "Close modal"
            }}>
            <Cross fill="black" width={24} height={24} />
          </View>
        </View>
      </View>

      <Scrollbars style={{ height: "500px" }} universal={true}>
        <View padding={[sm, lg]} className={styles.modal__wrapper}>
          {/* Followers */}
          <View gap={md} direction={'column'}>
            {followers.map((follower) => (
              <FollowerCard key={follower.id} follower={follower} onUnfollow={handleUnfollow} followingLoading={followingLoading} />
            ))}
          </View>

          <Divider />

          {fetchingUnassignedRecruiters && <ReshapedLoader size={"medium"} />}
          <View padding={[md, 0]}>
            <Text variant='caption-2' color='neutral-faded'>{t("Assign others to track this candidate.")}</Text>
          </View>

          {/* Assign section */}
          {!fetchingUnassignedRecruiters  && <View gap={md} direction={'column'}>
            {unassignedRecruiters?.map((recruiter) => (
              <NonfollowerCard key={recruiter.id} recruiter={recruiter} onFollow={handleFollow} followingLoading={followingLoading} />
            ))}
          </View>}
        </View>
      </Scrollbars>
    </Modal>
  );
};

export default AssignFollowersModal;

const FollowerCard = ({ follower, onUnfollow, followingLoading }) => {
  const { md } = useSpacing();
  const { t } = useTranslation();
  const { letters, fullName } = useName(follower?.recruiter);

  return (
    <View>
      <View gap={md} direction={'row'} padding={[md, 0]} className={styles.modal__user_box}>
        <View direction={"row"} align={"center"} gap={md}>
          {follower?.recruiter.logo?.url && (
            <Avatar size={10} src={follower?.recruiter.logo?.url} alt={fullName} />
          )}
          {!follower?.recruiter.logo?.url && (
            <Avatar size={10} initials={letters} alt={fullName} />
          )}
          <View direction={"column"}>
            <Text variant="body-strong-2">{fullName}</Text>
            {follower?.recruiter?.position ? <Text variant='caption-1' color='neutral-faded'>{follower?.recruiter?.position}</Text> : <Text variant='caption-1' color='neutral-faded'>{follower?.recruiter?.email}</Text>}
          </View>
        </View>
        <View>
          <DropdownMenu width='fit-content' position="bottom-start">
            <DropdownMenu.Trigger>
              {(attributes) => <Button loading={followingLoading[follower?.recruiter?.id]} variant='outline' endIcon={ArrowDown} attributes={attributes}>{t("Following")}</Button>}
            </DropdownMenu.Trigger>
            <DropdownMenu.Content>
              <DropdownMenu.Item icon={UnfollowUser} onClick={() => onUnfollow(follower.recruiter.id)}>
                <Text color='critical'>{t("Unassign") }</Text>
              </DropdownMenu.Item>
            </DropdownMenu.Content>
          </DropdownMenu>
        </View>
      </View>
    </View>
  );
};

const NonfollowerCard = ({ recruiter, onFollow, followingLoading }) => {
  const { md } = useSpacing();
  const { t } = useTranslation();
  const { letters, fullName } = useName(recruiter);

  return (
    <View>
      <View gap={md} direction={'row'} padding={[md, 0]} className={styles.modal__user_box}>
        <View direction={"row"} align={"center"} gap={md}>
          {recruiter.logo?.url && (
            <Avatar size={10} src={recruiter.logo?.url} alt={fullName} />
          )}
          {!recruiter.logo?.url && (
            <Avatar size={10} initials={letters} alt={fullName} />
          )}
          <View direction={"column"}>
            <Text variant="body-strong-2">{fullName}</Text>
            {recruiter?.position ? <Text variant='caption-1' color='neutral-faded'>{recruiter?.position}</Text> : <Text variant='caption-1' color='neutral-faded'>{recruiter?.email}</Text>}
          </View>
        </View>
        <View>
          <Button startIcon={FollowUser} color='primary' loading={followingLoading[recruiter.id]} onClick={() => onFollow(recruiter.id)}>{t("Assign")}</Button>
        </View>
      </View>
    </View>
  );
};
