import { useState, useEffect } from "react";
import { Link, Text, useScrollLock, View } from "reshaped";

import { metaSlice } from "redux/meta";
import { useNavigate } from "react-router-dom";
import { candidateSlice } from "redux/candidate";
import useSpacing from "components/hooks/useSpacing";
import { useDispatch, useSelector } from "react-redux";
import useTranslation from "components/hooks/useTranslation";
import { areKeysAvailableWithType } from "utils/miniHelpers";
import { fetchFilteredCandidates } from "utils/pages/candidate";
import useUserLanguagePreference from "components/hooks/useUserLanguagePreference";
import { CandidateModal, ReshapedLoader, PaginationBoxes } from "components/reusables";
import {
  CandidatesTableRow,
  ProfileModalContent,
  CandidatesTableHeading,
} from "components/pages/candidates";
import { fetchRecruiters } from "redux/candidate/candidateSlice";

export const AllCandidates = ({ candidatesPageRef }) => {
  const [openModal, setOpenModal] = useState(false);
  const [viewingCandidateId, setViewingCandidateId] = useState("");

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { md, lg, xl } = useSpacing();
  const state = useSelector((state) => state);
  const userLang = useUserLanguagePreference();
  const { lockScroll, unlockScroll } = useScrollLock();

  const { user } = state.auth;
  const { isCandidateModalOpen } = state.meta;
  const { candidates: candidatesFilters } = state.filters;
  const { candidates: candidatesSorting } = state.sorting;
  const {
    candidates,
    pageCount,
    totalCandidates,
    isFiltering,
    filteringError,
    shouldCandidatesRefetch,
    recruiters,
    recruitersLoading,
    recruitersError 
  } = state.candidate;

  const { jobs, stages, search } = candidatesFilters;
  const { selected: selectedJobs } = jobs;
  const { selected: selectedStages } = stages;
  const { selected: selectedSearches } = search;

  const { setCandidateModalOpen } = metaSlice.actions;

  useEffect(() => {
    if (recruiters.length === 0) {
      dispatch(fetchRecruiters());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recruiters, recruitersLoading]);

  useEffect(() => {
    if (!isCandidateModalOpen) {
      if (shouldCandidatesRefetch) {
        async function refetch() {
          dispatch(
            candidateSlice.actions.set({
              isFiltering: true,
              filteringError: null,
              shouldCandidatesRefetch: false,
            })
          );

          const response = await fetchFilteredCandidates({
            company_id: user.company_id,
            selectedJobs,
            selectedStages,
            sorting: candidatesSorting,
            from: pageCount,
            current_language: userLang,
            selectedSearches: selectedSearches
          });

          if (areKeysAvailableWithType({ object: response, keys: [{ data: "object" }] })) {
            const { candidates, totalCandidates } = response.data;
            return dispatch(
              candidateSlice.actions.set({
                isFiltering: false,
                candidates,
                totalCandidates,
              })
            );
          }

          dispatch(
            candidateSlice.actions.set({
              isFiltering: false,
              filteringError: "something went wrong!",
            })
          );
        }
        refetch();
      }
      unlockScroll();
      setOpenModal(false);
    }
    //eslint-disable-next-line
  }, [isCandidateModalOpen]);

  const handleOpenModal = () => {
    window.history.pushState({ id: 1 }, null, "");
    lockScroll();
    setOpenModal(true);
    dispatch(setCandidateModalOpen(true));
  };

  const handleCloseModal = () => {
    unlockScroll();
    navigate("/candidates");
    dispatch(setCandidateModalOpen(false));
  };

  const handleViewingCandidate = (id) => {
    setViewingCandidateId(id);
    navigate(`/candidates?id=${id}`);
  };

  const modalProps = { openModal, setOpenModal };
  const viewingCandidateProps = { viewingCandidateId, setViewingCandidateId };

  const handlePageChange = async (next) => {
    if (
      candidatesPageRef &&
      candidatesPageRef.current &&
      candidatesPageRef.current instanceof HTMLDivElement
    ) {
      candidatesPageRef.current.scrollIntoView();
    }

    dispatch(
      candidateSlice.actions.set({
        isFiltering: true,
        filteringError: null,
      })
    );

    const response = await fetchFilteredCandidates({
      company_id: user.company_id,
      selectedJobs,
      selectedStages,
      sorting: candidatesSorting,
      from: next,
      current_language: userLang,
      selectedSearches: selectedSearches
    });

    if (areKeysAvailableWithType({ object: response, keys: [{ data: "object" }] })) {
      const { candidates, totalCandidates } = response.data;
      return dispatch(
        candidateSlice.actions.set({
          pageCount: next,
          isFiltering: false,
          candidates,
          totalCandidates,
        })
      );
    }

    dispatch(
      candidateSlice.actions.set({
        pageCount: next,
        isFiltering: false,
        filteringError: "something went wrong!",
      })
    );
  };

  const handleRetry = async () => {
    dispatch(
      candidateSlice.actions.set({
        isFiltering: true,
        filteringError: null,
      })
    );

    const response = await fetchFilteredCandidates({
      company_id: user.company_id,
      selectedJobs,
      selectedStages,
      sorting: candidatesSorting,
      from: pageCount,
      current_language: userLang,
      selectedSearches: selectedSearches
    });

    if (areKeysAvailableWithType({ object: response, keys: [{ data: "object" }] })) {
      const { candidates, totalCandidates } = response.data;
      return dispatch(
        candidateSlice.actions.set({
          isFiltering: false,
          candidates,
          totalCandidates,
        })
      );
    }

    dispatch(
      candidateSlice.actions.set({
        isFiltering: false,
        filteringError: "something went wrong!",
      })
    );
  };

  return (
    <View
      height="100%"
      maxWidth="1300px"
      gap={xl + lg}
      attributes={{
        style: {
          paddingBlockEnd: "var(--spacing-xxl)",
        },
      }}
    >
      <View gap={md} height="100%">
        <CandidatesTableHeading />
        {!filteringError && !recruitersError && (
          <>
            {isFiltering && <ReshapedLoader animationSpeed={"1s"} />}
            {!isFiltering && (
              <>
                {candidates.length > 0 && (
                  <>
                    {candidates.map((candidate) => {
                      // if (index >= 2) return null;
                      return (
                        <CandidatesTableRow
                          handleOpenModal={handleOpenModal}
                          key={candidate.id}
                          candidate={candidate}
                          handleViewingCandidate={handleViewingCandidate}
                        />
                      );
                    })}
                  </>
                )}
                {candidates.length <= 0 && (
                  <View align="center" justify="center" height="100%" padding={[xl, 0]}>
                    <Text variant="title-3" color="neutral-faded">
                      {t("No candidates found!")}
                    </Text>
                  </View>
                )}
              </>
            )}
          </>
        )}

        {recruitersError && (
          <View align="center" justify="center" height="100%" padding={[xl, 0]} gap={md}>
            <Text variant="title-3" color="critical">
              {t("Error loading recruiters data")}
            </Text>
            <View direction="row" align="center" justify="center" gap={md}>
              <Text color="critical">
                {recruitersError}{" "}
                <Link color="critical" onClick={() => dispatch(fetchRecruiters())}>
                  {t("Please try again.")}
                </Link>{" "}
              </Text>
            </View>
          </View>
        )}

        {filteringError && !isFiltering && (
          <View align="center" justify="center" height="100%" padding={[xl, 0]} gap={md}>
            <Text variant="title-3" color="critical">
              {filteringError}
            </Text>
            <View direction="row" align="center" justify="center" gap={md}>
              <Text color="critical">
                {t("Please refresh the page or")}{" "}
                <Link color="critical" onClick={handleRetry}>
                  {t("Please try again.")}
                </Link>{" "}
              </Text>
            </View>
          </View>
        )}
      </View>
       {!isFiltering && candidates.length > 0 && !filteringError && (
        <PaginationBoxes
          perPage={15}
          activePageIndex={pageCount}
          totalCandidates={totalCandidates}
          changeHandler={handlePageChange}
          title={"Candidate"}
        />
      )}
      <CandidateModal open={openModal} handleClose={handleCloseModal} overlayColor="#fff">
        <ProfileModalContent
          {...viewingCandidateProps}
          {...modalProps}
          candidates={candidates}
          handleClose={handleCloseModal}
          appendIDWithURL
        />
      </CandidateModal>
    </View>
  );
};
