export function lookForReplacementText(text = "") {
  const replacements = {
    "Language Application Form is required": "Application form language is required",
    "Enter valid Email": "Enter a valid E-mail address",
    "Enter valid Password": "Enter a valid password",
    "Enter valid New Password": "Enter a valid new password",
    "Not a country": "Please choose a country from the list",
  };
  return replacements[text] ? replacements[text] : text;
}
