import { useEffect, useState, useRef, useMemo } from "react";
import { Button, Text, useToast, View } from "reshaped";

import { MirrorScreen } from "icons";
import { backendAPI } from "utils/axios";
import { sortingSlice } from "redux/sorting";
import { filtersSlice } from "redux/filters";
import { jobSlice } from "redux/job/jobSlice";
import { bookingsSlice } from "redux/bookings";
import { dashboardSlice } from "redux/dashboard";
import { candidateSlice } from "redux/candidate";
import { sanitizeWord } from "utils/validateError";
import { useDispatch, useSelector } from "react-redux";
import { reloadOnUnauthorized } from "utils/checkForAuth";
import { areKeysAvailableWithType } from "utils/miniHelpers";
import useTranslation from "components/hooks/useTranslation";
import EditJob from "components/pages/jobs/updateJob/EditJob";
import { ContainerWrapper } from "components/reusables/common";
import { useNavigate, useSearchParams } from "react-router-dom";
import { lookForReplacementText } from "utils/replacementsTexts";
import styles from "components/styles/reshaped/jobs/new.module.css";
import usePublicCountries from "components/hooks/usePublicCountries";
import { ShowToast, ShowToastWithTranslation } from "utils/showToast";
import { EditorStateToHTML, EditorStateToText } from "utils/EditorHelpers";
import { FillRequiredFields, FillValidInput } from "pages/recruiter/jobs/New";
import { ReshapedLoader, LoadingOverlay, OperationFailed } from "components/reusables";

const UpdateJob = () => {
  const [foundJob, setFoundJob] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);

  const toast = useToast();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const Dispatch = useDispatch();
  const navigate = useNavigate();
  const optionsRef = useRef(null);
  const [searchParams] = useSearchParams();
  const state = useSelector((state) => state);
  const { getCountryCode, getCountryName } = usePublicCountries();

  const { jobs, loading } = state.job;

  useEffect(() => {
    const id = searchParams.get("id");
    if (!id || !jobs || !Array.isArray(jobs) || jobs.length <= 0) return navigate("/jobs");

    const fetchedJob = jobs.find((job) => job.id === Number(id));
    if (!fetchedJob) return navigate("/jobs");

    setFoundJob(fetchedJob);

    //eslint-disable-next-line
  }, []);

  const translations = useMemo(() => {
    return {
      Cancel: t("Cancel"),
      Update: t("Update"),
    };

    //eslint-disable-next-line
  }, []);

  const { startLoading, setError, setAPIError, updateJob } = jobSlice.actions;

  const currentStepProps = { currentStep, setCurrentStep };

  function sanitizedState(state) {
    const sanitizedState = { ...state };

    // sanitizing salary
    if (sanitizedState.max_salary === "") {
      sanitizedState.salary_kind = "single";
      delete sanitizedState["max_salary"];
    }
    if (sanitizedState.max_salary !== 0 && sanitizedState.max_salary) {
      sanitizedState.salary_kind = "range";
    }
    if (sanitizedState.min_salary !== "" || sanitizedState.max_salary !== "") {
      if (sanitizedState.salary_type === "") sanitizedState.salary_type = "year";
      if (sanitizedState.currency === "") sanitizedState.currency = "EUR";
    }
    if (!sanitizedState.min_salary && !sanitizedState.max_salary) {
      delete sanitizedState["max_salary"];
      delete sanitizedState["min_salary"];
      delete sanitizedState["currency"];
      delete sanitizedState["salary_type"];
      delete sanitizedState["salary_kind"];
    }

    for (let key in sanitizedState) {
      sanitizedState[key] = sanitizedState[key].replace(/^\s+|\s+$/, "");
      if (sanitizedState[key] === "") delete sanitizedState[key];
    }

    //sanitizing remote_job
    if (sanitizedState.remote_job) {
      sanitizedState.remote_job === "yes"
        ? (sanitizedState.remote_job = true)
        : (sanitizedState.remote_job = false);
    }

    return sanitizedState;
  }

  function sanitizedEditorState({ state, fields = [] }) {
    const sanitizedState = { ...state };
    const length = fields.length;
    for (let i = 0; i < length; i++) {
      const key = fields[i];
      const editorState = state[key];
      if (!editorState) continue;
      const onlyText = EditorStateToText(editorState);
      if (!onlyText) delete sanitizedState[key];
      if (onlyText) sanitizedState[key] = EditorStateToHTML(sanitizedState[key]);
    }
    return sanitizedState;
  }

  const handleOtherStates = () => {
    dispatch(candidateSlice.actions.reset());
    dispatch(dashboardSlice.actions.reset());
    dispatch(jobSlice.actions.set({ mini_jobs: null }));
    dispatch(sortingSlice.actions.resetPage("candidates"));
    dispatch(bookingsSlice.actions.set({ activePastBookings: null }));
    dispatch(
      filtersSlice.actions.resetPageFilter({ page: "candidates", types: ["jobs", "stages"] })
    );
  };

  const editJob = async (state, id) => {
    const stateWithHTML = sanitizedEditorState({
      state,
      fields: ["anything_else", "benefits", "requirements", "short_intro", "tasks"],
    });
    const job = sanitizedState(stateWithHTML);

    if (areKeysAvailableWithType({ object: job, keys: [{ country: "string" }] })) {
      const country = getCountryName({ country: job.country, defaultValue: "" });

      if (!country) return;

      job.country = country;

      const country_code = getCountryCode({ country: job.country });

      if (!country_code) return;

      job.country_code = country_code;
    }

    try {
      Dispatch(startLoading());
      const response = await backendAPI.put(`/jobs/${id}`, { job });
      if (response && response.status === 200) {
        const { data } = response;
        if (data.errors) {
          const error = {};
          for (let key in data.errors) {
            if (key === "user" || key === "company") continue;
            error[key] = data.errors[key][0];
          }
          Dispatch(setError(error));
          ShowToastWithTranslation({
            toast,
            Children: FillValidInput,
            text: t("Please enter required fields or valid inputs!"),
          });
          return;
        }
        if (data.job && data.job.id) {
          Dispatch(updateJob({ job: data.job, statusChanged: false }));
          handleOtherStates();
          navigate("/jobs");
          ShowToastWithTranslation({
            toast,
            Children: JobUpdated,
            text: t("Job updated successfully!"),
          });

          return;
        }
      }
      ShowToast({ toast, Children: OperationFailed });
      Dispatch(setAPIError("Something went wrong"));
      return;
    } catch (error) {
      reloadOnUnauthorized(error);
      ShowToast({ toast, Children: OperationFailed });
      Dispatch(setAPIError("Something went wrong"));
      return;
    }
  };

  const handleSubmit = () => {
    let hasError = false;
    const { errors, setErrors, state } = optionsRef.current;

    for (let field in errors) {
      if (errors[field]) {
        hasError = true;
        if (typeof errors[field] === "object") {
          setErrors((previousError) => {
            return {
              ...previousError,

              //eslint-disable-next-line
              [field]: t(lookForReplacementText(`${sanitizeWord(field, true)} is required`)),
            };
          });
        }
      }
    }

    if (hasError) {
      ShowToastWithTranslation({
        toast,
        Children: FillRequiredFields,
        text: t("Please enter required fields!"),
      });
      return;
    }

    editJob(state, foundJob?.id);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    Dispatch(setError(null));
    navigate("/jobs");
  };

  return (
    <ContainerWrapper extraClass={styles.container__wrapper}>
      {loading && (
        <LoadingOverlay>
          <ReshapedLoader />
        </LoadingOverlay>
      )}
      <>
        <EditJob
          {...currentStepProps}
          oldState={foundJob}
          handleSubmit={handleSubmit}
          ref={optionsRef}
        />
        {currentStep === 1 && (
          <View gap={4} className={styles.submit} direction="row">
            <Button onClick={handleCancel} color="critical" variant="outline">
              {translations.Cancel}
            </Button>
            <Button
              onClick={handleSubmit}
              startIcon={MirrorScreen}
              color="primary"
              attributes={{ "data-cooper": "job update button" }}
            >
              {translations.Update}
            </Button>
          </View>
        )}
      </>
      {loading && <ReshapedLoader withNav />}
    </ContainerWrapper>
  );
};

export default UpdateJob;

const JobUpdated = ({ children }) => {
  return (
    <Text color="positive" variant="body-strong-1">
      {children}
    </Text>
  );
};
