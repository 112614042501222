import { Text, View } from "reshaped";
import useSpacing from "components/hooks/useSpacing";
import useTranslation from "components/hooks/useTranslation";

export const CandidatesTitleGroup = () => {
  const { sm } = useSpacing();
  const { t } = useTranslation();

  return (
    <View direction="row" align="center" gap={sm}>
      <Text variant="title-2">{t("Candidates")}</Text>
      <Text variant="body-1" color="neutral-faded">
        {t("Candidate list")}
      </Text>
    </View>
  );
};
