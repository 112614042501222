import { backendAPI } from "utils/axios";
import { reloadOnUnauthorized } from "utils/checkForAuth";
import { areKeysAvailableIn, areKeysAvailableWithType } from "utils/miniHelpers";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// initialState template (refer this comment text below for full viewing candidate state)

// {
//   loading: true | false,
//   currentCandidate - "current viewing candidate ID",
//   overview: {
//     candidate_id - "current viewing candidate ID",
//     evaluations - "array of evaluations (latest 4 evaluations)",
//     notes - "array of notes (latest 3 notes)",
//   },
//   notes: {
//     candidate_id - "current viewing candidate ID",
//     data - "array of notes"
//   },
//   evaluations: {
//     candidate_id - "current viewing candidate ID",
//     data - "array of evaluations"
//   },
//   evaluationsError - "error string for evaluations error",
//   notesError - "error string for notes error"
// }

const initialState = {
  loading: false,
  currentCandidate: null,
  overview: null,
  evaluations: null,
  notes: null,
  error: null,
  evaluationsError: null,
  notesError: null,
  followers: [],
  unassignedRecruiters: [],
  fetchingUnassignedRecruiters: false,
  followingLoading: {},
  followingError: null,
};

export const fetchCandidateOverview = createAsyncThunk("candidateOverview/fetch", async (id) => {
  try {
    const response = await backendAPI.get(`/candidates/overview/${id}`);
    if (
      response.status === 200 &&
      response.data &&
      areKeysAvailableWithType({
        object: response.data.overview,
        keys: [{ candidate_id: "string" }, { evaluations: "array" }, { notes: "array" }],
      })
    ) {
      return { data: response.data.overview, id };
    }
    return {
      error: "Something went wrong",
    };
  } catch (error) {
    reloadOnUnauthorized(error);
    return {
      error: "Something went wrong",
    };
  }
});

export const fetchCandidateEvaluations = createAsyncThunk(
  "candidateEvaluations/fetch",
  async (id) => {
    try {
      const response = await backendAPI.get(`/candidates/${id}/evaluations`);
      if (
        response.status === 200 &&
        response.data &&
        areKeysAvailableWithType({
          object: response.data.evaluation_details,
          keys: [{ candidate_id: "string" }, { evaluations: "array" }],
        })
      ) {
        return { data: response.data.evaluation_details, id };
      }
      return {
        error: "Something went wrong",
      };
    } catch (error) {
      reloadOnUnauthorized(error);
      return {
        error: "Something went wrong",
      };
    }
  }
);

export const fetchCandidateNotes = createAsyncThunk("candidateNotes/fetch", async (id) => {
  try {
    const response = await backendAPI.get(`/candidates/${id}/notes`);
    if (
      response.status === 200 &&
      response.data &&
      areKeysAvailableWithType({
        object: response.data.note_details,
        keys: [{ candidate_id: "string" }, { notes: "array" }],
      })
    ) {
      return { data: response.data.note_details, id };
    }
    return {
      error: "Something went wrong",
    };
  } catch (error) {
    reloadOnUnauthorized(error);
    return {
      error: "Something went wrong",
    };
  }
});

export const fetchUnassignedRecruiters = createAsyncThunk(
  "candidate/fetchUnassignedRecruiters",
  async (candidateId) => {
    try {
      const response = await backendAPI.get(`/followers/recruiters?candidate_id=${candidateId}`);
      if (response.status === 200 && response.data) {
        return response.data;
      }
      return { error: "Failed to fetch unassigned recruiters" };
    } catch (error) {
      reloadOnUnauthorized(error);
      return { error: "Failed to fetch unassigned recruiters" };
    }
  }
);

export const followCandidate = createAsyncThunk(
  "candidate/follow",
  async ({ recruiterId, candidateId, recruiter = null }) => {
    try {
      const response = await backendAPI.post("/followers", {
        followers: {
          type: "follow",
          recruiter_id: recruiterId,
          candidate_id: candidateId,
        },
      });
      if (response.status === 200) {
        return { recruiterId, candidateId, recruiter };
      }
      // return { error: "Failed to follow candidate" };
    } catch (error) {
      reloadOnUnauthorized(error);
      return { error: "Failed to follow candidate" };
    }
  }
);

export const unfollowCandidate = createAsyncThunk(
  "candidate/unfollow",
  async ({ recruiterId, candidateId, recruiter = null }) => {
    try {
      const response = await backendAPI.post("/followers", {
        followers: {
          type: "unfollow",
          recruiter_id: recruiterId,
          candidate_id: candidateId,
        },
      });
      if (response.status === 200) {
        return { recruiterId, candidateId, recruiter };
      }
      // return { error: "Failed to unfollow candidate" };
    } catch (error) {
      reloadOnUnauthorized(error);
      return { error: "Failed to unfollow candidate" };
    }
  }
);

export const viewingCandidateSlice = createSlice({
  name: "viewingCandidate",
  initialState,
  reducers: {
    reset: (state) => {
      for (let key in initialState) {
        state[key] = initialState[key];
      }
    },
    setViewingCandidate: (state, action) => {
      state.currentCandidate = action.payload;
      state.followers = action.payload?.followers;
    },
    setLoading: (state, action) => {
      const { payload = true } = action;
      state.loading = payload;
    },
    updateStage: (state, action) => {
      const { payload } = action;
      if (state.currentCandidate) {
        state.currentCandidate.stage = payload.stage;
      }
    },
    setEvaluation: (state, action) => {
      const { evaluations, overview } = state;
      const { payload } = action;
      const { evaluation, candidate_id } = payload;
      state.loading = false;
      state.overview.evaluations = [evaluation, ...overview.evaluations];
      if (!evaluations || !Array.isArray(evaluations?.data)) {
        state.evaluations = {
          data: [evaluation],
          candidate_id,
        };
        return;
      }
      if (Array.isArray(evaluations?.data)) {
        state.evaluations = {
          data: [evaluation, ...evaluations?.data],
          candidate_id,
        };
        return;
      }
    },
    setNote: (state, action) => {
      const { notes, overview } = state;
      const { payload } = action;
      const { note, candidate_id } = payload;
      state.loading = false;
      state.overview.notes = [note, ...overview.notes];
      if (!notes || !Array.isArray(notes?.data)) {
        state.notes = {
          data: [note],
          candidate_id,
        };
        return;
      }
      if (Array.isArray(notes?.data)) {
        state.notes = {
          data: [note, ...notes?.data],
          candidate_id,
        };
        return;
      }
    },
    updateEvaluation: (state, action) => {
      const { evaluations, overview } = state;
      const { payload } = action;
      const { evaluation, candidate_id } = payload;
      state.loading = false;
      state.overview.evaluations = overview.evaluations.map((prevEvaluation) => {
        if (prevEvaluation.id === evaluation.id) return evaluation;
        return prevEvaluation;
      });
      if (Array.isArray(evaluations?.data)) {
        state.evaluations = {
          data: evaluations.data.map((prevEvaluation) => {
            if (prevEvaluation.id === evaluation.id) return evaluation;
            return prevEvaluation;
          }),
          candidate_id,
        };
        return;
      }
    },
    updateNote: (state, action) => {
      const { notes, overview } = state;
      const { payload } = action;
      const { note, candidate_id } = payload;
      state.loading = false;
      state.overview.notes = overview.notes.map((prevNote) => {
        if (prevNote.id === note.id) return note;
        return prevNote;
      });
      if (Array.isArray(notes?.data)) {
        state.notes = {
          data: notes.data.map((prevNote) => {
            if (prevNote.id === note.id) return note;
            return prevNote;
          }),
          candidate_id,
        };
        return;
      }
    },
    removeEvaluation: (state, action) => {
      const { evaluations, overview } = state;
      const { payload } = action;
      const { id, candidate_id } = payload;
      state.overview.evaluations = overview.evaluations.filter(
        (prevEvaluation) => prevEvaluation.id !== id
      );
      if (Array.isArray(evaluations?.data)) {
        state.evaluations = {
          data: evaluations.data.filter((prevEvaluation) => prevEvaluation.id !== id),
          candidate_id,
        };
        return;
      }
    },
    removeNote: (state, action) => {
      const { notes, overview } = state;
      const { payload } = action;
      const { id, candidate_id } = payload;
      state.overview.notes = overview.notes.filter((prevNote) => prevNote.id !== id);
      if (Array.isArray(notes?.data)) {
        state.notes = {
          data: notes.data.filter((prevNote) => prevNote.id !== id),
          candidate_id,
        };
        return;
      }
    },
    updateCandidate: (state, action) => {
      const { payload } = action;
      if (
        !areKeysAvailableWithType({
          object: payload,
          keys: [{ candidateID: "string" }, { data: "object" }],
        })
      )
        return;

      const { candidateID, data } = payload;

      if (
        areKeysAvailableWithType({
          object: state.currentCandidate,
          keys: [{ id: candidateID, exact: true }],
        })
      ) {
        state.currentCandidate = {
          ...state.currentCandidate,
          ...data,
        };
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCandidateOverview.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchCandidateOverview.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      const { payload } = action;
      if (payload.data) {
        state.overview = {
          evaluations: payload.data.evaluations,
          notes: payload.data.notes,
          candidate_id: payload.id,
        };
      }
      if (payload.error) {
        state.error = payload.error;
      }
    });
    builder.addCase(fetchCandidateOverview.rejected, (state) => {
      state.loading = false;
      state.overview = null;
      state.error = "Something went wrong";
    });

    builder.addCase(fetchCandidateEvaluations.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchCandidateEvaluations.fulfilled, (state, action) => {
      state.loading = false;
      state.evaluationsError = null;
      const { payload } = action;
      if (payload.data) {
        state.evaluations = {
          data: payload.data.evaluations,
          candidate_id: payload.id,
        };
      }
      if (payload.error) {
        state.evaluationsError = payload.error;
      }
    });
    builder.addCase(fetchCandidateEvaluations.rejected, (state) => {
      state.loading = false;
      state.evaluations = null;
      state.evaluationsError = "Something went wrong";
    });

    builder.addCase(fetchCandidateNotes.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchCandidateNotes.fulfilled, (state, action) => {
      state.loading = false;
      state.notesError = null;
      const { payload } = action;
      if (payload.data) {
        state.notes = {
          data: payload.data.notes,
          candidate_id: payload.id,
        };
      }
      if (payload.error) {
        state.notesError = payload.error;
      }
    });
    builder.addCase(fetchCandidateNotes.rejected, (state) => {
      state.loading = false;
      state.notes = null;
      state.notesError = "Something went wrong";
    });

    builder.addCase(fetchUnassignedRecruiters.pending, (state) => {
      state.fetchingUnassignedRecruiters = true;
    });
    builder.addCase(fetchUnassignedRecruiters.fulfilled, (state, action) => {
      state.fetchingUnassignedRecruiters = false;
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        const { recruiters } = action.payload;
        state.unassignedRecruiters = recruiters;
      }
    });
    builder.addCase(fetchUnassignedRecruiters.rejected, (state) => {
      state.fetchingUnassignedRecruiters = false;
      state.error = "Failed to fetch unassigned recruiters";
    });
    builder.addCase(followCandidate.pending, (state, action) => {
      state.followingLoading[action.meta.arg.recruiterId] = true;
      state.followingError = null;
    });
    builder.addCase(followCandidate.fulfilled, (state, action) => {
      state.followingLoading[action.meta.arg.recruiterId] = false;
      const { recruiterId, recruiter } = action.payload;
      const recruiterIndex = state.unassignedRecruiters.findIndex(rec => rec.id === recruiterId);

      if (recruiterIndex === -1 && areKeysAvailableIn({
        object: recruiter,
        keys: ["id", "first_name","email"],
      })) {
        state.followers.push({
          id: state.followers.length + 1,
          recruiter: recruiter
        });
      }

      if (recruiterIndex !== -1) {
        const recruiter = state.unassignedRecruiters[recruiterIndex];
        state.followers.push({
          id: state.followers.length + 1,
          recruiter: recruiter
        });
        state.unassignedRecruiters.splice(recruiterIndex, 1);
      }
    });
    builder.addCase(followCandidate.rejected, (state, action) => {
      state.followingLoading[action.meta.arg.recruiterId] = false;
      state.followingError = action.error.message;
    });
    builder.addCase(unfollowCandidate.pending, (state, action) => {
      state.followingLoading[action.meta.arg.recruiterId] = true;
      state.followingError = null;
    });
    builder.addCase(unfollowCandidate.fulfilled, (state, action) => {
      state.followingLoading[action.meta.arg.recruiterId] = false;
      const { recruiterId, recruiter } = action.payload;
      const followerIndex = state.followers.findIndex(f => f.recruiter.id === recruiterId);

      if (followerIndex === -1 && areKeysAvailableIn({
        object: recruiter,
        keys: ["id", "first_name", "email"],
      })) {
        state.unassignedRecruiters.push(recruiter);
        const recruiterIndex = state.followers.findIndex(f => f.recruiter.id === recruiter.id);
        state.followers.splice(recruiterIndex, 1);
      }
      
      if (followerIndex !== -1) {
        const recruiter = state.followers[followerIndex].recruiter;
        state.unassignedRecruiters.push(recruiter);
        state.followers.splice(followerIndex, 1);
      }
    });
    builder.addCase(unfollowCandidate.rejected, (state, action) => {
      state.followingLoading[action.meta.arg.recruiterId] = false;
      state.followingError = action.error.message;
    });
  },
});
