import { useEffect } from "react";
import { Button, Text, View } from "reshaped";

import { Reload } from "icons";
import { baseColor } from "utils/colors/accents";
import useSpacing from "components/hooks/useSpacing";
import { useDispatch, useSelector } from "react-redux";
import useTranslation from "components/hooks/useTranslation";
import { fetchActivePastBookings } from "redux/bookings/bookingsSlice";
import { ActivePastHeading, ActivePastRow } from "components/pages/bookings";

export const ActivePastBookings = ({ setBookingsTab }) => {
  const { lg } = useSpacing();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { activePastBookings, error } = useSelector((state) => state.bookings);

  useEffect(() => {
    if (!activePastBookings && !error.activePastBookings) {
      dispatch(fetchActivePastBookings());
    }

    //eslint-disable-next-line
  }, []);

  const handleReloadBookings = () => {
    dispatch(fetchActivePastBookings());
  };

  const handlePromote = () => {
    setBookingsTab("inventory");
  };

  return (
    <>
      {error && error.activePastBookings ? (
        <View gap={lg} align="center" justify="center" height="100%">
          <Text color="critical" variant="body-strong-1">
            {t(error.activePastBookings)}
          </Text>
          <Button
            color="critical"
            startIcon={() => <Reload fill={baseColor.white} />}
            onClick={handleReloadBookings}
          >
            {t("Reload bookings")}
          </Button>
        </View>
      ) : (
        <View
          // maxWidth="1300px"
          height="100%"
          gap={lg}
          attributes={{
            style: {
              paddingBlockEnd: "var(--spacing-xxl)",
            },
          }}
        >
          {Array.isArray(activePastBookings) && activePastBookings.length > 0 ? (
            <>
              <ActivePastHeading />
              {activePastBookings.map((booking) => {
                return (
                  <ActivePastRow
                    key={`${booking?.job_title} ${booking?.job_board_name}`}
                    booking={booking}
                  />
                );
              })}
            </>
          ) : (
            <View gap={lg} align="center" justify="center" height="100%">
              <Text variant="body-strong-1" color="neutral-faded">
                {t("Currently you do not have active and past bookings")}
              </Text>
              <Button color="primary" onClick={handlePromote}>
                {t("Promote job")}
              </Button>
            </View>
          )}
        </View>
      )}
    </>
  );
};
