import { Text, View } from "reshaped";
import register from "components/styles/reshaped/register.module.css";

export const CompanySize = (props) => {
  const {
    title,
    subTitle,
    setCategory,
    size,
    handleSubmit,
    userDetails,
    companyDetails,
    attribute,
  } = props;

  const handleCategory = (e) => {
    e.preventDefault();
    setCategory(size);
    handleSubmit(size, companyDetails, userDetails);
  };

  return (
    <View
      as="button"
      borderRadius="small"
      padding={4}
      gap={1}
      align="stretch"
      justify="center"
      borderColor="transparent"
      attributes={{ onClick: handleCategory, "data-cooper": attribute }}
      backgroundColor="neutral-faded"
      className={`${register.size__button}`}
    >
      <Text align="start" variant="title-3">
        {title}
      </Text>
      <Text align="start" variant="body-medium-1">
        {subTitle}
      </Text>
    </View>
  );
};
