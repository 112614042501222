import { cooperColors } from "utils/colors/accents";

export const LinkPin = ({ fill = cooperColors["background-primary"] }) => {
  return (
    <svg width="21" height="11" viewBox="0 0 21 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.37062 10.0883L5.12072 10.118C3.73742 10.1277 2.55487 9.64851 1.57306 8.68036C0.591255 7.71222 0.095505 6.5365 0.0858129 5.1532C0.0761208 3.7699 0.555347 2.58734 1.52349 1.60554C2.49164 0.62373 3.66736 0.127981 5.05066 0.118289L9.30055 0.0885117L9.31106 1.58847L5.06117 1.61825C4.08898 1.62506 3.26498 1.97055 2.58919 2.65472C1.91342 3.33887 1.57893 4.16624 1.58573 5.13681C1.59254 6.10737 1.9386 6.93193 2.62392 7.61047C3.30925 8.28902 4.13802 8.62489 5.11021 8.61808L9.36011 8.5883L9.37062 10.0883ZM6.21592 5.86027L6.20541 4.3603L13.9552 4.306L13.9657 5.80597L6.21592 5.86027ZM10.8706 10.0778L10.8601 8.57779L15.11 8.54802C16.0822 8.5412 16.9062 8.19571 17.5819 7.51155C18.2577 6.82739 18.5922 6.00003 18.5854 5.02945C18.5786 4.0589 18.2325 3.23434 17.5472 2.55579C16.8619 1.87725 16.0331 1.54138 15.0609 1.54819L10.811 1.57797L10.8005 0.0780022L15.0504 0.0482253C16.4337 0.0385332 17.6163 0.51776 18.5981 1.4859C19.5799 2.45405 20.0756 3.62977 20.0853 5.01307C20.095 6.39637 19.6158 7.57892 18.6476 8.56073C17.6795 9.54254 16.5038 10.0383 15.1205 10.048L10.8706 10.0778Z"
        fill={fill}
      />
    </svg>
  );
};
