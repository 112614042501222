import { useState } from "react";
import { backendAPI } from "utils/axios";
import PricingTick from "assets/images/PricingTick";
import useSpacing from "components/hooks/useSpacing";
import { Button, Text, View, useToast } from "reshaped";
import { reloadOnUnauthorized } from "utils/checkForAuth";
import { ShowToastWithTranslation } from "utils/showToast";
import useTranslation from "components/hooks/useTranslation";
import { countriesAndCurrencies } from "utils/constants";
import { OperationFailedWithTranslation } from "components/reusables";
import { planFeaturesList } from "utils/pages/settings";
import styles from "components/styles/reshaped/settings.module.css";
import { DiscountPill } from "components/reusables/DiscountPill";

const PricingCard = ({ plan, planType, subscription, companyCountryCode }) => {
  const country = companyCountryCode || "uk"; // default to uk for other countries
  const planCurrency = countriesAndCurrencies[country].code;
  const planCurrencySymbol = countriesAndCurrencies[country].symbol;
  const discount_percent = plan.pricing[planCurrency][planType].discount_percent;
  const discount_amount = plan.pricing[planCurrency][planType].discount_amount;

  const toast = useToast();
  const { t } = useTranslation();
  const { sm, md, xl } = useSpacing();

  const [checkoutLoading, setCheckoutLoading] = useState(false);

  const handleCheckout = async (plan) => {
    const plan_id = plan.pricing[planCurrency][planType].plan_id;
    const price_id = plan.pricing[planCurrency][planType].price_id;
    if (!plan_id || !price_id) return;

    setCheckoutLoading(true);
    let isFailed = false;

    if (isFailed) return setCheckoutLoading(false);

    try {
      const response = await backendAPI.post("/subscription", {
        subscription: { plan_id, price_id },
      });

      setCheckoutLoading(false);

      if (response.data && response.data.status === 200 && response.data.url) {
        return (window.location.href = response.data.url);
      }

      ShowToastWithTranslation({
        toast,
        Children: OperationFailedWithTranslation,
        text: t("Operation failed!"),
      });
    } catch (error) {
      reloadOnUnauthorized(error);
      setCheckoutLoading(false);
      ShowToastWithTranslation({
        toast,
        Children: OperationFailedWithTranslation,
        text: t("Operation failed!"),
      });
    }
  };

  return (
    <>
      <View.Item columns={4} gap={3} direction="column" key={plan.title}>
        <View
          padding={[sm, xl]}
          borderRadius="medium"
          backgroundColor="var(--rs-color-background-primary-faded)"
        >
          <View direction={"row"} align={"center"}>
            <Text
              variant="title-3"
              attributes={{
                style: {
                  marginBottom: sm,
                  marginRight: xl,
                },
              }}
            >
              {plan.title}
            </Text>
            {(!!discount_percent || !!discount_amount) ? <DiscountPill text={(!!discount_percent && `-${discount_percent}%`) || (!!discount_amount && `-${planCurrencySymbol}${discount_amount}`)} /> : null}

          </View>

          <Text
            color="neutral-faded"
            attributes={{
              style: {
                marginBottom: sm,
                minHeight: "70px",
              },
            }}
          >
            {t(plan.description)}
          </Text>
          <View direction={"row"} align={"center"}>
            {Math.floor(plan.pricing[planCurrency][planType].price !== plan.pricing[planCurrency][planType].discounted_price) ? (
              <View attributes={{
                style: {
                  marginRight: sm,
                }
              }}>
                <Text
                  align="center"
                  variant="body-strong-1"
                  color="neutral-faded"
                  className={styles.strike__text}
                  attributes={{
                    style: {
                      marginRight: md,
                    }
                  }}
                >
                  {planCurrencySymbol}{Math.floor(plan.pricing[planCurrency][planType].price) || 0}
                </Text>
              </View>) : null
            }
            <Text variant="title-1"> {planCurrencySymbol}{Math.floor(plan.pricing[planCurrency][planType].discounted_price) || 0}</Text>
            <Text
              color="neutral-faded"
              attributes={{
                style: {
                  marginLeft: "4px",
                  width: "40px",
                  height: "30px",
                  lineHeight: "1",
                },
              }}
            >
              {t("per")} {t(plan.pricing[planCurrency][planType].interval.slice(0, -2))}
            </Text>
          </View>
          {
            <Button
              color="primary"
              loading={checkoutLoading}
              attributes={{
                style: {
                  width: "100%",
                  marginTop: "12px",
                },
                // if subscription is active do nothing
                onClick: () => {
                  if (plan.title === "Free") return;
                  const isSubscribed = subscription.status === "subscribed";

                  if (!isSubscribed) {
                    handleCheckout(plan);
                  }
                },
              }}
            >
              <Text>{plan.title === "Free" ? "Free" : "Subscribe"}</Text>
            </Button>
          }
          <Text
            attributes={{
              style: {
                marginTop: "12px",
                marginBottom: "12px",
              },
            }}
          >
            {t("This includes")}:
          </Text>
          {planFeaturesList(plan.title).map((feature) => (
            <View
              direction={"row"}
              align={"center"}
              wrap={"nowrap"}
              key={feature}
              paddingBottom={2}
              gap={2}
              attributes={{
                style: {
                  flexWrap: "nowrap",
                },
              }}
            >
              <View>
                <PricingTick />
              </View>
              <Text key={feature}>{t(feature).replace("{{users_limit}}", plan.users_limit.toString()).replace("{{jobs_limit}}", plan.jobs_limit.toString())}</Text>
            </View>
          ))}
        </View>
      </View.Item>
    </>
  );
};

export default PricingCard;
