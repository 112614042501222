import { View } from "reshaped";
import { useSelector } from "react-redux";
import useSpacing from "components/hooks/useSpacing";
import useTranslation from "components/hooks/useTranslation";
import { MiniCart, Slots } from "components/pages/promote/slot";
import { TitleGroup } from "components/pages/promote/TitleGroup";

export const SlotSection = ({ board }) => {
  const { t } = useTranslation();
  const { sm, xl } = useSpacing();
  const { viewingSlots, viewingJobBoard } = useSelector((state) => state.slots);

  return (
    <View gap={xl} maxWidth="1300px">
      <TitleGroup
        title={"Products"}
        subTitle={`${t("Best plans from ")}${board.name}`}
        subTitleWithNoTranslation
      />
      <View
        direction="row"
        align="stretch"
        gap={sm + xl}
        attributes={{
          style: {
            marginBlockEnd: "var(--spacing-xxl)",
          },
        }}
      >
        <View.Item grow>
          <Slots slots={viewingSlots} jobBoard={viewingJobBoard} />
        </View.Item>
        <View>
          <MiniCart />
        </View>
      </View>
    </View>
  );
};
