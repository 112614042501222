export const formatISODateTime = (utcDateTimeStr) => {
  if (!utcDateTimeStr) {
    return "";
  }

  const date = new Date(utcDateTimeStr);

  const options = {
    hour: 'numeric',
    minute: 'numeric',
    month: 'short',
    day: 'numeric',
    year: 'numeric', 
    hour12: true
  };


  const formatter = new Intl.DateTimeFormat(undefined, options);
  const formattedDate = formatter.format(date);

  return formattedDate;
}
