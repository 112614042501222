import useWebkit from "components/hooks/useWebkit";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import styles from "components/styles/reshaped/reusables/AutoComplete.module.css";
import { areKeysAvailableWithType, isObject, sanitizeArray } from "utils/miniHelpers";

export function BasicAutoComplete({
  state,
  id = "",
  label = "",
  error = "",
  options = [],
  inputAtrributes,
  required = false,
  placeholder = "",
  hasError = false,
  handleBlur = () => {},
  handleChange = () => {},
  customClasses,
}) {
  const { isWebkit } = useWebkit();
  const [inFocus, setInFocus] = useState(false);
  const [optionIndex, setOptionIndex] = useState(-1);
  const [showDropdown, setShowDropdown] = useState(false);

  const [inHouseOptions, setInHouseOptions] = useState(() => {
    const sanitizedOptions = sanitizeArray({
      array: options,
      elementType: "string",
      outputElementStructureCallback: (value) => value,
    });
    const inputText = state.toLowerCase();
    const filteredOptions = sanitizedOptions.filter((option) =>
      option.toLowerCase().startsWith(inputText)
    );
    return filteredOptions;
  });

  useEffect(() => {
    setInHouseOptions(() => {
      const sanitizedOptions = sanitizeArray({
        array: options,
        elementType: "string",
        outputElementStructureCallback: (value) => value,
      });
      const inputText = state.toLowerCase();
      const filteredOptions = sanitizedOptions.filter((option) =>
        option.toLowerCase().startsWith(inputText)
      );
      return filteredOptions;
    });

    //eslint-disable-next-line
  }, [options]);

  const inputRef = useRef(null);
  const dropdownRef = useRef(null);

  useLayoutEffect(() => {
    function handleClick(e) {
      const target = e.target;
      if (target instanceof HTMLButtonElement && target.classList.contains(styles.option)) {
        e.preventDefault();
      }

      if (e.target === inputRef.current && !showDropdown) {
        setShowDropdown(true);
      }
    }

    if (inFocus) {
      document.addEventListener("mousedown", handleClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [inFocus, showDropdown]);

  useLayoutEffect(() => {
    function handleKeyDown(e) {
      const length = inHouseOptions.length;
      let index = optionIndex;

      if (areKeysAvailableWithType({ object: e, keys: [{ code: "string" }] })) {
        const keyPressed = e.code;
        if (["ArrowDown", "ArrowUp"].includes(keyPressed)) {
          e.preventDefault();
          if (keyPressed === "ArrowDown") {
            index = optionIndex + 1;
          }
          if (keyPressed === "ArrowUp") {
            index = optionIndex - 1;
          }

          if (index >= length) {
            index = 0;
          }

          if (index >= 0 && index < length) {
            const optionElement = document.getElementById(`autocomplete-${index}`);
            if (optionElement instanceof HTMLButtonElement) {
              scrollIntoViewIfNeeded(optionElement);
            }

            setOptionIndex(index);

            function scrollIntoViewIfNeeded(element) {
              const top = element.offsetTop;
              const bottom = top + element.offsetHeight;
              const list = dropdownRef.current;
              const listTop = list.scrollTop;
              const listBottom = listTop + list.offsetHeight;

              if (top < listTop) {
                list.scrollTop = top;
              } else if (bottom > listBottom) {
                list.scrollTop = bottom - list.offsetHeight;
              }
            }
          }
        }

        if (keyPressed === "Enter") {
          e.preventDefault();
          if (index === -1) {
            setShowDropdown(false);
          }
          const optionElement = document.getElementById(`autocomplete-${index}`);
          if (optionElement instanceof HTMLButtonElement) {
            const value = optionElement.value;
            handleOptionClick(value);
          }
        }
      }
    }

    if (showDropdown) {
      document.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };

    //eslint-disable-next-line
  }, [showDropdown, inHouseOptions, optionIndex]);

  function getCustomClassOf(property) {
    if (!property || typeof property !== "string") return "";
    if (areKeysAvailableWithType({ object: customClasses, keys: [{ [property]: "string" }] }))
      return customClasses[property];
    return "";
  }

  function handleInputChange(e) {
    const value = e.target.value;
    if (handleChange && typeof handleChange === "function") {
      handleChange(value);
    }
    const inputText = value.toLowerCase();
    const filteredOptions = options.filter((option) => option.toLowerCase().startsWith(inputText));
    setInHouseOptions(filteredOptions);
    if (!showDropdown) {
      setShowDropdown(true);
    }
    setOptionIndex(-1);
  }

  function handleOptionClick(value) {
    if (handleChange && typeof handleChange === "function") {
      handleChange(value);
    }
    setShowDropdown(false);
    setOptionIndex(-1);
  }

  function handleFocus() {
    setInFocus(true);
    setShowDropdown(true);
  }

  function handleUnfocus(e) {
    setInFocus(false);
    setShowDropdown(false);
    setOptionIndex(-1);
    if (handleBlur && typeof handleBlur === "function") {
      handleBlur(e);
    }
  }

  return (
    <div className={`${styles.wrapper} ${getCustomClassOf("wrapper")}`}>
      {label && typeof label === "string" && (
        <label
          htmlFor={id}
          className={`${styles.label} ${required ? styles.required : ""} ${getCustomClassOf(
            "label"
          )}`}
        >
          {label}
        </label>
      )}
      <div className={styles.input__wrapper}>
        <input
          id={id}
          ref={inputRef}
          className={`${styles.input} ${getCustomClassOf("input")}`}
          value={state}
          type="text"
          onChange={handleInputChange}
          onFocus={handleFocus}
          onBlur={handleUnfocus}
          placeholder={typeof placeholder === "string" ? placeholder : ""}
          {...(isObject(inputAtrributes) ? inputAtrributes : {})}
        />
        <div
          className={`${styles.input__decorator} ${getCustomClassOf("decorator")} ${
            inFocus ? styles.input__decorator__focus : ""
          } ${hasError ? styles.input__decorator__error : ""}`}
        />
        {inHouseOptions.length > 0 && (
          <div
            ref={dropdownRef}
            className={`${styles.dropdown} ${
              isWebkit ? "webkit" : "non__webkit"
            } ${getCustomClassOf("dropdown")}
       ${showDropdown ? styles.dropdown__show : ""}`}
          >
            {inHouseOptions.map((each, index) => {
              return (
                <button
                  key={each}
                  id={`autocomplete-${index}`}
                  value={each}
                  className={`${styles.option} ${getCustomClassOf("option")} ${
                    state === each ? styles.option__select : ""
                  }
                ${optionIndex === index ? styles.option__index : ""}
                `}
                  type="button"
                  onClick={() => handleOptionClick(each)}
                >
                  {each}
                </button>
              );
            })}
          </div>
        )}
      </div>
      {hasError && error && (
        <p className={`${styles.error} ${getCustomClassOf("error")}`}>{error}</p>
      )}
    </div>
  );
}
