import { View } from "reshaped";
import useSpacing from "components/hooks/useSpacing";
import { TabTitleGroup, Banner } from "components/pages/promote";

export const BannerSection = () => {
  const { md, xl } = useSpacing();

  return (
    <View gap={xl + md}>
      <View direction="row" gap={xl}>
        <View.Item grow>
          <TabTitleGroup />
        </View.Item>
        {/* <View overflow="hidden" borderRadius="medium">
          <Button startIcon={CartWhite} color="primary">
            {t("Your cart")}
          </Button>
        </View> */}
      </View>
      <Banner
        title={
          "Stay ahead in hiring the best talent by posting your job on top selected job boards locally and internationally!"
        }
      />
    </View>
  );
};
