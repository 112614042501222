import { useMemo } from "react";

const useFullName = ({ first_name, last_name, dependency }) => {
  const fullName = useMemo(
    () => {
      if (
        first_name &&
        typeof first_name === "string" &&
        last_name &&
        typeof last_name === "string"
      )
        return `${first_name} ${last_name}`;
      if (
        first_name &&
        typeof first_name === "string" &&
        (!last_name || typeof last_name !== "string")
      )
        return `${first_name}`;
      if (
        last_name &&
        typeof last_name === "string" &&
        (!first_name || typeof last_name !== "string")
      )
        return `${last_name}`;
      return "User";
    },

    // eslint-disable-next-line
    dependency ? [...dependency] : []
  );
  return fullName;
};

export default useFullName;
