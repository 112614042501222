import { Text, View } from "reshaped";
import { Evaluation, Message, Notes } from "icons";
import useSpacing from "components/hooks/useSpacing";
import useTranslation from "components/hooks/useTranslation";

export const CategoryWrapper = ({
  children,
  height = "auto",
  heading = "Evaluation",
  attributes = {},
}) => {
  const { t } = useTranslation();
  const { sm, xl } = useSpacing();
  return (
    <View
      padding={xl}
      backgroundColor="white"
      borderRadius="medium"
      height={height}
      {...attributes}
      attributes={{
        style: {
          minHeight: "220px",
        },
      }}
    >
      <View direction="row" gap={sm} align="center">
        {heading === "Evaluation" && <Evaluation />}
        {heading === "Notes" && <Notes />}
        {heading === "Messages" && <Message />}
        {heading && (
          <Text variant="body-1" color="neutral">
            {t(heading)}
          </Text>
        )}
      </View>
      {children}
    </View>
  );
};
