import { View, Text } from "reshaped";
import useTranslation from "components/hooks/useTranslation";
import register from "components/styles/reshaped/register.module.css";

export const PasswordProgress = (props) => {
  const { passwordProgress, position = "bottom-right" } = props;
  const { t } = useTranslation();

  return (
    <View gap={1} className={`${register.password__helper} ${register[`${position}`]}`} padding={4}>
      <Text variant="body-medium-1">{t("Requirements")}</Text>
      <View>
        <Text
          variant="body-2"
          decoration={`${passwordProgress.sevenCharacters ? "line-through" : ""}`}
        >
          7 {t("characters")}
        </Text>
        <Text variant="body-2" decoration={`${passwordProgress.oneNumber ? "line-through" : ""}`}>
          1 {t("number")}
        </Text>
        <Text
          variant="body-2"
          decoration={`${passwordProgress.oneLowercase ? "line-through" : ""}`}
        >
          1 {t("lowercase character")}
        </Text>
        <Text
          variant="body-2"
          decoration={`${passwordProgress.oneUppercase ? "line-through" : ""}`}
        >
          1 {t("uppercase character")}
        </Text>
        <Text variant="body-2" decoration={`${passwordProgress.oneSpecial ? "line-through" : ""}`}>
          1 {t("special character")}
        </Text>
      </View>
    </View>
  );
};
