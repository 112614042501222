import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the CSS for Quill's snow theme
import { isObject } from "utils/miniHelpers";
import "components/styles/reshaped/reusables/chat/defaultChat.css";

export const DefaultEditor = ({
  value = "",
  placeholder = "Welcome",
  handleChange = () => {},
  formats = ["bold", "italic", "underline", "list", "bullet", "link"],
  modules = {
    toolbar: [
      ["bold", "italic", "underline", "link", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
  },
  editorRef,
  ...attributes
}) => {
  return (
    <ReactQuill
      theme="snow" // You can change the theme if needed
      value={value}
      key={placeholder}
      modules={modules}
      formats={formats}
      onChange={handleChange}
      placeholder={placeholder}
      {...(editorRef && isObject(editorRef) ? { ref: editorRef } : {})}
      {...attributes}
    />
  );
};
