import { useMemo, useState } from "react";
import { Text, View, useToast } from "reshaped";

import { Tick } from "icons";
import { backendAPI } from "utils/axios";
import { Tooltip } from "components/library";
import { stageOptions, STAGES_LIMIT_BASED_STYLE } from "utils/constants";
import { trackColor } from "utils/colors/stage";
import { candidateSlice } from "redux/candidate";
import useSpacing from "components/hooks/useSpacing";
import { ReshapedLoader } from "components/reusables";
import { useDispatch, useSelector } from "react-redux";
import { TextWithEclipses } from "components/reusables";
import { reloadOnUnauthorized } from "utils/checkForAuth";
import { ShowToastWithTranslation } from "utils/showToast";
import useTranslation from "components/hooks/useTranslation";
import { isArrayWithElementsOfType } from "utils/miniHelpers";
import { viewingCandidateSlice } from "redux/viewingCandidate";
import styles from "components/styles/reshaped/candidates.module.css";
import { OperationFailedWithTranslation } from "components/reusables/OperationFailed";

export const StageTrack = ({ stage = "applied" }) => {
  const [loading, setLoading] = useState(false);
  const [selectedStage, setSelectedStage] = useState(null);

  const toast = useToast();
  const { t } = useTranslation();
  const { sm, xxl } = useSpacing();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const state = useSelector((state) => state);

  const { currentCandidate } = state.viewingCandidate;
  const { candidates: candidatesSorting } = state.sorting;
  const { candidates: candidatesFilters } = state.filters;
  const { shouldCandidatesRefetch, candidates, pageCount } = state.candidate;

  const { stages } = candidatesFilters;
  const { selected: selectedStages } = stages;
  const { updateStage } = viewingCandidateSlice.actions;

  const stagingOptionsTitle = useMemo(() => {
    return stageOptions.array.filter(({ value }) => value !== "rejected");

    //eslint-disable-next-line
  }, []);

  const stagingTrackColor = useMemo(() => {
    return trackColor[stage.toLowerCase()];
  }, [stage]);

  const userLanguageStageTextCount = useMemo(() => {
    return user?.language_preference === "de" ? 12 : 12;

    //eslint-disable-next-line
  }, []);

  const track = useMemo(() => {
    let completed = false;
    const result = stagingOptionsTitle.map((option) => {
      if (!completed && stage.toLowerCase() !== "rejected") {
        if (option.value === stage.toLowerCase()) {
          completed = true;
          return {
            title: t(option.title),
            checked: true,
            value: option.value,
          };
        }
        if (option.value !== stage.toLowerCase()) {
          return {
            title: t(option.title),
            checked: true,
            value: option.value,
          };
        }
      }
      return {
        title: t(option.title),
        checked: false,
        value: option.value,
      };
    });

    return result;
    //eslint-disable-next-line
  }, [stage, stagingOptionsTitle]);

  const handleStageChange = async (stageOption) => {
    try {
      if (stageOption.value === stage) return;

      setLoading(true);
      setSelectedStage(stageOption.value);
      const previousCandidateStage = stage;
      const response = await backendAPI.patch(`/candidates/${currentCandidate?.id}/change_stage`, {
        stage: { stage: stageOption.value },
      });
      setLoading(false);
      if (response.data && response.data.status === 200 && response.data.message) {
        const nextCandidateStage = response.data.message;
        let hired = 0;
        if (previousCandidateStage !== "hired" && nextCandidateStage === "hired") hired = 1;
        if (previousCandidateStage === "hired" && nextCandidateStage !== "hired") hired = -1;
        dispatch(
          updateStage({ stage: nextCandidateStage, candidate_id: currentCandidate?.id, hired })
        );
        if (
          !shouldCandidatesRefetch &&
          ((Array.isArray(selectedStages) && selectedStages.length > 0) ||
            isArrayWithElementsOfType({
              array: candidatesSorting,
              elementType: "object",
              keys: [{ key: "stage", exact: true }],
            }))
        ) {
          if (
            Array.isArray(candidates) &&
            candidates.length === 1 &&
            typeof pageCount === "number" &&
            pageCount > 0
          ) {
            dispatch(
              candidateSlice.actions.set({
                pageCount: pageCount - 1,
                shouldCandidatesRefetch: true,
              })
            );
          } else {
            dispatch(candidateSlice.actions.set({ shouldCandidatesRefetch: true }));
          }
        }
        ShowToastWithTranslation({
          toast,
          Children: StageChanged,
          text: t("Stage successfully changed!"),
        });
      } else {
        ShowToastWithTranslation({
          toast,
          Children: OperationFailedWithTranslation,
          text: t("Operation unsuccessful!"),
        });
      }
    } catch (error) {
      reloadOnUnauthorized(error);
      ShowToastWithTranslation({
        toast,
        Children: OperationFailedWithTranslation,
        text: t("Operation unsuccessful!"),
      });
    }
  };

  return (
    <View width="100%" height={`${xxl * 4}px`}>
      <View className={styles.track__wrapper}>
        <span
          className={styles.track__connector}
          style={{
            backgroundColor: stagingTrackColor.unChecked,
          }}
        />
        {track.map((option) => {
          return (
            <View key={option.title} gap={sm} align="center">
              <Tooltip position="bottom" text={t(option.title)}>
                {({ className }) => (
                  <View
                    className={`${
                      track.length > STAGES_LIMIT_BASED_STYLE ? styles.stage__small : styles.stage
                    } ${className} ${option.checked ? `${styles.stage__checked}` : ""}`}
                    attributes={{
                      onClick: () => handleStageChange(option),
                      style: {
                        backgroundColor: option.checked
                          ? stagingTrackColor.checked
                          : stagingTrackColor.unChecked,
                        cursor: stage !== option.value ? "pointer" : "default",
                      },
                    }}
                  >
                    {loading && option.value === selectedStage ? (
                      <ReshapedLoader size="small" animationSpeed={"2s"} />
                    ) : (
                      option.checked && <Tick />
                    )}
                  </View>
                )}
              </Tooltip>
              {stage === option.value && (
                <TextWithEclipses
                  variant="caption-1"
                  color={option.checked ? "neutral" : "neutral-faded"}
                  text={option.title}
                  characterLimit={userLanguageStageTextCount}
                  noOfEclipses={2}
                  customAttributes={{ style: { position: "absolute", bottom: "-24px" } }}
                />
              )}
            </View>
          );
        })}
      </View>
    </View>
  );
};

const StageChanged = ({ children }) => {
  return (
    <Text color="positive" variant="body-strong-1">
      {children}
    </Text>
  );
};
