import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { backendAPI } from "utils/axios";

export const fetchSubscriptionData = createAsyncThunk(
  "subscriptions/fetchSubscriptionData",
  async (_, { rejectWithValue }) => {
    try {
      const response = await backendAPI.get("/companies/subscriptions");
      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue("Failed to fetch subscription data");
      }
    } catch (error) {
      return rejectWithValue(error.message || "Network error");
    }
  }
);

const initialState = {
  subscription: {
    type: null,
    status: null,
    price_id: null,
    plan_id: null,
    stripe_customer_id: null,
    stripe_subscription_id: null,
    current_period_start_at: null,
    current_period_end_at: null,
    next_invoice: null,
    company_id: null,
    user_id: null,
    interval: null,
    stripe_price_id: null,
    currency: null,
    jobs_count: 0,
    users_count: 0,
    scheduled_for_downgrade: null,
    scheduled_plan_id: null,
    scheduled_price_id: null,
    max_jobs: null,
    max_users: null,
    max_candidates: null,
  },
  loading: false,
  error: null,
}

export const subscriptionsSlice = createSlice({
  name: "subscriptions",
  initialState: initialState,
  reducers: {
    reset: (state) => {
      for (let key in initialState) {
        state[key] = initialState[key];
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSubscriptionData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSubscriptionData.fulfilled, (state, action) => {
        state.loading = false;
        state.subscription = action.payload;
      })
      .addCase(fetchSubscriptionData.rejected, (state, action) => {
        state.status = false;
        state.error = action.error.message;
      });
  },
});

export default subscriptionsSlice.reducer;
