import { useRef, useState } from "react";
import { Button, DropdownMenu, FormControl, Text, View } from "reshaped";

import { TagOutline } from "icons";
import useSpacing from "components/hooks/useSpacing";
import { useDispatch, useSelector } from "react-redux";
import { placeholdersSlice } from "redux/placeholders";
import useTranslation from "components/hooks/useTranslation";
import { getPlaceholderFromAPI } from "utils/pages/settings";
import { HTMLValidationHelper } from "utils/validationHelper";
import { addBlankTargetToAnchorTags } from "utils/EditorHelpers";
import { CooperLabel } from "components/reusables/reshapedCustoms";
import styles from "components/styles/reshaped/settings.module.css";
import { ReshapedLoader, TextWithEclipses } from "components/reusables";
import { MessageTemplateEditor } from "components/reusables/richTextEditors/MessageTemplateEditor";
import {
  isObject,
  isString,
  areKeysAvailableIn,
  areKeysAvailableWithType,
} from "utils/miniHelpers";

export function MessagesTemplateInput({
  errors,
  stateRef,
  setErrors,
  setIsChanged,
  viewingTemplate,
}) {
  const [isInputInFocus, setIsInputInfocus] = useState(false);
  const [richMessage, setRichMessage] = useState(() => {
    if (areKeysAvailableIn({ object: viewingTemplate, keys: ["message"] }))
      return viewingTemplate.message;
    return "";
  });

  const editorRef = useRef();
  const dispatch = useDispatch();
  const { xs, md } = useSpacing();
  const { t, locale } = useTranslation();
  const { placeholders } = useSelector((state) => state);

  const {
    data: placeholdersData,
    error: placeholdersError,
    loading: placeholdersLoading,
  } = placeholders;

  const handleChange = (value) => {
    stateRef.current = {
      ...(isObject(stateRef.current) ? stateRef.current : {}),
      message: value,
    };
    setRichMessage(value);
    if (isInputInFocus) {
      setIsChanged(true);
    }
  };

  const handleFocus = () => {
    setIsInputInfocus(true);
  };

  const handleBlur = (e) => {
    setIsInputInfocus(false);

    const sanitizedValue = addBlankTargetToAnchorTags({ text: richMessage });

    const errorResult = HTMLValidationHelper({
      value: sanitizedValue,
      requiredErrorString: t("Message field is mandatory."),
      characterLimitErrorString: t("Message must not exceed 8000 characters."),
      sizeLimitErrorString: t("Message size must not exceed 20MB limit"),
    });

    if (errorResult) {
      setErrors((prev) => {
        return {
          ...prev,
          message: errorResult,
        };
      });
    } else {
      setErrors((prev) => {
        return {
          ...prev,
          message: null,
        };
      });
    }

    setRichMessage(sanitizedValue);

    stateRef.current = {
      ...(isObject(stateRef.current) ? stateRef.current : {}),
      message: sanitizedValue,
    };
  };

  const handleDropdown = (data) => {
    if (!areKeysAvailableIn({ object: data, keys: ["name", "key"] })) return;
    const quill = editorRef.current.getEditor();
    if (quill) {
      const selection = quill.getSelection();
      if (selection) {
        quill.insertText(selection.index, data.key);
        const cursorIndex = selection.index + data.key.length;
        quill.setSelection(cursorIndex, 0);
        setTimeout(() => {
          quill.focus();
        }, 2);
      }
    }
  };

  const handleRetryGetPlaceholders = async () => {
    dispatch(placeholdersSlice.actions.setLoading(true));

    const result = await getPlaceholderFromAPI();

    if (result.error) {
      dispatch(
        placeholdersSlice.actions.setProperties({
          loading: false,
          error: result.error,
          data: null,
        })
      );
    }
    if (result.data) {
      dispatch(
        placeholdersSlice.actions.setProperties({
          loading: false,
          data: result.data,
          error: null,
        })
      );
    }
  };
  const handleAnchorChange = () => {
    setTimeout(() => {
      if (
        areKeysAvailableWithType({
          object: stateRef.current,
          keys: [{ message: "string", callback: (value) => isString(value) }],
        })
      ) {
        handleChange(addBlankTargetToAnchorTags({ text: stateRef.current.message }));
      }
    }, 500);
  };

  return (
    <View gap={md}>
      <FormControl required>
        <FormControl.Label>
          <CooperLabel text={t("Message")} />
        </FormControl.Label>
        <View padding={[xs, 0]}>
          <div
            className={`quill-template-wrapper
        ${
          isInputInFocus
            ? "quill-focus"
            : errors["message"] && !isObject(errors["message"])
            ? "quill-error"
            : ""
        }
        `}
          >
            <MessageTemplateEditor
              value={richMessage}
              placeholder={t("Please input the content of your template message here.")}
              handleChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              editorRef={editorRef}
              handleLinkChanges={handleAnchorChange}
            />
            <DropdownMenu width={locale === "de" ? "270px" : "230px"} position="top-end">
              <DropdownMenu.Trigger>
                {(attributes) => (
                  <View
                    as="button"
                    className={styles.placeholder__btn}
                    attributes={{ ...attributes, title: t("Placeholders") }}
                    direction="row"
                    align="center"
                    justify="center"
                  >
                    <TagOutline width="28" className={styles.tagoutline__path} />
                  </View>
                )}
              </DropdownMenu.Trigger>
              <DropdownMenu.Content>
                {placeholdersLoading && (
                  <View height="50px">
                    <ReshapedLoader size="small" />
                  </View>
                )}
                {!placeholdersLoading && (
                  <>
                    {placeholdersData &&
                      Array.isArray(placeholdersData) &&
                      placeholdersData.map((each) => {
                        return (
                          <DropdownMenu.Item key={each.name} onClick={() => handleDropdown(each)}>
                            <TextWithEclipses
                              text={each.name}
                              variant="feature-3"
                              color="neutral"
                              characterLimit={locale === "de" ? 30 : 25}
                            />
                          </DropdownMenu.Item>
                        );
                      })}

                    {placeholdersError && typeof placeholdersError === "string" && (
                      <View height="100%" padding={md} align="center" justify="center" gap={md}>
                        <Text variant="caption-1" align="center" color="critical">
                          {t(placeholdersError)}
                        </Text>
                        <Button size="small" color="critical" onClick={handleRetryGetPlaceholders}>
                          <Text variant="caption-1">{t("Please try again.")}</Text>
                        </Button>
                      </View>
                    )}
                  </>
                )}
              </DropdownMenu.Content>
            </DropdownMenu>
          </div>
        </View>
        {errors["message"] && !isObject(errors["message"]) && (
          <Text color="critical" variant="body-1">
            {errors["message"]}
          </Text>
        )}
      </FormControl>
    </View>
  );
}
