import { useMemo } from "react";
import { View } from "reshaped";

import { useSelector } from "react-redux";
import { validationHelper } from "utils/validationHelper";
import useTranslation from "components/hooks/useTranslation";
import { getBrowserLanguage } from "utils/getBrowserLanguage";
import usePublicCountries from "components/hooks/usePublicCountries";
import { BasicAutoComplete } from "components/reusables/AutoCompletes";

function RegisterAutoCompleteCountries({ country, setCountry, error, setError }) {
  const { t } = useTranslation();
  const { getCountriesList } = usePublicCountries();
  const { publicState } = useSelector((state) => state);
  const { countries } = publicState;
  const { data } = countries;

  const browserLang = useMemo(
    () => getBrowserLanguage(),
    //eslint-disable-next-line
    []
  );

  const countriesList = useMemo(() => {
    return getCountriesList({ locale: browserLang });

    //eslint-disable-next-line
  }, [data]);

  const handleCountry = (value) => {
    setCountry(value);
    if (error.country) {
      setError((previousError) => {
        return {
          ...previousError,
          country: null,
        };
      });
    }
  };

  const handleCountryValidation = () => {
    const errorResult = validationHelper({
      value: country,
      requiredErrorString: "Country is required",
      callback: (value) => {
        if (countriesList.includes(value)) return "";
        return "Please choose a country from the list";
      },
    });

    if (errorResult) {
      setError((prev) => {
        return {
          ...prev,
          country: t(errorResult),
        };
      });
    }
  };

  return (
    <View attributes={{ style: { zIndex: 10 } }}>
      <BasicAutoComplete
        label={t("Country")}
        required
        id="register__company"
        state={country}
        options={countriesList}
        handleChange={handleCountry}
        handleBlur={handleCountryValidation}
        hasError={error["country"] && typeof error["country"] !== "object"}
        error={error["country"]}
        inputAtrributes={{
          type: "text",
          "data-cooper": "register_companycountry",
          autoComplete: "off",
        }}
      />
    </View>
  );
}

export default RegisterAutoCompleteCountries;
