import { HTMLTAGSLOCATER } from "utils/RegularExpressions";

export function removeHtmlTagsWithAttributes({ text = "", shouldTrim = false }) {
  const sanitizedText = text.replace(HTMLTAGSLOCATER, "");
  if (shouldTrim) return sanitizedText.trim();
  return sanitizedText;
}

export function replaceSpaceswithEntity({
  text = "",
  tabEntity = "",
  shouldTrim = false,
  spaceEntity = "&nbsp;",
  withNoRegex = false,
}) {
  if (typeof text !== "string") return "";
  let sanitizedText = text.replace(withNoRegex ? " " : / /g, spaceEntity);
  if (tabEntity) {
    sanitizedText = sanitizedText.replace(/\t/g, tabEntity);
  }
  if (shouldTrim) return sanitizedText.trim();
  return sanitizedText;
}

export function replacePattern({ withThisCharacter = "_", text = "", regex = /[^a-zA-Z0-9]/g }) {
  if (typeof text !== "string") return "";
  let sanitizedText = text.replace(regex, withThisCharacter);
  return sanitizedText;
}
