export const NoMessages = () => {
  return (
    <svg
      width="239"
      height="172"
      viewBox="0 0 239 172"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group 1000002875" opacity="0.3">
        <path
          id="path30"
          d="M222.526 46.554H33.3962C24.8497 46.554 17.9216 53.4821 17.9216 62.0286V142.494C17.9216 151.041 24.8497 157.969 33.3962 157.969H106.151L119.183 171L132.214 157.969H222.526C231.071 157.969 238 151.041 238 142.494V62.0286C238 53.4821 231.071 46.554 222.526 46.554Z"
          fill="white"
        />
        <path
          id="path32"
          d="M222.526 46.554H33.3962C24.8497 46.554 17.9216 53.4821 17.9216 62.0286V142.494C17.9216 151.041 24.8497 157.969 33.3962 157.969H106.151L119.183 171L132.214 157.969H222.526C231.071 157.969 238 151.041 238 142.494V62.0286C238 53.4821 231.071 46.554 222.526 46.554Z"
          stroke="#E2C0EF"
          strokeWidth="1.39743"
          strokeMiterlimit="10"
        />
        <path
          id="path34"
          d="M69.4595 78.9404C69.4595 87.8672 62.2238 95.1022 53.297 95.1022C44.3716 95.1022 37.1353 87.8672 37.1353 78.9404C37.1353 70.015 44.3716 62.7786 53.297 62.7786C62.2238 62.7786 69.4595 70.015 69.4595 78.9404Z"
          fill="#FBF0FF"
        />
        <path id="path46" d="M50.5547 102.316H36.2791V106.13H50.5547V102.316Z" fill="#E2C0EF" />
        <path id="path48" d="M54.0791 102.316V106.13H75.4806V102.316H54.0791Z" fill="#E2C0EF" />
        <path id="path50" d="M67.0681 113.648H36.2791V117.462H67.0681V113.648Z" fill="#FBF0FF" />
        <path id="path52" d="M151.651 113.648H79.6139V117.462H151.651V113.648Z" fill="#FBF0FF" />
        <path id="path54" d="M164.197 113.648V117.462H219.642V113.648H164.197Z" fill="#FBF0FF" />
        <path id="path56" d="M105.11 125.789H36.2791V129.603H105.11V125.789Z" fill="#FBF0FF" />
        <path id="path58" d="M185.242 125.789V129.603H219.642V125.789H185.242Z" fill="#FBF0FF" />
        <path id="path60" d="M177.147 125.789H116.847V129.603H177.147V125.789Z" fill="#FBF0FF" />
        <path id="path62" d="M124.131 137.93H36.2791V141.745H124.131V137.93Z" fill="#FBF0FF" />
        <path
          id="path86"
          d="M92.7792 1.00001H8.01536C4.18506 1.00001 1.08008 4.1047 1.08008 7.93437V24.8959C1.08008 28.7263 4.18506 31.8317 8.01536 31.8317H92.7792C96.6095 31.8317 99.7149 28.7263 99.7149 24.8959V7.93437C99.7149 4.1047 96.6095 1.00001 92.7792 1.00001Z"
          stroke="#E2C0EF"
          strokeWidth="1.39743"
          strokeMiterlimit="10"
        />
        <path
          id="path98"
          d="M22.5777 15.0483C22.5777 18.4648 19.8088 21.234 16.3931 21.234C12.9773 21.234 10.2083 18.4648 10.2083 15.0483C10.2083 11.6325 12.9773 8.86412 16.3931 8.86412C19.8088 8.86412 22.5777 11.6325 22.5777 15.0483Z"
          fill="#FBF0FF"
        />
        <path id="path100" d="M14.4046 22.507H8.94214V23.9667H14.4046V22.507Z" fill="#E2C0EF" />
        <path id="path102" d="M15.7534 22.507V23.9667H23.9433V22.507H15.7534Z" fill="#E2C0EF" />
      </g>
    </svg>
  );
};
