import { Button, Text, View } from "reshaped";

import { Reload } from "icons";
import { useDispatch } from "react-redux";
import { baseColor } from "utils/colors/accents";
import useSpacing from "components/hooks/useSpacing";
import useTranslation from "components/hooks/useTranslation";
import { fetchJobBoards } from "redux/jobBoards/jobBoardsSlice";

export function ShowJobBoardsError({ backgroundColor = "critical-faded" }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { sm, lg } = useSpacing();

  const handleRetry = () => {
    dispatch(fetchJobBoards());
  };

  return (
    <View
      padding={[sm, lg]}
      borderRadius="small"
      backgroundColor={backgroundColor}
      align="center"
      direction="row"
      gap={sm}
      width="100%"
    >
      <Text color="critical" variant="body-strong-1">
        {t("Job boards fetching failed")}
      </Text>
      <Button
        color="critical"
        variant="ghost"
        endIcon={() => <Reload fill={baseColor.critical} />}
        onClick={handleRetry}
      >
        <Text color="critical" variant="body-strong-1">
          {t("Please try again.")}
        </Text>
      </Button>
    </View>
  );
}
