import { baseColor } from "utils/colors/accents";

export const Reject = ({ color = baseColor.critical }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.99984 17.3334C7.84706 17.3334 6.76373 17.1147 5.74984 16.6772C4.73595 16.2397 3.854 15.6459 3.104 14.8959C2.354 14.1459 1.76025 13.264 1.32275 12.2501C0.885254 11.2362 0.666504 10.1529 0.666504 9.00008C0.666504 7.8473 0.885254 6.76397 1.32275 5.75008C1.76025 4.73619 2.354 3.85425 3.104 3.10425C3.854 2.35425 4.73595 1.7605 5.74984 1.323C6.76373 0.885498 7.84706 0.666748 8.99984 0.666748C10.1526 0.666748 11.2359 0.885498 12.2498 1.323C13.2637 1.7605 14.1457 2.35425 14.8957 3.10425C15.6457 3.85425 16.2394 4.73619 16.6769 5.75008C17.1144 6.76397 17.3332 7.8473 17.3332 9.00008C17.3332 10.1529 17.1144 11.2362 16.6769 12.2501C16.2394 13.264 15.6457 14.1459 14.8957 14.8959C14.1457 15.6459 13.2637 16.2397 12.2498 16.6772C11.2359 17.1147 10.1526 17.3334 8.99984 17.3334ZM8.99984 16.0834C10.9721 16.0834 12.6457 15.3959 14.0207 14.0209C15.3957 12.6459 16.0832 10.9723 16.0832 9.00008C16.0832 8.15286 15.9373 7.34036 15.6457 6.56258C15.354 5.7848 14.9443 5.07647 14.4165 4.43758L4.43734 14.4167C5.06234 14.9584 5.7672 15.3716 6.55192 15.6563C7.33664 15.9411 8.15262 16.0834 8.99984 16.0834ZM3.604 13.5626L13.5623 3.60425C12.9234 3.06258 12.2151 2.64591 11.4373 2.35425C10.6596 2.06258 9.84706 1.91675 8.99984 1.91675C7.02762 1.91675 5.354 2.60425 3.979 3.97925C2.604 5.35425 1.9165 7.02786 1.9165 9.00008C1.9165 9.8473 2.06928 10.6633 2.37484 11.448C2.68039 12.2327 3.09012 12.9376 3.604 13.5626Z"
        fill={color}
      />
    </svg>
  );
};
