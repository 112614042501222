import { cooperColors } from "utils/colors/accents";

export const Bin = ({ color = cooperColors["foreground-neutral-faded"] }) => {
  return (
    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.06745 19.75C2.63411 19.75 2.25495 19.5875 1.92995 19.2625C1.60495 18.9375 1.44245 18.5583 1.44245 18.125V2.6875H1.14453C0.914323 2.6875 0.721354 2.60918 0.565625 2.45255C0.409896 2.2959 0.332031 2.10181 0.332031 1.87026C0.332031 1.6387 0.409896 1.44618 0.565625 1.29271C0.721354 1.13924 0.914323 1.0625 1.14453 1.0625H5.4237C5.4237 0.827778 5.50156 0.633681 5.65729 0.480208C5.81302 0.326736 6.00599 0.25 6.2362 0.25H11.7612C11.9914 0.25 12.1844 0.327865 12.3401 0.483594C12.4958 0.639323 12.5737 0.832292 12.5737 1.0625H16.8529C17.0831 1.0625 17.276 1.14082 17.4318 1.29745C17.5875 1.4541 17.6654 1.64819 17.6654 1.87974C17.6654 2.1113 17.5875 2.30382 17.4318 2.45729C17.276 2.61076 17.0831 2.6875 16.8529 2.6875H16.5549V18.125C16.5549 18.5583 16.3924 18.9375 16.0674 19.2625C15.7424 19.5875 15.3633 19.75 14.9299 19.75H3.06745ZM3.06745 2.6875V18.125H14.9299V2.6875H3.06745ZM5.93828 14.9833C5.93828 15.2135 6.0166 15.4065 6.17323 15.5622C6.32988 15.718 6.52398 15.7958 6.75552 15.7958C6.98708 15.7958 7.1796 15.718 7.33307 15.5622C7.48655 15.4065 7.56328 15.2135 7.56328 14.9833V5.80208C7.56328 5.57188 7.48497 5.37891 7.32833 5.22318C7.17168 5.06745 6.97759 4.98958 6.74604 4.98958C6.51448 4.98958 6.32196 5.06745 6.16849 5.22318C6.01502 5.37891 5.93828 5.57188 5.93828 5.80208V14.9833ZM10.4341 14.9833C10.4341 15.2135 10.5124 15.4065 10.6691 15.5622C10.8257 15.718 11.0198 15.7958 11.2514 15.7958C11.4829 15.7958 11.6754 15.718 11.8289 15.5622C11.9824 15.4065 12.0591 15.2135 12.0591 14.9833V5.80208C12.0591 5.57188 11.9808 5.37891 11.8242 5.22318C11.6675 5.06745 11.4734 4.98958 11.2419 4.98958C11.0103 4.98958 10.8178 5.06745 10.6643 5.22318C10.5109 5.37891 10.4341 5.57188 10.4341 5.80208V14.9833Z"
        fill={color}
      />
    </svg>
  );
};
