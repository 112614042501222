import { useMemo } from "react";
import { Divider, View } from "reshaped";
import { useSearchParams } from "react-router-dom";
import useSpacing from "components/hooks/useSpacing";
import { TabButton } from "components/reusables/layouts";

export const BookingsNav = ({ bookingsTab, setBookingsTab }) => {
  const { sm } = useSpacing();
  //eslint-disable-next-line
  const [_, setSearchParams] = useSearchParams();

  const tabs = useMemo(() => {
    return [
      { title: "Inventory", value: "inventory" },
      { title: "Active & Past Bookings", value: "active" },
    ];

    //eslint-disable-next-line
  }, []);

  const handleBookingsTabChange = ({ value }) => {
    setSearchParams({ to: value });
    setBookingsTab(value);
  };

  return (
    <View gap={sm}>
      <View direction="row" align="center" justify="start">
        {tabs.map((tab) => {
          return (
            <TabButton
              key={tab.value}
              title={tab.title}
              value={tab.value}
              currentActiveTab={bookingsTab}
              handler={handleBookingsTabChange}
            />
          );
        })}
      </View>
      <Divider
        attributes={{
          style: {
            backgroundColor: "var(--rs-color-border-neutral)",
          },
        }}
      />
    </View>
  );
};
