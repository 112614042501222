export const NavigateLeft = () => {
  return (
    <svg width="16" height="27" viewBox="0 0 16 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.125 -1.90735e-06L15.5937 2.46875L4.90625 13.1562L15.5938 23.8437L13.125 26.3125L0 13.1562L13.125 -1.90735e-06Z"
        fill="#0A3D8A"
      />
    </svg>
  );
};

export const NavigateRight = () => {
  return (
    <svg width="16" height="28" viewBox="0 0 16 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.53125 27.0938L0.0625 24.625L10.75 13.9375L0.0625 3.25L2.53125 0.78125L15.6562 13.9375L2.53125 27.0938Z"
        fill="#0A3D8A"
      />
    </svg>
  );
};
