import { useMemo } from "react";
import { Button, DropdownMenu, FormControl, View } from "reshaped";

import { ArrowDown } from "icons";
import { useSelector } from "react-redux";
import useTranslation from "components/hooks/useTranslation";
import { stepOneProgressHelper } from "utils/jobStepProgress";
import styles from "components/styles/reshaped/jobs/new.module.css";

const ExperienceDropdown = (props) => {
  const { propTitle, setStepOneProgress, experience, setExperience } = props;

  const { t } = useTranslation();
  const auth = useSelector((state) => state.auth);
  const experienceOptions = useMemo(() => {
    return [
      { value: "Less than 1 year" },
      { value: "1-3 years" },
      { value: "3-5 years" },
      { value: "5-7 years" },
      { value: "+7 years" },
    ];

    //eslint-disable-next-line
  }, []);

  const translations = useMemo(() => {
    const experienceTranslations = experienceOptions.reduce(
      (acc, curr) => ({ ...acc, [curr.value]: t(curr.value) }),
      {}
    );
    return {
      Experience: t("Experience"),
      "Years of experience": t("Years of experience"),
      ...experienceTranslations,
    };

    //eslint-disable-next-line
  }, [auth && auth?.user ? [auth.user] : []]);

  const handleExperience = ({ selectedOption }) => {
    const { value } = selectedOption;
    setExperience(value);
    stepOneProgressHelper(propTitle, setStepOneProgress, "add");
  };

  return (
    <View.Item grow={true} className={styles.dropdown__wrapper}>
      <FormControl>
        <FormControl.Label>{translations["Years of experience"]}</FormControl.Label>
        <DropdownMenu position="bottom-start">
          <DropdownMenu.Trigger>
            {(attributes) => (
              <Button
                className={styles.dropdown__button}
                variant="outline"
                fullWidth
                endIcon={ArrowDown}
                attributes={{ ...attributes, "data-cooper": "experience button" }}
              >
                {experience ? translations[experience] : translations["Years of experience"]}
              </Button>
            )}
          </DropdownMenu.Trigger>
          <DropdownMenu.Content>
            {experienceOptions.map((option, index) => {
              return (
                <DropdownMenu.Item
                  onClick={() => handleExperience({ selectedOption: option })}
                  key={index}
                >
                  {translations[option.value]}
                </DropdownMenu.Item>
              );
            })}
          </DropdownMenu.Content>
        </DropdownMenu>
      </FormControl>
    </View.Item>
  );
};

export default ExperienceDropdown;
