import { View } from "reshaped";
import useSpacing from "components/hooks/useSpacing";
import { TabButton } from "components/reusables/layouts";

const Categories = (props) => {
  const {
    categories = [],
    selectedCategory,
    setSelectedCategory,
    setAddEvaluation,
    setAddNote,
  } = props;

  const { sm, lg } = useSpacing();

  const handleCategory = ({ value }) => {
    setAddEvaluation(false);
    setAddNote(false);

    if (selectedCategory === value) return;

    setSelectedCategory(value);
    // fetchJobs(value);
  };

  return (
    <View
      attributes={{
        style: {
          paddingInline: "var(--spacing-xl)",
          paddingBlockStart: "calc(var(--spacing-xl) + var(--spacing-sm))",
        },
      }}
    >
      <View
        height="48px"
        padding={[0, sm]}
        borderRadius="medium"
        backgroundColor="white"
        direction="row"
        align="center"
        justify="start"
        gap={lg}
      >
        {categories.map((category) => {
          return (
            <TabButton
              key={category.value}
              title={category.title}
              value={category.value}
              handler={handleCategory}
              currentActiveTab={selectedCategory}
            />
          );
        })}
      </View>
    </View>
  );
};

export default Categories;
