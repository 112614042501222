import React, { useMemo, useContext } from "react";
import { FormControl, View, TextField } from "reshaped";
import { ErrorContext } from "components/pages/candidates/AddCandidate";

export const CandidateInput = React.memo((props) => {
  const {
    title,
    columns = 6,
    propTitle,
    inputType = "text",
    isRequired = false,
    inputValue,
    setInputValue,
    inputAttributes = {},
  } = props;

  const { errors, setErrors } = useContext(ErrorContext);

  const id = useMemo(
    () => propTitle.toLowerCase(),

    //eslint-disable-next-line
    []
  );

  const handleChange = ({ value }) => {
    if (isRequired && errors[id]) {
      setErrors((previousError) => {
        return {
          ...previousError,
          [id]: null,
        };
      });
    }
    if (!isRequired && errors[id]) {
      setErrors((previousError) => {
        const prev = { ...previousError };
        delete prev[id];
        return {
          ...prev,
        };
      });
    }
    setInputValue(value);
  };

  const validateHandler = () => {
    let value = inputValue.replace(/^\s+|\s+$/g, "");
    if (!isRequired) {
      props.handleNotRequiredValidation({ id, value });
      return;
    }
    props.handleValidation({ id, value });
  };

  return (
    <View.Item columns={columns}>
      <FormControl
        required={isRequired}
        hasError={
          (isRequired && errors[id] && typeof errors[id] !== "object") ||
          (!isRequired && errors[id] && typeof errors[id] !== "object")
            ? true
            : false
        }
      >
        <FormControl.Label>{title}</FormControl.Label>
        <TextField
          value={inputValue}
          onChange={handleChange}
          inputAttributes={{ type: inputType, onBlur: validateHandler, ...inputAttributes }}
          id={propTitle}
        />
        <FormControl.Error>{errors[id]}</FormControl.Error>
      </FormControl>
    </View.Item>
  );
});
