import { useState, useCallback, useEffect } from "react";
import { Button, FormControl, View, Link, Text, TextField } from "reshaped";

import { Eye } from "icons";
import { metaSlice } from "redux/meta";
import { backendAPI } from "utils/axios";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { logoutUser } from "utils/checkForAuth";
import { encryptToken } from "utils/encryptions";
import { validateErrors } from "utils/validateError";
import { useDispatch, useSelector } from "react-redux";
import { SUPPORT_ERROR, userAuth } from "utils/constants";
import useTranslation from "components/hooks/useTranslation";
import { areKeysAvailableWithType } from "utils/miniHelpers";
import { EMAILVALIDATIONREGEX } from "utils/RegularExpressions";
import { isEmailRestricted } from "utils/checkRestrictionEmail";
import Titles from "components/reusables/loginAndRegister/Titles";
import styles from "components/styles/reshaped/reusables/form.module.css";
import { ShowSupportError } from "components/reusables/common/ShowSupportError";

const LoginUser = (props) => {
  const { title, subTitle } = props;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const { t } = useTranslation();
  const [cookies, setCookie, removeCookie] = useCookies([userAuth]);
  const { isLoading, locationHistory } = useSelector((state) => state.meta);

  const token = cookies[userAuth];

  const [error, setError] = useState({
    email: {},
    password: {},
  });

  const [APIError, setAPIError] = useState(null);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      navigate("/");
    }

    //eslint-disable-next-line
  }, []);

  const validate = useCallback((errorName, name, handleError, regex) => {
    validateErrors(errorName, name, handleError, t, regex);

    //eslint-disable-next-line
  }, []);

  const handleEmail = ({ value }) => {
    if (error.email) {
      setError((previousError) => {
        return {
          ...previousError,
          email: null,
        };
      });
    }

    if (APIError) setAPIError("");
    setEmail(value);
  };

  const handlePassword = ({ value }) => {
    if (error.password) {
      setError((previousError) => {
        return {
          ...previousError,
          password: null,
        };
      });
    }

    if (APIError) setAPIError("");
    setPassword(value);
  };

  const handleEmailValidation = (e) => {
    validate("email", email, setError, EMAILVALIDATIONREGEX);
  };

  const handlePasswordValidation = (e) => {
    validate("password", password, setError, /[a-zA-Z0-9]{1,}/);
  };

  const handleShowPassword = (e) => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (cookies[userAuth]) removeCookie(userAuth);
    logoutUser();

    let hasError = false;

    // if user submitting with empty or invalid fields
    for (let field in error) {
      if (error[field]) {
        hasError = true;
        if (typeof error[field] === "object") {
          setError((previousError) => {
            return {
              ...previousError,
              [field]: t(`${field.split(/(?=[A-Z])/).join(" ")} is required`),
            };
          });
        }
      }
    }

    if (hasError) return;

    const user = {
      email: email.trim(),
      password,
    };

    // Not allowing restricted emails
    if (isEmailRestricted(user.email)) {
      setError((previousError) => {
        return {
          ...previousError,
          email: t("Email is restricted"),
        };
      });
      return;
    }

    try {
      dispatch(metaSlice.actions.initialLoading());
      const res = await backendAPI.post("/users/login", {
        user,
      });

      dispatch(metaSlice.actions.stopLoading());
      if (
        areKeysAvailableWithType({
          object: res,
          keys: [{ status: 200, exact: true }, { data: "object" }],
        }) &&
        areKeysAvailableWithType({ object: res.data.user, keys: [{ token: "string" }] })
      ) {
        const { token } = res.data.user;
        if (cookies[userAuth]) removeCookie(userAuth);
        setCookie(userAuth, encryptToken({ token }), { path: "/" });
        if (locationHistory) {
          navigate(locationHistory);
          dispatch(metaSlice.actions.setLocationHistory(""));
          return;
        } else {
          navigate("/");
          return;
        }
      }
      setAPIError(SUPPORT_ERROR);
    } catch (error) {
      dispatch(metaSlice.actions.stopLoading());
      if (error && error?.response && typeof error?.response === "object") {
        const { data } = error.response;
        if (data && (data.errors || data.error)) {
          if (data.error) {
            setAPIError(data.error);
            return;
          } else {
            const err = Object.keys(data.errors);
            if (typeof error === "object") setAPIError(data.errors[err[0]]);
            return;
          }
        }
      }
      setAPIError(SUPPORT_ERROR);
    }
  };

  return (
    <View height="100%" width="100%" align="center" padding={[0, 6]} justify="center">
      <View as="form" gap={4} width="100%" maxWidth="50rem">
        <Titles title={title} subTitle={subTitle} />
        <FormControl required hasError={error["email"] && typeof error["email"] !== "object"}>
          <FormControl.Label>{t("Email")}</FormControl.Label>
          <TextField
            onChange={handleEmail}
            inputAttributes={{
              type: "email",
              onBlur: handleEmailValidation,
              tabIndex: 1,
              autoFocus: true,
              "data-cooper": "login_email",
            }}
            value={email}
          />
          <FormControl.Error>{error["email"]}</FormControl.Error>
        </FormControl>
        <View gap={2}>
          <FormControl
            required
            hasError={error["password"] && typeof error["password"] !== "object"}
          >
            <FormControl.Label>{t("Password")}</FormControl.Label>
            <View className={styles.password__input}>
              <TextField
                value={password}
                onChange={handlePassword}
                inputAttributes={{
                  type: showPassword ? "text" : "password",
                  onBlur: handlePasswordValidation,
                  autoComplete: "on",
                  "data-cooper": "login_password",
                  tabIndex: 2,
                }}
              />
              <Button
                className={`${styles.show__password__btn} ${showPassword ? styles.active : ""}`}
                variant="ghost"
                startIcon={Eye}
                attributes={{
                  tabIndex: -1,
                }}
                onClick={handleShowPassword}
              />
            </View>
            <FormControl.Error>{error["password"]}</FormControl.Error>
          </FormControl>
          {APIError && <ShowSupportError error={APIError} />}
          <Text variant="body-medium-2" gap={2}>
            {t("Forgot password? No worries")},{" "}
            <Link variant="plain" href="/forgot_password">
              {t("Click here")}
            </Link>
          </Text>
        </View>
        <Button
          onClick={handleSubmit}
          loading={isLoading}
          fullWidth
          attributes={{ type: "submit", "data-cooper": "login_submit", tabIndex: 3 }}
          color="primary"
        >
          {t("Log in")}
        </Button>
        <Text variant="body-medium-2" align="center">
          {t("Don't have an account?")}{" "}
          <Link variant="plain" href="/register">
            {t("Create an account.")}
          </Link>
        </Text>
      </View>
    </View>
  );
};

export default LoginUser;
