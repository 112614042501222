import { useMemo } from "react";
import { Avatar, Image, View } from "reshaped";

import { accent } from "utils/colors/accents";
import useName from "components/hooks/useName";
import { useNavigate } from "react-router-dom";
import useSpacing from "components/hooks/useSpacing";
import { areKeysAvailableIn } from "utils/miniHelpers";
import useCurrency from "components/hooks/useCurrency";
import useTranslation from "components/hooks/useTranslation";
import { PrimaryShadedText, TextWithEclipses } from "components/reusables";
import styles from "components/styles/reshaped/promote/promote.module.css";

export const JobBoard = ({ board, noClick = false }) => {
  const { name, subtitle = "", description, lowest_price_job_board_slot: valuePlan } = board;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { sm, lg, xl } = useSpacing();
  const { currencySymbol } = useCurrency();
  const jobBoardProfile = useName({ info: board, first_name: name });

  const priceDetails = useMemo(() => {
    let info = false;
    let hasLowestPriceJobBoardSlot = false;

    if (
      areKeysAvailableIn({
        object: valuePlan,
        keys: ["original_price", "discount", "discounted_price"],
      })
    ) {
      hasLowestPriceJobBoardSlot = true;
      const { original_price, discount, discounted_price } = valuePlan;

      info = {
        originalPrice: original_price,
        hasDiscount: false,
      };

      if (typeof discount === "number" && discount >= 0) {
        info = {
          ...info,
          hasDiscount: true,
          priceWithDiscount: discounted_price,
          discountPercentage: discount,
          color: accent["2-600"],
        };
      }
    }

    return {
      hasLowestPriceJobBoardSlot,
      info,
    };

    //eslint-disable-next-line
  }, [name]);

  const handleNavigation = () => {
    navigate(`${board.id}/slot`);
  };

  const noClickAttributes = useMemo(() => {
    if (noClick) {
      return {
        style: {
          overflow: "hidden",
        },
      };
    }
    return {
      onClick: handleNavigation,
      style: {
        overflow: "hidden",
      },
    };

    //eslint-disable-next-line
  }, [noClick]);

  return (
    <View
      direction="row"
      align="stretch"
      borderRadius="medium"
      shadow="base"
      className={noClick ? "" : styles.board}
      attributes={noClickAttributes}
    >
      <View.Item grow>
        <View
          padding={lg}
          gap={lg}
          height="100%"
          attributes={{
            style: {
              backgroundColor: "var(--rs-color-background-neutral-highlighted)",
            },
          }}
        >
          <View direction="row" gap={lg} align="center">
            <>
              {jobBoardProfile.hasURL && (
                <Image width="40px" height="40px" src={jobBoardProfile.url} alt={name} />
              )}
              {!jobBoardProfile.hasURL && (
                <Avatar squared size={10} initials={jobBoardProfile.letters} alt={name} />
              )}
            </>
            <View>
              <TextWithEclipses variant="body-strong-1" text={name} characterLimit={20} />
              <TextWithEclipses
                variant="body-2"
                color="neutral-faded"
                text={subtitle}
                characterLimit={20}
              />
            </View>
          </View>
          <View.Item grow>
            <TextWithEclipses
              variant="body-2"
              text={description}
              characterLimit={priceDetails.hasLowestPriceJobBoardSlot ? 90 : 120}
            />
          </View.Item>
        </View>
      </View.Item>
      {priceDetails.hasLowestPriceJobBoardSlot && (
        <View
          backgroundColor="primary-faded"
          justify="center"
          align="center"
          padding={sm}
          width="100px"
          height="100%"
          gap={xl}
          attributes={{
            style: {
              position: "relative",
              overflow: "hidden",
            },
          }}
        >
          <View gap={sm} width="100%">
            <PrimaryShadedText variant="caption-2">{t("Starting from")}</PrimaryShadedText>
            <PrimaryShadedText variant="body-strong-1">
              {currencySymbol}
              {priceDetails.info.hasDiscount
                ? priceDetails.info.priceWithDiscount
                : priceDetails.info.originalPrice}
            </PrimaryShadedText>
          </View>
        </View>
      )}
    </View>
  );
};

