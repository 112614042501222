import { useEffect, useId, useRef, useState } from "react";
import { Button, Text, View, useToast } from "reshaped";

import { Message, Reload } from "icons";
import { backendAPI } from "utils/axios";
import { baseColor } from "utils/colors/accents";
import useSpacing from "components/hooks/useSpacing";
import { conversationSlice } from "redux/conversation";
import { useDispatch, useSelector } from "react-redux";
import { ChatInput, ChatMessages } from "components/chat";
import { ShowToastWithTranslation } from "utils/showToast";
import useTranslation from "components/hooks/useTranslation";
import useSocketContent from "components/context/socket/useSocketContext";
import { SuccessfulToast } from "components/reusables/tab/SuccessfulToast";
import { inProgressConversationsSlice } from "redux/inProgressConversations";
import { OperationFailedWithTranslation, ReshapedLoader } from "components/reusables";
import useConversationSubscriptions from "components/hooks/useConversationSubscriptions";
import { isObject, areKeysAvailableIn, areKeysAvailableWithType } from "utils/miniHelpers";
import {
  sanitizeMessage,
  getPreviousMessages,
  mergeConversationWithSubject,
  decodeConversationOfLocalStorage,
  encodeConversationForLocalStorage,
  setCooperhireInProgressConversationToLocalStorage,
  removeCooperhireInProgressConversationFromLocalStorage,
} from "utils/pages/candidate";

export const ChatBox = ({ viewingCandidateId }) => {
  const [messages, setMessages] = useState([]);
  const [oldSubject, setOldSubject] = useState("");
  const [isTextInFocus, setIsTextInFocus] = useState(false);
  const [messagesBoxHeight, setMessagesBoxHeight] = useState(160);
  const [initialMessagesLoading, setInitialMessagesLoading] = useState(false);
  const [conversationFetchingError, setConversationFetchingError] = useState("");
  const [isSendMessageIsInProgress, setIsSendMessageIsInProgress] = useState(false);
  const [shouldLatestMessageInFocus, setShouldLatestMessageInFocus] = useState(false);

  const uniqueID = useId();
  // const runAfterFirstRenderRef = useRef(0);
  const messagesExhaustedAtInitialFetchRef = useRef(false);

  const toast = useToast();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { sm, lg, xl } = useSpacing();
  const { consumer } = useSocketContent();
  const { auth, conversation, inProgressConversations } = useSelector((state) => state);
  const { sendMessageToServer, getConversationSubscription, createConversationSubscription } =
    useConversationSubscriptions();

  const { user } = auth;
  const { newMessage } = conversation;
  const { inProgressConversations: universalInProgressConversation } = inProgressConversations;

  async function getPreviousConversation({ candidate_id, shouldConnectToSocket = false }) {
    let shouldConnect = shouldConnectToSocket;
    setInitialMessagesLoading(true);
    const result = await getPreviousMessages({ candidate_id });
    if (
      areKeysAvailableIn({
        object: result,
        keys: ["conversation", "latestSubject", "isExhausted"],
      })
    ) {
      messagesExhaustedAtInitialFetchRef.current = result.isExhausted;

      const currentViewingCandidateInProgressMessages = areKeysAvailableWithType({
        object: universalInProgressConversation,
        keys: [{ [candidate_id]: "array" }],
      })
        ? universalInProgressConversation[candidate_id]
        : [];
      dispatch(
        conversationSlice.actions.setCandidateInProgressMessages({
          data: currentViewingCandidateInProgressMessages,
          initial: true,
        })
      );
      const afterMergeResults = mergeConversationWithSubject({
        conversation: [...result.conversation, ...currentViewingCandidateInProgressMessages],
      });

      setMessages(afterMergeResults.conversation);
      setOldSubject(afterMergeResults.latestSubject);
      if (conversationFetchingError) setConversationFetchingError("");
    }
    if (
      areKeysAvailableIn({
        object: result,
        keys: ["error"],
      })
    ) {
      setConversationFetchingError(result.error);
      shouldConnect = false;
    }
    setInitialMessagesLoading(false);
    return shouldConnect;
  }

  function handleConversationConnection({ candidate_id, userID }) {
    if (!candidate_id || typeof candidate_id !== "string") return;

    function onReceived() {}

    const subscriptionResult = createConversationSubscription({
      consumer,
      // job_id,
      candidate_id,
      onReceived,
      userID,
    });
    if (!isObject(subscriptionResult)) {
      return false;
    }
    if (isObject(subscriptionResult)) {
      return subscriptionResult;
    }
  }

  useEffect(
    () => {
      async function handlePreviousMessage() {
        const shouldConnectToSocket = await getPreviousConversation({
          candidate_id: viewingCandidateId,
          shouldConnectToSocket: true,
        });
        const previousSubscription = getConversationSubscription({
          candidate_id: viewingCandidateId,
        });
        if (shouldConnectToSocket && !previousSubscription) {
          const subscriptionResult = handleConversationConnection({
            candidate_id: viewingCandidateId,
            userID: user.id,
          });
          if (!subscriptionResult) {
            setConversationFetchingError("Sorry we encountered a problem. Please refresh the page");
          }
        }
      }

      // if (runAfterFirstRenderRef.current > 0) {
      dispatch(conversationSlice.actions.setChatBox(true));
      handlePreviousMessage();
      // }

      // runAfterFirstRenderRef.current = runAfterFirstRenderRef.current + 1;

      return () => {
        dispatch(conversationSlice.actions.setChatBox(false));
        // unsubscribeConversation({ onDisconnect });
      };
    },

    //eslint-disable-next-line
    [viewingCandidateId]
  );

  useEffect(() => {
    function focus() {
      const subscriptionResult = getConversationSubscription({ candidate_id: viewingCandidateId });
      if (!subscriptionResult) {
        async function handlePreviousMessage() {
          const shouldConnectToSocket = await getPreviousConversation({
            candidate_id: viewingCandidateId,
            shouldConnectToSocket: true,
          });
          const previousSubscription = getConversationSubscription({
            candidate_id: viewingCandidateId,
          });
          if (shouldConnectToSocket && !previousSubscription) {
            const subscriptionResult = handleConversationConnection({
              candidate_id: viewingCandidateId,
              userID: user.id,
            });
            if (!subscriptionResult) {
              setConversationFetchingError(
                "Sorry we encountered a problem. Please refresh the page"
              );
            }
          }
        }

        handlePreviousMessage();
      }
    }

    window.addEventListener("focus", focus);
    return () => {
      window.removeEventListener("focus", focus);
    };

    //eslint-disable-next-line
  }, [viewingCandidateId, isSendMessageIsInProgress]);

  useEffect(() => {
    if (areKeysAvailableIn({ object: newMessage, keys: ["status", "message"] })) {
      const { status, message } = newMessage;
      if (status === "new") {
        setMessages((prevMessages) => {
          const newMessages = [];
          let inProgressMessages = [];
          const prevMessagesLength = prevMessages.length;

          for (let i = 0; i < prevMessagesLength; i++) {
            const each = prevMessages[i];
            if (
              areKeysAvailableWithType({ object: each, keys: [{ isSending: true, exact: true }] })
            ) {
              inProgressMessages.push(each);
            } else {
              newMessages.push(each);
            }
          }

          newMessages.push(message);
          const afterMergeResults = mergeConversationWithSubject({
            conversation: [...newMessages, ...inProgressMessages],
          });

          setOldSubject(afterMergeResults.latestSubject);
          return afterMergeResults.conversation;
        });
      }
      if (
        status === "inProgress" &&
        areKeysAvailableIn({ object: message, keys: ["message_id"] })
      ) {
        setMessages((prevMessages) => {
          let newMessages = [];
          const prevMessagesLength = prevMessages.length;

          for (let i = 0; i < prevMessagesLength; i++) {
            const each = prevMessages[i];
            if (
              areKeysAvailableWithType({
                object: each,
                keys: [{ message_id: message.message_id, exact: true }],
              })
            ) {
              if (
                areKeysAvailableWithType({
                  object: message,
                  keys: [{ shouldRetry: true, exact: true }],
                })
              )
                continue;
              newMessages.push(message);
            } else {
              newMessages.push(each);
            }
          }

          const afterMergeResults = mergeConversationWithSubject({
            conversation: newMessages,
          });

          newMessages = afterMergeResults.conversation;

          if (
            areKeysAvailableWithType({
              object: message,
              keys: [{ shouldRetry: true, exact: true }],
            })
          ) {
            newMessages.push(message);
          }

          setOldSubject(afterMergeResults.latestSubject);
          return newMessages;
        });
      }
      dispatch(conversationSlice.actions.emptyNewMessage());
    }

    //eslint-disable-next-line
  }, [newMessage]);

  function handleSendMessage({
    value,
    newSubject,
    candidate_id,
    conversation_id,
    attachments = [],
    exclude_attachments = [],
    retrying = false,
    retryingMessageID = "",
  }) {
    const hasNewSubject = newSubject && typeof newSubject === "string" && newSubject !== oldSubject;

    const { id, first_name, last_name, logo } = user;
    const sender = {
      id,
      first_name,
      last_name,
      logo,
    };
    const sanitizedMessage = retrying ? value : sanitizeMessage(value);

    if (
      areKeysAvailableWithType({
        object: sanitizedMessage,
        keys: [{ message: "nexigon", exact: true }],
      })
    )
      return;

    const message_id = `${uniqueID}${new Date().getTime().toString(36)}`;

    const encodedConversation = encodeConversationForLocalStorage({
      data: {
        sender,
        message_id,
        message: sanitizedMessage,
        subject: hasNewSubject ? newSubject : oldSubject,
        ...(attachments && attachments.length > 0 ? { attachment: attachments } : {}),
        ...(exclude_attachments && exclude_attachments.length > 0 ? { exclude_attachments } : {}),
        ...(conversation_id ? { conversation_id } : {}),
      },
      candidate_id,
    });

    if (!encodedConversation) return;

    setCooperhireInProgressConversationToLocalStorage({
      data: encodedConversation,
      candidate_id,
      userID: id,
    });

    const decodedConversation = decodeConversationOfLocalStorage({
      data: encodedConversation,
      candidate_id,
    });

    let subscriptionResult = getConversationSubscription({ candidate_id });

    if (!subscriptionResult) {
      subscriptionResult = handleConversationConnection({ candidate_id, userID: user.id });
    }

    if (isSendMessageIsInProgress) {
      setIsSendMessageIsInProgress(false);
    }

    let sendingMessageResult;
    if (subscriptionResult) {
      const data = {
        ...decodedConversation,
        isSending: true,
        shouldRetry: false,
        ...(hasNewSubject ? { showSubject: true } : {}),
      };

      dispatch(conversationSlice.actions.setCandidateInProgressMessages({ data }));
      dispatch(inProgressConversationsSlice.actions.setConversation({ candidate_id, data }));
      sendingMessageResult = sendMessageToServer({
        data,
        candidate_id,
        message_id,
        setMessages,
        userID: id,
      });
      if (sendingMessageResult) {
        if (retrying && retryingMessageID) {
          setMessages((prev) => {
            const newMessages = prev.reduce((acc, each) => {
              if (
                areKeysAvailableWithType({
                  object: each,
                  keys: [{ message_id: retryingMessageID, exact: true }],
                })
              ) {
                return acc;
              }
              return [...acc, each];
            }, []);

            const afterMergeResults = mergeConversationWithSubject({
              conversation: [...newMessages, data],
            });
            setOldSubject(afterMergeResults.latestSubject);
            return afterMergeResults.conversation;
          });
        } else {
          setMessages((prev) => [...prev, data]);
        }
        setShouldLatestMessageInFocus(true);
        return true;
      }
    }

    if (
      !subscriptionResult ||
      (typeof sendingMessageResult === "boolean" && !sendingMessageResult)
    ) {
      setConversationFetchingError("Sorry we encountered a problem. Please refresh the page");
      return false;
    }
  }

  function handleRetryFailedEmail({ data }) {
    if (!areKeysAvailableIn({ object: data, keys: ["message", "subject", "message_id"] })) return;
    handleSendMessage({
      value: data.message,
      newSubject: data.subject,
      candidate_id: viewingCandidateId,
      ...(data.conversation_id ? { conversation_id: data.conversation_id } : {}),
      ...(data.attachment ? { attachments: data.attachment } : {}),
      ...(data.exclude_attachments ? { exclude_attachments: data.exclude_attachments } : {}),
      retrying: true,
      retryingMessageID: data.message_id,
    });
    removeCooperhireInProgressConversationFromLocalStorage({
      candidate_id: viewingCandidateId,
      message_id: data.message_id,
      userID: user.id,
    });
    dispatch(
      inProgressConversationsSlice.actions.removeConversation({
        candidate_id: viewingCandidateId,
        message_id: data.message_id,
      })
    );
    dispatch(
      conversationSlice.actions.deleteCandidateInProgressMessages({ message_id: data.message_id })
    );
  }

  function handleDeleteFailedEmail({ data }) {
    if (areKeysAvailableWithType({ object: data, keys: [{ message_id: "string" }] })) {
      setMessages((prev) => {
        const newMessages = prev.reduce((acc, each) => {
          if (
            areKeysAvailableWithType({
              object: each,
              keys: [{ message_id: data.message_id, exact: true }],
            })
          ) {
            return acc;
          }
          return [...acc, each];
        }, []);

        const afterMergeResults = mergeConversationWithSubject({
          conversation: [...newMessages],
        });
        setOldSubject(afterMergeResults.latestSubject);
        return afterMergeResults.conversation;
      });
      removeCooperhireInProgressConversationFromLocalStorage({
        candidate_id: viewingCandidateId,
        message_id: data.message_id,
        userID: user.id,
      });
      dispatch(
        inProgressConversationsSlice.actions.removeConversation({
          candidate_id: viewingCandidateId,
          message_id: data.message_id,
        })
      );
      dispatch(
        conversationSlice.actions.deleteCandidateInProgressMessages({ message_id: data.message_id })
      );
    }
  }

  const handleRetry = async () => {
    if (conversationFetchingError) setConversationFetchingError("");
    async function handlePreviousMessage() {
      const shouldConnectToSocket = await getPreviousConversation({
        candidate_id: viewingCandidateId,
        shouldConnectToSocket: true,
      });
      const previousSubscription = getConversationSubscription({
        candidate_id: viewingCandidateId,
      });
      if (shouldConnectToSocket && !previousSubscription) {
        const subscriptionResult = handleConversationConnection({
          candidate_id: viewingCandidateId,
          userID: user.id,
        });
        if (!subscriptionResult) {
          setConversationFetchingError("Sorry we encountered a problem. Please refresh the page");
        }
      }
    }
    handlePreviousMessage();
  };

  async function handleDeleteBackendFailedEmail({ data }) {
    function noDelete() {
      ShowToastWithTranslation({
        toast,
        Children: OperationFailedWithTranslation,
        text: t("Oops, can't delete at the moment!"),
      });
    }

    function reset() {
      setMessages((prev) => {
        const newMessages = prev.reduce((acc, each) => {
          if (areKeysAvailableWithType({ object: each, keys: [{ id: data.id, exact: true }] })) {
            return [...acc, { ...each, shouldRetryOfBackend: true, isDeleting: false }];
          }
          return [...acc, each];
        }, []);

        const afterMergeResults = mergeConversationWithSubject({
          conversation: newMessages,
        });
        setOldSubject(afterMergeResults.latestSubject);
        return afterMergeResults.conversation;
      });
    }

    try {
      if (!areKeysAvailableIn({ object: data, keys: ["id"] })) {
        noDelete();
        return;
      }

      setMessages((prev) => {
        const newMessages = prev.reduce((acc, each) => {
          if (areKeysAvailableWithType({ object: each, keys: [{ id: data.id, exact: true }] })) {
            return [...acc, { ...each, shouldRetryOfBackend: false, isDeleting: true }];
          }
          return [...acc, each];
        }, []);

        const afterMergeResults = mergeConversationWithSubject({
          conversation: newMessages,
        });
        setOldSubject(afterMergeResults.latestSubject);
        return afterMergeResults.conversation;
      });

      const response = await backendAPI.delete(`/conversations/${data.id}`);

      if (
        areKeysAvailableWithType({
          object: response.data,
          keys: [{ message: "Conversation deleted successfully.", exact: true }],
        })
      ) {
        setMessages((prev) => {
          const newMessages = prev.reduce((acc, each) => {
            if (areKeysAvailableWithType({ object: each, keys: [{ id: data.id, exact: true }] })) {
              return acc;
            }
            return [...acc, each];
          }, []);

          const afterMergeResults = mergeConversationWithSubject({
            conversation: newMessages,
          });
          setOldSubject(afterMergeResults.latestSubject);
          return afterMergeResults.conversation;
        });
        ShowToastWithTranslation({
          toast,
          Children: SuccessfulToast,
          text: t("Deleted successfully!"),
        });
        return;
      }
      noDelete();
      reset();
    } catch (error) {
      noDelete();
      reset();
    }
  }

  return (
    <View
      borderRadius="large"
      attributes={{
        style: {
          position: "relative",
          height: "calc(100vh - 20rem)",
        },
      }}
    >
      <View
        direction="row"
        gap={sm}
        padding={xl}
        paddingBottom={sm}
        backgroundColor="white"
        align="center"
        borderRadius="medium"
        attributes={{
          style: {
            position: "absolute",
            top: 0,
            left: 0,
            right: "2rem",
            zIndex: 2,
          },
        }}
      >
        <Message />
        <Text variant="body-1" color="neutral">
          {t("Messages")}
        </Text>
      </View>
      {initialMessagesLoading && !conversationFetchingError && <ReshapedLoader />}
      {!initialMessagesLoading && !conversationFetchingError && (
        <>
          <ChatMessages
            {...{
              messages,
              setMessages,
              isTextInFocus,
              setIsTextInFocus,
              messagesBoxHeight,
              viewingCandidateId,
              handleRetryFailedEmail,
              handleDeleteFailedEmail,
              shouldLatestMessageInFocus,
              setShouldLatestMessageInFocus,
              handleDeleteBackendFailedEmail,
              messagesExhaustedAtInitialFetchRef,
            }}
          />
          <ChatInput
            {...{
              oldSubject,
              isTextInFocus,
              setOldSubject,
              setIsTextInFocus,
              handleSendMessage,
              setMessagesBoxHeight,
              setIsSendMessageIsInProgress,
            }}
          />
        </>
      )}
      {conversationFetchingError && !initialMessagesLoading && (
        <View.Item grow>
          <View height="100%" align="center" justify="center" gap={lg}>
            <Text variant="body-strong-1" color="critical">
              {t(conversationFetchingError)}
            </Text>
            <Button
              color="critical"
              size="small"
              startIcon={() => <Reload fill={baseColor.white} />}
              onClick={handleRetry}
            >
              {t("Please try again.")}
            </Button>
          </View>
        </View.Item>
      )}
    </View>
  );
};
