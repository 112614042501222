import { useContext, useMemo } from "react";

import strings from "translations/strings";
import { isLocale } from "translations/types";
import { defaultLocale } from "translations/config";
import { LocaleContext } from "translations/LocaleContext";

export default function useTranslation() {
  const { locale } = useContext(LocaleContext);

  function t(key) {
    var userLocale = locale;

    if (locale === undefined) {
      userLocale = localStorage.getItem("locale");
    }

    userLocale = isLocale(userLocale) ? userLocale : defaultLocale;

    if (!userLocale || !strings[userLocale] || !strings[userLocale][key]) {
      console.warn(`Translation '${key}' for locale '${userLocale}' not found.`);
      return key;
    }

    return strings[userLocale][key] || "";
  }

  const values = useMemo(() => {
    return {
      t,
      locale,
    };

    //eslint-disable-next-line
  }, [locale]);

  return values;
}
