import { Container, View } from "reshaped";
import common from "components/styles/reshaped/reusables/common.module.css";

export const ContainerWrapper = (props) => {
  const {
    children,
    withContainer = true,
    extraClass = "",
    containerClass = "",
    padding = 8,
    wrapperStyles = {},
    wrapperAttributes = {},
  } = props;

  return (
    <View
      as="div"
      className={`${common.container__wrapper} ${extraClass}`}
      attributes={{
        ...(wrapperAttributes && typeof wrapperAttributes === "object" ? wrapperAttributes : {}),
        style: {
          ...wrapperStyles,
        },
      }}
    >
      {withContainer && (
        <Container className={`${common.container__full} ${containerClass}`} padding={padding}>
          {children}
        </Container>
      )}
      {!withContainer && children}
    </View>
  );
};
