import React, { useState } from "react";
import useSpacing from "components/hooks/useSpacing";
import { ShowToastWithTranslation } from "utils/showToast";
import useTranslation from "components/hooks/useTranslation";
import styles from "components/styles/reshaped/settings.module.css";
import { OperationFailedWithTranslation } from "components/reusables";
import { Button, Checkbox, Dismissible, Modal, Text, View, useToast } from "reshaped";
import { getPlanDetails } from "utils/pages/settings";

const ChangePlanModal = ({
  active,
  deactivate,
  modalHeading,
  planChangeText,
  planChangeSummary,
  subscriptionPrices,
  checkoutLoading,
  planChangeRequest,
}) => {
  const toast = useToast();
  const { t } = useTranslation();
  const { sm, md, lg, xl } = useSpacing();

  const [isAccepted, setIsAccepted] = useState(false);
  const [showError, setShowError] = useState(false);

  return (
    <Modal size="700px" active={active} onClose={deactivate}>
      <Dismissible onClose={deactivate} closeAriaLabel="Close modal">
        <View
          justify={"center"}
          align={"center"}
          attributes={{
            style: {
              paddingInline: xl * 4,
              paddingBlock: lg * 2,
              borderRadius: lg * 2,
            },
          }}
        >
          <Text
            variant="title-3"
            attributes={{
              style: {
                textAlign: "center",
                marginBottom: xl * 2,
              },
            }}
          >
            {t(modalHeading)}
          </Text>

          <View gap={sm} direction={"column"}>
            {planChangeText.map((text, id) => (
              <Text className={styles.subscription__modal__text} key={id}>
                {<span dangerouslySetInnerHTML={{ __html: text }} />}
              </Text>
            ))}
          </View>
          <View attributes={{
            style: {
              marginTop: "10px"
            }
          }}>
            <Checkbox hasError={showError} name="accept" value="accept" checked={isAccepted}
              attributes={{
                onChange: () => {
                  setIsAccepted(!isAccepted);
                  setShowError(false);
                }
              }}>
              <Text variant="body-strong-2">{t("I have reviewed the summary and accept changes")}.</Text>
            </Checkbox>
          </View>
          <View
            direction={"row"}
            gap={md}
            attributes={{
              style: {
                marginTop: xl * 2,
              },
            }}
          >
            <Button
              loading={checkoutLoading}
              color="primary"
              disabled={!isAccepted}
              attributes={{
                style: {
                  width: "100%",
                },
                onClick: () => {
                  const { planLimitsChangeType, changeType, currentUsersCount, currentJobsCount, new_price_id } =
                    planChangeSummary;

                  let newPlanDetails = getPlanDetails(new_price_id, subscriptionPrices);
                  let maxUsers = newPlanDetails.usersLimit;
                  let maxJobs = newPlanDetails.jobsLimit;

                  if (
                    planLimitsChangeType === "downgrade" &&
                    changeType === "upgrade" &&
                    (currentUsersCount > maxUsers || currentJobsCount > maxJobs)
                  ) {
                    ShowToastWithTranslation({
                      toast,
                      Children: OperationFailedWithTranslation,
                      text: t("Please limit the users and jobs before downgrading the plan!"),
                    });
                    return;
                  }
                  planChangeRequest(planChangeSummary.changeType, planChangeSummary.new_price_id);
                },
              }}
            >
              <Text>{t("Confirm")}</Text>
            </Button>
            <Button
              color="critical"
              variant="outline"
              attributes={{
                style: {
                  width: "100%",
                },
                onClick: () => {
                  deactivate();
                },
              }}
            >
              <Text>{t("Cancel")}</Text>
            </Button>
          </View>
        </View>
      </Dismissible>
    </Modal >
  );
};

export default ChangePlanModal;
