import { useContext } from "react";
import ModalContext from "components/context/modal/ModalContext";

const useModalContext = () => {
  const modalContext = useContext(ModalContext);
  const { isModalOpen, setIsModalOpen } = modalContext;
  return { isModalOpen, setIsModalOpen };
};

export default useModalContext;
