import React from "react";
import { View, Text } from "reshaped";
import useSpacing from "components/hooks/useSpacing";
import styles from "components/styles/reshaped/settings.module.css";
import common from "components/styles/reshaped/reusables/common.module.css";
import useTranslation from "components/hooks/useTranslation";

const PricingToggleTabs = ({ planType, handlePlanTypeChange }) => {
  const { sm, xl } = useSpacing();
  const { t } = useTranslation();
  
  return (
    <View
      direction="row"
      backgroundColor={"neutral-faded"}
      align={"center"}
      justify={"center"}
      className={styles.subscription__toggle__wrapper}
    >
      <View
        as="button"
        height="38px"
        padding={[0, xl + sm]}
        borderRadius="medium"
        className={`${styles.subscription__toggle__tabs} ${common.category__small} ${
          planType === "monthly"
            ? `${styles.subscription__toggle__tab__active} ${common.active}`
            : ""
        }`}
        attributes={{ onClick: () => handlePlanTypeChange("monthly") }}
        direction="row"
        justify="center"
        align="center"
      >
        <Text variant={planType === "monthly" ? "body-strong-2" : "body-2"}>{t("Monthly")}</Text>
      </View>

      <View
        as="button"
        height="38px"
        padding={[0, xl + sm]}
        borderRadius="medium"
        className={`${styles.subscription__toggle__tabs} ${common.category__small} ${
          planType === "yearly"
            ? `${styles.subscription__toggle__tab__active} ${common.active}`
            : ""
        }`}
        attributes={{ onClick: () => handlePlanTypeChange("yearly") }}
        direction="row"
        justify="center"
        align="center"
      >
        <Text variant={planType === "yearly" ? "body-strong-2" : "body-2"}>{t("Yearly") }</Text>
      </View>
    </View>
  );
};

export default PricingToggleTabs;
