import { ATS_JOBS_FRONTEND_URL } from "constants";
import { getSanitizedJobURL } from "utils/urlHelpers";

export function copyJobURL({ jobTitle = "", companyID, jobID, slug }) {
  if (!companyID && !jobID && !jobTitle) return;
  const URL = getSanitizedJobURL({ jobID, companyID, jobTitle, slug });
  navigator?.clipboard?.writeText(URL);
}

export function copyCompanyURL({ slug }) {
  if (!slug) return;

  const COOPERHIRE_JOBS_URL = ATS_JOBS_FRONTEND_URL;
  const companiesPostfix = `${COOPERHIRE_JOBS_URL}/companies/${slug}`;

  navigator?.clipboard?.writeText(`${companiesPostfix}`);
}

export function copyTextToClipboard({ text = "" }) {
  navigator?.clipboard?.writeText(text);
}
