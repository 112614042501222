import axios from "axios";
import { NINJA_FILTER_HOST } from "constants";
import { ATS_BACKEND_HOST } from "constants";

// Can be changed URL of backend server
const baseURL = ATS_BACKEND_HOST;

export const backendAPI = axios.create({
  baseURL,
});

const ninjaFilterBaseURL = NINJA_FILTER_HOST;

export const ninjaFilterAPI = axios.create({
  baseURL: ninjaFilterBaseURL,
});