import React, { useEffect, useState } from "react";

import { Key, TickCircle2 } from "icons";
import { PublicTemplate } from "components/reusables";
import useTranslation from "components/hooks/useTranslation";
import { useNavigate, useSearchParams } from "react-router-dom";
import { NewPassword, UpdateSuccessful } from "components/pages/resetPassword";

const Accept = () => {
  const [token, setToken] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [resetPasswordStage, setResetPasswordStage] = useState("new");

  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    let URLToken = searchParams.get("invitation_token");
    if (!URLToken) {
      navigate("/login");
      return;
    }
    setToken(URLToken);
    //eslint-disable-next-line
  }, []);

  return (
    <>
      {resetPasswordStage === "new" && (
        <PublicTemplate
          IconSvg={Key}
          title={t("Set a password")}
          subTitle={t("Your password must be very strong with special characters")}
        >
          <NewPassword
            isInvitation={true}
            invitationToken={token}
            {...{
              newPassword,
              setNewPassword,
              confirmPassword,
              setConfirmPassword,
              resetPasswordStage,
              setResetPasswordStage,
            }}
          />
        </PublicTemplate>
      )}
      {resetPasswordStage === "success" && (
        <PublicTemplate
          IconSvg={TickCircle2}
          title={t("Password set")}
          subTitle={t("Your password has been set successfully")}
        >
          <UpdateSuccessful />
        </PublicTemplate>
      )}
    </>
  );
};

export default Accept;
