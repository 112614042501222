import { backendAPI } from "utils/axios";
import { logoutUser } from "utils/checkForAuth";
import { ShowToastWithTranslation } from "utils/showToast";
import { areKeysAvailableWithType } from "utils/miniHelpers";
import { updateCompanyInAPI } from "redux/company/companySlice";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SuccessfulToast } from "components/reusables/tab/SuccessfulToast";

const initialState = {
  isAuthenticated: false,
  user: null,
  loading: null,
  error: null,
};

export const getUserFromAPI = createAsyncThunk("auth/getUser", async () => {
  try {
    const res = await backendAPI.get("/user");
    const { data, status } = res;
    if (
      areKeysAvailableWithType({
        object: res,
        keys: [{ status: 200, exact: true }, { data: "object" }],
      }) &&
      areKeysAvailableWithType({ object: res.data.user, keys: [{ email: "string", id: "number" }] })
    ) {
      return { data, status };
    }
    return { status: 401, data: null };
  } catch (error) {
    return { status: 401, data: null };
  }
});

export const getUserAndSubscriptionFromAPI = createAsyncThunk(
  "auth/getSubscriptionAndUser",
  async () => {
    try {
      const { data, status } = getUserFromAPI();
      const res = await backendAPI.get("/companies/subscriptions");

      if (
        areKeysAvailableWithType({
          object: res,
          keys: [{ status: 200, exact: true }, { data: "object" }],
        }) &&
        areKeysAvailableWithType({
          object: res.data,
          keys: [{ subscription: "object" }, { company: "object" }],
        })
      ) {
        return { data, status };
      }
      return { status: 401, data: null };
    } catch (error) {
      return { status: 401, data: null };
    }
  }
);

export const updateUserInAPI = createAsyncThunk(
  "auth/updateUser",
  async ({ updatedUserData, toast, t }, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      for (let key in updatedUserData) {
        formData.append(`user[${key}]`, updatedUserData[key]);
      }
      const response = await backendAPI.put("/user", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      const { data, status = 401 } = response;
      if (status === 200 && data.user) {
        ShowToastWithTranslation({
          toast,
          Children: SuccessfulToast,
          text: t("User updated!"),
        });
        return { user: { ...data.user }, status };
      }
      if (status === 401 || data.error) {
        return { error: data.error, status };
      }
    } catch (error) {
      if (error && error.response) {
        const { status } = error.response;
        if (status === 422) {
          return {
            error: t("JPG, JPEG, PNG and GIF are allowed in profile"),
            status,
          };
        }
      }
      rejectWithValue(t("Something went wrong"));
    }
  }
);

export const updateEmailInAPI = createAsyncThunk(
  "auth/updateEmail",
  async (email, { rejectWithValue }) => {
    try {
      const res = new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve("email is not registered");
        }, 1000);
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.error = null;
    },
    reset: (state) => {
      for (let key in initialState) {
        state[key] = initialState[key];
      }
    },
  },
  extraReducers: (builder) => {
    // update user company details on company info update
    builder.addCase(updateCompanyInAPI.fulfilled, (state, action) => {
      const { payload } = action;
      if (
        payload &&
        payload?.status === 200 &&
        payload?.company &&
        typeof payload.company === "object" &&
        "slug" in payload.company &&
        "country" in payload.company
      ) {
        const { slug: updatedSlug, country: updatedCountry, country_code: updatedCountryCode } = payload.company;
        state.user.company = {
          currency: updatedCountry === "United Kingdom" ? "GBP" : "EUR",
          slug: updatedSlug,
          country: updatedCountry,
          country_code: updatedCountryCode
        };
      }
    });

    builder.addCase(getUserFromAPI.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserFromAPI.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload) {
        const { data, status } = action.payload;
        if (status === 200 && data) {
          state.isAuthenticated = true;
          state.user = data.user;
        }
        if (!data || status === 401) {
          state.user = null;
          state.isAuthenticated = false;
          document.cookie = "user-x-auth=; Max-Age=0;secure";
          logoutUser();
        }
      }
    });
    builder.addCase(getUserFromAPI.rejected, (state, action) => {
      state.loading = false;
      state.user = null;
      state.isAuthenticated = false;
      document.cookie = "user-x-auth=; Max-Age=0;secure";
      logoutUser();
    });

    builder.addCase(getUserAndSubscriptionFromAPI.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserAndSubscriptionFromAPI.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload) {
        const { data, status } = action.payload;
        if (status === 200 && data) {
          state.isAuthenticated = true;
          state.user = data.user;
        }
        if (!data || status === 401) {
          state.user = null;
          state.isAuthenticated = false;
          document.cookie = "user-x-auth=; Max-Age=0;secure";
          logoutUser();
        }
      }
    });
    builder.addCase(getUserAndSubscriptionFromAPI.rejected, (state, action) => {
      state.loading = false;
      state.user = null;
      state.isAuthenticated = false;
      document.cookie = "user-x-auth=; Max-Age=0;secure";
      logoutUser();
    });

    builder.addCase(updateUserInAPI.fulfilled, (state, action) => {
      const errorMessage = "Something went wrong";
      if (!action.payload) state.error = errorMessage;
      if (action.payload) {
        const { status, error = errorMessage } = action.payload;
        if (status === 200) state.user = action.payload.user;
        if (status === 401 || action.payload.error) state.error = action.payload.error || error;
      }
    });
    builder.addCase(updateUserInAPI.rejected, (state, action) => {
      state.error = action.payload;
    });

    builder.addCase(updateEmailInAPI.fulfilled, (state, action) => {
      // console.log(action.payload);
    });
    builder.addCase(updateEmailInAPI.rejected, (state, action) => {
      console.log(action.payload);
    });
  },
});
