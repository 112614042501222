import React, { useCallback, useMemo, useRef, useState } from "react";
import { Text, View, Button, Modal, Icon, useToast } from "reshaped";

import { metaSlice } from "redux/meta";
import { backendAPI } from "utils/axios";
import { Cross, TickCircle2 } from "icons";
import { candidateSlice } from "redux/candidate";
import { baseColor } from "utils/colors/accents";
import { useSearchParams } from "react-router-dom";
import useSpacing from "components/hooks/useSpacing";
import { ReshapedLoader } from "components/reusables";
import { useDispatch, useSelector } from "react-redux";
import { reloadOnUnauthorized } from "utils/checkForAuth";
import { ShowToastWithTranslation } from "utils/showToast";
import useTranslation from "components/hooks/useTranslation";
import { setInitialJobErrors } from "utils/setInitialErrors";
import { areKeysAvailableWithType } from "utils/miniHelpers";
import { fetchFilteredCandidates } from "utils/pages/candidate";
import settings from "components/styles/reshaped/settings.module.css";
import { candidateValidationHelper } from "utils/candidateValidationHelper";
import useUserLanguagePreference from "components/hooks/useUserLanguagePreference";
import { OperationFailedWithTranslation } from "components/reusables/OperationFailed";
import { UploadFile, CandidateInput, SelectAppliedJob } from "components/pages/candidates";
import { sanitizeWord, validateJobErrors, validateNotRequiredJobErrors } from "utils/validateError";
import {
  EMAIL,
  SOURCE,
  NUMBER,
  LAST_NAME,
  FIRST_NAME,
  APPLIED_JOB,
  LINKEDIN_URL,
} from "utils/constants";

export const ErrorContext = React.createContext();

export const AddCandidate = (props) => {
  const { active, deactivate } = props;
  const [email, setEmail] = useState("");
  // const [notes, setNotes] = useState("");
  const [source, setSource] = useState("");
  const [number, setNumber] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [appliedJob, setAppliedJob] = useState("");
  const [linkedinURL, setLinkedinURL] = useState("");
  const [filesError, setFilesError] = useState(null);
  const [loadingText, setLoadingText] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [addCandidateStage, setAddCandidateStage] = useState("add");
  const [errors, setErrors] = useState(
    setInitialJobErrors([FIRST_NAME, LAST_NAME, EMAIL, APPLIED_JOB])
  );

  const toast = useToast();
  const inputRef = useRef(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { sm, lg, xl } = useSpacing();
  const state = useSelector((state) => state);
  const userLang = useUserLanguagePreference();
  const [searchParams, setSearchParams] = useSearchParams();

  const { user } = state.auth;
  const { candidates: candidatesFilters } = state.filters;
  const { candidates: candidatesSorting } = state.sorting;
  const { loading, pageCount, shouldCandidatesRefetch } = state.candidate;

  const { jobs, stages, search } = candidatesFilters;
  const { selected: selectedJobs } = jobs;
  const { selected: selectedStages } = stages;
  const { selected: selectedSearches } = search;

  const { setLoading, stopLoading } = candidateSlice.actions;

  const filesErrorProps = useMemo(() => {
    return {
      filesError,
      setFilesError,
    };

    //eslint-disable-next-line
  }, [filesError]);

  const uploadFilesProps = useMemo(() => {
    return {
      uploadedFiles,
      setUploadedFiles,
    };

    //eslint-disable-next-line
  }, [uploadedFiles]);

  const handleCloseModalWithNoStateChange = () => {
    if (searchParams.has("to")) {
      searchParams.delete("to");
      setSearchParams(searchParams);
    }
    deactivate();
    dispatch(metaSlice.actions.setAddCandidateShortcut(false));
  };

  const handleRefetchCandidates = async () => {
    dispatch(
      candidateSlice.actions.set({
        isFiltering: true,
        filteringError: null,
      })
    );

    const response = await fetchFilteredCandidates({
      company_id: user.company_id,
      selectedJobs,
      selectedStages,
      sorting: candidatesSorting,
      from: pageCount,
      current_language: userLang,
      selectedSearches: selectedSearches
    });

    if (areKeysAvailableWithType({ object: response, keys: [{ data: "object" }] })) {
      const { candidates, totalCandidates } = response.data;
      return dispatch(
        candidateSlice.actions.set({
          isFiltering: false,
          candidates,
          totalCandidates,
          shouldCandidatesRefetch: false,
        })
      );
    }

    dispatch(
      candidateSlice.actions.set({
        isFiltering: false,
        filteringError: "something went wrong!",
      })
    );
  };

  const handleModalClose = () => {
    handleCloseModalWithNoStateChange();
    if (shouldCandidatesRefetch) {
      handleRefetchCandidates();
    }
    setTimeout(() => {
      setEmail("");
      // setNotes("");
      setSource("");
      setNumber("");
      setLastName("");
      setFirstName("");
      setAppliedJob("");
      setAppliedJob("");
      setLinkedinURL("");
      setUploadedFiles([]);
      setAddCandidateStage("add");
      setErrors(setInitialJobErrors([FIRST_NAME, LAST_NAME, EMAIL, APPLIED_JOB]));
    }, 200);
  };

  const handleUpload = () => {
    inputRef.current.value = "";
    inputRef.current.click();
    if (setFilesError) setFilesError(null);
  };

  const handleChange = (e) => {
    const supportedFormat = {
      doc: true,
      DOC: true,
      docx: true,
      DOCX: true,
      pdf: true,
      PDF: true,
      xls: true,
      XLS: true,
    };
    const { length } = e.target.files;

    if (length <= 0) return;

    const noOfFilesUploaded = length <= 5 ? length : 5;
    const noOfPreviouslyUploadedFiles = uploadedFiles.length;
    const totalNoOfFiles = length + noOfPreviouslyUploadedFiles;

    if (totalNoOfFiles > 5) return setFilesError(t("Documents should not exceed more than 5"));

    for (let i = 0; i < noOfFilesUploaded; i++) {
      const file = e.target.files[i];
      if (file.size > 5242880) {
        setFilesError(t("Uploaded document size should be less than 5MB"));
        break;
      }
      const extensionRegex = /(?:\.([^.]+))?$/;
      const fileName = file.name;
      const uploadedExtension = extensionRegex.exec(fileName);
      if (!uploadedExtension) continue;
      const uploadedFileExtension = uploadedExtension[1];
      const isFileSupported = supportedFormat[uploadedFileExtension];
      if (!isFileSupported) continue;
      setUploadedFiles((previousFiles) => {
        return [
          {
            type: uploadedFileExtension,
            file,
          },
          ...previousFiles,
        ];
      });
    }
  };

  const handleValidation = useCallback(({ id, value }) => {
    const { regex, message, ...rest } = candidateValidationHelper(id);
    return validateJobErrors(id, value, setErrors, regex, message, t, rest);

    //eslint-disable-next-line
  }, []);

  const handleNotRequiredValidation = useCallback(({ id, value }) => {
    const { regex, message, ...rest } = candidateValidationHelper(id);
    return validateNotRequiredJobErrors(id, value, setErrors, regex, message, t, rest);

    //eslint-disable-next-line
  }, []);

  const createCandidate = async (candidate) => {
    try {
      setLoadingText(t("Please wait adding candidate"));
      dispatch(setLoading());
      const formData = new FormData();

      for (let key in candidate) {
        if (key === "documents") {
          for (let i = 0; i < candidate[key].length; i++) {
            formData.append(`candidate[${key}][]`, candidate[key][i]);
          }
        } else {
          formData.append(`candidate[${key}]`, candidate[key]);
        }
      }

      const response = await backendAPI.post("/candidates", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const { data, status = 401 } = response;

      if (status === 200 && data.candidate) {
        setAddCandidateStage("success");
        dispatch(candidateSlice.actions.set({ shouldCandidatesRefetch: true }));
      }

      if (status === 422 || status === 401 || data.error) {
        ShowToastWithTranslation({
          toast,
          Children: OperationFailedWithTranslation,
          text: t("Operation unsuccessful!"),
        });
        handleModalClose();
      }

      setTimeout(() => {
        dispatch(stopLoading());
        setLoadingText("");
      }, 200);
    } catch (error) {
      reloadOnUnauthorized(error);
      if (error.response && error.response.status === 422 && error.response.data) {
        for (let key in error.response.data) {
          setErrors((prev) => {
            return {
              ...prev,
              [key]: error.response.data[key][0],
            };
          });
        }
      } else {
        handleModalClose();
      }

      ShowToastWithTranslation({
        toast,
        Children: OperationFailedWithTranslation,
        text: t("Operation unsuccessful!"),
      });

      setTimeout(() => {
        dispatch(candidateSlice.actions.stopLoading());
        setLoadingText("");
      }, 200);
    }
  };

  const handleSubmit = () => {
    let hasError = false;

    for (let field in errors) {
      if (errors[field]) {
        hasError = true;
        if (typeof errors[field] === "object") {
          setErrors((previousError) => {
            return {
              ...previousError,

              //eslint-disable-next-line
              [field]: t(`${sanitizeWord(field, true)} is required`),
            };
          });
        }
      }
    }

    if (hasError) {
      return;
    }

    const candidate = {
      email,
      phone_no: number,
      utm_source: source,
      job_id: appliedJob,
      last_name: lastName,
      first_name: firstName,
      // notes,
      linkedin_url: linkedinURL,
      documents: uploadedFiles.length > 0 ? uploadedFiles.map((upload) => upload.file) : null,
    };

    for (let key in candidate) {
      if (key !== "documents") candidate[key] = candidate[key].replace(/^\s+|\s+$/g, "");
      if (!candidate[key]) delete candidate[key];
    }

    createCandidate(candidate);
  };

  return (
    <Modal
      className={settings.modal}
      size={{ s: addCandidateStage === "add" && !loading ? "auto" : "300px", m: "600px" }}
      active={active}
      padding={0}
      onClose={handleCloseModalWithNoStateChange}
    >
      <Button
        className={settings.modal__close__btn}
        size="small"
        variant="ghost"
        startIcon={() => <Cross fill={baseColor.black} />}
        onClick={handleModalClose}
      />
      {!loading && addCandidateStage === "add" && (
        <View padding={xl}>
          <View gap={lg}>
            <Text variant="title-3">{t("Add Candidate")}</Text>
            <View gap={lg} direction="row">
              <ErrorContext.Provider value={{ errors, setErrors }}>
                <CandidateInput
                  inputValue={firstName}
                  setInputValue={setFirstName}
                  title={t("First Name")}
                  propTitle={FIRST_NAME}
                  handleValidation={handleValidation}
                  inputAttributes={{ autoFocus: true, "data-cooper": "add candidate first name" }}
                  isRequired
                />
                <CandidateInput
                  inputValue={lastName}
                  setInputValue={setLastName}
                  title={t("Last Name")}
                  propTitle={LAST_NAME}
                  handleValidation={handleValidation}
                  inputAttributes={{ "data-cooper": "add candidate last name" }}
                  isRequired
                />
                <CandidateInput
                  inputValue={email}
                  setInputValue={setEmail}
                  title={t("Email")}
                  propTitle={EMAIL}
                  handleValidation={handleValidation}
                  inputAttributes={{ "data-cooper": "add candidate email" }}
                  isRequired
                />
                <CandidateInput
                  inputValue={number}
                  setInputValue={setNumber}
                  title={t("Phone number")}
                  propTitle={NUMBER}
                  handleNotRequiredValidation={handleNotRequiredValidation}
                  inputAttributes={{ "data-cooper": "add candidate phone" }}
                />
                <CandidateInput
                  inputValue={linkedinURL}
                  setInputValue={setLinkedinURL}
                  title={t("LinkedIn URL")}
                  propTitle={LINKEDIN_URL}
                  handleNotRequiredValidation={handleNotRequiredValidation}
                  columns={12}
                  inputAttributes={{ "data-cooper": "add candidate linkedin" }}
                />
                <CandidateInput
                  inputValue={source}
                  setInputValue={setSource}
                  title={t("Candidate source")}
                  propTitle={SOURCE}
                  handleNotRequiredValidation={handleNotRequiredValidation}
                  columns={12}
                />
                <SelectAppliedJob
                  inputValue={appliedJob}
                  setInputValue={setAppliedJob}
                  propTitle={APPLIED_JOB}
                  handleValidation={handleValidation}
                  isRequired
                />
              </ErrorContext.Provider>
              <View.Item columns={12}>
                <View divided gap={lg}>
                  {/* <ErrorContext.Provider value={{ errors, setErrors }}>
                    <CandidateTextarea
                      inputValue={notes}
                      setInputValue={setNotes}
                      title="Candidate Notes"
                      propTitle={NOTES}
                      handleNotRequiredValidation={handleNotRequiredValidation}
                    />
                  </ErrorContext.Provider> */}
                  <View.Item columns={12}>
                    <View gap={sm}>
                      <View direction="row" align="center">
                        <View.Item grow={true}>
                          <Text variant="body-strong-1">{t("Your Documents")}</Text>
                        </View.Item>
                        <input
                          type="file"
                          multiple
                          accept=".pdf, .docx, .doc, .xls"
                          style={{ width: "0px", height: "0px" }}
                          ref={inputRef}
                          onChange={handleChange}
                          tabIndex={-1}
                        />
                        <Button
                          variant="outline"
                          size="small"
                          color="primary"
                          onClick={handleUpload}
                        >
                          {t("Upload New")}
                        </Button>
                      </View>
                      {filesError && (
                        <Text color="critical" variant="body-medium-2">
                          {filesError}
                        </Text>
                      )}
                      <UploadFile {...filesErrorProps} {...uploadFilesProps} />
                    </View>
                  </View.Item>
                </View>
              </View.Item>
            </View>
            <View direction="row" gap={lg} align="end" width="100%" justify="end">
              <Button color="critical" variant="outline" onClick={handleModalClose}>
                {t("Cancel")}
              </Button>
              <Button
                color="primary"
                onClick={handleSubmit}
                attributes={{ "data-cooper": "save new candidate form" }}
              >
                {t("Save")}
              </Button>
            </View>
          </View>
        </View>
      )}
      {!loading && addCandidateStage === "success" && (
        <View padding={xl} height="200px" width="100%" align="center" justify="center" gap={4}>
          <View align="center" justify="stretch" gap={1}>
            <View
              width="40px"
              height="40px"
              borderRadius="medium"
              align="center"
              justify="center"
              direction="row"
              backgroundColor="primary-faded"
            >
              <Icon svg={TickCircle2} size={5} />
            </View>
            <Text align="center" variant="title-2" as="h1" color="neutral">
              {t("Success")}
            </Text>
            <Text align="center" variant="body-medium-1" as="p" color="neutral-faded">
              {t("Candidate added successfully!")}
            </Text>
          </View>
        </View>
      )}

      {loading && (
        <View height="200px" width="100%" direction="row" align="center" justify="center">
          <ReshapedLoader text={loadingText} />
        </View>
      )}
    </Modal>
  );
};
