import { Divider, View } from "reshaped";
import useSpacing from "components/hooks/useSpacing";
import { JobBoards, TitleGroup } from "components/pages/promote";

export const JobBoardSection = () => {
  const { sm, lg, xl } = useSpacing();

  return (
    <View gap={xl + sm} attributes={{ style: { marginBottom: "var(--spacing-xxl)" } }}>
      <View gap={lg}>
        <TitleGroup
          title={"Top job boards for you"}
          subTitle={"Tailor made job boards for your hiring needs"}
        />
        <Divider attributes={{ style: { backgroundColor: "var(--rs-color-border-neutral)" } }} />
      </View>
      <JobBoards />
    </View>
  );
};
