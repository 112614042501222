import { useMemo } from "react";
import { useSelector } from "react-redux";

const useCurrency = () => {
  const auth = useSelector((state) => state.auth);

  const { user } = auth;
  const { company } = user;
  const { currency, country } = company;

  const currencySymbol = useMemo(() => {
    const currencySymbols = {
      GBP: "£",
      EUR: "€",
    };

    return currencySymbols[currency] ?? "€";
  }, [currency]);

  return {
    country,
    currencySymbol,
  };
};

export default useCurrency;
