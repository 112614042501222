export const restrictedEmails = {
  cc: [
    "487.nut.cc",
    "babau.usa.cc",
    "bloxter.cu.cc",
    "flu.cc",
    "goooogle.usa.cc",
    "jamesbond.usa.cc",
    "merda.usa.cc",
    "nut.cc",
    "purple.usa.cc",
    "rr-1.cu.cc",
    "securemail.usa.cc",
    "shitaway.flu.cc",
    "0-00.usa.cc",
    "2.0-00.usa.cc",
    "291.usa.cc",
    "3ew.usa.cc",
    "466453.usa.cc",
    "806.flu.cc",
    "[maildrop.cc",
    "a.a.fbmail.usa.cc",
    "a1.usa.cc",
    "a2.flu.cc",
    "abnamro.usa.cc",
    "airmail.cc",
    "alexandria.cc",
    "asiarap.usa.cc",
    "ay33rs.flu.cc",
    "b0.nut.cc",
    "babau.flu.cc",
    "babau.nut.cc",
    "blow-job.nut.cc",
    "bongobongo.flu.cc",
    "bongobongo.nut.cc",
    "bongobongo.usa.cc",
    "c.nut.emailfake.nut.cc",
    "cam4you.cc",
    "cc-cc.usa.cc",
    "cdpa.cc",
    "chilepro.cc",
    "cloudns.cc",
    "co.cc",
    "contrasto.cu.cc",
    "creo.cloudns.cc",
    "cu.cc",
    "cz.cc",
    "dhy.cc",
    "e.nodie.cc",
    "emailfake.nut.cc",
    "eml.cc",
    "fastest.cc",
    "fbmail.usa.cc",
    "flu-cc.flu.cc",
    "flucc.flu.cc",
    "ftpaccess.cc",
    "game-server.cc",
    "games4free.flu.cc",
    "gn8.cc",
    "goooogle.flu.cc",
    "goooogle.nut.cc",
    "grn.cc",
    "h2-yy.nut.cc",
    "hunrap.usa.cc",
    "i-phone.nut.cc",
    "i6.cloudns.cc",
    "imap.cc",
    "italia.flu.cc",
    "j8k2.usa.cc",
    "jamesbond.flu.cc",
    "jamesbond.nut.cc",
    "kiryubox.cu.cc",
    "ks87.usa.cc",
    "lopl.co.cc",
    "m21.cc",
    "mail21.cc",
    "mail4you.usa.cc",
    "maildrop.cc",
    "mailinator.usa.cc",
    "mailzilla.orgmbx.cc",
    "mbx.cc",
    "merda.flu.cc",
    "merda.nut.cc",
    "moldova.cc",
    "myphotos.cc",
    "napalm51.flu.cc",
    "napalm51.nut.cc",
    "napalm51.usa.cc",
    "nm7.cc",
    "nodie.cc",
    "nut-cc.nut.cc",
    "nutcc.nut.cc",
    "orgmbx.cc",
    "ovi.usa.cc",
    "peppe.usa.cc",
    "phus8kajuspa.cu.cc",
    "postfach.cc",
    "purple.flu.cc",
    "purple.nut.cc",
    "pw.flu.cc",
    "pw.nut.cc",
    "reddit.usa.cc",
    "retkesbusz.nut.cc",
    "rr-0.cu.cc",
    "rr-2.cu.cc",
    "s-s.flu.cc",
    "sasa22.usa.cc",
    "scrapping.cc",
    "secure-mail.cc",
    "securemail.flu.cc",
    "securemail.nut.cc",
    "secureserver.usa.cc",
    "shitaway.cu.cc",
    "shitaway.nut.cc",
    "shitaway.usa.cc",
    "shockinmytown.cu.cc",
    "spam.flu.cc",
    "spam.nut.cc",
    "spam.usa.cc",
    "stylemail.cz.cc",
    "this-is-a-free-domain.usa.cc",
    "tiv.cc",
    "trump.flu.cc",
    "uk.flu.cc",
    "uk.nut.cc",
    "usa-cc.usa.cc",
    "usa.cc",
    "viroleni.cu.cc",
    "wefjo.grn.cc",
    "wiz.cc",
    "wovz.cu.cc",
    "xoxox.cc",
    "yandere.cu.cc",
    "yellow.flu.cc",
    "yopmail.usa.cc",
    "youporn.flu.cc",
    "youporn.usa.cc",
    "yourlifesucks.cu.cc",
    "yy-h2.nut.cc",
    "zombo.flu.cc",
    "zombo.nut.cc",
  ],
  com: [
    "0hcow.com",
    "0ld0x.com",
    "0ne0ut.com",
    "10mail.com",
    "138mail.com",
    "160by2.com",
    "1auto.com",
    "1dmedical.com",
    "1googlemail.com",
    "1nut.com",
    "1webave.com",
    "247emails.com",
    "2babe.com",
    "2p-mail.com",
    "3126.com",
    "321media.com",
    "3d-painting.com",
    "4059.com",
    "4email.com",
    "4simpleemail.com",
    "5fm.za.com",
    "5ymail.com",
    "98usd.com",
    "99publicita.com",
    "a7996.com",
    "aaaw45e.com",
    "abyssmail.com",
    "academycougars.com",
    "acdczone.com",
    "address.com",
    "adultvidlite.com",
    "adx-telecom.com",
    "aeneasmail.com",
    "africa-11.com",
    "agentsosmed.com",
    "ajacied.com",
    "aju.onlysext.com",
    "algeria.com",
    "alienware13.com",
    "allfamus.com",
    "allsaintsfan.com",
    "amele.com",
    "ampsylike.com",
    "anappthat.com",
    "angelic.com",
    "annsmail.com",
    "anonymousspeech.com",
    "ansibleemail.com",
    "antireg.com",
    "aoeuhtns.com",
    "apown.com",
    "app-mailer.com",
    "archaeologist.com",
    "arhaelogist.com",
    "artlover.com",
    "asean-mail.com",
    "ashotmail.com",
    "asiancityweb.com",
    "asiki2in.com",
    "astroboymail.com",
    "asus.com",
    "atheist.com",
    "ato.check.com",
    "attymail.com",
    "aufeminin.com",
    "autowb.com",
    "azjuggalos.com",
    "b9x45v1m.com",
    "bachelorgal.com",
    "backtothefuturefans.com",
    "baptized.com",
    "barrypov.com",
    "base-weight.com",
    "basketballmail.com",
    "beefmilk.com",
    "berlin.com",
    "besttempmail.com",
    "bigassweb.com",
    "bikeracer.com",
    "binnary.com",
    "bisons.com",
    "blackburnmail.com",
    "blogdns.com",
    "bluesfans.com",
    "bobablast.com",
    "bombdiggity.com",
    "bornagain.com",
    "bostonoffice.com",
    "boxbg.com",
    "boximail.com",
    "bradfordfans.com",
    "braun4email.com",
    "brew-master.com",
    "brittonsign.com",
    "brokenvalve.com",
    "brutaldate.com",
    "bti.com",
    "btinternet.com",
    "budaya-tionghoa.com",
    "bugmenot.com",
    "bumpymail.com",
    "burner-email.com",
    "businessweekmail.com",
    "buttonfans.com",
    "buymoreplays.com",
    "byespm.com",
    "californiamail.com",
    "canoemail.com",
    "careless-whisper.com",
    "cashette.com",
    "cd2.com",
    "celineclub.com",
    "cetpass.com",
    "chance2mail.com",
    "charltonfans.com",
    "chauhanweb.com",
    "chek.com",
    "cheyenneweb.com",
    "chil-e.com",
    "chinamail.com",
    "chirk.com",
    "chogmail.com",
    "chronicspender.com",
    "city-of-coventry.com",
    "city-of-liverpool.com",
    "city-of-swansea.com",
    "citynetusa.com",
    "clear.com",
    "clinicatbf.com",
    "clubfier.com",
    "clubkawasaki.com",
    "clubmclaren.com",
    "clubsuzuki.com",
    "cluemail.com",
    "cnamed.com",
    "code-mail.com",
    "coin-one.com",
    "collegeclub.com",
    "columbusrr.com",
    "compuserve.com",
    "conok.com",
    "consultant.com",
    "coolsend.com",
    "cornerpub.com",
    "countrylover.com",
    "cowcell.com",
    "crazysexycool.com",
    "critterpost.com",
    "crusthost.com",
    "crymail2.com",
    "cumbriamail.com",
    "cutekittens.com",
    "cyber4all.com",
    "cybercafemaui.com",
    "cybersex.com",
    "daha.com",
    "dailypioneer.com",
    "dandinoo.com",
    "danirafsanjani.com",
    "dataarca.com",
    "dawin.com",
    "dawsonmail.com",
    "dealja.com",
    "deckerniles.com",
    "degoo.com",
    "desertmail.com",
    "deskpilot.com",
    "devotedparents.com",
    "digitalsanctuary.com",
    "DingBone.com",
    "dirtracers.com",
    "discofan.com",
    "discovery.com",
    "disposableinbox.com",
    "dividendxk.com",
    "dmailman.com",
    "dnsabr.com",
    "doanart.com",
    "doctor.com",
    "donjuan.com",
    "dontreg.com",
    "dotnow.com",
    "dress9x.com",
    "drivetagdev.com",
    "dslextreme.com",
    "dublin.com",
    "dumpmail.com",
    "dunlopdriver.com",
    "duskmail.com",
    "dygo.com",
    "dynalias.com",
    "e-tomarigi.com",
    "e4ward.com",
    "easy.com",
    "easypost.com",
    "eatrnet.com",
    "edmail.com",
    "elsitio.com",
    "email-value.com",
    "emailaddresses.com",
    "emailias.com",
    "emailoregon.com",
    "emailsensei.com",
    "emeyle.com",
    "emmasart.com",
    "end-war.com",
    "england.com",
    "eot.com",
    "eresmas.com",
    "est-le-patron.com",
    "etoast.com",
    "etranquil.com",
    "euaqa.com",
    "euroseek.com",
    "evopo.com",
    "execs2k.com",
    "ezehe.com",
    "fakemyinbox.com",
    "fangoh.com",
    "fast-email.com",
    "fastmazda.com",
    "fastmitsubishi.com",
    "fastsuzuki.com",
    "ffanet.com",
    "fidelium10.com",
    "filberts4u.com",
    "findhere.com",
    "fineemail.com",
    "flurred.com",
    "fmgirl.com",
    "footballmail.com",
    "fortunecity.com",
    "free.com",
    "freealtgen.com",
    "freeblackbootytube.com",
    "freecoolemail.com",
    "freesistercam.com",
    "freeuk.com",
    "freeyellow.com",
    "friendsfan.com",
    "from-ar.com",
    "from-brazil.com",
    "from-dc.com",
    "from-europe.com",
    "from-il.com",
    "from-ks.com",
    "from-mi.com",
    "from-mt.com",
    "from-nh.com",
    "from-ok.com",
    "from-ri.com",
    "from-tx.com",
    "from-wa.com",
    "fromalaska.com",
    "fromcolorado.com",
    "fromindiana.com",
    "fromlouisiana.com",
    "fromminnesota.com",
    "fromnebraska.com",
    "fromnorthdakota.com",
    "fromsouthdakota.com",
    "fromutah.com",
    "fromvirginia.com",
    "fromwashingtondc.com",
    "fuckingduh.com",
    "fuirio.com",
    "funkfan.com",
    "fwnb.com",
    "g-meil.com",
    "galaxyhit.com",
    "garrymccooey.com",
    "gawab.com",
    "geartower.com",
    "geeklife.com",
    "geo-crypto.com",
    "getonemail.com",
    "gifto12.com",
    "girl4god.com",
    "gishpuppy.com",
    "giyam.com",
    "gmai.com",
    "gmailssdf.com",
    "gmil.com",
    "go.com",
    "gocubs.com",
    "goodnewsmail.com",
    "goosemoose.com",
    "gowikibooks.com",
    "gowikigames.com",
    "gpcharlie.com",
    "grandmasmail.com",
    "greggamel.com",
    "guessmail.com",
    "guqoo.com",
    "haiapoteker.com",
    "hamptonroads.com",
    "happycounsel.com",
    "hazelnuts4u.com",
    "headset5pl.com",
    "heathenhero.com",
    "heremail.com",
    "heximail.com",
    "hiltonvr.com",
    "hk188188.com",
    "homal.com",
    "homestead.com",
    "hominidviews.com",
    "honor-8.com",
    "hotbot.com",
    "hotelnextmail.com",
    "hotlinemail.com",
    "hotmil.com",
    "housefan.com",
    "hurify1.com",
    "i-france.com",
    "i4j0j3iz0.com",
    "iam4msu.com",
    "iamwaiting.com",
    "icloud.com",
    "idea-mail.com",
    "ifoward.com",
    "ihateclowns.com",
    "ilovechocolate.com",
    "imail.com",
    "imap-mail.com",
    "imstations.com",
    "iname.com",
    "inboxbear.com",
    "inclusiveprogress.com",
    "indeedlebeans.com",
    "indocities.com",
    "inet.com",
    "infoapex.com",
    "inmano.com",
    "insightbb.com",
    "instance-email.com",
    "interburp.com",
    "internet-e-mail.com",
    "invalid.com",
    "iozak.com",
    "is-a-blogger.com",
    "is-a-democrat.com",
    "is-a-geek.com",
    "is-a-hunter.com",
    "is-a-nascarfan.com",
    "is-a-photographer.com",
    "is-a-teacher.com",
    "is-an-accountant.com",
    "is-an-artist.com",
    "is-into-games.com",
    "isbjct4e.com",
    "iservejesus.com",
    "isonfire.com",
    "itis0k.com",
    "itol.com",
    "iwan-fals.com",
    "jafps.com",
    "japan.com",
    "jazzandjava.com",
    "jdvmail.com",
    "jesusanswers.com",
    "jo-mail.com",
    "jojomail.com",
    "jourrapide.com",
    "jredm.com",
    "juniormail.com",
    "just-email.com",
    "jv6hgh1.com",
    "kaixo.com",
    "kalpoint.com",
    "kanzanishop.com",
    "kariplan.com",
    "kc.rr.com",
    "keepyourshitprivate.com",
    "kekita.com",
    "keptprivate.com",
    "khtyler.com",
    "kickassmail.com",
    "killermail.com",
    "kisshq.com",
    "kiwitown.com",
    "klzlk.com",
    "koko.com",
    "kozow.com",
    "ksee24mail.com",
    "l7b2l47k.com",
    "lacedmail.com",
    "lakmail.com",
    "laoho.com",
    "latemodels.com",
    "lavache.com",
    "lcelandic.com",
    "legistrator.com",
    "letterbox.com",
    "liamekaens.com",
    "linktrader.com",
    "litedrop.com",
    "littleapple.com",
    "llangollen.com",
    "loapq.com",
    "localbar.com",
    "locateme10.com",
    "LookUgly.com",
    "lover-boy.com",
    "lovethecowboys.com",
    "lron0re.com",
    "lycosmail.com",
    "maaill.com",
    "macbox.com",
    "madcrazy.com",
    "mail-cart.com",
    "mail-central.com",
    "mail-page.com",
    "mail.aws910.com",
    "mail2abby.com",
    "mail2adorable.com",
    "mail2aeolus.com",
    "mail2afghanistan.com",
    "mail2ahoy.com",
    "mail2airforce.com",
    "mail2alaska.com",
    "mail2alcoholic.com",
    "mail2alicia.com",
    "mail2allison.com",
    "mail2amazing.com",
    "mail2andorra.com",
    "mail2angela.com",
    "mail2anne.com",
    "mail2anything.com",
    "mail2arabia.com",
    "mail2aries.com",
    "mail2arkansas.com",
    "mail2art.com",
    "mail2arthur.com",
    "mail2athena.com",
    "mail2auction.com",
    "mail2baby.com",
    "mail2bahrain.com",
    "mail2ballplayer.com",
    "mail2bankrupt.com",
    "mail2barbara.com",
    "mail2beast.com",
    "mail2beijing.com",
    "mail2beth.com",
    "mail2beverly.com",
    "mail2billy.com",
    "mail2blake.com",
    "mail2bob.com",
    "mail2bolivia.com",
    "mail2boreas.com",
    "mail2bradley.com",
    "mail2broker.com",
    "mail2brunette.com",
    "mail2business.com",
    "mail2calvin.com",
    "mail2cancer.com",
    "mail2capricorn.com",
    "mail2carolyn.com",
    "mail2cathy.com",
    "mail2chad.com",
    "mail2charles.com",
    "mail2chile.com",
    "mail2christian.com",
    "mail2chuck.com",
    "mail2claude.com",
    "mail2close.com",
    "mail2colin.com",
    "mail2colombia.com",
    "mail2composer.com",
    "mail2congo.com",
    "mail2consultant.com",
    "mail2cory.com",
    "mail2country.com",
    "mail2craig.com",
    "mail2croatia.com",
    "mail2curt.com",
    "mail2cynthia.com",
    "mail2dallas.com",
    "mail2dancer.com",
    "mail2darling.com",
    "mail2dawn.com",
    "mail2debbie.com",
    "mail2delicious.com",
    "mail2denmark.com",
    "mail2desert.com",
    "mail2diana.com",
    "mail2dillon.com",
    "mail2diplomat.com",
    "mail2dive.com",
    "mail2djibouti.com",
    "mail2dominica.com",
    "mail2donna.com",
    "mail2dough.com",
    "mail2dream.com",
    "mail2dyke.com",
    "mail2eastend.com",
    "mail2edgar.com",
    "mail2eli.com",
    "mail2elvis.com",
    "mail2english.com",
    "mail2erica.com",
    "mail2ernie.com",
    "mail2eu.com",
    "mail2eurus.com",
    "mail2exciting.com",
    "mail2fanatic.com",
    "mail2farmer.com",
    "mail2female.com",
    "mail2films.com",
    "mail2firm.com",
    "mail2flexible.com",
    "mail2fly.com",
    "mail2football.com",
    "mail2frank.com",
    "mail2franklin.com",
    "mail2french.com",
    "mail2friendship.com",
    "mail2gabriel.com",
    "mail2games.com",
    "mail2gene.com",
    "mail2georgia.com",
    "mail2ghana.com",
    "mail2glen.com",
    "mail2goddess.com",
    "mail2grab.com",
    "mail2grandpa.com",
    "mail2greg.com",
    "mail2guy.com",
    "mail2hank.com",
    "mail2hawaii.com",
    "mail2heaven.com",
    "mail2helen.com",
    "mail2henry.com",
    "mail2herman.com",
    "mail2hindu.com",
    "mail2homer.com",
    "mail2hope.com",
    "mail2houston.com",
    "mail2hungary.com",
    "mail2hypnos.com",
    "mail2illinois.com",
    "mail2indian.com",
    "mail2indonesia.com",
    "mail2iran.com",
    "mail2irving.com",
    "mail2italian.com",
    "mail2jail.com",
    "mail2james.com",
    "mail2janet.com",
    "mail2jasmine.com",
    "mail2jazz.com",
    "mail2jennifer.com",
    "mail2jessica.com",
    "mail2jim.com",
    "mail2joanna.com",
    "mail2joey.com",
    "mail2jones.com",
    "mail2joy.com",
    "mail2julian.com",
    "mail2justin.com",
    "mail2karl.com",
    "mail2kay.com",
    "mail2kelly.com",
    "mail2kennedy.com",
    "mail2kenya.com",
    "mail2king.com",
    "mail2kristin.com",
    "mail2la.com",
    "mail2lance.com",
    "mail2laugh.com",
    "mail2lawrence.com",
    "mail2leo.com",
    "mail2letter.com",
    "mail2life.com",
    "mail2lipstick.com",
    "mail2litigator.com",
    "mail2lola.com",
    "mail2looking.com",
    "mail2louis.com",
    "mail2lucky.com",
    "mail2luxury.com",
    "mail2madison.com",
    "mail2maine.com",
    "mail2malaysia.com",
    "mail2mambo.com",
    "mail2mankind.com",
    "mail2margaret.com",
    "mail2marilyn.com",
    "mail2married.com",
    "mail2martha.com",
    "mail2mary.com",
    "mail2matt.com",
    "mail2maurice.com",
    "mail2maybe.com",
    "mail2medieval.com",
    "mail2melissa.com",
    "mail2methodist.com",
    "mail2miami.com",
    "mail2michelle.com",
    "mail2milano.com",
    "mail2millionaire.com",
    "mail2mini.com",
    "mail2minnesota.com",
    "mail2mitch.com",
    "mail2money.com",
    "mail2monty.com",
    "mail2morpheus.com",
    "mail2movies.com",
    "mail2msright.com",
    "mail2muslim.com",
    "mail2mygsm.com",
    "mail2mypalm.com",
    "mail2nancy.com",
    "mail2navy.com",
    "mail2nelson.com",
    "mail2network.com",
    "mail2newyork.com",
    "mail2nicole.com",
    "mail2no.com",
    "mail2normal.com",
    "mail2northcarolina.com",
    "mail2notus.com",
    "mail2ohio.com",
    "mail2one.com",
    "mail2online.com",
    "mail2optometrist.com",
    "mail2pakistan.com",
    "mail2paraguay.com",
    "mail2parker.com",
    "mail2patrick.com",
    "mail2pay.com",
    "mail2perry.com",
    "mail2pete.com",
    "mail2phoenix.com",
    "mail2pilot.com",
    "mail2plato.com",
    "mail2poet.com",
    "mail2policewoman.com",
    "mail2portugal.com",
    "mail2president.com",
    "mail2producer.com",
    "mail2psycho.com",
    "mail2rabbi.com",
    "mail2rage.com",
    "mail2rap.com",
    "mail2realtor.com",
    "mail2redhead.com",
    "mail2rent.com",
    "mail2rich.com",
    "mail2riley.com",
    "mail2roberta.com",
    "mail2rod.com",
    "mail2romania.com",
    "mail2ronnie.com",
    "mail2rugby.com",
    "mail2russian.com",
    "mail2ryan.com",
    "mail2sail.com",
    "mail2sam.com",
    "mail2sandra.com",
    "mail2sanmarino.com",
    "mail2sat.com",
    "mail2save.com",
    "mail2scorpio.com",
    "mail2seattle.com",
    "mail2sensual.com",
    "mail2shane.com",
    "mail2shoot.com",
    "mail2single.com",
    "mail2sky.com",
    "mail2slovenia.com",
    "mail2soccer.com",
    "mail2socialist.com",
    "mail2song.com",
    "mail2southamerica.com",
    "mail2southpole.com",
    "mail2splash.com",
    "mail2stacy.com",
    "mail2steve.com",
    "mail2stock.com",
    "mail2stockmarket.com",
    "mail2student.com",
    "mail2subscribe.com",
    "mail2susan.com",
    "mail2swim.com",
    "mail2sydney.com",
    "mail2taiwan.com",
    "mail2tanya.com",
    "mail2taxi.com",
    "mail2ted.com",
    "mail2teletubbie.com",
    "mail2terri.com",
    "mail2therapy.com",
    "mail2tim.com",
    "mail2toby.com",
    "mail2tommy.com",
    "mail2touch.com",
    "mail2tramp.com",
    "mail2traveler.com",
    "mail2trick.com",
    "mail2trump.com",
    "mail2tv.com",
    "mail2tyler.com",
    "mail2uk.com",
    "mail2uncle.com",
    "mail2usa.com",
    "mail2valentines.com",
    "mail2vanessa.com",
    "mail2venus.com",
    "mail2victoria.com",
    "mail2virgo.com",
    "mail2waiter.com",
    "mail2warren.com",
    "mail2wayne.com",
    "mail2weird.com",
    "mail2whether.com",
    "mail2whitney.com",
    "mail2willie.com",
    "mail2woman.com",
    "mail2wow.com",
    "mail2xfiles.com",
    "mail2yes.com",
    "mail2zenith.com",
    "mail2zoo.com",
    "mail2zurich.com",
    "mail4trash.com",
    "mailandnews.com",
    "mailback.com",
    "mailcity.com",
    "mailexcite.com",
    "mailfence.com",
    "mailfreeway.com",
    "mailHazard.com",
    "mailhood.com",
    "mailin8r.com",
    "mailinator1.com",
    "mailinator6.com",
    "mailinblack.com",
    "mailjet.com",
    "mailmoth.com",
    "mailnow2.com",
    "mailops.com",
    "mailpooch.com",
    "mailproxsy.com",
    "mailschain.com",
    "mailshiv.com",
    "mailslite.com",
    "mailtechx.com",
    "mailwire.com",
    "mankyrecords.com",
    "manutdfans.com",
    "markmurfin.com",
    "max-mail.com",
    "medscape.com",
    "mehtaweb.com",
    "meloo.com",
    "metalfan.com",
    "millionairemail.com",
    "mini-mail.com",
    "mituvn.com",
    "mohmal.com",
    "moldovacc.com",
    "moonwake.com",
    "mosaicfx.com",
    "mostlysunny.com",
    "mptrance.com",
    "msa.minsmail.com",
    "msgbox.com",
    "mt2009.com",
    "mt2017.com",
    "muathegame.com",
    "muellmail.com",
    "music.com",
    "muslimsonline.com",
    "mycampus.com",
    "myccscollection.com",
    "myfastmail.com",
    "myopera.com",
    "myownfriends.com",
    "myspaceinc.com",
    "mystvpn.com",
    "mytempmail.com",
    "myworldmail.com",
    "müllmail.com",
    "n2soccer.com",
    "nameplanet.com",
    "naseej.com",
    "naughty-blog.com",
    "neat-url.com",
    "neic.com",
    "nenter.com",
    "netexpressway.com",
    "netian.com",
    "netmongol.com",
    "netsquare.com",
    "netvigator.com",
    "neverbox.com",
    "newtempmail.com",
    "nicegal.com",
    "nightmail.com",
    "no-ux.com",
    "nobugmail.com",
    "nodezine.com",
    "northemquest.com",
    "notrnailinator.com",
    "ntscan.com",
    "nuvse.com",
    "nybella.com",
    "nym.hush.com",
    "nz11.com",
    "oaksw.com",
    "obfusko.com",
    "oddpost.com",
    "oe1f42q.com",
    "offsetmail.com",
    "okhuman.com",
    "okzk.com",
    "oldies1041.com",
    "omzae.com",
    "oneoffmail.com",
    "onmilwaukee.com",
    "operafan.com",
    "oreidresume.com",
    "our-office.com",
    "outel.com",
    "owlymail.com",
    "pa9e.com",
    "pacific-re.com",
    "pakistanoye.com",
    "parsmail.com",
    "partskyline.com",
    "pathtoig.com",
    "pay-mon.com",
    "pccareit.com",
    "peopleweb.com",
    "petlover.com",
    "pianoxltd.com",
    "pipeline.com",
    "planetall.com",
    "playersodds.com",
    "plus.com",
    "plutofox.com",
    "pokepost.com",
    "pookmail.com",
    "popgx.com",
    "popteen4u.com",
    "post.com",
    "postacin.com",
    "powerdivas.com",
    "pqoss.com",
    "prayersa3.com",
    "premiumservice.com",
    "procrackers.com",
    "prontomail.com",
    "providier.com",
    "psoxs.com",
    "publicist.com",
    "pulp-fiction.com",
    "pushmojo.com",
    "PutThisInYourSpamDatabase.com",
    "q8cbwendy.com",
    "qedwardr.com",
    "qisoa.com",
    "qlhnu526.com",
    "qprfans.com",
    "quackquack.com",
    "quickinbox.com",
    "quikmail.com",
    "racefanz.com",
    "rao-network.com",
    "rattlearray.com",
    "ravefan.com",
    "realradiomail.com",
    "rediff.com",
    "regalsz.com",
    "rejectmail.com",
    "religious.com",
    "representative.com",
    "respectanimals.com",
    "rezai.com",
    "rfreedomj.com",
    "rhyta.com",
    "ricret.com",
    "rnzcomesth.com",
    "rock.com",
    "rockfan.com",
    "rockmailgroup.com",
    "rotaniliam.com",
    "rppkn.com",
    "rstarmail.com",
    "rumgel.com",
    "rupayamail.com",
    "s8sigmao.com",
    "sabrestlouis.com",
    "samerica.com",
    "sanstr.com",
    "saturnfans.com",
    "sawoe.com",
    "sbuttone.com",
    "schoolmail.com",
    "scifianime.com",
    "scubadiving.com",
    "searchwales.com",
    "seekapps.com",
    "sells-for-less.com",
    "SendSpamHere.com",
    "sexical.com",
    "sharing-storage.com",
    "shieldemail.com",
    "shipfromto.com",
    "shuf.com",
    "sialkotian.com",
    "singlespride.com",
    "sjuaq.com",
    "sky-inbox.com",
    "skymailgroup.com",
    "sluteen.com",
    "smtp99.com",
    "snakemail.com",
    "snkml.com",
    "snowboarding.com",
    "socamail.com",
    "sogou.com",
    "soodomail.com",
    "soundofmusicfans.com",
    "space-travel.com",
    "spacetowns.com",
    "spainmail.com",
    "spamcowboy.com",
    "SpamHerePlease.com",
    "spammehere.com",
    "spamspot.com",
    "spartapiet.com",
    "speedrulz.com",
    "spinfinder.com",
    "sqoai.com",
    "srt.com",
    "ssgjylc1013.com",
    "stalag13.com",
    "starplace.com",
    "starwars-fans.com",
    "stillchronic.com",
    "stoned.com",
    "storiqax.com",
    "strongguy.com",
    "stumpfwerk.com",
    "suioe.com",
    "sunsgame.com",
    "supergreen.com",
    "swiftdesk.com",
    "swirve.com",
    "sylvannet.com",
    "system-765.com",
    "tafmail.com",
    "tagyourself.com",
    "talkmises.com",
    "taskscbo.com",
    "tattoofanatic.com",
    "tech69.com",
    "techscout.com",
    "teemia.com",
    "temp-mail.com",
    "tempm.com",
    "tempmailapp.com",
    "temporaryinbox.com",
    "tenup.com",
    "teufelsweb.com",
    "that-web.com",
    "the-airforce.com",
    "the-army.com",
    "the-biker.com",
    "the-canadian.com",
    "the-country.com",
    "the-german.com",
    "the-japanese.com",
    "the-marine.com",
    "the-monkey.com",
    "the-quickest.com",
    "the18th.com",
    "thebest4ever.com",
    "thedorm.com",
    "thegirlcute.com",
    "thegooner.com",
    "thelanddownunder.com",
    "thenewsdhhayy.com",
    "thesimpsonsfans.com",
    "thesunshinecrew.com",
    "thirdage.com",
    "thrott.com",
    "throwawaymail.com",
    "tidni.com",
    "timkassouf.com",
    "tkmy88m.com",
    "tm2mail.com",
    "toastsum.com",
    "tom.com",
    "toothfairy.com",
    "topbabygirl.com",
    "topsurf.com",
    "toquedequeda.com",
    "tqosi.com",
    "trash-amil.com",
    "trash2011.com",
    "trashmails.com",
    "trbvm.com",
    "trobertqs.com",
    "trommlergroup.com",
    "truckracers.com",
    "truthmail.com",
    "tutanota.com",
    "twc.com",
    "tycoonmail.com",
    "u6lvty2.com",
    "ubbi.com",
    "ufacturing.com",
    "ukcleaners.com",
    "ukmail.com",
    "ukschool.com",
    "unihome.com",
    "uplipht.com",
    "uroid.com",
    "usermail.com",
    "uwalumni.com",
    "uz6tgwk.com",
    "vampirehunter.com",
    "vctel.com",
    "vektik.com",
    "verizonmail.com",
    "veryrealmail.com",
    "vilnapresa.com",
    "vip.188.com",
    "vipepe.com",
    "virtual-mail.com",
    "visitweb.com",
    "vjmail.com",
    "vote-hillary.com",
    "vr5gpowerv.com",
    "vsimcard.com",
    "vuemail.com",
    "w4i3em6r.com",
    "wagfused.com",
    "wallm.com",
    "warmmail.com",
    "wasimismail.com",
    "waumail.com",
    "webcammail.com",
    "webmeetme.com",
    "websecuremail.com",
    "wem.com",
    "wg0.com",
    "whartontx.com",
    "wheelweb.com",
    "wholefitness.com",
    "wimsg.com",
    "wmrmail.com",
    "wongfaye.com",
    "worldmailer.com",
    "wowway.com",
    "wralawfirm.com",
    "wronghead.com",
    "wwdg.com",
    "www.bccto.com",
    "www.eairmail.com",
    "x24.com",
    "xcpy.com",
    "xgnowherei.com",
    "xmenfans.com",
    "xn--9kq967o.com",
    "xn--mllmail-n2a.com",
    "xplornet.com",
    "xxtreamcam.com",
    "yahoofs.com",
    "yam.com",
    "yapost.com",
    "yehaa.com",
    "yevme.com",
    "yk20.com",
    "ynmrealty.com",
    "yopweb.com",
    "youareadork.com",
    "yourdomain.com",
    "yournightmare.com",
    "yoursubdomain.zzn.com",
    "yspend.com",
    "yt-google.com",
    "za.com",
    "zetmail.com",
    "zipido.com",
    "zzz.com",
    "0-mail.com",
    "007addict.com",
    "027168.com",
    "080mail.com",
    "0815.ru0clickemail.com",
    "0clickemail.com",
    "0hboy.com",
    "0hdear.com",
    "0hiolce.com",
    "0ld0ak.com",
    "0mel.com",
    "0ne0ak.com",
    "0nelce.com",
    "0rdering.com",
    "0utln.com",
    "100likers.com",
    "1033edge.com",
    "1056windtreetrace.com",
    "10launcheds.com",
    "10minute-email.com",
    "10minutemail.com",
    "10minutemailbox.com",
    "10minutesmail.com",
    "10minutetempemail.com",
    "10x9.com",
    "11163.com",
    "11mail.com",
    "120mail.com",
    "123.com",
    "123anddone.com",
    "123india.com",
    "126.com",
    "12houremail.com",
    "12minutemail.com",
    "12storage.com",
    "139.com",
    "140unichars.com",
    "150mail.com",
    "15meg4free.com",
    "15qm.com",
    "163.com",
    "168.com",
    "16mail.com",
    "188.com",
    "1aolmail.com",
    "1blackmoon.com",
    "1chuan.com",
    "1colony.com",
    "1coolplace.com",
    "1e72.com",
    "1freeemail.com",
    "1funplace.com",
    "1hiphopfan.com",
    "1internetdrive.com",
    "1mail.x24hr.com",
    "1mum.com",
    "1musicrow.com",
    "1nsyncfan.com",
    "1secmail.com",
    "1shivom.com",
    "1st-forms.com",
    "1under.com",
    "1usemail.com",
    "1webhighway.com",
    "1zhuan.com",
    "2-mail.com",
    "200555.com",
    "20minutemail.com",
    "212.com",
    "21cn.com",
    "22office.com",
    "2323bryanstreet.com",
    "247emaildata.com",
    "24hinbox.com",
    "24hourmail.com",
    "25mails.com",
    "2980.com",
    "2anom.com",
    "2brutus.com",
    "2d2i.com",
    "2die4.com",
    "2hackermail.com",
    "2mailnext.com",
    "2mydns.com",
    "2odem.com",
    "2prong.com",
    "2trom.com",
    "2viewerl.com",
    "30gigs.com",
    "30minutemail.com",
    "30minutesmail.com",
    "30wave.com",
    "3202.com",
    "321-email.com",
    "33mail.com",
    "3675.mooo.com",
    "37.com",
    "3ammagazine.com",
    "3dmail.com",
    "3l6.com",
    "4057.com",
    "41v1relaxn.com",
    "4alphapro.com",
    "4easyemail.com",
    "4mg.com",
    "4newyork.com",
    "4orty.com",
    "4senditnow.com",
    "4struga.com",
    "4warding.com",
    "4x4fan.com",
    "50mail.com",
    "50saleclub.com",
    "555gmail.com",
    "56787.com",
    "5dsmartstore.com",
    "5gramos.com",
    "5star.com",
    "5x25.com",
    "60minutemail.com",
    "666-evil.com",
    "675hosting.com",
    "68mail.com",
    "6paq.com",
    "6url.com",
    "71compete.com",
    "75hosting.com",
    "7days-printing.com",
    "7ijabi.com",
    "7mail7.com",
    "7tags.com",
    "80665.com",
    "8127ep.com",
    "819110.com",
    "82c8.com",
    "899079.com",
    "91000.com",
    "97rock.com",
    "99.com",
    "99cows.com",
    "99experts.com",
    "99pubblicita.com",
    "99x99.com",
    "9toplay.com",
    "a.mailcker.com",
    "a.vztc.com",
    "a0f7ukc.com",
    "a41odgz7jh.com",
    "a41odgz7jh.com.com",
    "a54pd15op.com",
    "aa.da.mail-temp.com",
    "aaamail.zzn.com",
    "aahlife.com",
    "abakiss.com",
    "abb.dnsabr.com",
    "abc.com",
    "abcdefghijklmnopqrstuvwxyzabcdefghijklmnopqrstuvwxyzabcdefghijk.com",
    "abdulnour.com",
    "aberystwyth.com",
    "abolition-now.com",
    "absolutevitality.com",
    "abyssemail.com",
    "academiccommunity.com",
    "accamail.com",
    "accessgcc.com",
    "accountant.com",
    "acdcfan.com",
    "ace-of-base.com",
    "acentri.com",
    "acmecity.com",
    "acrobatmail.com",
    "activatormail.com",
    "activist.com",
    "acucre.com",
    "addictingtrailers.com",
    "adesktop.com",
    "adfarrow.com",
    "adipex7z.com",
    "administrativos.com",
    "adobeccepdm.com",
    "adoption.com",
    "adrenalinefreak.com",
    "adresseemailtemporaire.com",
    "adriaticmail.com",
    "adultcamzlive.com",
    "advantimal.com",
    "advantimo.com",
    "adwaterandstir.com",
    "adzek.com",
    "ae.pureskn.com",
    "aemail4u.com",
    "aeonpsi.com",
    "afcgroup40.com",
    "affricca.com",
    "afmail.com",
    "afreeinternet.com",
    "africamail.com",
    "africanpartnersonline.com",
    "agedmail.com",
    "agelessemail.com",
    "agentshipping.com",
    "agoodmail.com",
    "aichi.com",
    "aim.com",
    "air-blog.com",
    "aircraftmail.com",
    "airforceemail.com",
    "airmailhub.com",
    "airsoftshooters.com",
    "aiutamici.com",
    "ajiagustian.com",
    "ak.mintemail.com",
    "akapost.com",
    "akerd.com",
    "aklqo.com",
    "akphantom.com",
    "aksearches.com",
    "akutamvan.com",
    "albawaba.com",
    "alecsmail.com",
    "aledrioroots.youdontcare.com",
    "alex4all.com",
    "alfurqaaninternationalschools.com",
    "algeriamail.com",
    "alibaba.com",
    "aliceinchainsmail.com",
    "aligamel.com",
    "alisongamel.com",
    "aliyun.com",
    "allaboutebay2012.com",
    "alldirectbuy.com",
    "allergist.com",
    "alloymail.com",
    "allracing.com",
    "allroundawesome.com",
    "alltempmail.com",
    "alltopmail.com",
    "alonsofans.com",
    "altavista.com",
    "alternativagratis.com",
    "altmails.com",
    "alumni.com",
    "alumnidirector.com",
    "amadamus.com",
    "amail.com",
    "amail3.com",
    "amantapkun.com",
    "amazonses.com",
    "amelabs.com",
    "ameraldmail.com",
    "ameyprice.com",
    "amilegit.com",
    "amiriindustries.com",
    "amnetsal.com",
    "amoksystems.com",
    "amplimail.com",
    "amsspecialist.com",
    "amuromail.com",
    "anappfor.com",
    "ancestry.com",
    "anchrisbaton.acmetoy.com",
    "andreabocellimail.com",
    "andrew.com",
    "anfmail.com",
    "angelfire.com",
    "animalhouse.com",
    "animesos.com",
    "anjungcafe.com",
    "anniefans.com",
    "anonymbox.com",
    "anonymousness.com",
    "anonymstermail.com",
    "another.com",
    "anotherwin95.com",
    "anthony-junkmail.com",
    "anthropologycommunity.com",
    "anti-social.com",
    "antiqueemail.com",
    "antisocial.com",
    "antongijsen.com",
    "antwerpen.com",
    "anyett.com",
    "anymoment.com",
    "anytimenow.com",
    "ao4ffqty.com",
    "aol.com",
    "aolmail.com",
    "aopconsultants.com",
    "apexmail.com",
    "aphlog.com",
    "apmail.com",
    "apotekerid.com",
    "app-expert.com",
    "app-lex-acc.com",
    "appdollars.com",
    "appixie.com",
    "apple.dnsabr.com",
    "applynow0.com",
    "appmail24.com",
    "aprinta.com",
    "arabia.com",
    "arcademaster.com",
    "archerymail.com",
    "arcticmail.com",
    "aresting.com",
    "argentina.com",
    "armyspy.com",
    "arockee.com",
    "arroisijewellery.com",
    "artdrip.com",
    "artistemail.com",
    "artman-conception.com",
    "as-if.com",
    "as.onlysext.com",
    "asdfghmail.com",
    "asdhgsad.com",
    "asfdasd.com",
    "asgrad.com",
    "asheville.com",
    "ashleyandrew.com",
    "asia-links.com",
    "asia.com",
    "asiafind.com",
    "asianavenue.com",
    "asik2in.com",
    "ask-mail.com",
    "askpirate.com",
    "asm.snapwet.com",
    "asorent.com",
    "assala.com",
    "assamesemail.com",
    "astralcars.com",
    "astrolover.com",
    "asurfer.com",
    "atech5.com",
    "athens5.com",
    "atlaswebmail.com",
    "atnextmail.com",
    "atozasia.com",
    "att.com",
    "attbi.com",
    "attnetwork.com",
    "audiobrush.com",
    "augmentationtechnology.com",
    "aus-city.com",
    "ausi.com",
    "austimail.com",
    "austin.rr.com",
    "australiamail.com",
    "authorizedoffr.com",
    "autoescuelanerja.com",
    "automotiveauthority.com",
    "autorobotica.com",
    "autotwollow.com",
    "aver.com",
    "averdov.com",
    "aviani.com",
    "aw.kikwet.com",
    "awayonvacation.com",
    "awholelotofamechi.com",
    "aws910.com",
    "awsoo.com",
    "ax80mail.com",
    "axon7zte.com",
    "ayahoo.com",
    "ayna.com",
    "azcomputerworks.com",
    "azimiweb.com",
    "b1of96u.com",
    "b5safaria.com",
    "b6vscarmen.com",
    "b9x45v1m.com.com",
    "babycounter.com",
    "babylonize.com",
    "bachelorboy.com",
    "backfliper.com",
    "backstreet-boys.com",
    "backstreetboysclub.com",
    "backwards.com",
    "baconporker.com",
    "badoop.com",
    "badtzmail.com",
    "bagherpour.com",
    "bakpaka.com",
    "balanc3r.com",
    "ballyfinance.com",
    "baluch.com",
    "bangkok.com",
    "bangkok2000.com",
    "baphled.com",
    "baptistmail.com",
    "barcelona.com",
    "barid.com",
    "barrabravaz.com",
    "barryogorman.com",
    "barryspov.com",
    "bartoparcadecabinet.com",
    "baseball-email.com",
    "basketball2in.com",
    "battricks.com",
    "baudoinconsulting.com",
    "bauwerke-online.com",
    "bazaaboom.com",
    "bbetweenj.com",
    "bboy.com",
    "bboy.zzn.com",
    "bcbsla.com",
    "bcompiled3.com",
    "bcvibes.com",
    "bearsarefuzzy.com",
    "bebasmovie.com",
    "beddly.com",
    "beeebank.com",
    "BeefMilk.com",
    "beer.com",
    "beerandremotes.com",
    "beethoven.com",
    "begoz.com",
    "beirut.com",
    "belediyeevleri2noluasm.com",
    "belice.com",
    "belizehome.com",
    "belizeweb.com",
    "bellair.com",
    "belljonestax.com",
    "beluckygame.com",
    "berkscounty.com",
    "bestchoiceusedcar.com",
    "bestdvdblurayplayer.com",
    "bestpieter.com",
    "bestsoundeffects.com",
    "beverlytx.com",
    "bgtmail.com",
    "bharatmail.com",
    "bidourlnks.com",
    "big5mail.com",
    "bigbangfairy.com",
    "bigboab.com",
    "bigfoot.com",
    "bigger.com",
    "biggerbadder.com",
    "bigmailbox.com",
    "bigpond.com",
    "bigramp.com",
    "bikemechanics.com",
    "bikerider.com",
    "bimamail.com",
    "bing.com",
    "binkmail.com",
    "biologyfan.com",
    "biometicsliquidvitamins.com",
    "birdfanatic.com",
    "birminghamfans.com",
    "bismillah.com",
    "bit-degree.com",
    "bitmail.com",
    "bitwerke.com",
    "bizhosting.com",
    "bkkmail.com",
    "bla-bla.com",
    "blackburnfans.com",
    "blackplanet.com",
    "blackshipping.com",
    "blazemail.com",
    "blinkmatrix.com",
    "bloatbox.com",
    "blockfilter.com",
    "bloggersxmi.com",
    "blogos.com",
    "blogspot.com",
    "blondandeasy.com",
    "bluebottle.com",
    "bluehyppo.com",
    "bluesfan.com",
    "bluewerks.com",
    "blushmail.com",
    "bnote.com",
    "boardermail.com",
    "boatracers.com",
    "bobmurchison.com",
    "bodhi.lawlita.com",
    "bofthew.com",
    "boimail.com",
    "bolando.com",
    "bollywoodz.com",
    "boltonfans.com",
    "bookthemmore.com",
    "boom.com",
    "boopmail.com",
    "bootmail.com",
    "borged.com",
    "borgish.com",
    "bornnaked.com",
    "bossofthemoss.com",
    "bounces.amazon.com",
    "boxbe.com",
    "boxemail.com",
    "boxfrog.com",
    "boyzoneclub.com",
    "bpornd.com",
    "br.mintemail.com",
    "bratwurst.dnsabr.com",
    "brazilmail.com",
    "breakthru.com",
    "brefmail.com",
    "brestonline.com",
    "brew-meister.com",
    "briefemail.com",
    "briggsmarcus.com",
    "britneyclub.com",
    "broadbandninja.com",
    "broadwaybuff.com",
    "broilone.com",
    "brokeandhappy.com",
    "browniesgoreng.com",
    "brunty.com",
    "brusseler.com",
    "bsdmail.com",
    "bspooky.com",
    "bst-72.com",
    "bt.com",
    "bt3019k.com",
    "btconnect.com",
    "btintenet.com",
    "btopenworld.com",
    "bu.mintemail.com",
    "buchhandlung24.com",
    "budayationghoa.com",
    "buffemail.com",
    "bugmenever.com",
    "bulgaria.com",
    "bulkcleancheap.com",
    "bullsfan.com",
    "bulrushpress.com",
    "bumppack.com",
    "bumrap.com",
    "bunchofidiots.com",
    "bunko.com",
    "bunsenhoneydew.com",
    "burgoscatchphrase.com",
    "burntmail.com",
    "buryfans.com",
    "bushemail.com",
    "business-man.com",
    "businessbackend.com",
    "businesssuccessislifesuccess.com",
    "bussitussi.com",
    "bust.com",
    "busta-rhymes.com",
    "busymail.com.com",
    "busymail.comhomeart.com",
    "butter9x.com",
    "buxap.com",
    "buyersusa.com",
    "buygapfashion.com",
    "buzy.com",
    "buzzcluby.com",
    "bvimailbox.com",
    "by8006l.com",
    "byebyemail.com",
    "byke.com",
    "byteme.com",
    "c1oramn.com",
    "c4.com",
    "c51vsgq.com",
    "c7fk799.com",
    "ca.rr.com",
    "cabacabana.com",
    "cable.comcast.com",
    "caerwyn.com",
    "cairomail.com",
    "calcuttaads.com",
    "calendar-server.bounces.google.com",
    "californiafitnessdeals.com",
    "callnetuk.com",
    "camidge.com",
    "canada-11.com",
    "canadianmail.com",
    "candidteenagers.com",
    "cannoncrew.com",
    "canwetalk.com",
    "caramail.com",
    "care2.com",
    "careerbuildermail.com",
    "carolina.rr.com",
    "carrnelpartners.com",
    "carsencyclopedia.com",
    "casablancaresort.com",
    "cash4u.com",
    "casino.com",
    "casualdx.com",
    "cataloniamail.com",
    "cataz.com",
    "catchamail.com",
    "catemail.com",
    "catlover.com",
    "catsrule.garfield.com",
    "cbair.com",
    "ccnmail.com",
    "cd.mintemail.com",
    "cd2in.com",
    "cdnqa.com",
    "ce.mintemail.com",
    "cechire.com",
    "cekc-video.com",
    "cellurl.com",
    "celticfans.com",
    "centermail.com",
    "centralpets.com",
    "certifiedmail.com",
    "cfl.rr.com",
    "chaichuang.com",
    "chaiyomail.com",
    "champmails.com",
    "changingemail.com",
    "channelonetv.com",
    "chaosi0t.com",
    "charityemail.com",
    "charmedmail.com",
    "chattown.com",
    "cheap3ddigitalcameras.com",
    "check.com",
    "check1check.com",
    "cheeb.com",
    "cheerful.com",
    "chefmail.com",
    "chemist.com",
    "chequemail.com",
    "cheshiremail.com",
    "chewcow.com",
    "chewiemail.com",
    "chez.com",
    "chickenkiller.com",
    "chielo.com",
    "chilkat.com",
    "chinalook.com",
    "chinatov.com",
    "chinesecool.com",
    "chipkolik.com",
    "chithinh.com",
    "chocofan.com",
    "choicemail1.com",
    "chong-mail.com",
    "choqr6r4.com",
    "christopherfretz.com",
    "chubbyteenmodels.com",
    "chumpstakingdumps.com",
    "cia-agent.com",
    "cicciociccio.com",
    "cigar-auctions.com",
    "cinci.rr.com",
    "cirquefans.com",
    "city-of-birmingham.com",
    "city-of-cambridge.com",
    "city-of-edinburgh.com",
    "city-of-lincoln.com",
    "city-of-manchester.com",
    "city-of-oxford.com",
    "city-of-westminster.com",
    "city2city.com",
    "cjpeg.com",
    "ckiso.com",
    "ckoie.com",
    "claimab.com",
    "claramail.com",
    "clashatclintonemail.com",
    "classicalfan.com",
    "cleansafemail.com",
    "clerk.com",
    "click-email.com",
    "clickforadate.com",
    "cliffhanger.com",
    "clintonemailhearing.com",
    "clipmails.com",
    "clixser.com",
    "cloudmarriage.com",
    "cloudt12server01.com",
    "clrmail.com",
    "clubalfa.com",
    "clubaprilia.com",
    "clubbmw.com",
    "clubducati.com",
    "clubjaguar.com",
    "clublotus.com",
    "clubmorgan.com",
    "clubnetnoir.com",
    "clubstt.com",
    "clubsubaru.com",
    "clubtriumph.com",
    "clubwilliams.com",
    "clue-1.com",
    "cmail.com",
    "cmailg.com",
    "cmpmail.com",
    "cmpnetmail.com",
    "cn.ibm.com",
    "cndps.com",
    "cnn.com",
    "cnnsimail.com",
    "cobin2hood.com",
    "cocovpn.com",
    "codeandscotch.com",
    "codivide.com",
    "codupmyspace.com",
    "coffeeshipping.com",
    "coieo.com",
    "coin-link.com",
    "coindie.com",
    "coldmail.com",
    "coleure.com",
    "collapse3b.com",
    "collectiblesuperstore.com",
    "collegebeat.com",
    "collegemail.com",
    "columbus.rr.com",
    "columnist.com",
    "comast.com",
    "comcast.com",
    "comic.com",
    "comments2g.com",
    "communityconnect.com",
    "completegolfswing.com",
    "comprendemail.com",
    "computer-freak.com",
    "computerconfused.com",
    "computernaked.com",
    "conexcol.com",
    "conk.com",
    "connectbox.com",
    "constellational.com",
    "consumerriot.com",
    "contbay.com",
    "contentwanted.com",
    "cookiemonster.com",
    "coolgoose.com",
    "coolkiwi.com",
    "coolmail.com",
    "coolrio.com",
    "cooltoad.com",
    "cooooool.com",
    "copacabana.com",
    "copticmail.com",
    "cornells.com",
    "corporatedirtbag.com",
    "corrsfan.com",
    "cortinet.com",
    "cosmorph.com",
    "counsellor.com",
    "courrieltemporaire.com",
    "coventryfans.com",
    "cowaway.com",
    "cox.com",
    "coxmail.com",
    "cpinternet.com",
    "cr219.com",
    "cr97mt49.com",
    "craftemail.com",
    "crankmails.com",
    "crazedanddazed.com",
    "crazymailing.com",
    "creazionisa.com",
    "crewefans.com",
    "cristianemail.com",
    "croeso.com",
    "crosshairs.com",
    "crossroadsmail.com",
    "crowd-mail.com",
    "crunkmail.com",
    "crwmail.com",
    "cry4helponline.com",
    "cryingmail.com",
    "cs.com",
    "csi.com",
    "cswnet.com",
    "cubiclink.com",
    "cuemail.com",
    "cultmovie.com",
    "cumanuallyo.com",
    "curio-city.com",
    "curtsmail.com",
    "customs2g3.com",
    "cuteandcuddly.com",
    "cutey.com",
    "cyber-matrix.com",
    "cyber-wizard.com",
    "cyberbabies.com",
    "cyberdude.com",
    "cybergal.com",
    "cyberinbox.com",
    "cyberleports.com",
    "cyberservices.com",
    "cyberspace-asia.com",
    "cyclefanz.com",
    "cynetcity.com",
    "czqjii8.com",
    "d1yun.com",
    "d58pb91.com",
    "daabox.com",
    "dacoolest.com",
    "dadd.kikwet.com",
    "daemsteam.com",
    "dafinally.com",
    "daily-email.com",
    "daintly.com",
    "dallas.theboys.com",
    "damnthespam.com",
    "dancemanual.com",
    "dandikmail.com",
    "dangerous-minds.com",
    "dansegulvet.com",
    "darkharvestfilms.com",
    "darkstone.com",
    "dash-pads.com",
    "data54.com",
    "datarca.com",
    "datum2.com",
    "davegracey.com",
    "davidlcreative.com",
    "dawnsonmail.com",
    "daymailonline.com",
    "dayrep.com",
    "dazedandconfused.com",
    "dbunker.com",
    "dbz5mchild.com",
    "dc-business.com",
    "dcemail.com",
    "ddcrew.com",
    "ddividegs.com",
    "ddnsfree.com",
    "deadaddress.com",
    "deadspam.com",
    "deagot.com",
    "deal-maker.com",
    "dealrek.com",
    "death-star.com",
    "deepcleanac.com",
    "definingjtl.com",
    "deforestationsucks.com",
    "dejanews.com",
    "delayload.com",
    "deliveryman.com",
    "deltanet.com",
    "demandsxz.com",
    "density2v.com",
    "depechemode.com",
    "derbyfans.com",
    "deseretmail.com",
    "desertonline.com",
    "desertsaintsmail.com",
    "deskmail.com",
    "desoz.com",
    "despammed.com",
    "detik.com",
    "deucemail.com",
    "deutschland-net.com",
    "devnullmail.com",
    "devotedcouples.com",
    "deyom.com",
    "dfg6.kozow.com",
    "dfwatson.com",
    "dgd.mail-temp.com",
    "di-ve.com",
    "dialogus.com",
    "diamondemail.com",
    "diapaulpainting.com",
    "didamail.com",
    "diehardmail.com",
    "digital-email.com",
    "digital-message.com",
    "digitalmariachis.com",
    "digosnet.com",
    "dildosfromspace.com",
    "dingbone.com",
    "dinkmail.com",
    "diplomats.com",
    "directbox.com",
    "director-general.com",
    "diri.com",
    "dirtracer.com",
    "disaq.com",
    "discardmail.com",
    "disciples.com",
    "discordmail.com",
    "discoverwatch.com",
    "discoverymail.com",
    "discretevtd.com",
    "disign-revelation.com",
    "disposable-mail.com",
    "disposable.com",
    "disposable.dhc-app.com",
    "disposableaddress.com",
    "disposableemailaddresses.com",
    "disposableemailaddresses.emailmiser.com",
    "disposablemails.com",
    "disposeamail.com",
    "dispostable.com",
    "divermail.com",
    "divorcedandhappy.com",
    "dm.w3internet.co.ukexample.com",
    "dmail.unrivaledtechnologies.com",
    "dmv.com",
    "dndent.com",
    "dns-privacy.com",
    "dnsalias.com",
    "dnsdeer.com",
    "dnsmadeeasy.com",
    "doclist.bounces.google.com",
    "docs.google.com",
    "dodgeit.com",
    "dodgit.com",
    "dodsi.com",
    "doesntexist.com",
    "dog.com",
    "doglover.com",
    "doiea.com",
    "doityourself.com",
    "domain1dolar.com",
    "domozmail.com",
    "doneasy.com",
    "dongqing365.com",
    "donot-reply.com",
    "dontgotmail.com",
    "dontmesswithtexas.com",
    "dooboop.com",
    "doramail.com",
    "dostmail.com",
    "dotmsg.com",
    "dotslashrage.com",
    "douchelounge.com",
    "dp76.com",
    "dqkerui.com",
    "dr.com",
    "dragracer.com",
    "drdrb.com",
    "dremixd.com",
    "drid1gs.com",
    "drivecompanies.com",
    "drivesotp7.com",
    "dropjar.com",
    "droplister.com",
    "dropzone.com",
    "drynic.com",
    "dsiay.com",
    "dsleeping09.com",
    "dspwebservices.com",
    "dubaimail.com",
    "dudmail.com",
    "duk33.com",
    "dukedish.com",
    "dumpandjunk.com",
    "dumpyemail.com",
    "dunflimblag.mailexpire.com",
    "dunloprider.com",
    "durandinterstellar.com",
    "dustdevil.com",
    "dvaar.com",
    "dvd.dnsabr.com",
    "dvx.dnsabr.com",
    "dweezlemail.crabdance.com",
    "dyceroprojects.com",
    "dyn-o-saur.com",
    "dynamitemail.com",
    "dzimbabwegq.com",
    "e-hkma.com",
    "e-mailanywhere.com",
    "e-postkasten.com",
    "e-tapaal.com",
    "e-webtec.com",
    "e.benlotus.com",
    "e.wupics.com",
    "e4ivstampk.com",
    "E4ward.com",
    "earthalliance.com",
    "earthdome.com",
    "eastmail.com",
    "eastrolog.com",
    "easy-trash-mail.com",
    "easypeasy.com",
    "easytrashmail.com",
    "eatmydirt.com",
    "eb609s25w.com",
    "ebbob.com",
    "eboise.com",
    "ec.rr.com",
    "ecallheandi.com",
    "echina.com",
    "ecompare.com",
    "edinburgh-airporthotels.com",
    "ednatx.com",
    "edtnmail.com",
    "educastmail.com",
    "eelmail.com",
    "ehmail.com",
    "eilersmail.com",
    "einrot.com",
    "electriccarvehicle.com",
    "elitevipatlantamodels.com",
    "elp.rr.com",
    "eltimon.com",
    "elvisfan.com",
    "emadisonriver.com",
    "email-fake.com",
    "email-lab.com",
    "email-sms.com",
    "email-temp.com",
    "email-wizard.com",
    "email.com",
    "email.women.com",
    "email2me.com",
    "email60.com",
    "emailaccount.com",
    "emailahi.com",
    "emailchoice.com",
    "emailem.com",
    "emailer.hubspot.com",
    "emailfake.com",
    "emailfowarding.com",
    "emailgaul.com",
    "emailhearing.com",
    "emailinfive.com",
    "emailisvalid.com",
    "emaillime.com",
    "emailmiser.com",
    "emailondeck.com",
    "emailpinoy.com",
    "emailproxsy.com",
    "emailresort.com",
    "emails.incisivemedia.com",
    "emailsecurer.com",
    "emailservice.com",
    "emailsydney.com",
    "emailtemporanea.com",
    "emailtmp.com",
    "emailwarden.com",
    "emailxfer.com",
    "ematic.com",
    "embarqmail.com",
    "emeraldwebmail.com",
    "emil.com",
    "emlhub.com",
    "emltmp.com",
    "empas.com",
    "empereur.com",
    "emplawyernet.com",
    "emstjzh.com",
    "emumail.com",
    "enayu.com",
    "engineer.com",
    "engineering-ai.com",
    "englandmail.com",
    "enterto.com",
    "envy17.com",
    "eonmech.com",
    "eos2mail.com",
    "epatra.com",
    "epiqmail.com",
    "epomail.com",
    "eprompter.com",
    "eqqu.com",
    "equiemail.com",
    "equonecredite.com",
    "erasf.com",
    "erols.com",
    "escapehatchapp.com",
    "esemay.com",
    "esgeneri.com",
    "espana-official.com",
    "esseriod.com",
    "est-a-la-maison.com",
    "est-mon-blogueur.com",
    "etalase1.com",
    "etlgr.com",
    "etoic.com",
    "etrademail.com",
    "etszys.com",
    "eu6genetic.com",
    "eudoramail.com",
    "europe.com",
    "europemail.com",
    "eurosport.com",
    "evafan.com",
    "evcmail.com",
    "evertonfans.com",
    "evilbruce.com",
    "evilcomputer.com",
    "evyush.com",
    "ewroteed.com",
    "example.com",
    "exbts.com",
    "excite.com",
    "excitedchat.com",
    "execs.com",
    "exg6.exghost.com",
    "exoly.com",
    "expirebox.com",
    "explodemail.com",
    "expressasia.com",
    "extanewsmi.zzux.com",
    "extended.com",
    "eyepaste.com",
    "ezagenda.com",
    "ezcybersearch.com",
    "ezfill.com",
    "ezmail.egine.com",
    "ezrs.com",
    "ezstest.com",
    "f1drivers.com",
    "f5foster.com",
    "facebookmail.com",
    "facilesend.com",
    "fadingemail.com",
    "failbone.com",
    "faithkills.com",
    "fake-box.com",
    "fakedemail.com",
    "fakeinbox.com",
    "fakeinformation.com",
    "fakemailgenerator.com",
    "fakemailz.com",
    "falseaddress.com",
    "fammix.com",
    "fan.theboys.com",
    "fannclub.com",
    "fansonlymail.com",
    "fantasticmail.com",
    "farifluset.mailexpire.com",
    "faroweb.com",
    "fartwallet.com",
    "fast-coin.com",
    "fastacura.com",
    "fastchevy.com",
    "fastchrysler.com",
    "fastemailer.com",
    "fastermail.com",
    "fastimap.com",
    "fastkawasaki.com",
    "fastmail.com",
    "fastmailnow.com",
    "fastmessaging.com",
    "fastnissan.com",
    "fastsubaru.com",
    "fasttoyota.com",
    "fastyamaha.com",
    "fatflap.com",
    "fatyachts.com",
    "fbi-agent.com",
    "fc66998.com",
    "fdfdsfds.com",
    "feamail.com",
    "federalcontractors.com",
    "felicity.com",
    "felicitymail.com",
    "femenino.com",
    "fenceve.com",
    "ferton.com",
    "fetchmail.com",
    "fettometern.com",
    "feyenoorder.com",
    "fiberia.com",
    "fictionsite.com",
    "fificorp.com",
    "fightallspam.com",
    "figshot.com",
    "figurescoin.com",
    "filbert4u.com",
    "filipinolinks.com",
    "filzmail.com",
    "financier.com",
    "findfo.com",
    "findmail.com",
    "finebody.com",
    "finfin.com",
    "finklfan.com",
    "finnahappen.com",
    "fire-brigade.com",
    "firstcapitalfibers.com",
    "fishfuse.com",
    "five-club.com",
    "fivestarclt.com",
    "fizmail.com",
    "flashemail.com",
    "flashmail.com",
    "flemail.com",
    "flipcode.com",
    "floodbrother.com",
    "flowu.com",
    "fls4.gleeze.com",
    "flurre.com",
    "flypicks.com",
    "flytecrew.com",
    "fmailbox.com",
    "fmctc.com",
    "fmguy.com",
    "fnmail.com",
    "folkfan.com",
    "foodbooto.com",
    "footard.com",
    "football.theboys.com",
    "foquita.com",
    "for-president.com",
    "for4mail.com",
    "forecastertests.com",
    "forestfans.com",
    "forgetmail.com",
    "forotenis.com",
    "forpresident.com",
    "fortuncity.com",
    "fossefans.com",
    "foundationbay.com",
    "foxja.com",
    "fq1my2c.com",
    "francefans.com",
    "frapmail.com",
    "freddymail.com",
    "free-org.com",
    "freeaccount.com",
    "freeandsingle.com",
    "freebabysittercam.com",
    "freebox.com",
    "freechristianbookstore.com",
    "freedom.usa.com",
    "freedomlover.com",
    "freefanmail.com",
    "freefattymovies.com",
    "freeghana.com",
    "freemail.bozz.com",
    "freemaillink.com",
    "freemommyvids.com",
    "freeola.com",
    "freeolamail.com",
    "freeplumpervideos.com",
    "freeproblem.com",
    "freeschoolgirlvids.com",
    "freeservers.com",
    "freeshemaledvds.com",
    "freesistervids.com",
    "freeteenbums.com",
    "freetubearchive.com",
    "freewebemail.com",
    "fresnomail.com",
    "friendlydevices.com",
    "friends-cafe.com",
    "from-africa.com",
    "from-al.com",
    "from-america.com",
    "from-argentina.com",
    "from-australia.com",
    "from-belgium.com",
    "from-ca.com",
    "from-canada.com",
    "from-ct.com",
    "from-de.com",
    "from-england.com",
    "from-fl.com",
    "from-ga.com",
    "from-hi.com",
    "from-holland.com",
    "from-id.com",
    "from-in.com",
    "from-israel.com",
    "from-korea.com",
    "from-ky.com",
    "from-ma.com",
    "from-mexico.com",
    "from-mn.com",
    "from-ms.com",
    "from-nc.com",
    "from-ne.com",
    "from-nj.com",
    "from-nv.com",
    "from-oh.com",
    "from-or.com",
    "from-outerspace.com",
    "from-pr.com",
    "from-russia.com",
    "from-sd.com",
    "from-tn.com",
    "from-ut.com",
    "from-vt.com",
    "from-wi.com",
    "from-wy.com",
    "fromalabama.com",
    "fromarizona.com",
    "fromcalifornia.com",
    "fromconnecticut.com",
    "fromgeorgia.com",
    "fromidaho.com",
    "fromillinois.com",
    "fromiowa.com",
    "fromjupiter.com",
    "fromkentucky.com",
    "frommaryland.com",
    "frommassachusetts.com",
    "frommichigan.com",
    "frommississippi.com",
    "frommontana.com",
    "fromnevada.com",
    "fromnewhampshire.com",
    "fromnewmexico.com",
    "fromnorthcarolina.com",
    "fromohio.com",
    "fromoklahoma.com",
    "frompennsylvania.com",
    "fromru.com",
    "fromsouthcarolina.com",
    "fromtennessee.com",
    "fromthestates.com",
    "fromvermont.com",
    "fromwashington.com",
    "fromwestvirginia.com",
    "fromwyoming.com",
    "frontier.com",
    "fsmilitary.com",
    "ftpbd.com",
    "fuckedupload.com",
    "fuckrosoft.com",
    "fudgerub.com",
    "fuhoy.com",
    "fulhamfans.com",
    "fullmail.com",
    "fun64.com",
    "funiran.com",
    "funky4.com",
    "funnycodesnippets.com",
    "fuorissimo.com",
    "furnitureprovider.com",
    "fusemail.com",
    "fuwamofu.com",
    "fux0ringduh.com",
    "fw6m0bd.com",
    "fwbfleamarket.gccoxmail.com",
    "fxnxs.com",
    "fxsmails.com",
    "g-mailix.com",
    "g05zeg9i.com",
    "g212dnk5.com",
    "g4444mail.com",
    "galaxy5.com",
    "game.com",
    "gamebox.com",
    "gamegeek.com",
    "gamegregious.com",
    "games.com",
    "gamespotmail.com",
    "gamil.com",
    "garage46.com",
    "garbage.com",
    "gardener.com",
    "garizo.com",
    "garliclife.com",
    "garrifulio.mailexpire.com",
    "gathelabuc.almostmy.com",
    "gav0.com",
    "gay.com",
    "gazibooks.com",
    "geararticles.com",
    "gee-wiz.com",
    "geek.com",
    "geekforex.com",
    "gencmail.com",
    "general-hospital.com",
    "genxemail.com",
    "geocities.com",
    "geologist.com",
    "geopia.com",
    "germanymail.com",
    "geronra.com",
    "get1mail.com",
    "getairmail.com",
    "geteit.com",
    "getinboxes.com",
    "getmyip.com",
    "getnada.com",
    "getnowdirect.com",
    "getocity.com",
    "getsimpleemail.com",
    "gfcom.com",
    "gh2000.com",
    "ghanamail.com",
    "ghostmail.com",
    "giaiphapmuasam.com",
    "giantsfan.com",
    "gicua.com",
    "gift-link.com",
    "gimal.com",
    "girl-beautiful.com",
    "girl-nice.com",
    "girlncool.com",
    "girlsindetention.com",
    "girlsundertheinfluence.com",
    "GishPuppy.com",
    "giuypaiw8.com",
    "givepeaceachance.com",
    "givmy.com",
    "gleeze.com",
    "globalpagan.com",
    "globaltouron.com",
    "globo.com",
    "globomail.com",
    "glucosegrin.com",
    "gmail.com",
    "gmail.com.com",
    "gmail.gr.com",
    "gmaildottrick.com",
    "gmailup.com",
    "gmaul.com",
    "gmial.com",
    "gmx.com",
    "gmx.dnsabr.com",
    "gnctr-calgary.com",
    "gnwmail.com",
    "go2net.com",
    "gocollege.com",
    "godut.com",
    "goemailgo.com",
    "gofuckporn.com",
    "gol.com",
    "goldtoolbox.com",
    "golfemail.com",
    "golfwidows.com",
    "gomail.pgojual.com",
    "gonuts4free.com",
    "goodluckforu.cn.com",
    "goodstick.com",
    "googledottrick.com",
    "googlemail.com",
    "goooomail.com",
    "goplay.com",
    "gorillaswithdirtyarmpits.com",
    "gospelfan.com",
    "gotdns.com",
    "gothere.uk.com",
    "gotmail.com",
    "gotomy.com",
    "gotti.otherinbox.com",
    "govolsfan.com",
    "gowikicampus.com",
    "gowikifilms.com",
    "gowikimusic.com",
    "gowikinetwork.com",
    "gowikitv.com",
    "grabmail.com",
    "grandmamail.com",
    "graphic-designer.com",
    "grapplers.com",
    "gratis-gratis.com",
    "gratisweb.com",
    "greenhousemail.com",
    "greensloth.com",
    "greentech5.com",
    "gregorygamel.com",
    "greyjack.com",
    "grimsbyfans.com",
    "groupmail.com",
    "growlcombine.com",
    "grungecafe.com",
    "gudanglowongan.com",
    "guerillamail.com",
    "guerillamailblock.com",
    "guerrillamail.com",
    "guerrillamailblock.com",
    "guitarsxltd.com",
    "gulftel.com",
    "gunnersfans.com",
    "gurlmail.com",
    "gustr.com",
    "guy.com",
    "guyanafriends.com",
    "gwhsgeckos.com",
    "gwspt71.com",
    "gynzi.com",
    "gyorsposta.com",
    "h.mintemail.com",
    "h1z8ckvz.com",
    "h9js8y6.com",
    "hablas.com",
    "hacccc.com",
    "hackermail.com",
    "hahawrong.com",
    "hairdresser.com",
    "halofarmasi.com",
    "hammerdin.com",
    "hammersfans.com",
    "handbag.com",
    "hang-ten.com",
    "hangglidemail.com",
    "happemail.com",
    "happyalmostfriday.com",
    "happygoluckyclub.com",
    "happypuppy.com",
    "haqed.com",
    "harakirimail.com",
    "hardcorefreak.com",
    "hardyoungbabes.com",
    "haribu.com",
    "hawaii.rr.com",
    "hawrong.com",
    "haylam.com",
    "hazelnut4u.com",
    "hbo.dnsabr.com",
    "hcoupledp.com",
    "headbone.com",
    "heartsfans.com",
    "heathenhammer.com",
    "heathenhq.com",
    "heerschap.com",
    "hehe.com",
    "heihamail.com",
    "hellokitty.com",
    "helter-skelter.com",
    "hempseed.com",
    "herediano.com",
    "herono1.com",
    "hexagonmail.com",
    "hey.com",
    "hezll.com",
    "hg8n415.com",
    "hhdevel.com",
    "hi6547mue.com",
    "hiddentragedy.com",
    "hidemyass.com",
    "hidzz.com",
    "highmilton.com",
    "highquality.com",
    "hilarious.com",
    "hillary-email.com",
    "hinduhome.com",
    "hiphopfan.com",
    "hiru-dea.com",
    "hispavista.com",
    "hissfuse.com",
    "hitmail.com",
    "hitmanrecords.com",
    "hjdosage.com",
    "hjkcfa3o.com",
    "hkstarphoto.com",
    "hmamail.com",
    "hndard.com",
    "ho3twwn.com",
    "hoanggiaanh.com",
    "hobby-site.com",
    "hochsitze.com",
    "hollywoodkids.com",
    "homail.com",
    "home-email.com",
    "homeart.com",
    "homelinux.com",
    "homelocator.com",
    "homemail.com",
    "homenetmail.com",
    "homeonthethrone.com",
    "homeunix.com",
    "homeworkcentral.com",
    "honduras.com",
    "hongpress.com",
    "hoopsmail.com",
    "hopoverview.com",
    "horrormail.com",
    "host-info.com",
    "hostcalls.com",
    "hostlaba.com",
    "hot-shop.com",
    "hot.rr.com",
    "hotamil.com",
    "hotbrev.com",
    "hotcoolmail.com",
    "hotelsatparis.com",
    "hotletter.com",
    "hotlook.com",
    "hotmai.com",
    "hotmail.com",
    "hotmaill.com",
    "hotmailproduct.com",
    "hotmial.com",
    "hotpop.com",
    "hotvoice.com",
    "housat.com",
    "housemail.com",
    "hroundb.com",
    "hs130.com",
    "hstermail.com",
    "html.tou.com",
    "hu4ht.com",
    "huangniu8.com",
    "hubii-network.com",
    "huiledargane.com",
    "hulksales.com",
    "humanux.com",
    "humour.com",
    "hungpackage.com",
    "hunsa.com",
    "hurting.com",
    "hush.com",
    "hushmail.com",
    "hvc.rr.com",
    "hvtechnical.com",
    "hypernautica.com",
    "i-connect.com",
    "i-love-cats.com",
    "i-p.com",
    "i.ryanb.com",
    "i12.com",
    "i201zzf8x.com",
    "i35t0a5.com",
    "i66g2i2w.com",
    "iafrica.com",
    "iamallama.com",
    "iamfinallyonline.com",
    "iamwasted.com",
    "iaoss.com",
    "ibsats.com",
    "icantbelieveineedtoexplainthisshit.com",
    "icestorm.com",
    "ichimail.com",
    "icmsconsultants.com",
    "icq.com",
    "icqmail.com",
    "id-base.com",
    "id10tproof.com",
    "ididitmyway.com",
    "idirect.com",
    "idx4.com",
    "iencm.com",
    "iffymedia.com",
    "ignmail.com",
    "ignoremail.com",
    "ihwy.com",
    "iigmail.com",
    "ijustdontcare.com",
    "ikkjacket.com",
    "ilikespam.com",
    "illistnoise.com",
    "ilnostrogrossograssomatrimoniomolisano.com",
    "ilovejesus.com",
    "ilovelionking.com",
    "ilovepokemonmail.com",
    "ilovethemovies.com",
    "imaginemail.com",
    "imailbox.com",
    "imankul.com",
    "imgjar.com",
    "imneverwrong.com",
    "imperfectron.com",
    "improvedtt.com",
    "imstressed.com",
    "imtoosexy.com",
    "in.com",
    "in.mailsac.com",
    "inaby.com",
    "inappmail.com",
    "inbox.com",
    "inboxalias.com",
    "inboxclean.com",
    "inboxkitten.com",
    "inboxproxy.com",
    "incamail.com",
    "includingarabia.com",
    "incognitomail.com",
    "incq.com",
    "increase5f.com",
    "incredimail.com",
    "indeedemail.com",
    "india.com",
    "india2in.com",
    "indieclad.com",
    "indo-mail.com",
    "indoliqueur.com",
    "indomail.com",
    "indonesianherbalmedicine.com",
    "industyle.com",
    "inerted.com",
    "infinityclippingpath.com",
    "info.com",
    "info66.com",
    "infohq.com",
    "informaticos.com",
    "infospacemail.com",
    "inmail24.com",
    "innocent.com",
    "inonesearch.com",
    "inoutbox.com",
    "insanumingeniumhomebrew.com",
    "insight.rr.com",
    "inspectorjavert.com",
    "instaku-media.com",
    "instantemailaddress.com",
    "instantmailaddress.com",
    "instruction.com",
    "insurer.com",
    "intempmail.com",
    "interaccess.com",
    "internet-club.com",
    "internet-police.com",
    "internetbiz.com",
    "internetegypt.com",
    "internetmci.com",
    "internetoftags.com",
    "intersteller.com",
    "investormail.com",
    "iobox.com",
    "iowaemail.com",
    "ip3.com",
    "ipdeer.com",
    "iphoneaccount.com",
    "ipiranga.dynu.com",
    "iportalexpress.com",
    "ipswell.com",
    "iqemail.com",
    "irabops.com",
    "iraqmail.com",
    "ireland.com",
    "irelandmail.com",
    "irish2me.com",
    "irishspringrealty.com",
    "iroid.com",
    "is-a-anarchist.com",
    "is-a-bookkeeper.com",
    "is-a-bulls-fan.com",
    "is-a-caterer.com",
    "is-a-chef.com",
    "is-a-conservative.com",
    "is-a-cubicle-slave.com",
    "is-a-designer.com",
    "is-a-financialadvisor.com",
    "is-a-green.com",
    "is-a-hard-worker.com",
    "is-a-landscaper.com",
    "is-a-liberal.com",
    "is-a-libertarian.com",
    "is-a-llama.com",
    "is-a-musician.com",
    "is-a-nurse.com",
    "is-a-personaltrainer.com",
    "is-a-player.com",
    "is-a-republican.com",
    "is-a-socialist.com",
    "is-a-student.com",
    "is-a-techie.com",
    "is-a-therapist.com",
    "is-an-actor.com",
    "is-an-anarchist.com",
    "is-an-engineer.com",
    "is-an-entertainer.com",
    "is-certified.com",
    "is-gone.com",
    "is-into-anime.com",
    "is-into-cartoons.com",
    "is-leet.com",
    "is-not-certified.com",
    "is-slick.com",
    "is-uberleet.com",
    "is-with-theband.com",
    "isa-geek.com",
    "isa-hockeynut.com",
    "iscooler.com",
    "isellcars.com",
    "isleuthmail.com",
    "isonews2.com",
    "isosq.com",
    "ispuntheweb.com",
    "israelmail.com",
    "issmarterthanyou.com",
    "istreamingtoday.com",
    "isukrainestillacountry.com",
    "italy-mail.com",
    "itimes.com",
    "itloox.com",
    "itmtx.com",
    "its0k.com",
    "itunesgiftcodegenerator.com",
    "ivebeenframed.com",
    "ivillage.com",
    "iwantmyname.com",
    "iwmail.com",
    "ix.netcom.com",
    "izadpanah.com",
    "j3rqt89ez.com",
    "jabble.com",
    "jackmailer.com",
    "jacquelx.com",
    "jadopado.com",
    "jahoopa.com",
    "janproz.com",
    "japanyn7ys.com",
    "jaydemail.com",
    "jazzfan.com",
    "jbnote.com",
    "jdmadventures.com",
    "jeanvaljean.com",
    "jellyrolls.com",
    "jeramywebb.com",
    "jerusalemmail.com",
    "jetable.com",
    "jetableemail.com",
    "jewishmail.com",
    "jfkislanders.com",
    "jiaxin8736.com",
    "jilossesq.com",
    "jklasdf.com",
    "jnpayy.com",
    "jnxjn.com",
    "job4u.com",
    "joelonsoftware.com",
    "joetestalot.com",
    "joinme.com",
    "jokes.com",
    "jopho.com",
    "jordanmail.com",
    "journalist.com",
    "joymail.com",
    "jp.bigplanet.com",
    "jpopmail.com",
    "jsrsolutions.com",
    "jswfdb48z.com",
    "jugglepile.com",
    "jump.com",
    "junctiondx.com",
    "jungkamushukum.com",
    "junk.yourdomain.com",
    "junkmail.com",
    "juno.com",
    "jupimail.com",
    "just4spam.com",
    "justicemail.com",
    "justmailz.com",
    "justmarriedmail.com",
    "justnowmail.com",
    "juyouxi.com",
    "jwk4227ufn.com",
    "jyliananderik.com",
    "k2-herbal-incenses.com",
    "k3opticsf.com",
    "kaazoo.com",
    "kademen.com",
    "kadokatelco.com",
    "kaffeeschluerfer.com",
    "kaijenwan.com",
    "kalemproje.com",
    "kamsg.com",
    "kandymail.com",
    "kansascity.com",
    "kaovo.com",
    "kapoorweb.com",
    "karachian.com",
    "karbasi.com",
    "kartvelo.com",
    "kasmail.com",
    "katamail.com",
    "katztube.com",
    "kbjrmail.com",
    "kchkch.com",
    "kebi.com",
    "keepmymail.com",
    "keepmyshitprivate.com",
    "keftamail.com",
    "kekecog.com",
    "kellychen.com",
    "kemptvillebaseball.com",
    "kennedy808.com",
    "keromail.com",
    "kevintrankt.com",
    "kewpee.com",
    "keyemail.com",
    "keynote.com",
    "khalifahallah.com",
    "khoabung.com",
    "khosropour.com",
    "kiani.com",
    "kichimail.com",
    "kickmark.com",
    "killamail.com",
    "killergreenmail.com",
    "killmail.com",
    "kimo.com",
    "kimsdisk.com",
    "kingyslmail.com",
    "kiois.com",
    "kissfans.com",
    "kisstwink.com",
    "kitemail.com",
    "kitnastar.com",
    "kitten-mittons.com",
    "kittymail.com",
    "kiwibox.com",
    "klassmaster.com",
    "klipschx12.com",
    "kludgemush.com",
    "kmail.mooo.com",
    "koiqe.com",
    "kon42.com",
    "konx.com",
    "kopagas.com",
    "kopqi.com",
    "koreamail.com",
    "kosmetik-obatkuat.com",
    "kozmail.com",
    "kpooa.com",
    "kramatjegu.com",
    "krongthip.com",
    "krull.com",
    "ksanmail.com",
    "kuai909.com",
    "kuaijenwan.com",
    "kuatkanakun.com",
    "kube93mail.com",
    "kuhrap.com",
    "kuiqa.com",
    "kukamail.com",
    "kumarweb.com",
    "kunimedesu.com",
    "kusrc.com",
    "kuwait-mail.com",
    "kweci.com",
    "kyois.com",
    "kyokodate.com",
    "l.safdv.com",
    "l2n5h8c7rh.com",
    "l5prefixm.com",
    "l6factors.com",
    "l8oaypr.com",
    "laboriously.com",
    "ladyfire.com",
    "lagerlouts.com",
    "lahoreoye.com",
    "lakelivingstonrealestate.com",
    "lakqs.com",
    "lalamailbox.com",
    "lamdx.com",
    "langoo.com",
    "lankamail.com",
    "laoeq.com",
    "laonanrenj.com",
    "larisia.com",
    "lastmail.com",
    "latinmail.com",
    "laurenbt.com",
    "lavabit.com",
    "law.com",
    "lawlita.com",
    "lawyer.com",
    "lazyinbox.com",
    "lbjmail.com",
    "lcelander.com",
    "ldaho0ak.com",
    "ldtp.com",
    "leapradius.com",
    "lebanonatlas.com",
    "ledoktre.com",
    "legalactions.com",
    "legislator.com",
    "lenovog4.com",
    "lequitywk.com",
    "lesbugs.com",
    "letmeinonthis.com",
    "letsmail9.com",
    "letthemeatspam.com",
    "levele.com",
    "lexis-nexis-mail.com",
    "lexmark.com",
    "leysatuhell.sendsmtp.com",
    "lgloos.com",
    "lgxscreen.com",
    "lhsdv.com",
    "liamcyrus.com",
    "liberomail.com",
    "lick101.com",
    "lifebyfood.com",
    "lifetotech.com",
    "ligsb.com",
    "likes-pie.com",
    "likesyouback.com",
    "lilylee.com",
    "lindenbaumjapan.com",
    "linkmaster.com",
    "linuxfreemail.com",
    "liontrucks.com",
    "lissamail.com",
    "listomail.com",
    "literaturelover.com",
    "littleblueroom.com",
    "live.com",
    "liverpoolfans.com",
    "ljiljan.com",
    "lkim1wlvpl.com",
    "lkxloans.com",
    "llandudno.com",
    "lm0k.com",
    "lmcudh4h.com",
    "ln0hio.com",
    "loaoa.com",
    "lobbyist.com",
    "localgenius.com",
    "located6j.com",
    "locos.com",
    "logular.com",
    "loketa.com",
    "lol.com",
    "london.com",
    "loobie.com",
    "looksmart.com",
    "lookugly.com",
    "lopeure.com",
    "lordsofts.com",
    "losemymail.com",
    "louiskoo.com",
    "love.com",
    "loveable.com",
    "lovecat.com",
    "lovefootball.com",
    "loveforlostcats.com",
    "lovemail.com",
    "lovemeleaveme.com",
    "lovergirl.com",
    "lovethebroncos.com",
    "lovetohike.com",
    "lovingjesus.com",
    "lowandslow.com",
    "lpfmgmtltd.com",
    "lr78.com",
    "lroid.com",
    "lucyu.com",
    "lukecarriere.com",
    "luminario.com",
    "luukku.com",
    "luvrhino.com",
    "lvfityou.com",
    "lvwebmail.com",
    "lyahoo.com",
    "lycos.com",
    "lycosemail.com",
    "lyfestylecreditsolutions.com",
    "lzoaq.com",
    "m-hmail.com",
    "m.ddcrew.com",
    "m2r60ff.com",
    "ma.rr.com",
    "ma2limited.com",
    "maboard.com",
    "mac.hush.com",
    "macfreak.com",
    "machinecandy.com",
    "macr2.com",
    "macromaid.com",
    "mad.scientist.com",
    "madcreations.com",
    "madonnafan.com",
    "madurahoki.com",
    "maennerversteherin.com",
    "magamail.com",
    "magicbeep.com",
    "mahdevip.com",
    "mahewov.tripod.com",
    "mahmoodweb.com",
    "mail-2-you.com",
    "mail-apps.com",
    "mail-card.com",
    "mail-center.com",
    "mail-fake.com",
    "mail-fix.com",
    "mail-it24.com",
    "mail-me.com",
    "mail-owl.com",
    "mail-register.com",
    "mail-temp.com",
    "mail-temporaire.com",
    "mail-tester.com",
    "mail.austria.com",
    "mail.bccto.com",
    "mail.bentrask.com",
    "mail.bulgaria.com",
    "mail.com",
    "mail.entrepeneurmag.com",
    "mail.fettometern.com",
    "mail.freetown.com",
    "mail.hitthebeach.com",
    "mail.illistnoise.com",
    "mail.kmsp.com",
    "mail.libivan.com",
    "mail.mailinator.com",
    "mail.mnisjk.com",
    "mail.partskyline.com",
    "mail.pharmacy.com",
    "mail.r-o-o-t.com",
    "mail.sisna.com",
    "mail.spaceports.com",
    "mail.theboys.com",
    "mail.tom.com",
    "mail.twfaka.com",
    "mail.usa.com",
    "mail15.com",
    "mail1st.com",
    "mail2007.com",
    "mail2aaron.com",
    "mail2abc.com",
    "mail2admiral.com",
    "mail2adoration.com",
    "mail2adventure.com",
    "mail2aether.com",
    "mail2affection.com",
    "mail2africa.com",
    "mail2aha.com",
    "mail2aim.com",
    "mail2airbag.com",
    "mail2airport.com",
    "mail2alan.com",
    "mail2albania.com",
    "mail2alec.com",
    "mail2algeria.com",
    "mail2alien.com",
    "mail2allen.com",
    "mail2alpha.com",
    "mail2amanda.com",
    "mail2amber.com",
    "mail2american.com",
    "mail2andrea.com",
    "mail2anesthesiologist.com",
    "mail2angola.com",
    "mail2anna.com",
    "mail2anthony.com",
    "mail2aphrodite.com",
    "mail2april.com",
    "mail2aquarius.com",
    "mail2arabic.com",
    "mail2architect.com",
    "mail2argentina.com",
    "mail2arizona.com",
    "mail2armenia.com",
    "mail2arnold.com",
    "mail2artemus.com",
    "mail2artist.com",
    "mail2ask.com",
    "mail2astronomer.com",
    "mail2athlete.com",
    "mail2atom.com",
    "mail2attitude.com",
    "mail2aunt.com",
    "mail2australia.com",
    "mail2azerbaijan.com",
    "mail2bahamas.com",
    "mail2ballerina.com",
    "mail2band.com",
    "mail2bangladesh.com",
    "mail2banker.com",
    "mail2baptist.com",
    "mail2barbados.com",
    "mail2barter.com",
    "mail2basketball.com",
    "mail2beach.com",
    "mail2beatles.com",
    "mail2becky.com",
    "mail2belgium.com",
    "mail2ben.com",
    "mail2bernard.com",
    "mail2betty.com",
    "mail2beyond.com",
    "mail2bill.com",
    "mail2billionaire.com",
    "mail2bio.com",
    "mail2biologist.com",
    "mail2blackbelt.com",
    "mail2blind.com",
    "mail2blues.com",
    "mail2bobby.com",
    "mail2bombay.com",
    "mail2bookmark.com",
    "mail2bosnia.com",
    "mail2botswana.com",
    "mail2brazil.com",
    "mail2breakfast.com",
    "mail2bride.com",
    "mail2brittany.com",
    "mail2brook.com",
    "mail2brunei.com",
    "mail2brussels.com",
    "mail2bug.com",
    "mail2bulgaria.com",
    "mail2buy.com",
    "mail2california.com",
    "mail2cambodia.com",
    "mail2canada.com",
    "mail2capeverde.com",
    "mail2cardinal.com",
    "mail2cardiologist.com",
    "mail2caroline.com",
    "mail2casey.com",
    "mail2caterer.com",
    "mail2catlover.com",
    "mail2cell.com",
    "mail2champaign.com",
    "mail2chef.com",
    "mail2chemist.com",
    "mail2chicago.com",
    "mail2china.com",
    "mail2chocolate.com",
    "mail2christie.com",
    "mail2christy.com",
    "mail2cindy.com",
    "mail2classifieds.com",
    "mail2cliff.com",
    "mail2clint.com",
    "mail2club.com",
    "mail2coastguard.com",
    "mail2college.com",
    "mail2color.com",
    "mail2colorado.com",
    "mail2comedian.com",
    "mail2computer.com",
    "mail2concert.com",
    "mail2connect.com",
    "mail2connecticut.com",
    "mail2convict.com",
    "mail2cool.com",
    "mail2costarica.com",
    "mail2courtney.com",
    "mail2cowgirl.com",
    "mail2crave.com",
    "mail2create.com",
    "mail2cry.com",
    "mail2crystal.com",
    "mail2culture.com",
    "mail2customs.com",
    "mail2cutey.com",
    "mail2cyprus.com",
    "mail2czechrepublic.com",
    "mail2dale.com",
    "mail2dan.com",
    "mail2dance.com",
    "mail2danielle.com",
    "mail2darlene.com",
    "mail2darren.com",
    "mail2dave.com",
    "mail2dc.com",
    "mail2dealer.com",
    "mail2dearest.com",
    "mail2debby.com",
    "mail2delaware.com",
    "mail2demeter.com",
    "mail2denise.com",
    "mail2dennis.com",
    "mail2derek.com",
    "mail2devoted.com",
    "mail2diamond.com",
    "mail2diane.com",
    "mail2dilemma.com",
    "mail2dinner.com",
    "mail2dionysos.com",
    "mail2director.com",
    "mail2disco.com",
    "mail2diver.com",
    "mail2divorced.com",
    "mail2doctor.com",
    "mail2dominic.com",
    "mail2dominicanrepublic.com",
    "mail2donald.com",
    "mail2doris.com",
    "mail2doug.com",
    "mail2douglas.com",
    "mail2downtown.com",
    "mail2dreamer.com",
    "mail2dustin.com",
    "mail2dylan.com",
    "mail2earth.com",
    "mail2eat.com",
    "mail2economist.com",
    "mail2eddie.com",
    "mail2edwin.com",
    "mail2electron.com",
    "mail2elizabeth.com",
    "mail2elliot.com",
    "mail2elsalvador.com",
    "mail2emergency.com",
    "mail2engineer.com",
    "mail2environmentalist.com",
    "mail2eric.com",
    "mail2erin.com",
    "mail2erinyes.com",
    "mail2eritrea.com",
    "mail2eros.com",
    "mail2estonia.com",
    "mail2ethiopia.com",
    "mail2europe.com",
    "mail2eva.com",
    "mail2evelyn.com",
    "mail2everything.com",
    "mail2expert.com",
    "mail2faith.com",
    "mail2fancy.com",
    "mail2farm.com",
    "mail2fashion.com",
    "mail2feeling.com",
    "mail2fever.com",
    "mail2fiji.com",
    "mail2filmfestival.com",
    "mail2finance.com",
    "mail2fireman.com",
    "mail2fisherman.com",
    "mail2florence.com",
    "mail2floyd.com",
    "mail2fond.com",
    "mail2fondness.com",
    "mail2footballfan.com",
    "mail2france.com",
    "mail2frankfurt.com",
    "mail2fred.com",
    "mail2freddie.com",
    "mail2freedom.com",
    "mail2freudian.com",
    "mail2from.com",
    "mail2gabon.com",
    "mail2gail.com",
    "mail2gambia.com",
    "mail2gary.com",
    "mail2gemini.com",
    "mail2genes.com",
    "mail2george.com",
    "mail2gerald.com",
    "mail2germany.com",
    "mail2gilbert.com",
    "mail2girl.com",
    "mail2gloria.com",
    "mail2gold.com",
    "mail2golfclub.com",
    "mail2gordon.com",
    "mail2government.com",
    "mail2grace.com",
    "mail2grandma.com",
    "mail2grant.com",
    "mail2green.com",
    "mail2grenada.com",
    "mail2guard.com",
    "mail2guatemala.com",
    "mail2hades.com",
    "mail2hal.com",
    "mail2handhelds.com",
    "mail2hannah.com",
    "mail2harry.com",
    "mail2headhunter.com",
    "mail2heather.com",
    "mail2hebe.com",
    "mail2heidi.com",
    "mail2hell.com",
    "mail2helpdesk.com",
    "mail2hephaestus.com",
    "mail2hercules.com",
    "mail2hermes.com",
    "mail2hestia.com",
    "mail2highschool.com",
    "mail2hip.com",
    "mail2hiphop.com",
    "mail2holly.com",
    "mail2hollywood.com",
    "mail2honduras.com",
    "mail2hongkong.com",
    "mail2horse.com",
    "mail2hotel.com",
    "mail2howard.com",
    "mail2human.com",
    "mail2hungry.com",
    "mail2hyperspace.com",
    "mail2ian.com",
    "mail2ice-cream.com",
    "mail2idaho.com",
    "mail2idontknow.com",
    "mail2imam.com",
    "mail2india.com",
    "mail2indiana.com",
    "mail2infinity.com",
    "mail2iowa.com",
    "mail2iraq.com",
    "mail2ireland.com",
    "mail2iris.com",
    "mail2irresistible.com",
    "mail2irwin.com",
    "mail2israel.com",
    "mail2italy.com",
    "mail2jacob.com",
    "mail2jaime.com",
    "mail2jamaica.com",
    "mail2jamie.com",
    "mail2jane.com",
    "mail2janice.com",
    "mail2japanese.com",
    "mail2jason.com",
    "mail2jay.com",
    "mail2jed.com",
    "mail2jeffrey.com",
    "mail2jenny.com",
    "mail2jerry.com",
    "mail2jessie.com",
    "mail2jew.com",
    "mail2jeweler.com",
    "mail2jimmy.com",
    "mail2joann.com",
    "mail2jody.com",
    "mail2joel.com",
    "mail2john.com",
    "mail2jon.com",
    "mail2jonathan.com",
    "mail2jordan.com",
    "mail2josh.com",
    "mail2juan.com",
    "mail2judy.com",
    "mail2juggler.com",
    "mail2julie.com",
    "mail2junk.com",
    "mail2justme.com",
    "mail2kansas.com",
    "mail2karen.com",
    "mail2karma.com",
    "mail2kathleen.com",
    "mail2katie.com",
    "mail2kazakhstan.com",
    "mail2keith.com",
    "mail2kelsey.com",
    "mail2kendall.com",
    "mail2kenneth.com",
    "mail2kentucky.com",
    "mail2kerry.com",
    "mail2kim.com",
    "mail2kimberly.com",
    "mail2kirk.com",
    "mail2kosher.com",
    "mail2kurt.com",
    "mail2kyle.com",
    "mail2kyrgyzstan.com",
    "mail2lacrosse.com",
    "mail2lao.com",
    "mail2latvia.com",
    "mail2laura.com",
    "mail2laurie.com",
    "mail2lawyer.com",
    "mail2lee.com",
    "mail2leon.com",
    "mail2leonard.com",
    "mail2leslie.com",
    "mail2liberia.com",
    "mail2libertarian.com",
    "mail2libya.com",
    "mail2liechtenstein.com",
    "mail2linda.com",
    "mail2lionel.com",
    "mail2liquid.com",
    "mail2lithuania.com",
    "mail2liz.com",
    "mail2lois.com",
    "mail2london.com",
    "mail2lori.com",
    "mail2lou.com",
    "mail2louisiana.com",
    "mail2love.com",
    "mail2lucy.com",
    "mail2lust.com",
    "mail2luxembourg.com",
    "mail2lyle.com",
    "mail2madagascar.com",
    "mail2madrid.com",
    "mail2mail4.com",
    "mail2malawi.com",
    "mail2maldives.com",
    "mail2malta.com",
    "mail2man.com",
    "mail2manhunter.com",
    "mail2many.com",
    "mail2marcia.com",
    "mail2margie.com",
    "mail2maria.com",
    "mail2marines.com",
    "mail2marriage.com",
    "mail2marries.com",
    "mail2marsha.com",
    "mail2marshallislands.com",
    "mail2martin.com",
    "mail2marvin.com",
    "mail2maryland.com",
    "mail2massachusetts.com",
    "mail2matthew.com",
    "mail2mauritania.com",
    "mail2max.com",
    "mail2maxwell.com",
    "mail2mba.com",
    "mail2mechanic.com",
    "mail2megan.com",
    "mail2melanie.com",
    "mail2melody.com",
    "mail2memphis.com",
    "mail2mexican.com",
    "mail2mgz.com",
    "mail2michael.com",
    "mail2michigan.com",
    "mail2milan.com",
    "mail2mildred.com",
    "mail2millennium.com",
    "mail2milton.com",
    "mail2mindreader.com",
    "mail2minister.com",
    "mail2minneapolis.com",
    "mail2miracle.com",
    "mail2missionary.com",
    "mail2missouri.com",
    "mail2model.com",
    "mail2moldova.commail2molly.com",
    "mail2monaco.com",
    "mail2mongolia.com",
    "mail2montana.com",
    "mail2moon.com",
    "mail2morocco.com",
    "mail2mors.com",
    "mail2moslem.com",
    "mail2mouseketeer.com",
    "mail2mozambique.com",
    "mail2mrright.com",
    "mail2museum.com",
    "mail2musician.com",
    "mail2my.com",
    "mail2myboat.com",
    "mail2mycell.com",
    "mail2mylaptop.com",
    "mail2mypager.com",
    "mail2mypc.com",
    "mail2myphone.com",
    "mail2namibia.com",
    "mail2nasdaq.com",
    "mail2nauru.com",
    "mail2neal.com",
    "mail2nebraska.com",
    "mail2neil.com",
    "mail2nemesis.com",
    "mail2netherlands.com",
    "mail2nevada.com",
    "mail2newhampshire.com",
    "mail2newmexico.com",
    "mail2newzealand.com",
    "mail2nick.com",
    "mail2niger.com",
    "mail2nike.com",
    "mail2noah.com",
    "mail2noelle.com",
    "mail2norman.com",
    "mail2northamerica.com",
    "mail2northdakota.com",
    "mail2norway.com",
    "mail2noway.com",
    "mail2nowhere.com",
    "mail2nuclear.com",
    "mail2ny.com",
    "mail2oasis.com",
    "mail2oceanographer.com",
    "mail2ok.com",
    "mail2oklahoma.com",
    "mail2oman.com",
    "mail2onfire.com",
    "mail2oops.com",
    "mail2ophthalmologist.com",
    "mail2oregon.com",
    "mail2oslo.com",
    "mail2painter.com",
    "mail2palau.com",
    "mail2panama.com",
    "mail2paralegal.com",
    "mail2park.com",
    "mail2party.com",
    "mail2pat.com",
    "mail2patricia.com",
    "mail2patty.com",
    "mail2paula.com",
    "mail2peace.com",
    "mail2pediatrician.com",
    "mail2pennsylvania.com",
    "mail2persephone.com",
    "mail2peru.com",
    "mail2peter.com",
    "mail2pharmacist.com",
    "mail2philippines.com",
    "mail2phonecall.com",
    "mail2pickup.com",
    "mail2pisces.com",
    "mail2platinum.com",
    "mail2pluto.com",
    "mail2podiatrist.com",
    "mail2poland.com",
    "mail2policeman.com",
    "mail2politician.com",
    "mail2pope.com",
    "mail2popular.com",
    "mail2poseidon.com",
    "mail2power.com",
    "mail2presbyterian.com",
    "mail2priest.com",
    "mail2princess.com",
    "mail2professor.com",
    "mail2psychiatrist.com",
    "mail2psychologist.com",
    "mail2queen.com",
    "mail2race.com",
    "mail2rachel.com",
    "mail2rainmaker.com",
    "mail2randy.com",
    "mail2rare.com",
    "mail2ray.com",
    "mail2raymond.com",
    "mail2rebecca.com",
    "mail2recycle.com",
    "mail2reed.com",
    "mail2register.com",
    "mail2republican.com",
    "mail2rex.com",
    "mail2rhodeisland.com",
    "mail2richard.com",
    "mail2ride.com",
    "mail2rita.com",
    "mail2robert.com",
    "mail2robin.com",
    "mail2rocker.com",
    "mail2rodney.com",
    "mail2rome.com",
    "mail2ronald.com",
    "mail2rose.com",
    "mail2roy.com",
    "mail2rudy.com",
    "mail2runner.com",
    "mail2russia.com",
    "mail2rusty.com",
    "mail2rwanda.com",
    "mail2sa.com",
    "mail2sabrina.com",
    "mail2sagittarius.com",
    "mail2sailor.com",
    "mail2salaam.com",
    "mail2samantha.com",
    "mail2samurai.com",
    "mail2sandy.com",
    "mail2sanfrancisco.com",
    "mail2santa.com",
    "mail2sarah.com",
    "mail2saturn.com",
    "mail2saudiarabia.com",
    "mail2savings.com",
    "mail2scientist.com",
    "mail2scott.com",
    "mail2search.com",
    "mail2secretagent.com",
    "mail2senegal.com",
    "mail2seth.com",
    "mail2sevenseas.com",
    "mail2seychelles.com",
    "mail2sharon.com",
    "mail2ship.com",
    "mail2shirley.com",
    "mail2shuttle.com",
    "mail2sierraleone.com",
    "mail2singapore.com",
    "mail2site.com",
    "mail2skier.com",
    "mail2sleek.com",
    "mail2slovakia.com",
    "mail2smile.com",
    "mail2smooth.com",
    "mail2soccerfan.com",
    "mail2soldier.com",
    "mail2son.com",
    "mail2sos.com",
    "mail2southafrica.com",
    "mail2southcarolina.com",
    "mail2southkorea.com",
    "mail2spain.com",
    "mail2spare.com",
    "mail2spectrum.com",
    "mail2sponsor.com",
    "mail2srilanka.com",
    "mail2stan.com",
    "mail2stanley.com",
    "mail2state.com",
    "mail2stephanie.com",
    "mail2steven.com",
    "mail2stlouis.com",
    "mail2stockholm.com",
    "mail2storage.com",
    "mail2strong.com",
    "mail2studio.com",
    "mail2stuntman.com",
    "mail2sudan.com",
    "mail2superstar.com",
    "mail2suriname.com",
    "mail2suzie.com",
    "mail2swaziland.com",
    "mail2sweetheart.com",
    "mail2swimmer.com",
    "mail2switzerland.com",
    "mail2sylvia.com",
    "mail2taboo.com",
    "mail2tajikistan.com",
    "mail2tango.com",
    "mail2tanzania.com",
    "mail2taurus.com",
    "mail2taxidermist.com",
    "mail2taz.com",
    "mail2teacher.com",
    "mail2technician.com",
    "mail2telephone.com",
    "mail2tenderness.com",
    "mail2tennis.com",
    "mail2tennisfan.com",
    "mail2terry.com",
    "mail2texas.com",
    "mail2thailand.com",
    "mail2think.com",
    "mail2tiffany.com",
    "mail2time.com",
    "mail2timothy.com",
    "mail2titanic.com",
    "mail2todd.com",
    "mail2tom.com",
    "mail2tonga.com",
    "mail2tor.com",
    "mail2tourist.com",
    "mail2tracy.com",
    "mail2travel.com",
    "mail2travis.com",
    "mail2trex.com",
    "mail2triallawyer.com",
    "mail2trillionaire.com",
    "mail2truck.com",
    "mail2try.com",
    "mail2tunisia.com",
    "mail2turkey.com",
    "mail2turkmenistan.com",
    "mail2tycoon.com",
    "mail2u4me.com",
    "mail2uganda.com",
    "mail2ukraine.com",
    "mail2unsubscribe.com",
    "mail2uruguay.com",
    "mail2utah.com",
    "mail2uzbekistan.com",
    "mail2vacation.com",
    "mail2valerie.com",
    "mail2vamoose.com",
    "mail2vanuatu.com",
    "mail2venous.com",
    "mail2vermont.com",
    "mail2victor.com",
    "mail2vienna.com",
    "mail2vince.com",
    "mail2virginia.com",
    "mail2visionary.com",
    "mail2volleyball.com",
    "mail2wallstreet.com",
    "mail2walter.com",
    "mail2washington.com",
    "mail2way.com",
    "mail2waycool.com",
    "mail2web.com",
    "mail2webmaster.com",
    "mail2webtv.com",
    "mail2wendell.com",
    "mail2westend.com",
    "mail2westvirginia.com",
    "mail2whip.com",
    "mail2whitehouse.com",
    "mail2why.com",
    "mail2wilbur.com",
    "mail2willard.com",
    "mail2wine.com",
    "mail2wired.com",
    "mail2wisconsin.com",
    "mail2wonder.com",
    "mail2worship.com",
    "mail2www.com",
    "mail2wyoming.com",
    "mail2xox.com",
    "mail2yachtclub.com",
    "mail2yemen.com",
    "mail2yugoslavia.com",
    "mail2zambia.com",
    "mail2zephir.com",
    "mail2zipper.com",
    "mail2zoologist.com",
    "mail3000.com",
    "mail4gmail.com",
    "mail707.com",
    "mail8.com",
    "mailabconline.com",
    "mailandftp.com",
    "mailas.com",
    "mailautomated01.com",
    "mailbidon.com",
    "mailblocks.com",
    "mailbolt.com",
    "mailboom.com",
    "mailbox92.com",
    "mailcan.com",
    "mailcatch.com",
    "mailcc.com",
    "mailchop.com",
    "mailcker.com",
    "maildx.com",
    "maileater.com",
    "maileme101.com",
    "mailexpire.com",
    "mailfall.com",
    "mailfavorite.com",
    "mailfly.com",
    "mailformail.com",
    "mailfreeonline.com",
    "mailfs.com",
    "mailftp.com",
    "mailgc.com",
    "mailgutter.com",
    "mailhazard.com",
    "mailhex.com",
    "mailimate.com",
    "mailinatar.com",
    "mailinator.com",
    "mailinator0.com",
    "mailinator2.com",
    "mailinator3.com",
    "mailinator5.com",
    "mailinator7.com",
    "mailinator9.com",
    "mailincubator.com",
    "mailingweb.com",
    "mailismagic.com",
    "mailite.com",
    "mailmaniacs.com",
    "mailme24.com",
    "mailmetrash.com",
    "mailmight.com",
    "mailmoat.com",
    "mailms.com",
    "mailnator.com",
    "mailnew.com",
    "mailnowapp.com",
    "mailo.com",
    "mailonaut.com",
    "mailorc.com",
    "mailoye.com",
    "mailpokemon.com",
    "mailpost.zzn.com",
    "mailpride.com",
    "mailprotech.com",
    "mailpuppy.com",
    "mailrazer.com",
    "mailroom.com",
    "mailru.com",
    "mailsac.com",
    "mailscdn.com",
    "mailscrap.com",
    "mailshell.com",
    "mailshuttle.com",
    "mailslapping.com",
    "mailsnails.com",
    "mailsor.com",
    "mailstart.com",
    "mailstartplus.com",
    "mailsucre.com",
    "mailtag.com",
    "mailtemporaire.com",
    "mailtothis.com",
    "mailtraps.com",
    "mailway.com",
    "mailwithyou.com",
    "mailwriting.com",
    "mailzilla.com",
    "maine.rr.com",
    "majorleaguemail.com",
    "makemetheking.com",
    "maktoob.com",
    "malayalamdtp.com",
    "malayalapathram.com",
    "malibucoding.com",
    "mallinator.com",
    "maltesemail.com",
    "manifestgenerator.com",
    "mansiondev.com",
    "mantrafreenet.com",
    "mantraonline.com",
    "manybrain.com",
    "marchmail.com",
    "mariahc.com",
    "marijuana.com",
    "marketingfanatic.com",
    "marketweighton.com",
    "married-not.com",
    "marriedandlovingit.com",
    "marsattack.com",
    "martindalemail.com",
    "masafigroupbd.com",
    "mash4077.com",
    "masrawy.com",
    "mastella.com",
    "matmail.com",
    "mattersjf8.com",
    "mauimail.com",
    "max-direct.com",
    "maximumedge.com",
    "mccreedymail.com",
    "mchsi.com",
    "mciek.com",
    "mcrmail.com",
    "me.com",
    "mealcash.com",
    "meanpeoplesuck.com",
    "mechanicalresumes.com",
    "medmail.com",
    "medsheet.com",
    "meetingmall.com",
    "megago.com",
    "megapoint.com",
    "mehrani.com",
    "meihuajun76.com",
    "meintick.com",
    "mekhong.com",
    "melodymail.com",
    "meltedbrownies.com",
    "meltmail.com",
    "members.student.com",
    "merseymail.com",
    "message.myspace.com",
    "messagez.com",
    "metacrawler.com",
    "metaping.com",
    "mettamarketingsolutions.com",
    "mexicomail.com",
    "miaferrari.com",
    "miatadriver.com",
    "midcoastcustoms.com",
    "midcoastsolutions.com",
    "midlertidig.com",
    "mierdamail.com",
    "migumail.com",
    "mihep.com",
    "miho-nakayama.com",
    "mikrotamanet.com",
    "millionaireintraining.com",
    "milmail.com",
    "mindless.com",
    "mindspring.com",
    "minecraftrabbithole.com",
    "minex-coin.com",
    "minister.com",
    "minsmail.com",
    "mintemail.com",
    "mittalweb.com",
    "mixmail.com",
    "mjfrogmail.com",
    "mkpfilm.com",
    "mlanime.com",
    "mmail.com",
    "mmailinater.com",
    "mmmmail.com",
    "mn.curppa.com",
    "mnage-ctrl-aplex.com",
    "moakt.com",
    "mobilbatam.com",
    "mobilhondasidoarjo.com",
    "moburl.com",
    "mochamail.com",
    "mockmyid.com",
    "modernenglish.com",
    "mohammed.com",
    "mohsenfb.com",
    "moldova.com",
    "molms.com",
    "mom-mail.com",
    "momslife.com",
    "monemail.com",
    "monsieurcinema.com",
    "monumentmail.com",
    "moomia.com",
    "moonman.com",
    "moose-mail.com",
    "moreawesomethanyou.com",
    "moreorcs.com",
    "morsin.com",
    "mortmesttesre.wikaba.com",
    "moruzza.com",
    "moscowmail.com",
    "most-wanted.com",
    "motormania.com",
    "mouthube0t.com",
    "movemail.com",
    "movieluver.com",
    "mowgli.jungleheart.com",
    "mozartmail.com",
    "mp3haze.com",
    "mpictureb.com",
    "mr-potatohead.com",
    "mrchinh.com",
    "mroneeye.com",
    "mrpost.com",
    "mrspender.com",
    "ms9.mailslite.com",
    "msb.minsmail.com",
    "msg.mailslite.com",
    "msgden.com",
    "msgwire.com",
    "msn.com",
    "mspeciosa.com",
    "msxd.com",
    "mt2014.com",
    "mt2016.com",
    "mtmdev.com",
    "mttestdriver.com",
    "muchomail.com",
    "muellemail.com",
    "mufux.com",
    "muhammadafandi.com",
    "munich.com",
    "musclemailbox.com",
    "musician.com",
    "muslim.com",
    "muslimemail.com",
    "mustbe.ignorelist.com",
    "mutantweb.com",
    "muuyharold.com",
    "mvdmail.com",
    "mvrht.com",
    "mxfuel.com",
    "mxp.dnsabr.com",
    "my.com",
    "my10minutemail.com",
    "my6mail.com",
    "mybestmail.com",
    "mycabin.com",
    "mycasualtshirt.com",
    "mycity.com",
    "mycool.com",
    "mydomain.com",
    "mydotcomaddress.com",
    "myemailboxy.com",
    "myfamily.com",
    "myfunnymail.com",
    "mygo.com",
    "mygoldenmail.com",
    "myiris.com",
    "myjazzmail.com",
    "mykickassideas.com",
    "mylenecholy.com",
    "mymacmail.com",
    "mymail.com",
    "mymail90.com",
    "mymailoasis.com",
    "mynamedot.com",
    "mynet.com",
    "mynetaddress.com",
    "myopang.com",
    "myownemail.com",
    "mypad.com",
    "mypersonalemail.com",
    "myphantomemail.com",
    "myplace.com",
    "myrealbox.com",
    "myself.com",
    "mysentmail.com",
    "mysistersvids.com",
    "myspacepimpedup.com",
    "mystupidjob.com",
    "mytempemail.com",
    "mythirdage.com",
    "mytrashmail.com",
    "mytrashmailer.com",
    "mytrommleronline.com",
    "myway.com",
    "mywikitree.com",
    "myzat.com",
    "müllemail.com",
    "n2.com",
    "n2baseball.com",
    "n2mail.com",
    "n2software.com",
    "nabuma.com",
    "nafe.com",
    "nagamems.com",
    "nakedgreens.com",
    "name.com",
    "namefake.com",
    "nanaseaikawa.com",
    "nandomail.com",
    "nascimento.com",
    "nate.com",
    "nationalgardeningclub.com",
    "naturalious.com",
    "naver.com",
    "naz.com",
    "nbzmr.com",
    "nchoicemail.com",
    "ndsupernet.com",
    "neb.rr.com",
    "negated.com",
    "neibu963.com",
    "nekto.com",
    "nemra1.com",
    "neo.rr.com",
    "neomailbox.com",
    "nepnut.com",
    "net-c.com",
    "net-list.com",
    "net-shopping.com",
    "netaddress.com",
    "netbounce.com",
    "netbroadcaster.com",
    "netcmail.com",
    "netcom.com",
    "netcourrier.com",
    "netexecutive.com",
    "netfirms.com",
    "nethotmail.com",
    "netkushi.com",
    "netlimit.com",
    "netmail-pro.com",
    "netmails.com",
    "netmanor.com",
    "netpiper.com",
    "netradiomail.com",
    "netscape.com",
    "netsgo.com",
    "netspeedway.com",
    "netster.com",
    "nettemail.com",
    "netviewer-france.com",
    "network-source.com",
    "networkofemail.com",
    "netzoola.com",
    "neustreet.com",
    "neverenuff.com",
    "new-purse.com",
    "new.rr.com",
    "newairmail.com",
    "newmail.com",
    "newsboysmail.com",
    "newsusfun.com",
    "newtmail.com",
    "newyork.com",
    "newyorkcity.com",
    "nextmail.com",
    "nextstopvalhalla.com",
    "nezzart.com",
    "nfmail.com",
    "nguyenusedcars.com",
    "nhmail.com",
    "nice-4u.com",
    "nicebush.com",
    "nicewoodenbaskets.com",
    "nicknassar.com",
    "nicolastse.com",
    "nightimeuk.com",
    "nikopage.com",
    "nimail.com",
    "nincsmail.com",
    "ninfan.com",
    "nipponian.com",
    "njc65c15z.com",
    "nnh.com",
    "no-spammers.com",
    "noavar.com",
    "noblepioneer.com",
    "nobulk.com",
    "noclickemail.com",
    "nofbi.com",
    "noicd.com",
    "noifeelings.com",
    "nokiamail.com",
    "nomail2me.com",
    "nomailthankyou.com",
    "nomorespamemails.com",
    "nonpartisan.com",
    "nonstopcinema.com",
    "norih.com",
    "norika-fujiwara.com",
    "norseforce.com",
    "norwaymail.com",
    "nospam2me.com",
    "nospamme.com",
    "nostockui.com",
    "not0k.com",
    "notmailinator.com",
    "notyouagain.com",
    "now.mefound.com",
    "nowmymail.com",
    "ntlworld.com",
    "nubescontrol.com",
    "nurdea.com",
    "nurdintv.com",
    "nuts2trade.com",
    "nvc-e.com",
    "nwytg.com",
    "ny.com",
    "nybce.com",
    "nyc.com",
    "nyc.rr.com",
    "nycmail.com",
    "nypato.com",
    "nyusul.com",
    "nzoomail.com",
    "o060bgr3qg.com",
    "o3enzyme.com",
    "oaklandas-fan.com",
    "oalsp.com",
    "oaudienceij.com",
    "objectmail.com",
    "obobbo.com",
    "obxpestcontrol.com",
    "odaymail.com",
    "odem.com",
    "oduyzrp.com",
    "oepia.com",
    "office-email.com",
    "officedomain.com",
    "offroadwarrior.com",
    "oh.rr.com",
    "oicexchange.com",
    "oiizz.com",
    "oiqas.com",
    "ojooo.com",
    "okbank.com",
    "okclprojects.com",
    "okmad.com",
    "okuk.com",
    "oldbuthealthy.com",
    "oldies104mail.com",
    "olemail.com",
    "olg.com",
    "omail.com",
    "omaninfo.com",
    "omtamvan.com",
    "ondikoi.com",
    "onebiginbox.com",
    "onecitymail.com",
    "onelegalplan.com",
    "onemail1.com",
    "oneoffemail.com",
    "oneuk.com",
    "onewaymail.com",
    "onlinecasinogamblings.com",
    "onlinewiz.com",
    "onmicrosoft.com",
    "ono.com",
    "onobox.com",
    "onqin.com",
    "onvillage.com",
    "oohioo.com",
    "oohotmail.com",
    "oolus.com",
    "opayq.com",
    "opendiary.com",
    "opentrash.com",
    "operamail.com",
    "opmmail.com",
    "opp24.com",
    "optician.com",
    "oranek.com",
    "orange-bonplan.com",
    "ordinaryyz1.com",
    "orotab.com",
    "osendingwr.com",
    "oso.com",
    "otakumail.com",
    "otherinbox.codupmyspace.com",
    "otomax-pro.com",
    "our-computer.com",
    "ourbrisbane.com",
    "ourpreviewdomain.com",
    "outgun.com",
    "outlawspam.com",
    "outlook.com",
    "outloook.com",
    "ov3u841.com",
    "over-the-rainbow.com",
    "ovimail.com",
    "ovvee.com",
    "owlpic.com",
    "oxfarm1.com",
    "ozost.com",
    "ozozwd2p.com",
    "ozumz.com",
    "p71ce1m.com",
    "pachilly.com",
    "pacific-ocean.com",
    "pacificwest.com",
    "paddlepanel.com",
    "paidforsurf.com",
    "pakistanmail.com",
    "palestinemail.com",
    "pamapamo.com",
    "pamposhtrophy.com",
    "pancakemail.com",
    "pandawa.com",
    "panix.com",
    "paplease.com",
    "paradiseemail.com",
    "paris.com",
    "parisannonce.com",
    "parisvipescorts.com",
    "parkjiyoon.com",
    "parleasalwebp.zyns.com",
    "parrot.com",
    "partlycloudy.com",
    "parvazi.com",
    "passwordmail.com",
    "pastebitch.com",
    "pathfindermail.com",
    "patmail.com",
    "patonce.com",
    "paulfucksallthebitches.com",
    "pavilionx2.com",
    "payperex2.com",
    "pc1520.com",
    "pccw.com",
    "pcmylife.com",
    "pcpostal.com",
    "pcsrock.com",
    "pcusers.otherinbox.com",
    "pdold.com",
    "peacemail.com",
    "peapz.com",
    "pecdo.com",
    "pecinan.com",
    "pectcandtive.gettrials.com",
    "pediatrician.com",
    "pekklemail.com",
    "pelecandesign.com",
    "penpen.com",
    "peopledrivecompanies.com",
    "peoplepc.com",
    "pepbot.com",
    "perfectmail.com",
    "personales.com",
    "peterdethier.com",
    "petertijj.com",
    "petml.com",
    "petrolgames.com",
    "pettypool.com",
    "pezeshkpour.com",
    "pharmasiana.com",
    "phayze.com",
    "phpieso.com",
    "pi8.com",
    "pianomail.com",
    "picfame.com",
    "pickupman.com",
    "pidmail.com",
    "piercedallover.com",
    "pigeonportal.com",
    "pilotemail.com",
    "pimagop.com",
    "pimpedupmyspace.com",
    "pingir.com",
    "pinoymail.com",
    "pisls.com",
    "pjjkp.com",
    "planet-mail.com",
    "planetaccess.com",
    "planetdirect.com",
    "planetmail.com",
    "planetout.com",
    "playcard-semi.com",
    "playful.com",
    "playstation.sony.com",
    "ploae.com",
    "pluno.com",
    "plus.google.com",
    "plutocow.com",
    "pobox.com",
    "pochta.com",
    "poetic.com",
    "pokemonpost.com",
    "pokiemobile.com",
    "polandmail.com",
    "polbox.com",
    "policeoffice.com",
    "politician.com",
    "poly-swarm.com",
    "polyswarms.com",
    "pooae.com",
    "poohfan.com",
    "pool-sharks.com",
    "popaccount.com",
    "popesodomy.com",
    "popmail.com",
    "popsmail.com",
    "popstar.com",
    "pornoseti.com",
    "portableoffice.com",
    "portugalmail.com",
    "portugalnet.com",
    "positive-thinking.com",
    "postaccesslite.com",
    "postafree.com",
    "postaweb.com",
    "postinbox.com",
    "postmaster.twitter.com",
    "posvabotma.x24hr.com",
    "pousa.com",
    "poutineyourface.com",
    "powerfan.com",
    "powlearn.com",
    "ppc-e.com",
    "pqoia.com",
    "praize.com",
    "pray247.com",
    "premiumproducts.com",
    "preseven.com",
    "prettierthanher.com",
    "priest.com",
    "primposta.com",
    "priorityxn5.com",
    "privatemail.com",
    "privaterelay.appleid.com",
    "privy-mail.com",
    "probemail.com",
    "proeasyweb.com",
    "profilific.com",
    "proinbox.com",
    "project-xhabbo.com",
    "projectcl.com",
    "prolaunch.com",
    "promessage.com",
    "prontonmail.com",
    "propscore.com",
    "protectanimals.com",
    "protestant.com",
    "proto2mail.com",
    "protonmail.com",
    "proxsei.com",
    "proxyparking.com",
    "prtnx.com",
    "psles.com",
    "psv-supporter.com",
    "public.usa.com",
    "pubmail886.com",
    "puglieisi.com",
    "pullcombine.com",
    "pumps-fashion.com",
    "punkass.com",
    "purinmail.com",
    "purpleturtle.com",
    "putthisinyourspamdatabase.com",
    "pwrby.com",
    "pyahoo.com",
    "q.com",
    "q5vm7pi9.com",
    "q7t43q92.com.com",
    "qacquirep.com",
    "qasti.com",
    "qatarmail.com",
    "qdice.com",
    "qdrwriterx.com",
    "qeispacesq.com",
    "qiaua.com",
    "qisdo.com",
    "qj97r73md7v5.com",
    "qmail.com",
    "qocya.com",
    "qpalong.com",
    "qq.com",
    "qrio.com",
    "qs.dp76.com",
    "qtum-ico.com",
    "quadrafit.com",
    "quakemail.com",
    "qualityservice.com",
    "qudsmail.com",
    "querydirect.com",
    "queuem.com",
    "quichebedext.freetcp.com",
    "quickhosts.com",
    "quickwebmail.com",
    "quiklinks.com",
    "ququb.com",
    "quuradminb.com",
    "qwarmingu.com",
    "qwfox.com",
    "qwickmail.com",
    "qxpaperk.com",
    "r-o-o-t.com",
    "r2cakes.com",
    "r7.com",
    "r8r4p0cb.com",
    "raakim.com",
    "racedriver.com",
    "racingmail.com",
    "radecoratingltd.com",
    "radiodale.com",
    "raetp9.com",
    "ragingbull.com",
    "rajetempmail.com",
    "ralib.com",
    "ranmamail.com",
    "rapidmailbox.com",
    "raqid.com",
    "ratt-n-roll.com",
    "rattle-snake.com",
    "rattlecore.com",
    "ravearena.com",
    "ravemail.com",
    "rawmails.com",
    "raxtest.com",
    "razormail.com",
    "rcasd.com",
    "rcn.com",
    "re-gister.com",
    "reagan.com",
    "reallymymail.com",
    "realtyagent.com",
    "reborn.com",
    "receiveee.chickenkiller.com",
    "recipeforfailure.com",
    "reconmail.com",
    "recruitaware.com",
    "recycledmail.com",
    "recycler.com",
    "recyclermail.com",
    "reddcoin2.com",
    "reddithub.com",
    "redfeathercrow.com",
    "rediffmail.com",
    "rediffmailpro.com",
    "redpeanut.com",
    "redsfans.com",
    "redwhitearmy.com",
    "refurhost.com",
    "regbypass.com",
    "reggaefan.com",
    "regiononline.com",
    "registerednurses.com",
    "reincarnate.com",
    "relia.com",
    "reliable-mail.com",
    "renren.com",
    "repairman.com",
    "reply.ticketmaster.com",
    "represantive.com",
    "reptilegenetics.com",
    "rescueteam.com",
    "resource.calendar.google.com",
    "resumemail.com",
    "reubidium.com",
    "revenue.com",
    "rexian.com",
    "rfirewallj.com",
    "rheank.com",
    "rhombushorizons.com",
    "richmondhill.com",
    "rickymail.com",
    "ricrk.com",
    "risingsuntouch.com",
    "rk9.chickenkiller.com",
    "rkomo.com",
    "rmailcloud.com",
    "rn.com",
    "rnailinator.com",
    "roadrunner.com",
    "roastscreen.com",
    "robertspcrepair.com",
    "robot-mail.com",
    "rochester.rr.com",
    "rocketmail.com",
    "rocketship.com",
    "rockmailapp.com",
    "rodrun.com",
    "rogers.com",
    "rojname.com",
    "rome.com",
    "romymichele.com",
    "roosh.com",
    "rossifans.com",
    "rotfl.com",
    "roughnet.com",
    "rowe-solutions.com",
    "rpharmacist.com",
    "rq6668f.com",
    "rr.com",
    "rrohio.com",
    "rs311e8.com",
    "rsub.com",
    "rtrtr.com",
    "rubyridge.com",
    "ruffrey.com",
    "ruggedinbox.com",
    "runbox.com",
    "rundablage.com",
    "running-mushi.com",
    "rushpost.com",
    "rustydoor.com",
    "rvbspending.com",
    "rvshop.com",
    "ryanb.com",
    "s-mail.com",
    "s.vdig.com",
    "s33db0x.com",
    "s51zdw001.com",
    "sabreshockey.com",
    "sacbeemail.com",
    "sackboii.com",
    "safarimail.com",
    "safe-planet.com",
    "safenord.com",
    "safersignup.com",
    "safetempmail.com",
    "safrica.com",
    "saharanightstempe.com",
    "sailormoon.com",
    "saintly.com",
    "saktiemel.com",
    "sale-sale-sale.com",
    "sambalenak.com",
    "sambuzh.com",
    "sammimail.com",
    "san.rr.com",
    "sanchezsharks.com",
    "sandwhichvideo.com",
    "sanfranmail.com",
    "sanook.com",
    "sanriotown.com",
    "santanmail.com",
    "sanvekhuyenmai.com",
    "sapya.com",
    "satukosong.com",
    "saturnperformance.com",
    "saudia.com",
    "sausen.com",
    "savecougars.com",
    "saveowls.com",
    "saves-the-whales.com",
    "savewildlife.com",
    "saxophonexltd.com",
    "saynotospams.com",
    "sbcglobal.com",
    "sburningk.com",
    "sc.rr.com",
    "scandalmail.com",
    "scatmail.com",
    "schachrol.com",
    "schizo.com",
    "schoolemail.com",
    "schoolsucks.com",
    "scientist.com",
    "scotland.com",
    "scotlandmail.com",
    "scottishtories.com",
    "scrapbookscrapbook.com",
    "screamfused.com",
    "screechcontrol.com",
    "scrumexperts.com",
    "seanet.com",
    "search417.com",
    "searzh.com",
    "seasideorient.com",
    "seckinmail.com",
    "secret-police.com",
    "secretarias.com",
    "secure-fb.com",
    "secureinvox.com",
    "seductive.com",
    "seekfindask.com",
    "seekstoyboy.com",
    "sekomaonline.com",
    "selfdestructingmail.com",
    "selfip.com",
    "sellingspree.com",
    "sells-for-u.com",
    "semut-kecil.com",
    "sendingspecialflyers.com",
    "sendspamhere.com",
    "sennafans.com",
    "senseless-entertainment.com",
    "sent.com",
    "sentrismail.com",
    "servebbs.com",
    "servebeer.com",
    "servemymail.com",
    "servermuoihaikhongbon.com",
    "services391.com",
    "sesmail.com",
    "seven6s.com",
    "sexforswingers.com",
    "sexmagnet.com",
    "sezet.com",
    "sfamo.com",
    "sfs.com",
    "shakemain.com",
    "shaniastuff.com",
    "sharewaredevelopers.com",
    "sharklasers.com",
    "she.com",
    "shhmail.com",
    "shieldedmail.com",
    "shiftmail.com",
    "shinedyoureyes.com",
    "ship-from-to.com",
    "shipping-regulations.com",
    "shiprol.com",
    "shit.dnsabr.com",
    "shmeriously.com",
    "shootmail.com",
    "shopussy.com",
    "shortmail.com",
    "showfans.com",
    "shrib.com",
    "sialkotcity.com",
    "sialkotoye.com",
    "sibmail.com",
    "siddhacademy.com",
    "sify.com",
    "signaturefencecompany.com",
    "sikux.com",
    "simple-url.com",
    "sina.com",
    "sinamail.com",
    "singapore.com",
    "singles4jesus.com",
    "singmail.com",
    "singpost.com",
    "sink.fblay.com",
    "sirindia.com",
    "sizzlemctwizzle.com",
    "skafan.com",
    "skeefmail.com",
    "skim.com",
    "skunkbox.com",
    "sky.com",
    "sky.dnsabr.com",
    "skymailapp.com",
    "slamdunkfan.com",
    "slapsfromlastnight.com",
    "slingshot.com",
    "slopsbox.com",
    "slotter.com",
    "sltmail.com",
    "slushmail.com",
    "sm.westchestergov.com",
    "smailpro.com",
    "smapfree24.com",
    "smellfear.com",
    "smellrear.com",
    "smileyface.com",
    "sminkymail.com",
    "smoothmail.com",
    "smtp33.com",
    "snakebite.com",
    "snapunit.com",
    "sneakemail.com",
    "snkmail.com",
    "snoopymail.com",
    "socal.rr.com",
    "socceraccess.com",
    "soccermail.com",
    "sociologist.com",
    "sofimail.com",
    "sogetthis.com",
    "sohu.com",
    "soioa.com",
    "solution4u.com",
    "soodmail.com",
    "soodonims.com",
    "soozoop.com",
    "sosmanga.com",
    "soulfoodcookbook.com",
    "southparkmail.com",
    "spa.com",
    "space-bank.com",
    "space-ship.com",
    "space-to-rent.com",
    "space.com",
    "spaceart.com",
    "spacemart.com",
    "spacewar.com",
    "spacibbacmo.lflink.com",
    "spaerePlease.com",
    "spam-be-gone.com",
    "spam.coroiu.com",
    "spamarrest.com",
    "spambob.com",
    "spambog.com",
    "spambooger.com",
    "spambox.irishspringrealty.com",
    "spamcannon.com",
    "spamcero.com",
    "spamcorptastic.com",
    "spamday.com",
    "spameater.com",
    "spamex.com",
    "spamfree24.com",
    "spamgourmet.com",
    "spamherelots.com",
    "spamhereplease.com",
    "spamhole.com",
    "spaml.com",
    "spammedic.com",
    "spammotel.com",
    "spamslicer.com",
    "spamsphere.com",
    "spamthisplease.com",
    "spankthedonkey.com",
    "spazmail.com",
    "speedrules.com",
    "spikio.com",
    "spindl-e.com",
    "spiritseekers.com",
    "sporexbet.com",
    "sportsmail.com",
    "sporttruckdriver.com",
    "spreaddashboard.com",
    "sprintmail.com",
    "sprynet.com",
    "spymac.com",
    "squirtsnap.com",
    "sroff.com",
    "ssanphones.com",
    "ssl-mail.com",
    "sso-demo-okta.com",
    "ssongs34f.com",
    "stacklance.com",
    "staircraft5.com",
    "stampsprint.com",
    "stanfordujjain.com",
    "stargateradio.com",
    "starmail.com",
    "starmedia.com",
    "starspath.com",
    "startfu.com",
    "starting-point.com",
    "startrekmail.com",
    "statdvr.com",
    "statiix.com",
    "stationatprominence.com",
    "stealthmail.com",
    "steamprank.com",
    "stevesmusclecars.arcoxmail.com",
    "stg.malibucoding.com",
    "stny.rr.com",
    "stockracer.com",
    "stomach4m.com",
    "stonerfans.com",
    "stop-my-spam.com",
    "stopdropandroll.com",
    "storegmail.com",
    "storeyee.com",
    "storj99.com",
    "storksite.com",
    "streetwisemail.com",
    "stribmail.com",
    "strompost.com",
    "stuckmail.com",
    "stuff.munrohk.com",
    "stumblemanage.com",
    "stx.rr.com",
    "subnetwork.com",
    "subram.com",
    "suburbanthug.com",
    "suckmyd.com",
    "suddenlinkmail.com",
    "sudomail.com",
    "sudoverse.com",
    "sudoworld.com",
    "suhabi.com",
    "sumberkadalnya.com",
    "sunmail1.com",
    "sunrise-sunset.com",
    "supercoinmail.com",
    "supergreatmail.com",
    "superplatyna.com",
    "superrito.com",
    "surat.com",
    "surewest.com",
    "surfree.com",
    "surimail.com",
    "survivormail.com",
    "swapinsta.com",
    "swedenmail.com",
    "swift-mail.com",
    "swift10minutemail.com",
    "swindonfans.com",
    "swingeasyhithard.com",
    "swipermail.zzn.com",
    "swissmail.com",
    "switchboardmail.com",
    "swomc.com",
    "sx172.com",
    "sxylc113.com",
    "syadouchebag.com",
    "symphonyresume.com",
    "syom.com",
    "syriamail.com",
    "system-2125.com",
    "system-962.com",
    "szerz.com",
    "t-shirtcasual.com",
    "t24e4p7.com",
    "t3t97d1d.com",
    "taglead.com",
    "tagmymedia.com",
    "takdhinadhin.com",
    "takoe.com",
    "takuyakimura.com",
    "talk21.com",
    "talkinator.com",
    "tamarahillard.com",
    "tamil.com",
    "tampabay.rr.com",
    "tangmonkey.com",
    "tankpolice.com",
    "taosjw.com",
    "taotaotano.com",
    "tapchicuoihoi.com",
    "tarlancapital.com",
    "taskforcetech.com",
    "tatanova.com",
    "tattooedallover.com",
    "taxi-france.com",
    "taylorventuresllc.com",
    "tbwt.com",
    "tdf-illustration.com",
    "teacher.com",
    "teaches-yoga.com",
    "tech-center.com",
    "tech5group.com",
    "techemail.com",
    "techie.com",
    "technologist.com",
    "technologyandstocks.com",
    "techpointer.com",
    "techseek.com",
    "techspot.com",
    "teenagedirtbag.com",
    "teerest.com",
    "telebot.com",
    "telerymd.com",
    "teleserve.dynip.com",
    "teleworm.com",
    "telia.com",
    "telmex.com",
    "telstra.com",
    "temp-emails.com",
    "temp-mails.com",
    "temp.emeraldwebmail.com",
    "temp15qm.com",
    "tempail.com",
    "tempe-mail.com",
    "tempemail.com",
    "tempemailaddress.com",
    "tempimbox.com",
    "tempinbox.com",
    "tempmail2.com",
    "tempmaildemo.com",
    "tempmailid.com",
    "temporamail.com",
    "temporary-email.com",
    "temporaryforwarding.com",
    "temporarymailaddress.com",
    "tempsky.com",
    "tempymail.com",
    "tenchiclub.com",
    "tennismail.com",
    "tepzo.com",
    "terminalerror.com",
    "terra.com",
    "test.com",
    "testudine.com",
    "tf7nzhw.com",
    "thai.com",
    "thaimail.com",
    "thankyou2010.com",
    "the-african.com",
    "the-aliens.com",
    "the-animal.com",
    "the-astronaut.com",
    "the-big-apple.com",
    "the-boss.com",
    "the-brazilian.com",
    "the-canuck.com",
    "the-chinese.com",
    "the-cowboy.com",
    "the-davis-home.com",
    "the-eagles.com",
    "the-englishman.com",
    "the-fool.com",
    "the-frenchman.com",
    "the-genius.com",
    "the-gentleman.com",
    "the-gremlin.com",
    "the-italian.com",
    "the-lair.com",
    "the-mailinglist.com",
    "the-master.com",
    "the-ministry.com",
    "the-pentagon.com",
    "the-prayer.com",
    "the-professional.com",
    "the-russian.com",
    "the-snake.com",
    "the-spaceman.com",
    "the-stock-market.com",
    "the-wild-west.com",
    "theaperturelabs.com",
    "theaperturescience.com",
    "thebearshark.com",
    "thecloudindex.com",
    "thecriminals.com",
    "thedoghousemail.com",
    "theeasymail.com",
    "thefirstticket.com",
    "thegirlhot.com",
    "thegolfcourse.com",
    "theheadoffice.com",
    "theinternetemail.com",
    "thelimestones.com",
    "themegreview.com",
    "themostemail.com",
    "theothermail.com",
    "thepokerface.com",
    "theraces.com",
    "theracetrack.com",
    "thereisnogod.com",
    "thescrappermovie.com",
    "theskymail.com",
    "thespawningpool.com",
    "thestreetfighter.com",
    "theteebox.com",
    "thewatercooler.com",
    "thewizzard.com",
    "thexyz.com",
    "thinkingus24.com",
    "thisgirl.com",
    "thisisnotmyrealemail.com",
    "thnikka.com",
    "thoic.com",
    "thraml.com",
    "throam.com",
    "throwam.com",
    "throwawayemail.com",
    "throwawayemailaddress.com",
    "throya.com",
    "thundermail.com",
    "thxmate.com",
    "tiapz.com",
    "tibetemail.com",
    "ticklecontrol.com",
    "tilien.com",
    "timgiarevn.com",
    "timormail.com",
    "tinyurl24.com",
    "tipsandadvice.com",
    "tizi.com",
    "tkcity.com",
    "tkjngulik.com",
    "tlcfan.com",
    "tm.tosunkaya.com",
    "tmail.com",
    "tmailinator.com",
    "tmailservices.com",
    "tmobile.com",
    "toast.com",
    "toastmatrix.com",
    "toddsbighug.com",
    "toiea.com",
    "tokyo.com",
    "tonymanso.com",
    "toolsource.com",
    "toothandmail.com",
    "topaddress.com",
    "topchat.com",
    "topcoolemail.com",
    "topfreeemail.com",
    "topikt.com",
    "topladygirl.com",
    "topletter.com",
    "topmailings.com",
    "topofertasdehoy.com",
    "toprumours.com",
    "topteengirl.com",
    "torba.com",
    "torchmail.com",
    "torontomail.com",
    "tosunkaya.com",
    "total.com",
    "totalmail.com",
    "totalsurf.com",
    "totesmail.com",
    "townisp.com",
    "tp-qa-mail.com",
    "tpaglucerne.dnset.com",
    "tpg24.com",
    "tqoai.com",
    "trainspottingfan.com",
    "tranceversal.com",
    "trash-mail.com",
    "trash-me.com",
    "trash2010.com",
    "trash247.com",
    "trashcanmail.com",
    "trashinbox.com",
    "trashmail.com",
    "trashmailer.com",
    "trashspam.com",
    "trasz.com",
    "travala10.com",
    "trayna.com",
    "trbvn.com",
    "triad.rr.com",
    "trialbytrivia.com",
    "trillianpro.com",
    "trimsj.com",
    "trmailbox.com",
    "trollproject.com",
    "tropicalstorm.com",
    "truckers.com",
    "truckracer.com",
    "trungtamtoeic.com",
    "truth247.com",
    "tryalert.com",
    "tryzoe.com",
    "tt2dx90.com",
    "ttfans.com",
    "tualias.com",
    "tucumcaritonite.com",
    "tug.minecraftrabbithole.com",
    "tunisiamail.com",
    "turbonutter.com",
    "turkey.com",
    "turual.com",
    "tutamail.com",
    "tutye.com",
    "tvchd.com",
    "tverya.com",
    "tvstar.com",
    "twcny.com",
    "twcny.rr.com",
    "twentylove.com",
    "twinstarsmail.com",
    "two0aks.com",
    "twoweirdtricks.com",
    "txt7e99.com",
    "txtadvertise.com",
    "ty.squirtsnap.com",
    "tyldd.com",
    "typemail.com",
    "u2club.com",
    "u4nzbr5q3.com",
    "ua3jx7n0w3.com",
    "uacrossad.com",
    "uaemail.com",
    "uapproves.com",
    "uber-mail.com",
    "uboot.com",
    "ubuntu.dnsabr.com",
    "ucylu.com",
    "uemail99.com",
    "uggsrock.com",
    "uguuchantele.com",
    "uikd.com",
    "ujames3nh.com",
    "ujxspots.com",
    "uk2k.com",
    "ukbuilder.com",
    "ukcool.com",
    "ukdreamcast.com",
    "ukfun.com",
    "uklandlords.com",
    "ukmax.com",
    "uknuts.com",
    "ukpeople.com",
    "ukrtop.com",
    "ukshoppers.com",
    "ukuni.com",
    "ulaptopsn.com",
    "ultimatelimos.com",
    "ultrapostman.com",
    "umail2.com",
    "umpire.com",
    "umrohdulu.com",
    "unbounded.com",
    "underwriters.com",
    "unforgettable.com",
    "unids.com",
    "unit7lahaina.com",
    "unitelsd.com",
    "univision.com",
    "unlimit.com",
    "unomail.com",
    "uogtritons.com",
    "uole.com",
    "uolmail.com",
    "upgcsjy.com",
    "upliftnow.com",
    "uploadnolimit.com",
    "upskirtscr.com",
    "uraniomail.com",
    "urbanchickencoop.com",
    "urbismail.com",
    "urfey.com",
    "urhen.com",
    "usa.com",
    "usanetmail.com",
    "uscaves.com",
    "userbeam.com",
    "username.e4ward.com",
    "userzap.com",
    "usiaj.com",
    "utma.com",
    "utooemail.com",
    "uw5t6ds54.com",
    "uyhip.com",
    "uyuyuy.com",
    "uzrip.com",
    "v-sexi.com",
    "v7ecub.com",
    "v8man.com",
    "vahoo.com",
    "valhalladev.com",
    "valuemailers.com",
    "varbizmail.com",
    "vcmail.com",
    "vdig.com",
    "veanlo.com",
    "vedula.com",
    "vegemail.com",
    "velnet.com",
    "venompen.com",
    "verdejo.com",
    "verizon.com",
    "verizondw.com",
    "verticalheaven.com",
    "veryrealemail.com",
    "vhglvi6o.com",
    "victoriantwins.com",
    "viditag.com",
    "viewcastmedia.com",
    "vikingsonly.com",
    "vimail24.com",
    "vinernet.com",
    "vip.126.com",
    "vip.21cn.com",
    "vip.qq.com",
    "vip.sohu.com",
    "vip.tom.com",
    "viralplays.com",
    "virginmedia.com",
    "virtual-email.com",
    "virtualactive.com",
    "virtualguam.com",
    "visa.dnsabr.com",
    "visitmail.com",
    "visto.com",
    "vistomail.com",
    "visualcities.com",
    "vivavelocity.com",
    "vixletdev.com",
    "vjtimail.com",
    "vlmail.com",
    "vmailcloud.com",
    "vmani.com",
    "vmpanda.com",
    "voidbay.com",
    "volcanomail.com",
    "voltaer.com",
    "vomoto.com",
    "vote-democrats.com",
    "vote-republicans.com",
    "votenet.com",
    "voxelcore.com",
    "vpfbattle.com",
    "vps30.com",
    "vpscloudvntoday.com",
    "vpsmobilecloudkb.com",
    "vpstraffic.com",
    "vr9.com",
    "vradportal.com",
    "vrloco.com",
    "vs904a6.com",
    "vssms.com",
    "vstartup4q.com",
    "vteachesb.com",
    "vubby.com",
    "vvx046q.com",
    "vztc.com",
    "w22fe21.com",
    "w3mailbox.com",
    "w6mail.com",
    "w9y9640c.com",
    "wahoye.com",
    "wakingupesther.com",
    "walla.com",
    "wanaboo.com",
    "wapda.com",
    "warau-kadoni.com",
    "warnednl2.com",
    "washingtongarricklawyers.com",
    "watch-harry-potter.com",
    "watchironman3onlinefreefullmovie.com",
    "watfordfans.com",
    "wbdet.com",
    "wca.cn.com",
    "web-police.com",
    "web2mailco.com",
    "webaddressbook.com",
    "webave.com",
    "webbworks.com",
    "webdream.com",
    "webemaillist.com",
    "webinbox.com",
    "webindia123.com",
    "webmails.com",
    "webmailv.com",
    "webname.com",
    "webprogramming.com",
    "webskulker.com",
    "webstation.com",
    "webtopmail.com",
    "weedmail.com",
    "weekonline.com",
    "wegwerfemail.com",
    "wegwerfemailadresse.com",
    "wehshee.com",
    "welikecookies.com",
    "welsh-lady.com",
    "wesleymail.com",
    "westnet.com",
    "wetrainbayarea.com",
    "wfought0o.com",
    "whale-mail.com",
    "whatiaas.com",
    "whatifanalytics.com",
    "whatsaas.com",
    "whidbey.com",
    "whipmail.com",
    "whisperfocus.com",
    "whoever.com",
    "wholecustomdesign.com",
    "whoopymail.com",
    "whopy.com",
    "whstores.com",
    "whtjddn.33mail.com",
    "wi.rr.com",
    "wibblesmith.com",
    "wickedmail.com",
    "wideopenwest.com",
    "wildmail.com",
    "wilemail.com",
    "willloc.com",
    "willselfdestruct.com",
    "windowslive.com",
    "windstream.com",
    "winfirst.com",
    "wingnutz.com",
    "winning.com",
    "wisconsincomedy.com",
    "witty.com",
    "wkbwmail.com",
    "wla9c4em.com",
    "wmlorgana.com",
    "wn8c38i.com",
    "woh.rr.com",
    "wolf-web.com",
    "wolfmission.com",
    "wombles.com",
    "wonder-net.com",
    "wordpress.com",
    "worker.com",
    "workmail.com",
    "worldbreak.com",
    "worldemail.com",
    "wosaddict.com",
    "wow.com",
    "wowgirl.com",
    "wowmail.com",
    "wp2romantic.com",
    "wptamail.com",
    "wr9v6at7.com",
    "wrestlingpages.com",
    "writeme.com",
    "writemeback.com",
    "writesthisblog.com",
    "wrongmail.com",
    "wtvhmail.com",
    "wuhuw.com",
    "wvl238skmf.com",
    "wwpshop.com",
    "www.barryogorman.com",
    "www.com",
    "www.e4ward.com",
    "www.gishpuppy.com",
    "www.mailinator.com",
    "wyrm.supernews.com",
    "wzukltd.com",
    "x1x22716.com",
    "x5a9m8ugq.com",
    "x8h8x941l.com",
    "xagloo.com",
    "xasamail.com",
    "xc05fypuj.com",
    "xcompress.com",
    "xemaps.com",
    "xents.com",
    "xf.sluteen.com",
    "xgmailoo.com",
    "xinzk1ul.com",
    "xjoi.com",
    "xlgaokao.com",
    "xmail.com",
    "xmastime.com",
    "xmsg.com",
    "xn--4dbceig1b7e.com",
    "xn--mllemail-65a.com",
    "xnmail.mooo.com",
    "xoom.com",
    "xoommail.com",
    "xpectmore.com",
    "xperiae5.com",
    "xpressmail.zzn.com",
    "xrho.com",
    "xsmail.com",
    "xtram.com",
    "xuno.com",
    "xwaretech.com",
    "xxi2.com",
    "xxqx3802.com",
    "xzapmail.com",
    "xzsok.com",
    "y0brainx6.com",
    "y7mail.com",
    "yaboo.com",
    "yada-yada.com",
    "yahhoo.com",
    "yaho.com",
    "yahon.com",
    "yahoo.com",
    "yahoodashtrick.com",
    "yahoomail.com",
    "yalamail.com",
    "yalla.com",
    "yalook.com",
    "yandex.com",
    "yaoo.com",
    "yaqp.com",
    "yawmail.com",
    "ybmwukt.com",
    "yclub.com",
    "ydeclinegv.com",
    "yeah.com",
    "yebox.com",
    "yeehaa.com",
    "yehey.com",
    "yemenmail.com",
    "yeswebmaster.com",
    "yewmail.com",
    "ygroupvideoarchive.com",
    "yhoo.com",
    "yipple.com",
    "yjcoupone.com",
    "ykc.com",
    "ymail4.com",
    "yndrinks.com",
    "ynnmail.com",
    "yogamaven.com",
    "yopmail.com",
    "yopolis.com",
    "yoru-dea.com",
    "you.e4ward.com",
    "yougotgoated.com",
    "youmailr.com",
    "youpy.com",
    "youpymail.com",
    "your-mail.com",
    "yourewronghereswhy.com",
    "yourmailtoday.com",
    "yourname.freeservers.com",
    "yours.com",
    "yourssincerely.com",
    "yoursubdomain.findhere.com",
    "yourtempmail.com",
    "yourwap.com",
    "youthfire.com",
    "yroid.com",
    "yt-creator.com",
    "ytpayy.com",
    "yugasandrika.com",
    "yuoia.com",
    "yuurok.com",
    "yyhmail.com",
    "z6.com",
    "z7az14m.com",
    "z7az14m.com.com",
    "z9mail.com",
    "zahadum.com",
    "zamge.com",
    "zanmei5.com",
    "zasod.com",
    "zcities.com",
    "zdnetmail.com",
    "zebins.com",
    "zeeks.com",
    "zenbe.com",
    "zensearch.com",
    "zep-hyr.com",
    "zerotohero-1.com",
    "zeta-telecom.com",
    "zfymail.com",
    "zhaohishu.com",
    "zhcne.com",
    "zhewei88.com",
    "zipcad.com",
    "ziplip.com",
    "zipmax.com",
    "zippiex.com",
    "ziprol.com",
    "zipsendtest.com",
    "ziyap.com",
    "zjip.com",
    "zoaxe.com",
    "zoemail.com",
    "zoho.com",
    "zombie-hive.com",
    "zonai.com",
    "zooglemail.com",
    "zoqqa.com",
    "zoutlook.com",
    "zsero.com",
    "zubacteriax.com",
    "zubee.com",
    "zumpul.com",
    "zuvio.com",
    "zuzzurello.com",
    "zv68.com",
    "zvmail.com",
    "zw6provider.com",
    "zwallet.com",
    "zwoho.com",
    "zxcv.com",
    "zxcvbnm.com",
    "zxcxc.com",
    "zybermail.com",
    "zydecofan.com",
    "zymuying.com",
    "zzn.com",
    "zzom.co.uk1033edge.com",
    "zzom.co.uk123.com",
    "zzom.co.ukgmail.com",
    "zzom.co.ukhitmail.com",
    "zzom.co.uksina.com",
  ],
  pl: [
    "ee2.pl",
    "niepodam.pl",
    "podam.pl",
    "0.pl",
    "10g.pl",
    "10minut.com.pl",
    "10minutemail.pl",
    "10minutmail.pl",
    "2.pl",
    "amorki.pl",
    "asdf.pl",
    "autograf.pl",
    "bee.pl",
    "bij.pl",
    "btizet.pl",
    "cmoki.pl",
    "dwse.edu.pl",
    "ee1.pl",
    "f.moza.pl",
    "findu.pl",
    "fw.moza.pl",
    "g.pl",
    "gazeta.pl",
    "go2.pl",
    "gynzy.pl",
    "if.uj.edu.pl",
    "interia.pl",
    "itsme.edu.pl",
    "iv.pl",
    "jadamspam.pl",
    "jo.pl",
    "koszmail.pl",
    "kozacki.pl",
    "kyal.pl",
    "lykamspam.pl",
    "ma1l.bij.pl",
    "mailinator.pl",
    "mailmix.pl",
    "mailplus.pl",
    "migmail.pl",
    "mixbox.pl",
    "moza.pl",
    "net-c.pl",
    "netc.pl",
    "nie-podam.pl",
    "o2.pl",
    "one.pl",
    "onet.com.pl",
    "onet.pl",
    "op.pl",
    "opoczta.pl",
    "pedimed-szczecin.pl",
    "poczta.onet.pl",
    "posteo.pl",
    "prokonto.pl",
    "rr.ccs.pl",
    "scbox.one.pl",
    "spoko.pl",
    "szeptem.pl",
    "t.pl",
    "telecomix.pl",
    "tlen.pl",
    "uk.pl",
    "vip.onet.pl",
    "vp.pl",
    "wp.pl",
    "xzymoe.edu.pl",
    "yahoo.pl",
    "yandex.pl",
    "zzz.pl",
  ],
  biz: [
    "for-more.biz",
    "mailbox80.biz",
    "mailrock.biz",
    "001.igg.biz",
    "1-8.biz",
    "1thecity.biz",
    "333.igg.biz",
    "44556677.igg.biz",
    "a0.igg.biz",
    "ab0.igg.biz",
    "adgloselche.esmtp.biz",
    "ado888.biz",
    "alfaromeo.igg.biz",
    "asana.biz",
    "asik2in.biz",
    "audi.igg.biz",
    "babau.igg.biz",
    "bongobongo.igg.biz",
    "chef.asana.biz",
    "cnsa.biz",
    "coid.biz",
    "dvdxpress.biz",
    "e-mail.igg.biz",
    "email.biz",
    "eu.igg.biz",
    "fasternet.biz",
    "faze.biz",
    "film-blog.biz",
    "for-better.biz",
    "for-some.biz",
    "fun2.biz",
    "germanmails.biz",
    "geschent.biz",
    "goooogle.igg.biz",
    "gothere.biz",
    "guerillamail.biz",
    "guerrillamail.biz",
    "hafnia.biz",
    "hopemail.biz",
    "igg.biz",
    "islam.igg.biz",
    "italia.igg.biz",
    "jamesbond.igg.biz",
    "jeie.igg.biz",
    "kenecrehand.port25.biz",
    "kissyou.biz",
    "kommunity.biz",
    "ks87.igg.biz",
    "lolmail.biz",
    "madisonriver.biz",
    "mailbiz.biz",
    "mailblog.biz",
    "mailbox72.biz",
    "mailbox82.biz",
    "mailbox92.biz",
    "mailcat.biz",
    "mailgen.biz",
    "mailna.biz",
    "mailox.biz",
    "mailpick.biz",
    "mailrc.biz",
    "mao.igg.biz",
    "merda.igg.biz",
    "mmail.igg.biz",
    "mp-j.igg.biz",
    "mp.igg.biz",
    "nabc.biz",
    "nakedtruth.biz",
    "napalm51.igg.biz",
    "nurdea.biz",
    "ontyne.biz",
    "phentermine-mortgages-texas-holdem.biz",
    "postalmail.biz",
    "purple.igg.biz",
    "pw.igg.biz",
    "pw.islam.igg.biz",
    "r0.igg.biz",
    "rainmail.biz",
    "reallyfast.biz",
    "rxdoc.biz",
    "s.sa.igg.biz",
    "sa.igg.biz",
    "secure-mail.biz",
    "securemail.igg.biz",
    "selfip.biz",
    "shitaway.igg.biz",
    "shorterurl.biz",
    "spam.igg.biz",
    "streamfly.biz",
    "sudomail.biz",
    "t-online.biz",
    "tempemail.biz",
    "thecity.biz",
    "toomail.biz",
    "trump.igg.biz",
    "tweakacapun.wwwhost.biz",
    "uk.igg.biz",
    "urgentmail.biz",
    "veryfast.biz",
    "watchever.biz",
    "webhop.biz",
    "webmail.igg.biz",
    "willhackforfood.biz",
    "yellow.igg.biz",
    "yhg.biz",
    "ymails.biz",
    "youporn.igg.biz",
    "yourlms.biz",
    "z1p.biz",
    "zinfighkildo.ftpserver.biz",
    "zombo.igg.biz",
  ],
  cf: [
    "9mail9.cf",
    "aleanna704.cf",
    "discard.cf",
    "inibuatsgb.cf",
    "jumaprilia4191.cf",
    "logesra202.cf",
    "loghusnah2.cf",
    "password.nafko.cf",
    "0039.cf",
    "00b2bcr51qv59xst2.cf",
    "02466.cf",
    "07819.cf",
    "0x03.cf",
    "10minute.cf",
    "10minutemail.cf",
    "11-32.cf",
    "13sasytkgb0qobwxat.cf",
    "18-9-2.cf",
    "19922.cf",
    "1rmgqwfno8wplt.cf",
    "21lr12.cf",
    "2aitycnhnno6.cf",
    "2zpph1mgg70hhub.cf",
    "3krtqc2fr7e.cf",
    "3utasmqjcv.cf",
    "4mail.cf",
    "4up3vtaxujpdm2.cf",
    "4vq19hhmxgaruka.cf",
    "4xzotgbunzq.cf",
    "54tiljt6dz9tcdryc2g.cf",
    "5am5ung.cf",
    "5mail.cf",
    "6-6-6.cf",
    "666-satan.cf",
    "67832.cf",
    "6brmwv.cf",
    "6lhp5tembvpl.cf",
    "6mail.cf",
    "6q70sdpgjzm2irltn.cf",
    "7pccf.cf",
    "7uy35p.cf",
    "888z5.cf",
    "8imefdzddci.cf",
    "8mail.cf",
    "8oboi80bcv1.cf",
    "8xcdzvxgnfztticc.cf",
    "99mail.cf",
    "9mail.cf",
    "a-mule.cf",
    "aaronson.cf",
    "aasgashashashajh.cf",
    "ab-volvo.cf",
    "adadass.cf",
    "ag95.cf",
    "ahmadidik.cf",
    "aleagustina724.cf",
    "aleamanda606.cf",
    "aleanwisa439.cf",
    "alebutar-butar369.cf",
    "alegracia623.cf",
    "alemeutia520.cf",
    "alfa-romeo.cf",
    "alfaromeo147.cf",
    "anakjembutad.cf",
    "anaploxo.cf",
    "anayikt.cf",
    "anjingkokditolak.cf",
    "anthagine.cf",
    "antistream.cf",
    "aoltimewarner.cf",
    "aqazstnvw1v.cf",
    "aresanob.cf",
    "arybebekganteng.cf",
    "asahi.cf",
    "asgaccse-pt.cf",
    "asl13.cf",
    "astaghfirulloh.cf",
    "astonut.cf",
    "atoyot.cf",
    "audi-r8.cf",
    "audi-tt.cf",
    "avioaero.cf",
    "b6o7vt32yz.cf",
    "babau.cf",
    "backflip.cf",
    "barclays-plc.cf",
    "becausethenight.cf",
    "beeviee.cf",
    "beeviee1.cf",
    "belence.cf",
    "bellanotte.cf",
    "beresleting.cf",
    "berlusconi.cf",
    "beta.tyrex.cf",
    "bibicaba.cf",
    "bibucabi.cf",
    "bigtetek.cf",
    "biosor.cf",
    "bjmd.cf",
    "bkkpkht.cf",
    "bmw-ag.cf",
    "bmw-mini.cf",
    "bmw-rollsroyce.cf",
    "bmw-x5.cf",
    "bmw-z4.cf",
    "bnckms.cf",
    "bondrewd.cf",
    "bongobongo.cf",
    "boss.cf",
    "bp3xxqejba.cf",
    "bper.cf",
    "british-leyland.cf",
    "bsuakrqwbd.cf",
    "bungabunga.cf",
    "buntuty.cf",
    "bxfmtktkpxfkobzssqw.cf",
    "c0rtana.cf",
    "c4utar.cf",
    "c6h12o6.cf",
    "cahsintru.cf",
    "canyouhearmenow.cf",
    "casio-edu.cf",
    "cazzo.cf",
    "cc-s3x.cf",
    "cc2ilplyg77e.cf",
    "cebong.cf",
    "ceftvhxs7nln9.cf",
    "ceweknakal.cf",
    "channel9.cf",
    "chapar.cf",
    "chivasso.cf",
    "cjuprf2tcgnhslvpe.cf",
    "clipmail.cf",
    "cocodani.cf",
    "colafanta.cf",
    "colorweb.cf",
    "crypto.tyrex.cf",
    "csi-miami.cf",
    "csi-newyork.cf",
    "cts-lk-i.cf",
    "cul0.cf",
    "cvijqth6if8txrdt.cf",
    "d-link.cf",
    "da-da-da.cf",
    "daciasandero.cf",
    "daimlerag.cf",
    "dait.cf",
    "de-fake.instafly.cf",
    "deadfake.cf",
    "dekatri.cf",
    "delorieas.cf",
    "dengekibunko.cf",
    "desmo.cf",
    "dettol.cf",
    "dev-null.cf",
    "dhl-uk.cf",
    "didikselowcoffee.cf",
    "dikriemangasu.cf",
    "discard-email.cf",
    "dislike.cf",
    "disposable.cf",
    "dkert2mdi7sainoz.cf",
    "dl812pqedqw.cf",
    "dmc-12.cf",
    "dragcok2.cf",
    "dwango.cf",
    "dwipalinggantengyanglainlewat.cf",
    "dwutuemzudvcb.cf",
    "e-mule.cf",
    "e.discard-email.cf",
    "earthworksyar.cf",
    "ecocap.cf",
    "ef2qohn1l4ctqvh.cf",
    "ehoie03og3acq3us6.cf",
    "eins-zwei.cf",
    "email-fake.cf",
    "emailage.cf",
    "emailfake.cf",
    "emailfalsa.cf",
    "emailofnd.cf",
    "emailspam.cf",
    "emailz.cf",
    "emeil.cf",
    "emkei.cf",
    "emran.cf",
    "emule.cf",
    "enron.cf",
    "eur-sec1.cf",
    "eur0.cf",
    "f1kzc0d3.cf",
    "f39mltl5qyhyfx.cf",
    "facebook-email.cf",
    "fake-mail.cf",
    "fake.i-3gk.cf",
    "fakeinbox.cf",
    "fakemails.cf",
    "fannny.cf",
    "fca-nv.cf",
    "febbraio.cf",
    "ferastya.cf",
    "fiat-chrysler.cf",
    "fiat500.cf",
    "fiatgroup.cf",
    "fikrihidayah.cf",
    "fikrinhdyh.cf",
    "firef0x.cf",
    "foreskin.cf",
    "framemail.cf",
    "free-email.cf",
    "freemails.cf",
    "friscaa.cf",
    "fs16dubzzn0.cf",
    "fujitv.cf",
    "fuw65d.cf",
    "fw-nietzsche.cf",
    "g00g.cf",
    "g00glechr0me.cf",
    "g7lkrfzl7t0rb9oq.cf",
    "gajesajflk.cf",
    "garingsin.cf",
    "garrynacov.cf",
    "geaviation.cf",
    "geludkita.cf",
    "get-mail.cf",
    "getairmail.cf",
    "getnada.cf",
    "getnowtoday.cf",
    "gfmail.cf",
    "gg-byron.cf",
    "giooig.cf",
    "glassworks.cf",
    "goliokao.cf",
    "grangmi.cf",
    "group-llc.cf",
    "groupe-psa.cf",
    "guvewfmn7j1dmp.cf",
    "gwfh.cf",
    "h2o-web.cf",
    "hamusoku.cf",
    "hangxomcuatoilatotoro.cf",
    "hasegawa.cf",
    "hate.cf",
    "helrey.cf",
    "hendrikarifqiariza.cf",
    "hfmf.cf",
    "hgrmnh.cf",
    "hisalotk.cf",
    "hishyau.cf",
    "hitler-adolf.cf",
    "hiyrey.cf",
    "hkft7pttuc7hdbnu.cf",
    "hot-mail.cf",
    "hrb67.cf",
    "hrgmgka.cf",
    "hsls5guu0cv.cf",
    "hushmail.cf",
    "i-3gk.cf",
    "i-sp.cf",
    "i537244.cf",
    "idihgabo.cf",
    "idnkil.cf",
    "iefbcieuf.cf",
    "if58.cf",
    "ikelsik.cf",
    "ikoplak.cf",
    "ikuzus.cf",
    "imamail1928.cf",
    "indomina.cf",
    "inibuatkhoirul.cf",
    "inmynetwork.cf",
    "inrim.cf",
    "iolkjk.cf",
    "iq2kq5bfdw2a6.cf",
    "iqsfu65qbbkrioew.cf",
    "islamm.cf",
    "it-italy.cf",
    "ivecotrucks.cf",
    "ixkxirzvu10sybu.cf",
    "ixxycatmpklhnf6eo.cf",
    "j-keats.cf",
    "jaaj.cf",
    "jaguar-landrover.cf",
    "jamikait.cf",
    "jancokancene.cf",
    "janganjadiabu4.cf",
    "janganjadiabu9.cf",
    "jauhari.cf",
    "jdasdhj.cf",
    "jeep-official.cf",
    "jiancok.cf",
    "jiancokowe.cf",
    "jkcntadia.cf",
    "jkljkl.cf",
    "jkrowlg.cf",
    "jnggachoc.cf",
    "jnthn39vr4zlohuac.cf",
    "john-doe.cf",
    "johnpo.cf",
    "jorosc.cf",
    "josgishella681.cf",
    "josjihaan541.cf",
    "josrustam128.cf",
    "jp-morgan.cf",
    "jumanindya8240.cf",
    "jumbunga3502.cf",
    "jumnugroho6243.cf",
    "jwl3uabanm0ypzpxsq.cf",
    "kadokawa.cf",
    "kauinginpergi.cf",
    "keykeykelyns.cf",
    "keykeykelynss.cf",
    "keykeykelynsss.cf",
    "keykeykelynz.cf",
    "khan007.cf",
    "kithjiut.cf",
    "kmrx1hloufghqcx0c3.cf",
    "kodaka.cf",
    "kokorot.cf",
    "kompakteruss.cf",
    "koweancenjancok.cf",
    "krsw.sonshi.cf",
    "krupp.cf",
    "ksiskdiwey.cf",
    "kuatcak.cf",
    "kuatocokjaran.cf",
    "kucingarong.cf",
    "kudaponiea.cf",
    "kutakbisadekatdekat.cf",
    "kutakbisajauhjauh.cf",
    "kwalidd.cf",
    "laikacyber.cf",
    "lampadaire.cf",
    "laparbgt.cf",
    "lawson.cf",
    "lecz6s2swj1kio.cf",
    "lehman.cf",
    "level-3.cf",
    "lg-g7.cf",
    "lgfvh9hdvqwx8.cf",
    "lirikkuy.cf",
    "ljhjhkrt.cf",
    "localwomen-meet.cf",
    "logatarita892.cf",
    "logertasari851.cf",
    "logfika450.cf",
    "loghning469.cf",
    "logike708.cf",
    "login-email.cf",
    "lognoor487.cf",
    "logoktafiyanti477.cf",
    "logrialdhie707.cf",
    "logstefanny934.cf",
    "logtheresia637.cf",
    "lolitka.cf",
    "lordvold.cf",
    "lovxwyzpfzb2i4m8w9n.cf",
    "lrelsqkgga4.cf",
    "lwmhcka58cbwi.cf",
    "m-mail.cf",
    "mafiaa.cf",
    "mail-j.cf",
    "mail.backflip.cf",
    "mail0.cf",
    "mail1999.cf",
    "mail2000.cf",
    "mail2001.cf",
    "mail2nowhere.cf",
    "mail7.cf",
    "mail777.cf",
    "mailcc.cf",
    "mailcx.cf",
    "maildrop.cf",
    "mailinator.cf",
    "mailinbox.cf",
    "mailjunk.cf",
    "mailsac.cf",
    "mailserver2.cf",
    "mainphp.cf",
    "mandraghen.cf",
    "matamuasu.cf",
    "mayaaaa.cf",
    "mbt01.cf",
    "mcdonald.cf",
    "memecituenakganasli.cf",
    "merantikk.cf",
    "merda.cf",
    "mg-rover.cf",
    "mhdsl.cf",
    "mhmmmkumen.cf",
    "mimpaharpur.cf",
    "mintadomaindong.cf",
    "mintemail.cf",
    "mitsubishi-asx.cf",
    "mitsubishi-pajero.cf",
    "miur.cf",
    "montepaschi.cf",
    "morahdsl.cf",
    "mortystore.cf",
    "mp-j.cf",
    "mpaaf.cf",
    "mrossi.cf",
    "muqwftsjuonmc2s.cf",
    "myemail1.cf",
    "myfaceb00k.cf",
    "mymailjos.cf",
    "mymailto.cf",
    "mynetwork.cf",
    "mysafemail.cf",
    "nafko.cf",
    "naghini.cf",
    "napalm51.cf",
    "nasinyang.cf",
    "naskotk.cf",
    "nazimail.cf",
    "ndmail.cf",
    "nds8ufik2kfxku.cf",
    "nestle-usa.cf",
    "nichess.cf",
    "nktltpoeroe.cf",
    "nmail.cf",
    "nmfrvry.cf",
    "nmpkkr.cf",
    "no-vax.cf",
    "nokiahere.cf",
    "nokiamail.cf",
    "nomail.cf",
    "nongzaa.cf",
    "nsk1vbz.cf",
    "ntub.cf",
    "nub3zoorzrhomclef.cf",
    "nxdgrll3wtohaxqncsm.cf",
    "od21gwnkte.cf",
    "officialngentot.cf",
    "oing.cf",
    "ojosambat.cf",
    "onedaymail.cf",
    "orangotango.cf",
    "oriete.cf",
    "otoeqis66avqtj.cf",
    "ouhihu.cf",
    "ovimail.cf",
    "ovlov.cf",
    "oweiidfjjif.cf",
    "oyuhfer.cf",
    "ozqn1it6h5hzzxfht0.cf",
    "p-gdl.cf",
    "p180.cf",
    "p1nhompdgwn.cf",
    "paharpurmim.cf",
    "paller.cf",
    "pals-pay54.cf",
    "panama-real-estate.cf",
    "pancosj.cf",
    "paramail.cf",
    "paridisa.cf",
    "passw0rd.cf",
    "password.colafanta.cf",
    "pb-shelley.cf",
    "peoplehavethepower.cf",
    "perjalanandinas.cf",
    "personalcok.cf",
    "peugeot206.cf",
    "pgdln.cf",
    "phecrex.cf",
    "piaggioaero.cf",
    "pikirkumu.cf",
    "pinknbo.cf",
    "pkcabyr.cf",
    "poioijnkjb.cf",
    "polres-aeknabara.cf",
    "popsok.cf",
    "porco.cf",
    "pp98.cf",
    "predatorrat.cf",
    "pride.nafko.cf",
    "pringlang.cf",
    "printf.cf",
    "pumapumayes.cf",
    "puttana.cf",
    "pw-mail.cf",
    "px0dqqkyiii9g4fwb.cf",
    "pyiauje42dysm.cf",
    "q2gfiqsi4szzf54xe.cf",
    "qbqbtf4trnycocdg4c.cf",
    "r-mail.cf",
    "r1qaihnn9wb.cf",
    "r4ntwsd0fe58xtdp.cf",
    "r4unxengsekp.cf",
    "rabuberkah.cf",
    "raiasu.cf",
    "raimu.cf",
    "raimucok.cf",
    "raimuwedos.cf",
    "raiway.cf",
    "rajeshcon.cf",
    "remail.cf",
    "renault-sa.cf",
    "renaulttrucks.cf",
    "rexagod.cf",
    "rh3qqqmfamt3ccdgfa.cf",
    "rim7lth8moct0o8edoe.cf",
    "riski.cf",
    "rj-11.cf",
    "rj11.cf",
    "rocketmail.cf",
    "rollsroyce-plc.cf",
    "rolndedip.cf",
    "rover100.cf",
    "rover400.cf",
    "rover75.cf",
    "rr-ghost.cf",
    "rr-group.cf",
    "rvjtudarhs.cf",
    "rxmaof5wma.cf",
    "s.bungabunga.cf",
    "s0ny.cf",
    "s48aaxtoa3afw5edw0.cf",
    "saab9-3.cf",
    "saab9-4x.cf",
    "saab9-5.cf",
    "saab9-7x.cf",
    "saab900.cf",
    "saabaru.cf",
    "saabcars.cf",
    "saabgroup.cf",
    "saabscania.cf",
    "safaat.cf",
    "sahrulselow.cf",
    "salahkahaku.cf",
    "sandre.cf",
    "santhia.cf",
    "santikadyandra.cf",
    "schmid.cf",
    "scrmnto.cf",
    "selowcoffee.cf",
    "selowhellboy.cf",
    "semangat99.cf",
    "sendto.cf",
    "sennbox.cf",
    "sexboxx.cf",
    "sgb-itu-anjeng.cf",
    "sgb-itu-bangsat.cf",
    "sgizdkbck4n8deph59.cf",
    "shishish.cf",
    "shitaway.cf",
    "shiyakila.cf",
    "shoklin.cf",
    "sikomo.cf",
    "simscity.cf",
    "six-six-six.cf",
    "skhnlm.cf",
    "slmshf.cf",
    "snaknoc.cf",
    "sneakerbunko.cf",
    "so-net.cf",
    "sonshi.cf",
    "spamfighter.cf",
    "spamserver.cf",
    "spamserver2.cf",
    "spamwc.cf",
    "sperma.cf",
    "ss-hitler.cf",
    "ss00.cf",
    "ss02.cf",
    "ssangyong.cf",
    "st-m.cf",
    "steorn.cf",
    "stop-my-spam.cf",
    "subaru-brz.cf",
    "subaru-wrx.cf",
    "subaru-xv.cf",
    "supermail.cf",
    "sutiami.cf",
    "suxt3eifou1eo5plgv.cf",
    "sxzevvhpmitlc64k9.cf",
    "szi4edl0wnab3w6inc.cf",
    "tai-asu.cf",
    "takeshobo.cf",
    "takmemberi.cf",
    "tarma.cf",
    "tarzanmail.cf",
    "tehdini.cf",
    "tehsusu.cf",
    "telekgaring.cf",
    "telekteles.cf",
    "telekucing.cf",
    "tempmails.cf",
    "testoh.cf",
    "texac0.cf",
    "thidthid.cf",
    "thyfre.cf",
    "tirreno.cf",
    "titan-host.cf",
    "titaspaharpur1.cf",
    "titaspaharpur2.cf",
    "titaspaharpur3.cf",
    "titaspaharpur4.cf",
    "titaspaharpur5.cf",
    "tkeiyaku.cf",
    "tlvsmbdy.cf",
    "topinrock.cf",
    "toyota-rav-4.cf",
    "toyota-rav4.cf",
    "tq84vt9teyh.cf",
    "tr2k.cf",
    "trash-mail.cf",
    "trebusinde.cf",
    "trenord.cf",
    "trumpmail.cf",
    "ts-by-tashkent.cf",
    "ujapbk1aiau4qwfu.cf",
    "uk-unitedkingdom.cf",
    "ukjton.cf",
    "ulahadigung.cf",
    "ulahadigungproject.cf",
    "uojjhyhih.cf",
    "uqxcmcjdvvvx32.cf",
    "usachan.cf",
    "ushijima1129.cf",
    "uzxia.cf",
    "veebee.cf",
    "ver0.cf",
    "vercelli.cf",
    "vickaentb.cf",
    "vieebee.cf",
    "virgiliomail.cf",
    "visal168.cf",
    "vmhdisfgxxqoejwhsu.cf",
    "volkswagen-ag.cf",
    "volvo-ab.cf",
    "volvo-s60.cf",
    "vphnfuu2sd85w.cf",
    "vuzimir.cf",
    "vw-cc.cf",
    "vw-eos.cf",
    "wasdfgh.cf",
    "waskitacorp.cf",
    "watashiyuo.cf",
    "web.discard-email.cf",
    "wedooos.cf",
    "wesatikah407.cf",
    "wesruslian738.cf",
    "weswibowo593.cf",
    "weszwestyningrum767.cf",
    "wicked-game.cf",
    "wikipedia-inc.cf",
    "wikipedia-llc.cf",
    "wkhaiii.cf",
    "wmail.cf",
    "worldpetcare.cf",
    "wt2.orangotango.cf",
    "x-mule.cf",
    "x1bkskmuf4.cf",
    "xc40.cf",
    "xc60.cf",
    "xc90.cf",
    "xdavpzaizawbqnivzs0.cf",
    "xipcj6uovohr.cf",
    "xn--bei.cf",
    "y0up0rn.cf",
    "yaelahtodkokgitu.cf",
    "yaraon.cf",
    "yj3nas.cf",
    "yordanmail.cf",
    "youremail.cf",
    "z-mail.cf",
    "zanichelli.cf",
    "zarweek.cf",
    "zebua.cf",
    "zebuaboy.cf",
    "zer-0.cf",
    "zesta.cf",
    "zexeet9i5l49ocke.cf",
    "zgu5la23tngr2molii.cf",
    "zil4czsdz3mvauc2.cf",
    "zilmail.cf",
    "zinmail.cf",
    "zipo1.cf",
    "zmti6x70hdop.cf",
    "zn4chyguz9rz2gvjcq.cf",
    "znatb25xbul30ui.cf",
    "zualikhakk.cf",
    "zwwnhmmcec57ziwux.cf",
    "zyyu6mute9qn.cf",
  ],
  ga: [
    "8wkkrizxpphbm3c.ga",
    "alesapto153.ga",
    "jumnoor4036.ga",
    "ndfbmail.ga",
    "sdg4643ty34.ga",
    "sidamail.ga",
    "wesjuliyanto744.ga",
    "zeromail.ga",
    "0039.ga",
    "00b2bcr51qv59xst2.ga",
    "02466.ga",
    "0x03.ga",
    "10minutemail.ga",
    "11-32.ga",
    "13sasytkgb0qobwxat.ga",
    "18-9-2.ga",
    "19922.ga",
    "1spcziorgtfpqdo.ga",
    "2018-12-23.ga",
    "2dfmail.ga",
    "2zpph1mgg70hhub.ga",
    "3mail.ga",
    "3x0ex1x2yx0.ga",
    "4mail.ga",
    "4up3vtaxujpdm2.ga",
    "4vq19hhmxgaruka.ga",
    "54tiljt6dz9tcdryc2g.ga",
    "5am5ung.ga",
    "5mail.ga",
    "6-6-6.ga",
    "666-satan.ga",
    "67832.ga",
    "6brmwv.ga",
    "6lhp5tembvpl.ga",
    "6mail.ga",
    "6q70sdpgjzm2irltn.ga",
    "7mail.ga",
    "7pccf.ga",
    "7uy35p.ga",
    "888z5.ga",
    "8eoqovels2mxnxzwn7a.ga",
    "8imefdzddci.ga",
    "8mail.ga",
    "8verxcdkrfal61pfag.ga",
    "9skcqddzppe4.ga",
    "9ziqmkpzz3aif.ga",
    "a-mule.ga",
    "a4zerwak0d.ga",
    "aasgashashashajh.ga",
    "ab-volvo.ga",
    "ababmail.ga",
    "abarth.ga",
    "ac895.ga",
    "adadass.ga",
    "ag95.ga",
    "ahmadidik.ga",
    "akusayyangkamusangat.ga",
    "alaki.ga",
    "alefika98.ga",
    "alemaureen164.ga",
    "aleqodriyah730.ga",
    "alfa-romeo.ga",
    "alormbf88nd.ga",
    "amex-online.ga",
    "anakjembutad.ga",
    "anaploxo.ga",
    "anayikt.ga",
    "anjingkokditolak.ga",
    "anthagine.ga",
    "antistream.ga",
    "aoltimewarner.ga",
    "aqazstnvw1v.ga",
    "aresanob.ga",
    "arybebekganteng.ga",
    "asahi.ga",
    "asgaccse-pt.ga",
    "asgasgasgasggasg.ga",
    "asl13.ga",
    "astaghfirulloh.ga",
    "astonut.ga",
    "atoyot.ga",
    "att-warner.ga",
    "audi-r8.ga",
    "audi-tt.ga",
    "aus.schwarzmail.ga",
    "avioaero.ga",
    "b6o7vt32yz.ga",
    "babau.ga",
    "banjarworo.ga",
    "barclays-plc.ga",
    "bauimail.ga",
    "beeviee.ga",
    "beeviee1.ga",
    "belence.ga",
    "beresleting.ga",
    "berlusconi.ga",
    "bgboad.ga",
    "bhuyarey.ga",
    "bibicaba.ga",
    "bibucabi.ga",
    "bigtetek.ga",
    "bingakilo.ga",
    "bitchmail.ga",
    "bkkpkht.ga",
    "bmw-ag.ga",
    "bmw-mini.ga",
    "bmw-rollsroyce.ga",
    "bmw-x5.ga",
    "bmw-x6.ga",
    "bmw-z4.ga",
    "bnckms.ga",
    "bongobongo.ga",
    "british-leyland.ga",
    "bsquochoai.ga",
    "bsuakrqwbd.ga",
    "bundes-li.ga",
    "buntuty.ga",
    "bxfmtktkpxfkobzssqw.ga",
    "c0rtana.ga",
    "c4anec0wemilckzp42.ga",
    "c4utar.ga",
    "c6h12o6.ga",
    "casio-edu.ga",
    "cazzo.ga",
    "cc-s3x.ga",
    "cc2ilplyg77e.ga",
    "cebong.ga",
    "ceftvhxs7nln9.ga",
    "centrallosana.ga",
    "ceweknakal.ga",
    "channel9.ga",
    "chivasso.ga",
    "cilemail.ga",
    "clipmail.ga",
    "clublife.ga",
    "cnh.industrial.ga",
    "co1vgedispvpjbpugf.ga",
    "coepoebete.ga",
    "coldmail.ga",
    "contactout1000.ga",
    "cross-law.ga",
    "cryptontrade.ga",
    "csi-miami.ga",
    "csi-newyork.ga",
    "cts-lk-i.ga",
    "cul0.ga",
    "customersupportdepartment.ga",
    "cvijqth6if8txrdt.ga",
    "d-link.ga",
    "da-da-da.ga",
    "daciasandero.ga",
    "daimlerag.ga",
    "dait.ga",
    "deadfake.ga",
    "dekatri.ga",
    "dena.ga",
    "dengekibunko.ga",
    "desmo.ga",
    "dettol.ga",
    "dev-null.ga",
    "dfg456ery.ga",
    "dhl-uk.ga",
    "didikselowcoffee.ga",
    "digitex.ga",
    "dikriemangasu.ga",
    "dimimail.ga",
    "dinksai.ga",
    "discard.ga",
    "disposable.ga",
    "divad.ga",
    "dkert2mdi7sainoz.ga",
    "dl812pqedqw.ga",
    "dlmkme.ga",
    "dlwdudtwlt557.ga",
    "dm9bqwkt9i2adyev.ga",
    "dmc-12.ga",
    "domajabro.ga",
    "dr0pb0x.ga",
    "dropmail.ga",
    "dt3456346734.ga",
    "dvsdg34t6ewt.ga",
    "dwango.ga",
    "dwipalinggantengyanglainlewat.ga",
    "dwutuemzudvcb.ga",
    "e-mailbox.ga",
    "eaqso209ak.ga",
    "ecocap.ga",
    "edifice.ga",
    "ef2qohn1l4ctqvh.ga",
    "ehoie03og3acq3us6.ga",
    "eins-zwei.ga",
    "email-fake.ga",
    "email2an.ga",
    "email3.ga",
    "emailage.ga",
    "emailfake.ga",
    "emailfalsa.ga",
    "emails.ga",
    "emailspam.ga",
    "emailz.ga",
    "emirmail.ga",
    "emkei.ga",
    "empireanime.ga",
    "emule.ga",
    "enfsmq2wel.ga",
    "enggalman.ga",
    "ennemail.ga",
    "enron.ga",
    "epot.ga",
    "eqiluxspam.ga",
    "eramis.ga",
    "eur-sec1.ga",
    "eur0.ga",
    "f1kzc0d3.ga",
    "facebook-email.ga",
    "fake-mail.ga",
    "fake.i-3gk.ga",
    "fakeinbox.ga",
    "fakemails.ga",
    "fakiralio.ga",
    "fakyah.ga",
    "fannny.ga",
    "fassagforpresident.ga",
    "fawwaz.ga",
    "fca-nv.ga",
    "fcgfdsts.ga",
    "feaethplrsmel.ga",
    "ferastya.ga",
    "fi-pdl.ga",
    "fiat-chrysler.ga",
    "fiat500.ga",
    "fica.ga",
    "fikrihidayah.ga",
    "fikrinhdyh.ga",
    "firef0x.ga",
    "flarmail.ga",
    "foreskin.ga",
    "free-email.ga",
    "freedom-mail.ga",
    "freemails.ga",
    "freemeil.ga",
    "friscaa.ga",
    "fs16dubzzn0.ga",
    "fujitv.ga",
    "funxmail.ga",
    "fusixgasvv1gbjrbc.ga",
    "fuw65d.ga",
    "fw-nietzsche.ga",
    "g00g.ga",
    "g00glechr0me.ga",
    "g00gledrive.ga",
    "g7lkrfzl7t0rb9oq.ga",
    "gapemail.ga",
    "garingsin.ga",
    "geaviation.ga",
    "geezmail.ga",
    "geludkita.ga",
    "general-electric.ga",
    "get-mail.ga",
    "getairmail.ga",
    "getnada.ga",
    "gfmail.ga",
    "gg-byron.ga",
    "giooig.ga",
    "gitumau.ga",
    "gogreeninc.ga",
    "goliokao.ga",
    "goranko.ga",
    "goromail.ga",
    "grangmi.ga",
    "group-llc.ga",
    "gsxstring.ga",
    "guvewfmn7j1dmp.ga",
    "gwfh.ga",
    "h1tler.ga",
    "h2o-web.ga",
    "haitmail.ga",
    "hamusoku.ga",
    "hangxomcuatoilatotoro.ga",
    "hbxrlg4sae.ga",
    "hdhkmbu.ga",
    "helpcustomerdepartment.ga",
    "helrey.ga",
    "hendrikarifqiariza.ga",
    "hezemail.ga",
    "hfmf.ga",
    "hgrmnh.ga",
    "hisalotk.ga",
    "hishyau.ga",
    "hitler-adolf.ga",
    "hiyrey.ga",
    "hngwrb7ztl.ga",
    "holl.ga",
    "hot-mail.ga",
    "hotlunches.ga",
    "housemail.ga",
    "hrb67.ga",
    "hrgmgka.ga",
    "hsls5guu0cv.ga",
    "hustq7tbd6v2xov.ga",
    "hxvxxo1v8mfbt.ga",
    "i-3gk.ga",
    "i537244.ga",
    "icetmail.ga",
    "idnkil.ga",
    "idt8wwaohfiru7.ga",
    "if58.ga",
    "ik7gzqu2gved2g5wr.ga",
    "ikelsik.ga",
    "ikoplak.ga",
    "ikuzus.ga",
    "inibuatsgb.ga",
    "inmynetwork.ga",
    "inrim.ga",
    "interserver.ga",
    "iodizc3krahzsn.ga",
    "iolokdi.ga",
    "iq2kq5bfdw2a6.ga",
    "iqsfu65qbbkrioew.ga",
    "it-italy.ga",
    "itue33ubht.ga",
    "ivecotrucks.ga",
    "iwv06uutxic3r.ga",
    "ixkxirzvu10sybu.ga",
    "ixxycatmpklhnf6eo.ga",
    "j-keats.ga",
    "jaguar-landrover.ga",
    "jamikait.ga",
    "jancokancene.ga",
    "janganjadiabu3.ga",
    "janganjadiabu8.ga",
    "jauhari.ga",
    "jcpclothing.ga",
    "jdasdhj.ga",
    "jeep-official.ga",
    "jiancok.ga",
    "jiancokowe.ga",
    "jinggakop.ga",
    "jkcntadia.ga",
    "jkljkl.ga",
    "jnthn39vr4zlohuac.ga",
    "joasantos.ga",
    "john-doe.ga",
    "johnpo.ga",
    "jorosc.ga",
    "josamadea480.ga",
    "josfitrawati410.ga",
    "josnurul491.ga",
    "jp-morgan.ga",
    "jumnia4726.ga",
    "jumrestia9994.ga",
    "junkmail.ga",
    "jwl3uabanm0ypzpxsq.ga",
    "kadokawa.ga",
    "kauinginpergi.ga",
    "keykeykelyns.ga",
    "keykeykelynss.ga",
    "keykeykelynsss.ga",
    "keykeykelynz.ga",
    "kingsq.ga",
    "kithjiut.ga",
    "kiuyutre.ga",
    "kodaka.ga",
    "kodemail.ga",
    "kokorot.ga",
    "koweancenjancok.ga",
    "kqhs4jbhptlt0.ga",
    "krupp.ga",
    "ksmtrck.ga",
    "kuatocokjaran.ga",
    "kucingarong.ga",
    "kudaponiea.ga",
    "kum38p0dfgxz.ga",
    "kusam.ga",
    "kutakbisajauhjauh.ga",
    "laikacyber.ga",
    "lakngin.ga",
    "lancia.ga",
    "laparbgt.ga",
    "lawson.ga",
    "lecz6s2swj1kio.ga",
    "lembarancerita.ga",
    "lenlusiana5967.ga",
    "level-3.ga",
    "lg-g7.ga",
    "lh451.ga",
    "ljhjhkrt.ga",
    "localwomen-meet.ga",
    "logdewi370.ga",
    "loghermawaty297.ga",
    "login-email.ga",
    "logrialdhie62.ga",
    "logsinuka803.ga",
    "logyanti412.ga",
    "lolitka.ga",
    "lordvold.ga",
    "lovxwyzpfzb2i4m8w9n.ga",
    "lwmhcka58cbwi.ga",
    "m-p-s.ga",
    "maggotymeat.ga",
    "mail-j.ga",
    "mail-w.ga",
    "mail.ticket-please.ga",
    "mail0.ga",
    "mail1999.ga",
    "mail2000.ga",
    "mail2001.ga",
    "mail2nowhere.ga",
    "mail7.ga",
    "mail8.ga",
    "mailbox52.ga",
    "mailcc.ga",
    "mailcx.ga",
    "maildrop.ga",
    "mailfree.ga",
    "mailinator.ga",
    "mailinbox.ga",
    "mailjunk.ga",
    "mailjuose.ga",
    "mailkuatjku2.ga",
    "mailsac.ga",
    "mailserver2.ga",
    "mainphp.ga",
    "matamuasu.ga",
    "mayaaaa.ga",
    "mb69.ga",
    "mbt01.ga",
    "memecituenakganasli.ga",
    "merantikk.ga",
    "merda.ga",
    "mhmmmkumen.ga",
    "miegrg.ga",
    "milk.gage.ga",
    "mimpaharpur.ga",
    "mindmail.ga",
    "mintadomaindong.ga",
    "mintconditionin.ga",
    "mintemail.ga",
    "mitsubishi-asx.ga",
    "mitsubishi-pajero.ga",
    "mitsubishi2.ga",
    "miur.ga",
    "mozillafirefox.ga",
    "mp-j.ga",
    "mpaaf.ga",
    "mqg77378.ga",
    "mrossi.ga",
    "muqwftsjuonmc2s.ga",
    "mv1951.ga",
    "myemail1.ga",
    "myfake.ga",
    "myfakemail.ga",
    "mymailjos.ga",
    "mymailto.ga",
    "mysafemail.ga",
    "naghini.ga",
    "napalm51.ga",
    "nasinyang.ga",
    "naskotk.ga",
    "nazimail.ga",
    "ndemail.ga",
    "ndfakemail.ga",
    "ndif8wuumk26gv5.ga",
    "ndinstamail.ga",
    "nds8ufik2kfxku.ga",
    "nestle-usa.ga",
    "nfnov28y9r7pxox.ga",
    "nichess.ga",
    "nitza.ga",
    "nmfrvry.ga",
    "nmpkkr.ga",
    "no-vax.ga",
    "nokiamail.ga",
    "nolemail.ga",
    "nomail.ga",
    "nomeucu.ga",
    "notmail.ga",
    "nowemail.ga",
    "ntuv4sit2ai.ga",
    "oboymail.ga",
    "officialngentot.ga",
    "officialtolol.ga",
    "ohkogtsh.ga",
    "onedaymail.ga",
    "onenime.ga",
    "opmmedia.ga",
    "orangotango.ga",
    "otonmail.ga",
    "ouhihu.ga",
    "ovimail.ga",
    "ovlov.ga",
    "oweiidfjjif.ga",
    "oyuhfer.ga",
    "p-gdl.ga",
    "p180.ga",
    "p1nhompdgwn.ga",
    "paharpurmim.ga",
    "pakadebu.ga",
    "pancosj.ga",
    "paridisa.ga",
    "passwort.schwarzmail.ga",
    "pb-shelley.ga",
    "perjalanandinas.ga",
    "personalcok.ga",
    "peugeot206.ga",
    "pgdln.ga",
    "phecrex.ga",
    "piaggio.ga",
    "piaggioaero.ga",
    "pikirkumu.ga",
    "pilpres2018.ga",
    "pinknbo.ga",
    "plgbgus.ga",
    "poalmail.ga",
    "popsok.ga",
    "porco.ga",
    "posurl.ga",
    "pp98.ga",
    "predatorrat.ga",
    "pringlang.ga",
    "purplemail.ga",
    "puttana.ga",
    "pw-mail.ga",
    "px0dqqkyiii9g4fwb.ga",
    "pyiauje42dysm.ga",
    "qwerqwerty.ga",
    "qwtof1c6gewti.ga",
    "r-mail.ga",
    "r1qaihnn9wb.ga",
    "r4ntwsd0fe58xtdp.ga",
    "raiasu.ga",
    "raimucok.ga",
    "raimuwedos.ga",
    "raiway.ga",
    "ratta.ga",
    "rav-4.ga",
    "remail.ga",
    "remehan.ga",
    "renault-sa.ga",
    "renaulttrucks.ga",
    "rethmail.ga",
    "return0.ga",
    "rexagod.ga",
    "rh3qqqmfamt3ccdgfa.ga",
    "rifkian.ga",
    "rj-11.ga",
    "rj11.ga",
    "rocketmail.ga",
    "roewe.ga",
    "rollsroyce-plc.ga",
    "rolndedip.ga",
    "rover100.ga",
    "rover400.ga",
    "rover75.ga",
    "rr-ghost.ga",
    "rr-group.ga",
    "rvjtudarhs.ga",
    "rxmaof5wma.ga",
    "s-mail.ga",
    "s00.orangotango.ga",
    "s0ny.ga",
    "s48aaxtoa3afw5edw0.ga",
    "saab9-3.ga",
    "saab9-4x.ga",
    "saab9-5.ga",
    "saab9-7x.ga",
    "saab900.ga",
    "saabaru.ga",
    "saabcars.ga",
    "saabgroup.ga",
    "saabscania.ga",
    "safe-mail.ga",
    "sahrulselow.ga",
    "salahkahaku.ga",
    "samblad.ga",
    "santhia.ga",
    "santikadyandra.ga",
    "schmid.ga",
    "schwarzmail.ga",
    "scrmnto.ga",
    "sdg34563yer.ga",
    "sdgewrt43terdsgt.ga",
    "secmail.ga",
    "selowcoffee.ga",
    "selowhellboy.ga",
    "semarhouse.ga",
    "sennbox.ga",
    "senttmail.ga",
    "sexboxx.ga",
    "sgb-itu-anjeng.ga",
    "sgb-itu-bangsat.ga",
    "shishish.ga",
    "shitaway.ga",
    "shitmail.ga",
    "shittymail.ga",
    "shiyakila.ga",
    "shoklin.ga",
    "sicamail.ga",
    "sikomo.ga",
    "siliwangi.ga",
    "simsmail.ga",
    "six-six-six.ga",
    "skhnlm.ga",
    "sky-mail.ga",
    "slugmail.ga",
    "snaknoc.ga",
    "snam.ga",
    "sneakerbunko.ga",
    "snpsex.ga",
    "so-net.ga",
    "spam.fassagforpresident.ga",
    "spamfighter.ga",
    "spamserver2.ga",
    "spamwc.ga",
    "ss-hitler.ga",
    "ss00.ga",
    "ss01.ga",
    "ss02.ga",
    "ssangyong.ga",
    "sssppua.ga",
    "st-m.ga",
    "statx.ga",
    "steemail.ga",
    "steorn.ga",
    "stop-my-spam.ga",
    "subaru-brz.ga",
    "subaru-wrx.ga",
    "subaru-xv.ga",
    "suntory.ga",
    "surga.ga",
    "sutiami.ga",
    "suxt3eifou1eo5plgv.ga",
    "sxzevvhpmitlc64k9.ga",
    "szi4edl0wnab3w6inc.ga",
    "t-student.ga",
    "tai-asu.ga",
    "takeshobo.ga",
    "tarma.ga",
    "teamspeak3.ga",
    "tehdini.ga",
    "tehsusu.ga",
    "telekgaring.ga",
    "telekteles.ga",
    "telekucing.ga",
    "ternaklele.ga",
    "testoh.ga",
    "texac0.ga",
    "thidthid.ga",
    "thuguimomo.ga",
    "thyfre.ga",
    "ticket-please.ga",
    "tirreno.ga",
    "titan-host.ga",
    "titaspaharpur2.ga",
    "titaspaharpur3.ga",
    "titaspaharpur4.ga",
    "titaspaharpur5.ga",
    "tlvsmbdy.ga",
    "toyota-rav-4.ga",
    "toyota-rav4.ga",
    "tq84vt9teyh.ga",
    "tr2k.ga",
    "tragaver.ga",
    "tralalajos.ga",
    "trash-mail.ga",
    "trenord.ga",
    "trumpmail.ga",
    "ts-by-tashkent.ga",
    "tytfhcghb.ga",
    "ujapbk1aiau4qwfu.ga",
    "uk-unitedkingdom.ga",
    "ukjton.ga",
    "ulahadigung.ga",
    "ulahadigungproject.ga",
    "umrn.ga",
    "uo8fylspuwh9c.ga",
    "uojjhyhih.ga",
    "uphomail.ga",
    "usa-gov.ga",
    "ushijima1129.ga",
    "veebee.ga",
    "veldmail.ga",
    "ver0.ga",
    "vercelli.ga",
    "vickaentb.ga",
    "vieebee.ga",
    "virgiliomail.ga",
    "visal168.ga",
    "volkswagen-ag.ga",
    "volvo-ab.ga",
    "volvo-s60.ga",
    "volvogroup.ga",
    "vw-cc.ga",
    "vw-eos.ga",
    "w634634.ga",
    "wapl.ga",
    "wasdfgh.ga",
    "waskitacorp.ga",
    "watashiyuo.ga",
    "webide.ga",
    "wedooos.ga",
    "wesazalia927.ga",
    "wesgaluh852.ga",
    "weshutahaean910.ga",
    "weskusumawardhani993.ga",
    "wesmuharia897.ga",
    "whitemail.ga",
    "wicked-game.ga",
    "wikipedia-inc.ga",
    "wikipedia-llc.ga",
    "wkhaiii.ga",
    "x1bkskmuf4.ga",
    "xbvrfy45g.ga",
    "xc40.ga",
    "xc60.ga",
    "xc90.ga",
    "xdvsagsdg4we.ga",
    "xipcj6uovohr.ga",
    "xxhamsterxx.ga",
    "yaelahtodkokgitu.ga",
    "yaraon.ga",
    "yarnpedia.ga",
    "yelloww.ga",
    "yj3nas.ga",
    "youmail.ga",
    "youngcrew.ga",
    "yppm0z5sjif.ga",
    "yx48bxdv.ga",
    "z-mail.ga",
    "z-mild.ga",
    "zanichelli.ga",
    "zarweek.ga",
    "zchatz.ga",
    "zeroknow.ga",
    "zexeet9i5l49ocke.ga",
    "zgu5la23tngr2molii.ga",
    "zilmail.ga",
    "zinmail.ga",
    "zl0irltxrb2c.ga",
    "zmti6x70hdop.ga",
    "znatb25xbul30ui.ga",
    "zonamail.ga",
    "zrmail.ga",
    "zualikhakk.ga",
    "zwwnhmmcec57ziwux.ga",
    "zxgsd4gydfg.ga",
    "zyyu6mute9qn.ga",
  ],
  gq: [
    "0039.gq",
    "07819.gq",
    "0nedrive.gq",
    "0x03.gq",
    "10minutemail.gq",
    "11-32.gq",
    "13sasytkgb0qobwxat.gq",
    "18-9-2.gq",
    "1up.orangotango.gq",
    "2aitycnhnno6.gq",
    "3gk2yftgot.gq",
    "4.fackme.gq",
    "4up3vtaxujpdm2.gq",
    "4vq19hhmxgaruka.gq",
    "4xzotgbunzq.gq",
    "5am5ung.gq",
    "666-satan.gq",
    "6brmwv.gq",
    "6lhp5tembvpl.gq",
    "6q70sdpgjzm2irltn.gq",
    "7pccf.gq",
    "7uy35p.gq",
    "8.fackme.gq",
    "888z5.gq",
    "8eoqovels2mxnxzwn7a.gq",
    "8imefdzddci.gq",
    "8usmwuqxh1s1pw.gq",
    "8wkkrizxpphbm3c.gq",
    "8xcdzvxgnfztticc.gq",
    "9ziqmkpzz3aif.gq",
    "a4zerwak0d.gq",
    "ab-volvo.gq",
    "abarth.gq",
    "ac895.gq",
    "adadass.gq",
    "adrianou.gq",
    "afflive.gq",
    "ag95.gq",
    "ahmadidik.gq",
    "akash9.gq",
    "akryn4rbbm8v.gq",
    "aleepapalae.gq",
    "alfa-romeo.gq",
    "alfaromeo147.gq",
    "alormbf88nd.gq",
    "amex-online.gq",
    "anakjembutad.gq",
    "anaploxo.gq",
    "anayikt.gq",
    "anjingkokditolak.gq",
    "anthagine.gq",
    "antistream.gq",
    "aqazstnvw1v.gq",
    "aresanob.gq",
    "arurgitu.gq",
    "arybebekganteng.gq",
    "asgaccse-pt.gq",
    "asgasghashashas.gq",
    "asl13.gq",
    "astaghfirulloh.gq",
    "atoyot.gq",
    "att-warner.gq",
    "audi-r8.gq",
    "audi-tt.gq",
    "avio.gq",
    "avioaero.gq",
    "azote.gq",
    "b6o7vt32yz.gq",
    "babau.gq",
    "babyk.gq",
    "barclays-plc.gq",
    "beeviee.gq",
    "beeviee1.gq",
    "belence.gq",
    "beresleting.gq",
    "berlusconi.gq",
    "bibicaba.gq",
    "bibucabi.gq",
    "bigtetek.gq",
    "bkkpkht.gq",
    "bmw-ag.gq",
    "bmw-i8.gq",
    "bmw-mini.gq",
    "bmw-rollsroyce.gq",
    "bmw-x5.gq",
    "bmw-x6.gq",
    "bmw-z4.gq",
    "bnckms.gq",
    "bongobongo.gq",
    "bp3xxqejba.gq",
    "bper.gq",
    "british-leyland.gq",
    "bsuakrqwbd.gq",
    "bucbdlbniz.gq",
    "bxfmtktkpxfkobzssqw.gq",
    "c-14.gq",
    "c-mail.gq",
    "c0rtana.gq",
    "c4ster.gq",
    "c4utar.gq",
    "c6h12o6.gq",
    "casio-edu.gq",
    "cazzo.gq",
    "cc2ilplyg77e.gq",
    "cebong.gq",
    "ceftvhxs7nln9.gq",
    "channel9.gq",
    "chivasso.gq",
    "clipmail.gq",
    "co1vgedispvpjbpugf.gq",
    "coldmail.gq",
    "covfefe-mail.gq",
    "cross-law.gq",
    "cs715a3o1vfb73sdekp.gq",
    "csi-miami.gq",
    "csi-newyork.gq",
    "cts-lk-i.gq",
    "cul0.gq",
    "cvijqth6if8txrdt.gq",
    "d-link.gq",
    "da-da-da.gq",
    "daciasandero.gq",
    "daewoo.gq",
    "daimlerag.gq",
    "dait.gq",
    "datrr.gq",
    "de4ce.gq",
    "dekatri.gq",
    "dengekibunko.gq",
    "desmo.gq",
    "dettol.gq",
    "dev-null.gq",
    "dhl-uk.gq",
    "didikselowcoffee.gq",
    "digitex.gq",
    "dikriemangasu.gq",
    "discard.gq",
    "dkert2mdi7sainoz.gq",
    "dl812pqedqw.gq",
    "dragcok2.gq",
    "dwango.gq",
    "dwipalinggantengyanglainlewat.gq",
    "dwutuemzudvcb.gq",
    "ec97.gq",
    "ecocap.gq",
    "ehoie03og3acq3us6.gq",
    "eins-zwei.gq",
    "email-fake.gq",
    "email1.gq",
    "email3.gq",
    "emailage.gq",
    "emailfake.gq",
    "emailfalsa.gq",
    "emailspam.gq",
    "emailz.gq",
    "emkei.gq",
    "emule.gq",
    "enfsmq2wel.gq",
    "enron.gq",
    "erasedebt.gq",
    "eur-sec1.gq",
    "eur0.gq",
    "f1kzc0d3.gq",
    "f39mltl5qyhyfx.gq",
    "facebookmail.gq",
    "fackme.gq",
    "fake-mail.gq",
    "fake.i-3gk.gq",
    "fakemails.gq",
    "fannny.gq",
    "fawwaz.gq",
    "fca-nv.gq",
    "febbraio.gq",
    "ferastya.gq",
    "fi-pdl.gq",
    "fiat-chrysler.gq",
    "fiat500.gq",
    "fica.gq",
    "fikrihidayah.gq",
    "fikrinhdyh.gq",
    "firef0x.gq",
    "foreskin.gq",
    "four.fackme.gq",
    "freemeil.gq",
    "friscaa.gq",
    "fs16dubzzn0.gq",
    "fujitv.gq",
    "fusixgasvv1gbjrbc.gq",
    "fuw65d.gq",
    "fw-nietzsche.gq",
    "g00glechr0me.gq",
    "g7lkrfzl7t0rb9oq.gq",
    "gajesajflk.gq",
    "garingsin.gq",
    "geaviation.gq",
    "geludkita.gq",
    "general-electric.gq",
    "getairmail.gq",
    "getnada.gq",
    "gfmail.gq",
    "gg-byron.gq",
    "giooig.gq",
    "godmail.gq",
    "goliokao.gq",
    "grangmi.gq",
    "gree.gq",
    "group-llc.gq",
    "groupe-psa.gq",
    "gwfh.gq",
    "h1tler.gq",
    "hamusoku.gq",
    "hangxomcuatoilatotoro.gq",
    "hasegawa.gq",
    "helrey.gq",
    "hendrikarifqiariza.gq",
    "hfmf.gq",
    "hgrmnh.gq",
    "hisalotk.gq",
    "hishyau.gq",
    "hitler-adolf.gq",
    "hiyrey.gq",
    "hngwrb7ztl.gq",
    "hot-mail.gq",
    "hrb67.gq",
    "hrgmgka.gq",
    "htaae8jvikgd3imrphl.gq",
    "i-3gk.gq",
    "i-sp.gq",
    "idihgabo.gq",
    "idnkil.gq",
    "idt8wwaohfiru7.gq",
    "if58.gq",
    "ikelsik.gq",
    "ikoplak.gq",
    "ikuzus.gq",
    "inibuatsgb.gq",
    "inmynetwork.gq",
    "inrim.gq",
    "iodizc3krahzsn.gq",
    "iolkjk.gq",
    "iqsfu65qbbkrioew.gq",
    "islamm.gq",
    "it-italy.gq",
    "ivecotrucks.gq",
    "iwv06uutxic3r.gq",
    "ixkxirzvu10sybu.gq",
    "ixxycatmpklhnf6eo.gq",
    "j-keats.gq",
    "jaguar-landrover.gq",
    "jamikait.gq",
    "jancokancene.gq",
    "janganjadiabu10.gq",
    "janganjadiabu5.gq",
    "jauhari.gq",
    "jdasdhj.gq",
    "jeep-official.gq",
    "jiancok.gq",
    "jiancokowe.gq",
    "jinggakop.gq",
    "jkcntadia.gq",
    "jkrowlg.gq",
    "jnggachoc.gq",
    "jnthn39vr4zlohuac.gq",
    "john-doe.gq",
    "johnpo.gq",
    "jorosc.gq",
    "jp-morgan.gq",
    "junkmail.gq",
    "justshoes.gq",
    "k4tbtqa7ag5m.gq",
    "kadokawa.gq",
    "kauinginpergi.gq",
    "keykeykelyns.gq",
    "keykeykelynss.gq",
    "keykeykelynsss.gq",
    "keykeykelynz.gq",
    "kithjiut.gq",
    "kmrx1hloufghqcx0c3.gq",
    "kodaka.gq",
    "kokorot.gq",
    "koweancenjancok.gq",
    "kqhs4jbhptlt0.gq",
    "kqwyqzjvrvdewth81.gq",
    "kuatmail.gq",
    "kuatocokjaran.gq",
    "kucingarong.gq",
    "kudaterbang.gq",
    "kutakbisajauhjauh.gq",
    "l1rwscpeq6.gq",
    "laikacyber.gq",
    "lancia.gq",
    "laparbgt.gq",
    "lawson.gq",
    "lecz6s2swj1kio.gq",
    "lehman.gq",
    "lellno.gq",
    "level-3.gq",
    "lg-g7.gq",
    "lgfvh9hdvqwx8.gq",
    "lh451.gq",
    "localwomen-meet.gq",
    "lolitka.gq",
    "lordvold.gq",
    "lovesea.gq",
    "lovxwyzpfzb2i4m8w9n.gq",
    "luxusmail.gq",
    "lwmhcka58cbwi.gq",
    "m-mail.gq",
    "mail-j.gq",
    "mail0.gq",
    "mail1999.gq",
    "mail2000.gq",
    "mail2001.gq",
    "mail2nowhere.gq",
    "mailcx.gq",
    "maildrop.gq",
    "mailfree.gq",
    "mailinator.gq",
    "mailinbox.gq",
    "mailjunk.gq",
    "mailme.gq",
    "mailpost.gq",
    "mailpress.gq",
    "mainphp.gq",
    "massrewardgiveaway.gq",
    "matamuasu.gq",
    "mayaaaa.gq",
    "mbt01.gq",
    "mcdonald.gq",
    "mecbuc.gq",
    "memecituenakganasli.gq",
    "merantikk.gq",
    "merda.gq",
    "mhdsl.gq",
    "mhmmmkumen.gq",
    "migserver2.gq",
    "mimpaharpur.gq",
    "mintadomaindong.gq",
    "mintemail.gq",
    "mitsubishi-asx.gq",
    "mitsubishi-pajero.gq",
    "miur.gq",
    "mixi.gq",
    "montepaschi.gq",
    "mozillafirefox.gq",
    "mp-j.gq",
    "mpaaf.gq",
    "mrblacklist.gq",
    "mrossi.gq",
    "munoubengoshi.gq",
    "muqwftsjuonmc2s.gq",
    "mv1951.gq",
    "my-email.gq",
    "my-server-online.gq",
    "myfaceb00k.gq",
    "myfake.gq",
    "myfakemail.gq",
    "mysafemail.gq",
    "naghini.gq",
    "napalm51.gq",
    "nasinyang.gq",
    "nazimail.gq",
    "ndif8wuumk26gv5.gq",
    "nestle-usa.gq",
    "nfnov28y9r7pxox.gq",
    "nichess.gq",
    "nmfrvry.gq",
    "nmpkkr.gq",
    "no-vax.gq",
    "nokiahere.gq",
    "nokiamail.gq",
    "nongzaa.gq",
    "notmail.gq",
    "nsk1vbz.gq",
    "ntuv4sit2ai.gq",
    "nxdgrll3wtohaxqncsm.gq",
    "od21gwnkte.gq",
    "officialngentot.gq",
    "ojdh71ltl0hsbid2.gq",
    "ooeawtppmznovo.gq",
    "orangotango.gq",
    "otoeqis66avqtj.gq",
    "ouhihu.gq",
    "ovimail.gq",
    "ovlov.gq",
    "oweiidfjjif.gq",
    "oyuhfer.gq",
    "p-gdl.gq",
    "p180.gq",
    "p1nhompdgwn.gq",
    "paharpurmim.gq",
    "palpialula.gq",
    "pancosj.gq",
    "panelpros.gq",
    "paridisa.gq",
    "pb-shelley.gq",
    "pd6badzx7q8y0.gq",
    "perjalanandinas.gq",
    "personalcok.gq",
    "peugeot206.gq",
    "pgdln.gq",
    "phecrex.gq",
    "piaggio.gq",
    "piaggioaero.gq",
    "pikirkumu.gq",
    "pinknbo.gq",
    "popsok.gq",
    "porco.gq",
    "pp98.gq",
    "predatorrat.gq",
    "pringlang.gq",
    "ptpigeaz0uorsrygsz.gq",
    "puds5k7lca9zq.gq",
    "purplemail.gq",
    "puttana.gq",
    "pw-mail.gq",
    "px0dqqkyiii9g4fwb.gq",
    "pyiauje42dysm.gq",
    "q2gfiqsi4szzf54xe.gq",
    "q8ec97sr791.gq",
    "qwtof1c6gewti.gq",
    "r-mail.gq",
    "r1qaihnn9wb.gq",
    "r4ntwsd0fe58xtdp.gq",
    "r4unxengsekp.gq",
    "raiasu.gq",
    "raimucok.gq",
    "raimuwedos.gq",
    "rav-4.gq",
    "readyforyou.gq",
    "renault-sa.gq",
    "renaulttrucks.gq",
    "return0.gq",
    "rexagod.gq",
    "rh3qqqmfamt3ccdgfa.gq",
    "rim7lth8moct0o8edoe.gq",
    "rj-11.gq",
    "rj11.gq",
    "rocketmail.gq",
    "roewe.gq",
    "rollsroyce-plc.gq",
    "rolndedip.gq",
    "rover100.gq",
    "rover400.gq",
    "rover75.gq",
    "rr-group.gq",
    "rvjtudarhs.gq",
    "s-mail.gq",
    "s0ny.gq",
    "s48aaxtoa3afw5edw0.gq",
    "saab9-3.gq",
    "saab9-4x.gq",
    "saab9-5.gq",
    "saab9-7x.gq",
    "saab900.gq",
    "saabaru.gq",
    "saabcars.gq",
    "saabgroup.gq",
    "saabscania.gq",
    "sahrulselow.gq",
    "salahkahaku.gq",
    "sandre.gq",
    "santhia.gq",
    "santikadyandra.gq",
    "satcom.gq",
    "scrmnto.gq",
    "secmail.gq",
    "selowcoffee.gq",
    "selowhellboy.gq",
    "sennbox.gq",
    "sepatusupeng.gq",
    "sexboxx.gq",
    "sexymail.gq",
    "sgb-itu-anjeng.gq",
    "sgb-itu-bangsat.gq",
    "shishish.gq",
    "shitaway.gq",
    "shitmail.gq",
    "shittymail.gq",
    "shiyakila.gq",
    "shoklin.gq",
    "sikomo.gq",
    "sivtmwumqz6fqtieicx.gq",
    "six-six-six.gq",
    "skhnlm.gq",
    "snaknoc.gq",
    "snam.gq",
    "sneakerbunko.gq",
    "so-net.gq",
    "spamfighter.gq",
    "spamserver.gq",
    "spamserver2.gq",
    "spamwc.gq",
    "ss-hitler.gq",
    "ss00.gq",
    "ss01.gq",
    "ss02.gq",
    "ssangyong.gq",
    "st-m.gq",
    "steorn.gq",
    "subaru-brz.gq",
    "subaru-wrx.gq",
    "subaru-xv.gq",
    "suntory.gq",
    "sutiami.gq",
    "suxt3eifou1eo5plgv.gq",
    "syosetu.gq",
    "szi4edl0wnab3w6inc.gq",
    "t-student.gq",
    "tai-asu.gq",
    "takeshobo.gq",
    "takmemberi.gq",
    "tehdini.gq",
    "tehsusu.gq",
    "telekgaring.gq",
    "telekteles.gq",
    "telekucing.gq",
    "tempmails.gq",
    "testoh.gq",
    "texac0.gq",
    "thidthid.gq",
    "thyfre.gq",
    "tirreno.gq",
    "titan-host.gq",
    "titaspaharpur1.gq",
    "titaspaharpur2.gq",
    "titaspaharpur3.gq",
    "titaspaharpur4.gq",
    "titaspaharpur5.gq",
    "tlvsmbdy.gq",
    "toyota-rav-4.gq",
    "toyota-rav4.gq",
    "tr2k.gq",
    "tralalajos.gq",
    "trash-mail.gq",
    "trashmail.gq",
    "trenord.gq",
    "trumpmail.gq",
    "ts-by-tashkent.gq",
    "tsukushiakihito.gq",
    "u14269.gq",
    "ujapbk1aiau4qwfu.gq",
    "ujijima1129.gq",
    "uk-unitedkingdom.gq",
    "ukjton.gq",
    "ulahadigung.gq",
    "ulahadigungproject.gq",
    "umrn.gq",
    "uo8fylspuwh9c.gq",
    "uojjhyhih.gq",
    "usa623.gq",
    "usachan.gq",
    "ushijima1129.gq",
    "uzxia.gq",
    "vaffanculo.gq",
    "veebee.gq",
    "ver0.gq",
    "vercelli.gq",
    "vickaentb.gq",
    "vieebee.gq",
    "virgilio.gq",
    "virgiliomail.gq",
    "virtualmail.gq",
    "visal168.gq",
    "vlipbttm9p37te.gq",
    "vmhdisfgxxqoejwhsu.gq",
    "volkswagen-ag.gq",
    "volvo-ab.gq",
    "volvo-s60.gq",
    "volvogroup.gq",
    "vphnfuu2sd85w.gq",
    "vw-cc.gq",
    "vw-eos.gq",
    "vyhade3z.gq",
    "wasdfgh.gq",
    "waskitacorp.gq",
    "watashiyuo.gq",
    "wedooos.gq",
    "wicked-game.gq",
    "wikipedia-inc.gq",
    "wikipedia-llc.gq",
    "wkhaiii.gq",
    "wongndeso.gq",
    "x1bkskmuf4.gq",
    "xc40.gq",
    "xc90.gq",
    "xdavpzaizawbqnivzs0.gq",
    "xipcj6uovohr.gq",
    "yaelahtodkokgitu.gq",
    "yaraon.gq",
    "yelloww.gq",
    "yj3nas.gq",
    "yopmail.gq",
    "z-mail.gq",
    "z870wfurpwxadxrk.gq",
    "zanichelli.gq",
    "zesta.gq",
    "zexeet9i5l49ocke.gq",
    "zil4czsdz3mvauc2.gq",
    "zilmail.gq",
    "zinmail.gq",
    "zipo1.gq",
    "zleohkaqpt5.gq",
    "zlmsl0rkw0232hph.gq",
    "zmti6x70hdop.gq",
    "znatb25xbul30ui.gq",
    "zualikhakk.gq",
    "zylpu4cm6hrwrgrqxb.gq",
    "zyyu6mute9qn.gq",
  ],
  ml: [
    "josasjari494.ml",
    "jummayang1472.ml",
    "koyocah.ml",
    "logardha605.ml",
    "logfrisaha808.ml",
    "logwulan9.ml",
    "mail11.ml",
    "rainbowly.ml",
    "salaopm.ml",
    "vw-skoda.ml",
    "0039.ml",
    "00b2bcr51qv59xst2.ml",
    "02466.ml",
    "0x03.ml",
    "10minutemail.ml",
    "11-32.ml",
    "13sasytkgb0qobwxat.ml",
    "18-9-2.ml",
    "19922.ml",
    "1mail.ml",
    "2.emailfake.ml",
    "225522.ml",
    "2aitycnhnno6.ml",
    "2dfmail.ml",
    "3gk2yftgot.ml",
    "3x0ex1x2yx0.ml",
    "40.volvo-xc.ml",
    "4vq19hhmxgaruka.ml",
    "50mb.ml",
    "5am5ung.ml",
    "6.emailfake.ml",
    "60.volvo-xc.ml",
    "666-satan.ml",
    "67832.ml",
    "6brmwv.ml",
    "6lhp5tembvpl.ml",
    "6mail.ml",
    "6q70sdpgjzm2irltn.ml",
    "7mail.ml",
    "7pccf.ml",
    "7uy35p.ml",
    "888z5.ml",
    "8imefdzddci.ml",
    "8mail.ml",
    "90.volvo-xc.ml",
    "9ziqmkpzz3aif.ml",
    "a-mule.ml",
    "a4zerwak0d.ml",
    "ab-volvo.ml",
    "ac895.ml",
    "adadass.ml",
    "ag95.ml",
    "ahmadidik.ml",
    "akusayyangkamusangat.ml",
    "aleaisyah710.ml",
    "aleelma686.ml",
    "alefachria854.ml",
    "aleshiami275.ml",
    "aletasya616.ml",
    "alfa-romeo.ml",
    "alfaromeo147.ml",
    "amex-online.ml",
    "anakjembutad.ml",
    "anaploxo.ml",
    "anayikt.ml",
    "anjingkokditolak.ml",
    "anthagine.ml",
    "antistream.ml",
    "aoltimewarner.ml",
    "app-inc-vol.ml",
    "appnowl.ml",
    "apssdc.ml",
    "aresanob.ml",
    "arurimport.ml",
    "arybebekganteng.ml",
    "asgaccse-pt.ml",
    "asgasgasgasggasg.ml",
    "asghashasdhasjhashag.ml",
    "asl13.ml",
    "asls.ml",
    "astaghfirulloh.ml",
    "astonut.ml",
    "atoyot.ml",
    "att-warner.ml",
    "audi-r8.ml",
    "audi-tt.ml",
    "autotest.ml",
    "avioaero.ml",
    "b6o7vt32yz.ml",
    "baban.ml",
    "babau.ml",
    "babirousa.ml",
    "banjarworo.ml",
    "becausethenight.ml",
    "beeviee1.ml",
    "belence.ml",
    "beresleting.ml",
    "berlusconi.ml",
    "bgboad.ml",
    "bhuyarey.ml",
    "bibicaba.ml",
    "bibucabi.ml",
    "bigtetek.ml",
    "bingakilo.ml",
    "bkkpkht.ml",
    "blackmail.ml",
    "bmw-ag.ml",
    "bmw-mini.ml",
    "bmw-x5.ml",
    "bmw-x6.ml",
    "bmw-z4.ml",
    "bnckms.ml",
    "bongobongo.ml",
    "british-leyland.ml",
    "brunhilde.ml",
    "bucbdlbniz.ml",
    "bugmenot.ml",
    "bukwos7fp2glo4i30.ml",
    "buntuty.ml",
    "c0rtana.ml",
    "c20vussj1j4glaxcat.ml",
    "c4utar.ml",
    "c6h12o6.ml",
    "casio-edu.ml",
    "cc-s3x.ml",
    "cc2ilplyg77e.ml",
    "cebong.ml",
    "ceftvhxs7nln9.ml",
    "ceweknakal.ml",
    "channel9.ml",
    "chibakenma.ml",
    "chivasso.ml",
    "clipmail.ml",
    "cobarekyo1.ml",
    "coldmail.ml",
    "crotslep.ml",
    "csi-miami.ml",
    "csi-newyork.ml",
    "cts-lk-i.ml",
    "cul0.ml",
    "d-link.ml",
    "da-da-da.ml",
    "daciasandero.ml",
    "daimlerag.ml",
    "daimlerchrysler.ml",
    "dait.ml",
    "deadfake.ml",
    "dekatri.ml",
    "delorieas.ml",
    "demen.ml",
    "dena.ml",
    "dengekibunko.ml",
    "dettol.ml",
    "dev-null.ml",
    "dfghj.ml",
    "dhl-uk.ml",
    "didikselowcoffee.ml",
    "dikriemangasu.ml",
    "dinksai.ml",
    "discard.ml",
    "disposable-email.ml",
    "disposable.ml",
    "dkert2mdi7sainoz.ml",
    "dl812pqedqw.ml",
    "dlmkme.ml",
    "dm9bqwkt9i2adyev.ml",
    "dmc-12.ml",
    "dot-ml.ml",
    "dragcok2.ml",
    "drope.ml",
    "ds-3.ml",
    "dusnedesigns.ml",
    "dwango.ml",
    "dwipalinggantengyanglainlewat.ml",
    "dwutuemzudvcb.ml",
    "e0yk-mail.ml",
    "eaqso209ak.ml",
    "earthworksyar.ml",
    "ecocap.ml",
    "ef2qohn1l4ctqvh.ml",
    "eins-zwei.ml",
    "ekiibete.ml",
    "email-fake.ml",
    "email2.ml",
    "email3.ml",
    "emailage.ml",
    "emailfake.ml",
    "emailfalsa.ml",
    "emailfreedom.ml",
    "emailspam.ml",
    "emailz.ml",
    "emall.ml",
    "emkei.ml",
    "enggalman.ml",
    "enron.ml",
    "ericjohnson.ml",
    "eur-sec1.ml",
    "everytg.ml",
    "ewer.ml",
    "f1kzc0d3.ml",
    "f39mltl5qyhyfx.ml",
    "facebook-email.ml",
    "facebookmail.ml",
    "fake-mail.ml",
    "fake.i-3gk.ml",
    "fakeinbox.ml",
    "fakemails.ml",
    "fakiralio.ml",
    "fakyah.ml",
    "fannny.ml",
    "fawwaz.ml",
    "fbmail1.ml",
    "fca-nv.ml",
    "fddns.ml",
    "fdgdfgdfgf.ml",
    "feaethplrsmel.ml",
    "ferastya.ml",
    "fggjghkgjkgkgkghk.ml",
    "fi-pdl.ml",
    "fiat-chrysler.ml",
    "fiat500.ml",
    "fiatgroup.ml",
    "fica.ml",
    "fikrihidayah.ml",
    "fikrinhdyh.ml",
    "firef0x.ml",
    "fobsos.ml",
    "foreskin.ml",
    "freemails.ml",
    "freemeil.ml",
    "friscaa.ml",
    "fs16dubzzn0.ml",
    "fusixgasvv1gbjrbc.ml",
    "fuw65d.ml",
    "fw-nietzsche.ml",
    "g00g.ml",
    "g00glechr0me.ml",
    "g7lkrfzl7t0rb9oq.ml",
    "galaxy-s9.ml",
    "garingsin.ml",
    "geaviation.ml",
    "geludkita.ml",
    "get-mail.ml",
    "getairmail.ml",
    "getnada.ml",
    "gg-byron.ml",
    "giooig.ml",
    "gitumau.ml",
    "goliokao.ml",
    "google-email.ml",
    "grangmi.ml",
    "grodins.ml",
    "group-llc.ml",
    "groupe-psa.ml",
    "gwfh.ml",
    "h1tler.ml",
    "hamusoku.ml",
    "hangxomcuatoilatotoro.ml",
    "hasanmail.ml",
    "hays.ml",
    "hdhkmbu.ml",
    "helm.ml",
    "helrey.ml",
    "hendrikarifqiariza.ml",
    "hfmf.ml",
    "hgrmnh.ml",
    "hisalotk.ml",
    "hishyau.ml",
    "hitler-adolf.ml",
    "hiyrey.ml",
    "hjfgyjhfyjfytujty.ml",
    "hkft7pttuc7hdbnu.ml",
    "hngwrb7ztl.ml",
    "horvathurtablahoz.ml",
    "hot-mail.ml",
    "hrb67.ml",
    "hrgmgka.ml",
    "htaae8jvikgd3imrphl.ml",
    "huck.ml",
    "hummer-h3.ml",
    "i-3gk.ml",
    "i-sp.ml",
    "idnkil.ml",
    "idt8wwaohfiru7.ml",
    "iefbcieuf.ml",
    "if58.ml",
    "ik7gzqu2gved2g5wr.ml",
    "ikelsik.ml",
    "ikoplak.ml",
    "ikuzus.ml",
    "inbax.ml",
    "info-radio.ml",
    "inibuatsgb.ml",
    "inmynetwork.ml",
    "inrim.ml",
    "iodizc3krahzsn.ml",
    "iolokdi.ml",
    "iq2kq5bfdw2a6.ml",
    "it-italy.ml",
    "ivecotrucks.ml",
    "ixkxirzvu10sybu.ml",
    "j-keats.ml",
    "jagongan.ml",
    "jaguar-landrover.ml",
    "jaguar-xj.ml",
    "jamikait.ml",
    "jancokancene.ml",
    "janganjadiabu2.ml",
    "janganjadiabu7.ml",
    "jdasdhj.ml",
    "jeep-official.ml",
    "jellow.ml",
    "jiancokowe.ml",
    "jinggakq.ml",
    "jkcntadia.ml",
    "jkrowlg.ml",
    "jnthn39vr4zlohuac.ml",
    "john-doe.ml",
    "johnpo.ml",
    "jonrepoza.ml",
    "jorosc.ml",
    "josalita95.ml",
    "josalyani102.ml",
    "josangel381.ml",
    "josdita632.ml",
    "jp-morgan.ml",
    "jumaelda4846.ml",
    "jummario7296.ml",
    "jumossi51.ml",
    "jumreynard5211.ml",
    "justemail.ml",
    "kadokawa.ml",
    "karatraman.ml",
    "kauinginpergi.ml",
    "kazelink.ml",
    "keykeykelyns.ml",
    "keykeykelynss.ml",
    "keykeykelynsss.ml",
    "keykeykelynz.ml",
    "kithjiut.ml",
    "kiuyutre.ml",
    "kodaka.ml",
    "kokorot.ml",
    "kook.ml",
    "koweancenjancok.ml",
    "krupp.ml",
    "kuatocokjaran.ml",
    "kucingarong.ml",
    "kudaponiea.ml",
    "kum38p0dfgxz.ml",
    "kutakbisadekatdekat.ml",
    "kutakbisajauhjauh.ml",
    "lacercadecandi.ml",
    "laika999.ml",
    "laikacyber.ml",
    "lakngin.ml",
    "laparbgt.ml",
    "lecz6s2swj1kio.ml",
    "lembarancerita.ml",
    "lenmawarni5581.ml",
    "lennurfitria2852.ml",
    "lenprayoga2653.ml",
    "level-3.ml",
    "levy.ml",
    "lg-g7.ml",
    "linguistic.ml",
    "ljhjhkrt.ml",
    "localwomen-meet.ml",
    "logaelda603.ml",
    "logartika465.ml",
    "logavrilla544.ml",
    "logdufay341.ml",
    "logefrinda237.ml",
    "logfitriani914.ml",
    "loghermawaty297.ml",
    "login-email.ml",
    "logismi227.ml",
    "logmardhiyah828.ml",
    "lognadiya556.ml",
    "logpabrela551.ml",
    "logsharifa965.ml",
    "logtiara884.ml",
    "logutomo880.ml",
    "logw735.ml",
    "logwan245.ml",
    "logwibisono870.ml",
    "lonthe.ml",
    "lordvold.ml",
    "lovefall.ml",
    "loyalherceghalom.ml",
    "lwmhcka58cbwi.ml",
    "mail-j.ml",
    "mail-w.ml",
    "mail.defaultdomain.ml",
    "mail.myde.ml",
    "mail10.ml",
    "mail1999.ml",
    "mail2.worksmobile.ml",
    "mail2000.ml",
    "mail2001.ml",
    "mail2nowhere.ml",
    "mailcc.ml",
    "mailcdn.ml",
    "mailcx.ml",
    "maildrop.ml",
    "mailfree.ml",
    "mailinbox.ml",
    "mailjunk.ml",
    "mailsac.ml",
    "mailserver2.ml",
    "mailthunder.ml",
    "mainphp.ml",
    "martyvole.ml",
    "matamuasu.ml",
    "materiali.ml",
    "mayaaaa.ml",
    "mb69.ml",
    "mbt01.ml",
    "mecbuc.ml",
    "memecituenakganasli.ml",
    "merantikk.ml",
    "merda.ml",
    "mg-rover.ml",
    "mhmmmkumen.ml",
    "miegrg.ml",
    "migserver2.ml",
    "mimpaharpur.ml",
    "mintadomaindong.ml",
    "mintemail.ml",
    "mitsubishi-asx.ml",
    "mitsubishi-pajero.ml",
    "monadi.ml",
    "mooblan.ml",
    "morriesworld.ml",
    "movanfj.ml",
    "mozillafirefox.ml",
    "mp-j.ml",
    "mpaaf.ml",
    "mrossi.ml",
    "msrc.ml",
    "muqwftsjuonmc2s.ml",
    "myde.ml",
    "myemail1.ml",
    "myfake.ml",
    "myinterserver.ml",
    "naghini.ml",
    "napalm51.ml",
    "nash.ml",
    "nasinyang.ml",
    "naskotk.ml",
    "nazimail.ml",
    "ndif8wuumk26gv5.ml",
    "nds8ufik2kfxku.ml",
    "nestle-usa.ml",
    "nfnov28y9r7pxox.ml",
    "nichess.ml",
    "nmfrvry.ml",
    "nmpkkr.ml",
    "no-vax.ml",
    "nokiahere.ml",
    "nokiamail.ml",
    "nosh.ml",
    "notmail.ml",
    "nownaw.ml",
    "nsk1vbz.ml",
    "ntuv4sit2ai.ml",
    "nxdgrll3wtohaxqncsm.ml",
    "officialngentot.ml",
    "ohkogtsh.ml",
    "oiplikai.ml",
    "ojosambat.ml",
    "oplaskit.ml",
    "orangotango.ml",
    "ouhihu.ml",
    "ovimail.ml",
    "ovlov.ml",
    "oweiidfjjif.ml",
    "oyuhfer.ml",
    "ozqn1it6h5hzzxfht0.ml",
    "p-gdl.ml",
    "p180.ml",
    "p1nhompdgwn.ml",
    "paharpurmim.ml",
    "pancosj.ml",
    "papermakers.ml",
    "paridisa.ml",
    "pay-pals5467.ml",
    "pb-shelley.ml",
    "pd6badzx7q8y0.ml",
    "peoplehavethepower.ml",
    "perjalanandinas.ml",
    "personal-email.ml",
    "personalcok.ml",
    "peugeot206.ml",
    "pgdln.ml",
    "phd-com.ml",
    "phecrex.ml",
    "piaggioaero.ml",
    "pikirkumu.ml",
    "pilpres2018.ml",
    "pinknbo.ml",
    "pkcabyr.ml",
    "plgbgus.ml",
    "poioijnkjb.ml",
    "pojok.ml",
    "polarkingxx.ml",
    "polimi.ml",
    "popsok.ml",
    "porco.ml",
    "porta.loyalherceghalom.ml",
    "pp98.ml",
    "predatorrat.ml",
    "prekuldown47mmi.ml",
    "pringlang.ml",
    "pumamaning.ml",
    "pumapumayes.ml",
    "purplemail.ml",
    "pw-mail.ml",
    "pw.loyalherceghalom.ml",
    "pyiauje42dysm.ml",
    "qbqbtf4trnycocdg4c.ml",
    "qwerqwerty.ml",
    "qwtof1c6gewti.ml",
    "r1qaihnn9wb.ml",
    "r4unxengsekp.ml",
    "raiasu.ml",
    "raimucok.ml",
    "raimuwedos.ml",
    "raiway.ml",
    "rav-4.ml",
    "readyforyou.ml",
    "remehan.ml",
    "renault-sa.ml",
    "renaulttrucks.ml",
    "return0.ml",
    "rexagod.ml",
    "rh3qqqmfamt3ccdgfa.ml",
    "rim7lth8moct0o8edoe.ml",
    "rj-11.ml",
    "rj11.ml",
    "roewe.ml",
    "rollsroyce-plc.ml",
    "rolndedip.ml",
    "rover100.ml",
    "rover400.ml",
    "rover75.ml",
    "roys.ml",
    "rr-ghost.ml",
    "rr-group.ml",
    "rudymail.ml",
    "rxmaof5wma.ml",
    "s0ny.ml",
    "s1xssanlgkgc.ml",
    "s48aaxtoa3afw5edw0.ml",
    "saab9-3.ml",
    "saab9-4x.ml",
    "saab9-5.ml",
    "saab9-7x.ml",
    "saab900.ml",
    "saabaru.ml",
    "saabcars.ml",
    "saabgroup.ml",
    "saabscania.ml",
    "sahrulselow.ml",
    "salahkahaku.ml",
    "samblad.ml",
    "santikadyandra.ml",
    "savelife.ml",
    "scrmnto.ml",
    "secmail.ml",
    "selowcoffee.ml",
    "selowhellboy.ml",
    "semarhouse.ml",
    "sennbox.ml",
    "service4.ml",
    "sexboxx.ml",
    "sgb-itu-anjeng.ml",
    "sgb-itu-bangsat.ml",
    "shishish.ml",
    "shitaway.ml",
    "shittymail.ml",
    "shiyakila.ml",
    "shoklin.ml",
    "shorten.tempm.ml",
    "sikomo.ml",
    "sinema.ml",
    "sivtmwumqz6fqtieicx.ml",
    "six-six-six.ml",
    "skhnlm.ml",
    "skzokgmueb3gfvu.ml",
    "smirusn6t7.ml",
    "snaknoc.ml",
    "sneakerbunko.ml",
    "so-net.ml",
    "sohai.ml",
    "spamfighter.ml",
    "spamserver.ml",
    "spamserver2.ml",
    "spamwc.ml",
    "ss-hitler.ml",
    "ss00.ml",
    "ss02.ml",
    "ssangyong.ml",
    "sssppua.ml",
    "st-m.ml",
    "steorn.ml",
    "stop-my-spam.ml",
    "students-class1.ml",
    "subaru-brz.ml",
    "subaru-wrx.ml",
    "subaru-xv.ml",
    "supere.ml",
    "suremail.ml",
    "susi.ml",
    "sutiami.ml",
    "sweetpotato.ml",
    "sxzevvhpmitlc64k9.ml",
    "szi4edl0wnab3w6inc.ml",
    "t-student.ml",
    "tai-asu.ml",
    "takeshobo.ml",
    "tarma.ml",
    "tarzanmail.ml",
    "tehdini.ml",
    "tehsusu.ml",
    "telekgaring.ml",
    "telekteles.ml",
    "telekucing.ml",
    "temp-mail.ml",
    "tempm.ml",
    "testoh.ml",
    "texac0.ml",
    "thidthid.ml",
    "thyfre.ml",
    "tirreno.ml",
    "titan-host.ml",
    "titaspaharpur2.ml",
    "titaspaharpur3.ml",
    "titaspaharpur4.ml",
    "titaspaharpur5.ml",
    "tlvsmbdy.ml",
    "toon.ml",
    "toyota-rav-4.ml",
    "toyota-rav4.ml",
    "tq84vt9teyh.ml",
    "tr2k.ml",
    "tralalajos.ml",
    "trash-mail.ml",
    "trebusinde.ml",
    "trenord.ml",
    "trumpmail.ml",
    "ts-by-tashkent.ml",
    "ttdfytdd.ml",
    "twkly.ml",
    "u14269.ml",
    "ucupdong.ml",
    "uk-unitedkingdom.ml",
    "ukjton.ml",
    "ulahadigung.ml",
    "ulahadigungproject.ml",
    "umrn.ml",
    "uojjhyhih.ml",
    "usachan.ml",
    "ushijima1129.ml",
    "uzxia.ml",
    "ver0.ml",
    "vercelli.ml",
    "vickaentb.ml",
    "vihost.ml",
    "vip-mail.ml",
    "virgilio.ml",
    "virgiliomail.ml",
    "visal168.ml",
    "vmhdisfgxxqoejwhsu.ml",
    "volkswagen-ag.ml",
    "volvo-ab.ml",
    "volvo-s60.ml",
    "volvo-v40.ml",
    "volvo-xc.ml",
    "volvogroup.ml",
    "vw-audi.ml",
    "vw-cc.ml",
    "vw-eos.ml",
    "vw-seat.ml",
    "wasdfgh.ml",
    "waskitacorp.ml",
    "watashiyuo.ml",
    "wedooos.ml",
    "wesandrianto241.ml",
    "wesatikah407.ml",
    "wesfajriah489.ml",
    "weshasni356.ml",
    "wesmubasyiroh167.ml",
    "wesw881.ml",
    "weswidihastuti191.ml",
    "wicked-game.ml",
    "wikipedia-inc.ml",
    "wikipedia-llc.ml",
    "wkhaiii.ml",
    "wolfmail.ml",
    "wolfsmail.ml",
    "x-mule.ml",
    "xc40.ml",
    "xc60.ml",
    "xc90.ml",
    "xipcj6uovohr.ml",
    "xn--bei.ml",
    "xn--j6h.ml",
    "xtrars.ml",
    "y0up0rn.ml",
    "yaelahtodkokgitu.ml",
    "yaraon.ml",
    "yelloww.ml",
    "yj3nas.ml",
    "yormanwhite.ml",
    "yourtube.ml",
    "yppm0z5sjif.ml",
    "z5cpw9pg8oiiuwylva.ml",
    "z8zcx3gpit2kzo.ml",
    "zanichelli.ml",
    "zbpefn95saft.ml",
    "zebuasadis.ml",
    "zemail.ml",
    "zer-0.ml",
    "zexeet9i5l49ocke.ml",
    "zgu5la23tngr2molii.ml",
    "zilmail.ml",
    "zipo1.ml",
    "zlmsl0rkw0232hph.ml",
    "zmti6x70hdop.ml",
    "zrmail.ml",
    "zualikhakk.ml",
    "zwwnhmmcec57ziwux.ml",
    "zyyu6mute9qn.ml",
  ],
  uk: [
    "clubbeetle.co.uk",
    "clublexus.co.uk",
    "grandparents.co.uk",
    "imposter.co.uk",
    "virgos.co.uk",
    "020.co.uk",
    "10minutemail.co.uk",
    "123qwe.co.uk",
    "12freeukisp.co.uk",
    "14n.co.uk",
    "2bmail.co.uk",
    "abbeyroadlondon.co.uk",
    "aberdeenfans.co.uk",
    "allemails.co.uk",
    "aol.co.uk",
    "aquarians.co.uk",
    "arians.co.uk",
    "aristino.co.uk",
    "atlasaerials.co.uk",
    "baggiesfans.co.uk",
    "bikeracers.co.uk",
    "blackpoolfans.co.uk",
    "blueyonder.co.uk",
    "bournemouthfans.co.uk",
    "brightonfans.co.uk",
    "bristolroversfans.co.uk",
    "britishintelligence.co.uk",
    "bryanston.co.uk",
    "btconnect.co.uk",
    "btopenworld.co.uk",
    "burnleyfans.co.uk",
    "cancerians.co.uk",
    "capricornians.co.uk",
    "cardifffans.co.uk",
    "carenthusiasts.co.uk",
    "carnuts.co.uk",
    "chesterfieldfans.co.uk",
    "clubaudi.co.uk",
    "clubaustin.co.uk",
    "clubbentley.co.uk",
    "clubcaterham.co.uk",
    "clubharley.co.uk",
    "clublamborghini.co.uk",
    "clubmg.co.uk",
    "clubmini.co.uk",
    "clubmorris.co.uk",
    "clubtoyota.co.uk",
    "cubeunlimited.co.uk",
    "derbydales.co.uk",
    "dfjunkmail.co.uk",
    "dm.w3internet.co.uk",
    "dogmail.co.uk",
    "easyriders.co.uk",
    "email-london.co.uk",
    "excite.co.uk",
    "farrse.co.uk",
    "fastfords.co.uk",
    "fastmail.co.uk",
    "fetchmail.co.uk",
    "fetchnet.co.uk",
    "fmail.co.uk",
    "force9.co.uk",
    "freeserve.co.uk",
    "freeukisp.co.uk",
    "freezone.co.uk",
    "friendlymail.co.uk",
    "fsbusiness.co.uk",
    "fslife.co.uk",
    "fsnet.co.uk",
    "fsworld.co.uk",
    "gaybrighton.co.uk",
    "gemineans.co.uk",
    "gillsfans.co.uk",
    "ginzi.co.uk",
    "ginzy.co.uk",
    "gmail.co.uk",
    "gmx.co.uk",
    "gofree.co.uk",
    "grandmothers.co.uk",
    "gsrv.co.uk",
    "gynzi.co.uk",
    "hamiltonfans.co.uk",
    "happysinner.co.uk",
    "hibsfans.co.uk",
    "hotmail.co.uk",
    "hunks.co.uk",
    "imperialcity.co.uk",
    "kontol.co.uk",
    "leonians.co.uk",
    "leos.org.uk",
    "librans.co.uk",
    "live.co.uk",
    "live.mdx.ac.uk",
    "live.ucl.ac.uk",
    "looksmart.co.uk",
    "luxusmail.uk",
    "lycos.co.uk",
    "mail.bcu.ac.uk",
    "mail.org.uk",
    "mailinator.co.uk",
    "maxmail.co.uk",
    "mobileninja.co.uk",
    "msn.co.uk",
    "mummies.co.uk",
    "myemailaddress.co.uk",
    "netscapeonline.co.uk",
    "o2.co.uk",
    "openlate.co.uk",
    "orangehome.co.uk",
    "pisceans.co.uk",
    "plymouthfans.co.uk",
    "posteo.co.uk",
    "posteo.uk",
    "postmaster.co.uk",
    "ramail.co.uk",
    "sagittarians.co.uk",
    "scorpions.co.uk",
    "scottishmail.co.uk",
    "shopkeepers.co.uk",
    "slickriffs.co.uk",
    "soton.ac.uk",
    "spamthis.co.uk",
    "strath.ac.uk",
    "student.gla.ac.uk",
    "suppdiwaren.ddns.me.uk",
    "talktalk.co.uk",
    "taureans.co.uk",
    "telegraph.co.uk",
    "tempinbox.co.uk",
    "themoon.co.uk",
    "thewebpros.co.uk",
    "tiscali.co.uk",
    "topgamers.co.uk",
    "topmail.co.uk",
    "uku.co.uk",
    "v21.me.uk",
    "velnet.co.uk",
    "verybig.co.uk",
    "verywild.co.uk",
    "vettelfans.co.uk",
    "violinmakers.co.uk",
    "virginmedia.co.uk",
    "virgoans.co.uk",
    "w3internet.co.uk",
    "wanadoo.co.uk",
    "wiganfans.co.uk",
    "wizkids.co.uk",
    "xoxy.uk",
    "yahho.co.uk",
    "yahoo.co.uk",
    "zzom.co.uk",
  ],
  cn: [
    "0317123.cn",
    "189.cn",
    "bpda.cn",
    "christina365.cn",
    "cntv.cn",
    "csoftmail.cn",
    "cta.cq.cn",
    "cuc.edu.cn",
    "czpanda.cn",
    "daef.cn",
    "fastmail.cn",
    "fpmo.cn",
    "gmail.cn",
    "haida-edu.cn",
    "home.news.cn",
    "huajiachem.cn",
    "jdz.gov.cn",
    "live.cn",
    "msn.cn",
    "secure-mail.cn",
    "sina.cn",
    "sina.com.cn",
    "sohus.cn",
    "spoy.cn",
    "svip520.cn",
    "thexyz.cn",
    "trimix.cn",
    "w.cn",
    "whu.edu.cn",
    "wo.com.cn",
    "workflowy.cn",
    "wormseo.cn",
    "yahoo.cn",
    "yahoo.com.cn",
    "zhaoyuanedu.cn",
    "zimufensi.cn",
  ],
  tk: [
    "alekikhmah967.tk",
    "b.yertxenor.tk",
    "ck12.tk",
    "domforfb18.tk",
    "domforfb27.tk",
    "domforfb5.tk",
    "domforfb9.tk",
    "josivangkia341.tk",
    "logmoerdiati40.tk",
    "mail-z.tk",
    "oofmail.tk",
    "rav4.tk",
    "tmail2.tk",
    "toyota-yaris.tk",
    "wolfsmails.tk",
    "07819.tk",
    "0x02.tk",
    "0x03.tk",
    "11-32.tk",
    "13sasytkgb0qobwxat.tk",
    "18-9-2.tk",
    "1fsdfdsfsdf.tk",
    "1spcziorgtfpqdo.tk",
    "2and2mail.tk",
    "2dfmail.tk",
    "2fdgdfgdfgdf.tk",
    "2zpph1mgg70hhub.tk",
    "3trtretgfrfe.tk",
    "3utasmqjcv.tk",
    "3x0ex1x2yx0.tk",
    "40.volvo-xc.tk",
    "4gfdsgfdgfd.tk",
    "5am5ung.tk",
    "5ghgfhfghfgh.tk",
    "60.volvo-xc.tk",
    "666-satan.tk",
    "67832.tk",
    "69-ew.tk",
    "6brmwv.tk",
    "6hjgjhgkilkj.tk",
    "6lhp5tembvpl.tk",
    "6q70sdpgjzm2irltn.tk",
    "7pccf.tk",
    "7uy35p.tk",
    "800sacramento.tk",
    "888z5.tk",
    "8usmwuqxh1s1pw.tk",
    "90.volvo-xc.tk",
    "9skcqddzppe4.tk",
    "a.yertxenor.tk",
    "a4zerwak0d.tk",
    "ab-volvo.tk",
    "abarth.tk",
    "acmeco.tk",
    "adadass.tk",
    "aeon.tk",
    "ag95.tk",
    "akusayyangkamusangat.tk",
    "aledestrya671.tk",
    "aleherlin351.tk",
    "alenina729.tk",
    "alenovita373.tk",
    "alesulalah854.tk",
    "alfaromeo147.tk",
    "aliases.tk",
    "amex-online.tk",
    "anakjembutad.tk",
    "anaploxo.tk",
    "anjingkokditolak.tk",
    "anotherdomaincyka.tk",
    "antistream.tk",
    "antonveneta.tk",
    "aoltimewarner.tk",
    "apalo.tk",
    "appl3.tk",
    "aresanob.tk",
    "ariasexy.tk",
    "arur01.tk",
    "arybebekganteng.tk",
    "asgaccse-pt.tk",
    "asgardia-space.tk",
    "asl13.tk",
    "astonut.tk",
    "atoyot.tk",
    "att-warner.tk",
    "audi-r8.tk",
    "audi-tt.tk",
    "avikd.tk",
    "avioaero.tk",
    "azazazatashkent.tk",
    "azmeil.tk",
    "b.royal-syrup.tk",
    "b6o7vt32yz.tk",
    "baconsoi.tk",
    "badpotato.tk",
    "bae-systems.tk",
    "bambee.tk",
    "barclays-plc.tk",
    "becausethenight.tk",
    "beeviee1.tk",
    "belence.tk",
    "beresleting.tk",
    "beteajah.tk",
    "bigtetek.tk",
    "bizbiz.tk",
    "blackgate.tk",
    "blurp.tk",
    "bmw-ag.tk",
    "bmw-mini.tk",
    "bmw-rollsroyce.tk",
    "bmw-x5.tk",
    "bmw-x6.tk",
    "bmw-z4.tk",
    "bocil.tk",
    "bohani.tk",
    "bongobongo.tk",
    "british-leyland.tk",
    "bsuakrqwbd.tk",
    "buatwini.tk",
    "bucbdlbniz.tk",
    "c0rtana.tk",
    "c4anec0wemilckzp42.tk",
    "c4utar.tk",
    "c6h12o6.tk",
    "cadillac-ats.tk",
    "caseedu.tk",
    "casio-edu.tk",
    "cc-s3x.tk",
    "cebong.tk",
    "cekajahhs.tk",
    "cellphoneparts.tk",
    "chaparmail.tk",
    "chivasso.tk",
    "cjuprf2tcgnhslvpe.tk",
    "ckaazaza.tk",
    "clipmail.tk",
    "cloudmail.tk",
    "cmc88.tk",
    "covfefe-mail.tk",
    "crotslep.tk",
    "csi-miami.tk",
    "csi-newyork.tk",
    "cts-lk-i.tk",
    "cul0.tk",
    "curlhph.tk",
    "custom12.tk",
    "cvijqth6if8txrdt.tk",
    "d-link.tk",
    "da-da-da.tk",
    "daciasandero.tk",
    "daimlerag.tk",
    "dait.tk",
    "damanik.tk",
    "dasdasdascyka.tk",
    "deadfake.tk",
    "dettol.tk",
    "dhl-uk.tk",
    "dikriemangasu.tk",
    "discard.tk",
    "dl812pqedqw.tk",
    "dm9bqwkt9i2adyev.tk",
    "dmc-12.tk",
    "domforfb1.tk",
    "domforfb19.tk",
    "domforfb23.tk",
    "domforfb29.tk",
    "domforfb4.tk",
    "domforfb6.tk",
    "domforfb8.tk",
    "doquier.tk",
    "dot-ml.tk",
    "dragcok2.tk",
    "ds-3.tk",
    "dwango.tk",
    "dwipalinggantengyanglainlewat.tk",
    "dwutuemzudvcb.tk",
    "e-mule.tk",
    "ecocap.tk",
    "ef2qohn1l4ctqvh.tk",
    "eins-zwei.tk",
    "ekiibetekorea.tk",
    "email-fake.tk",
    "email0.tk",
    "email2.tk",
    "email3.tk",
    "emailage.tk",
    "emailfalsa.tk",
    "emailspam.tk",
    "emkei.tk",
    "emoreno.tk",
    "eqeqeqeqe.tk",
    "eur-sec1.tk",
    "euromail.tk",
    "f1kzc0d3.tk",
    "fake-mail.tk",
    "fakeemail.tk",
    "fakeinbox.tk",
    "familiekersten.tk",
    "farfurmail.tk",
    "fbma.tk",
    "fca-nv.tk",
    "feaethplrsmel.tk",
    "ferastya.tk",
    "fi-pdl.tk",
    "fiat-chrysler.tk",
    "fiat500.tk",
    "fikrihidayah.tk",
    "fikrinhdyh.tk",
    "firef0x.tk",
    "fixmail.tk",
    "fm69.tk",
    "fmailx.tk",
    "foreskin.tk",
    "fragolina2.tk",
    "frappina.tk",
    "frappina99.tk",
    "freemeil.tk",
    "friscaa.tk",
    "fs-fitzgerald.tk",
    "fs16dubzzn0.tk",
    "fuw65d.tk",
    "fw-nietzsche.tk",
    "g00glechr0me.tk",
    "galaxy-s9.tk",
    "geaviation.tk",
    "geludkita.tk",
    "general-motors.tk",
    "get-mail.tk",
    "getairmail.tk",
    "getnada.tk",
    "gfmail.tk",
    "gg-byron.tk",
    "ghgluiis.tk",
    "giooig.tk",
    "giplwsaoozgmmp.tk",
    "gitumau.tk",
    "glick.tk",
    "googdad.tk",
    "gpi8eipc5cntckx2s8.tk",
    "greenforce.tk",
    "group-llc.tk",
    "groupe-psa.tk",
    "gwfh.tk",
    "h1tler.tk",
    "h2o-web.tk",
    "hackersquad.tk",
    "hamusoku.tk",
    "hangxomcuatoilatotoro.tk",
    "harvard-ac-uk.tk",
    "hendrikarifqiariza.tk",
    "hitler-adolf.tk",
    "hot-mail.tk",
    "hotakama.tk",
    "hqv8grv8dxdkt1b.tk",
    "hrb67.tk",
    "htstar.tk",
    "hukkmu.tk",
    "hxvxxo1v8mfbt.tk",
    "i-sp.tk",
    "iefbcieuf.tk",
    "if58.tk",
    "ihavedildo.tk",
    "ik7gzqu2gved2g5wr.tk",
    "ikuzus.tk",
    "imeil.tk",
    "inbax.tk",
    "inibuatsgb.tk",
    "inmynetwork.tk",
    "inrim.tk",
    "ippandansei.tk",
    "istmail.tk",
    "it-italy.tk",
    "it2-mail.tk",
    "itempmail.tk",
    "ivecotrucks.tk",
    "j-keats.tk",
    "jaguar-landrover.tk",
    "jaguar-xj.tk",
    "janganjadiabu1.tk",
    "janganjadiabu6.tk",
    "jdasdhj.tk",
    "jeep-official.tk",
    "jfiee.tk",
    "jkcntadia.tk",
    "jnthn39vr4zlohuac.tk",
    "johnpo.tk",
    "jorosc.tk",
    "josadelia100.tk",
    "josamanda777.tk",
    "josfrisca409.tk",
    "joshendriyawati219.tk",
    "josnarendra746.tk",
    "josprayugo291.tk",
    "josyahya751.tk",
    "jumlatifani8910.tk",
    "jumonji.tk",
    "jumreza258.tk",
    "jumveronica8959.tk",
    "kadokawa.tk",
    "kaguya.tk",
    "kaxks55ofhkzt5245n.tk",
    "kdjngsdgsd.tk",
    "kerupukmlempem.tk",
    "keykeykelyns.tk",
    "keykeykelynss.tk",
    "keykeykelynsss.tk",
    "keykeykelynz.tk",
    "kodaka.tk",
    "kokorot.tk",
    "krsw.tk",
    "krypton.tk",
    "ksmtrck.tk",
    "kuatcak.tk",
    "kuatmail.tk",
    "kuatocokjaran.tk",
    "kudaponiea.tk",
    "kum38p0dfgxz.tk",
    "kutakbisadekatdekat.tk",
    "kutakbisajauhjauh.tk",
    "ladymacbeth.tk",
    "laikacyber.tk",
    "lehman.tk",
    "lenovo120s.tk",
    "level-3.tk",
    "lg-g7.tk",
    "lh451.tk",
    "linuxmail.tk",
    "liveradio.tk",
    "logatarita947.tk",
    "logfauziyah838.tk",
    "loghermawaty297.tk",
    "login-email.tk",
    "logmaureen141.tk",
    "logrozi350.tk",
    "logsutanti589.tk",
    "logtanuwijaya670.tk",
    "logvirgina229.tk",
    "lolito.tk",
    "luisgiisjsk.tk",
    "luxusmail.tk",
    "lvc2txcxuota.tk",
    "mail-c.tk",
    "mail-j.tk",
    "mail-w.tk",
    "mail1999.tk",
    "mail2000.tk",
    "mail2001.tk",
    "mail2nowhere.tk",
    "mailcc.tk",
    "mailcx.tk",
    "maildump.tk",
    "mailfa.tk",
    "mailfs.tk",
    "mailita.tk",
    "mailjunk.tk",
    "mailsac.tk",
    "mailserver2.tk",
    "mailtempmha.tk",
    "mayaaaa.tk",
    "mb69.tk",
    "mdhc.tk",
    "mecbuc.tk",
    "memecituenakganasli.tk",
    "merantikk.tk",
    "metuwar.tk",
    "mg-rover.tk",
    "mial.tk",
    "mic3eggekteqil8.tk",
    "mimpaharpur.tk",
    "mintadomaindong.tk",
    "mintemail.tk",
    "mitsubishi-asx.tk",
    "mitsubishi-pajero.tk",
    "miur.tk",
    "monachat.tk",
    "moviflix.tk",
    "mozillafirefox.tk",
    "mp-j.tk",
    "mrichacrown39dust.tk",
    "mrresourcepacks.tk",
    "muehlacker.tk",
    "muq.orangotango.tk",
    "muqwftsjuonmc2s.tk",
    "my-webmail.tk",
    "myfake.tk",
    "myfakemail.tk",
    "mymailjos.tk",
    "mysafemail.tk",
    "n8tini3imx15qc6mt.tk",
    "napalm51.tk",
    "nazimail.tk",
    "ndif8wuumk26gv5.tk",
    "nds8ufik2kfxku.tk",
    "nestle-usa.tk",
    "newbpotato.tk",
    "no-vax.tk",
    "nokiahere.tk",
    "nongzaa.tk",
    "nsk1vbz.tk",
    "officialngentot.tk",
    "ooeawtppmznovo.tk",
    "openmailbox.tk",
    "orangotango.tk",
    "ovimail.tk",
    "ovlov.tk",
    "oweiidfjjif.tk",
    "p-gdl.tk",
    "p180.tk",
    "p1nhompdgwn.tk",
    "pagamenti.tk",
    "paharpurmim.tk",
    "paridisa.tk",
    "parlimentpetitioner.tk",
    "passw0rd.tk",
    "pasukanganas.tk",
    "pb-shelley.tk",
    "pecmail.tk",
    "pejovideomaker.tk",
    "pelor.tk",
    "penoto.tk",
    "peoplehavethepower.tk",
    "perjalanandinas.tk",
    "personalcok.tk",
    "phd-com.tk",
    "phecrex.tk",
    "phuongpt9.tk",
    "piaggioaero.tk",
    "pilpres2018.tk",
    "polacy-dungannon.tk",
    "poliusraas.tk",
    "popso.tk",
    "popsok.tk",
    "pp98.tk",
    "predatorrat.tk",
    "prince-khan.tk",
    "prismlasers.tk",
    "ptpigeaz0uorsrygsz.tk",
    "punggur.tk",
    "purplemail.tk",
    "puttana.tk",
    "puttanamaiala.tk",
    "pw-mail.tk",
    "pyiauje42dysm.tk",
    "q8ec97sr791.tk",
    "qwerqwerty.tk",
    "qwtof1c6gewti.tk",
    "r1qaihnn9wb.tk",
    "r9ycfn3nou.tk",
    "raiasu.tk",
    "raiway.tk",
    "rav-4.tk",
    "regspaces.tk",
    "remailer.tk",
    "renault-sa.tk",
    "renaulttrucks.tk",
    "resgedvgfed.tk",
    "rexagod.tk",
    "rh3qqqmfamt3ccdgfa.tk",
    "rj-11.tk",
    "rj11.tk",
    "rollsroyce-plc.tk",
    "rolndedip.tk",
    "rover100.tk",
    "rover400.tk",
    "rover75.tk",
    "rr-ghost.tk",
    "rr-group.tk",
    "saab9-3.tk",
    "saab9-4x.tk",
    "saab9-5.tk",
    "saab9-7x.tk",
    "saab900.tk",
    "saabaru.tk",
    "saabcars.tk",
    "saabgroup.tk",
    "saabscania.tk",
    "salmeow.tk",
    "sandre.tk",
    "santhia.tk",
    "santikadyandra.tk",
    "schmeissweg.tk",
    "sdfghyj.tk",
    "secret-area.tk",
    "sedasagreen01try.tk",
    "semarhouse.tk",
    "sennbox.tk",
    "setefi.tk",
    "sexboxx.tk",
    "sgb-itu-anjeng.tk",
    "sgb-itu-bangsat.tk",
    "shahimul.tk",
    "shitaway.tk",
    "shittymail.tk",
    "sikomo.tk",
    "six-six-six.tk",
    "skrx.tk",
    "smallker.tk",
    "smellypotato.tk",
    "sneakerbunko.tk",
    "so-com.tk",
    "social-mailer.tk",
    "softbank.tk",
    "spamfighter.tk",
    "spamserver.tk",
    "spamserver2.tk",
    "ss-hitler.tk",
    "ss02.tk",
    "sssppua.tk",
    "st-m.tk",
    "stanford-edu.tk",
    "steorn.tk",
    "stop-my-spam.tk",
    "stophabbos.tk",
    "stresser.tk",
    "subaru-brz.tk",
    "subaru-wrx.tk",
    "subaru-xv.tk",
    "sumitra.tk",
    "surfmail.tk",
    "synergie.tk",
    "szi4edl0wnab3w6inc.tk",
    "t099.tk",
    "takeshobo.tk",
    "tarma.tk",
    "tastaravalli.tk",
    "te.caseedu.tk",
    "tenesu.tk",
    "testoh.tk",
    "texac0.tk",
    "tirreno.tk",
    "titaspaharpur2.tk",
    "titaspaharpur3.tk",
    "titaspaharpur4.tk",
    "titaspaharpur5.tk",
    "tlvsmbdy.tk",
    "tmail1.tk",
    "tmail3.tk",
    "tmail5.tk",
    "toyota-rav-4.tk",
    "toyota-rav4.tk",
    "tq84vt9teyh.tk",
    "tr2k.tk",
    "tralalajos.tk",
    "trash-mail.tk",
    "trenord.tk",
    "trumpmail.tk",
    "ts-by-tashkent.tk",
    "tukudawet.tk",
    "tuongtactot.tk",
    "udns.tk",
    "ujapbk1aiau4qwfu.tk",
    "uk-unitedkingdom.tk",
    "ukjton.tk",
    "ulahadigung.tk",
    "ulahadigungproject.tk",
    "usa-gov.tk",
    "usenetmail.tk",
    "ushijima1129.tk",
    "uzxia.tk",
    "vaasfc4.tk",
    "vanbil.tk",
    "vdmmhozx5kxeh.tk",
    "veebee.tk",
    "ver0.tk",
    "vickaentb.tk",
    "vieebee.tk",
    "vihost.tk",
    "vip-mail.tk",
    "virgiliomail.tk",
    "visal007.tk",
    "visal168.tk",
    "vlipbttm9p37te.tk",
    "volkswagen-ag.tk",
    "volvo-ab.tk",
    "volvo-s60.tk",
    "volvo-v40.tk",
    "volvo-xc.tk",
    "volvogroup.tk",
    "vonbe.tk",
    "vw-ag.tk",
    "vw-cc.tk",
    "vw-eos.tk",
    "vzlom4ik.tk",
    "wasdfgh.tk",
    "waskitacorp.tk",
    "watashiyuo.tk",
    "weseni427.tk",
    "wesfajria37.tk",
    "wesnadya714.tk",
    "wesnurullah701.tk",
    "wessastra497.tk",
    "wesyuliyansih469.tk",
    "wfgdfhj.tk",
    "whiteseoromania.tk",
    "wholesaleelec.tk",
    "wicked-game.tk",
    "wierie.tk",
    "wikipedia-inc.tk",
    "wikipedia-llc.tk",
    "wkhaiii.tk",
    "wmail.tk",
    "wolfsmail.tk",
    "wow.royalbrandco.tk",
    "x-mule.tk",
    "xc40.tk",
    "xc60.tk",
    "xc90.tk",
    "xpee.tk",
    "xwaretech.tk",
    "xx-9.tk",
    "xy9ce.tk",
    "y0up0rn.tk",
    "yabai-oppai.tk",
    "yaelahtodkokgitu.tk",
    "yalild.tk",
    "yaraon.tk",
    "yellow.hotakama.tk",
    "yelloww.tk",
    "yertxenon.tk",
    "yj3nas.tk",
    "z870wfurpwxadxrk.tk",
    "z8zcx3gpit2kzo.tk",
    "zanichelli.tk",
    "zilmail.tk",
    "zinmail.tk",
    "zleohkaqpt5.tk",
    "zlmsl0rkw0232hph.tk",
    "zmti6x70hdop.tk",
    "zn4chyguz9rz2gvjcq.tk",
    "znatb25xbul30ui.tk",
    "zpvozwsri4aryzatr.tk",
    "zualikhakk.tk",
    "zxcvbnm.tk",
    "zyyu6mute9qn.tk",
  ],
  ru: [
    "08-life.ru",
    "2014mail.ru",
    "30rip.ru",
    "50abc.ru",
    "8601.ru",
    "au.ru",
    "autostupino.ru",
    "bcedetyam2.ru",
    "beautifulvideo.ru",
    "beribase.ru",
    "berirabotay.ru",
    "bestgifok.ru",
    "dlesha.ru",
    "dostavkapoz.ru",
    "freundin.ru",
    "koshu.ru",
    "msx.ru",
    "my-pomsies.ru",
    "neiroseven.ru",
    "neuro7system.ru",
    "neurosystem7-org.ru",
    "p-fishing.ru",
    "pivo-bar.ru",
    "potencis.ru",
    "redusslimium.ru",
    "relaxology.ru",
    "siftportal.ru",
    "superurotrinz.ru",
    "tgma.ru",
    "top1post.ru",
    "urotrin62.ru",
    "videoprosmotr.ru",
    "vlcity.ru",
    "wikilibhub.ru",
    "zarabotokdoma11.ru",
    "08-gazeta.ru",
    "08-mir.ru",
    "08-novosti.ru",
    "0815.ru",
    "0845.ru",
    "100hot.ru",
    "105kg.ru",
    "10minutemail.ru",
    "10minutesmail.ru",
    "1676.ru",
    "1airpods.ru",
    "1bstb.ru",
    "2012-2016.ru",
    "20abc.ru",
    "22mot.ru",
    "23423.ru",
    "28it.ru",
    "30abc.ru",
    "30mot.ru",
    "33bit.ru",
    "360.ru",
    "40abc.ru",
    "45it.ru",
    "4nmv.ru",
    "4x4n.ru",
    "500-0-501.ru",
    "50it.ru",
    "50set.ru",
    "52it.ru",
    "5ballov.ru",
    "5oz.ru",
    "62it.ru",
    "6somok.ru",
    "74.ru",
    "75it.ru",
    "7magazinov.ru",
    "80it.ru",
    "85it.ru",
    "90bit.ru",
    "9mot.ru",
    "a4craft.ru",
    "aeterna.ru",
    "afronorilsk.ru",
    "alfaomega24.ru",
    "all-cats.ru",
    "allnewsblog.ru",
    "alpha-lamp.ru",
    "angelovgabriel.ru",
    "anobox.ru",
    "antalex7.ru",
    "antireg.ru",
    "apollo-led.ru",
    "aport.ru",
    "aport2000.ru",
    "apple.sib.ru",
    "arendabatumi24.ru",
    "armanik.ru",
    "armocompany.ru",
    "artamebel.ru",
    "asdasd.ru",
    "askaclub.ru",
    "atomtoys.ru",
    "atrus.ru",
    "atvclub.msk.ru",
    "aurelstyle.ru",
    "automail.ru",
    "autorambler.ru",
    "autosouvenir39.ru",
    "avaba.ru",
    "avantatravel.ru",
    "avosbka.ru",
    "avtoritet.ru",
    "babiwatch.ru",
    "baikal-autotravel.ru",
    "baltecosalon.ru",
    "bandamn.ru",
    "banikata.ru",
    "bank-opros1.ru",
    "bazaorg.ru",
    "bcedetyam1.ru",
    "bcedetyam3.ru",
    "bea32.ru",
    "beautifulmassage.ru",
    "beautyboo.ru",
    "beep.ru",
    "belorus-kosmetix.ru",
    "beri-delay.ru",
    "beribaza.ru",
    "beriglobal.ru",
    "beristeam.ru",
    "bestbyuaty.ru",
    "bestg1rls.ru",
    "bestoffworld.ru",
    "bestxfilm.ru",
    "bezvodki.ru",
    "bioresonanthome.ru",
    "biosoznanie.ru",
    "bk.ru",
    "black-magi.ru",
    "black-magick.ru",
    "black-privoroti.ru",
    "blackgoldagency.ru",
    "blackmagi.ru",
    "blackmagick.ru",
    "blackprivoroti.ru",
    "blogsme.ru",
    "bofamily.ru",
    "bohrer-shop.ru",
    "bossmail.ru",
    "botkaislove.ru",
    "boymail.ru",
    "bratan.ru",
    "brgo.ru",
    "brunetka.ru",
    "brunto.ru",
    "bruson.ru",
    "buccalmassage.ru",
    "budgetgoods.ru",
    "buncar.ru",
    "buratin7.ru",
    "butrew.ru",
    "bykov-stroj.ru",
    "cakeonline.ru",
    "casekoga.ru",
    "cavisto.ru",
    "centr-luch.ru",
    "changshutea-official.ru",
    "chat.ru",
    "chatlane.ru",
    "chery-clubs.ru",
    "chicpick.ru",
    "chudosbor-yagodnica.ru",
    "clashkings.ru",
    "clever-game.ru",
    "clickfun.ru",
    "clickmagnit.ru",
    "cmail.ru",
    "cmecsgocup.ru",
    "coachfit.ru",
    "cong.ru",
    "cpsystems.ru",
    "crazy.ru",
    "csgo-market.ru",
    "d-sadokam.ru",
    "dacha-24.ru",
    "ddd.ru",
    "denispushkin.ru",
    "dentistry32norma.ru",
    "deocrystal.ru",
    "dev-api.ru",
    "dezigner.ru",
    "diplomvirkutske.ru",
    "divismail.ru",
    "dlemail.ru",
    "dlyavasfrend.ru",
    "dmirekt.ru",
    "dmitry.ru",
    "dog-n-cats-shelter.ru",
    "domien.ru",
    "domremonta-nv.ru",
    "donemail.ru",
    "doroobo-bet.ru",
    "doublewave.ru",
    "dozvon-spb.ru",
    "drag1metal.ru",
    "dred.ru",
    "dretety.ru",
    "dvsplus18.ru",
    "e-mail.ru",
    "e-mails.ru",
    "e-marketstore.ru",
    "e-torrent.ru",
    "e2e4d7d5.ru",
    "earn-now.ru",
    "edagolic.ru",
    "edgex.ru",
    "effective-neurosystem.ru",
    "elki-mkzn.ru",
    "email.ru",
    "emails.ru",
    "emale.ru",
    "epage.ru",
    "esbano-ru.ru",
    "etotvibor.ru",
    "exolozinium.ru",
    "extremail.ru",
    "ezmail.ru",
    "f-neyrosystem.ru",
    "familiashop.ru",
    "fax.ru",
    "female.ru",
    "fermaxxi.ru",
    "findwater.ru",
    "fitnesrezink.ru",
    "fiver5.ru",
    "flemail.ru",
    "flurred.ru",
    "forexgost.ru",
    "fotoplenka.ru",
    "freemail.ru",
    "freerubli.ru",
    "freunde.ru",
    "fromru.ru",
    "front.ru",
    "g-neurosystem.ru",
    "gajjmorit.ru",
    "geew.ru",
    "geokomponent.ru",
    "gfxartist.ru",
    "girlmail.ru",
    "gmail.ru",
    "go.ru",
    "goldenmail.ru",
    "goldfox.ru",
    "goldmail.ru",
    "google.ru",
    "goplaygame.ru",
    "gosuslugi-spravka.ru",
    "graphtech.ru",
    "greendom67.ru",
    "gruz-m.ru",
    "gynzy.ru",
    "hairs24.ru",
    "happydomik.ru",
    "haramamba.ru",
    "helpjobs.ru",
    "hewgen.ru",
    "holodol.ru",
    "hotbox.ru",
    "hotmail.ru",
    "hu2.ru",
    "id.ru",
    "ideer.msk.ru",
    "imail.ru",
    "inbox.ru",
    "indus.ru",
    "infitter.ru",
    "inshapeactive.ru",
    "internet-v-stavropole.ru",
    "ipex.ru",
    "ipizza24.ru",
    "irobotlab.ru",
    "ispyco.ru",
    "jwork.ru",
    "kamen-market.ru",
    "karta-kykyruza.ru",
    "kdeos.ru",
    "kingclickcompany.ru",
    "kingmain.ru",
    "kino-100.ru",
    "kinovideohit.ru",
    "kismail.ru",
    "km.ru",
    "konbat.ru",
    "konsul.ru",
    "konultant-jurist.ru",
    "korona-nedvizhimosti.ru",
    "kreditor.ru",
    "ktbk.ru",
    "kvartira-volgograd.ru",
    "lackmail.ru",
    "land.ru",
    "lapeksp.ru",
    "le-tim.ru",
    "lenta.ru",
    "linind.ru",
    "list.ru",
    "live-shop24.ru",
    "live.ru",
    "llogin.ru",
    "logins.ru",
    "lov.ru",
    "ls-server.ru",
    "lubovnik.ru",
    "lycos.ru",
    "macbox.ru",
    "mail.ru",
    "mail2000.ru",
    "mail2k.ru",
    "mail666.ru",
    "mailapi.ru",
    "mailboxer.ru",
    "mailgate.ru",
    "mailserver.ru",
    "mailspeed.ru",
    "mailzi.ru",
    "male.ru",
    "mamulenok.ru",
    "margarita.ru",
    "mark-compressoren.ru",
    "marry.ru",
    "mask03.ru",
    "mayaple.ru",
    "medkabinet-uzi.ru",
    "mega-water.ru",
    "megabox.ru",
    "memori.ru",
    "mfsa.ru",
    "mns.ru",
    "mobico.ru",
    "momentics.ru",
    "mosk.ru",
    "moy-elektrik.ru",
    "msk.ru",
    "mswork.ru",
    "music.maigate.ru",
    "my-group223.ru",
    "my-teddyy.ru",
    "mymac.ru",
    "myrambler.ru",
    "mysugartime.ru",
    "naslazhdai.ru",
    "nc.ru",
    "neiro777-official.ru",
    "neiroseven-official.ru",
    "neirosystem7-store.ru",
    "nekto.ru",
    "neotlozhniy-zaim.ru",
    "netaddres.ru",
    "netaddress.ru",
    "netcity.ru",
    "netman.ru",
    "neuro7-shop.ru",
    "neurosystem-cool.ru",
    "neurosystem7-coolshop.ru",
    "neurosystemseven-store.ru",
    "newap.ru",
    "newfilm24.ru",
    "newmail.ru",
    "newneurosystem7.ru",
    "nextmail.ru",
    "ngs.ru",
    "nightmail.ru",
    "nm.ru",
    "nnov.ru",
    "nnoway.ru",
    "no4ma.ru",
    "numep.ru",
    "nxt.ru",
    "oborudovanieizturcii.ru",
    "odnorazovoe.ru",
    "ok.ru",
    "oligarh.ru",
    "olimp-case.ru",
    "olypmall.ru",
    "omen.ru",
    "online.ru",
    "onpotencialex.ru",
    "oonies-shoprus.ru",
    "ozz.ru",
    "p-banlis.ru",
    "para2019.ru",
    "pechkin.ru",
    "petr.ru",
    "pfui.ru",
    "phone-elkey.ru",
    "photofile.ru",
    "pitaniezdorovie.ru",
    "plhk.ru",
    "pobox.ru",
    "pochta.ru",
    "pochtamt.ru",
    "poehali-otdihat.ru",
    "pop3.ru",
    "posteo.ru",
    "postshift.ru",
    "potencid.ru",
    "potencyalex.ru",
    "potenzialex149.ru",
    "pravorobotov.ru",
    "prazdnik-37.ru",
    "predprinimatel.ru",
    "prepodavatel.ru",
    "professor.ru",
    "programist.ru",
    "prokuratura.ru",
    "q-urotrin.ru",
    "qip.ru",
    "quake.ru",
    "quickmail.ru",
    "qvap.ru",
    "r.yasser.ru",
    "rabota24-v-internet.ru",
    "railway-shop.ru",
    "rambler.ru",
    "rbcmail.ru",
    "reddduslim.ru",
    "reduslimf.ru",
    "reduslimius.ru",
    "reksatal-effective.ru",
    "rin.ru",
    "risencraft.ru",
    "ro.ru",
    "rosebearmylove.ru",
    "ru.ru",
    "ruskovka.ru",
    "rx.dred.ru",
    "safebox.ru",
    "sendmail.ru",
    "seven-neuro.ru",
    "shotmail.ru",
    "sidelka-mytischi.ru",
    "sim-simka.ru",
    "simplesport.ru",
    "skyfi.org.ru",
    "slsrs.ru",
    "smap.4nmv.ru",
    "smotretvideotut.ru",
    "smtp.ru",
    "softkey-office.ru",
    "spacebazzar.ru",
    "spam.2012-2016.ru",
    "spambog.ru",
    "spb.ru",
    "spec-energo.ru",
    "sportmail.ru",
    "steam-area.ru",
    "stylist-volos.ru",
    "superkardioru.ru",
    "supermail.ru",
    "superman.ru",
    "superpotencialex.ru",
    "superstarvideo.ru",
    "tamb.ru",
    "technoproxy.ru",
    "temp-mail.ru",
    "tgasa.ru",
    "tgngu.ru",
    "thebestremont.ru",
    "thismail.ru",
    "thoas.ru",
    "tiran.ru",
    "tm.slsrs.ru",
    "top-shop-tovar.ru",
    "top1mail.ru",
    "topmail24.ru",
    "trend-maker.ru",
    "trixtrux1.ru",
    "u.qvap.ru",
    "uhhu.ru",
    "ultrada.ru",
    "unmail.ru",
    "uralplay.ru",
    "urotrin-supershop.ru",
    "urotrinium.ru",
    "urotrinz-supershop.ru",
    "uvidetsmotret.ru",
    "v-kirove.ru",
    "video-dlj-tebya.ru",
    "videohd-clip.ru",
    "videotubegames.ru",
    "vipmail.ru",
    "viwanet.ru",
    "vkcode.ru",
    "vodgard.ru",
    "vovan.ru",
    "vremonte24-store.ru",
    "w-asertun.ru",
    "walkmail.ru",
    "webmail.ru",
    "wegas.ru",
    "whitebot.ru",
    "wikidocuslava.ru",
    "x-pod.ru",
    "x1x.spb.ru",
    "xaker.ru",
    "ya.ru",
    "yahoo.com.ru",
    "yahoo.ru",
    "yandex.ru",
    "yasser.ru",
    "yirotrin15.ru",
    "yourvideos.ru",
    "yreduslim.ru",
    "z-o-e-v-a.ru",
    "z86.ru",
    "zarabotaibystro.ru",
    "zarabotati-sei4as.ru",
    "zaym-zaym.ru",
    "ziza.ru",
    "zmail.ru",
    "zurotrinz.ru",
  ],
  ry: ["0815.ry"],
  su: [
    "0815.su",
    "asu.su",
    "email.su",
    "i.klipp.su",
    "klipp.su",
    "krovatka.su",
    "spam.su",
    "student.su",
  ],
  org: [
    "0nly.org",
    "chongsoft.org",
    "dx.awiki.org",
    "endrix.org",
    "garbagemail.org",
    "is-very-evil.org",
    "jpco.org",
    "servegame.org",
    "0ak.org",
    "0clock.org",
    "0hio.org",
    "0ils.org",
    "0live.org",
    "0nes.org",
    "0regon.org",
    "0wnd.org",
    "10mail.org",
    "10minutemail.org",
    "123mail.org",
    "1secmail.org",
    "1to1mail.org",
    "20boxme.org",
    "2sea.org",
    "42o.org",
    "4dm.4dy.org",
    "4warding.org",
    "510520.org",
    "5july.org",
    "675hosting.org",
    "688as.org",
    "75hosting.org",
    "a-player.org",
    "aalna.org",
    "adpugh.org",
    "adsd.org",
    "afraid.org",
    "alfamailr.org",
    "allowed.org",
    "allthegoodnamesaretaken.org",
    "alsheim.no-ip.org",
    "amazon-aws.org",
    "anal.accesscam.org",
    "anhthu.org",
    "anonymized.org",
    "anonymousmail.org",
    "antichef.org",
    "aol.org",
    "areyouthere.org",
    "aristotle.org",
    "at0mik.org",
    "austin-njrotc.org",
    "awiki.org",
    "axiz.org",
    "babau.mywire.org",
    "badhus.org",
    "balochistan.org",
    "basscode.org",
    "belamail.org",
    "benipaula.org",
    "blogdns.org",
    "blogmyway.org",
    "blogsite.org",
    "blumail.org",
    "bmpk.org",
    "boldlygoingnowhere.org",
    "borged.org",
    "brasx.org",
    "breeze.eu.org",
    "bspamfree.org",
    "btcc.org",
    "bullbeer.org",
    "buspad.org",
    "buycow.org",
    "buyusedlibrarybooks.org",
    "c.theplug.org",
    "cartelera.org",
    "catholic.org",
    "childsavetrust.org",
    "choirschool.org",
    "chong-mail.org",
    "city-of-bath.org",
    "city-of-brighton.org",
    "cityoflondon.org",
    "civx.org",
    "cl-cl.org",
    "clubmember.org",
    "cmail.org",
    "coincal.org",
    "collector.org",
    "comilzilla.org",
    "comm.craigslist.org",
    "compareshippingrates.org",
    "coolimpool.org",
    "corp.ereality.org",
    "cowstore.org",
    "crapmail.org",
    "cuirushi.org",
    "cx.de-a.org",
    "cybertrains.org",
    "cylab.org",
    "darknode.org",
    "ddns.org",
    "de-a.org",
    "deadchildren.org",
    "deadlymob.org",
    "denyfromall.org",
    "dfgggg.org",
    "dgoh.org",
    "disbox.org",
    "disposableemail.org",
    "dnsalias.org",
    "dnsdojo.org",
    "dodgit.org",
    "doesntexist.org",
    "dontexist.org",
    "doomdns.org",
    "downloadeguide.mywire.org",
    "dragons-spirit.org",
    "drop.ekholm.org",
    "dvrdns.org",
    "dx.allowed.org",
    "dynalias.org",
    "dyndns.org",
    "dz-geek.org",
    "e-mail.org",
    "e3b.org",
    "earth.doesntexist.org",
    "ed101.org",
    "elearningjournal.org",
    "elitemail.org",
    "email.freecrypt.org",
    "email.org",
    "emailengine.org",
    "emailll.org",
    "emailplus.org",
    "emailspot.org",
    "endofinternet.org",
    "endoftheinternet.org",
    "enveloped.org",
    "ero-tube.org",
    "ethersports.org",
    "etranquil.org",
    "fadrasha.org",
    "fahr-zur-hoelle.org",
    "faithkills.org",
    "fast-mail.org",
    "fathersrightsne.org",
    "fcrpg.org",
    "fer-gabon.org",
    "fingermouse.org",
    "fishburne.org",
    "flashbox.5july.org",
    "freemymail.org",
    "freeweb.org",
    "from-me.org",
    "front14.org",
    "fsfsdf.org",
    "fsociety.org",
    "game-host.org",
    "garbagecollector.org",
    "getamailbox.org",
    "giessdorf.eu.org",
    "gigileung.org",
    "gigs.craigslist.org",
    "ginzy.org",
    "glay.org",
    "gmatch.org",
    "go.dyndns.org",
    "gotdns.org",
    "gotmail.org",
    "graduate.org",
    "gs-arc.org",
    "gsredcross.org",
    "guerillamail.org",
    "guerrillamail.org",
    "gynzi.org",
    "h8s.org",
    "hard-life.org",
    "hargaku.org",
    "hatespam.org",
    "hazmatshipping.org",
    "helpinghandtaxcenter.org",
    "hidebox.org",
    "highbros.org",
    "hingis.org",
    "hobby-site.org",
    "hola.org",
    "home.dyndns.org",
    "homedns.org",
    "homeftp.org",
    "homelinux.org",
    "homeunix.org",
    "hopto.org",
    "horsefucker.org",
    "hous.craigslist.org",
    "hths.mcvsd.org",
    "i2pmail.org",
    "idigo.org",
    "iheartspam.org",
    "imail.org",
    "imapmail.org",
    "imel.org",
    "inapplicable.org",
    "inboxclean.org",
    "incognitomail.org",
    "infest.org",
    "inggo.org",
    "internet-mail.org",
    "interstats.org",
    "ipoo.org",
    "ipsur.org",
    "is-a-bruinsfan.org",
    "is-a-candidate.org",
    "is-a-celticsfan.org",
    "is-a-chef.org",
    "is-a-geek.org",
    "is-a-knight.org",
    "is-a-linux-user.org",
    "is-a-patsfan.org",
    "is-a-soxfan.org",
    "is-found.org",
    "is-lost.org",
    "is-saved.org",
    "is-very-bad.org",
    "is-very-good.org",
    "is-very-sweet.org",
    "isa-geek.org",
    "javamail.org",
    "jetable.org",
    "jjmsb.eu.org",
    "job.craigslist.org",
    "josefadventures.org",
    "jp.hopto.org",
    "junk.beats.org",
    "k4ds.org",
    "kabissa.org",
    "kalapi.org",
    "kemulastalk.https443.org",
    "kicks-ass.org",
    "klammlose.org",
    "l6.org",
    "letterboxes.org",
    "linuxmail.org",
    "lndex.org",
    "lnvoke.org",
    "loanins.org",
    "localserv.no-ip.org",
    "longio.org",
    "ltuc.edu.eu.org",
    "luckymail.org",
    "lupabapak.org",
    "m4.org",
    "mail.anhthu.org",
    "mail2rss.org",
    "mail4-us.org",
    "mailadadad.org",
    "mailbucket.org",
    "mailinator.org",
    "mailingaddress.org",
    "mailmetrash.comilzilla.org",
    "mailorg.org",
    "mailworks.org",
    "mailzilla.org",
    "mariah-carey.ml.org",
    "miam.kd2.org",
    "midlertidig.org",
    "misconfused.org",
    "mix-mail.org",
    "moeri.org",
    "mugglenet.org",
    "musician.org",
    "musicscene.org",
    "mustbedestroyed.org",
    "mwarner.org",
    "mymailboxpro.org",
    "myspaceinc.org",
    "n1nja.org",
    "navy.org",
    "nervhq.org",
    "neverbox.org",
    "nezdiro.org",
    "nowhere.org",
    "numail.org",
    "nymathacademy.org",
    "o.idigo.org",
    "o2stk.org",
    "oerpub.org",
    "olatheschools.org",
    "omnievents.org",
    "oopi.org",
    "openmailbox.org",
    "opojare.org",
    "p.k4ds.org",
    "p33.org",
    "pagons.org",
    "pcsb.org",
    "pecinan.org",
    "pers.craigslist.org",
    "pinehill-seattle.org",
    "pleasenoham.org",
    "plez.org",
    "podzone.org",
    "poofy.org",
    "popmailserv.org",
    "posta.rosativa.ro.org",
    "posteo.org",
    "pro-tag.org",
    "purelogistics.org",
    "rainwaterstudios.org",
    "rawhidefc.org",
    "rbb.org",
    "rccgmail.org",
    "readmyblog.org",
    "res.craigslist.org",
    "revolvingdoorhoax.org",
    "rfc822.org",
    "rootprompt.org",
    "royaldoodles.org",
    "saint-mike.org",
    "sale.craigslist.org",
    "sativa.ro.org",
    "schweiz.org",
    "scottsboro.org",
    "sdf.org",
    "sector2.org",
    "selfdestructingmail.org",
    "selfip.org",
    "sellsyourhome.org",
    "send-email.org",
    "sendfree.org",
    "serv.craigslist.org",
    "servebbs.org",
    "serveftp.org",
    "sharedmailbox.org",
    "shhuut.org",
    "shiphazmat.org",
    "shippingterms.org",
    "shitmail.org",
    "smapfree24.org",
    "so-simple.org",
    "socialfurry.org",
    "sonnenkinder.org",
    "spam.ozh.org",
    "spam.pyphus.org",
    "spambob.org",
    "spambox.org",
    "spamcon.org",
    "spamcowboy.org",
    "spameater.org",
    "spamfree24.org",
    "spamgourmet.org",
    "spamspameverywhere.org",
    "speedfreaks.org",
    "speedymail.org",
    "starmail.org",
    "stealthypost.org",
    "studentcenter.org",
    "stuff-4-sale.org",
    "suisse.org",
    "svxr.org",
    "swissinfo.org",
    "switzerland.org",
    "takedowns.org",
    "tanukis.org",
    "teachers.org",
    "tech4peace.org",
    "teewars.org",
    "temp-mail.org",
    "tempemail.org",
    "tempmailid.org",
    "tempmails.org",
    "temporarymail.org",
    "thediamants.org",
    "theplug.org",
    "thexyz.org",
    "thunkinator.org",
    "tinoza.org",
    "tlpn.org",
    "tmpmail.org",
    "topmall.org",
    "tormail.org",
    "trashmail.org",
    "u.2sea.org",
    "ukhotels.org",
    "ukmail.org",
    "ummah.org",
    "unimark.org",
    "upf.org",
    "vaati.org",
    "viewcastmedia.org",
    "viphone.eu.org",
    "viralhits.org",
    "vorga.org",
    "vote4gop.org",
    "votiputox.org",
    "walala.org",
    "wasd.10mail.org",
    "wasteland.rfc822.org",
    "watchfree.org",
    "webadicta.org",
    "webarnak.fr.eu.org",
    "webhop.org",
    "wegwerfemail.org",
    "wegwerfmail.org",
    "wegwrfmail.org",
    "weibsvolk.org",
    "wetrainbayarea.org",
    "wh4f.org",
    "whiffles.org",
    "wir-sind-cool.org",
    "women-at-work.org",
    "worlddonation.org",
    "wpmail.org",
    "wr.moeri.org",
    "xsecurity.org",
    "yedi.org",
    "ymail.org",
    "yood.org",
    "yopmail.org",
    "yourname.ddns.org",
    "ypmail.webarnak.fr.eu.org",
    "zerocrime.org",
    "zhouemail.510520.org",
    "zionweb.org",
    "zoemail.org",
    "zoetropes.org",
    "zomail.org",
  ],
  eu: [
    "mx18.mailr.eu",
    "0box.eu",
    "1email.eu",
    "20email.eu",
    "20mail.eu",
    "20mm.eu",
    "2k18.mailr.eu",
    "30minutenmail.eu",
    "abuser.eu",
    "adiq.eu",
    "assurancespourmoi.eu",
    "axeprim.eu",
    "badgerland.eu",
    "clipmail.eu",
    "cyber-phone.eu",
    "disign-concept.eu",
    "dispomail.eu",
    "dx.abuser.eu",
    "e-postkasten.eu",
    "fornow.eu",
    "freelance-france.eu",
    "getmails.eu",
    "ginzi.eu",
    "ginzy.eu",
    "gynzy.eu",
    "haddo.eu",
    "ieatspam.eu",
    "inoutmail.eu",
    "interia.eu",
    "ioio.eu",
    "jama.trenet.eu",
    "l33r.eu",
    "linuxpl.eu",
    "lixo.loxot.eu",
    "mail.svenz.eu",
    "meepsheep.eu",
    "mx0.wwwnew.eu",
    "mx19.mailr.eu",
    "netc.eu",
    "new.eu",
    "nonspam.eu",
    "onet.eu",
    "photo-impact.eu",
    "poczta.onet.eu",
    "posteo.eu",
    "proxymail.eu",
    "prtz.eu",
    "raikas77.eu",
    "shqiptar.eu",
    "smapfree24.eu",
    "spamfree.eu",
    "spamfree24.eu",
    "squizzy.eu",
    "tempmail.eu",
    "unseen.eu",
    "veryday.eu",
    "web-email.eu",
    "web-emailbox.eu",
    "webcontact-france.eu",
    "wp.eu",
    "wwwnew.eu",
    "z0d.eu",
    "zebins.eu",
  ],
  net: [
    "0nce.net",
    "acninc.net",
    "animal.net",
    "approvers.net",
    "asianwired.net",
    "ballsofsteel.net",
    "barmyarmy.net",
    "brandallday.net",
    "centurytel.net",
    "citiz.net",
    "city-of-york.net",
    "cloud-mail.net",
    "cyber-host.net",
    "dndbs.net",
    "dogsnob.net",
    "earthlink.net",
    "emailuser.net",
    "enetis.net",
    "everyone.net",
    "financemail.net",
    "freecat.net",
    "from-co.net",
    "from-japan.net",
    "frostbyte.uk.net",
    "gaggle.net",
    "gets-it.net",
    "gramszu.net",
    "gti.net",
    "internetmailing.net",
    "ionet.net",
    "islandemail.net",
    "kicks-ass.net",
    "kwilco.net",
    "leehom.net",
    "mailsent.net",
    "mailtrix.net",
    "mongol.net",
    "nativeweb.net",
    "nervtmich.net",
    "novokosino.net",
    "ochakovo.net",
    "omegafive.net",
    "optimum.net",
    "photomark.net",
    "pigpig.net",
    "posta.net",
    "randomail.net",
    "rgphotos.net",
    "rooftest.net",
    "royal.net",
    "scfn.net",
    "siouxvalley.net",
    "snip.net",
    "snowdonia.net",
    "sonic.net",
    "speedpost.net",
    "sqxx.net",
    "suddenlink.net",
    "surfy.net",
    "telkom.net",
    "TempEMail.net",
    "thangberus.net",
    "top-mails.net",
    "tverskoe.net",
    "uk8.net",
    "usma.net",
    "vipxm.net",
    "walkmail.net",
    "wxnw.net",
    "yeppee.net",
    "zdfpost.net",
    "0clock.net",
    "0hio.net",
    "0ils.net",
    "0n0ff.net",
    "0nes.net",
    "0sg.net",
    "0wnd.net",
    "10.dns-cloud.net",
    "10minutemail.net",
    "10minutesmail.net",
    "117.yyolf.net",
    "123.dns-cloud.net",
    "123box.net",
    "123mail.net",
    "126.net",
    "12hosting.net",
    "12minutemail.net",
    "13dk.net",
    "168.net",
    "1mail.net",
    "1me.net",
    "1secmail.net",
    "1website.net",
    "20.dns-cloud.net",
    "2120001.net",
    "24hourmail.net",
    "2911.net",
    "2coolforyou.net",
    "2ether.net",
    "2hotforyou.net",
    "30.dns-cloud.net",
    "3xl.net",
    "444.net",
    "456.dns-cloud.net",
    "4email.net",
    "4warding.net",
    "55hosting.net",
    "672643.net",
    "675hosting.net",
    "75hosting.net",
    "789.dns-cloud.net",
    "8848.net",
    "8xyz8.dynu.net",
    "9ox.net",
    "a-bc.net",
    "a.wxnw.net",
    "a2z4u.net",
    "aamail.net",
    "aaronkwok.net",
    "abb.dns-cloud.net",
    "abcflash.net",
    "access4less.net",
    "ace-mail.net",
    "acmemail.net",
    "acsalaska.net",
    "adelphia.net",
    "adios.net",
    "aegia.net",
    "aegiscorp.net",
    "aesopsfables.net",
    "africamel.net",
    "agtx.net",
    "aiiots.net",
    "aim.net",
    "aircapitol.net",
    "airforce.net",
    "airmail.net",
    "airpost.net",
    "ajaxapp.net",
    "alhilal.net",
    "alice-dsl.net",
    "all-mail.net",
    "allmail.net",
    "allofthem.net",
    "alltel.net",
    "alpha-web.net",
    "altavista.net",
    "ameritech.net",
    "amiri.net",
    "amrer.net",
    "amuro.net",
    "andylau.net",
    "animail.net",
    "animalwoman.net",
    "ano-mail.net",
    "anonbox.net",
    "anonymous-email.net",
    "anonymousfeedback.net",
    "anti-ignorance.net",
    "antichef.net",
    "aol.net",
    "appraiser.net",
    "aquaticmail.net",
    "arabtop.net",
    "army.net",
    "arrl.net",
    "arvig.net",
    "aschenbrandt.net",
    "asdfasdfmail.net",
    "asdfmail.net",
    "asiansonly.net",
    "asiapoint.net",
    "asooemail.net",
    "astrosfan.net",
    "at-band-camp.net",
    "athenachu.net",
    "atlanticbb.net",
    "atmc.net",
    "att.net",
    "attglobal.net",
    "auctioneer.net",
    "austrosearch.net",
    "awsom.net",
    "axsup.net",
    "b2bx.net",
    "bakpaka.net",
    "ballerstatus.net",
    "bannertown.net",
    "bareck.net",
    "barlick.net",
    "bartender.net",
    "batuta.net",
    "bcpl.net",
    "bd.dns-cloud.net",
    "beck-it.net",
    "belizemail.net",
    "bell.net",
    "bellair.net",
    "bellaliant.net",
    "bellatlantic.net",
    "bellsouth.net",
    "best-mail.net",
    "bestshopcoupon.net",
    "bestweb.net",
    "bettergolf.net",
    "bev.net",
    "bigmir.net",
    "bigpond.net",
    "bikeracers.net",
    "billsfan.net",
    "bimla.net",
    "bio-muesli.net",
    "birdowner.net",
    "bitpage.net",
    "blacksburg.net",
    "bladesmail.net",
    "blazenet.net",
    "blink182.net",
    "blnkt.net",
    "blogdns.net",
    "blogos.net",
    "bmaker.net",
    "bmlsports.net",
    "bonbon.net",
    "borged.net",
    "bounce.net",
    "boyracer.net",
    "brainonfire.net",
    "brasilia.net",
    "bredband.net",
    "bresnan.net",
    "bright.net",
    "broadcast.net",
    "broke-it.net",
    "brostream.net",
    "brujula.net",
    "bsnow.net",
    "bullbeer.net",
    "bum.net",
    "bunita.net",
    "businessman.net",
    "businesssource.net",
    "bussitussi.net",
    "butch-femme.net",
    "butovo.net",
    "buyshouses.net",
    "bwa33.net",
    "c.hcac.net",
    "c2i.net",
    "cableone.net",
    "cachedot.net",
    "cais.net",
    "callsign.net",
    "canggih.net",
    "cantv.net",
    "capu.net",
    "carbtc.net",
    "carioca.net",
    "cbes.net",
    "cem.net",
    "centermail.net",
    "centurylink.net",
    "chacuo.net",
    "chalkmail.net",
    "chandrasekar.net",
    "charm.net",
    "charter.net",
    "cheaphub.net",
    "chef.net",
    "chelseafans.net",
    "chong-mail.net",
    "christianmail.net",
    "cincinow.net",
    "citeweb.net",
    "citlink.net",
    "city-of-westminster.net",
    "cityofcardiff.net",
    "cl0ne.net",
    "clds.net",
    "clearwire.net",
    "click-mail.net",
    "clickanerd.net",
    "cliptik.net",
    "close2you.net",
    "clovermail.net",
    "club4x4.net",
    "clubbers.net",
    "clubhonda.net",
    "clubmercedes.net",
    "clubrenault.net",
    "clubvdo.net",
    "cmail.net",
    "cnmsg.net",
    "coapp.net",
    "coastalnow.net",
    "columbiacable.net",
    "comast.net",
    "comcast.net",
    "comporium.net",
    "computer-expert.net",
    "computermail.net",
    "comsafe-mail.net",
    "concentric.net",
    "connect4free.net",
    "contractor.net",
    "coolmail.net",
    "coolsite.net",
    "cooperation.net",
    "cooperationtogo.net",
    "copper.net",
    "correo.blogos.net",
    "cortex.kicks-ass.net",
    "cotas.net",
    "covad.net",
    "cowstore.net",
    "cox.net",
    "coxinet.net",
    "cpaonline.net",
    "crap.kakadua.net",
    "crosslink.net",
    "crosswinds.net",
    "cumangeblog.net",
    "cyber-africa.net",
    "cybercity-online.net",
    "cyberforeplay.net",
    "cybermail.net",
    "cymail.net",
    "dabsol.net",
    "dahongying.net",
    "dammexe.net",
    "daryxfox.net",
    "daum.net",
    "davidkoh.net",
    "dca.net",
    "dcsi.net",
    "de.sytes.net",
    "dea-love.net",
    "deafemail.net",
    "deepseafisherman.net",
    "degradedfun.net",
    "delayload.net",
    "deneg.net",
    "derder.net",
    "dff55.dynu.net",
    "dfgh.net",
    "dharmatel.net",
    "die-optimisten.net",
    "digital-work.net",
    "digitalforeplay.net",
    "diodecom.net",
    "dioscolwedddas.3-a.net",
    "directmail24.net",
    "disbox.net",
    "discoverymail.net",
    "dishmail.net",
    "disinfo.net",
    "dlemail.net",
    "dmail.kyty.net",
    "dmitrovka.net",
    "dnainternet.net",
    "dns-cloud.net",
    "dnsalias.net",
    "dnsdojo.net",
    "doar.net",
    "doc-mail.net",
    "does-it.net",
    "doge-mail.net",
    "dolphinnet.net",
    "donegal.net",
    "dontexist.net",
    "dontmail.net",
    "dota2bets.net",
    "downloadarea.net",
    "doxcity.net",
    "dragoncon.net",
    "drdrb.net",
    "duam.net",
    "dumoac.net",
    "dvd-fan.net",
    "dvd.dns-cloud.net",
    "dvdcloset.net",
    "dwp.net",
    "dynalias.net",
    "dynathome.net",
    "dynu.net",
    "dz17.net",
    "e-mail.net",
    "earthcam.net",
    "earthling.net",
    "earthonline.net",
    "eastwan.net",
    "ebprofits.net",
    "ecbsolutions.net",
    "edge.net",
    "eircom.net",
    "emadisonriver.net",
    "email-sms.net",
    "email.cbes.net",
    "email.net",
    "email2me.net",
    "emailasso.net",
    "emailcorner.net",
    "emailengine.net",
    "emailforyou.net",
    "emailgroups.net",
    "emailnode.net",
    "emailsingularity.net",
    "emailtemporanea.net",
    "emailthe.net",
    "emailure.net",
    "emailx.net",
    "emz.net",
    "endofinternet.net",
    "enel.net",
    "enelpunto.net",
    "enter.net",
    "ephemail.net",
    "epix.net",
    "eset.t28.net",
    "eshche.net",
    "esmailweb.net",
    "ether123.net",
    "etranquil.net",
    "europamel.net",
    "every1.net",
    "everymail.net",
    "examnotes.net",
    "existiert.net",
    "exitstageleft.net",
    "expirebox.net",
    "extenda.net",
    "ezy.net",
    "f1fans.net",
    "fadrasha.net",
    "fakemailgenerator.net",
    "farang.net",
    "fastemailextractor.net",
    "fastmail.net",
    "fastmailbox.net",
    "fastmailforyou.net",
    "fatcock.net",
    "fcc.net",
    "fepg.net",
    "fghmail.net",
    "fificorp.net",
    "financefan.net",
    "fireman.net",
    "first-email.net",
    "fitnessmail.net",
    "five-plus.net",
    "flashcom.net",
    "flashmail.net",
    "floridaemail.net",
    "flyinggeek.net",
    "fnzm.net",
    "foobarbot.net",
    "foothills.net",
    "forspam.net",
    "free-online.net",
    "freebullets.net",
    "freehotmail.net",
    "freemail.tweakly.net",
    "freemailnow.net",
    "freeola.net",
    "freetmail.net",
    "freeuk.net",
    "friction.net",
    "from-az.net",
    "from-china.net",
    "from-france.net",
    "from-germany.net",
    "from-italy.net",
    "from-la.net",
    "from-ny.net",
    "from-spain.net",
    "fromflorida.net",
    "fromhawaii.net",
    "frominter.net",
    "frommaine.net",
    "fromnewyork.net",
    "fromoregon.net",
    "frontiernet.net",
    "fsmail.net",
    "ftc-i.net",
    "fullchannel.net",
    "fun64.net",
    "fuse.net",
    "gafy.net",
    "galamb.net",
    "gallatinriver.net",
    "gamebox.net",
    "gateway.net",
    "gaza.net",
    "gci.net",
    "gdi.net",
    "genderfuck.net",
    "geography.net",
    "geroev.net",
    "getapet.net",
    "getonemail.net",
    "getvmail.net",
    "gibtelecom.net",
    "gilray.net",
    "ginzi.net",
    "glendale.net",
    "globaleuro.net",
    "globetrotter.net",
    "gmx.dns-cloud.net",
    "gmx.net",
    "go2vpn.net",
    "gobrainstorm.net",
    "godaddyrenewalcoupon.net",
    "goldenwest.net",
    "goldinbox.net",
    "golidi.net",
    "gonavy.net",
    "gorontalo.net",
    "gotmail.net",
    "graffiti.net",
    "greenmail.net",
    "greggamel.net",
    "gregorygamel.net",
    "grics.net",
    "gtemail.net",
    "gtmc.net",
    "guerillamail.net",
    "guerrillamail.net",
    "guju.net",
    "gwtc.net",
    "habitue.net",
    "hackermail.net",
    "hailmail.net",
    "hairdresser.net",
    "ham-radio-op.net",
    "hanmail.net",
    "haribu.net",
    "hawaiiantel.net",
    "hbo.dns-cloud.net",
    "hcac.net",
    "healthemail.net",
    "heartofiowa.net",
    "heavynoize.net",
    "heesun.net",
    "hitthe.net",
    "hkg.net",
    "home.no.net",
    "homeftp.net",
    "homeip.net",
    "homelinux.net",
    "homeunix.net",
    "hookup.net",
    "horizonview.net",
    "hostmonitor.net",
    "hotfire.net",
    "hsuchi.net",
    "hughes.net",
    "humanoid.net",
    "hurt.net",
    "huskion.net",
    "hwsye.net",
    "i-mailbox.net",
    "i-plus.net",
    "ibm.net",
    "ilovegiraffes.net",
    "ilovetocollect.net",
    "in-box.net",
    "in-the-band.net",
    "inbox.net",
    "inboxhub.net",
    "incognitomail.net",
    "ineec.net",
    "information-account.net",
    "inoutmail.net",
    "insidebaltimore.net",
    "instructor.net",
    "integra.net",
    "internetemails.net",
    "internode.on.net",
    "ionemail.net",
    "irangate.net",
    "irishhome.net",
    "is-a-chef.net",
    "is-a-geek.net",
    "isa-geek.net",
    "islamonline.net",
    "ismart.net",
    "isp9.net",
    "ist-genial.net",
    "it-simple.net",
    "iwi.net",
    "jetable.net",
    "jetemail.net",
    "jglopez.net",
    "jingjo.net",
    "jobposts.net",
    "jobs-to-be-done.net",
    "justemail.net",
    "justonemail.net",
    "kaduku.net",
    "kakadua.net",
    "kanokla.net",
    "kazper.net",
    "key-mail.net",
    "kickmarx.net",
    "killmail.net",
    "kinglibrary.net",
    "klassmaster.net",
    "knology.net",
    "konkovo.net",
    "kopaka.net",
    "kosino.net",
    "krovatka.net",
    "kuzminki.net",
    "kwift.net",
    "kyokofukada.net",
    "l0real.net",
    "lackmail.net",
    "lagugratis.net",
    "lantic.net",
    "laposte.net",
    "lawlz.net",
    "ldaho.net",
    "learn2compute.net",
    "leeching.net",
    "lefortovo.net",
    "leonlai.net",
    "letsgomets.net",
    "lianozovo.net",
    "lillemap.net",
    "lineone.net",
    "link2mail.net",
    "linshiyouxiang.net",
    "liquidinformation.net",
    "ll47.net",
    "llogin.net",
    "lnvoke.net",
    "locomodev.net",
    "lolfreak.net",
    "lolnetwork.net",
    "lovelygirl.net",
    "lovetocook.net",
    "m.convulse.net",
    "magspam.net",
    "mail-apps.net",
    "mail-click.net",
    "mail-finder.net",
    "mail-group.net",
    "mail-point.net",
    "mail-space.net",
    "mail.jpgames.net",
    "mail.mezimages.net",
    "mail.salu.net",
    "mail114.net",
    "mailbomb.net",
    "mailbox.r2.dns-cloud.net",
    "mailc.net",
    "mailclub.net",
    "mailforce.net",
    "mailgenie.net",
    "mailimails.patzleiner.net",
    "mailinator.net",
    "mailinator2.net",
    "mailline.net",
    "mailpremium.net",
    "mailsearch.net",
    "mailsnare.net",
    "mailsucker.net",
    "mailtemp.net",
    "mailtrash.net",
    "mailtv.net",
    "mailup.net",
    "malayalamtelevision.net",
    "mamber.net",
    "mancity.net",
    "manlymail.net",
    "marfino.net",
    "martinguerre.net",
    "maskmail.net",
    "mastahype.net",
    "master-mail.net",
    "matchpol.net",
    "mcache.net",
    "mciworldcom.net",
    "mcst-sd.net",
    "md5hashing.net",
    "meatismurder.net",
    "mebtel.net",
    "medione.net",
    "megapathdsl.net",
    "meltel.net",
    "memeware.net",
    "menja.net",
    "mesra.net",
    "messageden.net",
    "metroset.net",
    "mezimages.net",
    "mhwolf.net",
    "micsocks.net",
    "midcoastcustoms.net",
    "midcoastsolutions.net",
    "midlertidig.net",
    "migmail.net",
    "mini-mail.net",
    "miraigames.net",
    "misery.net",
    "ml1.net",
    "mlb.bounce.ed10.net",
    "mo3gov.net",
    "mobileemail.vodafone.net",
    "modemnet.net",
    "momo365.net",
    "money.net",
    "moneypipe.net",
    "morecoolstuff.net",
    "motorcyclefan.net",
    "mountainregionallibrary.net",
    "movieemail.net",
    "moyy.net",
    "mozhno.net",
    "mp3geulis.net",
    "mp3nt.net",
    "mundomail.net",
    "muttwalker.net",
    "mvrht.net",
    "mx.dysaniac.net",
    "mxbin.net",
    "mxp.dns-cloud.net",
    "my.longaid.net",
    "myactv.net",
    "mycasualclothing.net",
    "mycleaninbox.net",
    "mycorneroftheinter.net",
    "myfairpoint.net",
    "mymail-in.net",
    "myn4s.ddns.net",
    "myotw.net",
    "mypacks.net",
    "myspaceinc.net",
    "mythrashmail.net",
    "mytop-in.net",
    "mywarnernet.net",
    "nagpal.net",
    "naplesnews.net",
    "nativestar.net",
    "naui.net",
    "nauticom.net",
    "neeva.net",
    "neko2.net",
    "nekto.net",
    "nervmich.net",
    "net-pager.net",
    "netcenter-vn.net",
    "netdove.net",
    "netins.net",
    "netmail3.net",
    "netmails.net",
    "netnoir.net",
    "netposta.net",
    "netris.net",
    "netscape.net",
    "netsero.net",
    "netzero.net",
    "neverbox.net",
    "newarbat.net",
    "newmail.net",
    "newyorkskyride.net",
    "nextemail.net",
    "nfast.net",
    "nicholastse.net",
    "nikulino.net",
    "niwl.net",
    "nl.szucsati.net",
    "nnot.net",
    "northgates.net",
    "nospam.barbees.net",
    "nospammail.net",
    "novogireevo.net",
    "nowmymail.net",
    "ntelos.net",
    "null.net",
    "nurdea.net",
    "nutpa.net",
    "nwldx.net",
    "nwytg.net",
    "nxbrasil.net",
    "o7i.net",
    "oceanfree.net",
    "office-on-the.net",
    "offshore-proxies.net",
    "ok.net",
    "okname.net",
    "olympist.net",
    "omeaaa124.ddns.net",
    "omi4.net",
    "onemoremail.net",
    "open.brainonfire.net",
    "optician.net",
    "optonline.net",
    "orange.net",
    "ordinaryamerican.net",
    "orgmail.net",
    "orthodontist.net",
    "otherdog.net",
    "outlookpro.net",
    "ownmail.net",
    "pacbell.net",
    "parcel4.net",
    "patmedia.net",
    "patra.net",
    "pconnections.net",
    "pecinan.net",
    "pemail.net",
    "perovo.net",
    "petrzilka.net",
    "phone.net",
    "photographer.net",
    "phreaker.net",
    "physicist.net",
    "pigmail.net",
    "piracha.net",
    "pisem.net",
    "planetarymotion.net",
    "planetearthinter.net",
    "planetmail.net",
    "pmail.net",
    "podzone.net",
    "poisontech.net",
    "pokemail.net",
    "populus.net",
    "porsh.net",
    "post0.profimedia.net",
    "postemail.net",
    "posteo.net",
    "postmark.net",
    "postpro.net",
    "presnya.net",
    "primabananen.net",
    "privacy.net",
    "privatdemail.net",
    "prodigy.net",
    "programmer.net",
    "prontomail.compopulus.net",
    "provmail.net",
    "psi.net",
    "ptd.net",
    "pulwarm.net",
    "put2.net",
    "pw.r4.dns-cloud.net",
    "q314.net",
    "qipmail.net",
    "qis.net",
    "qt1.ddns.net",
    "qwest.net",
    "qwestoffice.net",
    "r4.dns-cloud.net",
    "radiologist.net",
    "rancidhome.net",
    "rastogi.net",
    "rcs.gaggle.net",
    "realemail.net",
    "realestatemail.net",
    "recursor.net",
    "reftoken.net",
    "regbypass.comsafe-mail.net",
    "rev-zone.net",
    "rgb9000.net",
    "rmqkr.net",
    "ronnierage.net",
    "rootfest.net",
    "royal-soft.net",
    "rtotlmail.net",
    "ryumail.net",
    "s0ny.net",
    "safe-mail.net",
    "saintmail.net",
    "salehi.net",
    "salesperson.net",
    "samilan.net",
    "samiznaetekogo.net",
    "sandcars.net",
    "sanim.net",
    "sayhi.net",
    "sbcglbal.net",
    "sbcglobal.net",
    "scay.net",
    "sccoast.net",
    "scrapper-site.net",
    "secretary.net",
    "secretservices.net",
    "secured-link.net",
    "segalnet.net",
    "selfip.net",
    "sellcow.net",
    "sells-it.net",
    "servebbs.net",
    "serveftp.net",
    "servermaps.net",
    "sex.dns-cloud.net",
    "sfgov.net",
    "shahweb.net",
    "shalar.net",
    "shayzam.net",
    "shellov.net",
    "shit.dns-cloud.net",
    "shop4mail.net",
    "shoproyal.net",
    "shortmail.net",
    "sigaret.net",
    "silkroad.net",
    "sio.midco.net",
    "siteposter.net",
    "skyinet.net",
    "slave-auctions.net",
    "slipry.net",
    "slo.net",
    "slothmail.net",
    "smapxsmap.net",
    "smileyface.comsmithemail.net",
    "smithemail.net",
    "smoug.net",
    "snail-mail.net",
    "sndt.net",
    "snet.net",
    "snowcrest.net",
    "socceramerica.net",
    "socialworker.net",
    "softhome.net",
    "sohu.net",
    "sokolniki.net",
    "songwriter.net",
    "sovsem.net",
    "spam.deluser.net",
    "spam.netpirates.net",
    "spam.visuao.net",
    "spambob.net",
    "spambog.net",
    "spamcannon.net",
    "spamcowboy.net",
    "spamdecoy.net",
    "spamfree24.net",
    "spamgourmet.net",
    "spamlot.net",
    "spammehere.net",
    "spamstack.net",
    "spamtroll.net",
    "speedemail.net",
    "speedgaus.net",
    "sperke.net",
    "squizzy.net",
    "srilankan.net",
    "st-davids.net",
    "standalone.net",
    "starlight-breaker.net",
    "starpower.net",
    "stathost.net",
    "steambot.net",
    "stinkefinger.net",
    "sudanmail.net",
    "sudolife.net",
    "sudomail.net",
    "sudoverse.net",
    "sudoworld.net",
    "sukhumvit.net",
    "sullivanemail.net",
    "sunpoint.net",
    "suratku.dynu.net",
    "surf3.net",
    "surfsupnet.net",
    "surgical.net",
    "surveyrnonkey.net",
    "suzukilab.net",
    "sviblovo.net",
    "swbell.net",
    "sweetville.net",
    "swissmail.net",
    "swva.net",
    "szucsati.net",
    "takoe.net",
    "talktalk.net",
    "tb-on-line.net",
    "tds.net",
    "teachermail.net",
    "teamtulsa.net",
    "telebot.net",
    "telefonica.net",
    "telinco.net",
    "telkomsa.net",
    "telpage.net",
    "telus.net",
    "temp-mail.net",
    "temp.wheezer.net",
    "tempemail.net",
    "tempmailid.net",
    "temporaryemail.net",
    "tempthe.net",
    "temtulsa.net",
    "tennisfans.net",
    "terbuny.net",
    "tesco.net",
    "tfbnw.net",
    "tfz.net",
    "thaimail.net",
    "the-fastest.net",
    "the-galaxy.net",
    "the-newsletter.net",
    "the-student.net",
    "the-whitehouse.net",
    "thelightningmail.net",
    "themailpro.net",
    "themillionare.net",
    "theoffice.net",
    "thepostmaster.net",
    "therapist.net",
    "thexyz.net",
    "thezhangs.net",
    "thismail.net",
    "thruhere.net",
    "tiberia.net",
    "tifosi.net",
    "timein.net",
    "tmailcloud.net",
    "tmails.net",
    "tmicha.net",
    "tmpmail.net",
    "top-mailer.net",
    "topmum.net",
    "tormail.net",
    "tot.net",
    "totalmusic.net",
    "toughguy.net",
    "trash-mail.net",
    "trashinbox.net",
    "trashmail.net",
    "trashymail.net",
    "trevas.net",
    "trickmail.net",
    "tritium.net",
    "trjam.net",
    "truckeremail.net",
    "tulipsmail.net",
    "tushino.net",
    "tverskie.net",
    "tweakly.net",
    "twocowmail.net",
    "ubismail.net",
    "ubuntu.dns-cloud.net",
    "ugimail.net",
    "uk2.net",
    "uk7.net",
    "ukbikers.net",
    "ukhome.net",
    "ukr.net",
    "ukrpost.net",
    "umail.net",
    "undeva.net",
    "usa.net",
    "usaaccess.net",
    "usako.net",
    "users.sourceforge.net",
    "usit.net",
    "usmc.net",
    "uswestmail.net",
    "uu.net",
    "uzhe.net",
    "valemail.net",
    "valudeal.net",
    "verizon.net",
    "vermutlich.net",
    "veryspeedy.net",
    "vfemail.net",
    "viewcastmedia.net",
    "vip.citiz.net",
    "vip.sohu.net",
    "vipsohu.net",
    "virgin.net",
    "visa.dns-cloud.net",
    "vivianhsu.net",
    "vmailpro.net",
    "vncoders.net",
    "vnet.citiz.net",
    "vnukovo.net",
    "vps911.net",
    "vyhino.net",
    "wales2000.net",
    "wamail.net",
    "warpmail.net",
    "watchfull.net",
    "wbml.net",
    "wearab.net",
    "web-experts.net",
    "webhop.net",
    "webmail.bellsouth.net",
    "webmail.kolmpuu.net",
    "webstable.net",
    "webtribe.net",
    "webtv.net",
    "wegwerf-email.net",
    "wegwerfemail.net",
    "wegwerfmail.net",
    "wegwrfmail.net",
    "wellhungup.dynu.net",
    "whidbey.net",
    "wickmail.net",
    "wildblue.net",
    "windrivers.net",
    "windstream.net",
    "wishan.net",
    "wolke7.net",
    "women-only.net",
    "worldnet.att.net",
    "wraped.net",
    "wrexham.net",
    "wuzup.net",
    "wuzupmail.net",
    "www2000.net",
    "wx88.net",
    "wxs.net",
    "wyvernia.net",
    "x-mail.net",
    "x-networks.net",
    "xcodes.net",
    "xn--d-bga.net",
    "xoxy.net",
    "xwaretech.net",
    "xxx.sytes.net",
    "xyzfree.net",
    "yahoo.net",
    "yahooproduct.net",
    "yapped.net",
    "yeah.net",
    "yepmail.net",
    "yesaccounts.net",
    "yesey.net",
    "ygroupvideoarchive.net",
    "yifan.net",
    "ymail.net",
    "yopmail.net",
    "yourlover.net",
    "yourteacher.net",
    "youvegotmail.net",
    "youzend.net",
    "yuuhuu.net",
    "yx.dns-cloud.net",
    "yxzx.net",
    "yyolf.net",
    "yytv.ddns.net",
    "zainmax.net",
    "zdenka.net",
    "zdorovja.net",
    "zensearch.net",
    "zhaowei.net",
    "zip.net",
    "ziplink.net",
    "zoemail.net",
    "zoneview.net",
    "zoominternet.net",
  ],
  info: [
    "adjun.info",
    "barrell-of-knowledge.info",
    "groks-this.info",
    "royalhost.info",
    "0mixmail.info",
    "0x207.info",
    "10minutemail.info",
    "10vpn.info",
    "12ab.info",
    "1webmail.info",
    "2wc.info",
    "300book.info",
    "5-mail.info",
    "5music.info",
    "69postix.info",
    "7ddf32e.info",
    "7rtay.info",
    "abilityskillup.info",
    "accesorii.info",
    "acemail.info",
    "aditus.info",
    "adubiz.info",
    "affiliate-nebenjob.info",
    "ai.hsfz.info",
    "alexbrowne.info",
    "astroempires.info",
    "ausgefallen.info",
    "backalleybowling.info",
    "barrel-of-knowledge.info",
    "bbbbyyzz.info",
    "betterlink.info",
    "bibbiasary.info",
    "bigua.info",
    "bio-muesli.info",
    "bioauto.info",
    "bizsearch.info",
    "bluedumpling.info",
    "bnyzw.info",
    "bobmail.info",
    "bogotadc.info",
    "boopmail.info",
    "burnthespam.info",
    "burstmail.info",
    "business-agent.info",
    "buyordie.info",
    "camping-grill.info",
    "canhoehome4.info",
    "centermail.info",
    "chammy.info",
    "clearwatermail.info",
    "clickmail.info",
    "cloudstreaming.info",
    "clutunpodli.ddns.info",
    "coldemail.info",
    "crazymail.info",
    "crypemail.info",
    "daawah.info",
    "daibond.info",
    "datab.info",
    "digitalesbusiness.info",
    "digitalmail.info",
    "direct-mail.info",
    "disario.info",
    "doctorlane.info",
    "dtools.info",
    "dump-email.info",
    "e-postkasten.info",
    "eastmail.info",
    "easy-apps.info",
    "easyemail.info",
    "easynetwork.info",
    "email-host.info",
    "email-server.info",
    "email4u.info",
    "emailapps.info",
    "emailmenow.info",
    "emailportal.info",
    "emailsy.info",
    "emailtech.info",
    "eth2btc.info",
    "ethersportz.info",
    "evanfox.info",
    "fakeinbox.info",
    "fast-email.info",
    "fastair.info",
    "findemail.info",
    "first-mail.info",
    "for-our.info",
    "foxtrotter.info",
    "fr33mail.info",
    "freedom4you.info",
    "from.onmypc.info",
    "funnysmell.info",
    "futureof2019.info",
    "getcoolmail.info",
    "giacmosuaviet.info",
    "giveh2o.info",
    "go2usa.info",
    "golfilla.info",
    "gomailbox.info",
    "goround.info",
    "greenst.info",
    "groks-the.info",
    "guerillamail.info",
    "guerrillamail.info",
    "gynzy.info",
    "here-for-more.info",
    "hostguru.info",
    "hotmail.info",
    "hotmailpro.info",
    "hotmessage.info",
    "huachichi.info",
    "ieatspam.info",
    "ihateyoualot.info",
    "ihaxyour.info",
    "ikaza.info",
    "imails.info",
    "immo-gerance.info",
    "imul.info",
    "inbox2.info",
    "inoutmail.info",
    "insorg-mail.info",
    "instantblingmail.info",
    "insuranceonlinequotes.info",
    "iphonees.info",
    "ist-allein.info",
    "ist-genial.info",
    "je-recycle.info",
    "joseihorumon.info",
    "kappala.info",
    "knowsitall.info",
    "kochkurse-online.info",
    "kotsu01.info",
    "kulturbetrieb.info",
    "kumail8.info",
    "lacto.info",
    "lami4you.info",
    "libertymail.info",
    "liebt-dich.info",
    "lifetimefriends.info",
    "lukemail.info",
    "m4ilweb.info",
    "macromice.info",
    "maidlow.info",
    "mail-hub.info",
    "mail-pro.info",
    "mail4u.info",
    "mailde.info",
    "mailgov.info",
    "mailinator.info",
    "maillink.info",
    "mailsource.info",
    "mailtemp.info",
    "mailw.info",
    "mailz.info",
    "mainerfolg.info",
    "marketlink.info",
    "masonline.info",
    "max-mail.info",
    "maxmail.info",
    "mebelnu.info",
    "meine-dateien.info",
    "meine-fotos.info",
    "mfsa.info",
    "mrmail.info",
    "myfavorite.info",
    "mygeoweb.info",
    "mylapak.info",
    "nawmin.info",
    "net-solution.info",
    "netmails.info",
    "networkapps.info",
    "newideasfornewpeople.info",
    "next-mail.info",
    "next2cloud.info",
    "nextmail.info",
    "nhatdinhmuaduocxe.info",
    "nic.info",
    "nice-tits.info",
    "nogmailspam.info",
    "nospamthanks.info",
    "notsharingmy.info",
    "nullbox.info",
    "o22.info",
    "one2mail.info",
    "onlatedotcom.info",
    "onlineidea.info",
    "onlinenet.info",
    "prydirect.info",
    "quick-mail.info",
    "quickemail.info",
    "qv7.info",
    "reallyfast.info",
    "red-mail.info",
    "resolution4print.info",
    "royalgifts.info",
    "safermail.info",
    "safetymail.info",
    "salonyfryzjerskie.info",
    "samsclass.info",
    "secbadger.info",
    "secure-box.info",
    "selfip.info",
    "send22u.info",
    "shedplan.info",
    "shonky.info",
    "simpleemail.info",
    "simpleitsecurity.info",
    "smapfree24.info",
    "smart-mail.info",
    "smwg.info",
    "socialxbounty.info",
    "solvemail.info",
    "spambox.info",
    "spamfree24.info",
    "spamkill.info",
    "stattech.info",
    "stelliteop.info",
    "suremail.info",
    "t-online.info",
    "techfevo.info",
    "techmail.info",
    "technoinsights.info",
    "tehoopcut.info",
    "temp-mail.info",
    "tempcloud.info",
    "tempemail.info",
    "thanksnospam.info",
    "thatim.info",
    "the2012riots.info",
    "thedirhq.info",
    "theteastory.info",
    "tmpeml.info",
    "top9appz.info",
    "topmailer.info",
    "topmall.info",
    "totoan.info",
    "tradermail.info",
    "trgovinanaveliko.info",
    "tropicalbass.info",
    "ttxcom.info",
    "uaix.info",
    "upozowac.info",
    "utilities-online.info",
    "veryday.info",
    "virtualemail.info",
    "vmailing.info",
    "vuabai.info",
    "web-contact.info",
    "webgmail.info",
    "webhop.info",
    "webkiff.info",
    "webm4il.info",
    "wegwerfemail.info",
    "wegwerfmail.info",
    "widaryanto.info",
    "winemaven.info",
    "wollan.info",
    "wpower.info",
    "xwaretech.info",
    "ya.yomail.info",
    "yomail.info",
    "yopmail.info",
    "youcankeepit.info",
    "youneedmore.info",
    "yyt.resolution4print.info",
    "zasve.info",
    "zippymail.info",
    "zomg.info",
    "zonemail.info",
  ],
  ro: [
    "0w.ro",
    "e.amav.ro",
    "o.spamtrap.ro",
    "u.dmarc.ro",
    "0u.ro",
    "0v.ro",
    "4pet.ro",
    "9q.ro",
    "agger.ro",
    "amav.ro",
    "andreihusanu.ro",
    "anit.ro",
    "bcb.ro",
    "bgx.ro",
    "blogspam.ro",
    "bloq.ro",
    "bumerang.ro",
    "c.andreihusanu.ro",
    "c.wlist.ro",
    "cartestraina.ro",
    "cfo2go.ro",
    "civvic.ro",
    "codec.ro",
    "codec.ro.ro",
    "codec.roemail.ro",
    "coza.ro",
    "csh.ro",
    "dab.ro",
    "dextm.ro",
    "dhm.ro",
    "dmarc.ro",
    "dnses.ro",
    "e.4pet.ro",
    "e.blogspam.ro",
    "e.milavitsaromania.ro",
    "email.ro",
    "emailtemporar.ro",
    "epb.ro",
    "eww.ro",
    "g.ycn.ro",
    "go.ro",
    "gynzy.ro",
    "gzb.ro",
    "hmh.ro",
    "home.ro",
    "hotmail.ro",
    "i.istii.ro",
    "i.xcode.ro",
    "icx.ro",
    "istii.ro",
    "j.rvb.ro",
    "jdz.ro",
    "jmail.ro",
    "jsonp.ro",
    "k.ro",
    "linux.7m.ro",
    "magicbox.ro",
    "mailed.ro",
    "milavitsaromania.ro",
    "mji.ro",
    "mymail.ro",
    "nh3.ro",
    "nonze.ro",
    "o.cfo2go.ro",
    "o.muti.ro",
    "o.opendns.ro",
    "opendns.ro",
    "p.9q.ro",
    "personal.ro",
    "posta.ro",
    "posteo.ro",
    "printesamargareta.ro",
    "proprietativalcea.ro",
    "qsl.ro",
    "rol.ro",
    "rvb.ro",
    "s.bloq.ro",
    "s.dextm.ro",
    "s.proprietativalcea.ro",
    "sast.ro",
    "smsforum.ro",
    "spamtrap.ro",
    "tefl.ro",
    "thex.ro",
    "tyhe.ro",
    "u.0u.ro",
    "u.civvic.ro",
    "v.0v.ro",
    "v.jsonp.ro",
    "vda.ro",
    "w.0w.ro",
    "wlist.ro",
    "xcode.ro",
    "xww.ro",
    "y.bcb.ro",
    "yahoo.ro",
    "ycn.ro",
    "yodx.ro",
    "yoo.ro",
    "ywoe@mailed.ro",
  ],
  name: [
    "forgot.his.name",
    "0x00.name",
    "forgot.her.name",
    "jeodumifi.ns3.name",
    "powered.name",
    "sanporeta.ddns.name",
    "shut.name",
    "u1.myftp.name",
    "vipmail.name",
  ],
  stream: [
    "1000rebates.stream",
    "2000rebates.stream",
    "hdfshsh.stream",
    "indoserver.stream",
    "lolllipop.stream",
    "rebates.stream",
    "wordme.stream",
  ],
  xyz: [
    "100kti.xyz",
    "10minut.xyz",
    "11top.xyz",
    "1secmail.xyz",
    "2nd-mail.xyz",
    "2sea.xyz",
    "45kti.xyz",
    "5mails.xyz",
    "7mail.xyz",
    "99email.xyz",
    "agustusmp3.xyz",
    "aistis.xyz",
    "akademiyauspexa.xyz",
    "alimunjaya.xyz",
    "alumnimp3.xyz",
    "anonmail.xyz",
    "bigwiki.xyz",
    "bopra.xyz",
    "btstr.lowbi.xyz",
    "businesscredit.xyz",
    "cafecar.xyz",
    "catchmeifyoucan.xyz",
    "central-servers.xyz",
    "civilizationdesign.xyz",
    "clay.xyz",
    "cognitiveways.xyz",
    "communitybuildingworks.xyz",
    "digdown.xyz",
    "dispomail.xyz",
    "doctordieu.xyz",
    "doughmaine.xyz",
    "edrishn.xyz",
    "emailinbox.xyz",
    "emohawk.xyz",
    "fasty.xyz",
    "fierymeets.xyz",
    "filerpost.xyz",
    "fishfortomorrow.xyz",
    "flash-mail.xyz",
    "fruitandvegetable.xyz",
    "fsagc.xyz",
    "ghtreihfgh.xyz",
    "godataflow.xyz",
    "gotimes.xyz",
    "govnomail.xyz",
    "gqlsryi.xyz",
    "hackrz.xyz",
    "happykoreas.xyz",
    "harmonyst.xyz",
    "healyourself.xyz",
    "helmade.xyz",
    "hewke.xyz",
    "hiddencorner.xyz",
    "hidebusiness.xyz",
    "hideweb.xyz",
    "hjirnbt56g.xyz",
    "imgrpost.xyz",
    "imhtcut.xyz",
    "inpowiki.xyz",
    "ircbox.xyz",
    "jamesmaylovescabbage.xyz",
    "khongsocho.xyz",
    "kormail.xyz",
    "krompakan.xyz",
    "l0.l0l0.xyz",
    "lostpositive.xyz",
    "m2project.xyz",
    "mail.mixhd.xyz",
    "mail.stars19.xyz",
    "mailkor.xyz",
    "mailspam.xyz",
    "mattmason.xyz",
    "mejjang.xyz",
    "moboinfo.xyz",
    "morteinateb.xyz",
    "nakam.xyz",
    "nakammoleb.xyz",
    "nctuiem.xyz",
    "newdawnnm.xyz",
    "nkhfmnt.xyz",
    "nomail.nodns.xyz",
    "nostrajewellery.xyz",
    "oceancares.xyz",
    "ohdomain.xyz",
    "ohioticketpayments.xyz",
    "omdo.xyz",
    "onlinemail.xyz",
    "otnasus.xyz",
    "parkcrestlakewood.xyz",
    "pencalc.xyz",
    "phuongblue1507.xyz",
    "pop3.xyz",
    "promails.xyz",
    "prs7.xyz",
    "psychedelicwarrior.xyz",
    "pyrokiwi.xyz",
    "ravyn.xyz",
    "rcs7.xyz",
    "rdklcrv.xyz",
    "rohingga.xyz",
    "rrwbltw.xyz",
    "rtskiya.xyz",
    "ruditnugnab.xyz",
    "safeemail.xyz",
    "shurs.xyz",
    "slowfoodfoothills.xyz",
    "spam.dnsx.xyz",
    "spambox.xyz",
    "sraka.xyz",
    "stpetersandstpauls.xyz",
    "tastyemail.xyz",
    "teleosaurs.xyz",
    "tellos.xyz",
    "timekr.xyz",
    "torm.xyz",
    "ttszuo.xyz",
    "ufgqgrid.xyz",
    "ulumdocab.xyz",
    "v-mail.xyz",
    "vanacken.xyz",
    "virginsrus.xyz",
    "weammo.xyz",
    "winfreegifts.xyz",
    "wjhndxn.xyz",
    "writeme.xyz",
    "wuespdj.xyz",
    "xjin.xyz",
    "xsellize.xyz",
    "yentzscholarship.xyz",
    "yreilof.xyz",
    "zipmail.xyz",
  ],
  co: [
    "priceio.co",
    "pricepage.co",
    "storant.co",
    "storectic.co",
    "storenia.co",
    "storeodont.co",
    "storero.co",
    "storesteia.co",
    "101price.co",
    "33m.co",
    "8chan.co",
    "99price.co",
    "a.betr.co",
    "adamastore.co",
    "adbet.co",
    "agistore.co",
    "aliex.co",
    "aprice.co",
    "arylabs.co",
    "asdasd.co",
    "asdfasdf.co",
    "asdfsdf.co",
    "bandai.nom.co",
    "bastore.co",
    "bestofprice.co",
    "betaprice.co",
    "betr.co",
    "bione.co",
    "boxmail.co",
    "buyprice.co",
    "ceefax.co",
    "classywebsite.co",
    "clickdeal.co",
    "combustore.co",
    "copastore.co",
    "digiprice.co",
    "emailna.co",
    "ezlo.co",
    "ezprice.co",
    "flashmail.co",
    "forprice.co",
    "freeprice.co",
    "gmail.co",
    "goodseller.co",
    "grabitfast.co",
    "gustore.co",
    "hdprice.co",
    "hitprice.co",
    "hotmail.co",
    "hotprice.co",
    "impastore.co",
    "impostore.co",
    "instaprice.co",
    "investore.co",
    "javmaniac.co",
    "landmail.co",
    "lastmail.co",
    "mailchoose.co",
    "mailinator.co",
    "mailinbox.co",
    "mailna.co",
    "maxprice.co",
    "mephistore.co",
    "meprice.co",
    "messagesafe.co",
    "metaprice.co",
    "moakt.co",
    "mockmyid.co",
    "mr24.co",
    "mvrht.co",
    "mygoldenmail.co",
    "mygrammarly.co",
    "n.spamtrap.co",
    "nationalspeedwaystadium.co",
    "nhdental.co",
    "nuprice.co",
    "onprice.co",
    "polymnestore.co",
    "posteo.co",
    "priceblog.co",
    "pricenew.co",
    "priceonline.co",
    "priceworld.co",
    "proprice.co",
    "protestore.co",
    "quaestore.co",
    "questore.co",
    "rajarajut.co",
    "resistore.co",
    "robo3.co",
    "samerooteigelonline.co",
    "sdfsdf.co",
    "spamtrap.co",
    "storal.co",
    "storeamnos.co",
    "storective.co",
    "storeillet.co",
    "storendite.co",
    "storent.co",
    "storeodon.co",
    "storeodoxa.co",
    "storeotragus.co",
    "storestean.co",
    "storeutics.co",
    "storewood.co",
    "storist.co",
    "subpastore.co",
    "tandy.co",
    "tempemail.co",
    "tempmail.co",
    "testore.co",
    "themeg.co",
    "tokem.co",
    "tryprice.co",
    "tukupedia.co",
    "unpastore.co",
    "uol.com.co",
    "veryprice.co",
    "vistore.co",
    "vprice.co",
    "wikisite.co",
    "xagloo.co",
    "xprice.co",
    "yahoo.co",
    "yahoo.com.co",
  ],
  top: [
    "g3xmail.top",
    "gmxmail.top",
    "locantospot.top",
    "mailhub.top",
    "10host.top",
    "1rentcar.top",
    "24mail.top",
    "2mailnext.top",
    "4mail.top",
    "5music.top",
    "6mail.top",
    "7rent.top",
    "agustaa.top",
    "airsport.top",
    "aliblue.top",
    "aligreen.top",
    "alired.top",
    "aliwhite.top",
    "anonmail.top",
    "appmail.top",
    "bestats.top",
    "bestvpn.top",
    "bitwhites.top",
    "click-mail.top",
    "cloud99.top",
    "cloudstat.top",
    "crypstats.top",
    "cryptonet.top",
    "deliverme.top",
    "dennisss.top",
    "direct-mail.top",
    "directmail.top",
    "dirtysex.top",
    "discreetfuck.top",
    "disposablemail.top",
    "dominatingg.top",
    "dot-mail.top",
    "eaglemail.top",
    "easy-mail.top",
    "easymail.top",
    "eatme69.top",
    "eatmea2z.top",
    "eoffice.top",
    "ethereum1.top",
    "fucknloveme.top",
    "fuckxxme.top",
    "g1xmail.top",
    "g2xmail.top",
    "gamail.top",
    "ganslodot.top",
    "gbmail.top",
    "gcmail.top",
    "gdmail.top",
    "gmx1mail.top",
    "guehomo.top",
    "h0116.top",
    "hmail.top",
    "hornyalwary.top",
    "hostguru.top",
    "jatmikav.top",
    "kadokawa.top",
    "locantofuck.top",
    "mail-list.top",
    "mailhost.top",
    "maillink.top",
    "mailtoyou.top",
    "matra.top",
    "memeil.top",
    "mkk84.top",
    "mmclobau.top",
    "mobilevpn.top",
    "mywrld.top",
    "porororebus.top",
    "preparee.top",
    "prmail.top",
    "profast.top",
    "qq568.top",
    "quickemail.top",
    "quintania.top",
    "red-mail.top",
    "rockmail.top",
    "royalmail.top",
    "sexyalwasmi.top",
    "sfmail.top",
    "simplemail.top",
    "smart-mail.top",
    "storiqax.top",
    "storj99.top",
    "techgroup.top",
    "tempmail.top",
    "thestats.top",
    "ucho.top",
    "vpn33.top",
    "vpsorg.top",
    "webmail24.top",
    "webmails.top",
    "wemel.top",
    "workflowy.top",
    "xbaby69.top",
    "xloveme.top",
    "yahmail.top",
    "yahomail.top",
    "yolooo.top",
  ],
  be: [
    "10minutemail.be",
    "advalvas.be",
    "bartdevos.be",
    "calidifontain.be",
    "digibel.be",
    "fido.be",
    "freegates.be",
    "fuwa.be",
    "ginzi.be",
    "golfmail.be",
    "hideme.be",
    "hotmail.be",
    "k.fido.be",
    "kksm.be",
    "kpost.be",
    "live.be",
    "mail.be",
    "mofu.be",
    "net-c.be",
    "outlook.be",
    "pandora.be",
    "perso.be",
    "ponp.be",
    "posteo.be",
    "prin.be",
    "rapt.be",
    "risu.be",
    "ruru.be",
    "skynet.be",
    "telenet.be",
    "temp.bartdevos.be",
    "tiscali.be",
    "tvcablenet.be",
    "voo.be",
    "wanko.be",
    "yahoo.be",
  ],
  za: [
    "10minutemail.co.za",
    "702mail.co.za",
    "a-b.co.za",
    "allen.nom.za",
    "ananzi.co.za",
    "bblounge.co.za",
    "bigwhoop.co.za",
    "classicmail.co.za",
    "eastcoast.co.za",
    "eramail.co.za",
    "exclusivemail.co.za",
    "executive.co.za",
    "executivemail.co.za",
    "fnbmail.co.za",
    "gmail.co.za",
    "google.co.za",
    "highveldmail.co.za",
    "homemail.co.za",
    "hotmail.co.za",
    "jmail.co.za",
    "justice.gov.za",
    "kayafmmail.co.za",
    "live.co.za",
    "magicmail.co.za",
    "mail.co.za",
    "mailbox.co.za",
    "mighty.co.za",
    "mweb.co.za",
    "netactive.co.za",
    "nom.za",
    "ravemail.co.za",
    "starmail.co.za",
    "technisamail.co.za",
    "tempemail.co.za",
    "thecricket.co.za",
    "thegolf.co.za",
    "thepub.co.za",
    "therugby.co.za",
    "tsamail.co.za",
    "uct.ac.za",
    "wam.co.za",
    "webmail.co.za",
    "websurfer.co.za",
    "workmail.co.za",
    "yahoo.co.za",
  ],
  de: [
    "ama-trans.de",
    "baldpapa.de",
    "die-genossen.de",
    "emailgo.de",
    "geldwaschmaschine.de",
    "gmx-topmail.de",
    "ich-will-net.de",
    "keipino.de",
    "meinspamschutz.de",
    "partyheld.de",
    "privymail.de",
    "schrott-email.de",
    "sofortmail.de",
    "trashemail.de",
    "volloeko.de",
    "vorsicht-scharf.de",
    "weg-werf-email.de",
    "wegwerf-email-adressen.de",
    "10minutemail.de",
    "10minutenemail.de",
    "1pad.de",
    "5x2.de",
    "60-minuten-mail.de",
    "9ya.de",
    "abusemail.de",
    "abwesend.de",
    "addcom.de",
    "affilikingz.de",
    "airsi.de",
    "alpenjodel.de",
    "alphafrau.de",
    "ama-trade.de",
    "anon-mail.de",
    "anonmails.de",
    "antispam.de",
    "antispam24.de",
    "antispammail.de",
    "aol.de",
    "apfelkorps.de",
    "arcor.de",
    "area-thinking.de",
    "arvato-community.de",
    "awatum.de",
    "b2cmail.de",
    "baldmama.de",
    "berlin.de",
    "berlinexpo.de",
    "betriebsdirektor.de",
    "bigfoot.de",
    "bin-wieder-da.de",
    "bizimalem-support.de",
    "bleib-bei-mir.de",
    "bootybay.de",
    "bossmail.de",
    "brennendesreich.de",
    "briefkasten2go.de",
    "buerotiger.de",
    "byom.de",
    "candymail.de",
    "center-mail.de",
    "centermail.de",
    "cghost.s-a-d.de",
    "cheatmail.de",
    "cnsds.de",
    "comwest.de",
    "cookiecooker.de",
    "coole-files.de",
    "crastination.de",
    "curryworld.de",
    "cuvox.de",
    "cww.de",
    "delikkt.de",
    "der-kombi.de",
    "derkombi.de",
    "derluxuswagen.de",
    "die-besten-bilder.de",
    "die-optimisten.de",
    "diemailbox.de",
    "digital-filestore.de",
    "discardmail.de",
    "dma.in-ulm.de",
    "dodgemail.de",
    "dontsendmespam.de",
    "dotman.de",
    "doublemail.de",
    "download-privat.de",
    "droolingfanboy.de",
    "dropcake.de",
    "dsas.de",
    "dumpmail.de",
    "e-postkasten.de",
    "e3z.de",
    "ebeschlussbuch.de",
    "echt-mail.de",
    "einmalmail.de",
    "einrot.de",
    "eintagsmail.de",
    "email.de",
    "emaildienst.de",
    "emailgenerator.de",
    "emailigo.de",
    "emailto.de",
    "empiremail.de",
    "epost.de",
    "etgdev.de",
    "fakeemail.de",
    "fansworldwide.de",
    "fantasymail.de",
    "feinripptraeger.de",
    "fettabernett.de",
    "ficken.de",
    "fiifke.de",
    "firemail.de",
    "fivemail.de",
    "flitafir.de",
    "fly-ts.de",
    "fmail10.de",
    "freemail.de",
    "freenet.de",
    "freudenkinder.de",
    "fuettertdasnetz.de",
    "funnymail.de",
    "furzauflunge.de",
    "fyii.de",
    "gehensiemirnichtaufdensack.de",
    "gentlemansclub.de",
    "ghosttexter.de",
    "giantmail.de",
    "giga4u.de",
    "gmx-ist-cool.de",
    "gmx.de",
    "gratislose.de",
    "grish.de",
    "guerillamail.de",
    "guerrillamail.de",
    "hab-verschlafen.de",
    "habmalnefrage.de",
    "hartbot.de",
    "hat-geld.de",
    "headstrong.de",
    "herr-der-mails.de",
    "hidemail.de",
    "home.de",
    "hotmail.de",
    "hulapla.de",
    "ich-bin-verrueckt-nach-dir.de",
    "ieh-mail.de",
    "igelonline.de",
    "imgv.de",
    "imtek.uni-freiburg.de",
    "info-media.de",
    "inoutmail.de",
    "instant-mail.de",
    "iremail.de",
    "ironiebehindert.de",
    "ist-einmalig.de",
    "ist-ganz-allein.de",
    "ist-willig.de",
    "isteingeek.de",
    "istlecker.de",
    "istmein.de",
    "jetable.de",
    "justmail.de",
    "kabelmail.de",
    "kaffeeschluerfer.de",
    "katergizmo.de",
    "kcrw.de",
    "keinhirn.de",
    "keinpardon.de",
    "kommespaeter.de",
    "kostenlosemailadresse.de",
    "kurzepost.de",
    "lass-es-geschehen.de",
    "lebtimnetz.de",
    "leitungsen.de",
    "liquidmail.de",
    "live.de",
    "loveyouforever.de",
    "lycos.de",
    "maennerversteherin.de",
    "mail-awu.de",
    "mail.de",
    "mail.misterpinball.de",
    "mail1a.de",
    "mailbox2go.de",
    "mailbox87.de",
    "mailde.de",
    "maildu.de",
    "maileimer.de",
    "mailfish.de",
    "mailseal.de",
    "mailto.de",
    "mailtome.de",
    "mailueberfall.de",
    "malahov.de",
    "manager.de",
    "maximalbonus.de",
    "mazda.de",
    "meine-diashow.de",
    "meine-urlaubsfotos.de",
    "messagebeamer.de",
    "ministry-of-silly-walks.de",
    "misterpinball.de",
    "motique.de",
    "muellpost.de",
    "muskelshirt.de",
    "mybitti.de",
    "mynetstore.de",
    "mypartyclip.de",
    "mysamp.de",
    "netcologne.de",
    "netterchef.de",
    "netzidiot.de",
    "neue-dateien.de",
    "nevermail.de",
    "nonspammer.de",
    "nsaking.de",
    "nur-fuer-spam.de",
    "nurfuerspam.de",
    "office-dateien.de",
    "ohaaa.de",
    "online.de",
    "onlinehome.de",
    "opende.de",
    "oroki.de",
    "outlook.de",
    "ownsyou.de",
    "ozyl.de",
    "pagina.de",
    "partybombe.de",
    "phaantm.de",
    "plexolan.de",
    "pmlep.de",
    "politikerclub.de",
    "polizisten-duzer.de",
    "posteo.de",
    "postfach2go.de",
    "postino.de",
    "privy-mail.de",
    "public-files.de",
    "quantentunnel.de",
    "r4nd0m.de",
    "raketenmann.de",
    "raubtierbaendiger.de",
    "redseven.de",
    "reservelp.de",
    "riddermark.de",
    "saeuferleber.de",
    "safersignup.de",
    "safetypost.de",
    "sags-per-mail.de",
    "sandelf.de",
    "schafmail.de",
    "schmusemail.de",
    "schreib-doch-mal-wieder.de",
    "secretemail.de",
    "seriousalts.de",
    "servus-mail.de",
    "shared-files.de",
    "shitmail.de",
    "showslow.de",
    "sinnlos-mail.de",
    "sky-ts.de",
    "slowslow.de",
    "smapfree24.de",
    "smashmail.de",
    "sneakmail.de",
    "sofort-mail.de",
    "spam-en.de",
    "spam-nicht.de",
    "spamail.de",
    "spambog.de",
    "spamfree24.de",
    "spaminator.de",
    "spaml.de",
    "spamoff.de",
    "spamwc.de",
    "spoofmail.de",
    "spritzzone.de",
    "spybox.de",
    "squizzy.de",
    "staticintime.de",
    "streber24.de",
    "stud.uni-sb.de",
    "stuffmail.de",
    "sueddeutsche.de",
    "super-auswahl.de",
    "superstachel.de",
    "suse.de",
    "sweetxxx.de",
    "t-online.de",
    "telekom.de",
    "temp-mail.de",
    "temp.headstrong.de",
    "tempmail.de",
    "tempmailer.de",
    "temporarily.de",
    "terminverpennt.de",
    "test.de",
    "tkitc.de",
    "tm.in-ulm.de",
    "tokenmail.de",
    "top101.de",
    "topmail-files.de",
    "topranklist.de",
    "tortenboxer.de",
    "totalmail.de",
    "traeumtgerade.de",
    "trash-mail.de",
    "trashdevil.de",
    "trashemails.de",
    "trashmail.de",
    "trialmail.de",
    "turboprinz.de",
    "turboprinzessin.de",
    "tutanota.de",
    "twinmail.de",
    "uni.de",
    "uni.de.de",
    "uni.demailto.de",
    "unitybox.de",
    "unterderbruecke.de",
    "vankin.de",
    "verlass-mich-nicht.de",
    "vollbio.de",
    "vorsicht-bissig.de",
    "vp.ycare.de",
    "w9f.de",
    "war-im-urlaub.de",
    "web-mail.de",
    "web.de",
    "webmail.de",
    "weg-beschlussbuch.de",
    "wegwerf-email-addressen.de",
    "wegwerf-email.de",
    "wegwerf-emails.de",
    "wegwerfemail.de",
    "wegwerfmail.de",
    "wegwrfmail.de",
    "weibsvolk.de",
    "weinenvorglueck.de",
    "will-hier-weg.de",
    "wir-haben-nachwuchs.de",
    "wirsindcool.de",
    "yahoo.de",
    "ycare.de",
    "zehnminuten.de",
    "zehnminutenmail.de",
    "zipzaps.de",
  ],
  nl: [
    "zeepost.nl",
    "10minutemail.nl",
    "adres.nl",
    "aktiefmail.nl",
    "appinventor.nl",
    "asdasd.nl",
    "avpa.nl",
    "bitx.nl",
    "casema.nl",
    "chello.nl",
    "directmonitor.nl",
    "failinga.nl",
    "fastmail.nl",
    "freeaccess.nl",
    "freeler.nl",
    "freemail.nl",
    "freesurf.nl",
    "gynzi.nl",
    "herpderp.nl",
    "hetnet.nl",
    "home.nl",
    "hotmail.nl",
    "hvastudiesucces.nl",
    "ikbenspamvrij.nl",
    "ilse.nl",
    "jamieziggers.nl",
    "knol-power.nl",
    "kpnmail.nl",
    "kpnplanet.nl",
    "live.nl",
    "lycos.nl",
    "mailmij.nl",
    "marijuana.nl",
    "mijnhva.nl",
    "msn.nl",
    "mupload.nl",
    "n.zavio.nl",
    "net-c.nl",
    "netricity.nl",
    "online.nl",
    "outlook.nl",
    "planet.nl",
    "posteo.nl",
    "privatemailinator.nl",
    "quickmail.nl",
    "quicknet.nl",
    "rt.nl",
    "scarlet.nl",
    "shitware.nl",
    "solcon.nl",
    "sp.nl",
    "stefhf.nl",
    "stipte.nl",
    "tele2.nl",
    "telfort.nl",
    "telfortglasvezel.nl",
    "tijdelijke-email.nl",
    "tijdelijkmailadres.nl",
    "upc.nl",
    "upcmail.nl",
    "versatel.nl",
    "vodafone.nl",
    "vodafonethuis.nl",
    "wegwerpmailadres.nl",
    "xms.nl",
    "xs4all.nl",
    "yahoo.nl",
    "zavio.nl",
    "zeelandnet.nl",
    "ziggo.nl",
    "zonnet.nl",
  ],
  pro: [
    "10minutemail.pro",
    "88clean.pro",
    "art-en-ligne.pro",
    "bearegone.pro",
    "bigcrop.pro",
    "car101.pro",
    "clean.pro",
    "cloud99.pro",
    "emailo.pro",
    "flash-mail.pro",
    "flashmail.pro",
    "hidemail.pro",
    "ideer.pro",
    "imouto.pro",
    "last-chance.pro",
    "loan101.pro",
    "my365office.pro",
    "nicemail.pro",
    "omail.pro",
    "prolagu.pro",
    "puji.pro",
    "secmail.pro",
    "solar-impact.pro",
    "styliste.pro",
    "tempemail.pro",
    "tempmail.pro",
    "vcbox.pro",
    "vpsorg.pro",
  ],
  us: [
    "abundantwe.us",
    "accentwe.us",
    "achievewe.us",
    "acumenwe.us",
    "adventwe.us",
    "advocatewe.us",
    "alchemywe.us",
    "allaroundwe.us",
    "alliancewe.us",
    "allstarwe.us",
    "alpinewe.us",
    "amberwe.us",
    "ambitiouswe.us",
    "americaswe.us",
    "amplifywe.us",
    "analyticswe.us",
    "dicksinmyan.us",
    "farmtoday.us",
    "loves.dicksinmyan.us",
    "mailHazard.us",
    "wants.dicksinmyan.us",
    "10minutemail.us",
    "1ce.us",
    "2net.us",
    "4-n.us",
    "6ip.us",
    "abacuswe.us",
    "abanksat.us",
    "abilitywe.us",
    "abovewe.us",
    "absolutewe.us",
    "academywe.us",
    "acceleratewe.us",
    "acceptwe.us",
    "acclaimwe.us",
    "accreditedwe.us",
    "achievementwe.us",
    "acornwe.us",
    "acrylicwe.us",
    "activatewe.us",
    "activitywe.us",
    "acuitywe.us",
    "adaptivewe.us",
    "adcloud.us",
    "adeptwe.us",
    "admiralwe.us",
    "adoniswe.us",
    "advantagewe.us",
    "adventurewe.us",
    "advisorwe.us",
    "aegiswe.us",
    "affiliatedwe.us",
    "affinitywe.us",
    "affluentwe.us",
    "affordablewe.us",
    "afterhourswe.us",
    "agendawe.us",
    "agilewe.us",
    "agorawe.us",
    "agristyleapparel.us",
    "aheadwe.us",
    "airideas.us",
    "airwayy.us",
    "al-qaeda.us",
    "albionwe.us",
    "aliaswe.us",
    "alivewe.us",
    "allaccesswe.us",
    "allamericanwe.us",
    "allegiancewe.us",
    "allgoodwe.us",
    "allinonewe.us",
    "alloutwe.us",
    "alloywe.us",
    "allseasonswe.us",
    "allurewe.us",
    "alphaomegawe.us",
    "altairwe.us",
    "altitudewe.us",
    "altuswe.us",
    "amadeuswe.us",
    "ambassadorwe.us",
    "ambiancewe.us",
    "americanawe.us",
    "americasbestwe.us",
    "amicuswe.us",
    "amitywe.us",
    "amplewe.us",
    "amplifiedwe.us",
    "analogwe.us",
    "analysiswe.us",
    "analyticalwe.us",
    "analyticwe.us",
    "andthen.us",
    "anomail.us",
    "aron.us",
    "autohotline.us",
    "bacninhmail.us",
    "badamm.us",
    "bbhost.us",
    "bestmail.us",
    "big1.us",
    "biscutt.us",
    "bitcoinbet.us",
    "bitymails.us",
    "boatmail.us",
    "bund.us",
    "burgercentral.us",
    "cakk.us",
    "chocklet.us",
    "chris.burgercentral.us",
    "coolandwacky.us",
    "crazydoll.us",
    "ctmailing.us",
    "cursodemicropigmentacao.us",
    "d8u.us",
    "ddosed.us",
    "deekayen.us",
    "dicksinhisan.us",
    "ebookway.us",
    "electronicmail.us",
    "familytoday.us",
    "farmerlife.us",
    "fastemail.us",
    "fastemails.us",
    "fastmail.us",
    "fluidsoft.us",
    "freedompop.us",
    "fressmind.us",
    "g.hmail.us",
    "g4hdrop.us",
    "gakbec.us",
    "gero.us",
    "gibit.us",
    "gmail.zalvisual.us",
    "gmx.us",
    "gosearchcity.us",
    "h-mail.us",
    "hdmoviestore.us",
    "hidemail.us",
    "hmail.us",
    "hurramm.us",
    "hurrijian.us",
    "hypori.us",
    "i-booking.us",
    "iku.us",
    "indeedtime.us",
    "invert.us",
    "is-by.us",
    "iwantumake.us",
    "j-p.us",
    "klick-tipp.us",
    "l-c-a.us",
    "lags.us",
    "land-4-sale.us",
    "lazyinbox.us",
    "liveskiff.us",
    "livingsalty.us",
    "loadby.us",
    "loves.dicksinhisan.us",
    "lr7.us",
    "luv2.us",
    "mail-on.us",
    "mailhazard.us",
    "mailinator.us",
    "mailscheap.us",
    "mindsetup.us",
    "musicmakes.us",
    "myproximity.us",
    "n.ra3.us",
    "newsmag.us",
    "nicegarden.us",
    "nospam4.us",
    "nospamfor.us",
    "okrent.us",
    "oou.us",
    "passionwear.us",
    "perasut.us",
    "phonearea.us",
    "posteo.us",
    "pulpmail.us",
    "qbfree.us",
    "qiq.us",
    "ra3.us",
    "rockkes.us",
    "rxmail.us",
    "rxtx.us",
    "saigonmail.us",
    "semail.us",
    "skz.us",
    "sls.us",
    "spambox.us",
    "sportylife.us",
    "stuff-4-sale.us",
    "sutann.us",
    "teleworm.us",
    "tempmail.us",
    "temporaryemail.us",
    "theone2017.us",
    "thtt.us",
    "topemail.us",
    "toplessbucksbabes.us",
    "twelvee.us",
    "u-mail.us",
    "ucche.us",
    "uiu.us",
    "ureee.us",
    "utiket.us",
    "uwork4.us",
    "vaultpoint.us",
    "vtxmail.us",
    "want2lov.us",
    "wants.dicksinhisan.us",
    "with-u.us",
    "work4uber.us",
    "writeme.us",
    "xost.us",
    "xvx.us",
    "xxlocanto.us",
    "xxolocanto.us",
    "zalvisual.us",
    "zzi.us",
  ],
  in: [
    "10minutemails.in",
    "1lv.in",
    "20mail.in",
    "4vn.in",
    "60s.in",
    "a45.in",
    "ac20mail.in",
    "armail.in",
    "ayecapta.in",
    "bangsat.in",
    "boxformail.in",
    "clandest.in",
    "cntt.in",
    "cust.in",
    "dispo.in",
    "emailapps.in",
    "emeil.in",
    "fastmail.in",
    "fpt.in",
    "freetmail.in",
    "gelitik.in",
    "gnlu.ac.in",
    "gomail.in",
    "gowikimusic.great-host.in",
    "great-host.in",
    "grinn.in",
    "herp.in",
    "hi2.in",
    "hotmail.co.in",
    "icx.in",
    "jancok.in",
    "lianhe.in",
    "live.in",
    "loin.in",
    "lovebitco.in",
    "lviv.in",
    "mailed.in",
    "maillist.in",
    "mailna.in",
    "maxmail.in",
    "mohmal.in",
    "mytmail.in",
    "nextemail.in",
    "nextmail.in",
    "noref.in",
    "oneindia.in",
    "outlook.in",
    "penisgoes.in",
    "posteo.in",
    "quickmail.in",
    "renraku.in",
    "rmailgroup.in",
    "rq1.in",
    "scanova.in",
    "sd3.in",
    "simpleemail.in",
    "simplemail.in",
    "so1.in",
    "spamgoes.in",
    "spamsalad.in",
    "thexyz.in",
    "tittbit.in",
    "ttml.co.in",
    "we.lovebitco.in",
    "webm4il.in",
    "webuser.in",
    "winemaven.in",
    "yahoo.co.in",
    "yahoo.in",
    "zippymail.in",
    "zweb.in",
  ],
  fr: [
    "10minutesmail.fr",
    "1s.fr",
    "ac-strasbourg.fr",
    "ados.fr",
    "aliceadsl.fr",
    "aol.fr",
    "avia-tonic.fr",
    "caramail.lycos.fr",
    "cazis.fr",
    "club-internet.fr",
    "dotcom.fr",
    "ecolo-online.fr",
    "email-jetable.fr",
    "estate-invest.fr",
    "fakemail.fr",
    "fast-mail.fr",
    "francemel.fr",
    "free.fr",
    "freesbee.fr",
    "freesurf.fr",
    "get2mail.fr",
    "gmx.fr",
    "hotmail.fr",
    "indexa.fr",
    "instantmail.fr",
    "jet-renovation.fr",
    "le.monchu.fr",
    "libox.fr",
    "live.fr",
    "mail-easy.fr",
    "mail-temporaire.fr",
    "mail.fr",
    "mailclub.fr",
    "mailtemporaire.fr",
    "mottel.fr",
    "net-c.fr",
    "netc.fr",
    "neuf.fr",
    "noos.fr",
    "numericable.fr",
    "orange.fr",
    "outlook.fr",
    "premium-mail.fr",
    "sfr.fr",
    "speed.1s.fr",
    "stade.fr",
    "tempomail.fr",
    "thexyz.fr",
    "used-product.fr",
    "voila.fr",
    "wanadoo.fr",
    "web-ideal.fr",
    "yahoo.fr",
    "yopmail.fr",
    "zaktouni.fr",
  ],
  es: [
    "10x.es",
    "900k.es",
    "aelo.es",
    "cgac.es",
    "ctav.es",
    "esy.es",
    "f4k.es",
    "fastmail.es",
    "fut.es",
    "ginzi.es",
    "gmx.es",
    "gynzi.es",
    "gynzy.es",
    "hecat.es",
    "hotmail.es",
    "i.wawi.es",
    "icaoviedo.es",
    "iespana.es",
    "ige.es",
    "infomail.es",
    "inicia.es",
    "lycos.es",
    "martin.securehost.com.es",
    "moot.es",
    "myecho.es",
    "net-c.es",
    "outlook.es",
    "posteo.es",
    "publi.innovatio.es",
    "securehost.com.es",
    "spam.org.es",
    "teleline.es",
    "terra.es",
    "thexyz.es",
    "u.10x.es",
    "u.900k.es",
    "uco.es",
    "unican.es",
    "wanadoo.es",
    "wawi.es",
    "yahoo.com.es",
    "yahoo.es",
  ],
  cl: [
    "123mail.cl",
    "147.cl",
    "atina.cl",
    "campano.cl",
    "chilelinks.cl",
    "ebano.campano.cl",
    "live.cl",
    "outlook.cl",
    "posteo.cl",
    "sin.cl",
    "sinfiltro.cl",
    "terra.cl",
    "trendingtopic.cl",
  ],
  dk: [
    "gaf-net.dk",
    "henriksen.dk",
    "jubiigames.dk",
    "knus.dk",
    "mikkelsen.dk",
    "netby.dk",
    "privatpost.dk",
    "123mail.dk",
    "12fuel.dk",
    "12mail.dk",
    "12move.dk",
    "2night.dk",
    "2senior.dk",
    "418.dk",
    "909mail.dk",
    "9260.dk",
    "ahaa.dk",
    "alskens.dk",
    "anarki.dk",
    "anderledes.dk",
    "anonymail.dk",
    "backbone.dk",
    "bedstemail.dk",
    "begavet.dk",
    "bemybaby.dk",
    "bitnisse.dk",
    "bluemail.dk",
    "c.dk",
    "christensen.dk",
    "christiansen.dk",
    "city.dk",
    "cool.dk",
    "cyberdude.dk",
    "cyberjunkie.dk",
    "d3p.dk",
    "dancemail.dk",
    "e-box.dk",
    "e-mail.dk",
    "e-postboks.dk",
    "elinstallatoer.dk",
    "elsker.dk",
    "elvis.dk",
    "email.dk",
    "eriksen.dk",
    "fald.dk",
    "fedt.dk",
    "feminin.dk",
    "flirt.dk",
    "forening.dk",
    "forum.dk",
    "fraekkert.dk",
    "frandsen.dk",
    "frederiksen.dk",
    "funkymail.dk",
    "gadefejer.dk",
    "gason.dk",
    "godmail.dk",
    "grin.dk",
    "groovymail.dk",
    "grov.dk",
    "hammergod.dk",
    "hansen.dk",
    "hardworking.dk",
    "heaven.dk",
    "heavymail.dk",
    "hemmelig.dk",
    "henningsen.dk",
    "hippie.dk",
    "hotmail.dk",
    "huleboer.dk",
    "husk-mig.dk",
    "hyperpost.dk",
    "iloveu.dk",
    "inbound.dk",
    "indbakke.dk",
    "infile.dk",
    "info.dk",
    "io.dk",
    "it.dk",
    "iversen.dk",
    "jacobsen.dk",
    "jakobsen.dk",
    "johansen.dk",
    "jubii.dk",
    "jubiichat.dk",
    "jubiiconnect.dk",
    "jubiimail.dk",
    "jubiipostadresse.dk",
    "jyde.dk",
    "klog.dk",
    "knudsen.dk",
    "krudt.dk",
    "kulturel.dk",
    "kvalme.dk",
    "larsen.dk",
    "laursen.dk",
    "lazy.dk",
    "live.dk",
    "lortemail.dk",
    "lukop.dk",
    "lystig.dk",
    "madsen.dk",
    "mail-mig.dk",
    "mail.dk",
    "mail4.dk",
    "mail6.dk",
    "mail8.dk",
    "maildanmark.dk",
    "mailme.dk",
    "mailroyal.dk",
    "mailster.dk",
    "manmail.dk",
    "maskulin.dk",
    "mathiesen.dk",
    "mikaelsen.dk",
    "min-postkasse.dk",
    "minprivatemail.dk",
    "mortensen.dk",
    "musling.dk",
    "natteliv.dk",
    "netbruger.dk",
    "nicemail.dk",
    "nospam.dk",
    "olesen.dk",
    "outlook.dk",
    "pedal.dk",
    "pedersen.dk",
    "pengemand.dk",
    "petersen.dk",
    "pokerface.dk",
    "popme.dk",
    "post.tele.dk",
    "posteo.dk",
    "postman.dk",
    "poulsen.dk",
    "privat.dk",
    "privatmail.dk",
    "quake.dk",
    "rasmussen.dk",
    "ready.dk",
    "recyclemail.dk",
    "retromail.dk",
    "ringmybell.dk",
    "secret.dk",
    "sexysurfer.dk",
    "simonsen.dk",
    "skrivnu.dk",
    "sleepy.dk",
    "sol.dk",
    "sorensen.dk",
    "sporty.dk",
    "submail.dk",
    "superbruger.dk",
    "svendsen.dk",
    "talent.dk",
    "tanke.dk",
    "tastmig.dk",
    "taxidriver.dk",
    "teens.dk",
    "teknik.dk",
    "thomsen.dk",
    "tjekket.dk",
    "trashcan.dk",
    "trustme.dk",
    "ungdom.dk",
    "vestergaard.dk",
    "video.dk",
    "vittig.dk",
    "wildwildweb.dk",
    "yahoo.dk",
    "youmail.dk",
    "zepp.dk",
    "zoomail.dk",
    "zybermail.dk",
  ],
  red: ["15qm-mail.red"],
  to: [
    "1mail.uk.to",
    "ag.us.to",
    "anonymous.to",
    "b.reed.to",
    "blackmarket.to",
    "easy.to",
    "edv.to",
    "einfach.to",
    "fastmail.to",
    "hello.to",
    "hey.to",
    "i.am.to",
    "i.amhey.to",
    "junk.to",
    "lol.ovpn.to",
    "messages.to",
    "ovpn.to",
    "puk.us.to",
    "pw.epac.to",
    "qc.to",
    "qcmail.qc.to",
    "rx.qc.to",
    "spamama.uk.to",
    "uk.to",
    "us.to",
    "ux.uk.to",
    "w3.to",
    "webmail24.to",
  ],
  me: [
    "mailHz.me",
    "1ss.noip.me",
    "adrmwn.me",
    "amail4.me",
    "aminudin.me",
    "banit.me",
    "bccto.me",
    "bgsaddrmwn.me",
    "bigtuyul.me",
    "binka.me",
    "blutig.me",
    "c99.me",
    "cbgh.ddns.me",
    "comeonfind.me",
    "computations.me",
    "creo.nctu.me",
    "cumallover.me",
    "docu.me",
    "dropmail.me",
    "esmuse.me",
    "faecesmail.me",
    "fnord.me",
    "freeletter.me",
    "fuckmail.me",
    "fw2.me",
    "gmeil.me",
    "gondskumis69.me",
    "google-mail.me",
    "heeco.me",
    "howp.me",
    "hushmail.me",
    "ichigo.me",
    "iconsultant.me",
    "inboxdesign.me",
    "inboxstore.me",
    "iotu.nctu.me",
    "kartvelo.me",
    "keemail.me",
    "kumisgonds69.me",
    "l.bgsaddrmwn.me",
    "lagushare.me",
    "leemail.me",
    "leks.me",
    "lilo.me",
    "lru.me",
    "m.bccto.me",
    "mail.bccto.me",
    "mail.me",
    "mail.piaa.me",
    "mail2.ntuz.me",
    "mail56.me",
    "mailguard.me",
    "mailhz.me",
    "mailna.me",
    "mailshiv.me",
    "mailspam.me",
    "mhdpower.me",
    "mnode.me",
    "mundodigital.me",
    "mutant.me",
    "nbox.notif.me",
    "notif.me",
    "ny7.me",
    "opna.me",
    "papierkorb.me",
    "party4you.me",
    "peepto.me",
    "piaa.me",
    "plw.me",
    "pm.me",
    "posteo.me",
    "postonline.me",
    "psacake.me",
    "psh.me",
    "qvy.me",
    "recode.me",
    "robo3.me",
    "robot2.me",
    "saukute.me",
    "shitmail.me",
    "shopbaby.me",
    "shortmail.me",
    "smart-email.me",
    "smartbusiness.me",
    "smuse.me",
    "spam4.me",
    "spambox.me",
    "spammail.me",
    "studentmail.me",
    "sudolife.me",
    "sukasukasuka.me",
    "t.psh.me",
    "techgroup.me",
    "tmp.k3a.me",
    "tmpjr.me",
    "tohurt.me",
    "trash4.me",
    "trashmail.me",
    "uni.me",
    "vmail.me",
    "vnedu.me",
    "wasd.dropmail.me",
    "webemail.me",
    "whyspam.me",
    "williamcastillo.me",
    "www.bccto.me",
    "xxme.me",
    "yanet.me",
    "zimbail.me",
  ],
  space: [
    "2-ch.space",
    "abista.space",
    "bershka-terim.space",
    "devset.space",
    "disposablemail.space",
    "lancego.space",
    "london2.space",
    "mail2.space",
    "mail22.space",
    "myfreemail.space",
    "myloans.space",
    "noedgetest.space",
    "starpower.space",
    "supersentai.space",
    "tempmail.space",
    "yourspamgoesto.space",
  ],
  ooo: ["2.tebwinsoi.ooo", "2.sexymail.ooo", "sazhimail.ooo", "sexymail.ooo", "tebwinsoi.ooo"],
  it: [
    "fastwebnet.it",
    "poste.it",
    "20email.it",
    "20mail.it",
    "20minutemail.it",
    "3000.it",
    "420blaze.it",
    "alice.it",
    "aol.it",
    "blu.it",
    "caere.it",
    "caltanet.it",
    "centoper.it",
    "ciaoweb.it",
    "cybernet.it",
    "dea.soon.it",
    "despam.it",
    "dispose.it",
    "dott.it",
    "eco.ilmale.it",
    "email.it",
    "estranet.it",
    "excite.it",
    "fastwebmail.it",
    "freemail.it",
    "glitchwave.it",
    "globalfree.it",
    "gmail.it",
    "go4.it",
    "hotmail.it",
    "ignazio.it",
    "ilmale.it",
    "interfree.it",
    "inwind.it",
    "iol.it",
    "jumpy.it",
    "leonardo.it",
    "libero.it",
    "liberto.it",
    "live.it",
    "lol.it",
    "lycos.it",
    "mail.byte.it",
    "mail.crowdpress.it",
    "mp4.it",
    "mybox.it",
    "net-c.it",
    "netc.it",
    "outlook.it",
    "pec.it",
    "postcardsfromukraine.crowdpress.it",
    "postino.it",
    "progetplus.it",
    "quickreport.it",
    "redchan.it",
    "sdnr.it",
    "soon.it",
    "ss.undo.it",
    "supereva.it",
    "teletu.it",
    "tempmail.it",
    "test.crowdpress.it",
    "tim.it",
    "tin.it",
    "tiscali.it",
    "undo.it",
    "uno.it",
    "unofree.it",
    "virgilio.it",
    "wooow.it",
    "yahoo.it",
    "yep.it",
    "yui.it",
  ],
  email: [
    "20minute.email",
    "abcmail.email",
    "ahem.email",
    "bonobo.email",
    "btc.email",
    "businessagent.email",
    "cryp.email",
    "discard.email",
    "dreamcatcher.email",
    "drf.email",
    "ephemeral.email",
    "ethereal.email",
    "euroweb.email",
    "faketemp.email",
    "freeinbox.email",
    "freeweb.email",
    "generator.email",
    "itemp.email",
    "linx.email",
    "muell.email",
    "mytemp.email",
    "müll.email",
    "nada.email",
    "one-time.email",
    "pterodactyl.email",
    "purcell.email",
    "royalweb.email",
    "shuffle.email",
    "simplebox.email",
    "slippery.email",
    "synonyme.email",
    "tempr.email",
    "the-first.email",
    "thetrash.email",
    "utoo.email",
    "weave.email",
    "yop.email",
    "yourweb.email",
    "zebra.email",
  ],
  hk: [
    "2ch.coms.hk",
    "2ch.orgs.hk",
    "acgapp.hk",
    "amazon.coms.hk",
    "arduino.hk",
    "cnn.coms.hk",
    "coms.hk",
    "docs.coms.hk",
    "fbi.coms.hk",
    "fir.hk",
    "gmx.hk",
    "hotmail.com.hk",
    "hsbc.coms.hk",
    "ibm.coms.hk",
    "intel.coms.hk",
    "kein.hk",
    "live.hk",
    "mail1.ismoke.hk",
    "mail1.kein.hk",
    "nike.coms.hk",
    "ouhk.edu.hk",
    "pepsi.coms.hk",
    "tvb.com.hk",
    "visa.coms.hk",
    "vtc.edu.hk",
    "yahoo.com.hk",
  ],
  asia: [
    "b.cr.cloudns.asia",
    "mirrror.asia",
    "2ch.daemon.asia",
    "adel.asia",
    "adroit.asia",
    "amhar.asia",
    "azure.cloudns.asia",
    "biglive.asia",
    "clendere.asia",
    "cloudns.asia",
    "cr.cloudns.asia",
    "dolnaa.asia",
    "fax.dix.asia",
    "link.cloudns.asia",
    "mirrorrr.asia",
    "msft.cloudns.asia",
    "o.oai.asia",
    "oai.asia",
    "pro.cloudns.asia",
    "sargrip.asia",
    "softpls.asia",
    "ssl.tls.cloudns.asia",
    "temp.cloudns.asia",
    "thietbivanphong.asia",
    "tls.cloudns.asia",
    "unite.cloudns.asia",
    "yahoo.com.asia",
  ],
  ir: [
    "30mail.ir",
    "a.sach.ir",
    "arakcarpet.ir",
    "c.kadag.ir",
    "caspianfan.ir",
    "cnew.ir",
    "emailirani.ir",
    "emall.ir",
    "emeil.ir",
    "hatmail.ir",
    "kadag.ir",
    "mailme.ir",
    "mihanmail.ir",
    "sach.ir",
    "vrsim.ir",
    "wavemail.ir",
  ],
  ua: [
    "3g.ua",
    "a.org.ua",
    "add3000.pp.ua",
    "ass.pp.ua",
    "box.ua",
    "card.zp.ua",
    "d.megafon.org.ua",
    "e-mail.ua",
    "email.ua",
    "eml.pp.ua",
    "express.net.ua",
    "fake-email.pp.ua",
    "get.pp.ua",
    "hash.pp.ua",
    "i.ua",
    "index.ua",
    "infocom.zp.ua",
    "ip4.pp.ua",
    "ip6.pp.ua",
    "jetable.pp.ua",
    "lak.pp.ua",
    "loh.pp.ua",
    "mail.ua",
    "mail.zp.ua",
    "meta.ua",
    "mildin.org.ua",
    "mox.pp.ua",
    "mycard.net.ua",
    "net.ua",
    "org.ua",
    "pig.pp.ua",
    "poh.pp.ua",
    "pp.ua",
    "search.ua",
    "stop-my-spam.pp.ua",
    "temp-mail.pp.ua",
    "tempmail.pp.ua",
    "throwawaymail.pp.ua",
    "tool.pp.ua",
    "topping.com.ua",
    "web-mail.pp.ua",
    "yandex.ua",
    "yopmail.pp.ua",
    "zp.ua",
  ],
  rocks: [
    "3mail.rocks",
    "nameaaa.myddns.rocks",
    "quickmail.rocks",
    "remarkable.rocks",
    "zane.rocks",
  ],
  li: [
    "4free.li",
    "cock.li",
    "fuwa.li",
    "gmx.li",
    "gray.li",
    "ip6.li",
    "posteo.li",
    "remote.li",
    "sry.li",
    "tracciabi.li",
    "travel.li",
    "x.ip6.li",
  ],
  host: ["4tb.host", "astrology.host", "onemail.host", "outlook.com.hotpusssy69.host"],
  io: [
    "4w.io",
    "7mail.io",
    "brank.io",
    "burnermail.io",
    "carins.io",
    "dx.sly.io",
    "emaildrop.io",
    "ixx.io",
    "landmark.io",
    "laramail.io",
    "mailhero.io",
    "microcenter.io",
    "popmail.io",
    "pseudoname.io",
    "qatar.io",
    "qnb.io",
    "scanova.io",
    "sly.io",
    "spr.io",
    "tempemails.io",
    "tempmail.io",
    "trashmail.io",
    "tryninja.io",
    "tuta.io",
  ],
  fun: [
    "51xh.fun",
    "facenewsk.fun",
    "ggmob-us.fun",
    "keluruk.fun",
    "lalala.fun",
    "mailboxy.fun",
    "mailox.fun",
    "nbhsssib.fun",
    "newviral.fun",
    "picknameme.fun",
    "roundclap.fun",
    "savetimeerr.fun",
    "top5news.fun",
    "topfivestars.fun",
    "topplayers.fun",
  ],
  club: [
    "54np.club",
    "ac-nation.club",
    "amail.club",
    "asiapmail.club",
    "banit.club",
    "barcalovers.club",
    "bestchoiceofweb.club",
    "bestoption25.club",
    "bobandvikki.club",
    "buon.club",
    "cars2.club",
    "chratechbeest.club",
    "clonefbtmc1.club",
    "cls-audio.club",
    "cmail.club",
    "coinbroker.club",
    "coinlink.club",
    "crypto-net.club",
    "cutout.club",
    "cyber-innovation.club",
    "duck2.club",
    "eatmea2z.club",
    "ezfill.club",
    "firamax.club",
    "freshviralnewz.club",
    "fuckme69.club",
    "fun2night.club",
    "giuras.club",
    "givememail.club",
    "goodjab.club",
    "happykorea.club",
    "icemail.club",
    "ilovemyniggers.club",
    "istakalisa.club",
    "kampoeng3d.club",
    "ketiksms.club",
    "kiham.club",
    "lala-mailbox.club",
    "locanto1.club",
    "locantowsite.club",
    "mail.bestoption25.club",
    "mail.hanungofficial.club",
    "mail22.club",
    "makemenaughty.club",
    "makepleasure.club",
    "max88.club",
    "mendoanmail.club",
    "metalrika.club",
    "mooecofficail.club",
    "mythnick.club",
    "naaughty.club",
    "nodnor.club",
    "ocotbukanmain.club",
    "oohotmail.club",
    "openmindedzone.club",
    "planet-travel.club",
    "poopiebutt.club",
    "quick-mail.club",
    "rarame.club",
    "reality-concept.club",
    "riamof.club",
    "robo3.club",
    "robot2.club",
    "royalmarket.club",
    "satisfyme.club",
    "sinda.club",
    "socrazy.club",
    "techno5.club",
    "temp1.club",
    "temp2.club",
    "theopposition.club",
    "theroyalweb.club",
    "tntitans.club",
    "tokuriders.club",
    "totobet.club",
    "ussv.club",
    "wazabi.club",
    "wmail.club",
    "x4y.club",
  ],
  at: [
    "8191.at",
    "aon.at",
    "bin.8191.at",
    "centermail.at",
    "forfree.at",
    "gmx.at",
    "gynzy.at",
    "ist-genial.at",
    "kitznet.at",
    "labetteraverouge.at",
    "live.at",
    "lycos.at",
    "mail.htl22.at",
    "mk24.at",
    "net4you.at",
    "outlook.at",
    "partynight.at",
    "pii.at",
    "posteo.at",
    "qibl.at",
    "rcpt.at",
    "sent.at",
    "sms.at",
    "sp.woot.at",
    "spl.at",
    "tiscali.at",
    "trash-mail.at",
    "trashmail.at",
    "wegwerf-email.at",
    "wiki.8191.at",
    "yahoo.at",
  ],
  am: ["88.am", "i.am", "xyz.am"],
  nu: [
    "888.nu",
    "bot.nu",
    "com.nu",
    "email.nu",
    "lokum.nu",
    "mail.nu",
    "merseine.nu",
    "mine.nu",
    "mjukglass.nu",
    "po.bot.nu",
    "shacknet.nu",
  ],
  site: [
    "9me.site",
    "ahyars.site",
    "akunamatata.site",
    "alessia1818.site",
    "batpeer.site",
    "bitpost.site",
    "buzzzyaskz.site",
    "dr69.site",
    "easybedb.site",
    "freeforall.site",
    "freezzzm.site",
    "glassaas.site",
    "go1.site",
    "indogame.site",
    "jacckpot.site",
    "lalala.site",
    "mahiidev.site",
    "mailadresim.site",
    "malove.site",
    "matra.site",
    "mediciine.site",
    "memsg.site",
    "minishop.site",
    "mirmirchi.site",
    "moparayes.site",
    "musttufa.site",
    "mybx.site",
    "mywrld.site",
    "pokeett.site",
    "promail.site",
    "rafmix.site",
    "rblx.site",
    "resepku.site",
    "robo3.site",
    "sellim.site",
    "sexxfun69.site",
    "sikdar.site",
    "sman14kabtangerang.site",
    "sucknfuck.site",
    "takeitme.site",
    "wantplay.site",
    "wemel.site",
    "wiz2.site",
    "ymail.site",
    "zain.site",
  ],
  win: [
    "a1aemail.win",
    "absolutesuccess.win",
    "andetne.win",
    "b2email.win",
    "bbmail.win",
    "beupmore.win",
    "c3email.win",
    "conventionstrategy.win",
    "danceml.win",
    "ddmail.win",
    "dennmail.win",
    "eeemail.win",
    "emailr.win",
    "fakemail.win",
    "famail.win",
    "fizmail.win",
    "gbcmail.win",
    "gedmail.win",
    "geomail.win",
    "girlmail.win",
    "gmxmail.win",
    "hccmail.win",
    "igiveu.win",
    "ikke.win",
    "ioemail.win",
    "ipemail.win",
    "iqemail.win",
    "mailzen.win",
    "onmail.win",
    "outmail.win",
    "ppymail.win",
    "qqzymail.win",
    "spambox.win",
    "teamrnd.win",
    "tempmail.win",
    "vemomail.win",
    "verifymail.win",
    "yamail.win",
    "yannmail.win",
  ],
  au: [
    "ozemail.com.au",
    "aapt.net.au",
    "adam.com.au",
    "an.id.au",
    "aol.com.au",
    "artlover.com.au",
    "aussiemail.com.au",
    "bickmail.com.au",
    "bigblue.net.au",
    "bigpond.au",
    "bigpond.com.au",
    "bigpond.net.au",
    "chocaholic.com.au",
    "dodo.com.au",
    "ekidz.com.au",
    "exemail.com.au",
    "fastmail.com.au",
    "freemail.com.au",
    "gamil.com.au",
    "gmail.com.au",
    "hello.net.au",
    "hotmail.com.au",
    "i-mail.com.au",
    "iinet.net.au",
    "inet.net.au",
    "iprimus.com.au",
    "jamit.com.au",
    "kmail.com.au",
    "lionsfan.com.au",
    "live.com.au",
    "live.vu.edu.au",
    "looksmart.com.au",
    "mbox.com.au",
    "mcmedia.com.au",
    "medical.net.au",
    "netspace.net.au",
    "optusnet.com.au",
    "outlook.com.au",
    "ozbytes.net.au",
    "racingfan.com.au",
    "science.com.au",
    "start.com.au",
    "telstra.com.au",
    "thembones.com.au",
    "tpg.com.au",
    "virginbroadband.com.au",
    "westnet.com.au",
    "winmail.com.au",
    "yahoo.com.au",
  ],
  ch: [
    "abc1.ch",
    "blip.ch",
    "bluemail.ch",
    "bluewin.ch",
    "ctos.ch",
    "dplanet.ch",
    "e.shapoo.ch",
    "gmx.ch",
    "google.ch",
    "hackthatbit.ch",
    "hispeed.ch",
    "hotmail.ch",
    "labo.ch",
    "lain.ch",
    "mail.ch",
    "miodonski.ch",
    "nanonym.ch",
    "nomail.ch",
    "ntt.gotdns.ch",
    "posteo.ch",
    "postino.ch",
    "protonmail.ch",
    "shapoo.ch",
    "swissonline.ch",
    "t-online.ch",
    "teleport.ch",
    "u.labo.ch",
    "veryday.ch",
    "webtrip.ch",
    "yahoo.ch",
    "youveo.ch",
  ],
  tech: [
    "abcda.tech",
    "airmail.tech",
    "angesti.tech",
    "blan.tech",
    "hoanglong.tech",
    "infoprice.tech",
    "jancuk.tech",
    "mail.wvwvw.tech",
    "mohmal.tech",
    "uptodate.tech",
    "vinsmoke.tech",
    "vmail.tech",
    "wbdev.tech",
  ],
  men: [
    "abcmail.men",
    "amail.men",
    "ccmail.men",
    "chokiwnl.men",
    "dcemail.men",
    "fewminor.men",
    "freehosting.men",
    "gameme.men",
    "getfun.men",
    "gxemail.men",
    "iccmail.men",
    "instafun.men",
    "iuemail.men",
    "jcdmail.men",
    "kuemail.men",
    "qqqwwwil.men",
    "technikue.men",
    "wudet.men",
    "xyzmail.men",
    "zymail.men",
  ],
  tm: ["abcz.info.tm", "mail2.info.tm", "zmail.info.tm"],
  online: [
    "abiasa.online",
    "alexbox.online",
    "bannedpls.online",
    "botz.online",
    "clearmail.online",
    "crazymail.online",
    "email-list.online",
    "hard-life.online",
    "himail.online",
    "lala-mailbox.online",
    "lifeguru.online",
    "mail4.online",
    "mailapps.online",
    "mybisnis.online",
    "mygoldenmail.online",
    "myletter.online",
    "mysecurebox.online",
    "next-mail.online",
    "oohotmail.online",
    "ourawesome.online",
    "outlookkk.online",
    "placemail.online",
    "quick-mail.online",
    "rajasoal.online",
    "royalmarket.online",
    "secure-box.online",
    "sexyalwax.online",
    "socrazy.online",
    "thereddoors.online",
    "urbanstudios.online",
    "webaward.online",
    "webtempmail.online",
    "wibu.online",
    "world-travel.online",
    "youmails.online",
  ],
  sexy: ["abigail69.sexy"],
  bg: ["abv.bg", "arcotronics.bg", "lex.bg", "mail.bg", "orbitel.bg", "topteam.bg"],
  cr: ["acceso.or.cr", "boun.cr", "cariari.ucr.ac.cr", "posteo.cr", "tigo.cr", "ucr.ac.cr"],
  gy: ["ace.ace.gy", "humn.ws.gy", "ws.gy"],
  ltd: ["acfddy.ltd", "josse.ltd", "nada.ltd"],
  so: ["active.so", "bigprofessor.so", "go.irc.so", "irc.so", "linuxmail.so"],
  bike: ["adelaide.bike"],
  uy: ["adinet.com.uy", "montevideo.com.uy"],
  th: ["admin.in.th", "hotmail.co.th", "manager.in.th", "outlook.co.th", "yahoo.co.th"],
  pt: [
    "aeiou.pt",
    "avls.pt",
    "dei.uminho.pt",
    "educacao.te.pt",
    "gmx.pt",
    "iol.pt",
    "jovem.te.pt",
    "live.com.pt",
    "luso.pt",
    "mail.pt",
    "megamail.pt",
    "net4b.pt",
    "oninet.pt",
    "outlook.pt",
    "portugalmail.pt",
    "posteo.pt",
    "sapo.pt",
    "telepac.pt",
    "universal.pt",
    "yahoo.pt",
    "zmail.pt",
  ],
  jp: [
    "ahk.jp",
    "aol.jp",
    "dob.jp",
    "docomo.ne.jp",
    "e-mail.jp",
    "eay.jp",
    "email.plala.or.jp",
    "enjoy.email.ne.jp",
    "excite.co.jp",
    "ezweb.ne.jp",
    "fastmail.jp",
    "gally.jp",
    "hacked.jp",
    "hotmail.co.jp",
    "hotmail.jp",
    "i-mail.jp",
    "i.softbank.jp",
    "infomart.or.jp",
    "infoseek.jp",
    "inter7.jp",
    "jcom.home.ne.jp",
    "kyoto.email.ne.jp",
    "live.jp",
    "lycos.ne.jp",
    "mail.bbexcite.jp",
    "mail.goo.ne.jp",
    "mail1.hacked.jp",
    "mar.email.ne.jp",
    "my.email.ne.jp",
    "my.softbank.jp",
    "net.email.ne.jp",
    "ntc.keio.ac.jp",
    "ocn.ne.jp",
    "outlook.jp",
    "papa.email.ne.jp",
    "posteo.jp",
    "press.co.jp",
    "saitama.email.ne.jp",
    "sakura.ne.jp",
    "sea.plala.or.jp",
    "softbank.ne.jp",
    "supermailer.jp",
    "sute.jp",
    "svk.jp",
    "temp.mail.y59.jp",
    "ux.dob.jp",
    "via.tokyo.jp",
    "y.email.ne.jp",
    "y59.jp",
    "yahoo.co.jp",
    "yahoo.jp",
    "ybb.ne.jp",
    "ze.gally.jp",
  ],
  cx: [
    "aiot.ze.cx",
    "avr.ze.cx",
    "baxomale.ht.cx",
    "cloudns.cx",
    "edu.aiot.ze.cx",
    "ht.cx",
    "i6.cloudns.cx",
    "nomail.xl.cx",
    "xl.cx",
    "yop.ze.cx",
    "ze.cx",
  ],
  website: [
    "airmailbox.website",
    "echtzeit.website",
    "flowerss.website",
    "kanker.website",
    "pizzanadiapro.website",
    "premiumperson.website",
    "pubgm.website",
    "sendbananas.website",
    "sign-up.website",
    "thisurl.website",
  ],
  kr: [
    "lko.kr",
    "nuo.kr",
    "tko.kr",
    "vba.kr",
    "aji.kr",
    "bei.kr",
    "bel.kr",
    "beo.kr",
    "bfo.kr",
    "bho.kr",
    "bko.kr",
    "cid.kr",
    "cko.kr",
    "dbo.kr",
    "dko.kr",
    "doy.kr",
    "efo.kr",
    "eho.kr",
    "ely.kr",
    "emy.kr",
    "enu.kr",
    "eny.kr",
    "ese.kr",
    "ewa.kr",
    "exi.kr",
    "foy.kr",
    "gok.kr",
    "hix.kr",
    "hiz.kr",
    "hotmail.co.kr",
    "iki.kr",
    "irr.kr",
    "jil.kr",
    "jto.kr",
    "lal.kr",
    "lbe.kr",
    "lei.kr",
    "live.co.kr",
    "lko.co.kr",
    "lom.kr",
    "loy.kr",
    "luo.kr",
    "lycos.co.kr",
    "mall.tko.co.kr",
    "mbe.kr",
    "mko.kr",
    "mlo.kr",
    "nko.kr",
    "npv.kr",
    "nuo.co.kr",
    "obo.kr",
    "outlook.kr",
    "owa.kr",
    "oyu.kr",
    "poy.kr",
    "qbi.kr",
    "rao.kr",
    "rko.kr",
    "row.kr",
    "ruu.kr",
    "tko.co.kr",
    "tmo.kr",
    "toi.kr",
    "uha.kr",
    "uko.kr",
    "umy.kr",
    "uny.kr",
    "upy.kr",
    "uvy.kr",
    "uyu.kr",
    "vay.kr",
    "veo.kr",
    "wil.kr",
    "www.live.co.kr.beo.kr",
    "yahoo.co.kr",
  ],
  hu: [
    "lajt.hu",
    "ak47.hu",
    "alvilag.hu",
    "avh.hu",
    "bho.hu",
    "c2.hu",
    "c3.hu",
    "cia.hu",
    "citromail.hu",
    "coder.hu",
    "cracker.hu",
    "csinibaba.hu",
    "drotposta.hu",
    "eposta.hu",
    "euromail.hu",
    "fbi.hu",
    "fleckens.hu",
    "freemail.c3.hu",
    "freemail.hu",
    "freestart.hu",
    "geek.hu",
    "gimesson.pe.hu",
    "gportal.hu",
    "gyorsposta.hu",
    "hello.hu",
    "hotmail.hu",
    "indamail.hu",
    "irj.hu",
    "jakuza.hu",
    "jobbikszimpatizans.hu",
    "kgb.hu",
    "lajoska.pe.hu",
    "lamer.hu",
    "levele.hu",
    "maffia.hu",
    "mail.vasarhely.hu",
    "mailbox.hu",
    "me-mail.hu",
    "message.hu",
    "nincsmail.hu",
    "no-spam.hu",
    "odavissza.hu",
    "outlook.hu",
    "pe.hu",
    "pobox.hu",
    "postafiok.hu",
    "posteo.hu",
    "primposta.hu",
    "pro.hu",
    "programozo.hu",
    "reply.hu",
    "send.hu",
    "shotgun.hu",
    "skizo.hu",
    "sniper.hu",
    "soldier.hu",
    "t-online.hu",
    "theend.hu",
    "uni-miskolc.hu",
    "vipmail.hu",
    "warrior.hu",
    "webmail.hu",
    "yahoo.hu",
  ],
  kg: ["aknet.kg", "freenet.kg", "hotmail.kg", "netmail.kg"],
  al: ["akorde.al", "info.al"],
  ae: ["aldeyaa.ae", "xd.ae", "yahoo.ae"],
  cz: [
    "alive.cz",
    "atlas.cz",
    "centrum.cz",
    "chalupaurybnicku.cz",
    "docmail.cz",
    "email.cz",
    "hotmail.cz",
    "hypotekyonline.cz",
    "inmail.cz",
    "ladymail.cz",
    "love.cz",
    "mail-box.cz",
    "myneocards.cz",
    "outlook.cz",
    "post.cz",
    "posteo.cz",
    "sendme.cz",
    "seznam.cz",
    "sweb.cz",
    "volny.cz",
    "wowgoldy.cz",
    "yahoo.cz",
  ],
  wtf: ["alph.wtf", "mail.wtf"],
  se: [
    "altavista.se",
    "appc.se",
    "beerolympics.se",
    "blackhole.djurby.se",
    "bopunkten.se",
    "ceed.se",
    "fastmail.se",
    "home.se",
    "hotmail.se",
    "lez.se",
    "liu.se",
    "live.se",
    "lkgn.se",
    "mm5.se",
    "posteo.se",
    "slaskpost.se",
    "spray.se",
    "student.liu.se",
    "tiscali.se",
    "ty.ceed.se",
    "urfunktion.se",
    "yahoo.se",
    "zipzaprap.beerolympics.se",
  ],
  edu: [
    "austincollege.edu",
    "my.liu.edu",
    "student.framingham.edu",
    "unco.edu",
    "alumni.tufts.edu",
    "auburn.edu",
    "austin.utexas.edu",
    "australia.edu",
    "baylor.edu",
    "butler.edu",
    "cabrini.edu",
    "catamount.wcu.edu",
    "chp.edu",
    "columbia.edu",
    "cps.edu",
    "crimson.ua.edu",
    "d.umn.edu",
    "depauw.edu",
    "drexel.edu",
    "eiu.edu",
    "email.phoenix.edu",
    "england.edu",
    "eureka.edu",
    "franklinpierce.edu",
    "g.risd.edu",
    "gcc.edu",
    "gwmail.gwu.edu",
    "hkapa.edu",
    "lagcc.cuny.edu",
    "mail.goucher.edu",
    "mail.tmcc.edu",
    "mail.uc.edu",
    "mail.wlu.edu",
    "maricopa.edu",
    "mci.edu",
    "mst.edu",
    "muohio.edu",
    "my.apsu.edu",
    "my.fsu.edu",
    "my.mcphs.edu",
    "my.uri.edu",
    "mymail.nku.edu",
    "ncf.edu",
    "nid.edu",
    "ohsu.edu",
    "onu.edu",
    "pacificcollege.edu",
    "pratt.edu",
    "purdue.edu",
    "rice.edu",
    "rit.edu",
    "salud.unm.edu",
    "sfsu.edu",
    "skema.edu",
    "stanford.edu",
    "stcloudstate.edu",
    "student.american.edu",
    "student.kaplan.edu",
    "uc.edu",
    "udel.edu",
    "ufl.edu",
    "uky.edu",
    "umich.edu",
    "umn.edu",
    "uncg.edu",
    "ustc.edu",
    "utoledo.edu",
    "uw.edu",
    "uwsp.edu",
    "vermontlaw.edu",
    "virginia.edu",
    "weber.edu",
    "wesleyan.edu",
    "wiu.edu",
    "ysu.edu",
  ],
  ca: [
    "alumni.uwo.ca",
    "bellnet.ca",
    "burnmail.ca",
    "cgocable.ca",
    "cogeco.ca",
    "coolgoose.ca",
    "datazo.ca",
    "e.l5.ca",
    "eastlink.ca",
    "efxs.ca",
    "fastmail.ca",
    "ftpinc.ca",
    "gardenscape.ca",
    "gmx.ca",
    "hotmail.ca",
    "ihazspam.ca",
    "intergate.bc.ca",
    "l5.ca",
    "live.ca",
    "ml8.ca",
    "my.efxs.ca",
    "mymhc.ca",
    "net-c.ca",
    "netcom.ca",
    "nothingtoseehere.ca",
    "phas.ubc.ca",
    "physics.ubc.ca",
    "rabin.ca",
    "realtyalerts.ca",
    "runi.ca",
    "shaw.ca",
    "sympatico.ca",
    "tcc.on.ca",
    "thexyz.ca",
    "usask.ca",
    "videotron.ca",
    "webcity.ca",
    "xgaming.ca",
    "yahoo.ca",
  ],
  hm: ["america.hm", "at.hm", "emailx.at.hm"],
  pw: [
    "bgisfri.pw",
    "bringluck.pw",
    "fellowme.pw",
    "hellowman.pw",
    "luckjob.pw",
    "richfunds.pw",
    "ucansuc.pw",
    "ankoninc.pw",
    "anywhere.pw",
    "bdmuzic.pw",
    "belastingdienst.pw",
    "best-day.pw",
    "bestday.pw",
    "bestfuture.pw",
    "bestlucky.pw",
    "bestwishes.pw",
    "bgi-sfr-i.pw",
    "bring-luck.pw",
    "btcmail.pw",
    "cane.pw",
    "checknew.pw",
    "come-on-day.pw",
    "comeonday.pw",
    "cool-your.pw",
    "coolyour.pw",
    "crazespaces.pw",
    "day-one.pw",
    "dayone.pw",
    "fellow-me.pw",
    "flyxnet.pw",
    "fmail.pw",
    "futuregood.pw",
    "garasikita.pw",
    "get365.pw",
    "gnail.pw",
    "gooday.pw",
    "goodsmart.pw",
    "happyyou.pw",
    "hellow-man.pw",
    "hellowperson.pw",
    "hoer.pw",
    "ideepmind.pw",
    "inboxed.pw",
    "indomaed.pw",
    "justbegood.pw",
    "kah.pw",
    "keeplucky.pw",
    "kemska.pw",
    "lightpower.pw",
    "linkedintuts2016.pw",
    "luckboy.pw",
    "mepost.pw",
    "montokop.pw",
    "myalias.pw",
    "nacho.pw",
    "nomail.pw",
    "noway.pw",
    "ok-body.pw",
    "okbody.pw",
    "penguincreationdate.pw",
    "perfect-u.pw",
    "perfectu.pw",
    "rich-money.pw",
    "richfinances.pw",
    "richmoney.pw",
    "richsmart.pw",
    "rotate.pw",
    "secmail.pw",
    "smaretboy.pw",
    "smarttalent.pw",
    "successforu.pw",
    "toss.pw",
    "trg.pw",
    "u-wills-uc.pw",
    "ucandobest.pw",
    "udoiswell.pw",
    "ugreatejob.pw",
    "uwillsuc.pw",
    "vesa.pw",
    "vipmail.pw",
    "vuiy.pw",
    "wealthymoney.pw",
    "youbestone.pw",
  ],
  accountant: ["anypen.accountant"],
  lv: [
    "apollo.lv",
    "atl.lv",
    "dx.ez.lv",
    "e-apollo.lv",
    "eriga.lv",
    "ez.lv",
    "h.thc.lv",
    "inbox.lv",
    "mail.lv",
    "mailme.lv",
    "navigator.lv",
    "outlook.lv",
    "pwp.lv",
    "sejaa.lv",
    "tvnet.lv",
    "wer.ez.lv",
  ],
  app: ["apple-account.app", "appleaccount.app"],
  dj: ["apps.dj", "mega.zik.dj", "zik.dj"],
  jetzt: ["ariaz.jetzt"],
  ar: [
    "arnet.com.ar",
    "ciudad.com.ar",
    "com.ar",
    "ebs.com.ar",
    "fibertel.com.ar",
    "hotmail.com.ar",
    "infovia.com.ar",
    "interlap.com.ar",
    "keko.com.ar",
    "live.com.ar",
    "my.vondata.com.ar",
    "netizen.com.ar",
    "onenet.com.ar",
    "outlook.com.ar",
    "serga.com.ar",
    "speedy.com.ar",
    "terra.com.ar",
    "topmail.com.ar",
    "uol.com.ar",
    "web-mail.com.ar",
    "yahoo.com.ar",
  ],
  fi: [
    "arno.fi",
    "e.arno.fi",
    "elisanet.fi",
    "hotmail.fi",
    "iobox.fi",
    "jippii.fi",
    "kolumbus.fi",
    "netti.fi",
    "posteo.fi",
    "pp.inet.fi",
    "sci.fi",
    "suomi24.fi",
    "yahoo.fi",
  ],
  mx: [
    "asu.mx",
    "cavi.mx",
    "colegio-frances.edu.mx",
    "decoymail.mx",
    "fastmail.mx",
    "fcml.mx",
    "hotmail.com.mx",
    "itesm.mx",
    "lee.mx",
    "live.com.mx",
    "posteo.mx",
    "prodigy.net.mx",
    "uol.com.mx",
    "yahoo.com.mx",
  ],
  st: [
    "auti.st",
    "biz.st",
    "ethos.st",
    "fea.st",
    "ind.st",
    "ma-boite-aux-lettres.infos.st",
    "mm.st",
    "our.st",
    "thc.st",
    "vpn.st",
    "yopmail.biz.st",
  ],
  sk: [
    "azet.sk",
    "centrum.sk",
    "gynzy.sk",
    "inmail.sk",
    "lmxmail.sk",
    "miesto.sk",
    "outlook.sk",
    "pobox.sk",
    "post.sk",
  ],
  id: [
    "bacapedia.web.id",
    "belanjaonlineku.web.id",
    "flickshot.id",
    "gaf.oseanografi.id",
    "indosat.net.id",
    "mobi.web.id",
    "outlook.co.id",
    "qoo-10.id",
    "techindo.web.id",
    "web.id",
    "yahoo.co.id",
  ],
  live: [
    "badoo.live",
    "confirm.live",
    "mail-address.live",
    "maillink.live",
    "mailpm.live",
    "temp-mail.live",
  ],
  bid: ["bakar.bid", "hantem.bid", "qbmail.bid", "sapbox.bid"],
  cat: ["bambis.cat", "forward.cat", "net-c.cat"],
  vn: [
    "banhbeovodich.vn",
    "come.vn",
    "cuibap.vn",
    "long.idn.vn",
    "no1.vn",
    "outlook.com.vn",
    "soc.vn",
    "trung.name.vn",
    "very.vn",
    "vnn.vn",
    "xn--b-dga.vn",
    "yahoo.com.vn",
    "zing.vn",
  ],
  gs: ["barafa.gs", "n8.gs"],
  ws: [
    "bareed.ws",
    "bcast.ws",
    "blackbird.ws",
    "dyndns.ws",
    "indirect.ws",
    "krim.ws",
    "moakt.ws",
    "mypets.ws",
    "no-spam.ws",
    "pochta.ws",
    "shut.ws",
    "tempmail.ws",
    "tmail.ws",
    "trashmail.ws",
    "unkn0wn.ws",
  ],
  today: ["basssi.today"],
  date: [
    "bcdmail.date",
    "cdcmail.date",
    "demandfull.date",
    "qwqrwsf.date",
    "sucknfuck.date",
    "yuuywil.date",
  ],
  tv: [
    "better-than.tv",
    "galaxy.tv",
    "i-taiwan.tv",
    "irssi.tv",
    "mail1.i-taiwan.tv",
    "mail1.kaohsiung.tv",
    "mailtv.tv",
    "on-the-web.tv",
    "worse-than.tv",
  ],
  no: ["bi.no", "hotmail.no", "live.no", "posteo.no", "spray.no", "yahoo.no"],
  press: ["birdsfly.press", "breadtimes.press", "indosukses.press", "jokenaka.press"],
  br: [
    "bol.com.br",
    "boxtemp.com.br",
    "brazilmail.com.br",
    "brfree.com.br",
    "cool.br",
    "dglnet.com.br",
    "emailtemporario.com.br",
    "entregandobiblia.com.br",
    "francanet.com.br",
    "globalsite.com.br",
    "gmail.com.br",
    "hostload.com.br",
    "hotmail.com.br",
    "ig.com.br",
    "itelefonica.com.br",
    "karitas.com.br",
    "mailbr.com.br",
    "nospam.wins.com.br",
    "oi.com.br",
    "osite.com.br",
    "outlook.com.br",
    "plusmail.com.br",
    "posteo.com.br",
    "riopreto.com.br",
    "seguros.com.br",
    "sul.com.br",
    "superig.com.br",
    "temporarioemail.com.br",
    "terra.com.br",
    "ubbi.com.br",
    "unama.br",
    "uol.com.br",
    "veloxmail.com.br",
    "wins.com.br",
    "yahoo.com.br",
    "zipmail.com.br",
  ],
  ovh: [
    "bold.ovh",
    "crashkiller.ovh",
    "it7.ovh",
    "jmail.ovh",
    "lerch.ovh",
    "next.ovh",
    "nospammer.ovh",
    "rush.ovh",
    "uu2.ovh",
    "zx81.ovh",
  ],
  az: ["box.az", "mail.az"],
  np: ["box.yadavnaresh.com.np", "namunathapa.com.np", "yadavnaresh.com.np"],
  gr: [
    "cad.edu.gr",
    "fastmail.gr",
    "forthnet.gr",
    "freemail.gr",
    "gynzy.gr",
    "hol.gr",
    "hotmail.gr",
    "mail.gr",
    "mailbox.gr",
    "mailgate.gr",
    "otenet.gr",
    "outlook.com.gr",
    "posteo.gr",
    "tafoi.gr",
    "vip.gr",
    "wef.gr",
    "yahoo.gr",
  ],
  icu: [
    "canitta.icu",
    "cdcovers.icu",
    "funnyrabbit.icu",
    "gett.icu",
    "gitpost.icu",
    "icunet.icu",
    "mailfirst.icu",
    "mrflibble.icu",
    "muell.icu",
    "oida.icu",
    "portalweb.icu",
    "psirens.icu",
    "rockyoujit.icu",
    "spectro.icu",
    "topdait.icu",
    "topiphone.icu",
    "zerodog.icu",
  ],
  liamria: ["cc.liamria"],
  fm: ["cdr.fastmail.fm", "f-m.fm", "fastmail.fm", "poczta.fm", "simbamail.fm", "ua.fm"],
  pm: ["cek.pm", "posteo.pm"],
  tc: ["ch.tc", "kir.ch.tc", "mit.tc", "nospam.ze.tc", "ze.tc"],
  work: [
    "chechnya.conf.work",
    "conf.work",
    "config.work",
    "gamno.config.work",
    "hotmail.work",
    "notion.work",
    "successlocation.work",
    "xoxy.work",
  ],
  com12: ["check.com12"],
  md: ["childrens.md", "icu.md", "mail.md", "neuro.md", "ournet.md", "ubm.md"],
  vg: ["china.net.vg"],
  la: ["choco.la", "esc.la", "grr.la", "posteo.la", "rax.la", "spam.la", "wha.la"],
  gl: ["cl.gl", "gen.uu.gl", "mor19.uu.gl", "phpbb.uu.gl", "posteo.gl", "uu.gl", "xing886.uu.gl"],
  nz: [
    "clear.net.nz",
    "hotmail.co.nz",
    "outlook.co.nz",
    "paradise.net.nz",
    "posteo.nz",
    "slingshot.co.nz",
    "woosh.co.nz",
    "xtra.co.nz",
    "yahoo.co.nz",
    "yahooxtra.co.nz",
  ],
  ne: ["close2you.ne"],
  nr: ["co.nr"],
  company: ["cobin2hood.company", "concealed.company"],
  ninja: ["cocaine.ninja", "est.une.victime.ninja", "msgsafe.ninja", "victime.ninja"],
  life: [
    "silvercoin.life",
    "coffeelovers.life",
    "confidential.life",
    "cpmail.life",
    "daymail.life",
    "die.life",
    "emailna.life",
    "mobaratopcinq.life",
    "ourawesome.life",
    "royalmarket.life",
    "silsilah.life",
  ],
  pn: ["com.au.pn"],
  nf: [
    "cool.fr.nf",
    "courriel.fr.nf",
    "fr.nf",
    "gmx.fr.nf",
    "gratosmail.fr.nf",
    "jetable.fr.nf",
    "jmail.fr.nf",
    "moncourrier.fr.nf",
    "monemail.fr.nf",
    "monmail.fr.nf",
    "yopmail.fr.nf",
  ],
  gt: ["correo.terra.com.gt"],
  pink: ["cream.pink", "merry.pink"],
  webcam: ["damai.webcam"],
  by: ["date.by", "mail.by", "np.by", "pochta.by", "ring.by", "tut.by", "tyt.by", "yandex.by"],
  mil: ["ddg70.navy.mil"],
  tw: [
    "drama.tw",
    "fastmail.tw",
    "gmx.tw",
    "hpc.tw",
    "mail.vrfarm.com.tw",
    "mail1.drama.tw",
    "mail2.drama.tw",
    "mail3.drama.tw",
    "mail4.drama.tw",
    "mail5.drama.tw",
    "my365.tw",
    "ohi.tw",
    "sino.tw",
    "yahoo.com.tw",
  ],
  si: [
    "drevo.si",
    "email.si",
    "f5.si",
    "goat.si",
    "hi5.si",
    "inbox.si",
    "j.aq.si",
    "piki.si",
    "posteo.si",
  ],
  ie: [
    "dublin.ie",
    "gmx.ie",
    "live.ie",
    "mail.dcu.ie",
    "mail.ie",
    "online.ie",
    "outlook.ie",
    "posteo.ie",
    "spd.dcu.ie",
    "tcd.ie",
    "ul.ie",
    "upcmail.ie",
    "vodafone.ie",
    "yahoo.ie",
  ],
  cafe: ["duoduo.cafe"],
  im: ["dw.now.im", "fastmail.im", "inboxed.im", "mohmal.im", "mxit.im", "now.im", "wpg.im"],
  tr: [
    "e-mail.com.tr",
    "hotmail.com.tr",
    "mail.com.tr",
    "outlook.com.tr",
    "yahoo.com.tr",
    "yandex.com.tr",
  ],
  mn: ["electro.mn"],
  ee: ["email.ee", "hot.ee", "mail.ee", "posteo.ee", "uno.ee"],
  rs: ["eunet.rs", "mdu.edu.rs", "nigge.rs", "sbb.rs", "telenormail.rs"],
  kh: ["everyday.com.kh"],
  loan: ["extraam.loan", "legalrc.loan", "masasih.loan"],
  wf: ["fag.wf"],
  one: ["fast-mail.one", "mailing.one"],
  pe: ["free.com.pe", "outlook.com.pe", "posteo.pe", "terra.com.pe", "yahoo.com.pe"],
  casa: ["freedom.casa"],
  pk: ["freemail.com.pk"],
  et: ["freemail.et"],
  lt: ["freemail.lt", "gynzy.lt", "inbox.lt", "posteo.lt", "ro.lt", "sr.ro.lt"],
  ms: ["freemail.ms", "mailservice.ms", "online.ms", "server.ms"],
  mk: ["freemail.org.mk", "naim.mk"],
  sh: ["ftp.sh", "jp.ftp.sh"],
  tokyo: ["furusato.tokyo", "studiopolka.tokyo"],
  store: ["gabox.store", "highme.store", "highonline.store", "posta.store"],
  sx: ["glitch.sx"],
  ax: ["gmail.ax"],
  cm: ["gmail.cm", "hotmail.cm", "yahoo.cm"],
  ph: ["gmail.com.ph", "hotmail.ph", "outlook.ph", "yahoo.com.ph"],
  om: ["gmail.om", "yahoo.om"],
  sg: [
    "gmx.sg",
    "host-it.com.sg",
    "hotmail.sg",
    "live.com.sg",
    "lvie.com.sg",
    "netnet.com.sg",
    "nus.edu.sg",
    "outlook.sg",
    "posteo.sg",
    "singnet.com.sg",
    "yahoo.com.sg",
  ],
  py: ["go2.com.py"],
  zone: ["gregorsky.zone"],
  schule: ["griuc.schule"],
  mobi: ["gynzy.mobi", "hellodream.mobi", "thexyz.mobi"],
  guru: ["happymail.guru"],
  ai: ["hooohush.ai", "hush.ai"],
  il: [
    "hotmail.co.il",
    "intermail.co.il",
    "nana10.co.il",
    "netvision.net.il",
    "outlook.co.il",
    "vmail.co.il",
    "walla.co.il",
    "yahoo.co.il",
    "zahav.net.il",
  ],
  con: ["hotmail.con"],
  kz: ["hotmail.kz", "ok.kz", "yandex.kz"],
  my: [
    "hotmail.my",
    "live.com.my",
    "mm.my",
    "myemail.my",
    "outlook.my",
    "posteo.my",
    "puppy.com.my",
    "qq.my",
    "skkk.edu.my",
    "we.qq.my",
    "wee.my",
    "yahoo.com.my",
  ],
  roor: ["hotmail.roor"],
  vc: ["hs.vc", "radiku.ye.vc", "ye.vc", "yert.ye.vc"],
  bz: ["ibnuh.bz"],
  link: ["icemovie.link", "imovie.link", "oshietechan.link", "streamfly.link", "worldspace.link"],
  faith: [
    "ichichich.faith",
    "kanciang.faith",
    "lovemeet.faith",
    "mmoonz.faith",
    "singssungg.faith",
  ],
  plus: ["inbound.plus"],
  world: ["inboxmail.world", "maswae.world", "temporary-email.world"],
  af: ["is.af", "posteo.af", "us.af"],
  na: ["iway.na"],
  blog: ["kivoid.blog"],
  city: ["kontol.city"],
  ag: ["krd.ag", "themail.krd.ag"],
  gd: ["ksmtrck.rf.gd"],
  review: ["kuikytut.review", "moonm.review", "studioro.review"],
  ink: ["l0l.l1l.ink"],
  design: ["mail.fast10s.design"],
  pf: ["mail.pf"],
  vu: ["mail.vu", "pi.vu"],
  as: ["mailbox.as", "sent.as"],
  lu: [
    "marketing.lu",
    "net-c.lu",
    "netc.lu",
    "posteo.lu",
    "sagra.lu",
    "sagra.lu.lu",
    "sagra.lumarketing.lu",
    "tiscali.lu",
  ],
  re: ["mbox.re"],
  rest: ["messwiththebestdielikethe.rest"],
  lk: ["metta.lk"],
  com15: ["milmail.com15"],
  com19: ["music.com19"],
  equipment: ["mydemo.equipment"],
  services: ["myindohome.services"],
  agency: ["national.shitposting.agency", "robox.agency", "rollindo.agency", "shitposting.agency"],
  gov: ["ncbi.nlm.nih.gov"],
  tf: ["net.tf", "sprin.tf"],
  nondomain: ["nonexisted.nondomain"],
  ze: ["nospam.ze"],
  zw: ["nssa.org.zw"],
  mu: ["orange.mu", "zz.mu"],
  sa: ["outlook.sa"],
  computer: ["penis.computer"],
  party: ["popconn.party", "saxfun.party"],
  hn: ["posteo.hn"],
  is: ["posteo.is", "unseen.is", "yahoo.com.is"],
  tn: ["posteo.tn"],
  racing: ["powerml.racing"],
  team: ["qa.team"],
  best: ["quickmail.best"],
  reisen: ["rabiot.reisen"],
  gg: ["raffles.gg", "widget.gg"],
  trade: ["redpen.trade", "tubruk.trade"],
  technology: ["rejo.technology"],
  ec: ["rma.ec", "tic.ec"],
  ug: ["sci.mak.ac.ug"],
  social: ["showme.social"],
  horse: ["slutty.horse"],
  gravel: ["smaakt.naar.gravel"],
  ney: ["snail-mail.ney"],
  wiki: ["solventtrap.wiki", "webmail.wiki"],
  care: ["spam.care"],
  cricket: ["ssunz.cricket", "wicked.cricket"],
  sn: ["sunumail.sn"],
  accountants: ["syujob.accountants"],
  er: ["tfanus.com.er"],
  gf: ["tfwno.gf"],
  science: ["thunderbolt.science"],
  tz: ["tz.tz"],
  ac: ["uae.ac"],
  cu: ["uci.cu"],
  fyi: ["ultra.fyi"],
  ve: ["uol.com.ve", "uole.com.ve", "yahoo.com.ve"],
  blue: ["webmail.blue"],
  yu: ["webmail.co.yu"],
  fish: ["webmail.fish"],
  lawyer: ["webmail.lawyer"],
  malaysia: ["yahoo.com.malaysia"],
  lb: ["yalla.com.lb"],
  mail: ["yandex.mail"],
  inf: ["zomg.inf"],
  others: [
    "91.200.12.139",
    "91.200.12.141",
    "91.200.12.143",
    "91.200.12.180",
    "91.200.12.19",
    "91.200.12.236",
    "91.200.12.51",
    "91.200.12.63",
    "asean-mail",
    "housefancom",
    "jwspamspy",
    "multiplechoices",
    "boun.cr.",
    "brefmail.com.",
    "brennendesreich.de.",
    "broadbandninja.com.",
    "fxsmails.com&#xA0;",
  ],
};
