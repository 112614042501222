import NotFound from "pages/404";
import { DefaultLayout } from "components/reusables/layouts";

//App Components
import {
  Slot,
  Jobs,
  Login,
  Accept,
  Promote,
  Bookings,
  Settings,
  Register,
  CreateJob,
  UpdateJob,
  Dashboard,
  Candidates,
  DataPrivacy,
  ResetPassword,
  ChangePassword,
  DataProcessing,
  ForgotUserPassword,
  TermsAndConditions,
} from "pages";

const routes = [
  {
    path: "/",
    exact: true,
    role: "recruiter",
    component: <DefaultLayout component={Dashboard} />,
  },
  {
    path: "/jobs",
    exact: true,
    role: "recruiter",
    nested: [
      {
        index: true,
        component: <DefaultLayout component={Jobs} />,
      },
      {
        path: "new",
        component: <DefaultLayout component={CreateJob} />,
      },
      {
        path: "update",
        component: <DefaultLayout component={UpdateJob} />,
      },
    ],
  },
  {
    path: "/candidates",
    exact: true,
    role: "recruiter",
    nested: [
      {
        index: true,
        component: <DefaultLayout component={Candidates} />,
      },
    ],
  },
  {
    path: "/users",
    exact: true,
    role: "recruiter",
    nested: [
      {
        path: "invitation/accept",
        component: <DefaultLayout component={Accept} publicRoute />,
      },
    ],
  },
  {
    path: "/settings",
    exact: true,
    role: "recruiter",
    component: <DefaultLayout component={Settings} />,
  },
  {
    path: "/promote",
    exact: true,
    role: "recruiter",
    nested: [
      {
        index: true,
        component: <DefaultLayout component={Promote} />,
      },
      {
        path: ":board_id/slot",
        component: <DefaultLayout component={Slot} />,
      },
    ],
  },
  {
    path: "/bookings",
    exact: true,
    role: "recruiter",
    component: <DefaultLayout component={Bookings} />,
  },
  {
    path: "/register",
    exact: true,
    role: "recruiter",
    component: <DefaultLayout component={Register} publicRoute />,
  },
  {
    path: "/login",
    exact: true,
    role: "recruiter",
    component: <DefaultLayout component={Login} publicRoute />,
  },
  {
    path: "/forgot_password",
    exact: true,
    role: "recruiter",
    component: <DefaultLayout component={ForgotUserPassword} publicRoute />,
  },
  {
    path: "/change_password",
    exact: true,
    role: "recruiter",
    component: <DefaultLayout component={ChangePassword} publicRoute />,
  },
  {
    path: "/update_password",
    exact: true,
    role: "recruiter",
    component: <DefaultLayout component={ResetPassword} publicRoute />,
  },
  {
    path: "/terms-and-conditions",
    exact: true,
    role: "recruiter",
    component: <DefaultLayout component={TermsAndConditions} publicRoute />,
  },
  {
    path: "/data-privacy-policy",
    exact: true,
    role: "recruiter",
    component: <DefaultLayout component={DataPrivacy} publicRoute />,
  },
  {
    path: "/data-processing-agreements",
    exact: true,
    role: "recruiter",
    component: <DefaultLayout component={DataProcessing} publicRoute />,
  },
  {
    path: "*",
    exact: true,
    role: "recruiter",
    component: <DefaultLayout component={NotFound} publicRoute />,
  },
];

export default routes;
