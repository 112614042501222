import { createContext } from "react";

const MAX_RESHAPED_UNITS = 10;
const spacing = {
  xs: 1, // 4px
  sm: 2, // 8px
  md: 3, // 12px
  lg: 4, // 16px
  xl: 6, // 24px
  xxl: 12, // 48px
};

export const SpacingContext = createContext({
  spacing,
});

export const SpacingProvider = ({ children }) => {
  const spacingCssVars = [];

  Object.entries(spacing).forEach(([key, value]) => {
    if (value > MAX_RESHAPED_UNITS) {
      spacingCssVars.push(`--spacing-${key}: calc(var(--rs-unit-x1) * ${value}); `);
    } else {
      spacingCssVars.push(`--spacing-${key}: var(--rs-unit-x${value}); `);
    }
  });

  const spacingStyle = `<style>
                          [data-spacing="ats"] {
                            ${spacingCssVars.join("")}
                          }
                        </style>`;

  const createStyle = () => {
    return { __html: spacingStyle };
  };

  return (
    <SpacingContext.Provider value={{ spacing }}>
      <div dangerouslySetInnerHTML={createStyle()} />
      <div data-spacing="ats">{children}</div>
    </SpacingContext.Provider>
  );
};
