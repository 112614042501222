import { useMemo } from "react";
import { Avatar, Button, Icon, Text, View } from "reshaped";

import useName from "components/hooks/useName";
import { Attachment, Reload, Trash } from "icons";
import useSpacing from "components/hooks/useSpacing";
import useTranslation from "components/hooks/useTranslation";
import { baseColor, cooperColors } from "utils/colors/accents";
import styles from "components/styles/reshaped/reusables/chat/chat.module.css";
import { areKeysAvailableIn, areKeysAvailableWithType } from "utils/miniHelpers";
import { LinkTextWithEclipse, TextWithEclipses } from "components/reusables/TextHelper";

export const ChatMessage = ({
  data,
  isRecipient = false,
  handleRetryFailedEmail,
  handleDeleteFailedEmail,
  handleDeleteBackendFailedEmail,
}) => {
  const { t, locale } = useTranslation();

  const date = useMemo(() => {
    function getTranslations({ locale = "de", number = "", string = "" }) {
      const getLocaleFunctions = {
        de: ({ number, string }) => {
          if (string === "Yesterday") {
            return t("Yesterday");
          }
          if (string === "days ago") {
            return `vor ${number} Tagen`;
          }
          if (string === "weeks ago") {
            return `vor ${number} Wochen`;
          }
          if (string === "months ago") {
            return `vor ${number} Monaten`;
          }
          if (string === "years ago") {
            return `vor ${number} Jahren`;
          }
          return `${number} ${string}`;
        },
        en: ({ number, string }) => {
          return `${number} ${string}`;
        },
      };
      const result = getLocaleFunctions[locale];
      return typeof result === "function" ? result({ number, string }) : `${number} ${string}`;
    }
    const result = {
      ago: "-",
      title: "",
    };
    if (areKeysAvailableWithType({ object: data.date, keys: [{ full_date: "string" }] })) {
      result.title = data.date.full_date;
      if (areKeysAvailableIn({ object: data.date, keys: ["full_string", "number"] })) {
        const { full_string, number } = data.date;
        if (!number && typeof full_string === "string") {
          result.ago = t(full_string);
        }
        if ((typeof number === "number" || typeof number === "string") && !full_string) {
          result.ago = number;
        }
        if ((typeof number === "number" || typeof number === "string") && full_string) {
          result.ago = getTranslations({ locale, number, string: full_string });
        }
      }
    }

    return result;

    //eslint-disable-next-line
  }, [data]);

  return (
    <View direction="row" align="center" justify={isRecipient ? "start" : "end"} width="100%">
      {isRecipient ? (
        <Recipient
          {...{
            date,
            data,
            handleDeleteBackendFailedEmail,
          }}
        />
      ) : (
        <Sender
          {...{ date, data }}
          {...{
            handleRetryFailedEmail,
            handleDeleteFailedEmail,
            handleDeleteBackendFailedEmail,
          }}
        />
      )}
    </View>
  );
};

const Recipient = ({ data, date, handleDeleteBackendFailedEmail }) => {
  const { message, attachment, sender } = data;

  const { t } = useTranslation();
  const { xs, sm, md } = useSpacing();
  const profile = useName({
    first_name: sender.first_name,
    last_name: sender.last_name,
    info: sender,
  });

  const handleBackendDelete = () => {
    if (typeof handleDeleteBackendFailedEmail === "function")
      handleDeleteBackendFailedEmail({ data });
  };

  return (
    <View direction="row" align="end" gap={sm} width="100%">
      <Avatar
        size={8}
        initials={profile.letters}
        alt={profile.fullName}
        attributes={{ title: profile.fullName }}
      />
      <View
        gap={xs}
        maxWidth={"400px"}
        attributes={{
          style: {
            minWidth: "150px",
          },
        }}
      >
        <View
          backgroundColor="neutral-faded"
          borderRadius="large"
          overflow="hidden"
          attributes={{
            style: {
              borderBottomLeftRadius: 0,
            },
          }}
        >
          <View padding={md} width="100%">
            <div
              className={`${styles.message__wrapper} html__content ${styles.recipient__message}`}
              dangerouslySetInnerHTML={{ __html: message }}
            />
          </View>
          {attachment && Array.isArray(attachment) && (
            <>
              {attachment.map((each) => {
                if (
                  areKeysAvailableWithType({
                    object: each,
                    keys: [{ url: "string" }, { name: "string" }],
                  })
                ) {
                  return (
                    <View
                      key={each.name}
                      direction="row"
                      align="center"
                      gap={sm}
                      padding={md}
                      backgroundColor="primary-faded"
                      attributes={{
                        style: {
                          backgroundColor: "var(--rs-color-foreground-disabled)",
                        },
                      }}
                    >
                      <Icon svg={() => <Attachment color={cooperColors["foreground-neutral"]} />} />
                      <LinkTextWithEclipse
                        url={each.url}
                        text={each.name}
                        variant="caption-1"
                        characterLimit={40}
                      />
                    </View>
                  );
                }
                return null;
              })}
            </>
          )}
        </View>
        <View
          width="100%"
          direction="row"
          align="center"
          justify="start"
          maxWidth={"300px"}
          attributes={{
            title: date.title,
            style: {
              minWidth: "50px",
            },
          }}
        >
          {data.shouldRetryOfBackend ? (
            <View gap={xs}>
              {data.errorMessage && (
                <View backgroundColor="critical" padding={sm} borderRadius="small">
                  <TextWithEclipses
                    text={data.errorMessage}
                    variant="caption-1"
                    color="white"
                    characterLimit={45}
                  />
                </View>
              )}
              {/* should be commented out on no deleting feature */}
              <View direction="row" align="center" gap={xs} justify="start">
                <Button
                  size="small"
                  variant="ghost"
                  color="critical"
                  attributes={{
                    title: t("Delete"),
                  }}
                  onClick={handleBackendDelete}
                  startIcon={() => <Trash fill={baseColor.critical} />}
                />
              </View>
              {/* should be commented out on no deleting feature */}
            </View>
          ) : (
            <>
              {data.isDeleting ? (
                <Text variant="caption-2"> {t("Deleting...")}</Text>
              ) : (
                <Text variant="caption-2"> {date.ago}</Text>
              )}
            </>
          )}
        </View>
      </View>
    </View>
  );
};

const Sender = ({
  data,
  date,
  handleRetryFailedEmail,
  handleDeleteFailedEmail,
  handleDeleteBackendFailedEmail,
}) => {
  const { message, attachment, sender } = data;

  const { t } = useTranslation();
  const { xs, md, sm } = useSpacing();
  const profile = useName({
    first_name: sender.first_name,
    last_name: sender.last_name,
    info: sender,
  });

  const handleRetry = () => {
    if (typeof handleRetryFailedEmail === "function") handleRetryFailedEmail({ data });
  };

  const handleDelete = () => {
    if (typeof handleRetryFailedEmail === "function") handleDeleteFailedEmail({ data });
  };

  const handleBackendDelete = () => {
    if (typeof handleDeleteBackendFailedEmail === "function")
      handleDeleteBackendFailedEmail({ data });
  };

  return (
    <View direction="row" justify="end" align="end" gap={sm} width="100%">
      <View gap={xs} align="end">
        <View
          backgroundColor="primary"
          borderRadius="large"
          maxWidth={"400px"}
          overflow="hidden"
          className={
            data &&
            (data?.isSending || data?.shouldRetry || data?.shouldRetryOfBackend || data?.isDeleting)
              ? styles.message__loading
              : ""
          }
          attributes={{
            style: {
              minWidth: "150px",
              borderBottomRightRadius: 0,
            },
            "data-cooper": `candidate messages list ${
              data && (data?.isSending || data?.shouldRetry) ? "in-progress" : "sent"
            }`,
          }}
        >
          <View padding={md} width="100%">
            <div
              className={`${styles.message__wrapper} html__content ${styles.sender__message}`}
              dangerouslySetInnerHTML={{ __html: message }}
            />
          </View>
          {attachment &&
            Array.isArray(attachment) &&
            attachment.map((each) => {
              if (
                areKeysAvailableWithType({
                  object: each,
                  keys: [{ url: "string" }, { name: "string" }],
                })
              ) {
                return (
                  <View
                    key={each.name}
                    direction="row"
                    align="center"
                    gap={sm}
                    padding={md}
                    backgroundColor="primary-faded"
                    attributes={{
                      style: {
                        backgroundColor: "var(--rs-color-background-primary-highlighted)",
                      },
                    }}
                  >
                    <Icon svg={() => <Attachment color={baseColor.white} />} />
                    <LinkTextWithEclipse
                      url={each.url}
                      text={each.name}
                      variant="caption-1"
                      characterLimit={40}
                      color="white"
                    />
                  </View>
                );
              }
              return null;
            })}
        </View>
        <View direction="row" align="center" justify="end">
          <View
            width="100%"
            borderRadius="small"
            direction="row"
            align="center"
            justify="end"
            maxWidth={"300px"}
            attributes={{
              title: date.title,
              style: {
                minWidth: "50px",
              },
            }}
          >
            {data && (
              <>
                {data.isSending ? (
                  <Text variant="caption-2"> {t("Sending...")}</Text>
                ) : (
                  <>
                    {data.shouldRetry ? (
                      <View direction="row" align="center" gap={xs} justify="end">
                        <Button
                          size="small"
                          color="primary"
                          variant="ghost"
                          attributes={{
                            title: t("Retry"),
                          }}
                          onClick={handleRetry}
                          startIcon={() => <Reload fill={cooperColors["background-primary"]} />}
                        />
                        <Button
                          size="small"
                          variant="ghost"
                          color="critical"
                          attributes={{
                            title: t("Delete"),
                          }}
                          onClick={handleDelete}
                          startIcon={() => <Trash fill={baseColor.critical} />}
                        />
                      </View>
                    ) : (
                      <>
                        {data.shouldRetryOfBackend ? (
                          <View gap={xs}>
                            {data.errorMessage && (
                              <View backgroundColor="critical" padding={sm} borderRadius="small">
                                <TextWithEclipses
                                  text={data.errorMessage}
                                  variant="caption-1"
                                  color="white"
                                  characterLimit={45}
                                />
                              </View>
                            )}
                            {/* should be commented out on no deleting feature */}
                            <View direction="row" align="center" gap={xs} justify="end">
                              <Button
                                size="small"
                                variant="ghost"
                                color="critical"
                                attributes={{
                                  title: t("Delete"),
                                }}
                                onClick={handleBackendDelete}
                                startIcon={() => <Trash fill={baseColor.critical} />}
                              />
                            </View>
                            {/* should be commented out on no deleting feature */}
                          </View>
                        ) : (
                          <>
                            {data.isDeleting ? (
                              <Text variant="caption-2"> {t("Deleting...")}</Text>
                            ) : (
                              <Text variant="caption-2"> {date.ago}</Text>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </View>
        </View>
      </View>
      <>
        {profile.hasURL && (
          <Avatar
            size={8}
            src={profile.url}
            alt={profile.fullName}
            attributes={{ title: profile.fullName }}
          />
        )}
        {!profile.hasURL && (
          <Avatar
            size={8}
            initials={profile.letters}
            alt={profile.fullName}
            attributes={{ title: profile.fullName }}
          />
        )}
      </>
    </View>
  );
};
