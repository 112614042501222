import { backendAPI } from "utils/axios";
import { updateUserInAPI } from "redux/auth/authSlice";
import { reloadOnUnauthorized } from "utils/checkForAuth";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  accepted: null,
  pending: null,
  loading: false,
  error: null,
};

export const fetchInvitationFromAPI = createAsyncThunk("invitation/users", async () => {
  try {
    const response = await backendAPI.get("/teams");
    if (!response.status && !response.data) {
      return {
        error: "Something went wrong",
      };
    }
    if (!response.data.users) {
      return {
        status: response.status,
        users: [],
      };
    }
    return {
      status: response.status,
      users: response.data.users,
    };
  } catch (err) {
    reloadOnUnauthorized(err);
    return {
      error: "Something went wrong",
    };
  }
});

export const revokeInvitation = createAsyncThunk("invitation/revoke", async (id) => {
  try {
    const response = await backendAPI.delete(`/users/${id}/destroy_invitation`);
    if (response.status === 200) {
      return { status: response.status, id };
    }
    return {
      error: "Something went wrong",
    };
  } catch (err) {
    reloadOnUnauthorized(err);
    return {
      error: "Something went wrong",
    };
  }
});

export const invitationSlice = createSlice({
  name: "invitation",
  initialState,
  reducers: {
    reset: (state) => {
      for (let key in initialState) {
        state[key] = initialState[key];
      }
    },
    startLoading: (state) => {
      state.loading = true;
    },
    stopLoading: (state) => {
      state.loading = false;
    },
    deleteMember: (state, action) => {
      if (action.payload === null) return;
      state.accepted = state.accepted.filter((member) => member.id !== action.payload);
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    addPendingMember: (state, action) => {
      state.pending.unshift(action.payload);
    },
  },
  extraReducers: (builder) => {
    //update user in team user update details in settings
    builder.addCase(updateUserInAPI.fulfilled, (state, action) => {
      const { payload } = action;
      if (payload?.user && typeof payload.user === "object" && Array.isArray(state.accepted)) {
        const user = payload.user;
        const foundMember = state.accepted.find((member) => member?.id === user?.id);
        if (foundMember && typeof foundMember === "object") {
          Object.keys(foundMember).forEach((k) => {
            foundMember[k] = user[k] ?? foundMember[k];
          });
        }
      }
    });

    builder.addCase(fetchInvitationFromAPI.pending, (state) => {
      state.error = null;
      state.loading = true;
    });
    builder.addCase(fetchInvitationFromAPI.fulfilled, (state, action) => {
      const { payload } = action;
      state.loading = false;
      if (payload.error) {
        state.error = payload.error;
      }
      if ("status" in payload && "users" in payload) {
        state.accepted = payload.users.filter(
          (user) => user.invitation_accepted === true || user?.parent_admin === true
        );
        state.pending = payload.users.filter((user) => user.invitation_accepted === false);
      }
    });
    builder.addCase(fetchInvitationFromAPI.rejected, (state, action) => {
      const { payload } = action;
      state.loading = false;
      state.error = payload.error;
    });
    builder.addCase(revokeInvitation.pending, (state) => {
      state.error = null;
      state.loading = true;
    });
    builder.addCase(revokeInvitation.fulfilled, (state, action) => {
      state.loading = false;
      if ("status" in action.payload) {
        state.pending = state.pending.filter((user) => user.id !== action.payload.id);
      }
      if ("error" in action.payload) {
        state.error = action.payload.error;
      }
    });
    builder.addCase(revokeInvitation.rejected, (state, action) => {
      const { payload } = action;
      state.loading = false;
      state.error = payload.error;
    });
  },
});
