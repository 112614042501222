import { areKeysAvailableWithType } from "./miniHelpers";

const months = {
  0: "Jan",
  1: "Feb",
  2: "Mar",
  3: "Apr",
  4: "May",
  5: "Jun",
  6: "Jul",
  7: "Aug",
  8: "Sep",
  9: "Oct",
  10: "Nov",
  11: "Dec",
};

const fullMonths = {
  0: "January",
  1: "February",
  2: "March",
  3: "April",
  4: "May",
  5: "June",
  6: "July",
  7: "August",
  8: "September",
  9: "October",
  10: "November",
  11: "December",
};

export function getFormattedDate(date) {
  let resultDate;
  if (!date) {
    resultDate = new Date();
  } else {
    resultDate = new Date(date);
  }
  return `${resultDate.getDate()} ${months[resultDate.getMonth()]}, ${resultDate.getFullYear()}`;
}

export function getFullFormattedDate(date) {
  let resultDate;
  if (!date) {
    resultDate = new Date();
  } else {
    resultDate = new Date(date);
  }

  const fullDay = getSingleDigitWith({ digit: resultDate.getDate() });
  return {
    space: fullDay.space,
    text: `${fullDay.date} ${fullMonths[resultDate.getMonth()]}, ${resultDate.getFullYear()}`,
  };
}

export function getDatePickerFormattedDate(date) {
  let resultDate;
  if (!date) return;
  resultDate = new Date(date);
  let month = resultDate.getMonth() + 1;
  let dateNumber = resultDate.getDate();

  if (month === 1 || month === "1") {
    month = "01";
  }

  if (dateNumber === 1 || dateNumber === "1") {
    dateNumber = "01";
  }

  let result = `${resultDate.getFullYear()}-${month}-${dateNumber}`;
  return result;
}

export function getSingleDigitWith({ format = "no-space", digit }) {
  let newDigit;

  if (typeof digit !== "number") {
    if (format === "space") {
      newDigit = { space: true, date: "1" };
    } else if (format === "zero") {
      newDigit = { space: false, date: `01` };
    } else {
      newDigit = { space: false, date: `1` };
    }
    return newDigit;
  }

  const stringDigit = `${digit}`;
  newDigit = stringDigit;

  if (stringDigit.length === 1 && format === "space") {
    newDigit = { space: true, date: stringDigit };
  } else if (stringDigit.length === 1 && format === "zero") {
    newDigit = { space: false, date: `0${stringDigit}` };
  } else {
    newDigit = { space: false, date: `${stringDigit}` };
  }

  return newDigit;
}

export function getTimeAgoFromNow({ timestampInUTC, countryCode = "DE" }) {
  function getTimeZoneForCountry() {
    const timezones = {
      DE: "Europe/Berlin",
      UK: "Europe/London",
      FR: "Europe/Paris",
    };
    return typeof countryCode === "string" && timezones[countryCode.toUpperCase()]
      ? timezones[countryCode.toUpperCase()]
      : timezones["DE"];
  }

  const isValidDate = !isNaN(Date.parse(timestampInUTC));
  if (isValidDate) {
    const timeZone = getTimeZoneForCountry(countryCode);

    const past = new Date(timestampInUTC);
    const pastTime = new Date(past.toLocaleString("en-US", { timeZone }));

    // Get the current time in the specified timezone
    const now = new Date();
    const nowInTimeZone = new Date(now.toLocaleString("en-US", { timeZone }));

    const year = pastTime.getFullYear();
    const month = months[pastTime.getMonth()];
    const date = pastTime.getDate().toString().padStart(2, "0");
    const hours = pastTime.getHours().toString().padStart(2, "0");
    const minutes = pastTime.getMinutes().toString().padStart(2, "0");

    const fullTime = `${hours}:${minutes}`;
    const fullDate = `${fullTime} - ${date} ${month} ${year}`;

    // Calculate the time difference in milliseconds
    const timeDifference = nowInTimeZone - pastTime;

    // Calculate the time difference in various units
    const millisecondsInDay = 86400000;
    const millisecondsInWeek = 604800000;
    const millisecondsInMonth = 2629746000;
    const millisecondsInYear = 31622400000;

    const daysDiff = Math.floor(timeDifference / millisecondsInDay);
    const weeksDiff = Math.floor(timeDifference / millisecondsInWeek);
    const monthsDiff = Math.floor(timeDifference / millisecondsInMonth);
    const yearsDiff = Math.floor(timeDifference / millisecondsInYear);

    function callback(value) {
      return value >= 0;
    }

    if (
      areKeysAvailableWithType({
        object: { daysDiff, weeksDiff, monthsDiff, yearsDiff },
        keys: [
          { daysDiff: "number", callback },
          { weeksDiff: "number", callback },
          { monthsDiff: "number", callback },
          { yearsDiff: "number", callback },
        ],
      })
    ) {
      const result = {
        fullDate,
      };
      if (yearsDiff >= 1) {
        if (yearsDiff === 1) {
          return {
            ...result,
            fullString: "a year ago",
          };
        }
        return {
          ...result,
          number: yearsDiff,
          string: "years ago",
        };
      }

      if (monthsDiff >= 1) {
        if (monthsDiff === 1) {
          return {
            ...result,
            fullString: "a month ago",
          };
        }
        return {
          ...result,
          number: monthsDiff,
          string: "months ago",
        };
      }

      if (weeksDiff >= 1) {
        if (weeksDiff === 1) {
          return {
            ...result,
            fullString: "a week ago",
          };
        }
        return {
          ...result,
          number: weeksDiff,
          string: "weeks ago",
        };
      }

      if (daysDiff >= 0) {
        if (daysDiff === 0) {
          return {
            ...result,
            number: fullTime,
            string: "",
          };
        }
        if (daysDiff === 1) {
          return {
            ...result,
            fullString: "yesterday",
          };
        }
        return {
          ...result,
          number: daysDiff,
          string: "days ago",
        };
      }
    }
  }

  return {
    fullDate: "",
    fullString: "-",
  };
}
