import { baseColor } from "utils/colors/accents";

export const Filter = ({ fill = baseColor.primary }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.728678 1.71942C0.837917 1.48397 1.07387 1.33334 1.33342 1.33334H14.6668C14.9263 1.33334 15.1623 1.48397 15.2715 1.71942C15.3807 1.95486 15.3434 2.2323 15.1758 2.43049L10.0001 8.55077V14C10.0001 14.2311 9.88046 14.4456 9.68391 14.5671C9.48737 14.6886 9.24194 14.6996 9.03528 14.5963L6.36861 13.263C6.14276 13.15 6.00009 12.9192 6.00009 12.6667V8.55077L0.824377 2.43049C0.656779 2.2323 0.61944 1.95486 0.728678 1.71942ZM2.77029 2.66667L7.1758 7.87619C7.27758 7.99654 7.33342 8.14905 7.33342 8.30667V12.2546L8.66676 12.9213V8.30667C8.66676 8.14905 8.7226 7.99654 8.82438 7.87619L13.2299 2.66667H2.77029Z"
        fill={fill}
      />
    </svg>
  );
};
