export const Notes = () => {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.61503 11.483H4.1214V11.5848C4.1214 12.5203 4.95061 13.2811 5.96982 13.2811H6.78964H10.7021H11.0538H11.3849C12.4044 13.2811 13.2336 12.5203 13.2336 11.5848V5.21343C13.2336 4.27819 12.4044 3.51705 11.3849 3.51705H10.8785V3.41525C10.8785 2.47965 10.0496 1.71884 9.03006 1.71884H8.34729H4.43484H3.61503C2.59582 1.71884 1.7666 2.47968 1.7666 3.41525V9.78656C1.7666 10.7218 2.59582 11.483 3.61503 11.483ZM11.3849 4.07238C12.0717 4.07238 12.6284 4.58326 12.6284 5.21343V11.5848C12.6284 12.2149 12.0717 12.7259 11.3849 12.7259H11.0538H10.7021H6.78967H5.96985C5.28325 12.7259 4.72664 12.2149 4.72664 11.5848V11.483V10.9276V5.21343C4.72664 4.58323 5.28325 4.07238 5.96985 4.07238H6.78967H10.2736H10.7021H10.8785H11.3849ZM2.37149 3.41525C2.37149 2.78504 2.92811 2.27416 3.61503 2.27416H4.43484H8.34729H9.03006C9.71698 2.27416 10.2736 2.78504 10.2736 3.41525V3.51705H6.78967H5.96985C4.95064 3.51705 4.12143 4.27819 4.12143 5.21343V10.9276H3.61503C2.92811 10.9276 2.37149 10.4164 2.37149 9.78656V3.41525Z"
        fill="black"
      />
      <path
        d="M5.76299 5.80182C5.63007 5.80182 5.52246 5.90946 5.52246 6.04238C5.52246 6.17527 5.63007 6.2829 5.76299 6.2829H10.5744C10.7073 6.2829 10.8149 6.17527 10.8149 6.04238C10.8149 5.90946 10.7073 5.80182 10.5744 5.80182H5.76299Z"
        fill="black"
      />
      <path
        d="M6.52319 7.67682C6.52319 7.80974 6.63115 7.91738 6.76407 7.91738H11.2727C11.4056 7.91738 11.5136 7.80974 11.5136 7.67682C11.5136 7.5439 11.4056 7.43597 11.2727 7.43597H6.76407C6.63115 7.43597 6.52319 7.5439 6.52319 7.67682Z"
        fill="black"
      />
      <path
        d="M10.2292 9.31099C10.2292 9.17807 10.1215 9.07047 9.98859 9.07047H5.76299C5.63007 9.07047 5.52246 9.17807 5.52246 9.31099C5.52246 9.44388 5.63007 9.5519 5.76299 9.5519H9.98859C10.1215 9.5519 10.2292 9.44388 10.2292 9.31099Z"
        fill="black"
      />
      <path
        d="M11.2726 10.7049H7.31279C7.1799 10.7049 7.07227 10.8126 7.07227 10.9455C7.07227 11.0784 7.1799 11.186 7.31279 11.186H11.2726C11.4055 11.186 11.5135 11.0784 11.5135 10.9455C11.5134 10.8126 11.4055 10.7049 11.2726 10.7049Z"
        fill="black"
      />
    </svg>
  );
};

export const NoNotesMessage = () => {
  return (
    <svg
      width="190"
      height="147"
      viewBox="0 0 190 147"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.35">
        <path
          d="M3.45826 71.988L2.4088 55.3827L0.0358643 17.8453C0.0119976 17.4627 0 17.0813 0 16.704C0.0068 7.41599 7.3224 -7.62939e-06 16.7541 -7.62939e-06H102.266C112.08 -7.62939e-06 120.619 8.03067 121.239 17.8453L122.484 37.536C122.508 37.92 122.52 38.3 122.519 38.6773C122.512 47.9667 115.198 55.3827 105.766 55.3827H19.0151L3.45826 71.988Z"
          fill="#71A8F4"
        />
        <path
          d="M100.837 24.4543H20.0347C18.3987 24.4543 16.9763 23.117 16.8727 21.481C16.769 19.845 18.0232 18.5063 19.6586 18.5063H100.461C102.097 18.5063 103.519 19.845 103.623 21.481C103.727 23.117 102.473 24.4543 100.837 24.4543Z"
          fill="white"
        />
        <path
          d="M74.1926 38.1823H20.9027C19.2669 38.1823 17.8434 36.8436 17.7403 35.2076C17.6371 33.5716 18.8907 32.2343 20.5262 32.2343H73.8166C75.4526 32.2343 76.8752 33.5716 76.9792 35.2076C77.0819 36.8436 75.8286 38.1823 74.1926 38.1823Z"
          fill="white"
        />
        <path
          d="M81.9059 71.9877H167.418C177.233 71.9877 185.771 80.0183 186.391 89.8343L188.923 129.888L189.973 146.494L172.317 129.888H85.5659C75.7512 129.888 67.2126 121.857 66.5926 112.041L65.1886 89.8343C64.5686 80.0183 72.0912 71.9877 81.9059 71.9877Z"
          fill="#166EE9"
        />
        <path
          d="M120.078 98.7973C120.296 102.265 123.286 105.077 126.754 105.077C130.22 105.077 132.855 102.265 132.635 98.7973C132.416 95.3307 129.427 92.5187 125.959 92.5187C122.491 92.5187 119.858 95.3307 120.078 98.7973Z"
          fill="white"
        />
        <path
          d="M99.1328 100.905C99.3515 104.373 102.341 107.185 105.809 107.185C109.277 107.185 111.91 104.373 111.691 100.905C111.471 97.4372 108.483 94.6266 105.015 94.6266C101.547 94.6266 98.9141 97.4372 99.1328 100.905Z"
          fill="white"
        />
        <path
          d="M141.488 104.068C141.707 107.536 144.696 110.346 148.164 110.346C151.632 110.346 154.266 107.536 154.046 104.068C153.827 100.598 150.838 97.7877 147.371 97.7877C143.903 97.7877 141.268 100.598 141.488 104.068Z"
          fill="white"
        />
      </g>
    </svg>
  );
};