import { View } from "reshaped";
import { ReshapedLoader } from "components/reusables";

export const LoadingScreen = () => {
  return (
    <View height="100vh" width="100vw">
      <ReshapedLoader />
    </View>
  );
};
