import "themes/fragments/borderSmallToMedium/theme.css";
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { Icon, Link, Text, View, Button, TextField, FormControl, ThemeProvider } from "reshaped";

import { backendAPI } from "utils/axios";
import { getTextSize } from "utils/getters";
import { Attachment, Plus, Trash } from "icons";
import { cooperColors } from "utils/colors/accents";
import useSpacing from "components/hooks/useSpacing";
import { conversationSlice } from "redux/conversation";
import { useDispatch, useSelector } from "react-redux";
import { ChatTemplatesDropdown } from "components/chat";
import { IMGTAGLOCATER } from "utils/RegularExpressions";
import { reloadOnUnauthorized } from "utils/checkForAuth";
import { ErrorSticker } from "components/reusables/common";
import useTranslation from "components/hooks/useTranslation";
import { addBlankTargetToAnchorTags } from "utils/EditorHelpers";
import { removeHtmlTagsWithAttributes } from "utils/regexHelpers";
import { DefaultEditor } from "components/reusables/richTextEditors";
import { LinkTextWithEclipse } from "components/reusables/TextHelper";
import common from "components/styles/reshaped/reusables/common.module.css";
import styles from "components/styles/reshaped/reusables/chat/chat.module.css";
import { Placeholders } from "components/reusables/richTextEditors/CustomTextEditor";
import { ReshapedLoader, TextWithEclipses } from "components/reusables";
import {
  isString,
  sanitizeArray,
  areKeysAvailableIn,
  areKeysAvailableWithType,
  isArrayWithElementsOfType,
} from "utils/miniHelpers";

export const ChatInput = ({
  oldSubject,
  setOldSubject,
  setIsTextInFocus,
  setMessagesBoxHeight,
  setIsSendMessageIsInProgress,
  handleSendMessage = () => undefined,
}) => {
  const [newSubject, setNewSubject] = useState("");
  const [filesError, setFilesError] = useState("");
  const [deletedFiles, setDeletedFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [richTextInput, setRichTextInput] = useState("");
  const [subjectError, setSubjectError] = useState(false);
  const [filesLoading, setFilesLoading] = useState(false);
  const [conversationID, setConversationID] = useState("");
  const [isSendDisabled, setIsSendDisabled] = useState(true);
  const [isInputInFocus, setIsInputInfocus] = useState(false);
  const [conversationError, setConversationError] = useState("");
  const [subjectErrorMessage, setSubjectErrorMessage] = useState("");
  const [isSubjectInProgress, setIsSubjectInProgress] = useState(false);
  const [conversationSize, setConversationSize] = useState({ files: 0, textContent: 0 });

  const charactersLimit = 8000;

  const editorRef = useRef();
  const stateRef = useRef("");
  const inputBoxRef = useRef();
  const fileInputRef = useRef();
  const isEditingRef = useRef(false);
  const newSubjectInputRef = useRef();

  const dispatch = useDispatch();
  const { t, locale } = useTranslation();
  const { xs, sm, md, lg, xl } = useSpacing();
  const { conversation, viewingCandidate } = useSelector((state) => state);

  const { destination, connectionError } = conversation;
  const MAX_HEADERS_SIZE = 50 * 1024; // in kilo bytes(kb)
  const MAX_EMAIL_SIZE = 20 * 1024 * 1024 - MAX_HEADERS_SIZE;

  useLayoutEffect(() => {
    const editorContainer = document.querySelector(".ql-snow.ql-container");
    if (editorContainer && editorContainer instanceof HTMLDivElement) {
      const fileWrapper = document.querySelector(`.quill-wrapper.quill-files`);
      if (fileWrapper && fileWrapper instanceof HTMLDivElement) {
        fileWrapper.classList.remove("quill-files-hidden");
        editorContainer.append(fileWrapper);
      }
    }
  }, []);

  useLayoutEffect(() => {
    const placeholderElement = document.querySelector('[data-link="https://quilljs.com"]');
    if (placeholderElement instanceof HTMLInputElement) {
      placeholderElement.setAttribute("placeholder", "https://www.cooperhire.io");
      placeholderElement.setAttribute("data-link", "https://www.cooperhire.io");
    }
  }, []);

  useLayoutEffect(() => {
    const tooltip = document.querySelector(".quill-wrapper .ql-tooltip");

    function handleClassChange() {
      if (tooltip instanceof HTMLDivElement) {
        tooltip.style.left = "0px";
      }
    }

    const observerConfig = {
      attributes: true,
      attributeFilter: ["class"],
      attributeOldValue: true,
      subtree: false,
    };

    const observer = new MutationObserver(handleClassChange);

    if (tooltip instanceof HTMLDivElement) {
      observer.observe(tooltip, observerConfig);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  useLayoutEffect(() => {
    const tooltip = document.querySelector(".quill-wrapper .ql-tooltip");

    function handleClassChange(entries) {
      if (Array.isArray(entries)) {
        const length = entries.length;
        for (let i = 0; i < length; i++) {
          const entry = entries[i];
          if (typeof entry !== "object") continue;
          if (!isEditingRef.current && entry.oldValue === "ql-tooltip ql-editing") {
            isEditingRef.current = true;
          }
          const target = entry.target;
          if (
            isEditingRef.current &&
            target instanceof HTMLDivElement &&
            target.getAttribute("class") === "ql-tooltip ql-editing ql-hidden"
          ) {
            isEditingRef.current = false;
            const quill =
              editorRef.current &&
              typeof editorRef.current === "object" &&
              typeof editorRef.current.getEditor === "function"
                ? editorRef.current.getEditor()
                : {};
            if (typeof quill.blur === "function") quill.blur();
            setTimeout(() => {
              if (isString(stateRef.current)) {
                handleRichTextChange(addBlankTargetToAnchorTags({ text: stateRef.current }));
              }
            }, 500);
          }
        }
      }
    }

    const observerConfig = {
      attributes: true,
      attributeFilter: ["class"],
      attributeOldValue: true,
      subtree: false,
    };

    const observer = new MutationObserver(handleClassChange);
    if (tooltip instanceof HTMLDivElement) {
      observer.observe(tooltip, observerConfig);
    }

    return () => {
      observer.disconnect();
    };

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isSubjectInProgress) {
      if (newSubjectInputRef.current) {
        newSubjectInputRef.current.focus();
      }
    }
  }, [isSubjectInProgress]);

  useEffect(() => {
    const inputBoxElement = inputBoxRef.current;

    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const newHeight = entry.contentRect.height;
        setMessagesBoxHeight(newHeight);
      }
    });

    if (inputBoxElement && inputBoxElement instanceof HTMLDivElement) {
      resizeObserver.observe(inputBoxElement);
    }
    return () => {
      if (inputBoxElement && inputBoxElement instanceof HTMLDivElement) {
        resizeObserver.unobserve(inputBoxElement);
      }
    };

    //eslint-disable-next-line
  }, []);

  const placeholder = useMemo(() => {
    if (
      areKeysAvailableIn({ object: destination, keys: ["full_name"] }) &&
      destination?.full_name
    ) {
      const sentence =
        locale === "de"
          ? `Eine Nachricht an ${destination?.full_name} senden`
          : `Send a message to ${destination?.full_name}`;
      return sentence;
    }
    return t("Send email");

    //eslint-disable-next-line
  }, [destination]);

  const handleRichTextChange = (value) => {
    stateRef.current = value;
    const textContent = removeHtmlTagsWithAttributes({ text: value, shouldTrim: true });

    if (textContent && textContent.length > 0) {
      if (textContent.length > charactersLimit) {
        setConversationError(t(`Message limit is ${charactersLimit} characters`));
      }
      if (textContent.length <= charactersLimit && conversationError) {
        setConversationError("");
      }
    }

    const hasImageTag = value.match(IMGTAGLOCATER);

    if (!hasImageTag && (!textContent || typeof textContent !== "string" || textContent === "")) {
      setIsSendDisabled(true);
    } else {
      setIsSendDisabled(false);
    }
    setRichTextInput(value);
  };

  const handleUploadFiles = async ({ data, totalCurrentUploadingFileSize, conversationID }) => {
    try {
      const formData = new FormData();
      if (
        !areKeysAvailableIn({ object: data, keys: ["candidate_id", "job_id", "attachments"] }) ||
        !isArrayWithElementsOfType({
          array: data.attachments,
          elementType: "object",
          keys: ["file"],
          noPropertyTypeChecking: true,
        })
      )
        return;

      if (conversationID) formData.append(`conversation_id`, conversationID);

      formData.append(`conversation[candidate_id]`, data.candidate_id);
      formData.append(`conversation[job_id]`, data.job_id);

      const sanitizedAttachments = data.attachments.map((attachment) => {
        formData.append(`conversation[attachments][]`, attachment.file);
        return {
          name: attachment.name,
          size: attachment.size,
        };
      });

      const response = await backendAPI.post("/conversations/add_attachment", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (
        areKeysAvailableWithType({
          object: response.data,
          keys: [{ conversation_id: "number" }, { attachments: "array" }],
        })
      ) {
        const { attachments, conversation_id } = response.data;
        setConversationID(conversation_id);
        setUploadedFiles((previousFiles) => {
          const newFiles = [];
          const responseAttachmentsLength = attachments.length;

          for (let i = 0; i < responseAttachmentsLength; i++) {
            const each = attachments[i];
            if (areKeysAvailableIn({ object: each, keys: ["name", "url"] })) {
              let found = null;
              found = previousFiles.find((uploadedFile) => uploadedFile.name === each.name);
              if (areKeysAvailableIn({ object: found, keys: ["name", "size"] })) {
                newFiles.push({ name: each.name, url: each.url, size: found.size });
              }
              if (!areKeysAvailableIn({ object: found, keys: ["name", "size"] })) {
                found = sanitizedAttachments.find(
                  (sanitizedAttachment) => sanitizedAttachment.name === each.name
                );
                if (areKeysAvailableIn({ object: found, keys: ["name", "size"] })) {
                  newFiles.push({ name: each.name, url: each.url, size: found.size });
                }
              }
            }
          }
          return newFiles;
        });

        setConversationSize((prev) => {
          const totalSizeOfNewFiles =
            typeof totalCurrentUploadingFileSize === "number" ? totalCurrentUploadingFileSize : 0;
          return {
            ...prev,
            files: prev.files + totalSizeOfNewFiles,
          };
        });
      } else {
        setFilesError(t("Documents uploading failed. Try again"));
      }
      setFilesLoading(false);
    } catch (error) {
      setFilesLoading(false);
      reloadOnUnauthorized(error);
      setFilesError(t("Documents uploading failed. Try again"));
    }
  };

  const handleFileChange = (e) => {
    if (connectionError) return;
    const maxFileSize = 5;
    const maxFilesLength = 10;
    const supportedFormat = {
      doc: true,
      docx: true,
      pdf: true,
      ppt: true,
      pptx: true,
      xls: true,
      xlsx: true,
      txt: true,
      rft: true,
      jpg: true,
      jpeg: true,
      png: true,
      svg: true,
      zip: true,
      rar: true,
      html: true,
      css: true,
      csv: true,
      xml: true,
      json: true,
    };

    const { length } = e.target.files;
    if (filesError) setFilesError("");
    if (conversationError) setConversationError("");

    if (length <= 0) return;

    function showError(cb = () => undefined, setter) {
      cb();
      setTimeout(() => {
        if (setter && typeof setter === "function") {
          setter("");
        } else {
          setFilesError("");
        }
      }, 5000);
    }

    setFilesLoading(true);
    const noOfFilesUploaded = length <= maxFilesLength ? length : maxFilesLength;
    const noOfPreviouslyUploadedFiles = uploadedFiles.length;
    const totalNoOfFiles = length + noOfPreviouslyUploadedFiles;

    if (totalNoOfFiles > maxFilesLength) {
      setFilesLoading(false);
      return showError(() =>
        setFilesError(t(`File limit exceeded, you can upload a maximum of 10 documents.`))
      );
    }

    let hasError = false;
    let newFiles = [];
    let totalCurrentUploadingFileSize = 0;
    const textContentSize = getTextSize({ text: richTextInput });
    let totalCurrentEmailSize = conversationSize.files + textContentSize;

    for (let i = 0; i < noOfFilesUploaded; i++) {
      const file = e.target.files[i];

      if (
        areKeysAvailableWithType({ object: file, keys: [{ name: "string" }, { size: "number" }] })
      ) {
        const fileName = file.name;
        const fileSize = file.size;

        if (fileSize === 0) {
          let emptyFileError = `(${fileName}) file is 0 bytes, so it will not be attached.`;
          if (locale === "de") {
            emptyFileError = `Die Datei (${fileName}) hat eine Größe von 0 Bytes, daher wird sie nicht angehängt.`;
          }
          showError(() => setFilesError(emptyFileError));
          hasError = true;
          break;
        }

        const foundFile = uploadedFiles.find((each) => each.name === fileName);

        if (areKeysAvailableIn({ object: foundFile, keys: ["name"] })) continue;

        const foundFileInDeletedFiles = deletedFiles.find((each) => each.name === fileName);

        if (
          areKeysAvailableIn({ object: foundFileInDeletedFiles, keys: ["name", "size", "url"] })
        ) {
          if (totalCurrentEmailSize + fileSize + totalCurrentUploadingFileSize > MAX_EMAIL_SIZE) {
            showError(
              () =>
                setConversationError(
                  "You can upload a total number of documents that are collectively 20 MB or under in size."
                ),
              setConversationError
            );
            hasError = true;
            break;
          }
          setUploadedFiles((prevUploadedFiles) => {
            return [{ ...foundFileInDeletedFiles }, ...prevUploadedFiles];
          });
          setDeletedFiles((prevDeletedFiles) => {
            return prevDeletedFiles.filter((deletedFile) => deletedFile.name !== fileName);
          });

          totalCurrentUploadingFileSize += fileSize;

          continue;
        }

        if (fileSize > maxFileSize * 1024 * 1024) {
          showError(() =>
            setFilesError(
              t(
                `The current upload exceeds the maximum allowed file size of ${maxFileSize} MB per document.`
              )
            )
          );
          hasError = true;
          break;
        }

        const extensionRegex = /(?:\.([^.]+))?$/;
        const uploadedExtension = extensionRegex.exec(fileName);

        if (!uploadedExtension) {
          showError(() => setFilesError(t(`(${fileName}) is not supported`)));
          hasError = true;
          break;
        }

        const uploadedFileExtension = uploadedExtension[1];
        const isFileSupported = supportedFormat[uploadedFileExtension];

        if (!isFileSupported) {
          showError(() => setFilesError(t(`(${fileName}) is not supported`)));
          hasError = true;
          break;
        }

        if (totalCurrentEmailSize + fileSize + totalCurrentUploadingFileSize > MAX_EMAIL_SIZE) {
          showError(
            () =>
              setConversationError(
                "You can upload a total number of documents that are collectively 20 MB or under in size."
              ),
            setConversationError
          );
          hasError = true;
          break;
        }

        totalCurrentUploadingFileSize += fileSize;
        newFiles.unshift({
          name: fileName,
          size: fileSize,
          file,
        });
      }
    }

    if (hasError) {
      setFilesLoading(false);
      return;
    }

    if (
      areKeysAvailableWithType({
        object: viewingCandidate,
        keys: [{ currentCandidate: "object" }],
      }) &&
      areKeysAvailableWithType({
        object: viewingCandidate.currentCandidate,
        keys: [{ id: "string" }, { job_id: "number" }],
      }) &&
      newFiles.length > 0
    ) {
      const { id: candidate_id, job_id } = viewingCandidate.currentCandidate;
      const data = {
        candidate_id,
        job_id,
        attachments: newFiles,
      };
      handleUploadFiles({ data, totalCurrentUploadingFileSize, conversationID });
    } else {
      setFilesLoading(false);
    }
  };

  const handleFileUpload = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleSend = () => {
    if (connectionError || !richTextInput || typeof richTextInput !== "string") return;

    const textContent = removeHtmlTagsWithAttributes({ text: richTextInput, shouldTrim: true });

    const hasImageTag = richTextInput.match(IMGTAGLOCATER);

    if (!hasImageTag && (!textContent || textContent.length === 0)) return;

    if (textContent && textContent.length > 0) {
      if (textContent.length > charactersLimit) {
        setConversationError(t(`Message limit is ${charactersLimit} characters`));
        return;
      }
      if (textContent.length <= charactersLimit && conversationError) {
        setConversationError("");
      }
    }

    const textContentSize = getTextSize({ text: richTextInput });

    const usedSize = conversationSize.files + textContentSize;

    if (usedSize > MAX_EMAIL_SIZE) {
      setConversationError(
        t(
          "You can upload a total number of documents that are collectively 20 MB or under in size."
        )
      );
      setConversationSize((prev) => {
        return {
          ...prev,
          textContent: textContentSize,
        };
      });
      return;
    }

    if ((!oldSubject || oldSubject === "") && (!newSubject || newSubject === "")) {
      setSubjectError(true);
      return;
    }

    if (
      areKeysAvailableWithType({
        object: viewingCandidate,
        keys: [{ currentCandidate: "object" }],
      }) &&
      areKeysAvailableWithType({
        object: viewingCandidate.currentCandidate,
        keys: [{ id: "string" }, { job_id: "number" }],
      })
    ) {
      const { id: candidate_id, job_id } = viewingCandidate.currentCandidate;

      const attachments = sanitizeArray({
        array: uploadedFiles,
        elementType: "object",
        keys: ["name", "url"],
        noPropertyTypeChecking: true,
        hardSanitization: true,
      });

      const exclude_attachments = deletedFiles.reduce((acc, deletedFile) => {
        if (areKeysAvailableIn({ object: deletedFile, keys: ["name", "url"] })) {
          return [...acc, deletedFile.name];
        }
        return acc;
      }, []);

      const result = handleSendMessage({
        value: addBlankTargetToAnchorTags({ text: richTextInput }),
        job_id,
        newSubject,
        attachments,
        candidate_id,
        exclude_attachments,
        conversation_id: conversationID,
      });

      if (isSubjectInProgress) setIsSubjectInProgress(false);

      if (result) {
        const hasNewSubject = newSubject && typeof newSubject === "string";
        if (hasNewSubject) {
          setOldSubject(newSubject);
          setNewSubject("");
        }
        stateRef.current = "";
        setDeletedFiles([]);
        setUploadedFiles([]);
        setRichTextInput("");
        setConversationID("");
        setConversationSize({ files: 0, textContent: 0 });
      } else {
        dispatch(
          conversationSlice.actions.setError("Connection failed. Please refresh and try again.")
        );
      }
    }
  };

  const handleFocusInput = () => {
    setIsInputInfocus(true);
    setIsTextInFocus(true);
    setIsSendMessageIsInProgress(true);
  };

  const handleBlurInput = () => {
    const newRichTextInput = addBlankTargetToAnchorTags({ text: richTextInput });

    const textContentSize = getTextSize({ text: newRichTextInput });

    const usedSize = conversationSize.files + textContentSize;

    if (usedSize > MAX_EMAIL_SIZE) {
      setConversationError(
        t(
          "You can upload a total number of documents that are collectively 20 MB or under in size."
        )
      );
    }

    const textContent = removeHtmlTagsWithAttributes({ text: newRichTextInput, shouldTrim: true });
    if (!textContent || textContent.length === 0) {
      setConversationError("");
    }

    if (textContent && textContent.length > 0) {
      if (textContent.length > charactersLimit) {
        setConversationError(t(`Message limit is ${charactersLimit} characters`));
      }
      if (textContent.length <= charactersLimit && conversationError) {
        setConversationError("");
      }
    }

    if (!textContent || typeof textContent !== "string" || textContent === "") {
      setIsSendMessageIsInProgress(false);
    }

    setConversationSize((prev) => {
      return {
        ...prev,
        textContent: textContentSize,
      };
    });
    stateRef.current = newRichTextInput;
    setRichTextInput(newRichTextInput);
    setIsInputInfocus(false);
    setIsTextInFocus(false);
  };

  const handleNewSubject = () => {
    setIsSubjectInProgress(true);
  };

  const handleNewSubjectChange = ({ value }) => {
    if (typeof value === "string") {
      if (value.length > 150) {
        setSubjectErrorMessage(t("Subject limit is 150 characters"));
      }
      if (value.length <= 150 && subjectErrorMessage) {
        setSubjectErrorMessage("");
      }
      setNewSubject(value);
    }
  };

  const handleNewSubjectUnfocus = () => {
    if (!newSubject || newSubject === "" || typeof newSubject !== "string")
      setIsSubjectInProgress(false);

    if (newSubject && typeof newSubject === "string") {
      if (newSubject.length > 150) {
        setSubjectErrorMessage(t("Subject limit is 150 characters"));
      } else {
        setSubjectErrorMessage("");
      }
    }

    if (!oldSubject || oldSubject === "") {
      if (!newSubject || newSubject === "") {
        setSubjectError(true);
        setSubjectErrorMessage(t("Subject is required"));
      }
      if (newSubject && typeof newSubject === "string" && subjectError) {
        setSubjectError(false);
      }
    }
  };

  const handleUploadedFileDelete = ({ file }) => {
    if (!areKeysAvailableIn({ object: file, keys: ["size", "name", "url"] })) return;

    setConversationSize((prev) => {
      return {
        ...prev,
        files: prev.files - file.size,
      };
    });

    setUploadedFiles((prev) => {
      const newFiles = prev.filter((each) => {
        if (each.name === file.name) {
          const found = deletedFiles.find((deletedFile) => deletedFile.name === file.name);
          if (!found) {
            setDeletedFiles((prev) => [...prev, file]);
          }
        }
        return each.name !== file.name;
      });
      return newFiles;
    });
  };

  const handleFileClick = (event) => {
    if (areKeysAvailableIn({ object: event, keys: ["target"] })) {
      event.target.value = "";
    }
  };

  const handleTemplateDropdownInput = (data) => {
    if (
      !areKeysAvailableIn({ object: data, keys: ["id", "name", "subject", "message", "trigger"] })
    )
      return;

    const textContentSize = getTextSize({ text: data.message });

    const usedSize = conversationSize.files + textContentSize;

    if (usedSize > MAX_EMAIL_SIZE) {
      setConversationError(
        t(
          "You can upload a total number of documents that are collectively 20 MB or under in size."
        )
      );
      return;
    }

    stateRef.current = "";
    setNewSubject(data.subject);
    setRichTextInput(data.message);
    setSubjectError(false);
    setIsSendDisabled(false);
    setIsInputInfocus(false);
    setConversationError("");
    setSubjectError("");
    setConversationSize((prev) => {
      return {
        ...prev,
        textContent: textContentSize,
      };
    });
    setIsSubjectInProgress(true);
  };

  return (
    <View
      className={`${styles.input__wrapper} ${connectionError ? styles.error__wrapper : ""}`}
      padding={[0, xl]}
      backgroundColor="white"
      borderRadius="large"
      attributes={{
        ref: inputBoxRef,
      }}
    >
      {connectionError && (
        <View paddingBottom={md}>
          <ErrorSticker text={t(connectionError)} variant="body-strong-2" />
        </View>
      )}
      {!isSubjectInProgress && oldSubject && (
        <View direction="row" align="center" padding={[sm, 0]} gap={xs}>
          <Text variant="caption-1" color="neutral">
            {t("Subject")}:
          </Text>
          <TextWithEclipses
            text={oldSubject}
            variant="caption-1"
            color="neutral-faded"
            characterLimit={80}
          />
          <View padding={[0, sm]}>
            <Button
              variant="ghost"
              color="primary"
              size="small"
              onClick={handleNewSubject}
              attributes={{ "data-cooper": "candidates messages handle-new-subject" }}
            >
              <View direction="row" align="center" gap={xs}>
                <Icon size={md} svg={() => <Plus fill={cooperColors["foreground-primary"]} />} />
                <Text variant="caption-1">{t("New subject")}</Text>
              </View>
            </Button>
          </View>
        </View>
      )}
      {(isSubjectInProgress || oldSubject === "") && (
        <View paddingBottom={sm}>
          <ThemeProvider theme="borderSmallToMedium">
            <FormControl required hasError={subjectError || subjectErrorMessage}>
              {subjectErrorMessage && <FormControl.Error>{subjectErrorMessage}</FormControl.Error>}
              <TextField
                inputAttributes={{
                  ref: newSubjectInputRef,
                  "data-cooper": "candidates messages subject",
                }}
                placeholder={t("New subject")}
                value={newSubject}
                attributes={{
                  onBlur: handleNewSubjectUnfocus,
                  style: {
                    minHeight: "40px",
                  },
                }}
                onChange={handleNewSubjectChange}
              />
            </FormControl>
          </ThemeProvider>
        </View>
      )}
      <div
        className={`quill-wrapper
        ${isInputInFocus ? "quill-focus" : ""}
        `}
      >
        <DefaultEditor
          value={richTextInput}
          handleChange={handleRichTextChange}
          formats={["bold", "italic", "underline", "strike", "link", "list", "image"]}
          modules={{
            toolbar: {
              container: [
                ["bold", "italic", "underline", "strike", "link"],
                [{ list: "ordered" }, { list: "bullet" }],
              ],
            },
          }}
          placeholder={placeholder}
          onFocus={handleFocusInput}
          onBlur={handleBlurInput}
          editorRef={editorRef}
        />
      </div>
      <View className={styles.attach__wrapper} direction="row" align="center" gap={xl}>
        <View direction="row" align="center" justify="end" gap={md} className={styles.attach}>
          <input
            type="file"
            ref={fileInputRef}
            className="file-input"
            multiple
            onChange={handleFileChange}
            onClick={handleFileClick}
          />
          <Placeholders editorRef={editorRef} buttonClassName={styles.placeholders__custom__btn} />
          <ChatTemplatesDropdown handleSelectedOption={handleTemplateDropdownInput} />
          <button
            disabled={filesLoading}
            variant="ghost"
            size="small"
            onClick={handleFileUpload}
            className={styles.attach__btn}
          >
            <Attachment width="13" color={cooperColors["foreground-neutral"]} />
          </button>
          <Button
            disabled={
              isSendDisabled ||
              conversationError ||
              filesLoading ||
              ((!oldSubject || oldSubject === "") &&
                (!newSubject || newSubject === "") &&
                !isSubjectInProgress) ||
              subjectErrorMessage
            }
            size="small"
            color="primary"
            onClick={handleSend}
            attributes={{
              "data-cooper": "candidates messages send-email",
            }}
          >
            {t("Send email")}
          </Button>
        </View>
      </View>
      <View className="quill-wrapper quill-files quill-files-hidden">
        <View.Item grow>
          {filesLoading && (
            <View
              width="20px"
              attributes={{
                style: {
                  marginBlockStart: "var(--spacing-sm)",
                  marginInlineStart: "var(--spacing-lg)",
                },
              }}
            >
              <ReshapedLoader size="small" animationSpeed={"0.8s"} />
            </View>
          )}
          {filesError && (
            <View padding={[xs, md]}>
              <ErrorSticker text={filesError} />
            </View>
          )}
          {conversationError && (
            <View padding={[xs, lg]}>
              <ErrorSticker text={conversationError} />
            </View>
          )}
          {uploadedFiles && Array.isArray(uploadedFiles) && uploadedFiles.length > 0 && (
            <View
              className={uploadedFiles.length > 3 ? `${common.more__shadow__small}` : ""}
              attributes={{
                style: {
                  marginBlockStart: "var(--spacing-md)",
                },
              }}
            >
              <View
                gap={sm}
                padding={[0, lg]}
                paddingBottom={lg + md}
                maxHeight="100px"
                className={`${common.custom__scroll}`}
                attributes={{
                  style: {
                    overflow: "hidden",
                    overflowY: "scroll",
                  },
                }}
              >
                {uploadedFiles.map((uploadedFile) => {
                  return (
                    <View key={uploadedFile.name} gap={sm} direction="row" align="center">
                      <Icon
                        svg={() => <Attachment color={cooperColors["foreground-neutral-faded"]} />}
                        size={md}
                      />
                      <View
                        className={styles.file__wrapper}
                        direction="row"
                        align="center"
                        justify="center"
                        gap={xs}
                      >
                        <LinkTextWithEclipse
                          characterLimit={50}
                          variant="caption-1"
                          color="neutral-faded"
                          url={uploadedFile.url}
                          text={`${uploadedFile.name}`}
                        />
                        <Link
                          onClick={() => handleUploadedFileDelete({ file: uploadedFile })}
                          className={styles.file__delete}
                        >
                          <Icon
                            size={md}
                            svg={() => <Trash color={cooperColors["foreground-neutral-faded"]} />}
                          />
                        </Link>
                      </View>
                    </View>
                  );
                })}
              </View>
            </View>
          )}
        </View.Item>
      </View>
    </View>
  );
};
