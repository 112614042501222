import { useMemo } from "react";
import { Divider, Link, Text, View } from "reshaped";
import useSpacing from "components/hooks/useSpacing";
import { TextWithEclipses } from "components/reusables";
import useTranslation from "components/hooks/useTranslation";
import { getFileNameFromURL } from "utils/getFileDetailsFromURL";

export const CandidateDocuments = (props) => {
  const { candidate } = props;
  const { documents } = candidate;

  const { t } = useTranslation();
  const documentDetails = useMemo(() => {
    if (Array.isArray(documents) && documents.length > 0) {
      const allDocs = documents.map((doc, index) => {
        const name = getFileNameFromURL(doc?.url);
        return {
          name: name ? name : `${t("Document-")}${index + 1}`,
          url: doc?.url,
        };
      });
      return allDocs;
    }
    return [];

    //eslint-disable-next-line
  }, [candidate]);

  const { xs, sm, xl } = useSpacing();

  return (
    <View padding={[0, xl + sm]}>
      <Divider
        attributes={{
          style: {
            backgroundColor: "var(--rs-color-foreground-disabled)",
          },
        }}
      />
      <View
        gap={sm}
        padding={[xl + xs, 0]}
        attributes={{
          style: {
            minHeight: "15rem",
          },
        }}
      >
        <Text variant="caption-1">{t("Documents")}</Text>
        {documentDetails.length > 0 ? (
          <>
            {documentDetails.map((doc, index) => {
              return (
                <View gap={xl} key={index} direction="row" align="center" justify="start">
                  <View.Item grow>
                    <TextWithEclipses
                      variant="body-strong-2"
                      text={`${doc?.name}`}
                      characterLimit={40}
                      {...{
                        style: {
                          wordBreak: "break-all",
                        },
                      }}
                    />
                  </View.Item>
                  <View.Item>
                    <Link variant="plain" href={doc?.url} attributes={{ target: "_blank" }}>
                      {t("Download")}
                    </Link>
                  </View.Item>
                </View>
              );
            })}
          </>
        ) : (
          <Text variant="body-strong-1" color="neutral-faded">
            {t("No documents uploaded")}
          </Text>
        )}
      </View>
    </View>
  );
};
