export const ArrowDown = ({ fill = "#292D32", rotate = 0 }) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: `rotate(${rotate}deg)` }}
    >
      <path
        d="M6.00001 8.39995C5.65001 8.39995 5.30001 8.26495 5.03501 7.99995L1.77501 4.73995C1.63001 4.59495 1.63001 4.35495 1.77501 4.20995C1.92001 4.06495 2.16001 4.06495 2.30501 4.20995L5.56501 7.46995C5.80501 7.70995 6.19501 7.70995 6.43501 7.46995L9.69501 4.20995C9.84001 4.06495 10.08 4.06495 10.225 4.20995C10.37 4.35495 10.37 4.59495 10.225 4.73995L6.96501 7.99995C6.70001 8.26495 6.35001 8.39995 6.00001 8.39995Z"
        fill={fill}
      />
    </svg>
  );
};
