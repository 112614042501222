import React from "react";
import common from "components/styles/reshaped/reusables/common.module.css";

export const TransparentInput = React.forwardRef(
  (
    {
      value = "",
      className = "",
      placeholder = "",
      onBlur = () => {},
      onFocus = () => {},
      onChange = () => {},
      ...rest
    },
    ref
  ) => {
    return (
      <input
        {...rest}
        {...(ref ? { ref } : {})}
        value={typeof value === "string" ? value : ""}
        {...(onBlur && typeof onBlur === "function" ? { onBlur } : {})}
        {...(onFocus && typeof onFocus === "function" ? { onFocus } : {})}
        {...(onChange && typeof onChange === "function" ? { onChange } : {})}
        {...(placeholder && typeof placeholder === "string" ? { placeholder } : {})}
        className={`${common.transparent__input} ${
          className && typeof className === "string" ? className : ""
        }`}
      />
    );
  }
);
