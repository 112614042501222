import { Avatar, Text, View } from "reshaped";

import useName from "components/hooks/useName";
import useRating from "components/hooks/useRating";
import useSpacing from "components/hooks/useSpacing";
import useFullName from "components/hooks/useFullName";
import { CandidateRating } from "components/reusables";
import useTranslation from "components/hooks/useTranslation";

const EvaluationOverview = (props) => {
  const { evaluation } = props;
  const { user, rating } = evaluation;
  const { first_name, last_name } = user;

  const { t } = useTranslation();
  const { sm, md, lg, xl } = useSpacing();
  const userRating = useRating({ rating: Number(rating) });
  const userProfile = useName({ first_name, last_name, info: user });
  const fullName = useFullName({ first_name, last_name, dependency: [user] });

  return (
    <>
      <View
        gap={xl}
        padding={lg}
        borderRadius="medium"
        attributes={{
          style: {
            border: `0.5px solid var(--rs-color-foreground-disabled)`,
            position: "relative",
          },
        }}
      >
        <View gap={md}>
          <View direction="row" align="center" gap={sm}>
            <>
              {userProfile.hasURL && <Avatar size={xl} src={userProfile.url} alt={fullName} />}
              {!userProfile.hasURL && (
                <Avatar size={xl} initials={userProfile.letters} alt={fullName} />
              )}
            </>
            <Text
              attributes={{
                style: {
                  textTransform: "capitalize",
                },
              }}
              color="neutral-faded"
              variant="body-1"
            >
              {fullName}
            </Text>
          </View>
          <View direction="row" gap={lg} align="center">
            <View
              borderRadius="small"
              padding={[sm, xl]}
              attributes={{
                style: {
                  background: `${userRating.background}`,
                  width: "max-content",
                },
              }}
            >
              <CandidateRating
                pointsSecured={rating}
                fill={userRating.fill}
                emptyFill={userRating.emptyFill}
                stroke={userRating.strokeColor}
                emptyStroke={userRating.emptyStrokeColor}
              />
            </View>
            <Text
              variant="caption-1"
              attributes={{
                style: {
                  color: `${userRating.textColor}`,
                },
              }}
            >
              {t(userRating.text)}
            </Text>
          </View>
        </View>
      </View>
    </>
  );
};

export default EvaluationOverview;
