import React, { useEffect, useState, useCallback, useMemo } from "react";
import { View, Text } from "reshaped";

import { EditorState } from "draft-js";
import { useSelector } from "react-redux";
import { isObject, isString } from "utils/miniHelpers";
import { HTMLToEditorState } from "utils/EditorHelpers";
import { setJobErrorsToNull } from "utils/setInitialErrors";
import useTranslation from "components/hooks/useTranslation";
import { jobValidationHelper } from "utils/jobValidationHelper";
import { getDatePickerFormattedDate } from "utils/getFormattedDate";
import usePublicCountries from "components/hooks/usePublicCountries";
import NewJobInput from "components/pages/jobs/newJob/steps/NewJobInput";
import RemoteOption from "components/pages/jobs/newJob/steps/RemoteOption";
import EditSalaryForm from "components/pages/jobs/updateJob/steps/EditSalaryForm";
import useUserLanguagePreference from "components/hooks/useUserLanguagePreference";
import NewJobTextEditor from "components/pages/jobs/newJob/steps/NewJobTextEditor";
import EducationDropdown from "components/pages/jobs/newJob/steps/EducationDropdown";
import DepartmentDropdown from "components/pages/jobs/newJob/steps/DepartmentDropdown";
import ExperienceDropdown from "components/pages/jobs/newJob/steps/ExperienceDropdown";
import AutoCompleteCountryInput from "components/pages/jobs/newJob/steps/CountryDropDown";
import EmploymentContractDropdown from "components/pages/jobs/newJob/steps/EmploymentContractDropdown";
import LanguageApplicationDropdown from "components/pages/jobs/newJob/steps/LanguageApplicationDropdown";
import {
  sanitizeWord,
  validateDropdown,
  validateJobErrors,
  validateNotRequiredJobErrors,
} from "utils/validateError";
import {
  CITY,
  TITLE,
  TASKS,
  COUNTRY,
  ZIP_CODE,
  BENEFITS,
  EDUCATION,
  REMOTE_JOB,
  EXPERIENCE,
  DEPARTMENT,
  SHORT_INTRO,
  REQUIREMENTS,
  ANYTHING_ELSE,
  EMPLOYMENT_CONTRACT,
  APPLICATION_DEADLINE,
  LANGUAGE_APPLICATION_FORM,
  requiredFields,
} from "components/pages/jobs/jobReducer";

const StepOne = React.forwardRef((props, ref) => {
  const { setStepOneProgress, oldState } = props;

  const [title, setTitle] = useState("");
  const [shortIntro, setShortIntro] = useState(EditorState.createEmpty());
  const [tasks, setTasks] = useState(EditorState.createEmpty());
  const [requirements, setRequirements] = useState(EditorState.createEmpty());
  const [benefits, setBenefits] = useState(EditorState.createEmpty());
  const [anythingElse, setAnythingElse] = useState(EditorState.createEmpty());
  const [minSalary, setMinSalary] = useState("");
  const [maxSalary, setMaxSalary] = useState("");
  const [currencyType, setCurrencyType] = useState("");
  const [salaryType, setSalaryType] = useState("");
  const [education, setEducation] = useState("");
  const [experience, setExperience] = useState("");
  const [employmentContract, setEmploymentContract] = useState("");
  // const [jobCategory, setJobCategory] = useState("");
  const [department, setDepartment] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [remote, setRemote] = useState("");
  const [language, setLanguage] = useState("");
  const [applicationDeadline, setApplicationDeadline] = useState("");

  const { t, locale } = useTranslation();
  const userLang = useUserLanguagePreference();
  const { getCountryName } = usePublicCountries();
  const { auth, job } = useSelector((state) => state);
  const { errors } = job;

  const [jobErrors, setJobErrors] = useState(setJobErrorsToNull(requiredFields, errors));

  const departmentTitleTemplate = useMemo(() => {
    return {
      sales: "Sales",
      "finance and operations": "Finance and Operations",
      engineering: "Engineering",
      "customer service": "Customer service",
      product: "Product",
      "human resources": "Human resources",
      marketing: "Marketing",
      other: "Other",
    };

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (oldState) {
      setRemote(oldState["remote_job"] ? "yes" : "no");
      setCity(oldState["city"] ? oldState["city"] : "");
      setTitle(oldState["title"] ? oldState["title"] : "");
      setZipCode(oldState["zip_code"] ? oldState["zip_code"] : "");
      setEducation(oldState["education"] ? oldState["education"] : "");
      // setJobCategory(oldState["category"] ? oldState["category"] : "");
      setCurrencyType(oldState["currency"] ? oldState["currency"] : "");
      setMinSalary(oldState["min_salary"] ? oldState["min_salary"] : "");
      setMaxSalary(oldState["max_salary"] ? oldState["max_salary"] : "");
      setExperience(oldState["experience"] ? oldState["experience"] : "");
      setDepartment(
        departmentTitleTemplate[oldState["department"]]
          ? departmentTitleTemplate[oldState["department"]]
          : ""
      );
      setSalaryType(oldState["salary_type"] ? oldState["salary_type"] : "");
      setEmploymentContract(oldState["employment_contract"] ? oldState["employment_contract"] : "");
      setLanguage(
        oldState["language_application_form"] ? oldState["language_application_form"] : ""
      );
      setApplicationDeadline(
        oldState["application_deadline"]
          ? getDatePickerFormattedDate(oldState["application_deadline"])
          : ""
      );
      setCountry(() => {
        if (isObject(oldState)) {
          const countryName = getCountryName({
            country: isString(oldState["country_code"])
              ? oldState["country_code"]
              : oldState["country"],
            defaultValue: "",
            locale: userLang,
          });
          return countryName;
        }
        return "";
      });
      setTasks(HTMLToEditorState(oldState["tasks"]));
      setBenefits(HTMLToEditorState(oldState["benefits"]));
      setShortIntro(HTMLToEditorState(oldState["short_intro"]));
      setRequirements(HTMLToEditorState(oldState["requirements"]));
      setAnythingElse(HTMLToEditorState(oldState["anything_else"]));
    }

    //eslint-disable-next-line
  }, [oldState]);

  const translations = useMemo(
    () => {
      return {
        "Job Details": t("Job Details"),
        "Job title": t("Job title"),
        "Short intro": t("Short intro"),
        Tasks: t("Tasks"),
        Requirements: t("Requirements"),
        Benefits: t("Benefits"),
        "Anything else you wish to share with the candidate": t(
          "Anything else you wish to share with the candidate"
        ),
        Salary: t("Salary"),
        EUR: t("EUR"),
        USD: t("USD"),
        GBP: t("GBP"),
        to: t("to"),
        "Single value": t("Single value"),
        Range: t("Range"),
        Day: t("Day"),
        Week: t("Week"),
        Month: t("Month"),
        Year: t("Year"),
        Education: t("Education"),
        Experience: t("Experience"),
        "Employment Contract": t("Employment Contract"),
        Department: t("Department"),
        "Language Application Form": t("Language Application Form"),
        Country: t("Country"),
        City: t("City"),
        "ZIP Code": t("ZIP Code"),
        "Remote Job": t("Remote Job"),
        "Application Deadline": t("Application Deadline"),
        "Backend Engineer": t("Backend Engineer"),
        "Our mission is to create and sell quality products...": t(
          "Our mission is to create and sell quality products..."
        ),
        "Executing full lifecycle software development (SDLC)": t(
          "Executing full lifecycle software development (SDLC)"
        ),
        "Excellent knowledge of relational databases and ORM technologies...": t(
          "Excellent knowledge of relational databases and ORM technologies..."
        ),
        "Performance Bonus, Paid Sick Leaves etc.": t("Performance Bonus, Paid Sick Leaves etc."),
        "Bonus point if candidate is open to learn new technologies...": t(
          "Bonus point if candidate is open to learn new technologies..."
        ),
      };
    },
    //eslint-disable-next-line
    auth && auth?.user ? [auth.user] : []
  );

  ref.current = (() => {
    let refOptions = {};
    if (ref.current) refOptions = { ...ref.current };
    refOptions = {
      ...refOptions,
      errors: jobErrors,
      setErrors: setJobErrors,
      state: {
        city,
        title,
        tasks,
        country,
        zip_code: zipCode,
        benefits,
        // category: jobCategory,
        education,
        remote_job: remote,
        experience,
        department: department.toLowerCase(),
        short_intro: shortIntro,
        requirements,
        anything_else: anythingElse,
        employment_contract: employmentContract,
        application_deadline: applicationDeadline,
        language_application_form: language.toLowerCase(),
        min_salary: minSalary,
        max_salary: maxSalary,
        salary_type: salaryType,
        currency: currencyType,
      },
    };
    return refOptions;
  })();

  const handleValidation = useCallback(
    ({ id, value }) => {
      const { regex, message, ...rest } = jobValidationHelper(id);

      if (id === "country") {
        let userLang = "en";
        if (auth.user && auth.user.language_preference === "de") {
          userLang = "de";
        }
        return validateJobErrors(id, value, setJobErrors, regex, message, t, { userLang, ...rest });
      }

      return validateJobErrors(id, value, setJobErrors, regex, message, t, rest);
    },

    //eslint-disable-next-line
    [locale]
  );

  const handleNotRequiredValidation = useCallback(
    ({ id, isDropdown, value }) => {
      if (isDropdown) {
        return validateDropdown(id, value, setJobErrors, /[a-zA-Z\s]{1,}/, "Invalid option", t);
      }
      const { regex, message, ...rest } = jobValidationHelper(id);
      return validateNotRequiredJobErrors(id, value, setJobErrors, regex, message, t, rest);
    },

    //eslint-disable-next-line
    [locale]
  );

  return (
    <View gap={6}>
      <View direction="row" gap={4}>
        <Text variant="title-2">{translations["Job Details"]}</Text>
      </View>
      <View gap={6}>
        <NewJobInput
          inputValue={title}
          setInputValue={setTitle}
          isRequired
          title={translations["Job title"]}
          propTitle={TITLE}
          errors={jobErrors}
          setErrors={setJobErrors}
          setStepOneProgress={setStepOneProgress}
          handleValidation={handleValidation}
          placeholder={translations["Backend Engineer"]}
          noEmojis
        />
        <NewJobTextEditor
          errors={jobErrors}
          setErrors={setJobErrors}
          inputValue={shortIntro}
          setInputValue={setShortIntro}
          title={translations["Short intro"]}
          propTitle={SHORT_INTRO}
          setStepOneProgress={setStepOneProgress}
          handleNotRequiredValidation={handleNotRequiredValidation}
          handleValidation={handleValidation}
          placeholder={translations["Our mission is to create and sell quality products..."]}
        />
        <NewJobTextEditor
          inputValue={tasks}
          setInputValue={setTasks}
          isRequired
          title={translations.Tasks}
          propTitle={TASKS}
          errors={jobErrors}
          setErrors={setJobErrors}
          setStepOneProgress={setStepOneProgress}
          handleValidation={handleValidation}
          placeholder={translations["Executing full lifecycle software development (SDLC)"]}
        />
        <NewJobTextEditor
          inputValue={requirements}
          setInputValue={setRequirements}
          isRequired
          errors={jobErrors}
          title={translations.Requirements}
          propTitle={REQUIREMENTS}
          setErrors={setJobErrors}
          setStepOneProgress={setStepOneProgress}
          handleValidation={handleValidation}
          placeholder={
            translations["Excellent knowledge of relational databases and ORM technologies..."]
          }
        />
        <NewJobTextEditor
          inputValue={benefits}
          setInputValue={setBenefits}
          title={translations.Benefits}
          errors={jobErrors}
          propTitle={BENEFITS}
          setErrors={setJobErrors}
          setStepOneProgress={setStepOneProgress}
          handleNotRequiredValidation={handleNotRequiredValidation}
          placeholder={translations["Performance Bonus, Paid Sick Leaves etc."]}
        />
        <NewJobTextEditor
          inputValue={anythingElse}
          setInputValue={setAnythingElse}
          errors={jobErrors}
          setErrors={setJobErrors}
          propTitle={ANYTHING_ELSE}
          setStepOneProgress={setStepOneProgress}
          title={translations["Anything else you wish to share with the candidate"]}
          handleNotRequiredValidation={handleNotRequiredValidation}
          placeholder={
            translations["Bonus point if candidate is open to learn new technologies..."]
          }
        />
        <EditSalaryForm
          minSalary={minSalary}
          maxSalary={maxSalary}
          currencyType={currencyType}
          salaryType={salaryType}
          setMinSalary={setMinSalary}
          setMaxSalary={setMaxSalary}
          setCurrencyType={setCurrencyType}
          setSalaryType={setSalaryType}
          errors={jobErrors}
          setErrors={setJobErrors}
          setStepOneProgress={setStepOneProgress}
          handleNotRequiredValidation={handleNotRequiredValidation}
          oldState={oldState}
        />
        <EducationDropdown
          education={education}
          setEducation={setEducation}
          propTitle={EDUCATION}
          setStepOneProgress={setStepOneProgress}
        />
        <View direction="row" gap={4}>
          <ExperienceDropdown
            experience={experience}
            setExperience={setExperience}
            propTitle={EXPERIENCE}
            setStepOneProgress={setStepOneProgress}
          />
          <EmploymentContractDropdown
            employmentContract={employmentContract}
            setEmploymentContract={setEmploymentContract}
            propTitle={EMPLOYMENT_CONTRACT}
            setStepOneProgress={setStepOneProgress}
          />
        </View>
        {/* <JobCategoryDropdown
          errors={jobErrors}
          jobCategory={jobCategory}
          setJobCategory={setJobCategory}
          propTitle={CATEGORY}
          setErrors={setJobErrors}
          setStepOneProgress={setStepOneProgress}
        /> */}
        <View direction="row" gap={4}>
          <DepartmentDropdown
            department={department}
            setDepartment={setDepartment}
            errors={jobErrors}
            setErrors={setJobErrors}
            setStepOneProgress={setStepOneProgress}
            propTitle={DEPARTMENT}
          />
          <LanguageApplicationDropdown
            language={sanitizeWord(language)}
            setLanguage={setLanguage}
            errors={jobErrors}
            setErrors={setJobErrors}
            setStepOneProgress={setStepOneProgress}
            propTitle={LANGUAGE_APPLICATION_FORM}
          />
        </View>
        <View direction="row" gap={4} attributes={{ style: { zIndex: 10 } }}>
          <AutoCompleteCountryInput
            {...{ country, setCountry }}
            errors={jobErrors}
            setErrors={setJobErrors}
            setStepOneProgress={setStepOneProgress}
            propTitle={COUNTRY}
          />

          <NewJobInput
            inputValue={city}
            setInputValue={setCity}
            isRequired
            title={translations.City}
            propTitle={CITY}
            errors={jobErrors}
            setErrors={setJobErrors}
            handleValidation={handleValidation}
            setStepOneProgress={setStepOneProgress}
          />
        </View>
        <View direction="row" align="center" gap={4}>
          <NewJobInput
            inputValue={zipCode}
            setInputValue={setZipCode}
            isRequired
            title={translations["ZIP Code"]}
            errors={jobErrors}
            propTitle={ZIP_CODE}
            setErrors={setJobErrors}
            handleValidation={handleValidation}
            setStepOneProgress={setStepOneProgress}
            placeholder="123.."
          />
          <RemoteOption
            remote={remote}
            setRemote={setRemote}
            title={translations["Remote Job"]}
            propTitle={REMOTE_JOB}
            setStepOneProgress={setStepOneProgress}
            handleNotRequiredValidation={handleNotRequiredValidation}
          />
        </View>
        <NewJobInput
          inputValue={applicationDeadline}
          setInputValue={setApplicationDeadline}
          inputType="date"
          errors={jobErrors}
          setErrors={setJobErrors}
          title={translations["Application Deadline"]}
          propTitle={APPLICATION_DEADLINE}
          setStepOneProgress={setStepOneProgress}
        />
      </View>
    </View>
  );
});

export default StepOne;
