import { useEffect, useState } from "react";
import { Text, useToast, View } from "reshaped";

import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import useSpacing from "components/hooks/useSpacing";
import { WrapperWithNav } from "components/reusables";
import { ShowToastWithTranslation } from "utils/showToast";
import useTranslation from "components/hooks/useTranslation";
import { OperationFailedWithTranslation } from "components/reusables/OperationFailed";
import { BookingsBanner, BookingsSection, NoBookings } from "components/pages/bookings";

const Bookings = () => {
  const [bookingsTab, setBookingsTab] = useState("inventory");

  const toast = useToast();
  const { t } = useTranslation();
  const { xs, lg, xl } = useSpacing();
  const [searchParams, setSearchParams] = useSearchParams();
  const { newAccount } = useSelector((state) => state.bookings);

  useEffect(() => {
    const error = searchParams.get("error");
    const success = searchParams.get("success");

    if (success && success === "true") {
      ShowToastWithTranslation({
        toast,
        Children: PaymentSuccessful,
        text: t("Checkout successful!"),
      });
      searchParams.delete("success");
      setSearchParams(searchParams);
    }

    if (error && error === "true") {
      ShowToastWithTranslation({
        toast,
        Children: OperationFailedWithTranslation,
        text: t("Checkout failed!"),
      });
      searchParams.delete("error");
      setSearchParams(searchParams);
    }

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const to = searchParams.get("to");
    const queries = {
      inventory: true,
      active: true,
    };

    if (to && typeof to === "string" && queries[to.toLowerCase()]) {
      setBookingsTab(to.toLowerCase());
    }

    //eslint-disable-next-line
  }, []);

  return (
    <WrapperWithNav wrapperStyles={{ background: "var(--rs-color-white)" }}>
      <View.Item grow>
        {newAccount ? (
          <NoBookings />
        ) : (
          <View gap={xs + lg + xl} height="100%">
            <BookingsBanner {...{ bookingsTab, setBookingsTab }} />
            <BookingsSection {...{ bookingsTab, setBookingsTab }} />
          </View>
        )}
      </View.Item>
    </WrapperWithNav>
  );
};

export default Bookings;

const PaymentSuccessful = ({ children }) => {
  return (
    <Text color="positive" variant="body-strong-1">
      {children}
    </Text>
  );
};
