import { Button, Image, Text, View } from "reshaped";

import { Plus } from "icons";
import useSpacing from "components/hooks/useSpacing";
import useTranslation from "components/hooks/useTranslation";

export const NoCandidates = (props) => {
  const { handleModalOpen } = props;

  const { t } = useTranslation();
  const { sm, xl } = useSpacing();

  return (
    <View gap={xl + sm} align="center" justify="end" height="100%">
      <View
        maxWidth="600px"
        attributes={{
          style: {
            paddingBlockStart: "var(--spacing-xl)",
          },
        }}
      >
        <Text color="neutral-faded" variant="title-2" align="center">
          {t("There are no candidates, right now!")}
          <br />
          {t(`Don't worry we will inform you when someone applies, or you can`)}
        </Text>
      </View>
      <Button color="primary" size="large" startIcon={Plus} onClick={handleModalOpen}>
        {t("Add Candidate")}
      </Button>
      <Image src="images/NoCandidates.svg" alt="No candidates" height="calc(100vh - 55vh)" />
    </View>
  );
};
