import { useState, useEffect } from "react";
import { Divider, Loader, useToast, useToggle, View } from "reshaped";

import useSpacing from "components/hooks/useSpacing";
import { useDispatch, useSelector } from "react-redux";
import { fetchInvitationFromAPI } from "redux/invitation/invitationSlice";
import common from "components/styles/reshaped/reusables/common.module.css";
import { ReshapedErrorToast } from "components/reusables/layouts/ErrorToast";
import { PendingInvites, InvitationModal, AcceptedInvites } from "components/pages/settings";
import { exceededUsersLimit, isCronjobDowngradeTriggered } from "utils/pages/settings";
import { ShowToastWithTranslation } from "utils/showToast";
import useTranslation from "components/hooks/useTranslation";
import { OperationFailedWithTranslation } from "components/reusables";
import { fetchSubscriptionData } from "redux/subscriptions/subscriptionsSlice";

export const Team = () => {
  const [role, setRole] = useState("member");
  const [inviteEmail, setInviteEmail] = useState("");
  const [invitationStage, setInvitationStage] = useState("invite");
  const [invitationError, setInvitationError] = useState({
    email: {},
  });

  const dispatch = useDispatch();
  const { sm, xl } = useSpacing();
  const { active, activate, deactivate } = useToggle(false);
  const { pending, accepted, loading, error } = useSelector((state) => state.invitation);
  const { subscription } = useSelector((state) => state.subscriptions);

  const emailProps = { email: inviteEmail, setEmail: setInviteEmail };
  const roleProps = { role, setRole };
  const invitationStageProps = { invitationStage, setInvitationStage };
  const invitationErrorProps = { error: invitationError, setError: setInvitationError };
  const modalProps = { active, deactivate };
  const toast = useToast();
  const { t } = useTranslation();

  useEffect(() => {
    if (!accepted && !pending) {
      dispatch(fetchInvitationFromAPI());
    }

    //eslint-disable-next-line
  }, [accepted, pending]);

  useEffect(() => {
    if (!subscription.subscription_type) {
      dispatch(fetchSubscriptionData());
    }
    //eslint-disable-next-line
  }, []);

  const handleInvitationModalOpen = () => {
    if (exceededUsersLimit(subscription)) {
      ShowToastWithTranslation({  
        toast,
        Children: OperationFailedWithTranslation,
        text: `${t(
          "Please update your payment plan before adding more users. You cannot invite more than"
        )}
          ${subscription.max_users} ${t("users with the")} ${subscription.subscription_type} ${t(
          "plan user_limit_error"
        )}`,
      });

      return;
    };

    if (isCronjobDowngradeTriggered(subscription)) {
      ShowToastWithTranslation({
        toast,
        Children: OperationFailedWithTranslation,
        text: t("You have already scheduled a downgrade for your subscription, please wait for the downgrade to take effect before changing the plan!"),
      });
      return;
    }

    activate();
  };

  const handleClearance = ({ setError, setEmail, setInvitationStage }) => {
    setTimeout(() => {
      setError({ email: {} });
      setEmail("");
      setInvitationStage("invite");
    }, 500);
  };

  const resetInvitation = (rest) => {
    const { setInvitationStage, setError, deactivate, setEmail } = rest;
    deactivate();
    handleClearance({ setInvitationStage, setEmail, setError });
  };

  return (
    <>
      {!loading && (
        <>
          <View padding={[xl, 0]} gap={xl + sm}>
            <AcceptedInvites handleInvitation={handleInvitationModalOpen} />
            <Divider
              attributes={{
                style: {
                  backgroundColor: "var(--rs-color-border-neutral)",
                },
              }}
            />
            <PendingInvites />
          </View>
          {active && (
            <InvitationModal
              {...emailProps}
              {...roleProps}
              {...invitationStageProps}
              {...invitationErrorProps}
              {...modalProps}
              subscription={subscription}
              resetInvitation={resetInvitation}
            />
          )}
        </>
      )}
      {loading && (
        <View height="100%" align="center" justify="center">
          <Loader size="medium" className={common.loader__big} />
        </View>
      )}
      {!loading && error && <ReshapedErrorToast navigateTo="/settings" message={error} />}
    </>
  );
};
