import { Cross } from "icons";
import { Button, Text, View } from "reshaped";
import useSpacing from "./../hooks/useSpacing";
import { baseColor } from "utils/colors/accents";
import useTranslation from "components/hooks/useTranslation";

export const SubscriptionAlert = ({ handleAlertClose, message, paymentFailed = false }) => {
  const { lg } = useSpacing();
  const { t } = useTranslation();
  
  return (
    <>
      <View
        padding={[0, 15]}
        backgroundColor="white"
        height="auto"
        width="100%"
        direction="row"
        align="center"
        gap={lg}
        attributes={{
          style: {
            position: "fixed",
            top: "50px",
            zIndex: "10",
            paddingBlock: "8px",
            backgroundColor: "var(--accent-4-200)",
            justifyContent: "space-between",
          },
        }}
      >
        <Text variant="body-strong-2"
          attributes={{
            style: {
              maxWidth: "90%",
            }
          }}
        >{paymentFailed ? t("Your payment has failed. Please update your payment details in billing portal to avoid service interruption.") : message}</Text>
        {!paymentFailed ?
          <Button
          size="small"
          variant="ghost"
          startIcon={() => <Cross fill={baseColor.black} />}
          onClick={handleAlertClose}
          /> : null
        }
      </View>
    </>
  );
};
