import { Bin } from "icons";
import useName from "components/hooks/useName";
import useSpacing from "components/hooks/useSpacing";
import { Avatar, View, Text, Button, useToast, useToggle, Modal } from "reshaped";
import useFullName from "components/hooks/useFullName";
import { useState } from "react";
import { ShowToastWithTranslation } from "utils/showToast";
import { OperationFailedWithTranslation } from "components/reusables";
import useTranslation from "components/hooks/useTranslation";
import { reloadOnUnauthorized } from "utils/checkForAuth";
import { SuccessfulToast } from "components/reusables/tab/SuccessfulToast";
import { revokeInvitation } from "redux/invitation/invitationSlice";
import { useDispatch } from "react-redux";
import { fetchSubscriptionData } from "redux/subscriptions/subscriptionsSlice";

export const PendingInvite = (props) => {
  const { user } = props;
  const { first_name, last_name, email } = user;
  const dispatch = useDispatch();
  const { sm, lg } = useSpacing();
  const profile = useName({ first_name, last_name, info: user });
  const fullName = useFullName({ first_name, last_name, dependency: [user] });
  const { active, activate, deactivate } = useToggle(false);

  const [isLoading, setLoading] = useState(false);

  const toast = useToast();
  const { t } = useTranslation();

  const handleDeleteInvite = async (user_id) => {
    setLoading(true);
    dispatch(revokeInvitation(user_id)).then((res) => {
      if (res.payload && res.payload.status === 200) {
        dispatch(fetchSubscriptionData())
        ShowToastWithTranslation({
          toast,
          Children: SuccessfulToast,
          text: t("Invite deleted successfully!"),
        });
      }
      if (res.payload && res.payload.status !== 200) {
        ShowToastWithTranslation({
          toast,
          Children: OperationFailedWithTranslation,
          text: t("Operation failed!"),
        });
      }
      if (res.error) {
        reloadOnUnauthorized(res.error);
      }
    });

    setLoading(false);
  };

  return (
    <View.Item columns={6}>
      <View
        direction="row"
        padding={lg}
        borderRadius="medium"
        align="center"
        gap={sm}
        borderColor="neutral-faded"
        attributes={{
          style: {
            borderBlockStyle: "dashed",
          },
        }}
      >
        <View
          borderColor="neutral-faded"
          attributes={{
            style: {
              borderRadius: "50%",
            },
          }}
        >
          {profile.hasURL && (
            <Avatar src={profile.url} size={10} alt={fullName ? fullName : "team user profile"} />
          )}
          {!profile.hasURL && (
            <Avatar
              size={10}
              initials={profile.letters}
              alt={fullName ? fullName : "team user profile"}
            />
          )}
        </View>
        <View>
          <Text variant="body-strong-2">{fullName}</Text>
          <Text color="neutral" variant="body-2">
            {email}
          </Text>
        </View>
        <View
          height="100%"
          attributes={{
            style: {
              marginLeft: "auto",
            },
          }}
        >
          <Button startIcon={Bin} disabled={isLoading} variant="ghost" onClick={activate} />
          <DeleteInviteModal
            user={user}
            handleDeleteInvite={handleDeleteInvite}
            active={active}
            deactivate={deactivate}
          />
        </View>
      </View>
    </View.Item>
  );
};

const DeleteInviteModal = ({ user, handleDeleteInvite, active, deactivate }) => {
  const { t } = useTranslation();
  const { sm, xl } = useSpacing();

  return (
    <Modal active={active} onClose={deactivate}>
      <View gap={3}>
        <View
          justify={"center"}
          align={"center"}
          attributes={{
            style: {
              padding: sm,
              borderRadius: sm,
            },
          }}
        >
          <Text
            variant="title-3"
            attributes={{
              style: {
                textAlign: "center",
              },
            }}
          >
            {t("Are you sure you want to delete this invite?")}
          </Text>
          <View
            attributes={{
              style: {
                textAlign: "center",
                marginBottom: 2 * xl,
              },
            }}
          >
            <Text variant="body-1">
              {t(`This action will revoke invitation to`)}{" "}
              {<b>{user.email}</b>}
            </Text>
          </View>
          <View direction="row" gap={sm} justify="center">
            <Button onClick={deactivate}>{t("Cancel")}</Button>
            <Button
              color="critical"
              onClick={() => {
                handleDeleteInvite(user.id);
                deactivate();
              }}
            >
              {t("Delete")}
            </Button>
          </View>
        </View>
      </View>
    </Modal>
  );
};
