import { forwardRef } from "react";
import { View } from "reshaped";

import StepOne from "components/pages/jobs/updateJob/steps/StepOne";
import styles from "components/styles/reshaped/jobs/new.module.css";

const EditJobForm = forwardRef((props, ref) => {
  const { setStepOneProgress, oldState } = props;

  ref.current = (() => {
    let refOptions = {};
    if (ref.current) refOptions = { ...ref.current };
    refOptions = {
      ...refOptions,
    };
    return refOptions;
  })();

  return (
    <View.Item grow={true} className={styles.wrapper__child__right}>
      <StepOne oldState={oldState} setStepOneProgress={setStepOneProgress} ref={ref} />
    </View.Item>
  );
});

export default EditJobForm;
