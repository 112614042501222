import { useMemo } from "react";
import { Text, View } from "reshaped";

import { useSelector } from "react-redux";
import { isString } from "utils/miniHelpers";
import useSpacing from "components/hooks/useSpacing";
import { getFormattedDate } from "utils/getFormattedDate";
import useTranslation from "components/hooks/useTranslation";
import { areKeysAvailableWithType } from "utils/miniHelpers";
import { getLocationFromCityAndCountry } from "utils/getters";
import { EditCandidate, Property } from "components/pages/candidates";

export const Credentials = (props) => {
  const { candidate } = props;
  const { created_at, job, id } = candidate;
  const { city, country, country_code } = job;

  const { xs, sm, xl } = useSpacing();
  const { t, locale } = useTranslation();
  const { publicState } = useSelector((state) => state);

  const { countries } = publicState;
  const { data } = countries;

  const location = useMemo(() => {
    return getLocationFromCityAndCountry({
      city,
      locale,
      country: isString(country_code) ? country_code : country,
      noLocationText: "",
      countryList: data,
    });

    //eslint-disable-next-line
  }, [data]);

  return (
    <View direction="row" gap={xl} padding={[xl + xs, xl + sm]}>
      <View.Item columns={12}>
        <Text variant="title-3" color="black">
          {t("Details")}
        </Text>
      </View.Item>
      <Property title={t("Date Applied")} value={getFormattedDate(created_at)} />
      <Property title={t("Job Location")} value={location} />
      <View.Item columns={6}>
        <EditCandidate
          candidateID={id}
          propertyName="utm_source"
          placeholder={""}
          label={t("Candidate source")}
          defaultValue={
            areKeysAvailableWithType({ object: candidate, keys: [{ utm_source: "string" }] })
              ? candidate.utm_source
              : ""
          }
        />
      </View.Item>
      <View.Item columns={6}>
        <EditCandidate
          candidateID={id}
          label={"LinkedIn"}
          placeholder={""}
          propertyName="linkedin_url"
          defaultValue={
            areKeysAvailableWithType({ object: candidate, keys: [{ linkedin_url: "string" }] })
              ? candidate.linkedin_url
              : ""
          }
        />
      </View.Item>
    </View>
  );
};
