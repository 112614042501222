import { Button, Icon, View } from "reshaped";

import { Cross } from "icons";
import { cooperColors } from "utils/colors/accents";
import useSpacing from "components/hooks/useSpacing";
import { TextWithEclipses } from "components/reusables/TextHelper";

export const Chip = ({
  handlerArgs,
  closeHandler,
  title = "Chip",
  chipClassName = "",
  characterLimit = 40,
  crossColor = cooperColors["foreground-neutral-faded"],
}) => {
  const { xs, sm, lg } = useSpacing();

  const handleClose = () => {
    if (!closeHandler || typeof closeHandler !== "function") return;
    if (!handlerArgs || typeof handlerArgs !== "object") return closeHandler();
    closeHandler(handlerArgs);
  };

  return (
    <View
      direction="row"
      className={`${chipClassName}`}
      align="center"
      justify="center"
      gap={sm}
      padding={[xs, lg]}
      paddingEnd={xs}
      borderRadius="large"
      backgroundColor="white"
      attributes={{
        style: {
          borderRadius: "100vmax",
          zIndex: 0,
          border: `1px solid ${cooperColors["border-chips-dark-neutral-faded"]}`
        },
      }}
    >
      <TextWithEclipses text={title} characterLimit={characterLimit} variant="body-2" />
      <Button
        variant="ghost"
        onClick={handleClose}
        size="small"
        attributes={{
          style: {
            borderRadius: "50%",
          },
        }}
      >
        <Icon size={lg} svg={() => <Cross fill={crossColor} />} />
      </Button>
    </View>
  );
};
