import { useEffect } from "react";
import { Button, Divider, Text, View } from "reshaped";

import { Reload } from "icons";
import { baseColor } from "utils/colors/accents";
import useSpacing from "components/hooks/useSpacing";
import { ReshapedLoader } from "components/reusables";
import { useDispatch, useSelector } from "react-redux";
import { PromotingJob } from "components/pages/bookings";
import useTranslation from "components/hooks/useTranslation";
import styles from "components/styles/reshaped/bookings/bookings.module.css";
import { fetchNotPromotedPublishedJobs } from "redux/bookings/bookingsSlice";

export const SelectJobPromotion = ({
  promotingJob,
  promotingSlot,
  promotingStage,
  setPromotingJob,
  handleModalClose,
  setPromotingStage,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { sm, lg, xl } = useSpacing();
  const { modalLoading, jobs, error } = useSelector((state) => state.bookings);
  const { slot_mgt_id: slot_management_id, job_board_id } = promotingSlot;

  useEffect(() => {
    if (!jobs)
      dispatch(
        fetchNotPromotedPublishedJobs({ slot_management: { slot_management_id, job_board_id } })
      );

    //eslint-disable-next-line
  }, []);

  const handlePromoteStageChange = () => {
    if (!promotingJob || error.jobs) return;
    setPromotingStage("confirm");
  };

  const handleRefetchJobs = () => {
    if (error.jobs)
      dispatch(
        fetchNotPromotedPublishedJobs({ slot_management: { slot_management_id, job_board_id } })
      );
  };

  return (
    <View padding={xl} gap={lg}>
      <View gap={xl}>
        <Text variant="title-3" color="neutral">
          {t("Select the job you want to promote")}
        </Text>
        <View gap={sm}>
          <View direction="row">
            <View.Item grow>
              <Text variant="body-1">{t("Select job")}:</Text>
            </View.Item>
            <Text variant="body-1" color="neutral-faded">
              {promotingStage === "job" ? 1 : 2}
              /2
            </Text>
          </View>
          <Divider
            attributes={{
              style: {
                backgroundColor: "var(--rs-color-foreground-neutral-faded)",
              },
            }}
          />
        </View>
      </View>
      <View className={`${Array.isArray(jobs) && jobs.length >= 4 ? styles.more__shadow : ""}`}>
        <View height="320px" className={`no__scroll`}>
          <View.Item grow>
            {modalLoading && <ReshapedLoader size="medium" />}
            {!modalLoading && error.jobs && (
              <View align="center" height="100%" justify="center" gap={lg}>
                <Text variant="body-strong-1" color="critical">
                  {t(error.jobs)}
                </Text>
                <Button
                  color="critical"
                  startIcon={() => <Reload fill={baseColor.white} />}
                  onClick={handleRefetchJobs}
                >
                  {t("Reload jobs")}
                </Button>
              </View>
            )}
            {jobs && Array.isArray(jobs) && (
              <>
                {jobs.length <= 0 ? (
                  <View align="center" justify="center" height="100%">
                    <Text color="neutral-faded" variant="body-strong-1">
                      {t("Looks like you do not have published jobs!")}
                    </Text>
                  </View>
                ) : (
                  <View
                    attributes={{
                      style: {
                        marginBlockEnd: "var(--rs-unit-x6)",
                      },
                    }}
                  >
                    {jobs.map((job) => {
                      return (
                        <PromotingJob
                          key={job.job_id}
                          job={job}
                          isSelected={promotingJob && promotingJob?.job_id === job.job_id}
                          setPromotingJob={setPromotingJob}
                        />
                      );
                    })}
                  </View>
                )}
              </>
            )}
          </View.Item>
        </View>
      </View>
      <View
        direction="row"
        align="center"
        justify="end"
        gap={lg}
        attributes={{
          style: {
            paddingBlockStart: "var(--rs-unit-x10)",
          },
        }}
      >
        <Button variant="ghost" onClick={handleModalClose}>
          {t("Cancel")}
        </Button>
        <View borderRadius="medium" overflow="hidden">
          <Button color="primary" onClick={handlePromoteStageChange} disabled={!promotingJob}>
            {t("Next")}
          </Button>
        </View>
      </View>
    </View>
  );
};
