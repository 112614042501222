import { useRef, useState } from "react";
import { Text, View } from "reshaped";

import { ArrowDown } from "icons";
import useWebkit from "components/hooks/useWebkit";
import { cooperColors } from "utils/colors/accents";
import useSpacing from "components/hooks/useSpacing";
import styles from "components/styles/reshaped/candidates.module.css";
import {
  FilterAccordionStatusOptions,
} from "components/pages/jobs";

export const FilterAccordion = ({
  isOdd = true,
  title = "Job Profile",
  selectedData,
  handleUpdate,
  optionsName,
  setIsUpdated,
}) => {
  const { isWebkit } = useWebkit();
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const menuRef = useRef();
  const panelRef = useRef();
  const { sm, lg } = useSpacing();

  const handleAccordion = (e) => {
    const panelElement = panelRef?.current;
    if (isAccordionOpen) {
      e.target.classList.remove(styles.accordion__active);
      if (panelElement instanceof HTMLDivElement) {
        panelElement.style.maxHeight = null;
      }
      setIsAccordionOpen(false);
    }
    if (!isAccordionOpen) {
      e.target.classList.add(styles.accordion__active);
      if (panelElement instanceof HTMLDivElement) {
        if (optionsName === "jobs") {
          panelElement.style.maxHeight = `205px`;
        } else {
          panelElement.style.maxHeight = `185px`;
        }
      }
      setIsAccordionOpen(true);
    }
  };

  return (
    <>
      <View className={styles.accordion__wrapper}>
        <View
          direction="row"
          align="center"
          as="button"
          attributes={{
            onClick: handleAccordion,
            ref: menuRef,
            "data-cooper": `jobs ${optionsName.toLowerCase()} acc`,
          }}
          className={`${styles.accordion}  ${isOdd ? styles.accordion__primary : ""}`}
          borderRadius="small"
          padding={[sm, lg]}
        >
          <View.Item
            grow
            attributes={{
              style: { pointerEvents: "none" },
            }}
          >
            <Text variant="body-strong-2">{title}</Text>
          </View.Item>
          <ArrowDown fill={cooperColors["foreground-neutral"]} rotate={isAccordionOpen ? 180 : 0} />
        </View>
        <View
          className={`${styles.accordion__panel} ${isWebkit ? "webkit" : "non__webkit"}`}
          attributes={{ ref: panelRef }}
        >
          {optionsName && typeof optionsName === "string" && (
            <>
              {optionsName === "status" && (
                <FilterAccordionStatusOptions
                  {...{ handleUpdate, selectedData, showOptions: isAccordionOpen, setIsUpdated }}
                />
              )}
            </>
          )}
        </View>
        {isAccordionOpen && <span className={`${styles.shadow}`} />}
      </View>
    </>
  );
};
