import { StrictMode } from "react";

import "index.css";
import "themes/cooperhireTheme/theme.css";

import store from "app/store";
import App from "components/App";
import "themes/cooper/theme.css";
import { Reshaped } from "reshaped";
import { Provider } from "react-redux";
import ReactDOM from "react-dom/client";
import { rollbarConfig } from "utils/rollbar";
import reportWebVitals from "reportWebVitals";
import { CookiesProvider } from "react-cookie";
import { BrowserRouter as Router } from "react-router-dom";
import { SpacingProvider } from "contexts/spacing/SpacingContext";
import { Provider as RollbarProvider, ErrorBoundary } from "@rollbar/react";

const root = ReactDOM.createRoot(document.getElementById("cooperhire"));
root.render(
  <StrictMode>
    <RollbarProvider config={rollbarConfig}>
      <ErrorBoundary>
        <Reshaped theme="cooper">
          <SpacingProvider>
            <CookiesProvider>
              <Provider store={store}>
                <Router>
                  <App />
                </Router>
              </Provider>
            </CookiesProvider>
          </SpacingProvider>
        </Reshaped>
      </ErrorBoundary>
    </RollbarProvider>
  </StrictMode>
);

reportWebVitals();
