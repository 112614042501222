import { useState } from "react";
import { Button, DropdownMenu, Text, View, useToast } from "reshaped";

import { MessageOutline } from "icons";
import { templatesSlice } from "redux/templates";
import { cooperColors } from "utils/colors/accents";
import useSpacing from "components/hooks/useSpacing";
import { areKeysAvailableIn } from "utils/miniHelpers";
import { useDispatch, useSelector } from "react-redux";
import { ShowToastWithTranslation } from "utils/showToast";
import useTranslation from "components/hooks/useTranslation";
import styles from "components/styles/reshaped/reusables/chat/chat.module.css";
import { getMinimalTemplateFromAPI, getMinimalTemplatesFromAPI } from "utils/pages";
import {
  ReshapedLoader,
  TextWithEclipses,
  OperationFailedWithTranslation,
} from "components/reusables";

export function ChatTemplatesDropdown({ handleSelectedOption }) {
  const [loadedTemplate, setLoadedTemplate] = useState({});
  const [templateLoading, setTemplateLoading] = useState(false);

  const toast = useToast();
  const { md } = useSpacing();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { templates } = useSelector((state) => state);
  const { minimalLoading, minimalData, minimalError, data } = templates;

  const handleDropdown = async (selectedData) => {
    if (!handleSelectedOption || typeof handleSelectedOption !== "function") return;

    if (!areKeysAvailableIn({ object: selectedData, keys: ["id", "name"] })) return;

    const { id } = selectedData;
    let templateData;

    if (loadedTemplate[id]) {
      templateData = loadedTemplate[id];
    }

    if (Array.isArray(data) && !templateData) {
      const found = data.find((each) => each.id === id);
      if (found) templateData = found;
    }

    if (!templateData) {
      setTemplateLoading(true);

      const result = await getMinimalTemplateFromAPI({ id });

      setTemplateLoading(false);

      if (result.data) {
        setLoadedTemplate((prev) => {
          return {
            ...prev,
            [result.data.id]: result.data,
          };
        });
        templateData = result.data;
      }

      if (result.error) {
        ShowToastWithTranslation({
          toast,
          Children: OperationFailedWithTranslation,
          text: t(result.error),
        });
      }
    }

    handleSelectedOption(templateData);
  };

  const handleRetryGetPlaceholders = async () => {
    dispatch(
      templatesSlice.actions.setProperties({
        minimalLoading: true,
      })
    );
    const result = await getMinimalTemplatesFromAPI();
    if (result.data) {
      dispatch(
        templatesSlice.actions.setProperties({
          minimalLoading: false,
          minimalData: result.data,
          minimalError: null,
        })
      );
    }
    if (result.error) {
      dispatch(
        templatesSlice.actions.setProperties({
          minimalLoading: false,
          minimalData: null,
          minimalError: result.error,
        })
      );
    }
  };

  return (
    <>
      {templateLoading ? (
        <View direction="row" align="center" justify="center" width="30px">
          <ReshapedLoader size="small" />
        </View>
      ) : (
        <DropdownMenu width="250px" position="top-end">
          <DropdownMenu.Trigger>
            {(attributes) => (
              <View
                as="button"
                className={styles.template__btn}
                direction="row"
                align="center"
                justify="center"
                attributes={{
                  ...attributes,
                  title: t("Message Templates"),
                  style: {
                    marginBlockStart: "3px",
                  },
                }}
              >
                <MessageOutline width="20" color={cooperColors["foreground-neutral"]} />
              </View>
            )}
          </DropdownMenu.Trigger>
          <DropdownMenu.Content>
            {minimalLoading && (
              <View height="50px">
                <ReshapedLoader size="small" />
              </View>
            )}
            {!minimalLoading && (
              <>
                {(!Array.isArray(minimalData) || minimalError) && (
                  <View height="100%" padding={md} align="center" justify="center" gap={md}>
                    <Text variant="caption-1" align="center" color="critical">
                      {t(
                        minimalError && typeof minimalError === "string"
                          ? minimalError
                          : "Unable to retrieve message templates at this time. Please try again later"
                      )}
                    </Text>
                    <Button size="small" color="critical" onClick={handleRetryGetPlaceholders}>
                      <Text variant="caption-1">{t("Please try again.")}</Text>
                    </Button>
                  </View>
                )}
                {Array.isArray(minimalData) && !minimalError && (
                  <>
                    {minimalData.map((each) => {
                      return (
                        <DropdownMenu.Item key={each.id} onClick={() => handleDropdown(each)}>
                          <TextWithEclipses
                            text={each.name}
                            variant="feature-3"
                            color="neutral"
                            characterLimit={30}
                          />
                        </DropdownMenu.Item>
                      );
                    })}
                  </>
                )}
              </>
            )}
          </DropdownMenu.Content>
        </DropdownMenu>
      )}
    </>
  );
}
