import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Button, DropdownMenu, Text, View, useToast } from "reshaped";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { getTextSize } from "utils/getters";
import { MAX_EMAIL_SIZE } from "utils/constants";
import { templatesSlice } from "redux/templates";
import { MessageOutline, TagOutline } from "icons";
import { cooperColors } from "utils/colors/accents";
import useSpacing from "components/hooks/useSpacing";
import { placeholdersSlice } from "redux/placeholders";
import { useDispatch, useSelector } from "react-redux";
import { ShowToastWithTranslation } from "utils/showToast";
import useTranslation from "components/hooks/useTranslation";
import { HTMLValidationHelper } from "utils/validationHelper";
import { getPlaceholderFromAPI } from "utils/pages/settings";
import { addBlankTargetToAnchorTags } from "utils/EditorHelpers";
import "components/styles/reshaped/reusables/chat/customTextEditor.css";
import { getMinimalTemplateFromAPI, getMinimalTemplatesFromAPI } from "utils/pages";
import {
  isObject,
  isString,
  areKeysAvailableIn,
  areKeysAvailableWithType,
} from "utils/miniHelpers";
import {
  ReshapedLoader,
  TextWithEclipses,
  OperationFailedWithTranslation,
} from "components/reusables";

export const CustomTextEditor = ({
  defaultValue = "",
  placeholder = "Write something...",
  stateRef,
  error,
  setError,
  setPreLoadTemplate,
  handleTemplateSelect,
  enableTemplates = true,
  preLoadTemplate = false,
  enablePlaceholders = true,
  ...attributes
}) => {
  const [isInputInFocus, setIsInputInfocus] = useState(false);
  const [value, setValue] = useState(() =>
    defaultValue && typeof defaultValue === "string" ? defaultValue : ""
  );

  const editorRef = useRef();
  const isEditingRef = useRef(false);

  const { xs } = useSpacing();
  const { t } = useTranslation();

  useLayoutEffect(() => {
    const placeholderElement = document.querySelector('[data-link="https://quilljs.com"]');
    if (placeholderElement instanceof HTMLInputElement) {
      placeholderElement.setAttribute("placeholder", "https://www.cooperhire.io");
      placeholderElement.setAttribute("data-link", "https://www.cooperhire.io");
    }
  }, []);

  useLayoutEffect(() => {
    const tooltip = document.querySelector(".quill-custom-wrapper .ql-tooltip");

    function handleClassChange() {
      if (tooltip instanceof HTMLDivElement) {
        tooltip.style.left = "0px";
      }
    }

    const observerConfig = {
      attributes: true,
      attributeFilter: ["class"],
      attributeOldValue: true,
      subtree: false,
    };

    const observer = new MutationObserver(handleClassChange);

    if (tooltip instanceof HTMLDivElement) {
      observer.observe(tooltip, observerConfig);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  useLayoutEffect(() => {
    const tooltip = document.querySelector(".quill-custom-wrapper .ql-tooltip");

    function handleClassChange(entries) {
      if (Array.isArray(entries)) {
        const length = entries.length;
        for (let i = 0; i < length; i++) {
          const entry = entries[i];
          if (typeof entry !== "object") continue;
          if (!isEditingRef.current && entry.oldValue === "ql-tooltip ql-editing") {
            isEditingRef.current = true;
          }
          const target = entry.target;
          if (
            isEditingRef.current &&
            target instanceof HTMLDivElement &&
            target.getAttribute("class") === "ql-tooltip ql-editing ql-hidden"
          ) {
            isEditingRef.current = false;
            const quill =
              editorRef.current &&
              typeof editorRef.current === "object" &&
              typeof editorRef.current.getEditor === "function"
                ? editorRef.current.getEditor()
                : {};
            if (typeof quill.blur === "function") quill.blur();
            setTimeout(() => {
              if (isString(stateRef.current)) {
                handleChange(addBlankTargetToAnchorTags({ text: stateRef.current }));
              }
            }, 500);
          }
        }
      }
    }

    const observerConfig = {
      attributes: true,
      attributeFilter: ["class"],
      attributeOldValue: true,
      subtree: false,
    };

    const observer = new MutationObserver(handleClassChange);
    if (tooltip instanceof HTMLDivElement) {
      observer.observe(tooltip, observerConfig);
    }

    return () => {
      observer.disconnect();
    };

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      preLoadTemplate &&
      areKeysAvailableWithType({ object: stateRef, keys: [{ current: "string" }] })
    ) {
      const textContentSize = getTextSize({ text: stateRef.current });

      const usedSize = textContentSize;

      if (usedSize > MAX_EMAIL_SIZE) {
        setError(t("Message size must not exceed 20MB limit"));
        return;
      }

      if (usedSize > 0) {
        setError("");
      }

      setValue(stateRef.current);
      if (setPreLoadTemplate && typeof setPreLoadTemplate === "function") setPreLoadTemplate(false);
    }

    //eslint-disable-next-line
  }, [preLoadTemplate]);

  const handleChange = (value) => {
    stateRef.current = value;
    setValue(value);
  };

  const handleFocus = () => {
    setIsInputInfocus(true);
  };

  const handleBlur = () => {
    setIsInputInfocus(false);

    const sanitizedValue = addBlankTargetToAnchorTags({ text: value });

    if (typeof setError === "function") {
      const errorResult = HTMLValidationHelper({
        value: sanitizedValue,
        requiredErrorString: t("Message field is mandatory."),
        characterLimitErrorString: t("Message must not exceed 8000 characters."),
        sizeLimitErrorString: t("Message size must not exceed 20MB limit"),
      });

      if (errorResult) {
        setError(errorResult);
      } else {
        setError("");
      }
    }

    stateRef.current = sanitizedValue;
    setValue(sanitizedValue);
  };

  const handleTemplateSelectionOption = (data) => {
    if (
      !areKeysAvailableIn({ object: data, keys: ["id", "name", "subject", "message", "trigger"] })
    )
      return;

    const textContentSize = getTextSize({ text: data.message });

    if (textContentSize > MAX_EMAIL_SIZE) {
      setError(t("Message size must not exceed 20MB limit"));
      return;
    }

    if (textContentSize > 0) {
      setError("");
    }

    stateRef.current = data.message;
    setValue(data.message);

    if (handleTemplateSelect && typeof handleTemplateSelect === "function")
      handleTemplateSelect(data);
  };

  return (
    <View gap={xs}>
      <div
        className={`quill-custom-wrapper ${
          isInputInFocus ? "quill-focus" : error ? "quill-error" : ""
        }`}
      >
        <ReactQuill
          theme="snow"
          value={value}
          key={placeholder}
          formats={[
            "bold",
            "italic",
            "underline",
            "list",
            "bullet",
            "link",
            // "image"
            "strike",
          ]}
          modules={{
            toolbar: {
              container: "#custom-toolbar",
            },
          }}
          onChange={handleChange}
          placeholder={placeholder}
          {...(editorRef && isObject(editorRef) ? { ref: editorRef } : {})}
          onFocus={handleFocus}
          onBlur={handleBlur}
          {...attributes}
        />
        <CustomToolbar editorRef={editorRef} />
        <div className={"custom__btns"}>
          {enableTemplates && <Templates handleSelectedOption={handleTemplateSelectionOption} />}
          {enablePlaceholders && <Placeholders editorRef={editorRef} />}
        </div>
      </div>
      {error && typeof error === "string" && (
        <Text color="critical" variant="body-2">
          {error}
        </Text>
      )}
    </View>
  );
};

function CustomToolbar() {
  return (
    <div id="custom-toolbar" className="custom__toolbar__message__template">
      <button className="ql-bold"></button>
      <button className="ql-italic"></button>
      <button className="ql-underline"></button>
      <button className="ql-strike"></button>
      <button className="ql-link"></button>
      <button className="ql-list" value="ordered"></button>
      <button className="ql-list" value="bullet"></button>
    </div>
  );
}

export function Placeholders({ editorRef, buttonClassName }) {
  const { md } = useSpacing();
  const dispatch = useDispatch();
  const { t, locale } = useTranslation();
  const { placeholders } = useSelector((state) => state);

  const {
    data: placeholdersData,
    error: placeholdersError,
    loading: placeholdersLoading,
  } = placeholders;

  useEffect(() => {
    if (!placeholdersData) {
      getPlaceholders();
    }

    async function getPlaceholders() {
      dispatch(placeholdersSlice.actions.setLoading(true));

      const result = await getPlaceholderFromAPI();

      if (result.error) {
        dispatch(
          placeholdersSlice.actions.setProperties({
            loading: false,
            error: result.error,
            data: null,
          })
        );
      }
      if (result.data) {
        dispatch(
          placeholdersSlice.actions.setProperties({
            loading: false,
            data: result.data,
            error: null,
          })
        );
      }
    }

    //eslint-disable-next-line
  }, []);

  const handleDropdown = (data) => {
    if (!areKeysAvailableIn({ object: data, keys: ["name", "key"] })) return;
    const quill = editorRef.current.getEditor();
    if (quill) {
      const selection = quill.getSelection();
      if (selection) {
        quill.insertText(selection.index, data.key);
        const cursorIndex = selection.index + data.key.length;
        quill.setSelection(cursorIndex, 0);
        setTimeout(() => {
          quill.focus();
        }, 2);
      }
    }
  };

  const handleRetryGetPlaceholders = async () => {
    dispatch(placeholdersSlice.actions.setLoading(true));

    const result = await getPlaceholderFromAPI();

    if (result.error) {
      dispatch(
        placeholdersSlice.actions.setProperties({
          loading: false,
          error: result.error,
          data: null,
        })
      );
    }
    if (result.data) {
      dispatch(
        placeholdersSlice.actions.setProperties({
          loading: false,
          data: result.data,
          error: null,
        })
      );
    }
  };

  return (
    <DropdownMenu width={locale === "de" ? "270px" : "230px"} position="top-end">
      <DropdownMenu.Trigger>
        {(attributes) => (
          <View
            as="button"
            className={`placeholder__btn ${isString(buttonClassName) ? buttonClassName : ""}`}
            attributes={{ ...attributes, title: t("Placeholders") }}
            direction="row"
            align="center"
            justify="center"
          >
            <TagOutline width="28" className={"tagoutline__path"} />
          </View>
        )}
      </DropdownMenu.Trigger>
      <DropdownMenu.Content>
        {placeholdersLoading && (
          <View height="50px">
            <ReshapedLoader size="small" />
          </View>
        )}
        {!placeholdersLoading && (
          <>
            {placeholdersData &&
              Array.isArray(placeholdersData) &&
              placeholdersData.map((each) => {
                return (
                  <DropdownMenu.Item key={each.name} onClick={() => handleDropdown(each)}>
                    <TextWithEclipses
                      text={each.name}
                      variant="feature-3"
                      color="neutral"
                      characterLimit={locale === "de" ? 30 : 25}
                    />
                  </DropdownMenu.Item>
                );
              })}

            {placeholdersError && typeof placeholdersError === "string" && (
              <View height="100%" padding={md} align="center" justify="center" gap={md}>
                <Text variant="caption-1" align="center" color="critical">
                  {t(placeholdersError)}
                </Text>
                <Button size="small" color="critical" onClick={handleRetryGetPlaceholders}>
                  <Text variant="caption-1">{t("Please try again.")}</Text>
                </Button>
              </View>
            )}
          </>
        )}
      </DropdownMenu.Content>
    </DropdownMenu>
  );
}

export function Templates({ handleSelectedOption }) {
  const [loadedTemplate, setLoadedTemplate] = useState({});
  const [templateLoading, setTemplateLoading] = useState(false);

  const toast = useToast();
  const { md } = useSpacing();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { templates } = useSelector((state) => state);
  const { minimalLoading, minimalData, minimalError, data } = templates;

  const handleDropdown = async (selectedData) => {
    if (!handleSelectedOption || typeof handleSelectedOption !== "function") return;

    if (!areKeysAvailableIn({ object: selectedData, keys: ["id", "name"] })) return;

    const { id } = selectedData;
    let templateData;

    if (loadedTemplate[id]) {
      templateData = loadedTemplate[id];
    }

    if (Array.isArray(data) && !templateData) {
      const found = data.find((each) => each.id === id);
      if (found) templateData = found;
    }

    if (!templateData) {
      setTemplateLoading(true);

      const result = await getMinimalTemplateFromAPI({ id });

      setTemplateLoading(false);

      if (result.data) {
        setLoadedTemplate((prev) => {
          return {
            ...prev,
            [result.data.id]: result.data,
          };
        });
        templateData = result.data;
      }

      if (result.error) {
        ShowToastWithTranslation({
          toast,
          Children: OperationFailedWithTranslation,
          text: t(result.error),
        });
      }
    }

    handleSelectedOption(templateData);
  };

  const handleRetryGetPlaceholders = async () => {
    dispatch(
      templatesSlice.actions.setProperties({
        minimalLoading: true,
      })
    );
    const result = await getMinimalTemplatesFromAPI();
    if (result.data) {
      dispatch(
        templatesSlice.actions.setProperties({
          minimalLoading: false,
          minimalData: result.data,
          minimalError: null,
        })
      );
    }
    if (result.error) {
      dispatch(
        templatesSlice.actions.setProperties({
          minimalLoading: false,
          minimalData: null,
          minimalError: result.error,
        })
      );
    }
  };

  return (
    <>
      {templateLoading ? (
        <View direction="row" align="center" justify="center" width="30px">
          <ReshapedLoader size="small" />
        </View>
      ) : (
        <DropdownMenu width="250px" position="top-end">
          <DropdownMenu.Trigger>
            {(attributes) => (
              <View
                as="button"
                className={"template__btn"}
                direction="row"
                align="center"
                justify="center"
                attributes={{
                  ...attributes,
                  title: t("Message Templates"),
                  style: {
                    marginBlockStart: "3px",
                  },
                }}
              >
                <MessageOutline
                  width="20"
                  color={cooperColors["foreground-neutral"]}
                  className={"messageoutline__path"}
                />
              </View>
            )}
          </DropdownMenu.Trigger>
          <DropdownMenu.Content>
            {minimalLoading && (
              <View height="50px">
                <ReshapedLoader size="small" />
              </View>
            )}
            {!minimalLoading && (
              <>
                {(!Array.isArray(minimalData) || minimalError) && (
                  <View height="100%" padding={md} align="center" justify="center" gap={md}>
                    <Text variant="caption-1" align="center" color="critical">
                      {t(
                        minimalError && typeof minimalError === "string"
                          ? minimalError
                          : "Unable to retrieve message templates at this time. Please try again later"
                      )}
                    </Text>
                    <Button size="small" color="critical" onClick={handleRetryGetPlaceholders}>
                      <Text variant="caption-1">{t("Please try again.")}</Text>
                    </Button>
                  </View>
                )}
                {Array.isArray(minimalData) && !minimalError && (
                  <>
                    {minimalData.map((each) => {
                      return (
                        <DropdownMenu.Item key={each.id} onClick={() => handleDropdown(each)}>
                          <TextWithEclipses
                            text={each.name}
                            variant="feature-3"
                            color="neutral"
                            characterLimit={30}
                          />
                        </DropdownMenu.Item>
                      );
                    })}
                  </>
                )}
              </>
            )}
          </DropdownMenu.Content>
        </DropdownMenu>
      )}
    </>
  );
}
