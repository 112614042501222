import { createSlice } from "@reduxjs/toolkit";
import { getFullName } from "utils/nameHelpers";
import { getUserFromAPI, updateUserInAPI } from "redux/auth/authSlice";
import { areKeysAvailableIn, areKeysAvailableWithType } from "utils/miniHelpers";
import { inProgressConversationsSlice } from "redux/inProgressConversations";

const props = {
  destination: true,
  source: false,
};

// inProgressMessages will be key value pairs with candidate_id as key, array as value with messages payload init
const initialState = {
  destination: null,
  source: null,
  viewingCandidateID: "",
  connectionError: "",
  inProgressMessages: [],
  isChatBoxOpen: false,

  // newMessage will object with status and message - status: "new || inProgress", message: conversation
  newMessage: "empty",
};

// property in initialState should be an object with below properties for ex: destination
// {
//   email,
//   id,
//   first_name,
//   last_name
//   full_name
// }

export const conversationSlice = createSlice({
  name: "conversation",
  initialState,
  reducers: {
    reset: (state) => {
      for (let key in initialState) {
        state[key] = initialState[key];
      }
    },
    setInfo: (state, action) => {
      const { payload } = action;
      if (!payload || typeof payload !== "object") return;
      Object.keys(payload).forEach((payloadProp) => {
        if (props[payloadProp] && typeof payload[payloadProp] === "object") {
          state[payloadProp] = {
            ...state[payloadProp],
            ...payload[payloadProp],
          };
        }
      });
    },
    setChatBox: (state, action) => {
      const { payload } = action;
      if (typeof payload === "boolean") state.isChatBoxOpen = payload;
    },
    setError: (state, action) => {
      const { payload } = action;
      if (typeof payload === "string") state.connectionError = payload;
    },

    receiveConversation: (state, action) => {
      const { payload } = action;
      if (
        areKeysAvailableWithType({
          object: payload,
          keys: [{ candidate_id: "string" }, { data: "object" }, { on: "string" }],
        })
      ) {
        const { candidate_id, data, on } = payload;
        if (!state.viewingCandidateID === candidate_id) return;

        if (!areKeysAvailableIn({ object: data, keys: ["message_id"] }) && state.isChatBoxOpen) {
          state.newMessage = {
            status: "new",
            message: data,
          };
        }

        if (areKeysAvailableIn({ object: data, keys: ["message_id"] })) {
          if (on === "success") {
            const result = state.inProgressMessages.reduce(
              (acc, each) => {
                if (
                  areKeysAvailableWithType({
                    object: each,
                    keys: [{ message_id: data.message_id, exact: true }],
                  })
                ) {
                  return {
                    ...acc,
                    found: { ...data, shouldRetry: false, isSending: false },
                  };
                }
                return {
                  ...acc,
                  array: [...acc.array, each],
                };
              },
              { found: false, array: [] }
            );

            if (result.found) {
              state.inProgressMessages = result.array;
              state.newMessage = {
                status: "inProgress",
                message: result.found,
              };
            }

            if (!result.found && state.isChatBoxOpen) {
              state.newMessage = {
                status: "new",
                message: data,
              };
            }
          }

          if (on === "fail") {
            const result = state.inProgressMessages.reduce(
              (acc, each) => {
                if (
                  areKeysAvailableWithType({
                    object: each,
                    keys: [{ message_id: data.message_id, exact: true }],
                  })
                ) {
                  const found = { ...each, shouldRetry: true, isSending: false };
                  return {
                    array: [...acc.array, found],
                    found,
                  };
                }
                return {
                  ...acc,
                  array: [...acc.array, each],
                };
              },
              { found: false, array: [] }
            );

            if (result.found) {
              state.inProgressMessages = result.array;
              state.newMessage = {
                status: "inProgress",
                message: result.found,
              };
            }
          }
        }
      }
    },
    emptyNewMessage: (state) => {
      state.newMessage = "empty";
    },
    setViewingCandidateID: (state, action) => {
      const { payload } = action;
      if (typeof payload === "string") {
        state.viewingCandidateID = payload;
      }
    },
    setCandidateInProgressMessage: (state, action) => {
      const { payload } = action;
      if (
        !payload ||
        typeof payload !== "object" ||
        !areKeysAvailableIn({ object: payload, keys: ["candidate_id", "messagePayload"] })
      )
        return;

      const { candidate_id, messagePayload } = payload;
      const existingMessages = areKeysAvailableWithType({
        object: state,
        keys: [{ inProgressMessages: "object" }],
      })
        ? state.inProgressMessages
        : {};

      if (
        !areKeysAvailableWithType({
          object: state.inProgressMessages,
          keys: [{ [candidate_id]: "array" }],
        })
      ) {
        state.inProgressMessages = {
          ...existingMessages,
          [candidate_id]: [messagePayload],
        };
        return;
      }

      if (
        areKeysAvailableWithType({
          object: state.inProgressMessages,
          keys: [{ [candidate_id]: "array" }],
        })
      ) {
        state.inProgressMessages = {
          ...existingMessages,
          [candidate_id]: [...state.inProgressMessages[candidate_id], messagePayload],
        };
      }
    },
    setCandidateInProgressMessages: (state, action) => {
      const { payload } = action;
      if (
        areKeysAvailableWithType({
          object: payload,
          keys: [{ data: "array" }, { initial: true, exact: true }],
        })
      ) {
        state.inProgressMessages = payload.data;
        return;
      }

      if (areKeysAvailableIn({ object: payload, keys: ["data", "message_id"] })) {
        state.inProgressMessages = state.inProgressMessages.map((each) => {
          if (
            areKeysAvailableWithType({
              object: each,
              keys: [{ message_id: payload.message_id, exact: true }],
            })
          ) {
            return payload.data;
          }
          return each;
        });
        return;
      }
      if (areKeysAvailableIn({ object: payload, keys: ["data"] })) {
        state.inProgressMessages = [...state.inProgressMessages, payload.data];
      }
    },

    deleteCandidateInProgressMessages: (state, action) => {
      const { payload } = action;
      if (areKeysAvailableIn({ object: payload, keys: ["message_id"] })) {
        state.inProgressMessages = state.inProgressMessages.reduce((acc, each) => {
          if (
            areKeysAvailableWithType({
              object: each,
              keys: [{ message_id: payload.message_id, exact: true }],
            })
          ) {
            return acc;
          }
          return [...acc, each];
        }, []);
      }
    },
    deleteCandidateInProgressMessage: (state, action) => {
      const { payload } = action;
      if (
        !payload ||
        typeof payload !== "object" ||
        !areKeysAvailableIn({ object: payload, keys: ["candidate_id", "message_id"] })
      )
        return;

      const { candidate_id, message_id } = payload;
      const existingMessages = areKeysAvailableWithType({
        object: state,
        keys: [{ inProgressMessages: "object" }],
      })
        ? state.inProgressMessages
        : {};

      const existingCandidateMessages = (() => {
        if (
          areKeysAvailableWithType({
            object: state.inProgressMessages,
            keys: [{ [candidate_id]: "array" }],
          })
        ) {
          const updatedMessages = state.inProgressMessages[candidate_id].filter(
            (each) =>
              !areKeysAvailableWithType({ object: each, keys: [{ message_id, exact: true }] })
          );
          if (updatedMessages.length > 0) {
            return {
              [candidate_id]: updatedMessages,
            };
          }
        }
        return {};
      })();

      state.inProgressMessages = {
        ...existingMessages,
        ...existingCandidateMessages,
      };
    },
    setDestination: (state, action) => {
      const { payload } = action;
      if (
        !areKeysAvailableIn({
          object: payload,
          keys: ["id", "last_name", "first_name", "email"],
        })
      )
        return;
      const { id, last_name, first_name, email } = payload;
      if (areKeysAvailableWithType({ object: state.destination, keys: [{ id, exact: true }] })) {
        state.destination = {
          id,
          email,
          last_name,
          first_name,
          full_name: getFullName({
            last_name,
            first_name,
          }),
        };
      }
    },
    setSource: (state, action) => {
      const { payload } = action;
      if (
        !areKeysAvailableIn({
          object: payload,
          keys: ["id", "last_name", "first_name", "email"],
        })
      )
        return;
      const { id, last_name, first_name, email } = payload;
      if (areKeysAvailableWithType({ object: state.source, keys: [{ id, exact: true }] })) {
        state.source = {
          id,
          email,
          last_name,
          first_name,
          full_name: getFullName({
            last_name,
            first_name,
          }),
        };
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserFromAPI.fulfilled, (state, action) => {
      if (action.payload) {
        const { data, status } = action.payload;
        if (
          status === 200 &&
          areKeysAvailableIn({
            object: data.user,
            keys: ["id", "first_name", "last_name", "email"],
          })
        ) {
          state.source = {
            email: data.user.email,
            id: data.user.id,
            first_name: data.user.first_name,
            last_name: data.user.last_name,
            full_name: getFullName({
              first_name: data.user.first_name,
              last_name: data.user.last_name,
            }),
          };
        }
      }
    });
    builder.addCase(updateUserInAPI.fulfilled, (state, action) => {
      const { payload } = action;
      if (!areKeysAvailableIn({ object: payload, keys: ["status", "user"] })) return;
      const { user } = payload;
      if (!areKeysAvailableIn({ object: user, keys: ["id", "first_name", "last_name", "email"] }))
        return;
      const { id, first_name, last_name, email } = user;
      if (areKeysAvailableWithType({ object: state.source, keys: [{ id, exact: true }] })) {
        state.source = {
          id,
          email,
          last_name,
          first_name,
          full_name: getFullName({
            last_name,
            first_name,
          }),
        };
      }
    });
    builder.addCase(
      inProgressConversationsSlice.actions.setViewingingCandidateID,
      (state, action) => {
        const { payload } = action;
        if (typeof payload === "string") {
          state.viewingCandidateID = payload;
        }
      }
    );
  },
});
