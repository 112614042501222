import { useMemo } from "react";
import { getFullName } from "utils/nameHelpers";
import { getInitialLettersFromName } from "utils/getLettersFromName";

const useName = ({ info, first_name, last_name }) => {
  const result = useMemo(() => {
    const fullName = getFullName({
      first_name: first_name,
      last_name: last_name,
      fallback: "John Doe",
    });
    const letters = getInitialLettersFromName(fullName);

    if (!info?.logo) {
      return {
        hasURL: false,
        letters,
        fullName,
      };
    }

    if (info.logo && !info.logo.url) {
      return {
        hasURL: false,
        letters,
        fullName,
      };
    }

    if (info.logo.url) {
      return {
        hasURL: true,
        url: info.logo.url,
        fullName,
      };
    }

    // eslint-disable-next-line
  }, [info]);

  return result;
};

export default useName;
