import { useState } from "react";
import { Envelope, Key } from "icons";
import { PublicTemplate } from "components/reusables";
import useTranslation from "components/hooks/useTranslation";
import { EmailSent, PasswordEmail } from "components/pages/forgotUserPassword";

const ForgotUserPassword = ({
  title = "Forgot Password?",
  backButtonText = "Back to log in",
  backButtonHref = "/login",
}) => {
  const [email, setEmail] = useState("");
  const [forgetPasswordStage, setForgetPasswordStage] = useState("email");

  const { t } = useTranslation();

  return (
    <>
      {forgetPasswordStage === "email" && (
        <PublicTemplate
          IconSvg={Key}
          title={t(title)}
          subTitle={t("No worries, we will send you the reset instructions")}
          showBackButton
          {...{
            backButtonHref,
            backButtonText,
          }}
        >
          <PasswordEmail {...{ forgetPasswordStage, setForgetPasswordStage, email, setEmail }} />
        </PublicTemplate>
      )}
      {forgetPasswordStage === "sent" && (
        <PublicTemplate
          IconSvg={Envelope}
          title={t("Check your email")}
          subTitle={`${t("We sent you a password reset link to")} ${email}`}
          showBackButton
        >
          <EmailSent {...{ forgetPasswordStage, setForgetPasswordStage, email, setEmail }} />
        </PublicTemplate>
      )}
    </>
  );
};

export default ForgotUserPassword;
