export function getNameFromEmail(email) {
  let name = `user${Date.now()}`;

  if (!email || typeof email !== "string") return name;

  const splittedEmail = email.split("@");

  if (splittedEmail.length > 1) {
    name = splittedEmail[0];
  }

  return name;
}
