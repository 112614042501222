import ReactDOM from "react-dom";
import { Reshaped, View } from "reshaped";

import store from "app/store";
import { Provider } from "react-redux";
import { SpacingProvider } from "contexts/spacing/SpacingContext";
import styles from "components/styles/reshaped/reusables/modal.module.css";

export const CandidateModal = (props) => {
  const { open, overlay = true, overlayColor = "rgba(0, 0, 0, 0.7)", children } = props;

  if (!open) return null;

  return ReactDOM.createPortal(
    <Reshaped theme="cooper">
      <Provider store={store}>
        <SpacingProvider>
          {overlay && (
            <View
              className={styles.candidate__overlay}
              attributes={{
                style: {
                  backgroundColor: overlayColor,
                },
              }}
            ></View>
          )}
          <View className={`${styles.candidate__wrapper} no__scroll`}>{children}</View>
        </SpacingProvider>
      </Provider>
    </Reshaped>,
    document.getElementById("modal-root")
  );
};
