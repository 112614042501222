import React from "react";
import routes from "routes";
import Main from "components/Main";
import { Routes, Route } from "react-router-dom";
import Scrollbars from "react-custom-scrollbars-2";
import { GenerateNestedRoutes } from "utils/router";
import { LocaleProvider } from "translations/LocaleContext";
import ModalProvider from "components/context/modal/ModalProvider";
import usePublicCountries from "components/hooks/usePublicCountries";
import { ScrollToTop, ErrorBoundary } from "components/reusables/common";
import usePreviousPageStateReset from "components/hooks/usePreviousPageStateReset";

export default function App() {
  usePreviousPageStateReset();
  usePublicCountries(true);
  return (
    <ErrorBoundary>
      <LocaleProvider>
        <ModalProvider>
          <Scrollbars style={{ height: "100vh" }} autoHide universal={true}>
            <Main>
              <ScrollToTop />
              <Routes>
                {routes.map(({ path, component, exact, nested }) =>
                  nested && nested.length > 0 ? (
                    <React.Fragment key={path}>
                      {GenerateNestedRoutes({ path, component, exact, nested })}
                    </React.Fragment>
                  ) : (
                    <Route path={path} exact={exact} element={component} key={path} />
                  )
                )}
              </Routes>
            </Main>
          </Scrollbars>
        </ModalProvider>
      </LocaleProvider>
    </ErrorBoundary>
  );
}
