import { Text, View } from "reshaped";
import { useSelector } from "react-redux";
import useTranslation from "components/hooks/useTranslation";
import { JobBoard, ShowJobBoardsError } from "components/pages/promote";
import styles from "components/styles/reshaped/promote/promote.module.css";

export const JobBoards = () => {
  const { t } = useTranslation();
  const { allJobBoards } = useSelector((state) => state.jobBoards);

  return (
    <>
      {Array.isArray(allJobBoards) ? (
        <View className={styles.boards__wrapper}>
          {allJobBoards.length > 0 ? (
            allJobBoards.map((board) => {
              return <JobBoard key={board.id} board={board} />;
            })
          ) : (
            <Text variant="body-strong-1" color="neutral-faded">
              {t("No job boards found")}
            </Text>
          )}
        </View>
      ) : (
        <ShowJobBoardsError />
      )}
    </>
  );
};
