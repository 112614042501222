import { useEffect, useRef, useState } from "react";

import { useSelector } from "react-redux";
import { JobLocation, Reload } from "icons";
import { baseColor } from "utils/colors/accents";
import useSpacing from "components/hooks/useSpacing";
import { fetchFilterJobs } from "utils/pages/candidate";
import useTranslation from "components/hooks/useTranslation";
import { Button, Checkbox, Icon, Text, View } from "reshaped";
import { getLocationFromCityAndCountry } from "utils/getters";
import { areKeysAvailableWithType, isString } from "utils/miniHelpers";
import { ReshapedLoader, TextWithEclipses } from "components/reusables";
import { getTextWithEclipses, isTextExceeding } from "utils/getTextWithEclipses";

export const FilterAccordionJobOptions = ({ handleUpdate, showOptions, setIsUpdated }) => {
  const [data, setData] = useState([]);
  const [batch, setBatch] = useState(-1);
  const [after, setAfter] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(null);
  const [checkedData, setCheckedData] = useState([]);
  const [isListExhausted, setIsListExhausted] = useState(false);

  const observerTarget = useRef(null);
  const { t, locale } = useTranslation();
  const { xs, md, lg, sm, xl } = useSpacing();
  const state = useSelector((state) => state);

  const { user } = state.auth;
  const { candidates: candidatesFilterData } = state.filters;
  const { jobs } = candidatesFilterData;
  const { isExhausted, viewing, list, selected, error, afterKey } = jobs;

  useEffect(() => {
    setData(list);
    setBatch(viewing);
    setHasError(error);
    setAfter(afterKey);
    setCheckedData(selected);
    setIsListExhausted(isExhausted);
    handleUpdate({ updatedData: { list, viewing, isExhausted, selected, error } });
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!showOptions) return;
    if (!error && batch < 0 && Array.isArray(data) && data.length === 0 && !isListExhausted) {
      async function getInitialJobs() {
        setLoading(true);
        const response = await fetchFilterJobs({
          batch: 0,
          company_id: user.company_id,
          key: after,
        });
        const newData = [...data, ...response.list];
        setData(newData);
        setBatch(response.viewing);
        setHasError(response.error);
        setAfter(response.afterKey);
        setIsListExhausted(response.isExhausted);
        handleUpdate({ updatedData: { ...response, list: newData } });
        setLoading(false);
      }
      getInitialJobs();
    }

    //eslint-disable-next-line
  }, [showOptions]);

  useEffect(
    () => {
      const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && !isListExhausted && !hasError && !loading) {
            async function fetchMoreJobs() {
              const newBatch = batch + 1;
              setLoading(true);
              const response = await fetchFilterJobs({
                batch: newBatch,
                company_id: user.company_id,
                key: after,
              });
              const newData = [...data, ...response.list];
              setData(newData);
              setBatch(response.viewing);
              setHasError(response.error);
              setAfter(response.afterKey);
              setIsListExhausted(response.isExhausted);
              handleUpdate({ updatedData: { ...response, list: newData } });
              setLoading(false);
            }
            fetchMoreJobs();
          }
        },
        { threshold: 1 }
      );

      if (observerTarget.current && observerTarget.current instanceof HTMLDivElement) {
        observer.observe(observerTarget.current);
      }
      return () => {
        if (observerTarget.current && observerTarget.current instanceof HTMLDivElement) {
          //eslint-disable-next-line
          observer.unobserve(observerTarget.current);
        }
      };
    },
    //eslint-disable-next-line
    [data]
  );

  const handleChange = ({ value, event }) => {
    setIsUpdated(true);
    if (event.target.checked) {
      setCheckedData((prev) => {
        const newData = [...prev, value];
        handleUpdate({ updatedData: { selected: newData } });
        return newData;
      });
    } else {
      setCheckedData((prev) => {
        const newData = prev.filter((v) => v.id !== value.id);
        handleUpdate({ updatedData: { selected: newData } });
        return newData;
      });
    }
  };

  const handleRefetchJobs = async () => {
    setLoading(true);
    setHasError(() => {
      handleUpdate({ updatedData: { error: false } });
      return false;
    });
    const response = await fetchFilterJobs({ batch, company_id: user.company_id, key: after });
    const newData = [...data, ...response.list];
    setData(newData);
    setBatch(response.viewing);
    setHasError(response.error);
    setAfter(response.afterKey);
    setIsListExhausted(response.isExhausted);
    handleUpdate({ updatedData: { ...response, list: newData } });
    setLoading(false);
  };

  return (
    <View gap={lg} padding={lg} paddingBottom={xl + sm}>
      {Array.isArray(data) && (
        <>
          {data.length === 0 && !hasError && isListExhausted && (
            <Text variant="caption-1" align="center" color="neutral-faded">
              {t("Looks like you don't have active jobs!")}
            </Text>
          )}
          {data.length > 0 && (
            <>
              {data.map((each) => {
                if (areKeysAvailableWithType({ object: each, keys: [{ title: "string" }] })) {
                  return (
                    <Checkbox
                      key={each.id}
                      checked={
                        Array.isArray(checkedData) &&
                        checkedData.some((data) => data.id === each.id)
                      }
                      name={each.title}
                      value={each}
                      inputAttributes={{ "data-cooper": `job filter ${each.title}` }}
                      onChange={handleChange}
                      attributes={{ title: isTextExceeding(each.title, 30) ? each.title : "" }}
                    >
                      <View>
                        <Text variant="body-2">{getTextWithEclipses(each.title, 30)}</Text>
                        {getLocationFromCityAndCountry({
                          city: each.city,
                          country: isString(each.country_code) ? each.country_code : each.country,
                          noLocationText: "",
                          locale,
                        }) && (
                          <View gap={xs} align="center" direction="row">
                            <Icon svg={JobLocation} size={md} />
                            <TextWithEclipses
                              variant="caption-1"
                              color="neutral-faded"
                              text={getLocationFromCityAndCountry({
                                city: each.city,
                                country: isString(each.country_code)
                                  ? each.country_code
                                  : each.country,
                                noLocationText: "",
                                locale,
                              })}
                            />
                          </View>
                        )}
                      </View>
                    </Checkbox>
                  );
                }
                return null;
              })}
              {!isListExhausted && batch >= 0 && !loading && !hasError && (
                <View attributes={{ ref: observerTarget }}>
                  <View height="50px" />
                </View>
              )}
            </>
          )}
        </>
      )}
      {hasError && !loading && !isListExhausted && (
        <View gap={sm} direction="row" align="center" justify="center" height="100%">
          <Text color="critical" variant="caption-1">
            {t("Jobs fetching failed")}
          </Text>
          <Button
            size="small"
            color="critical"
            variant="ghost"
            startIcon={() => <Reload fill={baseColor.critical} />}
            onClick={handleRefetchJobs}
          />
        </View>
      )}
      {loading && <ReshapedLoader size="small" animationSpeed={"0.7s"} />}
    </View>
  );
};
