import { useMemo } from "react";

const useEmploymentContract = (employment_contract) => {
  const contract = useMemo(() => {
    const fullTimeRegex = /(full)-(time)/i;
    const partTimeRegex = /(part)-(time)/i;
    if (fullTimeRegex.test(employment_contract)) return "Full Time";
    if (partTimeRegex.test(employment_contract)) return "Part Time";
    return "";

    //eslint-disable-next-line
  }, []);
  return contract;
};

export default useEmploymentContract;
