import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Tooltip } from "components/library";
import { Avatar, Text, View } from "reshaped";
import useName from "components/hooks/useName";
import { getFullName } from "utils/nameHelpers";
import { Rating } from "components/reusables/common";
import useSpacing from "components/hooks/useSpacing";
import useFullName from "components/hooks/useFullName";
import { TextWithEclipses } from "components/reusables";
import useTranslation from "components/hooks/useTranslation";
import { CandidateBadge } from "components/pages/candidates";
import { getFullFormattedDate } from "utils/getFormattedDate";
import { getLocationFromCityAndCountry } from "utils/getters";
import { getInitialLettersFromName } from "utils/getLettersFromName";
import styles from "components/styles/reshaped/candidates.module.css";
import common from "components/styles/reshaped/reusables/common.module.css";
import { areKeysAvailableWithType, getValueFrom, isString } from "utils/miniHelpers";

export const CandidatesTableRow = (props) => {
  const { candidate, handleOpenModal, handleViewingCandidate, rowColor = "white", isDashboard = false } = props;

  const {
    id,
    city,
    stage,
    country,
    followers,
    last_name,
    first_name,
    created_at,
    country_code,
    average_rating,
  } = candidate;

  const { xs,sm, lg, xl } = useSpacing();
  const { t, locale } = useTranslation();
  const { publicState, candidate: candidateState } = useSelector((state) => state);

  const candidateProfile = useName({ first_name, last_name, info: candidate });
  const fullName = useFullName({ first_name, last_name, dependency: [candidate] });

  const { countries } = publicState;
  const { data } = countries;
  const { recruiters } = candidateState;

  const followerData = useMemo(() => {
    if (isDashboard) return;

    return followers.map((followerId) =>
      recruiters.find((recruiter) => recruiter.id === followerId)
    ).filter(Boolean);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [followers, recruiters]);

  const fullDate = useMemo(() => {
    return getFullFormattedDate(created_at);

    //eslint-disable-next-line
  }, []);

  const jobInfo = useMemo(() => {
    const versions = {
      de: "Keine Jobinformationen",
      en: "No job information",
    };

    const result = {
      name: getValueFrom({
        object: versions,
        key: locale,
        defaultKey: "en",
        defaultValue: "NA",
      }),
      isDeleted: false,
    };

    if (areKeysAvailableWithType({ object: candidate, keys: [{ applied_job: "string" }] })) {
      result.name = candidate.applied_job;
      if (
        areKeysAvailableWithType({ object: candidate, keys: [{ job_deleted: true, exact: true }] })
      ) {
        result.isDeleted = true;
      }
    }

    return result;

    //eslint-disable-next-line
  }, []);

  const location = useMemo(() => {
    return getLocationFromCityAndCountry({
      city,
      locale,
      country: isString(country_code) ? country_code : country,
      noLocationText: "",
      countryList: data,
    });

    //eslint-disable-next-line
  }, [data]);

  const handleCandidateNavigation = () => {
    handleViewingCandidate(id);
    handleOpenModal();
  };

  return (
    <View
      direction="row"
      align="center"
      gap={xl}
      padding={[0, lg]}
      height="48px"
      borderRadius="medium"
      className={`${common.shadow__base}`}
      attributes={{
        onClick: handleCandidateNavigation,
        style: {
          backgroundColor: rowColor,
        },
        "data-cooper": `candidate ${id}`,
      }}
    >
      <View.Item>
        <View direction="row" gap={2.5} align="center">
          {candidateProfile.hasURL && <Avatar size={8} src={candidateProfile.url} alt={fullName} />}
          {!candidateProfile.hasURL && (
            <Avatar size={8} initials={candidateProfile.letters} alt={fullName} />
          )}
          <View width="160px">
            <TextWithEclipses
              variant="body-2"
              text={fullName}
              characterLimit={20}
              {...{ maxLines: 1 }}
            />
          </View>
        </View>
      </View.Item>
      <View.Item columns={3}>
        <View direction="row" align="center" gap={sm}>
          <TextWithEclipses
            variant="body-2"
            text={`${jobInfo.name}`}
            characterLimit={jobInfo.isDeleted ? 23 : 45}
            noOfEclipses={2}
            {...{ maxLines: 1 }}
          />
          {jobInfo.isDeleted && (
            <Text variant="body-strong-2" color="critical">
              {"("}
              {t("Deleted")}
              {")"}
            </Text>
          )}
        </View>
      </View.Item>
      <View.Item grow>
        <TextWithEclipses
          color={location === "" ? "disabled" : "neutral"}
          variant={location === "" ? "body-strong-2" : "body-2"}
          text={location === "" ? "" : location}
          characterLimit={20}
          noOfEclipses={2}
          {...{ maxLines: 1 }}
        />
      </View.Item>
      <View.Item grow>
        <CandidateBadge stage={stage} candidate_id={id} />
      </View.Item>
      <View.Item grow>
        <Text variant="body-2" color="neutral">
          {fullDate.space ? fullDate.text : fullDate.text}
        </Text>
      </View.Item>

      {!isDashboard && (
        <View.Item grow>
          <View direction="row" align={"center"} gap={xs} attributes={{ style: { diaplay: "flex", flexWrap: "nowrap" } }}>
            <View direction={"row"} className={styles.followers_avatars} attributes={{ style: { maxWidth: "fit-content" } }}>
              {followerData.length ? followerData.slice(0, 4).map((follower) => {
                const fullName = getFullName(follower);
                const letters = getInitialLettersFromName(fullName);
                return (
                  <View key={follower.id} className={styles.followers_avatar}>
                    {follower.logo?.url && (
                      <Tooltip position="top" text={fullName}>
                        {({ className }) => (
                          <Avatar className={`${className}`} size={sm * 3} src={follower.logo?.url} alt={fullName} />
                        )}
                      </Tooltip>
                    )}
                    {!follower.logo?.url && (
                      <Tooltip position="top" text={fullName}>
                        {({ className }) => (
                          <Avatar className={`${className}`} size={sm * 3} initials={letters} alt={fullName} />
                        )}
                      </Tooltip>
                    )}
                  </View>
                );
              }) : (
                <Text color="neutral-faded" variant="caption-1">{t("No Followers")} </Text>
              )}
            </View>
            <View>
              {followers.length > 4 ? <Text variant="caption-2" color="neutral-faded">{`+${followers.length-4}` }</Text> : null}
            </View>
          </View>
        </View.Item>
      )}

      <View.Item grow>
        <Rating pointsSecured={average_rating ? average_rating : 0} size={20} />
      </View.Item>
    </View>
  );
};
