import { useMemo } from "react";
import { useSelector } from "react-redux";

const useRole = () => {
  const { user } = useSelector((state) => state.auth);

  const role = useMemo(
    () => {
      if (user && user.role) return user.role;
      return "member";
    },

    // eslint-disable-next-line
    user && user.role ? [user.role] : []
  );

  return role;
};

export default useRole;
