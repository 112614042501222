import React, { useRef } from "react";

import { Filter } from "icons";
import { Icon, Text, View } from "reshaped";
import { filtersSlice } from "redux/filters";
import { candidateSlice } from "redux/candidate";
import { cooperColors } from "utils/colors/accents";
import useSpacing from "components/hooks/useSpacing";
import { useDispatch, useSelector } from "react-redux";
import useTranslation from "components/hooks/useTranslation";
import { fetchFilteredCandidates } from "utils/pages/candidate";
import styles from "components/styles/reshaped/candidates.module.css";
import { FilterMenu } from "components/pages/candidates";
import CustomDropdown from "components/reusables/dropdowns/CustomDropdown";
import useUserLanguagePreference from "components/hooks/useUserLanguagePreference";
import { areKeysAvailableIn, areKeysAvailableWithType, sanitizeArray } from "utils/miniHelpers";

export const FiltersAndSorting = React.memo(() => {
  const jobRef = useRef({});
  const stageRef = useRef({});
  const followRef = useRef({});
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { xs, sm, md } = useSpacing();
  const state = useSelector((state) => state);
  const userLang = useUserLanguagePreference();
  const { candidates: candidatesFilters } = state.filters;
  const { search } = candidatesFilters;
  const { selected: selectedSearches } = search;

  const { user } = state.auth;
  const { candidates } = state.sorting;

  const handleUnfocus = async ({ reset = false }) => {
    const finalJobData = {};
    const finalStageData = {};
    const finalFollowData = {};

    if (areKeysAvailableWithType({ object: jobRef.current, keys: [{ isExhausted: "boolean" }] })) {
      finalJobData["isExhausted"] = jobRef.current.isExhausted;
    }
    if (areKeysAvailableIn({ object: jobRef.current, keys: ["list"] })) {
      finalJobData["list"] = jobRef.current.list;
    }
    if (areKeysAvailableWithType({ object: jobRef.current, keys: [{ viewing: "number" }] })) {
      finalJobData["viewing"] = jobRef.current.viewing;
    }
    if (areKeysAvailableWithType({ object: jobRef.current, keys: [{ error: "boolean" }] })) {
      finalJobData["error"] = jobRef.current.error;
    }
    if (areKeysAvailableIn({ object: jobRef.current, keys: ["afterKey"] })) {
      finalJobData["afterKey"] = jobRef.current.afterKey;
    }
    if (areKeysAvailableWithType({ object: followRef.current, keys: [{ selected: "array" }] })) {
      finalFollowData["selected"] = sanitizeArray({ array: followRef.current.selected, elementType: "number" });
    }

    if (!reset) {
      let selectedJobs = [];
      let selectedStages = [];
      let selectedFollowers = [];
      if (areKeysAvailableWithType({ object: jobRef.current, keys: [{ selected: "array" }] })) {
        selectedJobs = sanitizeArray({
          array: jobRef.current.selected,
          elementType: "object",
          keys: [{ title: "string" }],
        });
        finalJobData["selected"] = selectedJobs;
      }
      if (areKeysAvailableWithType({ object: stageRef.current, keys: [{ selected: "array" }] })) {
        selectedStages = sanitizeArray({ array: stageRef.current.selected, elementType: "string" });
        finalStageData["selected"] = selectedStages;
      }
      if (areKeysAvailableWithType({ object: followRef.current, keys: [{ selected: "array" }] })) {
        selectedFollowers = sanitizeArray({ array: followRef.current.selected, elementType: "number" });
        finalFollowData["selected"] = selectedFollowers;
      }
      dispatch(
        candidateSlice.actions.set({
          isFiltering: true,
          filteringError: null,
        })
      );
      const response = await fetchFilteredCandidates({
        company_id: user.company_id,
        selectedJobs: selectedJobs,
        selectedStages: selectedStages,
        selectedFollowers: selectedFollowers,
        sorting: candidates,
        current_language: userLang,
        selectedSearches: selectedSearches,
      });
      if (areKeysAvailableWithType({ object: response, keys: [{ data: "object" }] })) {
        const { candidates, totalCandidates } = response.data;
        dispatch(
          candidateSlice.actions.set({
            pageCount: 0,
            isFiltering: false,
            candidates,
            totalCandidates,
          })
        );
      }
      if (areKeysAvailableWithType({ object: response, keys: [{ hasError: "boolean" }] })) {
        dispatch(
          candidateSlice.actions.set({
            pageCount: 0,
            isFiltering: false,
            totalCandidates: 0,
            filteringError: "something went wrong!",
          })
        );
      }
      dispatch(
        filtersSlice.actions.setPageFilter({
          page: "candidates",
          types: { name: "jobs", value: finalJobData },
        })
      );

      dispatch(
        filtersSlice.actions.setPageFilter({
          page: "candidates",
          types: { name: "stages", value: finalStageData },
        })
      );
      dispatch(
        filtersSlice.actions.setPageFilter({
          page: "candidates",
          types: { name: "followers", value: finalFollowData },
        })
      );
      // jobRef.current = checkedJobs;
    }

    if (reset) {
      dispatch(
        filtersSlice.actions.setPageFilter({
          page: "candidates",
          types: { name: "jobs", value: finalJobData },
        })
      );

      dispatch(
        filtersSlice.actions.setPageFilter({
          page: "candidates",
          types: { name: "stages", value: finalStageData },
        })
      );

      dispatch(
        filtersSlice.actions.setPageFilter({
          page: "candidates",
          types: { name: "followers", value: finalFollowData },
        })
      );
    }

    jobRef.current = {};
    stageRef.current = {};
    followRef.current = {};
  };

  return (
    <View gap={md}>
      <View direction="row" attributes={{ style: { zIndex: 1 } }}>
        <CustomDropdown
          // focusHandler={handleFocus}
          unfocusHandler={handleUnfocus}
          MenuComponent={({ closeDropdown }) => (
            <FilterMenu
              {...{
                jobRef,
                stageRef,
                followRef,
                closeDropdown,
              }}
            />
          )}
          MenuClassName={styles.dropdown__menu}
        >
          <View
            gap={xs}
            padding={[sm, md]}
            backgroundColor="white"
            direction="row"
            align="center"
            borderRadius="small"
            attributes={{
              style: {
                border: "1px solid var(--rs-color-border-neutral-faded)",
                color: cooperColors["text-button-gray-color"]
              },
              "data-cooper": "candidates filter by",
            }}
          >
            <Icon size={md} svg={() => <Filter fill={cooperColors["text-button-gray-color"]} />} />
            <Text variant="body-2">
              {t("Filter by")}
            </Text>
          </View>
        </CustomDropdown>
      </View>
    </View>
  );
});
