import { useEffect, useState, useMemo } from "react";
import { Button, View } from "reshaped";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { NavigateLeft, NavigateRight } from "icons";
import useSpacing from "components/hooks/useSpacing";
import { areKeysAvailableWithType } from "utils/miniHelpers";
import { Profile, ProfileStats } from "components/pages/candidates";
import styles from "components/styles/reshaped/candidates.module.css";

export const ProfileModalContent = (props) => {
  const {
    handleClose,
    candidates,
    viewingCandidateId,
    setViewingCandidateId,
    appendIDWithURL = false,
  } = props;

  const [currentIndex, setCurrentIndex] = useState(null);

  const navigate = useNavigate();
  const { xs, lg, xl, xxl } = useSpacing();
  const { loading } = useSelector((state) => state.viewingCandidate);

  const CANDIDATESLENGTH = useMemo(() => {
    return props.newCandidate ? 0 : candidates.length;

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!viewingCandidateId) return handleClose({ notFound: true });

    if (!props.newCandidate) {
      let isCandidateInList = false;
      for (let i = 0; i < CANDIDATESLENGTH; i++) {
        if (candidates[i].id === viewingCandidateId) {
          isCandidateInList = true;
          setCurrentIndex(i);
        }
      }
      if (!isCandidateInList) {
        return handleClose({ notFound: true });
      }
    }

    //eslint-disable-next-line
  }, [viewingCandidateId]);

  // user experience with keyboard
  useEffect(() => {
    const handler = (e) => {
      const pressedKeyCode = e.keyCode || e.which;
      if (pressedKeyCode === 27) handleClose({ notFound: false });
    };
    window.addEventListener("keydown", handler);
    return () => {
      window.removeEventListener("keydown", handler);
    };

    //eslint-disable-next-line
  }, []);

  const previousIndex = useMemo(() => {
    if (currentIndex !== 0 && !currentIndex) return null;
    if (currentIndex === 0) return null;
    const activeIndex = Number(currentIndex);
    return activeIndex - 1;

    //eslint-disable-next-line
  }, [currentIndex]);

  const nextIndex = useMemo(() => {
    if (currentIndex !== 0 && !currentIndex) return null;
    if (currentIndex === CANDIDATESLENGTH - 1) return null;
    const activeIndex = Number(currentIndex);
    return activeIndex + 1;

    //eslint-disable-next-line
  }, [currentIndex]);

  const handlePreviousProfile = () => {
    if (previousIndex !== 0 && !previousIndex) return null;
    const previousCandidate = candidates[previousIndex];

    if (!areKeysAvailableWithType({ object: previousCandidate, keys: [{ id: "string" }] })) return;
    setViewingCandidateId(previousCandidate.id);
    if (appendIDWithURL) navigate(`/candidates?id=${previousCandidate.id}`);

    setCurrentIndex(previousIndex);
  };

  const handleNextProfile = () => {
    // if (props?.newView && props?.clearParams) {
    //   props.setNewView(false);
    //   props.clearParams();
    // }
    if (nextIndex !== 0 && !nextIndex) return null;
    const nextCandidate = candidates[nextIndex];

    if (!areKeysAvailableWithType({ object: nextCandidate, keys: [{ id: "string" }] })) return;
    setViewingCandidateId(nextCandidate.id);
    if (appendIDWithURL) navigate(`/candidates?id=${nextCandidate.id}`);

    setCurrentIndex(nextIndex);
  };

  return (
    <View className={styles.modal__container}>
      <View padding={[lg, xxl + lg + xs]} gap={xl} className={styles.profile__container}>
        {!loading && !props.newCandidate && (
          <Button
            disabled={typeof previousIndex !== "number" || (previousIndex !== 0 && !previousIndex)}
            onClick={handlePreviousProfile}
            startIcon={NavigateLeft}
            rounded
            size="large"
            color="white"
            attributes={{
              style: {
                background: "var(--rs-color-white)",
              },
            }}
            className={styles.navigate__prev}
          />
        )}
        {viewingCandidateId && (
          <>
            <Profile {...{ handleClose, viewingCandidateId }} />
            <ProfileStats {...{ handleClose, viewingCandidateId }} />
          </>
        )}
        {!loading && !props.newCandidate && (
          <Button
            disabled={typeof nextIndex !== "number" || (nextIndex !== 0 && !nextIndex)}
            className={styles.navigate__next}
            color="white"
            size="large"
            rounded
            attributes={{
              style: {
                background: "var(--rs-color-white)",
              },
            }}
            onClick={handleNextProfile}
            startIcon={NavigateRight}
          />
        )}
      </View>
    </View>
  );
};
