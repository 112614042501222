import { useMemo, useState } from "react";
import { Avatar, Button, Image, Text, useToast, View } from "reshaped";

import { backendAPI } from "utils/axios";
import { useDispatch } from "react-redux";
import useName from "components/hooks/useName";
import { bookingsSlice } from "redux/bookings";
import { FoldText } from "components/reusables";
import useSpacing from "components/hooks/useSpacing";
import { areKeysAvailableIn } from "utils/miniHelpers";
import { reloadOnUnauthorized } from "utils/checkForAuth";
import { ShowToastWithTranslation } from "utils/showToast";
import useTranslation from "components/hooks/useTranslation";
import { getFullFormattedDate } from "utils/getFormattedDate";
import { fetchInventory } from "redux/bookings/bookingsSlice";
import { SuccessfulToast } from "components/reusables/tab/SuccessfulToast";
import { OperationFailedWithTranslation } from "components/reusables/OperationFailed";

export const ConfirmJobPromotion = ({ handleModalClose, promotingSlot, promotingJob }) => {
  const { title, city, job_id } = promotingJob;
  const { job_board_name, slot_name, slot_desc } = promotingSlot;

  const [confirmationLoading, setConfirmationLoading] = useState(false);

  const toast = useToast();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { xs, sm, lg, xl, xxl } = useSpacing();

  const jobBoardProfile = useName({
    info: promotingSlot,
    first_name: promotingSlot?.job_board_name || "Job Board",
  });

  const handleConfirmation = async () => {
    if (!promotingJob || !promotingSlot) return;
    const slot_management = {
      slot_management_id: promotingSlot.slot_mgt_id,
      job_id: promotingJob.job_id,
    };

    function promotionError(text = "Operation unsuccessful!") {
      ShowToastWithTranslation({
        toast,
        Children: OperationFailedWithTranslation,
        text: t(text),
      });
      dispatch(fetchInventory());
    }

    try {
      setConfirmationLoading(true);
      const response = await backendAPI.post(`/companies/promote/job`, {
        slot_management,
      });
      setConfirmationLoading(false);
      handleModalClose({ hardReset: true });

      if (response.data) {
        const data = response.data;

        if (
          areKeysAvailableIn({ object: data, keys: ["inventory"] }) &&
          Array.isArray(data.inventory)
        ) {
          dispatch(bookingsSlice.actions.setInventory({ inventory: data.inventory }));
          ShowToastWithTranslation({
            toast,
            Children: SuccessfulToast,
            text: t("Operation successful!"),
          });

          return;
        }

        if (
          areKeysAvailableIn({ object: data, keys: ["errors", "status"] }) &&
          typeof data.errors === "string"
        ) {
          promotionError(data.errors);
          return;
        }
      }

      promotionError();
    } catch (error) {
      reloadOnUnauthorized(error);
      setConfirmationLoading(false);
      promotionError();
    }
  };

  return (
    <View padding={xl} gap={xxl + sm}>
      <Text variant="title-3">{t("Activate your booking")}</Text>
      <View gap={xl + lg}>
        <View direction="row" justify="start" gap={lg} align="center">
          {jobBoardProfile.hasURL ? (
            <Image width="76px" height="76px" src={jobBoardProfile.url} alt={job_board_name} />
          ) : (
            <Avatar squared size={19} initials={jobBoardProfile.letters} alt={job_board_name} />
          )}
          <View.Item grow>
            <View gap={xs}>
              <View direction="row" align="baseline" gap={sm}>
                <Text variant="title-3">{job_board_name}</Text>
                <Text variant="body-strong-1">{slot_name}</Text>
              </View>
              <FoldText variant="body-1" text={slot_desc} characterLimit={80} />
            </View>
          </View.Item>
        </View>
        <View gap={sm}>
          <Text align="start" variant="body-strong-1">
            {title}
          </Text>
          <View direction="row" align="center" gap={xs}>
            <Text variant="body-2">
              {t("Job ID ")} {job_id}
            </Text>
            <Text variant="body-2">|</Text>
            <Text variant="body-2">
              {t("Location")}: {city}
            </Text>
          </View>
        </View>
      </View>
      <SlotMetaData slot={promotingSlot} />
      <Button loading={confirmationLoading} color="primary" fullWidth onClick={handleConfirmation}>
        {t("Confirm Activation")}
      </Button>
    </View>
  );
};

const SlotMetaData = ({ slot }) => {
  const { t } = useTranslation();
  const { sm, lg, xl } = useSpacing();

  const fullDate = useMemo(() => {
    return getFullFormattedDate(slot.purchased_at);

    //eslint-disable-next-line
  }, []);

  return (
    <View gap={sm}>
      <View direction="row" gap={xl + lg}>
        <View.Item columns={3}>
          <Text variant="body-strong-1">{t("Duration")}</Text>
        </View.Item>
        <View.Item columns={3}>
          <Text variant="body-strong-1">{t("Quantity")}</Text>
        </View.Item>
        <View.Item columns={2}>
          <Text variant="body-strong-1">{t("Price")}</Text>
        </View.Item>

        <View.Item columns={4}>
          <Text variant="body-strong-1">{t("Purchase Date")}</Text>
        </View.Item>
      </View>
      <View direction="row" gap={xl + lg}>
        <View.Item columns={3}>
          <Text variant="body-2">
            {slot.duration} {slot.duration === 1 ? "day" : "days"}
          </Text>
        </View.Item>
        <View.Item columns={3}>
          <Text variant="body-2">{1}</Text>
        </View.Item>
        <View.Item columns={2}>
          <Text variant="body-2">€{slot.price}</Text>
        </View.Item>
        <View.Item columns={4}>
          <Text variant="body-2">{fullDate.text}</Text>
        </View.Item>
      </View>
    </View>
  );
};
