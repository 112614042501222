import { backendAPI } from "utils/axios";
import { reloadOnUnauthorized } from "utils/checkForAuth";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: {
    inventory: null,
    activePastBookings: null,
    jobs: null,
  },
  inventory: null,
  activePastBookings: null,
  // not promoted and published jobs
  jobs: null,
  modalLoading: false,
  newAccount: false,
};

export const fetchInventory = createAsyncThunk("inventory/fetch", async () => {
  try {
    const response = await backendAPI.get(`/companies/inventories`);
    if (response.data) {
      const data = response.data;
      if (data.new_account) {
        return {
          newAccount: true,
        };
      }
      if (data.inventory && Array.isArray(data.inventory)) {
        return {
          inventory: data.inventory,
        };
      }
    }
    return {
      error: "Something went wrong!",
    };
  } catch (error) {
    reloadOnUnauthorized(error);
    return {
      error: "Something went wrong!",
    };
  }
});

export const fetchActivePastBookings = createAsyncThunk("activePastBookings/fetch", async () => {
  try {
    const response = await backendAPI.get(`/companies/bookings`);
    if (response.data && response.data.bookings && Array.isArray(response.data.bookings)) {
      const { bookings } = response.data;
      return {
        bookings,
      };
    }
    return {
      error: "Something went wrong!",
    };
  } catch (error) {
    reloadOnUnauthorized(error);
    return {
      error: "Something went wrong!",
    };
  }
});

export const fetchNotPromotedPublishedJobs = createAsyncThunk(
  "not_promoted_jobs/fetch",
  async ({ slot_management }) => {
    try {
      const response = await backendAPI.post("/companies/jobs_for_promotion", { slot_management });
      if (
        response.status === 200 &&
        response.data &&
        response.data.jobs &&
        Array.isArray(response.data.jobs)
      ) {
        return { data: response.data.jobs };
      }
      return {
        error: "Jobs fetching failed!",
      };
    } catch (error) {
      reloadOnUnauthorized(error);
      return {
        error: "Jobs fetching failed!",
      };
    }
  }
);

export const bookingsSlice = createSlice({
  name: "bookings",
  initialState,
  reducers: {
    reset: (state) => {
      for (let key in initialState) {
        state[key] = initialState[key];
      }
    },
    set: (state, actions) => {
      const { payload } = actions;
      if (!payload || typeof payload !== "object") return;
      Object.keys(payload).forEach((k) => {
        if (k in initialState) state[k] = payload[k];
      });
    },
    setInventory: (state, action) => {
      const { payload } = action;
      state.inventory = payload.inventory;
      state.jobs = null;
      state.activePastBookings = null;
    },
    clearJobs: (state) => {
      state.jobs = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchInventory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchInventory.fulfilled, (state, action) => {
      state.loading = false;
      const { payload } = action;
      if (payload.error) {
        state.error.inventory = payload.error;
        state.inventory = null;
      }
      if (payload.inventory) {
        state.newAccount = false;
        state.error.inventory = null;
        state.inventory = payload.inventory;
      }
      if (payload.newAccount) {
        state.newAccount = true;
      }
    });
    builder.addCase(fetchInventory.rejected, (state, action) => {
      const { payload } = action;
      state.loading = false;
      state.error.inventory = payload.error;
    });

    builder.addCase(fetchActivePastBookings.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchActivePastBookings.fulfilled, (state, action) => {
      state.loading = false;
      const { payload } = action;
      if (payload.error) {
        state.error.activePastBookings = payload.error;
        state.activePastBookings = null;
      }
      if (payload.bookings) {
        state.error.activePastBookings = null;
        state.activePastBookings = payload.bookings;
      }
    });
    builder.addCase(fetchActivePastBookings.rejected, (state, action) => {
      const { payload } = action;
      state.loading = false;
      state.error.activePastBookings = payload.error;
    });

    builder.addCase(fetchNotPromotedPublishedJobs.pending, (state) => {
      state.modalLoading = true;
    });
    builder.addCase(fetchNotPromotedPublishedJobs.fulfilled, (state, action) => {
      state.modalLoading = false;
      const { payload } = action;
      if (payload.data) {
        state.jobs = payload.data;
        state.error.jobs = null;
      }
      if (payload.error) {
        state.error.jobs = payload.error;
        state.jobs = null;
      }
    });
    builder.addCase(fetchNotPromotedPublishedJobs.rejected, (state, action) => {
      state.modalLoading = false;
      state.jobs = null;
      state.error.jobs = action.payload.error;
    });
  },
});
