import ForgotUserPassword from "../forgotUserPassword";

const ChangePassword = () => {
  return (
    <ForgotUserPassword
      title="Change password"
      backButtonText="Back to settings"
      backButtonHref="/settings?to=user+profile"
    />
  );
};

export default ChangePassword;
