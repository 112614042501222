import { ArrowDown } from "icons";
import { Button, View, Text } from "reshaped";
import { cooperColors } from "utils/colors/accents";
import useSpacing from "components/hooks/useSpacing";
import React, { useEffect, useState, useMemo } from "react";
import { isNumber, getIdFromDate } from "utils/miniHelpers";
import useTranslation from "components/hooks/useTranslation";

export function PaginationBoxes({
  perPage,
  activePageIndex = 0,
  totalCandidates,
  title,
  changeHandler,
}) {
  const { t } = useTranslation();
  const { sm } = useSpacing();

  const currentPage = activePageIndex + 1;
  const totalPages = Math.ceil(totalCandidates / parseInt(perPage));
  const [noOfPages, setNoOfPages] = useState([]);

  useEffect(() => {
    if (!isNumber(totalPages) || totalPages === 0 || !isNumber(currentPage)) return;
    function generatePaginationArray({ totalPages, currentPage }) {
      const paginationArray = [];

      if (totalPages <= 5) {
        for (let i = 1; i <= totalPages; i++) {
          paginationArray.push(i === currentPage ? String(i) : i);
        }
      } else {
        paginationArray.push(1 === currentPage ? String(1) : 1);

        if (currentPage <= 3) {
          for (let i = 2; i <= 4; i++) {
            paginationArray.push(i === currentPage ? String(i) : i);
          }
          paginationArray.push("...", totalPages);
        } else if (currentPage >= totalPages - 2) {
          paginationArray.push("...");
          for (let i = totalPages - 3; i <= totalPages; i++) {
            paginationArray.push(i === currentPage ? String(i) : i);
          }
        } else {
          paginationArray.push(
            "...",
            currentPage - 1,
            String(currentPage),
            currentPage + 1,
            "...",
            totalPages
          );
        }
      }

      return paginationArray;
    }

    const result = generatePaginationArray({
      totalPages,
      currentPage,
    }).reduce((acc, each) => {
      const newValue = {
        value: each !== "..." ? parseInt(each) : "...",
        id: `${getIdFromDate()}-${Math.ceil(Math.random() * 9988)}`,
      };
      if (typeof each === "string" && each !== "...") newValue.active = true;
      if (typeof each === "number") newValue.active = false;
      return [...acc, newValue];
    }, []);
    setNoOfPages(result);
  }, [currentPage, totalPages]);

  const details = useMemo(() => {
    const from = (currentPage - 1) * perPage + 1;
    const to = currentPage * perPage > totalCandidates ? totalCandidates : currentPage * perPage;
    const disablePrev = currentPage <= 1;
    const disableNext = currentPage >= totalPages;

    return {
      from,
      to,
      disablePrev,
      disableNext,
    };

    //eslint-disable-next-line
  }, [currentPage, totalCandidates, totalPages]);

  const handleClick = ({ isNext = false, isPrev = false, value }) => {
    if (changeHandler && typeof changeHandler === "function") {
      let pageNumber = value;
      if (isNext) {
        pageNumber = value + 1;
      }
      if (isPrev) {
        pageNumber = value - 1;
      }

      if (pageNumber === currentPage || pageNumber <= 0 || pageNumber > totalPages) return;

      changeHandler(pageNumber - 1);
    }
  };

  return (
    <View direction="row" align="center" justify="center">
      {noOfPages && noOfPages.length > 0 && (
        <View
          direction="row"
          align="center"
          justify="center"
          attributes={{ style: { position: "relative" } }}
        >
          {title && typeof title === "string" && (
            <View
              attributes={{
                style: {
                  position: "absolute",
                  left: "calc(var(--spacing-xl)*-1)",
                  transform: "translateX(-100%)",
                  width: "max-content",
                },
              }}
            >
              <Text variant="body-2">
                {details.from}-{details.to} {t("of")} {totalCandidates}{" "}
                {totalCandidates === 1 ? t(title) : t(`${title}s`)}
              </Text>
            </View>
          )}
          <View direction="row" align="center" justify="center" gap={sm}>
            <Button
              color="primary"
              variant="ghost"
              size="small"
              onClick={() => handleClick({ isPrev: true, value: currentPage })}
              disabled={details.disablePrev}
              startIcon={() => (
                <ArrowDown fill={cooperColors["foreground-neutral"]} rotate={"90"} />
              )}
            />
            {noOfPages.map((each) => {
              if (each.value === "...") {
                return (
                  <Text key={each.id} size="small" variant="solid" color={"white"}>
                    {each.value}
                  </Text>
                );
              }
              return (
                <Button
                  key={each.id}
                  size="small"
                  variant="solid"
                  color={each.active ? "primary" : "white"}
                  {...(each.active ? {} : { onClick: () => handleClick({ value: each.value }) })}
                >
                  {each.value}
                </Button>
              );
            })}
            <Button
              color="primary"
              variant="ghost"
              size="small"
              onClick={() => handleClick({ isNext: true, value: currentPage })}
              disabled={details.disableNext}
              startIcon={() => (
                <ArrowDown fill={cooperColors["foreground-neutral"]} rotate={"-90"} />
              )}
            />
          </View>
        </View>
      )}
    </View>
  );
}
