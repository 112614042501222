import { baseColor, cooperColors } from "utils/colors/accents";

export const SpeakerHigh = ({ color = baseColor.white }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3273 4.46561C13.5223 4.27012 13.8389 4.26975 14.0344 4.46478C14.4994 4.92865 14.8682 5.47967 15.1199 6.08629C15.3716 6.69292 15.5012 7.34323 15.5012 8C15.5012 8.65676 15.3716 9.30708 15.1199 9.9137C14.8682 10.5203 14.4994 11.0713 14.0344 11.5352C13.8389 11.7302 13.5223 11.7299 13.3273 11.5344C13.1323 11.3389 13.1326 11.0223 13.3281 10.8273C13.7 10.4563 13.995 10.0156 14.1963 9.53048C14.3975 9.04533 14.5012 8.52525 14.5012 8C14.5012 7.47475 14.3975 6.95466 14.1963 6.46951C13.995 5.98437 13.7 5.54369 13.3281 5.17271C13.1326 4.97768 13.1323 4.66109 13.3273 4.46561Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.71968 1.55084C9.89122 1.63474 10 1.80903 10 2V14C10 14.191 9.89122 14.3653 9.71968 14.4492C9.54813 14.5331 9.34377 14.5119 9.19303 14.3947L4.82845 11H2C1.73478 11 1.48043 10.8946 1.29289 10.7071C1.10536 10.5196 1 10.2652 1 10V6C1 5.73478 1.10536 5.48043 1.29289 5.29289C1.48043 5.10536 1.73478 5 2 5H4.82845L9.19303 1.60532C9.34377 1.48808 9.54813 1.46694 9.71968 1.55084ZM9 3.02232L5.30697 5.89468C5.2192 5.96294 5.11119 6 5 6H2V10H5C5.11119 10 5.2192 10.0371 5.30697 10.1053L9 12.9777V3.02232Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 5C5.27614 5 5.5 5.22386 5.5 5.5V10.5C5.5 10.7761 5.27614 11 5 11C4.72386 11 4.5 10.7761 4.5 10.5V5.5C4.5 5.22386 4.72386 5 5 5Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5582 6.23463C11.7531 6.03899 12.0697 6.03838 12.2653 6.23327C12.4979 6.46496 12.6825 6.74029 12.8084 7.04347C12.9343 7.34665 12.9991 7.67171 12.9991 8C12.9991 8.32829 12.9343 8.65335 12.8084 8.95653C12.6825 9.25971 12.4979 9.53504 12.2653 9.76673C12.0697 9.96162 11.7531 9.96101 11.5582 9.76538C11.3634 9.56974 11.364 9.25316 11.5596 9.05827C11.6989 8.91949 11.8095 8.75456 11.8849 8.57296C11.9603 8.39136 11.9991 8.19665 11.9991 8C11.9991 7.80336 11.9603 7.60865 11.8849 7.42704C11.8095 7.24544 11.6989 7.08052 11.5596 6.94173C11.364 6.74685 11.3634 6.43026 11.5582 6.23463Z"
        fill={color}
      />
    </svg>
  );
};

export const VolumeHigh = ({ color = cooperColors["foreground-primary"] }) => {
  return (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.25 6.75V9.25C1.25 10.5 1.875 11.125 3.125 11.125H4.01875C4.25 11.125 4.48125 11.1937 4.68125 11.3125L6.50625 12.4562C8.08125 13.4437 9.375 12.725 9.375 10.8687V5.13125C9.375 3.26875 8.08125 2.55625 6.50625 3.54375L4.68125 4.6875C4.48125 4.80625 4.25 4.875 4.01875 4.875H3.125C1.875 4.875 1.25 5.5 1.25 6.75Z"
        stroke={color}
      />
      <path
        d="M11.25 5.5C12.3625 6.98125 12.3625 9.01875 11.25 10.5"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.3937 3.9375C14.2 6.34375 14.2 9.65625 12.3937 12.0625"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
