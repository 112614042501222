import { Button, View, Text } from "reshaped";

import { useNavigate } from "react-router-dom";
import useTranslation from "components/hooks/useTranslation";

const NotFound = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleNavigate = () => {
    navigate("/");
  };

  return (
    <View as="section" justify="center" align="center" gap={4} width="100vw" height="100vh">
      <Text variant="title-2">{t("404 Page not found")}</Text>
      <Button color="primary" onClick={handleNavigate}>
        {t("Go to home page")}
      </Button>
    </View>
  );
};

export default NotFound;
