import React, { useContext, useEffect, useMemo } from "react";
import { FormControl, View, Select, Text, Button } from "reshaped";

import { Reload } from "icons";
import { isString } from "utils/miniHelpers";
import useSpacing from "components/hooks/useSpacing";
import { ReshapedLoader } from "components/reusables";
import { fetchMiniJobData } from "redux/job/jobSlice";
import { useDispatch, useSelector } from "react-redux";
import useTranslation from "components/hooks/useTranslation";
import { getLocationFromCityAndCountry } from "utils/getters";
import { getTextWithEclipses } from "utils/getTextWithEclipses";
import { ErrorContext } from "components/pages/candidates/AddCandidate";

export const SelectAppliedJob = (props) => {
  const { propTitle, inputValue, setInputValue, isRequired } = props;

  const { sm } = useSpacing();
  const dispatch = useDispatch();
  const { t, locale } = useTranslation();
  const { errors, setErrors } = useContext(ErrorContext);
  const { job, publicState } = useSelector((state) => state);

  const { mini_jobs, loading, mini_error } = job;
  const { countries } = publicState;
  const { data } = countries;

  useEffect(() => {
    if (!mini_jobs) dispatch(fetchMiniJobData());

    //eslint-disable-next-line
  }, []);

  const id = useMemo(
    () => propTitle.toLowerCase(),

    //eslint-disable-next-line
    []
  );

  const sanitizedJobs = useMemo(() => {
    if (!mini_jobs) return [];

    if (!Array.isArray(mini_jobs)) return [];

    const optimizedJobs = mini_jobs.reduce((acc, job) => {
      if (job?.status !== "archived") {
        const location = getLocationFromCityAndCountry({
          city: job.city,
          country: isString(job.country_code) ? job.country_code : job.country,
          noLocationText: "",
          locale,
          countryList: data,
        });
        return [
          ...acc,
          {
            label: `${getTextWithEclipses(job.title, 30, 3)}${
              isString(location) ? ` [${location}]` : ""
            } - (Job id: ${job.id})`,
            value: job.id,
          },
        ];
      }
      return acc;
    }, []);

    return optimizedJobs;

    //eslint-disable-next-line
  }, [mini_jobs, data]);

  const handleChange = ({ value }) => {
    if (isRequired && errors[id]) {
      setErrors((previousError) => {
        return {
          ...previousError,
          [id]: null,
        };
      });
    }
    if (!isRequired && errors[id]) {
      setErrors((previousError) => {
        const prev = { ...previousError };
        delete prev[id];
        return {
          ...prev,
        };
      });
    }
    setInputValue(value);
  };

  const validateHandler = () => {
    if (!isRequired) return;
    props.handleValidation({ id, value: inputValue });
  };

  const handleRefetchJobs = () => {
    if (mini_error) dispatch(fetchMiniJobData());
  };

  return (
    <View.Item columns={12}>
      <FormControl
        required={isRequired}
        hasError={
          (isRequired && errors[id] && typeof errors[id] !== "object" && !mini_error) ||
          (!isRequired && errors[id] && typeof errors[id] !== "object" && !mini_error)
            ? true
            : false
        }
      >
        <FormControl.Label>{t("Applied Job")}</FormControl.Label>
        <View direction="row" align="center" gap={sm}>
          <View.Item grow>
            <Select
              name="dropdown"
              disabled={loading}
              placeholder={t("Select job")}
              defaultValue={inputValue}
              onChange={handleChange}
              inputAttributes={{ onBlur: validateHandler, "data-cooper": "select candidate job" }}
              options={sanitizedJobs}
            />
          </View.Item>
          {loading && (
            <View.Item columns={1}>
              <ReshapedLoader size="small" />
            </View.Item>
          )}
          {!loading && mini_error && (
            <View.Item columns={1}>
              <Button
                startIcon={Reload}
                variant="ghost"
                color="primary"
                onClick={handleRefetchJobs}
                attributes={{
                  title: t("Reload jobs"),
                }}
              />
            </View.Item>
          )}
        </View>
        <FormControl.Error>{errors[id]}</FormControl.Error>
        {mini_error && (
          <Text variant="body-2" color="critical">
            {t(mini_error)}
          </Text>
        )}
      </FormControl>
    </View.Item>
  );
};
