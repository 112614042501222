import { useState, useMemo } from "react";
import ModalContext from "components/context/modal/ModalContext";

const ModalProvider = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const value = useMemo(() => {
    return { isModalOpen, setIsModalOpen };
  }, [isModalOpen]);

  return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>;
};

export default ModalProvider;
