export const Envelope = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.8119 7.04991L20.0994 6.3374L13.4994 12.9374C12.6744 13.7624 11.2869 13.7624 10.4619 12.9374L3.86191 6.3749L3.14941 7.0874L8.06191 11.9999L3.14941 16.9124L3.86191 17.6249L8.77441 12.7124L9.74941 13.6874C10.3494 14.2874 11.1369 14.6249 11.9619 14.6249C12.7869 14.6249 13.5744 14.2874 14.1744 13.6874L15.1494 12.7124L20.0619 17.6249L20.7744 16.9124L15.8619 11.9999L20.8119 7.04991Z"
        fill="#2B4B6D"
      />
      <path
        d="M19.4246 18.8998H4.61211C3.74961 18.8998 3.03711 18.1873 3.03711 17.3248V6.7498C3.03711 5.8873 3.74961 5.1748 4.61211 5.1748H19.4246C20.2871 5.1748 20.9996 5.8873 20.9996 6.7498V17.3248C20.9996 18.1873 20.2871 18.8998 19.4246 18.8998ZM4.57461 6.1873C4.27461 6.1873 4.04961 6.4123 4.04961 6.7123V17.2873C4.04961 17.5873 4.27461 17.8123 4.57461 17.8123H19.3871C19.6871 17.8123 19.9121 17.5873 19.9121 17.2873V6.7123C19.9121 6.4123 19.6871 6.1873 19.3871 6.1873H4.57461Z"
        fill="#2B4B6D"
      />
    </svg>
  );
};
