import moment from "moment";
import { useEffect } from "react";
import { metaSlice } from "redux/meta";
import { useCookies } from "react-cookie";
import { decryptToken } from "utils/encryptions";
import { getUserFromAPI } from "redux/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import useTemplates from "components/hooks/useTemplates";
import { LoadingScreen } from "components/reusables/common";
import useTranslation from "components/hooks/useTranslation";
import { getPlanNameByPlanId, userAuth } from "utils/constants";
import { clearAuthToken, setAuthToken } from "utils/setAuthToken";
import SocketProvider from "components/context/socket/SocketProvider";
import { Capitalize, exceededPlanLimits } from "utils/pages/settings";
import { PrivateRootWrapper } from "components/Private/PrivateRootWrapper";
import { fetchSubscriptionData } from "redux/subscriptions/subscriptionsSlice";
import { Navigate, useLocation, useSearchParams, useNavigate } from "react-router-dom";
import { fetchSubscriptionPrices } from "redux/subscriptionPrices/subscriptionPricesSlice";

const PrivateRoute = (props) => {
  const { children } = props;

  const { getTemplates } = useTemplates();

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  //eslint-disable-next-line
  const [cookies, _, removeCookie] = useCookies([userAuth]);
  const {
    auth,
    templates,
    meta,
    subscriptions,
    subscriptionPrices: SubscriptionPrices,
  } = useSelector((state) => state);

  const { data: templateData } = templates;
  const { isAuthenticated, loading } = auth;
  const { subscription } = subscriptions;
  const { subscriptionPrices } = SubscriptionPrices;
  const { isAlertClosed, showSubscriptionAlert } = meta;
  const { showAlert, setPaymentFailed } = metaSlice.actions;
  const { t } = useTranslation();

  const token = cookies[userAuth];
  const { setLocationHistory } = metaSlice.actions;

  useEffect(() => {
    if (token && !isAuthenticated) {
      // old not encrypted token, saved in cookie of user's browser will be redirected for login
      try {
        const decryptedToken = decryptToken({
          encryptedToken: token,
        });
        setAuthToken(decryptedToken);
        if (!isAuthenticated) {
          dispatch(getUserFromAPI());
        }
      } catch (error) {
        if (cookies[userAuth]) removeCookie(userAuth);
        clearAuthToken();
        navigate("/login");
      }
    }
    // previous private route location of unauthenticated user
    if (!token) {
      let path = location.pathname;
      let searchQueries = "";
      for (const entry of searchParams.entries()) {
        const [param, value] = entry;
        if (searchQueries === "") {
          searchQueries = `${param}=${value}`;
        } else {
          searchQueries = `${searchQueries}&${param}=${value}`;
        }
      }
      if (searchQueries) {
        dispatch(setLocationHistory(`${path}?${searchQueries}`));
      } else {
        dispatch(setLocationHistory(`${path}`));
      }
    }
    // eslint-disable-next-line
  }, [isAuthenticated]);

  useEffect(() => {
    if (token && isAuthenticated && !loading && (!templateData || !Array.isArray(templateData))) {
      getTemplates();
    }

    //eslint-disable-next-line
  }, [isAuthenticated]);

  useEffect(() => {
    if (token && isAuthenticated && !loading) {
      if (!subscriptionPrices.length) {
        dispatch(fetchSubscriptionPrices());
      }
    }
    // eslint-disable-next-line
  }, [isAuthenticated]);

  useEffect(() => {
    if (token && isAuthenticated && !loading) {
      if (!subscription.subscription_type) {
        dispatch(fetchSubscriptionData());
      }
    }
    // eslint-disable-next-line
  }, [isAuthenticated, subscription]);

  useEffect(() => {
    if (token && isAuthenticated && !loading) {
      subscription.payment_status === "failed"
        ? dispatch(setPaymentFailed(true))
        : dispatch(setPaymentFailed(false));
      if (!isAlertClosed && exceededPlanLimits(subscription)) {
        dispatch(
          showAlert(
            t(
              "You have exceeded the limits of your current plan. Please upgrade or limit your usage according to your plan limits."
            )
          )
        );
      }
    }

    if (token && isAuthenticated && !loading && !isAlertClosed) {
      if (
        !!subscription.status &&
        !isAlertClosed &&
        subscriptionPrices.length &&
        moment(subscription.current_period_end_at).diff(moment(), "days") < 7 &&
        subscription.status === "subscribed" &&
        subscription.scheduled_for_downgrade
      ) {
        // Subscription downgrading soon alert
        const downgradeDate = moment(subscription.current_period_end_at).format("MMMM D, YYYY");
        dispatch(
          showAlert(
            `${t(
              "Your subscription is scheduled for a downgrade on {{downgradeDate}}. Please ensure that you manually check and adjust your usage to conform to the new limits of the {{new_plan_name}} plan before this date. The downgrade will not occur if the limits are not adhered to."
            )
              .replace("{{downgradeDate}}", `${downgradeDate}`)
              .replace(
                "{{new_plan_name}}",
                `${Capitalize(getPlanNameByPlanId(subscription.scheduled_plan_id))}`
              )}`
          )
        );
      }
    }
    // eslint-disable-next-line
  }, [isAlertClosed, showSubscriptionAlert]);

  if (!token || (!isAuthenticated && loading === false)) {
    return <Navigate to="/login" />;
  }
  if (loading && !isAuthenticated) {
    return <LoadingScreen />;
  }
  if (token && isAuthenticated && !loading) {
    return (
      <SocketProvider token={token}>
        <PrivateRootWrapper>{children}</PrivateRootWrapper>
      </SocketProvider>
    );
  }
};

export default PrivateRoute;
