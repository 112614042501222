import { Divider, View } from "reshaped";

import useSpacing from "components/hooks/useSpacing";
import { StageTrack, FollowersSection } from "components/pages/candidates";

export const CurrentStage = (props) => {
  const { candidate } = props;
  const { stage = "applied" } = candidate;

  const { xs, sm, lg, xl } = useSpacing();
  return (
    <View padding={[0, xl + sm]}>
      <Divider
        attributes={{
          style: {
            backgroundColor: "var(--rs-color-foreground-disabled)",
          },
        }}
      />
      <View gap={xl + lg} padding={[xl + xs, 0]}>
          <StageTrack stage={stage} />
        {<FollowersSection candidate={candidate} />}
      </View>
    </View>
  );
};
