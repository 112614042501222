import { baseColor, cooperColors } from "utils/colors/accents";

export const Search = ({ strokeColor = baseColor.primary }) => {
  return (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.81167 10.9774C8.36068 10.9774 10.4271 8.80512 10.4271 6.12544C10.4271 3.44575 8.36068 1.27344 5.81167 1.27344C3.26267 1.27344 1.19629 3.44575 1.19629 6.12544C1.19629 8.80512 3.26267 10.9774 5.81167 10.9774Z"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5809 12.191L9.07129 9.55273"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const Search3 = () => {
  return (
    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.875 11.375C9.63642 11.375 11.875 9.13642 11.875 6.375C11.875 3.61358 9.63642 1.375 6.875 1.375C4.11358 1.375 1.875 3.61358 1.875 6.375C1.875 9.13642 4.11358 11.375 6.875 11.375Z"
        stroke="#F55C39"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.125 12.625L10.4062 9.90625"
        stroke="#F55C39"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const NewSearch = () => {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.6338 12.8663L11.4606 9.69313C12.1163 8.705 12.5 7.52188 12.5 6.25C12.5 2.80375 9.69625 0 6.25 0C2.80375 0 0 2.80375 0 6.25C0 9.69625 2.80375 12.5 6.25 12.5C7.52188 12.5 8.705 12.1163 9.69313 11.4606L12.8663 14.6338C13.3538 15.1219 14.1463 15.1219 14.6338 14.6338C15.1219 14.1456 15.1219 13.3544 14.6338 12.8663ZM1.875 6.25C1.875 3.8375 3.8375 1.875 6.25 1.875C8.6625 1.875 10.625 3.8375 10.625 6.25C10.625 8.6625 8.6625 10.625 6.25 10.625C3.8375 10.625 1.875 8.6625 1.875 6.25Z"
        fill="#FBD3D0"
      />
    </svg>
  );
};

export const InputSearchIcon = ({ strokeColor = cooperColors['icon-stroke-dark-neutral-faded'] }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.33333 2.5C4.66396 2.5 2.5 4.66396 2.5 7.33333C2.5 10.0027 4.66396 12.1667 7.33333 12.1667C8.64041 12.1667 9.82631 11.6478 10.6963 10.8049C10.7113 10.7844 10.728 10.7649 10.7464 10.7464C10.7649 10.728 10.7844 10.7113 10.8049 10.6963C11.6478 9.82631 12.1667 8.64041 12.1667 7.33333C12.1667 4.66396 10.0027 2.5 7.33333 2.5ZM11.7966 11.0895C12.6515 10.0747 13.1667 8.76415 13.1667 7.33333C13.1667 4.11167 10.555 1.5 7.33333 1.5C4.11167 1.5 1.5 4.11167 1.5 7.33333C1.5 10.555 4.11167 13.1667 7.33333 13.1667C8.76415 13.1667 10.0747 12.6515 11.0895 11.7966L13.6464 14.3536C13.8417 14.5488 14.1583 14.5488 14.3536 14.3536C14.5488 14.1583 14.5488 13.8417 14.3536 13.6464L11.7966 11.0895Z"
        fill={strokeColor}
      />
    </svg>
  );
};
