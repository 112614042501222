export const ISHTTPURL = /https?:\/\//i;

export const HTMLTAGSLOCATER = /<[^>]+>/g;

export const IMGTAGLOCATER = /<img[^>]+>/g;

export const FILETYPEFROMFILENAME = /(?:\.([^.]+))?$/;

export const IMAGEHTTPSSRCLOCATERREGEX = /^https:\/\//;

export const FILENAMEFROMURL = /[^/]{1,}\.[a-zA-Z]{1,}$/i;

export const NOEMOJISREGEX = /[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu;

export const NOSYMBOLSINEMAIL = /[\s"',:;\\/[\]{}<>|^&%#?!*=]/g;

export const HTMLTAGSLOCATERWITHLINEBREAKS = /(<[^>]+>)|(\\n)/g;

export const IMAGEBASE64SRCLOCATERREGEX = /^data:image\/.+;base64,/;

export const QWERTYSYMBOLSLOCATER = /[!@#$%^&*()_+-={}[\]|\\:;"'<>,.?/`~]/g;

//eslint-disable-next-line
export const CUSTOMURLWITHNOSYMBOLSREGEX = /[`!@$^&+=\[\](){}|\\;:?,\.#_%"></*\s]+/;

//eslint-disable-next-line
export const CUSTOMURLWITHNOMULTIPLESYMBOLSREGEX = /[`!@$^&+=\[\](){}|\\;:?,\.#_%"></*\s]+/g;

export const VALIDURLREGEX =
  //eslint-disable-next-line
  /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;

export const PASSWORDVALIDATIONREGEX =
  // eslint-disable-next-line
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[`~!@#$%^&*+=\[\]()\_{}|\\;:'"<>?/.,-])[A-Za-z\d`~!@#$%^&*+=\[\]()\_{}|\\;:'"<>?/.,-]{7,128}$/;

export const EMAILVALIDATIONREGEX =
  // eslint-disable-next-line
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
