import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { stageOptions } from "utils/constants";
import { Checkbox, Text, View } from "reshaped";
import useSpacing from "components/hooks/useSpacing";
import useTranslation from "components/hooks/useTranslation";

export const FilterAccordionStageOptions = ({ handleUpdate, showOptions, setIsUpdated }) => {
  const [checkedData, setCheckedData] = useState([]);
  const [data] = useState(stageOptions.array);

  const { t } = useTranslation();
  const { lg, sm, xl } = useSpacing();
  const { candidates: candidatesFilterData } = useSelector((state) => state.filters);
  const { stages } = candidatesFilterData;
  const { selected } = stages;

  useEffect(() => {
    if (showOptions) {
      setCheckedData(selected);
    }

    handleUpdate({ updatedData: { selected } });

    //eslint-disable-next-line
  }, [showOptions]);

  const handleChange = ({ value, event }) => {
    setIsUpdated(true);
    if (event.target.checked) {
      setCheckedData((prev) => {
        const newData = [...new Set([...prev, value])];
        handleUpdate({ updatedData: { selected: newData } });
        return newData;
      });
    } else {
      setCheckedData((prev) => {
        const newData = prev.filter((v) => v !== value);
        handleUpdate({ updatedData: { selected: newData } });
        return newData;
      });
    }
  };

  return (
    <View gap={lg} padding={lg} paddingBottom={xl + sm}>
      {data.map(({ title, value }) => {
        return (
          <Checkbox
            key={value}
            checked={Array.isArray(checkedData) && checkedData.includes(value)}
            name={title}
            value={value.toLowerCase()}
            onChange={handleChange}
          >
            <Text variant="body-2">{t(`${title}`)}</Text>
          </Checkbox>
        );
      })}
    </View>
  );
};
