import React from "react";

const PricingTick = () => {
  return (
    <svg
      focusable="false"
      fill="#1a1a1a"
      color="#1a1a1a"
      fill-opacity="0.5"
      height="12px"
      viewBox="0 0 16 16"
      width="12px"
    >
      <path
        d="m8 16c-4.418278 0-8-3.581722-8-8s3.581722-8 8-8 8 3.581722 8 8-3.581722 8-8 8zm3.0832728-11.00479172-4.0832728 4.09057816-1.79289322-1.79289322c-.39052429-.39052429-1.02368927-.39052429-1.41421356 0s-.39052429 1.02368927 0 1.41421356l2.5 2.50000002c.39052429.3905243 1.02368927.3905243 1.41421356 0l4.79037962-4.79768495c.3905243-.3905243.3905243-1.02368927 0-1.41421357-.3905243-.39052429-1.0236893-.39052429-1.4142136 0z"
        fillRule="evenodd"
      ></path>
    </svg>
  );
};

export default PricingTick;
