import { useState } from "react";

export default function useWebkit() {
  const [isWebkit, setIsWebkit] = useState(() => {
    if (!/WebKit/i.test(navigator.userAgent)) {
      return false;
    }
    return true;
  });

  return {
    isWebkit,
    setIsWebkit,
  };
}
