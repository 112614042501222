import { baseColor } from "utils/colors/accents";

export const Setting = ({ color = baseColor.white }) => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.5 12.5C11.8807 12.5 13 11.3807 13 10C13 8.61929 11.8807 7.5 10.5 7.5C9.11929 7.5 8 8.61929 8 10C8 11.3807 9.11929 12.5 10.5 12.5Z"
        stroke={color}
        strokeWidth="1"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.1665 10.7334V9.2667C2.1665 8.40003 2.87484 7.68336 3.74984 7.68336C5.25817 7.68336 5.87484 6.6167 5.1165 5.30836C4.68317 4.55836 4.9415 3.58336 5.69984 3.15003L7.1415 2.32503C7.79984 1.93336 8.64984 2.1667 9.0415 2.82503L9.13317 2.98336C9.88317 4.2917 11.1165 4.2917 11.8748 2.98336L11.9665 2.82503C12.3582 2.1667 13.2082 1.93336 13.8665 2.32503L15.3082 3.15003C16.0665 3.58336 16.3248 4.55836 15.8915 5.30836C15.1332 6.6167 15.7498 7.68336 17.2582 7.68336C18.1248 7.68336 18.8415 8.3917 18.8415 9.2667V10.7334C18.8415 11.6 18.1332 12.3167 17.2582 12.3167C15.7498 12.3167 15.1332 13.3834 15.8915 14.6917C16.3248 15.45 16.0665 16.4167 15.3082 16.85L13.8665 17.675C13.2082 18.0667 12.3582 17.8334 11.9665 17.175L11.8748 17.0167C11.1248 15.7084 9.8915 15.7084 9.13317 17.0167L9.0415 17.175C8.64984 17.8334 7.79984 18.0667 7.1415 17.675L5.69984 16.85C4.9415 16.4167 4.68317 15.4417 5.1165 14.6917C5.87484 13.3834 5.25817 12.3167 3.74984 12.3167C2.87484 12.3167 2.1665 11.6 2.1665 10.7334Z"
        stroke={color}
        strokeWidth="1"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
