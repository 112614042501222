import { useEffect, useMemo } from "react";
import { fetchCart } from "redux/cart/cartSlice";
import { areKeysAvailableIn } from "utils/miniHelpers";
import { useDispatch, useSelector } from "react-redux";

const useCart = () => {
  const dispatch = useDispatch();
  const { slots, total } = useSelector((state) => state.cart);

  useEffect(() => {
    if (!slots) {
      dispatch(fetchCart());
    }

    //eslint-disable-next-line
  }, []);

  return useMemo(() => {
    let priceDetails = {
      originalPrice: 0,
      discount: 0,
      total: 0,
    };

    if (
      Array.isArray(slots) &&
      slots.length > 0 &&
      areKeysAvailableIn({
        object: total,
        keys: ["original_price", "discount", "discounted_price"],
      })
    ) {
      priceDetails.originalPrice = total.original_price;
      priceDetails.discount = total.discount;
      priceDetails.total = total.discounted_price;

      const allDetailedInCartSlots = slots.reduce((acc, curr) => {
        const currentSlot = curr.job_board_slot;
        const currentJobBoard = curr.job_board;

        return [
          ...acc,
          { ...currentSlot, logo: currentJobBoard.logo, job_board_name: currentJobBoard.name },
        ];
      }, []);

      return { ...priceDetails, inCartSlots: allDetailedInCartSlots };
    }

    return { inCartSlots: [], ...priceDetails };

    //eslint-disable-next-line
  }, [slots]);
};

export default useCart;
