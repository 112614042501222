const DASHBOARD = {
  Hi: "Hallo",
  "Welcome to CooperHire, your new Recruiting App":
    "Willkommen bei CooperHire, Ihrer neuen Recruiting App",
  "You received": "Sie haben diese Woche",
  "new applicants this week": "neue Bewerber erhalten",
  "new applicant this week": "neue Bewerber erhalten",
  "More Details": "Mehr Info",
  "Complete your profile": "Profil Vervollständigen",
  "Company Profile": "Firmenprofil",
  "Jobs online": "Jobs Erstellt",
  "Job online": "Jobs Erstellt",
  "New Applicants": "Neue Bewerber",
  "My Candidates": "Alle Kandidaten",
  Name: "Name",
  "Job title": "Position",
  Position: "Stelle",
  Stage: "Bewerbungsprozess",
  Date: "Datum",
  applied: "Beworben",
  interviewing: "Interview",
  Search: "Suche",
  "Add New": "Neu Hinzufügen",
  Dashboard: "Dashboard",
  Jobs: "Stellenangebote",
  Candidate: "Kandidat",
  Candidates: "Bewerbungen",
  Inbox: "Posteingang",
  Promote: "Promoten",
  Settings: "Einstellungen",
  rejected: "Abgelehnt",
  Interviews: "Interviews",
  Job: "Job",
  Super: "Super",
  Good: "Gut",
  Average: "Ok",
  Vacant: "Unbesetzt",
  "No candidates applied yet!": "Es haben sich noch keine Kandidaten beworben!",
  "We would love your feedback!": "Wir würden uns über Ihr Feedback freuen!",
  "Give Feedback": "Feedback geben",
  "Request Feature": "Funktion anfordern",
};

const LOGIN = {
  "Welcome to CooperHire!": "Willkommen bei CooperHire!",
  "Get started to hire candidates quickly with our easy to use platform":
    "Ihre Recruiting-Journey startet jetzt!",
  "Welcome to CooperHire": "Willkommen bei CooperHire",
  "A modern recruiting tool that helps you hire top candidates quickly":
    "Ein modernes Recruiting-Tool für kleine Teams und schnelles Hiring",
  Email: "E-Mail",
  Password: "Passwort",
  "Forgot password? No worries": "Passwort vergessen? Kein Problem",
  "Don't have an account?": "Sie haben noch keinen Account?",
  "Invalid email or password.": "Ungültige E-Mail oder ungültiges Passwort.",
  "No account exists for this email.": "Für diese E-Mail existiert kein Konto.",
  "You must confirm your account before you can proceed.":
    "Sie müssen Ihren Account bestätigen bevor Sie fortfahren können.",
  "Forgot Password?": "Passwort vergessen?",
  "No worries, we will send you the reset instructions":
    "Keine Sorge, wir senden Ihnen einen Passwort-Reset Link.",
  "Send email": "E-Mail senden",
  "Back to log in": "Zurück zum Login",
  "Back to settings": "Zurück zu Einstellungen",
  "Check your email": "Schauen Sie in Ihrem Postfach nach",
  "We sent you a password reset link to":
    "Wir haben Ihnen einen Link zum Zurücksetzen des Passworts geschickt",
  "Didn't receive the email? ": "Sie haben die E-Mail nicht erhalten? ",
  Resend: "Erneut senden",
  "Change password": "Passwort ändern",
  "Your password must be very strong with special characters":
    "Ihr Passwort sollte sehr sicher sein und allen Passwort Anforderungen entsprechen",
  "Set a password": "Passwort festlegen",
  "New password": "Neues Passwort",
  "Confirm password": "Bestätigen Sie das Passwort",
  "New Password is required": "Neues Passwort ist erforderlich",
  "Confirm Password is required": "Passwortbestätigung ist erforderlich",
  "Enter valid New Password": "Gültiges neues Passwort eingeben",
  "Enter a valid new password": "Geben Sie ein gültiges neues Passwort ein",
  "Password does not match": "Passwort stimmt nicht überein",
  "Activate account": "Konto freischalten",
  "Password set": "Passwort festlegen",
  "Your password has been set successfully": "Ihr Passwort wurde erfolgreich festgelegt",
  "Click here to log in": "Klicken Sie hier, um sich anzumelden",
  "You have deleted your account. Thanks!": "Sie haben Ihr Konto gelöscht!",
  "Your account is now deleted": "Ihr Konto ist jetzt",
  "You have to confirm your email address before continuing.":
    "Bevor Sie fortfahren, müssen Sie Ihre E-Mail-Adresse bestätigen.",
  "Your account has been deleted.": "Ihr Konto wurde gelöscht.",
  "Account not found": "Konto nicht gefunden",
  "Your account is currently under review. If you have any questions, you can contact hello@cooperhire.io.":
    "Ihr Konto wird derzeit überprüft. Wenn Sie Fragen haben, können Sie sich an hello@cooperhire.io wenden.",
  "Something went wrong, please delete your browser cache and try again. For further help please reach out to ":
    "Etwas ist schiefgelaufen, bitte löschen Sie Ihren Browser-Cache und versuchen Sie es erneut. Für weitere Unterstützung wenden Sie sich bitte an ",
};

const REGISTER = {
  "Create an account.": "Benutzerkonto erstellen.",
  "Click here": "Hier Klicken",
  "First Name": "Vorname",
  "Last Name": "Nachname",
  "Already have a recruiter account?": "Sie haben bereits ein Benutzerkonto?",
  "Log in": "Login",
  "I agree to the Cooperhire": "Ich stimme den",
  "Terms & Conditions": "Allgemeinen Geschäftsbedingungen",
  "and the": "von CooperHire und den",
  "Data Processing Agreements": "Vereinbarungen zur Datenverarbeitung",
  "and have read the": "zu und habe die",
  "Data Privacy Policy": "Datenschutzrichtlinie gelesen",
  "First Name is required": "Vorname ist erforderlich",
  "Last Name is required": "Nachname ist erforderlich",
  "email is required": "E-Mail ist erforderlich",
  "Email is restricted": "E-Mail ist eingeschränkt",
  "password is required": "Kennwort ist erforderlich",
  "Email is required": "E-Mail ist erforderlich",
  "Password is required": "Kennwort ist erforderlich",
  "Enter valid Password": "Gültiges Passwort eingeben",
  "Enter a valid password": "Geben Sie ein gültiges Passwort ein",
  "Enter valid Email": "Gültige Email eingeben",
  "Enter a valid E-mail address": "Geben Sie eine gültige E-Mail Adresse ein",
  "Please agree terms and conditions": "Bitte stimmen Sie den Bedingungen und Konditionen zu",
  Requirements: "Anforderungen",
  characters: "Zeichen",
  "lowercase character": "Kleinbuchstabe",
  "uppercase character": "Großbuchstabe",
  "special character": "Sonderzeichen",
  number: "Nummer",
  "Set up your company profile": "Richten Sie Ihr Unternehmensprofil ein",
  "Set up your company profile in just a few easy steps!":
    "Richten Sie Ihr Firmenprofil in wenigen einfachen Schritten ein!",
  "Give candidates a clear picture of your business and open roles":
    "Gib Kandidaten ein klares Bild Ihres Unternehmens und offenen Stellen",
  "Let candidates know who you are.": "Lassen Sie Bewerber:innen wissen, wer Sie sind.",
  "Company Name": "Name der Firma",
  "Company website": "Webseite der Firma",
  "Phone number": "Telefonnummer",
  "Enter valid Number": "Gültige Nummer eingeben",
  "Enter valid Country": "Gültiges Land eingeben",
  "Website is required": "Website ist erforderlich",
  "Number is required": "Telefonnummer ist erforderlich",
  "Country is required": "Länderangabe ist erforderlich",
  "Name is required": "Name ist erforderlich",
  "Not a country": "Nicht ein Land",
  "Please choose a country from the list": "Bitte wähle ein Land aus der Liste.",
  "Enter valid Website": "Gültige Webseite eingeben",
  "Create company profile": "Unternehmensprofil erstellen",
  "Which of the following best describes your company":
    "Welcher der folgenden Vorschläge beschreibt Ihr Unternehmen am besten",
  "Which of the following best describes your company size?": "Wie groß ist Ihr Unternehmen?",
  "We always tailor the customer experience - CooperHire is best suited for SMB size companies":
    "CooperHire eignet sich am besten für kleine und mittelständische Unternehmen",
  "Tailor CooperHire to your needs": "Passen Sie CooperHire an Ihre Bedürfnisse an",
  "Micro company": "Mikro-Unternehmen",
  employee: "Mitarbeiter",
  "Small size company": "Kleines Unternehmen",
  "Medium size company": "Mittelgroßes Unternehmen",
  "Large size company": "Großes Unternehmen",
  "Hiring Agency": "Personalvermittlungsagentur",
  "Find suitable candidates for employees": "Geeignete Kandidaten finden",
  "Thank you for joining CooperHire!":
    "Vielen Dank, dass Sie sich bei CooperHire angemeldet haben!",
  "We have sent a confirmation mail with an activation link to your registered email address to get you started.":
    "Wir haben eine Bestätigungsmail mit einem Aktivierungslink an Ihre registrierte E-Mail-Adresse geschickt, damit Sie loslegen können.",
  "Thank You for Signing Up to Cooperhire": "Vielen Dank für Ihre Anmeldung bei Cooperhire",
  "We’ve just sent you an account activation link. Please check your email and your spam folder to get started. Welcome aboard!":
    "Wir haben Ihnen soeben einen Link zur Aktivierung Ihres Kontos gesendet. Bitte überprüfen Sie Ihr E-Mail-Postfach und den Spam-Ordner, um mit der Nutzung zu beginnen. Herzlich willkommen!",
  "Create recruiter account": "Benutzerkonto erstellen",
  "Something went wrong": "Etwas ist schief gelaufen",
  "Something went wrong!": "Etwas ist schief gelaufen!",
  "Number, Symbols and other characters are not allowed in First Name":
    "Zahlen, Symbole und andere Zeichen sind im Vornamen nicht erlaubt.",
  "Number, Symbols and other characters are not allowed in Last Name":
    "Zahlen, Symbole und andere Zeichen sind im Nachnamen nicht erlaubt.",
  "A company account already exists with this email domain. Please reach out to your admin ":
    "Es existiert bereits ein Firmenkonto mit dieser E-Mail-Domäne. Bitte wenden Sie sich an Ihren Administrator ",
  " to invite you.": " um Sie einzuladen.",
  "Email already exists.": "E-Mail existiert bereits.",
  "Something went wrong! Please refresh the page":
    "Etwas ist schief gelaufen! Bitte aktualisieren Sie die Seite",
  "First Name should have maximum 240 characters":
    "Der Vorname sollte maximal 240 Zeichen lang sein.",
  "Last Name should have maximum 240 characters":
    "Der Nachname sollte maximal 240 Zeichen lang sein.",
};

const JOBS = {
  "All Jobs": "Alle Jobs",
  "All Created": "Alle Erstellt",
  "Filtered": "Gefiltert",
  List: "Liste",
  Filters: "Filter",
  "Create job": "Job erstellen",
  "Total Candidates": "Kandidaten",
  "Full Time": "Vollzeit",
  "Part Time": "Teilzeit",
  "Job ID": "Job ID",
  Edit: "Bearbeiten",
  Delete: "Löschen",
  "Copy URL": "URL kopieren",
  Closed: "Geschlossen",
  Published: "Veröffentlicht",
  Internal: "Intern",
  Archived: "Archiviert",
  "Are you sure you want to delete ": "Sind Sie sicher, dass Sie Folgendes löschen möchten ",
  " job": " job",
  "Delete job?": "Job löschen?",
  "Publish Job?": "Job veröffentlichen?",
  Publish: "veröffentlichen.",
  Cancel: "Abbrechen",
  "Publishing a job will display it publicly and on free job boards. Are you sure you want to publish this job?":
    "Wenn Sie eine Stelle veröffentlichen, wird sie öffentlich und in kostenlosen Stellenbörsen angezeigt. Sind Sie sicher, dass Sie diese Stelle veröffentlichen möchten?",
  "Job status changed!": "Jobstatus geändert!",
  "URL copied!": "URL kopiert!",
  "Job deleted!": "Job gelöscht!",
  "No jobs created!": "Noch keine Jobs erstellt!",
  "No job found!": "Kein Job gefunden!",
  "You are not authorized to perform this action.":
    "Sie sind nicht berechtigt, diese Aktion durchzuführen.",
  "Normal user": "Normaler Benutzer",
};

const CREATE_JOB = {
  "Add job details": "Stellenbeschreibung hinzufügen",
  "Job Title": "Stellentitel",
  "Short Company Description": "Kurze Firmenbeschreibung",
  "Job Responsibilities": "Aufgabenbereich",
  Optional: "fakultativ",
  "Job Qualifications": "Qualifikationen",
  "Qualification Requirement": "Qualifikationsanforderungen",
  "Job Experience": "Berufserfahrung",
  "Less than 1 Year": "Weniger als 1 Jahr",
  Years: "Jahre",
  "Employment Type": "Art der Beschäftigung",
  "Full-Time (Permanent)": "Vollzeit (unbefristet)",
  "Full-Time (Temporary)": "Vollzeit (befristet)",
  "Part-Time (Permanent)": "Teilzeit (unbefristet)",
  "Part-Time (Temporary)": "Teilzeit (befristet)",
  State: "Bundesland",
  "Zip Code": "Postleitzahl",
  "Last Words": "Letzte Worte die Sie mit Bewerber:inen teilen möchten?",
  "eg. Happy to receive your application": "z.B.. Ich freue mich auf Ihre Bewerbung",
  "Remote option": "Remote-Option",
  Remote: "Remote",
  "Add Screening Questions": "Screening-Fragen hinzufügen",
  Yes: "Ja",
  No: "Nein",
  "Save as draft": "Als Entwurf speichern",
  Back: "Zurück",
  "Job added successfully!": "Job erfolgreich hinzugefügt!",
  "Please enter required fields!": "Bitte geben Sie die erforderlichen Felder ein!",
  "Please enter valid input!": "Bitte geben Sie eine gültige Eingabe ein!",
  "Short intro": "Kurzes Intro",
  Tasks: "Aufgaben",
  Benefits: "Benefits",
  "Anything else you wish to share with the candidate":
    "Alles andere, was Sie dem Bewerber mitteilen möchten",
  Salary: "Gehalt",
  EUR: "EUR",
  USD: "USD",
  GBP: "GBP",
  to: "zu",
  "Single value": "Einfacher Wert",
  Range: "Spanne",
  Day: "Tag",
  Week: "Woche",
  Month: "Monat",
  Year: "Jahr",
  Education: "Bildung",
  "Level of education": "Niveau der Ausbildung",
  Experience: "Erfahrung",
  "Years of experience": "Jahre der Erfahrung",
  "Employment Contract": "Arbeitsvertrag",
  "Employment contract": "Arbeitsvertrag",
  Department: "Abteilung",
  "Language Application Form": "Bewerbungsformular Sprache",
  "Application form language": "Sprache des Antragsformulars",
  Country: "Land",
  City: "Stadt",
  "ZIP Code": "Postleitzahl",
  "Remote Job": "Fernarbeit",
  "Application Deadline": "Bewerbungsfrist",
  "High school or equivalent required": "High School oder gleichwertiger Abschluss erforderlich",
  "Vocational training required": "Berufliche Ausbildung erforderlich",
  "Undergraduate student": "Studentin im Grundstudium",
  "Bachelor degree required": "Bachelor-Abschluss erforderlich",
  "Graduate student": "Student mit Hochschulabschluss",
  "Master degree required": "Master-Abschluss erforderlich",
  "PhD required": "PhD erforderlich",
  "Less than 1 year": "Weniger als 1 Jahr",
  "1-3 years": "1-3 Jahre",
  "3-5 years": "3-5 Jahre",
  "5-7 years": "5-7 Jahre",
  "+7 years": "+7 Jahre",
  Sales: "Vertrieb",
  "Finance and Operations": "Buchhaltung und Operations",
  Engineering: "Software und Technik",
  "Customer service": "Kundenservice",
  Product: "Produktentwicklung",
  "Human resources": "HR und Recruiting",
  Marketing: "Marketing",
  Other: "Andere",
  English: "Englisch",
  German: "Deutsch",
  "Please select valid Date": "Please select valid Date",
  "Add your job in a few simple steps": "Fügen Sie Ihren Job in wenigen einfachen Schritten hinzu",
  "Job Details": "Job-Details",
  "Title is required": "Job Titel ist erforderlich",
  "Title should have minimum 2 characters": "Der Titel sollte mindestens 2 Zeichen enthalten",
  "Title should have maximum 250 characters": "Der Titel darf maximal 250 Zeichen umfassen",
  "Short Intro should have minimum 2 characters":
    "Kurzes Intro sollte mindestens 2 Zeichen umfassen",
  "Short Intro should have maximum 8000 characters":
    "Die Kurzvorstellung sollte maximal 8000 Zeichen umfassen.",
  "Tasks is required": "Aufgaben ist erforderlich",
  "Tasks should have minimum 2 characters": "Aufgaben sollten mindestens 2 Zeichen haben",
  "Tasks should have maximum 10000 characters":
    "Die Aufgaben sollten maximal 10000 Zeichen umfassen.",
  "Requirements is required": "Anforderungen sind erforderlich",
  "Requirement should have minimum 2 characters":
    "Die Anforderung sollte mindestens 2 Zeichen umfassen",
  "Requirement should have maximum 10000 characters":
    "Die Anforderung sollte maximal 10000 Zeichen umfassen.",
  "Benefits should have minimum 2 characters": "Benefits sollten mindestens 2 Zeichen haben",
  "Benefits should have maximum 10000 characters":
    "Benefits sollten maximal 10000 Zeichen umfassen.",
  "Text field should have minimum 2 characters": "Textfeld sollte mindestens 2 Zeichen haben",
  "Text field should have minimum 1 character": "Textfeld muss mindestens 1 Zeichen enthalten",
  "Text field should have maximum 600 characters":
    "Das Textfeld sollte maximal 600 Zeichen enthalten.",
  "Text field should have maximum 1200 characters":
    "Das Textfeld sollte maximal 1200 Zeichen enthalten.",
  "Text field should have maximum 70 characters":
    "Das Textfeld sollte maximal 70 Zeichen enthalten.",
  "Text field should have maximum 8000 characters":
    "Das Textfeld sollte maximal 8000 Zeichen enthalten.",
  "Category is required": "Kategorie ist erforderlich",
  "Department is required": "Abteilung ist erforderlich",
  "Language Application Form is required": "Bewerbungsformular Sprache ist erforderlich",
  "Application form language is required": "Sprache des Antragsformulars ist erforderlich",
  "City is required": "Stadt ist erforderlich",
  "Zip Code is required": "Postleitzahl ist erforderlich",
  "Zip Code should have minimum 4 characters": "Postleitzahl sollte mindestens 4 Zeichen haben",
  "Zip Code should have maximum 12 characters":
    "Die Postleitzahl sollte maximal 12 Zeichen lang sein.",
  "Zip Code should have maximum 20 characters":
    "Die Postleitzahl sollte maximal 20 Zeichen lang sein.",
  "Symbols are not allowed in zip code": "Symbole sind in der Postleitzahl nicht erlaubt",
  "Numbers are not allowed in country": "Nummern sind im Land nicht erlaubt",
  "Please select a valid date": "Bitte wählen Sie ein gültiges Datum",
  "Invalid option": "Ungültige Option",
  "Enter valid option": "Gültige Option eingeben",
  Update: "Update",
  "Job updated successfully!": "Job erfolgreich aktualisiert!",
  "Backend Engineer": "Backend Entwickler",
  "Our mission is to create and sell quality products...":
    "Unser Ziel ist die Herstellung und der Verkauf von Qualitätsprodukten...",
  "Executing full lifecycle software development (SDLC)":
    "Durchführung der Softwareentwicklung über den gesamten Lebenszyklus (SDLC)",
  "Excellent knowledge of relational databases and ORM technologies...":
    "Ausgezeichnete Kenntnisse von relationalen Datenbanken und ORM-Technologien...",
  "Performance Bonus, Paid Sick Leaves etc.":
    "Leistungsprämie, bezahlter Urlaub bei Krankheit usw.",
  "Bonus point if candidate is open to learn new technologies...":
    "Bonuspunkt, wenn der Bewerber offen ist, neue Technologien zu erlernen...",
};

const CANDIDATES = {
  "All Candidates": "Alle Kandidaten",
  "No View Selected": "Keine Ansicht ausgewählt",
  "Add Candidates": "Kandidaten hinzufügen",
  "Search any tags or positions": "Suche nach beliebigen Tags oder Positionen",
  Name: "Name",
  CandanditeJob: "Stelle",
  Stage: "Bewerbungsphase",
  Date: "Datum",
  "Candidate Score": "Bewerberscore",
  "Candidate list": "Bewerber",
  " Lets find the best fit for the role!": " Zeit die besten Kandidaten zu finden!",
  "Job Deleted": "Job gestrichen",
  "There are no candidates, right now!": "Im Moment gibt es keine Kandidaten!",
  "Don't worry we will inform you when someone applies, or you can":
    "Keine Sorge, wir werden Sie informieren, wenn sich jemand bewirbt.",
  hired: "Eingestellt",
  "Candidate not found!": "Kandidat nicht gefunden!",
  "Full Name": "Name",
  "Position applied for": "Beworben für Stelle",
  "Email Address": "E-Mail Adresse",
  "Take an action": "Stufe Bearbeiten",
  Reject: "Ablehnen",
  "Reject candidate?": "Kandidat ablehnen?",
  "Candidate Rejected": "Kandidat abgelehnt",
  "Are you sure you want to reject ": "Sind Sie sicher, dass Sie diese Bewerbung ablehnen möchten?",
  Applied: "Beworben",
  "In Review": "In Prüfung",
  Interviewing: "Interview",
  Evaluation: "Bewertungen",
  Offer: "Angebot",
  Hired: "Eingestellt",
  Rejected: "Abgelehnt",
  Evaluating: "Bewertung",
  "Offer Sent": "Angebot",
  Details: "Eckdaten",
  "Date Applied": "Bewerbungsdatum",
  "Job Location": "Arbeitsstandort",
  LinkedIn: "LinkedIn",
  "Job Owner": "Job Owner",
  Documents: "Dokumente",
  "Document-": "Dokument-",
  Download: "Herunterladen",
  "No documents uploaded": "Keine Dokumente hochgeladen",
  Notes: "Notizen",
  "There is no evalution for this candidate": "Es gibt noch keine Bewertung",
  "Add your evaluation": "Bewertung hinzufügen",
  "Refresh this page": "Diese Seite aktualisieren",
  "Add your Notes": "Notizen hinzufügen",
  "Add Evaluation": "Bewertung hinzufügen",
  "View All": "Alle anzeigen",
  "Strong No Hire": "Sehr Schlecht",
  "No Hire": "Schlecht",
  "Not Sure": "Unsicher",
  Hire: "Positiv",
  "Strong Hire": "Sehr Positiv",
  Overview: "Übersicht",
  "View all Notes": "Alle Notizen anzeigen",
  "Rate candidate": "Kandidat bewerten",
  "Share your evaluation about the candidate": "Teilen Sie Ihre Bewertung",
  "Submit evaluation": "Bewertung erstellen",
  "Evaluation field should have minimum 2 characters":
    "Das Bewertungsfeld sollte mindestens 2 Zeichen enthalten",
  "Evaluation field should have maximum 8000 characters":
    "Das Bewertungsfeld sollte maximal 8000 Zeichen umfassen.",
  "Evaluation added!": "Bewertung hinzugefügt!",
  "You have already evaluated this candidate!": "Sie haben bereits eine Bewertung erstellt!",
  "Delete evaluation?": "Bewertung löschen?",
  "Evaluation updated!": "Bewertung aktualisiert!",
  "Evaluation deleted!": "Bewertung gelöscht!",
  "Are you sure you want to delete evaluation?":
    "Sind Sie sicher, dass Sie die Bewertung löschen möchten?",
  "Write a note about the candidate": "Erstellen Sie eine Notiz",
  "Add note": "Notiz hinzufügen",
  "Note added!": "Notiz hinzugefügt!",
  "Submit note": "Notiz erstellen",
  "Update note": "Notiz aktualisieren",
  "Note updated!": "Notiz aktualisiert!",
  "Delete note?": "Notiz löschen?",
  "Are you sure you want to delete note?": "Sind Sie sicher, dass Sie die Notiz löschen wollen?",
  "Note deleted!": "Notiz gelöscht!",
  Copy: "Kopieren",
  "Candidate source": "Kandidatenquelle",
  Reload: "Neu laden",
  "Refresh candidates": "Kandidaten aktualisieren",
  "Filter by": "Filter Hinzufügen",
  "Candidate stage": "Bewerbungsphase",
  "Job title": "Jobtitel",
  "Jobs fetching failed": "Abruf von Aufträgen fehlgeschlagen",
  "Clear all": "Alle löschen",
  Apply: "Anwenden",
  Disable: "Deaktivieren Sie",
  "Looks like you don't have active jobs!": "Sieht so aus, als hätten Sie keine aktiven Jobs!",
  "No candidates found!": "Keine Kandidaten gefunden!",
  "Please refresh the page or": "Bitte aktualisieren Sie die Seite oder",
  "Send email": "E-Mail senden",
  "Add Note": "Notiz hinzufügen",
  "No messages": "Keine Meldungen",
  "No data": "Keine Daten",
  "No source": "Keine Quelle",
  Messages: "Nachrichten",
  "Connection failed. Please refresh and try again.":
    "Verbindung fehlgeschlagen. Bitte aktualisieren und erneut versuchen.",
  Yesterday: "Gestern",
  "Sending...": "Ich schicke...",
  "New subject": "Neues Thema",
  Retry: "Wiederholung",
  "a week ago": "vor einer Woche",
  "a month ago": "vor einem Monat",
  "a year ago": "vor einem Jahr",
  Today: "Heute",
  "Subject limit is 150 characters": "Betreffzeichenbegrenzung beträgt 150 Zeichen.",
  "Message limit is 8000 characters": "Nachrichtenlimit beträgt 8000 Zeichen.",
  "": "",
  "Refresh page; connection to server not found.":
    "Aktualisieren Sie die Seite; keine Verbindung zum Server gefunden.",
  "Connection issue with the server. Please refresh the page!":
    "Verbindungsproblem mit dem Server. Bitte aktualisieren Sie die Seite!",
  "Sorry we encountered a problem. Please refresh the page":
    "Leider ist ein Fehler aufgetreten. Bitte aktualisieren Sie die Seite.",
  "Oops, can't delete at the moment!": "Hoppla, das Löschen ist derzeit nicht möglich!",
  "Deleted successfully!": "Erfolgreich gelöscht!",
  "Failed to get message template.": "Nachrichtenvorlage konnte nicht abgerufen werden.",
  "Default Template": "Standardvorlage",
  Phone: "Telefon",
  "No location": "Kein Standort",
  Deleted: "gelöscht",
  "Candidate rejection unsuccessful. Please try again.":
    "Die Ablehnung des Bewerbers war nicht erfolgreich. Bitte versuchen Sie es erneut.",
  "Would you like to send a rejection message?": "Möchten Sie eine Ablehnungsnachricht senden?",
  "Reject Candidate": "Kandidaten ablehnen",
  "Customise Message": "Nachricht anpassen.",
  "Personalise Your Message": "Personalisieren Sie Ihre Nachricht.",
  "Reject with Email": "Mit E-Mail ablehnen",
  "Reject without Email": "Ohne E-Mail ablehnen",
  "Last Name not found": "Nachname nicht gefunden.",
  "Operation unsuccessful!": "Die Operation war nicht erfolgreich!",
  "Candidate rejection successful.": "Bewerber erfolgreich abgelehnt.",
  "Stage successfully changed!": "Stufe erfolgreich geändert!",
  "Message Templates": "Nachrichtenvorlagen.",
  "1 day ago": "Vor 1 Tag",
  "Note is required": "Notiz ist erforderlich.",
  "Note should have minimum 2 characters": "Ihre Notiz muss mindestens 2 Zeichen haben.",
  "Note should have maximum 8000 characters": "Ihre Notiz darf höchstens 8000 Zeichen haben.",
  "Evaluation should have minimum 2 characters": "Ihre Bewertung muss mindestens 2 Zeichen haben.",
  "Evaluation should have maximum 8000 characters":
    "Ihre Bewertung darf höchstens 8000 Zeichen haben.",
  "Search candidates": "Kandidaten suchen"
};

const CREATE_CANDIDATE = {
  "Add Candidate": "Kandidat hinzufügen",
  "First Name": "Vorname",
  "Last Name": "Nachname",
  "E-mail address": "E-Mail Adresse",
  "Phone Number": "Telefonnummer",
  "LinkedIn URL": "LinkedIn URL",
  "Applied to job": "Beworbener Job",
  "Candidate Notes": "Anmerkungen",
  "Your Documents": "Dokumente",
  Cancel: "Abbrechen",
  Save: "Speichern",
  "Upload New": "Neu hochladen",
  "Applied Job": "Beworbener Job",
  "Select job": "Job auswählen",
  "Jobs fetching failed!": "Abruf von Jobs fehlgeschlagen!",
  "Reload jobs": "Jobs neu laden",
  "Please wait adding candidate": "Bitte warten Sie mit dem Hinzufügen eines Kandidaten",
  "First Name should have minimum alphabet character":
    "Der Vorname sollte mindestens das Buchstabe enthalten",
  "Last Name should have minimum alphabet character":
    "Nachname sollte mindestens ein Buchstabe enthalten",
  "Enter valid LinkedIn URL": "Gültige LinkedIn URL eingeben",
  "Applied Job is required": "Beworbener Job ist erforderlich",
  "Select valid Job": "Gültigen Job auswählen",
  "Uploaded document size should be less than 5MB":
    "Die Größe des hochgeladenen Dokuments sollte weniger als 5 MB betragen.",
  "Documents should not exceed more than 5": "Die Dokumente sollten nicht mehr als 5",
  "Candidate added successfully!": "Kandidat erfolgreich hinzugefügt!",
  "No documents uploaded!": "Keine Dokumente hochgeladen!",
  of: "von",
};

const PROMOTION = {
  "Job Board Promotion": "Job Portal Anzeigen",
  "Search Job Boards": "Job Portal suchen",
  "Job boards": "Jobbörsen",
  "Your cart": "Ihr Warenkorb",
  Jobs: "Jobs",
  Regions: "Regionen",
  "Add product": "Produkt hinzufügen",
  "Original Price": "Original Preis",
  Discount: "Rabatt",
  Total: "Gesamt",
  "More Jobs": "Mehr Jobs",
  "404 Page not found": "404 Seite nicht gefunden",
};

const SETTINGS = {
  "Set up your company profile": "Unternehmensprofil einrichten",
  Settings: "Einstellungen",
  "Company settings": "Unternehmenseinstellungen",
  Cancel: "Abbrechen",
  "Save changes": "Änderungen speichern",
  "Company Name": "Firmenname",
  "Company Website": "Unternehmens Webseite",
  "Phone number": "Telefonnummer",
  "Brief Company Description": "Kurze Firmenbeschreibung",
  "Industry type": "Branche",
  "Company size": "Größe des Unternehmens",
  "Privacy Options": "Datenschutz-Optionen",
  "Custom URL": "Eigene URL",
  "Coming soon": "Demnächst verfügbar",
  "Team Settings": "Team Einstellungen",
  "Pending invites": "Ausstehende Einladungen",
  "Send invite": "Einladung senden",
  Role: "Rolle",
  "Email invite": "E-Mail Einladung",
  "Send Invitation": "Einladung senden",
  "User Profile Settings": "Benutzerprofil-Einstellungen",
  "Language Preference": "Bevorzugte Sprache",
  "Language preference of company page": "Spracheinstellung der Unternehmensseite",
  "Privacy Settings": "Datenschutz-Einstellungen",
  "Email ID": "E-Mail-Kennung",
  "Can't remember your password?": "Sie können sich nicht mehr an Ihr Passwort erinnern?",
  "This action will freeze your account and deactivate all your active jobs. Please proceed with caution.":
    "Diese Aktion friert Ihr Konto ein und deaktiviert alle Ihre aktiven Jobs. Bitte gehen Sie mit äußerster Vorsicht vor.",
  "Deactivate Account": "Konto deaktivieren",
  Change: "Ändern",
  "Reset your password": "Ihr Passwort zurücksetzen",
  Company: "Unternehmen",
  Team: "Team",
  Purchases: "Käufe",
  "User Profile": "Benutzerprofil",
  "Brief company description": "Kurze Beschreibung des Unternehmens",
  "Company name": "Name des Unternehmens",
  "ProTip!": "Top Tipp!",
  " Use hyphen (-) instead of space in Custom URL":
    " Verwenden Sie einen Bindestrich (-) anstelle eines Leerzeichens in der benutzerdefinierten URL",
  "Company name is required": "Firmenname ist erforderlich",
  "Enter valid Company name": "Gültigen Firmennamen eingeben",
  "Phone number is required": "Telefonnummer ist erforderlich",
  "Enter valid Phone number": "Gültige Rufnummer eingeben",
  "Company website is required": "Website des Unternehmens ist erforderlich",
  "Enter valid Company website": "Gültige Website des Unternehmens eingeben",
  Micro: "Mikro",
  "Hiring Agency": "Einstellungsagentur",
  Small: "Klein",
  Medium: "Mittel",
  Large: "Groß",
  "Spaces and symbols except hyphen are not allowed in Custom URL":
    "Leerzeichen und Symbole außer dem Bindestrich sind in der benutzerdefinierten URL nicht erlaubt.",
  "Accepted Invites": "Angenommene Einladungen",
  "Your team": "Mein Team",
  "Send Invite": "Einladung senden",
  "No accepted invites": "Keine angenommenen Einladungen",
  "No pending invites": "Keine ausstehenden Einladungen",
  Member: "Mitglied",
  Admin: "Verwaltung",
  "Assign as admin": "Als Administrator zuweisen",
  "Assign as member": "Als Mitglied zuweisen",
  Remove: "entfernen",
  "Are you sure you want to remove": "Sind Sie sicher, dass Sie Folgendes entfernen möchten",
  "Remove user?": "Benutzer entfernen?",
  "Member deleted!": "Teammitglied Gelöscht!",
  "on the way": "Unterwegs",
  "Coming soon...": "Demnächst...",
  "First name is required": "Vorname ist erforderlich",
  "Enter valid First name": "Gültigen Vornamen eingeben",
  "Last name is required": "Nachname ist erforderlich",
  "Enter valid Last name": "Gültigen Nachnamen eingeben",
  Logout: "Abmeldung",
  Success: "Erfolg",
  "Invitation was sent to ": "Die Einladung wurde verschickt an ",
  "Please update input fields with valid or new values!":
    "Bitte aktualisieren Sie die Eingabefelder mit gültigen oder neuen Werten!",
  "Only admins can send email invites": "Nur Administratoren können E-Mail-Einladungen versenden",
  "Email address is already taken": "E-Mail-Adresse ist bereits vergeben",
  "Save & Submit": "Speichern & Einreichen",
  "Add team member": "Teammitglied hinzufügen",
  "Invite your team to collaborate": "Laden Sie Ihr Team zur Zusammenarbeit ein",
  Add: "hinzufügen",
  "Add company logo": "Firmenlogo hinzufügen",
  "Company updated!": "Unternehmen aktualisiert!",
  "JPG, JPEG, PNG and GIF are allowed in profile": "JPG, JPEG, PNG und GIF sind im Profil erlaubt",
  "Custom URL contains invalid characters.": "Benutzerdefinierte URL enthält ungültige Zeichen.",
  "Custom URL already taken.": "Diese benutzerdefinierte URL ist bereits vergeben",
  "User updated!": "Benutzer aktualisiert!",
  "Admin access": "Admin-Zugang",
  "Easily attract talent by setting up your company details and career page.":
    "Gestalten Sie jetzt Ihre Unternehmensseite und Einstellungen.",
  "Add and manage new team members to better collaborate on hiring.":
    "Optimieren Sie Ihre Zusammenarbeit bei der Einstellung, indem Sie Ihr Team effektiv verwalten.",
  "Show your team who you are by customising your profile and settings.":
    "Passen Sie Ihr Profil und  Einstellungen individuell für Ihre Bedürfnisse an.",
  "My team": "Mein Team",
  "Edit your User profile": "Bearbeiten Sie Ihr Benutzerprofil",
  "Change avatar": "Avatar ändern",
  "Please ask your admins to remove you from team!":
    "Bitte bitten Sie Ihre Admins, Sie aus dem Team zu entfernen!",
  "Email already exists": "Diese E-Mail-Adresse existiert bereits",
  "Description should have maximum 240 characters":
    "Die Beschreibung sollte maximal 240 Zeichen umfassen.",
  "Please enter required fields or valid inputs!":
    "Bitte geben Sie Pflichtfelder oder gültige Eingaben ein!",
  "Company name should have maximum 240 characters":
    "Der Firmenname sollte maximal 240 Zeichen lang sein.",
  "Company website should have maximum 240 characters":
    "Die Unternehmenswebsite sollte maximal 240 Zeichen umfassen.",
  "City should have maximum 240 characters": "Die Stadt sollte maximal 240 Zeichen lang sein.",
  "Email Invite": "Teammitglied Einladen",
  "Last name should have maximum 240 characters":
    "Der Nachname sollte maximal 240 Zeichen lang sein.",
  "First name should have maximum 240 characters":
    "Der Vorname sollte maximal 240 Zeichen lang sein.",
  "Position should have maximum 240 characters":
    "Die Position sollte maximal 240 Zeichen umfassen.",
  "Company description in 2500 characters...": "Unternehmensbeschreibung in 2500 Zeichen...",
  "Description should have maximum 2500 characters":
    "Der Firmenname sollte maximal 2500 Zeichen lang sein.",
  "Failed to get message templates.": "Fehler beim Abrufen von Nachrichtenvorlagen.",
  "Create new template": "Neue Vorlage erstellen",
  "Enter the contents of your template message here":
    "Geben Sie den Inhalt Ihrer Vorlagennachricht hier ein.",
  "Message is required": "Nachricht ist erforderlich",
  "Message should have maximum 2000 characters": "Die Nachricht darf maximal 2000 Zeichen haben.",
  "The email signature is automatically added using the details on your profile":
    "Die E-Mail-Signatur wird automatisch mit den Angaben in Ihrem Profil hinzugefügt.",
  "Please update template!": "Bitte aktualisieren Sie die Vorlage!",
  "Message Trigger": "Nachrichten-Auslöser",
  "Template Name": "Vorlagenname",
  Message: "Nachricht",
  "Template Name is required": "Vorlagenname ist erforderlich.",
  "Subject is required": "Betreff ist erforderlich.",
  "Subject should have maximum 240 characters": "Der Betreff darf maximal 240 Zeichen haben.",
  "Template Name should have maximum 240 characters":
    "Der Vorlagenname darf maximal 240 Zeichen haben.",
  "Message should have maximum 8000 characters": "Die Nachricht darf maximal 8000 Zeichen haben.",
  "What do you want to call your new template?": "Wie möchten Sie Ihre neue Vorlage nennen?",
  "What is the subject of this message?": "Was ist der Betreff dieser Nachricht?",
  "Delete template?": "Vorlage löschen?",
  "Template updated.": "Vorlage aktualisiert.",
  "Template created.": "Vorlage erstellt.",
  "Template deleted.": "Vorlage gelöscht.",
  "Operation failed, Try again!": "Operation fehlgeschlagen. Bitte versuchen Sie es erneut!",
  "Unable to fetch the placeholders.": "Es war nicht möglich, die placeholders abzurufen.",
  "Candidate Last Name": "Candidate Nachname",
  "Candidate Full Name": "Candidate Vollständiger Name",
  "Candidate First Name": "Vorname des Kandidaten",
  "Unsupported file format.": "Nicht unterstütztes Dateiformat.",
  "File size exceeds 5MB limit.": "Dateigröße überschreitet das 5-MB-Limit.",

  "Messaging Template Settings": "E-Mail Vorlageneinstellungen",
  "Default Rejection Template": "Standard-Ablehnungsvorlage",
  "This template is selected by default when rejecting a candidate":
    "Diese Vorlage wird standardmäßig verwendet, um Bewerber abzulehnen.",
  "Default Apply Template": "Standard-Bewerbungsvorlage",
  "This template is sent out to any candidate that applies to one of your jobs.":
    "Diese Vorlage wird an jeden Bewerber gesendet, der sich auf eine Ihrer Stellen bewirbt.",
  Subject: "Betreff",
  "Create a new template": "Neue Vorlage erstellen",
  "Unable to retrieve message templates at this time. Please try again later":
    "Es ist momentan nicht möglich, Nachrichtenvorlagen abzurufen.",
  "Please try again.": "Bitte versuchen Sie es erneut.",
  "Would you like to delete this template?": "Möchten Sie diese Vorlage löschen?",
  "Message field is mandatory.": "Das Nachrichtenfeld ist obligatorisch.",
  "Message must not exceed 8000 characters.": "Die Nachricht darf maximal 8000 Zeichen enthalten.",
  "Please update the template.": "Bitte aktualisieren Sie die Vorlage.",
  "Template successfully generated.": "Vorlage erfolgreich generiert.",
  "Template successfully updated.": "Vorlage erfolgreich bearbeitet.",
  "Operation unsuccessful. Please retry.":
    "Die Operation war nicht erfolgreich. Bitte versuchen Sie es erneut.",
  "Unable to retrieve placeholders.": "Die Platzhalter können nicht abgerufen werden.",
  "Template Name is mandatory.": "Der Vorlagenname ist obligatorisch.",
  "Subject field is mandatory.": "Das Betrefffeld ist obligatorisch.",
  "Please specify the name for your new template.":
    "Bitte geben Sie den Namen Ihrer neuen Vorlage an.",
  "Template Name should not exceed 240 characters.":
    "Der Vorlagenname darf maximal 240 Zeichen lang sein.",
  "Please add the subject of this message.": "Bitte geben Sie den Betreff dieser Nachricht an.",
  "Subject should not exceed 240 characters.": "Der Betreff darf maximal 240 Zeichen lang sein.",
  "Template successfully removed.": "Vorlage erfolgreich entfernt.",
  "Please input the content of your template message here.":
    "Bitte geben Sie den Inhalt Ihrer Vorlagen-Nachricht hier ein.",
  "File size exceeds the 5MB limit.": "Die Dateigröße überschreitet das 5-MB-Limit.",
  "File format is not supported.": "Das Dateiformat wird nicht unterstützt.",
  Templates: "Nachrichtenvorlagen",
  templates: "Nachrichtenvorlagen",
  Placeholders: "Platzhalter",
  "Message size must not exceed 20MB limit": "Die Nachricht darf 20 MB nicht überschreiten.",
  Trigger: "Auslöser",
  "Reject duplicate applications from the same candidate for the same job":
    "Doppelte Bewerbungen desselben Kandidaten für dieselbe Stelle ablehnen",
  "How long should duplicate applications to the same job be blocked?":
    "Wie lange sollen doppelte Bewerbungen für dieselbe Stelle blockiert werden?",
  "1 day": "1 Tag",
  "1 week": "1 Woche",
  "2 weeks": "2 Wochen",
  "1 month": "1 Monat",
  "2 months": "2 Monate",
  "6 months": "6 Monate",
  "Select an option": "Option auswählen",
  "Search candidates": "Kandidaten suchen",
  "Follower": "Follower",
  "Followers": "Follower",
  "more followers": "mehr Follower", // +2 more followers
  "Unfollowed successfully!": "Erfolgreich entfolgt!",
  "Followed successfully!": "Erfolgreich gefolgt!",
  "0 Following (follow to keep track of this candidate)": "0 Folgen (folgen, um diesen Kandidaten zu verfolgen)",
  "+Assign": "+Zuweisen",
  "Assigned successfully!": "Erfolgreich zugewiesen!",
  "Failed to Assign!": "Zuweisung fehlgeschlagen!",
  "Unassigned successfully!": "Erfolgreich zugewiesen!",
  "Failed to Unassign!": "Zuweisung aufheben fehlgeschlagen!",
  "Assign others to track this candidate.": "Andere zuweisen, um diesen Kandidaten zu verfolgen.",
  "Follow": "Jetzt Folgen",
  "Following": "Am Folgen",
  "Unassign": "Zuweisung aufheben",
  "Assign": "Zuweisen",
  "No Followers": "Keine Follower",
};

const SUBSCRIPTIONS = {
  "My Subscription": "Mein Abonnement",
  "Current Subscription": "Aktuelles Abonnement",
  Subscriptions: "Abonnements",
  Status: "Status",
  Subscribed: "Abonniert",
  Canceled: "Gekündigt",
  "Next Invoice": "Nächste Rechnung",
  Renews: "Erneuert",
  Every: "Jedes",
  Month: "Monat",
  Year: "Jahr",
  month: "monat",
  year: "jahr",
  "Downgrade Status": "Downgrade Status",
  to: "auf",
  on: "am",
  On: "Am",
  "Manage Subscription": "Abonnement verwalten",
  per: "im",
  From: "Von",
  "This includes": "Dies beinhaltet",
  "Change Plan": "Plan ändern",
  Monthly: "Monatlich",
  Yearly: "Jährlich",
  this: "dieses",
  Scheduled: "Geplant",
  "Please review the new plan details and features":
    "Bitte überprüfen Sie die Details und Funktionen des neuen Tarifs",
  "You are currently on the": "Sie sind derzeit auf dem",
  Manage: "Verwalten",
  Confirm: "Bestätigen",
  "including tax": "inkl. MwSt.",
  "plus tax": "zzgl. MwSt.",
  Starting: "Ab dem",
  "you will be charged": "zahlen Sie",
  upgrade: "upgrade",
  downgrade: "downgrade",
  off: "Rabatt",
  "I have reviewed the summary and accept changes":
    "Ich habe die Zusammenfassung geprüft und stimme den Änderungen zu",
  "Manage your subscriptions and billing details":
    "Verwalten Sie Ihre Abonnements und Rechnungsdetails",

  "You are about to change your subscription from {{oldInterval}} {{oldPlan}} to {{newInterval}} {{newPlan}}.":
    "Sie ändern Ihr Abonnement von {{oldInterval}} {{oldPlan}} auf {{newInterval}} {{newPlan}}.",
  "Your new subscription will cost {{newPlanPrice}} (plus tax) per {{newInterval}}.":
    "Ihr neues Abonnement kostet {{newPlanPrice}} (zzgl. MwSt.) im {{newInterval}}",
  "Your {{planLimitsChangeType}} will be scheduled to take effect on {{downgradeDate}}.":
    "Ihr {{planLimitsChangeType}} tritt in kraft, am {{downgradeDate}}.",
  "Please ensure to limit the users and jobs according to the {{newPlanType}} {{newPlan}} plan before due date for the downgrade to take place. If adjustments are not made before the due date, your account will automatically continue on your current plan":
    "Bitte stellen Sie sicher, dass Sie die Anzahl der Nutzer und Jobs gemäß dem {{newPlanType}} {{newPlan}} plan vor dem Fälligkeitsdatum ändern, damit das Downgrade durchgeführt werden kann. Werden die Anpassungen nicht vor dem Fälligkeitsdatum vorgenommen, wird Ihr aktueller Plan beibehalten.",
  "Since you have already paid for your current subscription, a prorated credit of {{proratedCredit}} will be applied towards your new subscription. As a result, this {{oldInterval}} you will only be charged {{newCharge}}.":
    "Da Sie Ihr aktuelles Abonnement bereits bezahlt haben, wird eine anteilige Gutschrift von {{proratedCredit}} auf Ihr neues Abonnement angerechnet. In diesem {{oldInterval}} müssen Sie nur folgenden Betrag zahlen: {{newCharge}}.",
  "Your {{planLimitsChangeType}} will takes place immediately.":
    "Ihr {{planLimitsChangeType}} erfolgt sofort.",
  "Starting {{downgradeDate}} you will be charged {{newPlanPrice}} (plus tax) per {{newInterval}}.":
    "Ab dem {{downgradeDate}} zahlen Sie {{newPlanPrice}} (zzgl. MwSt.) im {{newInterval}}.",

  // notifications
  "Downgrade canceled successfully!": "Downgrade erfolgreich abgebrochen!",
  "Please renew your plan to update the subscription!":
    "Bitte erneuern Sie Ihr Abonnement, um das Abonnement zu aktualisieren!",
  "Downgrade scheduled successfully!": "Herabstufung erfolgreich eingeplant!",
  "Updating...": "Wird aktualisiert...",
  "Your plan has successfully changed!": "Ihr Abonnement wurde erfolgreich geändert!",
  plan: "plan",
  "<b>before due date</b> for the downgrade to take place":
    " <b>vor dem Fälligkeitsdatum</b> für den Downgrade",
  "If adjustments are not made before the due date, your account will automatically continue on your current plan":
    "Sollten Anpassungen nicht vor dem Stichtag vorgenommen werden, wird Ihr Konto automatisch mit Ihrem aktuellen Tarif fortgeführt",
  "Please update your payment plan before adding more users. You cannot invite more than":
    "Bitte aktualisieren Sie Ihren Zahlungsplan, bevor Sie weitere Benutzer hinzufügen. Sie können mit dem",
  "users with the": "Zahlungsplan nicht mehr als",
  "plan user_limit_error": "Benutzer einladen",
  "Please update your payment plan before adding more jobs. You cannot add more than":
    "Bitte aktualisieren Sie Ihren Zahlungsplan, bevor Sie weitere Jobs hinzufügen. Sie können mit dem",
  "jobs with the": "Zahlungsplan nicht mehr als",
  "plan job_limit_error": "Jobs einladen",
  "Please limit the users and jobs before downgrading the plan!":
    "Bitte begrenzen Sie die Anzahl der Benutzer und Jobs, bevor Sie das Abonnement herabstufen!",
  "Are you sure you want to delete this invite?":
    "Sind Sie sicher, dass Sie diese Einladung löschen möchten?",
  "This action will revoke invitation to": "Diese Aktion widerruft die Einladung an",
  "Your plan is scheduled to change on": "Ihr Plan ist geplant, am",
  "Please ensure everything is within the new limits, according to the":
    "zu ändern. Bitte stellen Sie sicher, dass alles den neuen Grenzen entsprechend dem",
  "plan plan_change_toast": "-Plan entspricht",
  "Your subscription is scheduled for downgrade on": "Ihr Abonnement ist für ein Downgrade am",
  "plan by due date": "-Plan entspricht",
  "Your subscription has for downgraded to": "Ihr Abonnement wurde auf den",
  "on due_date_notification": "-Plan am",
  "Your posted jobs are currently inactive/deleted":
    "herabgestuft. Ihre veröffentlichten Jobs sind derzeit inaktiv/gelöscht",
  "You have already scheduled a downgrade for your subscription, please wait for the downgrade to take effect before changing the plan!":
    "Sie haben bereits ein Downgrade Ihres Abonnements geplant. Bitte warten Sie, bis das Downgrade wirksam wird, bevor Sie den Tarif ändern!",
  "Your payment has failed. Please update your payment details in billing portal to avoid service interruption.":
    "Ihre Zahlung ist fehlgeschlagen. Bitte aktualisieren Sie Ihre Zahlungsinformationen im Abrechnungsportal, um eine Unterbrechung des Dienstes zu vermeiden.",
  "Please update your payment plan before adding more message templates. You cannot add more than":
    "Bitte aktualisieren Sie Ihren Zahlungsplan, bevor Sie weitere Nachrichtenvorlagen hinzufügen. Sie können nicht mehr als",
  "message templates with the": "Nachrichtenvorlagen mit dem",
  "plan message_template_limit_error": "-Plan hinzufügen.",
  "You have exceeded the limits of your current plan. Please upgrade or limit your usage according to your plan limits.":
    "Sie haben die Grenzen Ihres aktuellen Tarifs überschritten. Bitte führen Sie ein Upgrade durch oder schränken Sie Ihre Nutzung gemäß den Tarifgrenzen ein.",
  "Please update your payment plan before adding more users. You cannot invite more than {{subscription.max_users}} users with the {{subscription.subscription_type}} plan":
    "Bitte aktualisieren Sie Ihren Zahlungsplan bevor Sie weitere Nutzer hinzufügen. Sie können mit dem {{subscription.subscription_type}} Abonnement nicht mehr als {{subscription.max_users}} Benutzer einladen",
  "For companies starting out with basic recruiting needs.":
    "Für Unternehmen, die mit grundlegenden Rekrutierungsbedürfnissen beginnen.",
  "You are about to change your subscription plan from": "Sie ändern Ihr Abonnement von",
  "Invite deleted successfully!": "Einladung erfolgreich gelöscht!",
  "Cancel Downgrade": "Herabstufung abbrechen",
  "Your subscription is scheduled for a downgrade on {{downgradeDate}}. Please ensure that you manually check and adjust your usage to conform to the new limits of the {{new_plan_name}} plan before this date. The downgrade will not occur if the limits are not adhered to.":
    "Ihr Abonnement wird am {{downgradeDate}} auf einen niedrigeren Tarif umgestellt. Bitte stellen Sie sicher, dass Sie vor diesem Termin manuell Ihre Nutzung an die neuen Beschränkungen des {{new_plan_name}}-Plan anpassen.",
  "Please update your payment plan before adding more templates. You cannot add more than {{subscription.max_message_templates}} templates with the {{subscription.subscription_type}} plan":
    "Bitte aktualisieren Sie Ihren Zahlungsplan, bevor Sie weitere Vorlagen hinzufügen. Sie können nicht mehr als {{subscription.max_message_templates}} Vorlagen mit dem {{subscription.subscription_type}}-Plan hinzufügen.",
  // Pricing cards
  "For companies that are hiring all year round.":
    "Für Unternehmen, die das ganze Jahr über einstellen.",
  "Free Multiposting": "Kostenloses Multiposting",
  "Basic Customer Support": "Basis-Kundensupport",
  "Normal application speed": "Normale Anwendungsgeschwindigkeit",
  "{{jobs_limit}} Total Jobs": "{{jobs_limit}} Stellenanzeigen gesamt",
  "{{users_limit}} User Account": "{{users_limit}} Benutzerkonto",
  "For Companies that are growing their perfect team!":
    "Für Unternehmen, die ihr großartiges Team ausbauen!",
  "Premium Multiposting": "Premium Multiposting",
  "All your jobs on Kleinanzeigen": "Alle Ihre Stellenanzeigen auf Kleinanzeigen",
  "Fast (zappy) Application Speed": "Schnelle (zappy) Bewerbungsgeschwindigkeit",
  "{{users_limit}} User Accounts": "{{users_limit}} Benutzerkonten",
  "For companies that are hiring all yearly round.":
    "Für Unternehmen, die das ganze Jahr über einstellen.",
  "Everything in growth tier": "Alles im Growth-Tarif",
  "{{jobs_limit}} Active Jobs (upgradable)": "{{jobs_limit}} aktive Jobs (erweiterbar)",
  "{{users_limit}} User Accounts (upgradable)": "{{users_limit}} Benutzerkonten (erweiterbar)",
  "Dedicated Account Manager": "Dedizierter Account-Manager",
  "Dedicated Customer Support Manager": "Dedizierter Kundensupport-Manager",
  "Access to Beta Features": "Zugang zu Beta-Funktionen",
  "Migration Support": "Migrationsunterstützung",
};

const PROMOTE = {
  Promotions: "Promoten",
  "Top job boards for you": "Top Job Portale in Deutschland",
  "Tailor made job boards for your hiring needs":
    "buchen Sie maßgeschneiderte Jobangebote von unseren Jobportal-Partnern.",
  RECOMMENDED: "EMPFOHLEN",
  "VALUE PLAN": "Preiswert",
  Before: "Vorher",
  OFF: "RABATT",
  Now: "Jetzt",
  "Starting from": "Verfügbar ab",
  "Stay ahead in hiring the best talent by posting your job on top selected job boards locally and internationally!":
    "Verschaffen Sie sich einen Vorsprung bei der Einstellung der besten Talente, indem Sie Ihre Stelle auf ausgewählten lokalen und internationalen Stellenbörsen veröffentlichen!",
  "No job boards found": "Keine Jobbörsen gefunden",
  "Job boards fetching failed": "Das Abrufen von Stellenbörsen ist fehlgeschlagen.",
};

const SLOT = {
  "Job Board": "Job Board",
  "Monthly Visitors": "Monatliche Besucher",
  Reach: "Reichweite",
  Jobs: "Jobs",
  MRegions: "MRegions",
  Products: "Produkte",
  "Best plans from ": "Die besten Angebote von ",
  day: "Tag",
  days: "Tage",
  Price: "Preis",
  "Go to cart": "Zum Warenkorb",
  "No slots found!": "Keine Premium-Stellenanzeige gefunden",
  "Slot added!": "Premium-Stellenanzeige hinzugefügt!",
  "Slots added!": "Premium-Stellenanzeigen hinzugefügt!",
  "Slot updated!": "Premium-Stellenanzeige aktualisiert!",
  "Slots updated!": "Premium-Stellenanzeigen aktualisiert!",
  "Slot deleted!": "Premium-Stellenanzeige gelöscht!",
  "Slots deleted!": "Premium-Stellenanzeigen gelöscht!",
  "slot already exist!": "Premium-Stellenanzeige existiert bereits!",
  "slots already exist!": "Premium-Stellenanzeigen existieren bereits!",
  "No slots added!": "Keine Premium-Stellenanzeige hinzugefügt!",
  "Your cart is empty!": "Ihr Warenkorb ist leer!",
};

const BOOKINGS = {
  "My Bookings": "Meine Buchungen",
  "You haven’t made any bookings yet.": "Sie haben noch keine Buchungen vorgenommen.",
  "You can visit our Job Board Store by clicking the button below.":
    "Sie können unseren Job Board Store besuchen, indem Sie auf die Schaltfläche unten klicken.",
  "Explore Job Boards": "Jobbörsen erkunden",
  Inventory: "Inventar",
  "Active & Past Bookings": "Aktive und inaktive Buchungen",
  "Easily track all your job board bookings and manage your promoted jobs in one place":
    "Verwalten Sie alle Ihre Buchungen und promoteten Jobs an einem Ort",
  "Job Board": "Job Portal",
  Booking: "Buchung",
  "Purchase Date": "Kaufdatum",
  Duration: "Dauer",
  Action: "Aktion",
  "Activate Now": "Jetzt aktivieren",
  "Job Name": "Job",
  "Activated On": "Aktivierungsdatum",
  "days left": "verbleibende Tage",
  "day left": "Tag übrig",
  Clicks: "Klicks",
  Link: "Link",
  Days: "Tage",
  Expired: "Abgelaufen",
  "days ago": "Tage zuvor",
  "day ago": "Tag zuvor",
  "View link": "Link anzeigen",
  "Select the job you want to promote": "Wählen Sie die Stelle aus, die Sie bewerben möchten",
  "Job ID ": "Job-ID ",
  Location: "Standort",
  Next: "Weiter",
  "Activate your booking": "Aktivieren Sie Ihre Buchung",
  "Confirm Activation": "Bestätigung der Aktivierung",
  Quantity: "Menge",
  Bookings: "Buchungen",
  "Looks like you do not have published jobs!": "Sie haben noch keine veröffentlichten Stellen!",
  "Reload inventory": "Inventar neu laden",
  "Your inventory is empty!": "Ihr Inventar ist leer!",
  "Reload bookings": "Buchungen wieder aufladen",
  "Currently you do not have active and past bookings":
    "Sie haben derzeit keine aktiven und vergangenen Buchungen",
  "Promote job": "Job Promoten",
  "No date": "Kein Datum",
  "Expiring today": "Heute ablaufend",
  "can't promote same job twice before expiry for same job board":
    "Dieselbe Stelle kann nicht zweimal vor Ablauf der Frist für dieselbe Stellenbörse befördert werden",
  "Operation successful!": "Operation erfolgreich!",
  Pending: "Anhängig",
};

const strings = {
  ...DASHBOARD,
  ...LOGIN,
  ...REGISTER,
  ...JOBS,
  ...CREATE_JOB,
  ...CANDIDATES,
  ...CREATE_CANDIDATE,
  ...PROMOTION,
  ...SETTINGS,
  ...SUBSCRIPTIONS,
  ...PROMOTE,
  ...SLOT,
  ...BOOKINGS,
};

export default strings;
