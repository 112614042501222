import React, { useMemo } from "react";
import { View } from "reshaped";

import { useSelector } from "react-redux";
import { validationHelper } from "utils/validationHelper";
import useTranslation from "components/hooks/useTranslation";
import { stepOneProgressHelper } from "utils/jobStepProgress";
import usePublicCountries from "components/hooks/usePublicCountries";
import styles from "components/styles/reshaped/jobs/jobs.module.css";
import { BasicAutoComplete } from "components/reusables/AutoCompletes";
import useUserLanguagePreference from "components/hooks/useUserLanguagePreference";

function AutoCompleteCountryInput(props) {
  const { errors, setErrors, propTitle, setStepOneProgress, country, setCountry } = props;

  const { t } = useTranslation();
  const userLang = useUserLanguagePreference();
  const { getCountriesList } = usePublicCountries();
  const { publicState } = useSelector((state) => state);
  const { countries } = publicState;
  const { data } = countries;

  const id = useMemo(
    () => propTitle.toLowerCase(),

    //eslint-disable-next-line
    []
  );

  const countriesList = useMemo(() => {
    return getCountriesList({ locale: userLang });

    //eslint-disable-next-line
  }, [data]);

  const handleCountry = (value) => {
    setCountry(value);
    if (errors.country) {
      setErrors((previousError) => {
        return {
          ...previousError,
          country: null,
        };
      });
    }
  };

  const handleCountryValidation = () => {
    const errorResult = validationHelper({
      value: country,
      requiredErrorString: "Country is required",
      callback: (value) => {
        if (countriesList.includes(value)) return "";
        return "Please choose a country from the list";
      },
    });

    if (errorResult) {
      setErrors((prev) => {
        return {
          ...prev,
          country: t(errorResult),
        };
      });
      stepOneProgressHelper(propTitle, setStepOneProgress, "remove");
    }

    if (!errorResult) {
      setErrors((prev) => {
        return {
          ...prev,
          country: null,
        };
      });
      stepOneProgressHelper(propTitle, setStepOneProgress, "add");
    }
  };

  return (
    <View.Item grow={true}>
      <BasicAutoComplete
        label={t("Country")}
        required
        id={id}
        state={country}
        options={countriesList}
        handleChange={handleCountry}
        placeholder={t("Country")}
        handleBlur={handleCountryValidation}
        hasError={errors["country"] && typeof errors["country"] !== "object"}
        error={errors["country"]}
        inputAtrributes={{
          type: "text",
          "data-cooper": "job_country",
          autoComplete: "off",
        }}
        customClasses={{
          dropdown: styles.job__country__dropdown,
        }}
      />
    </View.Item>
  );
}

export default React.memo(AutoCompleteCountryInput);
