import { backendAPI } from "utils/axios";
import { reloadOnUnauthorized } from "utils/checkForAuth";
import { ShowToastWithTranslation } from "utils/showToast";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SuccessfulToast } from "components/reusables/tab/SuccessfulToast";

const initialState = {
  company: null,
  error: null,
  loading: false,
};

export const fetchUserCompany = createAsyncThunk(
  "company/company",
  async (_, { rejectWithValue }) => {
    try {
      const response = await backendAPI.get("/companies");
      const { data, status = 401 } = response;
      if (status === 200 && data.company) {
        return {
          company: {
            ...(data?.company || {}),
            custom_url: data?.company?.slug || "",
          },
          status,
        };
      }
      if (status === 401 || data.error) {
        return { error: data?.error, status };
      }
    } catch (error) {
      reloadOnUnauthorized(error);
      rejectWithValue("Something went wrong");
    }
  }
);

export const updateCompanyInAPI = createAsyncThunk(
  "company/update",
  async ({ updatedCompanyData, toast, t, previousSlug, previousCountry }, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      for (let key in updatedCompanyData) {
        formData.append(`company[${key}]`, updatedCompanyData[key]);
      }
      const response = await backendAPI.put("/companies", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      const { data, status = 401 } = response;
      if (status === 200 && data.company && typeof data.company === "object") {
        const { profile_percentage = 75, ...company } = data.company;
        company["custom_url"] = company?.slug || "";
        const updatedSlug = company?.slug;
        const updatedCountry = company?.country;
        const updatedCountryCode = company?.country_code;

        ShowToastWithTranslation({
          toast,
          Children: SuccessfulToast,
          text: t("Company updated!"),
        });
        return {
          company,
          status,
          profile_percentage,
          isCountryUpdated: previousCountry !== updatedCountry,
          isSlugUpdated: previousSlug !== updatedSlug,
          country_code: updatedCountryCode,
        };
      }
      if (status === 401 || data.error) {
        return { error: data.error, status };
      }
    } catch (error) {
      reloadOnUnauthorized(error);
      if (error && error.response) {
        const { status, data } = error.response;
        if (status === 422 && data.errors && typeof data.errors === "object") {
          if (
            "slug" in data.errors &&
            Array.isArray(data.errors.slug) &&
            data.errors.slug.length > 0
          ) {
            const errorMessage = data.errors.slug[0];
            if (errorMessage === "contains invalid characters") {
              return {
                error: t("Custom URL contains invalid characters."),
                status,
              };
            }
            if (errorMessage === "slug is already taken") {
              return {
                error: t("Custom URL already taken."),
                status,
              };
            }
            return {
              error: t("Something went wrong!"),
              status,
            };
          }
          if ("logo" in data.errors && Array.isArray(data.errors.logo)) {
            return {
              error: t("JPG, JPEG, PNG and GIF are allowed in profile"),
              status,
            };
          }
        }
      }
      rejectWithValue(t("Something went wrong!"));
    }
  }
);

export const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    reset: (state) => {
      for (let key in initialState) {
        state[key] = initialState[key];
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserCompany.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchUserCompany.fulfilled, (state, action) => {
      const errorMessage = "Something went wrong";
      state.loading = false;
      if (!action.payload) state.error = errorMessage;
      if (action.payload) {
        const { status, error = errorMessage } = action.payload;
        if (status === 200) {
          state.company = action.payload.company;
          state.error = null;
        }
        if (status === 401 || action.payload.error) state.error = action.payload.error || error;
      }
    });
    builder.addCase(fetchUserCompany.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(updateCompanyInAPI.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateCompanyInAPI.fulfilled, (state, action) => {
      const errorMessage = "Something went wrong!";
      state.loading = false;
      if (!action.payload) state.error = errorMessage;
      if (action.payload) {
        const { status, error = errorMessage } = action.payload;
        if (status === 200) {
          state.company = action.payload.company;
          state.error = null;
        }
        if (status === 401 || action.payload.error) state.error = action.payload.error || error;
      }
    });
    builder.addCase(updateCompanyInAPI.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});
