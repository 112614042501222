import { useMemo } from "react";

import { Arrow } from "icons";
import { Icon, Text, View } from "reshaped";
import { sortingSlice } from "redux/sorting";
import { candidateSlice } from "redux/candidate";
import useSpacing from "components/hooks/useSpacing";
import { useDispatch, useSelector } from "react-redux";
import useTranslation from "components/hooks/useTranslation";
import { fetchFilteredCandidates } from "utils/pages/candidate";
import common from "components/styles/reshaped/reusables/common.module.css";
import { areKeysAvailableIn, areKeysAvailableWithType } from "utils/miniHelpers";
import useUserLanguagePreference from "components/hooks/useUserLanguagePreference";

export const CandidatesTableHeading = (props) => {
  const { noScore = false, noSorting = false, isDashboard = false } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { xs, xl } = useSpacing();
  const state = useSelector((state) => state);
  const userLang = useUserLanguagePreference();

  const { user } = state.auth;
  const { isFiltering, candidates, filteringError } = state.candidate;
  const { candidates: candidatesFilters } = state.filters;
  const { candidates: candidatesSorting } = state.sorting;

  const { jobs, stages, followers, search } = candidatesFilters;
  const { selected: selectedJobs } = jobs;
  const { selected: selectedStages } = stages;
  const { selected: selectedFollowers } = followers;
  const { selected: selectedSearches } = search;

  const headingColumns = useMemo(() => {
    if (noScore) {
      return {
        name: t("Name"),
        job: t("Job title"),
        location: t("Job Location"),
        stage: t("Stage"),
        date: t("Date"),
      };
    }
    return {
      name: t("Name"),
      job: t("Job title"),
      location: t("Job Location"),
      stage: t("Stage"),
      date: t("Date"),
      followers: t("Followers"),
      candidateScore: t("Candidate Score"),
    };

    //eslint-disable-next-line
  }, []);

  const order = useMemo(() => {
    if (noSorting) return {};
    const result = {};
    if (candidatesSorting && Array.isArray(candidatesSorting)) {
      candidatesSorting.forEach((column) => {
        result[column.key] = column.order;
      });
    }
    return result;
  }, [candidatesSorting, noSorting]);

  const handleSorting = async ({ columnName, order }) => {
    if (noSorting) return;
    dispatch(candidateSlice.actions.setFiltering(true));

    const response = await fetchFilteredCandidates({
      company_id: user.company_id,
      selectedJobs,
      selectedStages,
      selectedFollowers,
      sorting: [{ key: columnName, order }],
      current_language: userLang,
      selectedSearches: selectedSearches
    });

    if (areKeysAvailableWithType({ object: response, keys: [{ data: "object" }] })) {
      const { candidates, totalCandidates } = response.data;
      dispatch(
        candidateSlice.actions.set({
          pageCount: 0,
          isFiltering: false,
          candidates,
          totalCandidates,
        })
      );
    }
    if (areKeysAvailableWithType({ object: response, keys: [{ hasError: "boolean" }] })) {
      dispatch(
        candidateSlice.actions.set({
          pageCount: 0,
          isFiltering: false,
          totalCandidates: 0,
          filteringError: "something went wrong!",
        })
      );
    }

    dispatch(sortingSlice.actions.setCandidatesPageSorting({ data: [{ key: columnName, order }] }));
  };

  return (
    <View
      direction="row"
      align="center"
      gap={xl}
      height="48px"
      {...(noSorting
        ? {}
        : {
            className:
              isFiltering || candidates.length <= 0 || filteringError ? common.cover__wrapper : "",
          })}
      attributes={{
        style: {
          paddingInline: "var(--spacing-lg)",
          paddingBlockStart: "calc(var(--spacing-lg) + var(--spacing-xs))",
        },
      }}
    >
      <View.Item>
        <View width="200px">
          <Text variant="body-strong-1" color="neutral">
            {headingColumns.name}
          </Text>
        </View>
      </View.Item>
      <View.Item columns={3}>
        <View direction="row" align="center" gap={xs}>
          <Text variant="body-strong-1" color="neutral">
            {headingColumns.job}
          </Text>
        </View>
      </View.Item>
      <View.Item grow>
        <Text variant="body-strong-1" color="neutral">
          {headingColumns.location}
        </Text>
      </View.Item>
      <View.Item grow>
        <View direction="row" align="center" gap={xs}>
          {!noSorting && Array.isArray(candidates) && candidates.length > 1 ? (
            <SortableColumn
              columnTitle={headingColumns.stage}
              orderColumnName={"stage"}
              order={order}
              handleSorting={handleSorting}
            />
          ) : (
            <Text variant="body-strong-1" color="neutral">
              {headingColumns.stage}
            </Text>
          )}
        </View>
      </View.Item>
      <View.Item grow>
        <View direction="row" align="center" gap={xs}>
          {!noSorting && Array.isArray(candidates) && candidates.length > 1 ? (
            <SortableColumn
              columnTitle={headingColumns.date}
              orderColumnName={"date"}
              order={order}
              handleSorting={handleSorting}
            />
          ) : (
            <Text variant="body-strong-1" color="neutral">
              {headingColumns.date}
            </Text>
          )}
        </View>
      </View.Item>
      {
        !isDashboard ? <View.Item grow>
        <Text variant="body-strong-1" color="neutral">
          {headingColumns.followers}
        </Text>
      </View.Item> : null
      }
      <View.Item grow>
        <Text variant="body-strong-1" color="neutral">
          {headingColumns.candidateScore}
        </Text>
      </View.Item>
    </View>
  );
};

const SortableColumn = ({ columnTitle, orderColumnName, handleSorting, order }) => {
  const { xs } = useSpacing();
  return (
    <>
      {areKeysAvailableIn({ object: order, keys: [orderColumnName] }) ? (
        <View
          direction="row"
          align="center"
          justify="center"
          gap={xs}
          as="button"
          attributes={{
            onClick: () =>
              handleSorting({
                columnName: orderColumnName,
                order: order[orderColumnName] === "asc" ? "desc" : "asc",
              }),
            style: {
              backgroundColor: "transparent",
              border: "none",
              cursor: "pointer",
            },
            "data-cooper": `candidates sort ${orderColumnName.toLowerCase()}`,
          }}
        >
          <Text
            variant="body-strong-1"
            color="neutral"
            attributes={{
              style: {
                fontWeight: "800",
              },
            }}
          >
            {columnTitle}
          </Text>
          <Icon svg={() => <Arrow rotate={order[orderColumnName] === "asc" ? 0 : 180} />} />
        </View>
      ) : (
        <View
          direction="row"
          align="center"
          justify="center"
          attributes={{
            onClick: () =>
              handleSorting({
                columnName: orderColumnName,
                order: "asc",
              }),
            style: {
              backgroundColor: "transparent",
              border: "none",
              cursor: "pointer",
            },
            "data-cooper": `candidates sort ${orderColumnName.toLowerCase()}`,
          }}
        >
          <Text variant="body-strong-1" color="neutral">
            {columnTitle}
          </Text>
        </View>
      )}
    </>
  );
};
