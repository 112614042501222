import { TickCircle2 } from "icons";
import { Dismissible, View, Icon, Text } from "reshaped";
import useTranslation from "components/hooks/useTranslation";
import styles from "components/styles/reshaped/settings.module.css";

export const InvitationSuccess = (props) => {
  const { email, resetInvitation } = props;

  const { t } = useTranslation();

  const handleModalClose = () => {
    resetInvitation({ ...props });
  };

  return (
    <View gap={4} padding={2}>
      <View className={styles.close__icon}>
        <Dismissible onClose={handleModalClose} closeAriaLabel="Close modal" />
      </View>
      <View direction="row" align="center" justify="center">
        <View
          width="40px"
          height="40px"
          borderRadius="medium"
          align="center"
          justify="center"
          direction="row"
          backgroundColor="neutral-faded"
        >
          <Icon svg={TickCircle2} size={5} />
        </View>
      </View>
      <View.Item>
        <Text align="center" variant="title-3">
          {t("Success")}
        </Text>
        <Text align="center" variant="body-1" as="p" color="neutral">
          {t("Invitation was sent to ")}
          {email}
        </Text>
      </View.Item>
    </View>
  );
};
