export const stepOneInitialState = {
  15: [],
  10: [],
  5: [],
  2.5: [],
  others: [],
  total: 0,
};

const stepOneTemplate = {
  15: ["TITLE", "TASKS", "REQUIREMENTS"],
  10: ["BENEFITS", "ANYTHING_ELSE"],
  5: ["SHORT_INTRO", "EDUCATION"],
  2.5: ["MIN_SALARY", "MAX_SALARY"],
  others: [
    "CITY",
    "COUNTRY",
    "ZIP_CODE",
    "CURRENCY",
    "EXPERIENCE",
    "DEPARTMENT",
    "SALARY_TYPE",
    "EMPLOYMENT_CONTRACT",
    "APPLICATION_DEADLINE",
    "LANGUAGE_APPLICATION_FORM",
  ],
};

const stepOneOthersPercentage = 20 / stepOneTemplate.others.length;

export function stepOneProgressHelper(value, setter, action, salary_type) {
  let matchedPercentage;
  for (let key in stepOneTemplate) {
    let found = stepOneTemplate[key].find((property) => property === value);
    if (found) {
      if (key !== "others") {
        matchedPercentage = Number(key);
      } else {
        matchedPercentage = key;
      }
      break;
    }
  }

  if (matchedPercentage) {
    setter((previousState) => {
      let result = { ...previousState };
      const MAX_OR_MIN_SALARY_PERCENTAGE = 2.5;
      if (action === "add") {
        if (matchedPercentage === MAX_OR_MIN_SALARY_PERCENTAGE) {
          result = {
            ...addProperty({
              name: value,
              percentage: matchedPercentage,
              state: previousState,
              salary_type,
            }),
          };
        } else {
          result = {
            ...addProperty({
              name: value,
              percentage: matchedPercentage,
              state: previousState,
            }),
          };
        }
      }
      if (action === "remove") {
        if (matchedPercentage === MAX_OR_MIN_SALARY_PERCENTAGE) {
          result = removeProperty({
            name: value,
            percentage: matchedPercentage,
            state: previousState,
            salary_type,
          });
        } else {
          result = removeProperty({
            name: value,
            percentage: matchedPercentage,
            state: previousState,
          });
        }
      }
      return result;
    });
  }
}

function addProperty({ name, percentage, state, salary_type }) {
  if (!salary_type) {
    state[percentage] = [...new Set([...state[percentage], name])];
  }
  if (salary_type && salary_type === "single") {
    state[percentage] = [...new Set([...state[percentage], name, "MAX_SALARY"])];
  }
  if (salary_type && salary_type === "range") {
    state[percentage] = [...new Set([...state[percentage], name])];
  }
  const newState = {
    ...state,
    total: calculateTotal(state),
  };
  return newState;
}

function removeProperty({ name, percentage, state, salary_type }) {
  if (!salary_type) {
    const sanitizedNoErrorInputs = state[percentage].filter((error) => error !== name);
    state[percentage] = [...new Set(sanitizedNoErrorInputs)];
  }
  if (salary_type && salary_type === "single") {
    let sanitizedNoErrorInputs = state[percentage].filter((error) => error !== name);
    sanitizedNoErrorInputs = sanitizedNoErrorInputs.filter((error) => error !== "MAX_SALARY");
    state[percentage] = [...new Set(sanitizedNoErrorInputs)];
  }
  if (salary_type && salary_type === "range") {
    let sanitizedNoErrorInputs = state[percentage].filter((error) => error !== name);
    state[percentage] = [...new Set(sanitizedNoErrorInputs)];
  }
  const newState = {
    ...state,
    total: calculateTotal(state),
  };
  return newState;
}

function calculateTotal(state) {
  let newState = { ...state };
  let total = 0;
  for (let key in newState) {
    if (key === "others") {
      total += newState["others"].length * Number(stepOneOthersPercentage);
    } else if (key === "total") {
      continue;
    } else {
      total += newState[key].length * Number(key);
    }
  }
  return total;
}
