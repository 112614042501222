import { cooperColors } from "utils/colors/accents";

export const Reload = ({ fill = cooperColors["foreground-primary"] }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.0186 4.09686C21.4328 4.09686 21.7686 4.43265 21.7686 4.84686V9.34686C21.7686 9.76108 21.4328 10.0969 21.0186 10.0969H16.5186C16.1043 10.0969 15.7686 9.76108 15.7686 9.34686C15.7686 8.93265 16.1043 8.59686 16.5186 8.59686H20.2686V4.84686C20.2686 4.43265 20.6043 4.09686 21.0186 4.09686Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0002 4.50088C11.0156 4.50088 10.0406 4.695 9.13113 5.07212C8.22162 5.44924 7.39537 6.00198 6.69966 6.6987C6.40698 6.99181 5.93211 6.99216 5.639 6.69948C5.3459 6.4068 5.34555 5.93192 5.63823 5.63882C6.47326 4.80257 7.46496 4.13915 8.5566 3.68651C9.64825 3.23387 10.8184 3.00089 12.0002 3.00089C13.182 3.00089 14.3521 3.23387 15.4438 3.68651C16.5352 4.13906 17.5267 4.8023 18.3617 5.63831C18.3615 5.63814 18.3618 5.63848 18.3617 5.63831L21.5485 8.81577C21.8418 9.10823 21.8425 9.58311 21.5501 9.87643C21.2576 10.1698 20.7827 10.1705 20.4894 9.87799L17.3007 6.6987C16.605 6.00198 15.7788 5.44924 14.8693 5.07212C13.9597 4.695 12.9848 4.50088 12.0002 4.50088Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.23145 14.6531C2.23145 14.2389 2.56723 13.9031 2.98145 13.9031H7.48145C7.89566 13.9031 8.23145 14.2389 8.23145 14.6531C8.23145 15.0674 7.89566 15.4031 7.48145 15.4031H3.73145V19.1531C3.73145 19.5674 3.39566 19.9031 2.98145 19.9031C2.56723 19.9031 2.23145 19.5674 2.23145 19.1531V14.6531Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.45033 14.1236C2.7428 13.8303 3.21767 13.8296 3.51099 14.122L6.69966 17.3013C7.39537 17.998 8.22162 18.5508 9.13113 18.9279C10.0406 19.305 11.0156 19.4991 12.0002 19.4991C12.9848 19.4991 13.9597 19.305 14.8693 18.9279C15.7788 18.5508 16.605 17.998 17.3007 17.3013C17.5934 17.0082 18.0683 17.0079 18.3614 17.3005C18.6545 17.5932 18.6548 18.0681 18.3622 18.3612C17.5271 19.1975 16.5354 19.8609 15.4438 20.3135C14.3521 20.7662 13.182 20.9991 12.0002 20.9991C10.8184 20.9991 9.64825 20.7662 8.5566 20.3135C7.46523 19.861 6.47376 19.1978 5.63886 18.3618C5.63865 18.3616 5.63844 18.3614 5.63823 18.3612L2.4519 15.1842C2.15857 14.8918 2.15787 14.4169 2.45033 14.1236Z"
        fill={fill}
      />
    </svg>
  );
};
