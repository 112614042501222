import React from "react";
import { View } from "reshaped";
import common from "components/styles/reshaped/reusables/common.module.css";

export const CustomInputBox = React.forwardRef(
  (
    {
      value = "",
      className = "",
      placeholder = "",
      startIcon,
      onBlur = () => {},
      onFocus = () => {},
      onChange = () => {},
      onKeyPress = () => {},
      ...rest
    },
    ref
  ) => {
    return (
      <View
        direction="row"
        align="center"
        className={`${common.custom__input__wrapper} ${
          className && typeof className === "string" ? className : ""
        }`}
        attributes={{
          style: {
            ...rest.style,
          },
        }}
      >
        {startIcon && (
          <View
            attributes={{
              style: { alignItems: "center", display: "flex" },
            }}
          >
            {startIcon}
          </View>
        )}
        <View
          attributes={{
            style: { flex: 1 },
          }}
        >
          <input
            {...(ref ? { ref } : {})}
            value={typeof value === "string" ? value : ""}
            {...(onBlur && typeof onBlur === "function" ? { onBlur } : {})}
            {...(onFocus && typeof onFocus === "function" ? { onFocus } : {})}
            {...(onChange && typeof onChange === "function" ? { onChange } : {})}
            {...(onKeyPress && typeof onKeyPress === "function" ? { onKeyPress } : {})}
            {...(placeholder && typeof placeholder === "string" ? { placeholder } : {})}
            className={`${common.custom__input__box} ${
              className && typeof className === "string" ? className : ""
            }`}
          />
        </View>
      </View>
    );
  }
);
