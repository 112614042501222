import { View } from "reshaped";
import { ChatBox } from "components/chat";

const MessagesCategory = (props) => {
  const { viewingCandidateId } = props;

  return (
    <View backgroundColor="white" borderRadius="large">
      <ChatBox viewingCandidateId={viewingCandidateId} />
    </View>
  );
};

export default MessagesCategory;
