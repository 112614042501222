import React, { useEffect, useRef, useState } from "react";
import { Avatar, Button, Link, Loader, View, Text, useToast } from "reshaped";

import { Camera } from "icons";
import { useCookies } from "react-cookie";
import { userAuth } from "utils/constants";
import useName from "components/hooks/useName";
import { useNavigate } from "react-router-dom";
import { candidateSlice } from "redux/candidate";
import useSlices from "components/hooks/useSlices";
import { clearAuthToken } from "utils/setAuthToken";
import useSpacing from "components/hooks/useSpacing";
import { placeholdersSlice } from "redux/placeholders";
import { updateUserInAPI } from "redux/auth/authSlice";
import useFullName from "components/hooks/useFullName";
import { useDispatch, useSelector } from "react-redux";
import { TextWithEclipses } from "components/reusables";
import { ShowToastWithTranslation } from "utils/showToast";
import { UserProfileForm } from "components/pages/settings";
import useTranslation from "components/hooks/useTranslation";
import { FillRequiredFields } from "pages/recruiter/jobs/New";
import { useDisplayImage } from "components/hooks/useDisplayImage";
import { CooperButton } from "components/reusables/reshapedCustoms";
import styles from "components/styles/reshaped/settings.module.css";
import common from "components/styles/reshaped/reusables/common.module.css";
import { ReshapedErrorToast } from "components/reusables/layouts/ErrorToast";
import { areKeysAvailableIn, isArrayWithElementsOfType } from "utils/miniHelpers";

const languagePreferenceOptions = {
  en: "English",
  de: "German",
  English: "en",
  German: "de",
};

export const UserProfile = React.memo(() => {
  const state = useSelector((state) => state);
  const { isLoading } = state.meta;
  const { user, error } = state.auth;
  const { first_name, last_name } = user;
  const { candidates: candidatesSorting } = state.sorting;

  const [isChanged, setIsChanged] = useState(false);
  const [newUserProfile, setNewUserProfile] = useState(null);
  const [cancelChanges, setCancelChanges] = useState(false);
  const [errors, setErrors] = useState({
    "first name": null,
    "last name": null,
  });

  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { resetAll } = useSlices();
  const changedDataRef = useRef(null);
  const { t, locale } = useTranslation();
  const { sm, md, lg, xl } = useSpacing();
  const { result, uploader } = useDisplayImage();
  const profile = useName({ first_name, last_name, info: user });
  const fullName = useFullName({ first_name, last_name, dependency: [user] });

  //eslint-disable-next-line
  const [cookies, _, removeCookie] = useCookies([userAuth]);

  const changed = { isChanged, setIsChanged };
  const changes = { cancelChanges, setCancelChanges };

  useEffect(() => {
    if (isChanged) {
      if (newUserProfile) setNewUserProfile(null);
      setIsChanged(false);
    }
    //eslint-disable-next-line
  }, [user]);

  const handleUserProfile = (e) => {
    if (!isChanged) setIsChanged(true);

    const target = e.target;
    const file = target.files[0];
    if (file) {
      const supportedFormat = {
        jpg: true,
        jpeg: true,
        png: true,
        gif: true,
      };
      const extension = file.type;

      const type = /(?:\/([^.]+))?$/.exec(extension)[1];
      if (supportedFormat[type]) {
        setNewUserProfile(target.files[0]);
        uploader(e);
      }
    }
  };

  const handleSaveChanges = () => {
    if (!changedDataRef.current) return;
    const changedData = { ...changedDataRef.current };
    changedData.language_preference = languagePreferenceOptions[changedData.language_preference];
    for (let key in user) {
      if (!(changedData[key] === undefined)) {
        if (user[key] === changedData[key]) {
          delete changedData[key];
        }
      }
    }

    let hasError = false;

    for (let field in errors) {
      if (errors[field]) {
        hasError = true;
      }
    }

    if (hasError) {
      ShowToastWithTranslation({
        toast,
        Children: FillRequiredFields,
        text: t("Please enter required fields or valid inputs!"),
      });
      return;
    }

    if (newUserProfile) changedData.logo = newUserProfile;

    if (typeof changedData === "object" && Object.keys(changedData).length === 0) {
      ShowToastWithTranslation({
        toast,
        Children: FillRequiredFields,
        text: t("Please update input fields with valid or new values!"),
      });
      setIsChanged(false);
      return;
    }

    if (areKeysAvailableIn({ object: changedData, keys: ["language_preference"] })) {
      if (
        isArrayWithElementsOfType({
          array: candidatesSorting,
          elementType: "object",
          keys: [{ key: "stage", exact: true }],
        })
      ) {
        dispatch(candidateSlice.actions.set({ shouldCandidatesRefetch: true }));
      }
      dispatch(placeholdersSlice.actions.reset());
    }

    dispatch(updateUserInAPI({ updatedUserData: changedData, toast, t }));
  };

  const handleLogout = () => {
    const locale = localStorage.getItem("locale");
    if (locale) localStorage.removeItem("locale");
    if (cookies[userAuth]) removeCookie(userAuth);
    clearAuthToken();
    resetAll();
  };

  const handleResetPassword = () => {
    navigate("/change_password");
  };

  return (
    <View
      height="100%"
      attributes={{
        style: {
          position: "relative",
        },
      }}
    >
      {!isLoading && (
        <View padding={[xl, 0]} gap={xl + sm}>
          <View className={styles.action__buttons}>
            <View direction="row" gap={sm}>
              <CooperButton color="primary" onClick={handleSaveChanges} disabled={!isChanged}>
                {t("Save changes")}
              </CooperButton>
            </View>
          </View>
          <View.Item grow>
            <View gap={xl + sm} maxWidth="650px">
              <Text variant="title-3">{t("Edit your User profile")}</Text>
              <View gap={!profile.hasURL ? md : 0}>
                {!profile.hasURL && <Text variant="body-strong-1">{t("Change avatar")}</Text>}
                <label htmlFor="user__profile" className={styles.upload__label}>
                  {!newUserProfile && (
                    <>
                      {profile.hasURL && <Avatar src={profile.url} size={18} alt={fullName} />}
                      {!profile.hasURL && (
                        <Avatar initials={profile.letters} size={18} alt={fullName} />
                      )}
                    </>
                  )}
                  {newUserProfile && result && <Avatar src={result} size={18} atl={fullName} />}
                  <input
                    id="user__profile"
                    type="file"
                    accept="image/gif, image/jpeg, image/jpg, image/png"
                    className={styles.upload__input}
                    onChange={handleUserProfile}
                  />
                  <View as="span" className={styles.icon__wrapper}>
                    <Camera />
                  </View>
                </label>
              </View>
              <UserProfileForm
                {...changed}
                {...changes}
                user={user}
                ref={changedDataRef}
                error={errors}
                setError={setErrors}
              />
            </View>
          </View.Item>
          <View gap={xl} maxWidth={locale === "de" ? "500px" : "400px"}>
            <Text variant="title-3">{t("Privacy Settings")}</Text>
            <View gap={lg}>
              <Text variant="body-strong-1">{t("Password")}</Text>
              <View direction="row" align="center">
                <TextWithEclipses
                  variant="caption-1"
                  text={t("Can't remember your password?")}
                  characterLimit={60}
                />
                <View.Item grow>
                  <Text align="end" variant="body-2">
                    <Link onClick={handleResetPassword}>{t("Reset your password")}</Link>
                  </Text>
                </View.Item>
              </View>
              <View>
                <Button onClick={handleLogout} variant="solid" color="neutral">
                  {t("Logout")}
                </Button>
              </View>
            </View>
          </View>
        </View>
      )}
      {isLoading && (
        <View height="100%" align="center" justify="center">
          <Loader size="medium" className={common.loader__big} />
        </View>
      )}
      {!isLoading && error && (
        <ReshapedErrorToast position="absolute" navigateTo="/settings" message={error} />
      )}
    </View>
  );
});
