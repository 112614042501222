import React, { useEffect, useState, useMemo } from "react";
import { Button, DropdownMenu, FormControl, View, Text, TextField } from "reshaped";

import { ArrowDown } from "icons";
import { useSelector } from "react-redux";
import useTranslation from "components/hooks/useTranslation";
import { stepOneProgressHelper } from "utils/jobStepProgress";
import styles from "components/styles/reshaped/jobs/new.module.css";
import { CURRENCY, MAX_SALARY, MIN_SALARY, SALARY_TYPE } from "components/pages/jobs/jobReducer";

const EditSalaryForm = (props) => {
  const {
    errors,
    setErrors,
    setStepOneProgress,
    minSalary,
    setMinSalary,
    maxSalary,
    setMaxSalary,
    currencyType,
    setCurrencyType,
    salaryType,
    setSalaryType,
    oldState,
  } = props;
  const [salaryCategory, setSalaryCategory] = useState("Range");

  const { t } = useTranslation();
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (oldState) {
      if (!oldState.salary_kind) {
        setSalaryCategory("Range");
      }
      if (oldState.salary_kind === "range") {
        setSalaryCategory("Range");
      }
      if (oldState.salary_kind === "single") {
        setSalaryCategory("Single");
      }
    }
  }, [oldState]);

  const currencyOptions = useMemo(() => {
    return [{ value: "USD" }, { value: "EUR" }, { value: "GBP" }];

    //eslint-disable-next-line
  }, []);

  const sanitizedSalaryOptions = {
    year: "Year",
    day: "Day",
    month: "Month",
    week: "Week",
  };

  const salaryTypeOptions = useMemo(() => {
    return [{ value: "day" }, { value: "week" }, { value: "month" }, { value: "year" }];

    //eslint-disable-next-line
  }, []);

  const translations = useMemo(() => {
    return {
      Salary: t("Salary"),
      EUR: t("EUR"),
      USD: t("USD"),
      GBP: t("GBP"),
      to: t("to"),
      "Single value": t("Single value"),
      Range: t("Range"),
      Day: t("Day"),
      Week: t("Week"),
      Month: t("Month"),
      Year: t("Year"),
    };

    //eslint-disable-next-line
  }, [auth && auth?.user ? [auth.user] : []]);

  const handleCurrency = ({ selectedOption }) => {
    const { value } = selectedOption;
    setCurrencyType(value);
    const hasError = props.handleNotRequiredValidation({ id: "currency", isDropdown: true, value });
    if (!hasError) {
      stepOneProgressHelper(CURRENCY, setStepOneProgress, "add");
    }
    if (hasError) {
      //removing error input if it has one
      stepOneProgressHelper(CURRENCY, setStepOneProgress, "remove");
    }
  };

  const handleSalaryType = ({ selectedOption }) => {
    const { value } = selectedOption;
    setSalaryType(value);
    const hasError = props.handleNotRequiredValidation("salary_type", true, value);
    if (!hasError) {
      stepOneProgressHelper(SALARY_TYPE, setStepOneProgress, "add");
    }
    if (hasError) {
      //removing error input if it has one
      stepOneProgressHelper(SALARY_TYPE, setStepOneProgress, "remove");
    }
  };

  const handleRange = (e) => {
    e.preventDefault();
    setSalaryCategory((prev) => {
      if (prev === "Single") return "Range";
      return "Single";
    });
    setMinSalary("");
    setMaxSalary("");
    stepOneProgressHelper(MIN_SALARY, setStepOneProgress, "remove", "single");
    setErrors((previousError) => {
      const prev = { ...previousError };
      delete prev["min_salary"];
      delete prev["max_salary"];
      return {
        ...prev,
      };
    });
  };

  const handleSalary = ({ value, event }) => {
    const { name } = event.target;
    const ID = name.toLowerCase();
    if (/[a-zA-Z]{1,}/.test(value)) return;
    if (errors[ID]) {
      setErrors((previousError) => {
        const prev = { ...previousError };
        delete prev[ID];
        return {
          ...prev,
        };
      });
    }
    if (name === MIN_SALARY) setMinSalary(value);
    if (name === MAX_SALARY) setMaxSalary(value);
    // setter({ type: name, payload: value });
  };

  const validateHandler = (event) => {
    const { name } = event.target;
    const ID = name.toLowerCase();
    let hasError;
    if (name === MIN_SALARY)
      hasError = props.handleNotRequiredValidation({ id: ID, value: minSalary });
    if (name === MAX_SALARY)
      hasError = props.handleNotRequiredValidation({ id: ID, value: maxSalary });
    if (!hasError) {
      if (salaryCategory === "Single") {
        stepOneProgressHelper(name, setStepOneProgress, "add", "single");
        return;
      }
      stepOneProgressHelper(name, setStepOneProgress, "add", "range");
    }
    if (hasError) {
      //removing error input if it has one
      if (salaryCategory === "Single") {
        stepOneProgressHelper(name, setStepOneProgress, "remove", "single");
        return;
      }
      stepOneProgressHelper(name, setStepOneProgress, "remove", "range");
    }
    if (minSalary === "" && maxSalary && salaryCategory === "Range") {
      setErrors((previousError) => {
        return {
          ...previousError,
          min_salary: "Please fill salary",
        };
      });
    }
    if (maxSalary === "" && minSalary && salaryCategory === "Range") {
      setErrors((previousError) => {
        return {
          ...previousError,
          max_salary: "Please fill salary",
        };
      });
    }
    if (hasError && maxSalary === "" && minSalary === "" && salaryCategory === "Range") {
      setErrors((previousError) => {
        const prev = { ...previousError };
        delete prev["min_salary"];
        delete prev["max_salary"];
        return {
          ...prev,
        };
      });
    }
  };

  return (
    <View gap={1}>
      <FormControl>
        <FormControl.Label>{translations.Salary}</FormControl.Label>
        {salaryCategory === "Range" && (
          <View direction="row" align="center" gap={1}>
            <DropdownMenu position="bottom-start">
              <DropdownMenu.Trigger>
                {(attributes) => (
                  <Button
                    color="neutral"
                    className={styles.currency}
                    endIcon={ArrowDown}
                    attributes={attributes}
                  >
                    {currencyType ? translations[currencyType] : translations.EUR}
                  </Button>
                )}
              </DropdownMenu.Trigger>
              <DropdownMenu.Content>
                {currencyOptions.map((currency, index) => {
                  return (
                    <DropdownMenu.Item
                      onClick={() => handleCurrency({ selectedOption: currency })}
                      key={index}
                    >
                      {translations[currency.value]}
                    </DropdownMenu.Item>
                  );
                })}
              </DropdownMenu.Content>
            </DropdownMenu>
            <View.Item grow={true}>
              <View direction="row" align="center" gap={2}>
                <View.Item grow={true}>
                  <TextField
                    value={minSalary}
                    onChange={handleSalary}
                    name={MIN_SALARY}
                    inputAttributes={{ type: "text", onBlur: validateHandler }}
                    hasError={errors["min_salary"] && typeof errors["min_salary"] !== "object"}
                    placeholder="60000"
                  />
                </View.Item>
                <Text>{translations.to}</Text>
                <View.Item grow={true}>
                  <TextField
                    value={maxSalary}
                    onChange={handleSalary}
                    name={MAX_SALARY}
                    inputAttributes={{ type: "text", onBlur: validateHandler }}
                    hasError={errors["max_salary"] && typeof errors["max_salary"] !== "object"}
                    placeholder="80000"
                  />
                </View.Item>
                <DropdownMenu position="bottom-start">
                  <DropdownMenu.Trigger>
                    {(attributes) => (
                      <Button variant="ghost" endIcon={ArrowDown} attributes={attributes}>
                        {salaryType
                          ? translations[sanitizedSalaryOptions[salaryType]]
                          : translations.Year}
                      </Button>
                    )}
                  </DropdownMenu.Trigger>
                  <DropdownMenu.Content>
                    {salaryTypeOptions.map((salaryTypeOption, index) => {
                      return (
                        <DropdownMenu.Item
                          onClick={() => handleSalaryType({ selectedOption: salaryTypeOption })}
                          key={index}
                        >
                          {translations[sanitizedSalaryOptions[salaryTypeOption.value]]}
                        </DropdownMenu.Item>
                      );
                    })}
                  </DropdownMenu.Content>
                </DropdownMenu>
              </View>
            </View.Item>
          </View>
        )}
        {salaryCategory === "Single" && (
          <View direction="row" align="center" gap={2}>
            <View.Item grow={true}>
              <View direction="row" gap={1}>
                <DropdownMenu position="bottom-start">
                  <DropdownMenu.Trigger>
                    {(attributes) => (
                      <Button
                        color="neutral"
                        endIcon={ArrowDown}
                        attributes={attributes}
                        className={styles.currency}
                      >
                        {currencyType ? translations[currencyType] : translations.EUR}
                      </Button>
                    )}
                  </DropdownMenu.Trigger>
                  <DropdownMenu.Content>
                    {currencyOptions.map((currency, index) => {
                      return (
                        <DropdownMenu.Item
                          onClick={() => handleCurrency({ selectedOption: currency })}
                          key={index}
                        >
                          {translations[currency.value]}
                        </DropdownMenu.Item>
                      );
                    })}
                  </DropdownMenu.Content>
                </DropdownMenu>
                <View.Item grow={true}>
                  <TextField
                    value={minSalary}
                    onChange={handleSalary}
                    name={MIN_SALARY}
                    inputAttributes={{ type: "text", onBlur: validateHandler }}
                    hasError={errors["min_salary"] && typeof errors["min_salary"] !== "object"}
                    placeholder="60000"
                  />
                </View.Item>
              </View>
            </View.Item>
            <DropdownMenu position="bottom-start">
              <DropdownMenu.Trigger>
                {(attributes) => (
                  <Button variant="ghost" endIcon={ArrowDown} attributes={attributes}>
                    {salaryType
                      ? translations[sanitizedSalaryOptions[salaryType]]
                      : translations.Year}
                  </Button>
                )}
              </DropdownMenu.Trigger>
              <DropdownMenu.Content>
                {salaryTypeOptions.map((salaryTypeOption, index) => {
                  return (
                    <DropdownMenu.Item
                      onClick={() => handleSalaryType({ selectedOption: salaryTypeOption })}
                      key={index}
                    >
                      {translations[sanitizedSalaryOptions[salaryTypeOption.value]]}
                    </DropdownMenu.Item>
                  );
                })}
              </DropdownMenu.Content>
            </DropdownMenu>
          </View>
        )}
      </FormControl>
      <View.Item>
        <Button size="small" variant="ghost" onClick={handleRange}>
          {salaryCategory === "Single" ? translations.Range : translations["Single value"]}
        </Button>
      </View.Item>
    </View>
  );
};

export default React.memo(EditSalaryForm);
