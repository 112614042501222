import { useEffect } from "react";
import { View } from "reshaped";

import useSpacing from "components/hooks/useSpacing";
import { useDispatch, useSelector } from "react-redux";
import { fetchJobBoards } from "redux/jobBoards/jobBoardsSlice";
import { WrapperWithNav, ReshapedLoader } from "components/reusables";
import { BannerSection, JobBoardSection, ShowJobBoardsError } from "components/pages/promote";

function Promote() {
  const dispatch = useDispatch();
  const { sm, xxl } = useSpacing();
  const { loading, error, allJobBoards } = useSelector((state) => state.jobBoards);

  useEffect(() => {
    if (Array.isArray(allJobBoards) && allJobBoards.length <= 0) dispatch(fetchJobBoards());

    //eslint-disable-next-line
  }, []);

  return (
    <WrapperWithNav wrapperStyles={{ background: "var(--rs-color-white)" }}>
      {!loading && (
        <View gap={xxl + sm}>
          <BannerSection />
          {error ? <ShowJobBoardsError /> : <JobBoardSection />}
        </View>
      )}
      {loading && (
        <View.Item grow={true}>
          <ReshapedLoader />
        </View.Item>
      )}
    </WrapperWithNav>
  );
}

export default Promote;
