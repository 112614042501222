import { cooperColors } from "utils/colors/accents";

export const Arrow = ({ fill = cooperColors["foreground-neutral"], rotate = 0 }) => {
  return (
    <svg
      width="6"
      height="12"
      viewBox="0 0 6 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        transform: `rotate(${rotate}deg)`,
      }}
    >
      <path
        d="M3.3062 1.46314V12H2.40826V1.46314L0.63484 3.156L0 2.55L2.53981 0.12557C2.624 0.0452251 2.73818 9.15527e-05 2.85723 9.15527e-05C2.97628 9.15527e-05 3.09045 0.0452251 3.17465 0.12557L5.71446 2.55L5.07962 3.156L3.3062 1.46314Z"
        fill={fill}
      />
    </svg>
  );
};
