import { backendAPI, ninjaFilterAPI } from "utils/axios";

export function setAuthToken(token) {
  if (token) {
    backendAPI.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    ninjaFilterAPI.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete backendAPI.defaults.headers.common["Authorization"];
    delete ninjaFilterAPI.defaults.headers.common["Authorization"];
  }
}

export function clearAuthToken() {
  delete backendAPI.defaults.headers.common["Authorization"];
  delete ninjaFilterAPI.defaults.headers.common["Authorization"];
}
