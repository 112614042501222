import { CUSTOMURLWITHNOSYMBOLSREGEX, NOEMOJISREGEX } from "utils/RegularExpressions";

export const ALL = "ALL";
export const NAME = "NAME";
export const CITY = "CITY";
export const WEBSITE = "WEBSITE";
export const COUNTRY = "COUNTRY";
export const PHONE_NO = "PHONE_NO";
export const CUSTOM_URL = "CUSTOM_URL";
export const DESCRIPTION = "DESCRIPTION";
export const INDUSTRY_TYPE = "INDUSTRY_TYPE";
export const LANGUAGE_PREFERENCE = "LANGUAGE_PREFERENCE ";
export const DUPLICATE_CANDIDATE_DAYS = "DUPLICATE_CANDIDATE_DAYS";
export const ACCEPT_DUPLICATE_CANDIDATES = "ACCEPT_DUPLICATE_CANDIDATES";

export let initialState = {
  name: "",
  city: "",
  website: "",
  country: "",
  phone_no: "",
  custom_url: "",
  description: "",
  industry_type: "",
  language_preference: "",
  duplicate_candidate_days: 0,
  accept_duplicate_candidates: true,
};

export const reducer = (state, action) => {
  if (action.type === ALL) {
    return {
      ...state,
      ...action.payload,
    };
  }
  if (action.type === NAME) {
    return {
      ...state,
      name: action.payload,
    };
  }
  if (action.type === WEBSITE) {
    return {
      ...state,
      website: action.payload,
    };
  }
  if (action.type === CITY) {
    return {
      ...state,
      city: action.payload,
    };
  }
  if (action.type === COUNTRY) {
    return {
      ...state,
      country: action.payload,
    };
  }
  if (action.type === INDUSTRY_TYPE) {
    return {
      ...state,
      industry_type: action.payload,
    };
  }
  if (action.type === DESCRIPTION) {
    return {
      ...state,
      description: action.payload,
    };
  }
  if (action.type === LANGUAGE_PREFERENCE) {
    return {
      ...state,
      language_preference: action.payload,
    };
  }
  if (action.type === DUPLICATE_CANDIDATE_DAYS) {
    return {
      ...state,
      duplicate_candidate_days: action.payload,
    }
  }
  if (action.type === ACCEPT_DUPLICATE_CANDIDATES) {
    return {
      ...state,
      accept_duplicate_candidates: action.payload,
    }
  }
  if (action.type === CUSTOM_URL) {
    if (CUSTOMURLWITHNOSYMBOLSREGEX.test(action.payload)) return state;

    const moreThan50CharactersNotAllowedRegex = /.{51,}/;
    if (moreThan50CharactersNotAllowedRegex.test(action.payload)) return state;

    let sanitizedInputValue = action.payload;
    sanitizedInputValue = sanitizedInputValue.replace(NOEMOJISREGEX, "");

    return {
      ...state,
      custom_url: sanitizedInputValue,
    };
  }
  if (action.type === PHONE_NO) {
    //eslint-disable-next-line
    const phoneNotAllowedRegex = /[`~!@#$%^&*=\[\]\_{}|\\;:'"<>?/.,a-zA-Z]+/;
    if (phoneNotAllowedRegex.test(action.payload)) return state;
    return {
      ...state,
      phone_no: action.payload,
    };
  }
  return state;
};
