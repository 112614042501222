import { getTextSize } from "utils/getters";
import { MAX_EMAIL_SIZE } from "utils/constants";
import { IMGTAGLOCATER } from "utils/RegularExpressions";
import { removeHtmlTagsWithAttributes } from "utils/regexHelpers";

export function validationHelper({
  value,
  requiredCheck = true,
  requiredErrorString = "Input is required!",
  characterLimitCheck = true,
  characterLimit = 240,
  characterLimitErrorString = "Characters limit exceeded!",
  callback,
}) {
  if (requiredCheck && (!value || typeof value !== "string" || value === "")) {
    return requiredErrorString;
  }
  if (characterLimitCheck && value.length > characterLimit) {
    return characterLimitErrorString;
  }
  if (callback && typeof callback === "function") {
    return callback(value);
  }
  return "";
}

export function HTMLValidationHelper({
  value,
  requiredCheck = true,
  requiredErrorString = "Input is required!",
  minimumLimitCheck = false,
  minimumLimit = 2,
  minimumLimitErrorString = "minimum characters required",
  characterLimitCheck = true,
  characterLimit = 8000,
  characterLimitErrorString = "Characters limit exceeded!",
  sizeLimitCheck = true,
  sizeLimit = MAX_EMAIL_SIZE,
  sizeLimitErrorString = "Input size exceeded!",
}) {
  if (requiredCheck && (!value || typeof value !== "string" || value === "")) {
    return requiredErrorString;
  }

  if (sizeLimitCheck && value.match(IMGTAGLOCATER)) {
    const textContentSize = getTextSize({ text: value });
    if (textContentSize > sizeLimit) {
      return sizeLimitErrorString;
    }
  }

  const textContent = removeHtmlTagsWithAttributes({ text: value, shouldTrim: true });
  if (!value.match(IMGTAGLOCATER) && requiredCheck && (!textContent || textContent === "")) {
    return requiredErrorString;
  }

  if (minimumLimitCheck && textContent.length > 0) {
    if (textContent.length < minimumLimit) {
      return minimumLimitErrorString;
    }
  }

  if (characterLimitCheck) {
    if (textContent.length > characterLimit) {
      return characterLimitErrorString;
    }
  }

  return "";
}
