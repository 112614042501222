import { cooperColors } from "utils/colors/accents";

export const Warning = ({ color = cooperColors["foreground-critical"] }) => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 11C8.7615 11 11 8.7615 11 6C11 3.2385 8.7615 1 6 1C3.2385 1 1 3.2385 1 6C1 8.7615 3.2385 11 6 11ZM5.25 8.4955C5.25 8.062 5.5795 7.75 5.9955 7.75C6.4205 7.75 6.75 8.062 6.75 8.4955C6.75 8.929 6.4205 9.25 5.9955 9.25C5.5795 9.25 5.25 8.929 5.25 8.4955ZM5.586 3C5.55226 3.00003 5.51889 3.0069 5.48787 3.02017C5.45686 3.03345 5.42885 3.05286 5.40554 3.07725C5.38223 3.10163 5.3641 3.13049 5.35223 3.16206C5.34037 3.19364 5.33501 3.2273 5.3365 3.261L5.4895 6.761C5.49233 6.82538 5.51992 6.88618 5.5665 6.93071C5.61308 6.97524 5.67506 7.00006 5.7395 7H6.261C6.32544 7.00006 6.38742 6.97524 6.434 6.93071C6.48058 6.88618 6.50816 6.82538 6.511 6.761L6.6635 3.261C6.66499 3.22726 6.65962 3.19356 6.64772 3.16195C6.63582 3.13033 6.61765 3.10146 6.59428 3.07707C6.57092 3.05268 6.54285 3.03327 6.51178 3.02003C6.48071 3.00678 6.44728 2.99997 6.4135 3H5.586Z"
        fill={color}
      />
    </svg>
  );
};
