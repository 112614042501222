import { Text } from "reshaped";
import { isObject } from "utils/miniHelpers";

export const CooperLabel = ({ text = "Form label", attributes = {} }) => {
  return (
    <Text
      variant="body-strong-2"
      attributes={{
        style: { display: "inline" },
        ...(attributes && isObject(attributes) ? attributes : {}),
      }}
    >
      {text}
    </Text>
  );
};
