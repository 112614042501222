import React, { useRef, useState } from "react";
import {
  Text,
  View,
  Radio,
  Button,
  TextField,
  RadioGroup,
  FormControl,
  ThemeProvider,
} from "reshaped";

import { Pencil, Slash } from "icons";
import { candidateSlice } from "redux/candidate";
import useSpacing from "components/hooks/useSpacing";
import { ReshapedLoader } from "components/reusables";
import { useDispatch, useSelector } from "react-redux";
import "themes/fragments/borderSmallToMedium/theme.css";
import useTemplates from "components/hooks/useTemplates";
import { ErrorSticker } from "components/reusables/common";
import { rejectCandidateAPI } from "utils/pages/candidate";
import useTranslation from "components/hooks/useTranslation";
import { viewingCandidateSlice } from "redux/viewingCandidate";
import { addBlankTargetToAnchorTags } from "utils/EditorHelpers";
import styles from "components/styles/reshaped/candidates.module.css";
import { CustomTextEditor } from "components/reusables/richTextEditors";
import { HTMLValidationHelper, validationHelper } from "utils/validationHelper";
import {
  areKeysAvailableIn,
  areKeysAvailableWithType,
  isArrayWithElementsOfType,
} from "utils/miniHelpers";

export function RejectCandidate({ closeRejectModal, candidateID }) {
  const [subject, setSubject] = useState("");
  const [sendMessage, setSendMessage] = useState("no");
  const [subjectError, setSubjectError] = useState("");
  const [customMessage, setCustomMessage] = useState(false);
  const [rejectionFailed, setRejectedFailed] = useState("");
  const [sendingMessage, setSendingMessage] = useState(false);
  const [preLoadTemplate, setPreLoadTemplate] = useState(false);
  const [customMessageError, setCustomMessageError] = useState("");

  const messageRef = useRef("");

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { xs, sm, md, lg, xl } = useSpacing();
  const state = useSelector((state) => state);
  const { getDefaultTemplate } = useTemplates();

  const { currentCandidate } = state.viewingCandidate;
  const { candidates: candidatesSorting } = state.sorting;
  const { candidates: candidatesFilters } = state.filters;
  const { shouldCandidatesRefetch, candidates, pageCount } = state.candidate;

  const { stages } = candidatesFilters;
  const { selected: selectedStages } = stages;

  const handleSendMessageChange = ({ value }) => {
    setSubject("");
    setSubjectError("");
    setSendMessage(value);
    setCustomMessage(false);
    messageRef.current = "";
    setCustomMessageError("");
  };

  const handleCustomMessage = (value) => {
    if (typeof value !== "boolean") return;
    setCustomMessage(value);
    if (value) {
      const template = getDefaultTemplate({ trigger: "rejected" });

      if (!areKeysAvailableIn({ object: template, keys: ["subject", "message"] })) return;

      const { subject, message } = template;
      messageRef.current = message;
      setSubject(subject);
      setPreLoadTemplate(true);
    } else {
      setSubject("");
      setSubjectError("");
      messageRef.current = "";
      setCustomMessageError("");
    }
  };

  const handleSubjectChange = ({ value }) => {
    setSubject(value);

    const errorResult = validationHelper({
      value: value,
      requiredErrorString: t("Subject field is mandatory."),
      characterLimitErrorString: t("Subject should not exceed 240 characters."),
      characterLimit: 240,
    });

    if (errorResult) {
      setSubjectError(errorResult);
    } else {
      setSubjectError("");
    }
  };

  const handleSubjectBlur = () => {
    const errorResult = validationHelper({
      value: subject,
      requiredErrorString: t("Subject field is mandatory."),
      characterLimitErrorString: t("Subject should not exceed 240 characters."),
      characterLimit: 240,
    });

    if (errorResult) {
      setSubjectError(errorResult);
    } else {
      setSubjectError("");
    }
  };

  const handleCancel = () => {
    if (typeof closeRejectModal === "function") closeRejectModal({ withSuccess: false });
  };

  const handleReject = async () => {
    if (sendingMessage || customMessageError || subjectError) return;
    setRejectedFailed("");

    let hasError = false;
    let data = { send_notification: false };

    if (sendMessage === "yes") {
      data = { send_notification: true };

      if (customMessage) {
        const subjectErrorResult = validationHelper({
          value: subject,
          requiredErrorString: t("Subject field is mandatory."),
          characterLimitErrorString: t("Subject should not exceed 240 characters."),
          characterLimit: 240,
        });

        if (subjectErrorResult) {
          hasError = true;
          setSubjectError(subjectErrorResult);
        } else {
          setSubjectError("");
        }

        const messageErrorResult = HTMLValidationHelper({
          value: messageRef.current,
          requiredErrorString: t("Message field is mandatory."),
          characterLimitErrorString: t("Message must not exceed 8000 characters."),
          sizeLimitErrorString: t("Message size must not exceed 20MB limit"),
        });

        if (messageErrorResult) {
          hasError = true;
          setCustomMessageError(messageErrorResult);
        } else {
          setCustomMessageError("");
        }

        if (hasError) return;

        data = {
          send_notification: true,
          custom_message: {
            subject,
            message: addBlankTargetToAnchorTags({ text: messageRef.current }),
          },
        };
      }
    } else {
      data = { send_notification: false };
    }

    if (hasError) return;

    setSendingMessage(true);

    const result = await rejectCandidateAPI({ data, id: candidateID });

    setSendingMessage(false);

    if (result.error) {
      setRejectedFailed(t("Candidate rejection unsuccessful. Please try again."));
    }

    if (result.data) {
      let previousCandidateStage = areKeysAvailableWithType({
        object: currentCandidate,
        keys: [{ stage: "string" }],
      })
        ? currentCandidate.stage
        : "applied";
      const nextCandidateStage = "rejected";
      let hired = 0;
      if (previousCandidateStage !== "hired" && nextCandidateStage === "hired") hired = 1;
      if (previousCandidateStage === "hired" && nextCandidateStage !== "hired") hired = -1;
      dispatch(
        viewingCandidateSlice.actions.updateStage({
          stage: nextCandidateStage,
          candidate_id: candidateID,
          hired,
        })
      );
      if (
        !shouldCandidatesRefetch &&
        ((Array.isArray(selectedStages) && selectedStages.length > 0) ||
          isArrayWithElementsOfType({
            array: candidatesSorting,
            elementType: "object",
            keys: [{ key: "stage", exact: true }],
          }))
      ) {
        if (
          Array.isArray(candidates) &&
          candidates.length === 1 &&
          typeof pageCount === "number" &&
          pageCount > 0
        ) {
          dispatch(
            candidateSlice.actions.set({
              pageCount: pageCount - 1,
              shouldCandidatesRefetch: true,
            })
          );
        } else {
          dispatch(candidateSlice.actions.set({ shouldCandidatesRefetch: true }));
        }
      }
      closeRejectModal({ withSuccess: true });
    }
  };

  const handleTemplateSelect = (data) => {
    if (!areKeysAvailableIn({ object: data, keys: ["subject", "message"] })) return;
    setSubject(data.subject);
  };

  return (
    <View padding={[12 * xs, 0]}>
      <View gap={2 * lg} height="100%" padding={[0, 12 * xs]}>
        <Text variant="title-2">{t("Reject Candidate")}</Text>
        <View gap={7 * xs}>
          <View gap={xs}>
            <Text variant="body-strong-2">{t("Would you like to send a rejection message?")}</Text>
            <RadioGroup name="send message" onChange={handleSendMessageChange} defaultValue="no">
              <View gap={md} direction="row" align="center">
                <View.Item columns={6}>
                  <View
                    padding={sm}
                    borderRadius="small"
                    attributes={{
                      style: {
                        background: "var(--rs-color-border-neutral-faded)",
                      },
                    }}
                  >
                    <Radio value="yes">{t("Yes")}</Radio>
                  </View>
                </View.Item>
                <View.Item columns={6}>
                  <View
                    padding={sm}
                    borderRadius="small"
                    attributes={{
                      style: {
                        background: "var(--rs-color-border-neutral-faded)",
                      },
                    }}
                  >
                    <Radio value="no">{t("No")}</Radio>
                  </View>
                </View.Item>
              </View>
            </RadioGroup>
          </View>
          {sendMessage === "yes" && (
            <>
              <View direction="row" align="center" justify="end">
                <Button
                  variant="ghost"
                  color="primary"
                  onClick={() => handleCustomMessage(customMessage ? false : true)}
                >
                  <View direction="row" align="center" justify="center" gap={sm}>
                    <Text>{t("Customise Message")}</Text>
                    <Pencil />
                  </View>
                </Button>
              </View>
              {customMessage && (
                <View
                  className={`${customMessage ? styles.open__message__box : ""} ${
                    styles.message__box
                  }`}
                >
                  <View padding={xs} gap={md}>
                    <ThemeProvider theme="borderSmallToMedium">
                      <FormControl required hasError={subjectError}>
                        {subjectError && <FormControl.Error>{subjectError}</FormControl.Error>}
                        <TextField
                          placeholder={t("Subject")}
                          value={subject}
                          attributes={{
                            onBlur: handleSubjectBlur,
                            style: {
                              minHeight: "40px",
                            },
                          }}
                          onChange={handleSubjectChange}
                        />
                      </FormControl>
                    </ThemeProvider>
                    <CustomTextEditor
                      stateRef={messageRef}
                      error={customMessageError}
                      placeholder={t("Personalise Your Message")}
                      setError={setCustomMessageError}
                      preLoadTemplate={preLoadTemplate}
                      setPreLoadTemplate={setPreLoadTemplate}
                      handleTemplateSelect={handleTemplateSelect}
                    />
                  </View>
                </View>
              )}
            </>
          )}
        </View>
        <View gap={rejectionFailed ? lg : 0}>
          {rejectionFailed && (
            <ErrorSticker variant="body-strong-2" text={rejectionFailed} maxWidth="100%" />
          )}
          <View direction="row" align="center" gap={xl} width="100%">
            <View.Item grow>
              <Button variant="ghost" color="primary" onClick={handleCancel}>
                {t("Cancel")}
              </Button>
            </View.Item>
            <Button
              color="primary"
              onClick={handleReject}
              disabled={subjectError || customMessageError}
            >
              <View
                direction="row"
                align="center"
                justify="center"
                gap={sm}
                attributes={{ style: { minWidth: "100px" } }}
              >
                {sendingMessage ? (
                  <ReshapedLoader size="small" loaderColor={"inherit"} />
                ) : (
                  <>
                    <Text>
                      {t(sendMessage === "yes" ? "Reject with Email" : "Reject without Email")}
                    </Text>
                    <Slash />
                  </>
                )}
              </View>
            </Button>
          </View>
        </View>
      </View>
    </View>
  );
}
