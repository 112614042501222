import { Text } from "reshaped";

export const URLCopied = () => {
  return (
    <Text color="primary" variant="body-strong-1">
      URL Copied!
    </Text>
  );
};

export const URLCopiedWithTranslation = ({ children }) => {
  return (
    <Text color="primary" variant="body-strong-1">
      {children}
    </Text>
  );
};
