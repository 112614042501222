import { backendAPI } from "utils/axios";
import { areKeysAvailableIn } from "utils/miniHelpers";
import { reloadOnUnauthorized } from "utils/checkForAuth";
import { updateCompanyInAPI } from "redux/company/companySlice";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  slots: null,
  cart_id: null,
  total: null,
};

export const fetchCart = createAsyncThunk("fetchCart", async () => {
  try {
    const response = await backendAPI.get(`/companies/cart`);
    if (response.data && "cart_id" in response.data) {
      const { data } = response;
      if ("details" in data && "total" in data && Array.isArray(data.details)) {
        return {
          slots: data.details,
          cart_id: data.cart_id,
          total: data.total,
        };
      }
      return {
        slots: [],
        cart_id: data.cart_id,
        total: {
          original_price: 0,
          discount: 0,
          discounted_price: 0,
        },
      };
    }
    return {
      slots: [],
      cart_id: null,
      total: null,
    };
  } catch (error) {
    reloadOnUnauthorized(error);
    return {
      error: "Something went wrong",
    };
  }
});

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    reset: (state) => {
      for (let key in initialState) {
        state[key] = initialState[key];
      }
    },
    setLoading: (state) => {
      state.loading = true;
    },
    stopLoading: (state) => {
      state.loading = false;
    },
    addSlot: (state, action) => {
      const { slot, jobBoard, total, cart_id } = action.payload;

      if (cart_id) {
        state.cart_id = cart_id;
      }

      const foundSlot = state.slots.find((s) => s.job_board_slot.id === slot.id);
      if (foundSlot) {
        foundSlot.job_board_slot.quantity = slot.quantity;
      } else {
        state.slots.unshift({ job_board: jobBoard, job_board_slot: slot });
      }
      if (
        areKeysAvailableIn({
          object: total,
          keys: ["discount", "discounted_price", "original_price"],
        })
      ) {
        state.total = total;
      }
    },
    removeSlot: (state, action) => {
      const { id, total } = action.payload;
      state.slots = state.slots.filter((slot) => slot.job_board_slot.id !== id);
      if (
        areKeysAvailableIn({
          object: total,
          keys: ["discount", "discounted_price", "original_price"],
        })
      ) {
        state.total = total;
      }
    },
  },
  extraReducers: (builder) => {
    // refetch cart info on company info update
    builder.addCase(updateCompanyInAPI.fulfilled, (state, action) => {
      const { payload } = action;
      if (
        payload &&
        areKeysAvailableIn({ object: payload, keys: ["isSlugUpdated", "isCountryUpdated"] })
      ) {
        const { isCountryUpdated } = payload;
        if (isCountryUpdated) {
          state.cart_id = null;
          state.slots = null;
          state.total = null;
        }
      }
    });

    builder.addCase(fetchCart.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchCart.fulfilled, (state, action) => {
      const { slots, cart_id, total } = action.payload;
      state.loading = false;
      state.slots = slots;
      state.cart_id = cart_id;
      state.total = total || null;
    });
    builder.addCase(fetchCart.rejected, (state, action) => {
      const { error } = action.payload;
      state.loading = false;
      state.error = error;
    });
  },
});
