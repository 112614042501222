import { View } from "reshaped";
import useSpacing from "components/hooks/useSpacing";
import { ContainerWrapper } from "components/reusables/common";

export const WrapperWithNav = ({ children, wrapperStyles = {}, wrapperAttributes = {} }) => {
  const { sm, xl } = useSpacing();

  return (
    <ContainerWrapper
      padding={xl + sm}
      wrapperStyles={wrapperStyles}
      wrapperAttributes={wrapperAttributes}
    >
      <View
        attributes={{
          style: {
            minHeight: "calc(100vh - var(--topbar-height))",
            paddingBlockStart: "calc(var(--spacing-xl) + var(--spacing-sm))",
          },
        }}
      >
        {children}
      </View>
    </ContainerWrapper>
  );
};
