import React, { Fragment, Suspense, useEffect } from "react";
import { ScrollToTop } from "components/reusables/common";
import PrivateRoute from "components/Private/PrivateRoute";
import { TopNavbar, LeftSideNavbar } from "components/reusables/layouts";
import { SubscriptionAlert } from "components/reusables";
import { metaSlice } from "redux/meta";
import { useDispatch, useSelector } from "react-redux";
import { fetchSubscriptionData } from "redux/subscriptions/subscriptionsSlice";

export const DefaultLayout = ({ component: Component, publicRoute = false, ...rest }) => {
  
  if (Component && !publicRoute) {
    return (
      <Fragment>
        <Suspense fallback={<div>Loading...</div>}>
          <PrivateRoute>
            <ScrollToTop />
            <PrivateComponent Component={Component} {...rest} />
          </PrivateRoute>
        </Suspense>
      </Fragment>
    );
  }

  if (Component && publicRoute) {
    return (
      <Fragment>
        <Suspense fallback={<div>Loading...</div>}>
          <ScrollToTop />
          <Component {...rest} />
        </Suspense>
      </Fragment>
    );
  }

  if (!Component) {
    return null;
  }
};

const PrivateComponent = ({Component, ...rest}) => {
  const { showSubscriptionAlert, isAlertClosed } = useSelector((state) => state.meta);
  const alertMessage = useSelector((state) => state.meta.alertMessage);
  const paymentFailed = useSelector((state) => state.meta.paymentFailed);
  const subscription = useSelector((state) => state.subscriptions.subscription);

  const dispatch = useDispatch();
  const { hideAlert } = metaSlice.actions;

  const handleAlertClose = () => {
    dispatch(hideAlert());
  };

  useEffect(() => {
    if (!subscription.subscription_type) {
      dispatch(fetchSubscriptionData());
    }
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <LeftSideNavbar isAlertVisible={(showSubscriptionAlert && !isAlertClosed) || paymentFailed} />
      <TopNavbar />
      {
        (showSubscriptionAlert && !isAlertClosed) || paymentFailed ? (
          <SubscriptionAlert handleAlertClose={handleAlertClose} message={alertMessage} paymentFailed={paymentFailed} />
        ) : null
      }
      <div style={{ paddingTop: (showSubscriptionAlert && !isAlertClosed) || paymentFailed ? "50px" : "0" }}>
        <Component {...rest} />
      </div>
    </>
  )
}