import { useState } from "react";
import { Button, Text, View } from "reshaped";
import useSpacing from "components/hooks/useSpacing";
import useTranslation from "components/hooks/useTranslation";
import { FilterAccordion } from "components/pages/candidates";

export const FilterMenu = ({ jobRef, stageRef, followRef, closeDropdown }) => {
  const [isUpdated, setIsUpdated] = useState(false);

  const { t } = useTranslation();
  const { xs, md, lg, xl } = useSpacing();

  const handleUpdateJobs = ({ updatedData = {} }) => {
    jobRef.current = { ...jobRef.current, ...updatedData };
  };

  const handleUpdateStages = ({ updatedData = {} }) => {
    stageRef.current = { ...stageRef.current, ...updatedData };
  };

  const handleUpdateFollowers = ({ updatedData = {} }) => {
    followRef.current = { ...followRef.current, ...updatedData };
  };

  const handleClose = () => {
    if (closeDropdown && typeof closeDropdown === "function") closeDropdown({ reset: false });
  };

  return (
    <>
      <View padding={[md, xs]} gap={xl} paddingBottom={xl}>
        <View padding={[0, lg]}>
          <Text variant="body-2">{t("Filters")}</Text>
        </View>
        <View gap={lg}>
          <View>
            <FilterAccordion
              title={t("Job title")}
              optionsName="jobs"
              handleUpdate={handleUpdateJobs}
              setIsUpdated={setIsUpdated}
            />
            <FilterAccordion
              title={t("Candidate stage")}
              optionsName="stages"
              isOdd={false}
              handleUpdate={handleUpdateStages}
              setIsUpdated={setIsUpdated}
            />
            <FilterAccordion
              title={t("Followers")}
              optionsName="followers"
              handleUpdate={handleUpdateFollowers}
              setIsUpdated={setIsUpdated}
            />
          </View>
          {isUpdated && (
            <View padding={[0, lg]}>
              <View
                borderRadius="medium"
                overflow="hidden"
                attributes={{ style: { width: "max-content" } }}
              >
                <Button
                  color="primary"
                  onClick={handleClose}
                  attributes={{ "data-cooper": "candidates apply filter" }}
                  disabled={!isUpdated}
                >
                  {t("Apply")}
                </Button>
              </View>
            </View>
          )}
        </View>
      </View>
    </>
  );
};
