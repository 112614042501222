import { useMemo, useState } from "react";
import {
  Card,
  View,
  Text,
  Icon,
  Link,
  Avatar,
  Loader,
  useToast,
  useToggle,
  DropdownMenu,
} from "reshaped";

import { backendAPI } from "utils/axios";
import { filtersSlice } from "redux/filters";
import useName from "components/hooks/useName";
import useSlug from "components/hooks/useSlug";
import { useNavigate } from "react-router-dom";
import { candidateSlice } from "redux/candidate";
import useSpacing from "components/hooks/useSpacing";
import { getSanitizedJobURL } from "utils/urlHelpers";
import useFullName from "components/hooks/useFullName";
import { useDispatch, useSelector } from "react-redux";
import { TextWithEclipses } from "components/reusables";
import { deleteJob, jobSlice } from "redux/job/jobSlice";
import { getFormattedDate } from "utils/getFormattedDate";
import { reloadOnUnauthorized } from "utils/checkForAuth";
import { ShowToastWithTranslation } from "utils/showToast";
import useTranslation from "components/hooks/useTranslation";
import { copyTextToClipboard } from "utils/clipboardHelpers";
import { getLocationFromCityAndCountry } from "utils/getters";
import { baseColor, cooperColors } from "utils/colors/accents";
import styles from "components/styles/reshaped/jobs/jobs.module.css";
import { areKeysAvailableWithType, isString } from "utils/miniHelpers";
import { URLCopiedWithTranslation } from "components/reusables/URLCopied";
import useEmploymentContract from "components/hooks/useEmploymentContract";
import ConfirmationModal from "components/reusables/modals/ConfirmationModal";
import { getTextWithEclipses, isTextExceeding } from "utils/getTextWithEclipses";
import { ArrowDown, Briefcase2, JobLocation, Options, SpeakerHigh } from "icons";
import { OperationFailedWithTranslation } from "components/reusables/OperationFailed";
import { fetchSubscriptionData } from "redux/subscriptions/subscriptionsSlice";

export const JobsCard = (props) => {
  const { job } = props;
  const {
    title,
    city,
    id,
    created_at,
    user,
    country,
    post_url,
    country_code,
    status = "internal",
    employment_contract,
    total_candidates = 0,
  } = job;
  const { first_name, last_name, position } = user;

  const [loading, setLoading] = useState(false);

  const slug = useSlug();
  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, locale } = useTranslation();
  const state = useSelector((state) => state);
  const { xs, sm, md, lg, xl } = useSpacing();
  const { active, activate, deactivate } = useToggle(false);
  const contract = useEmploymentContract(employment_contract);
  const profile = useName({ first_name, last_name, info: user });
  const { countries } = state.publicState;
  const { candidates: candidatesFilters, jobs: jobsFilters } = state.filters;

  const fullName = useFullName({ first_name, last_name, dependency: [user] });

  const { data } = countries;
  const { jobs } = candidatesFilters;
  const { selected: selectedJobs, viewing } = jobs;
  const { statuses } = jobsFilters;
  const { selected: selectedStatuses } = statuses;

  const {
    active: confirmActive,
    activate: confirmActivate,
    deactivate: confirmDeactivate,
  } = useToggle(false);

  const { updateJob } = jobSlice.actions;

  const statusOptionsTitle = useMemo(() => {
    return ["Internal", "Published", "Closed", "Archived"];

    //eslint-disable-next-line
  }, []);

  const statusOptionsValue = useMemo(() => {
    return {
      Internal: "internal",
      Published: "published",
      Closed: "closed",
      Archived: "archived",
      internal: "Internal",
      published: "Published",
      closed: "Closed",
      archived: "Archived",
    };

    //eslint-disable-next-line
  }, []);

  const jobURL = useMemo(() => {
    if (status === "closed" || status === "published")
      return (
        post_url ||
        getSanitizedJobURL({
          jobTitle: title,
          jobID: id,
          companyID: job?.company?.id,
          slug,
        })
      );

    return "";

    //eslint-disable-next-line
  }, [status, title]);

  const location = useMemo(() => {
    return getLocationFromCityAndCountry({
      city,
      locale,
      country: isString(country_code) ? country_code : country,
      noLocationText: "",
      countryList: data,
    });

    //eslint-disable-next-line
  }, [data]);

  const handleFilteredAndSelectedJobs = ({ title }) => {
    if (typeof title !== "string") return;

    let shouldCandidatesRefetch = true;

    if (Array.isArray(selectedJobs) && selectedJobs.length > 0) {
      const foundJob = selectedJobs.find((selectedJob) => selectedJob?.title === title);
      if (areKeysAvailableWithType({ object: foundJob, keys: [{ title: "string" }] })) {
        const sanitizedSelectedJobs = selectedJobs.filter(
          (selectedJob) => selectedJob?.title !== title
        );
        shouldCandidatesRefetch = false;
        dispatch(
          candidateSlice.actions.set({
            loading: false,
            error: null,
            pageCount: 0,
            candidates: null,
            refetching: false,
          })
        );
        dispatch(
          filtersSlice.actions.setPageFilter({
            page: "candidates",
            types: {
              name: "jobs",
              value: { selected: sanitizedSelectedJobs },
            },
          })
        );
      }
    }

    if (viewing >= 0) {
      dispatch(
        filtersSlice.actions.setPageFilter({
          page: "candidates",
          types: {
            name: "jobs",
            value: { viewing: -1, isExhausted: false, list: [], error: false, afterKey: null },
          },
        })
      );
    }

    if (shouldCandidatesRefetch) {
      dispatch(
        candidateSlice.actions.set({
          shouldCandidatesRefetch,
        })
      );
    }
  };

  const updateJobStage = async (status) => {
    const previousJobStatus = props?.job?.status;
    if (!statusOptionsValue[status]) return;
    const job = { status };
    try {
      setLoading(true);
      const response = await backendAPI.put(`/jobs/${id}`, { job });
      if (response.data && response.data.job && response.data.job.id) {
        const job = response.data.job;
        const nextJobStatus = response.data?.job?.status;
        let jobStatusPoint = 0;
        if (previousJobStatus !== "published" && nextJobStatus === "published") jobStatusPoint = 1;
        if (previousJobStatus === "published" && nextJobStatus !== "published") jobStatusPoint = -1;
        if (previousJobStatus === "archived" || nextJobStatus === "archived") {
          handleFilteredAndSelectedJobs({ title: job?.title });
        }
        dispatch(
          updateJob({
            job,
            statusChanged: true,
            point: jobStatusPoint,
            previousJobStatus,
          })
        );

        if (Array.isArray(selectedStatuses) && selectedStatuses.length > 0) {
          dispatch(
            jobSlice.actions.set({
              shouldJobsRefetch: true,
            })
          );
        }

        ShowToastWithTranslation({
          toast,
          Children: StatusChanged,
          text: t("Job status changed!"),
        });
      } else {
        ShowToastWithTranslation({
          toast,
          Children: OperationFailedWithTranslation,
          text: t("Operation unsuccessful!"),
        });
      }
      setLoading(false);
    } catch (error) {
      reloadOnUnauthorized(error);
      setLoading(false);
      ShowToastWithTranslation({
        toast,
        Children: OperationFailedWithTranslation,
        text: t("Operation unsuccessful!"),
      });
    }
  };

  const handleDropdown = ({ selectedOption }) => {
    if (selectedOption === "Edit") {
      navigate(`update?id=${id}`);
    }
    if (selectedOption === "Delete") {
      activate();
    }
    if (selectedOption === "Copy") {
      if (status === "internal" || status === "archived") return;
      copyTextToClipboard({ text: jobURL });
      ShowToastWithTranslation({
        toast,
        Children: URLCopiedWithTranslation,
        text: t("URL copied!"),
      });
    }
  };

  const handleStatusDropdown = ({ selectedOption }) => {
    if (statusOptionsValue[selectedOption] && selectedOption !== "published") {
      updateJobStage(selectedOption);
    }
    if (selectedOption === "published") {
      confirmActivate();
    }
  };

  const handleDeleteJob = async (action) => {
    if (action === "accept") {
      dispatch(
        deleteJob({
          id,
          status,
          toast,
          t,
        })
      );
      handleFilteredAndSelectedJobs({ title });
    }
    dispatch(fetchSubscriptionData());

    deactivate();
  };

  const handleStatusChange = (action) => {
    if (action === "accept") {
      updateJobStage("published");
    }
    confirmDeactivate();
  };

  return (
    <>
      <Card padding={0} className={styles.shadow}>
        <View
          height="100%"
          gap={0}
          divided
          attributes={{
            style: { display: "flex", flexDirection: "column", justifyContent: "space-between" },
          }}
        >
          <View
            attributes={{
              style: {
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                flexGrow: 1,
              },
            }}
          >
            <View direction="row" align="center" padding={md} gap={sm} width="100%">
              <View.Item grow>
                <View direction="row" align="center" justify="start">
                  <Text
                    variant="caption-2"
                    attributes={{
                      title: isTextExceeding(id, 6) ? id : "",
                      style: {
                        color: "var(--rs-color-black)",
                      },
                    }}
                  >
                    Job id: {`${getTextWithEclipses(id, 6, 3)}`} &nbsp;|&nbsp;
                  </Text>
                  <Text color="neutral-faded" variant="caption-2">
                    {`${getFormattedDate(created_at)}`}
                  </Text>
                </View>
              </View.Item>
              <DropdownMenu width="150px" position="bottom-end">
                <DropdownMenu.Trigger>
                  {(attributes) => (
                    <View
                      width="10px"
                      attributes={{
                        ...attributes,
                        style: {
                          cursor: "pointer",
                        },
                        "data-cooper": `job ${id} edit menu`,
                      }}
                    >
                      <Icon
                        size={md}
                        svg={() => <Options color={cooperColors["foreground-primary"]} />}
                      />
                    </View>
                  )}
                </DropdownMenu.Trigger>
                <DropdownMenu.Content>
                  <DropdownMenu.Item
                    attributes={{ "data-cooper": `job ${id} edit button` }}
                    onClick={() => handleDropdown({ selectedOption: "Edit" })}
                  >
                    <Text variant="feature-3">{t("Edit")}</Text>
                  </DropdownMenu.Item>
                  <DropdownMenu.Item
                    attributes={{ "data-cooper": `job ${id} delete button` }}
                    onClick={() => handleDropdown({ selectedOption: "Delete" })}
                  >
                    <Text variant="feature-3">{t("Delete")}</Text>
                  </DropdownMenu.Item>
                  <DropdownMenu.Item
                    attributes={{ "data-cooper": `job ${id} edit button` }}
                    disabled={status === "internal" || status === "archived"}
                    onClick={() => handleDropdown({ selectedOption: "Copy" })}
                  >
                    <Text variant="feature-3">{t("Copy URL")}</Text>
                  </DropdownMenu.Item>
                </DropdownMenu.Content>
              </DropdownMenu>
            </View>
            <View
              width="80%"
              padding={[md, 0]}
              justify="center"
              attributes={{ style: { minHeight: "72px" } }}
            >
              {status === "internal" || status === "archived" ? (
                <TextWithEclipses
                  variant="body-strong-1"
                  className={styles.force__wrap}
                  text={title}
                  characterLimit={55}
                  {...{ align: "center" }}
                />
              ) : (
                <Link
                  className={styles.title}
                  href={jobURL}
                  variant="plain"
                  color="inherit"
                  attributes={{
                    target: "_blank",
                    "data-cooper": `job ${id} link`,
                  }}
                >
                  <TextWithEclipses
                    variant="body-strong-1"
                    className={styles.force__wrap}
                    text={title}
                    characterLimit={55}
                    {...{ align: "center" }}
                  />
                </Link>
              )}
            </View>
            <View
              gap={lg}
              width="100%"
              align="center"
              attributes={{ style: { paddingBlockEnd: "var(--spacing-xl)" } }}
            >
              {location && (
                <View gap={sm} align="center" justify="center" direction="row">
                  <Icon svg={JobLocation} size={md} />
                  <TextWithEclipses
                    color="neutral-faded"
                    variant="body-2"
                    text={location}
                    characterLimit={30}
                    noOfEclipses={2}
                  />
                </View>
              )}
              <View
                // width="80%"
                direction="row"
                gap={xs}
              >
                <View
                  backgroundColor="neutral-faded"
                  height="24px"
                  gap={xs}
                  align="center"
                  justify="center"
                  direction="row"
                  borderRadius="small"
                  padding={[xs, sm]}
                >
                  <Text variant="caption-1">{t(statusOptionsValue[status])}</Text>
                  <Icon
                    size={md}
                    svg={() => <SpeakerHigh color={cooperColors["foreground-neutral"]} />}
                  />
                </View>
                {contract && (
                  <View
                    backgroundColor="neutral-faded"
                    height="24px"
                    gap={xs}
                    align="center"
                    justify="center"
                    direction="row"
                    borderRadius="small"
                    padding={[xs, sm]}
                  >
                    <Text variant="caption-1">{t(contract)}</Text>
                    <Icon size={md} svg={Briefcase2} />
                  </View>
                )}
              </View>
              <View
                justify="center"
                align="center"
                direction="row"
                padding={[sm, xl]}
                borderRadius="medium"
                attributes={{
                  style: {
                    backgroundColor: "var(--rs-color-background-page)",
                  },
                }}
                gap={sm}
              >
                <Text variant="body-strong-1">{total_candidates}</Text>
                <Text variant="body-1">
                  {total_candidates === 1 ? t("Candidate") : t("Candidates")}
                </Text>
              </View>
            </View>
          </View>
          <View divided overflow="hidden" backgroundColor="white" as="div" width="100%">
            <View padding={[md, lg]} direction="row" gap={sm} align="center">
              <>
                {profile.hasURL && (
                  <Avatar
                    size={10}
                    src={profile.url}
                    alt={user ? `${user.first_name + user.last_name}` : "John Doe"}
                  />
                )}
                {!profile.hasURL && (
                  <Avatar
                    size={10}
                    initials={profile.letters}
                    alt={user ? `${user.first_name + user.last_name}` : "John Doe"}
                  />
                )}
              </>
              <View>
                <TextWithEclipses
                  variant="body-strong-2"
                  text={fullName}
                  characterLimit={14}
                  noOfEclipses={2}
                />
                <TextWithEclipses
                  variant="caption-1"
                  text={position ? position : t("Normal user")}
                  characterLimit={20}
                  noOfEclipses={2}
                />
              </View>
              {loading && (
                <View.Item grow>
                  <View padding={[0, xl]} width="100%" direction="row" justify="end" align="center">
                    <Loader size="small" />
                  </View>
                </View.Item>
              )}
              {!loading && (
                <View.Item gapBefore="auto">
                  <DropdownMenu position="top-end" width="150px">
                    <DropdownMenu.Trigger>
                      {(attributes) => (
                        <View
                          backgroundColor="primary"
                          direction="row"
                          padding={[0, sm]}
                          align="center"
                          gap={sm}
                          height="24px"
                          borderRadius="small"
                          attributes={{
                            ...attributes,
                            style: {
                              cursor: "pointer",
                              minWidth: "80px",
                              justifyContent: "space-between",
                            },
                            "data-cooper": `job ${id} status button`,
                          }}
                        >
                          <Text
                            variant="caption-1"
                            attributes={{
                              title: isTextExceeding(t(`${statusOptionsValue[status]}`), 14)
                                ? t(`${statusOptionsValue[status]}`)
                                : "",
                            }}
                          >
                            {getTextWithEclipses(t(`${statusOptionsValue[status]}`), 14, 2)}
                          </Text>
                          <Icon svg={() => <ArrowDown fill={baseColor.white} />} />
                        </View>
                      )}
                    </DropdownMenu.Trigger>
                    <DropdownMenu.Content>
                      {statusOptionsTitle.map((option) => {
                        return (
                          <DropdownMenu.Item
                            key={option}
                            disabled={statusOptionsValue[option] === status}
                            onClick={() =>
                              handleStatusDropdown({ selectedOption: statusOptionsValue[option] })
                            }
                            attributes={{
                              "data-cooper": `job status ${id} ${option}`,
                            }}
                          >
                            {t(option)}
                          </DropdownMenu.Item>
                        );
                      })}
                    </DropdownMenu.Content>
                  </DropdownMenu>
                </View.Item>
              )}
            </View>
          </View>
        </View>
      </Card>
      <ConfirmationModal
        activate={activate}
        active={active}
        deactivate={deactivate}
        title={t("Delete job?")}
        subTitle={
          <Text>
            {t("Are you sure you want to delete ")}
            <strong>({title})</strong>
            {t(" job")}?
          </Text>
        }
        acceptText="Delete"
        colorVariant={"critical"}
        handleAction={handleDeleteJob}
      />
      <ConfirmationModal
        activate={confirmActivate}
        active={confirmActive}
        deactivate={confirmDeactivate}
        acceptText={"Publish"}
        title={t("Publish Job?")}
        subTitle={t(
          "Publishing a job will display it publicly and on free job boards. Are you sure you want to publish this job?"
        )}
        colorVariant={"positive"}
        handleAction={handleStatusChange}
      />
    </>
  );
};

const StatusChanged = ({ children }) => {
  return (
    <Text color="positive" variant="body-strong-1">
      {children}
    </Text>
  );
};
