import { useMemo } from "react";
import { Text, View } from "reshaped";
import { stageOptions } from "utils/constants";
import { stageColor } from "utils/colors/stage";
import useTranslation from "components/hooks/useTranslation";

export const CandidateBadge = (props) => {
  const { stage = "applied", candidate_id } = props;

  const { t } = useTranslation();

  const stageTitles = useMemo(() => {
    return stageOptions;

    //eslint-disable-next-line
  }, []);

  return (
    <View
      direction="row"
      justify="center"
      align="center"
      width="95px"
      height="26px"
      borderRadius="small"
      attributes={{
        style: {
          textTransform: "capitalize",
          backgroundColor: `${stageColor[stage.toLowerCase()]}`,
        },
      }}
    >
      <Text
        variant="caption-1"
        color="neutral"
        attributes={{ "data-cooper": `${stageTitles[stage]?.value?.toLowerCase()} ${candidate_id}` }}
      >
        {t(stageTitles[stage]?.title)}
      </Text>
    </View>
  );
};
