import { createSlice } from "@reduxjs/toolkit";
import { areKeysAvailableIn, areKeysAvailableWithType, isString } from "utils/miniHelpers";

const initialState = {
  data: null,
  error: null,
  loading: false,
  minimalData: null,
  minimalError: null,
  minimalLoading: false,
};

export const templatesSlice = createSlice({
  name: "templates",
  initialState,
  reducers: {
    reset: (state) => {
      for (let key in initialState) {
        state[key] = initialState[key];
      }
    },
    setLoading: (state, action) => {
      if (!areKeysAvailableWithType({ object: action, keys: [{ payload: "boolean" }] })) return;
      state.loading = action.payload;
    },
    setError: (state, action) => {
      if (!areKeysAvailableIn({ object: action, keys: ["payload"] })) return;
      state.error = action.payload;
    },
    setData: (state, action) => {
      if (!areKeysAvailableIn({ object: action, keys: ["payload"] })) return;
      state.data = action.payload;
    },
    addData: (state, action) => {
      if (
        !areKeysAvailableIn({
          object: action.payload,
          keys: ["id", "message", "subject", "trigger", "name", "is_default_trigger"],
        })
      )
        return;
      const { payload } = action;
      const newData = [payload];
      const newMinimalData = [{ id: payload.id, name: payload.name }];
      const { trigger, is_default_trigger } = payload;
      if (Array.isArray(state.data)) {
        state.data.forEach((each) => {
          if (
            areKeysAvailableIn({
              object: each,
              keys: ["id", "message", "subject", "trigger", "name", "is_default_trigger"],
            })
          ) {
            if (
              is_default_trigger &&
              isString(trigger) &&
              areKeysAvailableWithType({ object: each, keys: [{ trigger, exact: true }] })
            ) {
              newData.push({
                ...each,
                is_default_trigger: null,
              });
            } else {
              newData.push(each);
            }
            newMinimalData.push({ id: each.id, name: each.name });
          }
        });
      }
      state.data = newData;
      state.minimalData = newMinimalData;
    },
    updateData: (state, action) => {
      if (
        !areKeysAvailableIn({
          object: action.payload,
          keys: ["id", "message", "subject", "trigger", "name", "is_default_trigger"],
        })
      ) {
        return;
      }
      const { payload } = action;
      const { trigger, is_default_trigger } = payload;

      if (Array.isArray(state.data)) {
        const newMinimalData = [];
        let newData = [];
        newData = state.data.reduce((acc, each) => {
          if (areKeysAvailableWithType({ object: each, keys: [{ id: payload.id, exact: true }] })) {
            newMinimalData.push({ id: each.id, name: payload.name });
            return [...acc, payload];
          }
          if (
            is_default_trigger &&
            isString(trigger) &&
            areKeysAvailableWithType({ object: each, keys: [{ trigger, exact: true }] })
          ) {
            newMinimalData.push({ id: each.id, name: each.name });
            return [...acc, { ...each, is_default_trigger: null, trigger: "" }];
          }
          newMinimalData.push({ id: each.id, name: each.name });
          return [...acc, each];
        }, []);

        state.data = newData;
        state.minimalData = newMinimalData;
      }
    },
    deleteData: (state, action) => {
      if (!areKeysAvailableIn({ object: action.payload, keys: ["id"] })) return;
      const { payload } = action;
      if (Array.isArray(state.data)) {
        const newMinimalData = [];
        state.data = state.data.reduce((acc, each) => {
          if (
            areKeysAvailableWithType({
              object: each,
              keys: [{ id: payload.id, exact: true }, { name: "string" }],
            })
          ) {
            return acc;
          }
          newMinimalData.push({ id: each.id, name: each.name });
          return [...acc, each];
        }, []);
        state.minimalData = newMinimalData;
      }
    },
    setProperties: (state, action) => {
      if (areKeysAvailableWithType({ object: action, keys: [{ payload: "object" }] })) {
        const { payload } = action;
        for (let k in payload) {
          if (initialState.hasOwnProperty(k)) {
            state[k] = payload[k];
          }
        }
      }
    },
  },
});
