import { useMemo, useState } from "react";
import { DropdownMenu, Text, useToast, View } from "reshaped";

import { ArrowDown } from "icons";
import { cartSlice } from "redux/cart";
import { backendAPI } from "utils/axios";
import useSpacing from "components/hooks/useSpacing";
import { areKeysAvailableIn } from "utils/miniHelpers";
import { useDispatch, useSelector } from "react-redux";
import useCurrency from "components/hooks/useCurrency";
import { accent, baseColor } from "utils/colors/accents";
import { reloadOnUnauthorized } from "utils/checkForAuth";
import { ShowToastWithTranslation } from "utils/showToast";
import useTranslation from "components/hooks/useTranslation";
import styles from "components/styles/reshaped/promote/promote.module.css";
import { FoldText, ReshapedLoader, TextWithEclipses } from "components/reusables";
import { OperationFailedWithTranslation } from "components/reusables/OperationFailed";

export const Slot = ({ slot, jobBoard }) => {
  const { name, description, duration, discount, original_price, id, discounted_price } = slot;

  const [loading, setLoading] = useState(false);

  const toast = useToast();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { currencySymbol } = useCurrency();
  const { xs, sm, md, lg, xl } = useSpacing();
  const { slots } = useSelector((state) => state.cart);

  const slotDetails = useMemo(() => {
    let discountInfo = false;
    if (typeof discount === "number" && discount >= 0) {
      discountInfo = {
        priceWithDiscount: discounted_price,
        discountPercentage: discount,
      };
    }
    return {
      originalPrice: original_price,
      hasDiscount: discountInfo,
    };

    //eslint-disable-next-line
  }, [name]);

  const updateCart = async ({ cart, isPresent = false, quantity }) => {
    try {
      const response = await backendAPI.post("/companies/cart", { cart });
      if (
        response.data &&
        areKeysAvailableIn({ object: response.data, keys: ["cart"] }) &&
        areKeysAvailableIn({
          object: response.data.cart,
          keys: ["discount", "discounted_price", "original_price"],
        })
      ) {
        const slotPhrase = quantity === 1 ? "Slot" : "Slots";
        const { original_price, discount, discounted_price, id } = response.data.cart;
        return {
          message: isPresent ? `${slotPhrase} updated!` : `${slotPhrase} added!`,
          total: {
            original_price,
            discount,
            discounted_price,
          },
          cart_id: id,
        };
      }
      return {
        error: "Operation unsuccessful!",
      };
    } catch (error) {
      reloadOnUnauthorized(error);
      return {
        error: "Operation unsuccessful!",
      };
    }
  };

  const handleAddSlotInCart = async (quantity) => {
    setLoading(true);
    const slot = {
      id,
      name,
      original_price,
      discount,
      discounted_price,
      quantity,
    };

    let details = [];
    let isPresent = false;
    let isQuantityExist = false;

    if (Array.isArray(slots)) {
      details = slots.reduce((acc, curr) => {
        if (curr.job_board_slot.id === id) {
          isPresent = true;
          if (quantity === curr.job_board_slot.quantity) {
            isQuantityExist = true;
          }
          return [
            ...acc,
            {
              job_board_slot_id: curr.job_board_slot.id,
              quantity,
            },
          ];
        }
        return [
          ...acc,
          {
            job_board_slot_id: curr.job_board_slot.id,
            quantity: curr.job_board_slot.quantity,
          },
        ];
      }, []);
    }

    if (isQuantityExist) {
      setLoading(false);
      const phrase = quantity === 1 ? `slot already exist!` : `slots already exist!`;
      ShowToastWithTranslation({
        toast,
        Children: OperationFailedWithTranslation,
        text: `${quantity} ${jobBoard.name} ${name} ${t(phrase)}`,
      });
      return;
    }

    if (!isPresent) {
      details.push({
        job_board_slot_id: id,
        quantity,
      });
    }

    const cart = { details, status: "saved" };

    const result = await updateCart({ cart, isPresent, quantity });
    setLoading(false);

    if (result.message && result.total && result.cart_id) {
      dispatch(
        cartSlice.actions.addSlot({
          slot,
          jobBoard: { id: jobBoard.id, name: jobBoard.name, logo: jobBoard.logo },
          total: result.total,
          cart_id: result.cart_id,
        })
      );
      ShowToastWithTranslation({
        toast,
        Children: SlotAdded,
        text: t(result.message),
      });
    }

    if (result.error) {
      ShowToastWithTranslation({
        toast,
        Children: OperationFailedWithTranslation,
        text: t(result.error),
      });
    }
  };

  return (
    <View
      gap={md}
      padding={[lg + xs, xl]}
      shadow="base"
      borderRadius="medium"
      attributes={{
        style: {
          minHeight: "170px",
        },
      }}
    >
      <View direction="row" gap={sm} align="center">
        <Text variant="body-strong-1" color="neutral-faded">
          {name}
        </Text>
        <SlotBadge text={`${duration} ${duration === 1 ? t("day") : t("days")}`} />
        {slotDetails.hasDiscount && (
          <SlotBadge text={`-${slotDetails.hasDiscount.discountPercentage}%`} />
        )}
      </View>
      <View direction="row" align="center" gap={md + xl}>
        <View.Item grow>
          <FoldText
            variant="body-1"
            color="neutral-faded"
            text={description}
            characterLimit={170}
          />
        </View.Item>
        <View justify="center" align="center" width="100px">
          {slotDetails.hasDiscount ? (
            <View align="center" justify="center" gap={md} width="100%">
              <Text
                align="center"
                variant="body-strong-1"
                color="neutral-faded"
                className={styles.slot__strike__text}
              >
                {t("Before")} {currencySymbol}
                {slotDetails.originalPrice}
              </Text>
              <Text variant="title-3" align="center">
                {currencySymbol}
                {slotDetails.hasDiscount.priceWithDiscount}
              </Text>
            </View>
          ) : (
            <Text variant="title-3">
              {currencySymbol}
              {slotDetails.originalPrice}
            </Text>
          )}
        </View>
        <DropdownMenu>
          <DropdownMenu.Trigger>
            {(attributes) => (
              <View
                className={styles.slot__dropdown}
                direction="row"
                align="center"
                justify="center"
                gap={sm}
                padding={[lg, md]}
                borderRadius="small"
                attributes={{
                  ...attributes,
                  disabled: loading,
                }}
              >
                {loading ? (
                  <ReshapedLoader size="small" loaderClass={styles.slot__loader} />
                ) : (
                  <>
                    <TextWithEclipses
                      variant="body-strong-2"
                      text={t("Add product")}
                      characterLimit={12}
                      noOfEclipses={2}
                    />
                    <ArrowDown fill={baseColor.black} />
                  </>
                )}
              </View>
            )}
          </DropdownMenu.Trigger>
          <DropdownMenu.Content>
            {[1, 2, 3, 4, 5, 10, 15].map((quantity) => {
              return (
                <DropdownMenu.Item
                  onClick={() => {
                    handleAddSlotInCart(quantity);
                  }}
                  key={quantity}
                >
                  <View gap={xl} direction="row" align="center" justify="center">
                    <TextWithEclipses
                      variant="body-1"
                      text={`${quantity} ${name}`}
                      characterLimit={20}
                      noOfEclipses={2}
                    />
                    <View.Item grow>
                      {slotDetails.hasDiscount ? (
                        <Text align="end" variant="body-1">
                          {currencySymbol}
                          {quantity * slotDetails.hasDiscount.priceWithDiscount}
                        </Text>
                      ) : (
                        <Text align="end" variant="body-1">
                          {currencySymbol}
                          {quantity * slotDetails.originalPrice}
                        </Text>
                      )}
                    </View.Item>
                  </View>
                </DropdownMenu.Item>
              );
            })}
          </DropdownMenu.Content>
        </DropdownMenu>
      </View>
    </View>
  );
};

export const SlotBadge = ({ text }) => {
  const { xs } = useSpacing();

  return (
    <View
      height="20px"
      padding={[0, xs]}
      direction="row"
      align="center"
      justify="center"
      attributes={{
        style: {
          backgroundColor: accent["2-600"],
          borderRadius: "2px",
        },
      }}
    >
      <Text
        variant="caption-1"
        attributes={{
          style: {
            color: "var(--rs-color-white)",
          },
        }}
      >
        {text}
      </Text>
    </View>
  );
};

const SlotAdded = ({ children }) => {
  return (
    <Text color="positive" variant="body-strong-1">
      {children}
    </Text>
  );
};
