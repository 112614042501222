import { useMemo } from "react";
import { View, Text, Button } from "reshaped";
import { useNavigate } from "react-router-dom";
import useSpacing from "components/hooks/useSpacing";
import { ProgressCircle } from "components/pages/dashboard";
import useTranslation from "components/hooks/useTranslation";

export const ProfileStats = (props) => {
  const { total } = props;
  const { percentage = 0 } = total;

  const { t } = useTranslation();
  const { lg } = useSpacing();
  const navigate = useNavigate();

  const getColor = useMemo(() => {
    const empty = "var(--rs-color-background-neutral)";
    if (!percentage) return { filled: "var(--rs-color-background-critical)", empty };
    if (percentage <= 50) return { filled: "var(--rs-color-background-critical)", empty };
    if (percentage <= 80) return { filled: "var(--rs-color-background-positive)", empty };
    if (percentage <= 100) return { filled: "var(--rs-color-background-primary)", empty };
  }, [percentage]);

  const handleProfileNavigation = () => {
    navigate("/settings?to=company");
  };

  return (
    <View height="215px" padding={lg} gap={lg} borderRadius="medium" backgroundColor="white">
      <Text variant="body-strong-1">{t("Company Profile")}</Text>
      <View align="center" gap={lg}>
        <ProgressCircle
          completed={percentage}
          size={95}
          filledStrokeColor={getColor.filled}
          emptyStrokeColor={getColor.empty}
        />
        <Button
          size="small"
          variant="ghost"
          color="primary"
          onClick={handleProfileNavigation}
          {...(percentage === 100 ? { disabled: true } : {})}
        >
          {t("Complete your profile")}
        </Button>
      </View>
    </View>
  );
};
