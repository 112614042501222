import { useMemo } from "react";
import { Text, View } from "reshaped";
import useTranslation from "components/hooks/useTranslation";
import styles from "components/styles/reshaped/bookings/bookings.module.css";

export const InventoryHeading = () => {
  const { t } = useTranslation();

  const HeadingLabels = useMemo(() => {
    const labels = [
      {
        title: t("Job Board"),
        grow: 2,
      },
      {
        title: t("Booking"),
        grow: 2,
      },
      {
        title: t("Purchase Date"),
        grow: 2,
      },
      // disabling "price" for period of time
      // {
      //   title: t("Price"),
      // },
      {
        title: t("Quantity"),
      },
      {
        title: t("Duration"),
      },
      {
        title: t("Action"),
      },
    ];
    return labels;

    //eslint-disable-next-line
  }, []);

  return (
    <View height="58px" direction="row" align="center" className={styles.inventory__row}>
      {HeadingLabels.map((label) => {
        return (
          <View
            className={`${styles.inventory__column} ${
              "grow" in label ? styles[`grow-${label.grow}`] : ""
            }`}
            key={label.title}
          >
            <Text variant="body-strong-1">{label.title}</Text>
          </View>
        );
      })}
    </View>
  );
};
