import { useEffect, useRef, useState, useMemo } from "react";
import { Button, Text, useToast, View } from "reshaped";

import { MirrorScreen } from "icons";
import { backendAPI } from "utils/axios";
import { filtersSlice, jobsFiltersSlice } from "redux/filters";
import { useNavigate } from "react-router-dom";
import { sanitizeWord } from "utils/validateError";
import { useDispatch, useSelector } from "react-redux";
import NewJob from "components/pages/jobs/newJob/NewJob";
import { reloadOnUnauthorized } from "utils/checkForAuth";
import { ShowToastWithTranslation } from "utils/showToast";
import { fetchJobData, jobSlice } from "redux/job/jobSlice";
import { areKeysAvailableWithType } from "utils/miniHelpers";
import useTranslation from "components/hooks/useTranslation";
import { ContainerWrapper } from "components/reusables/common";
import { lookForReplacementText } from "utils/replacementsTexts";
import styles from "components/styles/reshaped/jobs/new.module.css";
import usePublicCountries from "components/hooks/usePublicCountries";
import { LoadingOverlay, ReshapedLoader } from "components/reusables";
import { EditorStateToHTML, EditorStateToText } from "utils/EditorHelpers";
import { fetchSubscriptionData } from "redux/subscriptions/subscriptionsSlice";
import { OperationFailedWithTranslation } from "components/reusables/OperationFailed";
import { exceededJobsLimit, isCronjobDowngradeTriggered } from "utils/pages/settings";

function CreateJob() {
  const optionsRef = useRef(null);
  const [currentStep, setCurrentStep] = useState(1);

  const toast = useToast();
  const Dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const state = useSelector((state) => state);
  const { getCountryCode, getCountryName } = usePublicCountries();

  const { loading, jobs } = state.job;

  const { subscription } = state.subscriptions;
  const { candidates: candidatesFilters, jobs: jobsFilters } = state.filters;

  const { jobs: filterJobs } = candidatesFilters;
  const { viewing } = filterJobs;
  const { statuses } = jobsFilters;
  const { selected: selectedStatuses } = statuses;

  const { startLoading, stopLoading, setError, setAPIError, setJob } = jobSlice.actions;

  const currentStepProps = { currentStep, setCurrentStep };

  useEffect(() => {
    if (!jobs) {
      Dispatch(fetchJobData());
    }

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!subscription.subscription_type) {
      Dispatch(fetchSubscriptionData());
    }
    //eslint-disable-next-line
  }, []);

  const translations = useMemo(() => {
    return {
      Cancel: t("Cancel"),
      "Create job": t("Create job"),
    };

    //eslint-disable-next-line
  }, []);

  function sanitizedState(state) {
    const sanitizedState = { ...state };

    // sanitizing salary
    if (sanitizedState.max_salary === "") {
      sanitizedState.salary_kind = "single";
      delete sanitizedState["max_salary"];
    }
    if (sanitizedState.max_salary !== 0 && sanitizedState.max_salary) {
      sanitizedState.salary_kind = "range";
    }
    if (sanitizedState.min_salary !== "" || sanitizedState.max_salary !== "") {
      if (sanitizedState.salary_type === "") sanitizedState.salary_type = "year";
      if (sanitizedState.currency === "") sanitizedState.currency = "EUR";
    }
    if (!sanitizedState.min_salary && !sanitizedState.max_salary) {
      delete sanitizedState["max_salary"];
      delete sanitizedState["min_salary"];
      delete sanitizedState["currency"];
      delete sanitizedState["salary_type"];
      delete sanitizedState["salary_kind"];
    }

    for (let key in sanitizedState) {
      sanitizedState[key] = sanitizedState[key].replace(/^\s+|\s+$/, "");
      if (sanitizedState[key] === "") delete sanitizedState[key];
    }

    //sanitizing remote_job
    if (sanitizedState.remote_job) {
      sanitizedState.remote_job === "yes"
        ? (sanitizedState.remote_job = true)
        : (sanitizedState.remote_job = false);
    }

    return sanitizedState;
  }

  function sanitizedEditorState({ state, fields = [] }) {
    const sanitizedState = { ...state };
    const length = fields.length;
    for (let i = 0; i < length; i++) {
      const key = fields[i];
      const editorState = state[key];
      if (!editorState) continue;
      const onlyText = EditorStateToText(editorState);
      if (!onlyText) delete sanitizedState[key];
      if (onlyText) sanitizedState[key] = EditorStateToHTML(sanitizedState[key]);
    }
    return sanitizedState;
  }

  const createJob = async (state) => {
    const stateWithHTML = sanitizedEditorState({
      state,
      fields: ["anything_else", "benefits", "requirements", "short_intro", "tasks"],
    });
    const job = sanitizedState(stateWithHTML);

    if (areKeysAvailableWithType({ object: job, keys: [{ country: "string" }] })) {
      const country = getCountryName({ country: job.country, defaultValue: "" });

      if (!country) return;

      job.country = country;

      const country_code = getCountryCode({ country: job.country });

      if (!country_code) return;

      job.country_code = country_code;
    }

    try {
      Dispatch(startLoading());
      const response = await backendAPI.post("/jobs", { job });
      Dispatch(stopLoading());
      if (response && response.status === 200) {
        const { data } = response;
        if (data.errors) {
          const error = {};
          for (let key in data.errors) {
            if (key === "user" || key === "company") continue;
            error[key] = data.errors[key][0];
          }
          Dispatch(setError(error));
          ShowToastWithTranslation({
            toast,
            Children: FillValidInput,
            text: t("Please enter valid input!"),
          });
          return;
        }
        if (data.job) {
          Dispatch(setJob(data.job));
          if (viewing >= 0) {
            Dispatch(
              filtersSlice.actions.setPageFilter({
                page: "candidates",
                types: {
                  name: "jobs",
                  value: {
                    viewing: -1,
                    isExhausted: false,
                    list: [],
                    error: false,
                    afterKey: null,
                  },
                },
              })
            );
          }

          if (Array.isArray(selectedStatuses) && selectedStatuses.length > 0) {
            Dispatch(
              jobsFiltersSlice.actions.setPageFilter({
                page: "jobs",
                types: { name: "statuses", value: { selected: [] } }
              })
            );
            Dispatch(
              jobSlice.actions.set({
                isFiltering: false,
                shouldJobsRefetch: true,
                filteringError: null
              })
            );
          }

          navigate("/jobs");
          Dispatch(fetchSubscriptionData());
          ShowToastWithTranslation({
            toast,
            Children: JobAdded,
            text: t("Job added successfully!"),
          });
          return;
        }
      }

      ShowToastWithTranslation({
        toast,
        Children: OperationFailedWithTranslation,
        text: t("Operation unsuccessful!"),
      });
      Dispatch(setAPIError("Something went wrong"));
      return;
    } catch (error) {
      Dispatch(stopLoading());
      reloadOnUnauthorized(error);
      ShowToastWithTranslation({
        toast,
        Children: OperationFailedWithTranslation,
        text: t("Operation unsuccessful!"),
      });
      Dispatch(setAPIError("Something went wrong"));
      return;
    }
  };

  const handleSubmit = () => {
    if (exceededJobsLimit(subscription)) {
      ShowToastWithTranslation({
        toast,
        Children: OperationFailedWithTranslation,
        text: `${t(
          "Please update your payment plan before adding more jobs. You cannot add more than"
        )}
          ${subscription.max_jobs} ${t("jobs with the")} ${subscription.subscription_type} ${t(
          "plan job_limit_error"
        )}`,
      });
      return;
    }

    if (isCronjobDowngradeTriggered(subscription)) {
      ShowToastWithTranslation({
        toast,
        Children: OperationFailedWithTranslation,
        text: t(
          "You have already scheduled a downgrade for your subscription, please wait for the downgrade to take effect before changing the plan!"
        ),
      });
      return;
    }

    let hasError = false;
    const { errors, setErrors, state } = optionsRef.current;

    for (let field in errors) {
      if (errors[field]) {
        hasError = true;
        if (typeof errors[field] === "object") {
          setErrors((previousError) => {
            return {
              ...previousError,

              //eslint-disable-next-line
              [field]: t(lookForReplacementText(`${sanitizeWord(field, true)} is required`)),
            };
          });
        }
      }
    }

    if (hasError) {
      ShowToastWithTranslation({
        toast,
        Children: FillRequiredFields,
        text: t("Please enter required fields or valid inputs!"),
      });
      return;
    }

    createJob(state);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    Dispatch(setError(null));
    navigate("/jobs");
  };

  return (
    <ContainerWrapper extraClass={styles.container__wrapper}>
      {loading && (
        <LoadingOverlay>
          <ReshapedLoader />
        </LoadingOverlay>
      )}
      <>
        <NewJob {...currentStepProps} handleSubmit={handleSubmit} ref={optionsRef} />
        {currentStep === 1 && (
          <View gap={4} className={styles.submit} direction="row">
            <Button onClick={handleCancel} color="critical" variant="outline">
              {translations.Cancel}
            </Button>
            <Button
              onClick={handleSubmit}
              startIcon={MirrorScreen}
              color="primary"
              attributes={{ "data-cooper": "create job submit" }}
            >
              {translations["Create job"]}
            </Button>
          </View>
        )}
      </>
    </ContainerWrapper>
  );
}

export default CreateJob;

const JobAdded = ({ children }) => {
  return (
    <Text color="positive" variant="body-strong-1">
      {children}
    </Text>
  );
};

export const FillRequiredFields = ({ children }) => {
  return (
    <Text color="critical" variant="body-strong-1">
      {children}
    </Text>
  );
};

export const FillValidInput = ({ children }) => {
  return (
    <Text color="critical" variant="body-strong-1">
      {children}
    </Text>
  );
};
