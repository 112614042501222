import { areKeysAvailableWithType } from "utils/miniHelpers";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// pages initial states

const initials = {
  // number of filters in candidates page
  candidates: {
    jobs: {
      selected: [],
      viewing: -1,
      isExhausted: false,
      list: [],
      error: false,
      afterKey: null,
    },
    stages: {
      selected: [],
    },
    followers: {
      selected: [],
    },
    search: {
      selected: []
    }
  },
  jobs: {
    statuses: {
      selected: []
    }
  },
};

const initialState = {
  // candidates is page here
  candidates: initials.candidates,
  jobs: initials.jobs,
};

export const getFilterJobsFromElastic = createAsyncThunk(
  "filterjobs/fetch",
  async ({ viewing = 0 }) => {
    try {
      const promise = new Promise((res, rej) => {
        setTimeout(() => {
          res({
            list: [
              { id: 242, title: "Ruby developer" },
              { id: 243, title: "Frontend developer" },
              { id: 244, title: "Product Manager" },
              { id: 245, title: "Senior business analyst" },
              { id: 246, title: "Fullstack developer" },
              { id: 247, title: "Backend Engineer" },
              { id: 248, title: "Senior Designer" },
            ],
            isExhausted: true,
            viewing,
            error: null,
          });
        }, 3000);
      });
      const result = await promise;
      return result;
    } catch (error) {
      return {
        list: [],
        isExhausted: false,
        viewing,
        error: "Jobs fetching failed",
      };
    }
  }
);

export const filtersSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    reset: (state) => {
      for (let key in initialState) {
        state[key] = initialState[key];
      }
    },
    resetPageFilter: (state, action) => {
      const { payload } = action;
      if (typeof payload === "object") {
        const { page = "candidates", types = ["jobs", "stages", "followers"] } = payload;
        state[page] = {
          ...state[page],
          ...types.reduce((acc, curr) => ({ ...acc, [curr]: initials[page][curr] }), {}),
        };
      }
    },
    setPageFilter: (state, action) => {
      const { payload } = action;
      if (typeof payload !== "object") return;
      if (
        !areKeysAvailableWithType({
          object: payload,
          keys: [{ page: "string" }, { types: "object" }],
        }) &&
        !areKeysAvailableWithType({
          object: payload.types,
          keys: [{ name: "string" }, { value: "object" }],
        })
      )
        return;
      const { page, types } = payload;
      state[page] = {
        ...state[page],
        [types.name]: {
          ...state[page][types.name],
          ...types.value,
        },
      };
    },
    setCandidateSearch: (state, action) => {
      const { payload } = action;
      const { value } = payload;
      state.candidates.search = value
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFilterJobsFromElastic.pending, (state) => {
      state.candidates.jobs.loading = true;
    });
    builder.addCase(getFilterJobsFromElastic.fulfilled, (state, action) => {
      state.candidates.jobs.loading = false;
      const { error, list, isExhausted, viewing } = action.payload;
      state.candidates = {
        ...state["candidates"],
        jobs: {
          ...state["candidates"]["jobs"],
          list: [...state.candidates.jobs.list, ...list],
          error,
          isExhausted,
          viewing,
        },
      };
    });
    builder.addCase(getFilterJobsFromElastic.rejected, (state, action) => {
      state.candidates.jobs.loading = false;
      const { error, list, isExhausted, viewing } = action.payload;
      state.candidates = {
        ...state["candidates"],
        jobs: {
          ...state["candidates"]["jobs"],
          list: [...state.candidates.jobs.list, ...list],
          error,
          isExhausted,
          viewing,
        },
      };
    });
  },
});

export const jobsFiltersSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    reset: (state) => {
      for (let key in initialState) {
        state[key] = initialState[key];
      }
    },
    resetPageFilter: (state, action) => {
      const { payload } = action;
      if (typeof payload === "object") {
        const { page = "jobs", types = ["statuses"] } = payload;
        state[page] = {
          ...state[page],
          ...types.reduce((acc, curr) => ({ ...acc, [curr]: initials[page][curr] }), {}),
        };
      }
    },
    setPageFilter: (state, action) => {
      const { payload } = action;
      if (typeof payload !== "object") return;
      if (
        !areKeysAvailableWithType({
          object: payload,
          keys: [{ page: "string" }, { types: "object" }],
        }) &&
        !areKeysAvailableWithType({
          object: payload.types,
          keys: [{ name: "string" }, { value: "object" }],
        })
      )
        return;
      const { page, types } = payload;
      state[page] = {
        ...state[page],
        [types.name]: {
          ...state[page][types.name],
          ...types.value,
        },
      };
    },
  }
});
