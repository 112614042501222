export function setInitialErrors(fields = [], errors, errorFields = []) {
  if (errors) {
    return fields.reduce((previousValue, currentValue) => {
      if (errorFields.includes(currentValue) && errors[currentValue]) {
        return {
          ...previousValue,
          [currentValue]: errors[currentValue][0],
        };
      }
      if (currentValue === "password") {
        return {
          ...previousValue,
          [currentValue]: {},
        };
      }
      return {
        ...previousValue,
        [currentValue]: null,
      };
    }, {});
  }
  return fields.reduce((previousValue, currentValue) => {
    return {
      ...previousValue,
      [currentValue]: {},
    };
  }, {});
}

export function setInitialJobErrors(fields = [], errors) {
  if (errors) {
    return fields.reduce((previousValue, currentValue) => {
      const value = currentValue.toLowerCase();
      if (errors[value]) {
        return {
          ...previousValue,
          [value]: errors[value][0],
        };
      }
      return {
        ...previousValue,
        [value]: null,
      };
    }, {});
  }
  return fields.reduce((previousValue, currentValue) => {
    const value = currentValue.toLowerCase();
    return {
      ...previousValue,
      [value]: {},
    };
  }, {});
}

export function setJobErrorsToNull(fields = [], errors) {
  if (errors) {
    return fields.reduce((previousValue, currentValue) => {
      const value = currentValue.toLowerCase();
      if (errors[value]) {
        return {
          ...previousValue,
          [value]: errors[value],
        };
      }
      return {
        ...previousValue,
        [value]: null,
      };
    }, {});
  }
  return fields.reduce((previousValue, currentValue) => {
    const value = currentValue.toLowerCase();
    return {
      ...previousValue,
      [value]: null,
    };
  }, {});
}