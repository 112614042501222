import { Button, View } from "reshaped";
import { Chip } from "components/reusables";
import { filtersSlice } from "redux/filters";
import { stageOptions } from "utils/constants";
import { candidateSlice } from "redux/candidate";
import useSpacing from "components/hooks/useSpacing";
import { useDispatch, useSelector } from "react-redux";
import useTranslation from "components/hooks/useTranslation";
import { fetchFilteredCandidates } from "utils/pages/candidate";
import { areKeysAvailableIn, areKeysAvailableWithType } from "utils/miniHelpers";
import useUserLanguagePreference from "components/hooks/useUserLanguagePreference";
import { useCallback } from "react";
import { getFullName } from "utils/nameHelpers";

export const FilterChips = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { sm, lg } = useSpacing();
  const state = useSelector((state) => state);
  const userLang = useUserLanguagePreference();

  const { user } = state.auth;
  const { recruiters } = state.candidate;

  const followerData = useCallback((recruiterID) =>
    recruiters.find((recruiter) => recruiter.id === recruiterID)
  , [recruiters]);

  const { candidates: candidatesFilters } = state.filters;
  const { candidates: candidatesSorting } = state.sorting;

  const { jobs, stages, search, followers } = candidatesFilters;
  const { selected: selectedJobs } = jobs;
  const { selected: selectedStages } = stages;
  const { selected: selectedFollowers } = followers;
  const { selected: selectedSearches } = search;

  const handleRemoveJobChip = async ({ id }) => {
    if (!Array.isArray(selectedJobs)) return;

    dispatch(
      candidateSlice.actions.set({
        isFiltering: true,
        filteringError: null,
      })
    );
    const updatedJobs = selectedJobs.filter((job) => job?.id !== id);

    const response = await fetchFilteredCandidates({
      company_id: user.company_id,
      selectedJobs: updatedJobs,
      selectedStages,
      selectedFollowers,
      sorting: candidatesSorting,
      current_language: userLang,
      selectedSearches: selectedSearches,
    });

    if (areKeysAvailableWithType({ object: response, keys: [{ data: "object" }] })) {
      const { candidates, totalCandidates } = response.data;
      dispatch(
        candidateSlice.actions.set({
          pageCount: 0,
          isFiltering: false,
          candidates,
          totalCandidates,
        })
      );
    }
    if (areKeysAvailableWithType({ object: response, keys: [{ hasError: "boolean" }] })) {
      dispatch(
        candidateSlice.actions.set({
          pageCount: 0,
          isFiltering: false,
          totalCandidates: 0,
          filteringError: "something went wrong!",
        })
      );
    }

    dispatch(
      filtersSlice.actions.setPageFilter({
        page: "candidates",
        types: { name: "jobs", value: { selected: updatedJobs } },
      })
    );
  };

  const handleRemoveStageChip = async ({ stage }) => {
    if (!Array.isArray(selectedStages)) return;

    dispatch(
      candidateSlice.actions.set({
        isFiltering: true,
        filteringError: null,
      })
    );
    const updatedStages = selectedStages.filter((s) => s !== stage);

    const response = await fetchFilteredCandidates({
      company_id: user.company_id,
      selectedJobs,
      selectedStages: updatedStages,
      sorting: candidatesSorting,
      current_language: userLang,
      selectedSearches: selectedSearches,
    });

    if (areKeysAvailableWithType({ object: response, keys: [{ data: "object" }] })) {
      const { candidates, totalCandidates } = response.data;
      dispatch(
        candidateSlice.actions.set({
          pageCount: 0,
          isFiltering: false,
          candidates,
          totalCandidates,
        })
      );
    }
    if (areKeysAvailableWithType({ object: response, keys: [{ hasError: "boolean" }] })) {
      dispatch(
        candidateSlice.actions.set({
          pageCount: 0,
          isFiltering: false,
          totalCandidates: 0,
          filteringError: "something went wrong!",
        })
      );
    }

    dispatch(
      candidateSlice.actions.set({
        pageCount: 0,
        isFiltering: false,
      })
    );

    dispatch(
      filtersSlice.actions.setPageFilter({
        page: "candidates",
        types: { name: "stages", value: { selected: updatedStages } },
      })
    );
  };


  const handleRemoveFollowerChip = async ({ id }) => {
    if (!Array.isArray(selectedFollowers)) return;

    dispatch(
      candidateSlice.actions.set({
        isFiltering: true,
        filteringError: null,
      })
    );
    const updatedFollowers = selectedFollowers.filter((followerId) => followerId !== id);

    const response = await fetchFilteredCandidates({
      company_id: user.company_id,
      selectedJobs,
      selectedStages,
      selectedFollowers: updatedFollowers,
      sorting: candidatesSorting,
      current_language: userLang,
      selectedSearches: selectedSearches,
    });

    if (response.data) {
      const { candidates, totalCandidates } = response.data;
      dispatch(
        candidateSlice.actions.set({
          pageCount: 0,
          isFiltering: false,
          candidates,
          totalCandidates,
        })
      );
    } else if (response.hasError) {
      dispatch(
        candidateSlice.actions.set({
          pageCount: 0,
          isFiltering: false,
          totalCandidates: 0,
          filteringError: "something went wrong!",
        })
      );
    }

    dispatch(
      filtersSlice.actions.setPageFilter({
        page: "candidates",
        types: { name: "followers", value: { selected: updatedFollowers } },
      })
    );
  };

  const handleRemoveSearchChip = async ({ search }) => {
    if (!Array.isArray(selectedSearches)) return;

    dispatch(
      candidateSlice.actions.set({
        isFiltering: true,
        filteringError: null,
      })
    );
    const updatedSearches = selectedSearches.filter((s) => s !== search);
    const response = await fetchFilteredCandidates({
      company_id: user.company_id,
      selectedJobs,
      selectedStages: selectedStages,
      sorting: candidatesSorting,
      current_language: userLang,
      selectedSearches: updatedSearches,
    });

    if (areKeysAvailableWithType({ object: response, keys: [{ data: "object" }] })) {
      const { candidates, totalCandidates } = response.data;
      dispatch(
        candidateSlice.actions.set({
          pageCount: 0,
          isFiltering: false,
          candidates,
          totalCandidates,
        })
      );
    }
    if (areKeysAvailableWithType({ object: response, keys: [{ hasError: "boolean" }] })) {
      dispatch(
        candidateSlice.actions.set({
          pageCount: 0,
          isFiltering: false,
          totalCandidates: 0,
          filteringError: "something went wrong!",
        })
      );
    }

    dispatch(
      candidateSlice.actions.set({
        pageCount: 0,
        isFiltering: false,
      })
    );

    dispatch(
      filtersSlice.actions.setPageFilter({
        page: "candidates",
        types: { name: "search", value: { selected: updatedSearches } },
      })
    );
  };

  const handleClearAll = async () => {
    dispatch(
      candidateSlice.actions.set({
        isFiltering: true,
        filteringError: null,
      })
    );

    const response = await fetchFilteredCandidates({
      company_id: user.company_id,
      selectedJobs: [],
      selectedStages: [],
      selectedFollowers: [],
      sorting: candidatesSorting,
      current_language: userLang,
      selectedSearches: [],
    });

    if (areKeysAvailableWithType({ object: response, keys: [{ data: "object" }] })) {
      const { candidates, totalCandidates } = response.data;
      dispatch(
        candidateSlice.actions.set({
          pageCount: 0,
          isFiltering: false,
          candidates,
          totalCandidates,
        })
      );
    }
    if (areKeysAvailableWithType({ object: response, keys: [{ hasError: "boolean" }] })) {
      dispatch(
        candidateSlice.actions.set({
          pageCount: 0,
          isFiltering: false,
          totalCandidates: 0,
          filteringError: "something went wrong!",
        })
      );
    }

    dispatch(
      filtersSlice.actions.setPageFilter({
        page: "candidates",
        types: { name: "jobs", value: { selected: [] } },
      })
    );
    dispatch(
      filtersSlice.actions.setPageFilter({
        page: "candidates",
        types: { name: "stages", value: { selected: [] } },
      })
    );
    dispatch(
      filtersSlice.actions.setPageFilter({
        page: "candidates",
        types: { name: "followers", value: { selected: [] } },
      })
    );
    dispatch(
      filtersSlice.actions.setPageFilter({
        page: "candidates",
        types: { name: "search", value: { selected: [] } },
      })
    );
  };

  return (
    <>
      {((Array.isArray(selectedJobs) && selectedJobs.length > 0) ||
        (Array.isArray(selectedStages) && selectedStages.length > 0) ||
        (Array.isArray(selectedFollowers) && selectedFollowers.length > 0) ||
        (Array.isArray(selectedSearches) && selectedSearches.length > 0)) && (
        <View gap={lg} direction="row" align="center">
          <View direction="row" align="center" wrap gap={sm}>
            {Array.isArray(selectedJobs) && selectedJobs.length > 0 && (
              <>
                {selectedJobs.map((job) => {
                  if (!areKeysAvailableIn({ object: job, keys: ["id"] })) return null;
                  return (
                    <Chip
                      key={job.id}
                      title={job.title}
                      closeHandler={handleRemoveJobChip}
                      handlerArgs={{ id: job.id }}
                    />
                  );
                })}
              </>
            )}
            {Array.isArray(selectedStages) && selectedStages.length > 0 && (
              <>
                {selectedStages.map((stage) => {
                  if (!stage || typeof stage !== "string" || stage.length === 0) return null;
                  return (
                    <Chip
                      key={stage}
                      title={t(`${stageOptions[stage]?.title}`)}
                      closeHandler={handleRemoveStageChip}
                      handlerArgs={{ stage }}
                    />
                  );
                })}
              </>
            )}
            {Array.isArray(selectedFollowers) && selectedFollowers.length > 0 && (
              <>
                {selectedFollowers.map((followerId) => {
                  const followerDetails = followerData(followerId);
                  const fullName = getFullName(followerDetails);

                  return (
                    <Chip
                      key={followerId}
                      title={fullName ? fullName : `Follower ${followerId}`}
                      closeHandler={handleRemoveFollowerChip}
                      handlerArgs={{ id: followerId }}
                    />
                  );
                })}
              </>
            )}
            {Array.isArray(selectedSearches) && selectedSearches.length > 0 && (
              <>
                {selectedSearches.map((search) => {
                  if (!search || typeof search !== "string" || search.length === 0) return null;
                  return (
                    <Chip
                      key={search}
                      title={search}
                      closeHandler={handleRemoveSearchChip}
                      handlerArgs={{ search }}
                    />
                  );
                })}
              </>
            )}
          </View>
          <Button
            color="primary"
            variant="outline"
            onClick={handleClearAll}
            attributes={{ "data-cooper": "clear candidates filter" }}
          >
            {t("Clear all")}
          </Button>
        </View>
      )}
    </>
  );
};
