import { useDispatch } from "react-redux";
import { conversationSlice } from "redux/conversation";
import useSocketContent from "components/context/socket/useSocketContext";
import { inProgressConversationsSlice } from "redux/inProgressConversations";
import { getPropsOfErrorResponseCodes, sanitizeMessage } from "utils/pages/candidate";
import { sanitizeArray, areKeysAvailableIn, areKeysAvailableWithType } from "utils/miniHelpers";

const useConversationSubscriptions = () => {
  const { consumer } = useSocketContent();
  const dispatch = useDispatch();

  function onReceiveConversation({ data, candidate_id, userID }) {
    if (
      areKeysAvailableWithType({
        object: data,
        keys: [
          { id: "number" },
          { message: "string" },
          {
            sender: "object",
            callback: (sender) => areKeysAvailableIn({ object: sender, keys: ["id"] }),
          },
          { status: "string" },
          { subject: "string" },
          { updated_at: "string" },
          { date: "object" },
        ],
      })
    ) {
      const { message, id, updated_at, sender, subject, status, date } = data;
      const sanitizedAttachment = sanitizeArray({
        array: data.attachments,
        elementType: "object",
        keys: ["name", "url"],
        noPropertyTypeChecking: true,
      });

      const newMessage = {
        id,
        status,
        message: sanitizeMessage(message),
        subject,
        sender,
        isRecipient: sender.id === candidate_id,
        updated_at,
        attachment: sanitizedAttachment,
        date,
        ...(areKeysAvailableWithType({ object: data, keys: [{ message_id: "string" }] })
          ? { message_id: data.message_id }
          : {}),
        ...getPropsOfErrorResponseCodes(data),
      };

      if (
        areKeysAvailableWithType({
          object: newMessage,
          keys: [{ message: "nexigon", exact: true }],
        })
      )
        return;

      if (areKeysAvailableIn({ object: newMessage, keys: ["message_id"] })) {
        dispatch(
          inProgressConversationsSlice.actions.removeInProgressConversationInterval({
            message_id: newMessage.message_id,
            candidate_id,
          })
        );
      }

      dispatch(
        inProgressConversationsSlice.actions.receiveInProgressConversation({
          candidate_id,
          data: newMessage,
          on: "success",
          userID,
        })
      );
      dispatch(
        conversationSlice.actions.receiveConversation({
          candidate_id,
          data: newMessage,
          on: "success",
        })
      );
    }
  }

  function createConversationSubscription({
    consumer,
    // job_id,
    candidate_id,
    onConnection,
    onReceived,
    onDisconnect,
    userID,
  }) {
    if (!areKeysAvailableIn({ object: consumer, keys: ["subscriptions"] })) {
      throw new Error("Invalid socket consumer!");
    }

    const noOfRetries = 2;
    let result = false;

    for (let i = 0; i < noOfRetries; i++) {
      if (
        !areKeysAvailableIn({ object: result, keys: ["connected", "consumer", "disconnected"] })
      ) {
        result = consumer.subscriptions.create(
          {
            channel: "ConversationChannel",
            // job_id,
            candidate_id,
          },
          {
            connected: () => {
              if (onConnection && typeof onConnection === "function") onConnection();
            },
            received: (data) => {
              if (onReceived && typeof onReceived === "function") {
                onReceived({ data });
              }
              onReceiveConversation({ data, candidate_id, userID });
            },
            disconnected: () => {
              if (onDisconnect && typeof onDisconnect === "function") onDisconnect();
            },
          }
        );
      }
      if (areKeysAvailableIn({ object: result, keys: ["connected", "consumer", "disconnected"] })) {
        break;
      }
    }

    if (areKeysAvailableIn({ object: result, keys: ["connected", "consumer", "disconnected"] })) {
      return result;
    }

    return null;
  }

  function removeConversationSubscription({ candidate_id }) {
    if (!candidate_id || typeof candidate_id !== "string") return;
    const subscription = getConversationSubscription({ candidate_id });
    if (subscription) subscription.unsubscribe();
  }

  function getConversationSubscription({ candidate_id }) {
    if (!candidate_id || typeof candidate_id !== "string") return;
    if (areKeysAvailableIn({ object: consumer, keys: ["subscriptions"] })) {
      let subscription = false;
      const subscriptionList = consumer.subscriptions.subscriptions;
      if (Array.isArray(subscriptionList) && subscriptionList.length > 0) {
        const subscriptionListCount = subscriptionList.length;
        for (let i = 0; i < subscriptionListCount; i++) {
          const each = subscriptionList[i];
          if (each && each.unsubscribe && each.send) {
            if (areKeysAvailableIn({ object: each, keys: ["identifier"] })) {
              const identifier = JSON.parse(each.identifier);
              if (
                areKeysAvailableWithType({
                  object: identifier,
                  keys: [{ candidate_id, exact: true }],
                })
              ) {
                subscription = each;
                break;
              }
            }
          }
        }
      }
      return subscription;
    }
  }

  function sendMessageToServer({ data, candidate_id, message_id, userID }) {
    const subscription = getConversationSubscription({ candidate_id });
    if (!subscription || !subscription.send) return false;

    const RETRY_AFTER = 20; // seconds
    const NO_OF_RETRIES = 2;
    const WAIT_BEFORE_CANCEL = 2; //seconds
    let currentRetry = 0;

    subscription.send(data.request);

    const intervalCode = setInterval(() => {
      if (currentRetry < NO_OF_RETRIES) {
        currentRetry = currentRetry + 1;
        subscription.send(data.request);
      }
    }, RETRY_AFTER * 1000);

    dispatch(
      inProgressConversationsSlice.actions.setInProgressConversationInterval({
        code: intervalCode,
        message_id,
        candidate_id,
      })
    );

    setTimeout(() => {
      clearInterval(intervalCode);
      dispatch(
        inProgressConversationsSlice.actions.removeInProgressConversationInterval({
          message_id,
          candidate_id,
        })
      );
      dispatch(
        inProgressConversationsSlice.actions.receiveInProgressConversation({
          candidate_id,
          data,
          on: "fail",
          userID,
        })
      );
      dispatch(
        conversationSlice.actions.receiveConversation({
          candidate_id,
          data,
          on: "fail",
        })
      );
    }, NO_OF_RETRIES * RETRY_AFTER * 1000 + WAIT_BEFORE_CANCEL * 1000);

    return true;
  }

  return {
    createConversationSubscription,
    getConversationSubscription,
    removeConversationSubscription,
    sendMessageToServer,
  };
};

export default useConversationSubscriptions;
