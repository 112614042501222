import { baseColor } from "utils/colors/accents";

export const Category = ({ color = baseColor.white }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.83341 8.95829H4.16675C2.15008 8.95829 1.04175 7.84996 1.04175 5.83329V4.16663C1.04175 2.14996 2.15008 1.04163 4.16675 1.04163H5.83341C7.85008 1.04163 8.95841 2.14996 8.95841 4.16663V5.83329C8.95841 7.84996 7.85008 8.95829 5.83341 8.95829ZM4.16675 2.29163C2.85008 2.29163 2.29175 2.84996 2.29175 4.16663V5.83329C2.29175 7.14996 2.85008 7.70829 4.16675 7.70829H5.83341C7.15008 7.70829 7.70841 7.14996 7.70841 5.83329V4.16663C7.70841 2.84996 7.15008 2.29163 5.83341 2.29163H4.16675Z"
        fill={color}
      />
      <path
        d="M15.8334 8.95829H14.1667C12.1501 8.95829 11.0417 7.84996 11.0417 5.83329V4.16663C11.0417 2.14996 12.1501 1.04163 14.1667 1.04163H15.8334C17.8501 1.04163 18.9584 2.14996 18.9584 4.16663V5.83329C18.9584 7.84996 17.8501 8.95829 15.8334 8.95829ZM14.1667 2.29163C12.8501 2.29163 12.2917 2.84996 12.2917 4.16663V5.83329C12.2917 7.14996 12.8501 7.70829 14.1667 7.70829H15.8334C17.1501 7.70829 17.7084 7.14996 17.7084 5.83329V4.16663C17.7084 2.84996 17.1501 2.29163 15.8334 2.29163H14.1667Z"
        fill={color}
      />
      <path
        d="M15.8334 18.9583H14.1667C12.1501 18.9583 11.0417 17.85 11.0417 15.8333V14.1666C11.0417 12.15 12.1501 11.0416 14.1667 11.0416H15.8334C17.8501 11.0416 18.9584 12.15 18.9584 14.1666V15.8333C18.9584 17.85 17.8501 18.9583 15.8334 18.9583ZM14.1667 12.2916C12.8501 12.2916 12.2917 12.85 12.2917 14.1666V15.8333C12.2917 17.15 12.8501 17.7083 14.1667 17.7083H15.8334C17.1501 17.7083 17.7084 17.15 17.7084 15.8333V14.1666C17.7084 12.85 17.1501 12.2916 15.8334 12.2916H14.1667Z"
        fill={color}
      />
      <path
        d="M5.83341 18.9583H4.16675C2.15008 18.9583 1.04175 17.85 1.04175 15.8333V14.1666C1.04175 12.15 2.15008 11.0416 4.16675 11.0416H5.83341C7.85008 11.0416 8.95841 12.15 8.95841 14.1666V15.8333C8.95841 17.85 7.85008 18.9583 5.83341 18.9583ZM4.16675 12.2916C2.85008 12.2916 2.29175 12.85 2.29175 14.1666V15.8333C2.29175 17.15 2.85008 17.7083 4.16675 17.7083H5.83341C7.15008 17.7083 7.70841 17.15 7.70841 15.8333V14.1666C7.70841 12.85 7.15008 12.2916 5.83341 12.2916H4.16675Z"
        fill={color}
      />
    </svg>
  );
};
