import { createSlice } from "@reduxjs/toolkit";
import { candidateSlice } from "redux/candidate";
import { updateUserInAPI } from "redux/auth/authSlice";
import { deleteJob, jobSlice } from "redux/job/jobSlice";
import { areKeysAvailableWithType } from "utils/miniHelpers";
import { viewingCandidateSlice } from "redux/viewingCandidate";
import { updateCompanyInAPI } from "redux/company/companySlice";
import { updateOldWithNewObjectValues } from "utils/updateOldWithNewObjectValues";

// here "total" will be with below properties
// {
//   percentage; // profile percentage
//   candidates; // total candidates
//   hired; // total candidates hired
//   jobs; //total jobs
//   weekly_candidates; //total candidates applied in preset week
// }

const initialState = {
  loading: false,
  total: null,
  new_candidates: null,
  error: null,
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setDashboardInfo: (state, action) => {
      const { payload } = action;
      state.loading = false;
      state.total = payload?.total;
      state.new_candidates = payload?.new_candidates;
      state.error = null;
    },
    reset: (state) => {
      for (let key in initialState) {
        state[key] = initialState[key];
      }
    },
    updateCandidate: (state, action) => {
      const { payload } = action;
      if (
        !areKeysAvailableWithType({
          object: payload,
          keys: [{ candidateID: "string" }, { data: "object" }],
        })
      )
        return;

      if (!Array.isArray(state.new_candidates)) return;

      const { candidateID, data } = payload;
      state.new_candidates = state.new_candidates.reduce((acc, each) => {
        if (areKeysAvailableWithType({ object: each, keys: [{ id: candidateID, exact: true }] })) {
          return [
            ...acc,
            {
              ...each,
              ...data,
            },
          ];
        }
        return [...acc, each];
      }, []);
    },
    setLoading: (state) => {
      state.loading = true;
    },
    stopLoading: (state) => {
      state.loading = false;
    },
    setError: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = "Something went wrong!";
      }
    },
    setProperties: (state, action) => {
      if (areKeysAvailableWithType({ object: action, keys: [{ payload: "object" }] })) {
        const { payload } = action;
        for (let k in payload) {
          if (initialState.hasOwnProperty(k)) {
            state[k] = payload[k];
          }
        }
      }
    },
  },
  extraReducers: (builder) => {
    //update respective recruiter in candidate list when current user details updates
    builder.addCase(updateUserInAPI.fulfilled, (state, action) => {
      const { payload } = action;
      if (
        payload?.user &&
        typeof payload.user === "object" &&
        Array.isArray(state.new_candidates)
      ) {
        const user = payload.user;
        state.new_candidates = state.new_candidates.map((candidate) => {
          const newCandidate = { ...candidate };
          if (candidate?.recruiter?.id === user?.id) {
            newCandidate.recruiter = updateOldWithNewObjectValues({
              oldObj: candidate.recruiter,
              newObj: user,
            });
          }
          return newCandidate;
        });
      }
    });

    builder.addCase(viewingCandidateSlice.actions.updateStage, (state, action) => {
      const { payload } = action;
      const { new_candidates, total } = state;
      if (new_candidates && Array.isArray(new_candidates)) {
        const found = new_candidates.find((candidate) => candidate.id === payload.candidate_id);
        if (found) found.stage = payload.stage;
      }
      if (payload?.hired && payload.hired !== 0 && total) {
        const { hired = 0 } = total;
        if (!isNaN(Number(hired))) state.total.hired = state.total.hired + payload.hired;
      }
    });
    builder.addCase(updateCompanyInAPI.fulfilled, (state, action) => {
      const { payload } = action;
      if (payload?.status === 200 && payload.profile_percentage) {
        if (state.total && state.total.percentage)
          state.total.percentage = payload.profile_percentage;
      }
    });
    builder.addCase(viewingCandidateSlice.actions.setEvaluation, (state, action) => {
      const { payload } = action;
      const { new_candidates } = state;
      if (new_candidates && Array.isArray(new_candidates)) {
        const found = new_candidates.find((candidate) => candidate.id === payload.candidate_id);
        if (found) found.average_rating = payload.average_rating;
      }
    });
    builder.addCase(viewingCandidateSlice.actions.updateEvaluation, (state, action) => {
      const { payload } = action;
      const { new_candidates } = state;
      if (new_candidates && Array.isArray(new_candidates)) {
        const found = new_candidates.find((candidate) => candidate.id === payload.candidate_id);
        if (found) found.average_rating = payload.average_rating;
      }
    });
    builder.addCase(viewingCandidateSlice.actions.removeEvaluation, (state, action) => {
      const { payload } = action;
      const { new_candidates } = state;
      if (new_candidates && Array.isArray(new_candidates)) {
        const found = new_candidates.find((candidate) => candidate.id === payload.candidate_id);
        if (found) found.average_rating = payload.average_rating;
      }
    });
    builder.addCase(jobSlice.actions.updateJob, (state, action) => {
      const { total } = state;
      const { payload } = action;
      if (payload?.job && payload?.statusChanged && total) {
        const { jobs = 0 } = total;
        if (!isNaN(Number(jobs))) state.total.jobs = state.total.jobs + payload?.point;
      }

      if (typeof payload.job !== "object" || !Array.isArray(state.new_candidates)) return;
      const { job } = payload;
      state.new_candidates = state.new_candidates.map((candidate) => {
        const newCandidate = { ...candidate };
        if (candidate?.recruiter?.job?.id === job?.id) {
          newCandidate.recruiter.job = updateOldWithNewObjectValues({
            oldObj: candidate.recruiter.job,
            newObj: job,
          });
        }
        return newCandidate;
      });
    });
    builder.addCase(deleteJob.fulfilled, (state, action) => {
      const { total } = state;
      const { payload } = action;
      if (payload.id && payload.status && payload.status === "published" && total) {
        const { jobs = 0 } = total;
        if (!isNaN(Number(jobs))) state.total.jobs = state.total.jobs - 1;
      }
    });
    builder.addCase(candidateSlice.actions.addNewCandidate, (state, action) => {
      const { payload } = action;
      const { total, new_candidates } = state;
      if (payload.id && total) {
        const { candidates = 0, weekly_candidates } = total;
        if (!isNaN(Number(candidates))) {
          state.total.candidates = state.total.candidates + 1;
        }
        if (!isNaN(Number(weekly_candidates))) {
          state.total.weekly_candidates = state.total.weekly_candidates + 1;
        }
      }
      if (payload.id && new_candidates && Array.isArray(new_candidates)) {
        state.new_candidates.unshift(payload);
      }
    });
  },
});
