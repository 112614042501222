import { useState, useCallback } from "react";
import { Button, FormControl, TextField, View } from "reshaped";

import axios from "axios";
import { metaSlice } from "redux/meta";
import { useCookies } from "react-cookie";
import { userAuth } from "utils/constants";
import { ATS_BACKEND_HOST } from "constants";
import useSlices from "components/hooks/useSlices";
import { validateErrors } from "utils/validateError";
import { areKeysAvailableIn } from "utils/miniHelpers";
import { useDispatch, useSelector } from "react-redux";
import useTranslation from "components/hooks/useTranslation";
import { isEmailRestricted } from "utils/checkRestrictionEmail";
import { EMAILVALIDATIONREGEX } from "utils/RegularExpressions";

export const PasswordEmail = (props) => {
  const { email, setEmail, setForgetPasswordStage } = props;

  const [error, setError] = useState({
    email: {},
  });

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { resetAll } = useSlices();
  const { isLoading } = useSelector((state) => state.meta);
  const { isAuthenticated } = useSelector((state) => state.auth);

  //eslint-disable-next-line
  const [cookies, _, removeCookie] = useCookies([userAuth]);

  const validate = useCallback((errorName, name, handleError, regex) => {
    validateErrors(errorName, name, handleError, t, regex);

    //eslint-disable-next-line
  }, []);

  const handleEmail = ({ value }) => {
    if (error.email) {
      setError((previousError) => {
        return {
          ...previousError,
          email: null,
        };
      });
    }
    setEmail(value);
  };

  const handleEmailValidation = (e) => {
    validate("email", email, setError, EMAILVALIDATIONREGEX);
  };

  const sendEmail = async (email) => {
    try {
      dispatch(metaSlice.actions.initialLoading());
      const user = { email };
      const response = await axios.post(`${ATS_BACKEND_HOST}/users/password`, { user });
      dispatch(metaSlice.actions.stopLoading());
      if (response?.data) {
        const { data } = response;
        if (areKeysAvailableIn({ object: data, keys: ["status"] })) {
          if (data.status === 200 && data.message) {
            if (isAuthenticated) resetAll();
            if (cookies[userAuth]) removeCookie(userAuth);
            setForgetPasswordStage("sent");
            return;
          }
          if (data.status === 422 && data.errors) {
            return setError({ email: t(data.errors) });
          }
        }
      }
      return setError({ email: t("Something went wrong") });
    } catch (err) {
      dispatch(metaSlice.actions.stopLoading());
      const { response } = err;
      if (response?.status === 422 && response?.data && response?.data?.errors) {
        const { errors } = response.data;
        if (errors && errors?.email && Array.isArray(errors.email)) {
          return setError({ email: t(errors.email[0]) });
        }
        if (errors && typeof errors === "string") {
          return setError({ email: t(errors) });
        }
      }
      return setError({ email: t("Something went wrong") });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let hasError = false;

    // if user submitting with empty or invalid fields
    for (let field in error) {
      if (error[field]) {
        hasError = true;
        if (typeof error[field] === "object") {
          setError((previousError) => {
            return {
              ...previousError,
              [field]: t(`${field.split(/(?=[A-Z])/).join(" ")} is required`),
            };
          });
        }
      }
    }

    if (hasError) return;

    // Not allowing restricted emails
    if (isEmailRestricted(email)) {
      setError((previousError) => {
        return {
          ...previousError,
          email: t("Email is restricted"),
        };
      });
      return;
    }
    await sendEmail(email);
  };

  return (
    <View gap={4}>
      <FormControl required hasError={error["email"] && typeof error["email"] !== "object"}>
        <FormControl.Label>{t("Email")}</FormControl.Label>
        <TextField
          onChange={handleEmail}
          inputAttributes={{ type: "email", onBlur: handleEmailValidation, autoFocus: true }}
          value={email}
        />
        <FormControl.Error>{error["email"]}</FormControl.Error>
      </FormControl>
      <Button
        onClick={handleSubmit}
        loading={isLoading}
        fullWidth
        attributes={{ type: "submit" }}
        color="primary"
      >
        {t("Send email")}
      </Button>
    </View>
  );
};
