import { backendAPI } from "utils/axios";
import { reloadOnUnauthorized } from "utils/checkForAuth";
import {
  isNumber,
  sanitizeArray,
  areKeysAvailableIn,
  areKeysAvailableWithType,
} from "utils/miniHelpers";

export async function getMinimalTemplatesFromAPI() {
  const errorResult = {
    error: "Unable to retrieve message templates at this time. Please try again later",
  };
  try {
    const response = await backendAPI.get("/conversation_templates/minimal");
    if (areKeysAvailableWithType({ object: response, keys: [{ data: "array" }] })) {
      const sanitizedTemplates = sanitizeArray({
        array: response.data,
        elementType: "object",
        keys: ["id", "name"],
      });
      return {
        data: sanitizedTemplates,
      };
    }
    return errorResult;
  } catch (error) {
    reloadOnUnauthorized(error);
    return errorResult;
  }
}

export async function getMinimalTemplateFromAPI({ id }) {
  const errorResult = {
    error: "Failed to get message template.",
  };
  try {
    if (!isNumber(id)) return errorResult;
    const response = await backendAPI.get(`/conversation_templates/${id}`);
    if (
      areKeysAvailableWithType({
        object: response.data,
        keys: [
          {
            conversation_template: "object",
            callback: (value) =>
              areKeysAvailableIn({ object: value, keys: ["id", "name", "message", "subject"] }),
          },
        ],
      })
    ) {
      return {
        data: response.data.conversation_template,
      };
    }
    return errorResult;
  } catch (error) {
    reloadOnUnauthorized(error);
    return errorResult;
  }
}
