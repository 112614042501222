import PropTypes from "prop-types";
import { getProgressText } from "utils/TextHelper";
import styles from "components/styles/reshaped/dashboard.module.css";

export const ProgressCircle = ({
  completed,
  size = 120,
  filledStrokeColor = "var(--rs-color-background-primary)",
  emptyStrokeColor = "var(--rs-color-background-primary-faded)",
}) => {
  return (
    <svg
      height={size}
      width={size}
      viewBox="-2 -2 100 100"
      strokeDasharray={`${(completed / 100) * 276} 276.46015351590177`}
      className={styles.progress__svg}
    >
      <path
        d="M48 4 a 44 44 0 0 1 0 88 a 44 44 0 0 1 0 -88"
        className={styles.path__empty}
        style={{
          stroke: emptyStrokeColor,
        }}
      />
      <path
        d="M48 4 a 44 44 0 0 1 0 88 a 44 44 0 0 1 0 -88"
        className={styles.path__filled}
        style={{
          stroke: filledStrokeColor,
        }}
      />
      <text
        x="48"
        y="48"
        letterSpacing="0.5"
        dy="0rem"
        dx="0.3rem"
        className={styles.text__percentage}
      >
        {completed}%
      </text>
      <text x="48" y="65" letterSpacing="1" className={styles.text__stage}>
        {getProgressText(completed)}
      </text>
    </svg>
  );
};

ProgressCircle.propTypes = {
  completed: PropTypes.number,
  size: PropTypes.number,
  filledStrokeColor: PropTypes.string,
  emptyStrokeColor: PropTypes.string,
};
