import { useEffect, useMemo } from "react";
import { View, Text } from "reshaped";

import { useSelector } from "react-redux";
import useTranslation from "components/hooks/useTranslation";
import styles from "components/styles/reshaped/jobs/new.module.css";
import NewJobStepProgress from "components/pages/jobs/newJob/NewJobStepProgress";
import {
  updateProgressWithOldState,
  updateProgressWithOldStateSalaries,
} from "utils/updateJobHelpers";

const NewJobSteps = (props) => {
  const { stepOneProgress, setStepOneProgress, oldState } = props;

  const { t } = useTranslation();
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (oldState) {
      updateProgressWithOldState({
        oldState,
        setProgressState: setStepOneProgress,
      });
      updateProgressWithOldStateSalaries({
        oldState,
        setProgressState: setStepOneProgress,
      });
    }

    //eslint-disable-next-line
  }, [oldState]);

  // return (
  //   <>
  //     <View.Item columns="5" className={styles.wrapper__child__left}>
  //       <View gap={6} className={styles.progress__layout}>
  //         <Text variant="title-2">Add your job in three simple steps</Text>
  //         {currentStep === 1 ? (
  //           <View gap={1}>
  //             <View direction="row" gap={4}>
  //               <Text variant="title-3">1</Text>
  //               <Text variant="title-3">Job Details</Text>
  //             </View>
  //             <NewJobStepProgress stepProgress={stepOneProgress} />
  //           </View>
  //         ) : (
  //           <View direction="row" gap={4}>
  //             <Text variant="body-1" color="neutral-faded">
  //               1
  //             </Text>
  //             <Text variant="body-1" color="neutral-faded">
  //               Job Details
  //             </Text>
  //           </View>
  //         )}
  //         {currentStep === 2 ? (
  //           <View direction="row" gap={4}>
  //             <Text variant="body-1" color="neutral-faded">
  //               2
  //             </Text>
  //             <Text variant="body-1" color="neutral-faded">
  //               Custom Application Form (coming soon!)
  //             </Text>
  //           </View>
  //         ) : (
  //           <View direction="row" gap={4}>
  //             <Text variant="body-1" color="neutral-faded">
  //               2
  //             </Text>
  //             <Text variant="body-1" color="neutral-faded">
  //               Custom Application Form (coming soon!)
  //             </Text>
  //           </View>
  //         )}
  //         {currentStep === 1 ? (
  //           <View direction="row" gap={4}>
  //             <Text variant="body-1" color="neutral-faded">
  //               3
  //             </Text>
  //             <Text variant="body-1" color="neutral-faded">
  //               Job Promotion (coming soon!)
  //             </Text>
  //           </View>
  //         ) : (
  //           <View direction="row" gap={4}>
  //             <Text variant="body-1" color="neutral-faded">
  //               3
  //             </Text>
  //             <Text variant="body-1" color="neutral-faded">
  //               Job Promotion (coming soon!)
  //             </Text>
  //           </View>
  //         )}
  //       </View>
  //     </View.Item>
  //   </>
  // );

  const translations = useMemo(() => {
    return {
      "Add your job in a few simple steps": t("Add your job in a few simple steps"),
      "Job Details": t("Job Details"),
    };

    //eslint-disable-next-line
  }, [auth && auth?.user ? [auth.user] : []]);

  return (
    <>
      <View.Item columns="5" className={styles.wrapper__child__left}>
        <View gap={6} className={styles.progress__layout}>
          <Text variant="title-2" attributes={{ "data-cooper": "new job page text" }}>
            {translations["Add your job in a few simple steps"]}
          </Text>
          <View gap={1}>
            <View direction="row" gap={4}>
              <Text variant="title-3">{translations["Job Details"]}</Text>
            </View>
            <NewJobStepProgress stepProgress={stepOneProgress} />
          </View>
        </View>
      </View.Item>
    </>
  );
};

export default NewJobSteps;
