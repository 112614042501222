export function areKeysAvailableIn({ object, keys }) {
  if (!Array.isArray(keys)) {
    console.error("Invalid arguments in 'areKeysAvailable' function");
    return false;
  }

  if (!object || typeof object !== "object") return false;

  for (let k of keys) {
    if (!(k in object)) {
      return false;
    }
  }

  return true;
}

export function areKeysAvailableWithType({ object, keys, resultShouldBeInBoolean = true }) {
  const excludingProperties = {
    exact: "exact",
    callback: "callback",
  };
  function callbackExecuter({ eachKey, value }) {
    if (!("callback" in eachKey)) return true;
    if (!eachKey || typeof eachKey !== "object") return false;
    const cb = eachKey["callback"];
    if (typeof cb !== "function") return false;
    const result = cb(value);
    return typeof result === "boolean" ? result : false;
  }

  if (!resultShouldBeInBoolean) {
    if (!Array.isArray(keys)) {
      console.error("Invalid arguments in 'areKeysAvailableWithType' function");
      return { areValidKeys: false };
    }

    if (!object || typeof object !== "object") return { isValidObject: false };

    for (let k of keys) {
      if (k && typeof k === "object") {
        let key;
        let value;
        for (let KEY in k) {
          if (!excludingProperties[KEY]) {
            key = KEY;
            value = k[KEY];
            break;
          }
        }
        if (!(key in object)) return { [key]: "false" };
        if ("exact" in k) {
          if (k.exact) {
            if (object[key] === value && callbackExecuter({ eachKey: k, value: object[key] })) {
              continue;
            } else {
              return { [key]: "true" };
            }
          }
        }

        if (
          value === "array" &&
          Array.isArray(object[key]) &&
          callbackExecuter({ eachKey: k, value: object[key] })
        ) {
          continue;
        } else if (
          value === "object" &&
          typeof object[key] === value &&
          callbackExecuter({ eachKey: k, value: object[key] })
        ) {
          continue;
        } else if (
          value !== "object" &&
          value !== "array" &&
          typeof object[key] === value &&
          callbackExecuter({ eachKey: k, value: object[key] })
        ) {
          continue;
        } else {
          return { [key]: "true" };
        }
      }

      if (!(k in object)) {
        return { [k]: "false" };
      }
    }

    return { isValidObject: true };
  }
  if (!Array.isArray(keys)) {
    console.error("Invalid arguments in 'checkKeyIn' function");
    return false;
  }

  if (!object || typeof object !== "object") return false;

  for (let k of keys) {
    if (k && typeof k === "object") {
      let key;
      let value;
      for (let KEY in k) {
        if (!excludingProperties[KEY]) {
          key = KEY;
          value = k[KEY];
          break;
        }
      }
      if (!(key in object)) return false;
      if ("exact" in k) {
        if (k.exact) {
          if (object[key] === value && callbackExecuter({ eachKey: k, value: object[key] })) {
            continue;
          } else {
            return false;
          }
        }
      }
      if (
        value === "array" &&
        Array.isArray(object[key]) &&
        callbackExecuter({ eachKey: k, value: object[key] })
      ) {
        continue;
      } else if (
        value === "object" &&
        typeof object[key] === value &&
        callbackExecuter({ eachKey: k, value: object[key] })
      ) {
        continue;
      } else if (
        value !== "object" &&
        value !== "array" &&
        typeof object[key] === value &&
        callbackExecuter({ eachKey: k, value: object[key] })
      ) {
        continue;
      } else {
        return false;
      }
    }

    if (!(k in object)) {
      return false;
    }
  }

  return true;
}

export function isArrayWithElementsOfType({
  array,
  elementType,
  keys,
  noPropertyTypeChecking = false,
}) {
  if (!array || !Array.isArray(array) || !elementType || typeof elementType !== "string")
    return false;
  const length = array.length;
  if (typeof elementType === "string" && !keys) {
    if (elementType !== "array") {
      for (let i = 0; i < length; i++) {
        if (typeof array[i] !== elementType) return false;
      }
    }
    if (elementType === "array") {
      for (let i = 0; i < length; i++) {
        if (!Array.isArray(array[i])) return false;
      }
    }
  }

  if (elementType === "object" && Array.isArray(keys)) {
    for (let i = 0; i < length; i++) {
      const checkingResult = noPropertyTypeChecking
        ? areKeysAvailableIn({ object: array[i], keys })
        : areKeysAvailableWithType({ object: array[i], keys });
      if (!checkingResult) return false;
    }
  }
  return true;
}

export function sanitizeArray({
  array,
  elementType,
  keys,
  hardSanitization = false,
  noPropertyTypeChecking = false,
  emptyResultValue = [],
  outputElementStructureCallback,
}) {
  function callback() {
    if (outputElementStructureCallback && typeof outputElementStructureCallback === "function") {
      return (value) => outputElementStructureCallback(value);
    }
    return (value) => value;
  }

  const callbackExecuter = callback();

  if (!array || !Array.isArray(array) || !elementType || typeof elementType !== "string")
    return emptyResultValue;
  const length = array.length;
  const newArray = [];
  if (typeof elementType === "string" && !keys) {
    if (elementType !== "array") {
      for (let i = 0; i < length; i++) {
        if (typeof array[i] !== elementType) continue;
        newArray.push(callbackExecuter(array[i]));
      }
    }
    if (elementType === "array") {
      for (let i = 0; i < length; i++) {
        if (!Array.isArray(array[i])) continue;
        newArray.push(callbackExecuter(array[i]));
      }
    }
    return newArray.length > 0 ? newArray : emptyResultValue;
  }

  if (elementType === "object" && Array.isArray(keys)) {
    if (hardSanitization) {
      for (let i = 0; i < length; i++) {
        const checkingResult = noPropertyTypeChecking
          ? areKeysAvailableIn({ object: array[i], keys })
          : areKeysAvailableWithType({ object: array[i], keys });
        if (!checkingResult) continue;
        const newElement = {};
        for (let k of keys) {
          if (k && typeof k === "object") {
            const properties = Object.entries(k);
            const key = properties[0][0];
            newElement[key] = array[i][key];
          }
          if (k && typeof k === "string") {
            newElement[k] = array[i][k];
          }
        }
        newArray.push(callbackExecuter(newElement));
      }
    } else {
      for (let i = 0; i < length; i++) {
        const checkingResult = noPropertyTypeChecking
          ? areKeysAvailableIn({ object: array[i], keys })
          : areKeysAvailableWithType({ object: array[i], keys });
        if (!checkingResult) continue;
        newArray.push(callbackExecuter(array[i]));
      }
    }
    return newArray;
  }
  return newArray;
}

export function getValueFrom({ object, key, defaultKey, defaultValue }) {
  if (!defaultValue) throw new Error("No defaultValue");
  if (!object || typeof object !== "object" || !key) return defaultValue;
  if (key in object) return object[key];
  if (defaultKey in object) return object[defaultKey];
  return defaultValue;
}

export function isObject(obj) {
  return (
    obj && typeof obj === "object" && Object.prototype.toString.call(obj) === "[object Object]"
  );
}

export function isNumber(value) {
  if (value || value === 0) {
    const sanitizedNumber = Number(value);
    return !isNaN(sanitizedNumber) && isFinite(sanitizedNumber);
  }

  return false;
}

export function wait({ time = 3000, callback }) {
  return new Promise((resolve) => {
    setTimeout(() => {
      if (callback && typeof callback === "function") {
        resolve(callback());
      } else {
        resolve();
      }
    }, time);
  });
}

export function isOnlyString(text) {
  if (typeof text === "string") return true;
  return false;
}

export function isString(text) {
  if (text && typeof text === "string") return true;
  return false;
}

export function getIdFromDate() {
  return new Date().getTime().toString(32);
}
