import React, { useRef, useMemo } from "react";
import { FormControl, View, TextField } from "reshaped";
import { NOEMOJISREGEX } from "utils/RegularExpressions";
import useTranslation from "components/hooks/useTranslation";
import { stepOneProgressHelper } from "utils/jobStepProgress";

const NewJobInput = React.forwardRef((props, ref) => {
  const {
    title,
    errors,
    propTitle,
    setErrors,
    setInputValue,
    inputValue = "",
    placeholder = "",
    noEmojis = false,
    inputType = "text",
    isRequired = false,
    inputAttributes = {},
    setStepOneProgress,
  } = props;

  const calenderRef = useRef(null);
  const { t } = useTranslation();

  const id = useMemo(
    () => propTitle.toLowerCase(),

    //eslint-disable-next-line
    []
  );

  const translations = useMemo(() => {
    return {
      "Please select valid Date": t("Please select valid Date"),
      "Please select a valid date": t("Please select a valid date"),
    };

    //eslint-disable-next-line
  }, []);

  const handleChange = ({ value }) => {
    let sanitizedInputValue = value;

    if (noEmojis) {
      sanitizedInputValue = sanitizedInputValue.replace(NOEMOJISREGEX, "");
    }

    if (isRequired && errors[id]) {
      setErrors((previousError) => {
        return {
          ...previousError,
          [id]: null,
        };
      });
    }
    if (!isRequired && errors[id]) {
      setErrors((previousError) => {
        const prev = { ...previousError };
        delete prev[id];
        return {
          ...prev,
        };
      });
    }
    setInputValue(sanitizedInputValue);
    // setter({ type: propTitle, payload: value });
  };

  const validateHandler = () => {
    if (!isRequired) return;
    let value = inputValue.replace(/^\s+|\s+$/g, "");
    const hasError = props.handleValidation({ id, value });
    if (!hasError) {
      stepOneProgressHelper(propTitle, setStepOneProgress, "add");
    }
    if (hasError) {
      //removing error input if it has one
      stepOneProgressHelper(propTitle, setStepOneProgress, "remove");
    }
  };

  const handleCalender = () => {
    calenderRef.current.showPicker();
  };

  const handleCalenderValidation = () => {
    const selectedDate = new Date(inputValue);
    const currentDate = new Date();
    if (inputValue === "" || !inputValue) {
      //removing error input if it has one
      stepOneProgressHelper(propTitle, setStepOneProgress, "remove");
    }
    if (inputValue) {
      if (selectedDate < currentDate) {
        setErrors((previousError) => {
          return {
            ...previousError,
            [id]: translations["Please select a valid date"],
          };
        });
        //removing error input if it has one
        stepOneProgressHelper(propTitle, setStepOneProgress, "remove");
      } else {
        stepOneProgressHelper(propTitle, setStepOneProgress, "add");
      }
    }
  };

  return (
    <View.Item grow={true}>
      <FormControl
        required={isRequired}
        hasError={
          (isRequired && errors[id] && typeof errors[id] !== "object") ||
          (!isRequired && errors[id] && typeof errors[id] !== "object")
            ? true
            : false
        }
      >
        <FormControl.Label>{title}</FormControl.Label>
        {inputType !== "date" && (
          <TextField
            value={inputValue}
            onChange={handleChange}
            inputAttributes={{
              type: inputType,
              onBlur: validateHandler,
              "data-cooper": `jobs ${propTitle}`,
              ...inputAttributes,
            }}
            id={propTitle}
            placeholder={placeholder}
          />
        )}
        {inputType === "date" && (
          <TextField
            value={inputValue}
            onChange={handleChange}
            inputAttributes={{
              type: inputType,
              onBlur: handleCalenderValidation,
              onClick: handleCalender,
              ref: calenderRef,
            }}
            id={propTitle}
          />
        )}
        <FormControl.Error>{errors[id]}</FormControl.Error>
      </FormControl>
    </View.Item>
  );
});

export default React.memo(NewJobInput);
