import { backendAPI } from "utils/axios";
import { candidateSlice } from "redux/candidate";
import { areKeysAvailableIn } from "utils/miniHelpers";
import { updateUserInAPI } from "redux/auth/authSlice";
import { reloadOnUnauthorized } from "utils/checkForAuth";
import { ShowToastWithTranslation } from "utils/showToast";
import { updateCompanyInAPI } from "redux/company/companySlice";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { URLCopiedWithTranslation } from "components/reusables/URLCopied";
import { OperationFailedWithTranslation } from "components/reusables/OperationFailed";

const initialState = {
  loading: false,
  jobs: null,
  errors: null,
  APIError: null,
  mini_jobs: null,
  mini_error: null,
  isFiltering: false,
  filteringError: null,
  shouldJobsRefetch: false,
};

export const createJob = createAsyncThunk("jobs/create", async (job) => {
  try {
    const response = await backendAPI.post("/jobs", { job });
    if (response && response.status === 200) {
      const { data } = response;
      if (data.errors) {
        const error = {};
        for (let key in data.errors) {
          if (key === "user" || key === "company") continue;
          error[key] = data.errors[key][0];
        }
        return { status: 200, error };
      }
      if (data.job) {
        return { status: 200, job: data.job };
      }
    }
    return { status: 400, error: "Something went wrong" };
  } catch (error) {
    reloadOnUnauthorized(error);
    const { response } = error;
    if (response && response.data.status === 400) {
      return { status: 400, error: response.data.error || "Something went wrong" };
    }
    return { status: 400, error: "Something went wrong" };
  }
});

export const fetchJobData = createAsyncThunk("jobs/fetch", async () => {
  try {
    const response = await backendAPI.get("/jobs");
    if (response.status === 200 && response.data) {
      return { data: response.data };
    }
    return {
      error: "Something went wrong",
    };
  } catch (error) {
    reloadOnUnauthorized(error);
    return {
      error: "Something went wrong",
    };
  }
});

export const fetchMiniJobData = createAsyncThunk("mini_jobs/fetch", async () => {
  try {
    const response = await backendAPI.get("/minor_jobs");
    if (response.status === 200 && response.data && Array.isArray(response.data)) {
      return { data: response.data };
    }
    return {
      error: "Jobs fetching failed!",
    };
  } catch (error) {
    reloadOnUnauthorized(error);
    return {
      error: "Jobs fetching failed!",
    };
  }
});

export const deleteJob = createAsyncThunk("jobs/delete", async ({ id, status, toast, t }) => {
  try {
    const response = await backendAPI.delete(`/jobs/${id}`);
    if (response.data && response.data.message && response.data.status === 200) {
      ShowToastWithTranslation({
        toast,
        Children: URLCopiedWithTranslation,
        text: t("Job deleted!"),
      });
      return { id, status };
    }
    ShowToastWithTranslation({
      toast,
      Children: OperationFailedWithTranslation,
      text: t("Operation unsuccessful!"),
    });
    return {
      error: "Something went wrong",
    };
  } catch (error) {
    reloadOnUnauthorized(error);
    if (error.response && error.response.data && error.response.data.error) {
      ShowToastWithTranslation({
        toast,
        Children: OperationFailedWithTranslation,
        text: t(error.response.data.error),
      });
    } else {
      ShowToastWithTranslation({
        toast,
        Children: OperationFailedWithTranslation,
        text: t("Operation unsuccessful!"),
      });
    }
    return {
      error: "Something went wrong",
    };
  }
});

export const jobSlice = createSlice({
  name: "jobs",
  initialState,
  reducers: {
    reset: (state) => {
      for (let key in initialState) {
        state[key] = initialState[key];
      }
    },
    set: (state, actions) => {
      const { payload } = actions;
      if (!payload || typeof payload !== "object") return;
      Object.keys(payload).forEach((k) => {
        if (k in initialState) state[k] = payload[k];
      });
    },
    startLoading: (state) => {
      state.loading = true;
    },
    stopLoading: (state) => {
      state.loading = false;
    },
    setError: (state, action) => {
      state.loading = false;
      state.errors = action.payload;
    },
    setAPIError: (state, action) => {
      state.loading = false;
      state.APIError = action.payload;
    },
    setJob: (state, action) => {
      state.loading = false;
      const job = action.payload;
      state.jobs.unshift({ ...job, total_candidates: 0 });
      const newMiniJob = { id: job?.id, title: job?.title, city: job?.city, status: job?.status };
      if (state.mini_jobs && Array.isArray(state.mini_jobs)) {
        state.mini_jobs.unshift(newMiniJob);
      }
    },
    updateJob: (state, action) => {
      state.loading = false;
      const { jobs } = state;
      const { payload } = action;
      if (Array.isArray(jobs)) {
        state.jobs = jobs.map((job) => {
          if (job.id === payload?.job?.id) {
            return { ...job, ...payload?.job };
          }
          return job;
        });
      } else {
        state.jobs = [payload?.job];
      }

      // Refetch mini jobs on job status change
      if (Array.isArray(state.mini_jobs)) {
        const currentStatus = payload?.job?.status;
        if (
          currentStatus === "archived" ||
          (payload?.previousJobStatus === "archived" && currentStatus !== "archived")
        )
          state.mini_jobs = null;
      }
    },
  },
  extraReducers: (builder) => {
    // refetch jobs on company info update
    builder.addCase(updateCompanyInAPI.fulfilled, (state, action) => {
      const { payload } = action;
      if (
        payload &&
        areKeysAvailableIn({ object: payload, keys: ["isSlugUpdated", "isCountryUpdated"] })
      ) {
        const { isSlugUpdated } = payload;
        if (isSlugUpdated) state.jobs = null;
      }
    });

    //update user in job card when user update details in settings
    builder.addCase(updateUserInAPI.fulfilled, (state, action) => {
      const { payload } = action;
      if (payload?.user && typeof payload.user === "object" && Array.isArray(state.jobs)) {
        const user = payload.user;
        const foundJob = state.jobs.find((job) => job?.user?.id === user?.id);
        if (foundJob && typeof foundJob === "object" && typeof foundJob.user === "object") {
          Object.keys(foundJob.user).forEach((k) => {
            foundJob["user"][k] = user[k] ?? foundJob["user"][k];
          });
        }
      }
    });

    builder.addCase(fetchJobData.pending, (state) => {
      state.loading = true;
      state.shouldJobsRefetch = false;
    });
    builder.addCase(fetchJobData.fulfilled, (state, action) => {
      state.loading = false;
      state.errors = null;
      const { payload } = action;
      if (payload.data) {
        state.jobs = payload.data;
      }
      if (payload.error) {
        state.errors = payload.error;
      }
    });
    builder.addCase(fetchJobData.rejected, (state, action) => {
      state.loading = false;
      state.jobs = null;
      state.errors = action.payload.error;
    });

    builder.addCase(fetchMiniJobData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchMiniJobData.fulfilled, (state, action) => {
      state.loading = false;
      state.mini_error = null;
      const { payload } = action;
      if (payload.data) {
        state.mini_jobs = payload.data;
      }
      if (payload.error) {
        state.mini_error = payload.error;
      }
    });
    builder.addCase(fetchMiniJobData.rejected, (state, action) => {
      state.loading = false;
      state.mini_jobs = null;
      state.mini_error = action.payload.error;
    });

    builder.addCase(deleteJob.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteJob.fulfilled, (state, action) => {
      state.loading = false;
      state.errors = null;
      const { payload } = action;
      if (payload.id) {
        state.jobs = state.jobs.filter((job) => job.id !== payload.id);
        if (state.mini_jobs && Array.isArray(state.mini_jobs)) {
          state.mini_jobs = state.mini_jobs.filter((job) => job.id !== payload.id);
        }
      }
      if (payload.error) {
        state.APIError = payload.error;
      }
    });
    builder.addCase(deleteJob.rejected, (state, action) => {
      state.loading = false;
      state.API = action.payload.error;
    });

    builder.addCase(candidateSlice.actions.addNewCandidate, (state, action) => {
      const { payload } = action;
      const { jobs } = state;
      if (jobs && Array.isArray(jobs) && payload?.job_id) {
        const found = jobs.find((job) => job.id === payload.job_id);
        if (found && typeof found === "object" && "total_candidates" in found) {
          found.total_candidates = found.total_candidates + 1;
        }
      }
    });
  },
});

