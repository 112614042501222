import { useMemo } from "react";
import { Avatar, Link, View } from "reshaped";

import { LinkPin } from "icons";
import useName from "components/hooks/useName";
import useSpacing from "components/hooks/useSpacing";
import { TextWithEclipses } from "components/reusables";
import useTranslation from "components/hooks/useTranslation";
import { getFullFormattedDate } from "utils/getFormattedDate";
import styles from "components/styles/reshaped/bookings/bookings.module.css";

export const ActivePastRow = ({ booking = {} }) => {
  const { job_board_name, job_title, job_post_url, activated_on, duration } = booking;

  const { t } = useTranslation();
  const { sm, md, xl } = useSpacing();
  const jobBoardProfile = useName({ info: booking, first_name: job_board_name });

  const fullDate = useMemo(() => {
    if (activated_on === "Pending") return { space: false, text: "Pending" };
    return getFullFormattedDate(activated_on);

    //eslint-disable-next-line
  }, []);

  const slotDuration = useMemo(() => {
    const common = {
      isExpired: false,
      textColor: "neutral",
    };

    if (duration && typeof duration === "object" && "expired" in duration) {
      const expired = duration?.expired;

      if (typeof expired === "boolean" && duration.expired) {
        return {
          text: t("Expired"),
          textColor: "critical",
          isExpired: true,
        };
      }

      if (typeof expired === "string") {
        return {
          text: t(expired === "expiring today" ? "Expiring today" : expired),
          ...common,
        };
      }
    }

    if (typeof duration === "number") {
      return {
        text: `${duration} ${duration === 1 ? t("day left") : t("days left")}`,
        ...common,
      };
    }

    if (duration === null || Object.is(duration, null)) {
      return {
        text: ``,
        ...common,
      };
    }

    return {
      text: t(`No date`),
      ...common,
    };

    //eslint-disable-next-line
  }, [booking]);

  return (
    <View
      height="58px"
      className={styles.inventory__row}
      attributes={{
        style: {
          backgroundColor: "var(--rs-color-background-neutral-faded)",
        },
      }}
    >
      <View className={`${styles.inventory__column} ${styles["grow-2"]}`}>
        <View direction="row" align="center" justify="start" gap={sm}>
          {jobBoardProfile.hasURL && (
            <Avatar size={xl} src={jobBoardProfile.url} alt={job_board_name} />
          )}
          {!jobBoardProfile.hasURL && (
            <Avatar size={xl} initials={jobBoardProfile.letters} alt={job_board_name} />
          )}
          <TextWithEclipses
            variant="body-strong-2"
            text={`${job_board_name}`}
            characterLimit={20}
          />
        </View>
      </View>
      <View className={`${styles.inventory__column} ${styles["grow-3"]}`}>
        <TextWithEclipses variant="body-2" text={`${job_title}`} characterLimit={55} />
      </View>
      <View className={styles.inventory__column}>
        <TextWithEclipses variant="body-2" text={fullDate.text} characterLimit={20} />
      </View>
      <View className={styles.inventory__column}>
        <TextWithEclipses
          variant="body-2"
          color={slotDuration.textColor}
          text={slotDuration.text}
          characterLimit={20}
        />
      </View>
      {/* <View className={styles.inventory__column}>
        <Text align="center" variant="body-2">
          {clicks}
        </Text>
      </View> */}
      {/* <View className={styles.inventory__column}>
        <Text align="center" variant="body-2">
          {candidates}
        </Text>
      </View> */}
      <View className={styles.inventory__column}>
        <Link
          href={job_post_url && typeof job_post_url === "string" ? job_post_url : "/bookings"}
          attributes={{
            style: {
              textDecoration: "none",
            },
            target: "_blank",
          }}
          disabled={!job_post_url || typeof job_post_url !== "string" || slotDuration.isExpired}
        >
          <View direction="row" align="center" justify="start" gap={md}>
            {t("View link")}
            <View
              attributes={{
                style: {
                  marginBlockStart: "3px",
                },
              }}
            >
              <LinkPin
                fill={job_post_url && typeof job_post_url === "string" ? "#1F74EA" : "#D1D1D1"}
              />
            </View>
          </View>
        </Link>
      </View>
    </View>
  );
};
