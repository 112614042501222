import { useContext, useMemo } from "react";
import { SpacingContext } from "contexts/spacing/SpacingContext";

const useSpacing = () => {
  const { spacing: space } = useContext(SpacingContext);

  const spacing = useMemo(() => {
    return space;

    //eslint-disable-next-line
  }, []);

  return {
    ...spacing,
  };
};

export default useSpacing;
