import { useEffect } from "react";
import { Text, View } from "reshaped";

import useSpacing from "components/hooks/useSpacing";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import useTranslation from "components/hooks/useTranslation";
import { WrapperWithNav, ReshapedLoader } from "components/reusables";
import { BoardInfo, SlotSection } from "components/pages/promote/slot";
import { fetchSlotsWithJobBoardID, slotsSlice } from "redux/slots/slotsSlice";

const Slot = () => {
  const params = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { lg, xl } = useSpacing();
  const { slots } = useSelector((state) => state);

  const { viewingSlots, viewingJobBoard, error, loading } = slots;

  useEffect(() => {
    if (!params.board_id) {
      return navigate("/promote");
    }

    if (!viewingJobBoard) {
      dispatch(fetchSlotsWithJobBoardID(params.board_id));
    }

    if (viewingJobBoard) {
      if ("id" in viewingJobBoard && "name" in viewingJobBoard) {
        if (`${viewingJobBoard.id}` !== params.board_id)
          dispatch(fetchSlotsWithJobBoardID(params.board_id));
      }
    }

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (viewingJobBoard) {
      if (typeof viewingJobBoard === "object" && Object.keys(viewingJobBoard).length === 0) {
        dispatch(slotsSlice.actions.resetViewingInfo());
        return navigate("/promote");
      }
    }

    //eslint-disable-next-line
  }, [viewingJobBoard]);

  return (
    <WrapperWithNav wrapperStyles={{ background: "var(--rs-color-white)" }}>
      {!loading && !error && Array.isArray(viewingSlots) && viewingJobBoard && (
        <View gap={lg + xl}>
          <BoardInfo board={viewingJobBoard} />
          <>
            {viewingSlots.length <= 0 ? (
              <Text variant="body-strong-1" color="neutral-faded">
                {t("No slots found!")}
              </Text>
            ) : (
              <SlotSection board={viewingJobBoard} />
            )}
          </>
        </View>
      )}
      {loading && !error && (
        <View.Item grow={true}>
          <ReshapedLoader />
        </View.Item>
      )}
    </WrapperWithNav>
  );
};

export default Slot;
