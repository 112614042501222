import { cooperColors } from "utils/colors/accents";

export const Cross = ({ fill = cooperColors["foreground-primary"], width=16, height=16}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8536 3.14645C13.0488 3.34171 13.0488 3.65829 12.8536 3.85355L3.85355 12.8536C3.65829 13.0488 3.34171 13.0488 3.14645 12.8536C2.95118 12.6583 2.95118 12.3417 3.14645 12.1464L12.1464 3.14645C12.3417 2.95118 12.6583 2.95118 12.8536 3.14645Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.14645 3.14645C3.34171 2.95118 3.65829 2.95118 3.85355 3.14645L12.8536 12.1464C13.0488 12.3417 13.0488 12.6583 12.8536 12.8536C12.6583 13.0488 12.3417 13.0488 12.1464 12.8536L3.14645 3.85355C2.95118 3.65829 2.95118 3.34171 3.14645 3.14645Z"
        fill={fill}
      />
    </svg>
  );
};

export const CrossRed = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.5854 13.633C16.1382 14.1864 16.1382 15.0471 15.5854 15.6004C15.309 15.877 14.9712 16 14.6027 16C14.2342 16 13.8964 15.877 13.62 15.6004L8 9.97502L2.38004 15.6004C2.10365 15.877 1.76584 16 1.39731 16C1.02879 16 0.690979 15.877 0.414587 15.6004C-0.138196 15.0471 -0.138196 14.1864 0.414587 13.633L6.03455 8.00769L0.414587 2.38233C-0.138196 1.82901 -0.138196 0.9683 0.414587 0.414986C0.96737 -0.138328 1.82726 -0.138328 2.38004 0.414986L8 6.04035L13.62 0.414986C14.1727 -0.138328 15.0326 -0.138328 15.5854 0.414986C16.1382 0.9683 16.1382 1.82901 15.5854 2.38233L9.96545 8.00769L15.5854 13.633Z"
        fill="#DC3813"
      />
    </svg>
  );
};
