import { useState, useMemo } from "react";
import { Button, DropdownMenu, Text, useToast, useToggle, View } from "reshaped";

import { backendAPI } from "utils/axios";
import { ArrowDown, Reject } from "icons";
import { Tooltip } from "components/library";
import { stageOptions } from "utils/constants";
import { candidateSlice } from "redux/candidate";
import { baseColor } from "utils/colors/accents";
import useSpacing from "components/hooks/useSpacing";
import { useDispatch, useSelector } from "react-redux";
import { reloadOnUnauthorized } from "utils/checkForAuth";
import { ShowToastWithTranslation } from "utils/showToast";
import useTranslation from "components/hooks/useTranslation";
import { RejectCandidate } from "components/pages/candidates";
import { isArrayWithElementsOfType } from "utils/miniHelpers";
import { viewingCandidateSlice } from "redux/viewingCandidate";
import { SuccessfulToast } from "components/reusables/tab/SuccessfulToast";
import { getTextWithEclipses, isTextExceeding } from "utils/getTextWithEclipses";
import { OperationFailedWithTranslation } from "components/reusables/OperationFailed";
import { RejectCandidateModal } from "components/reusables/modals/RejectCandidateModal";

export const ChangeCandidateStage = ({ candidateStage = "applied", fullName }) => {
  const [loading, setLoading] = useState(false);

  const toast = useToast();
  const { md } = useSpacing();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { active, activate, deactivate } = useToggle(false);

  const { currentCandidate } = state.viewingCandidate;
  const { candidates: candidatesSorting } = state.sorting;
  const { candidates: candidatesFilters } = state.filters;
  const { shouldCandidatesRefetch, candidates, pageCount } = state.candidate;

  const { stages } = candidatesFilters;
  const { selected: selectedStages } = stages;
  const { updateStage } = viewingCandidateSlice.actions;

  const stagingOptionsTitle = useMemo(() => {
    return stageOptions.array.filter(({ value }) => value !== "rejected");

    //eslint-disable-next-line
  }, []);

  const changeStage = async (selectedStage) => {
    try {
      setLoading(true);
      const previousCandidateStage = candidateStage;
      const response = await backendAPI.patch(`/candidates/${currentCandidate?.id}/change_stage`, {
        stage: selectedStage,
      });
      setLoading(false);
      if (response.data && response.data.status === 200 && response.data.message) {
        const nextCandidateStage = response.data.message;
        let hired = 0;
        if (previousCandidateStage !== "hired" && nextCandidateStage === "hired") hired = 1;
        if (previousCandidateStage === "hired" && nextCandidateStage !== "hired") hired = -1;
        dispatch(
          updateStage({ stage: nextCandidateStage, candidate_id: currentCandidate?.id, hired })
        );
        if (
          !shouldCandidatesRefetch &&
          ((Array.isArray(selectedStages) && selectedStages.length > 0) ||
            isArrayWithElementsOfType({
              array: candidatesSorting,
              elementType: "object",
              keys: [{ key: "stage", exact: true }],
            }))
        ) {
          if (
            Array.isArray(candidates) &&
            candidates.length === 1 &&
            typeof pageCount === "number" &&
            pageCount > 0
          ) {
            dispatch(
              candidateSlice.actions.set({
                pageCount: pageCount - 1,
                shouldCandidatesRefetch: true,
              })
            );
          } else {
            dispatch(candidateSlice.actions.set({ shouldCandidatesRefetch: true }));
          }
        }
        ShowToastWithTranslation({
          toast,
          Children: StageChanged,
          text: t("Stage successfully changed!"),
        });
      } else {
        ShowToastWithTranslation({
          toast,
          Children: OperationFailedWithTranslation,
          text: t("Operation unsuccessful!"),
        });
      }
    } catch (error) {
      reloadOnUnauthorized(error);
      setLoading(false);
      ShowToastWithTranslation({
        toast,
        Children: OperationFailedWithTranslation,
        text: t("Operation unsuccessful!"),
      });
    }
  };

  const handleStageChange = ({ value }) => {
    if (value !== "rejected") changeStage({ stage: value });
    if (value === "rejected") activate();
  };

  const closeRejectModal = ({ withSuccess = false }) => {
    deactivate();
    if (withSuccess) {
      ShowToastWithTranslation({
        toast,
        Children: SuccessfulToast,
        text: t("Candidate rejection successful."),
      });
    }
  };

  return (
    <>
      <View height="5rem" direction="row" align="center" gap={md}>
        <DropdownMenu position="bottom-start">
          <DropdownMenu.Trigger>
            {(attributes) => (
              <Button
                size="small"
                color="primary"
                loading={loading}
                attributes={{
                  ...attributes,
                  title: isTextExceeding(t("Take an action"), 16) ? t("Take an action") : "",
                  "data-cooper": "candidate change stage",
                }}
                endIcon={() => <ArrowDown fill={baseColor.white} />}
              >
                {getTextWithEclipses(t("Take an action"), 16, 2)}
              </Button>
            )}
          </DropdownMenu.Trigger>
          <DropdownMenu.Content>
            {stagingOptionsTitle.map(({ title, value }, index) => {
              return (
                <DropdownMenu.Item
                  disabled={candidateStage === value}
                  onClick={() => handleStageChange({ value: value })}
                  attributes={{ "data-cooper": `candidate ${title} option` }}
                  key={index}
                >
                  {t(title)}
                </DropdownMenu.Item>
              );
            })}
          </DropdownMenu.Content>
        </DropdownMenu>
        <Tooltip
          position="bottom"
          text={candidateStage === "rejected" ? t("Candidate Rejected") : t("Reject Candidate")}
          tooltipMaxWidth={"60px"}
          tooltipTextAlign={"center"}
        >
          {({ className }) => (
            <Button
              className={`${className}`}
              attributes={{
                ...(candidateStage === "rejected" && {
                  style: { background: "var(--rs-color-background-critical)" },
                }),
              }}
              disabled={candidateStage === "rejected"}
              startIcon={
                candidateStage === "rejected" ? (
                  <Reject color="var(--rs-color-white)" />
                ) : (
                  <Reject />
                )
              }
              variant="ghost"
              size="small"
              onClick={() => handleStageChange({ value: "rejected" })}
            />
          )}
        </Tooltip>
      </View>
      <RejectCandidateModal active={active} deactivate={deactivate}>
        <RejectCandidate
          active={active}
          closeRejectModal={closeRejectModal}
          candidateID={currentCandidate.id}
        />
      </RejectCandidateModal>
    </>
  );
};

const StageChanged = ({ children }) => {
  return (
    <Text color="positive" variant="body-strong-1">
      {children}
    </Text>
  );
};
