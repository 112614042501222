import { View, Text } from "reshaped";
import useSpacing from "components/hooks/useSpacing";
import useTranslation from "components/hooks/useTranslation";
import common from "components/styles/reshaped/reusables/common.module.css";

export const TabButton = ({ title = "tab", value, currentActiveTab, handler }) => {
  const { t } = useTranslation();
  const { sm, xl } = useSpacing();

  return (
    <View
      as="button"
      height="38px"
      padding={[0, xl + sm]}
      borderRadius="medium"
      className={`${common.category__small} ${value === currentActiveTab ? common.active : ""}`}
      attributes={{
        onClick: () => handler({ value }),
        "data-cooper": `candidate ${value}`
      }}
      direction="row"
      justfiy="center"
      align="center"
    >
      <Text variant={value === currentActiveTab ? "body-strong-1" : "body-1"}>{t(title)}</Text>
    </View>
  );
};
