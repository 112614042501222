import { Text } from "reshaped";

export const OperationFailed = () => {
  return (
    <Text color="critical" variant="body-strong-1">
      Operation unsuccessful!
    </Text>
  );
};

export const OperationFailedWithTranslation = ({ children }) => {
  return (
    <Text color="critical" variant="body-strong-1">
      {children}
    </Text>
  );
};
