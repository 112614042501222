import {
  CITY,
  TASKS,
  TITLE,
  BENEFITS,
  ZIP_CODE,
  DEPARTMENT,
  SHORT_INTRO,
  REQUIREMENTS,
  ANYTHING_ELSE,
} from "components/pages/jobs/jobReducer";

export const jobInputCharacterlimits = {
  [CITY]: 240,
  [TITLE]: 250,
  [TASKS]: 10000,
  [ZIP_CODE]: 20,
  [BENEFITS]: 10000,
  [DEPARTMENT]: 150,
  [SHORT_INTRO]: 8000,
  [REQUIREMENTS]: 10000,
  [ANYTHING_ELSE]: 6000,
};

const properties = {
  basic: {
    regex: /.{10,50}/,
    message: "should have minimum 10 and maximum 50 characters",
  },
  country: {
    regex: /[a-zA-Z\s\S]{1,}/,
    message: "Not a country",
    dontMatch: {
      regex: /[0-9]{1}/,
      message: "Numbers are not allowed in country",
    },
  },
  title: {
    regex: /[\w\W\s\S\n]{2,}/,
    message: "Title should have minimum 2 characters",
    max: {
      numberOfCharacters: jobInputCharacterlimits.TITLE,
      message: `Title should have maximum ${jobInputCharacterlimits.TITLE} characters`,
    },
  },
  tasks: {
    regex: /[\w\W\s\S\n]{2,}/,
    message: "Tasks should have minimum 2 characters",
    max: {
      numberOfCharacters: jobInputCharacterlimits.TASKS,
      message: `Tasks should have maximum ${jobInputCharacterlimits.TASKS} characters`,
    },
  },
  requirements: {
    regex: /[\w\W\s\S\n]{2,}/,
    message: "Requirement should have minimum 2 characters",
    max: {
      numberOfCharacters: jobInputCharacterlimits.REQUIREMENTS,
      message: `Requirement should have maximum ${jobInputCharacterlimits.REQUIREMENTS} characters`,
    },
  },
  department: {
    regex: /[\w\W\s\S\n]{2,}/,
    message: "Department should have minimum 2 characters",
    max: {
      numberOfCharacters: jobInputCharacterlimits.DEPARTMENT,
      message: `Department should have maximum ${jobInputCharacterlimits.DEPARTMENT} characters`,
    },
  },
  language_application_form: {
    regex: /[a-zA-Z\s]{1,50}/,
    message: "Invalid language",
  },
  city: {
    regex: /[\w\s]{2,}/,
    message: "Invalid city",
    max: {
      numberOfCharacters: jobInputCharacterlimits.CITY,
      message: `City should have maximum ${jobInputCharacterlimits.CITY} characters`,
    },
  },
  zip_code: {
    regex: /[0-9A-Za-z\s]{4,12}/,
    message: "Zip Code should have minimum 4 characters",
    max: {
      numberOfCharacters: jobInputCharacterlimits.ZIP_CODE,
      message: `Zip Code should have maximum ${jobInputCharacterlimits.ZIP_CODE} characters`,
    },
    dontMatch: {
      //eslint-disable-next-line
      regex: /[`~!@#$%^&*+=\[\]()\_{}|\\;:'"<>?/.,-]{1,}/,
      message: "Symbols are not allowed in zip code",
    },
  },

  // not required fields
  short_intro: {
    regex: /[\w\W\s\S\n]{2,}/,
    message: "Short Intro should have minimum 2 characters",
    max: {
      numberOfCharacters: jobInputCharacterlimits.SHORT_INTRO,
      message: `Short Intro should have maximum ${jobInputCharacterlimits.SHORT_INTRO} characters`,
    },
  },
  benefits: {
    regex: /[\w\W\s\S\n]{2,}/,
    message: "Benefits should have minimum 2 characters",
    max: {
      numberOfCharacters: jobInputCharacterlimits.BENEFITS,
      message: `Benefits should have maximum ${jobInputCharacterlimits.BENEFITS} characters`,
    },
  },
  anything_else: {
    regex: /[\w\W\s\S\n]{2,}/,
    message: "Text field should have minimum 2 characters",
    max: {
      numberOfCharacters: jobInputCharacterlimits.ANYTHING_ELSE,
      message: `Text field should have maximum ${jobInputCharacterlimits.ANYTHING_ELSE} characters`,
    },
  },
  min_salary: {
    regex: /[0-9,.]{0,}/,
    message: "Minimum salary should have only digits",
    dontMatch: {
      //eslint-disable-next-line
      regex: /[a-zA-Z`~!@#$%^&*+=\[\]()\_{}|\\;:'"<>?/-]{1,}/,
      message: "Minimum salary should have only digits",
    },
  },
  max_salary: {
    regex: /[0-9,.]{0,}/,
    message: "Maximum salary should have only digits",
    dontMatch: {
      //eslint-disable-next-line
      regex: /[a-zA-Z`~!@#$%^&*+=\[\]()\_{}|\\;:'"<>?/-]{1,}/,
      message: "Maximum salary should have only digits",
    },
  },
};

export const jobValidationHelper = (property) => {
  if (properties[property]) {
    return properties[property];
  }
  return properties["basic"];
};
