export const Evaluation = () => {
  return (
    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_969_128815)">
        <path
          d="M9.17615 7.06816L7.65644 5.19316C7.76329 5.02684 7.82339 4.83481 7.83044 4.63723C7.83749 4.43965 7.79122 4.24382 7.6965 4.0703C7.60178 3.89679 7.4621 3.75198 7.29213 3.6511C7.12216 3.55022 6.92816 3.49699 6.73053 3.49699C6.53289 3.49699 6.3389 3.55022 6.16893 3.6511C5.99895 3.75198 5.85927 3.89679 5.76455 4.0703C5.66983 4.24382 5.62357 4.43965 5.63061 4.63723C5.63766 4.83481 5.69777 5.02684 5.80461 5.19316L3.66138 7.83691C3.57611 7.8097 3.48728 7.79528 3.39779 7.79414C3.21635 7.79408 3.03896 7.84786 2.88807 7.94866C2.73718 8.04946 2.61957 8.19276 2.5501 8.36044C2.48064 8.52812 2.46245 8.71264 2.49784 8.89066C2.53322 9.06868 2.62059 9.2322 2.74889 9.36054C2.87719 9.48889 3.04066 9.57628 3.21862 9.61168C3.39658 9.64708 3.58104 9.62888 3.74866 9.55939C3.91628 9.48991 4.05954 9.37225 4.16031 9.22131C4.26108 9.07037 4.31483 8.89293 4.31477 8.71143C4.31333 8.55815 4.27303 8.40775 4.19762 8.27432L6.34145 5.63086C6.5897 5.72865 6.86579 5.72865 7.11404 5.63086L8.63463 7.50586C8.78616 7.32734 8.96985 7.17887 9.17615 7.06816Z"
          fill="black"
        />
        <path
          d="M10.0622 7.5293C9.82855 7.52959 9.60022 7.59913 9.40608 7.72914C9.21193 7.85915 9.06068 8.0438 8.97143 8.25974C8.88219 8.47569 8.85896 8.71324 8.90468 8.94238C8.9504 9.17152 9.06302 9.38197 9.2283 9.54713C9.39358 9.71229 9.60411 9.82475 9.83329 9.8703C10.0625 9.91584 10.3 9.89244 10.5159 9.80303C10.7318 9.71363 10.9163 9.56224 11.0461 9.368C11.176 9.17376 11.2454 8.94538 11.2455 8.71172C11.2453 8.39804 11.1205 8.09729 10.8986 7.87557C10.6767 7.65384 10.3759 7.5293 10.0622 7.5293Z"
          fill="black"
        />
        <path
          d="M13.3932 2.53594C12.8453 2.53656 12.32 2.75459 11.9326 3.14219C11.5452 3.52979 11.3273 4.0553 11.3267 4.60342C11.3286 5.10391 11.5135 5.58641 11.8466 5.95986L10.9478 7.06816C11.1531 7.17923 11.3358 7.32767 11.4866 7.50586L12.3869 6.39697C12.6934 6.57401 13.0405 6.6683 13.3944 6.6706C13.6679 6.67386 13.9393 6.62279 14.1929 6.52036C14.4464 6.41793 14.6772 6.26617 14.8717 6.07387C15.0663 5.88158 15.2207 5.65258 15.3261 5.40014C15.4315 5.1477 15.4858 4.87685 15.4858 4.60327C15.4858 4.32969 15.4315 4.05884 15.3261 3.8064C15.2207 3.55396 15.0663 3.32496 14.8717 3.13267C14.6772 2.94037 14.4464 2.78861 14.1929 2.68618C13.9393 2.58375 13.6679 2.53268 13.3944 2.53594H13.3932ZM13.3932 5.97627C13.1218 5.97621 12.8565 5.89564 12.6309 5.74476C12.4052 5.59387 12.2293 5.37944 12.1255 5.12858C12.0217 4.87772 11.9945 4.60169 12.0475 4.33541C12.1005 4.06912 12.2313 3.82453 12.4232 3.63256C12.6151 3.44059 12.8597 3.30987 13.1259 3.25692C13.3921 3.20397 13.668 3.23118 13.9188 3.3351C14.1695 3.43901 14.3839 3.61497 14.5347 3.84073C14.6854 4.06649 14.7659 4.3319 14.7659 4.60342C14.7656 4.9675 14.6209 5.31659 14.3635 5.57403C14.1062 5.83148 13.7572 5.97625 13.3932 5.97656V5.97627Z"
          fill="black"
        />
        <path
          d="M14.5415 11.7694H1.87071C1.78037 11.7721 1.69461 11.8098 1.63164 11.8746C1.56867 11.9395 1.53345 12.0263 1.53345 12.1167C1.53345 12.2072 1.56867 12.294 1.63164 12.3588C1.69461 12.4237 1.78037 12.4614 1.87071 12.4641H14.5415C14.6318 12.4614 14.7176 12.4237 14.7806 12.3588C14.8435 12.294 14.8788 12.2072 14.8788 12.1167C14.8788 12.0263 14.8435 11.9395 14.7806 11.8746C14.7176 11.8098 14.6318 11.7721 14.5415 11.7694Z"
          fill="black"
        />
        <path
          d="M14.5428 8.36455H12.7305C12.6384 8.36455 12.5501 8.40112 12.485 8.46623C12.4199 8.53134 12.3833 8.61964 12.3833 8.71171C12.3833 8.80379 12.4199 8.89209 12.485 8.9572C12.5501 9.02231 12.6384 9.05888 12.7305 9.05888H14.5428C14.6348 9.05888 14.7232 9.02231 14.7883 8.9572C14.8534 8.89209 14.8899 8.80379 14.8899 8.71171C14.8899 8.61964 14.8534 8.53134 14.7883 8.46623C14.7232 8.40112 14.6348 8.36455 14.5428 8.36455Z"
          fill="black"
        />
        <path
          d="M13.3934 10.2056C13.4838 10.2029 13.5696 10.1652 13.6326 10.1004C13.6956 10.0355 13.7308 9.94866 13.7308 9.85825C13.7308 9.76785 13.6956 9.681 13.6326 9.61616C13.5696 9.55131 13.4838 9.51357 13.3934 9.51094H12.7304C12.6401 9.51357 12.5543 9.55131 12.4913 9.61616C12.4283 9.681 12.3931 9.76785 12.3931 9.85825C12.3931 9.94866 12.4283 10.0355 12.4913 10.1004C12.5543 10.1652 12.6401 10.2029 12.7304 10.2056H13.3934Z"
          fill="black"
        />
        <path
          d="M8.75537 4.08604H10.5674C10.6595 4.08604 10.7478 4.04946 10.8129 3.98435C10.878 3.91925 10.9146 3.83094 10.9146 3.73887C10.9146 3.64679 10.878 3.55849 10.8129 3.49338C10.7478 3.42828 10.6595 3.3917 10.5674 3.3917H8.75537C8.6633 3.3917 8.57499 3.42828 8.50989 3.49338C8.44478 3.55849 8.4082 3.64679 8.4082 3.73887C8.4082 3.83094 8.44478 3.91925 8.50989 3.98435C8.57499 4.04946 8.6633 4.08604 8.75537 4.08604Z"
          fill="black"
        />
        <path
          d="M8.75537 5.23271H9.41865C9.51073 5.23271 9.59903 5.19614 9.66414 5.13103C9.72924 5.06592 9.76582 4.97762 9.76582 4.88554C9.76582 4.79347 9.72924 4.70517 9.66414 4.64006C9.59903 4.57495 9.51073 4.53838 9.41865 4.53838H8.75537C8.6633 4.53838 8.57499 4.57495 8.50989 4.64006C8.44478 4.70517 8.4082 4.79347 8.4082 4.88554C8.4082 4.97762 8.44478 5.06592 8.50989 5.13103C8.57499 5.19614 8.6633 5.23271 8.75537 5.23271Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_969_128815">
          <rect width="14.995" height="15" fill="white" transform="translate(0.994141)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const NoEvalutionMessage = () => {
  return (
    <svg
      width="239"
      height="172"
      viewBox="0 0 239 172"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.3">
        <path
          d="M222.526 46.554H33.3965C24.85 46.554 17.9219 53.4821 17.9219 62.0286V142.494C17.9219 151.041 24.85 157.969 33.3965 157.969H106.152L119.183 171L132.214 157.969H222.526C231.072 157.969 238.001 151.041 238.001 142.494V62.0286C238.001 53.4821 231.072 46.554 222.526 46.554Z"
          fill="white"
        />
        <path
          d="M222.526 46.554H33.3964C24.85 46.554 17.9219 53.4821 17.9219 62.0286V142.494C17.9219 151.041 24.85 157.969 33.3964 157.969H106.152L119.183 171L132.214 157.969H222.526C231.072 157.969 238.001 151.041 238.001 142.494V62.0286C238.001 53.4821 231.072 46.554 222.526 46.554Z"
          stroke="#D092DD"
          strokeWidth="1.39743"
          strokeMiterlimit="10"
        />
        <path
          d="M69.4595 78.9404C69.4595 87.8672 62.2238 95.1022 53.297 95.1022C44.3716 95.1022 37.1353 87.8672 37.1353 78.9404C37.1353 70.015 44.3716 62.7786 53.297 62.7786C62.2238 62.7786 69.4595 70.015 69.4595 78.9404Z"
          fill="#E2C0EF"
        />
        <path
          d="M86.9815 64.26L89.947 73.1553L99.3241 73.0826L91.7808 78.6531L94.7471 87.5484L87.1185 82.0954L79.5752 87.6652L82.4045 78.7251L74.7759 73.2728L84.1522 73.2001L86.9815 64.26Z"
          fill="#E69A05"
        />
        <path
          d="M116.847 64.26L119.813 73.1553L129.19 73.0826L121.647 78.6531L124.613 87.5484L116.985 82.0954L109.441 87.6652L112.27 78.7251L104.642 73.2728L114.018 73.2001L116.847 64.26Z"
          fill="#E69A05"
        />
        <path
          d="M146.712 64.26L149.678 73.1553L159.055 73.0826L151.512 78.6531L154.478 87.5484L146.85 82.0954L139.307 87.6652L142.135 78.7251L134.507 73.2728L143.884 73.2001L146.712 64.26Z"
          fill="#E69A05"
        />
        <path
          d="M176.577 64.26L179.544 73.1553L188.92 73.0826L181.377 78.6531L184.343 87.5484L176.715 82.0954L169.172 87.6652L172 78.7251L164.373 73.2728L173.749 73.2001L176.577 64.26Z"
          fill="#E7F0FD"
        />
        <path
          d="M206.443 64.26L209.409 73.1553L218.786 73.0826L211.242 78.6531L214.209 87.5484L206.581 82.0954L199.038 87.6652L201.866 78.7251L194.238 73.2728L203.615 73.2001L206.443 64.26Z"
          fill="#E7F0FD"
        />
        <path d="M50.5549 102.316H36.2793V106.13H50.5549V102.316Z" fill="#D092DD" />
        <path d="M54.0791 102.316V106.13H75.4806V102.316H54.0791Z" fill="#D092DD" />
        <path d="M67.0683 113.648H36.2793V117.462H67.0683V113.648Z" fill="#E2C0EF" />
        <path d="M151.651 113.648H79.6138V117.462H151.651V113.648Z" fill="#E2C0EF" />
        <path d="M164.197 113.648V117.462H219.642V113.648H164.197Z" fill="#E2C0EF" />
        <path d="M105.111 125.789H36.2793V129.603H105.111V125.789Z" fill="#E2C0EF" />
        <path d="M185.242 125.789V129.603H219.642V125.789H185.242Z" fill="#E2C0EF" />
        <path d="M177.148 125.789H116.847V129.603H177.148V125.789Z" fill="#E2C0EF" />
        <path d="M124.132 137.93H36.2793V141.745H124.132V137.93Z" fill="#E2C0EF" />
        <path
          d="M92.7792 0.999976H8.01536C4.18506 0.999976 1.08008 4.10467 1.08008 7.93434V24.8959C1.08008 28.7263 4.18506 31.8317 8.01536 31.8317H92.7792C96.6095 31.8317 99.7149 28.7263 99.7149 24.8959V7.93434C99.7149 4.10467 96.6095 0.999976 92.7792 0.999976Z"
          stroke="#D092DD"
          strokeWidth="1.39743"
          strokeMiterlimit="10"
        />
        <path
          d="M33.6806 11.1707L35.0098 15.1569L39.2122 15.1255L35.8315 17.6213L37.1611 21.6082L33.7421 19.1642L30.3615 21.66L31.6292 17.6541L28.2104 15.2101L32.4127 15.1779L33.6806 11.1707Z"
          fill="#E69A05"
        />
        <path
          d="M47.0661 11.1707L48.3954 15.1569L52.598 15.1255L49.2171 17.6213L50.5463 21.6082L47.1278 19.1642L43.747 21.66L45.0146 17.6541L41.5962 15.2101L45.7981 15.1779L47.0661 11.1707Z"
          fill="#E69A05"
        />
        <path
          d="M60.4509 11.1707L61.7799 15.1569L65.9822 15.1255L62.6013 17.6213L63.931 21.6082L60.5124 19.1642L57.1318 21.66L58.3991 17.6541L54.9805 15.2101L59.1828 15.1779L60.4509 11.1707Z"
          fill="#E69A05"
        />
        <path
          d="M73.8365 11.1707L75.1661 15.1569L79.3677 15.1255L75.9876 17.6213L77.3166 21.6082L73.898 19.1642L70.5171 21.66L71.7853 17.6541L68.3667 15.2101L72.5683 15.1779L73.8365 11.1707Z"
          fill="#E69A05"
        />
        <path
          d="M87.2212 11.1707L88.5509 15.1569L92.7532 15.1255L89.3723 17.6213L90.702 21.6082L87.2827 19.1642L83.9026 21.66L85.1701 17.6541L81.7515 15.2101L85.9538 15.1779L87.2212 11.1707Z"
          fill="#E7F0FD"
        />
        <path
          d="M22.5775 15.0484C22.5775 18.4649 19.8086 21.234 16.3929 21.234C12.9771 21.234 10.208 18.4649 10.208 15.0484C10.208 11.6326 12.9771 8.86415 16.3929 8.86415C19.8086 8.86415 22.5775 11.6326 22.5775 15.0484Z"
          fill="#E2C0EF"
        />
        <path d="M14.4044 22.507H8.94189V23.9667H14.4044V22.507Z" fill="#D092DD" />
        <path d="M15.7534 22.507V23.9667H23.9433V22.507H15.7534Z" fill="#D092DD" />
      </g>
    </svg>
  );
};
