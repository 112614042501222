import { useState, useEffect } from "react";
import { Text, useScrollLock, View } from "reshaped";

import { metaSlice } from "redux/meta";
import useSpacing from "components/hooks/useSpacing";
import { CandidateModal } from "components/reusables";
import { useDispatch, useSelector } from "react-redux";
import useTranslation from "components/hooks/useTranslation";
import {
  CandidatesTableRow,
  ProfileModalContent,
  CandidatesTableHeading,
} from "components/pages/candidates";

export const DashboardCandidates = (props) => {
  const { candidates } = props;

  const [openModal, setOpenModal] = useState(false);
  const [viewingCandidateId, setViewingCandidateId] = useState("");

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { xs, sm, lg } = useSpacing();
  const { meta } = useSelector((state) => state);
  const { lockScroll, unlockScroll } = useScrollLock();

  const { isCandidateModalOpen } = meta;
  const { setCandidateModalOpen } = metaSlice.actions;

  useEffect(() => {
    if (!isCandidateModalOpen) {
      unlockScroll();
      setOpenModal(false);
    }

    //eslint-disable-next-line
  }, [isCandidateModalOpen]);

  useEffect(() => {
    function handleBackButton() {
      if (isCandidateModalOpen) {
        dispatch(metaSlice.actions.setCandidateModalOpen(false));
      }
    }
    window.onpopstate = handleBackButton;

    return () => {
      window.onpopstate = () => {};
    };

    //eslint-disable-next-line
  }, [isCandidateModalOpen]);

  const handleOpenModal = () => {
    window.history.pushState({ id: 1 }, null, "");
    lockScroll();
    setOpenModal(true);
    dispatch(setCandidateModalOpen(true));
  };

  const handleCloseModal = () => {
    dispatch(setCandidateModalOpen(false));
  };

  const handleViewingCandidate = (id) => {
    setViewingCandidateId(id);
  };

  const modalProps = { openModal, setOpenModal };
  const viewingCandidateProps = { viewingCandidateId, setViewingCandidateId };

  return (
    <View gap={sm} maxWidth="1200px">
      <Text variant="title-3">{t("My Candidates")}</Text>
      <View gap={lg + xs}>
        {Array.isArray(candidates) && candidates.length !== 0 ? (
          <>
            <CandidatesTableHeading noSorting isDashboard={true}/>
            {candidates.length !== 0 && candidates.length < 4 && (
              <>
                {candidates.map((candidate) => {
                  return (
                    <CandidatesTableRow
                      handleOpenModal={handleOpenModal}
                      key={candidate.id}
                      candidate={candidate}
                      handleViewingCandidate={handleViewingCandidate}
                      rowColor="white"
                      isDashboard={true}
                    />
                  );
                })}
              </>
            )}

            {candidates.length >= 4 && (
              <>
                {[candidates[0], candidates[1], candidates[2]].map((candidate) => {
                  return (
                    <CandidatesTableRow
                      handleOpenModal={handleOpenModal}
                      key={candidate.id}
                      candidate={candidate}
                      handleViewingCandidate={handleViewingCandidate}
                      rowColor="white"
                      isDashboard={true}
                    />
                  );
                })}
              </>
            )}
          </>
        ) : (
          <View padding={[lg, 0]}>
            <View
              borderRadius="medium"
              height="200px"
              backgroundColor="neutral-faded"
              align="center"
              justify="center"
            >
              <Text variant="title-3" color="neutral-faded">
                {t("No candidates applied yet!")}
              </Text>
            </View>
          </View>
        )}
      </View>
      <CandidateModal open={openModal} handleClose={handleCloseModal} overlayColor="#fff">
        <ProfileModalContent
          {...viewingCandidateProps}
          {...modalProps}
          candidates={candidates}
          handleClose={handleCloseModal}
        />
      </CandidateModal>
    </View>
  );
};
