// Cookie token
export const userAuth = "user-x-auth";

export const cooperhireInProgressConversationLocalStorageName = "cooperhire_inprogress";

// candidate constants
export const EMAIL = "EMAIL";
export const NOTES = "NOTES";
export const NUMBER = "NUMBER";
export const SOURCE = "SOURCE";
export const LAST_NAME = "LAST_NAME";
export const FIRST_NAME = "FIRST_NAME";
export const APPLIED_JOB = "APPLIED_JOB";
export const LINKEDIN_URL = "LINKEDIN_URL";

//constants
export const SUPPORT_ERROR = "support@cooperhire.io";
export const SUPPORT_ERROR_FULL_VERSION =
  "Something went wrong, please delete your browser cache and try again. For further help please reach out to support@cooperhire.io";

export const stageOptions = {
  offer: { title: "Offer", value: "offer" },
  in_review: { title: "In Review", value: "in_review" },
  hired: { title: "Hired", value: "hired" },
  applied: { title: "Applied", value: "applied" },
  rejected: { title: "Rejected", value: "rejected" },
  evaluation: { title: "Evaluation", value: "evaluation" },
  interviewing: { title: "Interviewing", value: "interviewing" },
  array: [
    { title: "Applied", value: "applied" },
    { title: "In Review", value: "in_review" },
    { title: "Interviewing", value: "interviewing" },
    { title: "Evaluation", value: "evaluation" },
    { title: "Offer", value: "offer" },
    { title: "Hired", value: "hired" },
    { title: "Rejected", value: "rejected" },
  ],
};
export const STAGES_LIMIT_BASED_STYLE = 8;

export const supportedTemplateTriggers = ["applied", "rejected"];

const MAX_HEADERS_SIZE = 50 * 1024; // in kilo bytes(kb)
export const MAX_EMAIL_SIZE = 20 * 1024 * 1024 - MAX_HEADERS_SIZE;

export const CountryMap = {
  Germany: "de",
  "United Kingdom": "uk",
  "United States": "us",
};

export const PlanLimits = {
  free: {
    jobs: 3,
    users: 1,
    messageTemplates: 3,
  },
  growth: {
    jobs: 10,
    users: 10,
    messageTemplates: 20,
  },
  premium: {
    jobs: 25,
    users: 25,
    messageTemplates: 50,
  },
};

const plansAndIds = {
  1: "free",
  2: "growth",
  3: "premium",
};

export const PlansArray = ["Free", "Growth", "Premium"];

const defaultCurrency = {
  code: "USD",
  symbol: "$",
};

export const countriesAndCurrencies = new Proxy(
  {
    de: {
      code: "EUR",
      symbol: "€",
    },
  },
  {
    get: (target, prop) => {
      return prop in target ? target[prop] : defaultCurrency;
    },
  }
);

export const currenciesAndSymbols = {
  EUR: "€",
  USD: "$",
};

export const getPlanNameByPlanId = (plan_id) => {
  return plansAndIds[plan_id];
};

export const intervalToNumber = {
  monthly: 1,
  yearly: 12,
};

export const idsAndPlans = {
  free: 1,
  growth: 2,
  premium: 3,
};
