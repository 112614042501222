export const passwordProgressHelper = (value, setter) => {
  const oneNumberRegex = /[0-9]{1,}/;
  const oneUppercaseRegex = /[A-Z]{1,}/;
  const oneLowercaseRegex = /[a-z]{1,}/;

  //eslint-disable-next-line
  const oneSpecialRegex = /[`~!@#$%^&*+=\[\]()\_{}|\\;:'"<>?/.,-]{1,}/;

  //eslint-disable-next-line
  const sevenCharactersRegex = /[0-9A-Za-z`~!@#$%^&*+=\[\]()\_{}|\\;:'"<>?/.,-]{7,128}/;

  if (oneUppercaseRegex.test(value)) {
    setter((prevState) => {
      return {
        ...prevState,
        oneUppercase: true,
      };
    });
  } else {
    setter((prevState) => {
      return {
        ...prevState,
        oneUppercase: false,
      };
    });
  }

  if (oneLowercaseRegex.test(value)) {
    setter((prevState) => {
      return {
        ...prevState,
        oneLowercase: true,
      };
    });
  } else {
    setter((prevState) => {
      return {
        ...prevState,
        oneLowercase: false,
      };
    });
  }

  if (oneNumberRegex.test(value)) {
    setter((prevState) => {
      return {
        ...prevState,
        oneNumber: true,
      };
    });
  } else {
    setter((prevState) => {
      return {
        ...prevState,
        oneNumber: false,
      };
    });
  }

  if (oneSpecialRegex.test(value)) {
    setter((prevState) => {
      return {
        ...prevState,
        oneSpecial: true,
      };
    });
  } else {
    setter((prevState) => {
      return {
        ...prevState,
        oneSpecial: false,
      };
    });
  }

  if (sevenCharactersRegex.test(value)) {
    setter((prevState) => {
      return {
        ...prevState,
        sevenCharacters: true,
      };
    });
  } else {
    setter((prevState) => {
      return {
        ...prevState,
        sevenCharacters: false,
      };
    });
  }
};
