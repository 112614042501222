export const CooperhireLogo = () => {
  return (
    <svg
      width="188"
      height="24"
      viewBox="0 0 188 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38.7859 2.40462C36.4332 -2.16599 32.0092 0.495871 27.5192 4.43889C22.4707 8.78012 16.8814 11.3208 11.0941 11.97C5.9709 12.7318 2.60673 11.3771 3.99637 13.0954C5.33764 14.7141 11.4063 20.0638 17.0661 20.9035C23.3547 21.7735 29.4673 20.7434 35.0611 17.904C40.1227 15.5495 41.1386 6.86703 38.7859 2.40895V2.40462Z"
        fill="#F72585"
      />
      <path
        d="M1.93939 3.20062C4.80225 -1.08001 8.88763 2.05796 12.9027 6.47276C17.419 11.3507 22.683 14.493 28.3603 15.7785C33.3648 17.1072 36.8609 16.1334 35.2821 17.6872C33.765 19.1458 27.1202 23.7857 21.4033 23.9891C15.0575 24.1536 9.10312 22.4526 3.86995 19.0073C-0.901468 16.1117 -0.910263 7.37304 1.93939 3.20062Z"
        fill="#4CC9F0"
      />
      <path
        d="M54.8871 19.4703C53.7102 19.4703 52.6909 19.2701 51.8289 18.8697C50.9836 18.4692 50.2708 17.9354 49.6907 17.268C49.1271 16.6006 48.7045 15.8498 48.4227 15.0156C48.1409 14.1814 48 13.3306 48 12.463V11.9875C48 11.0699 48.1409 10.194 48.4227 9.35975C48.721 8.50886 49.1603 7.75808 49.7404 7.1074C50.3206 6.44004 51.0333 5.91449 51.8787 5.53076C52.7406 5.13034 53.7351 4.93014 54.8622 4.93014C56.0391 4.93014 57.0916 5.16371 58.0199 5.63087C58.9481 6.08134 59.6857 6.71533 60.2327 7.53285C60.7962 8.35037 61.1112 9.30136 61.1775 10.3858H57.821C57.7381 9.68509 57.4397 9.10115 56.9259 8.63399C56.4286 8.16684 55.7407 7.93326 54.8622 7.93326C54.0998 7.93326 53.4616 8.11679 52.9478 8.48384C52.4505 8.85089 52.0776 9.35975 51.8289 10.0104C51.5803 10.6444 51.456 11.3785 51.456 12.2127C51.456 13.0136 51.572 13.7393 51.8041 14.39C52.0527 15.0407 52.4256 15.5495 52.9229 15.9166C53.4367 16.2836 54.0915 16.4672 54.8871 16.4672C55.4838 16.4672 55.9977 16.3587 56.4286 16.1418C56.8596 15.9249 57.1994 15.6246 57.448 15.2409C57.7132 14.8571 57.879 14.4234 57.9453 13.9395H61.3018C61.2355 15.0407 60.9123 16.0083 60.3321 16.8425C59.7686 17.6601 59.0144 18.3024 58.0696 18.7696C57.1414 19.2367 56.0805 19.4703 54.8871 19.4703Z"
        fill="#1E1E1E"
      />
      <path
        d="M70.5596 19.4703C69.3662 19.4703 68.3137 19.2784 67.402 18.8947C66.4904 18.5109 65.7196 17.9937 65.0897 17.3431C64.4599 16.6757 63.9792 15.9249 63.6477 15.0907C63.3327 14.2565 63.1753 13.3889 63.1753 12.488V11.9625C63.1753 11.0282 63.341 10.1439 63.6725 9.3097C64.0206 8.45881 64.5096 7.70803 65.1395 7.05735C65.7859 6.38999 66.5649 5.87278 67.4766 5.50574C68.3882 5.122 69.4159 4.93014 70.5596 4.93014C71.7033 4.93014 72.731 5.122 73.6427 5.50574C74.5543 5.87278 75.3251 6.38999 75.9549 7.05735C76.6014 7.70803 77.0904 8.45881 77.4219 9.3097C77.7534 10.1439 77.9191 11.0282 77.9191 11.9625V12.488C77.9191 13.3889 77.7534 14.2565 77.4219 15.0907C77.1069 15.9249 76.6345 16.6757 76.0047 17.3431C75.3748 17.9937 74.604 18.5109 73.6924 18.8947C72.7807 19.2784 71.7365 19.4703 70.5596 19.4703ZM70.5596 16.4922C71.405 16.4922 72.1177 16.3087 72.6979 15.9416C73.278 15.5579 73.7173 15.049 74.0156 14.415C74.314 13.7643 74.4631 13.0302 74.4631 12.2127C74.4631 11.3785 74.3057 10.6444 73.9907 10.0104C73.6924 9.35975 73.2449 8.85089 72.6481 8.48384C72.068 8.1001 71.3718 7.90824 70.5596 7.90824C69.7474 7.90824 69.043 8.1001 68.4463 8.48384C67.8661 8.85089 67.4186 9.35975 67.1037 10.0104C66.7887 10.6444 66.6313 11.3785 66.6313 12.2127C66.6313 13.0302 66.7804 13.7643 67.0788 14.415C67.3937 15.049 67.8413 15.5579 68.4214 15.9416C69.0015 16.3087 69.7143 16.4922 70.5596 16.4922Z"
        fill="#1E1E1E"
      />
      <path
        d="M87.4345 19.4703C86.2411 19.4703 85.1886 19.2784 84.2769 18.8947C83.3653 18.5109 82.5945 17.9937 81.9646 17.3431C81.3348 16.6757 80.8541 15.9249 80.5226 15.0907C80.2076 14.2565 80.0502 13.3889 80.0502 12.488V11.9625C80.0502 11.0282 80.2159 10.1439 80.5474 9.3097C80.8955 8.45881 81.3845 7.70803 82.0144 7.05735C82.6608 6.38999 83.4398 5.87278 84.3515 5.50574C85.2631 5.122 86.2908 4.93014 87.4345 4.93014C88.5782 4.93014 89.6059 5.122 90.5176 5.50574C91.4292 5.87278 92.2 6.38999 92.8298 7.05735C93.4763 7.70803 93.9653 8.45881 94.2968 9.3097C94.6283 10.1439 94.794 11.0282 94.794 11.9625V12.488C94.794 13.3889 94.6283 14.2565 94.2968 15.0907C93.9818 15.9249 93.5094 16.6757 92.8796 17.3431C92.2497 17.9937 91.4789 18.5109 90.5673 18.8947C89.6556 19.2784 88.6114 19.4703 87.4345 19.4703ZM87.4345 16.4922C88.2799 16.4922 88.9926 16.3087 89.5728 15.9416C90.1529 15.5579 90.5922 15.049 90.8905 14.415C91.1889 13.7643 91.338 13.0302 91.338 12.2127C91.338 11.3785 91.1806 10.6444 90.8657 10.0104C90.5673 9.35975 90.1198 8.85089 89.523 8.48384C88.9429 8.1001 88.2467 7.90824 87.4345 7.90824C86.6223 7.90824 85.9179 8.1001 85.3212 8.48384C84.741 8.85089 84.2935 9.35975 83.9786 10.0104C83.6636 10.6444 83.5061 11.3785 83.5061 12.2127C83.5061 13.0302 83.6553 13.7643 83.9537 14.415C84.2686 15.049 84.7162 15.5579 85.2963 15.9416C85.8764 16.3087 86.5892 16.4922 87.4345 16.4922Z"
        fill="#1E1E1E"
      />
      <path
        d="M97.8201 24V5.40563H100.555V11.2617L100.132 11.2367C100.199 9.85193 100.481 8.69239 100.978 7.75808C101.492 6.82378 102.171 6.13139 103.017 5.68092C103.862 5.21377 104.807 4.98019 105.851 4.98019C106.812 4.98019 107.674 5.15537 108.437 5.50574C109.216 5.8561 109.879 6.34828 110.426 6.98227C110.989 7.61627 111.42 8.35871 111.719 9.20959C112.017 10.0438 112.166 10.9447 112.166 11.9124V12.438C112.166 13.4223 112.017 14.3399 111.719 15.1908C111.437 16.025 111.014 16.7675 110.451 17.4181C109.904 18.0521 109.241 18.5527 108.462 18.9197C107.683 19.2701 106.812 19.4453 105.851 19.4453C104.84 19.4453 103.912 19.2367 103.066 18.8196C102.238 18.3858 101.566 17.7268 101.052 16.8425C100.539 15.9416 100.248 14.7821 100.182 13.3639L101.276 15.0407V24H97.8201ZM104.981 16.5172C105.727 16.5172 106.373 16.3337 106.92 15.9666C107.484 15.5996 107.923 15.0907 108.238 14.44C108.553 13.7727 108.71 13.0219 108.71 12.1877C108.71 11.3201 108.553 10.5693 108.238 9.93535C107.923 9.30136 107.484 8.80918 106.92 8.45881C106.373 8.09176 105.727 7.90824 104.981 7.90824C104.301 7.90824 103.671 8.05839 103.091 8.35871C102.528 8.65902 102.063 9.10115 101.699 9.68509C101.351 10.269 101.177 10.9698 101.177 11.7873V12.7883C101.177 13.5725 101.359 14.2398 101.724 14.7904C102.088 15.341 102.561 15.7664 103.141 16.0667C103.721 16.367 104.334 16.5172 104.981 16.5172Z"
        fill="#1E1E1E"
      />
      <path
        d="M121.247 19.4703C120.086 19.4703 119.067 19.2701 118.189 18.8697C117.327 18.4692 116.606 17.9354 116.025 17.268C115.462 16.5839 115.031 15.8248 114.733 14.9906C114.451 14.1564 114.31 13.3055 114.31 12.438V11.9625C114.31 11.0615 114.451 10.194 114.733 9.35975C115.031 8.50886 115.462 7.75808 116.025 7.1074C116.606 6.44004 117.318 5.91449 118.164 5.53076C119.009 5.13034 119.987 4.93014 121.098 4.93014C122.556 4.93014 123.774 5.25548 124.752 5.90615C125.747 6.54015 126.493 7.38269 126.99 8.43379C127.487 9.4682 127.736 10.586 127.736 11.7873V13.0386H115.777V10.9114H125.573L124.504 11.9625C124.504 11.0949 124.379 10.3525 124.131 9.73514C123.882 9.11783 123.501 8.64234 122.987 8.30865C122.49 7.97497 121.86 7.80813 121.098 7.80813C120.335 7.80813 119.689 7.98332 119.158 8.33368C118.628 8.68405 118.222 9.19291 117.94 9.86027C117.675 10.5109 117.542 11.2951 117.542 12.2127C117.542 13.0636 117.675 13.8227 117.94 14.4901C118.205 15.1408 118.611 15.658 119.158 16.0417C119.705 16.4088 120.401 16.5923 121.247 16.5923C122.092 16.5923 122.78 16.4254 123.31 16.0918C123.841 15.7414 124.181 15.316 124.33 14.8154H127.512C127.313 15.7497 126.932 16.5673 126.368 17.268C125.805 17.9687 125.084 18.5109 124.205 18.8947C123.343 19.2784 122.357 19.4703 121.247 19.4703Z"
        fill="#1E1E1E"
      />
      <path
        d="M130.623 18.9948V5.40563H133.358V11.1616H133.283C133.283 9.20959 133.698 7.73306 134.527 6.73201C135.355 5.73097 136.574 5.23045 138.181 5.23045H138.679V8.2586H137.734C136.574 8.2586 135.67 8.5756 135.024 9.20959C134.394 9.8269 134.079 10.7278 134.079 11.9124V18.9948H130.623Z"
        fill="#1E1E1E"
      />
      <path
        d="M140.991 18.9948V0.725757H144.447V11.3869H143.85C143.85 10.0188 144.024 8.85923 144.372 7.90824C144.72 6.95725 145.234 6.23149 145.914 5.73097C146.61 5.23045 147.488 4.98019 148.549 4.98019H148.698C150.24 4.98019 151.408 5.51408 152.204 6.58186C153 7.64964 153.397 9.20125 153.397 11.2367V18.9948H149.941V10.9114C149.941 10.0438 149.693 9.35975 149.196 8.85923C148.715 8.35871 148.077 8.10845 147.281 8.10845C146.436 8.10845 145.748 8.39207 145.217 8.95933C144.704 9.50991 144.447 10.2357 144.447 11.1366V18.9948H140.991Z"
        fill="#1E1E1E"
      />
      <path
        d="M157.759 18.9948V5.40563H161.215V18.9948H157.759ZM155.87 8.00834V5.40563H161.215V8.00834H155.87ZM159.027 3.77894C158.348 3.77894 157.842 3.60375 157.511 3.25339C157.196 2.88634 157.038 2.43587 157.038 1.90198C157.038 1.33472 157.196 0.875914 157.511 0.525548C157.842 0.175183 158.348 0 159.027 0C159.707 0 160.204 0.175183 160.519 0.525548C160.834 0.875914 160.992 1.33472 160.992 1.90198C160.992 2.43587 160.834 2.88634 160.519 3.25339C160.204 3.60375 159.707 3.77894 159.027 3.77894Z"
        fill="#1E1E1E"
      />
      <path
        d="M165.198 18.9948V5.40563H167.933V11.1616H167.859C167.859 9.20959 168.273 7.73306 169.102 6.73201C169.931 5.73097 171.149 5.23045 172.757 5.23045H173.254V8.2586H172.309C171.149 8.2586 170.246 8.5756 169.599 9.20959C168.969 9.8269 168.654 10.7278 168.654 11.9124V18.9948H165.198Z"
        fill="#1E1E1E"
      />
      <path
        d="M181.511 19.4703C180.35 19.4703 179.331 19.2701 178.453 18.8697C177.591 18.4692 176.87 17.9354 176.289 17.268C175.726 16.5839 175.295 15.8248 174.997 14.9906C174.715 14.1564 174.574 13.3055 174.574 12.438V11.9625C174.574 11.0615 174.715 10.194 174.997 9.35975C175.295 8.50886 175.726 7.75808 176.289 7.1074C176.87 6.44004 177.582 5.91449 178.428 5.53076C179.273 5.13034 180.251 4.93014 181.362 4.93014C182.82 4.93014 184.038 5.25548 185.016 5.90615C186.011 6.54015 186.757 7.38269 187.254 8.43379C187.751 9.4682 188 10.586 188 11.7873V13.0386H176.041V10.9114H185.837L184.768 11.9625C184.768 11.0949 184.643 10.3525 184.395 9.73514C184.146 9.11783 183.765 8.64234 183.251 8.30865C182.754 7.97497 182.124 7.80813 181.362 7.80813C180.599 7.80813 179.953 7.98332 179.422 8.33368C178.892 8.68405 178.486 9.19291 178.204 9.86027C177.939 10.5109 177.806 11.2951 177.806 12.2127C177.806 13.0636 177.939 13.8227 178.204 14.4901C178.469 15.1408 178.875 15.658 179.422 16.0417C179.969 16.4088 180.665 16.5923 181.511 16.5923C182.356 16.5923 183.044 16.4254 183.574 16.0918C184.105 15.7414 184.445 15.316 184.594 14.8154H187.776C187.577 15.7497 187.196 16.5673 186.633 17.268C186.069 17.9687 185.348 18.5109 184.469 18.8947C183.608 19.2784 182.621 19.4703 181.511 19.4703Z"
        fill="#1E1E1E"
      />
    </svg>
  );
};
