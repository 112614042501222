import React, { useEffect, useState } from "react";

import { Key, TickCircle2 } from "icons";
import { PublicTemplate } from "components/reusables";
import useTranslation from "components/hooks/useTranslation";
import { useNavigate, useSearchParams } from "react-router-dom";
import { NewPassword, UpdateSuccessful } from "components/pages/resetPassword";

const Accept = () => {
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [resetPasswordStage, setResetPasswordStage] = useState("new");
  const resetPasswordProps = { resetPasswordStage, setResetPasswordStage };
  const confirmPasswordProps = { confirmPassword, setConfirmPassword };
  const newPasswordProps = { newPassword, setNewPassword };
  const [token, setToken] = useState("");

  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    let URLToken = searchParams.get("reset_password_token");
    if (!URLToken) {
      navigate("/login");
      return;
    }
    setToken(URLToken);
    //eslint-disable-next-line
  }, []);

  return (
    <>
      {resetPasswordStage === "new" && (
        <PublicTemplate
          IconSvg={Key}
          title={t("Set a new password")}
          subTitle={t("Your new password must be different from your previous passwords")}
        >
          <NewPassword
            isInvitation={false}
            resetPasswordToken={token}
            {...resetPasswordProps}
            {...confirmPasswordProps}
            {...newPasswordProps}
          />
        </PublicTemplate>
      )}
      {resetPasswordStage === "success" && (
        <PublicTemplate
          IconSvg={TickCircle2}
          title={t("Password reset")}
          subTitle={t("Your password has been successfully reset!")}
        >
          <UpdateSuccessful />
        </PublicTemplate>
      )}
    </>
  );
};

export default Accept;
