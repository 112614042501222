import { Icon, View, Text } from "reshaped";

import { Location } from "icons";
import { useSelector } from "react-redux";
import useSpacing from "components/hooks/useSpacing";

export const JobStep = (props) => {
  const { promotingJob, setPromotingJob } = props;

  const { jobs } = useSelector((state) => state.job);

  return (
    <>
      {jobs.length > 0 ? (
        <>
          <View
            divided
            className="no__scroll"
            overflow="hidden"
            attributes={{
              style: {
                maxHeight: "450px",
                overflowY: "scroll",
              },
            }}
          >
            {jobs.map((job) => {
              return (
                <Job
                  key={job.id}
                  job={job}
                  promotingJob={promotingJob}
                  setPromotingJob={setPromotingJob}
                />
              );
            })}
          </View>
        </>
      ) : (
        <View height="200px" align="center" justify="center">
          <Text variant="body-strong-2">No jobs created</Text>
        </View>
      )}
    </>
  );
};


const Job = (props) => {
  const { job, promotingJob, setPromotingJob } = props;
  const { title, id, country } = job;

  const { xs, md } = useSpacing();

  const handlePromoteJob = () => {
    setPromotingJob(job);
  };

  return (
    <View
      as="button"
      attributes={{
        onClick: () => handlePromoteJob(job),
      }}
      align="start"
      backgroundColor={promotingJob && promotingJob.id === job.id ? "neutral-faded" : "white"}
      direction="column"
      width="100%"
      padding={md}
      gap={xs}
    >
      <Text align="start" color="neutral" variant="body-strong-2">
        {title}
      </Text>
      <View divided direction="row" justify="start" align="center" gap={xs}>
        <Text variant="caption-1" color="neutral-faded">
          Job id: {id}
        </Text>
        <View direction="row" align="center" gap={xs}>
          <Icon svg={Location} size={md} />
          <Text variant="caption-1" color="neutral-faded">
            {country}
          </Text>
        </View>
      </View>
    </View>
  );
};
