import { useState, useEffect } from "react";

import { userAuth } from "utils/constants";
import { ATS_WEB_SOCKET_URL } from "constants";
import { useNavigate } from "react-router-dom";
import { decryptToken } from "utils/encryptions";
import { removeCookie } from "utils/checkForAuth";
import { clearAuthToken } from "utils/setAuthToken";
import { createConsumer } from "@rails/actioncable";
import { areKeysAvailableIn } from "utils/miniHelpers";
import SocketContext from "components/context/socket/SocketContext";

const SocketProvider = ({ children, token }) => {
  const [consumer, setConsumer] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (token && typeof token === "string") {
      try {
        const decryptedToken = decryptToken({
          encryptedToken: token,
        });
        const cable = createConsumer(`${ATS_WEB_SOCKET_URL}/cable?token=${decryptedToken}`);
        if (areKeysAvailableIn({ object: cable, keys: ["_url", "connection", "subscriptions"] })) {
          setConsumer(cable);
        }
      } catch (error) {
        removeCookie(userAuth);
        clearAuthToken();
        navigate("/login");
      }
    }
    return () => {
      if (areKeysAvailableIn({ object: consumer, keys: ["_url", "connection", "subscriptions"] })) {
        consumer.disconnect();
      }
    };

    //eslint-disable-next-line
  }, []);

  return (
    <SocketContext.Provider value={{ consumer, setConsumer }}>{children}</SocketContext.Provider>
  );
};

export default SocketProvider;
