import { Text, View } from "reshaped";
import { useSelector } from "react-redux";
import useSpacing from "components/hooks/useSpacing";
import { PendingInvite } from "components/pages/settings";
import useTranslation from "components/hooks/useTranslation";

export const PendingInvites = () => {
  const { t } = useTranslation();
  const { lg, xl } = useSpacing();
  const { pending } = useSelector((state) => state.invitation);

  return (
    <View gap={xl}>
      <Text variant="title-3">{t("Pending invites")}</Text>
      <View gap={lg} direction="row" width="700px">
        {pending && Array.isArray(pending) && (
          <>
            {pending.length === 0 && (
              <View.Item columns={12}>
                <Text color="neutral" variant="body-2">
                  {t("No pending invites")}
                </Text>
              </View.Item>
            )}
            {pending.length >= 0 && (
              <>
                {pending.map((user) => {
                  return <PendingInvite key={user.id} user={user} />;
                })}
              </>
            )}
          </>
        )}
      </View>
    </View>
  );
};
