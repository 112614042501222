import { useState, useEffect } from "react";

import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { userAuth } from "utils/constants";
import { useNavigate } from "react-router-dom";
import useTranslation from "components/hooks/useTranslation";
import {
  RegisterUser,
  RegisterLayout,
  RegisterConfirm,
  RegisterCompany,
  RegisterCompanySize,
} from "components/pages/register";

const Register = () => {
  const { registrationStage } = useSelector((state) => state.meta);

  const [userDetails, setUserDetails] = useState(null);
  const [companyDetails, setCompanyDetails] = useState(null);

  const user = { userDetails, setUserDetails };
  const company = { companyDetails, setCompanyDetails };
  const registration = { registrationStage };

  const [cookies] = useCookies([userAuth]);
  const token = cookies[userAuth];

  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (token) {
      navigate("/");
    }
    //eslint-disable-next-line
  }, []);

  return (
    <RegisterLayout {...registration}>
      {registrationStage === "user" && (
        <RegisterUser
          title={t("Welcome to CooperHire")}
          subTitle={t("A modern recruiting tool that helps you hire top candidates quickly")}
          {...registration}
          {...user}
        />
      )}
      {registrationStage === "company" && (
        <RegisterCompany
          title={t("Set up your company profile in just a few easy steps!")}
          subTitle={t("Give candidates a clear picture of your business and open roles")}
          {...registration}
          {...user}
          {...company}
        />
      )}
      {registrationStage === "size" && (
        <RegisterCompanySize
          title={t("Which of the following best describes your company size?")}
          subTitle={t(
            "We always tailor the customer experience - CooperHire is best suited for SMB size companies"
          )}
          {...registration}
          {...user}
          {...company}
        />
      )}
      {registrationStage === "confirm" && (
        <RegisterConfirm {...registration} {...user} {...company} />
      )}
    </RegisterLayout>
  );
};

export default Register;
