import React from "react";
import { Button, Modal, Reshaped } from "reshaped";

import store from "app/store";
import { Cross } from "icons";
import { Provider } from "react-redux";
import { baseColor } from "utils/colors/accents";
import { SpacingProvider } from "contexts/spacing/SpacingContext";
import settings from "components/styles/reshaped/settings.module.css";

export function RejectCandidateModal({ children, active, deactivate }) {
  const handleModalClose = () => {
    deactivate();
  };

  return (
    <Modal
      className={settings.modal}
      active={active}
      padding={0}
      onClose={deactivate}
      size={{ s: "auto", m: "700px" }}
    >
      <Button
        className={settings.modal__close__btn}
        size="small"
        variant="ghost"
        startIcon={() => <Cross fill={baseColor.black} />}
        onClick={handleModalClose}
      />
      <Reshaped theme="cooper">
        <Provider store={store}>
          <SpacingProvider>{children}</SpacingProvider>
        </Provider>
      </Reshaped>
    </Modal>
  );
}
