import { Text } from "reshaped";
import { SUPPORT_ERROR } from "utils/constants";
import useTranslation from "components/hooks/useTranslation";
import common from "components/styles/reshaped/reusables/common.module.css";

export const ShowSupportError = ({
  error,
  color = "critical",
  withMailTag = true,
  withTranslation = true,
  variant = "body-medium-1",
  defaultError = "Something went wrong! Please refresh the page",
}) => {
  const { t } = useTranslation();
  const supportEmail = "support@cooperhire.io";
  const supportText =
    "Something went wrong, please delete your browser cache and try again. For further help please reach out to ";
  return (
    <Text {...{ color, variant }}>
      {error && typeof error === "string" && error === SUPPORT_ERROR ? (
        <>
          {withMailTag ? (
            <>
              {withTranslation ? (
                <>
                  {t(supportText)}
                  <a className={common.hyperlink} href="mailto:support@cooperhire.io">
                    {supportEmail}
                  </a>
                </>
              ) : (
                <>
                  {supportText}
                  <a className={common.hyperlink} href="mailto:support@cooperhire.io">
                    {supportEmail}
                  </a>
                </>
              )}
            </>
          ) : (
            <>
              {withTranslation ? (
                <>
                  {t(supportText)}
                  {supportEmail}
                </>
              ) : (
                <>
                  {supportText}
                  {supportEmail}
                </>
              )}
            </>
          )}
        </>
      ) : (
        <>
          {withTranslation ? (
            t(error && typeof error === "string" ? error : defaultError)
          ) : (
            <>{error && typeof error === "string" ? error : defaultError}</>
          )}
        </>
      )}
    </Text>
  );
};
