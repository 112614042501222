import React, { useState, useEffect, useReducer, useMemo, useCallback } from "react";
import {
  Button,
  DropdownMenu,
  FormControl,
  View,
  Text,
  TextArea,
  TextField,
  Switch,
} from "reshaped";

import { ArrowDown } from "icons";
import { getCount } from "utils/getCount";
import { isString } from "utils/miniHelpers";
import useRole from "components/hooks/useRole";
import useSpacing from "components/hooks/useSpacing";
import { areKeysAvailableWithType } from "utils/miniHelpers";
import useTranslation from "components/hooks/useTranslation";
import { CompanySelectCountry } from "components/pages/settings";
import { sanitizeWord, validateErrors } from "utils/validateError";
import styles from "components/styles/reshaped/settings.module.css";
import usePublicCountries from "components/hooks/usePublicCountries";
import useUserLanguagePreference from "components/hooks/useUserLanguagePreference";
import { CUSTOMURLWITHNOSYMBOLSREGEX, VALIDURLREGEX } from "utils/RegularExpressions";
import { CooperInputWrapper, CooperLabel } from "components/reusables/reshapedCustoms";
import {
  ALL,
  reducer,
  initialState,
  INDUSTRY_TYPE,
  LANGUAGE_PREFERENCE,
  ACCEPT_DUPLICATE_CANDIDATES,
  DUPLICATE_CANDIDATE_DAYS,
} from "components/pages/settings/companyReducer";

const languagePreferenceOptions = {
  en: "English",
  de: "German",
  English: "en",
  German: "de",
};

export const CompanyForm = React.forwardRef((props, ref) => {
  const { company, isChanged, setIsChanged, cancelChanges, setCancelChanges, error, setError } =
    props;

  const [state, dispatch] = useReducer(reducer, initialState);
  const DESCRIPTIONLENGTH = 2500;

  const [urlError, setUrlError] = useState("");
  const [descriptionHeight, setDescriptionHeight] = useState(130);
  const [isDescriptionInFocus, setIsDescriptionInFocus] = useState(false);
  const [descriptionCountData, setDescriptionCountData] = useState(() => {
    if (areKeysAvailableWithType({ object: state, keys: [{ description: "string" }] })) {
      const length = state.description.length;
      return {
        count: length,
        isExceeded: length > DESCRIPTIONLENGTH ? true : false,
      };
    }

    return { count: 0, isExceeded: false };
  });

  const role = useRole();
  const { xl } = useSpacing();
  const { t } = useTranslation();
  const userLang = useUserLanguagePreference();
  const { getCountryName } = usePublicCountries();
  const validate = useCallback((errorName, name, handleError, regex) => {
    validateErrors(errorName, name, handleError, t, regex);

    //eslint-disable-next-line
  }, []);

  ref.current = {
    ...state,
  };

  const industryTypeoptions = useMemo(() => {
    return [
      { value: "micro" },
      { value: "small" },
      { value: "medium" },
      { value: "large" },
      { value: "hiring_agency" },
    ];

    //eslint-disable-next-line
  }, []);

  const duplicateCandidateDaysOptions = useMemo(
    () => [
      { label: "1 day", value: 1 },
      { label: "1 week", value: 7 },
      { label: "2 weeks", value: 14 },
      { label: "1 month", value: 30 },
      { label: "2 months", value: 60 },
      { label: "6 months", value: 180 },

      //eslint-disable-next-line
    ],
    []
  );

  const languagePreferences = useMemo(() => {
    return [{ value: "English" }, { value: "German" }];

    //eslint-disable-next-line
  }, []);

  const fieldProperties = useMemo(() => {
    return {
      name: "company name",
      website: "company website",
      phone_no: "phone number",
      country: "Country",
    };

    //eslint-disable-next-line
  }, []);

  const notRequiredFieldProperties = useMemo(() => {
    return {
      description: "description",
      city: "city",
    };

    //eslint-disable-next-line
  }, []);

  const customURLCount = useMemo(() => {
    return getCount(state.custom_url);
  }, [state.custom_url]);

  useEffect(() => {
    dispatch({
      type: ALL,
      payload: {
        name: company?.name || "",
        city: company?.city || "",
        website: company?.website || "",
        phone_no: company?.phone_no || "",
        custom_url: company?.custom_url || "",
        description: company?.description || "",
        industry_type: company?.industry_type || "",
        language_preference: languagePreferenceOptions[company?.language_preference || "en"],
        country: getCountryName({
          country: isString(company?.country_code) ? company.country_code : company?.country,
          locale: userLang,
          defaultValue: "",
        }),
        accept_duplicate_candidates: company?.accept_duplicate_candidates,
        duplicate_candidate_days: company?.duplicate_candidate_days,
      },
    });

    if (areKeysAvailableWithType({ object: company, keys: [{ description: "string" }] })) {
      const length = company.description.length;
      setDescriptionCountData({
        count: length,
        isExceeded: length > DESCRIPTIONLENGTH ? true : false,
      });
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (cancelChanges) {
      dispatch({
        type: ALL,
        payload: {
          name: company?.name || "",
          city: company?.city || "",
          website: company?.website || "",
          phone_no: company?.phone_no || "",
          custom_url: company?.custom_url || "",
          description: company?.description || "",
          industry_type: company?.industry_type || "",
          language_preference: languagePreferenceOptions[company?.language_preference || "en"],
          country: getCountryName({
            country: isString(company?.country_code) ? company.country_code : company?.country,
            locale: userLang,
            defaultValue: "",
          }),
          accept_duplicate_candidates: company?.accept_duplicate_candidates,
          duplicate_candidate_days: company?.duplicate_candidate_days,
        },
      });

      if (areKeysAvailableWithType({ object: company, keys: [{ description: "string" }] })) {
        const length = company.description.length;
        setDescriptionCountData({
          count: length,
          isExceeded: length > DESCRIPTIONLENGTH ? true : false,
        });
      }
      setCancelChanges(false);
    }
    //eslint-disable-next-line
  }, [cancelChanges]);

  const handleChange = (options) => {
    if (!isChanged) setIsChanged(true);
    if (cancelChanges) setCancelChanges(false);
    const { name, value } = options;
    const fieldPropertyName = fieldProperties[name];
    const notRequiredFieldPropertyName = notRequiredFieldProperties[name];
    if (error[fieldPropertyName]) {
      setError((previousError) => {
        return {
          ...previousError,
          [fieldPropertyName]: null,
        };
      });
    }
    if (error[notRequiredFieldPropertyName]) {
      setError((prev) => {
        const previousErrors = { ...prev };
        delete previousErrors[notRequiredFieldPropertyName];
        return previousErrors;
      });
    }
    if (name === "custom_url") {
      if (role === "member") return;
      if (CUSTOMURLWITHNOSYMBOLSREGEX.test(value)) {
        if (urlError) {
          return;
        }
        setUrlError(t("Spaces and symbols except hyphen are not allowed in Custom URL"));
        return;
      } else {
        if (urlError) {
          setUrlError("");
        }
      }
    }

    //calculating the height of textare
    if (name === "description") {
      const DEFAULTSIZE = 130;
      const DEFAULT_SIZE_FOR_FOUR_LINES_ABOVE = 100;
      const EACH_LINE_HEIGHT = 22.5;

      const NO_OF_CHARACTERS_IN_A_LINE = 90;
      const DEFAULT_LINES = 3;
      const DEFAULT_TOTAL_CHARACTER_LENGTH = NO_OF_CHARACTERS_IN_A_LINE * DEFAULT_LINES;
      const totalDescriptionCharacters = value && typeof value === "string" ? value.length : 0;
      if (totalDescriptionCharacters > DEFAULT_TOTAL_CHARACTER_LENGTH) {
        let CURRENTPOINT = DEFAULT_LINES - 1;
        let pointMet = false;
        while (!pointMet) {
          const MAX_CHARACTERS_FOR_A_CURRENT_POINT = CURRENTPOINT * NO_OF_CHARACTERS_IN_A_LINE;
          if (totalDescriptionCharacters > MAX_CHARACTERS_FOR_A_CURRENT_POINT) {
            CURRENTPOINT++;
          } else {
            pointMet = true;
          }
        }
        const TEN_BY_CURRENTPOINT = Math.ceil(CURRENTPOINT / 10) + Math.ceil(CURRENTPOINT / 10);
        const NO_OF_LINES = CURRENTPOINT >= 10 ? CURRENTPOINT + TEN_BY_CURRENTPOINT : CURRENTPOINT;
        setDescriptionHeight(() => {
          return DEFAULT_SIZE_FOR_FOUR_LINES_ABOVE + NO_OF_LINES * EACH_LINE_HEIGHT;
        });
      }
      if (totalDescriptionCharacters < DEFAULT_TOTAL_CHARACTER_LENGTH) {
        setDescriptionHeight(DEFAULTSIZE);
      }
      if (typeof value === "string") {
        const length = value.length;
        setDescriptionCountData({
          count: length,
          isExceeded: length > DESCRIPTIONLENGTH ? true : false,
        });
      }
    }
    dispatch({ type: `${name.toUpperCase()}`, payload: value });
  };

  const handleSwitchChange = (event) => {
    const { checked } = event.target;
    dispatch({ type: ACCEPT_DUPLICATE_CANDIDATES, payload: !checked });
    if (!isChanged) setIsChanged(true);
  };

  const handleDropdownChange = (option) => {
    dispatch({ type: DUPLICATE_CANDIDATE_DAYS, payload: option.value });
    if (!isChanged) setIsChanged(true);
  };

  const handleLimitedCharactersValidation = ({
    stateProperty,
    errorKey,
    labelName,
    characterLength = 240,
  }) => {
    let charactersExceeded = false;
    if (
      state &&
      state[stateProperty] &&
      typeof state[stateProperty] === "string" &&
      state[stateProperty].length > characterLength
    ) {
      charactersExceeded = true;
      setError((prev) => {
        return {
          ...prev,
          [errorKey]: t(`${labelName} should have maximum ${characterLength} characters`),
        };
      });
    }
    return charactersExceeded;
  };

  const handleIndustryType = ({ value }) => {
    if (!isChanged) setIsChanged(true);
    dispatch({ type: INDUSTRY_TYPE, payload: value });
  };

  const handleNameValidation = () => {
    const hasError = handleLimitedCharactersValidation({
      stateProperty: "name",
      errorKey: "company name",
      labelName: "Company name",
    });
    if (hasError) return;
    validate("company name", state?.name, setError);
  };

  const handleWebsiteValidation = () => {
    const hasError = handleLimitedCharactersValidation({
      stateProperty: "website",
      errorKey: "company website",
      labelName: "Company website",
    });
    if (hasError) return;
    validate("company website", state?.website, setError, VALIDURLREGEX);
  };

  const handleNumberValidation = () => {
    validate(
      "phone number",
      state?.phone_no,
      setError,
      //eslint-disable-next-line
      /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/
    );
  };

  const handleDescriptionFocus = () => {
    setIsDescriptionInFocus(true);
  };

  const handleDescriptionBlur = () => {
    setIsDescriptionInFocus(false);
    handleLimitedCharactersValidation({
      stateProperty: "description",
      errorKey: "description",
      labelName: "Description",
      characterLength: DESCRIPTIONLENGTH,
    });
  };

  const handleCustomURLValidation = () => {
    if (urlError) {
      setUrlError("");
    }
  };

  const handleLanguagePreference = ({ value }) => {
    if (!isChanged) setIsChanged(true);
    dispatch({ type: LANGUAGE_PREFERENCE, payload: value });
  };

  return (
    <>
      <CooperInputWrapper>
        <FormControl
          required
          hasError={error["company name"] && typeof error["company name"] !== "object"}
        >
          <FormControl.Label>
            <CooperLabel text={t("Company name")} />
          </FormControl.Label>
          <TextField
            name="name"
            value={state.name}
            onChange={handleChange}
            inputAttributes={{
              type: "text",
              onBlur: handleNameValidation,
            }}
          />
          <FormControl.Error>{error["company name"]}</FormControl.Error>
        </FormControl>
      </CooperInputWrapper>
      <CooperInputWrapper>
        <FormControl
          required
          hasError={error["company website"] && typeof error["company website"] !== "object"}
        >
          <FormControl.Label>
            <CooperLabel text={t("Company website")} />
          </FormControl.Label>
          <TextField
            name="website"
            value={state.website}
            onChange={handleChange}
            inputAttributes={{
              type: "text",
              onBlur: handleWebsiteValidation,
            }}
          />
          <FormControl.Error>{error["company website"]}</FormControl.Error>
        </FormControl>
      </CooperInputWrapper>
      <CooperInputWrapper>
        <FormControl hasError={error["description"] && typeof error["description"] !== "object"}>
          <FormControl.Label>
            <CooperLabel text={t("Brief company description")} />
          </FormControl.Label>
          <TextArea
            className={`${styles.textarea}`}
            name="description"
            value={state.description}
            placeholder={t("Company description in 2500 characters...")}
            attributes={{
              style: {
                height: `${descriptionHeight}px`,
              },
              onBlur: handleDescriptionBlur,
              onFocus: handleDescriptionFocus,
            }}
            onChange={handleChange}
          />
          <View direction="row" gap={xl}>
            <View.Item grow>
              <FormControl.Error>{error["description"]}</FormControl.Error>
            </View.Item>
            {isDescriptionInFocus && (
              <Text
                align={"end"}
                variant="body-2"
                color={descriptionCountData.isExceeded ? "critical" : "neutral"}
              >
                {descriptionCountData.count}/{DESCRIPTIONLENGTH}
              </Text>
            )}
          </View>
        </FormControl>
      </CooperInputWrapper>
      <View direction="row" gap={xl} attributes={{ style: { zIndex: 10 } }}>
        <View.Item grow>
          <CooperInputWrapper>
            <FormControl
              required
              hasError={error["phone number"] && typeof error["phone number"] !== "object"}
            >
              <FormControl.Label>
                <CooperLabel text={t("Phone number")} />
              </FormControl.Label>
              <TextField
                name="phone_no"
                value={state.phone_no}
                onChange={handleChange}
                inputAttributes={{
                  type: "text",
                  onBlur: handleNumberValidation,
                }}
              />
              <FormControl.Error>{error["phone number"]}</FormControl.Error>
            </FormControl>
          </CooperInputWrapper>
        </View.Item>
        <View.Item grow={true}>
          <CompanySelectCountry
            state={state}
            dispatch={dispatch}
            error={error}
            setError={setError}
            stateKey={"country"}
            errorKey={"Country"}
            reducerKey={"COUNTRY"}
            isChanged={isChanged}
            setIsChanged={setIsChanged}
          />
        </View.Item>
      </View>
      <View direction="row" gap={xl} attributes={{ style: { zIndex: 1 } }}>
        <View.Item grow>
          <CooperInputWrapper>
            <FormControl hasError={error["city"] && typeof error["city"] !== "object"}>
              <FormControl.Label>
                <CooperLabel text={t("City")} />
              </FormControl.Label>
              <TextField
                value={state.city}
                name="city"
                onChange={handleChange}
                inputAttributes={{
                  type: "text",
                  onBlur: () =>
                    handleLimitedCharactersValidation({
                      stateProperty: "city",
                      errorKey: "city",
                      labelName: "City",
                    }),
                }}
              />
              <FormControl.Error>{error["city"]}</FormControl.Error>
            </FormControl>
          </CooperInputWrapper>
        </View.Item>
        <View.Item grow={true} className={styles.dropdown__wrapper}>
          <CooperInputWrapper>
            <FormControl required>
              <FormControl.Label>
                <CooperLabel text={t("Company size")} />
              </FormControl.Label>
              <DropdownMenu position="bottom-start">
                <DropdownMenu.Trigger>
                  {(attributes) => (
                    <Button
                      className={styles.dropdown__button}
                      variant="outline"
                      fullWidth
                      attributes={attributes}
                      endIcon={ArrowDown}
                    >
                      <Text color="neutral" variant="body-2">
                        {state.industry_type !== ""
                          ? t(sanitizeWord(state.industry_type, true))
                          : ""}
                      </Text>
                    </Button>
                  )}
                </DropdownMenu.Trigger>
                <DropdownMenu.Content>
                  {industryTypeoptions.map((industryType, index) => {
                    return (
                      <DropdownMenu.Item
                        onClick={() => handleIndustryType({ value: industryType.value })}
                        key={index}
                      >
                        {t(sanitizeWord(industryType.value, true))}
                      </DropdownMenu.Item>
                    );
                  })}
                </DropdownMenu.Content>
              </DropdownMenu>
            </FormControl>
          </CooperInputWrapper>
        </View.Item>
      </View>
      <View.Item className={styles.dropdown__wrapper}>
        <CooperInputWrapper>
          <FormControl>
            <FormControl.Label>
              <CooperLabel text={t("Language preference of company page")} />
            </FormControl.Label>
            <DropdownMenu position="bottom-start">
              <DropdownMenu.Trigger>
                {(attributes) => (
                  <Button
                    className={styles.dropdown__button}
                    variant="outline"
                    fullWidth
                    attributes={attributes}
                    endIcon={ArrowDown}
                  >
                    <Text color="neutral" variant="body-2">
                      {state.language_preference !== "" ? t(state.language_preference) : ""}
                    </Text>
                  </Button>
                )}
              </DropdownMenu.Trigger>
              <DropdownMenu.Content>
                {languagePreferences.map((language, index) => {
                  return (
                    <DropdownMenu.Item
                      onClick={() => handleLanguagePreference({ value: language.value })}
                      key={index}
                    >
                      {t(language.value)}
                    </DropdownMenu.Item>
                  );
                })}
              </DropdownMenu.Content>
            </DropdownMenu>
          </FormControl>
        </CooperInputWrapper>
      </View.Item>
      <CooperInputWrapper>
        <FormControl hasError={urlError !== ""}>
          <FormControl.Label>
            <CooperLabel text={t("Custom URL")} />
          </FormControl.Label>
          <View as="div" className={styles.textarea__wrapper}>
            <View className={styles.textarea__limit}>
              <Text variant="caption-1">{customURLCount}/50</Text>
            </View>
            <View
              direction="row"
              gap={0}
              attributes={{
                style: {
                  position: "relative",
                },
                title: role === "member" ? t("Admin access") : "",
              }}
            >
              <Text
                hasError={urlError !== ""}
                variant="body-2"
                color="neutral-faded"
                align="center"
                className={styles.endpoint}
              >
                jobs.cooperhire.io/companies/
              </Text>
              <View.Item grow={true}>
                <TextField
                  disabled={role === "member"}
                  name="custom_url"
                  value={state.custom_url}
                  className={styles.url__input}
                  onChange={handleChange}
                  inputAttributes={{
                    type: "text",
                    onBlur: handleCustomURLValidation,
                  }}
                />
              </View.Item>
            </View>
          </View>
          <FormControl.Error>{urlError}</FormControl.Error>
        </FormControl>
      </CooperInputWrapper>
      <Text variant="body-2" color="neutral-faded">
        <strong>{t("ProTip!")}</strong>
        {t(` Use hyphen (-) instead of space in Custom URL`)}
      </Text>
      <CooperInputWrapper>
        <FormControl>
          <FormControl.Label>
            <CooperLabel
              text={t("Reject duplicate applications from the same candidate for the same job")}
            />
          </FormControl.Label>
          <Switch
            name="accept_duplicate_candidates"
            checked={!state.accept_duplicate_candidates}
            inputAttributes={{
              onClick: (e) => {
                handleSwitchChange(e);
              },
            }}
          >
            {t("Reject duplicate applications from the same candidate for the same job")}
          </Switch>
        </FormControl>
      </CooperInputWrapper>
      {!state.accept_duplicate_candidates && (
        <CooperInputWrapper>
          <FormControl>
            <FormControl.Label>
              <CooperLabel
                text={t("How long should duplicate applications to the same job be blocked?")}
              />
            </FormControl.Label>
            <DropdownMenu position="bottom-start">
              <DropdownMenu.Trigger>
                {(attributes) => (
                  <Button
                    className={styles.dropdown_button}
                    variant="outline"
                    attributes={attributes}
                    endIcon={ArrowDown}
                  >
                    <Text color="neutral" variant="body-2">
                      {t(
                        duplicateCandidateDaysOptions.find(
                          (option) => option.value === state.duplicate_candidate_days
                        )?.label
                      ) || t("Select an option")}
                    </Text>
                  </Button>
                )}
              </DropdownMenu.Trigger>
              <DropdownMenu.Content>
                {duplicateCandidateDaysOptions.map((option, index) => (
                  <DropdownMenu.Item
                    onClick={() => handleDropdownChange(option)}
                    key={index}
                    selected={state.duplicate_candidate_days === option.value}
                  >
                    {t(option.label)}
                  </DropdownMenu.Item>
                ))}
              </DropdownMenu.Content>
            </DropdownMenu>
          </FormControl>
        </CooperInputWrapper>
      )}
    </>
  );
});
