import { baseColor } from "utils/colors/accents";

export const Pencil = ({ width = "16", color = baseColor.primary }) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_7_730)">
        <path
          d="M11.3333 1.99998C11.5031 1.79933 11.7131 1.63601 11.95 1.52043C12.1869 1.40485 12.4454 1.33953 12.7091 1.32865C12.9728 1.31777 13.2359 1.36156 13.4816 1.45723C13.7273 1.55291 13.9503 1.69836 14.1362 1.88432C14.3222 2.07029 14.4671 2.29268 14.5617 2.53734C14.6563 2.78199 14.6986 3.04353 14.6857 3.3053C14.6729 3.56706 14.6053 3.8233 14.4873 4.05769C14.3692 4.29207 14.2033 4.49947 14 4.66664L5.00001 13.6666L1.33334 14.6666L2.33334 11L11.3333 1.99998Z"
          stroke={color}
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 3.33337L12.6667 6.00004"
          stroke={color}
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_7_730">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
