import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Template } from "components/pages/settings";
import useSpacing from "components/hooks/useSpacing";
import { Button, Text, View, useToast } from "reshaped";
import useTemplates from "components/hooks/useTemplates";
import { ShowToastWithTranslation } from "utils/showToast";
import useTranslation from "components/hooks/useTranslation";
import { exceededMessageTemplatesLimit, isCronjobDowngradeTriggered } from "utils/pages/settings";
import {
  ReshapedLoader,
  TextWithEclipses,
  OperationFailedWithTranslation,
} from "components/reusables";

export function ListTemplates({ handleChangeView, handleViewingTemplate }) {
  const toast = useToast();
  const { t } = useTranslation();
  const { md, lg, xl } = useSpacing();
  const { getTemplates } = useTemplates();
  const { templates, subscriptions } = useSelector((state) => state);

  const { subscription } = subscriptions;
  const { data, loading, error } = templates;

  useEffect(() => {
    if (!Array.isArray(data)) {
      getTemplates();
    }

    //eslint-disable-next-line
  }, []);

  const handleView = (view) => {
    if (view === "create") {
      if (exceededMessageTemplatesLimit(subscription)) {
        ShowToastWithTranslation({
          toast,
          Children: OperationFailedWithTranslation,
          text: t(
            `Please update your payment plan before adding more templates. You cannot add more than {{subscription.max_message_templates}} templates with the {{subscription.subscription_type}} plan`
          )
            .replace("{{subscription.max_message_templates}}", subscription.max_message_templates)
            .replace("{{subscription.subscription_type}}", subscription.subscription_type),
        });
        return;
      }

      if (isCronjobDowngradeTriggered(subscription)) {
        ShowToastWithTranslation({
          toast,
          Children: OperationFailedWithTranslation,
          text: t(
            "You have already scheduled a downgrade for your subscription, please wait for the downgrade to take effect before changing the plan!"
          ),
        });
        return;
      }
    }

    if (handleChangeView && typeof handleChangeView === "function") handleChangeView(view);
  };

  const handleTemplateClick = ({ data }) => {
    handleViewingTemplate({ data });
    handleView("update");
  };

  const handleRetryGetTemplates = async () => {
    getTemplates();
  };

  return (
    <View padding={[xl, 0]} height="100%">
      {loading && <ReshapedLoader />}
      {!loading && (
        <>
          {!error && Array.isArray(data) && (
            <View gap={md}>
              <View direction="row" align="center" justify="end" width="100%">
                <Button
                  size="small"
                  color="primary"
                  variant="ghost"
                  onClick={() => {
                    handleView("create");
                  }}
                >
                  <Text variant="body-2">+ {t("Create a new template")}</Text>
                </Button>
              </View>
              <View width="100%" direction="row" align="center" padding={md} gap={xl}>
                <View.Item columns={4}>
                  <TextWithEclipses
                    align="start"
                    color="neutral"
                    text={t("Template Name")}
                    characterLimit={35}
                    variant="body-strong-2"
                  />
                </View.Item>
                <View.Item columns={5}>
                  <TextWithEclipses
                    align="start"
                    color="neutral"
                    text={t("Subject")}
                    characterLimit={35}
                    variant="body-strong-2"
                  />
                </View.Item>
                <View.Item columns={3}>
                  <TextWithEclipses
                    align="end"
                    color="neutral"
                    text={t("Default Template")}
                    characterLimit={35}
                    variant="body-strong-2"
                  />
                </View.Item>
              </View>
              <View gap={md}>
                {data.map((each) => {
                  return <Template key={each.id} data={each} onClick={handleTemplateClick} />;
                })}
              </View>
            </View>
          )}
          {error && (
            <View height="100%" align="center" justify="center" gap={lg}>
              <Text variant="body-strong-1" color="critical">
                {t(error)}
              </Text>
              <Button color="critical" onClick={handleRetryGetTemplates}>
                {t("Please try again.")}
              </Button>
            </View>
          )}
        </>
      )}
    </View>
  );
}
