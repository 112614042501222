import { useState } from "react";
import { Button, Divider, Text, useToast, View } from "reshaped";

import { Cart } from "icons";
import { backendAPI } from "utils/axios";
import { useSelector } from "react-redux";
import useCart from "components/hooks/useCart";
import useSpacing from "components/hooks/useSpacing";
import { ReshapedLoader } from "components/reusables";
import useCurrency from "components/hooks/useCurrency";
import { CartItem } from "components/pages/promote/slot";
import { reloadOnUnauthorized } from "utils/checkForAuth";
import { ShowToastWithTranslation } from "utils/showToast";
import useTranslation from "components/hooks/useTranslation";
import styles from "components/styles/reshaped/promote/promote.module.css";
import { OperationFailedWithTranslation } from "components/reusables/OperationFailed";

export const MiniCart = () => {
  const [checkoutLoading, setCheckoutLoading] = useState(false);

  const toast = useToast();
  const { t } = useTranslation();
  const { xs, sm, lg, xl } = useSpacing();
  const { currencySymbol } = useCurrency();
  const { loading, slots, cart_id } = useSelector((state) => state.cart);
  const { originalPrice, discount, total, inCartSlots } = useCart();

  const handleCheckout = async () => {
    setCheckoutLoading(true);
    let isFailed = false;

    if (!Array.isArray(slots) || slots.length <= 0) {
      isFailed = true;
      ShowToastWithTranslation({
        toast,
        Children: OperationFailedWithTranslation,
        text: t("Your cart is empty!"),
      });
    }

    if (!isFailed && !cart_id) {
      isFailed = true;
      ShowToastWithTranslation({
        toast,
        Children: OperationFailedWithTranslation,
        text: t("Operation unsuccessful!"),
      });
    }

    if (isFailed) return setCheckoutLoading(false);

    try {
      const response = await backendAPI.post("/checkout", { cart_id });
      setCheckoutLoading(false);
      if (response.data && response.data.status === 200 && response.data.url) {
        return (window.location.href = response.data.url);
      }
      ShowToastWithTranslation({
        toast,
        Children: OperationFailedWithTranslation,
        text: t("Operation unsuccessful!"),
      });
    } catch (error) {
      reloadOnUnauthorized(error);
      setCheckoutLoading(false);
      ShowToastWithTranslation({
        toast,
        Children: OperationFailedWithTranslation,
        text: t("Operation unsuccessful!"),
      });
    }
  };

  return (
    <View
      padding={xl}
      gap={lg}
      borderRadius="medium"
      shadow="base"
      width="460px"
      className={styles.sticky__cart}
    >
      <Text variant="title-2">{t("Products")}</Text>
      {!loading && (
        <>
          <View
            className={`${
              Array.isArray(inCartSlots) && inCartSlots.length > 2 ? styles.more__shadow : ""
            }`}
          >
            <View
              gap={lg + xs}
              maxHeight="370px"
              className="no__scroll"
              attributes={{
                style: {
                  paddingBlockEnd: `${
                    Array.isArray(inCartSlots) && inCartSlots.length > 2
                      ? "var(--spacing-xl)"
                      : "var(--spacing-sm)"
                  }`,
                },
              }}
            >
              {Array.isArray(inCartSlots) && inCartSlots.length > 0 ? (
                <>
                  {inCartSlots.map((slot) => {
                    return <CartItem key={slot.id} slot={slot} />;
                  })}
                </>
              ) : (
                <View
                  direction="row"
                  borderRadius="medium"
                  backgroundColor="neutral-faded"
                  padding={xl}
                >
                  <Text variant="body-strong-1" color="neutral-faded">
                    {t("No slots added!")}
                  </Text>
                </View>
              )}
            </View>
          </View>
          <Divider
            attributes={{
              style: {
                background: "var(--rs-color-foreground-neutral-faded)",
              },
            }}
          />
          <View gap={sm}>
            <Price
              what={"Original Price"}
              price={`${currencySymbol}${originalPrice}`}
              textColor="neutral-faded"
            />
            <Price
              what={"Discount"}
              price={`-${currencySymbol}${discount}`}
              textColor="neutral-faded"
            />
            <Price what={"Total"} price={`${currencySymbol}${total}`} textColor="neutral" />
          </View>
          <Button
            color="primary"
            startIcon={Cart}
            fullWidth
            onClick={handleCheckout}
            loading={checkoutLoading}
          >
            {t("Go to cart")}
          </Button>
        </>
      )}
      {loading && (
        <View height="300px" width="100%">
          <ReshapedLoader size="medium" />
        </View>
      )}
    </View>
  );
};

const Price = ({ what, price, textColor }) => {
  const { xl } = useSpacing();
  const { t } = useTranslation();

  return (
    <View direction="row" align="center" gap={xl}>
      <View.Item grow>
        <Text variant="body-1" color={textColor ? textColor : "neutral"}>
          {t(what)}
        </Text>
      </View.Item>
      <Text
        variant={what === "Total" ? "body-strong-1" : "body-1"}
        color={textColor ? textColor : "neutral"}
      >
        {price}
      </Text>
    </View>
  );
};
