import { Text, View } from "reshaped";
import { ClickableStar } from "icons";
import useRating from "components/hooks/useRating";
import useSpacing from "components/hooks/useSpacing";
import useTranslation from "components/hooks/useTranslation";
import styles from "components/styles/reshaped/candidates.module.css";

export const InteractiveRating = ({ maxPoints = 5, rating = 0, setRating }) => {
  const points = Array.from(Array(maxPoints + 1).keys());
  points.shift();

  const { t } = useTranslation();
  const { md, xl } = useSpacing();
  const ratingProfile = useRating({ rating });

  const handleRatingChange = (e) => {
    const target = e.currentTarget;
    const id =
      (typeof target.id === "string" || typeof target.id === "number") && !isNaN(Number(target.id))
        ? Number(target.id)
        : 0;
    const name =
      (typeof target.name === "string" || typeof target.name === "number") &&
      !isNaN(Number(target.name))
        ? Number(target.name)
        : 0;
    setRating(id || name);
  };

  return (
    <View gap={xl} direction="row" align="center" justify="start">
      <View direction="row" align="center" justify="start" gap={md}>
        {points.map((point) => {
          return (
            <View
              key={point}
              className={styles.clickable__star}
              attributes={{
                onClick: handleRatingChange,
                id: point,
                name: point,
                style: {
                  cursor: "pointer",
                },
              }}
            >
              {point <= rating && (
                <ClickableStar
                  fillColor={ratingProfile.fill}
                  strokeColor={ratingProfile.strokeColor}
                />
              )}
              {point > rating && (
                <ClickableStar
                  fillColor={ratingProfile.emptyFill}
                  strokeColor={ratingProfile.emptyStrokeColor}
                />
              )}
            </View>
          );
        })}
      </View>
      {ratingProfile?.text && (
        <Text
          variant="caption-1"
          attributes={{
            style: {
              color: ratingProfile.textColor,
            },
          }}
        >
          {t(ratingProfile.text)}
        </Text>
      )}
    </View>
  );
};
