import { useState, useEffect } from "react";
import { View } from "reshaped";

import { metaSlice } from "redux/meta";
import { accent } from "utils/colors/accents";
import { useSearchParams } from "react-router-dom";
import useSpacing from "components/hooks/useSpacing";
import { placeholdersSlice } from "redux/placeholders";
import { useDispatch, useSelector } from "react-redux";
import { Subscriptions } from "components/pages/settings";
import { SideBanner } from "components/reusables/layouts";
import { getPlaceholderFromAPI } from "utils/pages/settings";
import useTranslation from "components/hooks/useTranslation";
import { ContainerWrapper } from "components/reusables/common";
import styles from "components/styles/reshaped/settings.module.css";
import {
  Team,
  Company,
  Templates,
  TeamBanner,
  UserProfile,
  SettingsNav,
  CompanyBanner,
  UserProfileBanner,
} from "components/pages/settings";

const Settings = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { xl, sm, md } = useSpacing();
  const [searchParams, setSearchParams] = useSearchParams();
  const { meta, placeholders } = useSelector((state) => state);

  const { toProfileShortcut } = meta;
  const { data: placeholdersData } = placeholders;

  const [presentLayout, setPresentLayout] = useState(() => {
    const settingStates = {
      userprofile: true,
      company: true,
      team: true,
      subscriptions: true,
    };
    let param = searchParams.get("to");
    if (param) {
      param = searchParams.get("to").toLowerCase();
      if (param === "userprofile" || param === "user profile") return "user profile";
      return settingStates[param] ? param : "company";
    }
    return "company";
  });

  useEffect(() => {
    if (toProfileShortcut) {
      setPresentLayout("user profile");
    }

    //eslint-disable-next-line
  }, [toProfileShortcut]);

  useEffect(() => {
    async function getPlaceholders() {
      dispatch(placeholdersSlice.actions.setLoading(true));
      const result = await getPlaceholderFromAPI();
      if (result.error) {
        dispatch(
          placeholdersSlice.actions.setProperties({
            loading: false,
            error: result.error,
            data: null,
          })
        );
      }
      if (result.data) {
        dispatch(
          placeholdersSlice.actions.setProperties({
            loading: false,
            data: result.data,
            error: null,
          })
        );
      }
    }

    if (!placeholdersData) {
      getPlaceholders();
    }
    //eslint-disable-next-line
  }, []);

  const handleLayoutChange = ({ value }) => {
    if (toProfileShortcut) dispatch(metaSlice.actions.setToProfileShortcut(false));
    setSearchParams({ to: value });
    setPresentLayout(value);
  };

  return (
    <ContainerWrapper
      fullHeight={true}
      wrapperStyles={{ backgroundColor: "var(--rs-color-white)" }}
    >
      <View direction="row" align="stretch" padding={[xl + sm, 0]} gap={xl + sm}>
        {presentLayout === "company" && (
          <SideBanner
            text={`${t(
              "Easily attract talent by setting up your company details and career page."
            )}`}
            Svg={CompanyBanner}
          />
        )}
        {presentLayout === "team" && (
          <SideBanner
            backgroundColor="var(--rs-color-background-primary-faded)"
            text={t("Add and manage new team members to better collaborate on hiring.")}
            Svg={TeamBanner}
          />
        )}
        {presentLayout === "user profile" && (
          <SideBanner
            backgroundColor={accent["4-200"]}
            text={`${t("Show your team who you are by customising your profile and settings.")}`}
            Svg={UserProfileBanner}
          />
        )}
        {presentLayout === "subscriptions" && (
          <SideBanner
            backgroundColor={accent["4-200"]}
            text={`${t("Manage your subscriptions and billing details")}`}
            Svg={UserProfileBanner}
          />
        )}
        {presentLayout === "templates" && (
          <SideBanner
            backgroundColor={accent["4-200"]}
            text={`${t("Show your team who you are by customising your profile and settings.")}`}
            Svg={UserProfileBanner}
          />
        )}

        <View.Item grow>
          <View
            height="100%"
            borderRadius="large"
            className={styles.panel}
            padding={[xl + sm, xl + md]}
          >
            <SettingsNav currentTab={presentLayout} handleChange={handleLayoutChange} />
            {presentLayout === "team" && <Team />}
            {presentLayout === "company" && <Company />}
            {presentLayout === "templates" && <Templates />}
            {presentLayout === "user profile" && <UserProfile />}
            {presentLayout === "subscriptions" && <Subscriptions />}
          </View>
        </View.Item>
      </View>
    </ContainerWrapper>
  );
};

export default Settings;
