import { baseColor } from "utils/colors/accents";

export const Camera = ({ color = baseColor.black }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.37596 3.33397C8.51506 3.12533 8.74924 3 9 3H15C15.2508 3 15.4849 3.12533 15.624 3.33397L16.9014 5.25H19.5C20.0967 5.25 20.669 5.48705 21.091 5.90901C21.5129 6.33097 21.75 6.90326 21.75 7.5V18C21.75 18.5967 21.5129 19.169 21.091 19.591C20.669 20.0129 20.0967 20.25 19.5 20.25H4.5C3.90326 20.25 3.33097 20.0129 2.90901 19.591C2.48705 19.169 2.25 18.5967 2.25 18V7.5C2.25 6.90326 2.48705 6.33097 2.90901 5.90901C3.33097 5.48705 3.90326 5.25 4.5 5.25H7.09861L8.37596 3.33397ZM9.40139 4.5L8.12404 6.41602C7.98494 6.62467 7.75076 6.75 7.5 6.75H4.5C4.30109 6.75 4.11032 6.82902 3.96967 6.96967C3.82902 7.11032 3.75 7.30109 3.75 7.5V18C3.75 18.1989 3.82902 18.3897 3.96967 18.5303C4.11032 18.671 4.30109 18.75 4.5 18.75H19.5C19.6989 18.75 19.8897 18.671 20.0303 18.5303C20.171 18.3897 20.25 18.1989 20.25 18V7.5C20.25 7.30109 20.171 7.11032 20.0303 6.96967C19.8897 6.82902 19.6989 6.75 19.5 6.75H16.5C16.2492 6.75 16.0151 6.62467 15.876 6.41602L14.5986 4.5H9.40139Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 9.75C10.5503 9.75 9.375 10.9253 9.375 12.375C9.375 13.8247 10.5503 15 12 15C13.4497 15 14.625 13.8247 14.625 12.375C14.625 10.9253 13.4497 9.75 12 9.75ZM7.875 12.375C7.875 10.0968 9.72183 8.25 12 8.25C14.2782 8.25 16.125 10.0968 16.125 12.375C16.125 14.6532 14.2782 16.5 12 16.5C9.72183 16.5 7.875 14.6532 7.875 12.375Z"
        fill={color}
      />
    </svg>
  );
};
