export const PDF = () => {
  return (
    <svg width="16" height="22" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.7968 0.731445H5.69639L0.833008 5.70916V19.787C0.833008 20.567 1.44731 21.2017 2.20236 21.2017H13.7968C14.5519 21.2017 15.1662 20.567 15.1662 19.787V2.14621C15.1662 1.3661 14.5519 0.731445 13.7968 0.731445ZM14.694 19.787C14.694 20.298 14.2915 20.7139 13.7968 20.7139H2.20238C1.7077 20.7139 1.30522 20.298 1.30522 19.787V5.70916H4.52451C5.16905 5.70916 5.69639 5.16941 5.69639 4.50973V1.21929H13.7968C14.2915 1.21929 14.694 1.63512 14.694 2.14621V19.787Z"
        fill="#C0392B"
      />
    </svg>
  );
};

export const DOCX = () => {
  return (
    <svg width="16" height="24" viewBox="0 0 16 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6 6.76863V21.7749C15.6 22.7697 14.8773 23.5758 13.9862 23.5758H1.6138C0.722376 23.5758 0 22.7697 0 21.7749V2.56701C0 1.57229 0.722376 0.766113 1.6138 0.766113H10.2205L15.6 6.76863Z"
        fill="#067FCB"
      />
    </svg>
  );
};
