import { useEffect, useMemo, useState } from "react";

import { useLocation } from "react-router-dom";
import { areKeysAvailableWithType } from "utils/miniHelpers";

const usePreviousPageStateReset = () => {
  const { pathname } = useLocation();

  const optimizedPageResetters = useMemo(() => {
    return {
      // candidates: () => {
      // dispatch(candidateSlice.actions.reset());
      // dispatch(sortingSlice.actions.setCandidatesPageSorting());
      // dispatch(
      //   filtersSlice.actions.resetPageFilter({ page: "candidates", types: ["jobs", "stages"] })
      // );
      // },
    };
    //eslint-disable-next-line
  }, []);

  const [previousPage, setPreviousPage] = useState(() => {
    const SLOTPAGEREGEX = /^\/promote\/\d+\/slot$/;

    const pages = {
      "/": "dashboard",
      "/jobs": "jobs",
      "/candidates": "candidates",
      "/promote": "promote",
      "/bookings": "bookings",
      "/settings": "settings",
    };
    return SLOTPAGEREGEX.test(pathname) ? "slot" : pages[pathname] ? pages[pathname] : "public";
  });

  useEffect(() => {
    const pages = {
      "/": "dashboard",
      "/jobs": "jobs",
      "/candidates": "candidates",
      "/promote": "promote",
      "/bookings": "bookings",
      "/settings": "settings",
    };

    const SLOTPAGEREGEX = /^\/promote\/\d+\/slot$/;

    const currentPage = SLOTPAGEREGEX.test(pathname)
      ? "slot"
      : pages[pathname]
      ? pages[pathname]
      : "public";

    if (currentPage !== previousPage) {
      setPreviousPage(currentPage);
      if (
        previousPage !== "public" &&
        currentPage !== "public" &&
        areKeysAvailableWithType({
          object: optimizedPageResetters,
          keys: [{ [previousPage]: "function" }],
        })
      ) {
        optimizedPageResetters[previousPage]();
      }
    }

    //eslint-disable-next-line
  }, [pathname]);
};

export default usePreviousPageStateReset;
