import { useMemo, useState } from "react";
import { FormControl, Text, View } from "reshaped";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { TextEditor } from "components/reusables";
import useSpacing from "components/hooks/useSpacing";
import { isEditorStateEmpty } from "utils/EditorHelpers";
import { stepOneProgressHelper } from "utils/jobStepProgress";
import { jobInputCharacterlimits } from "utils/jobValidationHelper";
import common from "components/styles/reshaped/reusables/common.module.css";

const NewJobTextEditor = (props) => {
  const {
    title,
    errors,
    propTitle,
    setErrors,
    placeholder = "",
    isRequired = false,
    setStepOneProgress,
    inputValue = "",
    setInputValue,
  } = props;

  const [isInfocus, setIsInFocus] = useState(false);
  const [textCountData, setTextCountData] = useState(() => {
    if (inputValue && inputValue.getCurrentContent) {
      const plainText = inputValue.getCurrentContent().getPlainText();
      if (typeof plainText === "string") {
        const length = plainText.length;
        return {
          count: length,
          isExceeded: length > jobInputCharacterlimits[propTitle] ? true : false,
        };
      }
    }

    return { count: 0, isExceeded: false };
  });

  const { xs, xl } = useSpacing();
  const id = useMemo(
    () => propTitle.toLowerCase(),

    //eslint-disable-next-line
    []
  );

  const handleEditorState = ({ state }) => {
    const plainText = state.getCurrentContent().getPlainText();

    if (typeof plainText === "string") {
      const length = plainText.length;
      setTextCountData({
        count: length,
        isExceeded: length > jobInputCharacterlimits[propTitle] ? true : false,
      });
    }

    if (isRequired && errors[id]) {
      setErrors((previousError) => {
        return {
          ...previousError,
          [id]: null,
        };
      });
    }

    if (!isRequired && errors[id]) {
      setErrors((previousError) => {
        const prev = { ...previousError };
        delete prev[id];
        return {
          ...prev,
        };
      });
    }
    // setInputValue(state);
  };

  const handleFocus = () => {
    const toolbarElement = document.querySelector(`.toolbar__${propTitle}`);
    if (toolbarElement) toolbarElement.classList.add("active");
    let placeholderElement = document.querySelector(
      ".public-DraftEditorPlaceholder-hasFocus.public-DraftEditorPlaceholder-root"
    );
    setTimeout(() => {
      placeholderElement = document.querySelector(
        ".public-DraftEditorPlaceholder-hasFocus.public-DraftEditorPlaceholder-root"
      );
      if (placeholderElement) {
        placeholderElement.setAttribute("id", `hide-placeholder-${propTitle}`);
      }
    }, 10);
    setIsInFocus(true);
  };

  const handleBlur = () => {
    setIsInFocus(false);

    const toolbarElement = document.querySelector(`.toolbar__${propTitle}.active`);
    if (toolbarElement) toolbarElement.classList.remove("active");

    const isEmpty = isEditorStateEmpty({ state: inputValue });
    if (isEmpty) {
      const placeholderElement = document.getElementById(`hide-placeholder-${propTitle}`);
      if (placeholderElement) {
        placeholderElement.removeAttribute("id");
      }
    }

    const value = inputValue.getCurrentContent().getPlainText();
    if (!isRequired) {
      const hasError = props.handleNotRequiredValidation({ id, value });
      if (!hasError) {
        stepOneProgressHelper(propTitle, setStepOneProgress, "add");
      }
      if (hasError) {
        //removing error input if it has one
        stepOneProgressHelper(propTitle, setStepOneProgress, "remove");
      }
      return;
    }
    const hasError = props.handleValidation({ id, value });
    if (!hasError) {
      stepOneProgressHelper(propTitle, setStepOneProgress, "add");
    }
    if (hasError) {
      //removing error input if it has one
      stepOneProgressHelper(propTitle, setStepOneProgress, "remove");
    }
  };

  return (
    <View>
      <FormControl.Label>
        <View direction="row">
          {title}
          {isRequired && <Text color="critical">*</Text>}
        </View>
      </FormControl.Label>
      <View
        gap={xs}
        borderRadius="small"
        attributes={{
          onFocus: handleFocus,
          onBlur: handleBlur,
        }}
      >
        <TextEditor
          state={inputValue}
          setState={setInputValue}
          handleChange={handleEditorState}
          wrapperClassName={`${isInfocus ? common.in__focus : ""} ${
            errors[id] && typeof errors[id] !== "object" ? common.error__field : ""
          }`}
          toolbarClassName={`toolbar__${propTitle}`}
          placeholder={placeholder}
          attributes={"short intro"}
        />
        <View direction="row" width="100%" gap={xl}>
          <View.Item grow>
            {errors[id] && typeof errors[id] !== "object" && (
              <Text color="critical" variant="body-2">
                {errors[id]}
              </Text>
            )}
          </View.Item>
          {isInfocus && (
            <Text
              align={"end"}
              variant="body-2"
              color={textCountData.isExceeded ? "critical" : "neutral"}
            >
              {textCountData.count}/{jobInputCharacterlimits[propTitle]}
            </Text>
          )}
        </View>
      </View>
    </View>
  );
};

export default NewJobTextEditor;
