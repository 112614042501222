import { View } from "reshaped";
import { useSelector } from "react-redux";
import useSpacing from "components/hooks/useSpacing";
import { ReshapedLoader } from "components/reusables";
import {
  BookingsNav,
  BookingsInventory,
  ActivePastBookings,
} from "components/pages/bookings/index";

export const BookingsSection = ({ bookingsTab, setBookingsTab }) => {
  const { lg } = useSpacing();
  const { loading } = useSelector((state) => state.bookings);

  return (
    <View gap={lg} height="100%">
      <BookingsNav {...{ bookingsTab, setBookingsTab }} />
      {loading && (
        <View.Item grow>
          <ReshapedLoader />
        </View.Item>
      )}
      {!loading && (
        <View.Item grow>
          {bookingsTab === "inventory" && <BookingsInventory />}
          {bookingsTab === "active" && <ActivePastBookings setBookingsTab={setBookingsTab} />}
        </View.Item>
      )}
    </View>
  );
};
