import { useCallback } from "react";
import { jobSlice } from "redux/job";
import { cartSlice } from "redux/cart";
import { authSlice } from "redux/auth";
import { metaSlice } from "redux/meta";
import { slotsSlice } from "redux/slots";
import { useDispatch } from "react-redux";
import { sortingSlice } from "redux/sorting";
import { filtersSlice } from "redux/filters";
import { companySlice } from "redux/company";
import { bookingsSlice } from "redux/bookings";
import { candidateSlice } from "redux/candidate";
import { jobBoardsSlice } from "redux/jobBoards";
import { dashboardSlice } from "redux/dashboard";
import { templatesSlice } from "redux/templates";
import { invitationSlice } from "redux/invitation";
import { placeholdersSlice } from "redux/placeholders";
import { conversationSlice } from "redux/conversation";
import { subscriptionsSlice } from "redux/subscriptions";
import { viewingCandidateSlice } from "redux/viewingCandidate";
import { subscriptionPricesSlice } from "redux/subscriptionPrices";
import { inProgressConversationsSlice } from "redux/inProgressConversations";

const useSlices = () => {
  const dispatch = useDispatch();

  const resetAll = useCallback(() => {
    const actions = [
      viewingCandidateSlice.actions.reset,
      sortingSlice.actions.reset,
      slotsSlice.actions.reset,
      metaSlice.actions.reset,
      jobBoardsSlice.actions.reset,
      jobSlice.actions.reset,
      invitationSlice.actions.reset,
      filtersSlice.actions.reset,
      dashboardSlice.actions.reset,
      companySlice.actions.reset,
      cartSlice.actions.reset,
      candidateSlice.actions.reset,
      bookingsSlice.actions.reset,
      authSlice.actions.reset,
      templatesSlice.actions.reset,
      conversationSlice.actions.reset,
      placeholdersSlice.actions.reset,
      subscriptionsSlice.actions.reset,
      subscriptionPricesSlice.actions.reset,
      inProgressConversationsSlice.actions.reset,
    ];
    actions.forEach((each) => {
      if (each && typeof each === "function") dispatch(each());
    });

    //eslint-disable-next-line
  }, []);
  return { resetAll };
};

export default useSlices;
