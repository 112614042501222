import { useMemo } from "react";
import { useSelector } from "react-redux";

const useUserLanguagePreference = () => {
  const { user } = useSelector((state) => state.auth);
  const userLang = useMemo(() => {
    let language = "en";
    if (user && user.language_preference === "de") {
      language = "de";
    }
    return language;

    //eslint-disable-next-line
  }, [user]);

  return userLang;
};

export default useUserLanguagePreference;
