import { lookForReplacementText } from "utils/replacementsTexts";
import {
  englishToEnglish,
  germanToEnglish,
  supportedEnglishToEnglish,
  supportedGermanToEnglish,
} from "translations/countries/translations";

export const validateErrors = (
  errorName = "",
  name,
  handleError,
  t,
  regex = /[A-Za-z]{1,}/,
  userLang,
  checkSupportedCountries = false
) => {
  if (name === "" || !name || typeof name !== "string") {
    handleError((previousError) => {
      return {
        ...previousError,
        [errorName]: t(`${sanitizeWord(errorName, false, true)} is required`),
      };
    });
    return;
  } else if (!regex.test(name)) {
    if (
      (errorName === "firstName" || errorName === "lastName") &&
      /[$@0-9"&#`*,.%!^()~=+-;:/|\\<>[\]?]+/.test(name)
    ) {
      handleError((previousError) => {
        return {
          ...previousError,
          [errorName]: t(
            `Number, Symbols and other characters are not allowed in ${sanitizeWord(errorName)}`
          ),
        };
      });
      return;
    }
    handleError((previousError) => {
      return {
        ...previousError,
        [errorName]: t(lookForReplacementText(`Enter valid ${sanitizeWord(errorName)}`)),
      };
    });
    return;
  } else if (
    (errorName === "firstName" || errorName === "lastName") &&
    /[$@0-9"&#`*,.%!^()~=+-;:/|\\<>[\]?]+/.test(name)
  ) {
    handleError((previousError) => {
      return {
        ...previousError,
        [errorName]: t(
          `Number, Symbols and other characters are not allowed in ${sanitizeWord(errorName)}`
        ),
      };
    });
  } else if (errorName === "country" || errorName === "Country") {
    let isCountry = "";
    if (checkSupportedCountries) {
      isCountry = supportedEnglishToEnglish[name];
      if (userLang === "de" && supportedGermanToEnglish[name])
        isCountry = supportedGermanToEnglish[name];
    } else {
      isCountry = englishToEnglish[name];
      if (userLang === "de" && germanToEnglish[name]) isCountry = germanToEnglish[name];
    }
    if (!isCountry) {
      handleError((previousError) => {
        return {
          ...previousError,
          [errorName]: t(lookForReplacementText(`Not a country`)),
        };
      });
    }
  } else {
    handleError((previousError) => {
      return {
        ...previousError,
        [errorName]: null,
      };
    });
  }
};

export function sanitizeWord(word, splitOnUnderScore = false, auto = false) {
  let result;
  if (!auto) {
    if (splitOnUnderScore) {
      result = word.split(/_/);
    } else {
      result = word.split(/(?=[A-Z])/);
    }
  }
  if (auto) {
    if (/_/.test(word)) {
      result = word.split(/_/);
    } else {
      result = word.split(/(?=[A-Z])/);
    }
  }
  const newWords = result.reduce((previous, current) => {
    const str = current.charAt(0).toUpperCase() + current.slice(1);
    return [...previous, str];
  }, []);
  return newWords.join(" ");
}

// validate job errors helpers

export function validateJobErrors(
  errorName = "",
  name,
  handleError,
  regex = /[A-Za-z]{1,}/,
  errorMessage = "",
  t,
  rest,
  checkSupportedCountries = false
) {
  let hasError = false;
  if (name === "" || !name || typeof name !== "string") {
    hasError = true;
    handleError((previousError) => {
      return {
        ...previousError,
        // [errorName]: `${captalizeFirstLetter(errorName.split(/_/).join(" "))} is required`,

        [errorName]: t(`${sanitizeWord(errorName, true)} is required`),
      };
    });
    return hasError;
  }
  if (!regex.test(name)) {
    hasError = true;
    handleError((previousError) => {
      return {
        ...previousError,
        [errorName]: errorMessage
          ? t(`${errorMessage}`)
          : t(`Enter valid ${t(sanitizeWord(errorName, true))}`),
      };
    });
    return hasError;
  }
  if (regex.test(name)) {
    if (rest.max && name.length > rest.max.numberOfCharacters) {
      hasError = true;
      handleError((previousError) => {
        return {
          ...previousError,
          [errorName]: t(rest.max.message),
        };
      });
      return hasError;
    }
    if (rest.dontMatch && rest.dontMatch.regex.test(name)) {
      hasError = true;
      handleError((previousError) => {
        return {
          ...previousError,
          [errorName]: t(rest.dontMatch.message),
        };
      });
      return hasError;
    }
    if (errorName === "country") {
      let isCountry = "";
      if (checkSupportedCountries) {
        isCountry = supportedEnglishToEnglish[name];
        if (rest?.userLang === "de" && supportedGermanToEnglish[name])
          isCountry = supportedGermanToEnglish[name];
      } else {
        isCountry = englishToEnglish[name];
        if (rest?.userLang === "de" && germanToEnglish[name]) isCountry = germanToEnglish[name];
      }
      if (!isCountry) {
        hasError = true;
        handleError((previousError) => {
          return {
            ...previousError,
            [errorName]: t(`Not a country`),
          };
        });
        return hasError;
      }
    }
  }
  hasError = false;
  handleError((previousError) => {
    return {
      ...previousError,
      [errorName]: null,
    };
  });
  return hasError;
}

export function validateNotRequiredJobErrors(
  errorName = "",
  name,
  handleError,
  regex = /[A-Za-z]{1,}/,
  errorMessage = "",
  t,
  rest
) {
  let hasError = false;
  if (typeof name === "string" && name.length > 0 && !regex.test(name)) {
    hasError = true;
    handleError((previousError) => {
      return {
        ...previousError,
        [errorName]: errorMessage
          ? t(errorMessage)
          : t(`Enter valid ${sanitizeWord(errorName, true)}`),
      };
    });
    return hasError;
  }
  if (typeof name === "string" && name.length > 0 && regex.test(name) && rest) {
    if (rest.max && name.length > rest.max.numberOfCharacters) {
      hasError = true;
      handleError((previousError) => {
        return {
          ...previousError,
          [errorName]: t(rest.max.message),
        };
      });
      return hasError;
    }
    if (rest.dontMatch && rest.dontMatch.regex.test(name)) {
      hasError = true;
      handleError((previousError) => {
        return {
          ...previousError,
          [errorName]: t(rest.dontMatch.message),
        };
      });
      return hasError;
    }
  }

  if (typeof name === "string" && name.length <= 0) {
    hasError = true;
    handleError((previousError) => {
      const prev = { ...previousError };
      delete previousError[errorName];
      return {
        ...prev,
      };
    });
    return hasError;
  }

  hasError = false;
  handleError((previousError) => {
    const prev = { ...previousError };
    delete previousError[errorName];
    return {
      ...prev,
    };
  });
  return hasError;
}

export function validateDropdown(
  errorName = "",
  name,
  handleError,
  regex = /[A-Za-z]{1,}/,
  errorMessage = "",
  t
) {
  let hasError = false;
  if (typeof name === "string" && name.length > 0 && !regex.test(name)) {
    hasError = true;
    handleError((previousError) => {
      return {
        ...previousError,
        [errorName]: errorMessage ? t(errorMessage) : t("Enter valid option"),
      };
    });
    return hasError;
  } else if (typeof name === "string" && name.length <= 0) {
    hasError = true;
    handleError((previousError) => {
      return {
        ...previousError,
        [errorName]: null,
      };
    });
    return hasError;
  } else {
    hasError = false;
    handleError((previousError) => {
      return {
        ...previousError,
        [errorName]: null,
      };
    });
    return hasError;
  }
}

export const captalizeFirstLetter = (word = "") => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};
