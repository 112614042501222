import { baseColor } from "utils/colors/accents";

export const Cart = ({ color = baseColor.white }) => {
  return (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.25 1.75H2.3375C3.0125 1.75 3.54375 2.33125 3.4875 3L2.96875 9.22499C2.88125 10.2437 3.6875 11.1187 4.7125 11.1187H11.3688C12.2688 11.1187 13.0563 10.3813 13.125 9.4875L13.4625 4.8C13.5375 3.7625 12.75 2.91875 11.7062 2.91875H3.6375"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.1562 14.25C10.5877 14.25 10.9375 13.9002 10.9375 13.4688C10.9375 13.0373 10.5877 12.6875 10.1562 12.6875C9.72478 12.6875 9.375 13.0373 9.375 13.4688C9.375 13.9002 9.72478 14.25 10.1562 14.25Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.15625 14.25C5.58772 14.25 5.9375 13.9002 5.9375 13.4688C5.9375 13.0373 5.58772 12.6875 5.15625 12.6875C4.72478 12.6875 4.375 13.0373 4.375 13.4688C4.375 13.9002 4.72478 14.25 5.15625 14.25Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.625 5.5H13.125"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
