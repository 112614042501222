import { updateUserInAPI } from "redux/auth/authSlice";
import { backendAPI, ninjaFilterAPI } from "utils/axios";
import { reloadOnUnauthorized } from "utils/checkForAuth";
import { viewingCandidateSlice } from "redux/viewingCandidate";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getSanitizedFilteredRequestBody } from "utils/pages/candidate";
import { updateOldWithNewObjectValues } from "utils/updateOldWithNewObjectValues";
import { areKeysAvailableIn, areKeysAvailableWithType, sanitizeArray } from "utils/miniHelpers";

const initialState = {
  loading: false,
  candidates: null,
  error: null,
  refetching: false,
  pageCount: 0,
  totalCandidates: 0,
  isFiltering: false,
  filteringError: null,
  shouldCandidatesRefetch: false,
  recruiters: [],
  recruitersLoading: false,
  recruitersError: null,
};

export const fetchCandidatesData = createAsyncThunk(
  "candidates/fetch",
  async ({ company_id, selectedJobs, selectedStages, selectedFollowers, sorting, from, size, current_language }) => {
    const reqBody = getSanitizedFilteredRequestBody({
      company_id,
      selectedJobs,
      selectedStages,
      selectedFollowers,
      sorting,
      from,
      size,
      current_language,
    });
    try {
      const response = await ninjaFilterAPI.post("/candidates", reqBody);
      if (
        areKeysAvailableWithType({
          object: response,
          keys: [{ status: 200, exact: true }, { data: "object" }],
        }) &&
        areKeysAvailableWithType({
          object: response.data,
          keys: [{ candidates: "array" }, { total_candidates: "number" }],
        })
      ) {
        return {
          data: {
            candidates: sanitizeArray({
              array: response.data.candidates,
              elementType: "object",
              keys: [
                { id: "string" },
                { first_name: "string" },
                { last_name: "string" },
                { created_at: "string" },
                { stage: "string" },
                { average_rating: "number" },
                { job_id: "number" },
                { followers: "array" },
              ],
            }),
            total_candidates: response.data.total_candidates,
          },
        };
      }
      return {
        error: "Something went wrong",
      };
    } catch (error) {
      reloadOnUnauthorized(error);
      if (
        areKeysAvailableWithType({ object: error.response, keys: [{ data: "object" }] }) &&
        areKeysAvailableWithType({
          object: error.response.data,
          keys: [{ error: "No candidate found", exact: true }],
        })
      ) {
        return { data: { candidates: [], total_candidates: 0 } };
      }
      return {
        error: "Something went wrong",
      };
    }
  }
);

export const refetchCandidatesData = createAsyncThunk("candidates/refetch", async () => {
  try {
    const response = await backendAPI.get("/candidates");
    if (response.status === 200 && response.data && response.data.candidate_details) {
      const { candidates } = response.data.candidate_details;
      return { data: candidates };
    }
    return {
      error: "Something went wrong",
    };
  } catch (error) {
    reloadOnUnauthorized(error);
    return {
      error: "Something went wrong",
    };
  }
});

export const fetchRecruiters = createAsyncThunk(
  "candidates/fetchRecruiters",
  async () => {
    try {
      const response = await backendAPI.get("/followers/recruiters");
      if (response.status === 200) {
        return response.data?.recruiters;
      }
      return {
        error: "Something went wrong",
      };
    } catch (error) {
      return {
        error: "Something went wrong",
      };
    }
  }
);

export const candidateSlice = createSlice({
  name: "candidate",
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    stopLoading: (state) => {
      state.loading = false;
    },
    reset: (state) => {
      for (let key in initialState) {
        state[key] = initialState[key];
      }
    },
    setFiltering: (state, action) => {
      const { payload = false } = action;
      state.isFiltering = payload;
    },
    set: (state, action) => {
      const { payload } = action;
      if (typeof payload !== "object") return;
      Object.keys(payload).forEach((key) => {
        if (areKeysAvailableIn({ object: state, keys: [key] })) state[key] = payload[key];
      });
    },
    addNewCandidate: (state, action) => {
      state.candidates.unshift(action.payload);
    },
    updateCandidate: (state, action) => {
      const { payload } = action;
      if (
        !areKeysAvailableWithType({
          object: payload,
          keys: [{ candidateID: "string" }, { data: "object" }],
        })
      )
        return;

      if (!Array.isArray(state.candidates)) return;

      const { candidateID, data } = payload;
      state.candidates = state.candidates.reduce((acc, each) => {
        if (areKeysAvailableWithType({ object: each, keys: [{ id: candidateID, exact: true }] })) {
          return [
            ...acc,
            {
              ...each,
              ...data,
            },
          ];
        }
        return [...acc, each];
      }, []);
    },
    setPageCount: (state, action) => {
      if (
        !areKeysAvailableWithType({ object: action, keys: [{ payload: "number" }] }) ||
        action.payload < 0
      ) {
        return;
      }
      state.pageCount = action.payload;
    },
  },
  extraReducers: (builder) => {
    //update respective recruiter in candidate list when current user details updates
    builder.addCase(updateUserInAPI.fulfilled, (state, action) => {
      const { payload } = action;
      if (payload?.user && typeof payload.user === "object" && Array.isArray(state.candidates)) {
        const user = payload.user;
        state.candidates = state.candidates.map((candidate) => {
          const newCandidate = { ...candidate };
          if (candidate?.recruiter?.id === user?.id) {
            newCandidate.recruiter = updateOldWithNewObjectValues({
              oldObj: candidate.recruiter,
              newObj: user,
            });
          }
          return newCandidate;
        });
      }
    });

    builder.addCase(fetchCandidatesData.pending, (state) => {
      state.error = null;
      state.loading = true;
      state.shouldCandidatesRefetch = false;
    });
    builder.addCase(fetchCandidatesData.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      const { payload } = action;
      if (payload.data) {
        state.candidates = payload.data.candidates;
        state.totalCandidates = payload.data.total_candidates;
      }
      if (payload.error) {
        state.error = payload.error;
      }
    });
    builder.addCase(fetchCandidatesData.rejected, (state, action) => {
      state.loading = false;
      state.candidates = null;
      state.error = action.payload.error;
    });

    builder.addCase(refetchCandidatesData.pending, (state) => {
      state.error = null;
      state.refetching = true;
    });
    builder.addCase(refetchCandidatesData.fulfilled, (state, action) => {
      state.refetching = false;
      state.error = null;
      const { payload } = action;
      if (payload.data) {
        state.candidates = payload.data;
      }
      if (payload.error) {
        state.error = payload.error;
      }
    });
    builder.addCase(refetchCandidatesData.rejected, (state, action) => {
      state.refetching = false;
      state.candidates = null;
      state.error = action.payload.error;
    });

    builder.addCase(viewingCandidateSlice.actions.updateStage, (state, action) => {
      const { payload } = action;
      const { candidates } = state;
      if (candidates && Array.isArray(candidates)) {
        const found = candidates.find((candidate) => candidate.id === payload.candidate_id);
        if (found) found.stage = payload.stage;
      }
    });
    builder.addCase(viewingCandidateSlice.actions.updateEvaluation, (state, action) => {
      const { payload } = action;
      const { candidates } = state;
      if (candidates && Array.isArray(candidates)) {
        const found = candidates.find((candidate) => candidate.id === payload.candidate_id);
        if (found) found.average_rating = payload.average_rating;
      }
    });
    builder.addCase(viewingCandidateSlice.actions.setEvaluation, (state, action) => {
      const { payload } = action;
      const { candidates } = state;
      if (candidates && Array.isArray(candidates)) {
        const found = candidates.find((candidate) => candidate.id === payload.candidate_id);
        if (found) found.average_rating = payload.average_rating;
      }
    });
    builder.addCase(viewingCandidateSlice.actions.removeEvaluation, (state, action) => {
      const { payload } = action;
      const { candidates } = state;
      if (candidates && Array.isArray(candidates)) {
        const found = candidates.find((candidate) => candidate.id === payload.candidate_id);
        if (found) found.average_rating = payload.average_rating;
      }
    });
    builder.addCase(fetchRecruiters.pending, (state) => {
      state.recruitersLoading = true;
      state.recruitersError = null;
    })
    builder.addCase(fetchRecruiters.fulfilled, (state, action) => {
      state.recruiters = action.payload;
      state.recruitersLoading = false;
    })
    builder.addCase(fetchRecruiters.rejected, (state, action) => {
      state.recruitersLoading = false;
      state.recruitersError = action.error.message;
    });
  },
});
