import { View, Text } from "reshaped";
import useSpacing from "components/hooks/useSpacing";
import useTranslation from "components/hooks/useTranslation";

export const DashboardMetaStats = (props) => {
  const { jobs = 0, candidates = 0, hired = 0 } = props;

  const { t } = useTranslation();
  const { lg } = useSpacing();

  return (
    <View direction="row" gap={lg}>
      <MetaCard count={jobs} title={t(`Job${jobs === 1 ? "" : "s"} online`)} />
      <MetaCard count={candidates} title={t(`Candidate${candidates === 1 ? "" : "s"}`)} />
      <MetaCard count={hired} title={t(`Hired`)} />
    </View>
  );
};


const MetaCard = ({ count, title }) => {
  const { lg, xl } = useSpacing();

  return (
    <View.Item columns="4">
      <View
        gap={xl}
        direction="row"
        align="center"
        padding={lg}
        borderRadius="medium"
        backgroundColor="white"
      >
        <Text variant="title-3">{count}</Text>
        <View.Item grow>
          <Text variant="body-1">{title}</Text>
        </View.Item>
      </View>
    </View.Item>
  );
};
