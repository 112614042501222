import { useEffect, useState } from "react";
import { View } from "reshaped";

import { backendAPI } from "utils/axios";
import { getFullName } from "utils/nameHelpers";
import useSpacing from "components/hooks/useSpacing";
import { ReshapedLoader } from "components/reusables";
import { useDispatch, useSelector } from "react-redux";
import { conversationSlice } from "redux/conversation";
import { viewingCandidateSlice } from "redux/viewingCandidate";
import styles from "components/styles/reshaped/candidates.module.css";
import { areKeysAvailableIn, areKeysAvailableWithType } from "utils/miniHelpers";
import {
  Credentials,
  CurrentStage,
  CandidateStat,
  CandidateDocuments,
} from "components/pages/candidates";

export const Profile = (props) => {
  const { handleClose, viewingCandidateId } = props;
  // const { job } = currentCandidate;
  const [candidateFetching, setCandidateFetching] = useState(true);

  const { xl } = useSpacing();
  const dispatch = useDispatch();
  const { currentCandidate } = useSelector((state) => state.viewingCandidate);
  const { setViewingCandidate } = viewingCandidateSlice.actions;

  useEffect(() => {
    (async () => {
      async function getCandidate() {
        try {
          const response = await backendAPI.get(`/candidates/${viewingCandidateId}`);
          if (
            !areKeysAvailableWithType({ object: response.data, keys: [{ candidate: "object" }] }) ||
            !areKeysAvailableWithType({
              object: response.data.candidate,
              keys: [
                { id: "string" },
                { first_name: "string" },
                { last_name: "string" },
                { email: "string" },
                { stage: "string" },
                { documents: "array" },
                { job: "object" },
                { recruiter: "object" },
              ],
            })
          ) {
            return handleClose({ notFound: true });
          }
          dispatch(setViewingCandidate(response.data.candidate));
          const { id, first_name, last_name, email } = response.data.candidate;
          dispatch(
            conversationSlice.actions.setInfo({
              destination: {
                email,
                id,
                last_name,
                first_name,
                full_name: getFullName({ first_name, last_name }),
              },
            })
          );
        } catch (error) {
          return handleClose({ notFound: true });
        }
      }

      if (
        !areKeysAvailableWithType({
          object: currentCandidate,
          keys: [{ id: viewingCandidateId, exact: true }],
        })
      ) {
        if (!candidateFetching) setCandidateFetching(true);
        await getCandidate(viewingCandidateId);
      }

      setCandidateFetching(false);
    })();

    //eslint-disable-next-line
  }, [viewingCandidateId]);

  return (
    <View className={`${styles.more__shadow}`}>
      <View
        className={`${styles.profile} no__scroll`}
        backgroundColor="white"
        shadow="base"
        borderRadius="large"
        paddingBottom={xl + xl}
      >
        {candidateFetching && <ReshapedLoader />}
        {!candidateFetching && typeof currentCandidate === "object" && (
          <>
            <CandidateStat candidate={currentCandidate} />
            {areKeysAvailableIn({
              object: currentCandidate.job,
              keys: ["id", "title", "country", "city"],
            }) && <Credentials candidate={currentCandidate} />}
            <CurrentStage candidate={currentCandidate} />
            {currentCandidate && <CandidateDocuments candidate={currentCandidate} />}
          </>
        )}
      </View>
    </View>
  );
};
