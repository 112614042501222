import React, { useMemo } from "react";
import { Button, DropdownMenu, FormControl, View } from "reshaped";

import { ArrowDown } from "icons";
import { useSelector } from "react-redux";
import useTranslation from "components/hooks/useTranslation";
import { stepOneProgressHelper } from "utils/jobStepProgress";
import styles from "components/styles/reshaped/jobs/new.module.css";

const DepartmentDropdown = (props) => {
  const { errors, setErrors, propTitle, setStepOneProgress, department, setDepartment } = props;

  const { t } = useTranslation();
  const auth = useSelector((state) => state.auth);

  const departmentOptions = useMemo(() => {
    return [
      { value: "Sales" },
      { value: "Finance and Operations" },
      { value: "Engineering" },
      { value: "Customer service" },
      { value: "Product" },
      { value: "Human resources" },
      { value: "Marketing" },
      { value: "Other" },
    ];

    //eslint-disable-next-line
  }, []);

  const translations = useMemo(() => {
    const departmentTranslations = departmentOptions.reduce(
      (acc, curr) => ({ ...acc, [curr.value]: t(curr.value) }),
      {}
    );
    return {
      Department: t("Department"),
      ...departmentTranslations,
    };

    //eslint-disable-next-line
  }, [auth && auth?.user ? [auth.user] : []]);

  const handleDepartment = ({ selectedOption }) => {
    const { value } = selectedOption;
    if (errors.department) {
      setErrors((previousError) => {
        return {
          ...previousError,
          department: null,
        };
      });
    }
    setDepartment(value);
    stepOneProgressHelper(propTitle, setStepOneProgress, "add");
  };

  return (
    <View.Item grow={true} className={styles.dropdown__wrapper}>
      <FormControl
        required
        hasError={errors["department"] && typeof errors["department"] !== "object"}
      >
        <FormControl.Label>{translations.Department}</FormControl.Label>
        <DropdownMenu position="bottom-start">
          <DropdownMenu.Trigger>
            {(attributes) => (
              <Button
                className={`${
                  errors["department"] && typeof errors["department"] !== "object"
                    ? styles.dropdown__button__critical
                    : styles.dropdown__button
                }`}
                variant="outline"
                color={
                  errors["department"] && typeof errors["department"] !== "object"
                    ? "critical"
                    : "neutral"
                }
                fullWidth
                endIcon={ArrowDown}
                attributes={{ ...attributes, "data-cooper": "jobs department button" }}
              >
                {department ? translations[department] : translations.Department}
              </Button>
            )}
          </DropdownMenu.Trigger>
          <DropdownMenu.Content>
            {departmentOptions.map((option, index) => {
              return (
                <DropdownMenu.Item
                  onClick={() => handleDepartment({ selectedOption: option })}
                  key={index}
                  attributes={{ "data-cooper": `department ${option.value}` }}
                >
                  {translations[option.value]}
                </DropdownMenu.Item>
              );
            })}
          </DropdownMenu.Content>
        </DropdownMenu>
        <FormControl.Error>{errors?.department}</FormControl.Error>
      </FormControl>
    </View.Item>
  );
};

export default React.memo(DepartmentDropdown);
