import { NewStar } from "icons";
import { View } from "reshaped";
import useSpacing from "components/hooks/useSpacing";

export const CandidateRating = ({
  maxPoints = 5,
  pointsSecured = 0,
  emptyFill,
  fill,
  stroke,
  emptyStroke,
  size = "15",
}) => {
  const { sm } = useSpacing();
  const points = Array.from(Array(maxPoints + 1).keys());
  points.shift();

  if (!pointsSecured || pointsSecured === 0) {
    return (
      <View direction="row" gap={sm} borderColor="critical">
        {points.map((point) => {
          return (
            <NewStar key={point} size={size} fillColor={emptyFill} strokeColor={emptyStroke} />
          );
        })}
      </View>
    );
  }

  return (
    <View gap={sm} direction="row">
      {points.map((point) => {
        if (point <= pointsSecured) {
          return <NewStar key={point} size={size} fillColor={fill} strokeColor={stroke} />;
        } else {
          return (
            <NewStar key={point} size={size} fillColor={emptyFill} strokeColor={emptyStroke} />
          );
        }
      })}
    </View>
  );
};
