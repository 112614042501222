import { Image, View, Hidden } from "reshaped";
import RegisterUser from "assets/images/RegisterUser";
import RegisterCompany from "assets/images/RegisterCompany";
import RegisterConfirm from "assets/images/RegisterConfirm";
import styles from "components/styles/reshaped/login.module.css";
import RegisterCompanySize from "assets/images/RegisterCompanySize";

export const RegisterLayout = (props) => {
  const { registrationStage, loginStage, children } = props;

  return (
    <>
      <View as="section" width="100vw" height="100vh">
        {/** Visible on every screen except small */}
        <Hidden hide={{ s: true, m: false, l: false, xl: false }}>
          <View direction="row" gap="0" align="stretch" height="100%">
            <View.Item className={styles.img__wrapper} columns="5" height="100vh">
              {loginStage === "login" && (
                <Image
                  src={`data:image/webp;base64,${RegisterUser}`}
                  width="100%"
                  height="100vh"
                  alt="Login user"
                />
              )}
              {registrationStage === "user" && (
                <Image
                  src={`data:image/webp;base64,${RegisterUser}`}
                  width="100%"
                  height="100vh"
                  alt="Register user"
                />
              )}
              {registrationStage === "company" && (
                <Image
                  src={`data:image/webp;base64,${RegisterCompany}`}
                  width="100%"
                  height="100vh"
                  alt="Register company"
                />
              )}
              {registrationStage === "size" && (
                <Image
                  src={`data:image/webp;base64,${RegisterCompanySize}`}
                  width="100%"
                  height="100vh"
                  alt="Register company size"
                />
              )}
              {registrationStage === "confirm" && (
                <Image
                  src={`data:image/webp;base64,${RegisterConfirm}`}
                  width="100%"
                  height="100vh"
                  alt="Register confirmed"
                />
              )}
            </View.Item>
            <View.Item columns="7">
              <View height="100%" backgroundColor="white">
                {children}
              </View>
            </View.Item>
          </View>
        </Hidden>

        {/** Visible on only small screen */}
        <Hidden hide={{ s: false, m: true, l: true, xl: true }}>
          <View height="100vh">
            <View.Item columns="12">
              <View height="100vh" backgroundColor="white">
                {children}
              </View>
            </View.Item>
          </View>
        </Hidden>
      </View>
    </>
  );
};
