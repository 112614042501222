import { View, Text } from "reshaped";
import styles from "components/styles/reshaped/candidates.module.css"
import useSpacing from "components/hooks/useSpacing";
import { formatISODateTime } from "utils/formatISODateToLocalDate";
import { cooperColors } from "utils/colors/accents";

export const DatePill = ({ date }) => {
  const { xs } = useSpacing();
  return (
    <View
      borderRadius={xs}
      className={styles.date_pill}
    >
      <Text
        className={styles.date_pill__text}
        attributes={{
          style: {
            color: cooperColors["foreground-dark-neutral-faded"],
            backgroundColor: cooperColors["background-page-disabled-faded"],
          }
      }}
      >
        {formatISODateTime(date)}
      </Text>
    </View>
  );
}