// differentKeyMapping value is when newObj have different key for specific property
export function updateOldWithNewObjectValues({ oldObj, newObj, differentKeyMapping }) {
  const finalObj = oldObj;
  if (typeof oldObj !== "object" && typeof newObj !== "object") return finalObj;
  Object.keys(oldObj).forEach((k) => {
    if (k in oldObj && k in newObj) {
      finalObj[k] = newObj[k];
    }
    if (
      k in oldObj &&
      !(k in newObj) &&
      typeof differentKeyMapping === "object" &&
      differentKeyMapping[k] in newObj
    ) {
      finalObj[k] = newObj[differentKeyMapping[k]];
    }
  });
  return finalObj;
}
