import axios from "axios";
import { useState } from "react";
import { metaSlice } from "redux/meta";
import { ATS_BACKEND_HOST } from "constants";
import { Text, View, Button } from "reshaped";
import { Counter } from "components/reusables";
import useSpacing from "components/hooks/useSpacing";
import { useDispatch, useSelector } from "react-redux";
import useTranslation from "components/hooks/useTranslation";

export const EmailSent = ({ email }) => {
  const [noOfMinutes, setNoOfMinutes] = useState(0);
  const [isCounting, setIsCounting] = useState(false);

  const { xs } = useSpacing();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.meta);
  const { stopLoading, initialLoading } = metaSlice.actions;

  const handleResendEmail = async () => {
    try {
      dispatch(initialLoading());
      const user = { email };
      const response = await axios.post(`${ATS_BACKEND_HOST}/users/password`, { user });
      dispatch(stopLoading());
      if (response.data.status === 200 && response.data && response.data.message) {
        setNoOfMinutes((prev) => prev + 1);
        setIsCounting(true);
      }
    } catch (err) {
      dispatch(stopLoading());
    }
  };

  return (
    <View direction="row" align="center" justify="center" gap={xs}>
      <Text color="neutral" variant="body-medium-2" align="center">
        {t("Didn't receive the email? ")}
      </Text>
      {!isCounting && (
        <Button
          size="small"
          loading={isLoading}
          variant="ghost"
          color="primary"
          onClick={handleResendEmail}
        >
          {t("Resend")}{" "}
        </Button>
      )}

      {isCounting && (
        <Counter minutes={noOfMinutes} isCounting={isCounting} setIsCounting={setIsCounting} />
      )}
    </View>
  );
};
