import { useEffect, useState } from "react";
import { View } from "reshaped";

import useSpacing from "components/hooks/useSpacing";
import { ReshapedLoader } from "components/reusables";
import { useDispatch, useSelector } from "react-redux";
import { CategoryWrapper } from "components/pages/candidates";
import { ShowSupportError } from "components/reusables/common";
import styles from "components/styles/reshaped/candidates.module.css";
import Evaluations from "components/pages/candidates/categories/evalution/Evaluations";
import NoEvalution from "components/pages/candidates/categories/evalution/NoEvalution";
import { fetchCandidateEvaluations } from "redux/viewingCandidate/viewingCandidateSlice";
import AddEvaluation from "components/pages/candidates/categories/evalution/AddEvaluation";
import EditEvaluation from "components/pages/candidates/categories/evalution/EditEvaluation";

const EvaluationCategory = (props) => {
  const {
    addEvaluation,
    setAddEvaluation,
    selectedCategory,
    setSelectedCategory,
    viewingCandidateId,
  } = props;

  const [editEvaluationID, setEditEvaluationID] = useState("");

  const { xl } = useSpacing();
  const dispatch = useDispatch();
  const { evaluations, loading, evaluationsError } = useSelector((state) => state.viewingCandidate);

  useEffect(() => {
    if (!evaluations || evaluations.candidate_id !== viewingCandidateId) {
      dispatch(fetchCandidateEvaluations(viewingCandidateId));
    }

    //eslint-disable-next-line
  }, [viewingCandidateId]);

  const editEvaluationProps = { editEvaluationID, setEditEvaluationID };

  return (
    <View
      gap={xl}
      height="100%"
      attributes={{
        style: {
          zIndex: 0,
        },
      }}
    >
      {!evaluationsError && !loading && (
        <>
          {!addEvaluation && !editEvaluationID && (
            <CategoryWrapper heading={"Evaluation"} height="100%">
              {evaluations &&
              evaluations.data &&
              Array.isArray(evaluations.data) &&
              evaluations.data.length > 0 ? (
                <>
                  <Evaluations
                    setAddEvaluation={setAddEvaluation}
                    evaluations={evaluations.data}
                    setEditEvaluationID={setEditEvaluationID}
                  />
                </>
              ) : (
                <>
                  <NoEvalution
                    setSelectedCategory={setSelectedCategory}
                    selectedCategory={selectedCategory}
                    setAddEvaluation={setAddEvaluation}
                  />
                </>
              )}
            </CategoryWrapper>
          )}
          {addEvaluation && !editEvaluationID && (
            <View height="100%" className={styles.add__evaluation_vector}>
              <AddEvaluation setAddEvaluation={setAddEvaluation} />
            </View>
          )}
          {!addEvaluation && editEvaluationID && (
            <View height="100%" className={styles.add__evaluation_vector}>
              <EditEvaluation {...editEvaluationProps} />
            </View>
          )}
        </>
      )}
      {loading && (
        <CategoryWrapper heading={"Evaluation"} height="100%">
          <ReshapedLoader />
        </CategoryWrapper>
      )}
      {evaluationsError && !loading && (
        <View align="center">
          <ShowSupportError variant="body-strong-1" />
        </View>
      )}
    </View>
  );
};

export default EvaluationCategory;
