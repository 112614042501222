import { Link, View, Text } from "reshaped";
import { useNavigate } from "react-router-dom";
import useSpacing from "components/hooks/useSpacing";
import useTranslation from "components/hooks/useTranslation";

export const ReshapedErrorToast = (props) => {
  const { message, navigateTo = "/", position, noTryAgain = false, reload = true } = props;

  const { sm } = useSpacing();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClick = () => {
    if (props.handler) {
      props.handler();
    } else {
      navigate(navigateTo);
    }
  };

  return (
    <View
      padding={sm}
      align="center"
      justify="center"
      direction="row"
      attributes={
        position
          ? {
              style: {
                position,
                top: 0,
                left: "50%",
                transform: "translateX(-50%)",
              },
            }
          : { ...{} }
      }
    >
      <View backgroundColor="critical" padding={2} borderRadius="small">
        <Text variant="body-strong-1">
          {message ? `${message} ` : t("Something went wrong! ")}
          {!noTryAgain && !reload && (
            <Link color="inherit" onClick={handleClick}>
              {t("Please try again.")}
            </Link>
          )}
          {!noTryAgain && reload && (
            <Link color="inherit" href={navigateTo}>
              {t("Please try again.")}
            </Link>
          )}
        </Text>
      </View>
    </View>
  );
};
