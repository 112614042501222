import { View, Text } from "reshaped";
import useSpacing from "components/hooks/useSpacing";
import useTranslation from "components/hooks/useTranslation";

export const SlotTitleGroup = () => {
  const { t } = useTranslation();
  const { xs, xl } = useSpacing();

  return (
    <View direction="row" gap={xl}>
      <View.Item grow>
        <View direction="row" gap={xs} align="center" justify="start">
          <Text variant="title-2">{t("Promotions")}</Text>
          <Text variant="body-1" color="neutral-faded">
            {t("Job Board")}
          </Text>
        </View>
      </View.Item>
      {/* <View overflow="hidden" borderRadius="medium">
        <Button startIcon={CartWhite} color="primary">
          {t("Your cart")}
        </Button>
      </View> */}
    </View>
  );
};
