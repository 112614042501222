import { restrictedEmails } from "utils/restrictedEmails";

export const isEmailRestricted = (email) => {
  if (!email) return true;

  // grabs the "com" in email "artificial@hello.world.com"
  const endRegex = /(?:\.)([a-zA-Z0-9]+$)/;
  const endPointDomain = endRegex.exec(email)[1];

  // grabs the "hello.world.com" in email "artificial@hello.world.com"
  //eslint-disable-next-line
  const exactRegex = /(?:\@)([^]*)/;
  const exactDomain = exactRegex.exec(email)[1];

  if (!restrictedEmails[endPointDomain]) return false;

  const foundDomainEmails = restrictedEmails[endPointDomain];
  const length = foundDomainEmails.length;
  let isRestricted = false;

  // checking for exact domain like "hello.world.com" in "com" list
  for (let i = 0; i < length; i++) {
    if (foundDomainEmails[i].toLowerCase() === exactDomain.toLowerCase()) {
      isRestricted = true;
      break;
    }
  }

  return isRestricted;
};
