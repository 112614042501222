import { Loader, View } from "reshaped";
import ChangePlanTabs from "./ChangePlanTabs";
import React, { useEffect, useState } from "react";
import useSpacing from "components/hooks/useSpacing";
import { useDispatch, useSelector } from "react-redux";
import { isCanceledAndPastDue } from "utils/pages/settings";
import PricingCard from "components/pages/settings/PricingCard";
import { ReshapedErrorToast } from "components/reusables/layouts";
import { fetchSubscriptionPrices } from "redux/subscriptionPrices/subscriptionPricesSlice";
import common from "components/styles/reshaped/reusables/common.module.css";
import PricingToggleTabs from "components/pages/settings/PricingToggleTabs";
import { fetchSubscriptionData } from "redux/subscriptions/subscriptionsSlice";
import SubscriptionDetails from "components/pages/settings/SubscriptionDetails";

export const Subscriptions = () => {
  const { sm, xl } = useSpacing();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!subscription.subscription_type) {
      dispatch(fetchSubscriptionData());
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!subscriptionPrices.length) {
      dispatch(fetchSubscriptionPrices());
    }
    //eslint-disable-next-line
  }, []);

  const handleRefetchSubscriptionDetails = () => {
    dispatch(fetchSubscriptionData());
  };

  const state = useSelector((state) => state);
  const { subscription, loading, error } = state.subscriptions;
  const { subscriptionPrices, loading: pricesLoading, error: pricesError } = state.subscriptionPrices;
  const companyCountryCode = state.auth.user.company.country_code;
  const isAdmin = state.auth.user.role === "admin";

  const [planType, setPlanType] = useState(subscription.interval || "monthly");

  const handlePlanTypeChange = (type) => {
    setPlanType(type);
  };

  return (
    <>
      <View
        height="100%"
        attributes={{
          style: {
            position: "relative",
          },
        }}
      >
        {!(loading || pricesLoading) && subscriptionPrices.length && (
          <View.Item grow>
            {!subscription.status || isCanceledAndPastDue(subscription) ? (
              <View gap={xl + sm} padding={[xl, 0]} justify={"center"} align={"center"}>
                <PricingToggleTabs
                  planType={planType}
                  handlePlanTypeChange={handlePlanTypeChange}
                />
                <View gap={sm} direction="row">
                  {subscriptionPrices.length && subscriptionPrices.map((plan) => (
                    <PricingCard
                      plan={plan}
                      planType={planType}
                      subscription={subscription}
                      key={plan.title}
                      companyCountryCode={companyCountryCode}
                    />
                  ))}
                </View>
              </View>
            ) : null}

            {!!subscription.status ? (
              subscriptionPrices.length && <SubscriptionDetails subscription={subscription} subscriptionPrices={subscriptionPrices} isAdmin={isAdmin} />
            ) : null}

            {!!subscription.status && !isCanceledAndPastDue(subscription) && isAdmin ? (
              subscriptionPrices.length && <ChangePlanTabs subscription={subscription} subscriptionPrices={subscriptionPrices} companyCountryCode={companyCountryCode} />
            ) : null}
          </View.Item>
        )}
        {(loading || pricesLoading) && (
          <View height="100%" align="center" justify="center">
            <Loader size="medium" className={common.loader__big} />
          </View>
        )}
        {!loading && !pricesLoading && (error || pricesError) && (
          <ReshapedErrorToast
            navigateTo="/settings"
            message={error}
            position={"absolute"}
            handler={handleRefetchSubscriptionDetails}
            reload={false}
          />
        )}
      </View>
    </>
  );
};
