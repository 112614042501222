import React, { useMemo } from "react";
import { Button, Icon, Text, View } from "reshaped";

import { metaSlice } from "redux/meta";
import { NavLink } from "react-router-dom";
import { baseColor } from "utils/colors/accents";
import useSpacing from "components/hooks/useSpacing";
import { useDispatch, useSelector } from "react-redux";
import useTranslation from "components/hooks/useTranslation";
import styles from "components/styles/reshaped/navbar.module.css";
import { Setting, Bookings, Category, Briefcase, UserSquare, SpeakerHigh } from "icons";

export const LeftSideNavbar = ({ isAlertVisible }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { xs, lg, xl } = useSpacing();
  const { meta } = useSelector((state) => state);

  const { isCandidateModalOpen, isNewCandidateModalOpen } = meta;

  const links = useMemo(() => {
    return [
      {
        to: "/",
        text: "Dashboard",
        Svg: Category,
      },
      {
        to: "/jobs",
        text: "Jobs",
        Svg: Briefcase,
      },
      {
        to: "/candidates",
        text: "Candidates",
        Svg: UserSquare,
      },
      {
        to: "/promote",
        text: "Promote",
        Svg: SpeakerHigh,
      },
      {
        to: "/bookings",
        text: "Bookings",
        Svg: Bookings,
      },
      {
        to: "/settings",
        text: "Settings",
        Svg: Setting,
      },
    ];
  }, []);

  const handleClick = (event) => {
    if (isCandidateModalOpen) {
      dispatch(metaSlice.actions.setCandidateModalOpen(false));
    }

    if (isNewCandidateModalOpen) {
      dispatch(metaSlice.actions.setNewCandidateModalOpen(false));
    }

    if (event?.currentTarget?.blur && typeof event.currentTarget.blur === "function") {
      event.currentTarget.blur();
    }
  };

  return (
    <View
      width="6rem"
      as="nav"
      height="100vh"
      backgroundColor="white"
      className={styles.leftbar}
      attributes={{
        style: {
          marginTop: isAlertVisible ? "50px" : 0,
        },
      }}
      align="center"
      justify="start"
      gap={xl + xs}
    >
      {links.map(({ to, text, Svg }) => {
        return (
          <NavLink key={text} to={to} className={styles.link} onClick={handleClick}>
            {({ isActive }) => {
              return (
                <>
                  <Button
                    className={styles.icon__btn}
                    size="small"
                    variant={isActive ? "solid" : "ghost"}
                    color={isActive ? "primary" : "white"}
                  >
                    <Icon
                      size={lg + xs}
                      svg={() => <Svg color={isActive ? baseColor.white : "#737791"} />}
                    />
                  </Button>
                  <Text variant="body-2" className={styles.title}>
                    {t(text)}
                  </Text>
                </>
              );
            }}
          </NavLink>
        );
      })}
    </View>
  );
};
