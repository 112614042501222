import { templatesSlice } from "redux/templates";
import { getTemplates } from "utils/pages/settings";
import { useDispatch, useSelector } from "react-redux";
import { supportedTemplateTriggers } from "utils/constants";
import { areKeysAvailableIn, areKeysAvailableWithType } from "utils/miniHelpers";

function useTemplates() {
  const dispatch = useDispatch();
  const { templates } = useSelector((state) => state);

  const { data } = templates;

  async function get(onSuccessCallback, onFailCallback) {
    dispatch(templatesSlice.actions.setLoading(true));
    const result = await getTemplates();

    if (result.error) {
      dispatch(
        templatesSlice.actions.setProperties({
          data: null,
          loading: false,
          error: result.error,
        })
      );
      if (onFailCallback && typeof onFailCallbackCallback === "function") onFailCallback();
      return;
    }

    if (result.data && Array.isArray(result.data)) {
      dispatch(
        templatesSlice.actions.setProperties({
          error: null,
          loading: false,
          data: result.data,
          minimalData: result.data.reduce((acc, each) => {
            if (areKeysAvailableIn({ object: each, keys: ["id", "name"] })) {
              return [...acc, { id: each.id, name: each.name }];
            }
            return acc;
          }, []),
        })
      );
      if (onSuccessCallback && typeof onSuccessCallback === "function") onSuccessCallback();
    }
  }

  function getDefaultTemplate({ trigger = "rejected" }) {
    if (
      !Array.isArray(data) ||
      !trigger ||
      typeof trigger !== "string" ||
      !supportedTemplateTriggers.includes(trigger)
    )
      return null;

    const length = data.length;

    let template = null;

    for (let i = 0; i < length; i++) {
      const each = data[i];
      if (
        areKeysAvailableWithType({
          object: each,
          keys: [
            { is_default_trigger: true, exact: true },
            { trigger, exact: true },
            { subject: "string" },
            { message: "string" },
          ],
        })
      ) {
        template = each;
        break;
      }
    }

    return template;
  }

  return {
    getTemplates: get,
    getDefaultTemplate,
  };
}

export default useTemplates;
