import { Text, View, Icon, Button } from "reshaped";

import { DOCX, PDF, Trash } from "icons";
import useSpacing from "components/hooks/useSpacing";
import useTranslation from "components/hooks/useTranslation";

export const UploadFile = (props) => {
  const { t } = useTranslation();
  const { xs, sm } = useSpacing();
  const { uploadedFiles, setUploadedFiles, filesError, setFilesError } = props;

  const handleDeleteFile = (index) => {
    if (filesError) setFilesError(null);
    setUploadedFiles((previousUploadedFiles) => {
      const files = [...previousUploadedFiles];
      files.splice(index, 1);
      return files;
    });
  };

  return (
    <View gap={xs} padding={[sm, 0]}>
      {Array.isArray(uploadedFiles) && uploadedFiles.length !== 0 ? (
        <>
          {uploadedFiles.map((upload, index) => {
            return (
              <View key={index} direction="row" align="center" gap={sm}>
                <>
                  {(upload.type === "pdf" || upload.type === "PDF") && <Icon svg={PDF} />}
                  {upload.type !== "pdf" && upload.type !== "PDF" && <Icon svg={DOCX} />}
                </>
                <Text variant="body-2" color="neutral">
                  {upload.file.name}
                </Text>
                <View.Item grow>
                  <View direction="row" justify="end">
                    <Button
                      onClick={() => handleDeleteFile(index)}
                      size="small"
                      color="critical"
                      variant="ghost"
                      startIcon={Trash}
                    />
                  </View>
                </View.Item>
              </View>
            );
          })}
        </>
      ) : (
        <View
          height="50px"
          align="center"
          justify="center"
          borderRadius="small"
          backgroundColor="page-faded"
        >
          <Text variant="body-strong-2" color="neutral-faded">
            {t("No documents uploaded!")}
          </Text>
        </View>
      )}
    </View>
  );
};
