import useTranslation from "components/hooks/useTranslation";
import { OperationFailedWithTranslation } from "components/reusables";
import React, { useState } from "react";
import { Button, Text, View, useToast } from "reshaped";
import { backendAPI } from "utils/axios";
import { Capitalize, getPlanDetails, formatDate, getPlanByPriceId } from "utils/pages/settings";
import { reloadOnUnauthorized } from "utils/checkForAuth";
import { ShowToastWithTranslation } from "utils/showToast";
import useSpacing from "components/hooks/useSpacing";
import { fetchSubscriptionData } from "redux/subscriptions/subscriptionsSlice";
import { useDispatch } from "react-redux";
import { SuccessfulToast } from "components/reusables/tab/SuccessfulToast";

const SubscriptionDetails = ({ subscription, subscriptionPrices, isAdmin }) => {
  const [checkoutLoading, setCheckoutLoading] = useState(false);
  const [cancelDowngradeLoading, setCancelDowngradeLoading] = useState(false);
  const dispatch = useDispatch();
  const toast = useToast();
  const { t } = useTranslation();
  const { sm, md, xl } = useSpacing();
  const handleManageSubscription = async () => {
    setCheckoutLoading(true);
    let isFailed = false;

    if (isFailed) return setCheckoutLoading(false);

    try {
      const response = await backendAPI.get("/companies/subscriptions/manage");

      setCheckoutLoading(false);

      if (response.data && response.data.status === 200 && response.data.url) {
        return (window.location.href = response.data.url);
      }
      ShowToastWithTranslation({
        toast,
        Children: OperationFailedWithTranslation,
        text: t("Operation failed!"),
      });
    } catch (error) {
      reloadOnUnauthorized(error);
      setCheckoutLoading(false);
      ShowToastWithTranslation({
        toast,
        Children: OperationFailedWithTranslation,
        text: t("Operation failed!"),
      });
    }
  };

  const handleHandleCancelDowngrade = async () => {
    setCancelDowngradeLoading(true);
    let isFailed = false;

    if (isFailed) return setCancelDowngradeLoading(false);

    try {
      const response = await backendAPI.post("/companies/subscriptions/cancel_downgrade");

      setCancelDowngradeLoading(false);

      if (response.data && response.status === 200) {
        ShowToastWithTranslation({
          toast,
          Children: SuccessfulToast,
          text: t("Downgrade canceled successfully!"),
        });
        dispatch(fetchSubscriptionData());
      } else {
        ShowToastWithTranslation({
          toast,
          Children: OperationFailedWithTranslation,
          text: t("Operation failed!"),
        });
      }
    } catch (error) {
      reloadOnUnauthorized(error);
      setCancelDowngradeLoading(false);
      ShowToastWithTranslation({
        toast,
        Children: OperationFailedWithTranslation,
        text: t("Operation failed!"),
      });
    }
  };

  return (
    <View
      width={"70%"}
      direction={"column"}
      attributes={{
        style: {
          backgroundColor: "var(--rs-color-white)",
          padding: xl * 4,
          paddingBottom: xl * 8,
        },
      }}
    >
      <Text variant="title-2">{t("My Subscription")}</Text>
      <Text
        variant="body-1"
        attributes={{
          style: {
            marginBottom: xl * 4,
          },
        }}
      >
        {`${t("You are currently on the")} ${t(Capitalize(subscription.interval))} ${Capitalize(
          subscription.subscription_type
        )} ${t("plan")}`}
      </Text>
      <View direction={"column"} gap={3} className="content-container">
        <View
          direction={"row"}
          textAlign="start"
          attributes={{ style: { marginBottom: md, justifyContent: "space-between" } }}
        >
          <Text variant="body-strong-1">{t("Current Subscription")}</Text>
          <View direction={"column"} align={"end"}>
            <Text variant="body-1">{Capitalize(subscription.subscription_type)}</Text>
            <Text color="neutral-faded">
              {t("From")} {formatDate(subscription.current_period_start_at)}
            </Text>
          </View>
        </View>
        <View
          direction={"row"}
          textAlign="start"
          attributes={{ style: { marginBottom: md, justifyContent: "space-between" } }}
        >
          <Text variant="body-strong-1">{t("Status")}</Text>
          <View direction={"column"} align={"end"}>
            <Text variant="body-1">{t(Capitalize(subscription.status))}</Text>
          </View>
        </View>
        {subscription.status !== "canceled" ? (
          <View
            direction={"row"}
            textAlign="start"
            attributes={{ style: { marginBottom: md, justifyContent: "space-between" } }}
          >
            <Text variant="body-strong-1">{t("Next Invoice")}</Text>
            <View direction={"column"} align={"end"}>
              <Text variant="body-1">
                {getPlanDetails(subscription.stripe_price_id, subscriptionPrices).currencySymbol}
                {
                  subscription.scheduled_for_downgrade
                    ? getPlanDetails(subscription.scheduled_price_id, subscriptionPrices).price
                    : subscription.upcoming_invoice_amount
                } {subscription.scheduled_for_downgrade !== true ? `(${t("including tax")})` : `(${t("plus tax")})`}
              </Text>
              <View>
                <Text color="neutral-faded">
                  {t("On") + " " + formatDate(subscription.next_invoice)}
                </Text>
              </View>
            </View>
          </View>
        ) : null}
        {subscription.status !== "canceled" && !subscription.scheduled_for_downgrade ? (
          <View
            direction={"row"}
            textAlign="start"
            attributes={{ style: { marginBottom: md, justifyContent: "space-between" } }}
          >
            <Text variant="body-strong-1">{t("Renews")}</Text>
            <View justify={"start"}>
              <Text variant="body-1">
                {t("Every")} {t(subscription.interval.slice(0, -2))}
              </Text>
            </View>
          </View>
        ) : null}
        {subscription.scheduled_for_downgrade || subscription.status === "canceled" ? (
          <View
            direction={"row"}
            textAlign="start"
            attributes={{ style: { marginBottom: md, justifyContent: "space-between" } }}
          >
            <Text variant="body-strong-1">{t("Downgrade Status")}</Text>
            <View direction={"column"} align={"end"}>
              <Text variant="body-1"> {t("Scheduled")}</Text>
              <Text color="neutral-faded">
                to{" "}
                {!subscription.scheduled_price_id
                  ? t("Free")
                  : t(Capitalize(getPlanDetails(subscription.scheduled_price_id, subscriptionPrices).interval))}{" "}
                {" " + (subscription.scheduled_price_id && getPlanByPriceId(subscription.scheduled_price_id, subscriptionPrices) ? getPlanByPriceId(subscription.scheduled_price_id, subscriptionPrices).title : "")}{" "}
                {t("plan")}
              </Text>
              <Text color="neutral-faded">
                {t("on")} {formatDate(subscription.current_period_end_at)}
              </Text>
            </View>
          </View>
        ) : null}
      </View>

      {isAdmin ? (
        <View
          width={"100%"}
          align={"center"}
          justify={"center"}
          direction={!subscription.scheduled_for_downgrade ? null : "row"}
          gap={sm}
        >
          <Button
            color="primary"
            loading={checkoutLoading}
            attributes={{
              style: {
                width: "50%",
                marginTop: xl * 4,
              },
              onClick: () => {
                handleManageSubscription();
              },
            }}
          >
            {t("Manage Subscription")}
          </Button>
          {subscription.scheduled_for_downgrade && subscription.status !== "canceled" ? (
            <Button
              color="critical"
              variant="outline"
              loading={cancelDowngradeLoading}
              attributes={{
                style: {
                  width: "50%",
                  marginTop: xl * 4,
                },
                onClick: () => {
                  handleHandleCancelDowngrade();
                },
              }}
            >
              {t("Cancel Downgrade")}
            </Button>
          ) : null}
        </View>
      ) : null}
    </View>
  );
};

export default SubscriptionDetails;
