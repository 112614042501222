import { Plus } from "icons";
import { useSelector } from "react-redux";
import { Button, Text, View } from "reshaped";
import { cooperColors } from "utils/colors/accents";
import useSpacing from "components/hooks/useSpacing";
import { AcceptedInvite } from "components/pages/settings";
import useTranslation from "components/hooks/useTranslation";

export const AcceptedInvites = ({ handleInvitation }) => {
  const { lg, xl } = useSpacing();
  const { t, locale } = useTranslation();
  const { invitation, auth } = useSelector((state) => state);

  const { user: currentUser } = auth;
  const { accepted } = invitation;

  return (
    <View gap={xl}>
      <Text variant="title-3">{t("My team")}</Text>
      <View gap={lg} direction="row" width="700px">
        {accepted && Array.isArray(accepted) && (
          <>
            {accepted.length === 0 && (
              <View.Item columns={12}>
                <Text color="neutral" variant="body-2">
                  {t("No accepted invites")}
                </Text>
              </View.Item>
            )}
            {accepted.length >= 0 && (
              <>
                {accepted.map((user) => {
                  return <AcceptedInvite key={user.id} user={user} currentUser={currentUser} />;
                })}
              </>
            )}
          </>
        )}
        <View.Item columns={locale === "de" ? 8 : 6}>
          <View
            direction="row"
            align="center"
            borderRadius="medium"
            borderColor="neutral-faded"
            padding={lg}
          >
            <View>
              <Text variant="body-1">{t("Add team member")}</Text>
              <Text color="neutral-faded" variant="body-2">
                {t("Invite your team to collaborate")}
              </Text>
            </View>
            <View.Item grow>
              <View width="100%" align="end" justify="center">
                <Button
                  variant="outline"
                  size="small"
                  onClick={handleInvitation}
                  attributes={{
                    style: {
                      borderColor: "var(--rs-color-foreground-neutral-faded)",
                      borderRadius: "50%",
                      height: "32px",
                      width: "32px",
                    },
                  }}
                >
                  <View height="100%" width="100%" direction="row" align="center" justify="center">
                    <Plus size={12} fill={cooperColors["foreground-neutral-faded"]} />
                  </View>
                </Button>
              </View>
            </View.Item>
          </View>
        </View.Item>
      </View>
    </View>
  );
};
